import {StateStoreService} from '@rgi/rx/state';
import {PncPsalesConfirmationState} from '../../resources/states/confirmation-state';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPostSalesOrchestratorService} from '../../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../../services/pnc-postsales-integration.service';
import {RgiPncPsalesStateManager} from '../../api/rgi-pnc-postsales-state.manager';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPsalesHttpErrorService} from '../../services/pnc-postsales-http-error.service';
import {Observable, of} from 'rxjs';
import {PncDomainService} from '../../resources/http/pnc-domain.service';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Documents} from '../../resources/model/api/document';
import {OutboundDocumentsModalComponent, OutboundDocumentsReference} from '@rgi/shared-components';
import {ModalService} from '@rgi/rx/ui';

export class PncPsalesConfirmationStateManager extends RgiPncPsalesStateManager<PncPsalesConfirmationState> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    protected resourceService: PncDomainService,
    protected modalService: ModalService) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
  }

  actionExit() {
    this.updateState$(this.orchestrator.goToNextStep(this.getCurrentState(), this.activeRoute));
  }

  actionOpenDetail() {
    this.integrationService.openPolicyDetail(this.activeRoute.id, this.getCurrentState().contractId, this.getCurrentState().policyNumber)
  }

  initState$(_state: Observable<PncPsalesConfirmationState>): Observable<PncPsalesConfirmationState> {
    return of(new PncPsalesConfirmationState(this.activeRoute)).pipe(
      concatMap((st: PncPsalesConfirmationState) => {
        return this.resourceService.getDocuments$(st.policyNumber, st.currentOperation.code).pipe(
          map((resp: Documents) => {
            st.documents = resp;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesConfirmationState) => {
        if (st.documents?.documents?.length > 0) {
          return this.resourceService.isFunctionAllowed$('ODMMFN').pipe(
            map((allowed: boolean) => {
              if (allowed) {
                st.allowedFunctions.push('ODMMFN');
              }
              return st;
            })
          );
        } else return of(st);
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesConfirmationState) => st)
    );
  }

  onAction(_action: string): void | Observable<PncPsalesConfirmationState> {
    throw new Error('Method not implemented.');
  }

  public downloadDocuments$(policyNumber: string, documentCode: string): Observable<Blob> {
    return this.resourceService.downloadDocuments$(policyNumber, documentCode);
  }

  openOutboundDocumentsModal() {
    const state = this.getCurrentState();
    if (this.isUserFnAllowed('ODMMFN')) {
      const docRef = new OutboundDocumentsReference(state.policyNumber, state.proposalNumber, state.movementId, state.currentOperation.description);
      const modal = this.modalService.openComponent(OutboundDocumentsModalComponent, docRef);
      modal.modal.enableClickBackground = false;
    } else {
      console.error('User does not have permission to open Outbound Documents Modal'); //TODO understand if we show the print button
    }
  }

  isUserFnAllowed(fnName: string): boolean {
    return this.getCurrentState().allowedFunctions?.includes(fnName);
  }

  showPrintDocumentsButton(){
    const state = this.getCurrentState();
    return state.documents?.documents?.length > 0 && this.isUserFnAllowed('ODMMFN');
  }

}
