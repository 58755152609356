<template #productAssetModalOverlay></template>
<template #premiumDetailsModalOverlay></template>
<template #packageModalModalOverlay></template>

<mic-quotation-controls (recalculate)="calculatePremium()" (showCommissions)="openCommissionsModal()"
  (showContributionsAndTaxes)="openContributionsAndTaxesModal()" (showDiscounts)="openDiscountsModal()"
  (showLPSTaxesModal)="openLPSTaxesModal()" (showPremiumDetails)="openPremiumDetailsModal()"
  (showWarrantiesSummary)="openWarrantiesSummaryModal()" (unitsViewMode)="onUnitsViewModeChange($event)"
  [annualPremiumValue]="annualPremiumValue" [premiumInstallment]="premiumSubscriptionInstalment"
  [recalculateAvailable]="recalculateAvailableQuotation" [showContributionAndTaxesMenu]="showContributionsAndTaxesMenu"
  [showLPSTaxes]="showLPSTaxes" [showWarrantiesSummaryModal]="showWarrantiesSummaryModal">
</mic-quotation-controls>

<div class="nmf_cont_page">
  <div class="nmf_cont_sez">
    <!-- TITLE SECTION ----------------------------------------------------------------------------->
    <div class="nmf_cont_titsez nmf_cont_titsez_color">
      <span class="header-icon rgifont rgi-dettagli_tecnici nfm_font_size"></span>
      <h3 translate>Asset Data</h3>
      <div>
        <button (click)="goToEditAssetData()" class="btn btn-default btn-quotation-detail" translate type="button">
          Edit Data<span class="glyphicon glyphicon-pencil"></span>
        </button>
        <button (click)="thisIsNotMyVehicle()" *ngIf="isModificaVeicoloVisibile"
                class="btn btn-default btn-quotation-detail" translate type="button">Change
          Vehicle
        </button>
      </div>
    </div>

    <!-- ASSET VEHICLE DATA ----------------------------------------------------------------------------->
    <div>
      <div class="nmf_cont_dati">
        <div *ngFor="let assetStaticDataRow of assetStaticDataRows">
          <div class="nmf_cont_single_data1">
            <div *ngFor="let data of assetStaticDataRow; index as i; trackBy: genericElementsTrackByFn"
              class="nmf_cont_single_data nmf_cont_single_data_color">
              <span *ngIf="data?.label !== 'Registration Date:'" translate>{{ data?.label }}
                <b> {{ data?.value }}</b>
              </span>
              <span *ngIf="data?.label === 'Registration Date:'" translate>{{ data?.label }}
                <b>{{ data?.value | date:'dd/MM/yyyy'}}</b>
              </span>
            </div>
          </div>
        </div>
      </div>


      <div *ngFor="let variableRow of variablesRows; index as i;" class="nmf_cont_riga">
        <div *ngIf="i === 0" class="nmf_cont_single_data nmf_cont_single_data_color">
          <form [formGroup]="assetForm">
            <label for="setup" translate>Setup:</label>
            <pnc-required required="true"></pnc-required>
            <div *ngIf="!setupOutOfMagazine">
              <div *ngIf="setupsListMode">
                <select (change)="onSetupChange()" [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="setup">
                  <option></option>
                  <option *ngFor="let setup of setups" [ngValue]="setup">
                    {{setup?.description}}
                  </option>
                </select>
                <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
              </div>
              <div *ngIf="!setupsListMode">
                <input (change)="onSetupChange()" [value]="staticData.setup.description" class="form-control mic-input"
                  data-qa="setup" formControlName="setupDescription" type="text">
              </div>
            </div>
            <div *ngIf="setupOutOfMagazine">
              <input (change)="onSetupChange()" [value]="staticData.setup.description" class="form-control mic-input"
                data-qa="setup" formControlName="setupDescription" type="text">
            </div>
          </form>
        </div>

        <div *ngFor="let variable of variableRow;" class="nmf_cont_single_data nmf_cont_single_data_color">
          <mic-variable-control (updateVariable)="onVariableChange($event)" *ngIf="isQuotationReady"
            [variableControlForm]="assetForm" [variable]="variable">
          </mic-variable-control>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- ACCORDION VARIABLE ----------------------------------------------------------------------------->
<div class="nmf_cont_moredetails">
  <pnc-accordion #accordion activeIds="panel-0" class="nmf_cont_moredetails">

    <pnc-panel class="panel-item">

      <ng-template class="panel-title" let-isOpen="isOpen" pncPanelTitle>
        <span [class.rgi-minus]="isOpen" [class.rgi-plus]="!isOpen"
          class="rgifont vertical-text-top margin-right-10"></span>
        <span class="rgifont" data-qa="panel-button"></span>
        <span class="header-icon rgifont rgi-fattori nfm_font_size vertical-bottom margin-right-10"></span>
        <span class="panel-name vertical-text-bottom" translate>Asset Variables</span>
      </ng-template>

      <ng-template *ngIf="variables" class="panel-container" pncPanelContent>
        <mic-variables *ngIf="isQuotationReady" [showHeader]="false" [variablesRows]="variablesRowsAsset()"
          [variablesType]="'ASSET'" [variables]="variables">
        </mic-variables>
      </ng-template>

    </pnc-panel>
  </pnc-accordion>
</div>

<!-- ACCORDION PARTY VARIABLE ----------------------------------------------------------------------------->
<div *ngIf="showPartyVariables" class="nmf_cont_moredetails">
  <pnc-accordion #accordion activeIds="panel-0" class="nmf_cont_moredetails">

    <pnc-panel class="panel-item">
      <ng-template class="panel-title" let-isOpen="isOpen" pncPanelTitle>
        <span [class.rgi-minus]="isOpen" [class.rgi-plus]="!isOpen"
          class="rgifont vertical-text-top margin-right-10"></span>
        <span class="rgifont" data-qa="panel-button"></span>
        <span class="header-icon rgi-nmf rgi-nmf-users_variables vertical-bottom margin-right-10"></span>
        <span class="panel-name vertical-text-bottom" translate>Party Variables Quotation</span>
      </ng-template>

      <ng-template *ngIf="variables" class="panel-container" pncPanelContent>
        <mic-party-variables *ngIf="isQuotationReady" [showHeader]="false" [variablesType]="'PARTY'"
          [variables]="variables"></mic-party-variables>
      </ng-template>

    </pnc-panel>
  </pnc-accordion>
</div>

<!-- ACCORDION INSURANCE BACKGROUND ----------------------------------------------------------------------------->
<div class="nmf_cont_moredetails">
  <pnc-accordion #accordion activeIds="panel-0" class="nmf_cont_moredetails">

    <pnc-panel class="panel-item">

      <ng-template class="panel-title" let-isOpen="isOpen" pncPanelTitle>
        <span [class.rgi-minus]="isOpen" [class.rgi-plus]="!isOpen"
          class="rgifont vertical-text-top margin-right-10"></span>
        <span class="rgifont" data-qa="panel-button"></span>
        <span class="header-icon rgi-nmf rgi-nmf-info vertical-bottom margin-right-10"></span>
        <span class="panel-name vertical-text-bottom" translate>Insurance Background</span>
      </ng-template>

      <ng-template class="panel-container" pncPanelContent>
        <mic-insurance-background (showATRHeader)="changeATRHeaderVisibility($event)"
          (showATRTable)="changeATRTableVisibility($event)" *ngIf="isQuotationReady"
          [insuranceStatusCompletenessErrors]="false" [showEdit]="false" [showHeader]="false">
        </mic-insurance-background>

        <!-- RISK CERTIFICATE -->
        <div *ngIf="showATRHeader || showATRTable" class="nfm-pad-top-20">
          <mic-risk-certificate *ngIf="isQuotationReady" [showATRHeader]="showATRHeader" [showATRTable]="showATRTable"
            [showEdit]="false">
          </mic-risk-certificate>
        </div>
      </ng-template>

    </pnc-panel>
  </pnc-accordion>
</div>

<!-- PACKAGES SECTION-->
<div *ngIf="quotation && quotation.packages && packageView"
  class="nmf_cont_titsez nmf_cont_titsez_color nmf_cont_titselgar">
  <span class="header-icon rgifont rgi-view_square nfm_font_size"></span>
  <h3 translate>Package selection</h3>
  <button (click)="switchView()" *ngIf="quotation.isSwitchEnable && packageView"
    class="btn btn-default btn-quotation-detail" type="button">
    {{'Customize' | translate}}<span class="rgifont rgi-sliders"></span>
  </button>
</div>

<mic-packages (editPacketDetails)="openPackageModal($event)" (eventPropagation)="eventPropagation.emit($event)"
  (recalculateAvailable)="changeRecalculateAvailable($event)" (updatePacket)="onPacketChange($event)"
  *ngIf="quotation && quotation.packages && packageView" [isMultiPacket]="quotation.isMultiPackages"
  [packagesList]="quotation.packages" [packagesPremium]="premium?.packagesPremium">
</mic-packages>

<!-- TITLE SECTION ----------------------------------------------------------------------------->
<ng-container *ngIf="!packageView">
  <div *ngIf="premium?.sectionsPremium" class="nmf_cont_titsez nmf_cont_titsez_color nmf_cont_titselgar">
    <span class="header-icon rgifont rgi-sliders nfm_font_size"></span>
    <h3 translate>Cover selection</h3>
    <button (click)="switchView()" *ngIf="quotation.isSwitchEnable && !packageView"
      class="btn btn-default btn-quotation-detail" type="button">
      {{'Show packages' | translate}}<span class="rgifont rgi-view_square"></span>
    </button>
  </div>

  <mic-asset-unit (eventPropagation)="eventPropagation.emit($event)"
    (recalculateAvailable)="changeRecalculateAvailable($event)" (updateUnitList)=refreshUnitList($event)
    (validationMessagesAsset)="verifyAsset($event)" *ngIf="unitSections && isQuotationReady"
    [sectionsPremium]="premium?.sectionsPremium" [unitSections]="unitSections" [viewUnitsBox]="viewUnitsBox">
  </mic-asset-unit>
</ng-container>

<br>
<div class="nmf_cont_titsez nmf_cont_titsez_color">
  <span class="header-icon rgifont rgi-dettagli_tecnici nfm_font_size"></span>
  <h3 class="header-text" translate>Policy Data</h3>
</div>
<mic-parameters (nonBlockingMessagesParameter)="verifyParameterNonBlockingMessages($event)"
  (recalculatePremium)="getPremiumResetPackage()" (reinitializeVariables)="initializeVariables()"
  (validationMessagesParameter)="verifyParameterBlockingMessages($event)" *ngIf="isQuotationReady">
</mic-parameters>


<div *ngIf="contractVariables != undefined && contractVariables.length > 0">
  <br>
  <br>
  <mic-contract-variables (eventPropagation)="eventPropagation.emit($event)" (recalculatePremium)="getPremium()"
    [variablesRows]="variablesRowsContract()" [variables]="contractVariables" editMode="true">
  </mic-contract-variables>
</div>

<div *ngIf="clausesList != undefined && clausesList.length > 0">
  <br>
  <br>
  <mic-contract-clause (eventPropagation)="eventPropagation.emit($event)" (recalculatePremium)="getPremium()"
    [clauseList]="clausesList" [headerIcon]="'rgi-clausole'" [header]="'Clausole di Contratto'" [viewError]="true">
  </mic-contract-clause>
</div>

<!-- <mic-questionnaires></mic-questionnaires>

<mic-addresses></mic-addresses>
<br>
-->

<div *ngIf="(validationMessages?.length > 0
    || nonBlockingMessages?.length > 0) && enableWarningIcon && !showFloatingMessages"
  class="errors-warnings-floating-wrapper ng-scope">
  <div (click)="onWarningIconClick()" class="errors-warnings-floating-icon minimized">
    <span class="rgifont rgi-exclamation-triangle"></span>
  </div>
</div>

<div #errorsElement class="errors-warnings-floating-wrapper fix-to-bottom">
  <div *ngIf="(validationMessages?.length > 0
        || nonBlockingMessages?.length > 0) && showFloatingMessages" class="errors-warnings-floating-messages">
    <div class="content-errors nmf_content-errors">
      <pnc-form-message [messages]="nonBlockingMessages" data-qa="action-error-messages" detail="validationError"
        objectType="complex" type="warning">
      </pnc-form-message>
      <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
        objectType="complex" type="error">
      </pnc-form-message>
      <div (click)="onCloseErrorsClick()" *ngIf="(validationMessages?.length > 0
                || nonBlockingMessages?.length > 0) && showFloatingMessages"
        class="errors-warnings-floating-icon expanded">
        <span class="rgifont rgi-close"></span>
      </div>
      <div class="nmf_triangle"></div>
    </div>
  </div>
</div>

<div *ngIf="!showFloatingMessages" id="error-container">
  <pnc-form-message *ngIf="nonBlockingMessages?.length > 0" [messages]="nonBlockingMessages"
    data-qa="action-error-messages" detail="validationError" objectType="complex" type="warning">
  </pnc-form-message>
  <pnc-form-message *ngIf="validationMessages?.length > 0" [messages]="validationMessages"
    data-qa="action-error-messages" detail="validationError" objectType="complex" type="error">
  </pnc-form-message>
</div>

<br>

<div class="btn-group btn-group-justified">
  <!-- Button for save quote -->
  <div *ngIf="showSaveQuoteButton && !isSubstitution" class="btn-group">
    <button (click)="saveQuote()" [disabled]="disableSaveQuote"
      class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="btn-quotation-savequote" type="button">
      <span translate>SAVE QUOTE</span>
    </button>
  </div>
  <div *ngIf="!isSubstitution" class="btn-group">
    <button (click)="goToProposal()"
      [disabled]="disableNext || insuranceStatusMessages?.length > 0 || parameterMessages?.length > 0"
      class="btn btn-warning pull-right text-uppercase" data-qa="btn-quotation-next" type="button">
      <span translate>NEXT</span>
    </button>
  </div>
  <div *ngIf="isSubstitution" class="btn-group">
    <button (click)="goToProposal()" [disabled]="insuranceStatusMessages?.length > 0"
      class="btn btn-warning pull-right text-uppercase" data-qa="btn-quotation-change-party" type="button">
      <span translate>CHANGE PARTY</span>
    </button>
  </div>
  <div *ngIf="isSubstitution" class="btn-group">
    <button (click)="goToMandatoryData()"
      [disabled]="insuranceStatusMessages?.length > 0 || parameterMessages?.length > 0"
      class="btn btn-warning pull-right text-uppercase" data-qa="btn-quotation-next" type="button">
      <span translate>NEXT</span>
    </button>
  </div>
</div>
