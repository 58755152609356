<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="{{TRANSFER_RESERVE_STEP.DATE.label}}" id="{{TRANSFER_RESERVE_STEP.DATE.id}}" [errors]="errors" fieldId="{{TRANSFER_RESERVE_STEP.DATE.fieldId}}" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="TRANSFER_RESERVE_STEP.DATE.formName" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="{{TRANSFER_RESERVE_STEP.POLICY_FACTORS.id}}" *ngIf="hasProductFactor" label="{{TRANSFER_RESERVE_STEP.POLICY_FACTORS.label}}" [errors]="errors" fieldId="{{TRANSFER_RESERVE_STEP.POLICY_FACTORS.fieldId}}"
    (next)="onNext($event)">
    <div formGroupName="{{TRANSFER_RESERVE_STEP.POLICY_FACTORS.formName}}" class="row">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateProductFactors($event)"
        [form]="formGroup.controls['factors']" [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="{{TRANSFER_RESERVE_STEP.QUESTS_FIN.id}}" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')"
    *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" fieldId="{{TRANSFER_RESERVE_STEP.QUESTS_FIN.fieldId}}"
    label="{{TRANSFER_RESERVE_STEP.QUESTS_FIN.label}}" (next)="onNext($event)">
    <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
      [questionnairesCode]="getQuestMap('FIN')" [type]="'FIN'" [date]="effectiveDate" [productCode]="productCode"
      [savedIds]="questionnairesFromAuthorization" (loaderQuest)="loaderQuestManager($event)"
      (loaded)="countQuest($event)" #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step id="{{TRANSFER_RESERVE_STEP.ASSET_FACTORS.id}}" *ngIf="hasAssetFactor" label="{{TRANSFER_RESERVE_STEP.ASSET_FACTORS.label}}" [errors]="errors" fieldId="{{TRANSFER_RESERVE_STEP.ASSET_FACTORS.fieldId}}"
    (next)="onNext($event)">
    <div formGroupName="{{TRANSFER_RESERVE_STEP.ASSET_FACTORS.formName}}" class="row">
      <lpc-factor-adapter #assetFactorAdapter (changeValEmitter)="updateAssetFactors($event)"
        [form]="formGroup.controls['assetFactors']" [factors]="listAssetFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="{{TRANSFER_RESERVE_STEP.QUESTS_HLT.id}}" [hidden]="!validQuestsCode.get('HLT')" [jump]="!validQuestsCode.get('HLT')"
    [feErrors]="errors" *ngIf="showSectionQuestHltAfterInit && showQuestionnaireByType('HLT')" feFieldId="questsHlt"
    fieldId="{{TRANSFER_RESERVE_STEP.QUESTS_HLT.fieldId}}" label="{{TRANSFER_RESERVE_STEP.QUESTS_HLT.label}}" (next)="onNext($event)">
    <lpc-questionnaire [active]="!isAfterId('questsHlt')" [key]="composeKey(key, 'questsHlt')"
      [questionnairesCode]="getQuestMap('HLT')" [type]="'HLT'" (loaded)="countQuest($event)" [date]="effectiveDate"
      [savedIds]="questionnairesFromAuthorization" [productCode]="productCode"
      (loaderQuest)="loaderQuestManager($event)" #healthQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step id="{{TRANSFER_RESERVE_STEP.DATA_TRANSFER.id}}" label="{{TRANSFER_RESERVE_STEP.DATA_TRANSFER.label}}" [errors]="errors" fieldId="{{TRANSFER_RESERVE_STEP.DATA_TRANSFER.fieldId}}"
    (next)="onNext($event)">
    <div *ngIf="!!draftDataTransfer.length" class="row">
      <lpc-form-field-handler [isActive]="isActiveStep(TRANSFER_RESERVE_STEP.DATA_TRANSFER.id)"
      [isOpen]="isActiveOrPassedStep(TRANSFER_RESERVE_STEP.DATA_TRANSFER.id)"  formControlName="dataTransfer" [definitions]="draftDataTransfer"></lpc-form-field-handler>
    </div>
  </lpc-step>

  <lpc-step id="{{TRANSFER_RESERVE_STEP.QUOTATION.id}}" [enableAuth]="isAuth" label="{{TRANSFER_RESERVE_STEP.QUOTATION.label}}" [errors]="errors" fieldId="{{TRANSFER_RESERVE_STEP.QUOTATION.fieldId}}"
    (next)="onNext($event)" [disableNext]="disableNext">
    <lpc-quotation-control [disableQuote]="disableQuote" [disableDetails]="disableDetails" [enableProv]="enableProv"
      (quote)="recalculate(false)" [quotationDefinition]="quotationDefinition" (openProvModal)="openProvModal($event)"
      (openQuotationModal)="openQuotationModal($event)" (openWarrantiesModal)="openWarrantiesModal($event)">
    </lpc-quotation-control>
    <div class="row">
      <lpc-unit-table *ngIf="!!risksForUnits?.length" formControlName="{{TRANSFER_RESERVE_STEP.RISKS.formName}}"
        [investedAmountOnQuotation]="getTotalAmount(quotationDefinition)" [risks]="risksForUnits"
        [showInsuredAmount]="false" [openSelectedUnits]="false" (updateUnitTable)="updateUnit($event)">
      </lpc-unit-table>
    </div>
  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" id="{{TRANSFER_RESERVE_STEP.QUESTS_UNIT.id}}" [errors]="errors" [enableAuth]="isAuth" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')"
  fieldId="{{TRANSFER_RESERVE_STEP.QUESTS_UNIT.fieldId}}" label="{{TRANSFER_RESERVE_STEP.QUESTS_UNIT.label}}" (next)="onNext($event)"
  (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-questionnaire
                [active]="!isAfterId('Unit Questionnaire')" [key]="composeKey(key, 'Unit Questionnaire')"
                [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                (loaderQuest)="loaderQuestManager($event)"
                (loaded)="countQuest($event)"
                #avcQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step *ngIf="showInvestment" id="investmentProfiles"
    label="lpc_Investment_profiles" fieldId="investmentProfiles" [jump]="skipInvestmentProfiles" [nextVisible]="!formGroup.get('investmentProfiles').invalid"
    (next)="onNext($event)">
  <lpc-investment-step-adapter *ngIf="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
    [currentStep]="isActiveStep('investmentProfiles')"
    [activeStep]="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
    [customFormGroup]="formGroup"
    [definition]="investmentProfiles"
    [viewOnly]="skipInvestmentProfiles"
    [totalAmount]="totalAmountToInvest"
    [config]="getInvestmentProfileConfigurations"
    (volatilityEvent)="handleVolatilityEvent($event)">
  </lpc-investment-step-adapter>
</lpc-step>

<lpc-step *ngIf="showInvestment" id="investmentFunds" [jump]="skipInvestmentFunds && isPaymentVisibile()"
  label="lpc_investment_funds" [nextVisible]="!formGroup.get('investmentFunds').invalid && (!isWithVolatility() || !!volatility)" fieldId="investmentFunds"
  (next)="onNext($event, !showSectionQuestAfterInit)">
  <lpc-investment-step-adapter *ngIf="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
    [currentStep]="isActiveStep('investmentFunds')"
    [activeStep]="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
    [customFormGroup]="formGroup"
    [definition]="investmentProfiles"
    [viewOnly]="skipInvestmentFunds"
    [totalAmount]="totalAmountToInvest"
    [(volatility)]="volatility"
    [config]="getInvestmentFundConfigurations"
    (volatilityEvent)="handleVolatilityEvent($event)">
  </lpc-investment-step-adapter>
</lpc-step>

  <lpc-step *ngIf="isPaymentVisibile()" id="{{TRANSFER_RESERVE_STEP.PAYMENT.id}}" [enableAuth]="isAuth" label="{{TRANSFER_RESERVE_STEP.PAYMENT.label}}" [errors]="errors"
    fieldId="{{TRANSFER_RESERVE_STEP.PAYMENT.fieldId}}" (next)="onNext($event, !showSectionQuestAfterInit)" (confirmAccept)="onNext($event)"
    [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-payment-step [paymentTypeDefinitions]="paymentTypes" [required]="true" [summary]="isAfterId('payment')"
      formControlName="{{TRANSFER_RESERVE_STEP.PAYMENT.formName}}">
    </lpc-payment-step>
  </lpc-step>

  <lpc-step
        *ngIf="isAuth || !!errors && !!errors.length"
        id="{{TRANSFER_RESERVE_STEP.WARNING.id}}" label="{{TRANSFER_RESERVE_STEP.WARNING.label}}"
        (next)="publish()"
        [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
        (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="{{TRANSFER_RESERVE_STEP.DOCUMENT.label}}" [errors]="errors" fieldId="{{TRANSFER_RESERVE_STEP.DOCUMENT.fieldId}}" id="{{TRANSFER_RESERVE_STEP.DOCUMENT.id}}"
    [nextVisible]=false>
    <lpc-document-wrapper *ngIf="!!publishMessage" [contractId]="getContractId" [operationCode]="getOperationCode"
      [publishMessage]="publishMessage" [isAuth]="isAuth" [authorizationId]="authorizationId" [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>
    <lpc-navigation-buttons id="last-step-buttons" (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'"
      [backVisible]="true" [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>
