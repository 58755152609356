<form [formGroup]="group">
  <div class="grid">
    <!-- <pnc-datepicker
      [label]="label"
      [customFormControlName]="config.name"
      [customFormGroup]="group"
      [required]="config.mandatory"
      (change)="onChange()"
      (dateSelect)="onChange()"
      data-qa="mic-datepicker-payment"
    >
    </pnc-datepicker> -->
    <rgi-rx-form-field>
      <label [for]="label" rgiRxLabel>{{label}}</label>
      <rgi-rx-date-picker [openOnClick]="false">
        <input
          [rgiRxDateTimeInput]="picker1"
          formControlName="{{config.name}}"
          data-qa="mic-datepicker-payment"
          (dateTimeChange)="onChange()">
      </rgi-rx-date-picker>
      <rgi-rx-date-time #picker1></rgi-rx-date-time>
     </rgi-rx-form-field>
  </div>
</form>
