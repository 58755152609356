/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
import {AuthTranslationsKeys} from './auth-translations-keys';


export const AUTH_CA_ES: AuthTranslationsKeys = {
    _AUTH_: {
        _CARD_TITLE_: 'Autoritzacions',
        /* eslint-disable max-len */
        // _VIEW_FROM_{{_START_}}_TO_{{_END}}_OF_{{AUTHORIZATIONLISTLENGTH}}_ELEMENTS: "Veure des de {{ start }} fins a {{ end}} d'elements {{authorizationList.length}}",
        _ACTION_IS_MANDATORY: 'L’acció és obligatòria',
        _ARE_YOU_SURE_TO_APPLY_THIS_ACTION: 'Voleu demanar aquesta acció?',
        _AUTHORIZATION_DEROGATION_LEVEL: 'Nivell de derogació de l’autorització:',
        _AUTHORIZATION_LEVEL: 'Nivell d\'autorització:',
        _AUTHORIZATION_REQUEST_CODE: 'Codi de petició de l’autorització',
        _BACK: 'Enrere',
        _BLOCK: 'Bloquegeu',
        _BUY_POLICY: 'Subscriviu_pòlissa',
        _BY_USER: 'per l’usuari',
        _CANCEL: 'Cancel·leu',
        _CANCEL_REQUEST: 'Cancel·leu sol·licitud',
        _CHARACTERS: 'Caràcters',
        _CODE: 'Codi',
        _CLOSE: 'cerrar',
        _COMMENT: 'Comentari:',
        _COMPETENCE_LEVEL: 'Nivell de competència:',
        _COMPETENT_LEVEL: 'Nivell competent',
        _COMPETENT_USER: 'Usuari competent',
        _CONFIRM: 'Confirmeu',
        _CONFIRM_VARIATION: 'Confirmeu_variació',
        _CONTRACT_NUMBER: 'Número de contracte',
        _CONTRACT_TYPE: 'Tipus de contracte',
        _DATE: 'Data',
        _DEROGATION_LEVEL: 'Nivell de derogació:',
        _DESCRIPTION: 'Descripció',
        _EFFECTIVE_DATE: 'Data d\'entrada en vigor',
        _ELEMENTS: 'elements',
        _EMPTY: 'buit',
        _ERROR: 'Control de suscripción',
        _ERR_AUTH_DEROGATION_LEVEL: 'ERR_AUTH_DEROGATION_LEVEL',
        _ERR_AUTH_EXTRA_CHECK: 'ERR_AUTH_EXTRA_CHECK',
        _ERR_AUTH_NOT_OPERABLE: 'ERR_AUTH_NOT_OPERABLE',
        _ERR_AUTH_NOT_SAVED: 'ERR_AUTH_NOT_SAVED',
        _EVENT: 'Esdeveniment',
        _FILL_IN_REQUEST_STATUS_OR_USER: 'Empleneu l’estat de sol·licitud o usuari',
        _FIND: 'trobeu',
        _HISTORY: 'Historial',
        _IN_ORDER_TO_ACCEPT_THE_REQUEST_ALL_DOCUMENTS_MUST_BE_VALIDATED: 'Per acceptat la sol·licitud, s’han de validar tots els documents',
        _ISSUE: 'emissió',
        _ISSUE_DATE: 'Data d\'emissió',
        _ISSUE_POLICY: 'emissió_pòlissa',
        _ISSUE_PROPOSAL: 'emissió_proposta',
        _ISSUE_USER: 'emeteu usuari',
        _LAST_ACTION: 'Darrera acció:',
        _LAST_ACTION_DATE: 'Data de la darrera acció:',
        _LAST_EVENT: 'Darrer esdeveniment',
        _LAST_EXECUTED_ACTION: 'Darrera acció exclosa',
        _MANAGEMENT_NODE: 'Node de gestió',
        _MODIFY_PROPOSAL: 'modifiqueu_proposta',
        _MODIFY_VARIATION: 'modifiqueu_variació',
        _NEXT_STATUS: 'Següent estat',
        _NODE: 'Nodo',
        _NODE2: 'Nodo:',
        _COMPANY: 'Companyia:',
        _NODE_CODE: 'Codi del node',
        _NODE_IS_MANDATORY: 'El node és obligatori',
        _NOTES: 'Notes',
        _NOT_AVAILABLE: 'No disponible',
        _NO_ELEMENT_PRESENT: 'No hi ha cap element present',
        _OF: 'de',
        _OK: 'ok',
        _OPERATION: 'Operació',
        _OPERATION_WAS_ISSUED_ON: 'L’operació es va emetre el',
        _OPERATOR: 'Operador:',
        _OPERATOR_DEROGATION_LEVEL: 'Nivell de derogació de l’operador',
        _OPERATOR_LEVEL: 'Nivell d\'operador:',
        _ORIGIN_TYPE: 'Tipus d\'origen',
        _PM_POLICY_NUMBER: 'Pòlissa mestra N.',
        _PM_PROPOSAL_NUMBER: 'Proposta mestra N.',
        _POLICY_NUMBER: 'Número de pòlissa',
        _POLICY_NUMBER_PM: 'Pòlissa mestra Número',
        _POLICY_TYPE: 'Tipus de pòlissa',
        _POSSIBLE_ACTIONS: 'Possibles accions',
        _POSSIBLE_DEROGATION_LEVEL: 'Possible nivell de derogació',
        _POSTSELL_CODE: 'Codi postvenda',
        _PRIORITY: 'Prioritat',
        _PRODUCT: 'Producte',
        _PRODUCT_TYPE: 'Tipus de producte',
        _PROPOSAL_NUMBER: 'Número de proposta',
        _PROPOSAL_NUMBER_PM: 'Proposta mestra Número',
        _REFERENCE: 'Referència',
        _REQUESTS_LIST: 'Llista de sol·licituds',
        _REQUEST_DATE: 'Data de la sol·licitud',
        _REQUEST_DATE_FROM: 'Data de sol·licitud des de',
        _REQUEST_DATE_FROM_IS_NOT_A_VALID_DATE: 'Data de sol·licitud des de’ no és una data vàlida',
        _REQUEST_DATE_FROM_MUST_BE_GREATER_THEN_REQUEST_DATE_TO: 'Data de sol·licitud ha de ser més gran de la data de sol·licitud a',
        _REQUEST_DATE_TO: 'Data de la sol·licitud a',
        _REQUEST_DATE_TO_IS_NOT_A_VALID_DATE: 'Data de sol·licitud no és una data vàlida',
        _REQUEST_DETAIL: 'Detall de sol·licitud',
        _REQUEST_NODE: 'Node de sol·licitud',
        _REQUEST_NUMBER: 'Número de petició',
        _REQUEST_OPERATION: 'Sol·liciteu operació',
        _REQUEST_STATUS: 'Estat de la sol·licitud',
        _REQUEST_STATUS_VARIATION: 'Variació de l’estat de la sol·licitud',
        _REQUEST_TYPE: 'Tipus de sol·licitud',
        _REQUEST_USER: 'Usuari de la sol·licitud:',
        _SALE_POINT_GROUP: 'Grup de punt de venda',
        _SEARCH_UNDER_SUBNET: 'Cerca de subxarxa',
        _SELECT_NODE: 'Seleccioneu node',
        _STATUS: 'Estat:',
        _STATUS_AFTER_EVENT: 'Estat després de l’esdeveniment',
        _STATUS_CHANGES_ARE_NOT_ADMITTED_FOR_THIS_AUTHORIZATION_REQUEST: 'No s’admeten modificacions d\'estat en aquesta sol·licitud d\'autorització',
        _STATUS_VARIATION: 'Variació de l\'estat',
        _SUBSCRIBER: 'Prenedor',
        _SUBSYSTEM: 'Subsistema',
        _SUBSYSTEM_IS_MANDATORY: 'El subsistema és obligatori',
        _TO: 'a',
        _TYPE: 'Tipus',
        _USER: 'Usuari',
        _USER_TYPE: 'Tipus d\'usuari',
        _VARIATION_SUMMARY: 'variació_resum',
        _VIEW_ELEMENT: 'Veieu element',
        _VIEW_FROM: 'Veieu formulari',
        _WARNINGS: 'Advertiments',
        _WORKFLOW_STATUS: 'Estat del flux de treball',
        _MODIFY_AUTHORIZATION_LABEL: 'Modifiqueu autorització',
        _CONFIRM_PROP_POL_AUTH: 'Confirmeu proposta/pòlissa a Autorització',
        _OPERATION_CONFIRMED: 'Operació confirmada',
        _REQUEST_AND_PROPOSAL_CANCELLED: '',
        _AUTH_REQUEST_CANCELLED: '',
        _ERROR_CANCEL_PROPOSAL_SERVICE: '',
        _NO_RESULTS: 'ca-ES_No results'
    },
    _AUTHORIZATION_SEARCH: 'Cerca de l’autorització',
    _AUTHORIZATION_SEARCH_DETAIL: 'Detall de cerca de l’autorització',
};
