/**
 * GEL REST API
 * Rest API's for Workflow services
 *
 * OpenAPI spec version: 0.0.1
 * Contact: info@rgigroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TypeBusiness = 'VN Life' | 'VN Non Life' | 'VN Life and Non Life';

export const TypeBusiness = {
    Life: 'VN Life' as TypeBusiness,
    NonLife: 'VN Non Life' as TypeBusiness,
    LifeAndNonLife: 'VN Life and Non Life' as TypeBusiness
};