<span *ngIf="!finishCharge" style="cursor: wait; width: 100%;">
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>

<span *ngIf="finishCharge">
  <div>
    <table style="width: 100%;">
      <tr>
        <td style="width: 40%;" colspan="2">
          {{ '_CLAIMS_._FROM' | translate }}: <br> <input type="text" value="{{dateFrom}}" [(ngModel)]="dateFrom"
            (change)="filterByDate()" (keyup)="filterByDate()" placeholder="aa/mm/aaaa" maxlength="10"
            style="width: 87%; font-size: 13px;" />
        </td>
        <td style="width: 40%;" colspan="2">
          {{ '_CLAIMS_._TO' | translate }}: <br> <input type="text" value="{{dateTo}}" [(ngModel)]="dateTo"
            (change)="filterByDate()" (keyup)="filterByDate()" placeholder="aa/mm/aaaa" maxlength="10"
            style="width: 87%; font-size: 13px;" />
        </td>
        <td style="width: 20%;" colspan="2"></td>
      </tr>
      <tr>
        <td style="width: 35%;">
          <input type="text" value="{{filterSelectionDescr}}" placeholder="{{placeHolderFilterType}}"
            style="width: 100%; font-size: 13px;" />
        </td>
        <td *ngIf="!showFilterType" style="width: 5%;">
          <button type="button" class="btn btn-flat btn-sm" (click)="openFilterType()" style="border-color: white;">
            <span class="glyphicon glyphicon-option-vertical" style="width:10px; height:10px;"
              title="{{ '_CLAIMS_._QUICK_FILTERS' | translate }}"></span></button>

        </td>
        <td *ngIf="showFilterType" style="width: 5%;">

          <button type="button" class="btn btn-flat btn-sm" (click)="filterByDate()" style="border-color: white;">
            <span class="glyphicon glyphicon-saved" style="width:10px; height:10px;"
              title="{{ '_CLAIMS_._BUTTONS_._SAVE' | translate }}"></span></button>
        </td>


        <td style="width: 35%;">
          <input type="text" value="{{filterSelectionBasicNoteDescr}}" placeholder="{{placeHolderFilterBasic}}"
            style="width: 100%; font-size: 13px;" />
        </td>
        <td *ngIf="!showFilterTypeBasicNote" style="width: 5%;">
          <button type="button" class="btn btn-flat btn-sm" (click)="openFilterTypeBasic()"
            style="border-color: white;">
            <span class="glyphicon glyphicon-option-vertical" style="width:10px; height:10px;"
              title="{{ '_CLAIMS_._QUICK_FILTERS' | translate }}"></span></button>

        </td>
        <td *ngIf="showFilterTypeBasicNote" style="width: 5%;">

          <button type="button" class="btn btn-flat btn-sm" (click)="filterByDate()" style="border-color: white;">
            <span class="glyphicon glyphicon-saved" style="width:10px; height:10px;"
              title="{{ '_CLAIMS_._BUTTONS_._SAVE' | translate }}"></span></button>
        </td>

        <td style="width: 15%;     text-align: center;">
          <button type="button" class="btn btn-flat btn-sm" (click)="reorder()" style="border-color: white;">
            <span class="glyphicon glyphicon-retweet" style="width:10px; height:10px;"
              title="{{ '_CLAIMS_._ASCENDING_ORDER' | translate }} -{{ '_CLAIMS_._DESCENDING_ORDER' | translate }}"></span></button>

        </td>
        <td style="float: right;" style="width: 15%;">
          <button (click)="addManualNote()" class="btn btn-warning btn-secondary pull-right" style="width: 105px;">
            {{ '_CLAIMS_._BUTTONS_._ADD_NOTE' | translate }}</button>
        </td>
      </tr>
      <tr *ngIf="showFilterType || showFilterTypeBasicNote">
        <td>
          <table *ngIf="showFilterType">
            <tr *ngFor="let filter of filterType">
              <td>
                <input type="checkbox" [(ngModel)]="filter.sel" /> {{filter.descr}}
              </td>
            </tr>
          </table>
        </td>
        <td></td>
        <td>
          <table *ngIf="showFilterTypeBasicNote">
            <tr *ngFor="let filter of filterBasicNote">
              <td>
                <input type="checkbox" [(ngModel)]="filter.sel" /> {{filter.descr}}
              </td>
            </tr>
          </table>
        </td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
  <br> <br>
</span>

<div style="height: 380px;overflow: auto;" *ngIf="finishCharge">
  <claims-day *ngFor="let day of days" [notes]="day" (rechargeEventEmitter)="recharge($event)"></claims-day>
</div>