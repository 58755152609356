<div class="container-fluid" data-qa="activity-list">
  <div *ngIf="card.isSubCard">
    <button class="btn btn-section col-6" (click)="onChangeTab(true)" [ngClass]="{'active': tabActive }">{{
      '_CLAIMS_._ACT_ACTIVE' | translate }}</button>
    <button class="btn btn-section col-6" (click)="onChangeTab(false)" [ngClass]="{'active': !tabActive }">{{
      '_CLAIMS_._ACT_CLOSED' | translate }}</button>
  </div>
  <div *ngIf="tabActive">
    <table *ngIf="hasResult()" class="table-responsive table coreDT">
      <div class="head-result thDataTable">
        <rgi-rx-datatable [sortable]="false" [data]="this.currentList" [schema]="schemaOpenedActivity"
          [sortHeader]="true" [selectable]="false" [pageOptions]="[5,10,20]">
          <ng-template rgiRxDataTableCell="activity" let-ctx>
            <div *ngIf="ctx.row?.actDictionaryBean">
              <span attr.data-qa="act-dictionary-description-label">{{ ctx.row?.actDictionaryBean?.dictionaryDescription
                }}</span>

              <div class="claims-activity-popover"
                *ngIf="ctx.row?.actTaskBean[0] && ctx.row?.actTaskBean[0].message">
                <input type="checkbox" id="btnControl" />
                <span class="rgifont rgi-info-circle float-right pull-right" for="btnControl">
                  <span class="claims-activity-popover-container">
                    <div class="activity-message">{{ ctx.row?.actTaskBean[0].message | translate }}</div>
                  </span>
                </span>
              </div>

            </div>
          </ng-template>
          <ng-template rgiRxDataTableCell="assigneeGroup" let-ctx>
            <div class="col-xl-3 hidden-xs"
              *ngIf="card.isExtend && ctx.row?.actTaskBean && ctx.row?.actTaskBean.length > 0">
              <span attr.data-qa="act-type-of-loss-label">{{ ctx.row?.actTaskBean[0].assigneeGroup }}</span>
            </div>
          </ng-template>
          <ng-template rgiRxDataTableCell="assigneeUser" let-ctx>
            <div class="col-xl-2 hidden-xs"
              *ngIf="card.isExtend && ctx.row?.actTaskBean && ctx.row?.actTaskBean.length > 0">
              <span attr.data-qa="act-type-of-loss-label">{{ ctx.row?.actTaskBean[0].assigneeUser }}</span>
            </div>
          </ng-template>
          <ng-template rgiRxDataTableCell="updDate" let-ctx>
            <div class="col-md-2 col-lg-2" *ngIf="ctx.row?.updDate">
              <span attr.data-qa="act-expiration-date-label">{{ ctx.row?.updDate | date:'dd/MM/yyyy' }}</span>
            </div>
          </ng-template>
          <ng-template rgiRxDataTableCell="expirationDate" let-ctx>
            <div class="col-md-2 col-lg-2 red-color"
              *ngIf="!dateGreaterThenToday(ctx.row) && ctx.row?.actTaskBean.status!== 4 && ctx.row?.expirationDate">
              <span attr.data-qa="act-expiration-date-label">{{ ctx.row?.expirationDate | date:'dd/MM/yyyy' }}</span>
            </div>
          </ng-template>
          <ng-template rgiRxDataTableCell="expirationDate" let-ctx>
            <div class="col-md-2 col-lg-2 red-color" *ngIf="ctx.row?.actTaskBean?.status === 4">
              <span attr.data-qa="act-expiration-date-label">{{ '_CLAIMS_._SUSPENDED' | translate }}</span>
            </div>
          </ng-template>
          <ng-template rgiRxDataTableCell="actTaskBean" let-ctx>
            <span attr.data-qa="act-actions-label"></span>
            <span class="column-name-xs visible-xs-block pull-left">Actions</span>
            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
              <claims-activity-menu [activity]="ctx.row" [card]="card"></claims-activity-menu>
            </div>
          </ng-template>
        </rgi-rx-datatable>
        <ng-template #rowDetailActive let-ctx> </ng-template>
      </div>
    </table>
    <div *ngIf="!hasResult()">
      <div class="noaction">
        <span class="icon-attention-sign"></span>
        <span data-qa="no-activity-show" translate class="notasks-message">{{ '_CLAIMS_._MESSAGE_._NO_ACTIVITY_TO_SHOW'
          | translate }}</span>
        <span class="icon-notepad"></span>
      </div>
    </div>
  </div>


  <div *ngIf="!tabActive" class="table-responsive table coreDT">
    <div *ngIf="hasClosed">
      <rgi-rx-datatable [sortable]="false" [data]="this.closedActivityList" [schema]="schemaClosedActivity"
        [sortHeader]="true" [selectable]="false" [pageOptions]="[5,10,20]" [expansionRow]="rowDetailClosed"
        [expansionModel]="customExpansionModel">
        <ng-template ngbPanelTitle rgiRxDataTableCell="activity" let-ctx>
          <div *ngIf="ctx.row?.actDictionaryBean">
            <span attr.data-qa="act-dictionary-description-label">{{
              ctx.row?.actDictionaryBean?.dictionaryDescription}}</span>

            <div class="claims-activity-popover"
              *ngIf="ctx.row?.actTaskBean[0] && ctx.row?.actTaskBean[0].message">
              <input type="checkbox" id="btnControl" />
              <span class="rgifont rgi-info-circle float-right pull-right" for="btnControl">
                <span class="claims-activity-popover-container">
                  <div class="activity-message">{{ ctx.row?.actTaskBean[0].message | translate }}</div>
                </span>
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template rgiRxDataTableCell="assigneeGroup" let-ctx>
          <div class="col-xl-3 hidden-xs"
            *ngIf="card.isExtend && ctx.row?.actTaskBean && ctx.row?.actTaskBean.length > 0">
            <span attr.data-qa="act-involved-party-label">{{ ctx.row?.actTaskBean[0].assigneeGroup }}</span>
          </div>
        </ng-template>
        <ng-template rgiRxDataTableCell="assigneeUser" let-ctx>
          <div class="col-xl-2 hidden-xs"
            *ngIf="card.isExtend && ctx.row?.actTaskBean && ctx.row?.actTaskBean.length > 0">
            <span attr.data-qa="act-type-of-loss-label">{{ ctx.row?.actTaskBean[0].assigneeUser }}</span>
          </div>
        </ng-template>
        <ng-template rgiRxDataTableCell="expirationDate" let-ctx>
          <div class="col-md-2 col-lg-2 red-color" *ngIf="!dateGreaterThenToday(ctx.row) && ctx.row?.expirationDate">
            <span attr.data-qa="act-expiration-date-label">{{ ctx.row?.expirationDate | date:'dd/MM/yyyy' }}</span>
          </div>
        </ng-template>
        <ng-template rgiRxDataTableCell="updDate" let-ctx>
          <div class="col-md-2 col-lg-2" *ngIf="ctx.row?.updDate">
            <span ttr.data-qa="act-expiration-date-label">{{ ctx.row?.updDate | date:'dd/MM/yyyy' }}</span>
          </div>
        </ng-template>
      </rgi-rx-datatable>

      <ng-template #rowDetailClosed let-ctx>
        <div class="row">
          <span>{{ '_CLAIMS_._NOTE' | translate }}: </span>
          <span>{{ctx.row?.note}}</span>
        </div>
        <div *ngIf="ctx.row?.endNewDocDesc && ctx.row?.endNewDocDesc!=='' " class="row">
          <span>{{ '_CLAIMS_._ESCAPE_METHODS' | translate }}:</span>
          <span> {{ctx.row?.endNewDocDesc}}</span>
        </div>
      </ng-template>
    </div>

    <div *ngIf="!hasClosed">
      <div class="noaction">
        <span class="icon-attention-sign"></span>
        <span data-qa="no-activity-show" class="notasks-message">{{ '_CLAIMS_._MESSAGE_._NO_ACTIVITY_TO_SHOW' |
          translate }}</span>
        <span class="icon-notepad"></span>
      </div>
    </div>
  </div>
</div>

<div class="btn-group btn-group-justified">
  <div class="btn-group">
    <button type="button" (click)="manualDeadline($event)" class="btn btn-warning pull-right text-uppercase"
      data-qa="manual-deadline-btn"><span>{{ '_CLAIMS_._BUTTONS_._NEW_MANUAL_DEADLINE' | translate }}</span></button>
  </div>
</div>