<div class="container-consensuses">
  <div class="titleConsensues" translate>_IIAB_._OTP_._CONSENSUSES_AND_CONTACTS_</div>
  <div class="editConsensues">
    <button type="button" class="rgi-ui-btn btn-edit"
            (click)="edit()" [disabled]="false">
      <span class="rgi-ui-icon-edit"></span>
    </button>
  </div>

  <pnc-dynamic-table [rows]="rowsConsensus" [objectMap]="consensusMap" ></pnc-dynamic-table>

  <div class="additional-info row">
    <div class="col-md-6">
      <p>
        <span class="tbld_col_title">{{ '_ANAG_._LABEL_._EMAIL_' | translate}}</span>:
        <span class="tbld_col_value"> {{ email }} </span>
      </p>
    </div>
    <div class="col-md-6">
      <p>
        <span class="tbld_col_title">{{ '_IIAB_._OTP_._MOBILE_PHONE_' | translate}}:</span>
        <span class="tbld_col_value" > {{ mobilePhone }} </span>
      </p>
    </div>
  </div>
  <div class="document-info row" *ngIf="document && document.documentType">
    <div class="col-md-6">
      <p>
        <span class="tbld_col_title">{{ '_ANAG_._LABEL_._DOCUMENT_TYPE_' | translate }}:</span>
        <span class="tbld_col_value"> {{ document.documentType.descrizione }} </span>
      </p>
    </div>
    <div class="col-md-6">
      <p>
        <span class="tbld_col_title">{{ '_ANAG_._LABEL_._DOCUMENT_NO_' | translate }}:</span>
        <span class="tbld_col_value" > {{ document.documentNumber }} </span>
      </p>
    </div>
    <div class="col-md-6">
      <p>
        <span class="tbld_col_title">{{ '_ANAG_._LABEL_._RELEASE_LOCATION_' | translate }}:</span>
        <span class="tbld_col_value" > {{ document.locationsRelease }} </span>
      </p>
    </div>
    <div class="col-md-6">
      <p>
        <span class="tbld_col_title">{{ '_IIAB_._LABEL_._RELEASE_DATE_' | translate }}:</span>
        <span class="tbld_col_value" > {{ this.formatDateToString(document.releaseDate) }} </span>
      </p>
    </div>
    <div class="col-md-6">
      <p>
        <span class="tbld_col_title">{{ '_IIAB_._LABEL_._EXPIRATION_DATE_' | translate }}:</span>
        <span class="tbld_col_value" > {{ this.formatDateToString(document.expirationDate) }} </span>
      </p>
    </div>
  </div>

  <rgi-rx-snackbar></rgi-rx-snackbar>

</div>

<!--</div>-->
