<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step *ngIf="terminationCauseDefinitions?.visible" label="lpc_operation_termination_reason" [errors]="errors" fieldId="terminationCause" (next)="onNext($event)"
    id="terminationCause">
    <div>
      <div *ngIf="activeStep === 0;else summary">
        <label class="label-core">{{terminationCauseDefinitions?.label}}</label>
        <lpc-required *ngIf="terminationCauseDefinitions?.required" required=" true" ></lpc-required><br>
        <ng-select formControlName="terminationCause">
          <ng-option *ngFor="let option of terminationCauseDefinitions?.listValue" [value]="option.code">{{option.description}}</ng-option>
        </ng-select>
      </div>
      <ng-template #summary>
          <span>{{terminationCauseDefinitions?.label}}</span>
          <span>: {{getDescription(formGroupValue.terminationCause)}}</span>
      </ng-template>
    </div>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event, checkLastStepDates, null,checkLastStepDates )" id="dates">
    <lpc-dates-step
      [inputFields]="dateDefinitions" formControlName="dates"
      (dateChange)="onDateChange($event)" (reloadRequest)="updateData()" [hide]="activeStep < 2">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" *ngIf="withListProductFactors" label="lpc_PolicyData" [errors]="errors" fieldId="factors" (next)="onNext($event)"
    id="factors">
    <lpc-factor-adapter #factorAdapter
      (changeValEmitter)="updateProductFactors($event)"
      [form]="formGroup.controls['factors']"
      [factors]="productFactorsDefinitions">
    </lpc-factor-adapter>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" *ngIf="!!quotationData && quotationData.visible" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event,!showCreditPayment)"
    id="quotation" (confirmAccept)="onNext($event, !showCreditPayment, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-quotation-recess
      [quotationData]="quotationData"
      [hide]="modalMessage"
      label="lpc_life_policy">
    </lpc-quotation-recess>
    <lpc-quotation-recess
      [quotationData]="damageQuotationData"
      [hide]="modalMessage"
      label="lpc_damages_policy">
    </lpc-quotation-recess>
  </lpc-step>

  <lpc-step *ngIf="showCreditPayment" [enableAuth]="isAuth" label="lpc_payment_data" [errors]="errors" [fieldId]="stepPaymentFieldId"
            (next)="onNext($event,true)" id="payment" (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-payment-step [required]="true" [paymentTypeDefinitions]="paymentTypes" formControlName="payment"
                      [summary]="isAfterId('payment')"></lpc-payment-step>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
