<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="!isAfterId('dates')">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="questsFin"
            [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')"
            *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')"
            fieldId="questsFin"
            label="lpc_financial_questionnaire_step"
            (next)="onNext($event)">
    <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                       [questionnairesCode]="getQuestMap('FIN')" [type]="'FIN'"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step id="factors" *ngIf="hasListProductFactor" label="lpc_PolicyData" [errors]="errors" fieldId="factors"
    (next)="onNext($event)">
    <div formGroupName="factors" class="row">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateProductFactors($event)"
        [form]="formGroup.controls['factors']" [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="assetFactors" *ngIf="hasAssetFactor" label="lpc_asset_factors" [errors]="errors" fieldId="assetFactors"
    (next)="onNext($event)">
    <div formGroupName="assetFactors" class="row">
      <lpc-factor-adapter #assetFactorAdapter (changeValEmitter)="updateAssetFactors($event)"
        [form]="formGroup.controls['assetFactors']" [factors]="listAssetFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step *ngIf="showDisinvestment" id="disinvestmentProfiles" label="lpc_disinvestment_profile_step" [jump]="skipDisinvestment" [errors]="errors" fieldId="disinvestmentProfiles" (next)="onNext($event)">
    <div>
      <span translate>lpc_selected_profiles</span>
    </div>
    <ul>
      <li [attr.data-qa]="profile.id + 'info_disinvestment_profile'" *ngFor="let profile of disinvestmentProfiles">{{ profile.description }} :
        {{profile.amount.toString() | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</li>
    </ul>
  </lpc-step>

  <lpc-step *ngIf="showInvestment" id="investmentProfiles" label="lpc_Investment_profiles" [errors]="errors" [jump]="skipInvestmentProfiles"
    [nextVisible]="!formGroup.get('investmentProfiles').invalid" [errors]="errors" fieldId="investmentProfiles" (next)="onNext($event)">

    <lpc-investment-step-adapter *ngIf="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
      [currentStep]="isActiveStep('investmentProfiles')"
      [activeStep]="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
      [customFormGroup]="formGroup"
      [definition]="investmentProfiles"
      [viewOnly]="skipInvestmentProfiles"
      [totalAmount]="totalAmountToInvest"
      (volatilityEvent)="handleVolatilityEvent($event)"
      [config]="getInvestmentProfileConfigurations">
    </lpc-investment-step-adapter>
  </lpc-step>

  <lpc-step *ngIf="showInvestment" id="investmentFunds" label="lpc_investment_funds" [errors]="errors" [jump]="skipInvestmentFunds"
    [nextVisible]="!formGroup.get('investmentFunds').invalid && (!isWithVolatility() || !!volatility)" fieldId="investmentFunds"
    (next)="onNext($event)">
    <lpc-investment-step-adapter *ngIf="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
      [currentStep]="isActiveStep('investmentFunds')"
      [activeStep]="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
      [customFormGroup]="formGroup"
      [definition]="investmentProfiles"
      [viewOnly]="skipInvestmentFunds"
      [totalAmount]="totalAmountToInvest"
      [(volatility)]="volatility"
      (volatilityEvent)="handleVolatilityEvent($event)"
      [config]="getInvestmentFundConfigurations">
    </lpc-investment-step-adapter>
  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" id="quests" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')" fieldId="quests"
            label="lpc_avc_questionnaire" (next)="onNext($event)" [errors]="errors">
    <lpc-questionnaire
                       [active]="!isAfterId('quests')" [key]="composeKey(key, 'quests')"
                       [questionnairesCode]="getQuestMap('ADVER')" [type]="'ADVER'"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #avcQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step id="costs" label="lpc_costs_step_label" fieldId="costs" (next)="onNext($event)" [errors]="errors">
      <span data-qa="management_fees" translate>lpc_management_fees</span>: <span>{{managementFees | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</span>
  </lpc-step>

  <lpc-step
    *ngIf="isAuth || !!errors && !!errors.length"
    id="warnings" label="lpc_warnings"
    (next)="publish()"
    [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
    (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">

    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>

  </lpc-step>


  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    (back)="closeCard()"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
