import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalOtpComponent } from './proposal-otp-component/proposal-otp.component';
import { ProposalOtpService } from './proposal-otp-service/proposal-otp.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import {RgiRxSnackbarModule} from '@rgi/rx/ui';
import {RgiAnagPortalModule} from "@rgi/anag/portal";
import {IIAB_TRANSLATIONS} from "../lib/i18n/translations";

@NgModule({

  declarations: [
    ProposalOtpComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(IIAB_TRANSLATIONS),
    PortalNgCoreModule,
    RgiRxSnackbarModule,
    RgiAnagPortalModule
  ],
  providers: [
    ProposalOtpService
  ],
  exports: [
    ProposalOtpComponent
  ]

})

export class ProposalOtpModule {}
