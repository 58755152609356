import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CovertureBranch,
  DetailNewClaimInfo,
  IGenerateClaim,
  ISimilarClaims,
  CaiOpeningSingle,
  CaiOpeningState,
  OutputDataFormSubjectHolder,
  OutputGetClaimPes,
  OutputResultCovertureBranches,
  OutputSaveDraft,
  OutputSimilarClaims,
  ReopenFromJs,
  SimilarclaimsEntity,
  NodeManagement
} from '../config-model/cai-opening-config.module';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { caiModuleEndPoint } from './cai-opening.endpoint';
import { UserService } from '@rgi/rx/auth';
import { DatePipe } from '@angular/common';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { ClaimDetail } from '../../shared/dsc-shared-model/dsc-model-claims';
import { Guardian, Heir, SubjectReduced } from '../../shared/dsc-shared-model/dsc-model-subject';
import { CategoryDetail, CodiceDescrizione, Unit } from '../../shared/dsc-shared-model/dsc-model-utility';
import { VehicleData } from '../../shared/dsc-shared-model/dsc-model-vehicle';
import { InsurancePolicy, PolicyData } from '../../shared/dsc-shared-model/dsc-model-policy';
import { CarBodywork } from '../../shared/dsc-shared-model/dsc-model-bodywork';
import { AddressEntityData } from '../../shared/dsc-shared-model/dsc-model-address';
import { OutputOccurrenceData } from '../../occurrence-data-modify/state-service/occurrence-config.model';
import { OuputDetailNode } from '../config-model/cai-opening-sales-node.model';
import { Item, QuestionnaireDetail, QuestionnaireDetailJS } from '../config-model/cai-opening-questionnaire.model';
import { GeneratedClaimDetail, NewClaim, OutputSetClaim } from '../config-model/cai-opening-claims.module';
import { IncidentData } from '../../shared/dsc-incident-data/dsc-incident-data.model';
import { CircumstanceIncident } from '../../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import { AdditionalData } from '../../shared/dsc-additional-data/dsc-additional-data.model';
import { AlertService, FunctionService, PolicyService } from '@rgi/digital-claims-common-angular';
import { CaiOpeningClaimUtility } from './utility/cai-opening-claim.utility';


@Injectable()
export class CaiOpeningService {
  private baseApiUrl: string;
  private baseApiUrlV2: string;
  isReopenCaiModule: BehaviorSubject<boolean> = new BehaviorSubject(false);
  context: string;

  constructor(
    private httpClient: HttpClient,
    protected userService: UserService,
    private alertService: AlertService,
    @Inject('claimsService') private claimsService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('eventService') private eventService: any,
    @Inject('authService') private authService,
    @Inject('environment') environment: any,
    private claimReducer: CaiOpeningClaimUtility,
    public datePipe: DatePipe,
    private rxTranslationsService: RgiRxTranslationService,
    private policyService: PolicyService,
    private functionService: FunctionService,

  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getDetailStepper$(
    st: Observable<CaiOpeningState>,
    sessionClaim: any,
    activeRouteKey: string
  ): Observable<CaiOpeningState> {
    return st.pipe(
      mergeMap((state) => {
        sessionClaim.detailNewClaimInfo.filterApplied.extension = { properties: [{ chiave: 'GET_INFO_SX', valore: '1' }] };
        const serviceCall = this.httpClient.post<any>(
          this.baseApiUrl + caiModuleEndPoint.urlListPolicies, { filterPolicies: sessionClaim.detailNewClaimInfo.filterApplied }
        );
        return combineLatest([serviceCall, of(state)]);
      }),
      mergeMap(([responseAllPolicies, state]) => {
        sessionClaim.detailNewClaimInfo.policiesList = responseAllPolicies.policies;
        sessionClaim.detailNewClaimInfo.policiesList.forEach(
          (singlePolicy: InsurancePolicy) => {
            singlePolicy.units = [];
            if (singlePolicy.extension !== null) {
              const unitContract = singlePolicy.extension.properties[0].valore.split('#');
              unitContract.forEach(res => {
                const singleUnit: Unit = {
                  code: res,
                  description: res,
                };
                singlePolicy.units.push(singleUnit);
              });
            }
            const claimsBranch = singlePolicy.extension.properties[1].valore.split('#');
            singlePolicy.claimBranchCodes = claimsBranch;
            singlePolicy.isCancelled = singlePolicy.state.codice === '13';
            this.unitsProductBin(
              singlePolicy.productCode,
              singlePolicy.category.codice
            ).subscribe((res: any) => {
              //singlePolicy.units = res.units;
              singlePolicy.claimBranchCodes = res.branches;
            });
          }
        );
        state.detailNewClaimInfo = sessionClaim.detailNewClaimInfo;
        state.similarClaims = sessionClaim.similarClaims;
        state.selectedNode = this.authService.getSalePointCode();
        state.listFunz = this.authService.getOperator().enabledFeatures;
        state.singleCai = new CaiOpeningSingle();
        state.occurrenceData = new OutputOccurrenceData(activeRouteKey);
        state.occurrenceData.addressEntity.country = 'IT';
        state.occurrenceData.addressEntity.editability = true;
        state.occurrenceData.circumstanceIncident.vehiclesNumber = 1;
        state.occurrenceData.circumstanceIncident.signatureType = 1;
        state.reopenFromJs = sessionClaim.reopenFromJs;
        state.occurrenceData.incidentData.occurrenceDate =
          sessionClaim.detailNewClaimInfo.occurrenceDate;
        state.occurrenceData.incidentData.actualOccurrenceDate =
          sessionClaim.detailNewClaimInfo.actualOccurrenceDate;
        state.occurrenceData.incidentData.actualOccurrenceTime =
          sessionClaim.detailNewClaimInfo.occurrenceHour;
        state.occurrenceData.incidentData.occurrenceTime =
          sessionClaim.detailNewClaimInfo.occurrenceHour;
        state.occurrenceData.generalData.claimType = 'RCA';
        state.occurrenceData.additionalData.externalClaimNumberValue = '';
        state.occurrenceData.additionalData.typeOfAuthorityInvolvedVisible = true;
        state.occurrenceData.additionalData.typeOfAuthorityInvolvedEnabled = true;
        state.occurrenceData.additionalData.authorityDescriptionVisible = true;
        state.occurrenceData.additionalData.authorityDescriptionEnabled = true;
        state.occurrenceData.additionalData.catastrophicEventEnabled = true;
        state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedVisible = true;
        state.occurrenceData.additionalData.notIdentifiedWitnessVisible = true;
        state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedEnabled = true;
        state.occurrenceData.additionalData.notIdentifiedWitnessEnabled = true;
        state.occurrenceData.additionalData.lastAuditDateEnabled = true;
        state.occurrenceData.additionalData.lastAuditDateVisible = true;
        state.occurrenceData.additionalData.toBeSettledEnabled = true;
        state.occurrenceData.additionalData.toBeSettledVisible = true;
        state.occurrenceData.additionalData.lastAuditDateValue = null;
        state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedValue = null;
        state.occurrenceData.additionalData.notIdentifiedWitnessValue = null;
        state.occurrenceData.additionalData.toBeSettledValue = null;
        state.occurrenceData.additionalData.typeOfAuthorityInvolvedValue = null;
        state.occurrenceData.additionalData.authorityDescriptionValue = null;
        state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedValue = null;
        state.occurrenceData.additionalData.notIdentifiedWitnessValue = null;
        return of(state);
      }),
      mergeMap((state) => {
        const nodeCode = state.detailNewClaimInfo.contract !== null ? state.detailNewClaimInfo.contract.nodeCode
        : state.selectedNode;
        const detailNodeCode = this.httpClient.get<OuputDetailNode>(
          this.baseApiUrl + caiModuleEndPoint.urlGetDetailNodeCompany
          + '/' + nodeCode
        );
        return combineLatest([detailNodeCode, of(state)]);
      }),
      mergeMap(([responseSails, state]) => {
        state.singleCai.insuranceCompany.agencyDescription = responseSails.salePoint.description;
        state.singleCai.insuranceCompany.address = responseSails.salePoint.address;
        state.singleCai.insuranceCompany.email = responseSails.salePoint.email;
        state.singleCai.insuranceCompany.phone = responseSails.salePoint.phone;
        const serviceCall = this.httpClient.get<CategoryDetail[]>(
          this.baseApiUrl + caiModuleEndPoint.urlGetAllCategories
        );
        return combineLatest([serviceCall, of(state)]);
      }),
      mergeMap(([responseAllCategories, state]) => {
        state.allCategories = responseAllCategories;
        return combineLatest([this.getNodesSinistri$('SXFIT2'), of(state)]);
      }),
      map(([responseNodesSinistri, state]) => {
        state.nodoFittSx = new NodeManagement(responseNodesSinistri[0].code, responseNodesSinistri[0].description, responseNodesSinistri[0].id);
        return state;
      }),
    );
  }

  checkIsNullString(value: any) {
    if (value === null || value === undefined) {
      return '';
    } else {
      return value.toString();
    }
  }

  setClaimFromReopenJs$(
    st: Observable<CaiOpeningState>,
    objReopen: ReopenFromJs,
    activeRouteKey: string
  ): Observable<CaiOpeningState> {
    return st.pipe(map(state => {
      //state.fillState(objReopen.returnClaim.caiOpeningState);
      state.generatedClaim.claim = objReopen.returnClaim;
      state.singleCai = new CaiOpeningSingle();
      state.reopenFromJs = objReopen;
      state.selectedNode = this.authService.getSalePointCode();
      state.listFunz = this.authService.getOperator().enabledFeatures;
      state.detailNewClaimInfo = new DetailNewClaimInfo();
      state.occurrenceData = new OutputOccurrenceData(activeRouteKey);
      if (objReopen.returnClaim.claimAddress) {
        state.occurrenceData.addressEntity.country = this.checkIsNullString(objReopen.returnClaim.claimAddress.contryCode);
        state.occurrenceData.addressEntity.address = this.checkIsNullString(objReopen.returnClaim.claimAddress.placeAddress);
        state.occurrenceData.addressEntity.postCode = this.checkIsNullString(objReopen.returnClaim.claimAddress.postCode);
        state.occurrenceData.addressEntity.houseNumber = this.checkIsNullString(objReopen.returnClaim.claimAddress.number);
        state.occurrenceData.addressEntity.city = this.checkIsNullString(objReopen.returnClaim.claimAddress.city);
        state.occurrenceData.addressEntity.province = this.checkIsNullString(objReopen.returnClaim.claimAddress.province);
        if (objReopen.returnClaim.claimAddress.umaAddress) {
          state.occurrenceData.addressEntity.adminLevel1 =
            this.checkIsNullString(objReopen.returnClaim.claimAddress.umaAddress.adminLevel1);
          state.occurrenceData.addressEntity.adminLevel2 =
            this.checkIsNullString(objReopen.returnClaim.claimAddress.umaAddress.adminLevel2);
          state.occurrenceData.addressEntity.adminLevel3 =
            this.checkIsNullString(objReopen.returnClaim.claimAddress.umaAddress.adminLevel3);
        }
      }
      state.occurrenceData.addressEntity.toponym = objReopen.returnClaim.claimAddress.toponym;
      state.occurrenceData.addressEntity.umaAddress = objReopen.returnClaim.claimAddress.umaAddress ?
        objReopen.returnClaim.claimAddress.umaAddress : null;
      state.occurrenceData.incidentData.occurrenceDate = objReopen.returnClaim.occurrenceDate;
      state.occurrenceData.incidentData.occurrenceTime = objReopen.returnClaim.occurrenceHour;
      state.occurrenceData.incidentData.actualOccurrenceDate = objReopen.returnClaim.occurrenceDate;
      state.occurrenceData.incidentData.actualOccurrenceTime = objReopen.returnClaim.occurrenceHour;
      state.occurrenceData.incidentData.intermediaryReceiptDate = objReopen.returnClaim.dateReceivedIntermediary;
      // data non più necessaria in creazione sinistro
      // state.occurrenceData.incidentData.chcReceiptDate = objReopen.returnClaim.dateReceivedCHL;
      state.occurrenceData.incidentData.dateReported = objReopen.returnClaim.reportDate;
      state.occurrenceData.incidentData.insuredReportDate = objReopen.returnClaim.dateReceivedInsured;
      state.occurrenceData.incidentData.additionalNotes = objReopen.returnClaim.incidentAdminNote;
      state.occurrenceData.incidentData.incidentDescription = objReopen.returnClaim.occurenceDescription;
      state.occurrenceData.incidentData.dateReportedEditable = objReopen.returnClaim.dateReportedEditable;
      state.occurrenceData.incidentData.certifiedOccurrenceDate = objReopen.returnClaim.certifiedOccurrenceDate;
      state.occurrenceData.incidentData.intermediaryReceiptDateEditable = objReopen.returnClaim.intermediaryReceiptDateEditable;
      state.occurrenceData.incidentData.incidentDescriptionEditable = objReopen.returnClaim.incidentDescriptionEditable;
      state.occurrenceData.incidentData.additionalNotesEditable = objReopen.returnClaim.additionalNotesEditable;
      state.occurrenceData.addressEntity.editability = true;
      state.occurrenceData.circumstanceIncident.vehiclesNumber = 1;
      state.occurrenceData.circumstanceIncident.eventDenial = objReopen.returnClaim.eventDenial;
      state.occurrenceData.circumstanceIncident.signatureType = +objReopen.returnClaim.signType.codice;
      state.occurrenceData.circumstanceIncident.percInsuredLiability = +objReopen.returnClaim.percInsuredLiability;
      //ADDITIONAL DATA
      state.occurrenceData.additionalData.externalClaimNumberValue = objReopen.returnClaim.externalClaimNumber;
      state.occurrenceData.additionalData.typeOfAuthorityInvolvedVisible = true;
      state.occurrenceData.additionalData.typeOfAuthorityInvolvedEnabled = true;
      state.occurrenceData.additionalData.authorityDescriptionVisible = true;
      state.occurrenceData.additionalData.authorityDescriptionEnabled = true;
      state.occurrenceData.additionalData.catastrophicEventEnabled = true;
      state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedVisible = true;
      state.occurrenceData.additionalData.notIdentifiedWitnessVisible = true;
      state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedEnabled = true;
      state.occurrenceData.additionalData.notIdentifiedWitnessEnabled = true;
      state.occurrenceData.additionalData.lastAuditDateEnabled = true;
      state.occurrenceData.additionalData.lastAuditDateVisible = true;
      state.occurrenceData.additionalData.toBeSettledEnabled = true;
      state.occurrenceData.additionalData.toBeSettledVisible = true;
      state.occurrenceData.additionalData.lastAuditDateValue = objReopen.returnClaim.lastAuditDateValue;
      state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedValue = objReopen.returnClaim.openedClaimDuetoSummonsIssuedValue;
      state.occurrenceData.additionalData.notIdentifiedWitnessValue = objReopen.returnClaim.notIdentifiedWitnessValue;
      state.occurrenceData.additionalData.toBeSettledValue = objReopen.returnClaim.toBeSettledValue;
      state.occurrenceData.additionalData.typeOfAuthorityInvolvedValue = objReopen.returnClaim.typeOfAuthorityInvolvedValue;
      state.occurrenceData.additionalData.authorityDescriptionValue = objReopen.returnClaim.authorityDescriptionValue;
      state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedValue = objReopen.returnClaim.openedClaimDuetoSummonsIssuedValue;
      state.occurrenceData.additionalData.notIdentifiedWitnessValue = objReopen.returnClaim.notIdentifiedWitnessValue;
      state.detailNewClaimInfo.policiesList = [];
      state.detailNewClaimInfo.occurrenceDate = new Date(state.generatedClaim.claim.occurrenceDate);
      state.detailNewClaimInfo.occurrenceHour = new Date(state.generatedClaim.claim.occurrenceHour);
      state.singleCai.insuranceCompany = objReopen.returnClaim.companyDetailObject;
      state.selectedPolicy = objReopen.returnClaim.selectedPolicy;
      state.detailNewClaimInfo.selectedEventCategory = objReopen.returnClaim.selectedEventCategory;
      this.isReopenCaiModule.next(true);
      return state;
    }));
  }

  clearState$(
    stateHomeMotorFlow$: Observable<CaiOpeningState>
  ): Observable<CaiOpeningState> {
    return stateHomeMotorFlow$.pipe(
      map((st) => {
        st = new CaiOpeningState(st.$$id);
        return st;
      })
    );
  }

  unitsProductBin(codProduct: string, codCategory: string) {
    return this.httpClient.get(
      this.baseApiUrl +
      caiModuleEndPoint.urlUnitsProductBin +
      '?' +
      'codProduct=' +
      codProduct +
      '&' +
      'categoryCode=' +
      codCategory
    );
  }

  similarClaims(criteria: SimilarclaimsEntity) {
    return this.httpClient.post(
      this.baseApiUrl + caiModuleEndPoint.urlSimilarpotential,
      criteria
    );
  }

  getAllSimilarClaims(
    st: Observable<CaiOpeningState>,
    selPolicy: InsurancePolicy
  ): Observable<CaiOpeningState> {
    const IBody: ISimilarClaims = {
      branchesCodes: selPolicy.claimBranchCodes,
      occurrenceDate: new Date().toISOString(),
      policyNumber: selPolicy.policyNumber,
      typePtf: '',
    };
    return st.pipe(
      mergeMap((state) => {
        state.selectedPolicy = selPolicy;
        state.contract = selPolicy;
        const serviceCall = this.httpClient.post<OutputSimilarClaims>(
          this.baseApiUrl + caiModuleEndPoint.urlSimilarpotential,
          IBody
        );
        return combineLatest([serviceCall, of(state)]);
      }),
      map(([responseSimilarClaims, state]) => {
        state.similarClaims = responseSimilarClaims;
        if (responseSimilarClaims.listPotentialClaims.length === 0) {
          state.stepper.activeItem = 1;
          state.stepper.selectedIndex = 1;
        }
        return state;
      }),
      mergeMap((state)=> {
        const compCode = this.authService.getOperator().salePoint.company
          ? this.authService.getOperator().salePoint.company.code
          : null;
        const IBody = {
          filter: {
            companyCode: compCode,
            incidentDate: new Date().toISOString(),
            policyNumber: state.selectedPolicy.policyNumber,
          },
        };
        const branchesCodes =
          this.httpClient.post<OutputResultCovertureBranches>(
            this.baseApiUrl + caiModuleEndPoint.urlGetCovertureBranches,
            IBody
          );
        return combineLatest([branchesCodes, of(state)]);
      }),
      map(([responseBranchesCode, state]) => {
        if (responseBranchesCode.covertureBranches.length > 0 ) {
          state.listCovertureBranches =
          responseBranchesCode.covertureBranches.find(
            (coverture: CovertureBranch) =>
              coverture.claimBranchCode ===
              state.detailNewClaimInfo.selectedEventCategory.branchesCodes[0]
          );
        state.claimBranchDescription =
          state.listCovertureBranches.claimBranchDescription;
        state.selectedCovertureBranch =
          state.listCovertureBranches.covertureTypes[0];
        }
        return state;
      })
    );
  }

  resetSimilarClaims$(state: Observable<CaiOpeningState>) {
    return state.pipe(
      map((st) => {
        st.similarClaims = null;
        return st;
      })
    );
  }

  clearValues$(state: Observable<CaiOpeningState>) {
    return state.pipe(
      map((st) => {
        st.reopenFromJs = new ReopenFromJs();
        st.questionnaire = new QuestionnaireDetail();
        st.questionnaire.isValid = false;
        st.defaultQuestionnaire = new QuestionnaireDetailJS();
        st.stepper.selectedIndex--;
        return st;
      })
    );
  }

  generateClaim$(
    st: Observable<CaiOpeningState>,
    selPolicy: InsurancePolicy
  ): Observable<CaiOpeningState> {
    return st.pipe(
      mergeMap((state) => {
        if(!selPolicy){
          selPolicy = state.selectedPolicy; 
        } 
       const serviceCall = this.policyService.getPolicyContext(selPolicy.policyNumber);
        return combineLatest([serviceCall, of(state)]);
      }),
      mergeMap(([resp, state])=> {
        this.context = (<any>resp).code;
        const IBody: IGenerateClaim = {
          claim: {
            policyNumber: selPolicy.policyNumber,
            coverTypeCode: state.selectedCovertureBranch.covertureTypeCode,
            claimBranch: state.claimBranchDescription,
            claimBranchCodes:
              state.detailNewClaimInfo?.selectedEventCategory.branchesCodes[0],
            draft: true,
            flowCreation: false,
            debtorFoundOnPolicy: false,
            intercompany: false,
            reopened: false,
            mediation: false,
            openingActFollowedQuote: false,
            openingFollowedRequestDamage: false,
            unknownVehicleColl: false,
            witnessesFor: false,
          },
        };
        const serviceCall = this.httpClient.post<any>(
          this.baseApiUrl + caiModuleEndPoint.urlGenerateClaim,
          IBody
        );
        return combineLatest([serviceCall, of(state)]);
      }),
      mergeMap(([generateClaim, state]) => {
        generateClaim.claim.context = this.context;
        state.generatedClaim = generateClaim;
        const IQuestBody = {
          getQuestionnaireInput: {
            chcCode: '001',
            claim: state.generatedClaim.claim,
          },
        };
        const serviceCallQuestionnaire = this.httpClient.post<any>(
          this.baseApiUrl + caiModuleEndPoint.urlGetQuestionnaire,
          IQuestBody
        );
        return combineLatest([serviceCallQuestionnaire, of(state)]);
      }),
      mergeMap(([generatedQuestionnaire, state]) => {
        if(state.reopenFromJs.stepToShow != 'questionaire') { 
          state.questionnaire = this.setQuestionnaireResponse(generatedQuestionnaire.questionnaire);
        }
        state.defaultQuestionnaire = generatedQuestionnaire.questionnaire;
        const IContractBody = {
          filter: {
            policyId: state.selectedPolicy.objectId,
            versionDate: new Date().toISOString(),
          },
        };
        const serviceGetContract = this.httpClient.post<any>(
          this.baseApiUrl + caiModuleEndPoint.urlGetContract,
          IContractBody
        );
        return combineLatest([serviceGetContract, of(state)]);
      }),
      map(([responseContract, state]) => {
        state.contract = responseContract.contract;
        state.singleCai.insuranceCompany.policyNumber = state.contract.policyNumber;
        state.singleCai.insuranceCompany.company = state.contract.company;
        state.generatedClaim.claim.contractDetail = responseContract.contract;
        state.generatedClaim.claim.contract = responseContract.contract;
        state.stepper.activeItem = 1;
        state.stepper.selectedIndex = 1;
        return state;
      })
    );
  }

  updateContractState$(
    st: Observable<CaiOpeningState>,
    newClaim: ClaimDetail
  ): Observable<CaiOpeningState> {
    return st.pipe(
      map((state) => {
        state.generatedClaim.claim = newClaim;
        return state;
      })
    );
  }

  setQuestionnaire$(state: CaiOpeningState): Observable<OutputSetClaim> {
    const initQuestionnaire = { ...state.defaultQuestionnaire };
    initQuestionnaire.questions.forEach((quest) => {
      quest.answers[0].value = state.questionnaire.items.find(
        (find: Item) => find.question.questionCode === quest.code
      ).answers[0].valueResponse;
    });
    const ISetBody = {
      setQuestionnaireInput: {
        chcCode: '001',
        claim: {
          ministerialBranchCodes: state.listCovertureBranches.ministerialBranchCode,
          policyNumber: state.generatedClaim.claim.policyNumber
        },
        questionnaire: initQuestionnaire,
      },
    };
    return this.httpClient.post<any>(
      this.baseApiUrl + caiModuleEndPoint.urlSetQuestionnaire,
      ISetBody
    );
  }

  setQuestionnaireResponse(questionnaire: any): QuestionnaireDetail {
    const newQuestionnaire = new QuestionnaireDetail();
    newQuestionnaire.questionnaireCategory = questionnaire.category;
    newQuestionnaire.questionnaireCode = questionnaire.code;
    newQuestionnaire.questionnaireCodeType = questionnaire.codeType;
    newQuestionnaire.questions = questionnaire.questions;
    const customQuestions = [];
    const customAnswers = [];
    const customItems = [];
    questionnaire.questions.forEach((res) => {
      const singleQuestionnaire = {
        questionCode: res.code,
        questionValue: res.text,
        questionState: res.status,
        questionDescription: res.description,
        questionRca: true,
      };
      customQuestions.push(singleQuestionnaire);
      const singleAnswer = {
        questionCode: res.code,
        questionnaireCode: '',
        answerCode: res.answers[0].factorCode,
        valueResponse: res.answers[0].value,
        typeResponse: res.answers[0].paramType === '2' ? 'BOOLEAN' : 'COMBO',
        valuesResponse: res.answers[0].values.map(x => {
          return { id: x.id, description: x.description}
        })
      };
      customAnswers.push(singleAnswer);
    });

    questionnaire.questions.forEach((res) => {
      const singleItem = {
        question: {
          questionCode: res.code,
          questionValue: res.text,
          questionState: res.status,
          questionDescription: res.description,
          questionRca: true,
        },
        answers: [
          {
            questionCode: res.code,
            questionnaireCode: res.codeVersion,
            answerCode: res.answers[0].factorCode,
            valueResponse: res.answers[0].value,
            typeResponse: res.answers[0].paramType === '2' ? 'BOOLEAN' : 'LIST',
            valuesResponse: res.answers[0].values.map(x => {
              return { id: x.id, description: x.description}
            })
          },
        ],
      };
      customItems.push(singleItem);
    });
    newQuestionnaire.questions = customQuestions;
    newQuestionnaire.answers = customAnswers;
    newQuestionnaire.items = customItems;
    return newQuestionnaire;
  }

  updatePolicyHolder$(
    policyHolder: OutputDataFormSubjectHolder,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        const holderIndex = st.singleCai.heirsList.driver.potentialHeirs
          .findIndex(h => h.linkedPartyObjectId === st.singleCai.roles.holder.objectId);
        if (policyHolder.subject) {
          const setChkMaterial = policyHolder.subject.chkMaterial;
          const setMaterialDescription = policyHolder.subject.materialDescription;
          policyHolder.subject.chkMaterial = setChkMaterial;
          policyHolder.subject.materialDescription = setMaterialDescription;
          if (policyHolder.subject.isOwner) {
            policyHolder.subject.isOwner = true;
          } else {
            policyHolder.subject.isOwner = false;
          }
          if (policyHolder.subject.isDriver) {
            policyHolder.subject.isDriver = true;
          } else {
            policyHolder.subject.isDriver = false;
          }
          if (policyHolder.subject.isPassenger) {
            policyHolder.subject.isPassenger = true;
          } else {
            policyHolder.subject.isPassenger = false;
          }
          policyHolder.subject
        }
        if (policyHolder.dataForm) {
          policyHolder.subject.subjectDataFormInjury = policyHolder.dataForm;
        }
        st.singleCai.roles.holder.objectId = policyHolder.subject.objectId;
        st.singleCai.policyHolder = policyHolder.subject;
        if (st.singleCai.heirsList.policyHolder.objectId === st.singleCai.heirsList.driver.objectId) {
          if (holderIndex > -1) {
            st.singleCai.heirsList.driver.potentialHeirs.splice(holderIndex, 1);
          }
        } else {
          if (!st.singleCai.policyHolder.subjectDataFormInjury.fatalDate) {
            if (holderIndex < 0) {
              st.singleCai.heirsList.driver.potentialHeirs.push(this.inizializeSubAsHeir(st.singleCai.policyHolder));
            }
          } else {
            if (holderIndex > -1) {
              st.singleCai.heirsList.driver.potentialHeirs.splice(holderIndex, 1);
              // this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID_FATAL_DATE').subscribe(
              //   res => {
              //       this.alertService.translatedMessage(res);
              //   })
            }
          }
        }
        if (st.singleCai.policyHolder.isDriver) {
          st.singleCai.roles.driver.objectId = policyHolder.subject.objectId;
          st.singleCai.driver = policyHolder.subject;
        } else {
          if (st.singleCai.roles.driver.objectId === st.singleCai.roles.holder.objectId) {
            st.singleCai.roles.driver.objectId = '';
            // if (st.singleCai.owner.objectId === st.singleCai.driver.objectId) {
            st.singleCai.driver = new SubjectReduced();
            // }
          }
        }
        if (st.singleCai.policyHolder.isOwner) {
          st.singleCai.roles.owner.objectId = policyHolder.subject.objectId;
          st.singleCai.owner = policyHolder.subject;
        } else {
          if (st.singleCai.policyHolder.objectId === st.singleCai.owner.objectId) {
            st.singleCai.roles.owner.objectId = '';
            st.singleCai.owner = new SubjectReduced();
          }
        }
        if (!st.singleCai.policyHolder.chkInjury) {
          st.singleCai.heirsList.policyHolder.potentialHeirs = [];
          if (st.singleCai.driver.objectId != '' && !st.singleCai.driver.subjectDataFormInjury.fatalDate && holderIndex < 0) {
            st.singleCai.heirsList.policyHolder.potentialHeirs
            .push(this.inizializeSubAsHeir(st.singleCai.driver));
          }
        }
        if (!st.singleCai.policyHolder.isIncapacitated) {
          st.singleCai.policyHolder.listMinorGuardianSubject = [];
        }
        return st;
      })
    );
  }
  updatePartyRole$(
    partyRole: CodiceDescrizione,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.singleCai.roles.partyRole = partyRole;
        return st;
      })
    );
  }
  updateVehicle$(vehicle: VehicleData, state: Observable<CaiOpeningState>) {
    return state.pipe(
      map((st) => {
        st.singleCai.vehicle = vehicle;
        return st;
      })
    );
  }
  updatePolicyData$(
    policyData: PolicyData,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.singleCai.insuranceCompany = policyData;
        return st;
      })
    );
  }
  updateOwner$(owner: OutputDataFormSubjectHolder, state: Observable<CaiOpeningState>) {
    return state.pipe(
      map((st) => {
        if (owner.subject) {
          const setChkMaterial = owner.subject.chkMaterial;
          const setMaterialDescription = owner.subject.materialDescription;
          owner.subject.chkMaterial = setChkMaterial;
          owner.subject.materialDescription = setMaterialDescription;
          owner.subject.isOwner = true;
          if (owner.subject.isDriver) {
            owner.subject.isDriver = true;
          } else {
            owner.subject.isDriver = false;
          }
          if (owner.subject.isPassenger) {
            owner.subject.isPassenger = true;
          } else {
            owner.subject.isPassenger = false;
          }
        }
        if (owner.dataForm) {
          owner.subject.subjectDataFormInjury = owner.dataForm;
        }
        st.singleCai.owner = owner.subject;
        st.singleCai.roles.owner.objectId = owner.subject.objectId;
        if (st.singleCai.roles.owner.objectId === st.singleCai.roles.holder.objectId) {
          st.singleCai.policyHolder.isOwner = true;
        } else {
          st.singleCai.policyHolder.isOwner = false;
        }
        if (st.singleCai.owner.isDriver) {
          st.singleCai.roles.driver.objectId = owner.subject.objectId;
          st.singleCai.driver = owner.subject;
          st.singleCai.policyHolder.isDriver = false;
        }
        else {
          if (st.singleCai.roles.driver.objectId === st.singleCai.roles.owner.objectId) {
            st.singleCai.roles.driver.objectId = '';
            st.singleCai.driver = new SubjectReduced();
          }
        }
        return st;
      })
    );
  }
  updateDriver$(
    driver: OutputDataFormSubjectHolder,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        const driverIndex = st.singleCai.heirsList.policyHolder.potentialHeirs
          .findIndex(h => h.linkedPartyObjectId === st.singleCai.roles.driver.objectId);
        if (driver.subject) {
          const setChkMaterial = driver.subject.chkMaterial;
          const setMaterialDescription = driver.subject.materialDescription;
          driver.subject.chkMaterial = setChkMaterial;
          driver.subject.materialDescription = setMaterialDescription;
        }
        if (driver.dataForm) {
          driver.subject.subjectDataFormInjury = driver.dataForm;
        }
        st.singleCai.driver = driver.subject;
        st.singleCai.roles.driver.objectId = driver.subject.objectId;
        st.singleCai.heirsList.driver.objectId = driver.subject.objectId;
        driver.subject.isDriver = true;
        if (st.singleCai.heirsList.policyHolder.objectId === st.singleCai.heirsList.driver.objectId) {
          if (driverIndex > -1) {
            st.singleCai.heirsList.policyHolder.potentialHeirs.splice(driverIndex, 1);
          }
        } else {
          if (st.singleCai.heirsList.driver.objectId !== '' && !st.singleCai.driver.subjectDataFormInjury.fatalDate) {
            const isDriverAlreadyHeir = st.singleCai.heirsList.policyHolder.potentialHeirs
            .some(h => h.linkedPartyObjectId === st.singleCai.roles.driver.objectId);
            if (driverIndex < 0 && !isDriverAlreadyHeir) {
              st.singleCai.heirsList.policyHolder.potentialHeirs.push(this.inizializeSubAsHeir(st.singleCai.driver));
            }
          }
          else {
            if (driverIndex > -1) {
              st.singleCai.heirsList.policyHolder.potentialHeirs.splice(driverIndex, 1);
              // this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID_FATAL_DATE').subscribe(
              //   res => {
              //       this.alertService.translatedMessage(res);
              //   })
            }
          }
        }
        if (st.singleCai.roles.driver.objectId === st.singleCai.roles.holder.objectId) {
          st.singleCai.policyHolder.isDriver = true;
        } else {
          if (st.singleCai.roles.driver.objectId !== '') {
            st.singleCai.policyHolder.isDriver = false;
          }
        }
        if (st.singleCai.roles.driver.objectId === st.singleCai.roles.owner.objectId) {
          st.singleCai.owner.isDriver = true;
        } else {
          st.singleCai.owner.isDriver = false;
        }

        if (!st.singleCai.driver.chkInjury) {
          st.singleCai.heirsList.driver.potentialHeirs = [];

          if (!st.singleCai.policyHolder.subjectDataFormInjury.fatalDate && driverIndex < 0) {
            st.singleCai.heirsList.driver.potentialHeirs
            .push(this.inizializeSubAsHeir(st.singleCai.policyHolder));
          }
        }
        return st;

      })
    );
  }

  inizializeSubAsHeir(subject: SubjectReduced): Heir {
    return {
      notes: null,
      linkSubjectNominative: subject.nominative,
      linkType: null,
      linkedPartyUniqueKey: '',
      linkedPartyObjectId: subject.objectId,
      idSubjectLinkType: '',
      checked: false
    }
  }

  updateDamagedParts$(
    damagedVehicle: VehicleData,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.singleCai.damagedParts = damagedVehicle;
        return st;
      })
    );
  }
  updateCarBodywork$(
    carBodywork: CarBodywork,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.singleCai.carBodywork = carBodywork;
        return st;
      })
    );
  }
  updateQuestionnaireState$(
    IQuestionnaire: QuestionnaireDetail,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        const validationQuestionnaire = IQuestionnaire.items.find(res => res.answers[0].questionCode === 'SX0034' || res.answers[0].questionCode === 'SX0000');
        IQuestionnaire.isValid = validationQuestionnaire ? validationQuestionnaire.answers[0].valueResponse !== null ? true : false : true;
        st.questionnaire = IQuestionnaire;
        this.eventService.broadcastEvent('stop-loader');
        return st;
      })
    );
  }
  updateIncidentData$(
    IincidentData: IncidentData,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.occurrenceData.incidentData = IincidentData;
        return st;
      })
    );
  }
  updateAddress$(
    IAddress: AddressEntityData,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.occurrenceData.addressEntity = IAddress;
        return st;
      })
    );
  }
  updateCircumstance$(
    IAddress: CircumstanceIncident,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.occurrenceData.circumstanceIncident = IAddress;
        return st;
      })
    );
  }
  updateAdditionalData$(
    IAddress: AdditionalData,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.occurrenceData.additionalData = IAddress;
        return st;
      })
    );
  }

  updatePolicyHolderHeirs$(
    heir: Heir,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        const index = st.singleCai.heirsList.policyHolder.potentialHeirs.findIndex(h => h.linkedPartyObjectId === heir.linkedPartyObjectId);
        st.singleCai.heirsList.policyHolder.potentialHeirs[index].checked = !st.singleCai.heirsList.policyHolder.potentialHeirs[index].checked;
        if (st.singleCai.heirsList.policyHolder.objectId === st.singleCai.heirsList.owner.objectId) {
          st.singleCai.heirsList.owner.potentialHeirs = st.singleCai.heirsList.policyHolder.potentialHeirs;
        }
        if (st.singleCai.heirsList.policyHolder.objectId === st.singleCai.heirsList.driver.objectId) {
          st.singleCai.heirsList.driver.potentialHeirs = st.singleCai.heirsList.policyHolder.potentialHeirs;
        }
        return st;
      })
    );
  }

  updateOwnerHeirs$(
    heir: Heir,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {

        const index = st.singleCai.heirsList.owner.potentialHeirs.findIndex(h => h.linkedPartyObjectId === heir.linkedPartyObjectId);
        st.singleCai.heirsList.owner.potentialHeirs[index].checked = !st.singleCai.heirsList.owner.potentialHeirs[index].checked;
        if (st.singleCai.heirsList.owner.objectId === st.singleCai.heirsList.driver.objectId) {
          st.singleCai.heirsList.driver.potentialHeirs = st.singleCai.heirsList.owner.potentialHeirs;
        }
        return st;
      })
    );
  }

  updateDriverHeirs$(
    heir: Heir,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {

        const index = st.singleCai.heirsList.driver.potentialHeirs.findIndex(h => h.linkedPartyObjectId === heir.linkedPartyObjectId);
        st.singleCai.heirsList.driver.potentialHeirs[index].checked = !st.singleCai.heirsList.driver.potentialHeirs[index].checked;
        return st;
      })
    );
  }

  updateAddressDataDamagedParts$(
    address: AddressEntityData,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.singleCai.propertyAddress = address;
        return st;
      })
    );
  }
  updateClaimForCAI$(
    motorFlowSingle: CaiOpeningSingle,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        console.log(motorFlowSingle, 'motor flow in service dopo reducer');
        st.singleCai = { ...motorFlowSingle };
        st.singleCai.driver = { ...motorFlowSingle.driver };
        st.occurrenceData.circumstanceIncident.signatureType = st.generatedClaim.claim.signType.codice;
        st.occurrenceData.circumstanceIncident.percInsuredLiability = st.generatedClaim.claim.percInsuredLiability;
        st.occurrenceData.circumstanceIncident.eventDenial = st.generatedClaim.claim.eventDenial;
        st.stepper.activeItem = 2;
        st.stepper.selectedIndex = 2;
        return st;
      })
    );
  }
  updateClaimDetail$(
    newClaim: ClaimDetail,
    state: Observable<CaiOpeningState>
  ) {
    return state.pipe(
      map((st) => {
        st.generatedClaim.claim = newClaim;
        return st;
      })
    );
  }

  goToSummary$(currState: CaiOpeningState) {
    //currState.claimToService.claim.caiOpeningState = currState;
    const detailResult: any = currState.claimToService.claim;
    detailResult.documents = [];
    detailResult.claims = [];
    detailResult.agendaEvents = [];
    const idNavigation = this.sessionService.open(
      'claimsNew',
      'summary',
      '',
      true
    );
    this.coreResult.setResult(
      idNavigation,
      'resourceId',
      detailResult.resourceId
    );
    this.coreResult.setResult(
      idNavigation,
      'potentialClaimDraft',
      detailResult
    );
    this.coreResult.setResult(
      idNavigation,
      'typeReturn',
      'openModuleCaiFromAngular8'
    );
    this.coreResult.setResult(idNavigation, 'claimsNew', {
      resourceId: detailResult.resourceId,
      contract: detailResult.selectedPolicy,
      numberClaim: detailResult.claimNumber,
      potentialClaimDraft: detailResult,
    });
  }

  goToDetailClaim$(currState: CaiOpeningState, idPotentialClaim: string, activeRouteId: string) {
    const companyCode = this.authService.getOperator().salePoint.company
      ? this.authService.getOperator().salePoint.company.code
      : null;
    this.urlGetClaimPes$(idPotentialClaim, companyCode, '6').subscribe(
      (result) => {
        const detailResult = result.claimInquiry;
        detailResult.internalCompanyCode = companyCode;
        detailResult.pesRequestType = result.potencialClaimTypeRequest;
        const idResult = this.sessionService.open(
          'claimsFinder',
          'detail-pes',
          '',
          true
        );
        detailResult.idResult = idResult;
        this.coreResult.setResult(idResult, 'detail-pes', detailResult);
        this.sessionService.remove(activeRouteId);
      }
    );
  }

  formatDateTimezone(date: string): string {
    if (date !== '') {
      const miaData = new Date(date);
      miaData.setHours(0);
      miaData.setMinutes(0);
      miaData.setSeconds(0);
      const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
      miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
      return miaData.toISOString();
    } else {
      return null;
    }
    /* const dataOutput = this.datePipe.transform(miaData, 'yyyy-MM-dd hh:mm:ss');
    return dataOutput; */
  }

  goToCaiModuleJs$(
    idActiveRoute: string,
    selectedPolicy: any,
    currentState: CaiOpeningState,
    signType: CodiceDescrizione
  ) {
    this.claimReducer._transformObjectToClaimJs(currentState).then((result) => {
      result.disabledBackButton = true;
      currentState.generatedClaim.claim = result;
      currentState.generatedClaim.claim.signType = signType;
      const idNavigation = this.sessionService.open(
        'claimsNew',
        'CAIModule',
        'Cai Module',
        true
      );
      this.coreResult.setResult(
        idNavigation,
        'resourceId',
        currentState.generatedClaim.resourceId
      );
      this.coreResult.setResult(
        idNavigation,
        'potentialClaimDraft',
        currentState.generatedClaim.claim
      );
      this.coreResult.setResult(
        idNavigation,
        'newDetailInfoContract',
        currentState.detailNewClaimInfo.contract
      );
      this.coreResult.setResult(
        idNavigation,
        'nodoFittSx',
        currentState.nodoFittSx
      );
      this.coreResult.setResult(idNavigation, 'claimsNew', {
        resourceId: currentState.generatedClaim.resourceId,
        contract: selectedPolicy,
        numberClaim: currentState.generatedClaim.claim.claimNumber,
        potentialClaimDraft: currentState.generatedClaim.claim,
        questionnaire: currentState.questionnaire,
        caiOpeningState: currentState
      });
    });
  }

  /* reloadQuestionarie$(st: Observable<CaiOpeningState>, reopenFromJs: ReopenFromJs){
      return st.pipe(
        map( (state) => { 
          const q2 = {...reopenFromJs.returnClaim.caiOpeningState.questionnaire};

          state.fillState(reopenFromJs.returnClaim.caiOpeningState);

      state.reopenFromJs = reopenFromJs;
      state.stepper.selectedIndex = 0;

      const validationQuestionnaire = reopenFromJs.returnClaim.caiOpeningState.questionnaire.items.find(res => res.answers[0].questionCode === 'SX0034');
      reopenFromJs.returnClaim.caiOpeningState.questionnaire.isValid = validationQuestionnaire ? validationQuestionnaire.answers[0].valueResponse !== null ? true : false : true;
      
      state.questionnaire = reopenFromJs.returnClaim.caiOpeningState.questionnaire;
      state.questionnaire = q2;

       return state;
     })
    )
  } */

  closeSession$(activeRouteId: string) {
    this.sessionService.remove(activeRouteId);
  }
  urlGetClaimPes$(
    numeroDenuncia: string,
    companyCode: string,
    potencialClaimTypeRequest: string
  ): Observable<OutputGetClaimPes> {
    const endPoint =
      caiModuleEndPoint.urlGetClaimPes +
      numeroDenuncia +
      '?chcCode=001&internalCompanyCode=' +
      companyCode +
      '&potencialClaimTypeRequest=' +
      potencialClaimTypeRequest;
    return this.httpClient.get<any>(this.baseApiUrl + endPoint);
  }

  saveDraftClaims$(state: CaiOpeningState, isDraft: boolean) {
    state.claimToService = new GeneratedClaimDetail();
    let currentState: any[] = [];
    currentState.push({ ...state });
    return new Promise<boolean>((resolve) => {
      this.claimReducer
        ._transformObjectToClaim(currentState[0], isDraft)
        .then((resolvePromise) => {
          /*  console.log(
             resolvePromise,
             'claim da passare al salvataggio del draft per poi andare in riepilogo'
           ); */
          state.claimToService.claim = resolvePromise.newClaimSaveInput.claim;
          this.saveDraftClaimsService$(resolvePromise)
            .subscribe(
              (response: OutputSaveDraft) => {
                if (response.result.claim !== null) {
                  response.result.claim.claimAddress.city =
                    response.result.claim.claimAddress.city === null
                      ? state.occurrenceData.addressEntity.city
                      : response.result.claim.claimAddress.city;
                  response.result.claim.claimAddress.province =
                    response.result.claim.claimAddress.province === null
                      ? state.occurrenceData.addressEntity.province
                      : response.result.claim.claimAddress.province;
                  response.result.claim.claimAddress.postCode =
                    response.result.claim.claimAddress.postCode === null
                      ? state.occurrenceData.addressEntity.postCode
                      : response.result.claim.claimAddress.postCode;
                  response.result.claim.claimAddress.number =
                    response.result.claim.claimAddress.number === null
                      ? state.occurrenceData.addressEntity.houseNumber
                      : response.result.claim.claimAddress.number;
                  response.result.claim.claimAddress.toponym = state.occurrenceData.addressEntity.toponym;
                  response.result.claim.claimAddress.umaAddress = state.occurrenceData.addressEntity.umaAddress;
                  response.result.claim.claimAddress.placeAddress =
                    response.result.claim.claimAddress.placeAddress === null
                      ? state.occurrenceData.addressEntity.address
                      : response.result.claim.claimAddress.placeAddress;
                  // data non necessaria in creazione sinistro
                  //response.result.claim.dateReceivedCHL = this.formatDateTimezone(state.occurrenceData.incidentData.chcReceiptDate);
                  response.result.claim.dateReceivedInsured = this.formatDateTimezone(state.occurrenceData.incidentData.insuredReportDate);
                  response.result.claim.dateReceivedIntermediary = this.formatDateTimezone(state.occurrenceData.incidentData.intermediaryReceiptDate);
                  response.result.claim.occurenceDescription = state.occurrenceData.incidentData.incidentDescription;
                  response.result.claim.incidentAdminNote = state.occurrenceData.incidentData.additionalNotes;
                  response.result.claim.occurenceDescription = state.occurrenceData.incidentData.incidentDescription;
                  response.result.claim.dateReportedEditable = state.occurrenceData.incidentData.dateReportedEditable;
                  response.result.claim.certifiedOccurrenceDate = state.occurrenceData.incidentData.certifiedOccurrenceDate;
                  response.result.claim.actualOccurrenceTime = state.occurrenceData.incidentData.actualOccurrenceTime;
                  response.result.claim.actualOccurrenceDate = state.occurrenceData.incidentData.actualOccurrenceDate;
                  response.result.claim.intermediaryReceiptDateEditable = state.occurrenceData.incidentData.intermediaryReceiptDateEditable;
                  response.result.claim.incidentDescriptionEditable = state.occurrenceData.incidentData.incidentDescriptionEditable;
                  response.result.claim.additionalNotesEditable = state.occurrenceData.incidentData.additionalNotesEditable;
                  response.result.claim.claimParties[0].damageDescriptions[0].asset.address = state.singleCai.propertyAddress;
                  response.result.claim.percInsuredLiability = state.occurrenceData.circumstanceIncident.percInsuredLiability ?
                    state.occurrenceData.circumstanceIncident.percInsuredLiability : null;
                  response.result.claim.eventDenial = state.occurrenceData.circumstanceIncident.eventDenial;
                  response.result.claim.externalClaimNumber = state.occurrenceData.additionalData.externalClaimNumberValue;
                  response.result.claim.authorityDescriptionValue = state.occurrenceData.additionalData.authorityDescriptionValue;
                  response.result.claim.typeOfAuthorityInvolvedValue = state.occurrenceData.additionalData.typeOfAuthorityInvolvedValue;
                  response.result.claim.lastAuditDateValue = state.occurrenceData.additionalData.lastAuditDateValue;
                  response.result.claim.openedClaimDuetoSummonsIssuedValue = state.occurrenceData.additionalData.openedClaimDuetoSummonsIssuedValue;
                  response.result.claim.notIdentifiedWitnessValue = state.occurrenceData.additionalData.notIdentifiedWitnessValue;
                  response.result.claim.toBeSettledValue = state.occurrenceData.additionalData.toBeSettledValue;
                  if (!state.singleCai.driver.chkInjury && !state.singleCai.driver.chkMaterial) {
                    response.result.claim.claimParties[0].damageDescriptions[0].asset.vehicle.driver = state.singleCai.driver.subject
                  }

                  const numCondProprietario = response.result.claim.claimParties.filter(res => res.partyRole.codice === '3');
                  if (numCondProprietario.length > 0) {
                    const indexProprietarioPresente = response.result.claim.claimParties.findIndex(res => res.partyRole.codice === '3');
                    if (state.singleCai.owner.chkInjury) {
                      response.result.claim.claimParties[indexProprietarioPresente].subject.subjectInjuryAsset =
                        state.singleCai.owner.subjectDataFormInjury;
                      response.result.claim.claimParties[indexProprietarioPresente].subject.chkInjury = true;
                    }
                    const indexVeicolo = response.result.claim.claimParties[indexProprietarioPresente]
                      .damageDescriptions.findIndex(res => res.assetType === 'Veicoli');
                    response.result.claim.claimParties[indexProprietarioPresente]
                      .damageDescriptions[indexVeicolo].asset.vehicle.bodyShopSubject =
                      state.singleCai.carBodywork;
                  } else {
                    const numTraspProprietario = response.result.claim.claimParties.filter(res => res.partyRole.codice === '7');
                    if (numTraspProprietario.length > 0) {
                      const indexProprietarioPresente = response.result.claim.claimParties.findIndex(res => res.partyRole.codice === '7');
                      if (state.singleCai.owner.chkInjury) {
                        response.result.claim.claimParties[indexProprietarioPresente].subject.subjectInjuryAsset =
                          state.singleCai.owner.subjectDataFormInjury;
                        response.result.claim.claimParties[indexProprietarioPresente].subject.chkInjury = true;
                      }
                      const indexVeicolo = response.result.claim.claimParties[indexProprietarioPresente]
                        .damageDescriptions.findIndex(res => res.assetType === 'Veicoli');
                      response.result.claim.claimParties[indexProprietarioPresente]
                        .damageDescriptions[indexVeicolo].asset.vehicle.bodyShopSubject =
                        state.singleCai.carBodywork;
                    } else {
                      const numProprietarioNonPresente = response.result.claim.claimParties.filter(res => res.partyRole.codice === '9');
                      if (numProprietarioNonPresente.length > 0) {
                        const indexProprietarioNonPresente =
                          response.result.claim.claimParties.findIndex(res => res.partyRole.codice === '9');
                        const indexVeicolo = response.result.claim.claimParties[indexProprietarioNonPresente]
                          .damageDescriptions.findIndex(res => res.assetType === 'Veicoli');
                        response.result.claim.claimParties[indexProprietarioNonPresente]
                          .damageDescriptions[indexVeicolo].asset.vehicle.bodyShopSubject =
                          state.singleCai.carBodywork;
                      }
                    }
                  }
                  if (state.singleCai.driver.chkInjury &&
                    state.singleCai.driver.objectId !== state.singleCai.owner.objectId) {
                    const numConducenti = response.result.claim.claimParties.filter(res => res.partyRole.codice === '1');
                    if (numConducenti.length > 0) {
                      const indexConducenteNonProprietario = response.result.claim.claimParties.findIndex(
                        res => res.partyRole.codice === '1');
                      response.result.claim.claimParties[indexConducenteNonProprietario].subject.subjectInjuryAsset =
                        state.singleCai.driver.subjectDataFormInjury;
                      response.result.claim.claimParties[indexConducenteNonProprietario].subject.chkInjury = true;
                    }
                  }
                  state.claimToService.claim = response.result.claim;
                  if (!state.reopenFromJs.isReopenedFromJs) {
                    state.claimToService.claim.selectedPolicy = { ...state.selectedPolicy };
                    state.claimToService.claim.contract = { ...state.contract };
                  } else {
                    state.claimToService.claim.selectedPolicy = { ...state.generatedClaim.claim.selectedPolicy };
                    state.claimToService.claim.contract = { ...state.generatedClaim.claim.contract };
                  }
                  state.claimToService.claim.selectedEventCategory = { ...state.detailNewClaimInfo.selectedEventCategory };
                  state.claimToService.claim.companyDetailObject = state.singleCai.insuranceCompany;
                  currentState = [];
                  this.goToSummary$(state);



                  console.log('response', response)

                  resolve(true);
                } else {
                  state.claimToService = new GeneratedClaimDetail();
                  currentState = [];
                  let logMessageError: string;
                  if (response.result.error.length > 0) {
                    logMessageError = response.result.error[0].logMessage;
                  } else {
                    logMessageError = 'Errore riscontrato durante il salvataggio del claim draft.';
                  }
                  this.alertService.message(logMessageError);
                  resolve(false);
                }
              }
            );
        });
    });
  }

  saveDraftClaimsService$(claimDraft: NewClaim): Observable<OutputSaveDraft> {
    return this.httpClient.post<OutputSaveDraft>(
      this.baseApiUrl + caiModuleEndPoint.urlClaimSave,
      claimDraft
    );
  }

  getCovertureBranch$(
    numberPolicy: string,
    st: Observable<CaiOpeningState>
  ) {
    const compCode = this.authService.getOperator().salePoint.company
      ? this.authService.getOperator().salePoint.company.code
      : null;
    const IBody = {
      filter: {
        companyCode: compCode,
        incidentDate: new Date().toISOString(),
        policyNumber: numberPolicy,
      },
    };
    return st.pipe(
      mergeMap((state) => {
        const branchesCodes =
          this.httpClient.post<OutputResultCovertureBranches>(
            this.baseApiUrl + caiModuleEndPoint.urlGetCovertureBranches,
            IBody
          );
        return combineLatest([branchesCodes, of(state)]);
      }),
      map(([responseBranchesCode, state]) => {
        state.listCovertureBranches =
          responseBranchesCode.covertureBranches.find(
            (coverture: CovertureBranch) =>
              coverture.claimBranchCode ===
              state.detailNewClaimInfo.selectedEventCategory.branchesCodes[0]
          );
        state.claimBranchDescription =
          state.listCovertureBranches?.claimBranchDescription;
        state.selectedCovertureBranch =
          state.listCovertureBranches?.covertureTypes[0];
        return state;
      })
    );
  }

  getPolicyAdditionalData$(state: CaiOpeningState): Observable<any> {
    return this.policyService.getPolicyAdditionalData(
      state.selectedPolicy.policyNumber,state.detailNewClaimInfo.selectedEventCategory.idCategory.toString(),
      new Date(), '00:00'
    );
  }

  isAuthorizedForClaimFeature$(functionString: string): boolean {
    return this.functionService.isAuthorizedForClaimFeature(functionString)
  }

  alertMessage(message: string) {
    this.alertService.translatedMessage(message);
  }

  getNodesSinistri$(valueSx: string): Observable<any> {
    let params = new HttpParams();
    params =   params.append('codeSp', valueSx);
    params = params.append('loadOverTreePOS', 'true');
    return this.httpClient.get<any>(`${this.baseApiUrlV2}/common/agencies`, {params});
  }

  decrementStepper$(state: Observable<CaiOpeningState>): Observable<CaiOpeningState> {
    return state.pipe(map(st => {
      st.stepper.activeItem += -1;
      st.stepper.selectedIndex += -1;
      return st
    }))
  }
}
