import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { NgSelectModule } from '@ng-select/ng-select';
import { LpcSubjectModule } from '../lpc-subject/lpc-subject.module';

import { LpcFormFieldHandlerComponent } from './lpc-form-field-handler.component';
import { ExtractFromArrayPipeModule } from '../../utils/pipe/extract-from-array/extract-from-array.module';
import { LpcDatepickerModule } from '../lpc-datepicker/lpc-datepicker.module';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcFormFieldHandlerComponent],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        NgSelectModule,
        LpcSubjectModule,
        ExtractFromArrayPipeModule,
        LpcDatepickerModule,
        LpcRequiredModule,
        RgiRxI18nModule
    ],
    exports: [LpcFormFieldHandlerComponent]
})
export class LpcFormFieldHandlerComponentModule {}

