<div class="panel-body nmf_cont_viewbox">
  <div class="nmf-packet-tabset">
    <div class="nmf-packet-tiles">
      <div *ngFor="let package of packagesList" class="nmf-packet-box">
        <div class="nmf-packet-box-heading">
          <div [ngClass]="{'nmf-packet-box-active': package.selection, 'nmf-packet-heading-title': !package.selection }">
            <button (click)="selectPacket(package)" [attr.data-qa]="'packet_'+package.code"
                    class="btn-block nmf-packet-box-heading_button"
                    type="button">
              <div class="nmf-packet-box-heading_button_sx">
                {{ package?.description }}
              </div>
              <div class="nmf-packet-box-heading_button_dx">
                  <span class="btn btn-checkbox checkbox-packet nmf-packet-box-checkbox" type="checkbox">
                    <span [ngClass]="{'glyphicon-ok': package?.selection }" class="glyphicon">
                    </span>
                  </span>
              </div>
            </button>
            <div *ngIf="packagesPremium && packagesPremium[package?.code] && packagesPremium[package?.code].premium"
                 class="nmf-packet-premium">
              {{packagesPremium[package?.code].premium?.instalment?.gross | currency:'EUR':'symbol':'1.2-2':'it' }}
            </div>
          </div>

          <div *ngFor="let unit of package.unitList" [ngClass]="{'nmf-row-packet': (unit.enabled || (!unit.enabled && unit.selected))}"
               class="row">
            <div>
              <div class="col-sm-12 nmf_cont_single_data_color">
                <div (click)="toggleUnit(unit, package.selection)" *ngIf="unit.enabled" [ngClass]="{'pointerEnabled':package.selection && (unit.enabled || unit.selected || unit.mandatory), 'pointerDisabled':!package.selection}"
                     class="btn btn-checkbox checkbox-unit"
                     type="checkbox">
                    <span [ngClass]="{'glyphicon-ok nmf-icon-check-ok': unit?.selected}" class="glyphicon">
                    </span>
                </div>
                {{ unit?.extendedDescription ? unit?.extendedDescription : unit?.description }}
                <b *ngIf="packagesPremium[package?.code] && packagesPremium[package?.code].unitsPremium"
                   class="pull-right">
                  {{packagesPremium[package?.code].unitsPremium[unit?.code + '_' + unit?.warrantyCode].instalment?.gross |
                  currency:'EUR':'symbol':'1.2-2':'it' }}
                </b>
              </div>
              <div class="col-sm-12">
                <mic-package-factors [unit]="unit"></mic-package-factors>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <button (click)="editVariablesModal(package)" *ngIf="package.selection"
                  class="btn btn-default btn-packet-detail">
            {{'Modify values' | translate}} <span *ngIf="!isPackageComplete(package)" class="field-required">*</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
