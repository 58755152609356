import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_GL_ES: ClaimsTranslationsKeys = {
    _CLAIMS_TITLE_CARD_CAI_: 'Novo sinistro',
    _CLAIMS_TITLE_DIALOGUE_DASHBOARD_: 'Dialogue dashboard',
    _CLAIMS_TITLE_TIREA_DASHBOARD_: 'Tirea Payment Board',
    _CLAIMS_TITLE_DIALOGUE_HANDLER_LIST: 'Dialogue List',
    _CLAIMS_TITLE_PAYMENT_TABLE_TYREA: 'Payments table Tirea',
    _INVOICE: 'Invoice',
    _NEW_PAYMENT: 'New payment',
    _CLAIMS_CONNECTOR: 'Claims Connector',
    _CHAT: 'CHAT',
    _EDIT_CLAIMS: 'Edit Claims',
    _ACTIVITY: 'Actividade',
    _CLAIMS_PILLS: 'Claims Pills',
    _AUTOSOFT_DASHBOARD: 'Autosoft Dashboard',
    _DOSSIER_MANAGEMENT: 'Dossier management',
    _CLAIM_DETAIL: 'Claim detail',
    _NEW_FNOL: 'New FNOL',
    _RCA_QUESTIONNAIRE: 'Cuestionario RCA',
    _CLAIM_SUBJECTS: 'Claim subjects',
    _CLAIMS_FROM_ELASTIC: 'Claims from elastic',
    _COMPENSATION_LIST: 'Compensation list',
    _EXPERTS: 'Experts',
    _TIMELINE: 'Timeline',
    _LETTER_TEMPLATES: 'Modelos de cartas',
    _LIMITS_MANAGEMENT: 'Xestión de límites',
    _INVOICES_LIST: 'Lista de facturas',
    _UPDATE_RESERVE: 'Update reserve',
    _CLAIMS_: {
        _TAX_REGIME: 'Régimen Fiscal',
        _IVA_REGIME: 'Régimen del IVA',
        _AGGREGATE_TAX_REGIME: 'Régimen fiscal agregado',
        _AGGREGATE_IVA_REGIME: 'Régime de TVA global',
        _FISCAL_DATA: 'Datos fiscales',
        _ACCOUNTING_DATE: 'Data de contabilidade',
        _ACT_ACTIVE: 'Acto activo',
        _ACT_CLOSED: 'Acto pechado',
        _ACT_EVASION_DATE: 'Acto cumprido data',
        _ACTION: 'Acción',
        _ACTIVE_CHATS: 'Chats activos',
        _ACTUAL_DATE_OF_OCCURRENCE: 'Data real do incidente',
        _ACTUAL_TIME_OCCURRENCE: 'Hora real do incidente',
        _ADD_ASSET: 'Engadir ben asegurado',
        _ADDRESS: 'Enderezo',
        _ADMIN_LEVEL_1: 'Nivel administrativo 1',
        _ADMIN_LEVEL_2: 'Nivel administrativo 2',
        _ADMIN_LEVEL_3: 'Nivel administrativo 3',
        _ADMINISTRATIVE_REGULARITY: 'Regularidade administrativa',
        _AGENCY_UNIQUE_KEY: 'Clave única da axencia',
        _AGENCY: 'Axencia',
        _ALLEGATION_DESCRIPTION: 'Descrición da denuncia',
        _AMOUNT_CLEARED: 'Importe liquidado',
        _AMOUNT_HOLD_TAX: 'Importe da retención a conta',
        _AMOUNT_TO_BE_PAYD: 'Importe a pagar',
        _ASSET: 'Ben asegurado',
        _ASSETS_DATA: 'Datos do ben asegurado',
        _ASSET_DESCRIPTION: 'Descrición do ben asegurado',
        _ASSIGNED_TO_A_GROUP: 'Asignado a un grupo',
        _ASSIGNMENT_SELECTION: 'Selección de asignación',
        _BODY: 'Corpo',
        _CANCELED_BY: 'Cancelado por',
        _CANCEL_REQUEST_DATE: 'Cancelar a data da solicitude',
        _CAP: 'ZIP',
        _CASH_PREVIDENTIAL_AMOUNT: 'Importe do fondo de cotizacións sociais',
        _CERTIFIED_OCCURRENCE_DATE: 'Data do incidente certificada',
        _CERTIFIED_EVENT_DATE: 'Data do evento certificada',
        _CHASSIS_NUMBER: 'Número de chasis',
        _CDL_RECEIPT_DATE: 'Data de recepción de CHC',
        _CHAT_HISTORY: 'Historial do chat',
        _CHATS_WITH_ME: 'Chats comigo',
        _CITY: 'Cidade',
        _CIVIC_NUMBER: 'Número da rúa',
        _CLAIM_NUMBER_RECEIVED: 'Número de sinistro recibido',
        _CLAIM_SELECTION: 'Selección de sinistro',
        _CLAIMS: 'SINISTROS',
        _CLAIMS_TECHNICAL_STATUS: 'Estado técnico do sinistro',
        _COMPLAINT_DATA: 'Datos da denuncia',
        _CLAIM_NUMBER: 'Número de sinistro',
        _CLAIM_POLICY: 'Claim Policy',
        _CLICK_HERE: 'faga clic aquí',
        _CLOSE: 'Close',
        _COHERENT: 'Coherente',
        _COLLECTION_DATE: 'Data de cobramento',
        _COMMENTS: 'Comentarios',
        _COMPULSORY_QUEST: 'Compulsory question: ',
        _COMPANY_DESCRIPTION: 'Descripción de la empresa',
        _COMPANY_DESCRIPTION_CTP: 'Descripción de la empresa de contraparte',
        _CONFIRM: 'Confirmar',
        _CONTRACTOR: 'Contratista',
        _CORPORATE_LITIGATION: 'Dereito corporativo',
        _COUNT: 'Contar',
        _COUNTERPARTY_PLATE_NUMBER: 'Claim counteparty plate number',
        _OUR_REFERENCES: 'Nuestras Referencias',
        _COUNTERPARTY_REFERENCES: 'Sus Referencias',
        _CREATION: 'Creación',
        _DAMAGE_CATEGORY: 'Categoría de danos',
        _DAMAGE_POSITION: 'Posición de dano',
        _DAMAGED: 'Parte prexudicada',
        _DATE_RECEIVED_INTERMEDIARY: 'Data de recepción do intermediario',
        _DAMAGE_TO_THINGS: 'Danos aos bens',
        _DAMAGED__ASSET: 'Parte prexudicada / Ben asegurado',
        _DAMAGED__INVOLVED_ITEM_DATA: 'Datos do elemento danado/implicado',
        _DAMAGED_ASSETS: 'Bens asegurados danados',
        _DAMAGED_PARTS: 'Partes danadas',
        _DEADLINE_DATA: 'Datos do prazo',
        _DELETED: 'Suprimido',
        _DEROGATION_LEVEL: 'Nivel de autorización',
        _DESELECT_ALL: 'Deseleccionar todo',
        _DETAILS: 'Información',
        _LAST_EXECUTION_DETAILS: 'Last execution details',
        _SAVE_NEW_REQUEST: 'Save new request',
        _DEPARTMENT: 'Departamento',
        _DOCUMENT_MANAGEMENT: 'Xestión de documentos',
        _DOCUMENTS_UPLOAD: 'Carga de documentos',
        _DRIVER: 'Condutor',
        _DRIVER_MANDATORY: 'Condutor obrigatorio',
        _DRIVER_LICENSE_TITLE: 'Permiso de conducir',
        _RELEASED_BY: 'Liberado por',
        _RELEASED_DATE: 'Data de liberación',
        _EXPIRATION_DATE: 'Data de vencemento',
        _CATEGORY: 'Categoría',
        _ADD_CATEGORY: 'Engadir categoría',
        _DUTIES_AMOUNT: 'Importe dos impostos',
        _EDIT_LIMIT: 'Edición de límite',
        _EMAIL: 'Correo electrónico',
        _ERROR_CODE: 'Código de erro',
        _EXCLUDE_FROM_CALCULATION: 'Excluír do cálculo',
        _EXCLUDED_FROM_CALCULATION: 'Excluído do cálculo',
        _EXPERT: 'Experto',
        _EFFECTIVE_COMPLAINT_DATE: 'Data efectiva de denuncia',
        _EXPERT_DATA: 'Datos do perito',
        _EXPERT_TYPE: 'Expert type',
        _FAX: 'Fax',
        _FEES_AMOUNT: 'Importe dos honorarios',
        _FIRST_REGISTRATION_DATE: 'Primeira data de rexistro',
        _FISCAL_CODE__VAT_CODE: 'Código fiscal / núm. de IVE',
        _FNOL_DATA_COLLECTION: 'Recollida de datos FNOL',
        _GENDER: 'Xénero',
        _GENERAL_DATA: 'Datos xerais',
        _GIVEN_FIRST_REGISTRATION: 'GL_ES_Given first registration',
        _GREEN_CARD_NUMBER: 'Número de carta verde',
        _GROSS: 'En bruto',
        _GROSS_EXCESS: 'Bruto',
        _INCONSISTENT_ROW_AT_LINE_: 'Fila inconsistente na liña ',
        _METAINFO: 'Metainfo',
        _INDEMNITY_AMOUNT: 'Importe da indemnización',
        _INITIAL_LIMIT_AMOUNT: 'Importe límite inicial',
        _INITIAL_LIMIT_VALUE: 'Valor límite inicial',
        _INJURY: 'Lesión',
        _INJURY_CODE: 'Código de lesións',
        _INCIDENT_DATA: 'Datos do incidente',
        _INJURY_PHYSICAL: 'Lesión física',
        _INPS_CONTRIB_AMOUNT: 'Importe de contribución INPS',
        _INSERT: 'Insert',
        _INS_DATE: 'GL_ES_Insertion date',
        _INSERT_NEW_REQUEST: 'Inserir nova solicitude',
        _INSERTION_DATE: 'Insertion date',
        _INSPECTOR: 'Inspector',
        _INSTANCE: 'Instancia',
        _INSURANCE_COMPANY: 'Compañía de seguros',
        _INSURED_ACTIVITY: 'Actividade asegurada',
        _INSUREDS: 'Asegurados',
        _INSURED_REPORT_DATE: 'Datos dos informes dos asegurados',
        _INVALID_FIELDS: 'Campo non válido',
        _INVOICE: 'Factura',
        _INVOICE_AMOUNT: 'Importe da factura',
        _INVOICE_DATA: 'Datos de factura',
        _INVOICE_DETAILS: 'Datos da factura',
        _INVOICE_NUMBER: 'Número de factura',
        _INVOICE_TYPE: 'Tipo de factura',
        _INVOICES: 'Facturas',
        _LETTER_DEROGATION_LEVEL: 'Nivel de autorización da carta',
        _LETTER_TEMPLATE_SAVED: 'Modelo de carta gardado',
        _LEVEL: 'Nivel',
        _LEVEL_CODE: 'Código de nivel',
        _LIMITS_POLICY_N: 'Límites póliza n.º',
        _MAIN_SUBSTATUS: 'Subestado principal',
        _MANAGE_PHASE: 'Fase de xestión',
        _MANAGEMENT_NOTE: 'Nota de xestión',
        _MANDATORY_DOCUMENTS: 'Documentos obrigatorios',
        _MANUAL_EXCESS: 'Manual de franquías',
        _MANUFACTURER: 'Fabricante',
        _MATERIAL_DAMAGE: 'Danos materiais',
        _MERGE_ABSORPTION_DATE: 'Merge absorption date',
        _MESSAGE: 'Mensaxe',
        _MESSAGE_RESPONSE: 'Resposta da mensaxe',
        _MESSAGE_REQUEST: 'GL_ES_Request Message',
        _MESSAGES: 'Mensaxes',
        _MINOR: 'Menor',
        _MINORGUARDIAN: 'TITOR MENOR',
        _MINISTERIAL_BRANCH: 'Clase ministerial',
        _MOBILE_PHONE: 'Teléfono móbil',
        _MODELS_LIST: 'Lista de modelos',
        _MODIFY_FNOL: 'Modificar FNOL',
        _MOTOR_CARD: 'Tarxeta do motor',
        _NA: 'No Aplica',
        _NAME: 'Nome',
        _NATION_ADDRESS: 'Enderezo do país',
        _NATIONALITY: 'Nacionalidade',
        _NET: 'Neto',
        _NETGROSS_EXCESS: 'Franquía neta/bruta',
        _NEW_COMPANY: 'Nova empresa',
        _NEW_LETTER_MODEL: 'Novo modelo de carta',
        _NEW_LIMIT: 'Novo límite',
        _NEW_UPLOAD: 'Nova carga',
        _NORMALIZE: 'Normalizar',
        _NOT_COHERENT: 'Non é coherente',
        _NOTIFICATION_DATE: 'Data de notificación',
        _NOT_TAXABLE_AMOUNT: 'Base impoñible exenta',
        _OBJECT: 'Obxecto',
        _OCCURRENCE_TIME: 'Hora do incidente',
        _OCCURRENCE_DESCRIPTION: 'Descrición do incidente',
        _OBSERVATIONS: 'Observacións',
        _OPENING_DATE_FROM: 'Data de apertura a partir de',
        _OPENING_DATE_TO: 'Data de apertura a',
        _OTHER_DOCUMENTS: 'Outros documentos',
        _OTHER: 'Outros',
        _OWNER: 'Propietario',
        _PARTY_INVOLVED: 'Parte implicada',
        _PARKED_VEHICLE: 'Vehículo estacionado',
        _PRODUCT: 'Produto',
        _PASSENGER: 'Pasaxeiro',
        _PAYMENT_AMOUNT: 'Importe de pagamento',
        _PEC: 'Porcentaxe',
        _PERC_PREV_FUND_1: 'Porcentaxe prev fondo 1',
        _PERC_PREV_FUND_2: 'Porcentaxe prev fondo 2',
        _PERC_WITH_HOLD_TAX: 'Porcentaxe de retención a conta',
        _PERCENTAGES: 'Porcentaxes',
        _PERSON: 'Persoa',
        _PHONE: 'Teléfono',
        _PHYSICAL_INJURY: 'Lesión física',
        _PLATE_NUMBER: 'Matrícula asegurado',
        _POLICY: 'Póliza',
        _POLICY_DETAIL: 'Información da póliza',
        _POLICY_HOLDER__INSURED: 'Tomador / Asegurado',
        _POLICY_LIST: 'Lista de pólizas',
        _PROVINCE: 'Provincia',
        _PROFESSIONAL_SERVICES: 'Servicios profesionales',
        _REAL_NAME: 'Nome real',
        _RECEIVED_DATE: 'Data de recepción',
        _RECEIVED_DATE_FROM: 'Data de recepción de',
        _RECEIVED_DATE_TO: 'Data de recepción ata',
        _RECIPIENT: 'Destinatario',
        _REGIME: 'Réxime',
        _REGISTRY: 'Rexistro',
        _REJECT_WORKED: 'Rexeitar traballado',
        _REPORT: 'Informe',
        _RESERVE: 'Reserva',
        _RESIDUE_LIMIT_AMOUNT: 'Importe límite residual',
        _RESIDUE_LIMIT_VALUE: 'Valor límite residual',
        _RISK_DESCRIPTION: 'Descrición do risco',
        _ROUNDING_AMOUNT: 'Importe de redondeo',
        _SAP_CODE: 'Código SAP',
        _SAP_RECORDING_DATE: 'Data de rexistro SAP',
        _SDI_CODE: 'Código SDI',
        _SEARCH_DOCTOR: 'Buscar médico',
        _SELECT_ALL: 'Seleccionar todo',
        _SELECT_ASSIGNMENT: 'Seleccionar asignación',
        _SELECT_AT_LEAST_ONE_USER: 'Seleccionar polo menos un usuario',
        _SELECT_BY_DEFAULT: 'Seleccionar por defecto',
        _SELECT_CLAIM: 'Seleccionar sinistro',
        _SELECT_POLICY: 'Seleccionar póliza',
        _SELECT_RECIPIENT: 'Seleccionar destinatario',
        _SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST: 'Seleccione a asignación correspondente da lista',
        _SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST: 'Seleccione o sinistro correspondente da lista',
        _SELECT_THE_CORRESPONDING_TYPE_OF_LOSS_FROM_THE_LIST: 'Seleccione na lista o tipo de sinistro correspondente',
        _SELECT_TYPE_OF_LOSS: 'Seleccione o tipo de sinistro',
        _SELECTECT_SUBJECT: 'Seleccionar parte',
        _SENDING_DATE: 'Data de envío',
        _SIC_OUTCOME: 'Resultado SIC',
        _SIMILAR_COMPLAINTS: 'Denuncias similares',
        _SIMILAR_SUBJECT: 'Parte similar',
        _STAMP_DUTY_AMOUNT: 'Importe do imposto sobre actos xurídicos documentados',
        _SUBJECT_NAME: 'Nome da parte',
        _SUBJECT_SURNAME_OR_DENOMINATION: 'Apelido ou denominación da parte',
        _SUBTYPOLOGY: 'Subtipoloxía',
        _SUBTYPOLOGY_CODE: 'Código de subtipoloxía',
        _SUIT_NUMBER: 'Número de demanda',
        _SURNAME: 'Apelido',
        _SX_SUBJECT: 'SX_Suxeito',
        _TAX_ID: 'Identificación fiscal',
        _TECHNICAL_COVERAGE: 'Cobertura técnica',
        _TAXABLE_AMOUNT: 'Base impoñible',
        _TOTAL_DISTRUCTION: 'Cancelación',
        _THINGS: 'Bens',
        _THIRD_PARTY: 'Terceiros',
        _TO_BE_EVALUATED: 'Pendente de avaliación',
        _TO_BE_REEVALUATED: 'Para reavaliar',
        _TO_SELECT: 'seleccionar',
        _TOPONYM: 'Nome do lugar',
        _TOTAL: 'Total',
        _TOT_AMOUNT_UNIT: 'Importe unitario total',
        _TOTAL_RESERVES_AMOUNT: 'Importe total das reservas',
        _TYPE_OF_LOSS_DESCRIPTION: 'Descrición do tipo de sinistro',
        _TYPE_OF_LOSS_SELECTION: 'Selección do tipo de sinistro',
        _TYPE_OF_DAMAGE: 'Tipo de dano',
        _TYPE_CIVIL__PENAL: 'Tipo Civil/Penal',
        _UNIQUE: 'Único',
        _UPDATE_REASON: 'Actualizar motivo',
        _USER_ASSOCIATION: 'Asociación de usuario',
        _USER_BY_WORKLOAD: 'Usuario (por carga de traballo)',
        _USER_DATA: 'Datos do usuario',
        _USER_DEROGATION_LEVEL: 'Nivel de autorización do usuario',
        _VALIDITY_END_DATE: 'Data de fin de validez',
        _VALIDITY_START_DATE: 'Data de inicio de validez',
        _VAT_AMOUNT: 'Importe IVE',
        _VAT_RATE: 'Tipo de IVE',
        _VEHICLE: 'Vehículo',
        _VISIBLE_DAMAGE_ON_VEHICLE: 'Danos visibles no vehículo',
        _NUMBER_CLAIM: 'Número de sinistro',
        _OCCURRENCE_DATE: 'Data de incidente',
        _CLAIM_BRANCH: 'Sucursal do sinistro',
        _CLAIM_TYPE: 'Tipo de sinistro',
        _POLICY_NUMBER: 'Número de póliza',
        _POLICY_NR: 'Núm. de póliza',
        _CDL: 'CHC',
        _ADJUSTER: 'Liquidador',
        _PROCEDURE_NUMBER: 'Número de procedemento',
        _COUNTERPART: 'Contraparte',
        _COUNTERPARTS: 'Counterparts',
        _INSURED: 'Asegurado',
        _COMPLAINT_DATE: 'Data da denuncia',
        _COVERTURE_TYPE: 'Tipo de cobertura',
        _CLAIM_NUMBER_STATUS: 'Número e estado do sinistro',
        _COMPLAINT_NUMBER_STATUS: 'Número e estado da denuncia',
        _OCCURRENCE_DATA: 'Datos do incidente',
        _DOCUMENTS: 'Documentos',
        _CANCELLATION_DATE: 'Data de cancelación',
        _DESCRIPTION: 'Descrición',
        _EFFECT_DATE: 'Data de vixencia',
        _INVALID_POLICY: 'Póliza non válida',
        _POLICY_HOLDER: 'Titular',
        _UNITS: 'Unidade',
        _VALID_POLICY: 'Póliza válida',
        _MANAGEMENT_NODE: 'Nodo de xestión',
        _CONTRACT_STATUS: 'Estado do contrato',
        _FRACTIONING: 'Pagamento a prazos',
        _ISSUE_FRACTIONING: 'Emisión a prazos',
        _POLICY_TYPE: 'Tipo de póliza',
        _ISSUE_DATE: 'data de emisión',
        _COVERAGE_EXPIRATION_DATE: 'Data de caducidade da cobertura',
        _COINSURANCE_SHARE: 'Cota de coaseguro',
        _INITIAL_EFFECTIVE_DATE: 'Data inicial de vixencia',
        _RESCINDABILITY_DATE: 'Data de rescisión',
        _COINSURANCE_TYPE: 'Tipo de coaseguro',
        _PREMIUM_ADJUSTMENT: 'Axuste da prima',
        _INDEXING: 'Indexación',
        _LPS: 'LPS',
        _NO: 'Non',
        _YES: 'Si',
        _INDIRECT_COINSURANCE: 'Coaseguro indirecto',
        _MIGRATED: 'Migrado',
        _REINSURANCE: 'Reaseguro',
        _BONDED: 'Garantido',
        _TACIT_RENEWAL: 'Renovación tácita',
        _SUBJECTS: 'Partes',
        _ARCHIVE_SUBJECTS: 'Arquivo de partes',
        _LOCATIONS: 'Localizacións',
        _PRINCIPAL: 'Principal',
        _PROPERTY_ADDRESS: 'Enderezo da propiedade',
        _SECONDARY: 'Secundario',
        _ACTIVITIES: 'Actividades',
        _VEHICLE_DATA: 'Información do vehículo',
        _CLASS: 'Clase',
        _EXTENSION: 'Extensión',
        _SPECIAL_ABBREVIATION: 'Abreviatura especial',
        _PLATE_TYPE: 'Tipo de matrícula',
        _BRAND: 'Marca',
        _MODEL: 'Modelo',
        _FACTORS: 'Factores',
        _CLAUSES: 'Cláusulas',
        _ADDITIONAL_DATA: 'Datos adicionais',
        _TYPE_OF_AUTHORITY: 'Tipo de autoridade',
        _DESCRIPTION_AUTHORITY: 'Descrición da autoridade',
        _CATASTROPHIC_EVENT: 'Catástrofe',
        _DESCRIPTION_EVENT: 'Descrición do suceso',
        _ANTI_FRAUD_CATHEGORY: 'Categoría antifraude',
        _LAST_AUDIT_DATE: 'Última data de auditoría',
        _LIQUIDABLE: 'Liquidable',
        _NATION: 'País',
        _ZIP_CODE: 'Código postal',
        _BODYWORK: 'Carrocería',
        _SELECT: 'Seleccionar',
        _DENOMINATION: 'Denominación',
        _TELEPHONE: 'Teléfono',
        _ACCIDENT_CIRCUMSTANCE: 'Circunstancia do accidente',
        _BAREMS: 'Escalas',
        _BLACK_BOX_COHERENCE: 'Coherencia de caixa negra',
        _ACCIDENTS: 'Accidentes',
        _INSURED_LIABILITY_PERC: 'Porcentaxe de responsabilidade asegurada',
        _EVENT_DENIAL: 'Casos de denegación',
        _SIGN_TYPE: 'Tipo de sinal',
        _INSURED_VEICHLE_POSITION: 'Posición do vehículo asegurado',
        _THIRD_PART_VEICHLE_POSITION: 'Posición do vehículo de terceiros',
        _CALCULATED_LIABILITY: 'Responsabilidade calculada',
        _LIABILITY_TYPE: 'Tipo de responsabilidade',
        _COUNTERPART_LIABILITY_PERC: 'Porcentaxe de responsabilidade da contraparte',
        _DECLARED_LIABILITY: 'Responsabilidade declarada',
        _ANIA_CLASSIFICATION: 'Clasificación ANIA',
        _APPLY_TECH_LIABILITY_FORCED: 'Aplicar a responsabilidade técnica obrigada',
        _NOTE: 'Nota',
        _APPLY_ANIA_LIABILITY_FORCED: 'Aplicar a responsabilidade obrigada ANIA',
        _FORCE_REASON: 'Forzar motivo',
        _FORCED_LIABILITY: 'Forzar a responsabilidade',
        _EDIT_ACCIDENTS: 'Editar accidentes',
        _USE: 'Uso',
        _BUTTONS_: {
            _ACTIONS: 'ACCIÓNS',
            _BACK: 'ATRÁS',
            _CLOSE: 'PECHAR',
            _NEXT: 'SEGUINTE',
            _NEW: 'NOVO',
            _CANCEL: 'CANCELAR',
            _CONFIRM: 'CONFIRMAR',
            _EXIT: 'SAÍR',
            _SAVE: 'GARDAR',
            _EMPTY: 'BALEIRO',
            _FIND: 'ATOPAR',
            _SHOW: 'AMOSAR',
            _HIDE: 'AGOCHAR',
            _INSERT: 'INSERIR',
            _NEW_DIALOGUE: 'NEW DIALOGUE',
            _ASSOCIATE: 'ASSOCIATE',
            _REQUEST: 'REQUEST',
            _RESET_ADVANCE: 'RESET ADVANCE',
            _SAVE_PES: 'SAVE PES',
            _CORRECT: 'CORRECT',
            _ADD: 'ADD',
            _SEARCH: 'Buscar',
            _IBD: 'IBD',
            _OK: 'OK',
            _ADD_NOTE: 'ADD NOTE',
            _FAILED_TRANSMISSION: 'FAILED TRANSMISSION',
            _EVENTS: 'EVENTS',
            _SEND: 'Enviar',
            _DOWNLOAD_DOCUMENTI_SELEZIONATI: 'DOWNLOAD SELECTED DOCUMENTS',
            _DOWNLOAD_CLAIM_DOCUMENTS: 'DOWNLOAD CLAIM DOCUMENTS',
            _DOCVIEWER: 'DOCVIEWER',
            _DOWNLOAD_POLICY_DOCUMENTS: 'DOWNLOAD POLICY DOCUMENTS',
            _EDIT: 'Edit',
            _NEW_REQUEST: 'New Request',
            _RERUN: 'Rerun',
            _RESERVATION: 'RESERVATION',
            _ONDEMAND: 'ONDEMAND',
            _NEW_MANUAL_DEADLINE: 'Novo prazo manual',
            _UPLOAD: 'UPOLAD',
            _TRANSMISSION: 'TRANSMISSION',
            _MODELS_LIST: 'Models list',
            _NEW_LETTER_MODEL: 'New letter model',
            _DELETE: 'GL_ES_DELETE',
            _ADD_ACTOR: 'GL_ES_ADD ACTOR',
            _RETRIEVE_INFORMATION: 'GL_ES_RETRIEVE INFORMATION',
            _REASSOCIATE: 'Reassociate',
            _MODIFY: 'Modify',
            _RESPONSE: 'RESPONSE',
        },
        _MESSAGE_: {
            _NO_RESULT: 'Ningún resultado',
            _OPENED_CLAIM_DO_ISSUE: 'Sinistro aberto por citación xudicial',
            _NOT_IDENTIFIED_WITNESS: 'Testemuña non identificada',
            _SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION: 'Seleccione só os recadros de uso para a descrición',
            _SELECT_AT_LEAST_ONE_CAUSAL: 'Seleccione polo menos un motivo antes de continuar',
            _INVALID_DATE_INCIDENT: 'A data inserida non pode ser diferente da data comunicada',
            _INVALID_REPORTED_DATE_INCIDENT: 'A data inserida non pode ser anterior á data do incidente',
            _INVALID_RECEIVED_DATE_INCIDENT: 'A data inserida non pode ser anterior á data da denuncia',
            _INVALID_CHC_DATE : 'A data inserida non pode ser anterior á data de recepción do intermediario',
            _INVALID_FUTURE_DATE : 'Non é posible seleccionar datas no futuro',
            _AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT: 'O importe a pagar non pode ser inferior á suma da base impoñible e a base non impoñible',
            _AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT: 'Importe a pagar distinto do importe da factura menos o importe da retención a conta',
            _AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO: 'O importe a pagar debe ser superior a cero',
            _ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Advertencia! Estase abrindo un sinistro sobre unha póliza tecnicamente non cuberta',
            _ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY: 'Advertencia: apertura de sinistro sobre unha póliza cancelada',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT: 'Advertencia: o pagamento supera os importes residuais do límite',
            _CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY: 'Non se pode abrir un sinistro sobre unha póliza cancelada',
            _CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY: 'Non se pode abrir un sinistro sobre unha póliza sen validez administrativa',
            _CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'O importe do fondo de cotizacións sociais non pode ser superior á base impoñible',
            _DRAG_A_FILE_TO_UPLOAD: 'Arrastre un arquivo para cargalo',
            _HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'O importe da retención a conta non pode ser superior á base impoñible',
            _IMPOSSIBLE_TO_EXECUTE_THE_REQUIRED_OPERATION_CLAIM_RECEIPTS_TO_AUTHORIZE: 'Imposible executar a operación requirida: recibos de sinistros para autorizar',
            _IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Non se pode proceder: Usuario non autorizado a abrir un sinistro sobre unha póliza tecnicamente non cuberta',
            _INSERT_A_CSV_FILE: 'Inserir un arquivo .csv',
            _INSERT_POLICY_NUMBER_OR_PLATE: 'Inserir número de póliza ou matrícula',
            _INSERT_SUBJECT_SURNAME_OR_DENOMINATION: 'Inserir apelido ou denominación da parte',
            _INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT: 'Importe da factura distinto da suma de la base impoñible, a base impoñible exenta, o importe do fondo de cotizacións sociais, o importe do imposto sobre actos xurídicos documentados e o importe do IVE',
            _INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'O importe da factura debe ser superior a cero',
            _IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM: 'Imposible suprimir un documento xa cargado no sistema de xestión dos documentos',
            _MISSING_CLAIM_NUMBER_AT_LINE: 'Falta o número de sinistro na liña ',
            _MISSING_NOT_TAXABLE_AMOUNT_AT_LINE: 'Falta a base impoñible exenta na liña ',
            _MISSING_TAXABLE_AMOUNT_AT_LINE: 'Falta a base impoñible na liña ',
            _MISSING_TOTAL_UNIT_AMOUNT_AT_LINE: 'Falta o importe total por unidade na liña ',
            _N_NOT_CONSISTENT: 'N Os danos do vehículo non concordan cos datos da caixa negra',
            _NO_ACTIVITY_TO_SHOW: 'Non hai actividade que amosar',
            _NO_CHANGE_MADE: 'Sen cambios',
            _NO_CHAT: 'Sen chat',
            _NO_DOCUMENTS_TO_UPLOAD: 'Non hai documentos para cargar',
            _NO_NORMALIZED_ADDRESS_FOUND: 'Non se atopou ningún enderezo normalizado',
            _NO_RESULTS: 'Non hai resultados',
            _POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY: 'Póliza non conforme coa categoría de danos seleccionada',
            _PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM: 'Prema o botón de calcular antes de confirmar',
            _RESERVE_AMOUNT_NOT_CAPACIOUS_UPDATE_RESERVE_BEFORE: 'O importe da reserva non é elevado: actualice primeiro a reserva',
            _POLICY_NOT_COMPLIANT: 'Póliza non conforme coa categoría de danos seleccionada',
            _S_CONSISTENT: 'E os danos do vehículo concordan cos datos da caixa negra',
            _SAVING_ERROR: 'Erro de gardado',
            _SEARCH_ERROR: 'Erro de busca',
            _STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'O importe do imposto sobre actos xurídicos documentados non pode ser superior á base impoñible',
            _SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'A suma da base impoñible e da base impoñible exenta debe ser superior a cero',
            _SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma da base impoñible exenta dos datos distinta da base impoñible exenta da factura',
            _SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE: 'Suma da base impoñible dos datos distinta da base impoñible da factura',
            _SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT: 'Suma do importe unitario total dos datos distinta do importe da factura',
            _TAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'A base impoñible debe ser superior a cero',
            _THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE: 'O documento suprimirase de forma permanente. Desexa continuar?',
            _THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE: 'A póliza tamén cobre os danos materiais do seu vehículo',
            _TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST: 'O título e o nivel de autorización xa existen',
            _TOL_CLAIMS_MADE: 'Existen tipos de sinistros xestionados con sinistros realizados / póstumos: repetiranse os controis de validación técnica e administrativa no apartado de tipo de sinistro',
            _TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'O importe total unitario debe ser superior a cero',
            _UNABLE_TO_MAKE_THE_PAYMENT_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED: 'Imposible efectuar o pagamento: importe residual do límite superado',
            _UPDATE_SUCCESSFUL: 'Actualización correcta',
            _VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'O importe IVE non pode ser superior á base impoñible',
            _WARNING_YOU_ARE_EXCEEDING_THE_CEILING: 'Advertencia: está superando o límite máximo',
            _X_TO_BE_VERIFIED: 'X Coherencia dos danos cos datos da caixa negra por verificar',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET: 'Debe seleccionar polo menos un ben asegurado',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY: 'Debe seleccionar polo menos unha parte',
            _YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD: 'Necesita engadir un documento para executar a carga',
            _TYPE_OF_BODYWORK_IS_MANDATORY: 'O tipo de carrocería é obrigatorio',
            _NO_RES_FOUND: 'Non se atopou ningún resultado',
            _CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY: 'It is impossible to open a claim on an uncovered policy',
            _FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES: 'Fields marked in red are required or contain incorrect values',
            _INCORRECT_OR_MISSING_DATA: 'Incorrect or missing data',
            _THERE_ARE_TYPES_OF_LOSS_MANAGED_WITH_CLAIMS_MADE__POSTUMA: 'There are types of loss managed with claims made / postuma:',
            _TECHNICAL_AND_ADMINISTRATIVE_VALIDATION_CONTROLS_WILL_BE_REDONE_IN_THE_TYPE_OF_LOSS_SECTION: ' technical and administrative validation controls will be redone in the type of loss section',
            _CACHE_ERROR: 'Cache error',
            _ERROR_IN_INITIALIZATION_PROCEED_WITH_MANUAL_SELECTION: 'ERROR in initialization. Proceed with manual selection',
            _UPDATE_ERROR: 'Update error',
            _SEARCH_EXPERTS_ERROR: 'Search Experts Error',
            _SEARCH_REJECT_TYPES_ERROR: 'Search reject types error',
            _EXISTING_PES_ALERT: 'A PES/claim with same date occurred and policy is already present. Continue?',
            _UPDATE_RESERVE_SUCCESSFUL: 'Update reserve successful',
            _UPDATE_RESERVE_ERROR: 'Update reserve error',
            _HEIR_ALREADY_ADDED: 'Heir has already been added',
            _HEIR_DATE_INVALID: 'It is not possible to add fatal date for a subject chosen as an heir',
            _HEIR_IS_EQUAL_SUBJECT: 'The deceased subject cannot be added as a subject',
            _HEIR_INVALID: 'It is not possible to select a person with a fatal date as an heir',
            _HEIR_INVALID_FATAL_DATE: 'Heir removed due to death date, check heirs again',
            _ON_CONFIRMATION: 'Upon confirmation, the regularity of the policy will be checked based on the coverage selected',
            _YOU_CAN_INSERT_1_TO_2_ATTACHMENT: 'You can insert 1 to 2 attachments.',
            _ALLOWED_EXTENSION: 'Allowed extensions: txt, pdf, jpg.',
            _THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB: 'The size of an attachment cannot exceed 1 MB.',
            _COMPILING_LETTER_CONTENT: 'Compiling letter content',
            _OPENED_POST_LITIGATION: 'Opened post litigation',
            _PRESENCE_REQUEST_DAMAGE: 'Presence request damage',
            _WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT: 'Warning, the reserve has been reduced by the paid amount',
            _OF_WHICH_ATTORNEYS_FEES: 'Of which attorneys fees',
            _NO_BANK_ACCOUNT_FOR_THE_SUBJECT: 'No bank account for the subject',
            _NO_IBAN_PRESENT_OF_THE_PAYEE: 'No iban present of the payee',
            _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
            _INSERT_DATE: 'Insert date',
            _CLAIM_EXCESS_POLICY_VALUES: 'Claim excess policy values',
            _CLAIM_EXCESS_RESIDUE: 'Claim excess residue',
            _CLAIM_EXCESS_ERODED_AMOUNT: 'Claim excess eroded amount',
            _EXCESS_AGGREGATE_POLICY_VALUES: 'Excess aggregate policy values',
            _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue',
            _EXCESS_AGGREGATE_ERODED_AMOUNT: 'Excess aggregate eroded amount',
            _RECEIPT_ACCEPTED: 'Receipt accepted',
            _RECEIPT_REJECTED: 'Receipt rejected',
            _REASON_REJECTED: 'Reason rejected',
            _ONE_PAYEE_IS_REQUIRED: 'One payee is required',
            _ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED: 'One payee and one beneficiary are required',
            _ONE_PRIMARY_BENEFICIARIES_REQUIRED: 'One primary beneficiary required',
            _ONE_PRIMARY_PAYEE_REQUIRED: 'One primary payee required',
            _TAX_CODE__VAT_NUMBER_MISSING: 'Tax code/Vat number missing',
            _PAYEE_MUST_BE_ADULT: 'Payee must be an adult',
            _PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION: 'Payee not regstered, save subject with subject managment function.',
            _BEFORE_DELETE_ADD_NEW_BENEFICIARY: 'Before delete add new beneficiary',
            _BEFORE_DELETE_ADD_NEW_PAYEE: 'Before delete add new payee',
            _THE_CANCELLATION_DATE_IS_NOT_VALID: 'The cancellation date is not valid',
            _SELECT_REASON_CANCELLATION: 'Select cancellation reason',
            _METHOD_NOT_IMPLEMENTED: 'Method not implemented',
            _THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE: 'There is a payment withou associated documents, continue?',
            _SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED: 'Subject with incomplete tax data, unable to proceed',
            _OPERATION_NOT_AVAILABLE: 'Operation not available',
            _FEE_DATA_MISSING: 'Fee data missing',
            _MANDATORY_DATA_MISSING: 'Mandatory data missing',
            _SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS: 'Select the definition',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS: 'You have to select at least one type of loss',
            _THE_AMOUNT_FOR_THE_REASON_SELECTED: 'The amount for the reason selected',
            _INCOMPLETE_ADDRESS: 'Incomplete address',
            _INCOMPLETE_DATA: 'Incomplete data',
            _UIC_NOT_VALID__MAX_3_CHARACTER: 'Uic not valid, max 3 character',
            _FOREIGN_BANK_ACCOUNT_INVALID: 'Foreign bank account not valid',
            _IBAN_INVALID: 'Iban not valid',
            _INSERT_A_VALID_ITALIAN_IBAN: 'Insert a valid italian iban',
            _SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE: 'Special characters are not allowed in iban code',
            _RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE: 'Reserve amount not capacious, update reserve before',
            _IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossible to execute required operation,claim receipts to authorize',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT: 'Attention,the payment exceeds the residual amount of the limit',
            _SELECT_REASON_REVERSAL: 'Select reason reversal',
            _ENTER_NOTES: 'Enter notes',
            _THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0: 'The total amount cannot be greater than or equal to 0 ',
            _THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE: 'The credit note cannot be higher than the original invoice',
            _INVOICE_TYPE_REQUIRED: 'Invoice type required',
            _FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED: 'Fee number and fee date are required',
            _THE_REVERSAL_DATE_IS_NOT_VALID: 'The reversal date is not valid',
            _ERROR_CODE: 'Error code',
            _ERROR: 'Error',
            _SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO: 'Specify event/assignmentNumber/date from-to',
            _SPECIFY_ONLY_CLAIM_NUMBER: 'Specify only claim number',
            _SPECIFY_CRITERIA: 'Specify a criteria',
            _SPECIFY_EVENT: 'Specify event',
            _SPEFCIFY_CLAIM_NUMBER: 'Specify claim number',
            _ALERT_NEW_DOC: 'Alert new doc',
            _EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH: 'Existing Requests for damages to match',
            _NO_REQUEST_DAMAGES: 'No hay daños',
            _REQUEST_FOR_DAMAGES_RECEIPT_DATE: 'Request for damages receipt date',
            _GENERIC_ERROR_ON_SAVE_QUESTIONNARIE: 'Error genérico al guardar el cuestionario.',
            _QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL: 'El cuestionario se actualizó correctamente',
            _QUESTIONNAIRE_MODIFICATION_CANCELLED: 'Modificación del cuestionario cancelada',
            _ENTER_A_VALID_IBAN: 'Enter a valid Iban',
            _INSERT_A_VALID_SPANISH_IBAN: 'Enter a valid Spanish Iban',
            _AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE: 'An error occurred while adding the note',
            _AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION: 'An error occurred while deleting the expert association',
            _AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST: 'An error occurred while processing the request',
            _ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION: 'Are you sure you want to delete the trustee association?',
            _CHARACTER_LIMIT_EXCEEDED: 'Charcater limit exceeded',
            _EXPERT_ASSOCIATION_DELETED_CORRECTLY: ' Expert association deleted correctly',
            _EXPERT_LINK_ADDED_WITH_SUCCESS: 'Expert link added with success',
            _FISCAL_CODE__VAT_UNCORRECT: 'Fiscal Code/Vat Uncorrect',
            _FISCAL_CODE_UNCORRECT: 'Fiscal code uncorrect',
            _IMPUTE_THE_NAME_OR_THE_FISCAL_CODE: 'Impute the name or the fiscal name',
            _IN_ERROR: 'In error',
            _INCOMPLETE_COMPILATION: 'Incomplete compilation',
            _NO_NOTES_FOR_THIS_COMPENSATION: ' No notes for this compensation',
            _OPERATION_PROPERLY_TERMINATED: ' Operation propely terminated',
            _SELECT_A_CRITERIA: 'Select a criteria',
            _SELECT_A_USER_BEFORE_CONFIRMING: 'Select a user before confirming',
            _SELECT_AT_LEAST_ONE_REASON: 'Select at least one reason',
            _SPECIFY_THE_TYPE_ASSET: 'Specify the type asset',
            _VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT: 'Value at least one field between: name, surname, business name, contractor fiscal code, VAT code, code independent expert',
            _VAT_UNCORRECT: 'Vat code uncorrect',
            _FILE_IS_EMPTY: 'File is empty',
            _MAXIMUM_FILE_SIZE_EXCEEDED: 'Maximum file size exceeded',
            _NOT_GENERATED_FOR_CHOICE: 'Not generated for choice',
            _GENERIC_ERROR_SAVE_ACTOR: 'GL_ES_Error encountered while saving new actor',
            _SAVE_NEW_ACTOR: 'GL_ES_Save new actor',
            _SELECT_THE_DOCUMENT_TO_ATTACH: 'Select the documents to attach to the payment',
            _KO_UPLOAD_DOC: 'An error occurred while uploading the document',
            _CANCELLATION_DIALOGUE: 'GL_ES_Are you sure you want to cancel this dialogue?',
            _CONFIRM_DEPRECATE_ASSET_EDIT_ACTOR: 'GL_ES_Cambiando il ruolo verranno deprecate delle causali associate ad un bene non disponibile per il ruolo selezionato. Si è sicuri di voler proseguire?',
            _EXIT_FROM_CAI_CONFIRM: 'GL_ES_Procedendo verrai riportato nella homepage perdendo i dati. Procedere?',
            _TASK_ASSIGNED_TO_USER: 'Task assigned to the user'
        },
        _TITLE_: {
            _QUESTIONNAIRE_TITLE: 'Cuestionario',
            _SELECT_POLICY: 'Seleccionar póliza',
            _OCCURRENCE_DATA: 'Datos do incidente',
            _DATA_COLLECTION: 'Recollida de datos',
            _SUMMARY: 'Resumo',
            _DIALOGUE_HANDLER_LIST: 'Dialogue List',
            _DIALOGUE_HANDLER_NEW: 'GL_ES_New Demand',
            _DIALOGUE_HANDLER_SUMMARY: 'Resumen Diálogo',
            _ADDRESS_MANAGEMENT: 'GL_ES_Address management'
        },
        _DATA_FORWARDING_CDL: 'Transmisión de datos a CHC',
        _INTERMEDIARY: 'Intermediario',
        _MASTER_POLICY_NUMBER: 'Número de póliza base',
        _APPLICATION_NUMBER: 'Número de solicitude',
        _COMPLAINT_STATE: 'Estado da denuncia',
        _PLATE__CHASSIS: 'Chapa / Chasis',
        _ACCOUNTING_COVERAGE: 'Cobertura contable',
        _LEGAL_GUARDIAN_ROLE: 'Papel de titor legal',
        _CAR_BODY_DETAIL: 'Información da carrocería',
        _START_DATE_OF_COLLABORATION: 'Data de inicio da colaboración',
        _END_DATE_COLLABORATION: 'Data de remate da colaboración',
        _HOUSE_NUMBER: 'Número de casa',
        _MUNICIPALITY: 'Municipio',
        _NEW_BODYWORK: 'Carrocería nova',
        _SUBJECT_TYPE: 'Tipo de parte',
        _CODE: 'Código',
        _BUSINESS_NAME: 'Razón social',
        _FISCAL_CODE: 'Código fiscal',
        _VAT_CODE: 'Código de IVA',
        _TYPE_OF_BODYWORK: 'Tipo de carrocería',
        _INJURY_PHISICAL: 'Lesión física',
        _CAUSE_OF_INJURY: 'Causa da lesión',
        _UNCODABLE: 'Incodificable',
        _MULTIPLE: 'Múltiple',
        _NATURE_OF_INJURY: 'Natureza da lesión',
        _INJURY_LOCATION: 'Localización da lesión',
        _FURTHER_INJURY_INDICATOR: 'Indicador de lesión adicional',
        _PROFESSIONAL_QUALIFICATION: 'Cualificación profesional',
        _PERCENTAGE_OF_PERMANENT_DISABILITY: 'Porcentaxe de incapacidade permanente',
        _TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE: 'Días de incapacidade temporal sen secuelas',
        _FATALITY_DATE: 'Data da morte',
        _FIRST_AID_COUNTRY: 'País de primeiros auxilios',
        _FIRST_AID_PLACE: 'Lugar de primeiros auxilios',
        _HEALTHCARE_PROVIDERS: 'Provedores de asistencia sanitaria',
        _BIOLOGICAL_DAMAGE: 'Dano biolóxico',
        _SUBROGATION_SOCIAL_ASS_EMPLOYER: 'Subrogación asistencia social/Empresario',
        _DOCTOR: 'Doutor',
        _SEARCH: 'Buscar',
        _SKULL: 'CRANIO',
        _SPINE_CERVICAL_COLUMN: 'COLUMNA VERTEBRAL-CERVICAL',
        _LEFT_SHOULDER: 'OMBREIRO ESQUERDO',
        _RIGHT_SHOULDER: 'OMBREIRO DEREITO',
        _SPINE_THORACIC_COLUMN: 'COLUMNA VERTEBRAL-TORÁCICA',
        _SPINE_LUMBAR_COLUMN: 'COLUMNA VERTEBRAL-LUMBAR',
        _SPINE_SACRUS_AND_COCCYX: 'COLUMNA VERTEBRAL-SACRO E CÓCCIX',
        _LEFT_ANKLE: 'TORNECELO ESQUERDO',
        _RIGHT_ANKLE: 'TORNECELO DEREITO',
        _RIGHT_EAR: 'ORELLA DEREITA',
        _RIGHT_EYE: 'OLLO DEREITO',
        _LEFT_EYE: 'OLLO ESQUERDO',
        _LEFT_EAR: 'ORELLA ESQUERDA',
        _FACE: 'CARA',
        _NECK: 'PESCOZO',
        _RIGHT_CHEST_WALL: 'PAREDE TORÁCICA DEREITA',
        _LEFT_CHEST_WALL: 'PAREDE TORÁCICA ESQUERDA',
        _RIGHT_ARM: 'BRAZO DEREITO',
        _THORACIC_ORGANS: 'ÓRGANOS TORÁCICOS',
        _LEFT_ARM: 'BRAZO ESQUERDO',
        _RIGHT_ELBOW: 'CÓBADO DEREITO',
        _ABDOMINAL_ORGANS: 'ÓRGANOS ABDOMINAIS',
        _LEFT_ELBOW: 'CÓBADO ESQUERDO',
        _RIGHT_FOREARM: 'ANTEBRAZO DEREITO',
        _ABDOMINAL_WALL: 'PAREDE ABDOMINAL',
        _LEFT_FOREARM: 'ANTEBRAZO ESQUERDO',
        _PELVIS___RIGHT_HIP: 'PELVE - CADRIL DEREITO',
        _UROGENITAL_ORGANS: 'ÓRGANOS UROXENITAIS',
        _PELVIS___LEFT_HIP: 'PELVE - CADRIL ESQUERDO',
        _RIGHT_WRIST: 'PUÑO DEREITO',
        _LEFT_WRIST: 'PUÑO ESQUERDO',
        _RIGHT_HAND: 'MAN DEREITA',
        _RIGHT_THIGH: 'COXA DEREITA',
        _LEFT_THIGH: 'COXA ESQUERDA',
        _LEFT_HAND: 'MAN ESQUERDA',
        _RIGHT_KNEE: 'XEONLLO DEREITO',
        _LEFT_KNEE: 'XEONLLO ESQUERDO',
        _RIGHT_LEG: 'PERNA DEREITA',
        _LEFT_LEG: 'PERNA ESQUERDA',
        _RIGHT_FOOT: 'PÉ DEREITO',
        _LEFT_FOOT: 'PÉ ESQUERDO',
        _REQUIRED: 'Requirido',
        _EXTERNAL_CLAIM_NUMBER: 'Número de sinistro externo',
        _POTENTIAL_CLAIM_NUMBER: 'Número de sinistro potencial',
        _ALIGN_POLICY: 'Aliñar a póliza',
        _ACTIONS: 'Accións',
        _HEIR: 'Heir',
        _HEIR_ADD: 'Add heir',
        _HEIRS: 'Heirs',
        _DATE_ENTRY_FROM: 'Date entry from',
        _ELABORATION_DATE_FROM: 'Elaboration date from',
        _EXPIRY_DATE_FROM: 'Expiry date from',
        _CLAIM_DATE_FROM: 'Claim date from',
        _FUNCTION: 'Function',
        _DAMAGE_MATCH: 'Damage Match',
        _TAX_ID_CODE: 'Tax Id Code',
        _COMPLAINT: 'Complaint',
        _NOT_ASSOCIATED: 'Not Associated',
        _CTP_COMPANY: 'Ctp Company',
        _US_CAR_LICENSE_PLATE: 'License Plate Us',
        _CTP_CAR_LICENSE_PLATE: 'License Plate Ctp',
        _ACTIVE_TASK: 'Active Task',
        _ERROR_MESSAGE: 'Error Message',
        _SIMILAR_FLAG: 'Similar Flag',
        _FILTER: 'Filter',
        _NOT_ASSOCIATED_DIALOGUES: 'Not Associated Dialogues',
        _DIALOGUE_DATE_START: 'Dialogue Date Start',
        _END_DATE: 'End Date',
        _SX_DATE: 'Sinister Date',
        _DIALOGUE: 'Dialogue',
        _EXTERNAL_KEY_DAMAGED: 'External Key Damaged',
        _INTERNAL_KEY_DEF: 'Internal Key definitive',
        _INTERNAL_KEY_TEMP: 'Internal Key temporary',
        _INTERNAL_KEY_DAMAGED_DEF: 'Internal Key Damaged definitive',
        _INTERNAL_KEY_DAMAGED_TEMP: 'Internal Key Damaged temporary',
        _INTERNAL_KEY_DAMAGED: 'Internal Key Damaged',
        _US_TYPE_PLATE: 'Type Plate Us',
        _CTP_TYPE_PLATE: 'Type Plate Ctp',
        _IDENTIFIER_UNIQUE_CODE: 'Identifier Unique Code',
        _PARAMETERIZED_DIALOGUE_DATA: 'Parameterized Dialogue Data',
        _REGULATORY_EXPIRY_DATE: 'Regulatory Expiry Date',
        _LAST_UPDATE_DATE: 'Last User Date',
        _USER_UPDATE: 'User Update',
        _ACTIVITY_TYPE: ' Activity Type',
        _PARAMETERS_UPPERCASE: 'PARAMETERS',
        _REQUEST_NOTE: 'Request Note',
        _RESPONSE_NOTE: 'Response Note',
        _DOCUMENTS_UPPERCASE: 'DOCUMENTS',
        _DISCARDED_UPPERCASE: 'DISCARDS',
        _CAUSAL: 'Causal',
        _EXPIRATION: 'Expiration',
        _SENDED: 'Sended',
        _START_STATUS: 'Start Status',
        _DOCUMENT_TYPE: 'Document Type',
        _DOCUMENT_TYPE_FLUX: 'Document Type (Flux)',
        _CODE_ERROR: 'Code Error',
        _INPUT: 'Input',
        _OUTPUT: 'Output',
        _RESPONSE_NOTES: 'Response Notes',
        _DOCUMENTS_REQUIRED: 'Documents required for managing the selected answer',
        _SELECTED_DOCUMENTS: 'Select the documents to associate with the response by entering the flow document type for each',
        _ACTOR_INVOLVED: 'Actor Involved',
        _REASON: 'Reason',
        _DOCUMENT_TYPE_FLOW: 'Document Type Flow',
        _MANDATORY: 'Mandatory',
        _MATTER: 'Matter',
        _SUB_CATEGORY: 'Sub-Category',
        _LST_MSG_CODE: 'Mensaje',
        _LST_MES_REQ_DATA: 'Request Date (LST. MS.)',
        _LST_MES_REG_EXP: 'Expiry Date (LST. MS.)',
        _START_DATE: 'Start Date',
        _INTERNAL_KEY: 'Internal Key',
        _LST_RESP_MES_CODE: 'Respuesta',
        _LST_MSG_DATE: 'Last Message Date',
        _LST_MSG_INT_EXP: 'Internal Expiry Date (LST. MS.)',
        _EXT_KEY_IDEF_DAM: 'External Key Identify Damage Party',
        _ONGOING: 'ONGOING',
        _EVADE: 'EVADE',
        _INSURANCE_CODE_US: 'Company',
        _ACTOR_NOMINATIVE: 'Actor Nominative',
        _DETAILS_CLAIMS_DATA: 'Claims Data Detail',
        _CONSOLIDATE: 'Consolidate',
        _BALANCE: 'Balance',
        _VALIDATION_AND_CONSOLIDATION: 'Validation&Upload',
        _PAYMENT_MANAGE: 'Payment Manage',
        _FORFAIT_MANAGE: 'Forfait Manage',
        _YEAR: 'Year',
        _MONTH: 'Month',
        _JANUARY: 'January',
        _FEBRUARY: 'February',
        _MARCH: 'March',
        _APRIL: 'April',
        _MAY: 'May',
        _JUNE: 'June',
        _JULY: 'July',
        _AUGUST: 'August',
        _SEPTEMBER: 'September',
        _OCTOBER: 'October',
        _NOVEMBER: 'November',
        _DECEMBER: 'December',
        _INCAPACITATED: 'Incapacitated',
        _TYPES_OF_LOSS: 'Types of loss',
        _RISK_DATA: 'Risk data',
        _COVERTURE_DATA: 'Coverture data',
        _RULES_FOR_UPLOADING_DOCUMENTS: 'Rules for uploading documents:',
        _OCCURRENCE_HOUR: 'Occurence hour',
        _DATE_INTERMEDIARY: 'Date intermediary',
        _TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED: 'Type of authorities possibly involved',
        _CATASTROPHIC_EVENT_DESCRIPTION: 'Catastrophic event description',
        _OPENING_MODE: 'Claim opening mode',
        _PARTY: 'Party',
        _ASSET_INSURED: 'Asset insurance',
        _NEW_DAMAGED: 'New damaged',
        _TYPE_OF_LOSS: 'Daño causal',
        _NOMINATIVE: 'Nominative',
        _NO_RESULT: 'No results.',
        _NEW_ASSET: 'New asset',
        _ASSET_TYPE: 'Asset type',
        _TYPE: 'Type',
        _FOUND: 'Found',
        _COVERAGES_DETAIL: 'Coverages detail',
        _ADD_A_DAMAGED: 'Add a dameged',
        _PHYSICAL_PERSON: 'Physical person',
        _POLICY_DATA: 'Policy data',
        _ADMINISTRATIVE_VALIDITY: 'Administrative Validity',
        _LIST_OF_RELATED_CLAIMS: 'List of related claims',
        _CF__PIVA: 'CF/PIVA',
        _REFERENCE: 'Reference',
        _DOL: 'DOL',
        _DON: 'DON',
        _MAIN: 'Main',
        _ORIGINAL_EFFECT_DATE: 'Original Effect date',
        _NO_POLICY_TO_SHOW: 'No policy to show',
        _ASSIGNMENT_DATE: 'Assignment Date',
        _STATUS: 'Estado',
        _FILTERS: 'Filters',
        _CLAIMS_HANDLER: ' Claims Handler',
        _COMPANY: 'Compañía',
        _TECHNICAL_STATE: 'Technical State',
        _RESERVED: 'Reserved',
        _INVOICE_DATE: 'Invoice Date',
        _AMOUNT: 'Amount',
        _REJECT_TYPE: 'Reject Type',
        _UPLOAD_DETAILS: 'Upload Details',
        _NUMBER: 'Number',
        _ACTOR: 'Actor',
        _UPLOAD: 'Upload',
        _EXPERT_INVOICE: 'Expert Invoice',
        _TAX_CODE__VAT_NUMBER: 'Tax Code/VAT Number',
        _MANUAL_NOTE: 'Manual note',
        _INSERT_A_NEW_MANUAL_NOTE: 'Insert a new manual note',
        _COMMENTS_FORM: 'Comments form',
        _THERE_ARE_NO_COMMENTS: 'There are no comments',
        _NO_PERMISSION: 'No permission',
        _CANCELLATION: 'Cancellation',
        _COMMENT: 'Comment',
        _TOTAL_ASSIGNMENT_EXPENSE_AMOUNT: 'Monto total de gastos de asignación',
        _TOTAL_RESERVE_FOR_LITIGATION: 'Reserva total para litigios',
        _FROM: 'Desde',
        _TO: 'Para',
        _QUICK_FILTERS: 'Quick filters',
        _ASCENDING_ORDER: 'Ascending order',
        _DESCENDING_ORDER: 'Descending order',
        _MOVEMENTS: 'Movimientos',
        _ACCESSES: 'Accesses',
        _ACTIVITY: 'Activity',
        _RESERVES: 'Reserves',
        _TITLES: 'Titles',
        _STATE: 'Estado',
        _ASSIGNEE_GROUP: 'Asignar grupo',
        _ASSIGNEE_USER: 'Asignar usuario',
        _RUN_DATE: 'Data de execución',
        _PRIORITY: 'Prioridade',
        _SUSPENDED: 'Suspended',
        _COMPLETION_DATE: 'Completion date',
        _ESCAPE_METHODS: 'Escape methods',
        _RUN_ACTIVITY: 'Run Activity',
        _TAKE_CHARGE_ACTIVITY: 'Take charge activity',
        _REASSESS_TASK: 'Reassess task',
        _NO_SUGGESTIONS: 'No suggestions',
        _CRITERIA: 'Criteria',
        _ASSIGNMENT: 'Assignment',
        _EVENTS: 'Events',
        _R_PER: 'Return assignment (R-PER)',
        _A_COM: 'Communication (A-COM)',
        _S_RIF: 'Refuse assignment (S-RIF)',
        _R_FAT: 'Invoice  (R-FAT)',
        _TRANSMISSION_LIST: 'Trasmission list',
        _FAILED_TRANSMISSION_LIST: 'Failed trasmission list',
        _STARTER_EVENT: 'Evento starter',
        _REQUEST: 'Request',
        _ID: 'Id',
        _WS: 'WS',
        _XML: 'Xml',
        _RESPONSE: 'Response',
        _DETAIL: 'Detail',
        _VIEW_DETAIL_BLOW: 'View detail blow',
        _INSERT_DATE: 'Insert date',
        _ELABORATION_DATE: 'Elaboration date',
        _INSUFFICIENT_PERMISSIONS: 'Insufficient Permissions',
        _CLAIM: 'Reclamación',
        _POTENTIAL_CLAIM_INQUIRY: 'Investigación de reclamación potencial',
        _VALIDATE: 'Validate',
        _INVALIDATE: 'Invalidate',
        _MODIFY: 'Modify',
        _SUBCATEGORY: 'Subcategory',
        _RECEPTION_CHANNEL: 'Reception channel',
        _FORMAT: 'Format',
        _SIZE: 'Size',
        _NUMBER_OF_PAGES: 'Number of pages',
        _RECEIIPT_DATE: 'Receipt date',
        _USER: 'User',
        _SUBTITLE: 'SubTitle',
        _DOC_KEY: 'Doc key',
        _POSTALIZATION_RESULT: 'Postalization result',
        _POSTALIZATION_DATE: 'Postalization date',
        _SHIPPING_TYPE: 'Shipping type',
        _VIEW_BY_CATEGORIES: 'View by categories',
        _UPLOAD_DATE: 'Upload date',
        _SUBCATEGORY_DEPRECATED: ' Subcategory deprecated',
        _CATEGORY_DEPRECATED: ' Category deprecated',
        _HISTORY: 'History',
        _OPERATIONS: 'Operations',
        _EDIT_DOCUMENT: 'Edit document data',
        _CHANGE_PARTY: 'Change party',
        _SELECT_CATEGORY: 'Select category',
        _SELECT_SUBCATEGORY: 'Select subcategory',
        _PEOPLE: 'People',
        _EXTERNAL_VISIBILITY: 'External visibility',
        _VEICHLE: 'Veichle',
        _VALIDATED: 'Validate',
        _NO_VALIDATE: 'No validate',
        _TECHNICAL_STATUS: 'Estado técnico',
        _RESERVE_AMOUNT: 'Cantidad de Reserva',
        _TECHNICAL_REACTIVATION_OPENING_DATE: 'Reactivación/fecha de apertura técnica',
        _ACCOUNTING_REACTIVATION_OPENING_DATE: 'Fecha de apertura de reactivación contable',
        _TECHNICAL_CLOSING_DATE: 'Fecha de cierre técnico',
        _NEW_QUESTIONNAIRE_DATE: 'New Questionnaire Date',
        _ROLE: 'Role',
        _CODE_CHAIN: 'Code chain',
        _EXTERNAL_KEY: 'External Key',
        _CODE_ACTION: 'Code action',
        _DATA_ENTRY: 'Data entry',
        _OUTCOME: 'Outcome',
        _INSERTED: 'Inserted',
        _JSON_N: 'Json N°',
        _LAST_ELABORATION: 'Last elaboration',
        _CANCELLATION_REASON: 'Cancellation Reason',
        _ASSOCIATED_ENTITY: 'Associated Entity',
        _EXECUTIONS: 'Executions',
        _JSON_OK_N: 'Json OK n.',
        _JSON_KO_N: 'Json KO n.',
        _RERUN: 'Rerun',
        _RESERVATION_DATE: 'Reservation date',
        _IN_PROGRESS: 'In progress',
        _CLOSED: 'Closed',
        _FORCED_CLOSURE: 'Forced closure',
        _NOT_STOPPED: 'Not stopped',
        _STOPPED_SUCCESSFULLY: 'Stopped successfully',
        _RERUN_RESERVED: 'Rerun reserved',
        _RERUN_FAILURE: 'Rerun failure',
        _RESULTS: 'Results',
        _TITLE: 'Title',
        _INSERTION: 'Insertion',
        _ELABORATION: 'Elaboration',
        _REQUEST_TYPE: 'Request type',
        _ELABORATION_REQUEST_DATE: 'Elaboration request date',
        _INPUT_PARAMETERS: 'Input parameters',
        _JSON: 'Json',
        _JSON_EXTERNAL_KEY: 'Json External key',
        _CREATION_FAILED: 'Creation failed',
        _REQUEST_CREATED: 'Request created',
        _RESERVATION: 'Reservation',
        _CREATION_ERROR: 'Creation error',
        _TOO_MANY_JSON_WERE_UPLOADED: 'Too many json were uploaded',
        _COMPANY_SETTLEMENT_AMOUNT: 'Company settlement amount',
        _PAYEE: 'Payee',
        _PAYMENT_TYPE: 'GL-ES_Payment type',
        _PAYMENT_DATE: 'GL-ES_Payment date',
        _BENEFICIARIES: 'GL-ES_Beneficiaries',
        _ADD_BENEFICIARY: 'Add beneficiary',
        _PAYEES: 'Payees',
        _ADD_PAYEE: 'Add Payee',
        _CALCULATE: 'Calculate',
        _REASON_CANCELLATION: 'Cancellation Reason',
        _C__O: 'C/O',
        _NO_DOCUMENTS: 'No Documents',
        _FEE_DATA: 'Fee Data',
        _FEE_NUMBER: 'Fee Number',
        _FEE_DATE: 'Fee Date',
        _IVA_AMOUNT: 'Iva amount',
        _IRPF_AMOUNT: 'IRPF amount',
        _WITH_HOLDING_AMOUNT: 'With holding amount',
        _NET_AMOUNT: 'Net amount',
        _CASH_AMOUNT_1: 'Cash amount 1',
        _CASH_AMOUNT_2: 'Cash amount 2',
        _SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED: 'Settlement blocked: % same invoice already reimbursed to insured',
        _PAID_AMOUNT: 'Paid Amount ',
        _PERCENTAGE_OF_THE_TOTAL: '% of the total ',
        _ALREADY_INSERT: 'Already insert',
        _REMAINING_AMOUNT: ' Remaining amount',
        _EVALUATION: ' Evaluation',
        _PAYMENT_DATA: 'Payment data',
        _CALCULATION_OF_THE_INSURED_FEE: 'Calculation of the insured fee',
        _BENEFICIARIES_AND_PAYEES: 'Beneficiaries and payees',
        _METHODS_OF_PAYMENT: 'Methods of Payment',
        _LETTERS_MANAGEMENT: 'Letters managment',
        _RECEIPT_SUMMARY: 'Receipt summary',
        _SETTLEMENT_SUMMARY: 'Settlement summary',
        _PARTY_ROLE: 'Party role',
        _OPERATION_USER: 'Operation user',
        _QUITTANCE_DATE: 'Quittance date',
        _CURRENCY_DATE: 'Currency date',
        _PAYMENT_TRANSACTION_TYPE: 'Payment transaction type',
        _RECEIPT_TYPE: 'Receipt type',
        _MANAGMENT_PAYMENT: 'Managment payment',
        _SEPARATE_RECEIPT: 'Separate receipt',
        _CURRENCY: 'Currency',
        _DUE_DATE: 'Due date',
        _PROCEDURE_CODE: 'Procedure code',
        _AMOUNT_QUOTA: 'Amount quota',
        _OPEN_DETAIL: 'Open detail',
        _FORFAIT_TYPE: 'Forfait type',
        _AMOUNT_QUOTA_FORFAIT: 'Amount quota forfait',
        _FORFAIT: 'Forfait',
        _IMPORT: 'Import',
        _METHOD_OF_PAYMENT: 'Method of payment',
        _BANK_TRANSFER_DESCRIPTION: 'Bank Transfer Description',
        _INCOMPLETE_ADDRESS: 'Incomplete address',
        _NOT_GENERATED_FOR_MISSING_DATA: 'Not generated for missing data',
        _GENERATED: 'Generated',
        _RETURN_OF_RECEIPT: 'Return of receipt',
        _RECEIPT_STATUS: 'Receipt status',
        _RECEIPT_DATE: 'Receipt date',
        _REASON_CANCEL_RECEIPT: 'Reason cancel receipt',
        _RECEIPT_NOTES: 'Receipt notes',
        _RETURN: 'Return',
        _BENEFICIARY: 'Beneficiary',
        _SENDING_LETTERS: 'Sending letters',
        _MANAGEMENT_PAYMENT: 'Managment payment',
        _PAYABILITY: 'Payability',
        _WAITING: 'Waiting',
        _SUBSTATUS: 'Substatus',
        _PAYMENT_DEFINITION: 'Payment Definition',
        _RESERVED_AMOUNT: 'Reserved Amount',
        _PARTIAL: 'Partial',
        _CHC: 'CHC',
        _LOAD_DATE: 'Load date',
        _OFFER_DATE: 'Offer date',
        _IBAN: 'Iban',
        _IBAN_ON_SUBJECT: 'Iban on subject',
        _ACCOUNT_HOLDER: 'Account holder',
        _BANK_DESCRIPTION: 'Bank description',
        _BANK_ADDRESS: 'Bank address',
        _SWIFT: 'Swift',
        _FOREIGN_BANK_ACCOUNT: 'Foreign Bank Account',
        _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
        _BIC: 'BIC',
        _UIC: 'UIC',
        _BANK: 'Bank',
        _LEGAL_OPPONENT: 'Legal opponent ',
        _REGISTER_SUBJECT: 'Register subject',
        _OPEN_REGISTER: 'Open register',
        _SETTLEMENTS_LIST: 'Settlements list',
        _RECEIPTS_LIST: 'Receipts list',
        _COMPENSATIONS_LIST: 'Compensation list',
        _APPOINTMENTS_LIST: 'Appointments list',
        _TYPOLOGIES_SETTLEMENT_LIST: 'Typologies settlement list',
        _ACCESSORY_CHECKS: 'Accessory checks',
        _FORCING_APPLY: 'Forcing apply',
        _ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO: 'Administrative validity overidden on policy no',
        _EFFECTIVE_DATE_OF_COLLECTION: 'Effective date of collection',
        _EXPIRY_DATE_OF_COLLECTION: 'Expiry date of collection',
        _DATE_OF_COLLECTION: 'Date of collection',
        _CONFIRM_RESPONSIBILITY: 'Confirm responsibility',
        _PERCENTAGE_INSURED_LIABILITY: 'Percentage insured liability',
        _REASON_TO_FORCE_LIABILITY_PERCENTAGE: 'Reason to force liability percentage',
        _COUNTERPART_VEHICLE: 'Counterpart vehicle',
        _CHASSIS__PLATE_NUMBER: 'Chassis plate number',
        _LIABILITY_PERCENTAGE: 'Liability percentage',
        _HISTORICAL_EROSION: 'Historical erosion',
        _INPUT_AMOUNT: 'Input amount',
        _INSURED_SETTLEMENT_AMOUNT: 'Insured settlement amount',
        _CLAIM_EXCESS: 'Claim excess ',
        _DEDUCTIBLE: 'Deductible',
        _EXCESS_AGGREGATE: 'Excess aggregate',
        _SIR: 'Sir',
        _LIMIT: 'Limit',
        _FIRST_SIR: 'First sir',
        _SECOND_SIR: 'Second sir',
        _ADVANCE: 'Advance',
        _SIR_ADVANCED_BY_COMPANY_SOMETIMES: 'Sir advanced by company sometimes',
        _CAP_INDICATOR: 'Cap indicator',
        _CAP_AMOUNT: 'Cap amount',
        _EXCESS_AMOUNT: 'Excess amount ',
        _FIRST_SIR_POLICY_VALUES: 'First sir policy values ',
        _FIRST_SIR_RESIDUE: 'First sir residue ',
        _FIRST_SIR_ERODED_AMOUNT: 'First sir eroded amount ',
        _SIR_AMOUNT: 'Sir amount',
        _DEDUCTIBLE_POLICY_VALUES: 'Deductible policy values',
        _DEDUCTIBLE_RESIDUE: 'Deductible residue',
        _DEDUCTIBLE_ERODED_AMOUNTS: 'Deductible eroded amounts',
        _PERCENTAGE_AMOUNT: 'Percentage amount',
        _DEDUCTIBLE_AMOUNT_RESIDUE: 'Deductible amount residue',
        _CALCULATED_AMOUNT: 'Calculated amount',
        _MAXIMUM_AMOUNT: 'Maximum amount',
        _MINIMUM_AMOUNT: 'Minimum amount',
        _MAXIMUM_LEVELS_POLICY_VALUES: 'Maximum levels policy values',
        _MAXIMUM_LEVELS_RESIDUE: 'Maximum levels residue',
        _MAXIMUM_LEVELS_ERODED_AMOUNTS: 'Maximum levels eroded amounts',
        _RETURN_DATE: 'Return date ',
        _SETTLEMENT_REVERSAL: 'Settlement reversal ',
        _REVERSAL_DATE: 'Reversal date ',
        _REASON_REVERSAL: 'Reason reversal ',
        _PAYMENT_NUMBER: 'Payment number ',
        _PAYMENT_SAVING_SUMMARY: 'Payment saving summary',
        _ACTIVATED: 'Activated',
        _SETTLEMENT_NUMBER: 'Settlement number ',
        _ISSUED_CORRECTLY: 'Issued correctly ',
        _RISK: 'Risk ',
        _POLICY_ID: 'Policy ID ',
        _CLAIM_EXCESS_POLICY_VALUE: 'Claim excess policy value',
        _CLAIM_CAP_AMOUNT: 'Claim cap amount ',
        _CLAIM_EXCESS_AMOUNT: 'Claim excess amount ',
        _POLICY_ID_END_DATE: 'Policy id end date ',
        _POLICY_ID_START_DATE: 'Policy id start date',
        _CLAIM_CAP_INDICATOR: 'Claim cap indicator ',
        _CLAIM_EXCESS_ADVANCE: 'Claim excess advance ',
        _CLAIM_EXCESS_RESIDUE: 'Claim excess residue ',
        _CLAIM_EXCESS_LIQUIDATOR_ADVANCE: 'Claim excess liquidator advance ',
        _CLAIM_EXCESS_AMOUNT_ADVANCE: 'Claim excess amount advance ',
        _CLAIM_CAP_AMOUNT_RESIDUE: 'Claim cap amount residue ',
        _FIRST_SIR_ADVANCE: 'First sir advance ',
        _AGGREGATE_CAP_INDICATOR: 'Aggregate cap indicator ',
        _EXCESS_AGGREGATE_POLICY_VALUE: 'Excess aggregate policy value ',
        _AGGREGATE_CAP_AMOUNT: 'Aggregate cap amount ',
        _EXCESS_AGGREGATE_AMOUNT: 'Excess aggregate amount ',
        _EXCESS_AGGREGATE_ADVANCE: 'Excess aggregate advance ',
        _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue ',
        _EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE: 'Excess aggregate liquidator advance ',
        _AGGREGATE_CAP_AMOUNT_RESIDUE: 'Aggregate cap amount residue ',
        _EXCESS_AGGREGATE_AMOUNT_RESIDUE: 'Excess aggregate amount residue ',
        _SECOND_SIR_POLICY_VALUES: 'Second sir policy values ',
        _EXCESS_SECOND_SIR: 'Excess second sir ',
        _CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR: 'Claim liquidator advance second sir',
        _SECOND_SIR_RESIDUE: 'Second sir residue ',
        _CLAIM_DATA: 'Claim data ',
        _NOTIFY_DATE: 'Notify date ',
        _SEVERITY_CODE: 'Severity code ',
        _PHISICAL_INJURY_CODE: 'Phisical injury code ',
        _CASHED: 'Cashed',
        _COLLECTED: 'Collected',
        _FISCAL_TAX_REGIME: 'Fiscal tax regime',
        _GENERATE_PAYMENT: 'Generate payment',
        _INDEMNITY_COST: 'Indemnity cost',
        _LEGAL_CODE: 'Legal code',
        _NEW_INVOICE: 'New invoice',
        _PARTY_CODE: 'Party code',
        _PRESENCE_LAWYERS: 'Presence lawyers',
        _PROTOCOLLO_SAP: 'Sap protocl',
        _PROTOCOLLO_SDI: 'SDI Protocol',
        _RECEIPTS: 'Receipts',
        _RESISTANCE_COST: 'Resistance cost',
        _SETTLEMENT_COST: 'Settlement cost',
        _SETTLEMENTS: 'Settlements',
        _TOTAL_AMOUNT: 'Total amount',
        _PAYMENTS_FIND: 'Payments find',
        _OPENING_DUE_TO_WRIT_OF_SUMMONS: 'Opening due to writ of summons',
        _APPLY_PERCENTAGE_LIABILITY_FORCING: 'Apply percentage liability forcing',
        _DAMAGE_CARD: 'Damage card',
        _SEND_TO: 'Send to',
        _CURRENT_RESERVE: 'Current reserve',
        _NOTES: 'Notes',
        _DIALOGUE_NOTES: 'Notes',
        _COMPENSATIONS: 'Compensations',
        _MAGIC_STRING: 'Magic string',
        _INVOLVED_PARTIES__DAMAGE_CAUSE: 'Involved parties/Damage cause',
        _SETTLEMENT_COUNTERS: 'Settlement counters',
        _TYPE_OF_LOSS_COUNTERS: 'Type of loss counters',
        _TRUSTEE_DATA: 'Trustee data',
        _SUBJECT: 'Subject',
        _ROSTER_REGISTRATION_CODE: 'Roster registration code',
        _COURT_REGISTRATION_DATE: 'Court registration date',
        _TAX_OFFICE: 'Tax office',
        _ASSOCIATED_TRUSTEES: 'Associated trustees',
        _SUBJECT_IDENTITY: 'Subject identity',
        _TYPE_PROVIDER: 'Type provider',
        _OFFICE_HEADQUARTERS: 'Office Headquarters',
        _COUNTRY: 'Country',
        _SEARCH_FILTERS: 'Search filters',
        _VAT_NUMBER: 'Vat number',
        _EXPERT_TYPE_PROVIDER: 'Expert Type Provider',
        _DATE_OF_INSERTION: 'Fecha de inserción',
        _CANCELED: 'Canceled',
        _UPDATE_TYPE: 'Actualizar tipo',
        _DATE: 'Fecha',
        _TYPOLOGY: 'Typology',
        _CREATION_DATE: 'Creation date',
        _GROUP: 'Group',
        _BY_WORKLOAD: 'By Workload',
        _USERS_ASSIGNABLE_TASKS: 'Tareas asignables a los usuarios',
        _ORIGIN: 'Origin',
        _LAST_UPDATE: 'Last update',
        _CANCELED_POLICY: 'Canceled policy',
        _EMISSION_DATE: 'Emission date',
        _POLICY_REPLACED_NUMBER: 'Policy replaced number',
        _POLICY_REPLACEMENT_NUMBER: 'Policy replacement number',
        _SUSPENSION_DATE_FROM: 'Suspension date from',
        _SUSPENSION_DATE_TO: 'Suspension date to',
        _PREMIUM_DATE_FROM: 'Premium date from',
        _PREMIUM_DATE_TO: 'Premium date to',
        _OUT_OF_RANGE: 'Out of range',
        _DIARIA_DAYS: 'Diaria days',
        _ARREARS_DAYS: 'Arrears days',
        _PRODUCT_CODE: 'Product code',
        _PRODUCT_DESCRIPTION: 'Product description',
        _DIARIA: 'Diaria',
        _LOCATION: 'Location',
        _TERM_CODE: 'Term code',
        _DAYS: 'Days',
        _SELECTABLE: 'Selectable',
        _POSTAL_CODE: 'Postal code',
        _POLICY_CONDITION: 'Policy condition',
        _POLICY_CEILING: 'Policy ceiling',
        _MINIMUM_UNCOVERED_VALUE: 'Minimum uncovered value',
        _SEARCH_RESULTS: 'Search results',
        _UPDATE_DATE: 'Update date',
        _EXCESS: 'Excess',
        _BRANCH: 'Branch',
        _SURNAME__BUSINESS_NAME: 'Surname/Business name',
        _PERSON_TYPE: 'Person type',
        _LEGAL_PERSON: 'Legal person',
        _EDIT_SUBJECT: 'Edit subject',
        _UPDATE_RESERVE: 'Update reserve',
        _REOPENING_DATA: 'Datos de Reapertura',
        _REOPENING_REASON: 'Motivo de Reapertura',
        _SETTLEMENT_COSTS: 'Settlement costs',
        _VIEW_FOR_ACTORS: 'View for Actors',
        _NEW_MANUAL_DEADLINE: 'Nova data límite do manual',
        _QUOTA_AMOUNT: 'Importe de cuota',
        _AMOUNT_ACTIVE: 'Activo importe',
        _AMOUNT_RESERVE_QUOTA: 'Cuota de reserva activa',
        _OPENING_DATE: 'Fecha de apertura',
        _CLOSING_DATE: 'Fecha de cierre',
        _REFUSED_DATE: 'Fecha rechazada',
        _COMPENSATION_DIARY_N: 'Compensation Diary N.',
        _NEW_NOTE: 'New note',
        _EXTRA_INFO: 'Extra info',
        _NO_DATA: 'No data',
        _CONV_TYPE: 'Convention Type',
        _INVALID_PARAMETER_FORM: 'GL-ES_Invalid parameter form',
        _NO_PARTIES: 'No parties',
        _SELECT_ACTOR_ROLE: 'GL_ES_Select actor role',
        _NEW_ACTOR_LIST: 'GL_ES_Actor list',
        _ASSETS_LIST: 'GL_ES_Assets list',
        _DAMAGED_ASSET: 'GL_ES_Damaged asset',
        _ADD_OTHER_ASSET: 'GL_ES_Add new damage',
        _ACTOR_ROLE: 'GL_ES_Actor role',
        _SOLD: 'GL_ES_Sold',
        _RISK_FACTORS: 'GL_ES_Risk Factors',
        _THING: 'GL_ES_Thing',
        _VEHICLE_ASSET: 'GL_ES_Vehicle Asset',
        _EVENT_RISK: 'GL_ES_Event risk',
        _EVENT: 'GL_ES_Event',
        _NO_REASON_MEETS_THE_SEARCH_CRITERIA: 'GL_ES_No reason meets the search criteria mettila in uppecase',
        _RESIDENCE: 'GL_ES_Residenza',
        _DOMICILE: 'GL_ES_Domicilio',
        _ADD_NEW_ADDRESS: 'GL_ES_Aggiungi nuovo indirizzo',
        _DIALOGUE_DESCRIPTION: 'Dialogue Description',
        _INSURED_PLATE_NUMBER: 'Insured Plate Number',
        _COMPANY_CODE: 'Company Code',
        _INVOLVED_PARTY_IDENTIFIER: 'Involved Party Identifier',
        _INVOLVED_PARTY_KEY: 'Involved Party Key',
        _NUMBER_REQUEST: 'Número de solicitude',
        _RESPONSE_IN: 'Response (In)',
        _REQUEST_OUT: 'Request (Out)',
        _REQUEST_IN: 'Request (In)',
        _RESPONSE_OUT: 'Response (Out)',
        _RESPONSE_INPUT: 'Response (Input)',
        _REQUEST_INPUT: 'Request (Input)',
        _REQUEST_OUTPUT: 'Request (Output)',
        _RESPONSE_OUTPUT: 'Response (Output)',
        _SIMILAR_DIALOGUES: 'Similar Dialogues',
        _CANCEL_MESSAGE: 'Canceled Successfully!',
        _CF_PIVA: 'CF/PIVA',
        _LOSS_DATE: 'Data de perda',
        _VERSION: 'GL_ES Version',
        _CODE_MESSAGE: 'Mensaxe de código',
        _PAYMENT_DELETE_CHECK_QUESTION: 'Estás seguro de que queres eliminar o seguinte elemento da táboa?',
        _ERROR_DURING_SAVING: 'Error while saving!',
        _NOT_CORRECT_ENTRY_DATA: 'The data entered are incorrect!',
        _WRONG_DATES: 'The dates entered are not valid!',
        _MANDATORY_FUNCTION_CODE: 'Mandatory function code!',
        _INCORRECT_AMOUNT: "The amount entered is not valid!",
        _ALREADY_EXISTS_RECORD: "A record with the same data already exists in the indicated time period",
        _NEW_FORFAIT: 'Novo forfait',
        _REVERSAL: 'GL-ES_Reversal',
        _CANCELLATION_REVERSAL: 'GL-ES_Cancellation/Reversal',
        _PAYMENTS: {
            _IDRECORDFLOW: 'ES_Id record flujo',
            _CODFUNCTION: 'ES_Código de función',
            _CODE_COMPANY: 'ES_Entidad',
            _LOTTO: 'ES_Lote',
            _SEQUENCE: 'ES_Secuencia',
            _REGISTERTYPE: 'ES_Tipo de registro',
            _CODMESSAGE: 'ES_Mensaje',
            _SENDINGFLOWDATE: 'ES_Fecha recepción',
            _YEAR: 'ES_Año',
            _MONTH: 'ES_Mes',
            _OPERATIONCENTRE: 'ES_Centro de Operaciones',
            _OPERATIONCENTRECNT: 'ES_Centro de Operaciones contraparte',
            _COMPANYCODEUS: 'ES_Código de empresa',
            _COMPANYCODECNT: 'ES_Entidad contraria',
            _INTERNALKEY: 'ES_Referencia',
            _EXTERNALKEY: 'ES_Referencia contraria',
            _LOSSDATE: 'ES_Fecha ocurrencia',
            _ROLE: 'ES_Posición',
            _ERRORCODE: 'ES_Código de error',
            _ERROR: 'ES_Error',
            _INTERNALKEYDLG: 'ES_Clave interna de diálogo',
            _ROLEDLG: 'ES_Posición diálogo',
            _PAVEEDLG: 'ES_Placa con el nombre del actor',
            _CLAIMNUMBERDLG: 'ES_Número de diálogo izquierdo',
            _COMPANYCTRDLG: 'ES_Diálogo empresa contraparte',
            _LOSSDATEDLG: 'ES_Fecha del diálogo de pérdida',
            _PLATECTRDLG: 'ES_Placa homóloga de diálogo',
            _SETTLEMENTNUMBER: 'ES_Número de liquidación',
            _PLATEDLG: 'ES_Placa de diálogo',
            _IMPORTFORFAIT: 'ES_Importe',
            _PAYMENTTYPE: 'ES_Tipo de pago',
            _FORCED: 'ES_Forzar',
            _SKIPPED: 'ES_Saltar',
            _DINS: 'ES_Fecha de inserción',
            _UNIQUECODE: 'ES_Código Tirea',
            _SETTLEMENTDATE: 'ES_Fecha liquidacion',
            _PAYMENTCOLLECTION: 'ES_Pago o recobro',
            _PAY: 'ES_Pago',
            _RECOVER: 'ES_Recobro',
            _TOTALRECORD: 'ES_Total Registros',
            _TOTALRECORDKO: 'ES_Total Registros KO',
            _TOTALRECORDOK: 'ES_Total Registros OK',
            _TOTALAMOUNTPAYMENTS: 'ES_Total Importe - Pagos',
            _TOTALAMOUNTRECOVERED: 'ES_Total Importe - Recobros'
        },
        _TYPE_LICENSE_PLATE_NUMBER_US: 'Type of License plate number us',
        _TYPE_LICENSE_PLATE_NUMBER_CTP: 'Type of License plate number ctp',
        _LST_MES_SEND_DATA: 'Send Date',
        _NO_DELEGATION: 'No Delegation',
        _OUR_DELEGATION: 'Our Delegation',
        _THIRD_PARTY_DELEGATION: 'Third party delegation'
    }
};

