import { Injectable, Output } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { AnagStateResult } from './anag-state-manager-result';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ActiveRoute, RgiRxRouteData, RoutingService } from '@rgi/rx/router';
import { ROUTES_DETAIL, ROUTES_HOME, ROUTES_MODAL_HOME, ROUTES_RESULT } from '../../anag-constants/routes-constants';
import { AnagRouteData } from '../../anag-model/anag-domain/anag-route-data';
import { HttpErrorResponse } from '@angular/common/http';
import { AnagResourceService } from '../../anag-resources/anag-resource.service';
import { OperatorLight } from '../../services/external-service';
import { RgiRxUserService } from '@rgi/rx/auth';
import { AnagApiSubject } from '../../anag-model/anag-api/anag-api-subject';
import { AnagIntegrationService } from '../../services/anag-integration.service';
import { KEY_DATA_UPDATE, KeyDataUpdateEvent, NewPartyEvent, NewPartyEventInstance, SubjectSelectedEvent, SubjectSelectedEventInstance } from '../../anag-events/anag-event';
import { RgiRxEventService } from '@rgi/rx';
import { KeyDataModalComponent } from '../../anag-components/party-create-key-data/key-data-modal/key-data-modal.component';
import { AnagStateManagerKeyData } from '../key-data/anag-state-manager-key-data';
import { ModalService } from '@rgi/rx/ui';
import { CORE_PARTY_CONFIG } from '../../anag-constants/anag-constants';
import { AnagStorageService } from '../../anag-resources/anag-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AnagStatelessOpResultService {

  constructor(
    protected routingService: RoutingService,
    protected userService: RgiRxUserService,
    protected anagResources: AnagResourceService,
    protected anagIntegration: AnagIntegrationService,
    protected eventService: RgiRxEventService,
    protected modalService: ModalService,
    protected anagStorage: AnagStorageService,) {
  }

  initResults$(stateResult$: Observable<AnagStateResult>, routeData: RgiRxRouteData<AnagRouteData>): Observable<AnagStateResult> {
    return stateResult$.pipe(
      mergeMap(
        (st) => {
          return combineLatest([
            of(st),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.partyHomonymy).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagShowCounterTerrorismFinder).pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              ([state, systemPropPartyHomonymy, systemPropShowTerrorism]) => {
                if (systemPropPartyHomonymy && systemPropPartyHomonymy.value && systemPropPartyHomonymy.key) {
                  state.isUmaPartyHomonymyEnable = systemPropPartyHomonymy.value === 'Enable';
                }
                if (systemPropShowTerrorism && systemPropShowTerrorism.value && systemPropShowTerrorism.key) {
                  state.showCounterTerrorism = systemPropShowTerrorism.value === 'Si';
                }
                state.parties = routeData.oldParties ? routeData.oldParties : routeData.parties;
                state.party = routeData.party;
                state.isModal = routeData.isModalFlow === true;
                state.canCreateParty = !routeData.isSearchFlow;
                state.canEditParty = !routeData.isEditFlow;
                state.isBackFromHomonymy = routeData.isBackFromHomonymy;
                state.totalCounterParties = routeData.totalCounterParties;
                state.redirectToMainFlow = routeData.redirectToMainFlow;
                state.flowData = routeData.flowData;
                return state;
              }
            )
          );
        }
      )
    );
  }

  goToHomePage(activeRoute: ActiveRoute) {
    let route = ROUTES_HOME;
    if (activeRoute.getRouteData<AnagRouteData>() && activeRoute.getRouteData<AnagRouteData>().isModalFlow) {
      route = ROUTES_MODAL_HOME;
      this.anagIntegration.navigate(this.routingService, route, activeRoute.getRouteData<AnagRouteData>(), activeRoute);
    } else {
      this.anagIntegration.backToHome(activeRoute);
    }
  }

  existOnNodoFittizio(st: AnagStateResult, configNodeFittizio) {
    let party = null;
    if (configNodeFittizio && configNodeFittizio.value) {
      this.anagStorage.getNodesSinistri$(configNodeFittizio.value).subscribe(res => {
        if(res){
          let idNodoFittizio = res[0].objectId;
          party = st.parties.find(party => party.objectId === idNodoFittizio);
        }
      });
    }
    return party;
  }

  findPartyFromModal$(stateResult$: Observable<AnagStateResult>, eventService: RgiRxEventService, row: any) {
    return stateResult$.pipe(
      mergeMap((st) => {
        return combineLatest([
          of(st), 
          this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.sinistriNodoFittizioAnagrafe).pipe(catchError((err) => of(err)))
        ])
      }),
      mergeMap(([st, configNodeFittizio]) => {
        return combineLatest([
          of(st), 
          configNodeFittizio && configNodeFittizio.value ? 
          this.anagStorage.getNodesSinistri$(configNodeFittizio.value).pipe(catchError((err) =>  of(err))) : 
          of(null),
        ])
      }),
      mergeMap(([st, responseNodiSinistri]) => {
        let nodeId = null;
        if (row && row.nodeId && row.nodeId) {

          let partyOnNodoFittizio = null;
          if(responseNodiSinistri){
            let idNodoFittizio = responseNodiSinistri[0].id;
            partyOnNodoFittizio = st.parties.find(party => party.partyKey 
              && this.hasSamePartyKey(party.partyKey, row.fiscalCode) 
              && party.node 
              && party.node.identification === idNodoFittizio);
          }

          if (partyOnNodoFittizio) {
            nodeId = partyOnNodoFittizio.node.identification;
          } else {
            nodeId = row.nodeId;
          }
        } else if (this.userService.isLoggedIn()) {
          nodeId = this.userService.getUser<OperatorLight>().salePoint.objectId;
        }
        return combineLatest([
          of(st), 
          this.anagResources.getSubjectDetail$(row.objectId, nodeId, null).pipe(catchError((err) =>  of(err))),
        ])
      }),
      map(([state, resp]) => {
          this.eventService.emit<SubjectSelectedEvent>(new SubjectSelectedEventInstance(resp.subject));
          
          return state;
        }
      )
    );
  }

  hasSamePartyKey(partyKeys, fiscalCode) {
    if (partyKeys && partyKeys[partyKeys.length -1] && fiscalCode) {
      let partyKeysFirst = partyKeys[partyKeys.length -1];

      return (partyKeysFirst.key1  && partyKeysFirst.key1.value === fiscalCode) ||
              (partyKeysFirst.key1 && partyKeysFirst.key2.value === fiscalCode) || 
              (partyKeysFirst.key1 && partyKeysFirst.key3.value === fiscalCode)
    }

    return false;
  }

  // tslint:disable-next-line:max-line-length
  findPartyAndGoTo$(stateResult$: Observable<AnagStateResult>, activeRoute: ActiveRoute, row: any, goTo: 'EDIT' | 'DETAIL' | 'EDIT_HOMONYMY', stateMgrKeyData: AnagStateManagerKeyData) {
    let nodeId = null;
    if (row && row.nodeId && row.nodeId) {
      nodeId = row.nodeId;
    } else if (this.userService.isLoggedIn()) {
      nodeId = this.userService.getUser<OperatorLight>().salePoint.objectId;
    }
    return stateResult$.pipe(
      mergeMap(
        (st) => {
          return combineLatest([
            of(st), this.anagResources.getSubjectDetail$(row.objectId, nodeId, null).pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              ([state, resp]: [AnagStateResult, AnagApiSubject]) => {
                if (resp && resp.subject) {
                  const routeData = {
                    ...activeRoute.getRouteData<AnagRouteData>(),
                    party: resp.subject,
                    redirectToMainFlow: state.redirectToMainFlow,
                    isUmaPartyHomonymyEnable: state.isUmaPartyHomonymyEnable,
                    isUmaPartyCFDuplicate: state.isUmaPartyCFDuplicate,
                    isUmaPartyGroupCompanies: state.isUmaPartyGroupCompanies
                  };
                  switch (goTo) {
                    case 'DETAIL':
                      this.anagIntegration.setPartyInContainerCache(routeData.party, activeRoute.id);
                      this.anagIntegration.navigate(this.routingService, ROUTES_DETAIL, routeData, activeRoute);
                      break;
                    case 'EDIT':
                      if (state.isModal) {
                        this.eventService.emit<NewPartyEvent>(new NewPartyEventInstance({
                          action: 'newParty',
                          party: routeData.party,
                          lastAction: 'searchFlow',
                          isModalFlow: true,
                          flowData: routeData.flowData,
                          redirectToMainFlow: routeData.redirectToMainFlow
                        }));
                      } else {
                        this.anagIntegration.openEditSubject(routeData);
                      }
                      break;
                    case 'EDIT_HOMONYMY':
                      if (state.isModal) {
                        this.eventService.emit<NewPartyEvent>(new NewPartyEventInstance({
                          action: 'keyData',
                          party: resp.subject,
                          lastAction: 'resultFlow',
                          isModalFlow: true,
                          flowData: state.flowData,
                          redirectToMainFlow: state.redirectToMainFlow,
                          parties: state.parties
                        }));
                      } else {
                        const keyDataModal = this.modalService.openComponent(KeyDataModalComponent, resp.subject, [
                          {
                            provide: AnagStateManagerKeyData,
                            useValue: stateMgrKeyData
                          }
                        ]);
                        keyDataModal.modal.enableClickBackground = false;
                        keyDataModal.modal.onClose.asObservable().pipe(take(1));
                        this.eventService.listen<KeyDataUpdateEvent>(KEY_DATA_UPDATE).pipe(take(1)).subscribe(eventInstance => {
                          this.anagIntegration.openEditSubject(eventInstance.event);
                        });
                      }
                      break;
                  }

                }
                if (resp instanceof HttpErrorResponse) {
                  state.serviceError = resp.message;
                }
                return state;
              }
            )
          );
        }
      )
    );
  }
}
