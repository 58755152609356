import { DatePipe } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import {
  Installment,
  MovementVariation, Profile,
  ResponseInvestments,
  ResponseOperationSyntesis,
  WarrantyDetailData
} from '../../../models/life-detail.model';
import {
  ContributionsPerInstalmentInfo,
  OperationWithdrawal,
  PremiumPaymentReactivation,
  ReserveRiskData
} from '../../../models/movement.model';
import { LpcQuestionnaireContainerComponent } from '../../../modules/lpc-questionnaire-container/lpc-questionnaire-container.component';
import { PlcQuestService } from '../../../services/plc-quest.service';
import { PlcCurrencyUtils } from '../../../utils/plc-currency';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';
import {
  LifeMovementInstallmentPremiumDetailComponent
} from './life-movement-installment-premium-detail/life-movement-installment-premium-detail.component';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { ProposalDetailService } from '../../../services/proposal-detail.service';
import { KarmaFundDefinition } from '../../../modules/lpc-karma-funds/model/karma-fund-definition';
import { fundTypeId } from '../../../models/enums/vita.enum';

@Component({
  selector: 'lpc-life-movement-detail',
  templateUrl: './life-movement-detail.component.html',
  styleUrls: ['./life-movement-detail.component.scss'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LifeMovementDetailComponent implements OnInit, AfterViewChecked {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };
  public quoteNumberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 4
  };
  public decimalNumberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2
  };
  public decimalNumberFormatterOptionsSix: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 6
  };

  @Input() data: any;
  @Output() eventPropagation = new EventEmitter<any>();
  @Input() id: string;

  public disinvestments: ResponseInvestments;
  public investments: ResponseInvestments;
  public opreationSyntesis: ResponseOperationSyntesis;
  public surveys: [];
  public capitalData: [];
  public settlementBeneficiaryDetails: [];
  public movementVariation: MovementVariation;
  public initializeViewDetailMovement = false; // section viewer
  public note: string[] = [];
  public premiumPaymentReactivation: PremiumPaymentReactivation;
  public reserveRiskData: ReserveRiskData[] = [];
  public installment: Installment;

  public warranties: [];

  private opWithdrawal = false;
  private showAmountFee = false;
  private _isTicket = false;
  private operationWithdrawal: OperationWithdrawal;
  public dueRegisterPlanInvestment: Profile[] = [];

  private hasContributions = false;
  private contributions: ContributionsPerInstalmentInfo[];

  // vettore che contiene descrizioni e valori per la sezione rata premio
  // è in questo formato [[{},{}], [{},{}] ... ]
  // viene diviso in un vettore contenente vettori di 2 elementi per intabellare le righe
  rataPremioVector = [];
  warrantiesDetailData: WarrantyDetailData;
  warrDescription: string;

  get isTicket(): boolean {
    return this._isTicket;
  }

  get haveOpWithdrawal(): boolean {
    return this.opWithdrawal;
  }

  get haveAmountFee(): boolean {
    return this.showAmountFee;
  }

  get subcause(): string {
    return this.data.movementDetail.movementInfo.subcause;
  }

  constructor(
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    protected modalService: NgbModal,
    protected questService: PlcQuestService,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    protected datePipe: DatePipe,
    protected proposalDetailService: ProposalDetailService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit(): void {
    if (!!this.data.movementDetail) {
      console.log(this.data, 'data');
      this.disinvestments = this.data.movementDetail.disinvestments;
      this.investments = this.data.movementDetail.investments;
      this.opWithdrawal = !!this.data.movementDetail.operationWithdrawal;
      this.operationWithdrawal = this.data.movementDetail.operationWithdrawal;
      this.showAmountFee = this.data.movementDetail.showAmountFee;
      this._isTicket = this.data.movementDetail.ticket;
      this.surveys = this.data.movementDetail.surveys;
      this.settlementBeneficiaryDetails = this.data.movementDetail.settlementBeneficiaryDetails;
      this.movementVariation = this.data.movementDetail.movementVariation;
      this.initializeViewDetailMovement = true;
      this.premiumPaymentReactivation = this.data.movementDetail.premiumPaymentReactivation;
      this.installment = this.data.movementDetail.movementInfo.installmentInfo;
      this.capitalData = this.data.movementDetail.capitalData;
      this.warranties =  this.data.movementDetail.warrantiesForInstalmentInfo;

      const installmentInfo = this.data.movementDetail.movementInfo.installmentInfo;
      this.hasContributions = !!installmentInfo && !!installmentInfo.contributionsPerInstalmentInfoList;
      this.contributions = this.hasContributions ? installmentInfo.contributionsPerInstalmentInfoList : null;
      
      if (this.data.movementDetail.note) {
        this.note.push(this.data.movementDetail.note);
      }
      if (!!this.data.movementDetail.movementInfo && !!this.data.movementDetail.movementInfo.installmentInfo) {
        this.getRataPremio(this.data.movementDetail.movementInfo.installmentInfo);
      }

      this.manageDueRegisterInvestmentPlanCall();
    }
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  get getHasContributions(): boolean {
    return this.hasContributions;
  }

  get getContributions(): ContributionsPerInstalmentInfo[] {
    return this.contributions;
  }

  public getValue(value: string): string {
    if (value === '0.0') {
      return EMPTY_STR;
    } else {
      return this.rgiNumberFormatter.transform(value, this.currencyService.locale, this.currencyFormatterOptions);
    }
  }

  public back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  public getCurrency(value: string | number, symbol?: string) {
    if (!!symbol && !!this.getSymbol(symbol).trim()) {
      return this.rgiNumberFormatter.transform(value, this.currencyService.locale, this.decimalNumberFormatterOptions) + ' ' + this.getSymbol(symbol);
    }
    return this.rgiNumberFormatter.transform(value, this.currencyService.locale, this.currencyFormatterOptions);
  }

  public getCurrencySix(value: string | number, symbol?: string) {
    if (!!symbol && !!this.getSymbol(symbol).trim()) {
      return this.rgiNumberFormatter.transform(value, this.currencyService.locale, this.decimalNumberFormatterOptionsSix) + ' ' + this.getSymbol(symbol);
    }
    const currencyFormatterOptionsSix = PlcObjectUtils.clone(this.currencyFormatterOptions);
    currencyFormatterOptionsSix.minimumFractionDigits = 6;
    return this.rgiNumberFormatter.transform(value, this.currencyService.locale, currencyFormatterOptionsSix);
  }

  public getValueUnit(value: string): string {
    if (value === '0.0') {
      return EMPTY_STR;
    } else {
      return this.rgiNumberFormatter.transform(value, this.currencyService.locale, this.quoteNumberFormatterOptions);
    }
  }

  public getPercentage(value: string): string {
    if (value === '0.0') {
      return EMPTY_STR;
    } else {
      return this.rgiNumberFormatter.transform(value, this.currencyService.locale, this.percentFormatterOptions);
    }
  }

  public getSymbol(symbol: string) {
    if ((this.data.movementDetail.haveTitleAndPayment || !this.data.movementDetail.haveTitleAndPayment) &&
      (!!this.data.operationWithdrawal || !!symbol)) {
      return PlcCurrencyUtils.fixEuroSymbol(symbol);
    } else {
      return ' ';
    }
  }

  public totalPercent(investments: ResponseInvestments): string {
    return investments.profiles.reduce((subtotal, item) => subtotal + Number(item.percent) / 100, 0)
      .toString(10);
  }

  public totalSummary(investments: ResponseInvestments, isInvestment: boolean): string {
    return investments.profiles.reduce((subtotal, item) => subtotal +
      (isInvestment ? Number(item.netAmount) : Number(item.grossAmount)), 0).toFixed(2);
  }

  public calculateFee(investment: ResponseInvestments): string {
    return (Number(investment.grossAmount) - Number(investment.netAmount)).toString(10);
  }

  public hasInvestments(investments: ResponseInvestments): boolean {
    return !!investments && Math.abs(Number(investments.grossAmount)) > 0;
  }

  public getPenalties(): string {
    return this.operationWithdrawal.penaltiesAmount.toString(10);
  }

  public getTax(): string {
    return this.operationWithdrawal.taxAmount.toString(10);
  }

  public getBonusAmount(): string {
    return !!this.operationWithdrawal.bonusAmount && this.operationWithdrawal.bonusAmount
      ? this.operationWithdrawal.bonusAmount.toString(10) : EMPTY_STR;
  }

  public getAmount(): string {
    if (!!this.data.movementDetail.operationWithdrawal) {
      if (!!this.data.movementDetail.operationWithdrawal.netAmount) {
        return this.data.movementDetail.operationWithdrawal.netAmount;
      }
    } else {
      return this.data.movementDetail.movementInfo.grossAmount;
    }

  }

  public getFundAmountByAmountType(fund: KarmaFundDefinition, amountType: string) {
    const amount = Number(fund[amountType]);
    if (fund.typeId === fundTypeId.GS) {
      return this.getCurrency(amount, this.disinvestments && this.disinvestments.currency && this.disinvestments.currency.symbol);
    }
      return this._isTicket ?
      this.getCurrency(amount, this.disinvestments && this.disinvestments.currency && this.disinvestments.currency.symbol) :
      this.getCurrency(amount * Number(fund.exchangeRate), fund && fund.currency && fund.currency.symbol);
    }


  public showLabel(): string {
    if (!!this.data.movementDetail.operationWithdrawal) {
      if (!!this.data.movementDetail.operationWithdrawal.netAmount) {
        return 'lpc_net_amount';
      }
    }
    if (!!this.data.movementDetail.movementInfo.grossAmount) {
      return 'lpc_gross_amount';
    }
  }

  public showDetailPaymentFrequent(): boolean {
    return !!this.data.movementDetail.paymentFrequencyDetail;
  }

  public showDetailVarationDate(): boolean {
    return !!this.data.movementDetail.startDateNewVariation;
  }

  // RDDL-3705
  public showDetailPremiumPaymentSuspension(): boolean {
    return !!this.data.movementDetail.premiumPaymentSuspension;
  }

  // RDDL-3705
  public showDetailPremiumPaymentReactivation(): boolean {
    return !!this.data.movementDetail && !!this.data.movementDetail.premiumPaymentReactivation;
  }
  public showDetailPremiumPaymentReactivationTable(): boolean {
    return !!this.data.movementDetail
            && !!this.data.movementDetail.premiumPaymentReactivation
            && !!this.data.movementDetail.premiumPaymentReactivation.detailNextInstallment;
  }

  public showOperationSyntesis(): boolean {
    if (!!this.data.movementDetail) {
      return !!this.data.movementDetail.operationSyntesis;
    }
  }

  public showCancellInstallment(): boolean {
    if (!!this.data.movementDetail) {
      return !!this.data.movementDetail.cancellInstallmentData;
    }
  }


  public getGrossAmount(): string {
    if (!!this.data.movementDetail) {
      return this.data.movementDetail.operationWithdrawal ?
        this.data.movementDetail.operationWithdrawal.grossAmount : this.data.movementDetail.movementInfo.grossAmount;
    }

  }

  public showGrossAmount(): boolean {
    if (!!this.data.movementDetail) {
      return !!this.data.movementDetail.operationWithdrawal;
    }
  }

  public get getPremiumPaymentOperationLabel(): string {
    return this.data && this.data.movementDetail
    && this.data.movementDetail.premiumPaymentReactivation
    && this.data.movementDetail.premiumPaymentReactivation.premiumPaymentDateLabel;
  }

  public openQuest(id: string) {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    const modalRef = this.modalService.open(LpcQuestionnaireContainerComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    this.questService.getSurveyVersion(id).subscribe(el => {
      modalRef.componentInstance.questionnaireFlat = el.lastVersion.questionnaire;
      this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
    });
  }

  public showReserveDetails() {
    if (!!this.data.movementDetail) {
      return !!this.data.movementDetail.reserveData;
    }
  }

  public get showReserveRiskDetails() {
    if (!!this.data.movementDetail && !!this.data.movementDetail.reserveData &&
        !!this.data.movementDetail.reserveData.reserveRiskData.length) {
      return !!this.data.movementDetail.reserveData.reserveRiskData.length;
    }
    return false;
  }

  public get showLoanDetail() {
    if (!!this.data.movementDetail && !!this.data.movementDetail.loanData) {
      return !!this.data.movementDetail.loanData.length;
    }
    return false;
  }

  public getFieldValue(value: string, type: string) {
    if (type === 'date') {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    } else if (type === 'currency') {
      return this.rgiNumberFormatter.transform(value, this.currencyService.locale, this.currencyFormatterOptions);
    } else {
      return value;
    }
  }

  public getWarrantyDetail(id: string, modal: any): any {
    this.proposalDetailService.getWarrantydetail(id, this.data.movementDetail.idMov).pipe().subscribe(
      val => {
        this.warrantiesDetailData = val;
        modal.open();
      });
  }

  openGrossPremiumDetail(ths) {
    const modalRef = ths.modalService.open(LifeMovementInstallmentPremiumDetailComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    modalRef.componentInstance.grossDetails = ths.data.movementDetail.movementInfo.installmentInfo.grossDetailW.grossDetails;
  }
  getRataPremio(installment) {
    this.rataPremioVector = [];
    const isOpenGrossPremiumDetail = installment.grossDetailW && installment.grossDetailW.detailEnabled;
    const method = isOpenGrossPremiumDetail ? this.openGrossPremiumDetail : null;
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_GrossPremium', installment.grossAmount, method);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_reactivation_interest', installment.reactivationInterests);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_Taxes', installment.taxes);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_loan_interest', installment.loanInterests);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_Fees', installment.fees);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_fractionation_interest', installment.splitInterests);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_extra_premium', installment.extraPremium);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_default_interest', installment.defaultInterests);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_Loading', installment.loadings);
    this.setFieldIntoVector(this.rataPremioVector, 'lpc_DeductiblePremium', installment.deductiblePremium);
  }

  setFieldIntoVector(vector, description, value, fun?) {
    if (vector.length === 0 || (!!vector[vector.length - 1] && vector[vector.length - 1].length > 1)) {
      vector.push([{ description, value, fun }]);
    } else {
      vector[vector.length - 1].push({ description, value: (value ? value : 'null')});
    }
  }

  callFunction(fun) {
    if (fun) {
      fun(this);
    }
  }

  /**
   * Calls getDueRegisterInvestmentPlan to show due register plan investment in page.
   */
  private manageDueRegisterInvestmentPlanCall() {
    const canCallDueRegisterInvestmentPlan: boolean = !!this.data.lifeContract && !!this.data.lifeContract.proposalNumber &&
      !!this.data.movementDetail.dueRegisterInfo && !!this.data.movementDetail.dueRegisterInfo.dueRegisterId;
    if (canCallDueRegisterInvestmentPlan) {
      const proposalNumber = this.data.lifeContract.proposalNumber;
      const dueRegisterId = this.data.movementDetail.dueRegisterInfo.dueRegisterId;
      this.proposalDetailService.getDueRegisterInvestmentPlan(proposalNumber, dueRegisterId)
        .subscribe((dueRegisterPlanInvestment: Profile[]) => {
          this.dueRegisterPlanInvestment = dueRegisterPlanInvestment;
        });
    }
  }

}
