export const idTireaPayment: string = 'idTireaPayment'
export const idRecordFlow: string = 'idRecordFlow'
export const idFunStatus: string = 'idFunStatus'
export const codFunction: string = 'codFunction'
export const lotto: string = 'lotto'
export const sequence: string = 'sequence'
export const registerType: string = 'registerType'
export const codMessage: string = 'codMessage'
export const sendingFlowDate: string = 'sendingFlowDate'
export const year: string = 'year'
export const month: string = 'month'
export const operationCentre: string = 'operationCentre'
export const companyCodeUs: string = 'companyCodeUs'
export const companyCodeCnt: string = 'companyCodeCnt'
export const operationCentreCnt: string = 'operationCentreCnt'
export const internalKey: string = 'internalKey'
export const externalKey: string = 'externalKey'
export const lossDate: string = 'lossDate'
export const role: string = 'role'
export const state: string = 'state'
export const errorCode: string = 'errorCode'
export const error: string = 'error'
export const idDialogue: string = 'idDialogue'
export const internalKeyDlg: string = 'internalKeyDlg'
export const roleDlg: string = "roleDlg"
export const paveeDlg: string = 'paveeDlg'
export const claimNumberDlg: string = 'claimNumberDlg'
export const lossDateDlg: string = 'lossDateDlg'
export const companyCtrDlg: string = 'companyCtrDlg'
export const plateCtrDlg: string = 'plateCtrDlg'
export const idTypeOfLossDlg: string = 'idTypeOfLossDlg'
export const idSettlement: string = 'idSettlement'
export const settlementNumber: string = 'settlementNumber'
export const importForfait: string = 'importForfait'
export const paymentType: string = 'paymentType'
export const forced: string = 'forced' 
export const skipped: string = 'skipped'
export const dIns: string = 'dIns'
export const uniqueCode = 'uniqueCode'