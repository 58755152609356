<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="true" #first color="info">
    <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-6 col-md-6">
                <label ng-bind="label">
                    {{ '_CLAIMS_._OCCURRENCE_DATA' | translate }}
                </label>
            </div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="containerIncidentData"></ng-container>
</rgi-rx-expansion-panel>


<div *ngIf="!isAccordion">
    <div class="row rgi-claims-sub-title-section-shared ">
      <div class="col-xs-12 col-md-12">
        <label ng-bind="label">
            {{ '_CLAIMS_._OCCURRENCE_DATA' | translate }}
        </label>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="containerIncidentData"></ng-container>
</div>

<ng-template #containerIncidentData>
    <form [formGroup]="incidentDataForm" *ngIf="viewFormIncidentData">
        <div class="row">
            <div class="col-md-3 col-xs-3 col-sm-3">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}</label>
                    <rgi-rx-date-picker [openOnClick]="false">
                        <input [rgiRxDateTimeInput]="occurrenceDatePicker"
                            data-qa="rgi-incident-data"
                            formControlName="occurrenceDate"
                            [max]="today">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time [pickerType]="'calendar'" #occurrenceDatePicker></rgi-rx-date-time>
                </rgi-rx-form-field>
            </div>

            <div class="col-md-3 col-xs-3 col-sm-3">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._OCCURRENCE_TIME' | translate }}</label>
                    <rgi-rx-date-picker [openOnClick]="false">
                        <input [rgiRxDateTimeInput]="occurrenceTimePicker" 
                            data-qa="rgi-incident-data"
                            formControlName="occurrenceTime">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time [pickerType]="'timer'" #occurrenceTimePicker></rgi-rx-date-time>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._COMPLAINT_DATE' | translate }}</label>
                    <rgi-rx-date-picker [openOnClick]="false">
                        <input [rgiRxDateTimeInput]="dateReportedPicker"
                            formControlName="dateReported"
                            data-qa="rgi-incident-data"
                            [min]="minDate"
                            [max]="today">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time #dateReportedPicker></rgi-rx-date-time>
                </rgi-rx-form-field>
                <div class="row" *ngIf="isInValidDateReported">
                    <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_REPORTED_DATE_INCIDENT' | translate }}</label>
                </div>
                <div class="row" *ngIf="futureDateReported">
                    <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_FUTURE_DATE' | translate }}</label>
                </div>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._INSURED_REPORT_DATE' | translate }}</label>
                    <rgi-rx-date-picker [openOnClick]="false">
                        <input [rgiRxDateTimeInput]="insuredReportDateDatePicker"
                            formControlName="insuredReportDate"
                            data-qa="rgi-incident-data"
                            [min]="dateReported"
                            [max]="today">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time #insuredReportDateDatePicker></rgi-rx-date-time>
                    <div class="row" *ngIf="isInvalidInsuredReportDate">
                        <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_RECEIVED_DATE_INCIDENT' | translate }}</label>
                    </div>
                    <div class="row" *ngIf="futureDateIsuredReported">
                        <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_FUTURE_DATE' | translate }}</label>
                    </div>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-xs-3 col-sm-3">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._DATE_RECEIVED_INTERMEDIARY' | translate }}</label>
                    <rgi-rx-date-picker [openOnClick]="false">
                        <input [rgiRxDateTimeInput]="intermediaryReceiptDatePicker"
                            formControlName="intermediaryReceiptDate"
                            data-qa="rgi-incident-data"
                            [min]="dateReported"
                            [max]="today">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time #intermediaryReceiptDatePicker></rgi-rx-date-time>
                    <div class="row" *ngIf="isInvalidReceivedIntermediary">
                        <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_RECEIVED_DATE_INCIDENT' | translate }}</label>
                    </div>
                    <div class="row" *ngIf="futureDateIntermediary">
                        <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_FUTURE_DATE' | translate }}</label>
                    </div>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="isNewClaim !== 'isReopened' && isNewClaim !== 'isNewClaimReopen'">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._CDL_RECEIPT_DATE' | translate }}</label>
                    <rgi-rx-date-picker [openOnClick]="false">
                        <input [rgiRxDateTimeInput]="chcReceiptDateDatePicker"
                            formControlName="chcReceiptDate"
                            data-qa="rgi-incident-data"
                            [min]="intermediaryReceiptDate"
                            [max]="today">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time #chcReceiptDateDatePicker></rgi-rx-date-time>
                    <div class="row" *ngIf="isInvalidChcReceiptDate">
                        <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_CHC_DATE' | translate }}</label>
                    </div>
                    <div class="row" *ngIf="futureDateChc">
                        <label class="alert-textc">{{ '_CLAIMS_._MESSAGE_._INVALID_FUTURE_DATE' | translate }}</label>
                    </div>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-xs-3 col-sm-3 ptc-2">
                <rgi-rx-form-field>
                    <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._CERTIFIED_EVENT_DATE' | translate }}</label>
                    <input rgiRxInput type="checkbox"
                    formControlName="certifiedOccurrenceDate"
                    id="certifiedOccurrenceDate"
                    name="certifiedOccurrenceDate">
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="incidentData && incidentData.certifiedDataConfigurationVisible">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._ACTUAL_DATE_OF_OCCURRENCE' | translate }}</label>
                    <rgi-rx-date-picker>
                        <input [rgiRxDateTimeInput]="actualOccurrenceDateDatePicker"
                            formControlName="actualOccurrenceDate"
                            data-qa="rgi-incident-data">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time #actualOccurrenceDateDatePicker></rgi-rx-date-time>
                </rgi-rx-form-field>
            </div>
            <div class="col-md-3 col-xs-3 col-sm-3" *ngIf="incidentData && incidentData.certifiedDataConfigurationVisible">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._ACTUAL_TIME_OCCURRENCE' | translate }}</label>
                    <rgi-rx-date-picker>
                        <input [rgiRxDateTimeInput]="actualOccurrenceTimeDatePicker"
                            formControlName="actualOccurrenceTime"
                            data-qa="rgi-incident-data">
                    </rgi-rx-date-picker>
                    <rgi-rx-date-time [pickerType]="'timer'" #actualOccurrenceTimeDatePicker></rgi-rx-date-time>
                </rgi-rx-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field class="wc-100">
                    <textarea rgiRxInput (blur)="onChangeValueForm()"
                    formControlName="incidentDescription"></textarea>
                    <label rgiRxLabel>{{ '_CLAIMS_._OCCURRENCE_DESCRIPTION' | translate }}</label>
                    </rgi-rx-form-field>
            </div>
            <div class="col-md-6 col-xs-6 col-sm-6">
                <rgi-rx-form-field class="wc-100">
                    <textarea rgiRxInput (blur)="onChangeValueForm()"
                    formControlName="additionalNotes"></textarea>
                    <label rgiRxLabel>{{ '_CLAIMS_._MANAGEMENT_NOTE' | translate }}</label>
                    </rgi-rx-form-field>
            </div>
        </div>
    </form>
</ng-template>