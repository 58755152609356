import { Component, EventEmitter, OnDestroy, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  BENEFICIARY_CHOICE, CAC, ENUMS_KEYS,
  ExtensionProperty, LicCustomProperties, Roles, SaveActions } from '../../enum/life-issue.enum';
import { ActionServiceErrorMessage } from '../../models/actions.model';
import { DamageRisk, DamagesInstalments } from '../../models/damage.model';
import { ChoiceBeneficiaryData, IUnitGroupWrapper, SummaryModel } from '../../models/policy.model';
import { EnumModel, ErrorType, LifeIssueMessage } from '../../models/response.model';
import { RoutableComponent } from '../../routable-component';
import { AuthService } from '../../services/auth.service';
import { PolicyService } from '../../services/policy-service';
import { SystemPropertiesService } from '../../services/system-properties.service';
import { LicErrorsUtils } from '../../utils/lic-errors-utils';
import { LicIssueModalErrorComponent } from '../issue/issue-modal-error/issue-modal-error.component';
import { CardsNavigationService } from './../../cards-navigation/cards-navigation.service';
import {
  Agreement,
  BeneficiaryModel,
  ContractData,
  GeneralData,
  Installment,
  LifeSavePolicyResponse,
  PolicyPremium,
  Proposal,
  SubjectModel
} from './../../models/policy.model';
import { ActionsService } from './../../services/actions.service';
import { LifeSessionService } from './../../services/life-session-service';
import { MasterPolicyService } from './../../services/policy-master.service';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { LicObjectUtils } from '../../utils/lic-object-utils';
import { RgiRxPushMessage, RgiRxPushMessageHandlerService } from '@rgi/rx';


@Component({
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class SummaryComponent implements OnInit, RoutableComponent, OnDestroy {
  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();

  public flowButtons: {label: string, code: string, disabled?: boolean}[] = [];

  proposal: SummaryModel;
  proposalNumber = null;
  policyDurationInYears: boolean;
  dur: string;
  durLabel: string;
  dynamicTableGeneralData: object[] = [];
  dynamicTableAccountHolder: object[] = [];
  dynamicTableEffectiveHolder: object[] = [];
  dynamicTableBeneficiaries: object[] = [];
  dynamicTableUnit: any[] = [];
  nCol = 3;
  numbers = [];
  generalDataMap: Map<number, object[]>;
  rowsGeneralData: number[];
  accountHoldersMap: Map<number, object[]>;
  rowsSubject: number[];
  accountHolders: Map<number, object[]>;
  policyPremium: PolicyPremium;
  primaRata: Installment;
  damagesInstalment: DamagesInstalments[];
  damageRisk: DamageRisk[];
  effectiveHoldersMap: Map<number, object[]>;
  rowsEffectiveHolders: number[];
  beneficiariesMap: Map<number, object[]>;
  rowsBeneficiaries: number[];
  productBeneficiaries = [];
  // Riepilogo Garanzie
  titoloUnit: string[] = [];
  nomeAssicurato: string;
  unitLinkedMap: Map<number, object[]>;
  rowsUnitLinked: number[];
  managementSingleMap: Map<number, object[]>;
  rowsmanagementSingle: number[];
  arrayUnitContainer: any[] = [];
  proposalAndIssueConfig = false;
  isSaveAndAcceptAuthProp = false;
  saveAcceptIssue = false;
  warningMsgs = [];
  authoMsgs = [];
  blockingMsgs = [];

  onInitEnd = false;

  messageFromPreviousPage: LifeIssueMessage[] = [];

  protected $subscriptions: Subscription[] = [];
  public temporaryProposalNumber: string;

  public isError = false;
  public modalError = 'lic_auth_error_msg';

  get getWarningMessages(): string[] {
    return this.removeDuplicate(
      this.warningMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.WARNING)
      .map(msg => msg.message))
    );
  }

  get getAuthMessages(): string[] {
    return this.removeDuplicate(
      this.authoMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.AUTH)
      .map(msg => msg.message))
    );
  }

  get getBlockingMessages(): string[] {
    return this.removeDuplicate(
      this.blockingMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.ERROR)
      .map(msg => msg.message))
    );
  }


  public setblockingMsgs() {
    this.pushMessageHandler.clearTag('blocking');
    this.pushMessageHandler.notify(
      ...(this.getBlockingMessages).map(m => new RgiRxPushMessage(m, { tag: 'blocking', status: 'danger', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setwarningMsgs() {
    this.pushMessageHandler.clearTag('warning');
    this.pushMessageHandler.notify(
      ...(this.getWarningMessages).map(m => new RgiRxPushMessage(m, { tag: 'warning', status: 'default', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setauthoMsgs() {
    this.pushMessageHandler.clearTag('auth');
    this.pushMessageHandler.notify(
      ...(this.getAuthMessages).map(m => new RgiRxPushMessage(m, { tag: 'auth', status: 'warning', dismissible: false, options: {icon: 'rgi-ui-icon-info'} }))
    );
  }


  removeDuplicate(messages: string[]) {
    return [...new Set(messages)];
  }

  constructor(protected policyService: PolicyService,
              protected lifeSessionService: LifeSessionService,
              protected cardsNavigationService: CardsNavigationService,
              protected systemPropertiesService: SystemPropertiesService,
              protected actionsService: ActionsService,
              protected translate: TranslationWrapperService,
              protected masterPolicyService: MasterPolicyService,
              @Optional() protected questCacheManager: QuestionnaireCacheService,
              protected authService: AuthService,
              protected modalService: NgbModal,
              protected rgiFormatter: RgiCountryLayerNumberFormatPipe,
              protected pushMessageHandler: RgiRxPushMessageHandlerService
  ) { }

  get isContextualPolicyAndProposal() {
    return this.proposalAndIssueConfig;
  }

  ngOnInit() {
    this.pushMessageHandler.clearTag('validation');
    this.pushMessageHandler.clearTag('blocking');
    this.pushMessageHandler.clearTag('warning');
    this.pushMessageHandler.clearTag('auth');
    this.onInitEnd = false;
    this.messageFromPreviousPage =  this.cardsNavigationService.getPreviousMessages(this.getErrorStep());
    this.proposal = new SummaryModel();
    this.proposal.generalData = new GeneralData();
    this.productBeneficiaries = this.policyService.mainProposal.quote.product.beneficiaries;
    this.createAllDynamicTables();

    this.proposalAndIssueConfig = this.systemPropertiesService.getStoredSystemProp('LifeContextualPolicyAndProposal');
    this.isSaveAndAcceptAuthProp = this.systemPropertiesService.getStoredSystemProp('AcceptProposalInAuthModif');
    this.saveAcceptIssue = this.systemPropertiesService.getStoredSystemProp('AcceptIssueProposalInAuthModif');

    // Premium Table
    this.policyPremium = this.policyService.mainProposal.proposal.policyPremium;
    this.primaRata = this.policyService.mainProposal.proposal.installment[0];
    this.damagesInstalment = this.policyService.mainProposal.proposal.damagesInstalments;
    this.damageRisk = this.policyService.mainProposal.proposal.damageRisks;

    this.setFlowButtons();
    this.onInitEnd = true;
    this.createPushMessage();
  }

  protected getErrorStep(): number {
    return this.cardsNavigationService.STEP.SOMMARIO.pos;
  }

  createAllDynamicTables() {
    this.setGeneralData();
    this.createDynamicTableforGeneralData();
    this.setSubjects();
    this.createDynamicTableforAccountHolders();
    this.createDynamicTableForEffectiveHolders();
    this.setBeneficiaries();
    this.setUnitType();
    this.generalDataMap = this.generateDynamicTable(this.dynamicTableGeneralData);
    this.rowsGeneralData = this.composeArray(Math.ceil(this.dynamicTableGeneralData.length / this.nCol));

    this.accountHoldersMap = this.generateDynamicTable(this.dynamicTableAccountHolder);
    this.rowsSubject = this.composeArray(Math.ceil(this.dynamicTableAccountHolder.length / this.nCol));

    this.effectiveHoldersMap = this.generateDynamicTable(this.dynamicTableEffectiveHolder);
    this.rowsEffectiveHolders = this.composeArray(Math.ceil(this.dynamicTableEffectiveHolder.length / this.nCol));

    this.beneficiariesMap = this.generateDynamicTable(this.dynamicTableBeneficiaries);
    this.rowsBeneficiaries = this.composeArray(Math.ceil(this.dynamicTableBeneficiaries.length / this.nCol));
  }

  private createPushMessage() {
    this.setblockingMsgs();
    this.setwarningMsgs();
    this.setauthoMsgs();
  }

  generateDynamicTable(objectsArray): Map<number, object[]> {
    let i = 0;
    const numberArray = [];
    objectsArray.map(() => {
      numberArray.push(i);
      i++;
    });
    return this.chunkSize(numberArray, 3, objectsArray);
  }

  composeArray(nRow: number): number[] {
    return Array(nRow).fill(0).map((x, i) => i);
  }

  chunkSize(array: number[], size: number, objectsArray): Map<number, object[]> {
    const indexMap = new Map<number, object[]>();
    let counter = 0;
    [].concat.apply([],
      array.map((i) => {
        const objectsForEachRow = [];
        if (i % size === 0) {
          array.slice(i, i + size).map((element) => objectsForEachRow.push(objectsArray[element]));
          indexMap.set(counter, objectsForEachRow);
          counter++;
        }
      })
    );
    return indexMap;
  }


  createDynamicTableforGeneralData() {
    this.putGeneralDataOnTable(this.dynamicTableGeneralData, this.translate.getImmediate('lic_product'), this.proposal.generalData.product);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData,
      this.translate.getImmediate('lic_managementNode'), this.proposal.generalData.managementNode);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData, this.translate.getImmediate('lic_branch'), this.proposal.generalData.branch);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData,
      this.translate.getImmediate('lic_agreement'), this.proposal.generalData.agreement.description);
    // this.putGeneralDataOnTable(this.dynamicTableGeneralData, 'Data emissione proposta', this.proposal.generalData.proposalDate);
    // this.putGeneralDataOnTable(this.dynamicTableGeneralData, 'Data emissione polizza', this.proposal.generalData.policyDate);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData,
      this.translate.getImmediate('lic_effect_date'), this.proposal.generalData.effectiveDate);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData, this.durLabel, this.dur);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData,
      this.translate.getImmediate('lic_exp_date'), this.proposal.generalData.policyExpirationDate);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData,
      this.translate.getImmediate('lic_payment_frequency'), this.proposal.generalData.paymentFrequency);
    this.putGeneralDataOnTable(this.dynamicTableGeneralData,
      this.translate.getImmediate('lic_means_of_payment'), this.proposal.generalData.debtModePrize);
  }

  createDynamicTableforAccountHolders() {
    this.proposal.subjects.map((subject) => {
      this.putGeneralDataOnTable(this.dynamicTableAccountHolder, subject.roleDescription, subject.name);
    });
    this.proposal.insured.map((insured) => {
      this.putGeneralDataOnTable(this.dynamicTableAccountHolder, insured.roleDescription, insured.name);
    });
    this.putGeneralDataOnTable(this.dynamicTableAccountHolder, this.proposal.refTerzo.roleDescription, this.proposal.refTerzo.name);
  }

  createDynamicTableForEffectiveHolders() {
    this.proposal.effectiveHolders.map((subject) => {
      this.putGeneralDataOnTable(this.dynamicTableEffectiveHolder, '', subject.name + ' ' + subject.percentage);
    });
  }

  putGeneralDataOnTable(table: object[], key: string, value: string) {
    if (value) {
      table.push({ chiave: key, valore: value });
    }
  }

  setGeneralData() {
    this.proposal.generalData.product = this.policyService.mainProposal.quote.product.description;

    this.proposal.generalData.managementNode = this.policyService.mainProposal.proposal.policyVersion.managmentNode.description;
    if (!!this.policyService.mainProposal.proposal.policyVersion.branch) {
      this.proposal.generalData.branch = this.policyService.mainProposal.proposal.policyVersion.branch.objectId;
    }

    if (!!this.policyService.mainProposal.proposal.policyVersion.convention) {
      this.proposal.generalData.agreement = new Agreement();
      this.proposal.generalData.agreement.description = this.policyService.mainProposal.proposal.policyVersion.convention.description;
    } else {
      this.proposal.generalData.agreement = new Agreement();
      this.proposal.generalData.agreement.description = '-';
    }

    this.proposal.generalData.proposalDate = this.formatDate(this.policyService.mainProposal.proposal.proposalIssueDate);
    this.proposal.generalData.policyDate = this.formatDate(this.policyService.mainProposal.proposal.policyIssueDate);

    this.setDurationPolicy();

    if (!this.proposal.generalData.proposalDate) {
      this.proposal.generalData.proposalDate = '-';
    }
    if (!this.proposal.generalData.policyDate) {
      this.proposal.generalData.policyDate = '-';
    }
    if (!this.proposal.generalData.policyDuration) {
      this.proposal.generalData.policyDuration = '-';
    }

    if (this.policyService.mainProposal.proposal.policyVersion.effectiveDate !== null) {
      this.proposal.generalData.effectiveDate = this.formatDate(this.policyService.mainProposal.proposal.policyVersion.effectiveDate);
    } else {
      this.proposal.generalData.effectiveDate = '-';
    }

    if (this.policyService.mainProposal.proposal.policyVersion.expirationDate !== null) {
      this.proposal.generalData.policyExpirationDate =
        this.formatDate(this.policyService.mainProposal.proposal.policyVersion.expirationDate);
    } else if (this.policyService.mainProposal.quote.policyExpirationDate) {
      this.proposal.generalData.policyExpirationDate =
        this.formatDate(this.policyService.mainProposal.quote.policyExpirationDate);
    } else {
      this.proposal.generalData.policyExpirationDate = '-';
    }

    if (this.policyService.mainProposal.proposal.policyVersion.fractionation !== null) {
      this.proposal.generalData.paymentFrequency = this.policyService.mainProposal.proposal.policyVersion.fractionation.description;
    } else {
      this.proposal.generalData.agreement.description = '-';
    }
    if (this.policyService.mainProposal.proposal.payments.length !== 0) {
      this.policyService.mainProposal.proposal.payments.map((p) => {
        this.proposal.generalData.debtModePrize = p.paymentType.descrizione;
      });
    } else {
      this.proposal.generalData.debtModePrize = '-';
    }
  }

  getPaymentFrequencyCode(): boolean {
    const pfc = this.policyService.mainProposal.quote.product.paymentFrequencyCode;

    let result = false;

    this.policyService.mainProposal.quote.product.paymentFrequencies.map((payment) => {
      if (!result) {
        if (payment.code === pfc) {
          if (payment.paymentFrequencyType === '3') {
            result = false;
          } else {
            result = true;
          }
        }
      }
    });
    return result;
  }

  setDurationPolicy() {
    this.durLabel = this.translate.getImmediate('lic_Durationin');
    this.dur = '';

    if (this.policyService.mainProposal.proposal.policyVersion.duration) {
      this.durLabel = this.durLabel + this.translate.getImmediate('lic_years');
      this.dur = this.policyService.mainProposal.proposal.policyVersion.duration;
    }
    if (this.policyService.mainProposal.proposal.policyVersion.durationMonths) {
      if (this.dur.length > 0) {
        this.durLabel = this.durLabel + '/';
        this.dur = this.dur + '/';
      }
      this.durLabel = this.durLabel + this.translate.getImmediate('lic_months');
      this.dur = this.dur + this.policyService.mainProposal.proposal.policyVersion.durationMonths;
    }
    if (this.policyService.mainProposal.proposal.policyVersion.durationDays) {
      if (this.dur.length > 0) {
        this.durLabel = this.durLabel + '/';
        this.dur = this.dur + '/';
      }
      this.durLabel = this.durLabel + this.translate.getImmediate('lic_days');
      this.dur = this.dur + this.policyService.mainProposal.proposal.policyVersion.durationDays;
    }
    this.proposal.generalData.policyDuration = this.dur;
  }

  formatDate(date: string) {
    if (date != null) {
      date.replace('/', '-');

      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      return [day, month, year].join('/');
    } else {
      return '-';
    }
  }

  setSubjects() {
    this.proposal.subjects = [];
    this.proposal.insured = [];
    this.proposal.effectiveHolders = [];
    this.proposal.refTerzo = new SubjectModel();

    // MASTER POLICY ROLE
    if (this.policyService.isCAC) {
      const mpSubject = new SubjectModel();
      mpSubject.roleDescription = this.translate.getImmediate('lic_MasterPolicyHolder');
      mpSubject.name = this.masterPolicyService.getMasterPolicy().subscriber;
      this.proposal.subjects.push(mpSubject);
    }

    this.policyService.mainProposal.proposal.lifeParty.map((s) => {
      const subject = new SubjectModel();
      subject.roleDescription = this.lifeSessionService.partyRoles.find(sbj => sbj.codice === s.party.partyRole).descrizione;
      subject.name = s.party.extraInformations;
      subject.percentage = s.percentageParty != null ?
      this.rgiFormatter.transform(
        // https://stackoverflow.com/questions/44978204/formatting-in-percent-by-intl-numberformat-in-javascript
        // using intl-numberformat percentage are fraction of 100, check the link above
        s.percentageParty / 100,
        this.policyService.currentLocale,
        this.policyService.getFormatterOptionsWithDecimal('percent', '0.2-2')
      ) :
      null;
      if (s.party.partyRole === Roles.INSURED || s.party.partyRole === Roles.SECOND_INSURED) {
        this.proposal.insured.push(subject);
      } else if (s.party.partyRole === Roles.THIRDREFERENT) {
        this.proposal.refTerzo = subject;
      } else if (s.party.partyRole === Roles.EFFECTIVEHOLDER) {
        this.proposal.effectiveHolders.push(subject);
      } else {
        this.proposal.subjects.push(subject);
      }
    });
  }

  // ----------------------------Inizio------------------------------------

  setUnitType() {
    const policyService = this.policyService.mainProposal;
    // Gestione per multi assicurato
    /* if (policyService.quote.product.assets.length > 0) {
       this.proposal.insured.map((insured) => {
         this.nomeAssicurato = insured.name
       });
       let unitMulti = new UnitModel();
        unit.tipoRischio = ;
       unit.tipoUnit = ;
       unit.stato = ;
       unit.sottoStato = ;
       unit.dataScadenza = ;
       unit.datadecorrenza = ;
       unit.prestazioneAllaData = ;
     } else {}*/
    let dynamicUnitWrappers: IUnitGroupWrapper[] = [];
    this.dynamicTableUnit = [];
    policyService.proposal.lifeRiskInsuredData.map((lRI) => {

      const dynamicUnitWrapper: IUnitGroupWrapper = {riskCode: null, groupingValue: null,
        description: null, dynamicTableUnit: null};
      const dinamicT: object[] = [];

      // recupero la shortDescription dalle unit
      if (lRI.riskCode != null) {
        dynamicUnitWrapper.riskCode = lRI.riskCode;
        this.policyService.mainProposal.quote.product.assets[0].instances.map((inst) => {
          inst.sections.map((sect) => {
            if (sect.units.length !== 0) {
              const fUnit = sect.units.find((unit) => unit.riskCode === lRI.riskCode);
              if (fUnit) {
                dynamicUnitWrapper.groupingValue = !!fUnit.transcoderCode1 ? fUnit.transcoderCode1 : dynamicUnitWrapper.groupingValue;
                dynamicUnitWrapper.description = fUnit.shortDescription;
              }
            }
          });
        });
      } else {
        dynamicUnitWrapper.description = lRI.insuredRisks.description;
      }
      this.putGeneralDataOnTable(dinamicT, this.translate.getImmediate('lic_unit_type'), lRI.insuredRisks.tipoUnit.descrizione);
      this.putGeneralDataOnTable(dinamicT, this.translate.getImmediate('lic_risk_type'), lRI.insuredRisks.riskType.descrizione);
      this.putGeneralDataOnTable(dinamicT, this.translate.getImmediate('lic_effect_date'), this.formatDate(lRI.insuredRisks.dataDecorrenza));
      this.putGeneralDataOnTable(dinamicT,
        this.translate.getImmediate('lic_exp_date'), this.formatDate(lRI.insuredRisks.version.dataScadenza));
      this.putGeneralDataOnTable(dinamicT, this.translate.getImmediate('lic_State'), lRI.insuredRisks.version.state.descrizione);
      this.putGeneralDataOnTable(dinamicT, this.translate.getImmediate('lic_substate'), lRI.insuredRisks.version.substate.descrizione);
      this.putGeneralDataOnTable(dinamicT,
        this.translate.getImmediate('lic_benefit'), this.filterInitialPerformanceBy(lRI.riskCode));
      // mi prendo il valore del tasso di ammortamento dal proposal
      const tasso = this.getRateOfDepreciation(policyService.proposal);
      if (tasso != null) {
        this.putGeneralDataOnTable(dinamicT, this.translate.getImmediate('lic_decreasing_frequency'), tasso);
      }
      if (lRI.insuredRisks.version.frazionamentoDecrescenza != null) {
        this.putGeneralDataOnTable(
          dinamicT, this.translate.getImmediate('lic_decreasing_frequency'),
          lRI.insuredRisks.version.frazionamentoDecrescenza.description
        );
      }
      dynamicUnitWrapper.dynamicTableUnit = dinamicT;
      dynamicUnitWrappers.push(dynamicUnitWrapper);
    });

    // CERCO UNIT DANNI SELEZIONATE
    this.policyService.mainProposal.quote.product.assets[0].instances.map((inst) => {
      const dynamicUnitWrapper: IUnitGroupWrapper = {riskCode: null, groupingValue: null,
        description: null, dynamicTableUnit: null};
      inst.sections.map((sect) => {
        sect.units.map((unit) => {
          if (!unit.life && unit.selection) {
            const fUnit = sect.units.find((u) => unit.riskCode === u.riskCode);
            if (fUnit) {
              dynamicUnitWrapper.groupingValue = !!fUnit.transcoderCode1 ? fUnit.transcoderCode1 : dynamicUnitWrapper.groupingValue;
              dynamicUnitWrapper.description = fUnit.shortDescription;
            } else {
              dynamicUnitWrapper.groupingValue = !!unit.transcoderCode1 ? unit.transcoderCode1 : dynamicUnitWrapper.groupingValue;
              dynamicUnitWrapper.description = unit.description;
            }
            const dinamicT: object[] = [];
            this.putGeneralDataOnTable(dinamicT, this.translate.getImmediate('lic_unit_type'), this.translate.getImmediate('lic_damages'));
            dynamicUnitWrapper.dynamicTableUnit = dinamicT;
            dynamicUnitWrappers.push(dynamicUnitWrapper);
          }
        });
      });
    });

    const keySum = this.translate.getImmediate('lic_benefit');
    dynamicUnitWrappers = this.manageGroupingCoverture(keySum, dynamicUnitWrappers);
    for (const dynamicUnitWrapper of dynamicUnitWrappers) {
      this.manageCurrencyConversion(keySum, dynamicUnitWrapper);
      this.titoloUnit.push(dynamicUnitWrapper.description);
      this.dynamicTableUnit.push(dynamicUnitWrapper.dynamicTableUnit);
      this.arrayUnitContainer.push(this.generateDynamicTable(dynamicUnitWrapper.dynamicTableUnit));
    }
  }

  filterInitialPerformanceBy(unitCode: string): string {
    const benefit = this.policyService.mainProposal.proposal.benefit.find(ben => ben.riskCode === unitCode);
    if (!!benefit) {
      return benefit.initialPerformance;
    } else {
      return null;
    }

  }

  getRateOfDepreciation(proposal: Proposal): string {
    let val = null;
    proposal.benefit.map((benefit) => {
      val = benefit.benefitVersion.borrowingDecreasingRate;
    });
    return val;
  }

  getCurrencyConversion(value) {
    if (!!value) {
      value = LicObjectUtils.getNormalizedForNumericConversion(value);
      const decimal = 2;
      if (decimal > 0) {
        const decInfo = '.' + decimal + '-' + decimal;
        value = this.rgiFormatter.transform(
          +value,
          this.policyService.currentLocale,
          this.policyService.getFormatterOptionsWithDecimal('currency', decInfo)
        );
      }
    }
    return value;
  }

  // ------------------------------Fine----------------------------------

  setBeneficiaries() {
    const beneficiaries = []; // array di BeneficiaryModel
    const anagBeneficiariesForCat = new Map<string, string[]>(); // mappa key: category, value: array di beneficiaries strings
    let anagBeneficiaries = []; // array di beneficiaries strings
    let currentType;
    this.policyService.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData.map((element) => {
      if (element.choiceTypeBeneficiary === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
        if (element.beneficiaryLifeParty != null) {
          if (element.beneficiaryLifeParty.party != null) {
            anagBeneficiariesForCat.set(element.typeBeneficiary.codice, anagBeneficiaries);
            anagBeneficiariesForCat.forEach((value, key) => {
              if (key === element.typeBeneficiary.codice) {
                if (element.typeBeneficiary.codice !== currentType) {
                  anagBeneficiaries = [];
                }
                const subSubjString = this.getSubBenefRoleSummary(element.beneficiaryLifeParty.linkedLifeParties);
                if (!!subSubjString) {
                  anagBeneficiaries.push(element.beneficiaryLifeParty.party.extraInformations + ' ' +
                  this.rgiFormatter.transform(
                    +element.beneficiaryLifeParty.percentageParty / 100,
                    this.policyService.currentLocale,
                    this.policyService.getFormatterOptionsWithDecimal('percent', '0.2-2')
                  ) + subSubjString);
                } else {
                  const groupedBenef = this.getBenefGroupingName(element);
                  anagBeneficiaries.push(element.beneficiaryLifeParty.party.extraInformations + ' ' +
                    this.rgiFormatter.transform(
                      +element.beneficiaryLifeParty.percentageParty / 100,
                      this.policyService.currentLocale,
                      this.policyService.getFormatterOptionsWithDecimal('percent', '0.2-2')
                    ) + ' ' + groupedBenef
                  );
                }
                anagBeneficiariesForCat.set(element.typeBeneficiary.codice, anagBeneficiaries);
                currentType = element.typeBeneficiary.codice;
              }
            });
          }
        }
      } else {
        /*  if (this.productBeneficiaries.length === 0) { */
        let beneficiary;
        this.lifeSessionService.enumTypeBeneficiary.map(t => {

          if ( Number(t.codice) ===  Number(element.typeBeneficiary.codice)) {
            beneficiary = new BeneficiaryModel();
            beneficiary.roleDescription = t.descrizione;
            if (this.productBeneficiaries.length === 0) {
              this.policyService.mainProposal.quote.product.assets.map(
                asset => {
                  asset.instances[0].sections.map(section => {
                    section.units.map(unit => {
                      unit.beneficiaries.map(ben => {
                        if (ben.code === element.choiceTypeBeneficiary) {
                          beneficiary.choiceBeneficiary = ben.description;
                        }
                      });
                    });
                  });
                }
              );
            } else {
              this.policyService.mainProposal.quote.product.beneficiaries.map(
                el => {
                  if (el.code === element.choiceTypeBeneficiary) {
                    beneficiary.choiceBeneficiary = el.description;
                  }
                }
              );
            }
          }
        });
        beneficiaries.push(beneficiary);
      }
    });
    console.log(beneficiaries, 'beneficiaries');
    anagBeneficiariesForCat.forEach((value: string[], key: string) => {
      const ben = new BeneficiaryModel();
      this.lifeSessionService.enumTypeBeneficiary.map((t) => {
        t.codice = parseInt(t.codice, 10);
        if (t.codice === Number(key)) {
          ben.roleDescription = t.descrizione;
        }
      });
      ben.namesAndPerc = value;
      beneficiaries.push(ben);
    });
    beneficiaries.map((subject) => {
      this.putGeneralDataOnTable(this.dynamicTableBeneficiaries,
        subject.roleDescription, subject.namesAndPerc != null ? subject.namesAndPerc : subject.choiceBeneficiary);
    });
  }

  private getBenefGroupingName(element: ChoiceBeneficiaryData): string {
    const groupingProp = element.extProperties &&
      element.extProperties.length &&
      element.extProperties.find(p => p.chiave === LicCustomProperties.POSITION_NUMBER_FOR_BENEFICIARIES);
    const groupingName = this.positionNumberEnums &&
      this.positionNumberEnums.values &&
      this.positionNumberEnums.values.find(p => groupingProp && p.codice === groupingProp.valore);

    return groupingName && `(${groupingName.descrizione})` || '';
  }

  getSubBenefRoleSummary(linkedLifeParties: any): string {
    let subSubjString = '(';
    if (!!linkedLifeParties && linkedLifeParties.length > 0) {
      linkedLifeParties.forEach(subRoleBenef => {
        if (subSubjString !== '(') {
          subSubjString += ', ';
        }
        subSubjString += subRoleBenef.party.extraInformations + ' ' +
          this.rgiFormatter.transform(
                    +subRoleBenef.percentageParty / 100,
                    this.policyService.currentLocale,
                    this.policyService.getFormatterOptionsWithDecimal('percent', '0.2-2')
                  );
      });
    }
    subSubjString += ')';

    if (subSubjString === '()') {
      subSubjString = null;
    }

    return subSubjString;
  }

  saveAndAcceptAuth() {
    this.eventPropagation.emit('startLoader');
    this.$subscriptions.push(this.policyService.saveFromQuote(false, this.questCacheManager, true).pipe(
      switchMap(respProposal => {
        this.policyService.mainProposal = respProposal.output;
        if (!!this.policyService.mainProposal.proposal.authorizationId) {
          return this.authService.getAuthorizationDetail(this.policyService.mainProposal.proposal.authorizationId);
        }
      }),
      switchMap(respAuthDetail => {
        if (!!respAuthDetail) {
          const eventID = respAuthDetail.acceptableEvents.find(accept => accept.id === '20').id;
          return this.authService.putAuthorizationStatus(
            {authorizationId: this.policyService.mainProposal.proposal.authorizationId, eventId: eventID, manualNotes: null});
        }
      }),
      catchError(error => {
        return of(error.message);
      })
    ).subscribe(lastOne => {
      this.eventPropagation.emit('stopLoader');
      if (typeof lastOne === 'string') {
        this.isError = true;
        this.openErrorModal(this.modalError);
      } else {
        this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
        this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
      }
    })
    );

  }


  saveAndAcceptAndIssue() {
    this.eventPropagation.emit('startLoader');
    this.$subscriptions.push(this.policyService.saveFromQuote(false, this.questCacheManager, true).pipe(
      switchMap(respProposal => {
        this.policyService.mainProposal = respProposal.output;
        if (!!this.policyService.mainProposal.proposal.authorizationId) {
          return this.authService.getAuthorizationDetail(this.policyService.mainProposal.proposal.authorizationId);
        }
      }),
      switchMap(respAuthDetail => {
        if (!!respAuthDetail) {
          const eventID = respAuthDetail.acceptableEvents.find(accept => accept.id === '20').id;
          return this.authService.putAuthorizationStatus(
            {authorizationId: this.policyService.mainProposal.proposal.authorizationId, eventId: eventID, manualNotes: null});
        }
      }),
      switchMap(() => {
        const proposal = this.policyService.mainProposal.proposal as Proposal;
        const userCode = this.lifeSessionService.getOperatorUsername();
        return this.policyService.lifeAuthsaveProposal(
          proposal.contractData[0].contractId,
          userCode,
          proposal.authorizationId);
      }),
      switchMap(() => {
        if (this.isContextualPolicyAndProposal) {
          const proposal = this.policyService.mainProposal.proposal as Proposal;
          const userCode = this.lifeSessionService.getOperatorUsername();
          return this.policyService.lifeAuthsavePolicy(
            proposal.contractData[0].contractId,
            userCode);
        } else {
          return of({});
        }
      }),
      catchError(error => {
        return of(error.message);
      })
    ).subscribe((lastOne: LifeSavePolicyResponse)  => {
      this.eventPropagation.emit('stopLoader');
      if (typeof lastOne === 'string') {
        this.isError = true;
        this.openErrorModal(lastOne);
      } else {
        const contractData: ContractData[] = [new ContractData(null, null), new ContractData(null, null)];
        if (this.isContextualPolicyAndProposal) {
          contractData[0].policyNumber = lastOne.lifePolicyNumber;
          contractData[0].contractId = lastOne.lifePolicyId;
          contractData[1].policyNumber = lastOne.damagePolicyNumber;
          contractData[1].contractId = lastOne.damagePolicyId;
          this.policyService.mainProposal.proposal.contractData = contractData;
          this.policyService.mainProposal.proposal.installment[0].movementId = lastOne.lifeMovementId;  // ASMC-8128
          this.policyService.setMovementID(lastOne.lifeMovementId);
          this.policyService.isSaveAcceptAndIssue = true;
        }
        this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
        this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
      }
    })
    );

  }

  onSubmit(itemClicked: {label: string, code: string}, proposalSaveModal = null) {
    this.policyService.flowTypeOnConfirm = itemClicked.code; // setto il tipo di azione
    this.policyService.productId = this.policyService.mainProposal.quote.productID;
    this.resetIntermediateSaveTrigger();
    switch (itemClicked.code) {
      case SaveActions.SAVE_ACCEPT:
        this.saveAndAcceptAuth();
        break;
      case SaveActions.SAVE_AUTH:
        this.saveAutho();
        break;
      case SaveActions.SAVE_CONTEXTUAL:
        this.saveProposalAndPolicy();
        break;
      case SaveActions.SAVE_FROM_AUTH:
        this.savePolicy();
        break;
      case SaveActions.SAVE_ACCEPT_POLICY_ISSUE:
      case SaveActions.SAVE_ACCEPT_PROP_ISSUE_CAC:
      case SaveActions.SAVE_ACCEPT_PROP_ISSUE:
        this.saveAndAcceptAndIssue();
        break;
      case SaveActions.SAVE_UNDEFINED_PROPOSAL:
        this.saveUndefinedProposal(proposalSaveModal);
        break;
      default:
        this.savePolicy();
        break;
    }
  }

  private resetIntermediateSaveTrigger() {
    const intermediateSave = this.policyService.getExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE);
    if (intermediateSave && intermediateSave.valore) {
      this.policyService.getExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE).valore = 'false';
    }
  }

  public saveUndefinedProposal(proposalSaveModal = null) {
    const intermediateSave = this.policyService.getExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE);
    if (intermediateSave) {
      this.policyService.getExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE).valore = 'true';
    } else {
      this.policyService.pushExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE, 'true');
    }

    this.eventPropagation.emit('startLoader');

    this.policyService.saveFromQuote(false, null, true).pipe(
      catchError(error => {
        return of(error.message);
      })
    ).subscribe(resp => {
      this.eventPropagation.emit('stopLoader');
      if (typeof resp === 'string') {
        this.isError = true;
        this.openErrorModal(resp);
      } else {
        this.policyService.mainProposal.proposal = resp.output.proposal;
        proposalSaveModal && proposalSaveModal.open();
        this.temporaryProposalNumber = this.policyService.mainProposal.proposal.contractData[0].proposalNumber;
      }
    });
  }

  setFlowButtons() {
    this.flowButtons = []; // label - code - disabled
    if (this.isFromAuth()) {
      if (this.saveAcceptIssue) { // RDDL-2610 - SALVA ACCETTA EMETTI
        if (this.isContextualPolicyAndProposal) {
          if (this.policyService.masterPolicyStatus === CAC) { // RDDL-4090
            this.flowButtons.push({label: 'lic_summary_save_accept_prop_issue', code: SaveActions.SAVE_ACCEPT_PROP_ISSUE_CAC});
          } else {
            this.flowButtons.push({label: 'lic_summary_save_accept_pol_issue', code: SaveActions.SAVE_ACCEPT_POLICY_ISSUE});
          }
        } else {
          if (this.policyService.masterPolicyStatus === CAC) { // RDDL-4090
            this.flowButtons.push({label: 'lic_summary_save_accept_prop_issue', code: SaveActions.SAVE_ACCEPT_PROP_ISSUE_CAC});
          } else {
            this.flowButtons.push({label: 'lic_summary_save_accept_prop_issue', code: SaveActions.SAVE_ACCEPT_PROP_ISSUE});
          }
        }
      }
      if (this.isSaveAndAcceptAuthProp) { // SALVA ACCETTA
        this.flowButtons.push({label: 'lic_summary_save_and_accept', code: SaveActions.SAVE_ACCEPT});
      }
      this.flowButtons.push({label: 'lic_save_button', code: SaveActions.SAVE_FROM_AUTH});
    } else if (this.actionsService.isAuthorization()) {
      this.flowButtons.push({label: 'lic_authorization_request', code: SaveActions.SAVE_AUTH});
    } else if (this.isContextualPolicyAndProposal) {
      if (this.policyService.masterPolicyStatus === CAC) { // RDDL-4090
        this.flowButtons.push({label: 'lic_ProposalIssue', code: SaveActions.SAVE_PROPOSAL_CAC});
      } else {
        this.flowButtons.push({label: 'lic_PolicyIssue', code: SaveActions.SAVE_CONTEXTUAL});
      }
    } else {
      if (this.policyService.masterPolicyStatus === CAC) { // RDDL-4090
        this.flowButtons.push({label: 'lic_ProposalIssue', code: SaveActions.SAVE_PROPOSAL_CAC});
      } else {
        this.flowButtons.push({label: 'lic_ProposalIssue', code: SaveActions.SAVE_PROPOSAL});
        this.flowButtons.push({label: 'lic_save_button', code: SaveActions.SAVE_UNDEFINED_PROPOSAL});
      }
    }
  }

  savePolicy() {
    this.eventPropagation.emit('startLoader');
    this.$subscriptions.push(this.policyService.saveFromQuote(false, this.questCacheManager, true).pipe(
      catchError(error => {
        return of(error.message);
      })
    ).subscribe((response) => {
      this.eventPropagation.emit('stopLoader');
      if (typeof response === 'string') {
        this.isError = true;
        this.openErrorModal(this.modalError);
      } else {
        this.policyService.mainProposal = response.output;
        this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
        this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
      }
    }));
  }

  countNumOfRows(tableUnit: any[]): number[] {
    return this.composeArray(Math.ceil(tableUnit.length / this.nCol));
  }

  saveProposalAndPolicy() {
    this.eventPropagation.emit('startLoader');
    this.$subscriptions.push(this.policyService.saveFromQuote(false, this.questCacheManager, true).pipe(
      switchMap(response => {
        this.policyService.mainProposal = response.output;
        return this.policyService.saveFromQuote(true);
      }),
      catchError(error => {
        return of(error.message);
      })
    ).subscribe((resp) => {
      this.eventPropagation.emit('stopLoader');
      if (typeof resp === 'string') {
        this.isError = true;
        this.openErrorModal(resp);
      } else {
        this.policyService.mainProposal = resp.result;
        this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
        this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
      }
    }));
  }

  saveAutho() {
    this.policyService.mainProposal.proposal.quotationMessages = this.getAllActionMessagesForIssue();
    this.eventPropagation.emit('startLoader');
    this.$subscriptions.push(this.policyService.saveFromQuote(false, this.questCacheManager, true).pipe(
      catchError(error => {
        return of(error.message);
      })
    ).subscribe((response) => {
      this.eventPropagation.emit('stopLoader');
      if (typeof response === 'string') {
        this.isError = true;
        this.openErrorModal(this.modalError);
      } else {
        this.policyService.mainProposal = response.output;
        this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
        this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
      }
    }));
  }

  // remove duplicated messages
  getAllActionMessagesForIssue(): ActionServiceErrorMessage[] {
    const finalMex: ActionServiceErrorMessage[] = [];
    this.actionsService.getAllActionMessages().forEach((m: ActionServiceErrorMessage) => {
      if (!finalMex.find((mx: ActionServiceErrorMessage) => mx.messageDescription === m.messageDescription)) {
        finalMex.push(m);
      }
    });
    return finalMex;
  }

  public isFromAuth() {
    return this.policyService.isFromAuth;
  }

  protected openErrorModal(error: string) {
    const dialog = this.modalService.open(LicIssueModalErrorComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    dialog.componentInstance.title = 'lic_error';
    dialog.componentInstance.message = error;
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  get positionNumberEnums(): EnumModel {
    return (this.lifeSessionService.otherEnums
        .find(e => e.code === ENUMS_KEYS.EPOSITIONNUMBER.EN || e.code === ENUMS_KEYS.EPOSITIONNUMBER.IT) || null);
  }

  private manageGroupingCoverture(keySum: string, dynamicDataWrappersInp: IUnitGroupWrapper[]) {

    let keyPropertyForGrouping = null;
    keyPropertyForGrouping = LicCustomProperties.LIFE_POLICY_COVERTURE_GROUPING;
    const isGroupingCov = LicObjectUtils.getPropertyBoleanValue(this.policyService.mainProposal.quote.product.customProperties,
      keyPropertyForGrouping);

    if (!isGroupingCov || !dynamicDataWrappersInp  || !dynamicDataWrappersInp.find(u => !!u.groupingValue)) {
      return dynamicDataWrappersInp;
    }

    const result: IUnitGroupWrapper[] = [];
    for (const dynamicDataWrapper of dynamicDataWrappersInp) {
      const foundedDataGroup = result.find(u => u.groupingValue === dynamicDataWrapper.groupingValue);
      if (!foundedDataGroup) {
        result.push(dynamicDataWrapper);
      } else {
        const keyValueCurr = this.getKeyValueObject(dynamicDataWrapper.dynamicTableUnit, keySum);
        if (!!keyValueCurr) {
          const keyValueSum = this.getKeyValueObject(foundedDataGroup.dynamicTableUnit, keySum);
          keyValueSum.valore = LicObjectUtils.getFloatConversion(keyValueSum.valore) +
                               LicObjectUtils.getFloatConversion(keyValueCurr.valore);
       }
      }
    }

    return result;
  }

  private manageCurrencyConversion(key: string, dynamicUnitWrapper: IUnitGroupWrapper) {

    const keyValue = this.getKeyValueObject(dynamicUnitWrapper.dynamicTableUnit, key);
    if (!!keyValue && !!keyValue.valore) {
      keyValue.valore = this.getCurrencyConversion(keyValue.valore);
    }

  }

  private getKeyValueObject(dynamicTableUnit: any[], key: any) {

    let result = null;
    for (const keyValue of dynamicTableUnit) {
      if (keyValue.chiave === key) {
         result =  keyValue;
      }
    }
    return result;
  }


}
