import { Component, OnInit, Input, Output, EventEmitter, Inject, LOCALE_ID } from '@angular/core';
import {
  EditablePanelContentComponent,
  PanelService,
  DateElement,
  AuthoritiesService,
  PanelSignal,
  Outcome,
  AddressesService,
  AddressData,
  PolicyService,
  MinisterialBranchesConstants,
  AddressTranslatorService,
  FunctionService,
  AlertService
} from '@rgi/digital-claims-common-angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ClaimOpeningService } from '../../../claim-opening.service';
import { ClaimOpeningDetailsService } from '../claim-opening-details.service';
import { OccurrenceData } from './dto/occurrence-data';
import { formatDate } from '@angular/common';
import { Authority, PanelStatus, ElemEnum, DamageLocation } from '@rgi/digital-claims-common-angular';
import { EnumType } from '@rgi/digital-claims-common-angular/lib/dto/enum-type';
import { DamageCategory } from '../../claim-opening-policy-search/dto/damage-categories';
import { Observable } from 'rxjs';
import { InjuryEntity } from './dto/injury-entity';
import { OpenTypeSharedComponent } from '../../../../shared/dsc-shared-model/dsc-model-enums';
import { AddressEntityData } from '../../../../shared/dsc-shared-model/dsc-model-address';
import { RgiRxSnackbarOverlay } from '@rgi/rx/ui';
import { RgiRxPushMessage } from '@rgi/rx';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
@Component({
  selector: 'claims-occurence-data-panel-content',
  templateUrl: './occurence-data-panel-content.component.html',
  styleUrls: ['./occurence-data-panel-content.component.scss']
})
export class OccurenceDataPanelContentComponent extends EditablePanelContentComponent implements OnInit {
  private static PROPERTY_CLAIMS_DEFAULT_AUTHORITY_BRANCHES: string = 'ClaimsDefaultAuthorityBranches';
  private static PROPERTY_LEGAL_PROTECTION_MANAGEMENT: string = 'ClaimsLegalProtectionManagement';
  private static PROPERTY_INJURY_CODE: string = 'ClaimInjuryCode';
  private static PROPERTY_ENABLED: string = 'Enable';
  private static CLAIMS_DEFAULT_AUTHORITY: string = '99';

  session: any;
  occurrenceDataForm: UntypedFormGroup;
  minDate: DateElement;
  minDateIntermediary: DateElement;
  countryCode: any = null;
  maxDate: DateElement;
  minDateIntermed: DateElement;
  authorities: Authority[];
  validationMessages: string[];
  emitter: any;
  municipality: any;
  internalSelectedCountry: any;
  cities: any[] = [];

  immutableCities: any[] = [];
  citiesCopy: any[] = [];
  numCapCities: number;
  zipCodeLookup: boolean = true;

  citiesFromResponse: any[] = [];
  countries: any[] = [];
  toponyms: any[] = [];
  zipCodes: any[] = [];
  internalSelectedToponym: any;
  occurrenceDate: Date;
  selectedAddress: DamageLocation;
  addressDisabled: boolean;
  addressInitialized: boolean;
  authorityControlFormValues = [];
  authorityControlFormDefaultValue: any;
  showCorporateLitigation = false;
  showNoteDirection = false;
  debugLogs = true;
  certifiedLossDateEnable: boolean = false;
  openingModes: Array<any>;
  openinModeEnable: boolean = false;
  catastrEvents: Array<any>;
  catastrEventEnable: boolean = false;
  isCategoriaRE: boolean = true;
  injuryCodeEnable: boolean = false;
  injuryCodeEntity: InjuryEntity;
  insuredActivities: any[] = [];
  policyModifyData: any;
  municipalityError: string = '';
  inserimentoManualeAbilitato = false;
  manualInsertionIntermediaryDateEnabled: boolean = false;
  panelStatus: PanelStatus;
  isForeign: boolean = this.authService.getDefaultContext() !== 'IT';
  isContextES: boolean = this.authService.getDefaultContext() === 'ES';
  enumTypeIsTitleComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isTitleComponent;
  addressData = new AddressEntityData();

  @Output() stepperEventEmitter = new EventEmitter<number>();
  @Output() editDone = new EventEmitter<boolean>();
  @Input() public card: any;
  @Input() damageCategory: DamageCategory;
  @Input() public pes: any;
  private debugLog: true;
  private occurrenceDataInternal: OccurrenceData;

  get selectedCity(): any {
    return this.municipality;
  }

  set selectedCity(v: any) {
    if (v) {
      this.municipality = v;
      this.occurrenceDataForm.get('selectedCity').setValue(v);
      // if user clears city select, no manual zipcode lookup
      if (this.municipality && !this.numCapCities) {
        this.zipCodeLookup = false;
        this.updateZipCodeList(this.citiesFromResponse[this.municipality.id].nomecomune);
        this.occurrenceDataForm.get('province').setValue(this.citiesFromResponse[this.municipality.id].descrizioneprovincia);
      } else if (this.municipality && this.numCapCities) {
        this.zipCodeLookup = true;
      } else if (!v && !this.numCapCities) {
        this.zipCodeLookup = true;
        this.occurrenceDataForm.get('province').setValue(null);
        this.occurrenceDataForm.get('zipCode').setValue(null);
        this.cities = this.immutableCities;
      } else if (!v && this.numCapCities) {
        this.zipCodeLookup = true;
        this.occurrenceDataForm.get('selectedCity').setValue(null);
      } else {
        this.numCapCities = 0;
        this.cities = this.immutableCities;
      }
    }
  }

  get selectedCountry() {
    return this.internalSelectedCountry;
  }

  set selectedCountry(v: any) {
    this.internalSelectedCountry = v;
    this.occurrenceDataForm.get('selectedCountry').setValue(v);
    if (v && v.id !== 'IT') {
      this.selectedCity = null;
      this.occurrenceDataForm.get('selectedCity').clearValidators();
      this.occurrenceDataForm.get('zipCode').clearValidators();
      this.occurrenceDataForm.get('selectedCity').updateValueAndValidity();
      this.occurrenceDataForm.get('zipCode').updateValueAndValidity();
      this.occurrenceDataForm.get('province').setValue(null);
      this.occurrenceDataForm.get('adminLevel1').setValue(null);
      this.occurrenceDataForm.get('adminLevel2').setValue(null);
      this.occurrenceDataForm.get('adminLevel3').setValue(null);
    } else {
      this.occurrenceDataForm.get('selectedCity').setValidators(Validators.required);
      this.occurrenceDataForm.get('zipCode').setValidators(Validators.required);
      this.occurrenceDataForm.get('selectedCity').updateValueAndValidity();
      this.occurrenceDataForm.get('zipCode').updateValueAndValidity();
    }
  }

  get selectedToponym() {
    return this.internalSelectedToponym;
  }

  set selectedToponym(v: any) {
    this.internalSelectedToponym = v;
    this.occurrenceDataForm.get('toponym').setValue(v);
  }

  @Input()
  set occurrenceData(occurrenceData: OccurrenceData) {
    this.occurrenceDataInternal = occurrenceData;
    if (!this.occurrenceDate) {
      this.initOccurrenceDate();
    }
    if (this.occurrenceDataInternal) {
      this.openPanel();
      this.disablePanelContent();
      this.setPanelComplete();
    } else {
      this.openPanel();
      this.disablePanelContent();
      this.setPanelComplete();
    }
    if (this.occurrenceDataForm) {
      if (occurrenceData) {
        this.fillOccurrenceDataForm(occurrenceData);
      }
    } else {
      this.initDataForm(occurrenceData);
    }
  }

  get occurrenceData(): OccurrenceData {
    return this.occurrenceDataInternal;
  }

  constructor(
    @Inject('sessionService') session: any,
    @Inject('eventService') private eventService: any,
    @Inject('authService') private authService,
    private snackbarService: RgiRxSnackbarOverlay,
    panelService: PanelService,
    public formBuilder: UntypedFormBuilder,
    public claimOpeningService: ClaimOpeningService,
    public authorityService: AuthoritiesService,
    public openingDetailsService: ClaimOpeningDetailsService,
    private alertService: AlertService,
    public addressService: AddressesService,
    public policyService: PolicyService,
    public ministerialConstants: MinisterialBranchesConstants,
    @Inject(LOCALE_ID) private locale: string,
    @Inject('getSystemProperty') private getSystemProperty: any,
    private addressTranslator: AddressTranslatorService,
    private functionService: FunctionService,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    super(panelService);
    this.emitter = eventService;
    this.session = session;
    const contractRes = claimOpeningService.getPolicyDetail();
    if (contractRes && contractRes.currentMovement.category) {
      this.isCategoriaRE = !(contractRes.currentMovement.category.codice === '1' ||
        contractRes.currentMovement.category.codice === '3' ||
        contractRes.currentMovement.category.codice === '4' ||
        contractRes.currentMovement.category.codice === '6');
    }
  }

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }

  occuranceDataFunction() {
    if (this.card) {
      this.policyModifyData = this.claimOpeningService.getPolicyModifyDataFromSession(this.card.id);
    }
    if (this.occurrenceData) {
      this.openPanel();
      this.disablePanelContent();
      this.setPanelComplete();
    } else {
      this.enableAndOpenContent();
      this.setPanelIncomplete();
    }
    this.initOccurrenceDate();
    this.initDataForm(this.occurrenceData);
    this.initInvolvedAuthorities();
    this.initDefaultAddress();
  }

  ngOnInit() {
    this.occuranceDataFunction();
    this.panelStatusRecieved(this.panelStatus);
    const listFunz = this.authService.getOperator().enabledFeatures;
    this.certifiedLossDateEnable = false;
    if (listFunz && listFunz.indexOf('SXDACE') >= 0) {
      this.certifiedLossDateEnable = true;
    }
    this.catastrEventEnable = false;
    if (listFunz && listFunz.indexOf('SXCTE') >= 0) {
      this.catastrEventEnable = true;
      const formatdate = 'dd-MM-yyyy';
      const dateString = formatDate(this.occurrenceDate, formatdate, this.locale);
      this.openingDetailsService.catastropheEvents(this.damageCategory.ministerialBranchCodes,
        dateString)
        .subscribe((response: any[]) => {
          this.catastrEvents = response;
        });
    }
    this.openinModeEnable = false;
    if (listFunz && listFunz.indexOf('SXOPMD') >= 0) {
      this.openinModeEnable = this.isCategoriaRE;
      if (this.openinModeEnable) {
        this.occurrenceDataForm.controls.idOpenMode.setValidators(Validators.required);
      }
      this.openingDetailsService.openingModes()
        .subscribe((response: any[]) => {
          this.openingModes = response;
        });
    }
    if (this.damageCategory) {
      this.getSystemProperty.findProperty(OccurenceDataPanelContentComponent.PROPERTY_INJURY_CODE).then((result) => {
        if (result && result.systemProperties && result.systemProperties.length) {
          for (const variable of result.systemProperties) {
            if (variable.key === OccurenceDataPanelContentComponent.PROPERTY_INJURY_CODE) {
              this.injuryCodeEnable = (variable.value === 'Enable');
              // if (this.injuryCodeEnable) {
              //  this.occurrenceDataForm.get('intermediaryDate').setValue(null);
              // }
              this.injuryCodeEntity = new InjuryEntity();
              this.injuryCodeEntity.ministerialBranch = this.damageCategory.ministerialBranchCodes[0];
            }
          }
        }
      });
    }
    const policy = this.claimOpeningService.getSessionSelectedPolicy(this.card.id);
    if (!this.claimOpeningService.getExistClMadePostuma() && policy.isCanceled) {
      const occurrenceDate = new Date(this.claimOpeningService.getSessionOccurrenceDate(this.card.id));
      const cancellationDate = new Date(policy.cancellationDate);
      if (occurrenceDate >= cancellationDate) {
        if (listFunz.indexOf('SXAPPA') > 0) {

          this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY').subscribe(
            (res) => {
              this.eventService.broadcastEvent('alertMsg',
                {
                  varMsg: res
                });
            })
        } else {
            this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY').subscribe(
              (res) => {
                this.eventService.broadcastEvent('alertMsg',
                  {
                    varMsg: res
                  });
              })
          const session = this.getCurrentSession();
          this.session.remove(session.idSession);
        }
      }
    }
    if (!this.claimOpeningService.getVisualizedMsgPostuma() &&
      this.claimOpeningService.getExistClMadePostuma()) {
        this.rxTranslationsService.translate('_CLAIMS_._TOL_CLAIMS_MADE').subscribe(
          (res) => {
            this.eventService.broadcastEvent('alertMsg',
              {
                varMsg: res
              });
          })
    }
    this.insuredActivities = this.claimOpeningService.getSessionInsuredActivities(this.card.id);
    if (this.policyModifyData) {
      if (this.validateContent()) {
        this.storeData();
      } else {
        // this.occurrenceDataForm.markAsPristine();
        this.validationMessages = [];
      }
    }
  }

  protected initOccurrenceDate() {

    const today = new Date();
    this.occurrenceDate = this.claimOpeningService.getSessionOccurrenceDate(this.card.id) ?
      new Date(this.claimOpeningService.getSessionOccurrenceDate(this.card.id)) : null;
    this.minDate = this.occurrenceDate ?
      new DateElement(this.occurrenceDate.getFullYear(), this.occurrenceDate.getMonth() + 1, this.occurrenceDate.getDate()) : null;
    this.maxDate = new DateElement(today.getFullYear(), today.getMonth() + 1, today.getDate());
    this.minDateIntermediary = this.occurrenceDate ?
      new DateElement(this.occurrenceDate.getFullYear(), this.occurrenceDate.getMonth() + 1, this.occurrenceDate.getDate()) : null;

    this.minDateIntermed = this.minDate;

  }

  defMinDateIntermediary() {
    this.minDateIntermediary = this.occurrenceDataForm.controls.complaintDate.value ?
      new DateElement(this.occurrenceDataForm.controls.complaintDate.value.getFullYear(),
        this.occurrenceDataForm.controls.complaintDate.value.getMonth() + 1,
        this.occurrenceDataForm.controls.complaintDate.value.getDate()) : null;
  }

  protected initInvolvedAuthorities() {
    const that = this;
    this.authorityService.getInvolvedAuthorities().subscribe(
      (response: Authority[]) => {
        this.authorities = response;
        this.manageAuthorityControlFormValues().subscribe((values) => {

          if (this.policyModifyData) {

            if (this.policyModifyData.involvedAuthorityCode) {
              this.occurrenceDataForm.controls.authority.setValue(this.policyModifyData.involvedAuthorityCode);
            } else {
              this.occurrenceDataForm.controls.authority.setValue(OccurenceDataPanelContentComponent.CLAIMS_DEFAULT_AUTHORITY);
            }

          } else {
            if (this.occurrenceData && this.occurrenceData.authorityCode) {
              that.occurrenceDataForm.controls.authority.setValue(this.occurrenceData.authorityCode);
            } else if (that.authorityControlFormDefaultValue && that.occurrenceDataForm) {
              that.occurrenceDataForm.controls.authority.setValue(that.authorityControlFormDefaultValue);
            }
          }
        });
      },
      (error: Error) => {
        console.error(error);
      }
    );
  }

  public codeAuthorityF() {
    this.occurrenceDataForm.get("authority").setValue(this.occurrenceDataForm.get("authority").value);
  }


  protected initCountries() {
    this.retrieveCountries(this.selectedAddress ? this.selectedAddress.nationAbbreviation : null);
  }

  protected retrieveCountries(selectedCountry?: string) {
    if (this.occurrenceDate) {
      this.addressService.getCountries(this.occurrenceDate).subscribe(
        (response: ElemEnum[]) => {
          for (const element of response) {
            this.countries.push({ id: element.codice, value: element.descrizione });
            if (!this.selectedCountry && (element.codice === 'IT' ||
              selectedCountry === element.codice)) {
              this.selectedCountry = { id: element.codice, value: element.descrizione };
            }
          }
        });
    }
  }

  protected selectCountry(nationAbbreviation: string) {
    if (this.countries && this.countries.length) {
      for (const country of this.countries) {
        if (country.id === 'IT' || nationAbbreviation === country.id) {
          this.selectedCountry = country;
        }
      }
    } else {
      this.retrieveCountries(nationAbbreviation);
    }
  }

  protected initCities() {
    this.retrieveCities(this.selectedAddress);
  }

  protected retrieveCities(damageLocation?: DamageLocation) {
    let selectedCity: any;
    this.addressService.getCities().subscribe(
      (response: any[]) => {
        let index = 0;
        for (const element of response) {
          const city = { id: index, value: element.description };
          this.cities.push(city);
          this.citiesFromResponse[index] = element;
          element.id = index++;
          const comuneLista: string = element.nomecomune;
          if (damageLocation != null &&
            element.nomecomune === damageLocation.adminLevel3 &&
            element.siglaprovincia === damageLocation.province) {
            selectedCity = city;

          }
        }
        if (selectedCity) {
          this.selectedCity = selectedCity;
        }
      });
  }

  protected selectCityForAddressData(occurrenceAddress: AddressData) {
    if (this.citiesFromResponse && this.citiesFromResponse.length) {
      for (const city of this.citiesFromResponse) {
        if (city.nomecomune === occurrenceAddress.adminLevel3 &&
          city.siglaprovincia === occurrenceAddress.province) {
          const selCity = { id: city.id, value: city.description };
          this.selectedCity = selCity;
        }
      }
    } else {
      this.retrieveCities(this.addressTranslator.addressDataToDamageLocation(occurrenceAddress));
    }
  }

  protected initToponym() {
    this.retrieveToponyms(this.selectedAddress && this.selectedAddress.idToponym ? this.selectedAddress.idToponym.description : null);
  }

  protected retrieveToponyms(toponymId?: string) {
    if (this.occurrenceDate) {
      let selectedToponym: any;
      this.addressService.getToponyms(this.occurrenceDate).subscribe(
        (response: ElemEnum[]) => {
          for (const element of response) {
            const toponym = { id: element.codice, value: element.descrizione };
            this.toponyms.push(toponym);
            if (element.descrizione === toponymId) {
              selectedToponym = toponym;
            }
          }
          if (selectedToponym) {
            this.selectedToponym = selectedToponym;
          }
        }
      );
    }
  }

  protected selectToponym(toponymId: string) {
    if (this.toponyms && this.toponyms.length) {
      if (toponymId) {
        for (const toponym of this.toponyms) {
          if (toponym.value === toponymId) {
            this.selectedToponym = toponym;
          }
        }
      }
    } else {
      this.retrieveToponyms(toponymId);
    }
  }

  protected updateZipCodeList(value: string) {
    this.zipCodes = [];
    this.addressService.getZipCodes(value).subscribe(
      (response: ElemEnum[]) => {
        for (const element of response) {
          this.zipCodes.push({ id: element.codice, value: element.descrizione });
        }
        if (!this.occurrenceDataForm.get('zipCode').value) {
          this.occurrenceDataForm.get('zipCode').setValue(this.zipCodes[0] ? this.zipCodes[0].id : null);
        }
      });
  }

  protected initDataForm(occurrenceData?: OccurrenceData) {

    if (this.card) {
      this.policyModifyData = this.claimOpeningService.getPolicyModifyDataFromSession(this.card.id);
    }

    if (!this.occurrenceDataForm) {
      if (occurrenceData) {
        this.setupOccurrenceDataForm(occurrenceData);
        this.occurrenceDataForm.get('province').enable();
      } else {
        this.occurrenceDataForm = this.getEmptyOccurrenceDataForm();
        this.occurrenceDataForm.get('province').disable();
        this.occurrenceDataForm.get('intermediaryDate').setValue(null);
      }
      this.occurrenceDataForm.get('occurrenceDate').disable();
      this.occurrenceDataForm.get('occurrenceTime').disable();
    }
  }

  protected setupOccurrenceDataForm(occurrenceData: OccurrenceData) {
    this.initOccurrenceDate();
    this.occurrenceDataForm = this.getNewOccurrenceDataForm(occurrenceData);
    this.selectAddressFromData(occurrenceData.occurrenceAddress);
    this.occurrenceDataForm.controls.authority.setValue(occurrenceData.authorityCode);
  }

  handleKeyUpZipcode(event) {
    if (event.target.value.length === 5) {
      this.zipCodeLookup = true;
      const tmpArr = [];
      const valZip = event.target.value;
      tmpArr.push({ id: event.target.value, value: event.target.value });
      this.occurrenceDataForm.get('zipCode').setValue(valZip);

      // const insertedZipCode = this.occurrenceDataForm.get('zipCode').value;
      const damageLocation = this.selectedAddress;
      // console.log(insertedZipCode);
      this.claimOpeningService.getCitiesFromZipCode(valZip).subscribe(res => {
        // 1 CAP --> 1 COMUNE
        if (res.length === 1) {
          // this.cities = [];
          this.numCapCities = 1;
          this.occurrenceDataForm.get('zipCode').setValue(valZip);
          const element = res[0];
          this.occurrenceDataForm.get('province').setValue(element.descrizioneprovincia);
          const city = { id: 0, value: element.description };
          this.selectedCity = city;
          // 1 CAP --> N COMUNI
        } else if (res.length > 1) {
          /*comuni sotto lo stesso cap sono nella stessa provincia*/
          this.numCapCities = res.length;
          this.snackbarService.notify(new RgiRxPushMessage(
            'Risultano più comuni sotto il CAP selezionato. Scegliere un comune', {
            status: 'warning',
            delay: 2000,
          }
          ));
          // save all cities in temp array
          // this.citiesCopy = [...this.cities];
          this.cities = [];

          const tmp = res[0];
          this.occurrenceDataForm.get('zipCode').setValue(valZip);
          this.occurrenceDataForm.get('province').setValue(tmp.descrizioneprovincia);
          this.occurrenceDataForm.get('selectedCity').setValue(null);

          // this.selectedCity = null;
          // save all cities on temporary array, so that you can go back to previous
          // list after a CAP with multiple cities has been selected

          for (const element of res) {
            let index = 0;
            // let found = false;
            // for (const element of res) {
            const city = { id: index, value: element.description };
            this.cities.push(city);
            // this.citiesFromResponse[index] = element;
            element.id = index++;
          }
          //  NO COMUNI ASSOCIATI AL CAP
        } else if (res.length === 0) {
          this.numCapCities = 0;
          this.snackbarService.notify(new RgiRxPushMessage(
            'Non risultano comuni con il CAP richiesto', {
            status: 'warning',
            delay: 3000,
          }
          ));
          this.selectedCity = null;
          this.occurrenceDataForm.get('zipCode').setValue(null);
          this.occurrenceDataForm.get('province').setValue(null);
          this.cities = this.immutableCities;
        }

      });
    } else {
      // put cities as they were before
      this.numCapCities = 0;
      this.cities = this.immutableCities;
    }
  }


  protected getEmptyOccurrenceDataForm() {

    if (this.policyModifyData) {


      return this.formBuilder.group({
        occurrenceDate: [new Date(this.policyModifyData.occurrenceDate)],
        occurrenceTime: [this.policyModifyData.occurrenceHour],
        complaintDate: [new Date(this.policyModifyData.potentialClaimDate), Validators.required],
        authority: [this.getAuthorityControlFormValues()],
        certifiedOccurrence: [],
        // if manual date insertion not enabled, remove validator required, so it doesnt block flow
        intermediaryDate: this.manualInsertionIntermediaryDateEnabled ?
          [this.policyModifyData.intermediaryDate ? new Date(this.policyModifyData.intermediaryDate) : null, Validators.required] :
          [this.policyModifyData.intermediaryDate ? new Date(this.policyModifyData.intermediaryDate) : null],

        corporateLitigation: [this.policyModifyData.openingActFollowedQuote],
        occurrenceDescription: [this.policyModifyData.occurrenceDescription],
        noteDirection: [this.policyModifyData.managementNote],
        selectedCity: [],
        zipCode: [],
        address: [],
        houseNumber: [],
        selectedCountry: [],
        toponym: [],
        province: [],
        adminLevel1: [],
        adminLevel2: [],
        adminLevel3: [],
        auditDate: [],
        idOpenMode: [],
        idCatastrEvent: [],
        insuredActivity: [],
        externalNumber: [],
      }, {
        // validator: [this.validateDate('complaintDate'), this.validateDate('intermediaryDate')]
      });


    } else {

      return this.formBuilder.group({
        occurrenceDate: [new Date(this.claimOpeningService.getSessionOccurrenceDate(this.card.id))],
        occurrenceTime: [this.claimOpeningService.getSessionTimeRequest(this.card.id) ?
          this.claimOpeningService.getSessionTimeRequest(this.card.id).toString() : null],
        complaintDate: [, Validators.required],
        intermediaryDate: this.manualInsertionIntermediaryDateEnabled ? [, Validators.required] : [],
        authority: [this.getAuthorityControlFormValues(), Validators.required],
        certifiedOccurrence: [],
        corporateLitigation: [],
        occurrenceDescription: [],
        noteDirection: [],
        selectedCity: [],
        zipCode: [],
        address: [],
        houseNumber: [],
        selectedCountry: [, Validators.required],
        toponym: [],
        province: [],
        adminLevel1: [],
        adminLevel2: [],
        adminLevel3: [],
        auditDate: [],
        idOpenMode: [],
        idCatastrEvent: [],
        insuredActivity: [],
        // intermediaryDate: [, Validators.required]

        externalNumber: []
      }, {
        validator: [this.validateDate('complaintDate'), this.validateDate('intermediaryDate')]
      });

    }
  }

  protected getNewOccurrenceDataForm(occurrenceData: OccurrenceData) {
    return this.formBuilder.group({
      occurrenceDate: [new Date(this.claimOpeningService.getSessionOccurrenceDate(this.card.id))],
      occurrenceTime: [this.claimOpeningService.getSessionTimeRequest(this.card.id) ?
        this.claimOpeningService.getSessionTimeRequest(this.card.id).toString() : null],
      complaintDate: [new Date(occurrenceData.reportDate), Validators.required],
      authority: [this.getAuthorityControlFormValues(), Validators.required],
      certifiedOccurrence: [occurrenceData.certifiedOccurrence],
      corporateLitigation: [occurrenceData.corporateLitigation],
      occurrenceDescription: [occurrenceData.occurrenceDescription],
      noteDirection: [occurrenceData.noteDirection],
      selectedCity: [],
      zipCode: [],
      address: [],
      houseNumber: [occurrenceData.occurrenceAddress.houseNumber],
      selectedCountry: [, Validators.required],
      toponym: [],
      province: [],
      adminLevel1: [],
      adminLevel2: [],
      adminLevel3: [],
      auditDate: [],
      idOpenMode: [],
      idCatastrEvent: [],
      insuredActivity: [],
      intermediaryDate: this.manualInsertionIntermediaryDateEnabled ? [, Validators.required] : [],
      externalNumber: []
    }, {
      validator: [this.validateDate('complaintDate'), this.validateDate('intermediaryDate')]
    });
  }

  protected fillOccurrenceDataForm(occurrenceData: OccurrenceData) {
    this.occurrenceDataForm.get('occurrenceDate').setValue(new Date(this.claimOpeningService.getSessionOccurrenceDate(this.card.id)));
    this.occurrenceDataForm.get('occurrenceTime').setValue(this.claimOpeningService.getSessionTimeRequest(this.card.id) ?
      this.claimOpeningService.getSessionTimeRequest(this.card.id).toString() : null);
    this.occurrenceDataForm.get('complaintDate').setValue(new Date(occurrenceData.reportDate));
    this.occurrenceDataForm.get('intermediaryDate').setValue(new Date(occurrenceData.intermediaryDate));
    this.occurrenceDataForm.get('authority').setValue(occurrenceData.authorityCode);
    this.occurrenceDataForm.get('occurrenceDescription').setValue(occurrenceData.occurrenceDescription);
    this.occurrenceDataForm.get('noteDirection').setValue(occurrenceData.noteDirection);
    this.occurrenceDataForm.get('certifiedOccurrence').setValue(occurrenceData.certifiedOccurrence);
    this.occurrenceDataForm.get('corporateLitigation').setValue(occurrenceData.corporateLitigation);
    this.occurrenceDataForm.get('selectedCity').setValue(occurrenceData.occurrenceAddress.city);
    this.occurrenceDataForm.get('adminLevel1').setValue(occurrenceData.occurrenceAddress.adminLevel1);
    this.occurrenceDataForm.get('adminLevel2').setValue(occurrenceData.occurrenceAddress.adminLevel2);
    this.occurrenceDataForm.get('adminLevel3').setValue(occurrenceData.occurrenceAddress.adminLevel3);
    this.occurrenceDataForm.get('province').setValue(occurrenceData.occurrenceAddress.province);
    this.occurrenceDataForm.get('zipCode').setValue(occurrenceData.occurrenceAddress.zipCode);
    this.occurrenceDataForm.get('houseNumber').setValue(occurrenceData.occurrenceAddress.houseNumber);
    this.occurrenceDataForm.get('address').setValue(occurrenceData.occurrenceAddress.address);

    this.selectCountry(occurrenceData.occurrenceAddress.countryCode);
    this.selectCityForAddressData(occurrenceData.occurrenceAddress);
    this.selectToponym(occurrenceData.occurrenceAddress.toponym);

    this.occurrenceDataForm.get('externalNumber').setValue(occurrenceData.externalNumber);

    this.occurrenceDataForm.get('province').enable();
  }

  protected initDefaultAddress() {

    const that = this;
    this.showCorporateLitigation = false;
    this.showNoteDirection = false;
    if (this.policyModifyData) {

      that.showCorporateLitigation = this.policyModifyData.isLegalProtectionEnabled === true;
      if (that.showCorporateLitigation) {
        that.showNoteDirection = this.functionService.isAuthorizedForClaimFeature('NOTE_DIRECTION');
      }

      this.setAddress();

      // address: "VIA TORINO"
      // adminLevel2: "BRESCIA"
      // adminLevel3: "ALFIANELLO"
      // city: "ALFIANELLO"
      // formatAddress: "VIA TORINO, 37 - 25020 ALFIANELLO (BS) - IT"
      // houseNumber: "37"
      // nationAbbreviation: "IT"
      // province: "BS"
      // zipCode: "25020"

      if (this.selectedAddress && this.selectedAddress.nationAbbreviation === 'IT') {


        this.occurrenceDataForm.controls.selectedCountry.setValue(this.selectedAddress.nationAbbreviation);

        this.occurrenceDataForm.controls.adminLevel3.setValue(this.selectedAddress.adminLevel3);

        this.occurrenceDataForm.controls.adminLevel2.setValue(this.selectedAddress.province);

        this.occurrenceDataForm.controls.adminLevel1.setValue(this.selectedAddress.nationAbbreviation);

        this.occurrenceDataForm.controls.province.setValue(this.selectedAddress.province);

        this.occurrenceDataForm.controls.address.setValue(this.selectedAddress.address);

        this.occurrenceDataForm.controls.zipCode.setValue(this.selectedAddress.zipCode);

        this.occurrenceDataForm.controls.selectedCity.setValue(this.selectedAddress.city);

      }

    } else {
      this.getSystemProperty.findProperty(OccurenceDataPanelContentComponent.
        PROPERTY_LEGAL_PROTECTION_MANAGEMENT).then((result) => {
          if (result && result.systemProperties && result.systemProperties.length) {
            for (const variable of result.systemProperties) {
              if (variable.key === OccurenceDataPanelContentComponent.PROPERTY_LEGAL_PROTECTION_MANAGEMENT) {
                if (variable.value) {
                  that.showCorporateLitigation = OccurenceDataPanelContentComponent.PROPERTY_ENABLED === variable.value;
                  if (that.showCorporateLitigation) {
                    that.showNoteDirection = this.functionService.isAuthorizedForClaimFeature('NOTE_DIRECTION');
                  }
                }
              }
            }
          }
          this.setAddress();
        });
    }
  }

  private setAddress() {
    if (!this.selectedAddress) {
      const policy = this.claimOpeningService.getSessionSelectedPolicy(this.card.id);
      const assets = policy.assets;
      if (assets && assets.length === 1 && assets[0].locations && assets[0].locations.length > 0) {
        const locations = assets[0].locations;
        this.addressInitialized = true;
        this.policyService.getAssetType(assets[0].code).subscribe(
          (response: EnumType) => {
            this.selectedAddress = assets[0].locations[0];
            this.initAddress();
            this.manageAddressEditing(response.codice);
          });
      } else if (policy.residenceHolder && this.showCorporateLitigation) {
        this.addressInitialized = true;
        this.selectedAddress = policy.residenceHolder;
        // this.initAddress();
        this.selectAddress(this.selectedAddress);
      }
    }
    if (!this.addressInitialized) {
      this.initAddress();
    }
  }

  initAddress() {
    this.initCountries();
    this.initCities();
    this.initToponym();
    if (this.selectedAddress) {
      this.occurrenceDataForm.controls.address.setValue(this.selectedAddress.address);
      this.occurrenceDataForm.controls.houseNumber.setValue(this.selectedAddress.houseNumber);
    }
    this.addressInitialized = true;
  }

  protected selectAddressFromData(address: AddressData) {
    this.selectAddress(this.addressTranslator.addressDataToDamageLocation(address));
  }

  protected selectAddress(address: DamageLocation): any {
    this.selectedAddress = address;
    this.initCountries();
    this.initCities();
    this.initToponym();
    this.occurrenceDataForm.controls.address.setValue(this.selectedAddress.address),
      this.occurrenceDataForm.controls.houseNumber.setValue(this.selectedAddress.houseNumber),
      this.occurrenceDataForm.get('zipCode').setValue(this.selectedAddress.zipCode);
  }

  protected manageAddressEditing(assetType: string) {
    if (assetType === 'BUILDING') {
      if (this.damageCategory.ministerialBranchCodes.indexOf(
        this.ministerialConstants.COD_RAMO_RCGENERALE) === -1) {
        this.addressDisabled = true;
        this.disableAddress();
      }
    }
    if (this.municipalityError) {
      this.occurrenceDataForm.get('selectedCity').enable();
    }
  }

  protected validateDate(controlName: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const ctrlNameAudit = 'auditDate';
      const control2 = formGroup.controls[ctrlNameAudit];
      const ctrlNameintermediaryDate = 'intermediaryDate';
      const control3 = formGroup.controls[ctrlNameintermediaryDate];

      const min: Date = new Date(this.claimOpeningService.getSessionOccurrenceDate(this.card.id));
      let minInter = min;
      if (controlName === 'intermediaryDate') {
        const ctrlNamecomplaintDate = 'complaintDate';
        const controlComplaintDate = formGroup.controls[ctrlNamecomplaintDate];
        minInter = controlComplaintDate.value ? new Date(controlComplaintDate.value) : min;
      } else {
        minInter = control.value ? new Date(control.value) : min;
      }

      this.controlData(control, min);
      this.controlData(control3, minInter);
      control2.setErrors(null);

    };
  }

  controlData(control: any, min: Date) {
    if (control.value) {
      if (control.value instanceof Date) {

        const inputDate = control.value;
        inputDate.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (inputDate > currentDate) {
          control.setErrors({ futureDate: true });
        } else if (inputDate < min) {
          control.setErrors({ tooOld: true });
        } else {
          control.setErrors(null);
        }
      } else {
        control.setErrors({ invalidDate: true });
      }
    } else {
      const validator: any = control.validator && control.validator(new UntypedFormControl());

      if (validator && validator.required) {
        control.setErrors({ required: true });
      } else {
        control.setErrors(null);
      }
    }
  }

  onConfirm() {
    if (this.validateContent()) {
      this.storeData();
      this.municipalityError = '';
    }
    this.editDone.emit(true);
  }
  protected validateContent(): boolean {
    this.validationMessages = [];
    let validate: boolean = true;
    if (!this.isForeign) {
      this.occurrenceDataForm.controls.selectedCountry.markAsDirty();
      this.occurrenceDataForm.controls.selectedCity.markAsDirty();
      this.occurrenceDataForm.controls.zipCode.markAsDirty();
      this.occurrenceDataForm.controls.adminLevel1.markAsDirty();
      this.occurrenceDataForm.controls.adminLevel2.markAsDirty();
      this.occurrenceDataForm.controls.adminLevel3.markAsDirty();
    }

    if (this.manualInsertionIntermediaryDateEnabled) {
      this.occurrenceDataForm.controls.intermediaryDate.markAsDirty();
    }
    if(this.countryCode == "ES"){
        if((this.occurrenceDataForm.controls.province.value==null ||this.occurrenceDataForm.controls.province.value =="" || this.occurrenceDataForm.controls.province.value== undefined) ||
          (this.occurrenceDataForm.controls.selectedCity.value==null || this.occurrenceDataForm.controls.selectedCity.value=="" || this.occurrenceDataForm.controls.selectedCity.value==undefined ) ){
          validate= false;
        }
    }
    if((!this.occurrenceDataForm.controls.complaintDate.valid))
       validate = false;
    if((!this.occurrenceDataForm.controls.authority.valid))
       validate = false;
    if(!validate){
       this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(res =>this.validationMessages.push(res));
    }
    if (this.injuryCodeEnable && this.injuryCodeEntity.ministerialBranch === '000013')
      this.occurrenceDataForm.controls.departmentInjury.markAsDirty();
    if (this.occurrenceDataForm.controls.typeOfLossInjury) 
      this.occurrenceDataForm.controls.typeOfLossInjury.markAsDirty();
    if (this.occurrenceDataForm.controls.matterTypeInjury) {
      this.occurrenceDataForm.controls.matterTypeInjury.markAsDirty();
    }
 
    return validate;
  }
  /*
  protected validateContent(): boolean {
    this.validationMessages = [];
    this.occurrenceDataForm.controls.complaintDate.markAsDirty();
    // check made only if the user is enabled to manually modify the intermediary date
    if (this.manualInsertionIntermediaryDateEnabled) {
      this.occurrenceDataForm.controls.intermediaryDate.markAsDirty();
    }
    this.occurrenceDataForm.controls.authority.markAsDirty();
    if (!this.isForeign) {
      this.occurrenceDataForm.controls.selectedCountry.markAsDirty();
      this.occurrenceDataForm.controls.selectedCity.markAsDirty();
      this.occurrenceDataForm.controls.zipCode.markAsDirty();
      this.occurrenceDataForm.controls.adminLevel1.markAsDirty();
      this.occurrenceDataForm.controls.adminLevel2.markAsDirty();
      this.occurrenceDataForm.controls.adminLevel3.markAsDirty();
    }
    // else{
    //   this.occurrenceDataForm.controls.selectedCity.setValue("Prova");
    //   this.occurrenceDataForm.controls.zipCode.setValue('00000');
    // }
    if (this.injuryCodeEnable && this.injuryCodeEntity.ministerialBranch === '000013') {
      this.occurrenceDataForm.controls.departmentInjury.markAsDirty();
    }

    if (this.occurrenceDataForm.controls.typeOfLossInjury) {
      this.occurrenceDataForm.controls.typeOfLossInjury.markAsDirty();
    }

    if (this.occurrenceDataForm.controls.matterTypeInjury) {
      this.occurrenceDataForm.controls.matterTypeInjury.markAsDirty();
    }
    this.occurrenceDataForm.controls.idOpenMode.markAsDirty();
    if (!this.occurrenceDataForm.valid) {
      this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(
        res => this.validationMessages.push(res)
      )
    }
    return this.occurrenceDataForm.valid;
  }
*/
  // protected getAddressData(): AddressData {

  //   let countryCode = this.authService.getDefaultContext();
  //   let city = null;
  //   let adminLevel1 = null;
  //   let adminLevel2 = null;
  //   let adminLevel3 = null;
  //   let toponym = null;
  //   let province = null;
  //   let zipCode = null;
  //   let address = null;
  //   let houseNumber = null;

  //   if (this.policyModifyData && !countryCode) {

  //     if(!this.isForeign){
  //       city = this.occurrenceDataForm.controls.adminLevel3.value;
  //       adminLevel1 = this.occurrenceDataForm.controls.adminLevel1.value;
  //       adminLevel2 = this.occurrenceDataForm.controls.adminLevel2.value;
  //       adminLevel3 = this.occurrenceDataForm.controls.adminLevel3.value;
  //       toponym = this.occurrenceDataForm.controls.toponym.value;
  //       province = this.occurrenceDataForm.controls.province.value;
  //       zipCode = this.occurrenceDataForm.controls.zipCode.value;
  //       address = this.occurrenceDataForm.controls.address.value;
  //       houseNumber =this.occurrenceDataForm.controls.houseNumber.value;
  //     }else{
  //       city = this.occurrenceDataForm.controls.addressForm.value.adminLevel3;
  //       adminLevel1 = this.occurrenceDataForm.controls.addressForm.value.adminLevel1;
  //       adminLevel2 = this.occurrenceDataForm.controls.addressForm.value.adminLevel2;
  //       adminLevel3 = this.occurrenceDataForm.controls.addressForm.value.adminLevel3;
  //       toponym = this.occurrenceDataForm.controls.addressForm.value.toponym;
  //       province = this.occurrenceDataForm.controls.addressForm.value.adminLevel2;
  //       zipCode = this.occurrenceDataForm.controls.addressForm.value.zip;
  //       address = this.occurrenceDataForm.controls.addressForm.value.address;
  //       houseNumber =this.occurrenceDataForm.controls.addressForm.value.houseNumber;
  //     }

  //   } else {
  //     if(!this.isForeign){
  //       city = this.citiesFromResponse[this.municipality.id].nomecomune;
  //       adminLevel1 = null;
  //       adminLevel2 = this.citiesFromResponse[this.municipality.id].siglaprovincia;
  //       adminLevel3 = this.citiesFromResponse[this.municipality.id].nomecomune;
  //       toponym = this.occurrenceDataForm.controls.toponym.value.value;
  //       province = this.citiesFromResponse[this.municipality.id].siglaprovincia;
  //       zipCode = this.occurrenceDataForm.controls.zipCode.value;
  //       address = this.occurrenceDataForm.controls.address.value;
  //       houseNumber =this.occurrenceDataForm.controls.houseNumber.value;
  //     }else{
  //       city = this.occurrenceDataForm.controls.addressForm.value.adminLevel3;
  //       adminLevel1 = this.occurrenceDataForm.controls.addressForm.value.adminLevel1;
  //       adminLevel2 = this.occurrenceDataForm.controls.addressForm.value.adminLevel2;
  //       adminLevel3 = this.occurrenceDataForm.controls.addressForm.value.adminLevel3;
  //       toponym = this.occurrenceDataForm.controls.addressForm.value.toponym;
  //       province = this.occurrenceDataForm.controls.addressForm.value.adminLevel2;
  //       zipCode = this.occurrenceDataForm.controls.addressForm.value.zip;
  //       address = this.occurrenceDataForm.controls.addressForm.value.address;
  //       houseNumber =this.occurrenceDataForm.controls.addressForm.value.houseNumber;
  //     }
  //   }

  //   return new AddressData(
  //     countryCode,
  //     zipCode,
  //     city,
  //     address,
  //     houseNumber,
  //     adminLevel1,
  //     adminLevel2,
  //     adminLevel3,
  //     toponym,
  //     province
  //   );
  // }

  protected storeData() {
    const formatdate = 'dd-MM-yyyy';
    const dateString = formatDate(this.occurrenceDataForm.controls.complaintDate.value, formatdate, this.locale);
    this.claimOpeningService.setCompliantDate(this.occurrenceDataForm.controls.complaintDate.value);
    const dateAuditString = this.occurrenceDataForm.controls.auditDate.value ?
      formatDate(this.occurrenceDataForm.controls.auditDate.value, formatdate, this.locale) : null;
    const dateIntermediaryString = this.occurrenceDataForm.controls.intermediaryDate.value ?
      formatDate(this.occurrenceDataForm.controls.intermediaryDate.value, formatdate, this.locale) : dateString;


    if (this.policyModifyData && (this.occurrenceDataForm.controls.authority &&
      this.occurrenceDataForm.controls.authority.value &&
      this.occurrenceDataForm.controls.authority.value.length === 0)) {
      this.occurrenceDataForm.controls.authority.setValue(
        this.policyModifyData.involvedAuthorityCode ?
          this.policyModifyData.involvedAuthorityCode :
          OccurenceDataPanelContentComponent.CLAIMS_DEFAULT_AUTHORITY);
    }

    const data = new OccurrenceData(
      this.claimOpeningService.getSessionUuid(this.card.id),
      dateString,
      // intermedDate,
      this.occurrenceDataForm.controls.authority.value,
      this.getAddressData(),
      this.occurrenceDataForm.controls.occurrenceDescription.value,
      this.occurrenceDataForm.controls.noteDirection.value,
      this.occurrenceDataForm.controls.certifiedOccurrence.value,
      this.occurrenceDataForm.controls.corporateLitigation.value,
      this.occurrenceDataForm.controls.idOpenMode.value,
      this.occurrenceDataForm.controls.idCatastrEvent.value,
      dateAuditString,
      this.claimOpeningService.getExistClMadePostuma(),
      dateIntermediaryString,
      this.injuryCodeEnable ? this.occurrenceDataForm.controls.departmentInjury.value : null,
      this.injuryCodeEnable ? this.occurrenceDataForm.controls.typeOfLossInjury.value : null,
      this.injuryCodeEnable ? this.occurrenceDataForm.controls.matterTypeInjury.value : null,
      this.injuryCodeEnable ? this.occurrenceDataForm.controls.injuryNode.value : null,
      this.injuryCodeEnable ? this.occurrenceDataForm.controls.noteInjury.value : null,
      this.injuryCodeEnable ? this.occurrenceDataForm.controls.allegationDescrInjury.value : null,
      this.occurrenceDataForm.controls.insuredActivity.value,
      this.occurrenceDataForm.controls.externalNumber.value
    );

    this.emitter.broadcastEvent('start-loader');
    this.openingDetailsService.saveOccurrenceDataInCache(data).subscribe(
      (response: Outcome) => {
        if (response.code === Outcome.OK) {
          this.setPanelComplete();
          this.disablePanelContent();
          this.signalToNextPanel(PanelSignal.open);
          if (this.claimOpeningService.isSessionEventSet(this.card.id) || this.pes) {
            this.signalToPanel('document-management', PanelSignal.init);
          }
          this.stepperEventEmitter.emit(3);
        } else {
          this.validationMessages = [response.descr];
        }
        if (this.claimOpeningService.getSessionSelectableCoverturesResponse(this.card.id)) {
          this.emitter.broadcastEvent('stop-loader');
        }
      },
      (error: Error) => {
        console.error(error);
        this.validationMessages = ['Saving error'];
        this.emitter.broadcastEvent('stop-loader');
      });
  }

  protected panelSignalRecieved(panelSignal) {
    throw new Error('Method not implemented.');
  }

  protected panelStatusRecieved(panelStatus: any): void {
    super.panelStatusRecieved(panelStatus);
    // check for SXDINT also inserted here. this function is called BEFORE the ngoninit
    const listFunz = this.authService.getOperator().enabledFeatures;
    // inserimento manuale data intermediario abilitato
    if (listFunz && listFunz.indexOf('SXDINT') >= 0) {
      this.manualInsertionIntermediaryDateEnabled = true;
    }
    this.initDataForm(this.occurrenceData);
    this.claimOpeningService.setCompliantDate(this.occurrenceDataForm.controls.complaintDate.value);
    if (panelStatus.contentEnabled) {
      this.occurrenceDataForm.get('complaintDate').enable();
      if (this.manualInsertionIntermediaryDateEnabled) {
        this.occurrenceDataForm.get('intermediaryDate').enable();
      }

      this.occurrenceDataForm.get('authority').enable();
      this.occurrenceDataForm.get('occurrenceDescription').enable();
      this.occurrenceDataForm.get('noteDirection').enable();
      this.occurrenceDataForm.get('certifiedOccurrence').enable();
      this.occurrenceDataForm.get('corporateLitigation').enable();
      // this.occurrenceDataForm.get('intermediaryDate').enable();
      this.occurrenceDataForm.get('idOpenMode').enable();
      this.occurrenceDataForm.get('auditDate').enable();
      this.occurrenceDataForm.get('insuredActivity').enable();
      this.occurrenceDataForm.get('externalNumber').enable();
      if (!this.addressDisabled) {
        this.enableAddress();
      }
    } else {
      this.occurrenceDataForm.get('complaintDate').disable();
      if (this.manualInsertionIntermediaryDateEnabled) {
        this.occurrenceDataForm.get('intermediaryDate').disable();
      }
      this.occurrenceDataForm.get('authority').disable();
      this.occurrenceDataForm.get('occurrenceDescription').disable();
      this.occurrenceDataForm.get('noteDirection').disable();
      this.occurrenceDataForm.get('certifiedOccurrence').disable();
      this.occurrenceDataForm.get('corporateLitigation').disable();
      // this.occurrenceDataForm.get('intermediaryDate').disable();
      this.occurrenceDataForm.get('idOpenMode').disable();
      this.occurrenceDataForm.get('auditDate').disable();
      this.occurrenceDataForm.get('insuredActivity').disable();
      this.occurrenceDataForm.get('externalNumber').disable();
      this.disableAddress();
    }
  }


  protected disableAddress() {
    this.occurrenceDataForm.get('selectedCity').disable();
    this.occurrenceDataForm.get('address').disable();
    this.occurrenceDataForm.get('houseNumber').disable();
    this.occurrenceDataForm.get('zipCode').disable();
  }

  protected enableAddress() {
    this.occurrenceDataForm.get('selectedCity').enable();
    this.occurrenceDataForm.get('address').enable();
    this.occurrenceDataForm.get('houseNumber').enable();
    this.occurrenceDataForm.get('zipCode').enable();
  }

  protected getAuthorityControlFormValues() {
    return this.authorityControlFormValues;
  }

  protected manageAuthorityControlFormValues() {
    const that = this;
    this.authorityControlFormDefaultValue = null;

    const observable = new Observable(observer => {
      this.getSystemProperty.findProperty(OccurenceDataPanelContentComponent.PROPERTY_CLAIMS_DEFAULT_AUTHORITY_BRANCHES).then((result) => {
        if (result && result.systemProperties && result.systemProperties.length) {
          for (const variable of result.systemProperties) {
            if (variable.key === OccurenceDataPanelContentComponent.PROPERTY_CLAIMS_DEFAULT_AUTHORITY_BRANCHES) {
              if (variable.value) {
                const values = variable.value.split(';');
                that.checkForBranchInArray(values);
              }
            }
          }
        }
        observer.next(result.systemProperties);
      });
    });

    return observable;
  }

  protected checkForBranchInArray(values: any) {
    if (values && values.length) {
      for (const value of values) {
        if (
          this.damageCategory
          && this.damageCategory.ministerialBranchCodes
          && this.damageCategory.ministerialBranchCodes.length) {
          for (const branch of this.damageCategory.ministerialBranchCodes) {
            if (branch === value) {
              this.authorityControlFormDefaultValue = OccurenceDataPanelContentComponent.CLAIMS_DEFAULT_AUTHORITY;
            }
          }
        }
      }
    }
  }

  public outputFormAddress(event) {
    this.countryCode = event.countryCode;
    this.occurrenceDataForm.get('selectedCity').setValue(event.adminLevel3);
    this.occurrenceDataForm.get('zipCode').setValue(event.postCode);
    this.occurrenceDataForm.get('selectedCountry').setValue(event.countryCode);
    this.occurrenceDataForm.get('address').setValue(event.address);
    this.occurrenceDataForm.get('province').setValue(event.province);
    if(event != null && event.toponym != null){
      this.occurrenceDataForm.get('toponym').setValue(event.toponym.description);
    }
    this.occurrenceDataForm.get('houseNumber').setValue(event.houseNumber);
    if(this.occurrenceDataForm.get('selectedCountry').value !== 'IT') {
      this.occurrenceDataForm.get('adminLevel1').setValue(event.country);
      this.occurrenceDataForm.get('adminLevel2').setValue(this.isContextES ? event.umaAddress.adminLevel2Short : event.adminLevel2);
      this.occurrenceDataForm.get('adminLevel3').setValue(event.adminLevel3);
    }
  }

  getAddressData() {
    return new AddressData(
      this.occurrenceDataForm.get('selectedCountry').value,
      this.occurrenceDataForm.get('zipCode').value,
      this.occurrenceDataForm.get('selectedCity').value,
      this.occurrenceDataForm.get('address').value,
      this.occurrenceDataForm.get('houseNumber').value,
      this.occurrenceDataForm.get('adminLevel1').value,
      this.occurrenceDataForm.get('adminLevel2').value,
      this.occurrenceDataForm.get('adminLevel3').value,
      this.occurrenceDataForm.get('toponym').value,
      this.occurrenceDataForm.get('province').value
      );
  }


  // public onDateSelect() {
  //   if (this.inserimentoManualeAbilitato) {

  //     const intermedDate = this.occurrenceDataForm.get('intermediaryDate').value;
  //     const oDataDenuncia: Date = this.occurrenceDataForm.controls.complaintDate.value;
  //     const dDataDenuncia: DateElement = new DateElement(oDataDenuncia.getFullYear(),
  //     oDataDenuncia.getMonth() + 1, oDataDenuncia.getDate());
  //     this.minDateIntermed = dDataDenuncia;
  //     if (intermedDate != null && oDataDenuncia > intermedDate) {
  //       this.occurrenceDataForm.get('intermediaryDate').setValue(null);
  //     }
  //   }
  // }

}
