import {SharedComponentsTranslationKeys} from './translation.keys';

export const ES: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'Añadir',
      ADD_DOCUMENT: 'Añadir documento',
      ARCHIVE: 'Archivar',
      ARCHIVED: 'Archivado',
      ARCHIVE_DOC: 'Archivar documento',
      ARCHIVING_USER: 'Usuario que archiva',
      CANCEL: 'Cancelar',
      CLOSE: 'Cerrar',
      CODE: 'Código',
      CONFIRM: 'Confirmar',
      CREATED: 'Creado',
      DELETE_DOC: 'Eliminar documento',
      DELIVERED: 'Entregado',
      DELIVERY_DOC: 'Documento de entrega',
      DELIVERY_ADDRESS: 'Dirección de entrega',
      DELIVERY_PARTY: 'Parte de entrega',
      DELIVERY_USER: 'Usuario de entrega',
      DELIVERY_TYPE: 'Tipo de entrega',
      DESCRIPTION: 'Descripción',
      DOCUMENTS_MANAGER: 'Gestor de documentos',
      DOCUMENT_TYPE: 'Tipo de documento',
      DOCUMENT_VALIDATION_MESSAGE: '¿Proceder con la validación del documento?',
      DOWNLOAD: 'Descargar',
      DOWNLOAD_DOC: 'Descargar documento',
      EDIT: 'Editar',
      FILE: 'Archivo',
      ERROR: 'Error',
      INPUT_DOCUMENTS: 'Documentos de entrada',
      LOAD: 'Cargar',
      NO: 'No',
      NOTE: 'Nota',
      NO_FILES: 'No hay archivos',
      NO_SIGN_NEEDED: 'El documento no requiere firma',
      NOT_DELIVERED: 'No entregado',
      NOT_SIGNED: 'No firmado',
      NUMBER_OF_FILES_INSERTED: '{{ filesLength }} de {{ fileMax }} archivos insertados',
      OUTBOUND_DOC_TITLE: 'Documentos de la póliza nº {{ policyNumber }} del movimiento {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'Documentos de la propuesta nº {{ proposalNumber }} del movimiento {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'Documentos de salida',
      PENDING_SIGNATURE_TOOLTIP: 'Documento en espera de firma. Haga clic para detener el proceso',
      REFRESH: 'Actualizar',
      SAVE: 'Guardar',
      SEND: 'Enviar',
      SIGN: 'Firmar',
      SIGN_DOCUMENT: 'Firmar documento',
      SIGNED: 'Firmado',
      SIGNED_DOCUMENT: 'Documento firmado',
      SUBJECT_SENDING_DATE: 'Fecha de envío al sujeto de registro',
      STATE: 'Estado',
      TYPE: 'Tipo',
      TO_BE_GENERATED: 'Por generar',
      TO_BE_UPLOADED: 'Por subir',
      UPDATE_FILE: 'Actualizar archivo',
      UPLOAD: 'Subir',
      UPLOADED: 'Subido',
      UPLOAD_NEW_DOCUMENT: 'Subir nuevo documento',
      UPLOADING_USER: 'Usuario que sube',
      VALIDATING_USER: 'Usuario que valida',
      VALIDATED: 'Validado',
      VALIDATION: 'Validación',
      VALIDATION_DOCUMENT: 'Documento de validación',
      YES: 'Sí',
      NO_DOCUMENTS_CONFIGURED: 'No hay documentos configurados para esta operación'
    }
};

