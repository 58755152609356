<div data-qa="life-movement-detail">

  <div class="row" id="funds-row">
    <!-- PRIMA COLONNA COL-8 -->
    <div class="col-md-12 col-lg-8">
      <!-- INIZIO DATI MOVIMENTO DINAMICO -->
      <div class="col-md-12 life-detail-margin-box life-detail-box" *ngIf="!!initializeViewDetailMovement"
           id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos" style="cursor: pointer;"
                    (click)="back()" data-qa="back-arrow"></span>
              <span class="separator"> | </span>
              <span class="menu-title" translate>lpc_Operation_detail</span>
            </div>
          </div>
        </div>
        <!-- MESSAGES AND WARNINGS -->
        <div class="col-md-12 life-detail-messages-container">
          <div *ngIf="note">
            <pnc-floating-error-messages
              [warningMsgs]="note"
            >
            </pnc-floating-error-messages>
          </div>
        </div>
        <!-- INIZIO DETTAGLI MOVIMENTO -->
        <div class="col-md-12 life-detail-first-info">
          <div class="col-md-5 life-detail-img">
            <div class="hidden-xs hidden-sm anag-user-ico">
              <span class="rgifont life-detail-icon-padding rgi-folder"></span>
            </div>
          </div>
          <div class="col-md-7 life-detail-general-info">
            <h3 class="col-md-12 card-color-blue" style="margin-top: 0 !important;">
              <span data-qa="movementDescription"> {{data?.movementDetail?.description}}</span>
            </h3>
            <div class="container-fluid anag-data">
              <div *ngIf="data?.movementDetail?.movementInfo.operationStatus" class="col-md-12">
                <span class="tbld_col_title" translate>lpc_status_investment_label</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value basic" data-qa="movement_status" translate>
                  {{data?.movementDetail?.movementInfo.operationStatus}}
                </span>
              </div>
              <div class="col-md-12">
                <span class="tbld_col_title" translate>lpc_fiscalDate</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="effective-date">
                  {{data?.movementDetail?.effectDate | date: 'dd/MM/yyyy'}}
                </span>
              </div>
              <!-- RDDL-4861 -->
              <div *ngIf="data?.movementDetail?.movementInfo.dateIssue" class="col-md-12">
                <span class="tbld_col_title" translate>lpc_issueDate</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="issue-date">
                  {{data?.movementDetail?.movementInfo.dateIssue | date: 'dd/MM/yyyy'}}
                </span>
              </div>
              <!-- IIAB-2756 -->
              <div *ngIf="data?.movementDetail?.movementInfo.orderStatus" class="col-md-12">
                <span class="tbld_col_title" translate>lpc_order_status_label</span>
                <span class="tbld_col_title">: </span>
                <span class="tbld_col_value" data-qa="order-status" translate>
                  {{data?.movementDetail?.movementInfo.orderStatus}}
                </span>
              </div>
              <!--RDDL-3705-->
              <ng-container *ngIf="showDetailPremiumPaymentSuspension()">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_premium_payment_suspension_date</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value" data-qa="effective-date">
                      {{data?.movementDetail?.premiumPaymentSuspension?.variationStartDate | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="showDetailPremiumPaymentReactivation()">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>{{getPremiumPaymentOperationLabel}}</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value" data-qa="effective-date">
                      {{data?.movementDetail?.premiumPaymentReactivation?.premiumPaymentReactivationDate | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="showDetailVarationDate()">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_Date_Start_Variation</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value" data-qa="effective-date">
                      {{data?.movementDetail?.paymentFrequencyDetail?.startDateNewVariation | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="showDetailPaymentFrequent()">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_New_Frequent_Payment</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">
                      {{data?.movementDetail?.paymentFrequencyDetail?.newFrequency}}
                    </span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="!!showGrossAmount()">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_gross_amount</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value" data-qa="gross-amount">
                      {{getGrossAmount() | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions }}
                  </span>

                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="showReserveDetails()">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_calculation_date</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">
                      {{data?.movementDetail?.reserveData?.calculationDate | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="showReserveDetails()">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_elaboration_date</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value">
                      {{data?.movementDetail?.reserveData?.elaborationDate | date: 'dd/MM/yyyy'}}
                    </span>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template [ngIf]="haveOpWithdrawal" [ngIfElse]="ExpensesManagement">
                <div class="col-md-12">
                  <span class="tbld_col_title" translate>lpc_penalties_amount</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="penalties-amount">
                      {{getPenalties() | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions }}
                    </span>
                </div>
                <div class="col-md-12">
                  <span class="tbld_col_title" translate>lpc_tax_amount</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="tax-amount">
                      {{getTax() | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions }}
                  </span>
                </div>
                <div class="col-md-12">
                  <span class="tbld_col_title" translate>lpc_bonus_amount</span>
                  <span class="tbld_col_title">: </span>
                  <span class="tbld_col_value" data-qa="bonus-amount">
                      {{getBonusAmount() | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions }}
                  </span>
                </div>
              </ng-template>
              <ng-template #ExpensesManagement>
                <ng-container *ngIf="haveAmountFee">
                  <ng-container *ngIf="hasInvestments(disinvestments) || hasInvestments(investments)">
                    <div class="col-md-12">
                      <span class="tbld_col_title" translate>lpc_management_fees</span>
                      <span class="tbld_col_title">: </span>
                      <span *ngIf="hasInvestments(disinvestments)" class="tbld_col_value" data-qa="gross-amount">
                          {{calculateFee(disinvestments) | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions }}
                      </span>
                      <span *ngIf="hasInvestments(investments) && !hasInvestments(disinvestments)"
                            class="tbld_col_value" data-qa="gross-amount">
                          {{calculateFee(investments) | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions }}
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-template>

              <ng-container *ngIf="!!subcause">
                <ng-container>
                  <div class="col-md-12">
                    <span class="tbld_col_title" translate>lpc_Subcause</span>
                    <span class="tbld_col_title">: </span>
                    <span class="tbld_col_value" data-qa="subcause">
                      {{subcause}}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- FINE DETTAGLI MOVIMENTO -->
        <div class="col-md-12 padding-card">
          <div class="col-md-6 life-detail-infobox">
            <div class="col-md-12">
              <span class="life-increase-font-size" data-qa="policyId">
                {{data?.lifeContract?.policyNumber ? data?.lifeContract?.policyNumber : data?.lifeContract?.proposalNumber}}
              </span>
            </div>
            <div class="col-md-12">
              <span class="tbld_col_title" style="color: white; font-size: 18px;" translate>lpc_contract</span>
            </div>
          </div>
          <div class="col-md-6 life-detail-infobox">
            <div class="col-md-12">
              <span class="life-increase-font-size" data-qa="netAmount">
                {{ !!getAmount() ? (getCurrency(getAmount(), data?.movementDetail?.currency?.symbol)) : '&nbsp;' }}
              </span>
              <span class="life-increase-font-size" data-qa="currency">

              </span>
            </div>
            <div class="col-md-12">
              <span class="tbld_col_title" style="color: white; font-size: 18px;"
                    translate>{{!!showLabel() ? showLabel() : '&nbsp;' }}</span>
            </div>
          </div>
        </div>

      </div>
      <!-- FINE DATI MOVIMENTO DINAMICO -->


      <!-- DISINVESTIMENTI -->
      <div *ngIf="disinvestments?.profiles?.length > 0" class="col-md-12 life-detail-margin-box life-detail-box"
           data-qa="disinvestment-table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_disinvstements</span>
            </div>
          </div>
        </div>

        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_category_fund</div>
            <div class="tbld_col tbld_col_title" translate>lpc_Percentage</div>
            <div class="tbld_col tbld_col_title" translate>lpc_equivalent_on_date</div>
            <div class="tbld_col tbld_col_title" translate>lpc_quotes_number</div>
            <div class="tbld_col tbld_col_title" translate>lpc_unit_linked_value</div>
            <div class="tbld_col tbld_col_title" translate>lpc_data_value</div>
            <div class="tbld_col tbld_col_title" translate>lpc_dis_date</div>
          </div>
          <ng-container *ngFor="let profile of disinvestments.profiles">
            <div class="tbld_row life-detail-investment-profile-row bolder">
              <div class="tbld_col tbld_col_value life-detail-table-border">{{profile.description}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"
                   [attr.data-qa]="profile.description + '-profilePercent'">{{profile.percent / 100 | rgiCountryLayerNumberFormat : currencyService.locale : percentFormatterOptions}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"
                   [attr.data-qa]="profile.description + '-profileNetAmount'">{{getCurrency(profile.grossAmount, disinvestments.currency?.symbol)}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
            </div>
            <div *ngFor="let fund of profile.funds" class="tbld_row regular">
              <div class="tbld_col tbld_col_value life-detail-table-border">{{fund.description}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"
                   [attr.data-qa]="profile.description + '-fundPercent'">{{fund.percent / 100 | rgiCountryLayerNumberFormat : currencyService.locale : percentFormatterOptions }}</div>
              <div [attr.data-qa]="fund.description + '-fundNetAmout'"
                   class="tbld_col tbld_col_value life-detail-table-border">{{getFundAmountByAmountType(fund, 'grossAmount')}}
              </div>
              <ng-template [ngIf]="fund.fundType !== 'GS'" [ngIfElse]="empty_column">
                <div class="tbld_col tbld_col_value life-detail-table-border"
                     [attr.data-qa]="fund.description + '-fundNetUnits'">{{ getValueUnit(fund.grossUnits) }}</div>
                <div [attr.data-qa]="fund.description + '-fundUnitLinked'"
                     class="tbld_col tbld_col_value life-detail-table-border">{{getCurrencySix(fund.marketValue, fund?.currency?.symbol)}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border"
                     [attr.data-qa]="fund.description + '-fundDate'">{{ fund.usedMarketValueDate | date: "dd/MM/yyyy" }}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border"
                     [attr.data-qa]="fund.description + '-investmentDate'">{{fund.marketValueDate | date: "dd/MM/yyyy"}}</div>
              </ng-template>
            </div>
          </ng-container>
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_percentage_total</div>
            <div class="tbld_col tbld_col_title"
                 translate>{{totalPercent(disinvestments) | rgiCountryLayerNumberFormat : currencyService.locale : percentFormatterOptions }}</div>
            <div class="tbld_col tbld_col_title"
                 translate>{{getCurrency(totalSummary(disinvestments, false), disinvestments?.currency?.symbol) }}</div>
            <div class="tbld_col tbld_col_title"></div>
            <div class="tbld_col tbld_col_title"></div>
            <div class="tbld_col tbld_col_title"></div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
        </div>
      </div>
      <!-- FINE DISINVESTIMENTI -->

      <!-- RATA PREMIO -->
      <div *ngIf="!!rataPremioVector && rataPremioVector.length > 0"
           class="col-md-12 life-detail-margin-box life-detail-box" id="installments-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont"></span>
              <span class="menu-title" translate>lpc_installment_detail</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <ng-container *ngFor="let row of rataPremioVector; let i = index">
            <div class="tbld_row life-detail-investment-profile-row bolder">
              <ng-container *ngFor="let element of row">
                <div class="tbld_col tbld_col_title life-detail-table-border" translate>{{element.description}}</div>
                <div *ngIf="element.value !== 'null' "
                     class="tbld_col tbld_col_title life-detail-table-border">
                  <span *ngIf="!element.fun">{{element.value | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</span>
                  <span *ngIf="element.fun"
                        lpcTooltip="lpc_Visualizza_dettaglio"
                        placement="top"
                        delay="200" class="premium-link" (click)="callFunction(element.fun)">{{element.value | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</span>
                </div>
                <div *ngIf="element.value === 'null'" class="tbld_col tbld_col_title life-detail-table-border">-</div>
              </ng-container>
            </div>
          </ng-container>
          <div class="tbld_row life-detail-investment-profile-row bolder">
          </div>
        </div>
      </div>
      <!-- FINE RATE PREMIO-->


      <!-- DETTAGLIO CONTRIBUZIONI PIP -->
      <div *ngIf="getHasContributions"
           class="col-md-12 life-detail-margin-box life-detail-box" id="pip-contributions-instalment-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont"></span>
              <span class="menu-title" translate>lpc_pip_contributions_installment_detail</span>
            </div>
          </div>
        </div>

        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_contribution_type</div>
            <div class="tbld_col tbld_col_title" translate>lpc_contribution_amount</div>
            <div class="tbld_col tbld_col_title" translate>lpc_contribution_year</div>
            <div class="tbld_col tbld_col_title" translate>lpc_contribution_month_from</div>
            <div class="tbld_col tbld_col_title" translate>lpc_contribution_month_to</div>
            <div class="tbld_col tbld_col_title" translate>lpc_contribution_thirteenth</div>
            <div class="tbld_col tbld_col_title" translate>lpc_contribution_fourteenth</div>
          </div>

          <ng-container *ngFor="let contribution of getContributions">
            <div class="tbld_row regular">
              <div class="tbld_col tbld_col_value life-detail-table-border">{{contribution.contributionType}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border">{{contribution.amount | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border">{{contribution.yearFrom}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border">{{contribution.monthFrom > 0 ? contribution.monthFrom : '-'}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border">{{contribution.monthTo > 0 ? contribution.monthTo : '-'}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border">{{contribution.thirteenth | dataValueFormatterPipe}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border">{{contribution.fourteenth | dataValueFormatterPipe}}</div>
            </div>
          </ng-container>

        </div>
      </div>
        <!-- FINE DETTAGLIO CONTRIBUZIONI PIP -->


      <!-- CAPITALE RIATTIVATO -->
      <div *ngIf="!!capitalData" class="col-md-12 life-detail-margin-box life-detail-box"
           id="consulting-detail">
        <div class="tbld postsales-table-style"
             id="consulting-detail">
          <div class="tbld_row postsales-col-title">
            <div class="tbld_col" translate>lpc_Guarantee</div>
            <div class="tbld_col" translate>lpc_reactivatedCapital</div>
          </div>
          <div *ngFor="let capital of capitalData" class="tbld_row postsales-row-content">
            <div class="tbld_col postsales-row-title">{{capital.description}}</div>
            <div class="tbld_col psvalue">{{capital.reinvestedCapital | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
          </div>
        </div>
      </div>
      <!-- FINE CAPITALE RIATTIVATO -->

      <!-- INVESTIMENTI -->
      <div *ngIf="investments?.profiles?.length > 0" class="col-md-12 life-detail-margin-box life-detail-box"
           data-qa="investment-table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_investment</span>
            </div>
          </div>
        </div>

        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_category_fund</div>
            <div class="tbld_col tbld_col_title" translate>lpc_Percentage</div>
            <div class="tbld_col tbld_col_title" translate>lpc_equivalent_on_date</div>
            <div class="tbld_col tbld_col_title" translate>lpc_quotes_number</div>
            <div class="tbld_col tbld_col_title" translate>lpc_unit_linked_value</div>
            <div class="tbld_col tbld_col_title" translate>lpc_data_value</div>
            <div class="tbld_col tbld_col_title" translate>lpc_inv_date</div>
          </div>
          <ng-container *ngFor="let profile of investments.profiles">
            <div class="tbld_row life-detail-investment-profile-row bolder">
              <div class="tbld_col tbld_col_value life-detail-table-border">{{profile.description}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"
                   [attr.data-qa]="profile.description + '-profilePercent'">{{profile.percent / 100 | rgiCountryLayerNumberFormat : currencyService.locale : percentFormatterOptions}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"
                   [attr.data-qa]="profile.description + '-profileNetAmount'">{{getCurrency(profile.netAmount, investments.currency.symbol) }}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
              <div class="tbld_col tbld_col_value life-detail-table-border"></div>
            </div>
            <div *ngFor="let fund of profile.funds" class="tbld_row regular">
              <div class="tbld_col tbld_col_value life-detail-table-border">{{fund.description}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"
                   [attr.data-qa]="profile.description + '-fundPercent'">{{fund.percent / 100 | rgiCountryLayerNumberFormat : currencyService.locale : percentFormatterOptions}}</div>
              <div [attr.data-qa]="fund.description + '-fundNetAmout'"
                   class="tbld_col tbld_col_value life-detail-table-border">{{getFundAmountByAmountType(fund, 'netAmount')}}
              </div>
              <ng-template [ngIf]="fund.fundType !== 'GS'" [ngIfElse]="empty_column">
                <div class="tbld_col tbld_col_value life-detail-table-border"
                     [attr.data-qa]="fund.description + '-fundNetUnits'">{{ getValueUnit(fund.netUnits) }}</div>
                <div [attr.data-qa]="fund.description + '-fundUnitLinked'"
                     class="tbld_col tbld_col_value life-detail-table-border">{{getCurrencySix(fund.marketValue, fund?.currency?.symbol)}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border"
                     [attr.data-qa]="fund.description + '-fundDate'">{{fund.usedMarketValueDate | date: "dd/MM/yyyy"}}</div>
                <div class="tbld_col tbld_col_value life-detail-table-border"
                     [attr.data-qa]="fund.description + '-fundDate'">{{isTicket ? '-' : (fund.marketValueDate | date: "dd/MM/yyyy")}}</div>
              </ng-template>
            </div>
          </ng-container>
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_percentage_total</div>
            <div class="tbld_col tbld_col_title"
                 translate>{{totalPercent(investments) | rgiCountryLayerNumberFormat : currencyService.locale : percentFormatterOptions }}</div>
            <div class="tbld_col tbld_col_title"
                 translate>{{getCurrency(totalSummary(investments, true), investments?.currency?.symbol) }}</div>
            <div class="tbld_col tbld_col_title"></div>
            <div class="tbld_col tbld_col_title"></div>
            <div class="tbld_col tbld_col_title"></div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
        </div>
      </div>
      <!-- FINE INVESTIMENTI -->

      <!--INFORMAZIONI ANNULLO RATA-->
      <div *ngIf="showCancellInstallment()" class="col-md-12 life-detail-margin-box life-detail-box"
           data-qa="cancellInstallment-table" id="consulting-detail">
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_DescOperazione</div>
            <div class="tbld_col tbld_col_title" translate>lpc_EffectiveDate</div>
            <div class="tbld_col tbld_col_title" translate>lpc_issueDate</div>
            <div class="tbld_col tbld_col_title" translate>lpc_RateAmount</div>
          </div>


          <div class="tbld_row life-detail-investment-profile-row bolder">
            <div data-qa="operation-descOperazione" class="tbld_col tbld_col_value life-detail-table-border"
                 translate>{{this.data.movementDetail.cancellInstallmentData.description}}</div>
            <div data-qa="operation-effectDate" class="tbld_col tbld_col_value life-detail-table-border"
                 translate>{{this.data.movementDetail.cancellInstallmentData.dateEffect | date: 'dd/MM/yyyy'}}</div>
            <div data-qa="operation-issueDate" class="tbld_col tbld_col_value life-detail-table-border"
                 translate>{{this.data.movementDetail.cancellInstallmentData.issueDate | date: 'dd/MM/yyyy'}}</div>
            <div data-qa="operation-rateAmount" class="tbld_col tbld_col_value life-detail-table-border"
                 translate>{{ getCurrency(this.data.movementDetail.cancellInstallmentData.rateAmount)}}</div>
          </div>

        </div>
      </div>

      <!--INFORMAZIONI RIATTIVAZIONE PAGAMENTO PREMIO-->


      <div *ngIf="showDetailPremiumPaymentReactivationTable()" class="col-md-12 life-detail-margin-box life-detail-box"
           id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_Premium</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_Loading</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_NetPremium</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_IntFract</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_Fees</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_Taxes</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-13" translate>lpc_GrossPremium</div>


          </div>

          <div class="tbld_row">
            <div data-qa="premium-nextInstallment-caricamenti"
                 class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{premiumPaymentReactivation.detailNextInstallment.costs | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-premioNetto"
                 class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{premiumPaymentReactivation.detailNextInstallment.netPremium | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-intFraz"
                 class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{premiumPaymentReactivation.detailNextInstallment.paymentFrequencyInterest | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-diritti"
                 class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{premiumPaymentReactivation.detailNextInstallment.administrationFee | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-imposte"
                 class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{premiumPaymentReactivation.detailNextInstallment.taxes | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
            <div data-qa="premium-nextInstallment-premioLordo"
                 class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-13">{{premiumPaymentReactivation.detailNextInstallment.grossPremium | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
          </div>
        </div>
      </div>


      <!-- SINTESI OPERAZIONI-->
      <div *ngIf="showOperationSyntesis()" class="col-md-12 life-detail-margin-box life-detail-box"
           data-qa="syntesisOperation-table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_OperationSynstesis</span>
            </div>
          </div>
        </div>

        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_category_fund</div>
            <div class="tbld_col tbld_col_title" translate>lpc_PreviusPerceent</div>
            <div class="tbld_col tbld_col_title" translate>lpc_PreviusValue</div>
            <div class="tbld_col tbld_col_title" translate>lpc_NumberOfUnit</div>
            <div class="tbld_col tbld_col_title" translate>lpc_PostPErcent</div>
            <div class="tbld_col tbld_col_title" translate>lpc_PostValue</div>
            <div class="tbld_col tbld_col_title" translate>lpc_PostNumberOfUnit</div>
          </div>

          <ng-container *ngFor="let operation of this.data.movementDetail.operationSyntesis.profilesOp">
            <div class="tbld_row life-detail-investment-profile-row bolder">
              <div data-qa="operation-profileName" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{operation.profileName}}</div>
              <div data-qa="operation-previousPercentage" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getPercentage(operation.previousPercentage)}}</div>
              <div data-qa="operation-previusValue" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getValue(operation.previusValue) }}</div>
              <div data-qa="operation-previusAllowancesNumber"
                   class="tbld_col tbld_col_value life-detail-table-border"></div>
              <div data-qa="operation-postOperationPercentage" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getPercentage(operation.postOperationPercentage)}}</div>
              <div data-qa="operation-postOperationValue" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getValue(operation.postOperationValue)}}</div>
              <div data-qa="operation-postOperationAllowancesNumber"
                   class="tbld_col tbld_col_value life-detail-table-border"></div>
            </div>
            <div *ngFor="let fund of operation.fundsOp" class="tbld_row regular">
              <div data-qa="fund-fundName" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{fund.fundName}}</div>
              <div data-qa="fund-previousPercentage" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getPercentage(fund.previousPercentage) }}</div>
              <div data-qa="fund-previusValue" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getValue(fund.previusValue)}}</div>
              <div data-qa="fund-previusAllowancesNumber" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getValueUnit(fund.previusAllowancesNumber)}}</div>
              <div data-qa="fund-postOperationPercentage" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getPercentage(fund.postOperationPercentage)}}</div>
              <div data-qa="fund-postOperationValue" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getValue(fund.postOperationValue) }}</div>
              <div data-qa="fund-postOperationAllowancesNumber" class="tbld_col tbld_col_value life-detail-table-border"
                   translate>{{getValueUnit(fund.postOperationAllowancesNumber)}}</div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- SINTESI FORMULE RISCHI -->
      <div *ngIf="showReserveRiskDetails" class="col-md-12 life-detail-margin-box life-detail-box"
           data-qa="formule-table" id="consulting-detail">

        <lpc-reserve-formula-detail
          [reserveData]="data.movementDetail.reserveData">
        </lpc-reserve-formula-detail>

      </div>
      <!-- FINE SINTESI FORMULE RISCHI -->

      <!-- SINTESI PRESTITI -->
      <div *ngIf="showLoanDetail" class="col-md-12 life-detail-margin-box life-detail-box" data-qa="loan-table"
           id="consulting-detail">
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_loan_data</div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
          <div *ngFor="let loan of data.movementDetail.loanData" class="tbld_row">
            <div class="tbld_col tbld_col_title life-detail-table-border" data-qa="info-loan-label" translate>{{loan.description}}</div>
            <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="info-loan-label">{{getFieldValue(loan.value, loan.type)}}</div>
          </div>
        </div>
      </div>
      <!-- FINE SINTESI PRESTITI -->

      <div *ngIf="surveys?.length > 0" class="col-md-12 life-detail-margin-box life-detail-box" data-qa="surveys-table"
           id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_questionnaire</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_questionnaire</div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
          <ng-container *ngFor="let survey of surveys">
            <div class="tbld_row life-detail-investment-profile-row">
              <div
                class="tbld_col tbld_col_value life-detail-table-border first_column_quest">{{survey.descrizione}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border" [attr.data-qa]="survey">
                <button class="btn dt-btn" (click)="openQuest(survey.uuid)">
                  <span class="rgifont rgi-chevron-right"></span>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>



      <ng-template #empty_column>
        <div class="tbld_col tbld_col_value life-detail-table-border"
             [attr.data-qa]="'fundNetUnits'"></div>
        <div [attr.data-qa]="'fundUnitLinked'" class="tbld_col tbld_col_value life-detail-table-border"></div>
        <div class="tbld_col tbld_col_value life-detail-table-border"
             [attr.data-qa]="'fundDate'"></div>
        <div class="tbld_col tbld_col_value life-detail-table-border"
             [attr.data-qa]="'fundDate'"></div>
      </ng-template>

      <!-- INIZIO DETTAGLIO VARIAZIONE MOVIMENTO -->
      <div *ngIf="!!movementVariation && movementVariation.sections.length>0" class="col-md-12 life-detail-margin-box life-detail-box" data-qa="movementVariation-table" id="consulting-detail">
        <ng-container *ngFor="let section of movementVariation.sections">
          <div class="tbld life-detail-margin-top">
            <ng-container  [ngSwitch]="section.code">
              <ng-container *ngSwitchCase="'headerDataTransfer'">
                <div class="tbld_row life-detail-tbld_row_header">
                  <div class="tbld_col tbld_col_title">{{section.title}}</div>
                  <div class="tbld_col tbld_col_title"></div>
                </div>
                <div *ngFor="let info of section.infos" class="tbld_row regular">
                  <div data-qa="info-label" class="tbld_col tbld_col_value life-detail-table-border">{{info.label}}</div>
                  <div data-qa="info-value" class="tbld_col tbld_col_value life-detail-table-border">{{info.value}}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <rgi-rx-expansion-panel color="default">
                  <div rgi-rx-expansion-panel-header>
                    <div class="row">
                      <span translate>{{section.title}}</span>
                    </div>
                  </div>
                  <ng-container rgiRxExpansionPanelContent >
                    <div *ngFor="let info of section.infos" class="lf-grid-container">
                      <div data-qa="info-label" class="lf-grid-item">{{info.label}}</div>
                      <div data-qa="info-value" class="lf-grid-item">{{info.value}}</div>
                    </div>
                  </ng-container>
                </rgi-rx-expansion-panel>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- FINE DETTAGLIO VARIAZIONE MOVIMENTO -->

      <!--GARANZIE-->
      <div *ngIf="!!warranties && warranties.length > 0" class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail" data-qa="active-guarantee-table">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_ActiveGuarantees</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title life-detail-table-width-34" translate>lpc_Guarantee</div>
            <div class="tbld_col tbld_col_title life-detail-table-width-33"></div>
          </div>
          <div *ngFor="let warranty of warranties" class="tbld_row">
            <div data-qa="garanzia-label" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-34" translate>{{warranty.warrantyDescription}}</div>
            <div data-qa="garanzia-freccia" class="tbld_col tbld_col_value life-detail-table-border life-detail-table-width-33">
              <button class="btn dt-btn pull-right" data-qa="open-detail-rata" (click)="getWarrantyDetail(warranty.idInstRisk,warrantyModal)">
                <span class="rgifont rgi-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <rgi-rx-modal #warrantyModal>
        <ng-container *ngIf="!!warrantiesDetailData">
          <rgi-rx-panel [attr.data-qa]="data.description + '-rate-panel'">
            <rgi-rx-panel-header [closeable]="true" (onClose)="warrantyModal.close()">
            <p>{{warrantiesDetailData.desc}}</p>
            </rgi-rx-panel-header>
            <div class="tbld life-detail-margin-top">
              <div class="row">
                  <div class="column-container">
                    <div class="tbld_row column-content life-detail-investment-profile-row" *ngFor="let warr of warrantiesDetailData.labelValues">
                      <div class="tbld_col tbld_col_value life-detail-table-border warr-font">{{warr.label}}</div>
                      <div class="tbld_col tbld_col_value life-detail-table-border warr-font">{{warr.value | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
                    </div>
                  </div>
              </div>
            </div>

            <rgi-rx-panel-footer>
              <button class="text-uppercase rgi-ui-btn rgi-ui-btn-primary" (click)="warrantyModal.close();" data-qa="close-btn" translate>close</button>
            </rgi-rx-panel-footer>
          </rgi-rx-panel>
        </ng-container>
      </rgi-rx-modal>

      <!--FINE GARANZIE-->

      <div *ngIf="settlementBeneficiaryDetails?.length > 0" class="col-md-12 life-detail-margin-box life-detail-box"
           data-qa="surveys-table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_life_detail_beneficiaries</span>
            </div>
          </div>
        </div>
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_life_detail_beneficiary</div>
            <div class="tbld_col tbld_col_title" translate>lpc_life_detail_percentage</div>
            <div class="tbld_col tbld_col_title" translate>lpc_life_detail_net_amount</div>
            <div class="tbld_col tbld_col_title" translate>lpc_life_detail_questionnaire</div>
          </div>
          <ng-container *ngFor="let settlementBeneficiaryDetail of settlementBeneficiaryDetails">
            <div class="tbld_row life-detail-investment-profile-row">
              <div
                class="tbld_col tbld_col_value life-detail-table-border col-width-50">{{settlementBeneficiaryDetail.description}}</div>
              <div
                class="tbld_col tbld_col_value life-detail-table-border">{{settlementBeneficiaryDetail.rate / 100 | rgiCountryLayerNumberFormat : currencyService.locale : percentFormatterOptions}}</div>
              <div
                class="tbld_col tbld_col_value life-detail-table-border">{{settlementBeneficiaryDetail.netAmount | rgiCountryLayerNumberFormat : currencyService.locale : currencyFormatterOptions}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border"
                   [attr.data-qa]="settlementBeneficiaryDetail.survey">
                <button class="btn dt-btn pull-right" (click)="openQuest(settlementBeneficiaryDetail.survey.uuid)">
                  <span class="rgifont rgi-chevron-right"></span>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="dueRegisterPlanInvestment.length > 0" class="col-md-12 life-detail-margin-box life-detail-box"
           data-qa="life-due_register-investment-plan-detail-table" id="consulting-detail">
        <div class="row life-detail-card-title-container">
          <div class="row life-detail-title">
            <div class="card-title">
              <span class="header-icon rgifont "></span>
              <span class="menu-title" translate>lpc_investment_plan_detail</span>
            </div>
          </div>
        </div>
        <lpc-profiles-and-funds-table [showTable]="dueRegisterPlanInvestment.length > 0" [showTotal]="false"
                                      [profiles]="dueRegisterPlanInvestment">
        </lpc-profiles-and-funds-table>
      </div>

    </div>
  </div>
</div>

