import { LpcTranslationsKeys } from '../lpc-translations-keys';


/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_DE: LpcTranslationsKeys = {
  lpc_Investment_profiles: '',
  lpc_payment_method: '',
  lpc_payment_type: '',
  lpc_investment_funds: '',
  lpc_fund_type: '',
  lpc_fields_generic_error: '',
  lpc_profile_type: '',
  lpc_elements: '',
  lpc_fund_name: '',
  lpc_profile_name: '',
  lpc_profiles: '',
  lpc_funds: '',
  lpc_division_type: '',
  lpc_start_validity_date: '',
  lpc_fund_description: '',
  lpc_ISIN_code: '',
  lpc_Allocation: '',
  lpc_profile_details: '',
  lpc_portfolio_fund_display: '',
  lpc_payment_data: '',
  lpc_Warning_you_are_deleting_the_contractor: '',
  lpc_Investment_amount: '',
  lpc_mov_status: '',
  lpc_mov_Amount: '',
  lpc_ticket_status_label: '',
  lpc_status_investment_label: '',
  lpc_order_status_label: '',
  lpc_selected_funds: '',
  lpc_Premium: '',
  lpc_premium_detail: '',
  lpc_InvestmentByFund: '',
  lpc_Loading: '',
  lpc_NetPremium: '',
  lpc_IntFract: '',
  lpc_Fees: '',
  lpc_Taxes: '',
  lpc_GrossPremium: '',
  lpc_FirstInstallment: '',
  lpc_AnnualPremium: '',
  lpc_NextInstallment: '',
  lpc_Guarantee: '',
  lpc_BenefitOnDate: '',
  lpc_Benefit: '',
  lpc_AnnualSinglePremium: '',
  lpc_ActiveGuarantees: '',
  lpc_Enhancement: '',
  lpc_CalculationData: '',
  lpc_ControvaloreAllaData: '',
  lpc_AccumulationOfPrizes: '',
  lpc_TotalSurrender: '',
  lpc_AccumulationOfReducedPremiums: '',
  lpc_TotalBalance: '',
  lpc_EffectiveDate: '',
  lpc_Movement: '',
  lpc_Movements: '',
  lpc_booking_number: '',
  lpc_booking_policyData: '',
  lpc_booking_assetData: '',
  lpc_PolicyLinked: '',
  lpc_Motivo_Legame: '',
  lpc_ProposalIssueDate: '',
  lpc_PolicyIssueDate: '',
  lpc_ExpirationDate: '',
  lpc_NextInstalmentDate: '',
  lpc_found_duplicate: '',
  lpc_duplicate_subject_message: '',
  lpc_SelectSettlement: '',
  lpc_You_must_select_the_settlement_to_perform_the_variation: '',
  lpc_beneficiaryPercentage: '',
  lpc_beneficiaryPercentage_greater: '',
  lpc_beneficiaryPercentage_lower: '',
  lpc_beneficiaryDuplicate: '',
  lpc_NoOperations: '',
  lpc_confirm: '',
  lpc_description: '',
  lpc_issueDate: '',
  lpc_effectiveDate: '',
  lpc_MovementData: '',
  lpc_Are_you_sure_you_want_to_confirm_this_claim: '',
  lpc_Are_you_sure_you_want_to_cancel_this_claim: '',
  lpc_warnings: '',
  lpc_warning: '',
  lpc_warning_message: '',
  lpc_ClaimCanceled: '',
  lpc_ClaimConfirmed: '',
  lpc_reserve: '',
  lpc_questionnaire: '',
  lpc_bonus_amount: '',
  lpc_life_detail_beneficiary: '',
  lpc_life_detail_net_amount: '',
  lpc_life_detail_questionnaire: '',
  lpc_life_detail_beneficiaries: '',
  lpc_AppendixNumber: '',
  lpc_nessuna_variazione: '',
  lpc_appendixLabel: '',
  lpc_operation_termination_reason: '',
  lpc_Lien_type_creditor: '',
  lpc_creditor: '',
  lpc_Open_lien: '',
  lpc_assignment_holder_type: '',
  lpc_assignment_holder: '',
  lpc_assignment_amount: '',
  lpc_assignment_policy_number: '',
  lpc_assignment_company: '',
  lpc_assignment_type: '',
  lpc_assignment_benefit: '',
  lpc_amount_less_of_benefit: '',
  lpc_open_assignment: '',
  lpc_sum_det_cancel_proposal: '',
  lpc_sum_det_success_cancel: '',
  lpc_sum_det_success_cancel_prev: '',
  lpc_sum_det_confirm_cancel: '',
  lpc_sum_det_confirm_cancel_prev: '',
  lpc_sum_det_confirm_prop_issue: '',
  lpc_sum_det_confirm_pol_issue: '',
  lpc_health_questionnaire: '',
  lpc_quotation_show_details: '',
  lpc_quotation_premium_details: '',
  lpc_quotation_warranties_details: '',
  lpc_quotation_commissions_details: '',
  lpc_Reference_Third_Party: '',
  lpc_DelegateEqualToPolicyHolder: '',
  lpc_LegalGuardianEqualToPolicyHolder: '',
  lpc_ThirdPayerEqualToPolicyHolder: '',
  lpc_DelegateEqualToPolicyHolderWarning: '',
  lpc_LegalGuardianEqualToPolicyHolderWarning: '',
  lpc_ThirdPayerEqualToPolicyHolderWarning: '',
  lpc_but_confirm_accept: '',
  lpc_Date_Start_Variation: '',
  lpc_New_Frequent_Payment: '',
  lpc_start_date_profit: '',
  lpc_end_date_profit: '',
  lpc_last_rate_profit: '',
  lpc_last_profit: '',
  lpc_Fund_Code: '',
  lpc_OperationSynstesis: '',
  lpc_PreviusPerceent: '',
  lpc_PreviusValue: '',
  lpc_NumberOfUnit: '',
  lpc_PostPErcent: '',
  lpc_PostValue: '',
  lpc_PostNumberOfUnit: '',
  lpc_Dati: '',
  lpc_PIP_Data: '',
  lpc_PIP_General: '',
  lpc_PIP_Contribution: '',
  lpc_HolderData: '',
  lpc_RiskData: '',
  lpc_IBAN_error: '',
  lpc_BACK: '',
  lpc_ACTIONS: '',
  lpc_DETAIL: '',
  lpc_CANCEL: '',
  lpc_CONFIRM_PROPOSAL: '',
  lpc_CONFIRM_POLICY: '',
  lpc_quote_number: '',
  lpc_valid_quote: '',
  lpc_BUY_PREV: '',
  lpc_EDIT_PREV: '',
  lpc_EDIT: '',
  lpc_CANCEL_PREV: '',
  lpc_buy_quote: '',
  lpc_Close_assignment: '',
  lpc_Close_lien: '',
  lpc_disinvestment_funds: '',
  lpc_error_mandatory_fields: '',
  lpc_disinvestment_msg: '',
  lpc_disinvestment_allocation_msg: '',
  lpc_maximum_percentage: '',
  lpc_maximum_amount: '',
  lpc_minimum_percentage: '',
  lpc_minimum_amount: '',
  lpc_investment_msg: '',
  lpc_Fields_marked_in_red_are_required: '',
  lpc_amount_format_validation: '',
  lpc_select_at_least_one_beneficiary: '',
  lpc_quote_due_date: '',
  lpc_roles_variation: '',
  lpc_fiscal_data: '',
  lpc_data_calc: '',
  lpc_tipo_liquidazione: '',
  lpc_valore_polizza: '',
  lpc_imponibile_imp: '',
  lpc_imposta_base: '',
  lpc_imposta_calcol: '',
  lpc_imposta_da_add: '',
  lpc_imposta_add: '',
  lpc_imposta_bollo: '',
  lpc_fiscal_data_detail: '',
  lpc_quote_white_list: '',
  lpc_data_rif: '',
  lpc_tipo_perc: '',
  lpc_CancellInstallment: '',
  lpc_DescOperazione: '',
  lpc_RateAmount: '',
  lpc_OPEN_LATEST_VERSION: '',
  lpc_TOTAL_INVESTED: '',
  lpc_REMAINING_AMOUNT: '',
  lpc_descrizione_rischio: '',
  lpc_prestazione_ridotta_iniz: '',
  lpc_prestazione_rivalutata: '',
  lpc_provvigioni_da_recuperare: '',
  lpc_reg41: '',
  lpc_disinvestment_amount: '',
  lpc_frequecy: '',
  lpc_the_occurenceNumber_must_be_a_value: '',
  lpc_occurenceNumber: '',
  lpc_disinvestment_range: '',
  lpc_state: '',
  lpc_substate: '',
  lpc_product_type: '',
  lpc_managementnode: '',
  lpc_subagency: '',
  lpc_branch_name: '',
  lpc_agreement: '',
  lpc_payment_frequency: '',
  lpc_mean_of_payment: '',
  lpc_Subcause: '',
  lpc_premium_payment_suspension_date: '',
  lpc_imposta_sostitutiva: '',
  lpc_novazione_contr: '',
  lpc_acc_premium_initial_2011: '',
  lpc_amount_intital_2011: '',
  lpc_acc_premium_2011: '',
  lpc_amount_2011: '',
  lpc_acc_premium_initial_2014: '',
  lpc_acc_premium_2014: '',
  lpc_amount_intital_2014: '',
  lpc_amount_2014: '',
  lpc_acc_premium_initial_2022: '',
  lpc_acc_premium_2022: '',
  lpc_amount_intital_2022: '',
  lpc_amount_2022: '',
  lpc_acc_premium_intial_tax_change: '',
  lpc_amount_initial_tax_change: '',
  lpc_acc_premium_tax_change: '',
  lpc_amount_tax_change: '',
  lpc_regime_change_date: '',
  lpc_please_enter_a_disinvestment_amount: '',
  lpc_please_select_at_least_a_disinvestment: '',
  lpc_reg41Msg: '',
  lpc_cost: '',
  lpc_paymentFrequencyInterest: '',
  lpc_netpremium: '',
  lpc_grosspremium: '',
  lpc_information: '',
  lpc_reinvest_data_detail: '',
  lpc_polizza: '',
  lpc_data_effetto_mov: '',
  lpc_netto_liquidazione: '',
  lpc_netto_reinvestire: '',
  lpc_stato_reinvest: '',
  lpc_reinvested_settlements: '',
  lpc_loans: '',
  lpc_elaboration_date: '',
  lpc_calculation_date: '',
  lpc_annuity_data: '',
  lpc_extra_premium: '',
  lpc_loan_interest: '',
  lpc_fractionation_interest: '',
  lpc_default_interest: '',
  lpc_reactivation_interest: '',
  lpc_installment_detail: '',
  lpc_effectiveAnnuityDate: '',
  lpc_firstInstallmentDate: '',
  lpc_expiryAnnuityDate: '',
  lpc_amountFirstInstallment: '',
  lpc_initialAnnuityAmount: '',
  lpc_periodicityInstallmentAnnuity: '',
  lpc_typePaymentAnnuity: '',
  lpc_performanceType: '',
  lpc_annuityType: '',
  lpc_annuity_detail: '',
  lpc_canceled_liquidation_detail: '',
  lpc_Percentage: '',
  lpc_loan_data: '',
  lpc_maxLoanAmount: '',
  lpc_loanRepaymentProcedure: '',
  lpc_loanAmount: '',
  lpc_rateType: '',
  lpc_maxLoanAmountKO: '',
  lpc_grant_date: '',
  lpc_loan_amount: '',
  lpc_return_way: '',
  lpc_interest_rate_type: '',
  lpc_residual_amount: '',
  lpc_refund_type: '',
  lpc_refund_amount: '',
  lpc_select_lpc_refund_type: '',
  lpc_leftInsuredAmount: '',
  lpc_insuredAmount: '',
  lpc_reactivatedCapital: '',
  lpc_premium_reactivation: '',
  lpc_negative_amount: '',
  lpc_zero_amount: '',
  lpc_error_mandatory_selected_type_of_refund: '',
  lpc_select_refund: '',
  lpc_selected_profiles: '',
  lpc_coupon_beneficiary: '',
  lpc_bookings: '',
  lpc_booking_type: '',
  lpc_booking_state: '',
  lpc_booking_effective_date: '',
  lpc_booking_extrapremiumHealth: '',
  lpc_booking_extrapremiumProf: '',
  lpc_booking_extrapremiumSport: '',
  lpc_booking_extrapremiumOther: '',
  lpc_booking_effectiveDate: '',
  lpc_booking_detail_label: '',
  lpc_Rischi: '',
  lpc_booking_destinationNode: '',
  lpc_booking_cause: '',
  lpc_PolicyData: '',
  lpc_booking_cancellationDate: '',
  lpc_extra_premiums: '',
  lpc_error_reservation_cancel: '',
  lpc_success_reservation_cancel: '',
  lpc_incomplete_disinvestment_for_the_funds: '',
  lpc_Sinistro_morte: '',
  lpc_Sinistro_invalidita: '',
  lpc_Sinistro_inabilità: '',
  lpc_Malattia_grave: '',
  lpc_insured_form: '',
  lpc_other_contract_list: '',
  lpc_modified_personal_data_for_the_subject: '',
  lpc_subject_roles: '',
  lpc_no_variation_modpd: '',
  lpc_DeductiblePremium: '',
  lpc_firstterm: '',
  lpc__1TERM_ATOOL: '',
  lpc__1TERM_MTOOL: '',
  lpc__1TERM_DTOOL: '',
  lpc_management_fees: '',
  lpc_investment_not_found: '',
  lpc_No_policies: '',
  lpc_variation_convention: '',
  lpc_andamento_polizza_alla_data: '',
  lpc_appendice_dichiarativa: '',
  lpc_policy_number_dot: '',
  lpc_investment_sum_must_be100: '',
  lpc_Operation_detail: '',
  lpc_Notwithstanding_any_ongoing_operations: '',
  lpc_percentage_max_zero: '',
  lpc_Mostra_tutti: '',
  lpc_Visualizza_dettaglio: '',
  lpc_consultazione_storica: '',
  lpc_dettaglio_movimento: '',
  lpc_mensile: '',
  lpc_annuale: '',
  lpc_sixmonthly: '',
  lpc_trimestrale: '',
  lpc_Titolo_non_incassato: '',
  lpc_Rid: '',
  lpc_In_attesa_valorizzazione_quote: '',
  lpc_switch_di_perequazione: '',
  lpc_SELEZIONA: '',
  lpc_settimanale: '',
  lpc_Valido: '',
  lpc_quotidiano: '',
  lpc_Assegno: '',
  lpc_Bonifico: '',
  lpc_Sdd: '',
  lpc_severeDisabilityMsg: '',
  lpc_benDg: '',
  lpc_distribution: '',
  lpc_Distribution_amount_exceeds: '',
  lpc_distribution_invalid_greater: '',
  lpc_distribution_invalid_greater_equal: '',
  lpc_perc: '',
  lpc_amount: '',
  lpc_Please_insert_a_distribution: '',
  lpc_For_the_Beneficiary: '',
  lpc_global_distribution: '',
  lpc_invalid_char: '',
  lpc_toDistribute: '',
  lpc_noClaims: '',
  lpc_Data_sinistro: '',
  lpc_Data_Pervenimento_Denuncia: '',
  lpc_Data_Denuncia_Sinistro: '',
  lpc_Tipo_sinistro: '',
  lpc_inserisci_denuncia: '',
  lpc_Conferma_inserimento_denuncia: '',
  lpc_Denuncia_inserita_correttamente: '',
  lpc_Stato_denuncia: '',
  lpc_marital_status: '',
  lpc_fiscal_code: '',
  lpc_birthdate: '',
  lpc_address: '',
  lpc_sex: '',
  lpc_annulla_denuncia: '',
  lpc_modifica_denuncia: '',
  lpc_conferma_denuncia: '',
  lpc_new_beneficiary: '',
  lpc_Seleziona: '',
  lpc_insert_phisical_sbj_for: '',
  lpc_insert_legal_sbj_for: '',
  lpc_payment_incomplete: '',
  lpc_add_mandatory_linkedroles: '',
  lpc_beneficiary_owner_recipient_must_be_physical: '',
  lpc_enter_the_beneficial_owners_of_the_recipient: '',
  lpc_global_percentage_must_be_a_maximum_100: '',
  lpc_overall_percentage_must_equal100: '',
  lpc_Questionario_deve_essere_compilato: '',
  lpc_enter_mandatory_data: '',
  lpc_enter_an_adult: '',
  lpc_enter_a_beneficial_owner: '',
  lpc_enter_a_physical_beneficial_owner: '',
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: '',
  lpc_beneficial_owners_percentages_must_be_greater_than0: '',
  lpc_effective_holder_perc: '',
  lpc_effective_holder_duplicated: '',
  lpc_enter_the_minimum_number_of_subjects: '',
  lpc_percentage_must_be_greater_than_0: '',
  lpc_enter_free_text: '',
  lpc_beneficiary_executor: '',
  lpc_effective_recipient: '',
  lpc_recipient_owner: '',
  lpc_beneficial_owner_of_beneficiary: '',
  lpc_confirmed_claim_modify: '',
  lpc_claim_modified_correctly: '',
  lpc_Assicurato: '',
  lpc_Assicurati: '',
  lpc_holderType: '',
  lpc_ageRetirement: '',
  lpc_accessTransfer: '',
  lpc_emptyContribution: '',
  lpc_firstEmployment: '',
  lpc_oldUnderWritt: '',
  lpc_underWitt1993: '',
  lpc_maturato2001: '',
  lpc_maturato2006: '',
  lpc_riduzImponibiTFR: '',
  lpc_aliquotaTFR: '',
  lpc_PIP_Contribution_Type: '',
  lpc_YES: '',
  lpc_PIP: '',
  lpc_PIP_Contribution_Percentage: '',
  lpc_PIP_Contribution_Amount: '',
  lpc_transfer_data: '',
  lpc_unit_questionnaire: '',
  lpc_amount_reimbured: '',
  lpc_Descr_not_handled: '',
  lpc_select_transfer: '',
  lpc_fiscalFolderDataPost2006: '',
  lpc_fiscalFolderData20012006: '',
  lpc_fiscalFolderDataBefore2001: '',
  lpc_filledOut: '',
  lpc_blank: '',
  lpc_desc_previousPenFund: '',
  lpc_companyPreviousPenFund: '',
  lpc_typePreviousPenFund: '',
  lpc_note: '',
  lpc_Benef_irrevocabile: '',
  lpc_PIP_Transfer: '',
  lpc_requestDate: '',
  lpc_Type_Previous_Pension_Fund: '',
  lpc_Previous_Pension_Fund: '',
  lpc_Import_Transfert: '',
  lpc_Note: '',
  lpc_relief: '',
  lpc_selected_settlement: '',
  lpc_EDIT_ANONYMOUS_PREV: '',
  lpc_invalid_quote: '',
  lpc_reasonForRequest: '',
  lpc_advancePaymentAmount: '',
  lpc_repaidAdvance: '',
  lpc_PIP_AdvancedPayment: '',
  lpc_requestReason: '',
  lpc_notRepaidAmount: '',
  lpc_fiscalDate: '',
  lpc_previousPension: '',
  lpc_fiscalCompetence: '',
  lpc_settlementStatus: '',
  lpc_repaidAmount: '',
  lpc_advancePaymentTransfer: '',
  lpc_policy_factors: '',
  lpc_asset_factors: '',
  lpc_Date_Variation: '',
  lpc_penalties_amount: '',
  lpc_tax_amount: '',
  lpc_warranties_detail: '',
  lpc_fixedCharges: '',
  lpc_catastrophe: '',
  lpc_health: '',
  lpc_other: '',
  lpc_professional: '',
  lpc_sports: '',
  lpc_collectionFee: '',
  lpc_lambdaFee: '',
  lpc_managementFee: '',
  lpc_purchaseFee: '',
  lpc_continue_button: '',
  lpc_confirm_button: '',
  lpc_authorization_request: '',
  lpc_questionSTR_invalid: '',
  lpc_questionNUM_invalid: '',
  lpc_Beneficiaryowner: '',
  lpc_confirm_operation: '',
  lpc_financial_questionnaire: '',
  lpc_avc_questionnaire: '',
  lpc_confirm_variation: '',
  lpc_Declarative_Appendix: '',
  lpc_investment_funds_step: '',
  lpc_disinvestment_profile_step: '',
  lpc_startDate: '',
  lpc_end_date: '',
  lpc_six_monthly: '',
  lpc_quarterly: '',
  lpc_weekly: '',
  lpc_monthly: '',
  lpc_annual: '',
  lpc_daily: '',
  lpc_start_date_cannot_be_earlier_than_the_effective_date: '',
  lpc_start_date_must_be_earlier_than_the_end_date: '',
  lpc_contract_value: '',
  lpc_invested_amount: '',
  lpc_funds_details_and_summary: '',
  lpc_separate_management_detail: '',
  lpc_denuncia_sinistro: '',
  lpc_duration_in: '',
  lpc_years: '',
  lpc_months: '',
  lpc_days: '',
  lpc_policy_number: '',
  lpc_proposal_number: '',
  lpc_LifeBeneficiary: '',
  lpc_DeathBeneficiary: '',
  lpc_NO: '',
  lpc_claim_data: '',
  lpc_node: '',
  lpc_sbj: '',
  lpc_empty: '',
  lpc_search_result: '',
  lpc_show_elemets: '',
  lpc_insured: '',
  lpc_nuova_denuncia: '',
  lpc_insured_policy: '',
  lpc_product: '',
  lpc_find: '',
  lpc_status: '',
  lpc_division_description: '',
  lpc_fund_trend: '',
  lpc_category_fund: '',
  lpc_equivalent_on_date: '',
  lpc_quotes_number: '',
  lpc_damages_policy: '',
  lpc_life_policy: '',
  lpc_next_installment_premium: '',
  lpc_no_result: '',
  lpc_data_value: '',
  lpc_paidAmount: '',
  lpc_total_gross_surrender: '',
  lpc_left_premiums_paid: '',
  lpc_left_premiums_amount: '',
  lpc_portfolio_volatility_on_subscription_date: '',
  lpc_portfolio_volatility: '',
  lpc_andamento_controvalore: '',
  lpc_policy_trend: '',
  lpc_calculate: '',
  lpc_frequency: '',
  lpc_date_variation_step: '',
  lpc_quotation_step: '',
  lpc_confirm_variation_step: '',
  lpc_financial_questionnaire_step: '',
  lpc_close_button: '',
  lpc_Operation_of_executed_correctly: '',
  lpc_AuthorizatioPolicyForwarded: '',
  lpc_are_you_sure_you_want_to_cancel_this_operation: '',
  lpc_cancel_button: '',
  lpc_cancel_reservation: '',
  lpc_system_error: '',
  lpc_generic_error_title: '',
  lpc_select_label: '',
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: '',
  lpc_factor_must_be_equal: '',
  lpc_factor_must_be_greater_than: '',
  lpc_factor_must_be_less_than: '',
  lpc_Please_enter_threshold_percentage_value: '',
  lpc_please_enter_a_value_between: '',
  lpc_variation_step_label: '',
  lpc_contractual_option: '',
  lpc_costs_step_label: '',
  lpc_variation_detail_step_label: '',
  lpc_ongoing_operations: '',
  lpc_exchange_rate: '',
  lpc_last_share_value: '',
  lpc_movement_description: '',
  lpc_settlement_amount: '',
  lpc_Annual_SinglePremium: '',
  lpc_policy_address: '',
  lpc_holders: '',
  lpc_last_revaluation: '',
  lpc_revalueted_benef: '',
  lpc_investment: '',
  lpc_position_number: '',
  lpc_invested_premium: '',
  lpc_operation_details: '',
  lpc_contract: '',
  lpc_dis_date: '',
  lpc_net_amount: '',
  lpc_net: '',
  lpc_quote: '',
  lpc_quote_and_save: '',
  lpc_info_investment_complete: '',
  lpc_effective_holders: '',
  lpc_effective_holder: '',
  lpc_lien_credit: '',
  lpc_notification_status: '',
  lpc_policyHolder: '',
  lpc_end_validity_date: '',
  lpc_start_date: '',
  lpc_unit_linked_value: '',
  lpc_allocation_profile: '',
  lpc_search_filter: '',
  lpc_expiry_option_detail: '',
  lpc_annuity_payment_type: '',
  lpc_annuity_frequency: '',
  lpc_percentage_reversibility: '',
  lpc_reversionary: '',
  lpc_certain_revenue_years: '',
  lpc_postponement_type: '',
  lpc_postponement_years: '',
  lpc_clauses: '',
  lpc_active_contractual_option: '',
  lpc_expiry_option: '',
  lpc_revaluation_history: '',
  lpc_monetary_fund: '',
  lpc_benefit_effect_date: '',
  lpc_initial_benefit: '',
  lpc_movement_update: '',
  lpc_update_effect_date: '',
  lpc_net_rate: '',
  lpc_macro_category: '',
  lpc_quotation_frequency: '',
  lpc_volatility: '',
  lpc_volatility_date: '',
  lpc_start_quotation: '',
  lpc_last_quotation: '',
  lpc_valuation: '',
  lpc_percentage_total: '',
  lpc_euro_valuation_not_fund_currency: '',
  lpc_disinvstements: '',
  lpc_life_detail_percentage: '',
  lpc_payment_amount: '',
  lpc_surrender_distribution: '',
  lpc_formula: '',
  lpc_roles_connected_benef: '',
  lpc_quotation_details: '',
  lpc_Disinvestmentsfunds: '',
  lpc_same_fund_distribution: '',
  lpc_guarantees_oveview: '',
  lpc_calculate_volatility: '',
  lpc_benef_death_not_same: '',
  lpc_global_percentage_equal_hundred: '',
  lpc_benefPresente_default_msg: '',
  lpc_effective_holder_natural_person_req: '',
  lpc_effective_holder_duplicate: '',
  lpc_effective_holder_adult_req: '',
  lpc_investment_plan: '',
  lpc_contract_detail: '',
  lpc_variation_reason: '',
  lpc_investment_plan_detail: '',
  lpc_revaluation_rate: '',
  lpc_benefit_commencement_date: '',
  lpc_update_effective_date: '',
  lpc_last_odds_value: '',
  lpc_total: '',
  lpc__EFFOP: '',
  lpc__OPZFR: '',
  lpc__OPZPM: '',
  lpc__OPBEN: '',
  lpc__MINTS: '',
  lpc__MAXTS: '',
  lpc__NUMOC: '',
  lpc__AMTOP: '',
  lpc__MALMT: '',
  lpc__CIBAN: '',
  lpc__PCOUP: '',
  lpc_threshold_percentage: '',
  lpc_pure: '',
  lpc_starting_fund: '',
  lpc_details: '',
  lpc_treshold: '',
  lpc_inv_date: '',
  lpc_beneficiary_perc_req: '',
  lpc_beneficiary_perc_req_range: '',
  lpc_invalid_total_position_percent: '',
  lpc_beneficiary_from_party_req: '',
  lpc_benefPresente: '',
  lpc_benMorteNoAssic: '',
  lpc_life_beneficiary: '',
  lpc_death_beneficiary: '',
  lpc_beneficiary_position_mandatory: '',
  lpc_reversionary_subject: '',
  lpc_gross_detail: '',
  lpc_type_of_covers: '',
  lpc_beneficiary_qualification: '',
  lpc_not_linked_detail: '',
  lpc_clausesinsuredClauses: '',
  lpc_clausespolicyClauses: '',
  lpc_clausesriskClauses: '',
  lpc_max_capital_amount: '',
  lpc_fiscal_period_M1: '',
  lpc_fiscal_period_M2: '',
  lpc_fiscal_period_M3: '',
  lpc_fiscal_period_capital_request_K1: '',
  lpc_fiscal_period_capital_request_K2: '',
  lpc_fiscal_period_capital_request_K3: '',
  lpc_max_amount_exceeded: '',
  lpc_max_amount_error: '',
  lpc_performance_data: '',
  lpc_role_is_mandatory: '',
  lpc_issue_authorized_proposal: '',
  lpc_confirm_prop_pol_auth: '',
  lpc_settlement: '',
  lpc_annuity: '',
  lpc_percentage_in_capital: '',
  lpc_insured_amount: '',
  lpc_custom_field_mandatory_error: '',
  lpc_print_docs: '',
  lpc_revisionary_err: '',
  lpc_revisionary_perc_err: '',
  lpc_modify_quote: '',
  lpc_modify_proposal: '',
  lpc_occurrenceNumber: '',
  lpc_gross_amount: '',
  lpc_third_party_role: '',
  lpc_not_same_fund_distribution: '',
  lpc_percentage_must_be_between: '',
  lpc_PIP_UndeductedPremiums: '',
  lpc_contributionYear: '',
  lpc_annualPremium: '',
  lpc_undeductedPremium : '',
  lpc_reversionario: '',
  lpc_firstEnrollmentComplementaryPension: '',
  lpc_PIP_amountForTaxation: '',
  lpc_PIP_amount: '',
  lpc_PIP_contribempl4Perc: '',
  lpc_PIP_reducttaxbltfr: '',
  lpc_PIP_aliqtfr: '',
  lpc_PIP_premdeducted2001: '',
  lpc_PIP_premdeductoffset: '',
  lpc_PIP_annuitytaxdeduct: '',
  lpc_pip_contributions_installment_detail: 'DE_Contributions Details',
  lpc_contribution_type: 'DE_Contribution type',
  lpc_contribution_amount: 'DE_Amount',
  lpc_contribution_year: 'DE_Year',
  lpc_contribution_month_from: 'DE_Month (from)',
  lpc_contribution_month_to: 'DE_Month (to)',
  lpc_contribution_thirteenth: 'DE_13^',
  lpc_contribution_fourteenth: 'DE_14^',
  lpc_incomplete_fund_investment: '',
  lpc_incomplete_profile_investment: '',
  lpc_tax_credit: '',
  lpc_clausesinsuredClause: '',
  lpc_clausespolicyClause: '',
  lpc_clausesriskClause: '',
  lpc_tariff_version: '',
  lic_purchase_commissions: '',
  lpc_inbound_documents_mov: '',
  lpc_single_premium: ''
};
export default LPC_DE;
