import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Movement } from '../../../models/movement.model';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { DocumentsService } from '@rgi/shared-components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lpc-inquiry-in-document',
  templateUrl: './lpc-inquiry-in-document.component.html',
  styleUrls: ['./lpc-inquiry-in-document.component.css']
})
export class LpcInquiryInDocumentComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() movement: Movement;
  @Input() proposalNumber: string;
  @Input() contractId: string;

  @Output() eventPropagation = new EventEmitter<any>();

  /** documents
  documents: any;*/

  $subscriptions: Subscription[] = [];

  constructor(
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected rgiDocumentService: DocumentsService
  ) { }


  ngOnInit() {
    this.plcGraphicFix.fixCardGraphic(this.id);
    /* if (this.movement?.idMov) {
      this.$subscriptions.push(
        this.rgiDocumentService.getDocumentsByMovIDAndProposalNumber(this.proposalNumber, this.movement.idMov.toString(), '2').subscribe((documents) => {
          this.documents = documents;
        })
      );
    } */
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  ngAfterViewChecked() {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  startDocuments(event: any) {
    /* if (event) {
      this.documentsCompleted = event.completed;
    } */
  }

  verifyDocuments(event: any) {
    /* if (event) {
      // this.enableSaveProposal = true;
      // this.uploadDocumentsChanged = true;
      this.documentsCompleted = event.completed;
    } */
  }
}
