/*
 * Public API Surface of iiab-portal-ext
 */

export * from './lib/iiab-portal-ext.module';
export * from './proposal-otp/proposal-otp-service/proposal-otp.service';
export * from './services/otp-service.service';
export * from './life-issue-card-ext/life-card/services/policy-service-ext';

export { ProposalOtpModule } from './proposal-otp/proposal-otp.module';
export { LicInvestmentsExtModule } from './life-issue-card-ext/life-card/invest-standalone-session/lic-investments-ext.module';

export * from './life-issue-card-ext/issue/issue-ext.component';
export * from './life-issue-card-ext/dati-amm/dati-amm-ext.component';
export * from './life-issue-card-ext/summary/summary-ext.component';
export * from './ext/anag-cards/privacy/anag-privacy.component-ext'
export * from './life-issue-card-ext/life-card/invest-standalone-session/component/lic-investments/lic-investments-ext.component';
export * from './life-postsales-card-ext/life-detail/life-detail-ext.component';
export * from './life-postsales-card-ext/lpc-inquiry-in-document/lpc-inquiry-in-document-ext.component';
export * from './passpro-pro-survey-ext/state/survey-evaluate-state-operations-ext.service';
export * from './proposal-otp/party-otp-component/party-otp.component';
export * from './proposal-otp/proposal-otp-component/proposal-otp.component';
export * from './proposal-otp/proposal-otp-service/proposal-otp.service';
export * from './proposal-otp/proposal-otp.module';
export * from './services/otp-service.service';
export * from './digital-sign-documents/digital-sign-documents.component';
export * from './issue-privacy-edit/issue-privacy-edit.component';
export * from './models/otp-document.model';
export * from './ext-rest/life/api';
export * from './life-issue-card-ext/life-card-ext.component';
export * from './life-issue-card-ext/life-card/authorizationFlowService.service';


// lib
export * from './services/lib-portal-integration.service';
export * from './lib/total-summary-card/total-summary-card.module';
export * from './lib/total-summary-card/components/total-summary.component';

// module
export * from './lib/total-summary-card/total-summary-card.module';
export * from './ext/authorizations-card/authorizations-ext.module';

// service
export * from './lib/total-summary-card/services/total-summary.service';
export * from './ext-rest/life/parties/service/parties.service';
export * from './ext-rest/life/common/model/models';
export * from './ext-rest/life/parties/model/models';
export * from  './ext/anag-cards/anag-resources/anag-utils.service-ext';

// enums
export * from './ext-rest/life/common/model/enums';
