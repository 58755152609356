<br>
<lic-quotation-controls
  [annualPremiumValue]="annualPremiumValue"
  [premiumInstallment]="inst"
  (recalculate)="quote()"
  (garDetail)="openGarDetailModal(garDetailModal)"
  (detail)="openDetailModal(detailModal)"
  [recalculateAvailable]="isQuotationAvailable()"
  (formule)="openFormuleModal(formuleModal)"
  [disableFormule]="disableFormule"
  (prov)="openProvModal(provModal)"
  [disableProv]="!areCommissionPresent()"
  (unitsViewMode)="null"
  (showCommonRisk)="openCommonRiskModal(commoRiskModal)"
  [damagesInstalment]="damagesInstalment" [damageRisk]="damageRisk" [operator]="operator">
</lic-quotation-controls>
<br><br><br>


<div class="core-header-icon ">
  <span class=" header-icon rgifont rgi-clausole"></span>
  <span class="header-text" translate>lic_available_covers</span>
  <span class="pull-right header-text"> </span>
</div>
<div class="example-row quotation-page" id="quotation-page" style="height: auto;">

  <ng-container>
    <form [formGroup]="quotationForm">
      <lic-units-select *ngIf="!isGrcovPropEnabled()" [unitsForm]="quotationForm.get('unitsForm')" [instancesArray]="instances"
        [lifeRiskInsuredMap]="lifeRiskInsuredMap" [openSelectedUnits]="false" [quotatedValues]="quotatedValues"
        (update)="updateUnit($event)" (undo)="undoChanges()">
      </lic-units-select>
      <lic-units-select-group *ngIf="isGrcovPropEnabled()" [unitsForm]="quotationForm.get('unitsForm')" [instancesArray]="instances"
        [lifeRiskInsuredMap]="lifeRiskInsuredMap" [openSelectedUnits]="false" [quotatedValues]="quotatedValues"
        (update)="updateUnit($event)" (undo)="undoChanges()">
      </lic-units-select-group>
      <div class="core-header-icon" *ngIf="showStepBeneficiaries">
        <span class=" header-icon rgifont rgi-ui-icon-hand-hold-user-group"></span>
        <span class="header-text" data-qa="section-beneficiaries" translate>lic_beneficiaries_label</span>
      </div>


    </form>
  </ng-container>


  <!-- BENEFICIARI -->
  <div *ngIf="showStepBeneficiaries" style="margin-left:10px;" class="row">
    <div [formGroup]="beneficiaryForm">
      <div *ngFor="let instance of instances">
        <div class="col-lg-6" *ngFor="let cat of allCategories">
          <div [formGroupName]="cat.beneficiaryType.codice" [attr.data-qa]="'benef-'+cat.beneficiaryType.codice" class="p-1">
            <rgi-rx-form-field [attr.data-obb]="'benef' + cat.beneficiaryType.descrizione + 'true'" [attr.data-qa]="'benef_' + cat.beneficiaryType.descrizione">
              <label rgiRxLabel>
                <span>{{cat.beneficiaryType.descrizione}}</span>
                </label>
              <select rgiRxNativeSelect formControlName="choiceValue" (change)="changeValueBenef(cat.beneficiaryType.codice)">
                <option value=""></option>
                <option *ngFor="let benefOpt of cat.values" [value]="benefOpt.code">{{ benefOpt.description }}</option>
              </select>
            </rgi-rx-form-field>
            <div class="body-panel" *ngIf="showFreeText(cat.beneficiaryType)">
              <label class="lbl_input" translate>lic_appendixLabel</label>
              <lic-required required="true"></lic-required>
              <input type="text" class="form-control" maxLength=500 formControlName="freeText"
                data-qa="beneficiary-appendix"
                [ngClass]="{ 'is-invalid': beneficiaryForm.get(cat.beneficiaryType.codice).get('freeText').invalid && beneficiaryForm.get(cat.beneficiaryType.codice).get('freeText').dirty && submitted }">
            </div>
            <div class="body-panel" *ngIf="showSelectBenef(cat.beneficiaryType)">
              <label translate class="lbl_input" translate>lic_appendixLabel</label>
              <lic-required required="true"></lic-required>
              <lic-beneficiaries-select [customForm]="beneficiaryForm.controls[cat.beneficiaryType.codice]"
                [minSubj]="minAnagSugject.get(cat.beneficiaryType.codice)"
                [maxSubj]="maxAnagSugject.get(cat.beneficiaryType.codice)" [(submitted)]="submitted"
                [insuredId]="!!insuredValue? insuredValue.objectId:''" (message)="printMessage($event)"
                [cat]="cat.beneficiaryType.codice" (deleteBen)="delete($event)" (eventPropagation)="openAnag($event)"
                (subjetAnagEditedEvent)="editAnag($event)" (deleteRelSubj)="deleteRelSubject($event)"
                [_REG41Property]="regProperty">
              </lic-beneficiaries-select>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
    <br><br><br>
  </div>
  <div class="row" *ngIf="showStepThirdRef">
    <div class="col-md-6">
      <div class="core-header-icon">
        <span class=" header-icon rgifont rgi-ui-icon-users-arrows"></span>
        <span class="header-text" translate>lic_third_contact_person</span>
      </div>
      <div style="padding-left:15px">
        <lic-select-subject (delete)="deleteRef($event)" [parentForm]="beneficiaryForm.get('nuovoRefTerzo')"
          (eventPropagation)="openRefAnag($event)" [role]="thirdRefCode"
          [val]="beneficiaryForm.get('nuovoRefTerzo').get('val').value">
        </lic-select-subject>
      </div>
    </div>
    <br>
  </div>
  <div class="row" *ngIf="showAssignmentHolder" style="margin-top:10px;">
    <div class="col-md-6">
      <div class="core-header-icon">
        <span class=" header-icon rgifont rgi-utenteterzo"></span>
        <span class="header-text" translate>lic_assignment_holder</span>
      </div>
      <div style="padding-left:15px">
        <lic-select-subject
          (delete)="deleteVinc($event)"
          [parentForm]="quotationForm.get('entVincolatario')"
          (eventPropagation)="openVincAnag($event)"
          [role]="vincolCode"
          [val]="quotationForm.get('entVincolatario').get('val').value">
        </lic-select-subject>
      </div>
    </div>
    <div class="row" *ngIf="showAssignmentFields">
      <div>
        <div class="col-lg-12">
            <ng-container [ngTemplateOutlet]="template"
                      [ngTemplateOutletContext] ="{assignmentHolderDefinition:assignmentData?.openDate , assignmentHolderControlName:'openDate' , form: assignmentHolderForm}">
            </ng-container>
            <ng-container [ngTemplateOutlet]="template"
                      [ngTemplateOutletContext] ="{assignmentHolderDefinition:assignmentData?.closeDate , assignmentHolderControlName:'closeDate' , form: assignmentHolderForm}">
            </ng-container>
        </div>
        <div class="col-lg-12">
            <ng-container [ngTemplateOutlet]="template"
                      [ngTemplateOutletContext] ="{assignmentHolderDefinition:assignmentData?.assignmentHolderType , assignmentHolderControlName:'assignmentHolderType' , form: assignmentHolderForm}">
            </ng-container>
        </div>
        <div class="col-lg-12">
            <ng-container [ngTemplateOutlet]="template"
                      [ngTemplateOutletContext] ="{assignmentHolderDefinition:assignmentData?.contractNumber , assignmentHolderControlName:'contractNumber' , form: assignmentHolderForm}">
            </ng-container>
        </div>
        <div class="col-lg-12">
            <ng-container [ngTemplateOutlet]="template"
                      [ngTemplateOutletContext] ="{assignmentHolderDefinition:assignmentData?.assignmentType , assignmentHolderControlName:'assignmentType' , form: assignmentHolderForm}">
            </ng-container>
        </div>
        <div class="hr-row col-lg-12">
          <hr>
        </div>
      </div>
    </div>
    <br>
  </div>

  <div *ngIf="showStepQuestionnaires" id="panel-quest" class="example-row">
    <rgi-rx-expansion-panel [expanded]="true" color="default">
      <div rgi-rx-expansion-panel-header>
        <span data-qa="panel-button" class="rgifont"></span>
        <span class="header-icon rgifont rgi-questionari"></span>
        <span class="header-text" data-qa="accordion-questionnaires" translate>lic_questionnaires_section</span>
      </div>
      <div content>
        <div class="panel-body">
          <lic-questionnaires (emitSave)="saveQuestionnaire($event)" (emitSaveOnInit)="saveQuestionnaire($event, true)"
            (emitDelete)="deleteQuestionnaire($event)" [questionnairesList]="questionnairesList"
            [customFormGroup]="questForm" (loaderQuest)="loaderQuestManager($event)">
          </lic-questionnaires>
          <lib-questionnaires-manager [modalClass]="'life-quest-modal-flow'" data-qa="questQuotation" class="col-lg-6"
            key={{ppevoQuestKey}} d={{effectiveDate}} customQuestLoader="BY_CODE" [questionnairesCode]="validQuestsCode"
            [savedIds]="getSavedUUIDs()" (listLoad)="countTotalQuest()" (loaderQuest)="loaderQuestManager($event)"
            [f]=questFactorsArray [sq]="prevalByQuestion" [dq]="disabledQuestionArray">
          </lib-questionnaires-manager>
        </div>
      </div>
    </rgi-rx-expansion-panel>
  </div>

  <div class="example-row">

    <!-- Dialog per il salvataggio intermedio della proposta -->
    <rgi-rx-modal #proposalSaveModal>
      <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="proposalSaveModal.close()">{{'lic_NumeroProposta' | translate}}</rgi-rx-panel-header>
        <rgi-rx-panel-footer>
          <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="proposalSaveModal.close()" translate>lic_close</button>
        </rgi-rx-panel-footer>
        <span translate>lic_Proposal_Saved</span> {{proposalNumber}}
      </rgi-rx-panel>
    </rgi-rx-modal>

    <!-- MESSAGGI di ERRORE -->
    <!-- <pnc-floating-error-messages [blockingMsgs]="getBlockingMessages" [warningMsgs]="getWarningMessages"
      [authoMsgs]="getAuthMessages">
    </pnc-floating-error-messages> -->

    <rgi-rx-snackbar tag="blocking"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="warning"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="auth"></rgi-rx-snackbar>

    <!-- TASTO AVANTI -->
    <div class="btn-group btn-group-justified">
      <div class="btn-group" *ngIf="showSaveButton()">
        <button type="submit" class="btn btn-warning pull-left btn-secondary text-uppercase" data-qa="lic-role-save" (click)="onSubmit(true, proposalSaveModal)"
          translate>lic_save</button>
      </div>
      <div class="btn-group" *ngIf="!isFromPreventive">
        <button type="button" (click)="onSubmit()" data-qa="lic-quotation-submit"
          class="btn btn-warning pull-right text-uppercase" translate>lic_NEXT</button>
      </div>
      <div class="btn-group" *ngIf="isFromPreventive">
        <button type="button" (click)="onSubmitSavePreventive()" data-qa="lic-quotation-submit"
          class="btn btn-warning pull-right text-uppercase" translate>lic_SaveEstimate</button>
      </div>
    </div>
  </div>


  <!-- modale dettaglio quotazione -->
  <rgi-rx-modal #detailModal>
    <ng-container *ngIf="showDetailModal">
      <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="detailModal.close()">{{'lic_quotation_detail_modal' | translate}}</rgi-rx-panel-header>
        <rgi-rx-panel-footer>
          <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="detailModal.close(); showDetailModal= false" translate>lic_close</button>
        </rgi-rx-panel-footer>
        <ng-container content>
          <lic-premium-table [primaRata]="primaRata" [policyPremium]="policyPremium"
            [ifNotSingleFrequency]="getPaymentFrequencyCode()" [damagesInstalment]="damagesInstalment"
            [damageRisk]="damageRisk">
          </lic-premium-table>
        </ng-container>
      </rgi-rx-panel>
    </ng-container>
  </rgi-rx-modal>

  <!-- modale garanzie -->
  <rgi-rx-modal #garDetailModal>
    <ng-container *ngIf="showGarDetailModal">
      <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="garDetailModal.close(); showGarDetailModal= false">{{'lic_covers_overview' | translate}}</rgi-rx-panel-header>
        <rgi-rx-panel-footer>
          <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="garDetailModal.close(); showGarDetailModal= false" translate>lic_close</button>
        </rgi-rx-panel-footer>
        <ng-container content>
          <ng-container *ngIf="isGrcovPropEnabled(); else notGroupedUnit">
            <lic-warranties-group-table [policyPremium]="policyPremium" [riskPremium]="riskPremium"
              [ifNotSingleFrequency]="getPaymentFrequencyCode()" [warrantiesDescr]="warrantiesDescr"
              [damagesInstalment]="damagesInstalment" [damageRisk]="damageRisk">
            </lic-warranties-group-table>

          </ng-container>
          <ng-template #notGroupedUnit>
            <lic-warranties-table [policyPremium]="policyPremium" [riskPremium]="riskPremium"
              [ifNotSingleFrequency]="getPaymentFrequencyCode()" [warrantiesDescr]="warrantiesDescr"
              [damagesInstalment]="damagesInstalment" [damageRisk]="damageRisk">
            </lic-warranties-table>
          </ng-template>
        </ng-container>
      </rgi-rx-panel>
    </ng-container>
  </rgi-rx-modal>



  <!-- Modale delle Formule -->
  <rgi-rx-modal #formuleModal>
    <ng-container *ngIf="showFormuleModal">
      <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="closeFormulaModal(formuleModal)">{{'lic_premium_detail' | translate}}</rgi-rx-panel-header>
        <rgi-rx-panel-footer>
          <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="closeFormulaModal(formuleModal)" translate>lic_close</button>
          <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="saveFormulaVal(formuleModal)" translate>lic_quote_save</button>
        </rgi-rx-panel-footer>
        <ng-container content>
          <form [formGroup]="formuleModalForm">
            <div *ngFor="let ins of vectorInsuredRisk" class="tbld_col_title_premi">
              <div *ngFor="let risk of ins">
                <form [formGroup]="formuleModalForm.get(risk[0].assetInstanceName)">
                  <div class="row header-unit-modal">
                    <b>{{risk[0].riskDescription}}</b>
                  </div>
                  <div *ngFor="let f of risk" class="row row-formule-modal">
                    <form [formGroup]="formuleModalForm.get(risk[0].assetInstanceName).get(f.riskCode)">
                      <div class="col-lg-10">
                        <b style="font-size: 15px">{{f.description}}</b>
                      </div>
                      <div class="col-lg-2">
                        <input [readonly]="!f.modifiable" class="form-control input-modal-formula" [formControlName]="f.code" [value]="f.value"
                         licCustomNumberFormatDirective />
                      </div>
                    </form>
                  </div>
                </form>
              </div>
            </div>
          </form>
        </ng-container>
      </rgi-rx-panel>
    </ng-container>
  </rgi-rx-modal>

  <!-- modale provvigioni -->
  <rgi-rx-modal #provModal>
    <ng-container *ngIf="showProvModal">
      <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="provModal.close(); showProvModal=false">{{'lic_commissions' | translate}}</rgi-rx-panel-header>
        <rgi-rx-panel-footer>
          <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="provModal.close(); showProvModal=false" translate>lic_close</button>
        </rgi-rx-panel-footer>
        <ng-container content>
          <div *ngIf="!!filterAllCommission('1').length">
            <div class="row header-warranties">
              <div class="col-lg-12">
                <p style="font-weight: bold" translate>lic_purchase_commissions</p>
              </div>
            </div>
            <table class="table-responsive table coreDT life-detail-margin-top">
              <thead>
                <tr class="head-result thDataTable life-detail-tbld_row_header">
                  <th *ngFor="let header of [{description: 'lic_cover'}].concat(filterAllCommission('1')); let i = index" [ngClass]="{'align-text-center': i !== 0}">{{ getHeader(header.description) }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let inst of filterCommissionForRisk('1'); let i = index">
                  <tr class="table-row table-background">
                    <td class="table-border" [attr.data-qa]="'risk_' + inst.riskCode" [ngClass]="{'align-text-center': i !== 0}">
                      {{warrantiesDescr.get(inst.riskCode)}}
                    </td>
                    <td *ngFor="let commission of filterAllCommission('1')" class="table-border" [attr.data-qa]="'commission_' + commission.code">
                      {{findValueOfRiskCommission(inst.commissionFormula, commission.code) | rgiCountryLayerNumberFormat : locale : policyService.getFormatterOptionsWithDecimal('decimal', '0.2-2')}}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div *ngIf="!!filterAllCommission('2').length">
            <div class="row header-warranties">
              <div class="col-lg-12">
                <p style="font-weight: bold" translate>lic_sales_commissions</p>
              </div>
            </div>
            <table class="table-responsive table coreDT life-detail-margin-top">
              <thead>
                <tr class="head-result thDataTable life-detail-tbld_row_header">
                  <th *ngFor="let header of [{description: 'lic_cover'}].concat(filterAllCommission('2')); let i = index" [ngClass]="{'align-text-center': i !== 0}">{{ header.description | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let inst of filterCommissionForRisk('2'); let i = index">
                  <tr class="table-row table-background">
                    <td class="table-border" [attr.data-qa]="'risk_' + inst.riskCode" [ngClass]="{'align-text-center': i !== 0}">
                      {{warrantiesDescr.get(inst.riskCode)}}
                    </td>
                    <td *ngFor="let commission of filterAllCommission('2')" class="table-border" [attr.data-qa]="'commission_' + commission.code">
                      {{findValueOfRiskCommission(inst.commissionFormula, commission.code) | rgiCountryLayerNumberFormat : locale}}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
      </rgi-rx-panel>
    </ng-container>
  </rgi-rx-modal>


  <!-- modale common risk -->
  <rgi-rx-modal #commoRiskModal>
    <ng-container *ngIf="showCommonRiskModal">
      <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="commoRiskModal.close(); showCommonRiskModal=false">{{'lic_common_risk' | translate}}</rgi-rx-panel-header>
        <rgi-rx-panel-footer>
          <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="commoRiskModal.close(); showCommonRiskModal=false" translate>lic_close</button>
        </rgi-rx-panel-footer>
        <ng-container content>
          <div class="tbld tbld_contributions_taxes hidden-xs">
            <div class="tbld_row tbld_row_contributions_taxes_header">
              <div class="tbld_col" style="text-align: left;" translate>lic_Policynumber</div>
              <div class="tbld_col" style="text-align: left;" translate>lic_NumeroProposta</div>
              <div class="tbld_col" style="text-align: left;" translate>lic_DescrizioneProdotto</div>
              <div class="tbld_col" style="text-align: left;" translate>lic_DescrizioneRischio</div>
              <div class="tbld_col" style="text-align: left;" translate>lic_CapitaleAssicurato</div>
            </div>
            <span style="display: contents">
              <div class="tbld_row tbld_row_contributions_taxes" *ngFor="let row of commonRiskTable">
                <div class="tbld_col" style="text-align: left;">{{row?.policyNumber}}</div>
                <div class="tbld_col" style="text-align: left;">{{row?.proposalNumber}}</div>
                <div class="tbld_col" style="text-align: left;">{{row?.productDesc}}</div>
                <div class="tbld_col" style="text-align: left;">{{row?.riskDesc}}</div>
                <div class="tbld_col">{{row?.insuredAmount | currency:currencyCode:true }}</div>
              </div>
            </span>
          </div>
        </ng-container>
      </rgi-rx-panel>
    </ng-container>
  </rgi-rx-modal>


<ng-template let-FormField="assignmentHolderDefinition" let-name="assignmentHolderControlName" let-Form="form" #template>
  <div class="col-lg-6"  [formGroup]="Form">
    <lic-generator [definition]="FormField"
               [formControlName]="name"
               (eventPropagation)="onEmit($event)"
               [disabled]="FormField.readOnly"
               [mandatory]="FormField.mandatory">
  </lic-generator>
  </div>
</ng-template>
