import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { InvestmentAdapterConfig } from '../../../interfaces/operation-with-financial-step';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { ToolCode } from '../../../models/enums/vita.enum';
import { percentageSumEqual100Validator } from '../../../utils/custom-validators/custom-validators-utils';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { KarmaFund } from '../model/karma-fund';
import { KarmaFundDefinition } from '../model/karma-fund-definition';
import { KarmaProfile } from '../model/karma-profile';
import { KarmaProfileDefinition } from '../model/karma-profile-definition';
import { PlcFormatterUtils } from './PlcFormatter-utils';

export class LpcKarmaFundUtils {

  public static getDefaultFundStepValueOf(profileDefinitions: KarmaProfileDefinition[]): any {
    const obj: any = {
      profiles: {},
      funds: {}
    };
    profileDefinitions.forEach(profile => {
      obj.profiles[profile.id] = profile.value ? profile.value : null;
      obj.funds[profile.id] = {};
      profile.funds.forEach(fund => {
        obj.funds[profile.id][fund.id] = fund.value ? fund.value : null;
      });
    });
    return obj;
  }

  public static filterProfileDefinitionBy(
    definitions: KarmaProfileDefinition[], profiles: KarmaProfileDefinition[] | KarmaProfile[]
  ): KarmaProfileDefinition[] {
    const investmentIds: any = {};
    profiles.forEach(investmentProfile => {
      investmentIds[investmentProfile.id] = investmentProfile.funds.map(fund => fund.id);
    });

    return definitions
      .filter(profile => investmentIds.hasOwnProperty(profile.id))
      .map(profile => {
        const prfl: KarmaProfileDefinition = Object.assign({}, profile);
        prfl.funds = profile.funds.filter(fund => (investmentIds[profile.id] as string[]).includes(fund.id));
        return prfl;
      });
  }

  public static getProfileFormValueOf(profileDefinitions: KarmaProfileDefinition[], toolId = EMPTY_STR, type = EMPTY_STR): any {
    const obj: any = {};
    profileDefinitions.forEach(profile => {
      obj[profile.id] = {
        percent: profile.percent ? PlcFormatterUtils.getPercentValueConverted(profile.percent) : null,
        funds: {}
      };
      profile.funds.forEach(fund => {
        if (toolId === ToolCode.PROGRESSIVE_SWITCH) {
          obj[profile.id].funds[fund.id] = fund.value ? fund.value : null;
        } else {
          obj[profile.id].funds[fund.id] = LpcKarmaFundUtils.getCorrectPercentageValue(fund, type);
        }
      });
    });
    return obj;
  }

  public static getProfilesValueFromForm(profiles: any, funds: any, toHundred: boolean = false): KarmaProfile[] {
    profiles = profiles ? profiles : {};
    const array: KarmaProfile[] = [];
    Object.keys(profiles).forEach(profileKey => {
      const profile: KarmaProfile = {
        id: profileKey,
        percentage: profiles[profileKey],
        funds: []
      };
      if (!!profile.percentage) {
        if (funds && !!funds[profileKey]) {
          Object.keys(funds[profileKey]).forEach(fundKey => {
            let percent: number = funds[profileKey][fundKey] ? funds[profileKey][fundKey] : 0;
            if (toHundred) {
              percent *= 100;
            }
            const fund: KarmaFund = {
              id: fundKey,
              percentage: percent
            };
            if (fund.percentage !== 0) {
              profile.funds.push(fund);
            }
          });
        }
        array.push(profile);
      }
    });
    return array;
  }

  /**
   * @description
   * for a different type of kelia response handling the BE returns a value as a percent,
   * to avoid this issue its handled here ckecking the type of investment.
   * The count digit method helps to identify if its a percentage.
   */
  private static getCorrectPercentageValue(fund: KarmaFundDefinition, type: string): number {
    const value = PlcFormatterUtils.getPercentValueConverted(fund.percent) ? fund.percent : null;
    if (!type) { return value; }
    if (type === 'disinvest' && !value && PlcObjectUtils.countDigit(fund.value) === 0) {
      return fund.value;
    }
    if (type === 'disinvest') { return value; }
  }

  public static getSelectedProfiles(fg, profileDefinitions) {
    const profiles: any = fg.getRawValue().investmentProfiles ? fg.getRawValue().investmentProfiles : {};
    const profileKeys = Object.keys(profiles).filter(key => !!profiles[key]);
    return profileDefinitions.filter(pr => profileKeys.includes(pr.id));
  }

  // calcolo della volatilità - è possibile chiamare il servizio?
public static canCalculateVolatility(
    formGroup, isAlreadyPresent = false, nextStep: string, skipDisinvestment: boolean, skipProfiles: boolean, result?: any
): boolean {
    if (!isAlreadyPresent && (
        ((nextStep === 'disinvestmentProfiles' || nextStep === 'disinvestmentFunds') && (skipDisinvestment && skipProfiles)) ||
        (nextStep === 'investmentProfiles' && skipProfiles) ||
        nextStep === 'investmentFunds')
    ) {
        // Check if result and required properties are present
        if (result && result.data && result.data.operationData && result.data.operationData.data &&
            result.data.operationData.data.investmentProfiles &&
            result.data.operationData.data.investmentProfiles.length > 0) {

            const investmentProfiles = result.data.operationData.data.investmentProfiles[0];

            // Check if funds property exists and has length
            const hasFunds = !!(investmentProfiles.funds && investmentProfiles.funds.length > 0);

            // Controllo che i form siano validi per poter calcolare la volatilità
            const isInvestmentsProfileValid = !!formGroup.get('investmentProfiles') && !formGroup.get('investmentProfiles').invalid;
            const isInvestmentsFundsValid = !!formGroup.get('investmentFunds') && !formGroup.get('investmentFunds').invalid;

            return hasFunds && isInvestmentsProfileValid && isInvestmentsFundsValid;
        } else {
            // Handle the case where result or required properties are missing
            return false;
        }
    } else {
        return false;
    }
}

  /**
   * @description gestisce lo step degli investimenti/disinvestimenti tramite dei metodi che:
   * - setta i form prevalorizzando in base all'input configurations
   * - aggiorna le definitions con il valore selezionato ad ogni update
   *  - aggiunge/rimuove i validatori per mantenere il modello sempre corretto
   */
  public static handleInvestmentsOnUpdate(
    profiles: KarmaProfileDefinition[], fg: UntypedFormGroup, profileConfig: InvestmentAdapterConfig, fundConfig: InvestmentAdapterConfig
  ) {
    // aggiorna i valori dei profiles e setta le validazioni in base alle selezioni
    LpcKarmaFundUtils.prevalProfileAndFundsForm(profiles, fg, profileConfig, fundConfig);
    // aggiorna le definition dell'operazione così da avere sempre la situazione corretta ad ogni avanti dei vari step
    LpcKarmaFundUtils.updateProfileAndFundDefinition(profiles, fg, profileConfig.profileFormGroupName, fundConfig.fundFormGroupName);
    // aggiorna le validazioni in base ai nuovi valori
    LpcKarmaFundUtils.updateValidatorsOnProfilesAndFunds(profiles, fg, profileConfig, fundConfig);
  }

  /**
   * @description aggiorna i valori dei form a partire da quelli della definition
   * è possibile prevalorizzare solo profili/fondi -> non passare il controlName di quello che si vuole saltare
   * gestisce i validatori in base ai booleani (se il profilo non ha percentuale, toglie il validatore sui fondi)
   */
  public static prevalProfileAndFundsForm(
    profiles: KarmaProfileDefinition[], fg: UntypedFormGroup, profileConfig: InvestmentAdapterConfig, fundConfig: InvestmentAdapterConfig
  ) {
    const profileControlName = profileConfig.profileFormGroupName;
    const fundControlName = fundConfig.fundFormGroupName;

    if (!!profiles && profiles.length > 0) {
      profiles.forEach(p => {
        // -------------------- PROFILI --------------------
        if (!!profileControlName) {
          if (!fg.get(profileControlName).get(p.id)) {
            if (profileConfig.prevalIfLengthEqual1 && profiles.length === 1) {
              // un solo profilo + prevalIfLengthEqual1 a true -> setto 100%
              (fg.get(profileControlName) as UntypedFormGroup).addControl(p.id, new UntypedFormControl(1));
            } else { // creo il controller con il valore dalle definitions
              (fg.get(profileControlName) as UntypedFormGroup).addControl(p.id, new UntypedFormControl(this.getElementDefaultValue(p)));
            }
            // VALIDAZIONI TOTALE PERCENTUALE UGUALE A 100 PER I PROFILI
            if (!!profileConfig.percentageSumEqual100) {
              (fg.get(profileControlName) as UntypedFormGroup).setValidators([percentageSumEqual100Validator]);
            }
          } else {
            // se il valore delle definitions è diverso da quello del form -> lo riporto sul form
            const definitionValue = this.getElementDefaultValue(p);
            if (fg.get(profileControlName).get(p.id).value !== definitionValue) {
              fg.get(profileControlName).get(p.id).setValue(definitionValue, { emitEvent: false });
            }
          }
        }
        // -------------------- PROFILI --------------------
        // -------------------- FONDI --------------------
        if (!!fundControlName) {
          const fundsVectorLength = !!p.funds ? p.funds.length : 0;
          if (fundsVectorLength > 0) {
            // se non è presente aggiungo il FG del profilo nel form dei fondi (i fondi di questo profilo saranno raggruppati qua)
            if (!fg.get(fundControlName).get(p.id)) {
              (fg.get(fundControlName) as UntypedFormGroup).addControl(p.id, new UntypedFormGroup({}));
            }

            p.funds.forEach(f => {
              // se non è presente aggiungo il FC del fondo all'interno del FG del profilo a cui appartiene
              if (!fg.get(fundControlName).get(p.id).get(f.id)) {
                // un solo fondo + prevalIfLengthEqual1 a true -> setto 100%
                if (fundConfig.prevalIfLengthEqual1 && fundsVectorLength === 1) {
                  (fg.get(fundControlName).get(p.id) as UntypedFormGroup).addControl(f.id, new UntypedFormControl(1));
                } else { // creo il controller con il valore dalle definitions
                  (fg.get(fundControlName).get(p.id) as UntypedFormGroup).addControl(f.id, new UntypedFormControl(this.getElementDefaultValue(f)));
                }
              } else {
                this.setDefaultFundValue(f, fg.get(fundControlName).get(p.id).get(f.id), fg.get(profileControlName).get(p.id).value);
              }
            });

            // VALIDAZIONI TOTALE PERCENTUALE UGUALE A 100 PER I FONDI (VALIDATORE SUL FG DEL PROFILO) - solo se la perc del profilo è > 0
            const profilePercentage: boolean = (fg.get(profileControlName).get(p.id) as UntypedFormGroup).value > 0;
            if (!!fundConfig.percentageSumEqual100 && profilePercentage) {
              (fg.get(fundControlName).get(p.id) as UntypedFormGroup).setValidators([percentageSumEqual100Validator]);
            } else {
              (fg.get(fundControlName).get(p.id) as UntypedFormGroup).setValidators([]);
            }
          }
        }
        // -------------------- FONDI --------------------
      });
    }
  }

  /**
   * @description aggiorna le validazioni del formGroup dei profili e dei fondi
   * - utilizza le config per capire se ci sono dei casi particolari da gestire (percentageSumEqual100)
   * - lancia le validazioni al termine del metodo
   */
  public static updateValidatorsOnProfilesAndFunds(
    profiles: KarmaProfileDefinition[], fg: UntypedFormGroup, profileConfig: InvestmentAdapterConfig, fundConfig: InvestmentAdapterConfig
  ) {
    const profileControlName = profileConfig.profileFormGroupName;
    const fundControlName = fundConfig.fundFormGroupName;

    if (!!profiles && profiles.length > 0) {
      profiles.forEach(p => {
        if (!!profileControlName && !!fg.get(profileControlName) && !!fg.get(profileControlName).get(p.id)) {
          // VALIDAZIONI TOTALE PERCENTUALE UGUALE A 100 PER I PROFILI
          const profileValidators = !!profileConfig.percentageSumEqual100 ? [percentageSumEqual100Validator] : [];
          (fg.get(profileControlName) as UntypedFormGroup).setValidators(profileValidators);

          if (!!fundControlName) {
            // VALIDAZIONI TOTALE PERCENTUALE UGUALE A 100 PER I FONDI (VALIDATORE SUL FG DEL PROFILO) - solo se la perc del profilo è > 0
            const profilePercentage: boolean = +(fg.get(profileControlName).get(p.id) as UntypedFormGroup).value > 0;
            const fundValidators = !!profilePercentage && !!fundConfig.percentageSumEqual100 ? [percentageSumEqual100Validator] : [];
            (fg.get(fundControlName).get(p.id) as UntypedFormGroup).setValidators(fundValidators);
            if (!profilePercentage) {
              // AZZERO TUTTI I CONTROL SOTTOSTANTI SE IL PROFILO NON HA LA PERCENTUALE
              Object.keys((fg.get(fundControlName).get(p.id) as UntypedFormGroup).controls).forEach(fKey => {
                const val = (fg.get(fundControlName).get(p.id).get(fKey) as UntypedFormGroup).value;
                if (!!val || val === 0) { // anche gli zeri diventano null
                  (fg.get(fundControlName).get(p.id).get(fKey) as UntypedFormGroup).setValue(null, { emitEvent: false });
                }
              });
            }
          }
        }
      });
    }

    // AGGIORNO LA VALIDAZIONE
    if (!!profileControlName) {
      fg.get(profileControlName).updateValueAndValidity();
      Object.keys((fg.get(profileControlName) as UntypedFormGroup).controls).forEach(profileId => {
        (fg.get(profileControlName).get(profileId) as UntypedFormControl).updateValueAndValidity();
      });
    }
    if (!!fundControlName) {
      fg.get(fundControlName).updateValueAndValidity();
      Object.keys((fg.get(fundControlName) as UntypedFormGroup).controls).forEach(profileId => {
        (fg.get(fundControlName).get(profileId) as UntypedFormGroup).updateValueAndValidity();
      });
    }
  }

  /**
   * @description aggiorna le definitions con i valori del form
   * - se non ho un valore sul form allora lascio quello delle definitions
   * - se il valore sul form esiste => sovrascrivo la definition
   * - se il valore sul form non esiste => lascio il valore di definitions (che potrebbe inizializzare il form)
   */
  public static updateProfileAndFundDefinition(
    profiles: KarmaProfileDefinition[], fg: UntypedFormGroup, profileControlName: string, fundControlName: string
  ) {
    if (!!profiles) {
      const profilesPercentageFromForm = fg.getRawValue() ? fg.getRawValue()[profileControlName] : null;
      const fundsPercentageFromForm = fg.getRawValue() ? fg.getRawValue()[fundControlName] : null;
      profiles.forEach(p => {
        if (!!profilesPercentageFromForm) {
          p.percent = profilesPercentageFromForm[p.id];
          p.percentage = profilesPercentageFromForm[p.id];
        }
        p.funds.forEach(f => {
          if (!!fundsPercentageFromForm && !!fundsPercentageFromForm[p.id]) {
            f.percent = fundsPercentageFromForm[p.id][f.id];
            f.percentage = fundsPercentageFromForm[p.id][f.id];
          }
        });
      });
    }
  }

  public static resetProfileAndFundForm(
    profiles: KarmaProfileDefinition[], fg: UntypedFormGroup, profileControlName: string, fundControlName: string
  ) {
    fg.get(profileControlName).setValidators(null);
    Object.keys((fg.get(profileControlName) as UntypedFormGroup).controls).forEach(profileId => {
      const definitionProfile = profiles.find(p => p.id === profileId);
      const val = !!definitionProfile ? this.getElementDefaultValue(definitionProfile) : null;
      fg.get(profileControlName).get(profileId).setValue(val, { emitEvent: false });
      fg.get(profileControlName).get(profileId).setValidators(null);
    });
    Object.keys((fg.get(fundControlName) as UntypedFormGroup).controls).forEach(profileId => {
      const fgProfile: UntypedFormGroup = fg.get(fundControlName).get(profileId) as UntypedFormGroup;
      fgProfile.setValidators(null);
      Object.keys(fgProfile.controls).forEach(fundId => {
        const definitionProfile: KarmaProfileDefinition = profiles.find(p => p.id === profileId);
        let definitionFund = null;
        if (!!definitionProfile && definitionProfile.funds && definitionProfile.funds.length > 0) {
          definitionFund = definitionProfile.funds.find(f => f.id === fundId);
        }
        const val = !!definitionFund ? this.getElementDefaultValue(definitionFund) : null;
        fgProfile.get(fundId).setValue(val, { emitEvent: false });
        fgProfile.get(fundId).setValidators(null);
      });
    });
  }

  // recupero il valore dell'elemento (profilo o fondo)
  // se ho il min === max restituisco quello altrimenti guardo in percent o percentage
  public static getElementDefaultValue(element: KarmaProfileDefinition | KarmaFundDefinition) {
    const blockedValue = element.minPercentAllocation === element.maxPercentAllocation ? element.minPercentAllocation : null;
    const value = !!blockedValue ? blockedValue : element.percent ? element.percent : element.percentage ? element.percentage : null;
    return value;
  }

  // setto il valore del fondo in base al profilo padre e abilito o disabilito il control a seconda della modificabilità
  public static setDefaultFundValue(element, fControl, profileValue) {
    if (profileValue > 0) {
      const blockedValue = element.minPercentAllocation === element.maxPercentAllocation ? element.minPercentAllocation : null;
      const value = !!blockedValue ? blockedValue : element.percent ? element.percent : element.percentage ? element.percentage : null;

      // setto il valore nuovo
      if (fControl.value !== value) {
        fControl.setValue(value, { emitEvent: false });
      }

      // aggiorno la disabilitazione
      if (!!blockedValue) {
        fControl.disable({ emitEvent: false });
      } else if (fControl.disabled) {
        fControl.enable({ emitEvent: false });
      }
    } else {
      // setto null se ha un valore diverso
      if (fControl.value !== null) {
        fControl.setValue(null, { emitEvent: false });
      }

      // abilito il control azzerato
      if (fControl.disabled) {
        fControl.enable({ emitEvent: false });
      }
    }
  }

  public static getBackendStructureOfProfiles(
    definitions: KarmaProfileDefinition[], profileData: any, fundData: any, multiplyPercentageX100: boolean = false
  ) {
    if (!profileData) {
      return [];
    }
    const selectedProfileIds: string[] = Object.keys(profileData).filter(id => !!profileData[id]);
    return definitions.filter(profile => selectedProfileIds.includes(profile.id))
      .map(profile => {
        const selectedFundIds: string[] = fundData[profile.id] ?
          Object.keys(fundData[profile.id]).filter(fundId => !!fundData[profile.id][fundId]) : [];
        const funds: any[] = profile.funds.filter(fund => {
          const fundPercentage = multiplyPercentageX100 ? (fundData[profile.id][fund.id] * 100) : (fundData[profile.id][fund.id]);
          return selectedFundIds.includes(fund.id) && (!!fundPercentage && fundPercentage > 0);
        }).map(fund => {
            const fundPercentage = multiplyPercentageX100 ? (fundData[profile.id][fund.id] * 100) : (fundData[profile.id][fund.id]);
            return {
              id: fund.id,
              percentage: Number(fundPercentage).toFixed(6),
              amount: null,
              fundTypeId: fund.fundTypeId,
              investmentType: null
            };
        });
        const profilePercentage = multiplyPercentageX100 ? (profileData[profile.id] * 100) : (profileData[profile.id]);
        return {
          id: profile.id,
          percentage: !!profilePercentage ? Number(profilePercentage).toFixed(6) : null,
          amount: null,
          funds
        };
      });
  }

  // necessario dividere per 100 i massimi e i minimi
  // contrariamente alle altre operazioni sono in scala 0-100 e non 0-1
  public static convertInvestmentStructure(profiles: KarmaProfileDefinition[]): KarmaProfileDefinition[] {
    const vector = [];
    profiles.forEach(p => {
      p.maxPercentAllocation = p.maxPercentAllocation / 100;
      p.minPercentAllocation = p.minPercentAllocation / 100;
      if (p.funds && p.funds.length > 0) {
        p.funds.map(f => {
          f.maxPercentAllocation = f.maxPercentAllocation / 100;
          f.minPercentAllocation = f.minPercentAllocation / 100;
        });
      }
      vector.push(p);
    });
    return vector;
  }

  /**
   * Updates KarmaProfileDefinitions based on allocation data.
   * 
   * This function updates the given KarmaProfileDefinitions with the percentages
   * provided in the allocation data. This is a workaround to "fake" the definition
   * which are the ones that will actually initialize the form and the values.
   * 
   * @param {Object[]} allocation - An array of allocation objects containing the updated percentages.
   * @param {Object[]} definitions - An array of KarmaProfileDefinition objects to be updated.
   * @returns {Object[]} - The updated KarmaProfileDefinitions.
   */
  public static updateProfileAndFundDefinitionsBasedOnOpData(allocation: any[], definitions: KarmaProfileDefinition[]): KarmaProfileDefinition[] {
    // Reset the percentages for profiles and funds to null
    const updatedDefinitions = this.resetProfilesAndFundsPercentages(definitions);

    allocation.forEach(allocationItem => {
        const profile = updatedDefinitions.find(p => p.id === allocationItem.id);
        if (profile) {
            // Update the profile percentage
            profile.percent = allocationItem.percentage.toString();
            allocationItem.funds.forEach(allocationFund => {
                const profileFund = profile.funds.find(f => f.id === allocationFund.id);
                if (profileFund) {
                    // Update the fund percentage
                    profileFund.percent = allocationFund.percentage.toString();
                }
            });
        }
    });

    return updatedDefinitions;
  }

  /**
  * Resets the percentages of profiles and their funds to null.
  * 
  * @param {Object[]} profiles - An array of profile objects to reset.
  * @returns {Object[]} - The profiles with reset percentages.
  */
  public static resetProfilesAndFundsPercentages(profiles: any[]): any[] {
    profiles.forEach(profile => {
        profile.percent = null;
        profile.funds.forEach(fund => {
            fund.percent = null;
        });
    });
    return profiles;
  }


}
