
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CaiOpeningStateService } from './state-service/cai-opening.state.manager';
import {  CaiOpeningState, OutputDataFormSubjectHolder } from './config-model/cai-opening-config.module';
import { QuestionnaireDetail } from './config-model/cai-opening-questionnaire.model';
import { CaiOpeningPartyUtility } from './state-service/utility/cai-opening-party.utility';
import { ActiveRoute } from '@rgi/rx/router';
import { OutputShowDetail } from '../shared/dsc-similar-claims/dsc-similar-claims.config';
import { IncidentData } from '../shared/dsc-incident-data/dsc-incident-data.model';
import { RgiRxStepperComponent } from '@rgi/rx/ui';
import { Subscription } from 'rxjs';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { AlertService } from '@rgi/digital-claims-common-angular';
import { AdditionalData } from '../shared/dsc-additional-data/dsc-additional-data.model';
import { CircumstanceIncident } from '../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import { InsurancePolicy, PolicyData } from '../shared/dsc-shared-model/dsc-model-policy';
import { CodiceDescrizione } from '../shared/dsc-shared-model/dsc-model-utility';
import { VehicleData } from '../shared/dsc-shared-model/dsc-model-vehicle';
import { CarBodywork } from '../shared/dsc-shared-model/dsc-model-bodywork';
import { AddressEntityData } from '../shared/dsc-shared-model/dsc-model-address';
import { PolicyService } from '@rgi/digital-claims-common-angular';
import { Heir, SubjectReduced } from '../shared/dsc-shared-model/dsc-model-subject';

@Component({
    selector: 'claims-cai-opening',
    templateUrl: './cai-opening.component.html',
    styleUrls: ['./cai-opening.component.scss']
})

export class CaiOpeningComponent implements OnInit, AfterViewInit {

    @ViewChild('RxStepper') RxStepper: RgiRxStepperComponent;

    public vertical = false;
    public showIndex = true;
    linear = true;
    policySelected: InsurancePolicy;
    viewSimilarClaims: boolean = false;
    viewListPolicy: boolean = true;
    selectedPolicy: InsurancePolicy;
    subscription: Subscription = new Subscription();
    activeItem = 0;
    showSingleCai: boolean = false;
    messageWarningCompulsoryQuest: string = '';
    isQuestionarieUpdated: boolean = false;
    isStartedQuestionaire: boolean = false;

    constructor(
        @Inject('claimsFinderIntegrationPassService') private claimsFinderIntegrationPassService: any,
        @Inject('eventService') private eventService: any,
        public formBuilder: UntypedFormBuilder,
        public caiOpeningState: CaiOpeningStateService,
        public caiPartyUtity: CaiOpeningPartyUtility,
        public activeRoute: ActiveRoute,
        private rxTranslationsService: RgiRxTranslationService,
        private alertService: AlertService,
		public policyService: PolicyService,
    ) {
        this.caiOpeningState.getState$().subscribe((state: CaiOpeningState) => {
            if(this.RxStepper) {
                this.RxStepper.selectedIndex = state.stepper.selectedIndex;
                this.activeItem = state.stepper.activeItem;
                /* if(state.reopenFromJs?.stepToShow == 'questionaire') {
                    if(state.stepper?.selectedIndex === 0 && !this.isStartedQuestionaire){
                        this.isStartedQuestionaire = true;
                        this.initQuestionnaire();
                    } else if( state.stepper?.selectedIndex === 1 && !this.isQuestionarieUpdated){
                        this.isQuestionarieUpdated = true;
                        this.caiOpeningState.updateQuestionnaireState(state.questionnaire);
                    }
                } */
                if((state.stepper.selectedIndex === 1 || state.stepper.selectedIndex === 2)
                    && state.generatedClaim.claim === null) {
                    this.caiOpeningState.generateClaim(state.selectedPolicy);
                }
            }
        })
     }

    ngAfterViewInit() {
        /* this.subscription.add(
            this.caiOpeningState.getState$().subscribe((state: CaiOpeningState) => {
                if (state) {
                    if (state.reopenFromJs && state.reopenFromJs !== null && state.reopenFromJs.isReopenedFromJs) {
                        this.reopenFromJs(this.caiOpeningState.getCurrentState());
                        this.caiOpeningState.getAllCovertureBranches(state.reopenFromJs.returnClaim.policyNumber);
                        this.showSingleCai = true;
                    }
                }
            })
        ); */
        const state = this.caiOpeningState.getCurrentState();
        if (state) {
            if(state.reopenFromJs && state.reopenFromJs !== null && state.reopenFromJs.isReopenedFromJs){
                this.reopenFromJs(this.caiOpeningState.getCurrentState());
                this.caiOpeningState.getAllCovertureBranches(state.reopenFromJs.returnClaim.policyNumber);
                this.showSingleCai = true;
            }
        }
    }

    ngOnInit() {
        /* this.caiOpeningState.getState$().subscribe(st => {
            console.log(st);
        }); */
       // this.caiOpeningState.loadClaims();
    }

    reopenFromJs(currState: CaiOpeningState) {
        if (currState.reopenFromJs.isReopenedFromJs && currState.generatedClaim && currState.generatedClaim.claim && currState.reopenFromJs.stepToShow != 'questionaire') {
            this.caiPartyUtity.setClaimDetail(currState, currState.generatedClaim.claim).then(resolve => {
                this.caiOpeningState.updateClaimGenerated(resolve);
                // vado al nuovo modulo cai
                this.caiPartyUtity.setClaimFromState(currState.generatedClaim.claim, true).then(resolveSingle => {
                    this.caiOpeningState.setStateClaim(resolveSingle);
                });
            });
        }
    }


    onSelectionChange(event: any) {
        // console.log(event);
    }

    searchSimilarClaims(event: InsurancePolicy) {
        this.caiOpeningState.verifyAuthorizedForClaim(this.caiOpeningState.getCurrentState(), event).then(resolve => {
            if(resolve) {
                const st = this.caiOpeningState.getCurrentState();
                if(!st.additionalInfoCoverture.covertures.technicalCoverture &&
                    st.listFunz !== null && st.listFunz.indexOf('SXIPNV') < 0) {
                    this.viewSimilarClaims = false;
                    this.rxTranslationsService.translate('_CLAIMS_._ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY').subscribe(res => {
                      this.alertService.message(res);
                    });
                  } else if (!st.additionalInfoCoverture.covertures.administrativeCoverture &&
                    !this.caiOpeningState.getFunctionService('ADMINISTRATIVE_FORCING_COMPLAINT')
                  ) {
                    this.viewSimilarClaims = false;
                    this.rxTranslationsService.translate('_CLAIMS_._CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY').subscribe(res => {
                        this.alertService.message(res);
                      });
                  } else {
                    this.goToAllSimilarClaims(event);
                  }
            } else {
                this.viewSimilarClaims = false;
                this.rxTranslationsService.translate('_CLAIMS_._SEARCH_ERROR').subscribe(res => {
                    this.alertService.message(res);
                    });
            }
        })
    }

    goToAllSimilarClaims(event: InsurancePolicy) {
        const state = this.caiOpeningState.getCurrentState();
        this.policySelected = event;
        this.caiOpeningState.getAllSimilarClaims(this.policySelected);
        this.viewListPolicy = false;
        this.viewSimilarClaims = true;
    }
    showDetailClaim(event: OutputShowDetail) {
        this.goToDetailFromSimilarClaims(event);
    }

    returnToPolicy() {
        this.caiOpeningState.resetSimilarClaims();
        this.policySelected = new InsurancePolicy();
        this.viewListPolicy = true;
        this.viewSimilarClaims = false;
    }

    backToPolicyList() {
        this.viewSimilarClaims = false;
        this.viewListPolicy  = true;
    }

    backToSimilarClaims() {
        this.viewSimilarClaims = true;
        this.viewListPolicy  = false;

        this.RxStepper.previous();
        this.caiOpeningState.clearValues();
    }

    initQuestionnaire() {
      const nextStep = this.caiOpeningState.generateClaim(this.policySelected);

      if (nextStep){
        this.RxStepper.next();
        this.activeItem += 1;
      }
    }
    updatePolicyHolder(event: OutputDataFormSubjectHolder) {
        this.caiOpeningState.updatePolicyHolder(event);
    }
    outputHolderHeirData(event: Heir) {
        this.caiOpeningState.outputHolderHeir(event);
    }
    outputOwnerHeirData(event: Heir) {
        this.caiOpeningState.outputOwnerHeir(event);
    }
    outputDriverHeirData(event: Heir) {
        this.caiOpeningState.outputDriverHeir(event);
    }
    outputPartyRole(event: CodiceDescrizione) {
        this.caiOpeningState.updatePartyRole(event);
    }
    updateVehicle(event: VehicleData) {
        this.caiOpeningState.updateVehicle(event);
    }
    updatePolicyData(event: PolicyData) {
        this.caiOpeningState.updatePolicyData(event);
    }
    updateOwner(event: OutputDataFormSubjectHolder) {
        this.caiOpeningState.updateOwner(event);
    }
    updateDriver(event: OutputDataFormSubjectHolder) {
        this.caiOpeningState.updateDriver(event);
    }
    updateDamagedParts(event: VehicleData) {
        this.caiOpeningState.updateDamagedParts(event);
    }
    updateCarBodywork(event: CarBodywork) {
        this.caiOpeningState.updateCarBodywork(event);
    }
    updateIncidentData(event: IncidentData) {
        this.caiOpeningState.updateIncidentData(event);
    }
    updateAddress(event: AddressEntityData) {
        this.caiOpeningState.updateAddress(event);
    }
    updateAdditionalData(event: AdditionalData) {
        this.caiOpeningState.updateAdditionalData(event);
    }
    updateCircumstance(event: CircumstanceIncident) {
        this.caiOpeningState.updateCircumstance(event);
    }
    updateAddressDataDamagedParts(event: AddressEntityData) {
        this.caiOpeningState.updateAddressDataDamagedParts(event);
    }
    changeQuestionnaireStatus(questionnaire: QuestionnaireDetail) {
        this.eventService.broadcastEvent('start-loader');
        this.caiOpeningState.updateQuestionnaireState(questionnaire);
    }

    goToCaiModule() {
        const st = this.caiOpeningState.getCurrentState();
        this.caiOpeningState.nextStepToCaiModule(st).then(response => {
            if(response) {
                this.RxStepper.next();
                this.showSingleCai = true;
                this.activeItem += 1;
            }
        });
    }



    goToDetailFromSimilarClaims(event: OutputShowDetail) {
        this.caiOpeningState.goToDetailFromSimilarClaims(event);
    }

    confirmModule(isDraft: boolean) {
        // const st = this.caiOpeningState.getCurrentState();
        // const holderHeirs =  st.singleCai.heirsList.policyHolder.potentialHeirs.filter(el => el.checked);
        // const ownerHeirs =  st.singleCai.heirsList.owner.potentialHeirs.filter(el => el.checked);
        // const driverHeirs =  st.singleCai.heirsList.driver.potentialHeirs.filter(el => el.checked);


        // if (this.heirsAreValid(
        //     st.singleCai.policyHolder,
        //     st.singleCai.owner,
        //     st.singleCai.driver,
        //     holderHeirs,
        //     ownerHeirs,
        //     driverHeirs
        // )
        // ) {
            this.caiOpeningState.saveDraftClaims(isDraft);
        // }
    }

    heirsAreValid(
        policyHolder: SubjectReduced,
        owner: SubjectReduced,
        driver: SubjectReduced,
        holderHeirs: Heir[],
        ownerHeirs: Heir[],
        driverHeirs: Heir[]): boolean {

            const holderHasFatalDate = !!policyHolder.subjectDataFormInjury.fatalDate;  // se la data � presente  restituisce true
            const ownerHasFatalDate = !!owner.subjectDataFormInjury.fatalDate;  // se la data � presente  restituisce true
            const driverHasFatalDate = !!driver.subjectDataFormInjury.fatalDate;  // se la data � presente  restituisce true

        const totalHeir = this.getTotalHeir(
            holderHeirs,
            ownerHeirs,
            driverHeirs
        );
        // controllo che nessuno tra i soggetti scelti come erede abbia la data decesso
        if (holderHasFatalDate && totalHeir.includes(policyHolder.objectId)) {
            this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID').subscribe(
                res => {
                    this.alertService.translatedMessage(res);
                })
            return false;
        }
        if (ownerHasFatalDate && totalHeir.includes(owner.objectId)) {
            this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID').subscribe(
                res => {
                    this.alertService.translatedMessage(res);
                })
            return false;
        }
        if (driverHasFatalDate && totalHeir.includes(driver.objectId)) {
            this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._HEIR_INVALID').subscribe(
                res => {
                    this.alertService.translatedMessage(res);
                })
            return false;
        }
        return true;
    }

    getTotalHeir(heirs1: Heir[], heirs2: Heir[], heirs3: Heir[]): string[] {
        const mergedHeirs = [...new Set([...heirs1, ...heirs2, ...heirs3])];
        return mergedHeirs.map((heir: Heir)=> heir.linkedPartyObjectId);
    }

    decrementStepper() {
        this.caiOpeningState.decrementStepper();
    }
}
