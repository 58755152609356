import { RgiRxTranslationService } from "@rgi/rx/i18n";
import { Observable, throwError } from "rxjs";
import { CommonErrorResponse, CommonMessage } from "../model/models";



export class MessageUtil {

  isMetaInfoMsg: boolean = false;

  constructor(
    protected translate: RgiRxTranslationService
  ) {
  }

  public setMetaInfoMsg(value: boolean) {
    this.isMetaInfoMsg=value;
  }

  public isErrorResponse(response :any) {

    return response && (response as any).error; 

  }
  public handleCommonOperationResponse(response: any, logInfo = '', isThrowIfEmpty = true, isThrowIfError = false, detail=false): string {
    if (!!response && !this.isErrorResponse(response)) {
      return null;
    }
    const msgErr = this.getCommonErrorResponseAsSimpleString(response,detail);
    if (!!msgErr) {
        console.log('ErrorInfo:' + logInfo + msgErr);
        if (!!response) {
          console.log('ErrorResponse:', response);
        }
      }
    if (!response && isThrowIfEmpty || this.isErrorResponse(response) && isThrowIfError) {
      throwError('ErrorInfo:' + logInfo + msgErr);
    }

    return msgErr;

  }

  public handleCommonErrorResponse(errorResponse :CommonErrorResponse, isThrowError = true) {
    const msgErr = this.getCommonErrorResponseAsSimpleString(errorResponse);
    if (isThrowError) {
      throwError(msgErr);
    } else {
      console.log(msgErr);
    }
  }
  public getCommonErrorResponseAsSimpleString(errorResponse: CommonErrorResponse, detail = true) {

    let result = 'Unknow or null error response';
    if (!!errorResponse && errorResponse.error) {
      result = this.getMessageAsSimpleString(errorResponse.error);
      if (detail && errorResponse.detail) {
        result += this.getMessagesAsSimpleString(errorResponse.detail.messages);

      }
    }

    return result;

  }

  public pushTraslatedMessages(idMsg: string, res: String[]) {

    this.translate.translate(idMsg).subscribe((translated) => {
      res.push(translated);
    }),
    err => {
        console.log(err);
        res.push(idMsg);
    };

  }

  public pushTraslationMap(idMsg: string, traslationMap: Map<string,string>) {
    this.translate.translate(idMsg).subscribe((translated) => {
      traslationMap.set(idMsg,translated)
    }),
    err => {
        console.log(err);
        traslationMap.set(idMsg,idMsg);
    };

  }

  public handleError(error: any, idMsgErrDefault?: string): Observable<any> {

    let msgErr = '';
    if (!!error && error.error) {
      const commonError = error as CommonErrorResponse;
      if (commonError.error.code) {
         msgErr += commonError.error.code

      }
    }

    if (!!idMsgErrDefault) {
      let msg = [''];
      this.translate.translate(idMsgErrDefault).subscribe((translated) => {
        msg = [translated];
        return throwError(msg + msgErr || error);
      });
    } else {
      return throwError(msgErr || error);
    }

  }

  public hasError(messages: CommonMessage[]): boolean {
    return this.hasType(messages, CommonMessage.TypeMessageEnum.Error);
  }

  public getErrors(messages: CommonMessage[]): CommonMessage[] {
    return this.getByType(messages, CommonMessage.TypeMessageEnum.Error);
  }

  public getErrorsAsSimpleStrings(messages: CommonMessage[]): string[] {
    return this.getByTypeAsSimpleStrings(messages, CommonMessage.TypeMessageEnum.Error);
  }

  public isError(message: CommonMessage): boolean {
    return this.isType(message, CommonMessage.TypeMessageEnum.Error);
  }

  public hasWarning(messages: CommonMessage[]): boolean {
    return this.hasType(messages, CommonMessage.TypeMessageEnum.Warning);
  }

  public getWarnings(messages: CommonMessage[]): CommonMessage[] {
    return this.getByType(messages, CommonMessage.TypeMessageEnum.Warning);
  }
  public getWarningsAsSimpleStrings(messages: CommonMessage[]): string[] {
    return this.getByTypeAsSimpleStrings(messages, CommonMessage.TypeMessageEnum.Warning);
  }

  public isWarning(message: CommonMessage): boolean {
    return this.isType(message, CommonMessage.TypeMessageEnum.Warning);
  }

  public hasInfo(messages: CommonMessage[]): boolean {
    return this.hasType(messages, CommonMessage.TypeMessageEnum.Info);
  }

  public getInfos(messages: CommonMessage[]): CommonMessage[] {
    return this.getByType(messages, CommonMessage.TypeMessageEnum.Info);
  }

  public getInfosAsSimpleStrings(messages: CommonMessage[]): string[] {
    return this.getByTypeAsSimpleStrings(messages, CommonMessage.TypeMessageEnum.Info);
  }

  public isInfo(message: CommonMessage): boolean {
    return this.isType(message, CommonMessage.TypeMessageEnum.Info);
  }

  private getByType(messages: CommonMessage[], type: CommonMessage.TypeMessageEnum): CommonMessage[] {
    const result: CommonMessage[] = [];
    for (const m of messages) {
      if (this.isType(m, type)) {
        result.push(m);
      }
    }
    return result;

  }

  private getByTypeAsSimpleStrings(messages: CommonMessage[], type: CommonMessage.TypeMessageEnum): string[] {

    return this.getMessagesAsSimpleStrings(this.getByType(messages, type));
  }

  private getMessagesAsSimpleString(messages: CommonMessage[]): string {

    let result = '';
    const messagesString: string[] = this.getMessagesAsSimpleStrings(messages);
    for (const m of messagesString) {
      result += m;
    }
    return result;
  }

  private getMessagesAsSimpleStrings(messages: CommonMessage[]): string[] {

    const result: string[] = [];
    for (const m of messages) {
      result.push(this.getMessageAsSimpleString(m));
    }
    return result;
  }

  private getMessageAsSimpleString(message: CommonMessage): string {

    if (!!message) {
      return this.isMetaInfoMsg ? message.typeMessage + ':' + message.code + ':' + message.message : message.message;
    }
  }

  private hasType(messages: CommonMessage[], type: CommonMessage.TypeMessageEnum): boolean {
    return !!this.getByType(messages, type) && !!this.getByType(messages, type).length;
  }

  private isType(message: CommonMessage, type: CommonMessage.TypeMessageEnum): boolean {
    if (!!message && type === message.typeMessage) {
      return true;
    }

    return false;
  }

  public getCommonResponse(message: string, type: CommonMessage.TypeMessageEnum = CommonMessage.TypeMessageEnum.Error, code?: string): CommonErrorResponse {
    return {
      error: this.getCommonMessage(message, code, type)
    }
   
  }

   public getCommonMessage(message: string, code?: string, typeMessage?: CommonMessage.TypeMessageEnum): CommonMessage {
    
    return { 
      code,
      typeMessage,
      message
   }
   
  }


}
