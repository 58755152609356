import { ClaimsTranslationsKeys } from './claims-translations-keys';
/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */
// tslint:disable:max-line-length
export const CLAIMS_DE: ClaimsTranslationsKeys = {
    _CLAIMS_TITLE_CARD_CAI_: 'New Claim',
    _CLAIMS_TITLE_DIALOGUE_DASHBOARD_: 'Dialogue dashboard',
    _CLAIMS_TITLE_TIREA_DASHBOARD_: 'Tirea Payment Board',
    _CLAIMS_TITLE_DIALOGUE_HANDLER_LIST: 'Dialogue List',
    _CLAIMS_TITLE_PAYMENT_TABLE_TYREA: 'Payments table Tirea',
    _INVOICE: 'Invoice',
    _NEW_PAYMENT: 'New payment',
    _COMPENSATION_LIST: 'Compensation list',
    _CLAIMS_CONNECTOR: 'Claims Connector',
    _CHAT: 'CHAT',
    _EDIT_CLAIMS: 'Edit Claims',
    _ACTIVITY: 'Activity',
    _CLAIMS_PILLS: 'Claims Pills',
    _AUTOSOFT_DASHBOARD: 'Autosoft Dashboard',
    _DOSSIER_MANAGEMENT: 'Dokumentenverwaltung',
    _CLAIM_DETAIL: 'Claim detail',
    _NEW_FNOL: 'New FNOL',
    _RCA_QUESTIONNAIRE: 'KFZ-Haftpflicht-Fragebogen',
    _CLAIM_SUBJECTS: 'Claim subjects',
    _CLAIMS_FROM_ELASTIC: 'Claims from elastic',
    _EXPERTS: 'Experts',
    _LETTER_TEMPLATES: 'Letter templates',
    _LIMITS_MANAGEMENT: 'Limits Management',
    _TIMELINE: 'Timeline',
    _INVOICES_LIST: 'Invoices list',
    _UPDATE_RESERVE: 'Update reserve',
    _CLAIMS_: {
        _TAX_REGIME: 'Tax Regime',
        _IVA_REGIME: 'VAT regime',
        _AGGREGATE_TAX_REGIME: 'Aggregate TAX regime',
        _AGGREGATE_IVA_REGIME: 'Aggregate VAT regime',
        _FISCAL_DATA: 'Fiscal Data',
        _ACCOUNTING_DATE: 'Accounting date',
        _ACT_ACTIVE: 'Act active',
        _ACT_CLOSED: 'Act closed',
        _ACT_EVASION_DATE: 'Act evasion date',
        _ACTION: 'Action',
        _ACTIVE_CHATS: 'Active chats',
        _ACTUAL_DATE_OF_OCCURRENCE: 'Actual date of occurrence',
        _ACTUAL_TIME_OCCURRENCE: 'Actual time occurrence',
        _ADD_ASSET: 'Add asset',
        _ADDRESS: 'Address',
        _ADMIN_LEVEL_1: 'Admin level 1',
        _ADMIN_LEVEL_2: 'Admin level 2',
        _ADMIN_LEVEL_3: 'Admin level 3',
        _ADMINISTRATIVE_REGULARITY: 'Administrative Regularity',
        _AGENCY_UNIQUE_KEY: 'Agency Unique Key',
        _AGENCY: 'Agency',
        _ALLEGATION_DESCRIPTION: 'Allegation Description',
        _AMOUNT_CLEARED: 'Amount Cleared',
        _AMOUNT_HOLD_TAX: 'Amount hold tax',
        _AMOUNT_TO_BE_PAYD: 'Amount to be payd',
        _ASSET: 'Asset',
        _ASSETS_DATA: 'Assets data',
        _ASSET_DESCRIPTION: 'Asset Description',
        _ASSIGNED_TO_A_GROUP: 'Assigned to a Group',
        _ASSIGNMENT_SELECTION: 'Assignment selection',
        _BODY: 'Body',
        _CANCELED_BY: 'Canceled by',
        _CANCEL_REQUEST_DATE: 'Cancel request date',
        _CAP: 'CAP',
        _CASH_PREVIDENTIAL_AMOUNT: 'Cash previdential amount',
        _CERTIFIED_OCCURRENCE_DATE: 'Certified occurrence date',
        _CERTIFIED_EVENT_DATE: 'Certified event date',
        _CHASSIS_NUMBER: 'Chassis Number',
        _CDL_RECEIPT_DATE: 'CDL Receipt Date',
        _CHAT_HISTORY: 'Chat history',
        _CHATS_WITH_ME: 'Chats with me',
        _CITY: 'City',
        _CIVIC_NUMBER: 'Civic Number',
        _CLAIM_NUMBER_RECEIVED: 'Claim number received',
        _CLAIM_SELECTION: 'Claim selection',
        _CLAIMS: 'CLAIMS',
        _CLAIMS_TECHNICAL_STATUS: 'Claims technical status',
        _COMPLAINT_DATA: 'Complaint Data',
        _CLAIM_NUMBER: 'Claim Number',
        _CLAIM_POLICY: 'Claim Policy',
        _CLICK_HERE: 'click here',
        _CLOSE: 'Close',
        _COHERENT: 'Coherent',
        _COLLECTION_DATE: 'Collection date',
        _COMMENTS: 'Comments',
        _COMPULSORY_QUEST: 'Compulsory question: ',
        _CONFIRM: 'Confirm',
        _CONTRACTOR: 'Contractor',
        _COMPANY_DESCRIPTION:'Company Description',
        _COMPANY_DESCRIPTION_CTP: 'Counterpart Company Description',
        _CORPORATE_LITIGATION: 'Corporate Litigation',
        _COUNT: 'Count',
        _COUNTERPARTY_PLATE_NUMBER: 'Claim counteparty plate number',
        _OUR_REFERENCES: 'DE_Our References',
        _COUNTERPARTY_REFERENCES: 'DE_Counteparty References',
        _CREATION: 'Creation',
        _DAMAGE_CATEGORY: 'Damage category',
        _DAMAGE_POSITION: 'Damage Position',
        _DAMAGED: 'Damaged',
        _DATE_RECEIVED_INTERMEDIARY: 'Date received intermediary',
        _DAMAGE_TO_THINGS: 'Damage to Things',
        _DAMAGED__ASSET: 'Damaged / Asset',
        _DAMAGED__INVOLVED_ITEM_DATA: 'Damaged / Involved item data',
        _DAMAGED_ASSETS: 'Damaged Assets',
        _DAMAGED_PARTS: 'Damaged Parts',
        _DEADLINE_DATA: 'Deadline Data',
        _DELETED: 'Deleted',
        _DEROGATION_LEVEL: 'Derogation level',
        _DESELECT_ALL: 'Deselect all',
        _DETAILS: 'Details',
        _LAST_EXECUTION_DETAILS: 'Last execution details',
        _SAVE_NEW_REQUEST: 'Save new request',
        _DEPARTMENT: 'Department',
        _DOCUMENT_MANAGEMENT: 'Document Management',
        _DOCUMENTS_UPLOAD: 'Documents upload',
        _DRIVER: 'Driver',
        _DRIVER_MANDATORY: 'Driver mandatory',
        _DRIVER_LICENSE_TITLE: 'Driver License',
        _RELEASED_BY: 'Released by',
        _RELEASED_DATE: 'Release date',
        _EXPIRATION_DATE: 'Expiration date',
        _CATEGORY: 'Category',
        _VALIDITY_START_DATE: 'Validity start date',
        _VALIDITY_END_DATE: 'Validity end date',
        _ADD_CATEGORY: 'Add Category',
        _DUTIES_AMOUNT: 'Duties amount',
        _EDIT_LIMIT: 'Edit Limit',
        _EMAIL: 'E-mail',
        _ERROR_CODE: 'Error Code',
        _EXCLUDE_FROM_CALCULATION: 'Exclude from calculation',
        _EXCLUDED_FROM_CALCULATION: 'Excluded from calculation',
        _EXPERT: 'Expert',
        _EFFECTIVE_COMPLAINT_DATE: 'Effective complaint date',
        _EXPERT_DATA: 'Expert data',
        _EXPERT_TYPE: 'Expert type',
        _FAX: 'Fax',
        _FEES_AMOUNT: 'Fees amount',
        _FIRST_REGISTRATION_DATE: 'First registration date',
        _FISCAL_CODE__VAT_CODE: 'Fiscal code / VAT code',
        _FNOL_DATA_COLLECTION: 'FNOL data collection',
        _GENDER: 'Gender',
        _GENERAL_DATA: 'General Data',
        _GIVEN_FIRST_REGISTRATION: 'Given first registration',
        _GREEN_CARD_NUMBER: 'Green Card Number',
        _GROSS: 'Gross',
        _GROSS_EXCESS: 'Gross excess',
        _INCONSISTENT_ROW_AT_LINE_: 'Inconsistent row at line ',
        _METAINFO: 'Metainfo',
        _INDEMNITY_AMOUNT: 'Indemnity amount',
        _INITIAL_LIMIT_AMOUNT: 'Initial Limit Amount',
        _INITIAL_LIMIT_VALUE: 'Initial Limit Value',
        _INJURY: 'Injury',
        _INJURY_CODE: 'Injury code',
        _INCIDENT_DATA: 'Incident Data',
        _INJURY_PHYSICAL: 'Injury physical',
        _INPS_CONTRIB_AMOUNT: 'Inps contrib amount',
        _INSERT: 'Insert',
        _INS_DATE: 'DE_Insertion date',
        _INSERT_NEW_REQUEST: 'Insert new request',
        _INSERTION_DATE: 'Insertion date',
        _INSPECTOR: 'Inspector',
        _INSTANCE: 'Instance',
        _INSURANCE_COMPANY: 'Insurance Company',
        _INSURED_ACTIVITY: 'Insured Activity',
        _INSUREDS: 'Insureds',
        _INSURED_REPORT_DATE: 'Insured report data',
        _INVALID_FIELDS: 'Invalid fields',
        _INVOICE: 'Invoice',
        _INVOICE_AMOUNT: 'Invoice amount',
        _INVOICE_DATA: 'Invoice data',
        _INVOICE_DETAILS: 'Invoice details',
        _INVOICE_NUMBER: 'Invoice number',
        _INVOICE_TYPE: 'Invoice type',
        _INVOICES: 'Invoices',
        _LETTER_DEROGATION_LEVEL: 'Letter derogation level',
        _LETTER_TEMPLATE_SAVED: 'Letter template saved',
        _LEVEL: 'Level',
        _LEVEL_CODE: 'Level Code',
        _LIMITS_POLICY_N: 'Limits Policy n.',
        _MAIN_SUBSTATUS: 'Main substatus',
        _MANAGE_PHASE: 'Manage phase',
        _MANAGEMENT_NOTE: 'Management note',
        _MANDATORY_DOCUMENTS: 'Mandatory Documents',
        _MANUAL_EXCESS: 'Manual excess',
        _MANUFACTURER: 'Manufacturer',
        _MATERIAL_DAMAGE: 'Material Damage',
        _MERGE_ABSORPTION_DATE: 'Merge absorption date',
        _MESSAGE: 'Message',
        _MESSAGE_RESPONSE: 'Message response',
        _MESSAGE_REQUEST: 'DE_Request Message',
        _MESSAGES: 'Messages',
        _MINOR: 'Minor',
        _MINORGUARDIAN: 'MINOR GUARDIAN',
        _MINISTERIAL_BRANCH: 'Ministerial Branch',
        _MOBILE_PHONE: 'Mobile Phone',
        _MODELS_LIST: 'Models list',
        _MODIFY_FNOL: 'Modify FNOL',
        _MOTOR_CARD: 'Motor card',
        _NA: 'NA',
        _NAME: 'Name',
        _NATION_ADDRESS: 'Nation Address',
        _NATIONALITY: 'Nationality',
        _NET: 'Net',
        _NETGROSS_EXCESS: 'Net/Gross excess',
        _NEW_COMPANY: 'New company',
        _NEW_LETTER_MODEL: 'New letter model',
        _NEW_LIMIT: 'New Limit',
        _NEW_UPLOAD: 'New upload',
        _NORMALIZE: 'Normalize',
        _NOT_COHERENT: 'Not coherent',
        _NOTIFICATION_DATE: 'Notification date',
        _NOT_TAXABLE_AMOUNT: 'Not taxable amount',
        _OBJECT: 'Object',
        _OCCURRENCE_TIME: 'Occurrence Time',
        _OCCURRENCE_DESCRIPTION: 'Occurrence Description',
        _OBSERVATIONS: 'Observations',
        _OPENING_DATE_FROM: 'Opening date from',
        _OPENING_DATE_TO: 'Opening date to',
        _OTHER_DOCUMENTS: 'Other Documents',
        _OTHER: 'Other',
        _OWNER: 'Owner',
        _PARTY_INVOLVED: 'Party Involved',
        _PARKED_VEHICLE: 'Parked Vehicle',
        _PRODUCT: 'Product',
        _PASSENGER: 'Passenger',
        _PAYMENT_AMOUNT: 'Payment amount',
        _PEC: 'Pec',
        _PERC_PREV_FUND_1: 'Perc prev fund 1',
        _PERC_PREV_FUND_2: 'Perc prev fund 2',
        _PERC_WITH_HOLD_TAX: 'Perc with hold tax',
        _PERCENTAGES: 'Percentages',
        _PERSON: 'Person',
        _PHONE: 'Phone',
        _PHYSICAL_INJURY: 'Physical Injury',
        _PLATE_NUMBER: 'Plate number',
        _POLICY: 'Policy',
        _POLICY_DETAIL: 'Policy Detail',
        _POLICY_HOLDER__INSURED: 'Policy holder / Insured',
        _POLICY_LIST: 'Policy List',
        _PROFESSIONAL_SERVICES: 'DE_Professional Services',
        _PROVINCE: 'Province',
        _REAL_NAME: 'Real name',
        _RECEIVED_DATE: 'Received date',
        _RECEIVED_DATE_FROM: 'Received date from',
        _RECEIVED_DATE_TO: 'Received date to',
        _RECIPIENT: 'Recipient',
        _REGIME: 'Regime',
        _REGISTRY: 'Registry',
        _REJECT_WORKED: 'Reject worked',
        _REPORT: 'Report',
        _RESERVE: 'Reserve',
        _RESIDUE_LIMIT_AMOUNT: 'Residue Limit Amount',
        _RESIDUE_LIMIT_VALUE: 'Residue Limit Value',
        _RISK_DESCRIPTION: 'Risk description',
        _ROUNDING_AMOUNT: 'Rounding amount',
        _SAP_CODE: 'Sap code',
        _SAP_RECORDING_DATE: 'Sap recording date',
        _SDI_CODE: 'Sdi code',
        _SEARCH_DOCTOR: 'Search doctor',
        _SELECT_ALL: 'Select all',
        _SELECT_ASSIGNMENT: 'Select assignment',
        _SELECT_AT_LEAST_ONE_USER: 'Select at least one user',
        _SELECT_BY_DEFAULT: 'Select by default',
        _SELECT_CLAIM: 'Select claim',
        _SELECT_POLICY: 'Select policy',
        _SELECT_RECIPIENT: 'Select recipient',
        _SELECT_THE_CORRESPONDING_ASSIGNMENT_FROM_THE_LIST: 'Select the corresponding assignment from the list',
        _SELECT_THE_CORRESPONDING_CLAIM_FROM_THE_LIST: 'Select the corresponding claim from the list',
        _SELECT_THE_CORRESPONDING_TYPE_OF_LOSS_FROM_THE_LIST: 'Select the corresponding type of loss from the list',
        _SELECT_TYPE_OF_LOSS: 'Select type of loss',
        _SELECTECT_SUBJECT: 'Selectect subject',
        _SENDING_DATE: 'Sending date',
        _SIC_OUTCOME: 'Sic Outcome',
        _SIMILAR_COMPLAINTS: 'Similar complaints',
        _SIMILAR_SUBJECT: 'Similar subject',
        _STAMP_DUTY_AMOUNT: 'Stamp duty amount',
        _SUBJECT_NAME: 'Subject Name',
        _SUBJECT_SURNAME_OR_DENOMINATION: 'Subject surname or denomination',
        _SUBTYPOLOGY: 'Subtypology',
        _SUBTYPOLOGY_CODE: 'Subtypology Code',
        _SUIT_NUMBER: 'Suit Number',
        _SURNAME: 'Surname',
        _SX_SUBJECT: 'SX_Subject',
        _TAX_ID: 'Tax ID',
        _TECHNICAL_COVERAGE: 'Technical Coverage',
        _TAXABLE_AMOUNT: 'Taxable amount',
        _TOTAL_DISTRUCTION: 'Total Distruction',
        _THINGS: 'Things',
        _THIRD_PARTY: 'Third party',
        _TO_BE_EVALUATED: 'To be evaluated',
        _TO_BE_REEVALUATED: 'To be reevaluated',
        _TO_SELECT: 'to select',
        _TOPONYM: 'Toponym',
        _TOTAL: 'Total',
        _TOT_AMOUNT_UNIT: 'Tot amount unit',
        _TOTAL_RESERVES_AMOUNT: 'Total reserves amount',
        _TYPE_OF_LOSS_DESCRIPTION: 'Type of loss description',
        _TYPE_OF_LOSS_SELECTION: 'Type of loss selection',
        _TYPE_OF_DAMAGE: 'Type of damage',
        _TYPE_CIVIL__PENAL: 'Type Civil/Penal',
        _UNIQUE: 'Unique',
        _UPDATE_REASON: 'Update Reason',
        _USER_ASSOCIATION: 'User association',
        _USER_BY_WORKLOAD: 'User (by workload)',
        _USER_DATA: 'User Data',
        _USER_DEROGATION_LEVEL: 'User derogation level',
        _VAT_AMOUNT: 'Vat amount',
        _VAT_RATE: 'Vat rate',
        _VEHICLE: 'Vehicle',
        _VISIBLE_DAMAGE_ON_VEHICLE: 'Visible Damage on Vehicle',
        _NUMBER_CLAIM: 'Claim number',
        _OCCURRENCE_DATE: 'Occurrence date',
        _CLAIM_BRANCH: 'Claim branch',
        _CLAIM_TYPE: 'Claim type',
        _POLICY_NUMBER: 'Policy number',
        _POLICY_NR: 'Policy nr.',
        _CDL: 'CDL',
        _ADJUSTER: 'Adjuster',
        _PROCEDURE_NUMBER: 'Procedure number',
        _COUNTERPART: 'Counterpart',
        _COUNTERPARTS: 'Counterparts',
        _INSURED: 'Insured',
        _COMPLAINT_DATE: 'Complaint date',
        _COVERTURE_TYPE: 'Coverture type',
        _CLAIM_NUMBER_STATUS: 'Claim number and status',
        _COMPLAINT_NUMBER_STATUS: 'Complaint number and status',
        _OCCURRENCE_DATA: 'Occurrence Data',
        _DOCUMENTS: 'Documents',
        _CANCELLATION_DATE: 'Cancellation Date',
        _DESCRIPTION: 'Description',
        _EFFECT_DATE: 'Effect date',
        _INVALID_POLICY: 'Invalid policy',
        _POLICY_HOLDER: 'Holder',
        _UNITS: 'Unit',
        _VALID_POLICY: 'Valid Policy',
        _MANAGEMENT_NODE: 'Managemebt node',
        _CONTRACT_STATUS: 'Contract status',
        _FRACTIONING: 'Fractioning',
        _ISSUE_FRACTIONING: 'Issue fractioning',
        _POLICY_TYPE: 'Policy type',
        _ISSUE_DATE: 'Issue date',
        _COVERAGE_EXPIRATION_DATE: 'Coverage expiration date',
        _COINSURANCE_SHARE: 'Coinsurance share',
        _INITIAL_EFFECTIVE_DATE: 'Initial effective date',
        _RESCINDABILITY_DATE: 'Rescindability date',
        _COINSURANCE_TYPE: 'Coinsurance type',
        _PREMIUM_ADJUSTMENT: 'Premium adjustment',
        _INDEXING: 'Indexing',
        _LPS: 'LPS',
        _NO: 'No',
        _YES: 'Yes',
        _INDIRECT_COINSURANCE: 'Indirect coinsurance',
        _MIGRATED: 'Migrated',
        _REINSURANCE: 'Reinsurance',
        _BONDED: 'Bonded',
        _TACIT_RENEWAL: 'Tacit renewal',
        _SUBJECTS: 'Subjects',
        _ARCHIVE_SUBJECTS: 'Archive subjects',
        _LOCATIONS: 'Locations',
        _PRINCIPAL: 'Principal',
        _PROPERTY_ADDRESS: 'Property Address',
        _SECONDARY: 'Secondary',
        _ACTIVITIES: 'Activities',
        _VEHICLE_DATA: 'Vehicle data',
        _CLASS: 'Class',
        _EXTENSION: 'Extension',
        _SPECIAL_ABBREVIATION: 'Special abbreviation',
        _PLATE_TYPE: 'Plate type',
        _BRAND: 'Brand',
        _MODEL: 'Model',
        _FACTORS: 'Factors',
        _CLAUSES: 'Clauses',
        _ADDITIONAL_DATA: 'Additional data',
        _TYPE_OF_AUTHORITY: 'Type of authority',
        _DESCRIPTION_AUTHORITY: 'Description authority',
        _CATASTROPHIC_EVENT: 'Catastrophic event',
        _DESCRIPTION_EVENT: 'Description event',
        _ANTI_FRAUD_CATHEGORY: 'Anti fraud cathegory',
        _LAST_AUDIT_DATE: 'Last audit date',
        _LIQUIDABLE: 'Payable',
        _NATION: 'Nation',
        _ZIP_CODE: 'Zip code',
        _BODYWORK: 'Bodywork',
        _SELECT: 'Select',
        _DENOMINATION: 'Denomination',
        _TELEPHONE: 'Telephone',
        _ACCIDENT_CIRCUMSTANCE: `Accident circumstance`,
        _BAREMS: 'Barems',
        _BLACK_BOX_COHERENCE: 'Black box coherence',
        _ACCIDENTS: 'Accidents',
        _INSURED_LIABILITY_PERC: 'Insured liability percentage',
        _EVENT_DENIAL: 'Event denial',
        _SIGN_TYPE: 'Sign type',
        _INSURED_VEICHLE_POSITION: 'Insured veichle position',
        _THIRD_PART_VEICHLE_POSITION: 'Third part veichle position',
        _CALCULATED_LIABILITY: 'Calculated liability',
        _LIABILITY_TYPE: 'Liability type',
        _COUNTERPART_LIABILITY_PERC: 'Counterpart liability percentage',
        _DECLARED_LIABILITY: 'Declared liability',
        _ANIA_CLASSIFICATION: 'Ania classification',
        _APPLY_TECH_LIABILITY_FORCED: 'Apply techinical liability forcing (bonus/malus system)',
        _NOTE: 'Note',
        _APPLY_ANIA_LIABILITY_FORCED: 'Apply percentage liability forcing declared to ANIA',
        _FORCE_REASON: 'Force reason',
        _FORCED_LIABILITY: 'Forced liability',
        _EDIT_ACCIDENTS: 'Edit accidents',
        _USE: 'Use',
        _BUTTONS_: {
            _ACTIONS: 'ACTIONS',
            _BACK: 'BACK',
            _CLOSE: 'CLOSE',
            _NEXT: 'NEXT',
            _NEW: 'NEW',
            _CANCEL: 'CANCEL',
            _CONFIRM: 'CONFIRM',
            _EXIT: 'EXIT',
            _SAVE: 'SAVE',
            _SAVE_PES: 'SAVE PES',
            _EMPTY: 'EMPTY',
            _FIND: 'FIND',
            _SHOW: 'SHOW',
            _HIDE: 'HIDE',
            _INSERT: 'INSERT',
            _NEW_DIALOGUE: 'NEW DIALOGUE',
            _ASSOCIATE: 'ASSOCIATE',
            _REQUEST: 'REQUEST',
            _RESET_ADVANCE: 'RESET ADVANCE',
            _CORRECT: 'CORRECT',
            _ADD: 'ADD',
            _SEARCH: 'SEARCH',
            _IBD: 'IBD',
            _OK: 'OK',
            _ADD_NOTE: 'ADD NOTE',
            _FAILED_TRANSMISSION: 'FAILED TRANSMISSION',
            _EVENTS: 'EVENTS',
            _SEND: 'SEND',
            _DOWNLOAD_DOCUMENTI_SELEZIONATI: 'DOWNLOAD SELECTED DOCUMENTS',
            _DOWNLOAD_CLAIM_DOCUMENTS: 'DOWNLOAD CLAIM DOCUMENTS',
            _DOCVIEWER: 'DOCVIEWER',
            _DOWNLOAD_POLICY_DOCUMENTS: 'DOWNLOAD POLICY DOCUMENTS',
            _EDIT: 'Edit',
            _NEW_REQUEST: 'New Request',
            _RERUN: 'Rerun',
            _RESERVATION: 'RESERVATION',
            _ONDEMAND: 'ONDEMAND',
            _NEW_MANUAL_DEADLINE: 'New Manual Deadline',
            _UPLOAD: 'UPOLAD',
            _TRANSMISSION: 'TRANSMISSION',
            _MODELS_LIST: 'Models list',
            _NEW_LETTER_MODEL: 'New letter model',
            _DELETE: 'DELETE',
            _ADD_ACTOR: 'ADD ACTOR',
            _RETRIEVE_INFORMATION: 'RETRIEVE INFORMATION',
            _REASSOCIATE: 'Reassociate',
            _MODIFY: 'MODIFY',
            _RESPONSE: 'RESPONSE',
        },
        _MESSAGE_: {
            _NO_RESULT: 'No result',
            _OPENED_CLAIM_DO_ISSUE: 'Opened claim due to summons Issued',
            _NOT_IDENTIFIED_WITNESS: 'Not identified witness',
            _SELECT_ONLY_THE_BOXES_USEFUL_FOR_DESCRIPTION: 'Select only the boxes useful for the description',
            _SELECT_AT_LEAST_ONE_CAUSAL: 'Please, select at least one causal before continuing.',
            _INVALID_DATE_INCIDENT: 'The date entered cannot be different from the date reported',
            _INVALID_REPORTED_DATE_INCIDENT: 'The date entered cannot be prior to the occurrence date',
            _INVALID_RECEIVED_DATE_INCIDENT: 'The date entered cannot be prior to the reported date',
            _INVALID_CHC_DATE : 'The date entered cannot be prior to the date received intermediary',
            _INVALID_FUTURE_DATE : 'It is not possible to select dates in the future',
            _CANNOT_OPEN_CLAIM_FOR_UNCOVERED_POLICY: 'It is impossible to open a claim on an uncovered policy',
            _FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES: 'Fields marked in red are required or contain incorrect values',
            _INCORRECT_OR_MISSING_DATA: 'Incorrect or missing data',
            _THERE_ARE_TYPES_OF_LOSS_MANAGED_WITH_CLAIMS_MADE__POSTUMA: 'There are types of loss managed with claims made / postuma:',
            _TECHNICAL_AND_ADMINISTRATIVE_VALIDATION_CONTROLS_WILL_BE_REDONE_IN_THE_TYPE_OF_LOSS_SECTION: ' technical and administrative validation controls will be redone in the type of loss section',
            _CACHE_ERROR: 'Cache error',
            _ERROR_IN_INITIALIZATION_PROCEED_WITH_MANUAL_SELECTION: 'ERROR in initialization. Proceed with manual selection',
            _UPDATE_ERROR: 'Update error',
            _SEARCH_EXPERTS_ERROR: 'Search Experts Error',
            _SEARCH_REJECT_TYPES_ERROR: 'Search reject types error',
            _AMOUNT_TO_BE_PAID_CANNOT_BE_LESS_THAN_THE_SUM_OF_THE_TAXABLE_AMOUNT_AND_THE_NONTAXABLE_AMOUNT: 'Amount to be paid cannot be less than the sum of the taxable amount and the non-taxable amount',
            _AMOUNT_TO_BE_PAID_DIFFERENT_FROM_INVOICE_AMOUNT_MINUS_HOLD_TAX_AMOUNT: 'Amount to be paid different from invoice amount minus hold tax amount',
            _AMOUNT_TO_BE_PAID_MUST_BE_GREATER_THAN_ZERO: 'Amount to be paid must be greater than zero',
            _ATTENTION_A_CLAIM_IS_BEING_OPENED_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Attention! A claim is being opened on a technically uncovered policy',
            _ATTENTION_OPENING_CLAIM_ON_A_CANCELED_POLICY: 'Attention opening claim on a canceled policy',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT: 'Attention the payment exceeds the residual amounts of the limit!',
            _CANNOT_OPEN_A_CLAIM_ON_A_CANCELED_POLICY: 'Cannot open a claim on a canceled policy',
            _CANNOT_OPEN_A_CLAIM_ON_A_POLICY_WITHOUT_ADMINISTRATIVE_VALIDITY: 'Cannot open a claim on a policy without administrative validity',
            _CASH_PREVIDENTIAL_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Cash previdential amount cannot be greater than the taxable amount',
            _DRAG_A_FILE_TO_UPLOAD: 'Drag a file to upload',
            _EXISTING_PES_ALERT: 'A PES/claim with same date occurred and policy is already present. Continue?',
            _HOLD_TAX_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Hold tax amount cannot be greater than the taxable amount',
            _IMPOSSIBLE_TO_EXECUTE_THE_REQUIRED_OPERATION_CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossible to execute the required operation: claim receipts to authorize',
            _IMPOSSIBLE_TO_PROCEED_USER_NOT_AUTHORIZED_TO_OPEN_A_CLAIM_ON_A_TECHNICALLY_UNCOVERED_POLICY: 'Impossible to proceed: User not authorized to open a claim on a technically uncovered policy',
            _INSERT_A_CSV_FILE: 'Insert a csv file',
            _INSERT_POLICY_NUMBER_OR_PLATE: 'Insert policy number or plate',
            _INSERT_SUBJECT_SURNAME_OR_DENOMINATION: 'Insert subject surname or denomination',
            _INVOICE_AMOUNT_DIFFERENT_FROM_SUM_OF_TAXABLE_AMOUNT_NONTAXABLE_AMOUNT_CASH_PREVIDENTIAL_AMOUNT_STAMP_DUTY_AMOUNT_VAT_AMOUNT: 'Invoice amount different from sum of taxable amount, non-taxable amount, cash previdential amount, stamp duty amount, vat amount',
            _INVOICE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Invoice amount must be greater than zero',
            _IT_IS_NOT_POSSIBLE_TO_DELETE_A_DOCUMENT_ALREADY_UPLOADED_TO_DOCUMENT_MANAGEMENT_SYSTEM: 'It is not possible to delete a document already uploaded to document management system',
            _MISSING_CLAIM_NUMBER_AT_LINE: 'Missing claim number at line ',
            _MISSING_NOT_TAXABLE_AMOUNT_AT_LINE: 'Missing not taxable amount at line ',
            _MISSING_TAXABLE_AMOUNT_AT_LINE: 'Missing taxable amount at line ',
            _MISSING_TOTAL_UNIT_AMOUNT_AT_LINE: 'Missing total unit amount at line ',
            _N_NOT_CONSISTENT: 'N Vehicle damage not consistent with black box data',
            _NO_ACTIVITY_TO_SHOW: 'No Activity to show',
            _NO_CHANGE_MADE: 'No change made',
            _NO_CHAT: 'No chat',
            _NO_DOCUMENTS_TO_UPLOAD: 'No documents to upload',
            _NO_NORMALIZED_ADDRESS_FOUND: 'No normalized address found',
            _NO_RESULTS: 'No results',
            _POLICY_NOT_COMPLIANT_WITH_THE_SELECTED_DAMAGE_CATEGORY: 'Policy not compliant with the selected damage category',
            _POLICY_NOT_COMPLIANT: 'Policy not compliant with the selected damage category',
            _PRESS_CALCULATE_BUTTON_BEFORE_CONFIRM: 'Press calculate button before confirm',
            _RESERVE_AMOUNT_NOT_CAPACIOUS_UPDATE_RESERVE_BEFORE: 'Reserve amount not capacious: update reserve before',
            _S_CONSISTENT: 'S Vehicle damage consistent with black box data',
            _SAVING_ERROR: 'Saving error',
            _SEARCH_ERROR: 'Search error',
            _STAMP_DUTY_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Stamp duty amount cannot be greater than the taxable amount',
            _SUM_OF_TAXABLE_AMOUNT_AND_NONTAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Sum of taxable amount and non-taxable amount must be greater than zero',
            _SUM_OF_THE_NONTAXABLE_AMOUNT_OF_THE_DETAILS_OTHER_THAN_THE_NONTAXABLE_AMOUNT_OF_THE_INVOICE: 'Sum of the non-taxable amount of the details other than the non-taxable amount of the invoice',
            _SUM_OF_THE_TAXABLE_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_TAXABLE_AMOUNT_OF_THE_INVOICE: 'Sum of the taxable amount of the details different from the taxable amount of the invoice',
            _SUM_OF_THE_TOTAL_UNIT_AMOUNT_OF_THE_DETAILS_DIFFERENT_FROM_THE_INVOICE_AMOUNT: 'Sum of the total unit amount of the details different from the invoice amount',
            _TAXABLE_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Taxable amount must be greater than zero',
            _THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE: 'The document will be permanently deleted. Do you with to continue?',
            _THE_POLICY_ALSO_COVERS_MATERIAL_DAMAGE_TO_YOUR_VEHICLE: 'The policy also covers material damage to your vehicle.',
            _TITLE_AND_DEROGATION_LEVEL_ALREADY_EXIST: 'Title and derogation level already exist',
            _TOL_CLAIMS_MADE: 'There are types of loss managed with claims made / postuma: technical and administrative validation controls will be redone in the type of loss section',
            _TOTAL_UNIT_AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Total unit amount must be greater than zero',
            _UNABLE_TO_MAKE_THE_PAYMENT_THE_RESIDUAL_AMOUNTS_OF_THE_LIMIT_EXCEEDED: 'Unable to make the payment: the residual amounts of the limit exceeded',
            _UPDATE_SUCCESSFUL: 'Update successful',
            _UPDATE_RESERVE_SUCCESSFUL: 'Update reserve successful',
            _UPDATE_RESERVE_ERROR: 'Update reserve error',
            _VAT_AMOUNT_CANNOT_BE_GREATER_THAN_THE_TAXABLE_AMOUNT: 'Vat amount cannot be greater than the taxable amount',
            _WARNING_YOU_ARE_EXCEEDING_THE_CEILING: 'Warning, you are exceeding the ceiling',
            _X_TO_BE_VERIFIED: 'X Consistency of the damage with the black box data to be verified',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET: 'You have to select at least one asset',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY: 'You have to select at least one party',
            _YOU_NEED_TO_ADD_A_DOCUMENT_IN_ORDER_TO_EXECUTE_THE_UPLOAD: 'You need to add a document in order to execute the upload.',
            _TYPE_OF_BODYWORK_IS_MANDATORY: 'Type of bodywork is mandatory',
            _NO_RES_FOUND: 'No result found',
            _HEIR_ALREADY_ADDED: 'Heir has already been added',
            _HEIR_DATE_INVALID: 'It is not possible to add fatal date for a subject chosen as an heir',
            _HEIR_IS_EQUAL_SUBJECT: 'The deceased subject cannot be added as a subject',
            _HEIR_INVALID: 'It is not possible to select a person with a fatal date as an heir',
            _HEIR_INVALID_FATAL_DATE: 'Heir removed due to death date, check heirs again',
            _ON_CONFIRMATION: 'Upon confirmation, the regularity of the policy will be checked based on the coverage selected',
            _YOU_CAN_INSERT_1_TO_2_ATTACHMENT: 'You can insert 1 to 2 attachments.',
            _ALLOWED_EXTENSION: 'Allowed extensions: txt, pdf, jpg.',
            _THE_SIZE_OF_AN_ATTACHMENT_CANNOT_EXCEED_1_MB: 'The size of an attachment cannot exceed 1 MB.',
            _COMPILING_LETTER_CONTENT: 'Compiling letter content',
            _OPENED_POST_LITIGATION: 'Opened post litigation',
            _PRESENCE_REQUEST_DAMAGE: 'Presence request damage',
            _WARNING_THE_RESERVE_HAS_BEEN_REDUCED_BY_THE_PAID_AMOUNT: 'Warning, the reserve has been reduced by the paid amount',
            _OF_WHICH_ATTORNEYS_FEES: 'Of which attorneys fees',
            _NO_BANK_ACCOUNT_FOR_THE_SUBJECT: 'No bank account for the subject',
            _NO_IBAN_PRESENT_OF_THE_PAYEE: 'No iban present of the payee',
            _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
            _INSERT_DATE: 'Insert date',
            _CLAIM_EXCESS_POLICY_VALUES: 'Claim excess policy values',
            _CLAIM_EXCESS_RESIDUE: 'Claim excess residue',
            _CLAIM_EXCESS_ERODED_AMOUNT: 'Claim excess eroded amount',
            _EXCESS_AGGREGATE_POLICY_VALUES: 'Excess aggregate policy values',
            _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue',
            _EXCESS_AGGREGATE_ERODED_AMOUNT: 'Excess aggregate eroded amount',
            _RECEIPT_ACCEPTED: 'Receipt accepted',
            _RECEIPT_REJECTED: 'Receipt rejected',
            _REASON_REJECTED: 'Reason rejected',
            _ONE_PAYEE_IS_REQUIRED: 'One payee is required',
            _ONE_PAYEE_AND_ONE_BENEFICIARIES_REQUIRED: 'One payee and one beneficiary are required',
            _ONE_PRIMARY_BENEFICIARIES_REQUIRED: 'One primary beneficiary required',
            _ONE_PRIMARY_PAYEE_REQUIRED: 'One primary payee required',
            _TAX_CODE__VAT_NUMBER_MISSING: 'Tax code/Vat number missing',
            _PAYEE_MUST_BE_ADULT: 'Payee must be an adult',
            _PAYEE_NOT_REGISTERED_SAVE_SUBJECT_WITH_SUBJECT_MANAGEMENT_FUNCTION: 'Payee not regstered, save subject with subject managment function.',
            _BEFORE_DELETE_ADD_NEW_BENEFICIARY: 'Before delete add new beneficiary',
            _BEFORE_DELETE_ADD_NEW_PAYEE: 'Before delete add new payee',
            _THE_CANCELLATION_DATE_IS_NOT_VALID: 'The cancellation date is not valid',
            _SELECT_REASON_CANCELLATION: 'Select cancellation reason',
            _METHOD_NOT_IMPLEMENTED: 'Method not implemented',
            _THERE_IS_A_PAYMENT_WITHOUT_ASSOCIATED_DOCUMENTS_CONTINUE: 'There is a payment withou associated documents, continue?',
            _SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED: 'Subject with incomplete tax data, unable to proceed',
            _OPERATION_NOT_AVAILABLE: 'Operation not available',
            _FEE_DATA_MISSING: 'Fee data missing',
            _MANDATORY_DATA_MISSING: 'Mandatory data missing',
            _SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS: 'Select the definition',
            _YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS: 'You have to select at least one type of loss',
            _THE_AMOUNT_FOR_THE_REASON_SELECTED: 'The amount for the reason selected',
            _INCOMPLETE_ADDRESS: 'Incomplete address',
            _INCOMPLETE_DATA: 'Incomplete data',
            _UIC_NOT_VALID__MAX_3_CHARACTER: 'Uic not valid, max 3 character',
            _FOREIGN_BANK_ACCOUNT_INVALID: 'Foreign bank account not valid',
            _IBAN_INVALID: 'Iban not valid',
            _INSERT_A_VALID_ITALIAN_IBAN: 'Insert a valid italian iban',
            _SPECIAL_CHARACTERS_NOT_ALLOWED_IN_IBAN_CODE: 'Special characters are not allowed in iban code',
            _RESERVE_AMOUNT_NOT_CAPACIOUS__UPDATE_RESERVE_BEFORE: 'Reserve amount not capacious, update reserve before',
            _IMPOSSIBLE_TO_EXECUTE_REQUIRED_OPERATION__CLAIM_RECEIPTS_TO_AUTHORIZE: 'Impossible to execute required operation,claim receipts to authorize',
            _ATTENTION_THE_PAYMENT_EXCEEDS_THE_RESIDUAL_AMOUNT_OF_THE_LIMIT: 'Attention,the payment exceeds the residual amount of the limit',
            _SELECT_REASON_REVERSAL: 'Select reason reversal',
            _ENTER_NOTES: 'Enter notes',
            _THE_TOTAL_AMOUNT_CANNOT_BE_GREATER_THAN_OR_EQUAL_TO_0: 'The total amount cannot be greater than or equal to 0 ',
            _THE_CREDIT_NOTE_CANNOT_BE_HIGHER_THAN_THE_ORIGINAL_INVOICE: 'The credit note cannot be higher than the original invoice',
            _INVOICE_TYPE_REQUIRED: 'Invoice type required',
            _FEE_NUMBER_AND_FEE_DATE_ARE_REQUIRED: 'Fee number and fee date are required',
            _THE_REVERSAL_DATE_IS_NOT_VALID: 'The reversal date is not valid',
            _ERROR_CODE: 'Error code',
            _ERROR: 'Error',
            _SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO: 'Specify event/assignmentNumber/date from-to',
            _SPECIFY_ONLY_CLAIM_NUMBER: 'Specify only claim number',
            _SPECIFY_CRITERIA: 'Specify a criteria',
            _SPECIFY_EVENT: 'Specify event',
            _SPEFCIFY_CLAIM_NUMBER: 'Specify claim number',
            _ALERT_NEW_DOC: 'Benachrichtigung für neue Dokumente',
            _EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH: 'Existing Requests for damages to match',
            _NO_REQUEST_DAMAGES: 'Keine Schadenmeldungen vorhanden',
            _REQUEST_FOR_DAMAGES_RECEIPT_DATE: 'Request for damages receipt date',
            _GENERIC_ERROR_ON_SAVE_QUESTIONNARIE: 'Allgemeiner Fehler beim Speichern des Fragebogens',
            _QUESTIONNAIRE_WAS_UPDATE_SUCCESSFUL: 'Der Fragebogen wurde erfolgreich aktualisiert',
            _QUESTIONNAIRE_MODIFICATION_CANCELLED: 'Änderung des Fragebogens abgebrochen',
            _ENTER_A_VALID_IBAN: 'Enter a valid Iban',
            _INSERT_A_VALID_SPANISH_IBAN: 'Enter a valid Spanish Iban',
            _NO_NOTES_FOR_THIS_COMPENSATION: 'No notes for this compensation',
            _CHARACTER_LIMIT_EXCEEDED: 'Character limit exceeded',
            _AN_ERROR_OCCURED_WHILE_ADDING_THE_NOTE: 'An error occurred while adding the note',
            _VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT: 'Value at least one field between: name, surname, business name, contractor fiscal code, VAT code, code independent expert',
            _ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION: 'Are you sure you want to delete the trustee association?',
            _EXPERT_LINK_ADDED_WITH_SUCCESS: 'Expert link added with success!',
            _AN_ERROR_OCCURRED_WHILE_PROCESSING_THE_REQUEST: 'An error occurred while processing the request.',
            _EXPERT_ASSOCIATION_DELETED_CORRECTLY: 'Expert association deleted correctly!',
            _AN_ERROR_OCCURRED_WHILE_DELETING_THE_EXPERT_ASSOCIATION: 'An error occurred while deleting the expert association',
            _IN_ERROR: 'In error',
            _SELECT_A_USER_BEFORE_CONFIRMING: 'Select a user before confirming',
            _OPERATION_PROPERLY_TERMINATED: 'Operation propely terminated',
            _INCOMPLETE_COMPILATION: 'Incomplete compilation',
            _FISCAL_CODE__VAT_UNCORRECT: 'Fiscal code/VAT uncorrect',
            _VAT_UNCORRECT: 'VAT uncorrect',
            _FISCAL_CODE_UNCORRECT: 'Fiscal code uncorrect',
            _SELECT_A_CRITERIA: 'Select a criteria',
            _IMPUTE_THE_NAME_OR_THE_FISCAL_CODE: 'Impute the name or the fiscal code',
            _SPECIFY_THE_TYPE_ASSET: 'Specify the type asset',
            _SELECT_AT_LEAST_ONE_REASON: 'Select at least one reason',
            _FILE_IS_EMPTY: 'File is empty',
            _MAXIMUM_FILE_SIZE_EXCEEDED: 'Maximum file size exceeded',
            _NOT_GENERATED_FOR_CHOICE:'Not generated for choice',
            _GENERIC_ERROR_SAVE_ACTOR: 'Error encountered while saving new actor',
            _SAVE_NEW_ACTOR: 'DE_Save new actor',
            _SELECT_THE_DOCUMENT_TO_ATTACH: 'Select the documents to attach to the payment',
            _KO_UPLOAD_DOC: 'An error occurred while uploading the document',
            _CANCELLATION_DIALOGUE: 'DE_Are you sure you want to cancel this dialogue?',
            _CONFIRM_DEPRECATE_ASSET_EDIT_ACTOR: 'DE_Cambiando il ruolo verranno deprecate delle causali associate ad un bene non disponibile per il ruolo selezionato. Si è sicuri di voler proseguire?',
            _EXIT_FROM_CAI_CONFIRM: 'DE_Procedendo verrai riportato nella homepage perdendo i dati. Procedere?',
            _TASK_ASSIGNED_TO_USER: 'Task assigned to the user'
        },
        _TITLE_: {
            _QUESTIONNAIRE_TITLE: 'Questionnaire',
            _SELECT_POLICY: 'Select policy',
            _OCCURRENCE_DATA: 'Occurrence data',
            _DATA_COLLECTION: 'Data collection',
            _SUMMARY: 'Summary',
            _DIALOGUE_HANDLER_LIST: 'Dialogue List',
            _DIALOGUE_HANDLER_NEW: 'New Demand',
            _DIALOGUE_HANDLER_SUMMARY: 'Dialogue Summary',
            _ADDRESS_MANAGEMENT: 'Address management'
        },
        _DATA_FORWARDING_CDL: ' Data forwarding CDL',
        _INTERMEDIARY: 'Intermediary',
        _MASTER_POLICY_NUMBER: 'Master policy number',
        _APPLICATION_NUMBER: 'Application number',
        _COMPLAINT_STATE: 'Complaint state',
        _PLATE__CHASSIS: 'Plate / Chassis',
        _ACCOUNTING_COVERAGE: 'Accounting coverage',
        _LEGAL_GUARDIAN_ROLE: 'Legal guardian role',
        _CAR_BODY_DETAIL: 'Car body detail',
        _START_DATE_OF_COLLABORATION: 'Start date of collaboration',
        _END_DATE_COLLABORATION: 'End date of collaboration',
        _HOUSE_NUMBER: 'House number',
        _MUNICIPALITY: 'Municipality',
        _NEW_BODYWORK: 'New bodywork',
        _SUBJECT_TYPE: 'Subject type',
        _CODE: 'Code',
        _BUSINESS_NAME: 'Business Name',
        _FISCAL_CODE: 'Fiscal code',
        _VAT_CODE: 'Vat Code',
        _TYPE_OF_BODYWORK: 'Type of bodywork',
        _INJURY_PHISICAL: 'Injury phisical',
        _CAUSE_OF_INJURY: 'Cause of injury',
        _UNCODABLE: 'Uncodable',
        _MULTIPLE: 'Multiple',
        _NATURE_OF_INJURY: 'Nature of injury',
        _INJURY_LOCATION: 'Injury location',
        _FURTHER_INJURY_INDICATOR: 'Further injury indicator',
        _PROFESSIONAL_QUALIFICATION: 'Professional qualification',
        _PERCENTAGE_OF_PERMANENT_DISABILITY: 'Percentage of permanent disability',
        _TEMPORARY_DISABILITY_DAYS_WITHOUT_SEQUELAE: 'Temporary disability days without sequelae',
        _FATALITY_DATE: 'Fatality Date',
        _FIRST_AID_COUNTRY: 'First aid country',
        _FIRST_AID_PLACE: 'First aid place',
        _HEALTHCARE_PROVIDERS: 'Healthcare Providers',
        _BIOLOGICAL_DAMAGE: 'Biological damage',
        _SUBROGATION_SOCIAL_ASS_EMPLOYER: 'Subrogation social ass/Employer',
        _DOCTOR: 'Doctor',
        _SEARCH: 'Search',
        _SKULL: 'SKULL',
        _SPINE_CERVICAL_COLUMN: 'SPINE-CERVICAL COLUMN',
        _LEFT_SHOULDER: 'LEFT SHOULDER',
        _RIGHT_SHOULDER: 'RIGHT SHOULDER',
        _SPINE_THORACIC_COLUMN: 'SPINE-THORACIC COLUMN',
        _SPINE_LUMBAR_COLUMN: 'SPINE-LUMBAR COLUMN',
        _SPINE_SACRUS_AND_COCCYX: 'SPINE-SACRUS AND COCCYX',
        _LEFT_ANKLE: 'LEFT ANKLE',
        _RIGHT_ANKLE: 'RIGHT ANKLE',
        _RIGHT_EAR: 'RIGHT EAR',
        _RIGHT_EYE: 'RIGHT EYE',
        _LEFT_EYE: 'LEFT EYE',
        _LEFT_EAR: 'LEFT EAR',
        _FACE: 'FACE',
        _NECK: 'NECK',
        _RIGHT_CHEST_WALL: 'RIGHT CHEST WALL',
        _LEFT_CHEST_WALL: 'LEFT CHEST WALL',
        _RIGHT_ARM: 'RIGHT ARM',
        _THORACIC_ORGANS: 'THORACIC ORGANS',
        _LEFT_ARM: 'LEFT ARM',
        _RIGHT_ELBOW: 'RIGHT ELBOW',
        _ABDOMINAL_ORGANS: 'ABDOMINAL ORGANS',
        _LEFT_ELBOW: 'LEFT ELBOW',
        _RIGHT_FOREARM: 'RIGHT FOREARM',
        _ABDOMINAL_WALL: 'ABDOMINAL WALL',
        _LEFT_FOREARM: 'LEFT FOREARM',
        _PELVIS___RIGHT_HIP: 'PELVIS - RIGHT HIP',
        _UROGENITAL_ORGANS: 'UROGENITAL ORGANS',
        _PELVIS___LEFT_HIP: 'PELVIS - LEFT HIP',
        _RIGHT_WRIST: 'RIGHT WRIST',
        _LEFT_WRIST: 'LEFT WRIST',
        _RIGHT_HAND: 'RIGHT HAND',
        _RIGHT_THIGH: 'RIGHT THIGH',
        _LEFT_THIGH: 'LEFT THIGH',
        _LEFT_HAND: 'LEFT HAND',
        _RIGHT_KNEE: 'RIGHT KNEE',
        _LEFT_KNEE: 'LEFT KNEE',
        _RIGHT_LEG: 'RIGHT LEG',
        _LEFT_LEG: 'LEFT LEG',
        _RIGHT_FOOT: 'RIGHT FOOT',
        _LEFT_FOOT: 'LEFT FOOT',
        _REQUIRED: 'Required',
        _HEIR: 'Heir',
        _HEIR_ADD: 'Add heir',
        _HEIRS: 'Heirs',
        _EXTERNAL_CLAIM_NUMBER: 'External claim number',
        _POTENTIAL_CLAIM_NUMBER: 'Potential claim number',
        _ALIGN_POLICY: 'Align Policy',
        _ACTIONS: 'Actions',
        _DATE_ENTRY_FROM: 'Date entry from',
        _ELABORATION_DATE_FROM: 'Elaboration date from',
        _EXPIRY_DATE_FROM: 'Expiry date from',
        _CLAIM_DATE_FROM: 'Claim date from',
        _FUNCTION: 'Function',
        _DAMAGE_MATCH: 'Damage Match',
        _TAX_ID_CODE: 'Tax Id Code',
        _COMPLAINT: 'Complaint',
        _NOT_ASSOCIATED: 'Not Associated',
        _CTP_COMPANY: 'Ctp Company',
        _US_CAR_LICENSE_PLATE: 'License Plate Us',
        _CTP_CAR_LICENSE_PLATE: 'License Plate Ctp',
        _ACTIVE_TASK: 'Active Task',
        _ERROR_MESSAGE: 'Error Message',
        _SIMILAR_FLAG: 'Similar Flag',
        _FILTER: 'Filter',
        _NOT_ASSOCIATED_DIALOGUES: 'Not Associated Dialogues',
        _DIALOGUE_DATE_START: 'Dialogue Date Start',
        _END_DATE: 'End Date',
        _SX_DATE: 'Sinister Date',
        _DIALOGUE: 'Dialogue',
        _EXTERNAL_KEY_DAMAGED: 'External Key Damaged',
        _INTERNAL_KEY_DEF: 'Internal Key definitive',
        _INTERNAL_KEY_TEMP: 'Internal Key temporary',
        _INTERNAL_KEY_DAMAGED_DEF: 'Internal Key Damaged definitive',
        _INTERNAL_KEY_DAMAGED_TEMP: 'Internal Key Damaged temporary',
        _INTERNAL_KEY_DAMAGED: 'Internal Key Damaged',
        _US_TYPE_PLATE: 'Type Plate Us',
        _CTP_TYPE_PLATE: 'Type Plate Ctp',
        _IDENTIFIER_UNIQUE_CODE: 'Identifier Unique Code',
        _PARAMETERIZED_DIALOGUE_DATA: 'Parameterized Dialogue Data',
        _REGULATORY_EXPIRY_DATE: 'Regulatory Expiry Date',
        _LAST_UPDATE_DATE: 'Last User Date',
        _USER_UPDATE: 'User Update',
        _ACTIVITY_TYPE: ' Activity Type',
        _PARAMETERS_UPPERCASE: 'PARAMETERS',
        _REQUEST_NOTE: 'Request Note',
        _RESPONSE_NOTE: 'Response Note',
        _DOCUMENTS_UPPERCASE: 'DOCUMENTS',
        _DISCARDED_UPPERCASE: 'DISCARDS',
        _CAUSAL: 'Causal',
        _EXPIRATION: 'Expiration',
        _SENDED: 'Sended',
        _START_STATUS: 'Start Status',
        _DOCUMENT_TYPE: 'Document Type',
        _DOCUMENT_TYPE_FLUX: 'Document Type (Flux)',
        _CODE_ERROR: 'Code Error',
        _INPUT: 'Input',
        _OUTPUT: 'Output',
        _RESPONSE_NOTES: 'Response Notes',
        _DOCUMENTS_REQUIRED: 'Documents required for managing the selected answer',
        _SELECTED_DOCUMENTS: 'Select the documents to associate with the response by entering the flow document type for each',
        _ACTOR_INVOLVED: 'Actor Involved',
        _REASON: 'Reason',
        _DOCUMENT_TYPE_FLOW: 'Document Type Flow',
        _MANDATORY: 'Mandatory',
        _MATTER: 'Matter',
        _SUB_CATEGORY: 'Sub-Category',
        _LST_MSG_CODE: 'Last Message Code',
        _LST_MES_REQ_DATA: 'Request Date (LST. MS.)',
        _LST_MES_REG_EXP : 'Expiry Date (LST. MS.)',
        _START_DATE: 'Start Date',
        _INTERNAL_KEY: 'Internal Key',
        _LST_RESP_MES_CODE : 'Response Code (LST. MS.)',
        _LST_MSG_DATE: 'Last Message Date',
        _LST_MSG_INT_EXP: 'Internal Expiry Date (LST. MS.)',
        _EXT_KEY_IDEF_DAM: 'External Key Identify Damage Party',
        _ONGOING: 'ONGOING',
        _EVADE: 'EVADE',
        _INSURANCE_CODE_US: 'Company',
        _ACTOR_NOMINATIVE: 'Actor Nominative',
        _DETAILS_CLAIMS_DATA: 'Claims Data Detail',
        _CONSOLIDATE: 'Consolidate',
        _BALANCE: 'Balance',
        _VALIDATION_AND_CONSOLIDATION: 'Validation&Upload',
        _PAYMENT_MANAGE: 'Payment Manage',
        _FORFAIT_MANAGE: 'Forfait Manage',
        _YEAR: 'Year',
        _MONTH: 'Month',
        _JANUARY: 'January',
        _FEBRUARY: 'February',
        _MARCH: 'March',
        _APRIL: 'April',
        _MAY: 'May',
        _JUNE: 'June',
        _JULY: 'July',
        _AUGUST: 'August',
        _SEPTEMBER: 'September',
        _OCTOBER: 'October',
        _NOVEMBER: 'November',
        _DECEMBER: 'December',
        _INCAPACITATED: 'Incapacitated',
        _TYPES_OF_LOSS: 'Types of loss',
        _RISK_DATA: 'Risk data',
        _COVERTURE_DATA: 'Coverture data',
        _RULES_FOR_UPLOADING_DOCUMENTS: 'Rules for uploading documents:',
        _OCCURRENCE_HOUR: 'Occurence hour',
        _DATE_INTERMEDIARY: 'Date intermediary',
        _TYPE_OF_AUTHORITIES_POSSIBLY_INVOLVED: 'Type of authorities possibly involved',
        _CATASTROPHIC_EVENT_DESCRIPTION: 'Catastrophic event description',
        _OPENING_MODE: 'Claim opening mode',
        _PARTY: 'Party',
        _ASSET_INSURED: 'Asset insurance',
        _NEW_DAMAGED: 'New damaged',
        _TYPE_OF_LOSS: 'Schadenursache',
        _NOMINATIVE: 'Nominative',
        _NO_RESULT: 'No results.',
        _NEW_ASSET: 'New asset',
        _ASSET_TYPE: 'Asset type',
        _TYPE: 'Type',
        _FOUND: 'Found',
        _COVERAGES_DETAIL: 'Coverages detail',
        _ADD_A_DAMAGED: 'Add a dameged',
        _PHYSICAL_PERSON: 'Physical person',
        _POLICY_DATA: 'Policy data',
        _ADMINISTRATIVE_VALIDITY: 'Administrative Validity',
        _LIST_OF_RELATED_CLAIMS: 'List of related claims',
        _CF__PIVA: 'CF/PIVA',
        _REFERENCE: 'Reference',
        _DOL: 'DOL',
        _DON: 'DON',
        _MAIN: 'Main',
        _ORIGINAL_EFFECT_DATE: 'Original Effect date',
        _NO_POLICY_TO_SHOW: 'No policy to show',
        _ASSIGNMENT_DATE: 'Assignment Date',
        _STATUS: 'Status',
        _FILTERS: 'Filters',
        _CLAIMS_HANDLER: ' Claims Handler',
        _COMPANY: ' Company',
        _TECHNICAL_STATE: 'Technical State',
        _RESERVED: 'Reserved',
        _INVOICE_DATE: 'Invoice Date',
        _AMOUNT: 'Amount',
        _REJECT_TYPE: 'Reject Type',
        _UPLOAD_DETAILS: 'Upload Details',
        _NUMBER: 'Number',
        _ACTOR: 'Actor',
        _UPLOAD: 'Upload',
        _EXPERT_INVOICE: 'Expert Invoice',
        _TAX_CODE__VAT_NUMBER: 'Tax Code/VAT Number',
        _MANUAL_NOTE: 'Manual note',
        _INSERT_A_NEW_MANUAL_NOTE: 'Insert a new manual note',
        _COMMENTS_FORM: 'Comments form',
        _THERE_ARE_NO_COMMENTS: 'There are no comments',
        _NO_PERMISSION: 'No permission',
        _CANCELLATION: 'Cancellation',
        _COMMENT: 'Comment',
        _TOTAL_ASSIGNMENT_EXPENSE_AMOUNT: 'Gesamtbetrag der Zuordnungskosten',
        _TOTAL_RESERVE_FOR_LITIGATION: 'Gesamtrücklage für Rechtsstreitigkeiten',
        _FROM: 'Von',
        _TO: 'Bis',
        _QUICK_FILTERS: 'Quick filters',
        _ASCENDING_ORDER: 'Aufsteigende Sortierung',
        _DESCENDING_ORDER: 'Absteigende Reihenfolge',
        _MOVEMENTS: 'Vorgänge',
        _ACCESSES: 'Accesses',
        _ACTIVITY: 'Aktivitäten',
        _RESERVES: 'Reserven',
        _TITLES: 'Titles',
        _STATE: 'Status',
        _ASSIGNEE_GROUP: 'Assign group',
        _ASSIGNEE_USER: 'Assign user',
        _RUN_DATE: 'Run date',
        _PRIORITY: 'Priority',
        _SUSPENDED: 'Suspended',
        _COMPLETION_DATE: 'Completion date',
        _ESCAPE_METHODS: 'Escape methods',
        _RUN_ACTIVITY: 'Run Activity',
        _TAKE_CHARGE_ACTIVITY: 'Take charge activity',
        _REASSESS_TASK: 'Reassess task',
        _NO_SUGGESTIONS: 'No suggestions',
        _CRITERIA: 'Criteria',
        _ASSIGNMENT: 'Assignment',
        _EVENTS: 'Events',
        _R_PER: 'Return assignment (R-PER)',
        _A_COM: 'Communication (A-COM)',
        _S_RIF: 'Refuse assignment (S-RIF)',
        _R_FAT: 'Invoice  (R-FAT)',
        _TRANSMISSION_LIST: 'Trasmission list',
        _FAILED_TRANSMISSION_LIST: 'Failed trasmission list',
        _STARTER_EVENT: 'Evento starter',
        _REQUEST: 'Request',
        _ID: 'Id',
        _WS: 'WS',
        _XML: 'Xml',
        _RESPONSE: 'Response',
        _DETAIL: 'Detail',
        _VIEW_DETAIL_BLOW: 'View detail blow',
        _INSERT_DATE: 'Insert date',
        _ELABORATION_DATE: 'Elaboration date',
        _INSUFFICIENT_PERMISSIONS: 'Insufficient Permissions',
        _CLAIM: 'Schaden',
        _POTENTIAL_CLAIM_INQUIRY: 'Schadenmeldung Beauskunftung',
        _VALIDATE: 'Validate',
        _INVALIDATE: 'Invalidate',
        _MODIFY: 'Modify',
        _SUBCATEGORY: 'Subcategory',
        _RECEPTION_CHANNEL: 'Reception channel',
        _FORMAT: 'Format',
        _SIZE: 'Size',
        _NUMBER_OF_PAGES: 'Number of pages',
        _RECEIIPT_DATE: 'Receipt date',
        _USER: 'User',
        _SUBTITLE: 'SubTitle',
        _DOC_KEY: 'Doc key',
        _POSTALIZATION_RESULT: 'Postalization result',
        _POSTALIZATION_DATE: 'Postalization date',
        _SHIPPING_TYPE: 'Shipping type',
        _VIEW_BY_CATEGORIES: 'View by categories',
        _UPLOAD_DATE: 'Upload date',
        _SUBCATEGORY_DEPRECATED: ' Subcategory deprecated',
        _CATEGORY_DEPRECATED: ' Category deprecated',
        _HISTORY: 'History',
        _OPERATIONS: 'Operations',
        _EDIT_DOCUMENT: 'Edit document data',
        _CHANGE_PARTY: 'Change party',
        _SELECT_CATEGORY: 'Kategorie auswählen',
        _SELECT_SUBCATEGORY: 'Unterkategorie auswählen',
        _PEOPLE: 'People',
        _EXTERNAL_VISIBILITY: 'External visibility',
        _VEICHLE: 'Veichle',
        _VALIDATED: 'Validate',
        _NO_VALIDATE: 'No validate',
        _TECHNICAL_STATUS: 'Technischer Status',
        _RESERVE_AMOUNT: 'Reservebetrag',
        _TECHNICAL_REACTIVATION_OPENING_DATE: 'Versicherungstechnisches Wiedereröffnungsdatum',
        _ACCOUNTING_REACTIVATION_OPENING_DATE: 'Datum der letzten Neueröffnung/Reaktivierung des Kontos',
        _TECHNICAL_CLOSING_DATE: 'Technisches Abschlussdatum',
        _NEW_QUESTIONNAIRE_DATE: 'New Questionnaire Date',
        _ROLE: 'Rolle',
        _CODE_CHAIN: 'Code chain',
        _EXTERNAL_KEY: 'External Key',
        _CODE_ACTION: 'Code action',
        _DATA_ENTRY: 'Data entry',
        _OUTCOME: 'Outcome',
        _INSERTED: 'Inserted',
        _JSON_N: 'Json N°',
        _LAST_ELABORATION: 'Last elaboration',
        _CANCELLATION_REASON: 'Cancellation Reason',
        _ASSOCIATED_ENTITY: 'Associated Entity',
        _EXECUTIONS: 'Executions',
        _JSON_OK_N: 'Json OK n.',
        _JSON_KO_N: 'Json KO n.',
        _RERUN: 'Rerun',
        _RESERVATION_DATE: 'Reservation date',
        _IN_PROGRESS: 'In progress',
        _CLOSED: 'Closed',
        _FORCED_CLOSURE: 'Forced closure',
        _NOT_STOPPED: 'Not stopped',
        _STOPPED_SUCCESSFULLY: 'Stopped successfully',
        _RERUN_RESERVED: 'Rerun reserved',
        _RERUN_FAILURE: 'Rerun failure',
        _RESULTS: 'Results',
        _TITLE: 'Title',
        _INSERTION: 'Insertion',
        _ELABORATION: 'Elaboration',
        _REQUEST_TYPE: 'Art der Anfrage',
        _ELABORATION_REQUEST_DATE: 'Elaboration request date',
        _INPUT_PARAMETERS: 'Input parameters',
        _JSON: 'Json',
        _JSON_EXTERNAL_KEY: 'Json External key',
        _CREATION_FAILED: 'Creation failed',
        _REQUEST_CREATED: 'Request created',
        _RESERVATION: 'Reservation',
        _CREATION_ERROR: 'Creation error',
        _TOO_MANY_JSON_WERE_UPLOADED: 'Too many json were uploaded',
        _COMPANY_SETTLEMENT_AMOUNT: 'Company settlement amount',
        _PAYEE: 'Payee',
        _PAYMENT_TYPE: 'DE_Payment type',
        _PAYMENT_DATE: 'DE_Payment date',
        _BENEFICIARIES: 'DE_Beneficiaries',
        _ADD_BENEFICIARY: 'Add beneficiary',
        _PAYEES: 'Payees',
        _ADD_PAYEE: 'Add Payee',
        _CALCULATE: 'Calculate',
        _REASON_CANCELLATION: 'Cancellation Reason',
        _C__O: 'C/O',
        _NO_DOCUMENTS: 'No Documents',
        _FEE_DATA: 'Fee Data',
        _FEE_NUMBER: 'Fee Number',
        _FEE_DATE: 'Fee Date',
        _IVA_AMOUNT: 'Iva amount',
        _IRPF_AMOUNT: 'IRPF amount',
        _WITH_HOLDING_AMOUNT: 'With holding amount',
        _NET_AMOUNT: ' Net amount',
        _CASH_AMOUNT_1: 'Cash amount 1',
        _CASH_AMOUNT_2: 'Cash amount 2',
        _SETTLEMENT_BLOCKED__PERCENTAGE_SAME_INVOICE_ALREADY_REIMBURSED_TO_INSURED: 'Settlement blocked: % same invoice already reimbursed to insured',
        _PAID_AMOUNT: 'Paid Amount ',
        _PERCENTAGE_OF_THE_TOTAL: '% of the total ',
        _ALREADY_INSERT: 'Already insert',
        _REMAINING_AMOUNT: ' Remaining amount',
        _EVALUATION: ' Evaluation',
        _PAYMENT_DATA: 'Payment data',
        _CALCULATION_OF_THE_INSURED_FEE: 'Calculation of the insured fee',
        _BENEFICIARIES_AND_PAYEES: 'Beneficiaries and payees',
        _METHODS_OF_PAYMENT: 'Methods of Payment',
        _LETTERS_MANAGEMENT: 'Letters managment',
        _RECEIPT_SUMMARY: 'Receipt summary',
        _SETTLEMENT_SUMMARY: 'Settlement summary',
        _PARTY_ROLE: 'Party role',
        _OPERATION_USER: 'Operation user',
        _QUITTANCE_DATE: 'Quittance date',
        _CURRENCY_DATE: 'Currency date',
        _PAYMENT_TRANSACTION_TYPE: 'Payment transaction type',
        _RECEIPT_TYPE: 'Receipt type',
        _MANAGMENT_PAYMENT: 'Managment payment',
        _SEPARATE_RECEIPT: 'Separate receipt',
        _CURRENCY: 'Currency',
        _DUE_DATE: 'Due date',
        _PROCEDURE_CODE: 'Procedure code',
        _AMOUNT_QUOTA: 'Amount quota',
        _OPEN_DETAIL: 'Open detail',
        _FORFAIT_TYPE: 'Forfait type',
        _AMOUNT_QUOTA_FORFAIT: 'Amount quota forfait',
        _FORFAIT: 'Forfait',
        _IMPORT: 'Import',
        _METHOD_OF_PAYMENT: 'Method of payment',
        _BANK_TRANSFER_DESCRIPTION: 'Bank Transfer Description',
        _INCOMPLETE_ADDRESS: 'Incomplete address',
        _NOT_GENERATED_FOR_MISSING_DATA: 'Not generated for missing data',
        _GENERATED: 'Generated',
        _RETURN_OF_RECEIPT: 'Return of receipt',
        _RECEIPT_STATUS: 'Receipt status',
        _RECEIPT_DATE: 'Receipt date',
        _REASON_CANCEL_RECEIPT: 'Reason cancel receipt',
        _RECEIPT_NOTES: 'Receipt notes',
        _RETURN: 'Return',
        _BENEFICIARY: 'Beneficiary',
        _SENDING_LETTERS: 'Sending letters',
        _MANAGEMENT_PAYMENT: 'Managment payment',
        _PAYABILITY: 'Payability',
        _WAITING: 'Waiting',
        _SUBSTATUS: 'Substatus',
        _PAYMENT_DEFINITION: 'Payment Definition',
        _RESERVED_AMOUNT: 'Reserved Amount',
        _PARTIAL: 'Partial',
        _CHC: 'CHC',
        _LOAD_DATE: 'Load date',
        _OFFER_DATE: 'Offer date',
        _IBAN: 'Iban',
        _IBAN_ON_SUBJECT: 'Iban on subject',
        _ACCOUNT_HOLDER: 'Account holder',
        _BANK_DESCRIPTION: 'Bank description',
        _BANK_ADDRESS: 'Bank address',
        _SWIFT: 'Swift',
        _FOREIGN_BANK_ACCOUNT: 'Foreign Bank Account',
        _FOREIGN_BANK_ACCOUNT_ON_SUBJECT: 'Foreign bank account on subject',
        _BIC: 'BIC',
        _UIC: 'UIC',
        _BANK: 'Bank',
        _LEGAL_OPPONENT: 'Legal opponent ',
        _REGISTER_SUBJECT: 'Register subject',
        _OPEN_REGISTER: 'Open register',
        _SETTLEMENTS_LIST: 'Settlements list',
        _RECEIPTS_LIST: 'Receipts list',
        _COMPENSATIONS_LIST: 'Compensation list',
        _APPOINTMENTS_LIST: 'Appointments list',
        _TYPOLOGIES_SETTLEMENT_LIST: 'Typologies settlement list',
        _ACCESSORY_CHECKS: 'Accessory checks',
        _FORCING_APPLY: 'Forcing apply',
        _ADMINISTRATIVE_VALIDITY_OVERIDDEN_ON_POLICY_NO: 'Administrative validity overidden on policy no',
        _EFFECTIVE_DATE_OF_COLLECTION: 'Effective date of collection',
        _EXPIRY_DATE_OF_COLLECTION: 'Expiry date of collection',
        _DATE_OF_COLLECTION: 'Date of collection',
        _CONFIRM_RESPONSIBILITY: 'Confirm responsibility',
        _PERCENTAGE_INSURED_LIABILITY: 'Percentage insured liability',
        _REASON_TO_FORCE_LIABILITY_PERCENTAGE: 'Reason to force liability percentage',
        _COUNTERPART_VEHICLE: 'Counterpart vehicle',
        _CHASSIS__PLATE_NUMBER: 'Chassis plate number',
        _LIABILITY_PERCENTAGE: 'Liability percentage',
        _HISTORICAL_EROSION: 'Historical erosion',
        _INPUT_AMOUNT: 'Input amount',
        _INSURED_SETTLEMENT_AMOUNT: 'Insured settlement amount',
        _CLAIM_EXCESS: 'Claim excess ',
        _DEDUCTIBLE: 'Deductible',
        _EXCESS_AGGREGATE: 'Excess aggregate',
        _SIR: 'Sir',
        _LIMIT: 'Limit',
        _FIRST_SIR: 'First sir',
        _SECOND_SIR: 'Second sir',
        _ADVANCE: 'Advance',
        _SIR_ADVANCED_BY_COMPANY_SOMETIMES: 'Sir advanced by company sometimes',
        _CAP_INDICATOR: 'Cap indicator',
        _CAP_AMOUNT: 'Cap amount',
        _EXCESS_AMOUNT: 'Excess amount ',
        _FIRST_SIR_POLICY_VALUES: 'First sir policy values ',
        _FIRST_SIR_RESIDUE: 'First sir residue ',
        _FIRST_SIR_ERODED_AMOUNT: 'First sir eroded amount ',
        _SIR_AMOUNT: 'Sir amount',
        _DEDUCTIBLE_POLICY_VALUES: 'Deductible policy values',
        _DEDUCTIBLE_RESIDUE: 'Deductible residue',
        _DEDUCTIBLE_ERODED_AMOUNTS: 'Deductible eroded amounts',
        _PERCENTAGE_AMOUNT: 'Percentage amount',
        _DEDUCTIBLE_AMOUNT_RESIDUE: 'Deductible amount residue',
        _CALCULATED_AMOUNT: 'Calculated amount',
        _MAXIMUM_AMOUNT: 'Maximum amount',
        _MINIMUM_AMOUNT: 'Minimum amount',
        _MAXIMUM_LEVELS_POLICY_VALUES: 'Maximum levels policy values',
        _MAXIMUM_LEVELS_RESIDUE: 'Maximum levels residue',
        _MAXIMUM_LEVELS_ERODED_AMOUNTS: 'Maximum levels eroded amounts',
        _RETURN_DATE: 'Return date ',
        _SETTLEMENT_REVERSAL: 'Settlement reversal ',
        _REVERSAL_DATE: 'Reversal date ',
        _REASON_REVERSAL: 'Reason reversal ',
        _PAYMENT_NUMBER: 'Payment number ',
        _PAYMENT_SAVING_SUMMARY: 'Payment saving summary',
        _ACTIVATED: 'Activated',
        _SETTLEMENT_NUMBER: 'Settlement number ',
        _ISSUED_CORRECTLY: 'Issued correctly ',
        _RISK: 'Risk ',
        _POLICY_ID: 'Policy ID ',
        _CLAIM_EXCESS_POLICY_VALUE: 'Claim excess policy value',
        _CLAIM_CAP_AMOUNT: 'Claim cap amount ',
        _CLAIM_EXCESS_AMOUNT: 'Claim excess amount ',
        _POLICY_ID_END_DATE: 'Policy id end date ',
        _POLICY_ID_START_DATE: 'Policy id start date',
        _CLAIM_CAP_INDICATOR: 'Claim cap indicator ',
        _CLAIM_EXCESS_ADVANCE: 'Claim excess advance ',
        _CLAIM_EXCESS_RESIDUE: 'Claim excess residue ',
        _CLAIM_EXCESS_LIQUIDATOR_ADVANCE: 'Claim excess liquidator advance ',
        _CLAIM_EXCESS_AMOUNT_ADVANCE: 'Claim excess amount advance ',
        _CLAIM_CAP_AMOUNT_RESIDUE: 'Claim cap amount residue ',
        _FIRST_SIR_ADVANCE: 'First sir advance ',
        _AGGREGATE_CAP_INDICATOR: 'Aggregate cap indicator ',
        _EXCESS_AGGREGATE_POLICY_VALUE: 'Excess aggregate policy value ',
        _AGGREGATE_CAP_AMOUNT: 'Aggregate cap amount ',
        _EXCESS_AGGREGATE_AMOUNT: 'Excess aggregate amount ',
        _EXCESS_AGGREGATE_ADVANCE: 'Excess aggregate advance ',
        _EXCESS_AGGREGATE_RESIDUE: 'Excess aggregate residue ',
        _EXCESS_AGGREGATE_LIQUIDATOR_ADVANCE: 'Excess aggregate liquidator advance ',
        _AGGREGATE_CAP_AMOUNT_RESIDUE: 'Aggregate cap amount residue ',
        _EXCESS_AGGREGATE_AMOUNT_RESIDUE: 'Excess aggregate amount residue ',
        _SECOND_SIR_POLICY_VALUES: 'Second sir policy values ',
        _EXCESS_SECOND_SIR: 'Excess second sir ',
        _CLAIM_LIQUIDATOR_ADVANCE_SECOND_SIR: 'Claim liquidator advance second sir',
        _SECOND_SIR_RESIDUE: 'Second sir residue ',
        _CLAIM_DATA: 'Claim data ',
        _NOTIFY_DATE: 'Notify date ',
        _SEVERITY_CODE: 'Severity code ',
        _PHISICAL_INJURY_CODE: 'Phisical injury code ',
        _CASHED: 'Cashed',
        _COLLECTED: 'Collected',
        _FISCAL_TAX_REGIME: 'Fiscal tax regime',
        _GENERATE_PAYMENT: 'Generate payment',
        _INDEMNITY_COST: 'Indemnity cost',
        _LEGAL_CODE: 'Legal code',
        _NEW_INVOICE: 'New invoice',
        _PARTY_CODE: 'Party code',
        _PRESENCE_LAWYERS: 'Presence lawyers',
        _PROTOCOLLO_SAP: 'Sap protocl',
        _PROTOCOLLO_SDI: 'SDI Protocol',
        _RECEIPTS: 'Receipts',
        _RESISTANCE_COST: 'Resistance cost',
        _SETTLEMENT_COST: 'Settlement cost',
        _SETTLEMENTS: 'Settlements',
        _TOTAL_AMOUNT: 'Total amount',
        _PAYMENTS_FIND: 'Payments find',
        _OPENING_DUE_TO_WRIT_OF_SUMMONS: 'Neuanlage nach Anklage',
        _APPLY_PERCENTAGE_LIABILITY_FORCING: 'Apply percentage liability forcing',
        _DAMAGE_CARD: 'Damage card',
        _SEND_TO: 'Send to',
        _CURRENT_RESERVE: 'Current reserve',
        _NOTES: 'Notizen',
        _DIALOGUE_NOTES: 'Notizen',
        _COMPENSATIONS: 'Compensations',
        _MAGIC_STRING: 'Magic string',
        _INVOLVED_PARTIES__DAMAGE_CAUSE: 'Involved parties/Damage cause',
        _SETTLEMENT_COUNTERS: 'Settlement counters',
        _TYPE_OF_LOSS_COUNTERS: 'Type of loss counters',
        _TRUSTEE_DATA: 'Trustee data',
        _SUBJECT: 'Subject',
        _ROSTER_REGISTRATION_CODE: 'Roster registration code',
        _COURT_REGISTRATION_DATE: 'Court registration date',
        _TAX_OFFICE: 'Tax office',
        _ASSOCIATED_TRUSTEES: 'Associated trustees',
        _SUBJECT_IDENTITY: 'Partneridentifikation',
        _TYPE_PROVIDER: 'Type provider',
        _OFFICE_HEADQUARTERS: 'Office Headquarters',
        _COUNTRY: 'Country',
        _SEARCH_FILTERS: 'Search filters',
        _VAT_NUMBER: 'Vat number',
        _EXPERT_TYPE_PROVIDER: 'Expert Type Provider',
        _DATE_OF_INSERTION: 'Eingabedatum',
        _CANCELED: 'Canceled',
        _UPDATE_TYPE: 'Aktualisierungsart',
        _DATE: 'Datum',
        _TYPOLOGY: 'Typology',
        _CREATION_DATE: 'Creation date',
        _GROUP: 'Group',
        _BY_WORKLOAD: 'By Workload',
        _USERS_ASSIGNABLE_TASKS: 'Vom Benutzer zuweisbare Aufgaben',
        _ORIGIN: 'Origin',
        _LAST_UPDATE: 'Last update',
        _CANCELED_POLICY: 'Canceled policy',
        _EMISSION_DATE: 'Emission date',
        _POLICY_REPLACED_NUMBER: 'Policy replaced number',
        _POLICY_REPLACEMENT_NUMBER: 'Policy replacement number',
        _SUSPENSION_DATE_FROM: 'Suspension date from',
        _SUSPENSION_DATE_TO: 'Suspension date to',
        _PREMIUM_DATE_FROM: 'Premium date from',
        _PREMIUM_DATE_TO: 'Premium date to',
        _OUT_OF_RANGE: 'Out of range',
        _DIARIA_DAYS: 'Diaria days',
        _ARREARS_DAYS: 'Arrears days',
        _PRODUCT_CODE: 'Product code',
        _PRODUCT_DESCRIPTION: 'Product description',
        _DIARIA: 'Diaria',
        _LOCATION: 'Location',
        _TERM_CODE: 'Term code',
        _DAYS: 'Days',
        _SELECTABLE: 'Selectable',
        _POSTAL_CODE: 'Postal code',
        _POLICY_CONDITION: 'Policy condition',
        _POLICY_CEILING: 'Policy ceiling',
        _MINIMUM_UNCOVERED_VALUE: 'Minimum uncovered value',
        _SEARCH_RESULTS: 'Search results',
        _UPDATE_DATE: 'Update date',
        _EXCESS: 'Excess',
        _BRANCH: 'Branch',
        _SURNAME__BUSINESS_NAME: 'Surname/Business name',
        _PERSON_TYPE: 'Person type',
        _LEGAL_PERSON: 'Legal person',
        _EDIT_SUBJECT: 'Edit subject',
        _UPDATE_RESERVE: 'Update reserve',
        _REOPENING_DATA: 'Daten Erneut öffnen',
        _REOPENING_REASON: 'Grund für die Wiedereröffnung',
        _SETTLEMENT_COSTS: 'Settlement costs',
        _VIEW_FOR_ACTORS: 'View for Actors',
        _NEW_MANUAL_DEADLINE: 'New Manual Deadline',
        _QUOTA_AMOUNT: 'Betragshöhe',
        _AMOUNT_ACTIVE: 'Aktive Reserve',
        _AMOUNT_RESERVE_QUOTA: 'Anteil aktive Reserve',
        _OPENING_DATE: 'Neuanlagedatum',
        _CLOSING_DATE: 'Abschlussdatum',
        _REFUSED_DATE: 'Kündigungsdatum',
        _COMPENSATION_DIARY_N: 'Compensation Diary N.',
        _NEW_NOTE: 'New note',
        _EXTRA_INFO: 'Extra info',
        _NO_DATA: 'No data',
        _CONV_TYPE: 'Convention Type',
        _INVALID_PARAMETER_FORM: 'DE_Invalid parameter form',
        _NO_PARTIES: 'No parties',
        _SELECT_ACTOR_ROLE: 'Select actor role',
        _NEW_ACTOR_LIST: 'Actor list',
        _ASSETS_LIST: 'Assets list',
        _DAMAGED_ASSET: 'Damaged asset',
        _ADD_OTHER_ASSET: 'Add new damage',
        _ACTOR_ROLE: 'Actor role',
        _SOLD: 'Sold',
        _RISK_FACTORS: 'Risk Factors',
        _THING: 'Thing',
        _VEHICLE_ASSET: 'Vehicle Asset',
        _EVENT_RISK: 'Event risk',
        _EVENT: 'Event',
        _NO_REASON_MEETS_THE_SEARCH_CRITERIA: 'No reason meets the search criteria mettila in uppecase',
        _RESIDENCE: 'DE_Residenza',
        _DOMICILE: 'DE_Domicilio',
        _ADD_NEW_ADDRESS: 'DE_Aggiungi nuovo indirizzo',
        _DIALOGUE_DESCRIPTION: 'Dialogue Description',
        _INSURED_PLATE_NUMBER: 'Insured Plate Number',
        _COMPANY_CODE: 'Company Code',
        _INVOLVED_PARTY_IDENTIFIER: 'Involved Party Identifier',
        _INVOLVED_PARTY_KEY: 'Involved Party Key',
        _NUMBER_REQUEST: 'Number Request',
        _RESPONSE_IN: 'Response (In)',
        _REQUEST_OUT: 'Request (Out)',
        _REQUEST_IN: 'Request (In)',
        _RESPONSE_OUT: 'Response (Out)',
        _RESPONSE_INPUT: 'Response (Input)',
        _REQUEST_INPUT: 'Request (Input)',
        _REQUEST_OUTPUT: 'Request (Output)',
        _RESPONSE_OUTPUT: 'Response (Output)',
        _SIMILAR_DIALOGUES: 'Similar Dialogues',
        _CANCEL_MESSAGE: 'Canceled Successfully!',
        _CF_PIVA: 'CF/PIVA',
        _LOSS_DATE: 'DE Loss Date',
        _VERSION: 'DE Version',
         _CODE_MESSAGE: 'Codenachricht',
        _PAYMENT_DELETE_CHECK_QUESTION: 'Möchten Sie das folgende Element wirklich aus der Tabelle entfernen?',
        _ERROR_DURING_SAVING: 'Error while saving!',
        _NOT_CORRECT_ENTRY_DATA: 'The data entered are incorrect!',
        _WRONG_DATES: 'The dates entered are not valid!',
        _MANDATORY_FUNCTION_CODE: 'Mandatory function code!',
        _INCORRECT_AMOUNT: "The amount entered is not valid!",
        _ALREADY_EXISTS_RECORD: "A record with the same data already exists in the indicated time period",
        _NEW_FORFAIT : 'New forfait',
        _REVERSAL: 'DE_Reversal',
        _CANCELLATION_REVERSAL: 'DE_Cancellation/Reversal',
        _PAYMENTS: {
            _IDRECORDFLOW: 'Id record flow',
            _CODFUNCTION: 'Function Code',
            _CODE_COMPANY: 'Company',
            _LOTTO: 'Lot',
            _SEQUENCE: 'Sequence',
            _REGISTERTYPE: 'Register type',
            _CODMESSAGE: 'Message',
            _SENDINGFLOWDATE: 'Reception date',
            _YEAR: 'Year',
            _MONTH: 'Month',
            _OPERATIONCENTRE: 'Operations Center',
            _OPERATIONCENTRECNT: 'Counterparty Operations Center',
            _COMPANYCODEUS: 'Company code',
            _COMPANYCODECNT: 'Counterparty company code',
            _INTERNALKEY: 'Internal key',
            _EXTERNALKEY: 'External key',
            _LOSSDATE: 'Loss date',
            _ROLE: 'Role',
            _ERRORCODE: 'Error code',
            _ERROR: 'Error',
            _INTERNALKEYDLG: 'Internal dialogue key',
            _ROLEDLG: 'Dialogue role',
            _PAVEEDLG: 'Actor - Type of loss',
            _CLAIMNUMBERDLG: 'Left dialogue number',
            _COMPANYCTRDLG: 'Company counterpart dialogue',
            _LOSSDATEDLG: 'Loss dialogue date',
            _PLATECTRDLG: 'Dialogue counterpart plate',
            _SETTLEMENTNUMBER: 'Settlement number',
            _PLATEDLG: 'Dialogue plate',
            _IMPORTFORFAIT: 'Forfait amount',
            _PAYMENTTYPE: 'Payment type',
            _FORCED: 'Forced',
            _SKIPPED: 'Skip',
            _DINS: 'Insertion date',
            _UNIQUECODE: 'Unique code',
            _SETTLEMENTDATE: 'Settlement date',
            _PAYMENTCOLLECTION: 'Pay or recover',
            _PAY: 'Pay',
            _RECOVER: 'Recover',
            _TOTALRECORD: 'Total record',
            _TOTALRECORDKO: 'Total record KO',
            _TOTALRECORDOK: 'Total record OK',
            _TOTALAMOUNTPAYMENTS: 'Total amount payments',
            _TOTALAMOUNTRECOVERED: 'Total amount recovered'
        },
        _TYPE_LICENSE_PLATE_NUMBER_US: 'Type of License plate number us',
        _TYPE_LICENSE_PLATE_NUMBER_CTP: 'Type of License plate number ctp',
        _LST_MES_SEND_DATA: 'Send Date',
        _NO_DELEGATION: 'No Delegation',
        _OUR_DELEGATION: 'Our Delegation',
        _THIRD_PARTY_DELEGATION: 'Third party delegation'
    }
};

