import {RgiRxi18nModuleLoadType, RgiRxSerializedI18n, RgiRxTranslations} from '@rgi/rx/i18n';
import {IT} from './it';
import {EN} from './en';
import {DE} from "./de";
import {FR} from "./fr";
import {ES} from "./es";
import {CA_ES} from "./ca-ES";
import {EU_ES} from "./eu-ES";
import {GL_ES} from "./gl-ES";

export function RgiSharedComponentsI18nLoaderIT(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(IT));
}

export function RgiSharedComponentsI18nLoaderEN(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(EN));
}

export function RgiSharedComponentsI18nLoaderDE(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(DE));
}

export function RgiSharedComponentsI18nLoaderFR(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(FR));
}

export function RgiSharedComponentsI18nLoaderES(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(ES));
}

export function RgiSharedComponentsI18nLoaderCAES(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(CA_ES));
}

export function RgiSharedComponentsI18nLoaderEUES(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(EU_ES));
}

export function RgiSharedComponentsI18nLoaderGLES(): RgiRxi18nModuleLoadType {
  return new Promise<RgiRxSerializedI18n>((resolve) => resolve(GL_ES));
}

export const RGI_SHARED_COMPONENTS_TRANSLATIONS: RgiRxTranslations = [
  {
    load: RgiSharedComponentsI18nLoaderIT,
    locale: 'it'
  },
  {
    load: RgiSharedComponentsI18nLoaderEN,
    locale: 'en'
  },
  {
    load: RgiSharedComponentsI18nLoaderDE,
    locale: 'de'
  },
  {
    load: RgiSharedComponentsI18nLoaderFR,
    locale: 'fr'
  },
  {
    load: RgiSharedComponentsI18nLoaderES,
    locale: 'es'
  },
  {
    load: RgiSharedComponentsI18nLoaderEUES,
    locale: 'eu-ES'
  },
  {
    load: RgiSharedComponentsI18nLoaderCAES,
    locale: 'ca-ES'
  },
  {
    load: RgiSharedComponentsI18nLoaderGLES,
    locale: 'gl-ES'
  }
];
