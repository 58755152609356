import { LicTranslationsKeys } from './lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LicTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_IT: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: 'Polizza numero {{num}} emessa correttamente',
  lic_ProposalCorrectlyIssued: 'Proposta numero {{num}} emessa correttamente',
  lic_LifeProposalCorrectlyIssued: 'Proposta Vita numero {{num}} emessa correttamente',
  lic_LifePolicyCorrectlyIssued: 'Polizza Vita numero {{num}} emessa correttamente',
  lic_DamagesPolicyCorrectlyIssued: 'Polizza Danni numero {{num}} emessa correttamente',
  lic_AuthorizatioProposalForwarded: 'La richiesta di autorizzazione per la proposta numero {{num}} è stata inoltrata all\'utente competente',
  lic_ProposalCorrectlySaved: 'Proposta numero {{num}} salvata correttamente',
  lic_PreventiveCorrectlySaved: 'Preventivo numero {{num}} salvato correttamente',
  lic_benMorteNoAssic: 'Il soggetto selezionato come Beneficiario Morte è già stato censito come assicurato, selezionare un altro soggetto.',
  lic_benMorteNoRef: 'Il soggetto selezionato come Beneficiario Morte è già stato censito come referente terzo, selezionare un altro soggetto.',
  lic_benefPresente: 'Il soggetto selezionato come Beneficiario Morte è già stato selezionato, selezionare un altro soggetto.',
  lic_refTerzoNoAssic: 'Il soggfetto selezionato come Referente Terzo è già stato selezionato, selezionare un altro soggetto.',
  lic_refTerzoNoContr: 'Il soggetto selezionato come Referente Terzo è già stato selezionato come contraente, selezionare un altro soggetto.',
  lic_refTerzoPresente: 'Il soggetto selezionato come Referente Terzo è già stato selezionato come beneficiario morte, selezionare un altro soggetto.',
  lic_DelegateEqualToPolicyHolder: 'Delegato uguale al contraente ?',
  lic_LegalGuardianEqualToPolicyHolder: 'Tutore legale uguale al contraente ?',
  lic_ThirdPayerEqualToPolicyHolder: 'Terzo pagatore uguale al contraente ?',
  lic_DelegateEqualToPolicyHolderWarning: 'Soggetto inserito per ruolo Delegato uguale al contraente',
  lic_LegalGuardianEqualToPolicyHolderWarning: 'Soggetto inserito per ruolo Tutore legale uguale al contraente',
  lic_ThirdPayerEqualToPolicyHolderWarning: 'Soggetto inserito per ruolo Terzo pagatore uguale al contraente',
  lic_MasterPolicyHolder: 'Contraente polizza madre',
  lic_SubjectXalreadyselectedfortherole: 'Soggetto {{subject}} già selezionato per il ruolo',
  lic_InsuredAmount: 'Capitale Assicurato',
  lic_InvestedPremium: 'Premio Investito',
  lic_FirstInstalment: 'Premio Firma',
  lic_InsuredNotEqualHolder: 'Assicurato e Contraente devono essere diversi',
  lic_SaveEstimate: 'Salva Preventivo',
  lic_LifeQuote: 'Preventivo Vita',
  lic_PreventiveIssue: 'Emissione Preventivo',
  lic_ProposalIssue: 'Emissione Proposta',
  lic_PolicyIssue: 'Emissione Polizza',
  lic_appendixLabel: 'Appendice Beneficiario',
  lic_summary_save_and_accept: 'salva e accetta',
  lic_summary_save_accept_pol_issue: 'salva accetta ed emetti polizza',
  lic_summary_save_accept_prop_issue: 'salva accetta ed emetti proposta',
  lic_effectivebeneficiaryholder: 'Titolare effettivo del beneficiario',
  lic_LifePolicy: 'Polizza Vita',
  lic_DamagesPolicy: 'Polizza Danni',
  lic_view: 'Visualizza',
  lic_premium_detail: 'Dettaglio premio',
  lic_guarantee_summary: 'Riepilogo garanzie',
  lic_commissions: 'Provvigioni',
  lic_Employer: 'Datore di lavoro',
  lic_SpecificPIPdata: 'Dati specifici PIP',
  lic_prev_continue: 'Prosegui',
  lic_Transferdata: 'Dati trasferimento',
  lic_Selectatleastonecontribution: 'Selezionare almeno una contribuzione',
  lic_Globalpercentagemustbe100: 'La percentuale globale deve essere 100%',
  lic_Selectcontributionsmandatoryfields: 'Selezionare i campi obbligatori per le contribuzioni',
  lic_Contributiondata: 'Dati contribuzioni',
  lic_Parties: 'Soggetti',
  lic_Party: 'Soggetto',
  lic_Globalamountmustbemajorthan0: 'L\'importo totale deve essere maggiore di 0',
  lic_Everycontributionselectedmustbemajorthan0: 'Ogni contribuzione selezionata deve essere maggiore di 0',
  lic_error_mandatory_fields: 'I campi marcati in rosso sono obbligatori o contengono valori non corretti',
  lic_error_iban: 'Il campo iban non è corretto',
  lic_authorization_request: 'RICHIEDI AUTORIZZAZIONE',
  lic_selected_profiles_: 'Profili selezionati',
  lic_Disinvestmentsfunds: 'Fondi da disinvestire',
  lic_reg41_comunication: 'Nominativo a cui inviare le comunicazioni',
  lic_disinvestment_amount: 'Importo da disinvestire',
  lic_occurrenceNumber: 'Occorrenze',
  lic_TheoccurenceNumbermustbeavalue: 'Il valore delle Occorrenze deve essere maggiore o uguale a {{min}}',
  lic_Disinvestmentrange: 'Inserire un importo da disinvestire maggiore di {{min}} e minore o uguale a {{max}}',
  lic_Incompleteinvestmentforthefunds: 'Investimento incompleto, prego procedere a completare l\'investimento per i Fondi di investimento',
  lic_Incompleteinvestmentfortheprofiles: 'Investimento incompleto, prego procedere a completare l\'investimento per i Profili di investimento',
  lic_Settlementstoreinvest: 'Liquidazione da reinvestire',
  lic_Policynumber: 'Numero polizza',
  lic_Type: 'Tipo',
  lic_Beneficiary: 'Beneficiario',
  lic_State: 'Stato',
  lic_Net: 'Netto',
  lic_Nettoreinvest: 'Netto da reinvestire',
  lic_Reinvestmentmustbelargerthanquotation: 'Reinvestimento deve essere maggiore del premio.',
  lic_Mustselectatleastonesettlementtoreinvest: 'Obbligatorio selezionare almeno una liquidazione da reinvestire.',
  lic_Nettoreinvestmustbeequalorlessthannet: 'Netto da reinvestiere deve essere minore o uguale al netto.',
  lic_Nosettlementstoreinvestexist: 'Non esistono liquidazioni da reinvestire.',
  lic_Missingvalueonnettoreinvest: 'Valore mancante sul Netto da reinvestire',
  lic_information: 'Informazioni',
  lic_Percentage: 'Percentuale',
  lic_Durationin: 'Durata in ',
  lic_years: 'anni',
  lic_months: 'mesi',
  lic_days: 'giorni',
  lic_Complementary: '(Complementare)',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: 'Completare la selezione del metodo di pagamento per il Beneficiario Cedola',
  lic_Netavailable: 'Netto disponibile',
  lic_Proposal_Saved: 'Proposta salvata correttamente col numero ',
  lic_add_employer: 'Inserire datore di lavoro',
  lic_AvailableGuarantees: 'Garanzie Selezionabili',
  lic_error: 'Errore',
  lic_error_msg: 'Errore durante l\'emissione della polizza',
  lic_auth_error_msg: 'Errore durante l\'elaborazione della richiesta',
  lic_common_risk: 'Rischio comune',
  lic_assignment_holder: 'Ente Vincolatario',
  lic_Sustainability: 'Sostenibilità',
  CalculateAssetSustainability: 'Calcola Sostenibilità asset',
  CalculateFinantialparameters: 'Calcolo parametri finanziari',
  lic_ecoSustainabilityAmount: 'Importo Ecosostenibilità',
  lic_ecoSustainabilityPerc: '% Ecosostenibilità',
  lic_envSustainabilityAmount: 'Importo Sostenibilità Ambientale',
  lic_envSustainabilityPerc: '% Sostenibilità Ambientale',
  lic_paiAmount: 'Importo PAI',
  lic_paiPerc: '% PAI',
  lic_socialSustainabilityAmount: 'Importo Sostenibilità Sociale',
  lic_socialSustainabilityPerc: '% Sostenibilità Sociale',
  lic_SecondInsured: 'Secondo Assicurato',
  lic_Secondinsuredmustbeaphysicalperson: 'Il secondo assicurato deve essere una persona fisica',
  lic_Selectasecondinsured: 'Seleziona un secondo assicurato',
  lic_benefSevereDisability: 'Beneficiario affetto da disabilità grave',
  lic_NumeroProposta: 'Numero Proposta',
  lic_DescrizioneProdotto: 'Descrizione Prodotto',
  lic_DescrizioneRischio: 'Descrizione Rischio',
  lic_CapitaleAssicurato: 'Capitale Assicurato',
  lic_SELEZIONA: '-- SELEZIONA --',
  lic_Aderente: 'Aderente​',
  lic_PrNetto: '% Pr. Netto',
  lic_ImpAcq: 'Imp. Acq.',
  lic_profileInvestmentIncomplete: 'Investimento incompleto, prego completare l\'investimento sui profili',
  lic_fundsInvestmentIncomplete: 'Investimento incompleto, prego completare l\'investimento sui fondi',
  lic_Clauses: 'Clausole',
  lic_Policyholder: 'Contraente',
  lic_quote_type: 'Tipo preventivo',
  lic_quote_type_anonymous: 'Anonimo',
  lic_quote_type_registered: 'Anagrafato',
  lic_text_required: '*Testo Obbligatorio',
  lic_text_required_error: 'Testo delle clausole obbligatorio.',
  lic_close: 'chiudi',
  lic_save: 'salva',
  lic_NEXT: 'AVANTI',
  lic_policyData: 'Dati di polizza',
  lic_pipData: 'Dati PIP',
  lic_parties: 'Soggetti',
  lic_assets: 'Dati di bene',
  lic_quotation: 'Quotazione',
  lic_summary: 'Riepilogo',
  lic_issue: 'Emissione',
  lic_investments: 'Investimenti',
  lic_invest_value_range: 'Inserire un valore compreso tra {{min}} e {{max}}',
  lic_disinvestment_range: 'Inserire un valore di disinvestimento compreso tra {{min}} e {{max}}',
  lic_mandatory_threshold_perc: 'Fondo: {{value}} - Inserire soglia percentuale',
  lic_mandatory_one_fund: 'Selezionare almeno un fondo',
  lic_invest_factor_min_validation: '{{factor}} deve essere minore di {{amount}}',
  lic_invest_factor_max_validation: '{{factor}} deve essere maggiore di {{amount}}',
  lic_invest_factor_eq_validation: '{{factor}} deve essere {{amount}}',
  lic_calculate_asset_volatility: 'Calcola volatilità asset',
  lic_calculate_sustainability_asset: 'Calcola sostenibilità asset',
  lic_occurance_number_range: 'Il valore delle Occorrenze deve essere maggiore o uguale a {{min}}',
  lic_policy_holder: 'Contraente',
  lic_guarantee: 'Garanzie',
  lic_co_applicant_not_same_holder: 'Il Cointestatario non può essere uguale al contraente',
  lic_co_applicant_physical: 'Il Cointestatario deve essere una persona fisica',
  lic_co_applicant_mandatory: 'Il Cointestatario è obbligatorio',
  lic_co_applicant: 'Cointestatario',
  lic_insured_must_be_physical: 'L\'assicurato deve essere una persona fisica',
  lic_mandatory_product: 'Seleziona un prodotto',
  lic_mandatory_insured: 'Seleziona un assicurato',
  lic_mandatory_holder: 'Seleziona un contraente',
  lic_contribution_frequency: 'La frequenza delle contribuzioni {{frequency}} deve essere la stessa',
  lic_mandatory_benef: 'Beneficiario {{cathegory}} : Beneficiario è obbligatorio',
  lic_mandatory_appendix_benef: 'Beneficiario {{cathegory}}: Appendice beneficiario è obbligatorio',
  lic_add_benef: 'Beneficiario {{cathegory}}: Aggiungere un beneficiario da anagrafe',
  lic_add_benef_perc: 'Beneficiario {{cathegory}} {{nominative}}: Inserisci la percentuale',
  lic_benef_perc_range: 'Beneficiario {{cathegory}} {{nominative}}: La percentuale deve essere compresa tra 0 e 100',
  lic_benef_total_perc: 'Beneficiario {{cathegory}}: La somma delle percentuali deve essere 100',
  lic_guarantee_mandatory_data: 'Garanzia {{unit}}: Compilare tutti i campi obbligatori',
  lic_quotation_not_done: 'Quotazione non eseguita: selezionare il pulsante "Ricalcola" per aggiornare la quotazione',
  lic_quotation_modified_value_quote: 'I dati sono stati modificati: selezionare il pulsante "Ricalcola" per aggiornare la quotazione',
  lic_quotation_benef_custom_msg: 'Beneficiario {{cathegory}} - {{msg}}',
  lic_product: 'Prodotto',
  lic_managementNode: 'Nodo di Gestione',
  lic_branch: 'Filiale',
  lic_agreement: 'Convenzione',
  lic_effect_date: 'Data effetto',
  lic_exp_date: 'Data scadenza',
  lic_payment_frequency: 'Frazionamento',
  lic_means_of_payment: 'Modalità di addebito del premio',
  lic_unit_type: 'Tipo unit',
  lic_risk_type: 'Tipo rischio',
  lic_substate: 'Sottostato',
  lic_benefit: 'Prestazione',
  lic_decreasing_frequency: 'Frazionamento Decrescenza',
  lic_damages: 'Danni',
  lic_effective_holder: 'Titolari Effettivi',
  lic_insured_role: 'Assicurato',
  lic_third_contact_person: 'Referente Terzo',
  lic_quotation_benef_owner_perc_validation: '{{roleDescr}} {{name}}, del beneficiario {{benefName}} non rispetta la percentuale minima di {{minPerc}}',
  lic_subscriber: 'Sottoscrittore',
  lic_yes: 'si',
  lic_no: 'no',
  lic_new_insured: 'Nuovo Assicurato',
  lic_simple_select: 'Seleziona',
  lic_first_instalment: 'Premio Firma',
  lic_next_instalment_premium: 'Premio Rata Successiva',
  lic_payment_type: 'Tipo pagamento',
  lic_first_instalment_payment_type: 'Tipo pagamento prima rata',
  lic_annual_premium: 'Premio Annuo',
  lic_quote_button: 'Ricalcola',
  lic_quote_save: 'Calcola e salva',
  lic_purchasing_commission: 'Provvigioni d\'acquisto',
  lic_beneficiaries_label: 'Beneficiari',
  lic_general_data_label: 'Dati Generali',
  lic_contractual_option_not_allowed: 'Non sono previste opzioni contrattuali',
  lic_investment_value_label: 'Valore dell\'investimento',
  lic_investment_profile: 'Profili di investimento',
  lic_investment_funds: 'Fondi in cui investire',
  lic_calculate_finantial_parameters: 'Calcolo parametri finanziari',
  lic_confirm_button: 'Conferma',
  lic_cancel_button: 'Annulla',
  lic_save_button: 'Salva',
  lic_continue_button: 'Prosegui',
  lic_next_button: 'Avanti',
  lic_required: 'obbligatorio',
  lic_node: 'Nodo',
  lic_company: 'Compagnia',
  lic_product_type: 'Tipo Prodotto',
  lic_taxes_label: 'Imposte',
  lic_fees_label: 'Diritti',
  lic_frequency_interests: 'Interessi di Frazionamento',
  lic_frequency: 'Frazionamento',
  lic_accessories: 'Accessori',
  lic_taxable: 'Imponibile',
  lic_quotation_detail_modal: 'Dettaglio quotazione',
  lic_guarantees_overview_modal: 'Guarantees Overview',
  lic_gross_label: 'Lordo',
  lic_int_fract_label: 'Int. Fraz',
  lic_next_instalment_label: 'Rata Successiva',
  lic_pure_premium_label: 'Premio Puro',
  lic_extra_premium_label: 'Sovrappremio',
  lic_loadings_label: 'Caricamenti',
  lic_total_label: 'Totale',
  lic_beneficiary_perc_is_over: 'Percentuale totale maggiore di 100 sui ruoli collegati al {{benefRole}} {{benefCat}}',
  lic_questionSTR_invalid: 'la risposta alla domanda: {{question}}, risulta errata',
  lic_purchase_commissions: 'Provvigioni d\'acquisto',
  lic_PurchaseCommission: 'Provvigione d\'acquisto',
  lic_NetPrem: '% prmio netto',
  lic_sales_commissions: 'Provvigioni di vendita',
  lic_IBAN_formally_incorrect: 'L\'IBAN non è formalmente valido',
  lic_holder_physical_person_req: 'Il contraente deve essere una persona fisica',
  lic_holder_legal_person_req: 'Il contraente deve essere una persona giuridica',
  lic_available_covers: 'Garanzie Selezionabili',
  lic_covers_overview: 'Riepilogo Garanzie',
  lic_covers: 'Garanzie',
  lic_cover: 'Garanzia',
  lic_config_profile_warning: 'WARNING: Configuration profiles has changed.',
  lic_select: 'Seleziona',
  lic_Irrevocable_beneficiary: 'Beneficiario irrevocabile',
  lic_Base: '(Base)',
  lic_Accessory: '(Accessoria)',
  lic_investment_value: 'Valore dell\'investimento',
  lic_volatility: 'Volatilità',
  lic_name: 'Nome',
  lic_to_be_invested: 'Da investire',
  lic_info_investment_completed: 'Informazioni: Investimento completo',
  lic_fund: 'Fondo',
  lic_max_percentage: 'percentuale massima ammessa',
  lic_min_percentage: 'percentuale minima ammessa',
  lic_selected_funds: 'Fondi selezionati',
  lic_allocation_exceeds: 'L\'investimento inserito supera l\'importo disponibile di',
  lic_percentage_threshold: '% Soglia',
  lic_fund_distribution_identical: 'Distribuzione fondi identica al versamento iniziale',
  lic_fund_not_available: 'Non sono presenti fondi su cui è attivabile l\'opzione',
  lic_details: 'Dettagli',
  lic_starting_fund: 'Starting fund',
  lic_fund_category: 'Profilo / Fondo',
  lic_equivalent: 'Controvalore alla Data',
  lic_policy_address: 'Recapito di Polizza',
  lic_policy_master_details: 'Dettaglio Polizza Madre',
  lic_administrative_data: 'Dati amministrativi',
  lic_ordinary_mail: 'Mail Ordinaria',
  lic_threshold: 'Soglia',
  lic_payment_method: 'Tipo pagamento rate successive',
  lic_additional_payment: 'Versamenti programmati',
  lic_contractual_options: 'Opzioni Contrattuali',
  lic_elements: 'Elementi',
  lic_position_number: 'Raggruppamento',
  lic_questionnaires_section: 'Questionari',
  lic_fees: 'Diritti',
  lic__1TERM: 'Primo termine',
  lic__EFFOP: 'Data effetto',
  lic__OPZFR: 'Frazionamento',
  lic__OPZPM: 'Pagamento',
  lic_expirationDate: 'Data scadenza',
  lic__OPBEN: 'Beneficiario',
  lic__MINTS: 'Soglia minima',
  lic__MAXTS: 'Soglia massima',
  lic__NUMOC: 'Occurrenze',
  lic__AMTOP: 'Importo riscatto',
  lic__MALMT: 'Importo Minimo',
  lic_amount: 'Importo',
  lic_currency: 'Currency',
  lic__CIBAN: 'Iban',
  lic_fixedFees: 'Fixed Fees',
  lic__PCOUP: 'Percentuale Cedola',
  lic_holder: 'Titolare',
  lic_ACTIVE_STATE: 'ATTIVO',
  lic_MANDATORY_STATE: 'OBBLIGATORIO',
  lic_DEACTIVATE_STATE: 'DISATTIVATO',
  lic_premium_label: 'Premio',
  lic_holders_title: 'Intestatari',
  lic_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lic_invalid_total_position_percentage: 'La percentuale totale per il raggruppamento \"{{groupType}}\" sul Beneficiario {{cat}} deve essere il 100%',
  lic_beneficiary_position_mandatory: 'Per il Beneficiario {{benefCat}} {{benefName}} indicare un \"Raggruppamento\"',
  lic_role_is_mandatory: '{{role}} obbligatorio',
  lic_effective_holder_duplicated: 'Titolare effettivo duplicato',
  lic_beneficiary_perc_req_range: ' {{benefRole}} {{benefCat}} {{benefName}}: La percentuale deve essere compresa tra 0.01 e 100',
  lic_beneficiary_perc_req: ' {{benefRole}} {{benefCat}} {{benefName}}: Inserisci la percentuale',
  lic_beneficiary_from_party_req: ' {{benefRole}} {{benefCat}}: Aggiungere un beneficiario da anagrafe',
  lic_effective_holder_perc: 'La percentuale del titolare effettivo {{linkedName}}, del beneficiario {{benefName}}, non rispetta la percentuale minima di {{linkedPerc}}%',
  lic_effective_holder_perc_req: '{{linkedName}} titolare effettivo di {{benefName}}: Inserisci la percentuale',
  lic_or_junction: 'o',
  lic_backdating_not_allowed: 'Non è consentito retrodatare una proposta precedentemente salvata',
  lic_print_docs: 'Documenti output',
};
export default LIC_IT ;
