import { OnInit, Output, Optional, Inject, Component, EventEmitter } from "@angular/core";
import { OnModalClose, DIALOG_DATA } from "@rgi/rx/ui";
import { InstallmentWarrantyObj } from "../../../../models/life-detail.model";
import { LpcDataTableHeadersObj } from "../../../../models/data-table.model";
import { TranslationWrapperService } from "../../../../i18n/translation-wrapper.service";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { CurrencyCacheService, LpcCurrencyCache } from "../../../../services/currency-cache.service";

@Component({
    selector: 'lpc-layer-view-modal',
    templateUrl: './lpc-layer-view-modal.component.html',
    styleUrls: ['./lpc-layer-view-modal.component.css'],
    animations: [
        trigger('fadeAnimation', [
          state('void', style({ opacity: 0 })),
          transition(':enter', animate(200)),
          transition(':leave', animate(200))
        ]),
      ]
  })

export class LpcLayerViewModalComponent implements OnInit, OnModalClose {

    @Output() modalClose = new EventEmitter();
    data: InstallmentWarrantyObj;
    public currencyFormatterOptions: Intl.NumberFormatOptions = {
      style: 'currency'
    };
    
    headers: LpcDataTableHeadersObj[] = [
        { title: this.translate.getImmediate('lpc_Guarantee'), field: 'description'},
        { title: this.translate.getImmediate('lpc_premium_detail'), field: 'purePremium'},
        { title: this.translate.getImmediate('lpc_Loading'), field: 'fees', toolTip: true },
        { title: this.translate.getImmediate('lpc_extra_premium'), field: 'extraPremium', toolTip: true },
        { title: this.translate.getImmediate('lpc_net'), field: 'net'},
        { title: this.translate.getImmediate('Int. Fraz'), field: 'installmentInterest'},
        { title: this.translate.getImmediate('lpc_fixedCharges'), field: 'fixedCharges'},
        { title: this.translate.getImmediate('lpc_Taxes'), field: 'taxes'},
        { title: this.translate.getImmediate('lpc_grosspremium'), field: 'gross'}
      ];
  
    constructor(
      @Optional() @Inject(DIALOG_DATA) data: InstallmentWarrantyObj,
      protected translate: TranslationWrapperService,
      @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.data = data;
      this.currencyFormatterOptions.currency = currencyService.currency;
    }
  
    ngOnInit() {
    }
  
}
  