export const STEPS: StepperConfiguration = {
    SOGGETTI: {
        pos: 0,
        component: 'lifeRolePageComponent',
        errorId: 'lifeRole',
        route: 'start'
    },
    DATI_AMMINISTRATIVI: {
        pos: 1,
        component: 'datiAmmComponent',
        errorId: 'datiAmm',
        route: 'factors'
    },
    PIP: {
        pos: 2,
        component: 'pipDataComponent',
        errorId: 'pip',
        route: 'pip'
    },
    DATI_DI_BENE: {
        pos: 3,
        component: 'goodsInfoComponent',
        errorId: 'goodsInfo',
        route: 'goodsInfo'
    },
    QUOTAZIONE: {
        pos: 4,
        component: 'licQuotationComponent',
        errorId: 'quotation',
        route: 'quotation'
    },
    INVESTIMENTI: {
        pos: 5,
        component: 'investmentsComponent',
        errorId: 'investments',
        route: 'investments'
    },
    SOMMARIO: {
        pos: 6,
        component: 'summaryComponent',
        errorId: 'summary',
        route: 'summary'
    },
    ISSUE: {
        pos: 7,
        component: 'issueComponent',
        errorId: 'issue',
        route: 'issue'
    }
};

export interface StepperConfiguration {
   /**
    * @stepField pos `0`
    * @stepField component `'lifeRolePageComponent'`
    * @stepField errorId `'lifeRole'`
    * @stepField route `'start'`
    */
    SOGGETTI: StepConfiguration;
   /**
    * @stepField pos `1`
    * @stepField component `'datiAmmComponent'`
    * @stepField errorId `'datiAmm'`
    * @stepField route `'factors'`
    */
    DATI_AMMINISTRATIVI: StepConfiguration;
   /**
    * @stepField pos `2`
    * @stepField component `'pipDataComponent'`
    * @stepField errorId `'pip'`
    * @stepField route `'pip'`
    */
    PIP: StepConfiguration;
   /**
    * @stepField pos `3`
    * @stepField component `'goodsInfoComponent'`
    * @stepField errorId `'goodsInfo'`
    * @stepField route `'goodsInfo'`
    */
    DATI_DI_BENE: StepConfiguration;
   /**
    * @stepField pos `4`
    * @stepField component `'licQuotationComponent'`
    * @stepField errorId `'quotation'`
    * @stepField route `'quotation'`
    */
    QUOTAZIONE: StepConfiguration;
   /**
    * @stepField pos `5`
    * @stepField component `'investmentsComponent'`
    * @stepField errorId `'summary'`
    * @stepField route `'summary'`
    */
    INVESTIMENTI: StepConfiguration;
   /**
    * @stepField pos `6`
    * @stepField component `'summaryComponent'`
    * @stepField errorId `'summary'`
    * @stepField route `'summary'`
    */
    SOMMARIO: StepConfiguration;
   /**
    * @stepField pos `7`
    * @stepField component `'issueComponent'`
    * @stepField errorId `'issue'`
    * @stepField route `'issue'`
    */
    ISSUE: StepConfiguration;
}

export interface StepConfiguration {
    pos: number;
    component: string;
    errorId: string;
    route: string;
}

export const PROFILES_INVESTMENT_CONTROL = 'profiles';
export const FUNDS_INVESTMENT_CONTROL = 'funds';
export const TOOLS_INVESTMENT_CONTROL = 'tools';
export const SCHEDULED_PREMIUM_CONTROL = 'scheduledPremium';
export const TOTAL_INVESTMENT_AMOUNT = 1;
export type InvestmentContext = typeof PROFILES_INVESTMENT_CONTROL | typeof FUNDS_INVESTMENT_CONTROL;

export const INVEST_STEPS = {
  PROFILES: {
    context: PROFILES_INVESTMENT_CONTROL,
    formName: PROFILES_INVESTMENT_CONTROL
  },
  FUNDS: {
    context: FUNDS_INVESTMENT_CONTROL,
    formName: FUNDS_INVESTMENT_CONTROL
  },
  SCHEDULED_PREMIUM: {
    context: SCHEDULED_PREMIUM_CONTROL,
    formName: SCHEDULED_PREMIUM_CONTROL
  },
  TOOLS: {
    context: TOOLS_INVESTMENT_CONTROL,
    formName: TOOLS_INVESTMENT_CONTROL
  }
};

export const INJECT_TOKEN = {
  NEW_OUTBOUND_DOC_HANDLER: 'HandleNewOutboundDocuments',
}
