import { Component, EventEmitter, Inject, Injector, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { of, Subscription, Observable, BehaviorSubject} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OperationCode, SaveActions, SystemProperties } from '../../enum/life-issue.enum';
import { DocumentEvent, DocumentList, _DOCUMENT_ } from '../../models/document.model';
import { ActionsService } from '../../services/actions.service';
import { DocumentService } from '../../services/document.service';
import { LifeSessionService } from '../../services/life-session-service';
import { PolicyService } from '../../services/policy-service';
import { SystemPropertiesService } from '../../services/system-properties.service';
import { CardsNavigationService } from './../../cards-navigation/cards-navigation.service';
import { ContractData, LifeSavePolicyResponse } from './../../models/policy.model';
import { LicIssueModalErrorComponent } from './issue-modal-error/issue-modal-error.component';
import { LifeRoleService } from '../../services/life-role.service';
import { ValidationSubjectsService } from './../../services/validation-subjects.service';
import { LayeredRuleService } from '../../services/layered-rule.service';
import { INJECT_TOKEN } from '../lic-consts';
import { DocumentsService, OutboundDocumentsService } from '@rgi/shared-components';

@Component({
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.css']
})
export class IssueComponent implements OnInit, OnDestroy {
  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();
  protected $subscriptions: Subscription[] = [];

  public policyNumber: ContractData[] = null;
  public isFromAuth: boolean;
  public buttonLabel: string;

  public label = '';
  public damageLabel = '';
  public type = null;

  documentsCompleted = true;
  public documentList: DocumentList[] = [];
  public onInitEnd: boolean;
  public titleOfIssue: string;

  public NOTIFY_ON_CLOSE_AFTER_PUBLISH = 'on-close-after-publish';
  public NOTIFY_CLOSE_BUY_QUOTE = 'on-close-buy-quote';

  public modalError = 'lic_error_msg';
  public isError = false;

  contractId = '';

  stillGeneratingDocs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pdDocCommonList = [];

  constructor(@Inject('$injector') protected injector: Injector,
              protected policyService: PolicyService,
              protected cardsNavigationService: CardsNavigationService,
              protected actionsService: ActionsService,
              protected lifeSessionService: LifeSessionService,
              protected lifeRoleService: LifeRoleService,
              protected validationSubjectsService: ValidationSubjectsService,
              protected layeredRuleService: LayeredRuleService,
              protected documentService: DocumentService,
              protected translate: TranslationWrapperService,
              protected notifierService: NotifierService,
              protected systemPropertiesService: SystemPropertiesService,
              protected modalService: NgbModal,
              @Optional() protected questCacheManager: QuestionnaireCacheService,
              @Optional() @Inject(INJECT_TOKEN.NEW_OUTBOUND_DOC_HANDLER) public newOutboundHandler: boolean,
              protected rgiDocumentService: DocumentsService,
              protected outBoundDocumentService: OutboundDocumentsService) { }

  ngOnInit(): void {
    this.onInitEnd = false;
    this.isFromAuth = this.policyService.isFromAuth;

    this.policyNumber = [new ContractData(null, null)];
    if (this.policyService.mainProposal.proposal.contractData !== null &&
      this.policyService.mainProposal.proposal.contractData.length > 0) {
      this.policyNumber = this.policyService.mainProposal.proposal.contractData;
    }

    this.setFlowOptions();
    this.buttonLabel = (this.isFromAuth) ?
      this.translate.getImmediate('lic_summary_save_accept_pol_issue') :
      this.translate.getImmediate('lic_PolicyIssue');


    if (this.newOutboundHandler) {
      // async call made by purpose to make the call silent and not blocking the UI
      this.callTheSavingDocuments();
      this.getNewDocumentList();
    } else {
      this.getDocumentList();
    }

    this.contractId = this.getCorrectResourceId();
  }

  private getCorrectResourceId(): string {
    return this.policyNumber[0].contractId;
    // on authorization flow the resourceId is in the mainProposal under the quote
    /* const isAuthFlow = this.policyService.isSaveFromAuth || this.isFromAuth;
    return isAuthFlow ? this.policyService.mainProposal.quote.resourceId : this.policyService.mainProposalResourceId; */
  }

  protected callTheSavingDocuments() {
    this.$subscriptions.push(
      this.rgiDocumentService.saveOutputDocuments(this.policyNumber[0]?.proposalNumber, this.policyService.getMovementID())
       .pipe(
          catchError((err) => {
            this.stillGeneratingDocs.next(false);
            return of('Errore in generateOutputDocument');
          })
      ).subscribe(() => {
        this.stillGeneratingDocs.next(false);
      })
    );
  }

  protected getNewDocumentList() {
    this.eventPropagation.emit('startLoader');
    this.$subscriptions.push(
      this.outBoundDocumentService.getOutboundDocumentsDataSource$(
        this.policyNumber[0].proposalNumber,
        this.policyService.getMovementID()
      ).subscribe((documents) => {
        this.pdDocCommonList = documents;
        const vectorOfDoc = [];
        documents.forEach((doc) => {
          vectorOfDoc.push({
            nomeFile: doc.description,
            documentCode: doc.id
          });
        });

        this.documentList = vectorOfDoc;
        this.onInitEnd = true
        this.eventPropagation.emit('stopLoader');
      }, (err) => {
        console.error('ERRORE SERVIZIO DOCUMENTI');
        this.onInitEnd = true;
        this.eventPropagation.emit('stopLoader');
      })
    );
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public setFlowOptions() {
    this.type = null;

    switch (this.policyService.flowTypeOnConfirm) {
      case SaveActions.SAVE_ACCEPT_POLICY_ISSUE:
        this.type = this.isDamagesPresent() ? 'saveAcceptAndPolIssueWithDamage' : 'saveAcceptAndPolIssue';
        break;
      case SaveActions.SAVE_ACCEPT_PROP_ISSUE:
        this.type = this.isDamagesPresent() ? 'saveAcceptAndPropIssueWithDamage' : 'saveAcceptAndPropIssue';
        break;
      case SaveActions.SAVE_ACCEPT_PROP_ISSUE_CAC:
        this.type = SaveActions.SAVE_ACCEPT_PROP_ISSUE_CAC;
        break;
      case SaveActions.SAVE_FROM_AUTH:
        this.type = 'modifyFromAuth';
        break;
      case SaveActions.SAVE_AUTH:
        this.type = 'authoRequest';
        break;
      case SaveActions.SAVE_CONTEXTUAL:
        this.type = this.isDamagesPresent() ? 'lifeAndDamagePolicy' : 'lic_LifePolicy';
        break;
      case SaveActions.SAVE_PROPOSAL:
        this.type = this.isDamagesPresent() ? 'lifeAndDamageProposal' : 'lifeProposal';
        break;
      case SaveActions.SAVE_PROPOSAL_CAC:
        this.type = SaveActions.SAVE_PROPOSAL_CAC;
        break;
      case SaveActions.SAVE_PREVENTIVE:
        this.type = 'preventiveFlow'; // comprende anche isFromQuoteModification
        break;
      case SaveActions.SAVE_ACCEPT:
        this.type = SaveActions.SAVE_ACCEPT;
        break;
    }

    this.setLabelForTitle();
    this.setLabelForConfirmMessage();
  }

  public setLabelForTitle() {
    const isPolicy = this.policyNumber[0].policyNumber != null;
    if (this.policyService.isFromPreventive || this.policyService.isFromQuoteModification) {
      this.titleOfIssue = this.translate.getImmediate('lic_PreventiveIssue');
    } else if (isPolicy) {
      this.titleOfIssue = this.translate.getImmediate('lic_PolicyIssue');
    } else {
      this.titleOfIssue = this.translate.getImmediate('lic_ProposalIssue');
    }
  }

  public setLabelForConfirmMessage() {
    const policyN = this.policyNumber[0].policyNumber;
    const proposalN = this.policyNumber[0].proposalNumber;

    switch (this.type) {
      case 'lic_LifePolicy': {
        this.label = this.translate.getImmediate('lic_PolicyCorrectlyIssued', { num: policyN });
        this.notifierService.notifyComponent('LIFE_ISSUE_OK');
        break;
      }
      case SaveActions.SAVE_ACCEPT_PROP_ISSUE_CAC:
      case SaveActions.SAVE_PROPOSAL_CAC:
      case 'lifeProposal': {
        this.label = this.translate.getImmediate('lic_ProposalCorrectlyIssued', { num: proposalN });
        this.notifierService.notifyComponent('LIFE_ISSUE_PROPOSAL_OK');
        break;
      }
      case 'lifeAndDamageProposal': {
        this.label = this.translate.getImmediate('lic_LifeProposalCorrectlyIssued', { num: proposalN });
        this.notifierService.notifyComponent('LIFE_ISSUE_PROPOSAL_OK');
        break;
      }
      case 'lifeAndDamagePolicy': {
        this.label = this.translate.getImmediate('lic_LifePolicyCorrectlyIssued', { num: policyN });
        this.notifierService.notifyComponent('LIFE_ISSUE_OK');
        break;
      }
      case 'saveAccept':
      case 'modifyFromAuth': {
        this.label = this.translate.getImmediate('lic_ProposalCorrectlySaved', { num: proposalN });
        this.notifierService.notifyComponent('LIFE_ISSUE_MODIFY_AUTH_OK');
        break;
      }
      case 'authoRequest': {
        this.label = this.translate.getImmediate('lic_AuthorizatioProposalForwarded', { num: proposalN });
        this.notifierService.notifyComponent('LIFE_ISSUE_AUTH_REQUEST_OK');
        break;
      }
      case 'preventiveFlow': {
        this.label = this.translate.getImmediate('lic_PreventiveCorrectlySaved', { num: proposalN });
        this.notifierService.notifyComponent('LIFE_PREVENTIVE_OK');
        break;
      }
      case 'saveAcceptAndPropIssue': {
        this.label = this.translate.getImmediate('lic_ProposalCorrectlyIssued', { num: proposalN });
        this.notifierService.notifyComponent('LIFE_ISSUE_MODIFY_AUTH_OK');
        break;
      }
      case 'saveAcceptAndPolIssue': {
        this.label = this.translate.getImmediate('lic_PolicyCorrectlyIssued', { num: policyN });
        this.notifierService.notifyComponent('LIFE_ISSUE_MODIFY_AUTH_OK');
        break;
      }
      case 'saveAcceptAndPropIssueWithDamage': {
        this.label = this.translate.getImmediate('lic_LifeProposalCorrectlyIssued', { num: proposalN });
        this.notifierService.notifyComponent('LIFE_ISSUE_MODIFY_AUTH_OK');
        break;
      }
      case 'saveAcceptAndPolIssueWithDamage': {
        this.label = this.translate.getImmediate('lic_LifePolicyCorrectlyIssued', { num: policyN });
        this.notifierService.notifyComponent('LIFE_ISSUE_MODIFY_AUTH_OK');
        break;
      }
    }

    // LABEL DANNI - compare se è valorizzato il numero di polizza danni
    if (this.isDamagesPresent() && !!this.policyNumber[1]) {
      this.damageLabel = this.translate.getImmediate('lic_DamagesPolicyCorrectlyIssued', { num: this.policyNumber[1].policyNumber });
    }

  }

  public onSubmit() {
    this.eventPropagation.emit('startLoader');
    const saveAcceptIssue = this.systemPropertiesService.getStoredSystemProp(SystemProperties.ACCEPT_ISSUE_PROP_IN_AUTH);
    if (this.policyService.isSaveFromAuth || this.isFromAuth && saveAcceptIssue) {
      this.$subscriptions.push(
        this.getCheckRole().pipe(
          switchMap(res => {
            return !!res.checkMsg ? of(res.checkMsg) : this.policyService.lifeAuthsavePolicy(
              this.policyNumber[0].contractId,
              this.lifeSessionService.getOperatorUsername()
            );
          }),
          catchError((err) => {
            return of('Errore in lifeAuthsavePolicy');
          })
        ).subscribe((response: LifeSavePolicyResponse) => {
          this.eventPropagation.emit('stopLoader');
          if (typeof response === 'string') {
            this.isError = true;
            this.openErrorModal(response);
          } else {
            // this.policyService.mainProposal = response.result;
            const policyNumber = !!response.policyNumber ? response.policyNumber : response.lifePolicyNumber;
            const contractData = new ContractData(response.proposalNumber, policyNumber);
            this.policyNumber[0] = contractData;
            this.policyNumber[0].contractId = response.lifePolicyId;
            this.policyService.mainProposal.proposal.contractData = this.policyNumber;
            this.policyService.setMovementID(response.lifeMovementId);
            this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
            this.policyService.flowTypeOnConfirm = SaveActions.SAVE_ACCEPT_POLICY_ISSUE;
            this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
            this.notifierService.notifyComponent(this.NOTIFY_ON_CLOSE_AFTER_PUBLISH);
          }
        }, err => { this.isError = true; this.openErrorModal(err.message); }
        ));
    } else {
      this.$subscriptions.push(
        this.getCheckRole().pipe(
          switchMap(res => {
            return !!res.checkMsg ? of(res.checkMsg) : this.policyService.saveFromQuote(true, this.questCacheManager);
          }),
          catchError((err) => {
            return of('Errore in saveFromQuote');
          })
        ).subscribe((response) => {
          this.eventPropagation.emit('stopLoader');
          if (typeof response === 'string') {
            this.isError = true;
            this.openErrorModal(response);
          } else {
            this.policyService.mainProposal = response.result;
            this.policyNumber = response.result.proposal.contractData.policyNumber;
            this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.ISSUE.route);
            this.policyService.flowTypeOnConfirm = SaveActions.SAVE_CONTEXTUAL;
            this.navigation.emit(this.cardsNavigationService.STEP.ISSUE.route);
            this.notifierService.notifyComponent(this.NOTIFY_ON_CLOSE_AFTER_PUBLISH);
          }
        }, err => { this.isError = true; this.openErrorModal(err.message); }
        ));
    }
  }

  protected openErrorModal(error: string = null) {
    const dialog = this.modalService.open(LicIssueModalErrorComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    dialog.componentInstance.title = 'lic_error';
    dialog.componentInstance.message =  error ? error : this.modalError;
  }

  public isDamagesPresent(): boolean {
    if (this.policyNumber[1] != null &&
      (this.policyNumber[1].policyNumber != null ||
        this.policyNumber[1].proposalNumber != null)) {
      return true;
    }
    return false;
  }

  public propagate(event) {
    this.eventPropagation.emit(event);
  }

  public getDocumentList() {
    const printMode = this.getFromFlowType();
    const  operationCode = this.getOperationCodeForDoc();
    this.eventPropagation.emit('startLoader');
    this.$subscriptions.push(this.documentService.getDocumentList(
      this.policyNumber[0].contractId,
      operationCode,
      [],
      this.lifeSessionService.getOperatorUsername(),
      printMode
    ).subscribe((response: any) => {
      const vectorOfDoc = [];
      response.documents.forEach((element: any) => {
        vectorOfDoc.push({
          nomeFile: element.documentName,
          documentCode: element.documentCode,
          idDocument: element.idDocument
        });
      });

      this.documentList = vectorOfDoc;
      this.onInitEnd = true;
      this.eventPropagation.emit('stopLoader');
    }, (err) => {
      console.error('ERRORE SERVIZIO DOCUMENTI');
      this.onInitEnd = true;
      this.eventPropagation.emit('stopLoader');
    }));
  }

  get areThereDocuments(): boolean {
    return this.documentList.length > 0;
  }

  public onClose() {
    const session = this.getDetailSession();
    let lifeContract;
    if (this.policyService.isFromConsultation) {
      if (!!session) {
        lifeContract = this.injector.get('coreResult').getResult(session, 'detail-life');
        if (!!lifeContract && !lifeContract.policyNumber) {
          lifeContract.policyNumber = this.policyNumber[0].policyNumber;
        }
      }
      this.notifierService.notifyComponent(this.NOTIFY_ON_CLOSE_AFTER_PUBLISH);
    } else if (this.policyService.isFromBuyQuote || this.policyService.isFromQuoteModification) {
      this.notifierService.notifyComponent(this.NOTIFY_CLOSE_BUY_QUOTE);
    }
    this.eventPropagation.emit('exit');
  }

  protected getFromFlowType() {
    let printMode;
    if (this.policyService.flowTypeOnConfirm === SaveActions.SAVE_AUTH) {
      printMode = 1; // flusso di salva autorizzazione
    } else if (this.isFromAuth) {
      // se la systemProperty 'AcceptIssueProposalInAuthModif' è true , rientra nella casistica printMode = 4 (come salva e accetta)
      if (!!this.systemPropertiesService.getStoredSystemProp(SystemProperties.ACCEPT_ISSUE_PROP_IN_AUTH)) {
        printMode = 4;
      } else {
        printMode = 3;
      }
    } else if (this.policyService.isSaveFromAuth) {
      printMode = 4;
    } else {
      printMode = 2;
    }
    return printMode;
  }

  protected getOperationCodeForDoc() {
    const isContextualPolicy = !!this.systemPropertiesService.getStoredSystemProp(SystemProperties.LIFE_CONTEXTUAL_POL_PROP);
    if (this.isProposalContext() && !isContextualPolicy) {
      return OperationCode.EMETTI_PROPOSTA_VITA;
    }
    return this.policyService.mainProposal.quote.product.operationCode;
  }

  private isProposalContext() : boolean {
    return this.policyService.flowTypeOnConfirm === SaveActions.SAVE_PROPOSAL ||
      this.policyService.flowTypeOnConfirm === SaveActions.SAVE_AUTH ||
      this.policyService.flowTypeOnConfirm === SaveActions.SAVE_FROM_AUTH
  }

  private getDetailSession() {
    const session = this.injector.get('sessionService').list().find(ss => ss.name === 'ptfallFinder');
    return !!session ? session.id : null;
  }

  downloadDocument(docEvent: DocumentEvent) {

    if (this.newOutboundHandler) {
      this.stillGeneratingDocs.asObservable().subscribe((stillGenerating) => {
        if (!stillGenerating) {
          const document = this.pdDocCommonList.find(d => d.id === docEvent.document.documentCode);
          this.rgiDocumentService.downloadDocument$(document.id, document.description, document.file, true, this.contractId).subscribe()
        }
      });
    } else {
      this.oldDownloadSystem(docEvent);
    }

  }

  protected oldDownloadSystem(docEvent: DocumentEvent) {
    let fileUrl = '';
    const movementId = this.policyService.getMovementID();
    this.documentService.downloadDocument(movementId, docEvent.document.documentCode).pipe(
      switchMap(resp => {
        if (!resp) {
          return of(null);
        }
        try {
          if (!!this.injector.get('fileDownload')) {
            const apiConf = this.injector.get('API_CONF');
            const coreConf = this.injector.get('CORE_CONF');
            // "/api",  "/rest",  "/system",   resp.fileUrl
            fileUrl = resp.fileUrl;
            this.injector.get('fileDownload').getFile(apiConf.server, apiConf.base_url, coreConf.system_area, `${resp.fileUrl}`);
            return of(null);
          }
        } catch (error) {
          console.error('KO injection');
          return of({error: 'KO_injection', fileUrl: resp.fileUrl});
        }
      }),
      switchMap((firstTryKo) => {
        if (firstTryKo && firstTryKo.error === 'KO_injection') {
          return this.documentService.callDocument(firstTryKo.fileUrl);
        }
        return of(null);
      })
    ).subscribe(arraybuffer => {
      if (!!arraybuffer) {
        const contentType = this.documentService.getDocumentContentTypeByURL(fileUrl);
        const fileName = fileUrl.split('/').pop();
        const extension = fileName.split('.').pop();
        const windowTarget = '_blank';
        const windowFeatures = 'name=' + fileName;
        const blob = new Blob([arraybuffer], {type: contentType});
        const file = new File([blob], fileName, {type: contentType});
        const URL = window.URL;
        const downloadUrl = URL.createObjectURL(file);
        if (extension === _DOCUMENT_.EXTENSION_PDF || extension === _DOCUMENT_.EXTENSION_HTML) {
            window.open(downloadUrl, windowTarget, windowFeatures);
        } else {
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
        URL.revokeObjectURL(downloadUrl);
      }
    });
  }

  startDocuments(event: any) {
    /* if (event) {
      this.documentsCompleted = event.completed;
    } */
  }

  verifyDocuments(event: any) {
    /* if (event) {
      // this.enableSaveProposal = true;
      // this.uploadDocumentsChanged = true;
      this.documentsCompleted = event.completed;
    } */
  }

 private getCheckRole(): Observable<{checkMsg: string }> {

  if (!this.layeredRuleService.isRequiredChekRolesIssuePolicy(this.policyService.currentContext)) {
    return of({checkMsg: ''});
  }

  return this.validationSubjectsService.checkRole(
    this.lifeRoleService.getPolicyHolder(), 0,
    this.lifeRoleService.getPolicyRolesFilled(this.validationSubjectsService), this.policyService.isFromPreventive
    ).pipe(
      map((res: {errorMessages: {errorDescription: string}[]}) => {
        if (!!res.errorMessages && !!res.errorMessages.length) {
          return {
              checkMsg: res.errorMessages.filter(m => m.errorDescription)
                        .map(m => m.errorDescription)
                        .reduce((msg, m) => m += msg, ' ').replace(/,/g, ' ').trim()
          };
        } else {
            return {checkMsg: ''};
        }
      })
    );

 }

}
