import { AfterViewChecked, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LpcInquiryInDocumentComponent, PV_TOKEN } from '@rgi/life-postsales-card';
import { DocumentsComponent, DocumentsService } from '@rgi/shared-components';
import { Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';

@Component({
  selector: 'iiab-inquiry-in-document-ext',
  templateUrl: './lpc-inquiry-in-document-ext.component.html',
  styleUrls: ['./lpc-inquiry-in-document-ext.component.css']
})
export class LpcInquiryInDocumentExtComponent extends LpcInquiryInDocumentComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('inputDocuments') inputDocuments: DocumentsComponent;

  /** documents
  documents: any;*/

  $subscriptions: Subscription[] = [];

  constructor(
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected rgiDocumentService: DocumentsService,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any
  ) {
    super(plcGraphicFix, rgiDocumentService);
  }

  override startDocuments(event: any): void {
    super.startDocuments(event);
    this.hideDeleteDocumentButton();
  }


  ngOnInit() {
    super.ngOnInit();

  }

  override ngAfterViewChecked(): void {
    super.ngAfterViewChecked();

    if (!!this.inputDocuments) {
      this.$subscriptions.push(
        this.inputDocuments?.eventModifiedDocument?.asObservable().pipe(
          debounceTime(700),
          take(1),
          tap(() => {
            this.hidePreviewButton();
            this.hideEditButton();
            this.hideDeleteDocumentButton();
          })
        ).subscribe(),
        this.inputDocuments.startDocument.asObservable().pipe(
          debounceTime(700),
          take(1),
          tap(() => {
            this.hidePreviewButton();
            this.hideEditButton();
            this.hideDeleteDocumentButton();
          })
        ).subscribe(
        )
      );
    }
  }

  /* override verifyDocuments(event: any) {
    super.verifyDocuments(event);
    this.hidePreviewButton();
    this.hideDeleteDocumentButton();
  } */

  protected hidePreviewButton() {
    if (this.inputDocuments?.documents) {
      const parents = Array.from(document.getElementsByClassName('rgishared-mb-s')).slice(1);
      if (parents.length) {
        this.findIndexFilesNotOfCurrentUser.forEach(objIndex => {
          const parent = parents[objIndex.parent];
          if (parent) {
            const childrenWithPreviewButton = Array.from(parent.getElementsByClassName('rgishared-ml-s')).filter(child => {
              return child.getElementsByClassName('rgi-ui-icon-search')?.length > 0;
            });
            if (childrenWithPreviewButton[objIndex.child]) {
              childrenWithPreviewButton[objIndex.child].setAttribute('style', 'display: none');
            }
          }
        });
      }
    }
  }

  protected get findIndexFilesNotOfCurrentUser(): {parent: number, child: number}[] {
    let objIndexList: {parent: number, child: number}[] = [];
    let documents = this.inputDocuments.documents.inputDocuments;
    for(let i = 0; i < documents.length; i++){
      let files = documents[i].files;
      for(let j = 0; j < files.length; j++){
        if(files[j].userIns.description !== this.authService.getOperator().username){
          objIndexList.push(
            {parent: i, child: j}
          )
        }
      }
    }
    return objIndexList;
  }


  protected hideEditButton() {
    const editButtons = document.getElementsByClassName('rgi-ui-icon-edit2');
    if (editButtons.length > 0) {
      for (let i = 0; i < editButtons.length; i++) {
        const buttonElement = editButtons[i].closest('button');
        if (buttonElement) {
          buttonElement.style.display = 'none';
        }
      }
    }
  }

  protected hideDeleteDocumentButton() {
    if (!this.inputDocuments?.documents?.documentsDelatable) {
      this.inputDocuments.deleteDocumentEnabled = false;
    }
  }
}
