import {SharedComponentsTranslationKeys} from './translation.keys';

export const EU_ES: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'EUES_Add',
      ADD_DOCUMENT: 'EUES_Add document',
      ARCHIVE: 'EUES_Archive',
      ARCHIVED: 'EUES_Archived',
      ARCHIVE_DOC: 'EUES_Archive document',
      ARCHIVING_USER: 'EUES_Archiving User',
      CANCEL: 'EUES_Cancel',
      CLOSE: 'EUES_Close',
      CODE: 'EUES_Code',
      CONFIRM: 'EUES_Confirm',
      CREATED: 'EUES_Created',
      DELETE_DOC: 'EUES_Delete document',
      DELIVERED: 'EUES_Delivered',
      DELIVERY_DOC: 'EUES_Delivery document',
      DELIVERY_ADDRESS: 'EUES_Delivery Address',
      DELIVERY_PARTY: 'EUES_Delivery Party',
      DELIVERY_USER: 'EUES_Delivery User',
      DELIVERY_TYPE: 'EUES_Delivery Type',
      DESCRIPTION: 'EUES_Description',
      DOCUMENTS_MANAGER: 'EUES_Documents Manager',
      DOCUMENT_TYPE: 'EUES_Document type',
      DOCUMENT_VALIDATION_MESSAGE: 'EUES_Proceed with the validation of the document?',
      DOWNLOAD: 'EUES_Download',
      DOWNLOAD_DOC: 'EUES_Download document',
      EDIT: 'EUES_Edit',
      FILE: 'EUES_File',
      ERROR: 'EUES_Error',
      INPUT_DOCUMENTS: 'EUES_Input Documents',
      LOAD: 'EUES_LOAD',
      NO: 'EUES_No',
      NOTE: 'EUES_Note',
      NO_FILES: 'EUES_No files',
      NO_SIGN_NEEDED: 'EUES_Document does not require signature',
      NOT_DELIVERED: 'EUES_Not delivered',
      NOT_SIGNED: 'EUES_Not signed',
      NUMBER_OF_FILES_INSERTED: 'EUES_{{ filesLength }} of {{ fileMax }} files inserted',
      OUTBOUND_DOC_TITLE: 'EUES_Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'EUES_Proposal documents nr. {{ proposalNumber }} of movement {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'EUES_Output Documents',
      PENDING_SIGNATURE_TOOLTIP: 'EUES_Document waiting for signature. Click to stop the process',
      REFRESH: 'EUES_Refresh',
      SAVE: 'EUES_SAVE',
      SEND: 'EUES_Send',
      SIGN: 'EUES_Sign',
      SIGN_DOCUMENT: 'EUES_Sign document',
      SIGNED: 'EUES_Signed',
      SIGNED_DOCUMENT: 'EUES_Document signed',
      SUBJECT_SENDING_DATE: 'EUES_Sending date to registry subject',
      STATE: 'EUES_Status',
      TYPE: 'EUES_Type',
      TO_BE_GENERATED: 'EUES_To be generated',
      TO_BE_UPLOADED: 'EUES_To be uploaded',
      UPDATE_FILE: 'EUES_Update file',
      UPLOAD: 'EUES_UPLOAD',
      UPLOADED: 'EUES_Uploaded',
      UPLOAD_NEW_DOCUMENT: 'EUES_Upload new document',
      UPLOADING_USER: 'EUES_Uploading User',
      VALIDATING_USER: 'EUES_Validation User',
      VALIDATED: 'EUES_Validated',
      VALIDATION: 'EUES_Validation',
      VALIDATION_DOCUMENT: 'EUES_Validation document',
      YES: 'EUES_Yes',
      NO_DOCUMENTS_CONFIGURED: 'EUES_There are no configured documents for this operation'
    }
};
