import {
  Component, Input, OnInit,
  EventEmitter, Output, Inject, LOCALE_ID
} from '@angular/core';
import {AddressesService, AlertService, ElemEnum,} from '@rgi/digital-claims-common-angular';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DscAddressDataService} from './dsc-address-detail-services/dsc-address-detail.service';
import {TableSchema} from '@rgi/rx/ui';
import {map} from 'rxjs/operators';
import {AnagApiAddress} from "@rgi/anag/lib/anag-model/anag-domain/anag-api-party";
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import { Address, AddressEntityData, AddressExt, AddressType, CityDetail, DictionaryType, ExtensionSubject, ExtensionSubjectProperties, NormalizedAddress, Toponym, UmaAddress, ZipCode } from '../dsc-shared-model/dsc-model-address';
import { CLAIMS_ADDRESS_NORMALIZED, OutputAddressList } from './dsc-address-detail-model/dsc-address-detail.config';

@Component({
  selector: 'claims-dsc-address-detail-component',
  templateUrl: './dsc-address-detail.component.html',
  styleUrls: ['./dsc-address-detail.component.scss'],
})
export class DscAddressDetailComponent implements OnInit {

  localContext: string;
  showUmaAddress: boolean = false;
  inputDataFormUma: AddressExt;
  italianContext: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public openingDetailsService: DscAddressDataService,
    public addressService: AddressesService,
    @Inject(LOCALE_ID) private locale: string,
    @Inject('authService') private authService,
  ) {
    this.localContext = authService.getDefaultContext();
    this.italianContext = (this.localContext === null || this.localContext === 'IT');
  }

  @Output() outputFormAddress = new EventEmitter<any>();

  @Input() titleAddress: string = '_CLAIMS_._ADDRESS';
  @Input()
  get addressData(): AddressEntityData {
    return this._addressData;
  }


  set addressData(entity: AddressEntityData) {
    if (entity.country !== null) {
      this._addressData = entity;
    } else {
      this._addressData = new AddressEntityData();
    }
    if (entity.city != '' && entity.city != null
      && entity.city !== this._addressData.city && this.italianContext) {
      this.addressService.getZipCodes(entity.city).subscribe(
        (response: ElemEnum[]) => {
          this._addressData.postCode = response[0].codice
        });
    }

  }

  getAllZipCode(city: string) {
    if (this.addressData.city !== city) {
      this.addressService.getZipCodes(city).subscribe(
        (response: ElemEnum[]) => {
          response.forEach(element => {
            this.zipCodes.push({id: element.codice, value: element.descrizione});
            this.filteredZipCodes.push({id: element.codice, value: element.descrizione});
          });
          this.addressDataForm.get('postCode').setValue(response[0].codice);
        });
    }
  }

  private _addressData = new AddressEntityData();
  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Input() isNewClaim: boolean = false;
  cities: CityDetail[] = [];
  filteredCities: CityDetail[] = [];
  countries: DictionaryType[] = [];
  filteredCountries: DictionaryType[] = [];
  toponyms: DictionaryType[] = [];
  filteredToponyms: { code: string, description: string }[] = [];
  zipCodes: DictionaryType[] = [];
  filteredZipCodes: DictionaryType[] = [];
  occurrenceDate: Date = new Date();
  addressDataForm: UntypedFormGroup;
  onlyMatching: boolean = true;
  hasLoadedCountry: boolean = false;
  selectedCity: CityDetail;
  isItalian: boolean;
  isAccordion: boolean = false;
  expanded: boolean;

  umaAddress: UmaAddress = null;

  enableNormalize: boolean = false;
  showNormalizedTable: boolean = false;

  dataSourceAddressNormalizedClaims: NormalizedAddress[] = [];
  schema: TableSchema = CLAIMS_ADDRESS_NORMALIZED;

  ngOnInit() {
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion;
    if (this.italianContext) {
      this.addressDataForm = this.formBuilder.group({
        country: ['ITALIA', Validators.required],
        city: ['', Validators.required],
        adminLevel1: [''],
        adminLevel2: [''],
        adminLevel3: [''],
        province: ['', Validators.required],
        extendedProvince: [''],
        postCode: ['', Validators.required],
        address: [null, this.addressData.isRequiredAddress ? Validators.required: Validators.nullValidator],
        houseNumber: [null, this.addressData.isRequiredHouseNumber ? Validators.required: Validators.nullValidator],
        toponym: [null]
      });
      this.addressDataForm.patchValue(this.addressData, {emitEvent: false});


      this.getAllCountry();
      this.getAllToponyms();
      if (this.addressData && !this.addressData.editability) {
        this.disabledAllAddressForm();
      }
    } else {
      this.initUmaAddress();
    }
  }

  initUmaAddress() {
    const initilizeValueExtensionSubject: ExtensionSubjectProperties = new ExtensionSubjectProperties();
    let initilizeExtensionSubject: ExtensionSubject = new ExtensionSubject();
    initilizeExtensionSubject.properties.push(initilizeValueExtensionSubject);
    this.inputDataFormUma = null;
    if (this.addressData) {
      this.inputDataFormUma = {
        addressAddition: '',
        adminLevel1: this._addressData.adminLevel1 ? this._addressData.adminLevel1 : '',
        adminLevel1Short: '',
        adminLevel2: this._addressData.adminLevel2 ? this._addressData.adminLevel2 : '',
        adminLevel2Short: '',
        adminLevel3: this._addressData.adminLevel3 ? this._addressData.adminLevel3 : '',
        adminLevel3Short: '',
        at: '',
        cab: '',
        cap: this._addressData.postCode ? this._addressData.postCode : '',
        city: this._addressData.city ? this._addressData.city : '',
        country: null,
        countryCode: this.localContext,
        detailCode: '',
        extensionSubject: initilizeExtensionSubject,
        formatAddress: '',
        fraction: '',
        id: '',
        objectId: '',
        latitude: '',
        locality: '',
        longitude: '',
        normalized: true,
        number: this._addressData.houseNumber ? this._addressData.houseNumber : '',
        placeAddress: this._addressData.address ? this._addressData.address : '',
        postalAddress: '',
        province: null,
        specialMention: '',
        subLocality: '',
        toponym: {
          codice: this._addressData.toponym?.code,
          descrizione: this._addressData.toponym?.description
        }
      };
      this.outputFormUmaAddress(this.inputDataFormUma);
    }
    this.showUmaAddress = true;
  }

  outputFormChange() {

    const toponymForm = this.addressDataForm.get('toponym').value !== '' && this.addressDataForm.get('toponym').value !== null
    ? this.addressDataForm.get('toponym').value : undefined;

    const countrySelected = this.addressDataForm.get('country').value ? this.addressDataForm.get('country').value : 'ITALIA';
    const filCountry = this.countries.filter(res => res.value.toLocaleLowerCase() === countrySelected.toLowerCase());

    let selectedCity = [];
    if (this.addressData.city !== '' && this.addressDataForm.get('city').value === '') {
      selectedCity = this.cities.filter(res => res.nomecomune === this.addressData.city);
    } else {
      selectedCity = this.cities.filter(res => res.nomecomune === this.addressDataForm.get('city').value);
    }
    const dataChange: AddressEntityData = {
      country: filCountry.length > 0 ? filCountry[0].value : 'ITALIA',
      countryCode: filCountry.length > 0 ? filCountry[0].id : 'IT',
      cityCode: selectedCity.length > 0 ? selectedCity[0].nomecomune : '',
      postCode: this.addressDataForm.get('postCode').value,
      city: selectedCity.length > 0 ? selectedCity[0].nomecomune : '',
      adminLevel1: this.addressDataForm.get('adminLevel1').value,
      adminLevel2: this.addressDataForm.get('adminLevel2').value,
      adminLevel3: this.addressDataForm.get('adminLevel3').value,
      province: selectedCity.length > 0 ? selectedCity[0].siglaprovincia : '',
      address: this.addressDataForm.get('address').value,
      houseNumber: this.addressDataForm.get('houseNumber').value,
      toponym: toponymForm,
      editability: this.addressData ? this.addressData.editability : true,
      isValidForm: this.addressDataForm.valid,
      umaAddress: this.umaAddress,
      isRequiredAddress: this.addressData.isRequiredAddress,
      isRequiredHouseNumber: this.addressData.isRequiredHouseNumber
    };
    this.changeRequiredField();
    this.isItalian = this.isItalianCountry();
    this.enableNormalizeButtone();
    this.outputFormAddress.emit(dataChange);
  }

  initAddressData() {
    let countrySelected: DictionaryType = {id: 'IT', value: 'ITALIA'};

    if (!this.addressData) {
      this.addressDataForm = this.formBuilder.group({
        country: [countrySelected.value, Validators.required],
        city: ['', Validators.required],
        adminLevel1: [''],
        adminLevel2: [''],
        adminLevel3: [''],
        province: ['', Validators.required],
        extendedProvince: [''],
        postCode: ['', Validators.required],
        address: [''],
        houseNumber: [''],
        toponym: [null],
        umaAddress: [null]
      });
    } else {
      if (this.addressData.countryCode && this.addressData.countryCode !== null) {
        countrySelected = this.countries.find(res => res.id === this.addressData.countryCode);
      } else {
        countrySelected = this.countries.find(res => res.id === this.addressData.country);
      }

      this.addressDataForm.patchValue(this.addressData, {
        emitEvent: false
      }); /* = this.formBuilder.group({
        country: [countrySelected.value, Validators.required],
        city: [this.addressData.city ? this.addressData.city : '', Validators.required],
        adminLevel1: [this.addressData.adminLevel1 ? this.addressData.adminLevel1 : ''],
        adminLevel2: [this.addressData.adminLevel2 ? this.addressData.adminLevel2 : ''],
        adminLevel3: [this.addressData.adminLevel3 ? this.addressData.adminLevel3 : ''],
        province: [this.addressData.province ? this.addressData.province : ''],
        extendedProvince: [this.addressData.province ? this.addressData.province : ''],
        postCode: [this.addressData.postCode ? this.addressData.postCode : ''],
        address: [this.addressData.address ? this.addressData.address : '', Validators.required],
        houseNumber: [this.addressData.houseNumber ? this.addressData.houseNumber : ''],
        toponym: [this.addressData.toponym ? this.addressData.toponym : '']
      }); */
      this.addressDataForm.get('country').setValue(countrySelected.value);
    }

    this.isItalian = this.addressDataForm.get('country').value === 'ITALIA';
    this.getAllCity(countrySelected.id);
    this.changeRequiredField();
    this.enableNormalizeButtone();
    this.outputFormChange();
  }

  normalizeAddress() {

    const toponymNormalize: Toponym = this.addressDataForm.get('toponym').value

    const address$: Address = {
      adminLevel2: this.addressDataForm.controls.city.value,
      adminLevel3: this.addressDataForm.controls.extendedProvince.value,
      cap: this.addressDataForm.controls.postCode.value,
      countryCode: this.filteredCountries[0].id,
      number: this.addressDataForm.controls.houseNumber.value !== '' ? this.addressDataForm.controls.houseNumber.value : null,
      placeAddress: (this.addressDataForm.controls.address.value !== '' && this.addressDataForm.controls.address.value !== null)
        ? this.addressDataForm.controls.address.value : undefined,
      toponym: this.addressDataForm.controls.toponym.value !== '' ? toponymNormalize : null
    };

    const addressType: AddressType = {
      address: address$
    };

    this.openingDetailsService.normalize(addressType).subscribe((res: OutputAddressList) => {

      if (res.result.normalizedAddresses.length === 0) {
        this.alertService.translatedMessage('No normalized address found');
      } else {
        this.dataSourceAddressNormalizedClaims = res.result.normalizedAddresses;
        this.showNormalizedTable = true;
      }
    });

  }

  onActionClick(event) {
    const itemSelected: NormalizedAddress = event.row;
    const toponym = this.toponyms
      .map((toponym) => ({code: toponym.id, description: toponym.value}))
      .find(res => itemSelected.toponym ? res.description.toUpperCase() === itemSelected.toponym.descrizione.toUpperCase() : null)
    const changeValue = {
      toponym: toponym,
      houseNumber: itemSelected.number,
      postCode: itemSelected.cap,
      city: itemSelected.adminLevel2,
      country: this.returnSingleCountry(itemSelected.countryCode).value,
      address: itemSelected.placeAddress,
    };

    this.umaAddress = {
      adminLevel2: itemSelected.adminLevel1,
      adminLevel2Short: itemSelected.adminLevel2Short && itemSelected.adminLevel2Short !== ''
        ? itemSelected.adminLevel2Short
        : null,
      adminLevel3: itemSelected.adminLevel3,
      cap: itemSelected.cap,
      countryCode: itemSelected.countryCode,
      normalized: itemSelected.normalized,
      number: itemSelected.number,
      placeAddress: itemSelected.placeAddress
    }
    const newAddress = {...itemSelected, ...changeValue};
    this.addressDataForm.patchValue(newAddress, {
      emitEvent: false
    });
    this.dataSourceAddressNormalizedClaims = [];
    this.showNormalizedTable = false;
    this.enableNormalize = false;
    this.outputFormChange();
  }

  enableNormalizeButtone() {
    if (this.addressDataForm.get('country').value === 'ITALIA') {
      this.enableNormalize = (this.addressDataForm.get('city').value !== ''
    && this.addressDataForm.get('city').value !== undefined
    && this.addressDataForm.get('city').value !== null
    && this.addressDataForm.get('city').value.length >= 3);
    } else {
      this.enableNormalize = (this.addressDataForm.get('adminLevel1').value !== ''
    && this.addressDataForm.get('adminLevel1').value !== undefined
    && this.addressDataForm.get('adminLevel1').value !== null
    && this.addressDataForm.get('adminLevel2').value !== ''
    && this.addressDataForm.get('adminLevel2').value !== undefined
    && this.addressDataForm.get('adminLevel2').value !== null
    && this.addressDataForm.get('adminLevel3').value !== ''
    && this.addressDataForm.get('adminLevel3').value !== undefined
    && this.addressDataForm.get('adminLevel3').value !== null);
    }
  }

  returnSingleCountry(countryCodeId): DictionaryType {
    return this.countries.filter((res: DictionaryType) => res.id.toLowerCase().includes(countryCodeId.toLowerCase()))[0];
  }

  changeRequiredField() {
    if (this.addressDataForm.get('country').value === 'ITALIA') {
      this.addressDataForm.controls.country.setValidators(Validators.required);
      this.addressDataForm.controls.city.setValidators(Validators.required);
      this.addressDataForm.controls.adminLevel1.clearValidators();
      this.addressDataForm.controls.adminLevel2.clearValidators();
      this.addressDataForm.controls.adminLevel3.clearValidators();
      this.addressDataForm.controls.province.setValidators(Validators.required);
      /* this.addressDataForm.controls.extendedProvince.setValidators(Validators.required); */
      this.addressDataForm.controls.extendedProvince.clearValidators();
      this.addressDataForm.controls.postCode.setValidators(Validators.required);
      /*  this.addressDataForm.controls.address.setValidators(Validators.required);
       this.addressDataForm.controls.houseNumber.setValidators(Validators.required);
       this.addressDataForm.controls.toponym.setValidators(Validators.required); */
      this.addressData.isRequiredAddress ? this.addressDataForm.controls.address.setValidators(Validators.required) :
        this.addressDataForm.controls.address.clearValidators();
      this.addressData.isRequiredHouseNumber ? this.addressDataForm.controls.houseNumber.setValidators(Validators.required) :
        this.addressDataForm.controls.houseNumber.clearValidators();
      this.addressDataForm.controls.toponym.clearValidators();
    } else {
      this.addressDataForm.controls.province.clearValidators();
      this.addressDataForm.controls.city.clearValidators();
      this.addressDataForm.controls.extendedProvince.clearValidators();
      this.addressDataForm.controls.postCode.clearValidators();
      this.addressDataForm.controls.country.setValidators(Validators.required);
      this.addressDataForm.controls.toponym.clearValidators();
      this.addressData.isRequiredAddress ? this.addressDataForm.controls.address.setValidators(Validators.required) :
        this.addressDataForm.controls.address.clearValidators();
      this.addressData.isRequiredHouseNumber ? this.addressDataForm.controls.houseNumber.setValidators(Validators.required) :
        this.addressDataForm.controls.houseNumber.clearValidators();
      this.addressDataForm.controls.adminLevel1.setValidators(Validators.required);
      this.addressDataForm.controls.adminLevel2.setValidators(Validators.required);
      this.addressDataForm.controls.adminLevel3.setValidators(Validators.required);
    }
    if (this.isNewClaim && this.addressDataForm.get('country').value === 'ITALIA') {
      this.addressDataForm.controls.country.setValidators(Validators.required);
      this.addressDataForm.controls.city.setValidators(Validators.required);
      this.addressDataForm.controls.province.setValidators(Validators.required);
      this.addressDataForm.controls.adminLevel1.clearValidators();
      this.addressDataForm.controls.adminLevel2.clearValidators();
      this.addressDataForm.controls.adminLevel3.clearValidators();
      this.addressDataForm.controls.extendedProvince.clearValidators();
      this.addressDataForm.controls.postCode.clearValidators();
      this.addressData.isRequiredAddress ? this.addressDataForm.controls.address.setValidators(Validators.required) :
        this.addressDataForm.controls.address.clearValidators();
      this.addressData.isRequiredHouseNumber ? this.addressDataForm.controls.houseNumber.setValidators(Validators.required) :
        this.addressDataForm.controls.houseNumber.clearValidators();
      this.addressDataForm.controls.toponym.clearValidators();
    }
    this.addressDataForm.get('province').disable();
    this.addressDataForm.get('extendedProvince').disable();
    if (this.addressDataForm.get('city').value === '') {
      this.addressDataForm.get('postCode').disable();
    } else {
      this.addressDataForm.get('postCode').enable();
    }
    this.addressDataForm.updateValueAndValidity();
  }

  getAllCity(filter?: string) {
    this.filteredCities = [];
    this.addressService.getCities().subscribe(
      (response: any[]) => {
        if (response.length > 0) {
          this.cities = response;
          this.filteredCities = [];
          if (this.addressDataForm.get('city').value !== '' && this.addressDataForm.get('city').value !== null) {
            this.filteredCities = this.cities.filter((res: CityDetail) =>
              res.description.toString().toLowerCase().includes(this.addressDataForm.get('city').value.toLowerCase()));
            const filCity = this.cities.filter((res: CityDetail) => res.nomecomune === this.addressDataForm.get('city').value);
            if (filCity.length > 0) {
              this.addressDataForm.get('extendedProvince').setValue(filCity[0].descrizioneprovincia);
              this.onValueChangeCity(this.addressDataForm.get('city').value);
            }
          }
        } else {
          this.cities = [];
          this.filteredCities = [];
        }
      });
  }

  getAllCountry() {
    this.filteredCountries = [];
    this.addressService.getCountries(this.occurrenceDate).subscribe(
      (response: ElemEnum[]) => {
        if (response.length > 0) {
          response.forEach((element: ElemEnum) => {
            this.countries.push({id: element.codice, value: element.descrizione});
          });
          this.initAddressData();
          if (this.addressData) {
            if (this.addressData.country === '') {
              const countrySelected = this.countries.find(res => res.id.toLowerCase() === this.locale.toLowerCase());
              this.addressDataForm.get('country').setValue(countrySelected.id);
              if (this.addressDataForm.get('country').value === 'IT') {
                this.getAllCity();
              }
            } else {
              if (this.addressData.countryCode && this.addressData.countryCode !== null) {
                this.addressDataForm.get('country').setValue(this.countries.find(res => res.id === this.addressData.countryCode).value);
              } else {
                this.addressDataForm.get('country').setValue(this.countries.find(res => res.id === this.addressData.country).value);
              }
            }
          }
          this.filteredCountries = this.countries.filter(res =>
            res.value.toLowerCase().includes(this.addressDataForm.get('country').value.toLowerCase()));
          this.hasLoadedCountry = true;
        } else {
          this.countries = [];
        }
      });
  }

  getAllToponyms() {
    this.filteredToponyms = [];
    this.addressService.getToponyms(this.occurrenceDate).subscribe(
      (response: ElemEnum[]) => {
        if (response.length > 0) {
          response.forEach((element: ElemEnum) => {
            this.toponyms.push({id: element.codice, value: element.descrizione});
          });
        } else {
          this.toponyms = [];
        }
      });
  }


  onCompleteCountry(event: string) {
    if (event.length >= 3) {
      this.filteredCountries = this.countries.filter((res: DictionaryType) =>
        res.value.toString().toLowerCase().includes(event.toLowerCase()));
    } else if (this.addressDataForm.get('country').value !== '' && this.addressDataForm.get('country').value.length >= 3) {
      this.filteredCountries = this.countries.filter((res: DictionaryType) =>
        res.value.toString().toLowerCase().includes(this.addressDataForm.get('country').value.toLowerCase()));
    }
    this.enableNormalizeButtone();
  }

  onValueChangeCountry(event: string) {
    const countrySelected = this.countries.find(res => res.value === event);
    this.addressDataForm.get('country').setValue(countrySelected.value);
    this.appllyEmptyForm(countrySelected.value);
    if (this.addressDataForm.get('country').value === 'IT') {
      this.getAllCity(countrySelected.id);
    }
    this.outputFormChange();
  }

  onCompleteCity(event: string) {
    this.enableNormalizeButtone();
    if (event !== '' && event.length >= 3) {
      this.filteredCities = this.cities.filter((res: CityDetail) =>
        res.description.toString().toLowerCase().includes(event.toLowerCase()));
    } else if (this.addressDataForm.get('city').value !== '' && this.addressDataForm.get('city').value.length >= 3) {
      this.filteredCities = this.cities.filter((res: CityDetail) =>
        res.description.toString().toLowerCase().includes(this.addressDataForm.get('city').value.toLowerCase()));
    }
  }

  onValueChangeCity(event: string) {
    this.enableNormalizeButtone();
    this.selectedCity = this.filteredCities.find((res: CityDetail) => res.nomecomune.toLowerCase() === event.toLowerCase());
    // this.filteredCities = this.cities.filter((res: CityDetail) => res.description.includes(event));
    this.addressDataForm.get('province').setValue(this.selectedCity.siglaprovincia);
    this.addressDataForm.get('extendedProvince').setValue(this.selectedCity.descrizioneprovincia);
    this.addressDataForm.get('province').disable();
    this.addressDataForm.get('extendedProvince').disable();
    //   this.addressDataForm.get('postCode').setValue('');
    this.filteredZipCodes = [];
    if(this.selectedCity && this.selectedCity !== null) {
      this.getAllZipCode(this.selectedCity.nomecomune);
    }
    this.outputFormChange();
  }

  onCompleteZipCode(event: any) {
    if (event.length === 5 && !isNaN(parseInt(event, 10))) {
      this.filteredZipCodes = this.zipCodes.filter((res: DictionaryType) =>
        res.value.toString().toLowerCase().includes(event.toLowerCase()));

      this.openingDetailsService.getCitiesFromZipCode(event).pipe(
        map((res: ZipCode[]) => res.map((el: ZipCode) => {
            this.addressDataForm.get('city').setValue(res[0].nomecomune);
            this.addressDataForm.get('extendedProvince').setValue(res[0].descrizioneprovincia)
            return {id: el.capgenerico, value: el.description}
          }
        ))
      ).subscribe(
        (res: DictionaryType[]) => {
          this.filteredZipCodes = res;
        }
      )
    } else if (event === '') {
      this.filteredZipCodes = [];
    }
    this.enableNormalizeButtone();
  }

  onValueChangeZipCode(event: any) {
    this.outputFormChange();
  }

  onCompleteToponym(event: any) {
    if (event !== '' && event.length >= 2) {
      this.filteredToponyms = this.toponyms.map((res) => ({code: res.id, description: res.value}))
        .filter((res: { code: string, description: string }) =>
          res.description.toString().toLowerCase().includes(event.toLowerCase()));
    } else if (event === '') {
      this.filteredToponyms = [];
    }
    this.enableNormalizeButtone();
  }

  onValueChangeToponym(event: any) {
    this.outputFormChange();
  }

  isItalianCountry(): boolean {
    return this.addressDataForm.get('country').value === 'ITALIA';
  }

  appllyEmptyForm(countrySelected: string) {
    this.addressDataForm = this.formBuilder.group({
      country: [countrySelected, Validators.required],
      city: ['', Validators.required],
      adminLevel1: [''],
      adminLevel2: [''],
      adminLevel3: [''],
      province: ['', Validators.required],
      extendedProvince: [''],
      postCode: ['', Validators.required],
      address: [null],
      houseNumber: [null],
      toponym: [null]
    });
  }

  disabledAllAddressForm() {
    this.addressDataForm.controls.country.disable();
    this.addressDataForm.controls.city.disable();
    this.addressDataForm.controls.adminLevel1.disable();
    this.addressDataForm.controls.adminLevel2.disable();
    this.addressDataForm.controls.adminLevel3.disable();
    this.addressDataForm.controls.province.disable();
    this.addressDataForm.controls.extendedProvince.disable();
    this.addressDataForm.controls.postCode.disable();
    this.addressDataForm.controls.address.disable();
    this.addressDataForm.controls.houseNumber.disable();
    this.addressDataForm.controls.toponym.disable();
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }

  outputFormUmaAddress(addressDetail: AddressExt) {
    const singleCountry: DictionaryType = this.returnSingleCountry(addressDetail.countryCode);
    const toponymForm = addressDetail.toponym? {
      code: addressDetail.toponym?.codice,
      description: addressDetail.toponym?.descrizione
    } : undefined;

    const dataChange: AddressEntityData = {
      country: addressDetail.countryCode,
      countryCode: addressDetail.countryCode,
      cityCode: addressDetail.city,
      postCode: addressDetail.cap,
      city: addressDetail.city,
      adminLevel1: addressDetail.adminLevel1,
      adminLevel2: addressDetail.adminLevel2,
      adminLevel3: addressDetail.adminLevel3,
      province: addressDetail.adminLevel2,
      address: addressDetail.placeAddress,
      houseNumber: addressDetail.number,
      toponym: toponymForm,
      editability: this.addressData ? this.addressData.editability : true,
      isValidForm: this.checkUmaAddressValidationForm(addressDetail),
      isRequiredAddress: this.addressData.isRequiredAddress,
      isRequiredHouseNumber: this.addressData.isRequiredHouseNumber,
      umaAddress: this.umaAddress = {
        adminLevel2: addressDetail.adminLevel2,
        cap: addressDetail.cap,
        adminLevel3: addressDetail.adminLevel3,
        placeAddress: addressDetail.placeAddress,
        adminLevel2Short: addressDetail.adminLevel2Short && addressDetail.adminLevel2Short !== ''
          ? addressDetail.adminLevel2Short
          : null,
        countryCode: addressDetail.countryCode,
        number: addressDetail.number,
        normalized: addressDetail.normalized
      }
    };

    this.outputFormAddress.emit(dataChange);
  }

  checkUmaAddressValidationForm(addressDetail: AddressExt): boolean {
    return addressDetail.countryCode && addressDetail.adminLevel2 && addressDetail.adminLevel3;
  }

}
