import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DynamicTableUtilService} from '../../card/utils/dynamic-table-util.service';
import {OutputPartyEntity} from '../proposal-otp-model/party-Interface';
import {ProposalOtpService} from '../proposal-otp-service/proposal-otp.service';
import {RgiRxTranslationService} from "@rgi/rx/i18n";

@Component({
  selector: 'iiab-party-otp',
  templateUrl: './party-otp.component.html',
  styleUrls: ['party-otp.component.css']
})
export class PartyOtpComponent implements OnInit {

  @Input() contractId: any;
  @Output() subjectOtpChanged: EventEmitter<any> = new EventEmitter<any>();
  partyHolder: any; // OutputPartyEntity;
  dynamicTableParty: object[] = [];
  partyMap: Map<number, object[]>;
  rowsParty: number[];
  nCol = 3;
  labelTableParty = '';

  constructor(
    public dynamicTableUtilityService: DynamicTableUtilService,
    private proposalOtpService: ProposalOtpService,
    protected translateService: RgiRxTranslationService
  ) {
  }

  ngOnInit() {
    this.getParty();
    this.translateService.translate('_IIAB_._OTP_._SUBSCRIBER_').subscribe(translation => this.labelTableParty = translation);
  }

  getParty(isChanged: boolean = false) {
    this.proposalOtpService.getParties(this.contractId).subscribe((res: OutputPartyEntity) => {
      this.partyHolder = res;
      this.createDynamicTableforParty();
      if (isChanged) {
        this.subjectOtpChanged.emit();
      }
    });
  }

  createDynamicTableforParty() {
    this.dynamicTableParty = [];
    this.dynamicTableUtilityService.putDataOnDynamicTable(this.dynamicTableParty, this.labelTableParty, this.partyHolder.subscriber.nominative);
    this.partyMap = this.dynamicTableUtilityService.generateDynamicTable(this.dynamicTableParty);
    this.rowsParty = this.dynamicTableUtilityService.composeArray(Math.ceil(this.dynamicTableParty.length / this.nCol));
  }

  refreshParty() {
    this.getParty(true);
  }
}
