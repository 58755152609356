import { Component, OnInit, Input, Output, EventEmitter, Inject, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { EditablePanelContentComponent, PanelService, PanelSignal } from '@rgi/digital-claims-common-angular';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { ExposureEntity } from '../domain/exposure-entity';
import { PotentialPaymentEntity } from '../domain/potential-payment-entity';
import { SubjectEntity } from '../domain/subject-entity';
import { NewPaymentService } from '../new-payment.service';
import { MethodPaymentEntity } from '../domain/method-payment-entity';
import { FeeDataModalComponent } from '../fee-data-modal/fee-data-modal.component';
import { LegalFeeEntity } from '../domain/legal-fee-entity';
import { LegalFeeOmniaEntity } from '../domain/legal-fee-omnia-entity';
import { InvoiceCtrlModalComponent } from '../invoice-ctrl-modal/invoice-ctrl-modal.component';
import { UtilityService } from '../../external-portfolio/utility.service';
import { CalculatorFactorComponent } from '../calculator-factor/calculator-factor.component';
import { ComboEntity } from '../domain/combo-entity';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-payment-data',
  templateUrl: './payment-data.component.html',
  styleUrls: ['./payment-data.component.scss']
})
export class PaymentDataComponent extends EditablePanelContentComponent implements OnInit, OnChanges {
  static PROPERTY_FOREIGN_PAYMENT_LIGHT = 'ForeignPaymentLight';
  static PROPERTY_ENABLED_CLAIMS_PAYMENT_URGENCY: string = 'ClaimsPaymentUrgency';
  static PROPERTY_ENABLED: string = 'Enable';
  public isAbilitatoClaimsPaymentUrgency: boolean = false;

  @Input() public card: any;
  @Input() potPayFE: InitPotentialPaymentEntity;
  @Input() newPayForm: UntypedFormGroup;
  @Output() stepperEventEmitter = new EventEmitter<number>();
  @Output() saveEmitter = new EventEmitter<boolean>();
  public errorMessage = null;
  public today: NgbDate;
  public authSrv: any;
  public listExpComboVisual: Array<ExposureEntity>;
  public mappaBranchExp: Map<string, Array<ExposureEntity>>;
  selectedMainSubStatus: string;
  @Input() valueBar: number = 0;
  public multiCurrencyIsoCodes: any;
  public currencyCodeDate: any;
  multyCurrencyEnabled: boolean;
  @Input() exposuresLoaded: boolean = false;
  @Input() receiptTypeLoaded: boolean = false;
  public labelCoherence: string = '';
  public procedureCodeSelected: string = '';
  listCoherence: TypeEnum[] = [];
  eTipoPagamentoSpeseLegali: TypeEnum;
  eTipoPagamentoSpeseCtu: TypeEnum;
  eTipoPagamentoSpeseCtp: TypeEnum;
  eTipoPagamentoImpRegistro: TypeEnum;
  eTipoPagamentoOnorariSeparati: TypeEnum;
  eQuietanzaStandard: TypeEnum;

  eQuietanzaPropTrans: TypeEnum;
  eTipoPagamOffertaReale: TypeEnum;
  eTipoPagamRimborso: TypeEnum;
  eTipoPagIntComm: TypeEnum;
  eQuietanzaOnorariSeparati: TypeEnum;
  eTipoPagProvvisionale: TypeEnum;
  eTipoDefinizContParziale: TypeEnum;
  eQuietanzaOmniaCompresi: TypeEnum;
  eSuccessService: TypeEnum;
  eFailureService: TypeEnum;
  eTipoDefinizContAll: TypeEnum[] = [];
  eTipoPagamentoAll: TypeEnum[] = [];
  eTipoQuietanzaAll: TypeEnum[] = [];
  eFeeType: TypeEnum[] = [];
  eRoleLegaleAvv: TypeEnum;
  eTipoCoassDelegaNostra: TypeEnum;
  eTipoCoassDelegaAltrui: TypeEnum;
  eTipoCoassNoCoass: TypeEnum;
  public isContextSpain: boolean = false;

  constructor(
    panelService: PanelService,
    @Inject('eventService') private eventService: any,
    @Inject('enumService') private enumService: any,
    private newPaymentService: NewPaymentService,
    @Inject('authService') authService,
    public translateService: RgiRxTranslationService,
    public formBuilder: UntypedFormBuilder,
    private utilityService: UtilityService,
    private modalService: NgbModal,
    private paymentConstants: PaymentConstants,
    @Inject('getSystemProperty') private getSystemProperty: any
  ) {
    super(panelService);
    const today = new Date();
    this.authSrv = authService;
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    this.multyCurrencyEnabled = false;
    let localContext = this.authSrv.getDefaultContext();
    this.isContextSpain = localContext === 'ES';
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.exposuresLoaded &&
      changes.exposuresLoaded.currentValue &&
      this.receiptTypeLoaded) ||
      (changes.receiptTypeLoaded &&
        changes.receiptTypeLoaded.currentValue &&
        this.exposuresLoaded)) {
      this.preCompile();
    }
  }

  initEnums() {
    this.listCoherence = this.paymentConstants.ECOERENZADANNOBENE_ALL();
    this.eTipoPagamentoSpeseLegali = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_SPESE_LEGALI);
    this.eTipoPagamentoSpeseCtu = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_SPESE_CTU);
    this.eTipoPagamentoSpeseCtp = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_SPESE_CTP);
    this.eTipoPagamentoImpRegistro = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_IMPOSTE_DI_REGISTRO);
    this.eTipoPagamentoOnorariSeparati = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOQUIETANZA_ONORARI_SEPARATI);
    this.eQuietanzaStandard = this.paymentConstants.getSingleEtipoQuietanza(ENUMSLISTPAYMENT.ETIPOQUIETANZA_STANDARD);
    this.eQuietanzaPropTrans = this.paymentConstants.getSingleEtipoQuietanza(ENUMSLISTPAYMENT.ETIPOQUIETANZA_PROPOSTA_DI_TRANSAZIONE);
    this.eQuietanzaOnorariSeparati = this.paymentConstants.getSingleEtipoQuietanza(ENUMSLISTPAYMENT.ETIPOQUIETANZA_ONORARI_SEPARATI);
    this.eQuietanzaOmniaCompresi = this.paymentConstants.getSingleEtipoQuietanza(ENUMSLISTPAYMENT.ETIPOQUIETANZA_OMNIA_COMPRESI);
    this.eTipoPagamOffertaReale = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_OFFERTA_REALE);
    this.eTipoPagamRimborso = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_RIMBORSO);
    this.eTipoPagIntComm = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_INTERVENTO_COMMERCIALE);
    this.eTipoPagProvvisionale = this.paymentConstants.getSingleEtipoPagamento(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_PROVVISIONALE);
    this.eTipoDefinizContParziale = this.paymentConstants.getSingleETipoDefinizCont(ENUMSLISTPAYMENT.ETIPODEFINIZCONT_PARZIALE);
    this.eSuccessService = this.paymentConstants.getSingleSuccessServer(ENUMSLISTPAYMENT.SUCCESS_SERVICE);
    this.eFailureService = this.paymentConstants.getSingleSuccessServer(ENUMSLISTPAYMENT.FAILURE_SERVICE);
    this.eTipoDefinizContAll = this.paymentConstants.ETIPODEFINIZCONT_ALL();
    this.eRoleLegaleAvv = this.paymentConstants.getSingleERoleLegaleAvversario(ENUMSLISTPAYMENT.EROLE_LEGALE_AVVERSARIO);
    this.eTipoQuietanzaAll = this.paymentConstants.ETIPOQUIETANZA_ALL();
    this.eTipoPagamentoAll = this.paymentConstants.ETIPOPAGAMENTO_ALL();
    this.eTipoCoassNoCoass = this.paymentConstants.getSingleETipoCoassicurazione(ENUMSLISTPAYMENT.ETIPOCOASS_NO_COASS);
    this.eTipoCoassDelegaAltrui = this.paymentConstants.getSingleETipoCoassicurazione(ENUMSLISTPAYMENT.ETIPOCOASS_DELEGA_ALTRUI);
    this.eTipoCoassDelegaNostra = this.paymentConstants.getSingleETipoCoassicurazione(ENUMSLISTPAYMENT.ETIPOCOASS_DELEGA_NOSTRA);
    this.eFeeType = this.paymentConstants.getSingleEFeeType();
  }



  ngOnInit() {
    this.initEnums();
    console.log('PaymentData - 3');
    this.enableAndOpenContent();
    this.setPanelIncomplete();
    this.createMultiCurrencyIsoCodes();
    this.getSystemProperty.findProperty(PaymentDataComponent.PROPERTY_ENABLED_CLAIMS_PAYMENT_URGENCY).then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (PaymentDataComponent.PROPERTY_ENABLED === variable.value) {
            this.isAbilitatoClaimsPaymentUrgency = true;
          }
        }
      }
    });
    this.utilityService.getTranslation('SI1024', null).subscribe(
      (res: ComboEntity) => {
        this.labelCoherence = res.description;
      },
      (error: any) => { });

    if (this.potPayFE.exposureList) {
      this.createBranchMap();
    }
  }
  createMultiCurrencyIsoCodes() {
    this.getSystemProperty.findProperty(PaymentDataComponent.PROPERTY_FOREIGN_PAYMENT_LIGHT).then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.value === 'Enable') {

            this.multyCurrencyEnabled = true;
            this.newPaymentService.getMulticurrencyIsoCodes().subscribe((response: any) => {

              this.multiCurrencyIsoCodes = response;
              this.newPayForm.controls.currencyCode.setValue(this.multiCurrencyIsoCodes.find(e =>
                e.idDivisa === 1).codiceDivisaISO, { onlySelf: true });

            },
              (error: any) => {
                this.eventService.broadcastEvent('stop-loader');
              });

          }

        }

      }

    });

  }
  createBranchMap() {
    this.mappaBranchExp = new Map<string, Array<any>>();
    this.listExpComboVisual = new Array<ExposureEntity>();
    this.newPayForm.controls.correctModalClose.setValue(true);

    this.potPayFE.exposureList.filter(aExp => aExp.visible).forEach(element => {
      if (this.mappaBranchExp.get(element.branchCode) == null) {
        this.mappaBranchExp.set(element.branchCode, new Array<ExposureEntity>());
      }
      this.mappaBranchExp.get(element.branchCode).push(element);
    });

    for (const key of this.mappaBranchExp.keys()) {
      const arrExp = this.mappaBranchExp.get(key);
      for (let index = 0; index < arrExp.length; index++) {
        const exp = arrExp[index];
        if (index === 0) {
          exp.branchTitle = exp.branchDescription;
        } else {
          exp.branchTitle = null;
        }
        this.listExpComboVisual.push(exp);
      }

    }
  }

  protected validateContent(): boolean {
    throw new Error('Method not implemented.');
  }

  protected storeData() {
    throw new Error('Method not implemented.');
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (this.acceptSignals) {
      if (panelSignal === PanelSignal.open) {
        this.enableAndOpenContent();
      }
    }
  }

  isActiveDynamicPayment(exp: ExposureEntity) {
    return exp.paymentFactors && exp.paymentFactors.length > 0;
  }

  openCalculator(exp: ExposureEntity) {
    const modalRef = this.modalService.open(CalculatorFactorComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.newPayFormModal = this.newPayForm;
    modalRef.componentInstance.exp = exp;
    modalRef.componentInstance.claimNumber = this.potPayFE.claimNumber;
  }

  loadTypeReceiptList() {
    this.potPayFE.receiptTypeListCombo = this.potPayFE.receiptTypeList;
    this.newPayForm.controls.receiptType.setValue('');
    if (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseLegali.eTypeCode ||
      this.newPayForm.controls.paymentType.value === this.eTipoPagamentoImpRegistro.eTypeCode ||
      this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtu.eTypeCode ||
      this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtp.eTypeCode ||
      !this.potPayFE.professionistList || this.potPayFE.professionistList.length === 0) {
      // SPESE LEGALI, IMPOSTE REGISTRO, SPESE CTU, SPESE CTP oppure non ci sono legali da pagare
      this.potPayFE.receiptTypeListCombo = this.potPayFE.receiptTypeList.filter(element => {
        return element.idObject === this.eQuietanzaStandard.eTypeCode;
      });
    } else if (this.newPayForm.controls.paymentType.value === this.eTipoPagamOffertaReale.eTypeCode ||
      this.newPayForm.controls.paymentType.value === this.eTipoPagIntComm.eTypeCode ||
      this.newPayForm.controls.paymentType.value === this.eTipoPagamRimborso.eTypeCode) {
      // OFFERTA REALE, INTERVENTO COMMERCIALE
      this.potPayFE.receiptTypeListCombo = this.potPayFE.receiptTypeList.filter(element => {
        return element.idObject !== this.eQuietanzaPropTrans.eTypeCode;
      });
    }

    if (!this.potPayFE.legaleComeAttoreAbilitato) {
      // evolutiva richiesta per potere gestire la scheda di calcolo correttamente
      this.potPayFE.receiptTypeListCombo = this.potPayFE.receiptTypeList.filter(element => {
        return element.idObject !== this.eQuietanzaOnorariSeparati.eTypeCode;
      });
    }


    this.exposureDefinition();

    if (this.newPayForm.controls.paymentType.value === this.eTipoPagProvvisionale.eTypeCode) {
      // la definizione deve essere parziale ---> inibire totale
      this.potPayFE.exposureList.filter(exp => exp.visible).forEach(expVis => {
        this.newPayForm.controls['expDefinition' + expVis.idCampo].setValue(this.eTipoDefinizContParziale);
        expVis.disableDefinition = true;

      });

    }

    if (this.newPayForm.controls.paymentType.value === this.eTipoPagamRimborso.eTypeCode) {
      this.newPayForm.controls.receiptType.setValue(this.eQuietanzaOmniaCompresi.eTypeCode);
      this.exposureDefinition();
    } else {
      this.newPayForm.controls.receiptType.setValue(this.eQuietanzaStandard.eTypeCode);
      this.exposureDefinition();
    }


  }

  onConfirm() {
    this.eventService.broadcastEvent('start-loader');
    this.saveEmitter.emit(false);
    this.errorMessage = null;

    this.potPayFE.managementPayment = this.newPayForm.controls.managementPayment.value;
    this.potPayFE.paymentType = this.newPayForm.controls.paymentType.value;
    this.potPayFE.procedureCode = this.newPayForm.controls.procedureCode.value;
    this.potPayFE.receiptDate = this.newPayForm.controls.receiptDate.value;
    this.potPayFE.receiptType = this.newPayForm.controls.receiptType.value;
    this.potPayFE.priority = this.newPayForm.controls.priority.value;
    this.potPayFE.dueDate = this.newPayForm.controls.dueDate.value;
    this.potPayFE.separateReceipt = this.newPayForm.controls.separateReceipt.value;
    this.potPayFE.mainSubStatus = this.selectedMainSubStatus;
    this.potPayFE.currencyCodeDate = this.newPayForm.controls.currencyCodeDate.value;
    this.potPayFE.currencyCode = this.newPayForm.controls.currencyCode.value;
    this.potPayFE.legalFeeCurrencyAmount = 0;
    this.potPayFE.currencyAmount = 0;
    this.potPayFE.note = this.newPayForm.controls.note.value;


    // for (let i = 0; i < this.potPayFE.exposureList.length; i++) {
    for (const aExp of this.potPayFE.exposureList) {
      // const aExp = this.potPayFE.exposureList[i];

      aExp.selected = this.newPayForm.controls['expSel' + aExp.idCampo].value;
      aExp.amount = this.newPayForm.controls['expAmount' + aExp.idCampo].value;
      aExp.definition = this.newPayForm.controls['expDefinition' + aExp.idCampo].value;
      aExp.legalFeeCurrencyAmount = aExp.amount;
      aExp.currencyAmount = (typeof (this.newPayForm.controls['currencyAmount' + aExp.idCampo].value) === 'object') ?
        0 : this.newPayForm.controls['currencyAmount' + aExp.idCampo].value;
      aExp.currencyCode = this.newPayForm.controls.currencyCode.value;
      aExp.currencyCodeDate = this.newPayForm.controls.currencyCodeDate.value;
      this.potPayFE.legalFeeCurrencyAmount = this.potPayFE.legalFeeCurrencyAmount + aExp.legalFeeCurrencyAmount;
      this.potPayFE.currencyAmount = this.potPayFE.currencyAmount + aExp.currencyAmount;

      if (aExp.substatus) {
        aExp.idSubstatusSel = this.newPayForm.controls['expIdSubstatus' + aExp.idCampo].value;
      }

      // for (let j = 0; j < aExp.subExposureList.length; j++) {
      if (aExp.subExposureList) {
        for (const aSubExp of aExp.subExposureList) {
          // const aSubExp = aExp.subExposureList[j];
          aSubExp.selected = this.newPayForm.controls['subExpSel' + aSubExp.idCampo].value;
          aSubExp.amount = this.newPayForm.controls['subExpAmount' + aSubExp.idCampo].value;
          aSubExp.legalFeeAmount = this.newPayForm.controls['subExpLegalFeeAmount' + aSubExp.idCampo].value;
          aSubExp.definition = this.newPayForm.controls['subExpDefinition' + aSubExp.idCampo].value;
        }
      }
    }

    /*
    if (this.validateContent()) {
      this.storeData();
    }
    */
    this.verify();

    if (this.errorMessage == null) {
      this.preparePayment();
      const payOrig = this.newPaymentService.getPaymentDB();

      if (payOrig) {
        this.potPayFE.paymentList[0].listBeneficiary = payOrig.listBeneficiary;
        this.potPayFE.paymentList[0].listPayee = payOrig.listPayee;
      }

      if(this.potPayFE.paymentList.filter(res => res.paymentType === '3').length > 0) {
        this.potPayFE.paymentList.filter(res => res.paymentType === '3').forEach(payment => {
          payment.currencyCode = this.potPayFE.currencyCode;
          payment.currencyCodeDate = this.potPayFE.currencyCodeDate;

          payment.exposureList.forEach(exp => {
            exp.currencyCode = this.potPayFE.currencyCode;
            exp.currencyCodeDate = this.potPayFE.currencyCodeDate;
          });
        });
      }

      this.newPaymentService.verifyPaymentData(this.potPayFE, this.authSrv.getOperator().liquidationCentre.code,
        this.authSrv.getOperator().objectId)
        .subscribe((responseVerify: any) => {
          console.log('verifyPaymentData - outcome:  ', responseVerify);
          if (responseVerify.code === this.eSuccessService.eTypeCode) {
            this.newPaymentService.savePhotoInitPotentialPayment(this.potPayFE)
              .subscribe((responseSave: any) => {
                console.log('savePhotoInitPotentialPayment - outcome:  ', responseSave);
              });

            // Cycle example
            this.setPanelComplete();
            this.disablePanelContent();
            this.stepperEventEmitter.emit(1);
            this.signalToNextPanel(PanelSignal.open);
            this.signalToNextPanel(PanelSignal.init);
            this.signalToPanel('beneficiary-payee', PanelSignal.init);
            if (responseVerify.descr) {
              this.errorMessage = responseVerify.descr;
            }
            // this.eventService.broadcastEvent('stop-loader');
          } else {
            // non sono stati superati i controlli lato server
            this.errorMessage = responseVerify.descr;
            this.eventService.broadcastEvent('stop-loader');
          }

        },
          (error: any) => {
            console.log('verifyPaymentData - outcome: ERRORE ');
            this.eventService.broadcastEvent('stop-loader');
          });

    } else {
      this.eventService.broadcastEvent('stop-loader');
    }

  }

  valDefinition(exp: ExposureEntity) {
    if (this.newPayForm.controls['expDefinition' + exp.idCampo].value) {
      return this.newPayForm.controls['expDefinition' + exp.idCampo].value;
    } else {
      return null;
    }
  }

  defDescription(code: string) {
    return this.eTipoDefinizContAll.find(adef => adef.eTypeCode === code).eTypeDescription;
  }

  preparePayment() {
    this.potPayFE.paymentList = new Array<PotentialPaymentEntity>();
    let idPay = 0;

    this.potPayFE.legaleComeAttoreAbilitato = Boolean(this.utilityService
      .getPISystemPorperty('SinistriAbilitazioneLegAvvAsAttCoinvolto'));

    const genPay = this.potPayFE.paymentTypeListCombo.find(aTyPy => aTyPy.eTypeCode === this.potPayFE.paymentType).generatePayment;

    if (this.potPayFE.actorRoleCode === this.eRoleLegaleAvv.eTypeCode) {
      // un oggetto PotentialPaymentEntity (primary=true) caricando all’interno tutte
      // le exposure selezionate --> in teoria si dovrebbe pagare una fattura per volta
      const aPotPay = new PotentialPaymentEntity();
      aPotPay.id = idPay;
      idPay++;
      aPotPay.paymentType = this.potPayFE.paymentType;
      aPotPay.paymentTypeDescr = this.eTipoPagamentoAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.paymentType)[0].eTypeDescription;
      aPotPay.receiptType = this.potPayFE.receiptType;
      aPotPay.receiptTypeDescr = this.eTipoQuietanzaAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.receiptType)[0].eTypeDescription;
      aPotPay.receiptDate = this.potPayFE.receiptDate;
      aPotPay.paymentManagement = this.potPayFE.managementPayment;
      aPotPay.procedureCode = this.potPayFE.procedureCode;
      aPotPay.exposureList = new Array<ExposureEntity>();
      aPotPay.listBeneficiary = new Array<SubjectEntity>();
      aPotPay.listPayee = new Array<SubjectEntity>();
      aPotPay.totalAmount = 0;
      aPotPay.totalAmountNet = 0;
      aPotPay.separateReceipt = this.potPayFE.separateReceipt;
      aPotPay.currencyAmount = this.potPayFE.currencyAmount;
      aPotPay.currencyCodeDate = this.potPayFE.currencyCodeDate;
      aPotPay.legalFeeCurrencyAmount = this.potPayFE.legalFeeCurrencyAmount;
      aPotPay.currencyCode = this.potPayFE.currencyCode;
      aPotPay.note = this.potPayFE.note;

      let i = 0;

      // setto temporaneament il metodo di pagamento
      aPotPay.methodPayment = new MethodPaymentEntity();
      // aPotPay.methodPayment.methodId = '4'; // assegno 1 assegno traenza

      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {

        if (i === 0) {
          aPotPay.exposureList.push(expSel);
          expSel.injured.primary = true;
          aPotPay.listBeneficiary.push(expSel.injured);
          if (expSel.beneficiaryDefault) {
            expSel.beneficiaryDefault.primary = true;
            aPotPay.listPayee.push(expSel.beneficiaryDefault);
          }
          aPotPay.generatePayment = expSel.feeData.generatePayment;
          // RDC-1155
          if(expSel.payeeList){
            expSel.payeeList.forEach(payeeList => {
              aPotPay.listPayee.push(payeeList);
            })
          }
          // END RDC-1155
        }
        expSel.amountNet = expSel.amount;
        aPotPay.editableBenefPayee = false;
        aPotPay.totalAmount += expSel.amount; // + expSel.amountTotal);
        aPotPay.totalAmountNet += expSel.amount; // + expSel.amountTotal);
        i++;
      });

      this.potPayFE.paymentList.push(aPotPay);

    } else if (
      this.newPayForm.controls.receiptType.value === this.eQuietanzaStandard.eTypeCode &&
      !(this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtp.eTypeCode ||
        this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtu.eTypeCode ||
        (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseLegali.eTypeCode &&
          !this.potPayFE.legaleComeAttoreAbilitato))) {
      // un oggetto PotentialPaymentEntity (primary=true) caricando all’interno tutte
      // le exposure selezionate ma il beneficiario e il percipiente devono essere modificabili
      const aPotPay = new PotentialPaymentEntity();
      aPotPay.id = idPay;
      idPay++;
      aPotPay.paymentType = this.potPayFE.paymentType;
      aPotPay.paymentTypeDescr = this.eTipoPagamentoAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.paymentType)[0].eTypeDescription;
      aPotPay.receiptType = this.potPayFE.receiptType;
      aPotPay.receiptTypeDescr = this.eTipoQuietanzaAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.receiptType)[0].eTypeDescription;
      aPotPay.receiptDate = this.potPayFE.receiptDate;
      aPotPay.paymentManagement = this.potPayFE.managementPayment;
      aPotPay.procedureCode = this.potPayFE.procedureCode;
      aPotPay.exposureList = new Array<ExposureEntity>();
      aPotPay.listBeneficiary = new Array<SubjectEntity>();
      aPotPay.listPayee = new Array<SubjectEntity>();
      aPotPay.totalAmount = 0;
      aPotPay.totalAmountNet = 0;
      aPotPay.generatePayment = genPay;
      aPotPay.separateReceipt = this.potPayFE.separateReceipt;
      aPotPay.currencyAmount = this.potPayFE.currencyAmount;
      aPotPay.currencyCodeDate = this.potPayFE.currencyCodeDate;
      aPotPay.legalFeeCurrencyAmount = this.potPayFE.legalFeeCurrencyAmount;
      aPotPay.currencyCode = this.potPayFE.currencyCode;
      aPotPay.note = this.potPayFE.note;
      let i = 0;

      // setto temporaneament il metodo di pagamento
      aPotPay.methodPayment = new MethodPaymentEntity();
      // aPotPay.methodPayment.methodId = '4'; // assegno 1 assegno traenza


      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
        expSel.amountNet = expSel.amount;
        aPotPay.exposureList.push(expSel);
        if (i === 0 && aPotPay.paymentType !== this.eTipoPagamentoImpRegistro.eTypeCode) {
          expSel.injured.primary = true;
          aPotPay.listBeneficiary.push(expSel.injured);
          // RDC-1155
          if(expSel.payeeList){
            expSel.payeeList.forEach(payeeList => {
              aPotPay.listPayee.push(payeeList);
            })
          }
          // END RDC-1155
          if (expSel.heirs && expSel.heirs.length > 0) {
            // i percipienti in caso di morte sono gli eredi
            expSel.heirs.forEach(hrs => {
              aPotPay.listPayee.push(hrs);
            });
          } else if (expSel.beneficiaryDefault) {
            expSel.beneficiaryDefault.primary = true;
            aPotPay.listPayee.push(expSel.beneficiaryDefault);
          }
        }
        aPotPay.editableBenefPayee = true;
        aPotPay.totalAmount += expSel.amount; // + expSel.amountTotal);
        aPotPay.totalAmountNet += expSel.amount; // + expSel.amountTotal);
        i++;
      });

      this.potPayFE.paymentList.push(aPotPay);
    } else if (
      this.newPayForm.controls.receiptType.value === this.eQuietanzaStandard.eTypeCode &&
      (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtp.eTypeCode ||
        this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtu.eTypeCode ||
        (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseLegali.eTypeCode &&
          !this.potPayFE.legaleComeAttoreAbilitato))) {
      // si deve generare un pagamento per ogni ogni sottoexposure selezionata
      // se è selezionata la sottoexposure è selezionata anche la copertura padre
      let i = 0;
      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
        expSel.subExposureList.filter(subExp => subExp.selected).forEach(subExpSel => {
          const aPotPay = new PotentialPaymentEntity();
          aPotPay.id = idPay;
          idPay++;
          aPotPay.paymentType = this.potPayFE.paymentType;
          aPotPay.paymentTypeDescr = this.eTipoPagamentoAll
            .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.paymentType)[0].eTypeDescription;
          aPotPay.receiptType = this.potPayFE.receiptType;
          aPotPay.receiptTypeDescr = this.eTipoQuietanzaAll
            .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.receiptType)[0].eTypeDescription;
          aPotPay.receiptDate = this.potPayFE.receiptDate;
          aPotPay.paymentManagement = this.potPayFE.managementPayment;
          aPotPay.procedureCode = this.potPayFE.procedureCode;
          aPotPay.exposureList = new Array<ExposureEntity>();
          aPotPay.listBeneficiary = new Array<SubjectEntity>();
          aPotPay.listPayee = new Array<SubjectEntity>();
          aPotPay.totalAmount = subExpSel.amount;
          aPotPay.totalAmountNet = subExpSel.amount;
          aPotPay.generatePayment = subExpSel.feeData.generatePayment;
          aPotPay.separateReceipt = this.potPayFE.separateReceipt;
          aPotPay.currencyAmount = this.potPayFE.currencyAmount;
          aPotPay.currencyCodeDate = this.potPayFE.currencyCodeDate;
          aPotPay.legalFeeCurrencyAmount = this.potPayFE.legalFeeCurrencyAmount;
          aPotPay.currencyCode = this.potPayFE.currencyCode;
          aPotPay.note = this.potPayFE.note;
          i++;

          // setto temporaneament il metodo di pagamento
          aPotPay.methodPayment = new MethodPaymentEntity();
          // aPotPay.methodPayment.methodId = '4'; // assegno 1 assegno traenza

          const tempExp = this.cloneExposure(expSel);
          tempExp.selected = true;
          tempExp.amount = subExpSel.amount;  // l'importo della fattura
          tempExp.amountNet = tempExp.amount;
          tempExp.amountTotal = 0;
          tempExp.legalFeeAmount = 0;
          tempExp.feeData = subExpSel.feeData;
          tempExp.injured.subjectLitigationType = subExpSel.injured.subjectLitigationType;

          if (i === expSel.subExposureList.filter(subExp => subExp.selected).length) {
            tempExp.definition = expSel.definition;
          } else {
            tempExp.definition = this.eTipoDefinizContParziale.eTypeCode;
          }
          tempExp.subExposureList = null;

          aPotPay.exposureList.push(tempExp);

          subExpSel.injured.primary = true;
          subExpSel.amountNet = subExpSel.amount;
          aPotPay.listBeneficiary.push(subExpSel.injured);
          if (subExpSel.beneficiaryDefault) {
            subExpSel.beneficiaryDefault.primary = true;
            aPotPay.listPayee.push(subExpSel.beneficiaryDefault);
          }
          // RDC-1155
          if(expSel.payeeList){
            expSel.payeeList.forEach(payeeList => {
              aPotPay.listPayee.push(payeeList);
            })
          }
          // END RDC-1155
          aPotPay.editableBenefPayee = false;
          i++;

          this.potPayFE.paymentList.push(aPotPay);

        });

      });

    } else if (
      this.newPayForm.controls.receiptType.value === this.eQuietanzaOmniaCompresi.eTypeCode) {
      // un oggetto PotentialPaymentEntity (primary=true) caricando all’interno tutte
      // le exposure selezionate
      const aPotPay = new PotentialPaymentEntity();
      aPotPay.id = idPay;
      idPay++;
      aPotPay.paymentType = this.potPayFE.paymentType;
      aPotPay.paymentTypeDescr = this.eTipoPagamentoAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.paymentType)[0].eTypeDescription;
      aPotPay.receiptType = this.potPayFE.receiptType;
      aPotPay.receiptTypeDescr = this.eTipoQuietanzaAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.receiptType)[0].eTypeDescription;
      aPotPay.receiptDate = this.potPayFE.receiptDate;
      aPotPay.paymentManagement = this.potPayFE.managementPayment;
      aPotPay.procedureCode = this.potPayFE.procedureCode;
      aPotPay.exposureList = new Array<ExposureEntity>();
      aPotPay.listBeneficiary = new Array<SubjectEntity>();
      aPotPay.listPayee = new Array<SubjectEntity>();
      aPotPay.totalAmount = 0;
      aPotPay.totalAmountNet = 0;
      aPotPay.separateReceipt = this.potPayFE.separateReceipt;
      aPotPay.generatePayment = genPay;
      aPotPay.currencyAmount = this.potPayFE.currencyAmount;
      aPotPay.currencyCodeDate = this.potPayFE.currencyCodeDate;
      aPotPay.legalFeeCurrencyAmount = this.potPayFE.legalFeeCurrencyAmount;
      aPotPay.currencyCode = this.potPayFE.currencyCode;
      aPotPay.note = this.potPayFE.note;
      let i = 0;

      // setto temporaneament il metodo di pagamento
      aPotPay.methodPayment = new MethodPaymentEntity();
      // aPotPay.methodPayment.methodId = '4'; // assegno 1 assegno traenza

      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
        aPotPay.exposureList.push(expSel);
        if (i === 0) {
          expSel.injured.primary = true;
          aPotPay.listBeneficiary.push(expSel.injured);
          if (expSel.beneficiaryDefault) {
            expSel.beneficiaryDefault.primary = true;
            aPotPay.listPayee.push(expSel.beneficiaryDefault);
          }
          // RDC-1155
          if(expSel.payeeList){
            expSel.payeeList.forEach(payeeList => {
              aPotPay.listPayee.push(payeeList);
            })
          }
          // END RDC-1155
        }
        expSel.amountNet = expSel.amount;
        aPotPay.editableBenefPayee = true;
        aPotPay.totalAmount += expSel.amount; // + expSel.amountTotal) ;
        aPotPay.totalAmountNet += expSel.amount;
        i++;
      });
      this.potPayFE.paymentList.push(aPotPay);

    } else if (
      this.newPayForm.controls.receiptType.value === this.eQuietanzaOnorariSeparati.eTypeCode) {
      let i = 0;
      // pagamenti di spese legali (una liquidazione per ogni legale selezionato)
      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
        expSel.subExposureList.filter(subExp => subExp.selected).forEach(subExpSel => {
          const aPotPayLeg = new PotentialPaymentEntity();
          aPotPayLeg.id = idPay;
          idPay++;
          aPotPayLeg.paymentType = this.eTipoPagamentoSpeseLegali.eTypeCode;
          aPotPayLeg.paymentTypeDescr = this.eTipoPagamentoSpeseLegali.eTypeDescription;
          aPotPayLeg.receiptType = this.eQuietanzaStandard.eTypeCode;
          aPotPayLeg.receiptTypeDescr = this.eQuietanzaStandard.eTypeDescription;
          aPotPayLeg.receiptDate = this.potPayFE.receiptDate;
          aPotPayLeg.paymentManagement = this.potPayFE.managementPayment;
          aPotPayLeg.procedureCode = this.potPayFE.procedureCode;
          aPotPayLeg.exposureList = new Array<ExposureEntity>();
          aPotPayLeg.listBeneficiary = new Array<SubjectEntity>();
          aPotPayLeg.listPayee = new Array<SubjectEntity>();
          aPotPayLeg.totalAmount = 0;
          aPotPayLeg.totalAmountNet = 0;
          aPotPayLeg.separateReceipt = this.potPayFE.separateReceipt;
          aPotPayLeg.generatePayment = subExpSel.feeData.generatePayment; // viene scelto dall'utente
          i++;

          // setto temporaneament il metodo di pagamento
          aPotPayLeg.methodPayment = new MethodPaymentEntity();
          // aPotPayLeg.methodPayment.methodId = '4'; // assegno 1 assegno traenza

          let tempExp: ExposureEntity;

          if (subExpSel.real) {
            tempExp = subExpSel;
            tempExp.amountNet = subExpSel.amount;
          } else {
            tempExp = this.cloneExposure(expSel);
            tempExp.selected = true;
            tempExp.amount = subExpSel.amount;
            tempExp.amountNet = subExpSel.amount;
            tempExp.feeData = subExpSel.feeData;
            tempExp.amountTotal = 0;
            tempExp.legalFeeAmount = 0;
            // si mette sempre P perchè la chiusura è demandata alla copertura dell'attore caricata sotto
            tempExp.definition = this.eTipoDefinizContParziale.eTypeCode;
            tempExp.injured.subjectLitigationType = subExpSel.injured.subjectLitigationType;
            tempExp.subExposureList = null;
          }

          aPotPayLeg.exposureList.push(tempExp);
          aPotPayLeg.generatePayment = subExpSel.feeData.generatePayment;

          subExpSel.injured.primary = true;
          aPotPayLeg.listBeneficiary.push(subExpSel.injured);
          if (expSel.beneficiaryDefault) {
            subExpSel.beneficiaryDefault.primary = true;
            aPotPayLeg.listPayee.push(subExpSel.beneficiaryDefault);
          }
          aPotPayLeg.editableBenefPayee = false;
          aPotPayLeg.totalAmount = subExpSel.amount;
          aPotPayLeg.totalAmountNet = subExpSel.amount;
          i++;

          this.potPayFE.paymentList.push(aPotPayLeg);

        });

      });

      // pagamento di indennizzo all'attore che definisce se la copertura è da chiudere
      // in base alla sua definizione

      const aPotPay = new PotentialPaymentEntity();
      aPotPay.id = idPay;
      idPay++;
      aPotPay.paymentType = this.potPayFE.paymentType;
      aPotPay.paymentTypeDescr = this.eTipoPagamentoAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.paymentType)[0].eTypeDescription;
      aPotPay.receiptType = this.potPayFE.receiptType;
      aPotPay.receiptTypeDescr = this.eTipoQuietanzaAll
        .filter(aTyPay => aTyPay.eTypeCode === this.potPayFE.receiptType)[0].eTypeDescription;
      aPotPay.receiptDate = this.potPayFE.receiptDate;
      aPotPay.paymentManagement = this.potPayFE.managementPayment;
      aPotPay.procedureCode = this.potPayFE.procedureCode;
      aPotPay.exposureList = new Array<ExposureEntity>();
      aPotPay.listBeneficiary = new Array<SubjectEntity>();
      aPotPay.listPayee = new Array<SubjectEntity>();
      aPotPay.totalAmount = 0;
      aPotPay.totalAmountNet = 0;
      aPotPay.separateReceipt = this.potPayFE.separateReceipt;
      aPotPay.generatePayment = genPay;
      aPotPay.currencyAmount = this.potPayFE.currencyAmount;
      aPotPay.currencyCodeDate = this.potPayFE.currencyCodeDate;
      aPotPay.legalFeeCurrencyAmount = this.potPayFE.legalFeeCurrencyAmount;
      aPotPay.currencyCode = this.potPayFE.currencyCode;
      aPotPay.note = this.potPayFE.note;
      i = 0;

      // setto temporaneament il metodo di pagamento
      aPotPay.methodPayment = new MethodPaymentEntity();
      // aPotPay.methodPayment.methodId = '4'; // assegno 1 assegno traenza

      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
        expSel.amountNet = expSel.amount;
        aPotPay.exposureList.push(expSel);
        if (i === 0) {
          expSel.injured.primary = true;
          aPotPay.listBeneficiary.push(expSel.injured);
          if (expSel.beneficiaryDefault) {
            expSel.beneficiaryDefault.primary = true;
            aPotPay.listPayee.push(expSel.beneficiaryDefault);
          }
          // RDC-1155
          if(expSel.payeeList){
            expSel.payeeList.forEach(payeeList => {
              aPotPay.listPayee.push(payeeList);
            })
          }
          // END RDC-1155
        }
        aPotPay.editableBenefPayee = true;
        aPotPay.totalAmount += expSel.amount; // + expSel.amountTotal);
        aPotPay.totalAmountNet += expSel.amount; // + expSel.amountTotal);
        i++;
      });

      this.potPayFE.paymentList.push(aPotPay);
    }
  }

  cloneExposure(expOrig: ExposureEntity): ExposureEntity {
    const expClone = Object.assign({}, expOrig);
    if (expOrig.injured) {
      const injClone = Object.assign({}, expOrig.injured);
      expClone.injured = injClone;
    }

    if (expOrig.feeData) {
      const feeClone = Object.assign({}, expOrig.feeData);
      expClone.feeData = feeClone;
    }

    return expClone;
  }

  verify() {
    this.errorMessage = null;

    if (!this.newPayForm.controls.correctModalClose.value) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._FEE_DATA_MISSING').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      return false;
    }

    if (this.potPayFE.paymentType == null ||
      this.potPayFE.receiptDate == null ||
      this.potPayFE.receiptType == null) {
      this.translateService.translate('_CLAIMS_._MESSAGE_._MANDATORY_DATA_MISSING').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      return false;
    }

    if (!this.potPayFE.exposureList.some(exp => exp.visible && exp.selected)) {
      // deve essere presente una copertura primaria selezionata
      this.translateService.translate('_CLAIMS_._MESSAGE_._YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS').subscribe(res=> {
        this.errorMessage=res;
      }
      )
      return false;
    }


    this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
      // per ogni copertura primaria selezionata --> deve essere presente la definizione e l'importo
      if (!expSel.disableDefinition && (expSel.definition === null || !expSel.definition)) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        return false;
      }

      if (expSel.substatus && !expSel.idSubstatusSel && expSel.definition === '1') {
        this.errorMessage = 'Mandatory data missing';
        this.translateService.translate('_CLAIMS_._MESSAGE_._MANDATORY_DATA_MISSING').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        return false;
      }

      if ((!expSel.disableAmount || expSel.feeDataNeeded) && (expSel.amount == null || !expSel.amount || expSel.amount <= 0)) {
        this.errorMessage = 'The amounts for the reasons selected';
        this.translateService.translate('_CLAIMS_._MESSAGE_._THE_AMOUNT_FOR_THE_REASON_SELECTED').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        return false;
      }

    });

    if (this.newPayForm.controls.receiptType.value === this.eQuietanzaOnorariSeparati.eTypeCode ||
      (this.newPayForm.controls.receiptType.value === this.eQuietanzaStandard.eTypeCode &&
        (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtp.eTypeCode ||
          this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtu.eTypeCode ||
          (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseLegali.eTypeCode &&
            !this.potPayFE.legaleComeAttoreAbilitato)))) {
      let almost1Fee = false;
      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
        // deve esistere almeno una sottocopertura selezionata (almeno un pagamento legale)
        if (expSel.subExposureList.some(subExp => subExp.visible && subExp.selected)) {
          almost1Fee = true;
        }

        expSel.subExposureList.filter(suex => suex.selected).forEach(suexSel => {
          // per ogni sotto copertura  selezionata deve essere valorizzato l'importo
          // indipendentemente dal fatto che il campo sia abilitato
          // questo copre anche il controllo di presenza dati fattura
          if (suexSel.amount == null || !suexSel.amount || suexSel.amount <= 0) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._THE_AMOUNT_FOR_THE_REASON_SELECTED').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            return false;
          }
          if (!suexSel.disableDefinition && (suexSel.definition == null || !suexSel.definition)) {
            this.translateService.translate('_CLAIMS_._MESSAGE_._SELECT_THE_DEFINITION_FOR_THE_SELECTED_CAUSALS').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            return false;
          }
        });

      });
      if (!almost1Fee) {
        this.errorMessage = 'You have to select at least one type of loss.';
        this.translateService.translate('_CLAIMS_._MESSAGE_._YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        return false;
      }
    } else if (
      this.newPayForm.controls.receiptType.value === this.eQuietanzaOmniaCompresi.eTypeCode) {

      this.potPayFE.exposureList.filter(exp => exp.selected).forEach(expSel => {
        // per ogni copertura primaria selezionata deve essere presente una sottocopertura selezionata
        if (!expSel.subExposureList.some(subExp => subExp.visible && subExp.selected)) {
          this.translateService.translate('_CLAIMS_._MESSAGE_._YOU_HAVE_TO_SELECT_AT_LEAST_ONE_TYPE_OF_LOSS').subscribe(res=> {
            this.errorMessage=res;
          }
          )

          return false;
        }

        if (expSel.amount < expSel.legalFeeAmount) {
          this.translateService.translate('_CLAIMS_._MESSAGE_._THE_AMOUNT_EXCEEDS_THE_TOTAL').subscribe(res=> {
            this.errorMessage=res;
          }
          )
          return false;
        }

        expSel.subExposureList.filter(suex => suex.selected).forEach(suexSel => {
          // per ogni sotto copertura  selezionata deve essere valorizzato l'importo omnia
          if (suexSel.legalFeeAmount == null || !suexSel.legalFeeAmount || suexSel.legalFeeAmount <= 0) {
            this.errorMessage = 'The amounts for the reasons selected';
            this.translateService.translate('_CLAIMS_._MESSAGE_._THE_AMOUNTS_FOR_THE_REASONS_SELECTED').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            return false;
          }

          if (suexSel.feeDataOmniaNeeded && !suexSel.feeDataOmniaOK) {
            this.errorMessage = 'Fee data missing';
            this.translateService.translate('_CLAIMS_._MESSAGE_._FEE_DATA_MISSING').subscribe(res=> {
              this.errorMessage=res;
            }
            )
            return false;
          }
        });

      });

    }

    if (this.potPayFE.antiFraudMessage !== '') {
      this.errorMessage = this.potPayFE.antiFraudMessage;
      return false;

    }

    return true;
  }

  receiptOmnia() {
    return this.potPayFE.receiptType === this.eQuietanzaOmniaCompresi.eTypeCode;
  }

  noCoass() {
    return this.potPayFE.coassType === this.eTipoCoassNoCoass.eTypeCode;
  }

  delegaNostra() {
    return this.potPayFE.coassType === this.eTipoCoassDelegaNostra.eTypeCode;
  }

  delegaAltrui() {
    return this.potPayFE.coassType === this.eTipoCoassDelegaAltrui.eTypeCode;
  }

  dataFee(idCampoExp: string, idCampoSubExp: string) {
    this.newPayForm.controls['expSel' + idCampoExp].setValue(true);
    if (idCampoSubExp) {
      this.newPayForm.controls['subExpSel' + idCampoSubExp].setValue(true);
    }
    let expSel: ExposureEntity;

    if (idCampoSubExp != null) {
      expSel = this.potPayFE.exposureList.find(exp => exp.idCampo === idCampoExp)
        .subExposureList.find(subExp => subExp.idCampo === idCampoSubExp);
    } else {
      expSel = this.potPayFE.exposureList.find(exp => exp.idCampo === idCampoExp);
    }

    if (expSel.feeData == null) {
      expSel.feeData = new LegalFeeEntity();
    }
    
    if (!expSel.feeData.taxRegime)
      expSel.feeData.taxRegime = expSel.injured.taxRegime;
    if (!expSel.feeData.idSoggLegal)
      expSel.feeData.idSoggLegal = expSel.injured.idSubject;
    if (!expSel.feeData.taxIvaRegime)
      expSel.feeData.taxIvaRegime = expSel.injured.taxIvaRegime;
    if (!expSel.feeData.country)
      expSel.feeData.country = expSel.injured.country;
    if (!expSel.feeData.legalCode)
      expSel.feeData.legalCode = expSel.injured.legalCode;
    if (!expSel.feeData.taxRegimeAggregate)
      expSel.feeData.taxRegimeAggregate = expSel.injured.taxRegimeAggregate;
    if (!expSel.feeData.taxIvaRegimeAggregate)
      expSel.feeData.taxIvaRegimeAggregate = expSel.injured.taxIvaRegimeAggregate;

    expSel.taxRegimeListEntity 

    const modalRef = this.modalService.open(FeeDataModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.newPayFormModal = this.newPayForm;
    modalRef.componentInstance.exp = expSel;
    modalRef.componentInstance.idCampoExp = idCampoExp;
    modalRef.componentInstance.idCampoSubExp = idCampoSubExp;
    modalRef.componentInstance.potPayFE = this.potPayFE;
    this.newPayForm.controls.correctModalClose.setValue(false);
  }


  dataFeeOmnia(idCampoExp: string, idCampoSubExp: string) {
    this.newPayForm.controls['expSel' + idCampoExp].setValue(true);
    if (idCampoSubExp) {
      this.newPayForm.controls['subExpSel' + idCampoSubExp].setValue(true);
    }
    let expSel: ExposureEntity;

    if (idCampoSubExp != null) {
      expSel = this.potPayFE.exposureList.find(exp => exp.idCampo === idCampoExp)
        .subExposureList.find(subExp => subExp.idCampo === idCampoSubExp);
    } else {
      expSel = this.potPayFE.exposureList.find(exp => exp.idCampo === idCampoExp);
    }

    if (expSel.feeDataOmnia == null) {
      expSel.feeDataOmnia = new LegalFeeOmniaEntity();
      expSel.feeDataOmnia.idSoggLegal = expSel.injured.idSubject;
    }

    const modalRef = this.modalService.open(InvoiceCtrlModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.newPayFormModal = this.newPayForm;
    modalRef.componentInstance.exp = expSel;
    modalRef.componentInstance.idCampoExp = idCampoExp;
    modalRef.componentInstance.idCampoSubExp = idCampoSubExp;
    modalRef.componentInstance.potPayFE = this.potPayFE;
    this.newPayForm.controls.correctModalClose.setValue(false);
  }

  selectedExp(idCampoExp: string) {
    if (this.newPayForm.controls['expAmount' + idCampoExp].value &&
      this.newPayForm.controls['expAmount' + idCampoExp].value !== 0) {
      this.newPayForm.controls['expSel' + idCampoExp].setValue(true);
    } else {
      this.newPayForm.controls['expSel' + idCampoExp].setValue(false);
    }

    this.errorMessage = null;
    if (this.newPayForm.controls.receiptType.value === this.eQuietanzaOmniaCompresi.eTypeCode) {
      this.potPayFE.exposureList.filter(exp => exp.idCampo === idCampoExp).forEach(expFnd => {
        let total = 0;
        // for (let j = 0; j < expFnd.subExposureList.length; j++) {
        for (const aSubExp of expFnd.subExposureList) {
          // const aSubExp = expFnd.subExposureList[j];

          total += this.newPayForm.controls['subExpLegalFeeAmount' + aSubExp.idCampo].value;

        }

        if (this.newPayForm.controls['expAmount' + expFnd.idCampo].value < total) {
          this.translateService.translate('_CLAIMS_._MESSAGE_._THE_AMOUNT_EXCEEDS_THE_TOTAL').subscribe(res=> {
            this.errorMessage=res;
          }
          )
        }
      });
    }
  }

  selectedExpFather(idCampoExp: string, idCanpoChild: string) {
    this.newPayForm.controls['expSel' + idCampoExp].setValue(false);
    this.potPayFE.exposureList.filter(exp => exp.idCampo === idCampoExp).forEach(expFnd => {

      for (const aSubExp of expFnd.subExposureList) {
        if (this.newPayForm.controls['subExpSel' + aSubExp.idCampo].value) {
          this.newPayForm.controls['expSel' + idCampoExp].setValue(true);
        }
      }
    });

  }

  calculateTotalAmount(idCampoExp: string) {
    this.potPayFE.exposureList.filter(exp => exp.idCampo === idCampoExp).forEach(expFnd => {
      let total = 0;
      // for (let j = 0; j < expFnd.subExposureList.length; j++) {
      for (const aSubExp of expFnd.subExposureList) {
        // const aSubExp = expFnd.subExposureList[j];

        total += this.newPayForm.controls['subExpAmount' + aSubExp.idCampo].value;

      }

      expFnd.amountTotal = total;

      if (total > 0) {
        this.newPayForm.controls['expSel' + expFnd.idCampo].setValue(true);
      }

    });

  }

  calculateTotalOmniaAmount(idCampoExp: string, idCampoSubExp: string) {
    this.errorMessage = null;
    this.potPayFE.exposureList.filter(exp => exp.idCampo === idCampoExp).forEach(expFnd => {
      let total = 0;
      // for (let j = 0; j < expFnd.subExposureList.length; j++) {
      for (const aSubExp of expFnd.subExposureList) {
        // const aSubExp = expFnd.subExposureList[j];

        total += this.newPayForm.controls['subExpLegalFeeAmount' + aSubExp.idCampo].value;
        if (aSubExp.idCampo === idCampoSubExp) {
          aSubExp.feeDataOmniaOK = false;
          this.newPayForm.controls['subExpSel' + idCampoSubExp].setValue(true);
        }
      }

      expFnd.legalFeeAmount = total;

      if (total > 0) {
        this.newPayForm.controls['expSel' + expFnd.idCampo].setValue(true);
      }

      if (this.newPayForm.controls['expAmount' + expFnd.idCampo].value < total) {
        this.translateService.translate('_CLAIMS_._MESSAGE_._THE_AMOUNT_EXCEEDS_THE_TOTAL').subscribe(res=> {
          this.errorMessage=res;
        }
        )
      }
    });

  }

  preCompile() {
    const payOrig = this.newPaymentService.getPaymentDB();

    if (payOrig) {
      // si devono prevalorizzare i campi
      this.newPayForm.controls.paymentType.setValue(payOrig.paymentType);
      this.loadTypeReceiptList();
      const recType = this.eTipoQuietanzaAll
        .filter(aTyPay => aTyPay.eTypeCode === payOrig.receiptType)[0];

      this.newPayForm.controls.receiptType.setValue(recType.eTypeCode);

      this.newPayForm.controls.managementPayment.setValue(payOrig.paymentManagement);
      this.newPayForm.controls.receiptDate.setValue(new Date(payOrig.receiptDate));
      //  this.newPayForm.controls.dueDate.setValue(new Date(''));

      this.newPayForm.controls.separateReceipt.setValue(payOrig.separateReceipt);
      this.newPayForm.controls.procedureCode.setValue(payOrig.procedureCode);

      this.newPayForm.controls.receiptType.setValue(recType.eTypeCode);
      for (const aExp of this.potPayFE.exposureList) {
        // const aExp = this.potPayFE.exposureList[i];
        const expOrig = payOrig.exposureList.find(ex => ex.idCausaleDanno === aExp.idCausaleDanno);
        if (expOrig) {
          this.newPayForm.controls['expSel' + aExp.idCampo].setValue(true);
          this.newPayForm.controls['expAmount' + aExp.idCampo].setValue(expOrig.amountNet + expOrig.amountInsured);
          this.newPayForm.controls['expDefinition' + aExp.idCampo].setValue(expOrig.definition);

          if (expOrig.feeData && aExp.subExposureList) {
            const aSubExp = aExp.subExposureList.find(el => el.idSoggettoLegal === expOrig.feeData.idSoggLegal);

            if (aSubExp) {
              aSubExp.feeData = expOrig.feeData;
              if (expOrig.feeData.feeType) {
                aSubExp.feeData.feeType = this.eFeeType.find(el => el.eTypeDescription === expOrig.feeData.feeType).eTypeCode;
              }

              aSubExp.feeData.taxRegime = aSubExp.injured.taxRegime;
              aSubExp.feeData.idSoggLegal = aSubExp.injured.idSubject;

              this.newPayForm.controls['subExpSel' + aSubExp.idCampo].setValue(true);
              this.newPayForm.controls['subExpAmount' + aSubExp.idCampo].setValue(expOrig.feeData.totalAmount);
              // this.newPayForm.controls['subExpLegalFeeAmount' + aSubExp.idCampo].value;
              this.newPayForm.controls['subExpDefinition' + aSubExp.idCampo].setValue(expOrig.definition);
            }
          }

        }
      }
    }

  }

  exposureDefinition() {
    this.errorMessage = null;
    // for (let i = 0; i < this.potPayFE.exposureList.length; i++) {
    for (const aExp of this.potPayFE.exposureList) {
      // const aExp = this.potPayFE.exposureList[i];
      this.newPayForm.controls['expSel' + aExp.idCampo].setValue(false);
      this.newPayForm.controls['expAmount' + aExp.idCampo].setValue(null);
      this.newPayForm.controls['expDefinition' + aExp.idCampo].setValue('');

      // for (let j = 0; j < aExp.subExposureList.length; j++) {
      if (aExp.subExposureList) {
        for (const aSubExp of aExp.subExposureList) {
          // const aSubExp = aExp.subExposureList[j];
          this.newPayForm.controls['subExpSel' + aSubExp.idCampo].setValue(false);
          this.newPayForm.controls['subExpAmount' + aSubExp.idCampo].setValue(null);
          this.newPayForm.controls['subExpLegalFeeAmount' + aSubExp.idCampo].setValue(null);
          this.newPayForm.controls['subExpDefinition' + aSubExp.idCampo].setValue('');
        }

        aExp.subExposureList.forEach(element => {
          element.visible = false;
        });
      }

      aExp.visible = false;
      aExp.selected = false;
      aExp.amount = 0;

      if (this.potPayFE.idCausaleOrigine == null ||
        (this.potPayFE.idCausaleOrigine && aExp.idCausaleDanno === this.potPayFE.idCausaleOrigine)) {

        if (this.potPayFE.actorRoleCode === this.eRoleLegaleAvv.eTypeCode) {
          aExp.feeDataNeeded = true;
          aExp.disableAmount = true;
          this.newPayForm.controls['expAmount' + aExp.idCampo].disable();
          aExp.feeDataOmniaNeeded = false;
          aExp.disableDefinition = false;
          this.newPayForm.controls['expDefinition' + aExp.idCampo].enable();
          aExp.disableSelection = false;
          this.newPayForm.controls['expSel' + aExp.idCampo].enable();
          aExp.disableOmniaAmount = true;
          aExp.visible = true;
        } else if (
          this.newPayForm.controls.receiptType.value === this.eQuietanzaStandard.eTypeCode &&
          !(this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtp.eTypeCode ||
            this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtu.eTypeCode ||
            (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseLegali.eTypeCode &&
              !this.potPayFE.legaleComeAttoreAbilitato))) {
          aExp.feeDataNeeded = false;
          aExp.disableAmount = false;
          this.newPayForm.controls['expAmount' + aExp.idCampo].enable();
          aExp.feeDataOmniaNeeded = false;
          aExp.disableDefinition = false;
          this.newPayForm.controls['expDefinition' + aExp.idCampo].enable();
          aExp.disableSelection = false;
          this.newPayForm.controls['expSel' + aExp.idCampo].enable();
          aExp.disableOmniaAmount = true;
          aExp.visible = true;
        } else if (
          this.newPayForm.controls.receiptType.value === this.eQuietanzaStandard.eTypeCode &&
          (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtp.eTypeCode ||
            this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtu.eTypeCode ||
            (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseLegali.eTypeCode &&
              !this.potPayFE.legaleComeAttoreAbilitato))) {
          aExp.feeDataNeeded = false;
          aExp.disableAmount = true;
          this.newPayForm.controls['expAmount' + aExp.idCampo].disable();
          aExp.feeDataOmniaNeeded = false;
          aExp.disableDefinition = false;
          this.newPayForm.controls['expDefinition' + aExp.idCampo].enable();
          aExp.disableSelection = true;
          this.newPayForm.controls['expSel' + aExp.idCampo].disable();
          aExp.disableOmniaAmount = true;
          aExp.visible = true;
          if (aExp.subExposureList) {
            aExp.subExposureList.forEach(subElement => {
              if ((this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtu.eTypeCode &&
                subElement.subExposureType === 'CTU') ||
                (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseCtp.eTypeCode &&
                  subElement.subExposureType === 'CTP') ||
                (this.newPayForm.controls.paymentType.value === this.eTipoPagamentoSpeseLegali.eTypeCode &&
                  subElement.subExposureType === 'LAV')) {
                subElement.visible = true;
              }

              if (subElement.visible) {
                subElement.feeDataNeeded = true;
                subElement.disableAmount = true;
                this.newPayForm.controls['subExpAmount' + subElement.idCampo].disable();
                subElement.feeDataOmniaNeeded = false;
                subElement.disableDefinition = true;
                this.newPayForm.controls['subExpDefinition' + subElement.idCampo].disable();
                subElement.disableSelection = false;
                this.newPayForm.controls['subExpSel' + subElement.idCampo].enable();
                subElement.disableOmniaAmount = true;
                this.newPayForm.controls['subExpLegalFeeAmount' + subElement.idCampo].disable();
              }
            });
          }
        } else if (
          this.newPayForm.controls.receiptType.value === this.eQuietanzaOmniaCompresi.eTypeCode) {
          aExp.feeDataNeeded = false;
          aExp.disableAmount = false;
          this.newPayForm.controls['expAmount' + aExp.idCampo].enable();
          aExp.feeDataOmniaNeeded = false;
          aExp.disableDefinition = false;
          this.newPayForm.controls['expDefinition' + aExp.idCampo].enable();
          aExp.disableSelection = false;
          this.newPayForm.controls['expSel' + aExp.idCampo].enable();
          aExp.disableOmniaAmount = true;
          aExp.visible = true;
          if (aExp.subExposureList) {
            aExp.subExposureList.forEach(subElement => {
            if (subElement.subExposureType === 'LAV'
              || this.newPayForm.controls.paymentType.value === this.eTipoPagamRimborso.eTypeCode) {
                subElement.visible = true;
              }

              if (subElement.visible) {
                subElement.feeDataNeeded = false;
                subElement.disableAmount = true;
                this.newPayForm.controls['subExpAmount' + subElement.idCampo].disable();
                subElement.feeDataOmniaNeeded = Boolean(this.utilityService
                  .getPISystemPorperty('ClaimsInvoiceDataControl'));
                subElement.disableOmniaAmount = false;
                this.newPayForm.controls['subExpLegalFeeAmount' + subElement.idCampo].enable();
                subElement.disableDefinition = true;
                this.newPayForm.controls['subExpDefinition' + subElement.idCampo].disable();
                subElement.disableSelection = false;
                this.newPayForm.controls['subExpSel' + subElement.idCampo].enable();
              }
            });
          }
        } else if (
          this.newPayForm.controls.receiptType.value === this.eQuietanzaOnorariSeparati.eTypeCode) {
          aExp.feeDataNeeded = false;
          aExp.disableAmount = false;
          this.newPayForm.controls['expAmount' + aExp.idCampo].enable();
          aExp.feeDataOmniaNeeded = false;
          aExp.disableDefinition = false;
          this.newPayForm.controls['expDefinition' + aExp.idCampo].enable();
          aExp.disableOmniaAmount = true;
          aExp.disableSelection = false;
          this.newPayForm.controls['expSel' + aExp.idCampo].enable();
          aExp.visible = true;

          if (aExp.subExposureList) {
            aExp.subExposureList.forEach(subElement => {
              if (subElement.subExposureType === 'LAV') {
                subElement.visible = true;
              }

              if (subElement.visible) {
                subElement.feeDataNeeded = true;
                subElement.disableAmount = true;
                this.newPayForm.controls['subExpAmount' + subElement.idCampo].disable();
                subElement.feeDataOmniaNeeded = false;
                subElement.disableOmniaAmount = true;
                this.newPayForm.controls['subExpLegalFeeAmount' + subElement.idCampo].disable();
                subElement.disableDefinition = !subElement.real;
                if (!subElement.real) {
                  this.newPayForm.controls['subExpDefinition' + subElement.idCampo].disable();
                } else {
                  this.newPayForm.controls['subExpDefinition' + subElement.idCampo].enable();
                }
                subElement.disableSelection = false;
                this.newPayForm.controls['subExpSel' + subElement.idCampo].enable();
              }
            });
          }
        }
      }
    }

    this.createBranchMap();

  }

  onMainSubStatusChange(id: string) {
    this.selectedMainSubStatus = id;

  }
  setProcedureSelected = (evento: any): void => {
    this.procedureCodeSelected = evento;
  }
  generateMinDate(data: Date): NgbDate {
    const date = new Date(data);
    return new NgbDate(date.getFullYear(), date.getMonth(), date.getDate());
  }
}
