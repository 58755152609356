import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of} from "rxjs";
import { CacheService } from "../../common/service/cache.service";
import { PathUtil } from "../../common/util/path-util";
import { MessageUtil } from "../../common/util/message-util";
import { EnviromentsService } from "@rgi/life-issue-card";
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { PartyNiceActimizeCheckResponses } from "../model/generated/partyNiceActimizeCheckResponses";
import { CommonErrorResponse, TypeCheck } from "../../common/model/models";
import { PartyNiceActimizeCheck } from "../model/models";
import { catchError, map } from "rxjs/operators";
import { ObjectUtil } from "../../common/util/object-util";

@Injectable()

export class PartiesService {

  pathUtil: PathUtil;
  messageUtil: MessageUtil;
  traslationMap: Map<string,string> = new Map<string,string>;
  MSG_NICE_ACTIMIZE_KO = '_IIAB_._MOTOR_._NICE_ACTIMIZE_KO_';
  //Preloaded traslation
  HEAD_NICE_ACTIMIZE_HIT_FOUND = '_NICE_ACTIMIZE_._HIT_FOUND';
  HEAD_NICE_ACTIMIZE__HIT_NOT_FOUND = '_NICE_ACTIMIZE_._HIT_NOT_FOUND';
  CONTRACT_EMITTABLE = 'CONTRACT_EMITTABLE';
  CONTRACT_EMITTABLE_WITH_FORCE = 'CONTRACT_EMITTABLE_WITH_FORCE';


  EMITTABLE

  constructor(
    @Inject('environment') apiConf: any,
    protected environment: EnviromentsService,
    protected translate: RgiRxTranslationService,
    private cacheService: CacheService,
    private httpClient: HttpClient,
  ) {
    this.pathUtil = new PathUtil(apiConf);
    this.messageUtil = new MessageUtil(translate);
    this.messageUtil.pushTraslationMap(this.HEAD_NICE_ACTIMIZE_HIT_FOUND,this.traslationMap);
    this.messageUtil.pushTraslationMap(this.HEAD_NICE_ACTIMIZE__HIT_NOT_FOUND,this.traslationMap);
  }


getPartyChecksResult(hasHits: boolean) {
    return hasHits ? this.traslationMap.get(this.HEAD_NICE_ACTIMIZE_HIT_FOUND) : this.traslationMap.get(this.HEAD_NICE_ACTIMIZE__HIT_NOT_FOUND);
}

getPartyChecksTypeCheck(typeCheck: TypeCheck) {
    let result = 'Unknow';
    if (TypeCheck.CTF === typeCheck) {
        result = 'CTF';
    }
    if (TypeCheck.PEP === typeCheck) {
        result = 'PEP';
    }

    return result;
}

getPartyChecksData(partyNiceActimizeResponses: PartyNiceActimizeCheckResponses): PartyNiceActimizeCheck [] {
  const result : PartyNiceActimizeCheck [] = [];
  if (partyNiceActimizeResponses) {
      for (const partyResponse of partyNiceActimizeResponses.content.partychecks) {
          result.push(partyResponse.content);
      }
  }    

  return result;
}

  public handleCommonOperationResponse(response: any, logInfo = '', isThrowIfEmpty = true, isThrowIfError = false): string {

    return this.messageUtil.handleCommonOperationResponse(response, logInfo,isThrowIfEmpty, isThrowIfError);
 }
 public getNiceActimizeMessages(res: PartyNiceActimizeCheckResponses): string[] {
    const messages: string[] = []
    for (const partyResponse of res.content.partychecks) {
      messages.push(...this.getMessageUtil().getErrorsAsSimpleStrings(partyResponse.messages.messages));
    }
    for (const partyResponse of res.content.partychecks) {
        messages.push(...this.getMessageUtil().getWarningsAsSimpleStrings(partyResponse.messages.messages));
        messages.push(...this.getMessageUtil().getInfosAsSimpleStrings(partyResponse.messages.messages));
    }

    return messages;
 }

 isEnabledForceEmittable(res: PartyNiceActimizeCheckResponses): boolean {
  return this.isValidPartyNiceActimizeCheckResponses(res) && this.CONTRACT_EMITTABLE_WITH_FORCE === res.content.emittable
 }


 isValidPartyNiceActimizeCheckResponses(res: PartyNiceActimizeCheckResponses): boolean {
  return !!res && !!res.content
 }
 isAuthorizationEmittable(res: PartyNiceActimizeCheckResponses) {
   return this.isValidPartyNiceActimizeCheckResponses(res) && this.CONTRACT_EMITTABLE === res.content.emittable;
 }

 getPartyChecksColumns(): string[] {
  return ['nominative','birthDate','result','typeCheck','idRequest'];
 }


  public getMessageUtil() : MessageUtil {
    return this.messageUtil;
  }
  public getPartyNiceActimizeCheck(idAuth: string, useCache = false): Observable<PartyNiceActimizeCheckResponses | CommonErrorResponse> {

    if (useCache) {
      return this.cacheService.getResource(this.getPath(idAuth),this.getCacheContext());
    }

    return this.httpClient.get<PartyNiceActimizeCheckResponses | CommonErrorResponse>(this.getPath(idAuth));
  }

  getPath(idAuth: string): string {
    return this.pathUtil.getPathNiceActimizeCheck(idAuth);
  }

  private getCacheContext() {
    return 'PartyService';
  }

  getPartyNiceActimizeCheckWithMessages(authId: any, messages: string[]): Observable<PartyNiceActimizeCheckResponses | CommonErrorResponse> {

 
    return this.getPartyNiceActimizeCheck(authId).pipe(
        catchError(error => {
          console.log(error);
          this.getMessageUtil().pushTraslatedMessages(this.MSG_NICE_ACTIMIZE_KO,messages)
          return of(this.getMessageUtil().getCommonResponse(this.MSG_NICE_ACTIMIZE_KO));
        }),
        map(res => {
        let msgErr  = this.handleCommonOperationResponse(res);
        if (msgErr) {
          this.getMessageUtil().pushTraslatedMessages(this.MSG_NICE_ACTIMIZE_KO,messages)
          if (this.MSG_NICE_ACTIMIZE_KO != msgErr) {
            this.getMessageUtil().pushTraslatedMessages(msgErr,messages)
          } 
          return this.getMessageUtil().getCommonResponse(this.MSG_NICE_ACTIMIZE_KO);
       }

        let niceActimizePartyMessages = null;
        const partyNiceActimizeResponses =  res as PartyNiceActimizeCheckResponses
        niceActimizePartyMessages = this.getNiceActimizeMessages(partyNiceActimizeResponses);
        if (ObjectUtil.isEmptyArray(niceActimizePartyMessages)) {
            this.getMessageUtil().pushTraslatedMessages(this.MSG_NICE_ACTIMIZE_KO,messages)
        }
        return partyNiceActimizeResponses;
 
  }));

}

}
