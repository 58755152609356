import {SharedComponentsTranslationKeys} from './translation.keys';

export const CA_ES: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'CAES_Add',
      ADD_DOCUMENT: 'CAES_Add document',
      ARCHIVE: 'CAES_Archive',
      ARCHIVED: 'CAES_Archived',
      ARCHIVE_DOC: 'CAES_Archive document',
      ARCHIVING_USER: 'CAES_Archiving User',
      CANCEL: 'CAES_Cancel',
      CLOSE: 'CAES_Close',
      CODE: 'CAES_Code',
      CONFIRM: 'CAES_Confirm',
      CREATED: 'CAES_Created',
      DELETE_DOC: 'CAES_Delete document',
      DELIVERED: 'CAES_Delivered',
      DELIVERY_DOC: 'CAES_Delivery document',
      DELIVERY_ADDRESS: 'CAES_Delivery Address',
      DELIVERY_PARTY: 'CAES_Delivery Party',
      DELIVERY_USER: 'CAES_Delivery User',
      DELIVERY_TYPE: 'CAES_Delivery Type',
      DESCRIPTION: 'CAES_Description',
      DOCUMENTS_MANAGER: 'CAES_Documents Manager',
      DOCUMENT_TYPE: 'CAES_Document type',
      DOCUMENT_VALIDATION_MESSAGE: 'CAES_Proceed with the validation of the document?',
      DOWNLOAD: 'CAES_Download',
      DOWNLOAD_DOC: 'CAES_Download document',
      EDIT: 'CAES_Edit',
      FILE: 'CAES_File',
      ERROR: 'CAES_Error',
      INPUT_DOCUMENTS: 'CAES_Input Documents',
      LOAD: 'CAES_LOAD',
      NO: 'CAES_No',
      NOTE: 'CAES_Note',
      NO_FILES: 'CAES_No files',
      NO_SIGN_NEEDED: 'CAES_Document does not require signature',
      NOT_DELIVERED: 'CAES_Not delivered',
      NOT_SIGNED: 'CAES_Not signed',
      NUMBER_OF_FILES_INSERTED: 'CAES_{{ filesLength }} of {{ fileMax }} files inserted',
      OUTBOUND_DOC_TITLE: 'CAES_Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'CAES_Proposal documents nr. {{ proposalNumber }} of movement {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'CAES_Output Documents',
      PENDING_SIGNATURE_TOOLTIP: 'CAES_Document waiting for signature. Click to stop the process',
      REFRESH: 'CAES_Refresh',
      SAVE: 'CAES_SAVE',
      SEND: 'CAES_Send',
      SIGN: 'CAES_Sign',
      SIGN_DOCUMENT: 'CAES_Sign document',
      SIGNED: 'CAES_Signed',
      SIGNED_DOCUMENT: 'CAES_Document signed',
      SUBJECT_SENDING_DATE: 'CAES_Sending date to registry subject',
      STATE: 'CAES_Status',
      TYPE: 'CAES_Type',
      TO_BE_GENERATED: 'CAES_To be generated',
      TO_BE_UPLOADED: 'CAES_To be uploaded',
      UPDATE_FILE: 'CAES_Update file',
      UPLOAD: 'CAES_UPLOAD',
      UPLOADED: 'CAES_Uploaded',
      UPLOAD_NEW_DOCUMENT: 'CAES_Upload new document',
      UPLOADING_USER: 'CAES_Uploading User',
      VALIDATING_USER: 'CAES_Validation User',
      VALIDATED: 'CAES_Validated',
      VALIDATION: 'CAES_Validation',
      VALIDATION_DOCUMENT: 'CAES_Validation document',
      YES: 'CAES_Yes',
      NO_DOCUMENTS_CONFIGURED: 'CAES_There are no configured documents for this operation'
    }
};
