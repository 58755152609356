export enum CONTEXT_TYPE {
    CONTRACT = 'Contract',
    PARTY  = 'Party'
}


export enum ExtensionPropertyChExt {
  DERISK_SELECTED = 'ext_derisk_selected',
  PARTY_QUESTIONNAIRE = 'PARTY_QUESTIONNAIRE',
  SHOW_ADJUST_PROPOSAL = 'show_adjust_proposal',
  SHOW_COUNTER_PROPOSAL = 'show_counter_proposal',
  COUNTER_PROPOSAL_VALUE = 'counter_proposal_value'

}

export enum SaveActionsChExt {
  SAVE_ADJUST_PROPOSAL = 'adjustProposal',
  COUNTER_PROPOSAL = 'counterProposal'
}
