<rgi-rx-expansion-panel [expanded]="true" #first color="info" *ngIf="isAccordion && showCircumstanceForContext">
    <!-- Intestazione / Titolo -->
    <div rgi-rx-expansion-panel-header>
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-md-3">
                <label ng-bind="label" class="title_accordion mr-3rem">
                    {{ '_CLAIMS_._ACCIDENT_CIRCUMSTANCE' | translate }}
                </label>
            </div>
            <div class="col-md-9" *ngIf="!isOneVehicle">
                <button rgi-rx-button variant="dashed" color="secondary" (click)="openModalBarems()">
                    {{ '_CLAIMS_._BAREMS' | translate }} <span class="rgifont rgi-pencil"></span>
                </button>
            </div>
        </div>
    </div>
    <!-- Form -->
    <ng-container *ngTemplateOutlet="containerAccidents"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion && showCircumstanceForContext">
    <div class="row rgi-claims-sub-title-section-shared ">
        <div class="col-xs-12 col-md-12">
            <label ng-bind="label">
                {{ '_CLAIMS_._ACCIDENTS' | translate }}
            </label>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="containerAccidents"></ng-container>
</div>

<ng-template #containerAccidents>
    <form [formGroup]="dataForm" *ngIf="dataForm">

            <!-- Form un veicolo - INIZIO -->
            <div *ngIf="isOneVehicle" class="form-group row d-flex align-items-center" style="vertical-align: middle;">
                <!-- Percentuale responsabilità assicurato -->
                <div class="col-md-4 col-sm-3 col-xs-3">
                    <rgi-rx-form-field>
                        <input rgiRxInput formControlName="percInsuredLiability" id="percInsuredLiability"
                            name="percInsuredLiability"
                            custom-pattern="100((\.|\,)0{0,1})?$|^\d{0,2}((\.|\,)\d{0,1})?" type="number" min="0"
                            max="100" (blur)="outputFormChange()">
                        <label rgiRxLabel>{{ '_CLAIMS_._INSURED_LIABILITY_PERC' | translate }}</label>
                    </rgi-rx-form-field>
                </div>

                <!-- Tipo firma -->
                <div class="col-md-4 col-sm-3 col-xs-3">
                    <rgi-rx-form-field>
                        <label rgiRxLabel>{{ '_CLAIMS_._SIGN_TYPE' | translate }}</label>
                        <select rgiRxNativeSelect id="signatureType" name="signatureType" (change)="outputFormChange()"
                            attr.data-qa="dsc-circumstance-signatureType-input" formControlName="signatureType">
                            <option value=""></option>
                            <option *ngFor="let st of dataCombo.comboSignatureType;" value="{{st.codice}}">
                                {{st.descrizione}}</option>
                        </select>
                    </rgi-rx-form-field>
                </div>

                <!-- Negazione evento -->
                <div class="col-md-4 col-sm-3 col-xs-3" style="padding-top: 20px;">
                    <rgi-rx-form-field>
                        <input rgiRxInput type="checkbox" formControlName="eventDenial" id="eventDenial"
                            (change)="outputFormChange()" name="eventDenial"
                            [disabled]="!circumstanceIncident.eventDenialEnabled">
                        <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._EVENT_DENIAL' | translate }}</label>
                    </rgi-rx-form-field>
                </div>
            </div>
            <!-- Form un veicolo - FINE -->

            <div class="form-group row" *ngIf="this.localContext == 'ES' && isOneVehicle && !isNewClaim">
                <div class="col-sm-4 col-xs-4">
                    <rgi-rx-form-field>
                        <input rgiRxInput formControlName="version" id="version"
                            name="version" type="string" (blur)="outputFormChange()">
                        <label rgiRxLabel>{{ '_CLAIMS_._VERSION' | translate }}</label>
                    </rgi-rx-form-field>
                </div>
            </div>
            
            <div class="form-group row" *ngIf="this.localContext == 'ES' && isOneVehicle && !isNewClaim">
                <div class="col-sm-4 col-xs-4">
                    <rgi-rx-form-field>
                        <label rgiRxLabel>{{ '_CLAIMS_._DECLARED_LIABILITY' | translate }}</label>
                        <select rgiRxNativeSelect id="liabilityDeclared" name="liabilityDeclared"
                            attr.data-qa="dsc-circumstance-liabilityDeclared-input" (change)="outputFormChange()"
                            formControlName="liabilityDeclared">
                            <option value=""></option>
                            <option *ngFor="let cfl of dataCombo.comboForcedLiability;" value="{{cfl.codice}}">
                                {{cfl.descrizione}}</option>
                        </select>
                    </rgi-rx-form-field>
                </div>
            </div>
            
            <!-- Form più di un veicolo - INIZIO -->
            <div *ngIf="!isOneVehicle">

                <div class="form-group row">
                    <!-- Caso nostro veicolo -->
                    <div>
                      <div class="col-sm-12 col-xs-12">
                        <label rgiRxLabel class="mr-1">
                          {{ '_CLAIMS_._INSURED_VEICHLE_POSITION' | translate }}:
                          <font color="red" *ngIf="circumstanceIncident.baremEntityARequired">*</font>
                        </label>
                        <label rgiRxLabel class="bold">
                          {{ circumstanceIncident.baremEntityA?.barem.descrizione }}
                        </label>
                      </div>
                       <!-- <div class="col-sm-2 col-xs-2">
                            <label rgiRxLabel class="mr-1">
                                {{ '_CLAIMS_._INSURED_VEICHLE_POSITION' | translate }}:
                                <font color="red" *ngIf="circumstanceIncident.baremEntityARequired">*</font>
                            </label>
                        </div>
                        <div class="col-sm-9 col-xs-2 mb-05">
                            <label rgiRxLabel class="bold">
                                {{ circumstanceIncident.baremEntityA?.barem.descrizione }} </label>
                        </div> -->

                    </div>

                    <!-- Caso veicolo controparte -->
                    <div>
                        <div class="col-sm-12 col-xs-12">
                            <label rgiRxLabel class="mr-1">
                                {{ '_CLAIMS_._THIRD_PART_VEICHLE_POSITION' | translate }}:
                                <font color="red" *ngIf="circumstanceIncident.baremEntityBRequired">*</font>
                            </label>
                            <label rgiRxLabel class="bold">
                              {{ circumstanceIncident.baremEntityB?.barem.descrizione }}
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <!-- Ragione/Torto definita da sistema -->
                    <div class="col-sm-3 col-xs-3" *ngIf="italianContext">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._CALCULATED_LIABILITY' | translate }}</label>
                            <select rgiRxNativeSelect id="liabilityBySystem" name="liabilityBySystem"
                                attr.data-qa="dsc-circumstance-liabilityBySystem-input"
                                formControlName="liabilityBySystem" (change)="outputFormChange()">
                                <option value=""></option>
                                <option *ngFor="let cfl of dataCombo.comboForcedLiability;" value="{{cfl.codice}}">
                                    {{cfl.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>

                    <!-- Tipo di responsabilità -->
                    <div class="col-sm-3 col-xs-3" *ngIf="this.localContext != 'ES'">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._LIABILITY_TYPE' | translate }}</label>
                            <select rgiRxNativeSelect id="liabilityType" name="liabilityType"
                                (change)="outputFormChange()" attr.data-qa="dsc-circumstance-liabilityType-input"
                                formControlName="liabilityType">
                                <option value=""></option>
                                <option *ngFor="let crt of dataCombo.comboResponsibilityType;" value="{{crt.codice}}">
                                    {{crt.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>

                    <!-- Percentuale responsabilità assicurato -->
                    <div class="col-sm-3 col-xs-3">
                        <rgi-rx-form-field>
                            <input rgiRxInput formControlName="percInsuredLiability" id="percInsuredLiability"
                                name="percInsuredLiability"
                                custom-pattern="100((\.|\,)0{0,1})?$|^\d{0,2}((\.|\,)\d{0,1})?" type="number" min="0"
                                max="100" (blur)="outputFormChange()">
                            <label rgiRxLabel>{{ '_CLAIMS_._INSURED_LIABILITY_PERC' | translate }}</label>
                        </rgi-rx-form-field>
                    </div>

                    <!-- Percentuale responsabilità controparte -->
                    <div class="col-sm-3 col-xs-3">
                        <rgi-rx-form-field>
                            <input rgiRxInput formControlName="percOtherPartyLiability" id="percOtherPartyLiability"
                                name="percOtherPartyLiability"
                                [disabled]="!circumstanceIncident.percOtherPartyLiabilityEnabled"
                                custom-pattern="100((\.|\,)0{0,1})?$|^\d{0,2}((\.|\,)\d{0,1})?" type="number" min="0"
                                max="100" (blur)="outputFormChange()">
                            <label rgiRxLabel>{{ '_CLAIMS_._COUNTERPART_LIABILITY_PERC' | translate }}</label>
                        </rgi-rx-form-field>
                    </div>
                </div>

                <div class="form-group row" *ngIf="this.localContext == 'ES'">
                    <div class="col-sm-3 col-xs-3">
                        <rgi-rx-form-field>
                            <input rgiRxInput formControlName="version" id="version"
                                name="version" type="string" (blur)="outputFormChange()">
                            <label rgiRxLabel>{{ '_CLAIMS_._VERSION' | translate }}</label>
                        </rgi-rx-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <!-- Responsabilità dichiarata -->
                    <div class="col-sm-4 col-xs-4">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._DECLARED_LIABILITY' | translate }}</label>
                            <select rgiRxNativeSelect id="liabilityDeclared" name="liabilityDeclared"
                                attr.data-qa="dsc-circumstance-liabilityDeclared-input" (change)="outputFormChange()"
                                formControlName="liabilityDeclared">
                                <option value=""></option>
                                <option *ngFor="let cfl of dataCombo.comboForcedLiability;" value="{{cfl.codice}}">
                                    {{cfl.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>

                    <!-- Classificazione ANIA -->
                    <div class="col-sm-4 col-xs-4" *ngIf="this.localContext != 'ES'">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._ANIA_CLASSIFICATION' | translate }}</label>
                            <select rgiRxNativeSelect id="classificationType" name="classificationType"
                                attr.data-qa="dsc-circumstance-classificationType-input" (change)="outputFormChange()"
                                formControlName="classificationType">
                                <option value=""></option>
                                <option *ngFor="let cct of dataCombo.comboClassificationType;" value="{{cct.codice}}">
                                    {{cct.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>

                    <!-- Tipo firma -->
                    <div class="col-sm-4 col-xs-4">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._SIGN_TYPE' | translate }}</label>
                            <select rgiRxNativeSelect id="signatureType" name="signatureType"
                                (change)="outputFormChange()" attr.data-qa="dsc-circumstance-signatureType-input"
                                formControlName="signatureType">
                                <option value=""></option>
                                <option *ngFor="let cst of dataCombo.comboSignatureType;" value="{{cst.codice}}">
                                    {{cst.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <!-- Applica forzatura percentuale responsabilità -->
                    <div class="col-md-4 col-xs-4 col-sm-4">
                        <rgi-rx-form-field class="pt-1">
                            <input rgiRxInput type="checkbox" formControlName="forcingTechnicalResponsibility"
                                id="forcingTechnicalResponsibility" name="forcingTechnicalResponsibility"
                                (change)="enableForcingTechnicalResponsibility($event.target)"
                                [disabled]="!circumstanceIncident.forcingTechnicalResponsibilityEnabled">
                            <label rgiRxLabel class="pr-2">
                                {{ '_CLAIMS_._APPLY_TECH_LIABILITY_FORCED' | translate }}</label>
                        </rgi-rx-form-field>
                    </div>
                    <!-- Note forzatura civilistica -->
                    <div class="col-md-4 col-xs-4 col-sm-4" *ngIf="enableNote">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                            <textarea rgiRxInput formControlName="notes" id="notes" name="notes"
                                attr.data-qa="dsc-injury-code-notes-input" (keyup)="outputFormChange()"></textarea>
                        </rgi-rx-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <!-- Applica forzatura -->
                    <div class="col-md-4 col-xs-4 col-sm-4" *ngIf="this.localContext != 'ES'">
                        <rgi-rx-form-field class="pt-1">
                            <input rgiRxInput type="checkbox" formControlName="forcingAniaResponsibility"
                                id="forcingAniaResponsibility" name="forcingAniaResponsibility"
                                (change)="enableForcedLiability($event.target)"
                                [disabled]="!circumstanceIncident.forcingAniaResponsibilityEnabled">
                            <label rgiRxLabel class="pr-2">{{ '_CLAIMS_._APPLY_ANIA_LIABILITY_FORCED' | translate }}</label>
                        </rgi-rx-form-field>
                    </div>

                    <!-- Note forzatura -->
                    <div class="col-md-4 col-xs-4 col-sm-4" *ngIf="enableItems">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                            <textarea rgiRxInput formControlName="aniaNotes" id="aniaNotes" name="aniaNotes"
                                attr.data-qa="dsc-injury-code-aniaNotes-input" (keyup)="outputFormChange()"></textarea>
                        </rgi-rx-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <!-- Motivo Forzatura -->
                    <div class="col-sm-4 col-xs-4" *ngIf="enableItems">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._FORCE_REASON' | translate }}</label>
                            <select rgiRxNativeSelect id="reasonToForceLiability" name="reasonToForceLiability"
                                attr.data-qa="dsc-circumstance-reasonToForceLiability-input"
                                (change)="outputFormChange()" formControlName="reasonToForceLiability">
                                <option value=""></option>
                                <option *ngFor="let cfrr of dataCombo.comboForcingResponsibilityReasons;"
                                    value="{{cfrr.codice}}">
                                    {{cfrr.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>

                    <!-- Responsabilità forzata -->
                    <div class="col-sm-4 col-xs-4" *ngIf="enableItems">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._FORCED_LIABILITY' | translate }}</label>
                            <select rgiRxNativeSelect id="forcedLiability" name="forcedLiability"
                                (change)="outputFormChange()" attr.data-qa="dsc-circumstance-forcedLiability-input"
                                formControlName="forcedLiability">
                                <option value=""></option>
                                <option *ngFor="let cfl of dataCombo.comboForcedLiability;" value="{{cfl.codice}}">
                                    {{cfl.descrizione}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                </div>
            <!-- Form più di un veicolo - FINE -->

        </div>
    </form>
</ng-template>
