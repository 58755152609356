import {SharedComponentsTranslationKeys} from './translation.keys';

export const EN: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'Add',
      ADD_DOCUMENT: 'Add document',
      ARCHIVE: 'Archive',
      ARCHIVED: 'Archived',
      ARCHIVE_DOC: 'Archive document',
      ARCHIVING_USER: 'Archiving User',
      CANCEL: 'Cancel',
      CLOSE: 'Close',
      CODE: 'Code',
      CONFIRM: 'Confirm',
      CREATED: 'Created',
      DELETE_DOC: 'Delete document',
      DELIVERED: 'Delivered',
      DELIVERY_DOC: 'Delivery document',
      DELIVERY_ADDRESS: 'Delivery Address',
      DELIVERY_PARTY: 'Delivery Party',
      DELIVERY_USER: 'Delivery User',
      DELIVERY_TYPE: 'Delivery Type',
      DESCRIPTION: 'Description',
      DOCUMENTS_MANAGER: 'Documents Manager',
      DOCUMENT_TYPE: 'Document type',
      DOCUMENT_VALIDATION_MESSAGE: 'Proceed with the validation of the document?',
      DOWNLOAD: 'Download',
      DOWNLOAD_DOC: 'Download document',
      EDIT: 'Edit',
      FILE: 'File',
      ERROR: 'Error',
      INPUT_DOCUMENTS: 'Input Documents',
      LOAD: 'LOAD',
      NO: 'No',
      NOTE: 'Note',
      NO_FILES: 'No files',
      NO_SIGN_NEEDED: 'Document does not require signature',
      NOT_DELIVERED: 'Not delivered',
      NOT_SIGNED: 'Not signed',
      NUMBER_OF_FILES_INSERTED: '{{ filesLength }} of {{ fileMax }} files inserted',
      OUTBOUND_DOC_TITLE: 'Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'Proposal documents nr. {{ proposalNumber }} of movement {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'Output Documents',
      PENDING_SIGNATURE_TOOLTIP: 'Document waiting for signature. Click to stop the process',
      REFRESH: 'Refresh',
      SAVE: 'SAVE',
      SEND: 'Send',
      SIGN: 'Sign',
      SIGN_DOCUMENT: 'Sign document',
      SIGNED: 'Signed',
      SIGNED_DOCUMENT: 'Document signed',
      SUBJECT_SENDING_DATE: 'Sending date to registry subject',
      STATE: 'Status',
      TYPE: 'Type',
      TO_BE_GENERATED: 'To be generated',
      TO_BE_UPLOADED: 'To be uploaded',
      UPDATE_FILE: 'Update file',
      UPLOAD: 'UPLOAD',
      UPLOADED: 'Uploaded',
      UPLOAD_NEW_DOCUMENT: 'Upload new document',
      UPLOADING_USER: 'Uploading User',
      VALIDATING_USER: 'Validation User',
      VALIDATED: 'Validated',
      VALIDATION: 'Validation',
      VALIDATION_DOCUMENT: 'Validation document',
      YES: 'Yes',
      NO_DOCUMENTS_CONFIGURED: 'There are no configured documents for this operation'
    }
};
