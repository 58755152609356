<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" [errors]="errors" fieldId="dates" id="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
                    (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="questsFin" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" fieldId="questsFin" label="lpc_financial_questionnaire_step" (next)="onNext($event)">
    <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                       [questionnairesCode]="getQuestMap('FIN')" type="FIN"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step label="lpc_variation_step_label" [errors]="errors" fieldId="declarativeAppendix" id="declarativeAppendix"
            (next)="onNext($event, true)">
    <div>
      <div class="core-header-icon ">
        <span class="header-text notes" translate>Notes</span>
      </div>
      <div class="form-group" *ngIf="isActiveStep('declarativeAppendix')">
        <textarea class="form-control" formControlName="declarativeAppendix" [attr.data-qa]="'declarativeAppendix'" rows="5"></textarea>
      </div>
      <!-- riepilogo -->
      <ul *ngIf="!isActiveStep('declarativeAppendix')"  data-qa="summary-declarativeAppendix-step">
        <li>{{ formGroupValue.declarativeAppendix }}</li>
      </ul>
      <!-- riepilogo -->
    </div>
  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" id="quests" [enableAuth]="isAuth" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')" fieldId="quests" label="lpc_avc_questionnaire" (next)="onNext($event)">
    <lpc-questionnaire
                       [active]="!isAfterId('quests')" [key]="composeKey(key, 'quests')"
                       [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #avcQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>


  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
