<template #documentModalContainerRef></template>

<div class="issue-container-min">
  <div class="middle-div" data-qa="proposalNumber-fromAuthModify-life" translate>
    <span>{{publishMessage}}</span>
  </div>
</div>

<ng-container *ngIf="onInitEnd">

  <!-- <ng-container *ngIf="!newOutboundHandler; else newOutboundDocuments"> -->
    <!-- this condition must be separate from the newOutboundHandler -->
    <ng-container *ngIf="areThereDocuments">
      <div class="rgishared-flex-center rgishared-mb-s">
        <div class="rgi-ui-title-1 rgishared-mr-s" translate>lic_print_docs</div>
      </div>
      <div rgi-rx-accordion [multi]="true" #accordionRef=rgiRxAccordion>
        <rgi-rx-expansion-panel [expanded]="false" color="default" (toggle)="contractAll()" *ngFor="let doc of documentList">
          <div rgi-rx-expansion-panel-header class="documenti-hide-expandability" (click)="contractAll()">
            <div rgi-rx-expansion-panel-label class="col-xs-11">
              <span class="rgi-ui-text-4 rgi-ui-icon-document rgi-ui-primary"></span> {{doc.nomeFile}}
            </div>
            <div class="col-xs-1 button-container">
              <button rgi-rx-button color="info"
                  [disabled]="false"
                  (click)="downloadDocument($event, doc)">
                  DOWNLOAD
                <span class="rgi-download"></span>
              </button>
            </div>
          </div>
        </rgi-rx-expansion-panel>
      </div>
    </ng-container>
  <!--</ng-container>
   <ng-template #newOutboundDocuments>
      <rgi-outbound-documents
        [policyNumber]="contract.policyNumber"
        [proposalNumber]="contract.proposalNumber"
        [movementId]="movementId"
        [movementDesc]="movementDescription">
      </rgi-outbound-documents>
  </ng-template> -->
</ng-container>
