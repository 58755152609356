<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="factors" *ngIf="hasProductFactors" label="lpc_variation_step_label" [errors]="errors" fieldId="factors" (next)="onNext($event)">
    <div formGroupName="factors" class="row">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateFactors($event)"
        [form]="formGroup.controls['factors']" [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="questsFin" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" fieldId="questsFin" label="lpc_financial_questionnaire_step" (next)="onNext($event)">
    <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                       [questionnairesCode]="getQuestMap('FIN')" type="FIN"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step *ngIf="!!profiles.length && !!formGroup.get('disinvestmentProfiles').value" [errors]="errors"  id="disinvestmentProfiles" label="lpc_disinvestment_profile_step"
    [jump]="useFixedTemplate && !modifiable" [nextVisible]="formGroup.get('disinvestmentProfiles').valid" fieldId="disinvestmentProfiles" (next)="onNext($event)">
    <lpc-karma-disinvestment-funds
            [investmentMode]="getInvestmentInsertMode()"
            *ngIf="!isAfterId('disinvestmentProfiles')"
            formControlName="disinvestmentProfiles"
            [totalAmount]="totalAmount"
            [definition]="profiles"
            [investedProfiles]="investedProfiles"
            [checksOnOverallAlloc]="false">
    </lpc-karma-disinvestment-funds>
    <div *ngIf="isAfterId('disinvestmentProfiles')">
      <ul>
        <li [attr.data-qa]="profile.id + 'info_disinvestment_profile'" *ngFor="let profile of selectedProfiles">
          <span for="label">{{ profile.description }} </span>
          <span
            for="label">{{ getProfileAmount(profile.id.toString()).toString() | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }}
          </span>
          <span for="value">({{ getProfilePercent(profile.id.toString()).toString() | rgiCountryLayerNumberFormat: currencyService.locale: percentFormatterOptions }})</span>
        </li>
      </ul>
    </div>
  </lpc-step>

  <lpc-step id="disinvestmentFunds" [errors]="errors"  [jump]="useFixedTemplate && !modifiable" *ngIf="!!profiles.length && !!formGroup.get('disinvestmentProfiles').value"
    label="lpc_disinvestment_funds" [nextVisible]="formGroup.get('disinvestmentFunds').valid" fieldId="disinvestmentFunds"
    (next)="onNext($event)">
    <div formGroupName="disinvestmentFunds" *ngIf="!isAfterId('disinvestmentFunds')">
      <!-- <div *ngIf="!useFixedTemplate"> -->
        <div rgi-rx-accordion [multi]="true" *ngFor="let profile of selectedProfiles">
          <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="accordion-text" translate>{{ profile.description }}</span>
          </div>
          <div content data-qa="panel-button">
            <lpc-karma-disinvestment-funds
              [investmentMode]="getInvestmentInsertMode()"
              [formControlName]="profile.id.toString()"
              [definition]="profile.funds"
              [investedProfiles]="getInvestedFundsByProfileId(profile.id)"
              [totalAmount]="getProfileAmount(profile.id)"
              [checksOnOverallAlloc]="false"
              [isprofile]="false"
              >
            </lpc-karma-disinvestment-funds>          </div>
          </rgi-rx-expansion-panel>
        </div>
      <!-- </div> -->
    </div>
    <div *ngIf="isAfterId('disinvestmentFunds')">
      <lpc-karma-fund-table
        [profiles]="profiles"
        [totalAmount]="totalAmount"
        [enableOpenListDefault]="true">
      </lpc-karma-fund-table>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <lpc-volatility-button
          *ngIf="isWithVolatility() && formGroup.get('disinvestmentFunds').valid && formGroup.get('disinvestmentFunds').touched  || isWithVolatility() && useFixedTemplate"
          [volatility]="volatility" [disabled]="!!volatility" (calculate)="onCalculateVolatility()">
        </lpc-volatility-button>
      </div>
    </div>
  </lpc-step>

  <lpc-step id="quotation" [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event, true)">
    <div formGroupName="quotation" class="row">
      <div *ngFor="let insuredSettlement of insuredSettlements">
        <p *ngIf="!!insuredSettlement.insured && insuredSettlement.insured.length > 1;else singleInsured" class="insured">
          <label translate>lpc_Assicurati</label>
          <label>: {{ insuredSettlement.insured[0] + ' - ' + insuredSettlement.insured[1] }}</label>
        </p>
        <ng-template #singleInsured>
          <p class="insured">
            <label translate>lpc_Assicurato</label>
            <label>: {{ insuredSettlement.insured[0] }}</label>
          </p>
        </ng-template>
        <lpc-definition-table
          [label]="'lpc_life_policy'"
          [definitions]="tableDefinitions"
          [rows]="insuredSettlement.settlement">
        </lpc-definition-table>
        <br>
      </div>
    </div>
  </lpc-step>


  <lpc-step id="beneficiaries" label="lpc_life_detail_beneficiaries" [errors]="errors" fieldId="beneficiaries"
      (next)="onNext($event)" feFieldId="beneficiaries" [feErrors]="feErrors" id="beneficiaries"
      (confirmAccept)="onNext($event)" [isConfirmAndAccept]="isConfirmAndAccept"
  >
    <lpc-claim-beneficiaries
        formControlName="beneficiaries" [paymentTypes]="paymentTypes"
        [beneficiaryType]="beneficiaryType"
        [idAssicurato]="idAssicurato" [summary]="!isActiveStep('beneficiaries')"
        [questionnairesCode]="getUnfilteredQuestMap('IST')"
        [questionnairesDefinitions]="questionnaireDefinitions"
        [blockBeneficiaries]="blockBeneficiaries"
        (addedSubject)="onAddedSubject($event)"
        (triggerQuestPreval)="onTriggerQuestPreval($event)"
        (loaderQuest)="loaderQuestManager($event)"
        [questFactorsArray]="questFactorsArray" [disabledQuestionArray]="disabledQuestionArray"
        #beneficiaries >
    </lpc-claim-beneficiaries>
  </lpc-step>


  <lpc-step *ngIf="isAuth || !!errors && !!errors.length"
          id="warnings"
          label="lpc_warnings"
          [isConfirmAndAccept]="isConfirmAndAccept"
          (confirmAccept)="publish(true)"
          (next)="publish()"
          [nextLabel]="isAuth ? 'lpc_authorization_request' : 'lpc_confirm_button'">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
    <lpc-error-messages [errors]="errors" [fieldType]="'tools'"></lpc-error-messages>
  </lpc-step>


  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
