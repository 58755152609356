import {SharedComponentsTranslationKeys} from './translation.keys';

export const GL_ES: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'GLES_Add',
      ADD_DOCUMENT: 'GLES_Add document',
      ARCHIVE: 'GLES_Archive',
      ARCHIVED: 'GLES_Archived',
      ARCHIVE_DOC: 'GLES_Archive document',
      ARCHIVING_USER: 'GLES_Archiving User',
      CANCEL: 'GLES_Cancel',
      CLOSE: 'GLES_Close',
      CODE: 'GLES_Code',
      CONFIRM: 'GLES_Confirm',
      CREATED: 'GLES_Created',
      DELETE_DOC: 'GLES_Delete document',
      DELIVERED: 'GLES_Delivered',
      DELIVERY_DOC: 'GLES_Delivery document',
      DELIVERY_ADDRESS: 'GLES_Delivery Address',
      DELIVERY_PARTY: 'GLES_Delivery Party',
      DELIVERY_USER: 'GLES_Delivery User',
      DELIVERY_TYPE: 'GLES_Delivery Type',
      DESCRIPTION: 'GLES_Description',
      DOCUMENTS_MANAGER: 'GLES_Documents Manager',
      DOCUMENT_TYPE: 'GLES_Document type',
      DOCUMENT_VALIDATION_MESSAGE: 'GLES_Proceed with the validation of the document?',
      DOWNLOAD: 'GLES_Download',
      DOWNLOAD_DOC: 'GLES_Download document',
      EDIT: 'GLES_Edit',
      FILE: 'GLES_File',
      ERROR: 'GLES_Error',
      INPUT_DOCUMENTS: 'GLES_Input Documents',
      LOAD: 'GLES_LOAD',
      NO: 'GLES_No',
      NOTE: 'GLES_Note',
      NO_FILES: 'GLES_No files',
      NO_SIGN_NEEDED: 'GLES_Document does not require signature',
      NOT_DELIVERED: 'GLES_Not delivered',
      NOT_SIGNED: 'GLES_Not signed',
      NUMBER_OF_FILES_INSERTED: 'GLES_{{ filesLength }} of {{ fileMax }} files inserted',
      OUTBOUND_DOC_TITLE: 'GLES_Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'GLES_Proposal documents nr. {{ proposalNumber }} of movement {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'GLES_Output Documents',
      PENDING_SIGNATURE_TOOLTIP: 'GLES_Document waiting for signature. Click to stop the process',
      REFRESH: 'GLES_Refresh',
      SAVE: 'GLES_SAVE',
      SEND: 'GLES_Send',
      SIGN: 'GLES_Sign',
      SIGN_DOCUMENT: 'GLES_Sign document',
      SIGNED: 'GLES_Signed',
      SIGNED_DOCUMENT: 'GLES_Document signed',
      SUBJECT_SENDING_DATE: 'GLES_Sending date to registry subject',
      STATE: 'GLES_Status',
      TYPE: 'GLES_Type',
      TO_BE_GENERATED: 'GLES_To be generated',
      TO_BE_UPLOADED: 'GLES_To be uploaded',
      UPDATE_FILE: 'GLES_Update file',
      UPLOAD: 'GLES_UPLOAD',
      UPLOADED: 'GLES_Uploaded',
      UPLOAD_NEW_DOCUMENT: 'GLES_Upload new document',
      UPLOADING_USER: 'GLES_Uploading User',
      VALIDATING_USER: 'GLES_Validation User',
      VALIDATED: 'GLES_Validated',
      VALIDATION: 'GLES_Validation',
      VALIDATION_DOCUMENT: 'GLES_Validation document',
      YES: 'GLES_Yes',
      NO_DOCUMENTS_CONFIGURED: 'GLES_There are no configured documents for this operation'
    }
};
