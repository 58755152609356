import { Company, DialogueFunction, FormSearch } from './../dialogue-handler-configuration/dialogue-handler-search.model';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { RoutingService } from '@rgi/portal-ng-core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogueHandlerSearchStateManager } from '../dialogue-handler-service/dialogue-handler-search.state.manager';
import { Subscription } from 'rxjs';
import { DialogueHandlerSearch } from '../dialogue-handler-configuration/dialogue-handler.model';
import { ActiveRoute } from '@rgi/rx/router';

@Component({
  selector: "claims-dialogue-handler-search",
  templateUrl: "./dialogue-handler-search.component.html",
  styleUrls: ["./dialogue-handler-search.component.scss"],
})
export class DialogueHandlerSearchComponent implements OnInit {
  @Input() userHasPermission: boolean = false;
  @Input() buttonCondition: boolean = true;
  @Input() searchForm: FormSearch;
  @Input() routeList: ActiveRoute;

  selectedFunction: DialogueFunction | null = null;
  subscriptions: Subscription[] = [];
  searchDialogueHandlerForm: UntypedFormGroup;
  selectedFunctionCode: number;
  errorMessage: string;
  objectIndex: number = 0;
  state: DialogueHandlerSearch = null;

  allCompanies: Company[] = []
  filteredCompanies: Company[] = []

  constructor(
    @Inject('authService') private authService,
    private formBuilder: UntypedFormBuilder,
    protected routingService: RoutingService,
    public dialogueStateSearch: DialogueHandlerSearchStateManager
  ) { }

  checkState() {
    this.subscriptions.push(this.dialogueStateSearch.getState$().subscribe((state: DialogueHandlerSearch) => {
      if (state && this.state == null){
        this.state = state;
      }
      if(state && this.allCompanies.length == 0){
        this.allCompanies = state.searchWrapperObject.dialogueCompResponse.counterpartCompany
        this.filteredCompanies = this.allCompanies
      }
      if (state && state.errorMessage) {
        this.errorMessage = state.errorMessage;
        state.errorMessage = null;
      }
      if(state && this.searchDialogueHandlerForm.controls.function.value && this.selectedFunction == null){
        this.onFunctionChange();
      }
      console.log(state.formSearch)
      console.log(this.routeList);
    })
    )
  }

  onFunctionChange(){
    let selectedFunctionCode = this.searchDialogueHandlerForm.controls.function.value; 

    if (selectedFunctionCode !=null && selectedFunctionCode !='') {
      if (this.selectedFunctionCode !== selectedFunctionCode) {
        this.selectedFunctionCode = selectedFunctionCode;
        this.selectedFunction = this.state.searchWrapperObject.dialogueFunctionResponse.find(elem => elem.functionCode == selectedFunctionCode)
        this.objectIndex = this.state.searchWrapperObject.dialogueObjectResponse.findIndex(elem=> elem.functionCode == this.searchDialogueHandlerForm.controls.function.value)
        
        this.filteredCompanies = this.allCompanies.filter(controparty => controparty.codeConv.includes(selectedFunctionCode))
      }
    } else {
      this.selectedFunction = null;
      this.selectedFunctionCode = null;
      this.objectIndex = 0;

      this.filteredCompanies = this.allCompanies
    }

    this.searchDialogueHandlerForm.controls.message.setValue(null);
    this.searchDialogueHandlerForm.controls.object.setValue(null);
    this.searchDialogueHandlerForm.controls.ctpCompany.setValue(null);
  }

  fillInForm() {
    if (this.searchForm && !this.buttonCondition) {
      this.searchDialogueHandlerForm.setValue(this.searchForm);
      console.log("this.searchDialogueHandlerForm ==> ", this.searchDialogueHandlerForm);
    }
    //  else if(this.buttonCondition){
    //   this.searchDialogueHandlerForm.reset();
    // }
  }

  ngOnInit() {
    const listFunz = this.authService.getOperator().enabledFeatures;
    if (listFunz && listFunz.indexOf('PSNDNW') >= 0) {
      this.userHasPermission = true;
    }

    this.searchDialogueHandlerForm = this.formBuilder.group({
      function: [],
      message: [],
      object: [],
      externalKey: [],
      damageMatch: [],
      taxIdCode: [],
      claim: [],
      complaint: [],
      association: [],
      company: [],
      ctpCompany: [],
      carLicensePlate: [],
      ctpCarLicensePlate: [],
      creationDateStart: [],
      creationDateEnd: [],
      elaborationDateStart: [],
      elaborationDateEnd: [],
      expiryDateStart: [],
      expiryDateEnd: [],
      claimDateStart: [],
      claimDateEnd: [],
      activeTask: [],
      errorMessage: [],
      similarFlag: [],
    });
    console.log("CARICATA LA SEARCH=============================>");
    this.searchDialogueHandlerForm.reset();
    this.fillInForm();
    this.onChangeForm();
    this.checkState();
  }

  onChangeForm() {
    this.subscriptions.push(this.searchDialogueHandlerForm.valueChanges.subscribe((res) => {
      this.dialogueStateSearch.updateFormSearch$(
        res,
        this.searchDialogueHandlerForm.valid
      );
    })
    )
  }

  goToSearchResults() {
    // this.unsubscribeState();
    this.errorMessage = null;
    if(this.searchDialogueHandlerForm.pristine){
      this.errorMessage='fill the form'
    }else {
      this.dialogueStateSearch.goToDialogueHandlerList$(this.routeList);
    }
  }

  openNewDialogue() {
    this.unsubscribeState();
    this.dialogueStateSearch.goToNewDialogue$();
  }

  emptyFields() {
    this.searchDialogueHandlerForm.reset();
  }
  //METODO SOLO PER TEST
  navigateToSummary() {
    this.unsubscribeState();
    this.dialogueStateSearch.goToDialogueHandlerSummary$();
  }


  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
        sub = null;
      }
    })
  }
}