import { LicTranslationsKeys } from '../lic-translations-keys';

// tslint:disable:max-line-length
export const LIC_FR_CH: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: "Le numéro de police {{num}} a été émis correctement",
  lic_ProposalCorrectlyIssued: "Proposition numéro {{num}} émise correctement",
  lic_LifeProposalCorrectlyIssued: "Le numéro de proposition Vie {{num}} a été émis correctement",
  lic_LifePolicyCorrectlyIssued: "La police d'assurance vie numéro {{num}} a été émise correctement",
  lic_DamagesPolicyCorrectlyIssued: "La police d'assurance non-vie numéro {{num}} a été émise correctement",
  lic_AuthorizatioProposalForwarded: "La demande d'autorisation pour la proposition numéro {{num}} a été transmise à l'utilisateur concerné",
  lic_ProposalCorrectlySaved: "Le numéro de proposition {{num}} a été correctement enregistré",
  lic_PreventiveCorrectlySaved: "Le numéro de devis {{num}} a été enregistré correctement",
  lic_benMorteNoAssic: "La partie sélectionnée comme bénéficiaire du décès est déjà sélectionnée comme assuré, sélectionnez une autre partie",
  lic_benMorteNoRef: "La personne sélectionnée comme bénéficiaire du décès est déjà sélectionnée comme personne de contact tierce, sélectionnez une autre personne",
  lic_benefPresente: "La partie sélectionnée comme bénéficiaire du décès est déjà sélectionnée, sélectionnez une autre partie",
  lic_refTerzoNoAssic: "La partie sélectionnée comme tiers de contact est déjà sélectionnée comme assurée, sélectionnez une autre partie",
  lic_refTerzoNoContr: "La partie sélectionnée comme personne de contact tierce est déjà sélectionnée comme titulaire de la police, sélectionnez une autre partie",
  lic_refTerzoPresente: "La personne sélectionnée comme tiers de contact est déjà sélectionnée comme bénéficiaire du décès, sélectionnez une autre personne",
  lic_DelegateEqualToPolicyHolder: "Le délégué est-il le même que le preneur d'assurance?",
  lic_LegalGuardianEqualToPolicyHolder: "Le tuteur légal est-il le même que le titulaire de la police?",
  lic_ThirdPayerEqualToPolicyHolder: "Le tiers payeur est-il le même que le titulaire de la police?",
  lic_DelegateEqualToPolicyHolderWarning: "Partie pour le rôle Délégué identique à celui du titulaire de la police",
  lic_LegalGuardianEqualToPolicyHolderWarning: "Partie pour le rôle Tuteur légal identique à celui du titulaire de la police",
  lic_ThirdPayerEqualToPolicyHolderWarning: "Partie pour le rôle Tiers payeur identique à celui du titulaire de la police",
  lic_MasterPolicyHolder: "Titulaire principal de la police d'assurance",
  lic_SubjectXalreadyselectedfortherole: "Partie {{partie}} déjà sélectionnée pour le rôle",
  lic_InsuredAmount: "Montant assuré",
  lic_InvestedPremium: "Prime investie",
  lic_FirstInstalment: "Premier versement",
  lic_InsuredNotEqualHolder: "Les termes assuré et titulaire doivent être différents",
  lic_SaveEstimate: "Enregistrer le devis",
  lic_LifeQuote: "Devis Vie",
  lic_PreventiveIssue: "Émission d'un devis",
  lic_ProposalIssue: "Émettre un proposition",
  lic_PolicyIssue: "Émettre une police",
  lic_appendixLabel: "Approbation du bénéficiaire",
  lic_summary_save_and_accept: "enregistrer et accepter",
  lic_summary_save_accept_pol_issue: "enregistrer, accepter et émettre la police",
  lic_summary_save_accept_prop_issue: "enregistrer, accepter et émettre la proposition",
  lic_effectivebeneficiaryholder: "bénéficiaire effectif",
  lic_LifePolicy: "Police Vie",
  lic_DamagesPolicy: "Police d'assurance non-vie",
  lic_view: "Vue",
  lic_premium_detail: "Détail de la prime",
  lic_guarantee_summary: "Résumé de la couverture",
  lic_commissions: "Commissions",
  lic_Parties: "Parties",
  lic_Party: "Partie",
  lic_Employer: "Employeur",
  lic_SpecificPIPdata: "Données PIP spécifiques",
  lic_prev_continue: "Continuer",
  lic_Transferdata: "Données de transfert",
  lic_Selectatleastonecontribution: "Sélectionnez au moins une contribution",
  lic_Globalpercentagemustbe100: "Le pourcentage global doit être de 100 %",
  lic_Selectcontributionsmandatoryfields: "Sélectionnez les champs obligatoires pour les contributions",
  lic_Contributiondata: "Données de contribution",
  lic_Globalamountmustbemajorthan0: "Le montant global doit être supérieur à 0",
  lic_Everycontributionselectedmustbemajorthan0: "Chaque contribution sélectionnée doit être supérieure à 0",
  lic_error_mandatory_fields: "Les champs marqués en rouge sont obligatoires ou contiennent des valeurs incorrectes",
  lic_error_iban: "IBAN n'est pas correct",
  lic_authorization_request: "DEMANDE D'AUTORISATION",
  lic_selected_profiles_: "Profils sélectionnés",
  lic_Disinvestmentsfunds: "Fonds de désinvestissement",
  lic_reg41_comunication: "Nom à qui envoyer les communications",
  lic_disinvestment_amount: "Montant de la cession",
  lic_occurrenceNumber: "Occurrence",
  lic_TheoccurenceNumbermustbeavalue: "Le nombre d'occurrences doit être supérieur à {{min}}",
  lic_Disinvestmentrange: "Saisissez un montant à céder supérieur à {{max}} et inférieur ou égal à {{min}}",
  lic_Incompleteinvestmentforthefunds: "Investissement incomplet, veuillez compléter l'investissement pour les fonds",
  lic_Incompleteinvestmentfortheprofiles: "Investissement incomplet, veuillez compléter l'investissement pour les profils",
  lic_Settlementstoreinvest: "Des règlements pour réinvestir",
  lic_Policynumber: "Numéro de police",
  lic_Type: "Type",
  lic_Beneficiary: "Bénéficiaire",
  lic_State: "État",
  lic_Net: "Net",
  lic_Nettoreinvest: "Net à réinvestir",
  lic_Reinvestmentmustbelargerthanquotation: "Le réinvestissement doit être plus important que le devis",
  lic_Mustselectatleastonesettlementtoreinvest: "Il faut sélectionner au moins un règlement à réinvestir",
  lic_Nettoreinvestmustbeequalorlessthannet: "Le montant net à réinvestir doit être égal ou inférieur au montant net.",
  lic_Nosettlementstoreinvestexist: "Il n'y a pas de règlement pour réinvestir",
  lic_Missingvalueonnettoreinvest: "Valeur manquante sur la valeur nette à réinvestir",
  lic_information: "Information",
  lic_Percentage: "Pourcentage",
  lic_Durationin: "Durée en ",
  lic_years: "années",
  lic_months: "mois",
  lic_days: "jours",
  lic_Complementary: "(complémentaire)",
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: "Veuillez indiquer un mode de paiement pour le bénéficiaire du coupon",
  lic_Netavailable: "Net disponible",
  lic_Proposal_Saved: "La proposition a été sauvegardée avec le numéro",
  lic_add_employer: "Ajouter un lic_Employer",
  lic_AvailableGuarantees: "Garanties disponibles",
  lic_error: "Erreur",
  lic_error_msg: "Erreur lors de l'émission de la police",
  lic_auth_error_msg: "Erreur lors du traitement de la demande",
  lic_common_risk: "Risque commun",
  lic_assignment_holder: "Titulaire de l'affectation",
  lic_Sustainability: "Durabilité",
  lic_ecoSustainabilityAmount: "Montant pour l'écoresponsabilité",
  lic_ecoSustainabilityPerc: "Écoresponsabilité %",
  lic_envSustainabilityAmount: "Montant pour l'environnement et le développement durable",
  lic_envSustainabilityPerc: "Environnement et développement durable %",
  lic_paiAmount: "Montant du PAI",
  lic_paiPerc: "PAI %",
  lic_socialSustainabilityAmount: "Montant pour la durabilité sociale",
  lic_socialSustainabilityPerc: "% de durabilité sociale",
  lic_benefSevereDisability: "Bénéficiaire souffrant d'un handicap grave",
  lic_SecondInsured: "Deuxième assuré",
  lic_Secondinsuredmustbeaphysicalperson: "Le deuxième assuré doit être une personne physique",
  lic_Selectasecondinsured: "Sélectionnez un deuxième assuré",
  lic_Aderente: "Souscripteur",
  lic_NumeroProposta: "Numéro de la proposition",
  lic_DescrizioneProdotto: "Description du produit",
  lic_DescrizioneRischio: "Description du risque",
  lic_CapitaleAssicurato: "Montant assuré",
  lic_SELEZIONA: "-- SÉLECTIONNER --",
  lic_PrNetto: "% de la prime nette",
  lic_ImpAcq: "Taxe à l'achat",
  lic_profileInvestmentIncomplete: "Les profils d'investissement incomplets, les profils d'investissement complets",
  lic_fundsInvestmentIncomplete: "Investissement incomplet, veuillez compléter les fonds d'investissement",
  lic_Clauses: "Clauses",
  lic_quote_type: "Type de devis",
  lic_Policyholder: "Titulaire de la police",
  lic_quote_type_anonymous: "Anonyme",
  lic_quote_type_registered: "Enregistré",
  lic_text_required: "*Texte obligatoire",
  lic_text_required_error: "Le texte de la clause est requis",
  lic_close: "fermer",
  lic_save: "enregistrer",
  lic_NEXT: "SUIVANT",
  lic_policyData: "Données de la police",
  lic_pipData: "Données PIP",
  lic_parties: "Parties",
  lic_assets: "Actifs",
  lic_quotation: "Devis",
  lic_summary: "Récapitulatif",
  lic_issue: "Émission",
  lic_investments: "Investissements",
  lic_invest_value_range: "Veuillez saisir une valeur comprise entre {{min}} et {{max}}",
  lic_disinvestment_range: "Veuillez saisir une valeur de cession comprise entre {{min}} et {{max}}",
  lic_mandatory_threshold_perc: "Fonds: {{value}} - Veuillez saisir le pourcentage de seuil",
  lic_mandatory_one_fund: "Sélectionnez au moins un fonds",
  lic_invest_factor_min_validation: "{{factor}} doit être inférieur à {{amount}}",
  lic_invest_factor_max_validation: "{{factor}} doit être supérieur à {{amount}}",
  lic_invest_factor_eq_validation: "{{factor}} doit être {{amount}}",
  lic_calculate_asset_volatility: "Calculer la volatilité des actifs",
  lic_calculate_sustainability_asset: "Calculer la viabilité des actifs",
  lic_occurance_number_range: "Le nombre d'occurrences doit être supérieur à {{min}}",
  lic_policy_holder: "Titulaire de la police",
  lic_guarantee: "Garantie",
  lic_co_applicant_not_same_holder: "Le co-demandeur ne doit pas être la même personne que le titulaire de la police",
  lic_co_applicant_physical: "Le co-demandeur doit être une personne physique",
  lic_co_applicant_mandatory: "Le co-demandeur est obligatoire",
  lic_co_applicant: "Co-demandeur",
  lic_insured_must_be_physical: "L'assuré doit être une personne physique",
  lic_mandatory_product: "Sélectionnez un produit",
  lic_mandatory_insured: "Sélectionnez un assuré",
  lic_mandatory_holder: "Sélectionnez un assuré",
  lic_contribution_frequency: "La fréquence de contribution {{frequency}} doit être la même",
  lic_mandatory_benef: "Bénéficiaire {{category}}: 'Le bénéficiaire est obligatoire",
  lic_mandatory_appendix_benef: "Bénéficiaire {{category}}: Le bénéficiaire de l'avenant est obligatoire",
  lic_add_benef: "Bénéficiaire {{category}}: 'Ajouter un bénéficiaire de l'état civil",
  lic_add_benef_perc: "Bénéficiaire {{category}} {{nominative}}: 'Ajouter un pourcentage",
  lic_benef_perc_range: "Bénéficiaire {{category}} {{nominative}}: 'Le pourcentage doit être compris entre 0,01 et 100",
  lic_benef_total_perc: "Bénéficiaire {{category}}: 'Le pourcentage doit être de 100 %",
  lic_guarantee_mandatory_data: "Garantie {{unit}}: 'Remplir toutes les données obligatoires",
  lic_quotation_not_done: "Le devis n'a pas été effectué: sélectionnez \"Devis\" pour mettre à jour le devis",
  lic_quotation_modified_value_quote: "Les données sont mises à jour: sélectionnez \"Devis\" pour mettre à jour le devis",
  lic_quotation_benef_custom_msg: "Bénéficiaire {{category}} - {{msg}}",
  lic_product: "Produit",
  lic_managementNode: "Nœud de gestion",
  lic_branch: "Branche",
  lic_agreement: "Accord",
  lic_effect_date: "Date d'entrée en vigueur",
  lic_exp_date: "Date d'expiration",
  lic_payment_frequency: "Fréquence de paiement",
  lic_means_of_payment: "Moyens de paiement",
  lic_unit_type: "Type d'unité",
  lic_risk_type: "Type de risque",
  lic_substate: "Sous-statut",
  lic_benefit: "Bénéfice",
  lic_decreasing_frequency: "Fréquence décroissante",
  lic_damages: "Non-vie",
  lic_effective_holder: "Bénéficiaires effectifs",
  lic_insured_role: "Assuré",
  lic_third_contact_person: "Personne de contact tierce",
  lic_quotation_benef_owner_perc_validation: "{{roleDescr}} {{nom}} du bénéficiaire {{benefName}} ne respecte pas le pourcentage minimum accepté {{minPerc}}",
  lic_subscriber: "Souscripteur",
  lic_yes: "Oui",
  lic_no: "non",
  lic_new_insured: "Nouveaux assurés",
  lic_simple_select: "Sélectionner",
  lic_first_instalment: "Premier versement",
  lic_next_instalment_premium: "Prime de la prochaine échéance",
  lic_payment_type: "Type de paiement",
  lic_first_instalment_payment_type: "Type de paiement de la première tranche",
  lic_annual_premium: "Prime annuelle",
  lic_quote_button: "Devis",
  lic_quote_save: "Établir un devis et sauvegarder",
  lic_purchasing_commission: "Commission d'achat",
  lic_beneficiaries_label: "Bénéficiaires",
  lic_general_data_label: "Données générales",
  lic_contractual_option_not_allowed: "Les options contractuelles ne sont pas autorisées",
  lic_investment_value_label: "Valeur d'investissement",
  lic_investment_profile: "Profil d'investissement",
  lic_investment_funds: "Fonds d'investissement",
  lic_calculate_finantial_parameters: "Calculer les paramètres financiers",
  lic_confirm_button: "Confirmer",
  lic_cancel_button: "Annuler",
  lic_save_button: "Enregistrer",
  lic_continue_button: "Continuer",
  lic_next_button: "Suivant",
  lic_required: "obligatoire",
  lic_node: "Nœud",
  lic_company: "Société",
  lic_product_type: "Type de produit",
  lic_taxes_label: "Impôts",
  lic_fees_label: "Frais",
  lic_frequency_interests: "Intérêt de fréquence",
  lic_frequency: "Fréquence",
  lic_accessories: "Accessoires",
  lic_taxable: "Imposable",
  lic_quotation_detail_modal: "Détail du devis",
  lic_guarantees_overview_modal: "Aperçu des garanties",
  lic_gross_label: "Brut",
  lic_int_fract_label: "Int. 'Séparer",
  lic_next_instalment_label: "Prochain versement",
  lic_pure_premium_label: "Prime pure",
  lic_extra_premium_label: "Ajouter. 'Prime",
  lic_loadings_label: "Chargements",
  lic_total_label: "Total",
  lic_beneficiary_perc_is_over: "La somme des pourcentages des rôles liés à {{benefCat}} {{benefRole}} est supérieure à 100",
  lic_questionSTR_invalid: "La réponse à la question: {{question}}, n'est pas correcte",
  lic_purchase_commissions: "Commissions d'achat",
  lic_PurchaseCommission: "Commission d'achat",
  lic_NetPrem: "% prime nette",
  lic_sales_commissions: "Commissions de vente",
  lic_IBAN_formally_incorrect: "L'IBAN n'est pas formellement valide",
  lic_holder_physical_person_req: "Le titulaire de la police doit être une personne physique",
  lic_holder_legal_person_req: "Le titulaire de la police doit être une personne morale",
  lic_available_covers: "Couvertures disponibles",
  lic_covers_overview: "Vue d'ensemble des couvertures",
  lic_covers: "Couvertures",
  lic_cover: "Couverture",
  lic_config_profile_warning: "ATTENTION: 'Les profils de configuration ont changé",
  lic_select: "Sélectionner",
  lic_Irrevocable_beneficiary: "Bénéficiaire irrévocable",
  lic_Base: "(Base)",
  lic_Accessory: "(Complément d'information)",
  lic_investment_value: "Valeur d'investissement",
  lic_volatility: "Volatilité",
  lic_name: "Nom",
  lic_to_be_invested: "",
  lic_info_investment_completed: "Information: l'investissement est terminé",
  lic_fund: "Fonds",
  lic_max_percentage: "pourcentage maximum accepté",
  lic_min_percentage: "pourcentage minimum accepté",
  lic_selected_funds: "Fonds sélectionnés",
  lic_allocation_exceeds: "L'allocation des investissements dépasse le montant disponible",
  lic_percentage_threshold: "Seuil en %",
  lic_fund_distribution_identical: "Distribution du fonds identique au paiement initial",
  lic_fund_not_available: "pas de fonds pour l'option",
  lic_details: "Détails",
  lic_starting_fund: "Fonds de départ",
  lic_fund_category: "Profil/Fonds",
  lic_equivalent: "Équivalent à la date",
  lic_policy_address: "Adresse de la police",
  lic_policy_master_details: "Détail de la police générale",
  lic_administrative_data: "Données administratives",
  lic_ordinary_mail: "Courrier ordinaire",
  lic_threshold: "Seuil",
  lic_payment_method: "Mode de paiement des prochaines échéances",
  lic_additional_payment: "Paiement supplémentaire",
  lic_contractual_options: "Options contractuelles",
  lic_elements: "Éléments",
  lic_position_number: "Numéro de position",
  lic_questionnaires_section: "Questionnaires",
  lic_fees: "Frais fixes",
  lic__1TERM: "Premier mandat",
  lic__EFFOP: "Date d'entrée en vigueur",
  lic__OPZFR: "Fréquence",
  lic__OPZPM: "Mode de paiement",
  lic_expirationDate: "Date d'expiration",
  lic__OPBEN: "Bénéficiaire",
  lic__MINTS: "Seuil minimum",
  lic__MAXTS: "Seuil maximum",
  lic__NUMOC: "Numéro de l'événement",
  lic__AMTOP: "Montant de la remise",
  lic__MALMT: "Montant minimum",
  lic_amount: "Montant",
  lic_currency: "Monnaie",
  lic__CIBAN: "IBAN",
  lic_fixedFees: "Frais fixes",
  lic__PCOUP: "Pourcentage d'escompte périodique",
  lic_holder: "Titulaire",
  lic_ACTIVE_STATE: "ACTIF",
  lic_MANDATORY_STATE: "OBLIGATOIRE",
  lic_DEACTIVATE_STATE: "DESACTIVER",
  lic_premium_label: "Prime",
  lic_holders_title: "Détenteurs",
  lic_beneficiary_qualification: " {{benefCat}} {{benefRole}} ",
  lic_invalid_total_position_percentage: "Le montant en pourcentage pour le groupe \"{{groupType}}\" pour le bénéficiaire {{cat}} doit être de 100 %",
  lic_beneficiary_position_mandatory: "Bénéficiaire {{benefCat}} {{benefName}}, sélectionnez un \"Numéro de poste\"",
  lic_role_is_mandatory: "vous devez entrer un {{role}}",
  lic_effective_holder_duplicated: "Détenteur efficace dupliqué",
  lic_beneficiary_perc_req_range: "{{benefCat}} {{benefRole}} {{benefName}}: 'Le pourcentage doit être compris entre 0,01 et 100",
  lic_beneficiary_perc_req: " {{benefCat}} {{benefRole}} {{benefName}}: 'Saisir un pourcentage",
  lic_beneficiary_from_party_req: "{{benefCat}} {{benefRole}}: 'Saisir un bénéficiaire à partir du référentiel des parties",
  lic_effective_holder_perc: "{{benefName}}'s bénéficiaire effectif {{linkedName}}'s le pourcentage est moins que le minimum autorisé de {{linkedPerc}}%",
  lic_effective_holder_perc_req: "{{benefName}}'s bénéficiaire effectif {{linkedName}}: 'Saisir un pourcentage",
  lic_or_junction: "ou",
  lic_backdating_not_allowed: "",
  lic_no_common_risk: "Il n'y a pas de propositions ou de polices qui entrent dans le calcul du capital à risque de l'assuré",
  lic_print_docs: 'Imprimer les documents',
};
export default LIC_FR_CH ;
