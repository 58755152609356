import { RgiRxAccordionDirective } from '@rgi/rx/ui';
import { DocumentList } from './../../models/document.model';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'lic-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

    @Input() documentList: DocumentList[];
    @Output() actionHandler = new EventEmitter();

    @ViewChild("accordionRef", {static: false}) multiAccordion: RgiRxAccordionDirective;

    activePanelLists: string[] = [];

    constructor() {}

    get areThereDocuments(): boolean {
      return this.documentList.length > 0;
    }

    ngOnInit() {
      this.activePanelLists = ['doc-panel'];
    }

    downloadDocument(event: any, doc: DocumentList) {
      event.stopPropagation();
      this.actionHandler.emit({
        eventName: 'downloadDocument',
        document: doc,
        type: 'life'
      });
    }

    printAllDocuments(event: any) {
        event.stopPropagation();
        this.actionHandler.emit({
          eventName: 'downloadAllDocuments',
          documents: this.documentList,
          type: 'life'
        });
    }

    contractAll(){
      this.multiAccordion.contractAll();
    }

}
