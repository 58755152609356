/**
 * GEL REST API
 * Rest API's for Workflow services
 *
 * OpenAPI spec version: 0.0.1
 * Contact: info@rgigroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TypeCheck = 'SD_VN_PEP' | 'SD_VN_CTF' | 'SD_VN_ALL';

export const TypeCheck = {
    PEP: 'SD_VN_PEP' as TypeCheck,
    CTF: 'SD_VN_CTF' as TypeCheck,
    ALL: 'SD_VN_ALL' as TypeCheck
};