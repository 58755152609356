import {downgradeComponent} from '@angular/upgrade/static';
import { JS_ROUTES } from '@rgi/life-postsales-card';
import { digitalSignDocuments, inboundDocumentDetailExt, lifeDetailCardExt } from '../cardTemplates';
import { LifeDetailExtComponent } from '../life-detail/life-detail-ext.component';
import { JS_ROUTES_EXT } from './consts/life-postsales-card-ext-consts';
import { DigitalSignDocumentsComponent } from '../../digital-sign-documents/digital-sign-documents.component';
import { LpcInquiryInDocumentExtComponent } from '../lpc-inquiry-in-document/lpc-inquiry-in-document-ext.component';

export enum LPC_AJS_DIRECTIVE_EXT {
  /** LIFE_DETAIL = 'iiabLpcLifeDetailExt' */
  LIFE_DETAIL = 'iiabLpcLifeDetailExt',
  /** OTP_DOCUMENTS_PAGE = 'iiabDigitalSignDocuments' */
  OTP_DOCUMENTS_PAGE = 'iiabDigitalSignDocuments',
  /** LIFE_INBOUND_DOC_INQURY = 'iiabLpcInquiryInDocumentExt' */
  LIFE_INBOUND_DOC_INQURY = 'iiabInquiryInDocumentExt'
}

// @ts-ignore
// ANGULARJS CONFIGURATION
declare var angular: angular.IAngularStatic;

const factory = (templateKey, template) => ['$templateCache', ($templateCache) => {
  $templateCache.put('assets/' + templateKey, template);
}];

export function AjsConfigurationLifePostsalesComplete() {

  registerDetailCard();
  registerOtpDocuments();
  registerInboundDocumentDetail();


  /** REGISTRAZIONE CARD FLUSSO POSTSALES */

  /******************* ROUTING DETTAGLIO VITA ******************** */
  function registerDetailCard() {
    // angular.module(
    //   'ptfall-card-finder'
    // ).config(
    //   ['$provide', ($provide) => { $provide.decorator(
    //     getLifePVJSDirective(LPC_AJS_DIRECTIVE.LIFE_DETAIL),
    //     [
    //       '$delegate',
    //       function myServiceDecorator($delegate) {
    //         $delegate[0] = downgradeComponent({component: LifeDetailExtComponent});
    //         return $delegate;
    //       }
    //     ]);
    //   }]
    // );

    angular.module('ptfall-card-finder')
      .run(factory('lifeDetailCard.tpl.html', lifeDetailCardExt))
      .config([
        'parallelRoutingProvider',
        (pRouter) => {
          pRouter.when('ptfallFinder', JS_ROUTES.LIFE_POLICY_DETAIL, {
            template: 'assets/lifeDetailCard.tpl.html',
            label: 'Detail Life',
            type: 'home',
            controller: 'AngularCommunicationCtrlPostsalesComplete'
          });
        }
      ])
      .directive(
        LPC_AJS_DIRECTIVE_EXT.LIFE_DETAIL,
        downgradeComponent({component: LifeDetailExtComponent})
      );
    /* SUBCARD */
  }

  // pagina dei movimenti
  function registerOtpDocuments() {
    angular.module('app')
      .run(factory('digitalSignDocuments.tpl.html', digitalSignDocuments))
      .config([
        'parallelRoutingProvider',
        (pRouter) => {
          pRouter.when('ptfallFinder', JS_ROUTES_EXT.OTP_DOCUMENTS_PAGE, {
            template: 'assets/digitalSignDocuments.tpl.html',
            label: 'Digital Sign - Documents',
            type: 'home',
            controller: 'AngularCommunicationCtrlPostsalesComplete'
          });
          // pRouter.when('ptfallFinder', JS_ROUTES_EXT.BACK_DETAIL_DEMAGE, {
          //   template: 'assets/digitalSignDocuments.tpl.html',
          //   label: 'Otp Documents',
          //   type: 'home',
          //   controller: 'AngularCommunicationCtrlPostsalesComplete'
          // });
        }
      ])
      .directive(
        LPC_AJS_DIRECTIVE_EXT.OTP_DOCUMENTS_PAGE,
        downgradeComponent({component: DigitalSignDocumentsComponent})
      );
  }

  function registerInboundDocumentDetail() {
    angular.module('ptfall-card-finder')
    .run(factory('inboundDocumentDetail.tpl.html', inboundDocumentDetailExt))
    .config([
      'parallelRoutingProvider',
      (pRouter) => {
        pRouter.when('ptfallFinder', JS_ROUTES.LIFE_INBOUND_DOC_INQURY, {
          template: 'assets/inboundDocumentDetail.tpl.html',
          label: 'Inbound Document Detail',
          type: 'home',
          controller: 'AngularCommunicationCtrlPostsalesComplete'
        });
      }
    ])
    .directive(
      LPC_AJS_DIRECTIVE_EXT.LIFE_INBOUND_DOC_INQURY,
      downgradeComponent({component: LpcInquiryInDocumentExtComponent})
    );
  }
}
