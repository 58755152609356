<div [formGroup]="formGroup" [ngSwitch]="inputType">

  <div class="form-group" *ngSwitchCase="'ENUM'">
    <div *ngIf="active">
      <label class="label-core" translate>{{ getLabel(definition.code) }}</label>
      <lpc-required *ngIf="definition.mandatory" required="true"></lpc-required>
      <!--<pnc-advanced-select class="ng-select" [customControlName]="'factor'"
                          [selectedValue]="currentValue()"
                          [customLabel]="'description'"
                          [customValue]="'id'"
                          [optionList]="definition.values" [customForm]="formGroup"
                          [compulsory]="true" [enableAutoSelect]="true">
      </pnc-advanced-select>-->
      <select formControlName="factor" class="form-control" [attr.data-qa]="'factor-select-' + definition.code"
              [attr.data-mod]="'factor' + definition.code + definition.readOnly"
              [attr.data-obb]="'factor' + definition.code + true">
      <option [disabled]="true" [value]="null" translate>-- SELEZIONA --</option>
      <option [value]="option.id" *ngFor="let option of definition.values; trackBy: trackByFn" translate>{{option.description}}</option>
      </select>
    </div>
    <div *ngIf="!active">
      <span translate>{{ getLabel(definition.code) }}</span> : <span translate>{{getSelectedLabel(formGroup.get('factor').value)}}</span>
    </div>
  </div>

  <div class="form-group" *ngSwitchCase="'INT'">
    <div *ngIf="active">
      <label class="label-core" translate>{{ getLabel(definition.code) }}</label>
      <lpc-required *ngIf="definition.mandatory" required="true"></lpc-required>
      <input formControlName="factor" class="form-control" [attr.data-qa]="'factor-' + definition.code"
            [attr.data-mod]="'factor' + definition.code + definition.readOnly"
            [attr.data-obb]="'factor' + definition.code + true">
    </div>
    <div *ngIf="!active">
      <span translate>{{ getLabel(definition.code) }}</span> : <span translate>{{formGroup.get('factor').value}}</span>
    </div>
  </div>

  <div class="form-group" *ngSwitchCase="'DATE'">
    <div *ngIf="active">
      <lpc-karma-datepicker formControlName="factor" [required]="true" [label]="getLabel(definition.code)"></lpc-karma-datepicker>
    </div>
    <div *ngIf="!active">
      <span translate>{{ getLabel(definition.code) }}</span> : {{getDate(formGroup.get('factor').value)}}
    </div>
  </div>

  <div class="form-group" *ngSwitchCase="'DOUBLE'">
    <div *ngIf="active">
      <label class="label-core" translate>{{ getLabel(definition.code) }}</label>
      <lpc-required *ngIf="definition.mandatory" required="true"></lpc-required>
      <input formControlName="factor" class="form-control" [attr.data-qa]="'factor-' + definition.code"
            [attr.data-mod]="'factor' + definition.code + definition.readOnly"
            [attr.data-obb]="'factor' + definition.code + true">
    </div>
    <div *ngIf="!active">
      <span translate>{{ getLabel(definition.code) }}</span> : <span translate>{{formGroup.get('factor').value}}</span>
    </div>
  </div>

  <div class="form-group" *ngSwitchCase="'BENEFICIARY'">
    <div *ngIf="active">
      <label class="label-core" translate>lpc_life_detail_beneficiary</label>
      <lpc-required *ngIf="definition.mandatory" required="true"></lpc-required>
      <input formControlName="factor" class="form-control" [attr.data-qa]="'factor-' + definition.code"
            [attr.data-mod]="'factor' + definition.code + definition.readOnly"
            [attr.data-obb]="'factor' + definition.code + true">
    </div>
    <div *ngIf="!active">
      <span translate>lpc_life_detail_beneficiary</span> : {{formGroup.get('factor').value}}
    </div>
  </div>

  <div class="form-group" *ngSwitchCase="'IBAN'">
    <div *ngIf="active">
      <label class="label-core" translate>IBAN</label>
      <lpc-required *ngIf="definition.mandatory" required="true"></lpc-required>
      <input formControlName="factor" class="form-control" [attr.data-qa]="'factor-' + definition.code"
            [attr.data-mod]="'factor' + definition.code + definition.readOnly"
            [attr.data-obb]="'factor' + definition.code + true">
    </div>
    <div *ngIf="!active">
      <span translate>IBAN</span> : {{formGroup.get('factor').value}}
    </div>
  </div>

  <div class="form-group" *ngSwitchCase="'DATELIST'">
    <div *ngIf="active">
      <label class="label-core" translate>{{ getLabel(definition.code) }}</label>
      <lpc-date-stepper formControlName="factor" [inputField]="inputField" [outputStringNotUTC]="true"></lpc-date-stepper>
    </div>
    <div *ngIf="!active">
      <span translate>{{ getLabel(definition.code) }}</span> : {{formGroup.get('factor').value | date : 'dd/MM/yyyy'}}
    </div>
  </div>

  <div class="factor form-group" *ngSwitchCase="'STRING'">
    <div *ngIf="active">
      <label class="label-core" translate>{{ getLabel(definition.code) }}</label>
      <lpc-required *ngIf="!!definition.mandatory" required="true"></lpc-required>
      <input [ngClass]="{'is-invalid': formGroup.get('factor').invalid}" formControlName="factor"
        class="form-control" [attr.data-qa]="'factor-' + definition.code"
        [attr.data-mod]="'factor' + definition.code + definition.readOnly"
        [attr.data-obb]="'factor' + definition.code + true">
    </div>
    <div *ngIf="!active">
      <span translate>{{ getLabel(definition.code) }}</span> : {{formGroup.get('factor').value}}
    </div>
  </div>

</div>
