<div class="example-row dati-amm-page" id="dati-amm-page">
  <form [formGroup]="form">

    <div rgi-rx-accordion [multi]="true">
      <ng-container *ngIf="showMasterPolicy()">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="header-icon rgifont"></span>
            <span class="header-text" data-qa="accordion-master-policy" translate>lic_policy_master_details</span>
          </div>
          <div content>
            <pnc-dynamic-table [rows]="rowsPolicyMasterData" [objectMap]="policyMasterMap"></pnc-dynamic-table>
            <div *ngIf="coversMap?.size !== 0">
              <pnc-dynamic-table [rows]="rowsCovers" [objectMap]="coversMap"></pnc-dynamic-table>
            </div>
          </div>
        </rgi-rx-expansion-panel>
        <br><br>
      </ng-container>


      <div class="example-row">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="header-icon rgifont rgi-dettagli_tecnici"></span>
            <span class="header-text" translate>lic_administrative_data</span>
          </div>
          <div content>
            <div class="panel-body">
              <!-- FILIALI -->
              <div class="col-lg-6 factor-box"  *ngIf="!!branches">
                <rgi-rx-form-field>
                  <label rgiRxLabel>
                    <span>{{branches.label | translate}}</span>
                  </label>
                  <select rgiRxNativeSelect formControlName="branch" data-qa="branch">
                    <option value=""></option>
                    <option *ngFor="let branch of branches.values" [value]="branch.id">{{ branch.description }}</option>
                  </select>
                </rgi-rx-form-field>
              </div>
              <!-- CONVENZIONI -->
              <div class="col-lg-6 factor-box" *ngIf="!!product.agreements?.length">
                <rgi-rx-form-field>
                  <label rgiRxLabel>{{'lic_agreement' | translate}}</label>
                  <select rgiRxNativeSelect formControlName="agreement" (change)="changeConv($event)" data-qa="agreement">
                    <option [value]=""></option>
                    <option *ngFor="let agreement of product.agreements; trackBy: trackByFn" [value]="agreement.code">{{ agreement.description }}</option>
                  </select>
                </rgi-rx-form-field>
              </div>
            </div>

            <!--OPZIONE POLIZZA DA REINVESTIMENTO-->
            <div *ngIf="reinvestmentFactor" class="col-lg-6 factor-box-content reinvest-factor">
              <ng-container [group]="form" [licFactorLoader]="reinvestmentFactor">
              </ng-container>
              <button type="button" class="btn btn-default btn-address btn-xs" *ngIf="isReinvestment"
                (click)="openReinvestments()">
                <span class="rgifont rgi-pencil-square-o"></span>
              </button>
            </div>

            <!-- FINE OPZIONE POLIZZA DA REINVESTIMENTO-->

            <!-- FATTORI -->

            <div class="panel-body"  style="height: auto;">
              <ng-container *ngFor="let item of groupedProductFactors">
                <ng-container *ngIf="!item.description; else groupedFactors">
                  <div class="row">
                    <lic-factors
                      (changeValEmitter)="updateFactors(false)"
                      [parentForm]="form"
                      [fattori]="item.factors">
                    </lic-factors>
                  </div>
                </ng-container>
                <ng-template #groupedFactors>
                  <div class="example-row">
                    <rgi-rx-expansion-panel color="default" [expanded]="true">
                      <div rgi-rx-expansion-panel-header >
                        <span translate>{{item.description}}</span>
                      </div>
                      <div class="row">
                        <lic-factors
                          (changeValEmitter)="updateFactors(false)"
                          [parentForm]="form"
                          [fattori]="item.factors">
                        </lic-factors>
                      </div>
                    </rgi-rx-expansion-panel>
                  </div>
                </ng-template>
              </ng-container>
            </div>

            <!-- COINTESTATARIO MUTUO-->

            <div *ngIf="showCoSigner()"  class="panel-body">
              <div class="factor-box">
                <label class="label-core" translate>lic_co_applicant</label>
                <lic-required required="true"></lic-required>
                <br>
                <lic-select-cosigned (delete)="resetCosigned()" [role]="cosignedRoleCode" [parentForm]="form"
                  [val]="form.controls['cosigned']?.value" (eventPropagation)="openAnag($event)">
                </lic-select-cosigned>
              </div>
            </div>

            <!-- FRAZIONAMENTO -->
            <div class="panel-body">
              <div class="col-lg-6 factor-box">
                <rgi-rx-form-field >
                  <label rgiRxLabel for="paymentFrequency">
                    <span>{{'lic_payment_frequency' | translate}}</span>
                  </label>
                  <select rgiRxNativeSelect formControlName="paymentFrequency" (change)="changeFraz($event)" data-qa="paymentFrequency" [attr.data-obb]="'paymentFrequency' + true">
                    <option value=""></option>
                    <option *ngFor="let frequency of product.paymentFrequencies; trackBy: trackByFn" [value]="frequency.code">{{ frequency.description }}</option>
                  </select>
                </rgi-rx-form-field>
              </div>
            </div>

          </div>
        </rgi-rx-expansion-panel>
        <br><br>
      </div>


      <div class="example-row" *ngIf="showAccordionPolicyAddress">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="header-icon rgifont rgi-indirizzi"></span>
            <span class="header-text" translate>lic_policy_address</span>
          </div>
          <div content>
            <!-- CONVENZIONI -->
            <div class="panel-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="text-center ordinary-mail" translate>lic_ordinary_mail</div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 factor-box">
                  <div class="row">
                    <div class="col-lg-1">
                      <input class="custom-radio" type="radio" [disabled]="true" [checked]="true">
                    </div>
                    <div class="col-lg-10"><input class="form-control" disabled [value]="getAddress()"></div>
                    <div class="col-lg-1">
                      <div class="input-group-btn">
                        <button type="button" attr.data-qa="select-plus-address" class="btn btn-default"
                          [disabled]="!showAddPolicyAddress">
                          <span class="rgifont rgi-plus"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </rgi-rx-expansion-panel>
        <br><br>
      </div>


      <!-- inizio Accordion Clausole -->
      <div class="example-row" *ngIf="product.clauses?.length">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="header-icon rgifont rgi-clausole"></span>
            <span class="header-text" translate>lic_Clauses</span>
          </div>
          <div content>
            <div class="panel-body">
              <form class="p-2" [formGroup]="formClauses">
                <ng-container *ngFor="let data of product.clauses">
                  <div [formGroupName]="data.code">
                      <input class="mr-1" [id]="'name' + data.code" type="checkbox" (click)="checkClauseValidation(data.code, true)" formControlName="selectedValue" [value]="data.selected">
                      <label class="mr-1 label-core" [for]="'name' + data.code">{{ data.description }}</label>
                      <span class="rgifont rgi-pencil-square-o" [ngStyle]="{ color: formClauses.get(data.code)?.get('selectedValue').value && data.compulsoryText && (!formClauses.get(data.code)?.get('textValue').value || formClauses.get(data.code)?.get('textValue').value.trim() === '') ? '#d22323' : '#525a60', cursor: 'pointer' }" (click)="clauseModal.open(); saveClauseText(formClauses.get(data.code)?.get('textValue')?.value)"></span>
                      <span class="text-required" [ngStyle]="{ color: data.compulsoryText ? '#d22323' : '#525a60' }" *ngIf="data.compulsoryText && formClauses.get(data.code)?.get('selectedValue').value" translate>lic_text_required</span>
                    <rgi-rx-modal #clauseModal>
                      <rgi-rx-panel [attr.data-qa]="data.description + '-clause-panel'">
                          <rgi-rx-panel-header [closeable]="true" (onClose)="clauseModal.close()">
                            <p>{{ data.description }}</p>
                          </rgi-rx-panel-header>
                          <ng-container content>
                            <rgi-rx-form-field>
                              <textarea rgiRxInput formControlName="textValue" [attr.data-qa]="'clausolaTextArea'" [value]="formClauses.get(data.code)?.get('textValue')?.value"></textarea>
                            </rgi-rx-form-field>
                          </ng-container>

                          <rgi-rx-panel-footer>
                            <button *ngIf="data.textType !== 0" class="text-uppercase rgi-ui-btn rgi-ui-btn-secondary" (click)="clauseModal.close(); checkClauseValidation(data.code, true)" data-qa="save-btn" translate>lic_save</button>
                            <button class="text-uppercase rgi-ui-btn rgi-ui-btn-primary" (click)="clauseModal.close(); checkClauseValidation(data.code, false)" data-qa="close-btn" translate>lic_close</button>
                          </rgi-rx-panel-footer>
                      </rgi-rx-panel>
                    </rgi-rx-modal>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
        </rgi-rx-expansion-panel>
        <br><br>
      </div>
      <!-- Fine Accordion Clausole -->

      <!-- inizio Accordion Dati Pagamento -->
      <div class="example-row" *ngIf="showAccordionPayments">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span class="header-icon rgifont rgi-ui-icon-credit-card"></span>
            <span class="header-text" data-qa="accordion-payments" translate>lic_payment_method</span>
          </div>
          <div content>
            <div class="panel-body" *ngIf='showFirstInst || showNextInst'>
              <!-- Prima Select-->
              <form [formGroup]="formPagamentiRataFirma">
                <div class="row">
                  <div class="col-lg-12 factor-box" *ngIf='showFirstInst'>
                    <div class="col-lg-3">
                      <label class="label-core">
                        {{stampPaymentLabel}}
                        <lic-required required="true"></lic-required>
                      </label>
                    </div>
                    <div class="col-lg-3">
                      <rgi-rx-form-field>
                        <select rgiRxNativeSelect
                            data-qa="paymentInfoFirst"
                            formControlName="paymentInfoFirst"
                            (change)="changeFirstPayments($event)"
                            [attr.data-obb]="'paymentInfoFirst' + true">
                          <option value=""></option>
                          <option *ngFor="let typePayment1 of firstInstPay" [value]="typePayment1.meanOfPayment.identification">{{typePayment1.meanOfPayment.description}}</option>
                        </select>
                      </rgi-rx-form-field>
                    </div>
                      <!-- PAGAMENTI AGGIUNTIVI RATA ALLA FIRMA -->
                  <div *ngIf='showFirstInst && showExtrapayForFirst' class="col-lg-12 extrapayment-section"
                  data-qa="extrapayment-section">
                  <lic-payment
                          (changeValOfEmitter)="modifyAddPayments()"
                          [parentForm]="formPagamentiRataFirma"
                          [pagamenti]="paymentField1"
                          (messageValOfEmitter)="printIBANMessage()">
                  </lic-payment>
                </div>
                  </div>

                </div>
              </form>

              <!-- Seconda Select-->
              <form [formGroup]="formPagamentiRateSuccessive">
                <div class="row">
                  <div class="col-lg-12 factor-box sel" *ngIf='showNextInst'>
                    <div class="col-lg-3">
                      <label class="label-core">
                        {{'lic_payment_method' | translate}}
                        <lic-required required="true"></lic-required>
                      </label>
                    </div>
                    <div class="col-lg-3">
                      <rgi-rx-form-field>
                        <select rgiRxNativeSelect
                          data-qa="paymentInfoNext"
                          formControlName="paymentInfoNext"
                          (change)="changeNextPayments($event)"
                          [attr.data-obb]="'paymentInfoNext' + true">
                          <option value=""></option>
                          <option *ngFor="let typePayment2 of nextInstPay" [value]="typePayment2.meanOfPayment.identification">{{typePayment2.meanOfPayment.description}}</option>
                        </select>
                      </rgi-rx-form-field>
                    </div>
                  </div>

                </div>
                <!-- PAGAMENTI AGGIUNTIVI RATE SUCCESSIVE -->
                <div *ngIf='showNextInst && showExtrapayForSecond' class="col-lg-12 extrapayment-section"
                  data-qa="extrapayment-section">
                  <lic-payment
                      (changeValOfEmitter)="modifyAddPayments()"
                      [parentForm]="formPagamentiRateSuccessive"
                      [disableField]="disablecondition ? 'paymentInfoNext' : null"
                      [pagamenti]="paymentField2"
                      (messageValOfEmitter)="printIBANMessage()">
                  </lic-payment>
                </div>
              </form>
            </div>
          </div>
        </rgi-rx-expansion-panel>
        <br><br>
      </div>
      <!-- fine Accordion Dati Pagamento -->

      <!-- Accordion Questionari -->
      <div *ngIf="showAccordionQuestionnaires" id="panel-quest">
        <rgi-rx-expansion-panel [expanded]="true" color="default">
          <div rgi-rx-expansion-panel-header>
            <span data-qa="panel-button" class="rgifont"></span>
            <span class="header-icon rgifont rgi-questionari"></span>
            <span class="header-text" data-qa="accordion-questionnaires" translate>lic_questionnaires_section</span>
          </div>
          <div content>
            <div class="panel-body">
              <lic-questionnaires (emitSave)="saveQuestionnaire($event)" (emitSaveOnInit)="saveQuestionnaire($event, true)"
                (emitDelete)="deleteQuestionnaire($event)" [questionnairesList]="questionnairesList"
                [customFormGroup]="questForm" (loaderQuest)="loaderQuestManager($event)">
              </lic-questionnaires>
              <lib-questionnaires-manager [modalClass]="'life-quest-modal-flow'" data-qa="questAsset" class="col-lg-6"
                key={{ppevoQuestKey}} d={{effectiveDate}} customQuestLoader="BY_CODE" [questionnairesCode]="validQuestsCode"
                [savedIds]="getSavedUUIDs()" (listLoad)="countTotalQuest()" (loaderQuest)="loaderQuestManager($event)">
              </lib-questionnaires-manager>
            </div>
          </div>
        </rgi-rx-expansion-panel>
      </div>
    </div>

    <!-- MESSAGGI di ERRORE -->
    <!-- <pnc-floating-error-messages [validationMessages]="validationMessages" [blockingMsgs]="blockingMessages"
      [warningMsgs]="warningMessages" [authoMsgs]="authMessages">
    </pnc-floating-error-messages> -->

    <rgi-rx-snackbar tag="validation"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="blocking"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="warning"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="auth"></rgi-rx-snackbar>


    <rgi-rx-modal #proposalSaveModal>
      <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="proposalSaveModal.close()">{{'lic_NumeroProposta' | translate}}</rgi-rx-panel-header>
        <rgi-rx-panel-footer>
          <button class="rgi-ui-btn rgi-ui-btn-secondary" (click)="proposalSaveModal.close()" translate>lic_close</button>
        </rgi-rx-panel-footer>
        <span translate>lic_Proposal_Saved</span> {{proposalNumber}}
      </rgi-rx-panel>
    </rgi-rx-modal>


    <div class="btn-group btn-group-justified">
      <div class="btn-group" *ngIf="showSaveButton()">
        <button type="submit" class="btn btn-warning pull-left btn-secondary text-uppercase" data-qa="lic-role-save"
          (click)="onSubmit(true, proposalSaveModal)" translate>lic_save</button>
      </div>
      <div class="btn-group">
        <button type="submit" (click)="onSubmit()" data-qa="lic-factors-submit"
          class="btn btn-warning pull-right text-uppercase" translate>lic_NEXT
        </button>
      </div>
    </div>
  </form>
</div>
