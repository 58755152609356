<template #productAssetModalOverlay></template>
<div class="nmf_cont_titsez nmf_cont_titsez_color">
  <span class="header-icon rgifont rgi-dettagli_tecnici nfm_font_size"></span>
  <h3 class="header-text" translate>Asset Data</h3>
  <button *ngIf="showBtnChangeVehicle" (click)="thisIsNotMyVehicle()" class="btn btn-default btn-quotation-detail" translate type="button">
    Change Vehicle<span class="glyphicon glyphicon-pencil"></span>
  </button>
  <span *ngIf="assetDataCompletenessErrors" class="rgifont rgi-survey_ko" style="color: #d22323"></span>
  <span class="pull-right header-text"> </span>
</div>


<div class="mic-start-form" id="start-form-wrapper">
  <form (ngSubmit)="onSubmit($event)" [formGroup]="assetForm">
    <div class="rgi-ui-grid-2-sm-1">
      <div class="rgi-ui-col">
        <label for="product" translate>Product:</label>
        <pnc-required required="true"></pnc-required>
        <input #product class="form-control mic-input" formControlName="product" type="text">
      </div>
      <div class="rgi-ui-col">
        <label translate>Asset Type:</label>
        <pnc-required required="true"></pnc-required>
        <input #assetType class="form-control mic-input" formControlName="assetType" type="text">
      </div>
    </div>

    <div *ngIf="stateService.getCurrentState() !== State.EDIT_ASSET_DATA_WITHOUT_PLATE" class="rgi-ui-grid-2-sm-1">
      <div class="rgi-ui-col">
        <label translate>License Plate Type:</label>
        <pnc-required required="true"></pnc-required>
        <input #plateType class="form-control mic-input" formControlName="plateType" type="text">
      </div>
    </div>

    <div class="rgi-ui-grid-2-sm-1">
      <div class="rgi-ui-col">
        <label translate>Class:</label>
        <pnc-required required="true"></pnc-required>
        <select #vehicleClass (change)="onVehicleClassChange();assetModified()" [compareWith]="compareGenericEntities"
                class="select-nativa" formControlName="vehicleClass" name="vehicleClass">
          <option></option>
          <option *ngFor="let vehicleClass of vehicleClasses; index as i; trackBy: genericEntitiesTrackByFn"
                  [ngValue]="vehicleClass">
            {{vehicleClass?.description}}
          </option>
        </select>
        <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
      </div>
      <div class="rgi-ui-col">
        <label translate>Usage:</label>
        <pnc-required required="true"></pnc-required>
        <select #usage (change)="onVehicleUsageChange();assetModified()" [compareWith]="compareGenericEntities" class="select-nativa"
                formControlName="usage" name="usage">
          <option></option>
          <option *ngFor="let usage of usages; index as i; trackBy: genericEntitiesTrackByFn" [ngValue]="usage">
            {{usage?.description}}
          </option>
        </select>
        <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
      </div>
    </div>

    <div class="rgi-ui-grid-2-sm-1">
      <div *ngIf="stateService.getCurrentState() !== State.EDIT_ASSET_DATA_WITHOUT_PLATE" class="rgi-ui-col">
        <label translate>License Plate:</label>
        <pnc-required required="true"></pnc-required>
        <input #licensePlate [value]="licensePlate?.value | uppercase" class="form-control mic-input" data-qa="licensePlate"
               formControlName="licensePlate" type="text">
      </div>
      <div *ngIf="vehicleProperties?.optionalPlateChassis || vehicleProperties?.requirePlateChassis || vehicleProperties?.registrationDateJuly"
           class="rgi-ui-col">
        <label translate>Frame</label>
        <div *ngIf="vehicleProperties?.requirePlateChassis || registrationDateBeforeJuly2006">
          <pnc-required required="true"></pnc-required>
        </div>
        <input #frame class="form-control mic-input" data-qa="frame" formControlName="frame" type="text">
      </div>
      <div class="rgi-ui-col">
        <label rgiRxLabel translate>Registration Date</label>
        <rgi-rx-date-picker [openOnClick]="false">
          <input [rgiRxDateTimeInput]="registrationDate"
            formControlName="registrationDate" data-qa="registrationDate" id="registrationDate">
          <rgi-rx-date-time #registrationDate></rgi-rx-date-time>
        </rgi-rx-date-picker>
      </div>
    </div>
    <div class="rgi-ui-grid-2-sm-1">
      <div *ngIf="extensionsListMode" class="rgi-ui-col">
        <label translate>Extensions:</label>
        <pnc-required required="true"></pnc-required>
        <div *ngIf="extensionsListMode">
          <select #extension (change)="assetModified()" [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="extension" name="extension">
            <option></option>
            <option *ngFor="let extension of extensions; index as i; trackBy: genericEntitiesTrackByFn"
                    [ngValue]="extension">{{extension?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
      </div>
    </div>
    <div class="rgi-ui-grid-2-sm-1">
      <div *ngIf="vehicleProperties?.engineNumberVisible" class="rgi-ui-col">
        <label translate>Engine Number:</label>
        <div *ngIf="vehicleProperties?.engineNumberMandatory">
          <pnc-required required="true"></pnc-required>
        </div>
        <input #engineNumber class="form-control mic-input" data-qa="engineNumber" formControlName="engineNumber"
               type="text">
      </div>
    </div>
    <div class="rgi-ui-grid-2-sm-1">
      <div class="rgi-ui-col">
        <label translate>Brand:</label>
        <pnc-required required="true"></pnc-required>
        <div *ngIf="brandsListMode">
          <select #brand (change)="onBrandChange()" [compareWith]="compareGenericEntities" class="select-nativa" formControlName="brand"
                  name="brand">
            <option></option>
            <option *ngFor="let brand of brands; index as i; trackBy: genericEntitiesTrackByFn" [ngValue]="brand">
              {{brand?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
        <div *ngIf="!brandsListMode">
          <input #brand (change)="assetModified()" class="form-control mic-input" data-qa="brand"
                 formControlName="brandDescription" type="text">
        </div>
      </div>
      <div class="rgi-ui-col">
        <label translate>Model:</label>
        <pnc-required required="true"></pnc-required>
        <!--nfIf nuova proprietà aggancio a riviste per rendere required o no -->
        <div *ngIf="modelsListMode">
          <select #model (change)="onModelChange()" [compareWith]="compareGenericEntities" class="select-nativa" formControlName="model"
                  name="model">
            <option></option>
            <option *ngFor="let model of models; index as i; trackBy: genericEntitiesTrackByFn" [ngValue]="model">
              {{model?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
        <div *ngIf="!modelsListMode">
          <input #model (change)="assetModified()" class="form-control mic-input" data-qa="model"
                 formControlName="modelDescription" type="text">
        </div>
      </div>
      <!-- Advanced Filters-->
      <ng-container *ngFor="let filterObj of advancedFilters">
        <div class="rgi-ui-col">
          <label translate>{{filterObj.description}}:</label>
          <pnc-required [required]="filterObj.mandatory"></pnc-required>
          <div *ngIf="!outOfMagazine || filterObj.code === 'fuel'">
            <select (change)="reInitializeSetups()" [compareWith]="compareGenericEntities" class="select-nativa"
              [formControlName]="filterObj.code" [name]="filterObj.code">
              <option></option>
              <ng-container *ngIf="!(outOfMagazine && filterObj.code === 'fuel'); else fuelTypeEnum">
                <option *ngFor="let filter of filterObj.values; index as i; trackBy: genericEntitiesTrackByFn"
                  [ngValue]="filter">{{filter?.description}}
                </option>
              </ng-container>
              <ng-template #fuelTypeEnum>
                <option *ngFor="let fuelType of fuelTypes; index as i; trackBy: genericEntitiesTrackByFn"
                  [ngValue]="fuelType">{{fuelType?.description}}
                </option>
              </ng-template>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
          </div>
          <div *ngIf="!(!outOfMagazine || filterObj.code === 'fuel')">
            <input (change)="assetModified()" class="form-control mic-input" [formControlName]="filterObj.code"
              type="text" [attr.data-qa]="filterObj.code">
          </div>
        </div>
      </ng-container>
    </div>
    <div class="rgi-ui-grid-2-sm-1">
      <div class="rgi-ui-col">
        <label translate>Setup:</label>
        <pnc-required required="true"></pnc-required>
        <div *ngIf="setupsListMode">
          <select #setup (change)="onSetupChange()" [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="setup" name="setup">
            <option></option>
            <option *ngFor="let setup of setups; index as i; trackBy: setupsTrackByFn" [ngValue]="setup">
              {{setup?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
        <div *ngIf="!setupsListMode">
          <input #setup (change)="assetModified()" class="form-control mic-input" data-qa="setup"
                 formControlName="setupDescription" type="text">
        </div>
      </div>
    </div>
    <div class="rgi-ui-grid-2-sm-1">
      <div [class.disabled]="!enableOutOfMagazineCheckbox" class="rgi-ui-col">
        <label translate>Values out of Catalogue</label>
        <div #outOfMagazineCheckbox (click)="valuesOutOfMagazine()"
             [class.disabled]="!enableOutOfMagazineCheckbox || !outOfBoxEnabledField" [formControlName]=""
             [ngClass]="{'pointerEnabled':outOfMagazine, 'pointerDisabled':!outOfMagazine}" class="btn btn-checkbox" ngDefaultControl
             type="checkbox">
          <span [ngClass]="{'glyphicon-ok': outOfMagazine }" class="glyphicon" style="color: #f68020;">
          </span>
        </div>

      </div>
      <div [class.disabled]="!updatableInsuredValue" class="rgi-ui-col">
        <label translate>Adapt guarantee</label>
        <div #adaptGuaranteeCheckbox (click)="updateInsuredValue()" [class.disabled]="!updatableInsuredValue || !insValueEnabledField"
             [formControlName]=""
             [ngClass]="{'pointerEnabled':adjustInsuredValue,
                'pointerDisabled':!adjustInsuredValue,
                'disabled': !updatableInsuredValue || !insValueEnabledField }"
              class="btn btn-checkbox" ngDefaultControl
             type="checkbox">
          <span [ngClass]="{'glyphicon-ok': adjustInsuredValue }" class="glyphicon" style="color: #f68020;">
          </span>
        </div>

      </div>
    </div>
    <div *ngIf="(assetForm?.invalid && submitted) || validationMessages?.length > 0" id="error-container"
         style="margin-top: 2em">
      <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                        objectType="complex" type="error"></pnc-form-message>
    </div>

    <br>
    <div *ngIf="editModeEnabled" class="btn-group btn-group-justified">
      <div class="btn-group">
        <button class="btn btn-warning pull-right text-uppercase" type="submit">
          <span translate>confirm</span>
        </button>
      </div>
    </div>
  </form>
</div>
