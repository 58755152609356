import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IncidentData } from './dsc-incident-data.model';
import { DatePipe } from '@angular/common';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';

@Component({
  selector: 'claims-dsc-incident-data',
  templateUrl: './dsc-incident-data.component.html',
  styleUrls: ['./dsc-incident-data.component.scss'],
})
export class DscIncidentDataComponent implements OnInit {
  constructor(public formBuilder: UntypedFormBuilder, private datepipe: DatePipe) {}

  @Input() incidentData: IncidentData;
  @Input() typeOpenComponent: OpenTypeSharedComponent =
    OpenTypeSharedComponent.isAccordion;
  @Input() isPot: OpenTypeSharedComponent = OpenTypeSharedComponent.isPot;
  @Input() isNewClaim: OpenTypeSharedComponent =
    OpenTypeSharedComponent.isNewClaim;7
  @Output() outputFormIncidentData: EventEmitter<IncidentData> =
    new EventEmitter();

  isAccordion: boolean = false;
  incidentDataForm: UntypedFormGroup;
  occurrenceDateSelected: string;
  occurrenceTimeSelected: string;
  dateReported: any;
  intermediaryReceiptDate: any;
  insuredReportDate: string;
  chcReceiptDate: string;
  actualOccurrenceDate: string;
  actualOccurrenceTime: string;
  isInValidDateReported: boolean = false;
  expanded: boolean = true;
  isInvalidInsuredReportDate: boolean = false;
  isInvalidReceivedIntermediary: boolean = false;
  isInvalidChcReceiptDate: boolean = false;
  viewFormIncidentData: boolean = false;
  today = new Date();
  minDate: any = null;
  futureDateReported = false;
  futureDateIsuredReported = false;
  futureDateIntermediary = false;
  futureDateChc = false;

  ngOnInit() {
    console.log(this.typeOpenComponent);
    console.log(this.isPot);
    this.isAccordion =
      this.typeOpenComponent === OpenTypeSharedComponent.isAccordion
        ? true
        : false;
    /*   this.incidentDataForm.patchValue(this.incidentData); */
    this.initFormValue();
  }

  initFormValue() {
    this.incidentDataForm = this.formBuilder.group({
      occurrenceDate: ['', Validators.required],
      occurrenceTime: ['', Validators.required],
      dateReported: ['', Validators.required],
      intermediaryReceiptDate: ['', Validators.required],
      incidentDescription: [''],
      chcReceiptDate: ['',  this.isNewClaim !== 'isReopened' && this.isNewClaim !== 'isNewClaimReopen' ? Validators.required : null],
      insuredReportDate: ['', Validators.required],
      certifiedOccurrenceDate: [false],
      actualOccurrenceDate: [''],
      actualOccurrenceTime: [''],
      additionalNotes: ['']
    }, {
      validator: [this.validateDate('dateReported'), this.validateDate('intermediaryReceiptDate'), this.validateDate('insuredReportDate'), this.validateDate('chcReceiptDate')]
    });
    if (this.isNewClaim === OpenTypeSharedComponent.isNewClaim) {
      this.occurrenceDateSelected = this.incidentData.occurrenceDate;
      this.actualOccurrenceDate = this.incidentData.actualOccurrenceDate;
      this.actualOccurrenceTime = this.incidentData.actualOccurrenceTime;
      this.insuredReportDate = this.incidentData.insuredReportDate;
      this.chcReceiptDate = this.incidentData.chcReceiptDate;
      const dateReported = new Date(this.incidentData.dateReported);
      dateReported.setDate(dateReported.getDate());
      dateReported.setHours(0);
      dateReported.setMinutes(0);
      this.dateReported = dateReported;
      this.intermediaryReceiptDate = this.incidentData.intermediaryReceiptDate;
      let date = new Date();
      if (this.incidentData.occurrenceTime.toString().length <= 5) {
        if (this.incidentData.occurrenceTime !== '') {
          const splitTime = this.incidentData.occurrenceTime.split(':');
          date.setHours(Number(splitTime[0]));
          date.setMinutes(Number(splitTime[1]));
        } else {
          date.setHours(0);
          date.setMinutes(1);
        }
        this.incidentData.occurrenceTime = date.toISOString();
      } else {
        date = new Date(this.incidentData.occurrenceTime);
      }
      this.occurrenceTimeSelected = date.toISOString();
      this.incidentDataForm.patchValue(this.incidentData, {emitEvent: false});
      if (!this.incidentData.occurrenceDateEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ) {
        this.incidentDataForm.controls.occurrenceDate.disable();
        this.incidentDataForm.controls.occurrenceTime.disable();
      } else {
        this.incidentDataForm.controls.occurrenceDate.enable();
        this.incidentDataForm.controls.occurrenceTime.enable();
      }

      !this.incidentData.dateReportedEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ?
        this.incidentDataForm.controls.insuredReportDate.disable() :
        this.incidentDataForm.controls.insuredReportDate.enable();
      !this.incidentData.intermediaryReceiptDateEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ?
       this.incidentDataForm.controls.intermediaryReceiptDate.disable() :
        this.incidentDataForm.controls.intermediaryReceiptDate.enable();
      !this.incidentData.incidentDescriptionEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ?
        this.incidentDataForm.controls.incidentDescription.disable() :
        this.incidentDataForm.controls.incidentDescription.enable();
      !this.incidentData.additionalNotesEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ?
        this.incidentDataForm.controls.additionalNotes.disable() :
        this.incidentDataForm.controls.additionalNotes.enable();
      if (this.incidentData.certifiedDataConfigurationVisible) {
        this.incidentDataForm.controls.actualOccurrenceDate.setValidators(Validators.required);
        this.incidentDataForm.controls.actualOccurrenceTime.setValidators(Validators.required);
      }
    } else {
      if (this.incidentData.dateReported === '') {
        this.incidentData.dateReported = this.incidentData.occurrenceDate;
      }
      let date = new Date();
      if (this.incidentData.occurrenceTime.toString().length <= 5) {
        if (this.incidentData.occurrenceTime !== '') {
          const splitTime = this.incidentData.occurrenceTime.split(':');
          date.setHours(Number(splitTime[0]));
          date.setMinutes(Number(splitTime[1]));
        } else {
          date.setHours(0);
          date.setMinutes(1);
        }
        this.incidentData.occurrenceTime = date.toISOString();
      } else {
        date = new Date(this.incidentData.occurrenceTime)
      }   
      this.incidentDataForm.patchValue(this.incidentData, { emitEvent: false });
      this.incidentDataForm.get('occurrenceDate').disable();
      this.incidentDataForm.get('occurrenceTime').disable();
      this.occurrenceTimeSelected = date.toISOString();
      this.occurrenceDateSelected = this.incidentData.occurrenceDate;
      this.insuredReportDate = this.incidentData.insuredReportDate;
      this.chcReceiptDate = this.incidentData.chcReceiptDate;
      this.dateReported = this.incidentData.dateReported;
      if (this.incidentData.certifiedDataConfigurationVisible) {
        this.incidentDataForm.controls.actualOccurrenceDate.setValidators(Validators.required);
        this.incidentDataForm.controls.actualOccurrenceTime.setValidators(Validators.required);
      }
    }
    const dataOcc = this.formatDateTimezone(
      this.datepipe.transform(this.incidentData.occurrenceDate, 'yyyy-MM-dd')
    );

    const dateReported = this.datepipe.transform(new Date(this.incidentData.dateReported), 'yyyy-MM-dd');
    const occurrenceDate = this.datepipe.transform(new Date(this.incidentData.occurrenceDate), 'yyyy-MM-dd');
    const currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.isInValidDateReported = occurrenceDate > dateReported && dateReported <= currentDate;

    const dateMin = new Date(occurrenceDate);
    dateMin.setDate(dateMin.getDate());
    dateMin.setHours(0);
    dateMin.setMinutes(1);
    this.minDate = dateMin;

    this.viewFormIncidentData = true;
    this.validateFormOutput(this.incidentData);
    this.onChangeValueForm();
  }

  protected validateDate(controlName: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const data = this.datepipe.transform(control.value, 'yyyy-MM-dd');
      const today = this.datepipe.transform(this.today, 'yyyy-MM-dd');
      
      if(control.value && this.datepipe.transform(control.value, 'yyyy-MM-dd') > today){
        control.setErrors({ futureDate: true });
      }      

    };
  }

  isValidDateReport() {

    const occurrenceDate = this.datepipe.transform(new Date(this.incidentData.occurrenceDate), 'yyyy-MM-dd');
    // this.occurrenceDateSelected = occurrenceDate;
    const dateMin = new Date(occurrenceDate);
    dateMin.setDate(dateMin.getDate());
    dateMin.setHours(0);
    dateMin.setMinutes(0);
    this.minDate = dateMin;

    const dtReported = this.datepipe.transform(this.incidentDataForm.get('dateReported').value, 'yyyy-MM-dd');
    const dtInsuredReported = this.datepipe.transform(this.incidentDataForm.get('insuredReportDate').value, 'yyyy-MM-dd');
    const dtIntermediaryReceiptDate = this.datepipe.transform(this.incidentDataForm.get('intermediaryReceiptDate').value, 'yyyy-MM-dd');
    const dtChcReceiptDate = this.datepipe.transform(this.incidentDataForm.get('chcReceiptDate').value, 'yyyy-MM-dd');
    const today = this.datepipe.transform(this.today, 'yyyy-MM-dd');

    this.futureDateReported = false;
    this.futureDateIsuredReported = false;
    this.futureDateIntermediary = false;
    this.futureDateChc = false;

    // controllo dateReported
    if (this.incidentDataForm.get('dateReported').value !== '') {
      if(dtReported > today){
        this.futureDateReported = true;
        this.incidentDataForm.controls.dateReported.setErrors(null);
        this.incidentDataForm.controls.dateReported.clearValidators();
        this.isInValidDateReported = false;
      } else if(dtReported >= occurrenceDate){
        this.isInValidDateReported = false;
        this.incidentDataForm.controls.dateReported.setErrors(null);
        this.incidentDataForm.controls.dateReported.clearValidators();
      } else {
        this.isInValidDateReported = true;
        this.incidentDataForm.controls.dateReported.setErrors({'': true});
      }
    } else {
      this.isInValidDateReported = false;
    }

    // controllo insuredReportDate
    if (this.incidentDataForm.get('insuredReportDate').value !== '')  {
      if(dtInsuredReported > today){
        this.futureDateIsuredReported = true;
        this.incidentDataForm.controls.insuredReportDate.setErrors(null);
        this.incidentDataForm.controls.insuredReportDate.clearValidators();
        this.isInvalidInsuredReportDate = false;
      } else if(dtInsuredReported >= dtReported){
        this.isInvalidInsuredReportDate = false;
        this.incidentDataForm.controls.insuredReportDate.setErrors(null);
        this.incidentDataForm.controls.insuredReportDate.clearValidators();
        // this.incidentDataForm.controls.insuredReportDate.updateValueAndValidity({ onlySelf: true });
      } else {
        this.isInvalidInsuredReportDate = true;
        this.incidentDataForm.controls.insuredReportDate.setErrors({'': true});
        // this.incidentDataForm.controls.insuredReportDate.updateValueAndValidity({ onlySelf: true });
      }
    } else {
      this.isInvalidInsuredReportDate = false;
    }

    // controllo intermediaryReceiptDate
    if (this.incidentDataForm.get('intermediaryReceiptDate').value !== '') {
      if(dtIntermediaryReceiptDate > today){
        this.futureDateIntermediary = true;
        this.incidentDataForm.controls.intermediaryReceiptDate.setErrors(null);
        this.incidentDataForm.controls.intermediaryReceiptDate.clearValidators();
        this.isInvalidReceivedIntermediary = false;
      } else if(dtIntermediaryReceiptDate >= dtReported) {
        this.isInvalidReceivedIntermediary = false;
        this.incidentDataForm.controls.intermediaryReceiptDate.setErrors(null);
        this.incidentDataForm.controls.intermediaryReceiptDate.clearValidators();
      } else {
        this.isInvalidReceivedIntermediary = true;
        this.incidentDataForm.controls.intermediaryReceiptDate.setErrors({'': true});
      }
    } else {
      this.isInvalidReceivedIntermediary = false;
    }

    // controllo chcReceiptDate
    if (this.incidentDataForm.get('chcReceiptDate').value) {
      if(dtChcReceiptDate > today){
        this.futureDateChc = true;
        this.incidentDataForm.controls.chcReceiptDate.setErrors(null);
        this.incidentDataForm.controls.chcReceiptDate.clearValidators();
        this.isInvalidChcReceiptDate = false;
      } else if(dtChcReceiptDate >= dtIntermediaryReceiptDate) {
        this.isInvalidChcReceiptDate = false;
        this.incidentDataForm.controls.chcReceiptDate.setErrors(null);
        this.incidentDataForm.controls.chcReceiptDate.clearValidators();
      } else {
        this.isInvalidChcReceiptDate = true;
        this.incidentDataForm.controls.chcReceiptDate.setErrors({'': true});
      }
    } else {
      this.isInvalidChcReceiptDate = false;
    }

    this.incidentDataForm.updateValueAndValidity({emitEvent: false});
    this.incidentData.isValidForm = this.incidentDataForm.valid;
  }

  onChangeValueForm() {
    this.incidentDataForm.valueChanges.subscribe((incident: IncidentData) => {
      this.validateFormOutput(incident);
    });
  }


  validateFormOutput(incident: IncidentData) {
    if (
      incident.occurrenceDate !== '' &&
      (this.incidentDataForm.get('insuredReportDate').value === '' ||
        this.incidentDataForm.get('insuredReportDate').value === null ||
        this.incidentDataForm.get('insuredReportDate').value === undefined)
    ) {
      // Se la data denuncia è valorizzata e viene svuotata

      this.incidentData.isOccurrenceDateChanged = true;
      this.incidentData.isOccurrenceDateFromEmptyToValued = false;
    } else if (
      incident.occurrenceDate === '' &&
      this.incidentDataForm.get('insuredReportDate').value !== '' &&
      this.incidentDataForm.get('insuredReportDate').value !== null &&
      this.incidentDataForm.get('insuredReportDate').value !== undefined
    ) {
      // Se la data denuncia era vuota e viene popolata

      this.incidentData.isOccurrenceDateChanged = false;
      this.incidentData.isOccurrenceDateFromEmptyToValued = true;
    } else {
      // In tutti gli altri casi

      this.incidentData.isOccurrenceDateChanged = false;
      this.incidentData.isOccurrenceDateFromEmptyToValued = false;
    }
    this.incidentData.occurrenceDate = this.incidentDataForm.get('occurrenceDate').value;
    this.incidentData.occurrenceTime = this.incidentDataForm.get('occurrenceTime').value;
    this.incidentData.dateReported = this.incidentDataForm.get('dateReported').value;
    this.incidentData.insuredReportDate = this.incidentDataForm.get('insuredReportDate').value;
    this.incidentData.intermediaryReceiptDate = this.incidentDataForm.get('intermediaryReceiptDate').value;
    this.incidentData.incidentDescription = this.incidentDataForm.get('incidentDescription').value;
    this.incidentData.additionalNotes = this.incidentDataForm.get('additionalNotes').value;
    this.incidentData.chcReceiptDate = this.incidentDataForm.get('chcReceiptDate').value;
    this.incidentData.actualOccurrenceTime = this.incidentDataForm.get('actualOccurrenceTime').value;
    this.incidentData.actualOccurrenceDate = this.incidentDataForm.get('actualOccurrenceDate').value;
    this.incidentData.certifiedOccurrenceDate = this.incidentDataForm.get('certifiedOccurrenceDate').value;

    const dateReported = this.datepipe.transform(new Date(this.incidentData.dateReported), 'yyyy-MM-dd');
    const occurrenceDate = this.datepipe.transform(new Date(this.incidentData.occurrenceDate), 'yyyy-MM-dd');
    const currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.isInValidDateReported = occurrenceDate > dateReported && dateReported <= currentDate;

    this.incidentData.isValidForm = this.incidentDataForm.valid;
    this.chcReceiptDate = this.incidentData.chcReceiptDate;
    const intermediaryReceiptDate = new Date(this.incidentData.intermediaryReceiptDate);
    intermediaryReceiptDate.setDate(intermediaryReceiptDate.getDate());
    intermediaryReceiptDate.setHours(0);
    intermediaryReceiptDate.setMinutes(0);
    this.intermediaryReceiptDate = intermediaryReceiptDate;
    this.insuredReportDate = this.incidentData.insuredReportDate;
    const dateReportedFormatted = new Date(this.incidentData.dateReported);
    dateReportedFormatted.setDate(dateReportedFormatted.getDate());
    dateReportedFormatted.setHours(0);
    dateReportedFormatted.setMinutes(0);
    this.dateReported = dateReportedFormatted;
    this.isValidDateReport();
    this.outputFormIncidentData.emit(this.incidentData);
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    const dataOutput = this.datepipe.transform(miaData, 'yyyy-MM-dd');
    return dataOutput;
  }
}
