<rgi-rx-panel class="rgi-claims-modal-large" *ngIf="detailReversalForm">
    <rgi-rx-panel-header>
        <div class="container-flex">
            <div class="title-modal p-0-15">
                <span>{{ '_CLAIMS_._SETTLEMENT_REVERSAL' | translate }}</span>
            </div>
            <div class="sub-title-modal p-0-15" *ngIf="feeDataReversal === null">
                <span>{{ '_CLAIMS_._AMOUNT' | translate }}: € - {{ amountTitle | number: '1.2-2' }} </span>
            </div>
            <div class="sub-title-modal p-0-15" *ngIf="feeModalData !== null">
                <span>{{ '_CLAIMS_._AMOUNT' | translate }}: € {{ feeModalData.totalAmount | number: '1.2-2' }} </span>
            </div>
            <div class="btn-close-custom text-right">
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal('Close')"></span>
            </div>
        </div>
    </rgi-rx-panel-header>

    <rgi-rx-panel-footer>
        <div class="btn-group btn-group-justified btn-group-justified-claim">
            <div class="btn-group p-0-01">
                <button rgi-rx-button color="primary" class="w-100-custom" (click)="closeModal('Close')">{{
                    '_CLAIMS_._BUTTONS_._CANCEL'| translate }}</button>
            </div>
            <div class="btn-group p-0-01">
                <!-- [disabled]="detailReceiptReturnForm.invalid" -->
                <button rgi-rx-button type="button" color="primary" class="w-100-custom"
                    (click)="confirmSettlementReversal()" [rgiRxTooltip]="dateErrorTooltipTemplate" [position]="'top'"
                    [trigger]="'mouseenter'" [disableTooltip]="descErrOrTooltip === ''"
                    [disabled]="descErrOrTooltip!==''">
{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
            </div>
        </div>
    </rgi-rx-panel-footer>

    <ng-container>
        <div class="card shadow" *ngIf="detailReversalForm">
            <form [formGroup]="detailReversalForm">
                <div class="row">
                    <div class="col-xs-6" style="font-size: 14px;">
                        <!-- Riga contenente la data -->
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._REVERSAL_DATE' | translate }}</label>
                            <rgi-rx-date-picker [openOnClick]="false">
                                <input rgiRxInput [rgiRxDateTimeInput]="consDate" formControlName="dateReversal"
                                    data-qa="rgi-reversal-date" placeholder="{{datepickerPlaceholder}}" [max]="today"
                                    [min]="currentReceiptDate" (ngModelChange)="enableConfirmButton()">
                            </rgi-rx-date-picker>
                            <rgi-rx-date-time #consDate></rgi-rx-date-time>
                        </rgi-rx-form-field>
                    </div>
                    <div class="col-xs-6" *ngIf="reasonReversalList.length > 0">
                        <rgi-rx-form-field>
                            <label rgiRxLabel>{{ '_CLAIMS_._REASON_REVERSAL' | translate }} </label>
                            <select rgiRxNativeSelect formControlName="reasonCancelled"
                                (ngModelChange)="enableConfirmButton()">
                                <option *ngFor="let reason of reasonReversalList;" value={{reason.code}}>
                                    {{reason.description}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                </div>
                <!-- Riga contenente il campo note -->
                <div class="row">
                    <rgi-rx-form-field>
                        <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                        <textarea rgiRxInput formControlName="note" (ngModelChange)="enableConfirmButton()"></textarea>
                    </rgi-rx-form-field>
                </div>
            </form>
        </div>
        <div class="card shadow table-white">
            <div class="card shadow" *ngIf="detailReversalForm">
                <div class="row colorBlu11">
                    &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
                    <label ng-bind="label" class="colorBlu2" style=" font-size: 16px;">
                    {{ '_CLAIMS_._TYPES_OF_LOSS' | translate }}</label>
                </div>
            </div>
            <div *ngFor="let exposure of exposures;">
                <div class="row">
                    <div class="col-xs-12 col-md-12" style="font-size: 14px;font-weight: bold;">
                        <label ng-bind="label" *ngIf="exposure.branchTitle" class="colorBlu2" style="font-size: 16px; ">
                        {{ '_CLAIMS_._CLAIM_BRANCH' | translate }}: {{exposure.branchTitle}}</label>
                        <br>
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 25%; text-align: left;">
                                    <label ng-bind="label" *ngIf="exposure.managementType" style="padding-top: 3px;">
                                        {{exposure.description}}&nbsp;
                                        {{exposure.moreInfo}}&nbsp;
                                        ({{exposure.managementType}})</label>
                                    <label ng-bind="label" *ngIf="!exposure.managementType" style="padding-top: 3px;">
                                        {{exposure.description}}&nbsp;
                                        {{exposure.moreInfo}}</label>
                                </td>
                                <td style="width: 75%; text-align: right;">
                                    <div class="col-xs-3 col-md-3">
                                        <label *ngIf="feeDataReversal === null" ng-bind="label"
                                            style="margin-right: 1%; font-weight: bold; padding-top: 12px">{{
                                            '_CLAIMS_._QUOTA_AMOUNT' | translate }}:</label>
                                        <label *ngIf="feeDataReversal === null" ng-bind="label">&euro; -
                                            {{exposure.quotaAmount |
                                            number:'1.2-2'}}</label>
                                    </div>

                                    <div class="col-xs-3 col-md-3">
                                        <label *ngIf="feeDataReversal === null" ng-bind="label"
                                            style="margin-right: 1%; font-weight: bold; padding-top: 12px">{{
                                            '_CLAIMS_._CASHED' | translate }}:</label>
                                        <label *ngIf="feeDataReversal === null" ng-bind="label">&euro; -
                                            {{exposure.amountNet | number:'1.2-2'}}</label>
                                    </div>

                                    <div class="col-xs-3 col-md-3">
                                        <label ng-bind="label"
                                            style="margin-right: 1%; font-weight: bold; padding-top: 12px">{{
                                            '_CLAIMS_._CURRENT_RESERVE' | translate }}:</label>
                                        <label ng-bind="label">&euro; {{exposure.reserveAmount |
                                            number:'1.2-2'}}</label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>


                </div>
            </div>
        </div>
        <ng-template #dateErrorTooltipTemplate>
            {{ descErrOrTooltip | translate }}
        </ng-template>
        <div class="card shadow" *ngIf="detailReversalForm && exposures.length > 0 && exposures[0].feeData !== null"
            style="padding-top: 10px;">
            <div class="row colorBlu11">
                &nbsp;&nbsp;<span class="rgifont rgi-dettagli_tecnici font-size: 16px;"></span>&nbsp;
                <label ng-bind="label" class="colorBlu2" style="font-size: 16px;">
                {{ '_CLAIMS_._INVOICE_DATA' | translate }}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-6">
                    <claims-fee-data-view-modal
                        *ngIf="detailReversalForm && exposures.length > 0 && exposures[0].feeData !== null"
                        [description]="exposures[0].description" [feeEntity]="exposureView">
                    </claims-fee-data-view-modal>
                </div>

                <div class="col-xs-6">
                    <claims-fee-data-modal
                        *ngIf="detailReversalForm && exposures.length > 0 && exposures[0].feeData !== null"
                        [openOutModal]="true" [newPayFormModal]="newPayFormModal" [exp]="exposures[0]"
                        [minDatePersonalized]="minDatePersonalized" [idCampoExp]="'Claims'" [idCampoSubExp]="null"
                        [potPayFE]="null" [isGeneratePayment]="false" [alignedInvoiceDisplay]="true"
                        (outputForm)="valueChangeForm($event)"
                        (ngModelChange)="enableConfirmButton()"></claims-fee-data-modal>
                </div>
            </div>
        </div>

    </ng-container>

</rgi-rx-panel>
