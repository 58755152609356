import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { OutputPartyEntity } from '../proposal-otp-model/party-Interface';
import { ProposalOtpService } from '../proposal-otp-service/proposal-otp.service';
import {LifeRoleService} from '@rgi/life-issue-card';
import { ModalService } from '@rgi/rx/ui';
import { IssuePrivacyEditComponent } from '../../issue-privacy-edit/issue-privacy-edit.component';
import {DynamicTableUtilService} from '../../card/utils/dynamic-table-util.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import {AnagApiParty, AnagConfiguration, AnagStatelessService, PACONF_VALUES, AnagConfigurationFilter, AnagStorageService} from '@rgi/anag';
import {DatePipe} from '@angular/common';
import { AnagIntPrefixEntity } from '@rgi/anag/lib/anag-model/anag-domain/anag-int-prefix-entity';
import {PushMessageHandlerService, RgiRxPushMessage, RgiRxPushMessageContent} from '@rgi/rx';

@Component({
  selector: 'iiab-mic-proposal-otp',
  templateUrl: './proposal-otp.component.html',
  styleUrls: ['./proposal-otp.component.scss']
})
export class ProposalOtpComponent implements OnInit {

  @Input() contractId: string;
  @Input() portfolio: string;
  @Input() partyHolder: any; // OutputPartyEntity;
  @Output() subjectChanged: EventEmitter<any> = new EventEmitter<any>();

  subject: any;
  anagSubject: any;

  mobilePhone = '';
  email = '';
  privacies: any[] = []; // PrivacyConsensu

  dynamicTableConsensus: object[] = [];
  consensusMap: Map<number, object[]>;
  rowsConsensus: number[];
  nCol = 3;

  document;
  anagConfig: AnagConfiguration;
  internationalPrefixes: AnagIntPrefixEntity[];

  // stateMgrProvider: any;

  constructor(
    private proposalOtpService: ProposalOtpService,
    private lifeRoleService: LifeRoleService,
    public modalService: ModalService,
    public dynamicTableUtilityService: DynamicTableUtilService,
    protected translateService: RgiRxTranslationService,
    public statelessService: AnagStatelessService,
    public datePipe: DatePipe,
    protected anagStorage: AnagStorageService,
    public pushMessageHandler: PushMessageHandlerService
  ) {
  }

  ngOnInit(): void {
    if (this.portfolio !== 'Life' && !this.partyHolder) {
      this.proposalOtpService.getParties(this.contractId).subscribe((res: OutputPartyEntity) => {
        console.log(res);
        this.partyHolder = res;
        this.getSubject();
      });
    } else {
      this.getSubject();
    }
  }

  getSubject() {
    if (this.portfolio !== 'Life') {
      this.subject = this.partyHolder.subscriber;
      this.proposalOtpService.getSubjectData(this.subject).subscribe(resAnagSub => {
        this.anagSubject = resAnagSub.subject;
        this.document = this.proposalOtpService.getDocumentForOtp('Damage', this.anagSubject.documents);
        this.setData();
      });
    } else {
      this.subject = this.lifeRoleService.holderSubj;
      this.document = this.proposalOtpService.getDocumentForOtp('Life', this.subject.documents);
      this.setData();
    }
  }

  setData() {
    this.privacies = this.subject.privacyConsensus;
    const mobilePhoneObj = this.subject.mobilePhone ? this.subject.mobilePhone[0] : undefined;
    this.mobilePhone = this.concatMobilePhone(mobilePhoneObj);
    this.email = this.subject.emails ? this.subject.emails[0] : '';
    // }
    this.checkNotes();
    this.createConsensusDynamicTable();
    this.retrievePartyConfig(this.subject);
  }

  concatMobilePhone(mobilePhoneObj: any) {
    let mobilePhone = '';
    if (mobilePhoneObj) {
      mobilePhone +=  mobilePhoneObj.internationalPrefix;
      if (mobilePhoneObj.localPrefix) {
        mobilePhone += mobilePhoneObj.localPrefix;
      }
      mobilePhone += mobilePhoneObj.number;
    }
    return mobilePhone;
  }


  getDescriptionFromPrivacyConsensus(consensus: any): string {
    const privacyConsensusValue: any = consensus ? consensus.privacyConsensusValue : undefined;
    return privacyConsensusValue ? privacyConsensusValue.descrizione ? privacyConsensusValue.descrizione : privacyConsensusValue.description ? privacyConsensusValue.description : '' : '';
  }

  getValueFromPrivacyConsensus(consensus: any): string {
    const privacyConsensusValue: any = consensus ? consensus.privacyConsensusValue : undefined;
    return privacyConsensusValue ? privacyConsensusValue.codice ? privacyConsensusValue.codice : privacyConsensusValue.code ? privacyConsensusValue.code : '' : '';
  }

  getDescriptionFromPrivacy(privacy: any): string {
    return privacy ? privacy.privacy ? privacy.privacy.description : '' : '';
  }

  createConsensusDynamicTable() {
    this.dynamicTableConsensus = [];
    if (this.privacies && this.privacies.length > 0) {
      const otpPrivacy = this.privacies.find(p => {
        return p.privacy.code === '_FROTP';
      });
      this.dynamicTableUtilityService.putDataOnDynamicTable(this.dynamicTableConsensus, this.getDescriptionFromPrivacy(otpPrivacy), this.getDescriptionFromPrivacyConsensus(otpPrivacy));
      // this.privacies.forEach(p => {
      //   this.dynamicTableUtilityService.putDataOnDynamicTable(this.dynamicTableConsensus, this.getDescriptionFromPrivacy(p), this.getDescriptionFromPrivacyConsensus(p));
      // });
      this.consensusMap = this.dynamicTableUtilityService.generateDynamicTable(this.dynamicTableConsensus);
      this.rowsConsensus = this.dynamicTableUtilityService.composeArray(Math.ceil(this.dynamicTableConsensus.length / this.nCol));
    }
  }

  checkNotes() {
    let msgCode = [];
    let messages : RgiRxPushMessage[] = [];
    this.pushMessageHandler.clear();
    if (this.privacies && this.privacies.length > 0) {
      const otpPrivacy = this.privacies.find(p => {
        return p.privacy.code === '_FROTP';
      });
      if (!otpPrivacy || this.getValueFromPrivacyConsensus(otpPrivacy) !== '1') {
        msgCode.push('_IIAB_._OTP_._DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_');
      }
    } else {
      msgCode.push('_IIAB_._OTP_._DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_');
    }
    if (!this.mobilePhone || this.mobilePhone === '') {
      msgCode.push('_IIAB_._OTP_._MOBILE_NOT_PRESENT_NOT_SIGNED_');
    }
    if (!this.email || this.email === '') {
      msgCode.push('_IIAB_._OTP_._EMAIL_NOT_PRESENT_NOT_SIGNED_');
    }
    if (!this.document || !this.document.documentType || !this.document.documentNumber || !this.document.expirationDate) {
      msgCode.push('_IIAB_._OTP_._DOCUMENT_DATA_NOT_PRESENT_NOT_SIGNED_');
    }
    msgCode.forEach(p => {
      this.translateService.translate(p).subscribe(translation => {
        messages.push(this.createOtpWarningMessage(translation));
        this.pushMessageHandler.notify(...messages);
      });
    });
  }

  createOtpWarningMessage(text: string): RgiRxPushMessage {
    let message: RgiRxPushMessage;
    let content: RgiRxPushMessageContent = text;
    message = new RgiRxPushMessage(content, {
      dismissible: false,
      status: "default",
      options: {
        icon : "rgi-ui-icon-info",
      }
    });
    return message;
  }

  edit() {
    const copiedSubject = this.getCopiedSubject();
    const privacyModal = this.modalService.openComponent(
      IssuePrivacyEditComponent, {party: copiedSubject,
        partyConfig: this.anagConfig,
        internationalPrefixes: this.internationalPrefixes,
        portfolio: this.portfolio
      });
    privacyModal.modal.enableClickBackground = false;
    privacyModal.modal.onClose.subscribe(responseSubject => {
      if (responseSubject) {
        if (this.portfolio !== 'Life') {
          this.updatePartyHolder(responseSubject);
        } else {
          this.lifeRoleService.holderSubj = responseSubject;
        }
        this.getSubject();
        this.setData();
      }
    });
  }

  getCopiedSubject() {
    if (this.portfolio !== 'Life') {
      return this.dynamicTableUtilityService.deepClone(this.anagSubject);
    } else {
      return this.dynamicTableUtilityService.deepClone(this.subject);
    }
  }

  updatePartyHolder(responseSubject) {
    const motorSubject = this.partyHolder.subscriber;
    motorSubject.privacyConsensus = responseSubject.privacyConsensus;
    motorSubject.mobilePhone = responseSubject.mobilePhone;
    motorSubject.emails = responseSubject.emails;
    motorSubject.idLatestPhotos = responseSubject.idLatestPhotos;
    this.partyHolder.subscriber = motorSubject;
    this.proposalOtpService.getPartyPhotos(this.contractId, responseSubject.objectId, responseSubject.idLatestPhotos).subscribe(res => {
      this.proposalOtpService.updateContractParties(this.contractId, this.partyHolder).subscribe(result => {
        this.subjectChanged.emit();
      });
    });
  }

  formatDateToString(date: Date): string {
    const DATE_FORMAT = this.statelessService.getLocaleDateFormat();
    return this.datePipe.transform(date, DATE_FORMAT);
  }

  retrievePartyConfig(inputParty: AnagApiParty) {
    const configFilter = new AnagConfigurationFilter();
    configFilter.partyType = inputParty.subjectType && inputParty.subjectType.codice ? parseInt(inputParty.subjectType.codice) : PACONF_VALUES.FILTER_DEFAULT;
    configFilter.country = inputParty.partyKey && inputParty.partyKey.length > 0 ? inputParty.partyKey[0].country : '';
    configFilter.legalForm = PACONF_VALUES.FILTER_DEFAULT;
    configFilter.partyRole = 1001;

    this.statelessService.retrievePartyConf(this.statelessService.getFlowDataUpdatedConfFilter(configFilter, null)).pipe().subscribe
      (conf => {
        if (typeof conf !== 'string' && conf.output.partyConfig) {
          this.anagConfig = conf.output;
        }
    });

    this.anagStorage.getIntPrefixes(null).pipe().subscribe
      (internationalPrefixes => {
        if (internationalPrefixes) {
          this.internationalPrefixes = internationalPrefixes;
        }
    });
  }


}
