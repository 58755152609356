export enum InstalmentType {
    ANNUAL_PREMIUM = 'lpc_AnnualPremium',
    FIRST_INSTALMENT = 'lpc_FirstInstallment',
    NEXT_INSTALMENT = 'lpc_NextInstallment',
    SINGLE_PREMIUM = 'lpc_single_premium'
}

export interface QuotationRiskData {
  code: string;
  selected: boolean;
  subRisks: QuotationSubRiskData[];
  factors: any[];
  purchaseCommissions: any[];
}

export interface QuotationSubRiskData {
  code: string;
  selected: boolean;
  factors: any[];
  purchaseCommissions: any[];
}
