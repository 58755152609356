import { Component, OnInit, Input, ViewEncapsulation, Optional, Inject} from '@angular/core';
import { Commission, Risk } from '../../models/postsales-operations-response.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyCacheService, LpcCurrencyCache } from '../../services/currency-cache.service';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';


@Component({
  selector: 'lpc-commission-detail-modal',
  templateUrl: './lpc-commission-detail-modal.component.html',
  styleUrls: ['./lpc-commission-detail-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LpcCommissionDetailModalComponent implements OnInit {
  // formatter Options
  public numberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2
  };

  commissions: Commission[] = [];
  @Input() risks: Risk[];

  constructor(protected activeModal: NgbActiveModal, 
    protected translateService: TranslationWrapperService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
  ) { }

  ngOnInit() {
    const riskVector = [];
    const allCommission = [];
    this.risks.forEach((r) => {
      riskVector.push(r);

      if (r.purchaseCommissions != null && r.purchaseCommissions.length > 0) {
        r.purchaseCommissions.forEach((com) => {
          const find = allCommission.find((c) => c.code === com.code );
          if (!find) {
            allCommission.push(com);
          }
        });
      }

      if (r.subRisks != null && r.subRisks.length > 0) {
        r.subRisks.forEach((sr) => {
          riskVector.push(sr);

          if (sr.purchaseCommissions != null && sr.purchaseCommissions.length > 0) {
            sr.purchaseCommissions.forEach((com) => {
              const find = allCommission.find((c) => c.code === com.code );
              if (!find) {
                allCommission.push(com);
              }
            });
          }
        });
      }
    });
    this.risks = riskVector;
    this.commissions = allCommission;
  }

  closeModal() {
    this.activeModal.close();
  }

  getListOfCommission() {
    const vectorCommission = [];

    this.risks.forEach((r) => {
      if (r.purchaseCommissions != null && r.purchaseCommissions.length > 0) {
        r.purchaseCommissions.forEach(element => {
          vectorCommission.push(element);
        });
      }
    });

    return vectorCommission;
  }

  getRiskWithProv() {
    return this.risks.filter((risk) => risk.purchaseCommissions != null && risk.purchaseCommissions.length > 0);
  }

  getValueFromRisk(risk: Risk, commissionCode: string): string {
    const find = risk.purchaseCommissions.find((com) => com.code === commissionCode);
    return !!find ? find.value.toString() : null;
  }

}
