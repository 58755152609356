import { Component, Inject, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { ExposureEntity } from '../domain/exposure-entity';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { PaymentEntity } from '../domain/payment-entity';
import { FeeDataModalComponent } from '../fee-data-modal/fee-data-modal.component';
import { NewPaymentService } from '../new-payment.service';
import { ClaimDocument } from '../domain/claim-document';
import { PreviewDocModalComponent } from '../preview-doc-modal/preview-doc-modal.component';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { FeeDataEntity } from '../domain/fee-data-entity';
import { AlertService, SessionService } from '@rgi/digital-claims-common-angular';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { PotentialPaymentEntity } from '../domain/potential-payment-entity';
import { SubjectEntity } from '../domain/subject-entity';
import { ReceiptReturnModalComponent } from '../receipt-return-modal/receipt-return-modal.component';
import { BodyRequest, DetailReceiptReturn } from '../receipt-return-modal/receipt-return.interface';
import { CancellationModalComponent } from '../cancellation-modal/cancellation-modal.component';
import { ModalService } from '@rgi/rx/ui';
import { CancellationBodyRequest, ResponseSaveCancelled,
  receiptCancellation, settlementsCancellation } from '../cancellation-modal/cancellation.interface';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { SettlementReversalComponent } from '../settlement-reversal/settlement-reversal.component';
import { ResponseSaveReversal, ReversalBodyRequest } from '../settlement-reversal/settlement-reversal.interface';
import { NewSettlementDetailModalComponent } from '../new-settlement-detail-modal/new-settlement-detail-modal.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineAll } from 'rxjs/operators';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'claims-new-payment-detail',
  templateUrl: './new-payment-detail.component.html',
  styleUrls: ['./new-payment-detail.component.scss']
})
export class NewPaymentDetailComponent implements OnInit, OnDestroy, RoutableComponent {
  public static PROPERTY_SHOW_SDI_PROTOCOL_NUMBER: string = 'ShowSDIProtocolCod';
  public static PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV: string = 'ClaimsManagementSapSRV';
  public static PROPERTY_CLAIMS_ENABLE_FORM_PHOTO: string = 'EnableFormPhoto';
  public static PROPERTY_ENABLED_CLAIMS_PAYMENT_URGENCY: string = 'ClaimsPaymentUrgency';
  public static PROPERTY_ENABLED: string = 'Enable';
  public showSDIProtocolNumber: boolean = false;
  public claimsManagementSapSRV: boolean = false;
  public isAbilitatoClaimsPaymentUrgency: boolean = false;
  public enableFormPhoto: boolean = false;
  public jump: boolean = false;
  eTipoMovLiquidazQuietanza: TypeEnum;
  eMezzoMagamentoBonifico: TypeEnum;
  eMezzoPagamentoBonficoEsteroEur: TypeEnum;
  eMezzoPagamentoBonificoEstero: TypeEnum;
  eTipoDefinizContAll: TypeEnum[] = [];
  eTipoPagamentoAll: TypeEnum[] = [];
  eTipoQuietanzaAll: TypeEnum[] = [];
  eTipoGestionePagamentoAll: TypeEnum[] = [];
  eShippingChannelAll: TypeEnum[] = [];
  eAddressETypeAll: TypeEnum[] = [];
  eTipoBeneFAll: TypeEnum[] = [];
  eMezzoPagamentoAll: TypeEnum[] = [];
  ePriorityAll: TypeEnum[] = [];
  public authSrv: any;
  public resCore: any;
  claimCurrent: any;
  [x: string]: any;
  @Input() finPays: Array<PaymentEntity>;
  @Input() message: string;
  @Input() classMsg: string;
  @Input() consult: boolean;
  @Input() document: ClaimDocument;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Input() card: any;
  public claimNumber: string;
  public party: string;
  public idSettlement: string;
  public showQuoteEvaluation: boolean = true;
  public receiptStatus: string;
  public receiptReturnDate: string;
  public receiptAnnulloMotivo: string;
  public receiptNote: string;
  // public finPay: PaymentEntity;
  // public mappaBranchExp: Map<string, Array<ExposureEntity>>;
  // public listExpComboVisual: Array<ExposureEntity>;
  public feeData: FeeDataEntity;
  public listFunz: any;
  public isFromSaveNewPayment: boolean = true;
  public potPayFE: InitPotentialPaymentEntity;
  public disableSettlement = false;
  public disableManage = false;
  public noVatOrFiscalCode = false;
  public enableUpgrade = false;
  showReturnReceipt: boolean = false;
  // (RDC-236) - Inizio
  receiptDate: Date; // Data quietanza
  descErrOrTooltip: string = ''; // Descrizione errore o messaggio tooltip
  descErrOrTooltipLiquidationTransferDelete: string = ''; // Descrizione errore o messaggio tooltip
  showCancellationReceipt: boolean = true; // Abilita (true)/Disabilita (false) bottone annullamento Quietanza
  showCancellationSettlement: boolean = true; // Abilita (true)/Disabilita (false) bottone annullamento/storno Liquidazione
  showLiquidationSettlementTooltip: boolean = true; // Abilita (true)/Disabilita (false) bottone annullamento/storno Liquidazione
  showLiquidationTransferDeleteTooltip: boolean = true; // Abilita (true)/Disabilita (false) bottone annullamento/storno Liquidazione
  disableLiquidationSettlement: boolean = true; // Abilita (true)/Disabilita (false) bottone annullamento/storno Liquidazione
  descErrOrTooltipLiquidation: string = ''; // Descrizione errore o messaggio tooltip
  // (RDC-236) - Fine

  // RDC-734 - Review bottone ANNULLO/STORNO
  liquidationButtonType: string;

  constructor(
    @Inject('sessionService') private session: any,
    @Inject('claimsFinderIntegrationPassService') private claimsFinderIntegrationPassService: any,
    @Inject('cardPassService') private cardPassService: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('authService') private authService: any,
    @Inject('eventService') private eventService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('claimsBaseObjectService') private claimsBaseObjectService: any,
    @Inject('enumService') private enumService: any,
    private sessionServiceComm: SessionService,
    public translateService: RgiRxTranslationService,
    private modalService: NgbModal,
    private newPaymentService: NewPaymentService,
    private paymentConstants: PaymentConstants,
    private alertService: AlertService,
    private modalServiceRgi: ModalService) {
      this.authSrv = authService;
      this.resCore = coreResult;
  }

  initEnums() {
    this.eTipoMovLiquidazQuietanza = this.paymentConstants.
    getSingleETipoMovLiquidaz(ENUMSLISTPAYMENT.ETIPOMOVLIQUIDAZQUIETANZA);
    this.eMezzoMagamentoBonifico = this.paymentConstants.
    getSingleEMezzoPagamento(ENUMSLISTPAYMENT.EMEZZOPAGAMENTO_BONIFICO);
    this.eMezzoPagamentoBonficoEsteroEur = this.paymentConstants.
    getSingleEMezzoPagamento(ENUMSLISTPAYMENT.EMEZZOPAGAMENTO_BONIFICO_ESTERO_EUR);
    this.eMezzoPagamentoBonificoEstero = this.paymentConstants.
    getSingleEMezzoPagamento(ENUMSLISTPAYMENT.EMEZZOPAGAMENTO_BONIFICO_ESTERO);
    this.eTipoDefinizContAll = this.paymentConstants.ETIPODEFINIZCONT_ALL();
    this.eTipoPagamentoAll = this.paymentConstants.ETIPOPAGAMENTO_ALL();
    this.eTipoQuietanzaAll = this.paymentConstants.ETIPOQUIETANZA_ALL();
    this.eTipoGestionePagamentoAll = this.paymentConstants.EGESTIONEPAGAMENTO_ALL();
    this.eShippingChannelAll = this.paymentConstants.ESHIPPINGCHANNEL_ALL();
    this.eAddressETypeAll = this.paymentConstants.EADDRESSETYPE_ALL();
    this.eTipoBeneFAll = this.paymentConstants.ETIPOBENEF_ALL();
    this.eMezzoPagamentoAll = this.paymentConstants.EMEZZOPAGAMENTO_ALL();
    this.ePriorityAll = this.paymentConstants.EPRIORITY_ALL();
  }

  ngOnInit() {
    // this.partyCurrent = this.resCore.getResult(this.card.id, 'party');
    this.initEnums();
    this.listFunz = this.authService.getOperator().enabledFeatures;

    this.getProperties();
    if (this.card) {
      this.claimCurrent = this.coreResult.getResult(this.card.id, 'claim');
      this.partyCurrent = this.resCore.getResult(this.card.id, 'party');
    }
    if (this.finPays == null) {
      this.finPays = this.newPaymentService.getFinalPayCache();
      if (this.finPays !== undefined && this.finPays !== null) {
        const temp = this.newPaymentService.getMessageCache();
        this.message = temp[0];
        this.classMsg = temp[1];
        this.consult = false;
        this.claimNumber = this.finPays[0].claimNumber;
        this.card = this.newPaymentService.getCardCache();
        this.translateService.translate('_CLAIMS_._PAYMENT_SAVING_SUMMARY')
        .subscribe(
          res =>  this.card.title = this.claimNumber + '-' + res
        )

        this.isFromSaveNewPayment = true;
        this.claimCurrent = this.newPaymentService.getClaimCache();
        if (this.finPays.length === 1) {
          this.idSettlement = this.finPays[0].idPayment;
        } else {
          this.showQuoteEvaluation = false;
        }
        this.initPotPayFE();
      }

      if (this.coreResult.getResult(this.card.id, 'fromDashboardPayment') !== undefined
        && this.coreResult.getResult(this.card.id, 'fromDashboardPayment') !== null &&
        this.coreResult.getResult(this.card.id, 'fromDashboardPayment') === true) {

        this.disableManage = true;
      }

    }
    if (this.finPays == null) {
      this.eventService.broadcastEvent('start-loader');
      const listFunz = this.authService.getOperator().enabledFeatures;
      let permissionNoCF = false;

      if (listFunz && listFunz.indexOf('SXCPM') >= 0) {
        permissionNoCF = true;
      }
      if (listFunz && listFunz.indexOf('SXLQUI') < 0) {
        this.disableSettlement = true;
      } else if (listFunz && listFunz.indexOf('SXQCFM') > 0) {
        this.enableUpgrade = true;
      }
      if (listFunz && listFunz.indexOf('SXBLGS') >= 0) {
        this.disableManage = true;
      }


      const consultationData = this.newPaymentService.getConsultationData();
      this.idSettlement = consultationData.get('idSettlement');
      const claim = consultationData.get('claimNum');
      const party = consultationData.get('partyId');
      this.claimNumber = claim;
      this.party = party;
      this.consult = true;
      this.translateService.translate('_CLAIMS_._PAYMENT_SAVING_SUMMARY')
      .subscribe(
        res =>  this.card.title = this.claimNumber + '-' + res
      )
      if (this.idSettlement && party) {
        this.finPays = new Array<PaymentEntity>();
        this.newPaymentService.getPaymentDetail(claim, party, this.idSettlement).subscribe(
          (paymentMethods: any) => {
            console.log('getPaymentDetail 2 -->', paymentMethods);
            this.finPays.push(paymentMethods);

            this.eventService.broadcastEvent('stop-loader');
            this.newPaymentService.clearLastConsultationData();
            this.initPotPayFE();

            if (this.finPays[0].paymentType === '10' ||
              this.finPays[0].paymentType === '11' ||
              this.finPays[0].paymentType === '12' ||
              this.finPays[0].paymentType === '13') {
              // pagamento recupero --> deve essere abilitata solo la consultazione
              this.disableManage = true;
            } else {
              if (this.finPays && (this.finPays[0].offerAcceptance && this.finPays[0].offerAcceptance !== true)) {
                this.disableManage = true;
              }
            }
            this.showReturnReceipt = paymentMethods.offerAcceptance ? paymentMethods.offerAcceptance : false;

            // Annullamento Quietanza / Liquidazione (RDC-236) - Inizio
            // Gestione abilitazione / disabilitazione del bottone "Annullo" relativo a Quietanza e Liquidazione
            if (paymentMethods.offerCancellation !== undefined && !paymentMethods.offerCancellation) {
               // Bottone annullamento Quietanza: true (abilitato); false (disabilitato)
              this.showCancellationReceipt = paymentMethods.offerCancellation;
            }

            // Bottone annullamento/storno Liquidazione: true (abilitato); false (disabilitato)
            if (paymentMethods.liquidationTransferDelete !== undefined && !paymentMethods.liquidationTransferDelete) {
              this.showCancellationSettlement = paymentMethods.liquidationTransferDelete;
              this.descErrOrTooltipLiquidationTransferDelete = paymentMethods.descError;
              this.showLiquidationTransferDeleteTooltip = true;
            } else {
              this.showLiquidationTransferDeleteTooltip = false;
              this.descErrOrTooltipLiquidationTransferDelete = '';
            }

            // gestione tooltip e disabilitazione bottone liquidazione
            if (paymentMethods.paymentError) {
              this.showLiquidationSettlementTooltip = true;
              this.disableLiquidationSettlement = true;
              this.descErrOrTooltipLiquidation = paymentMethods.paymentError.errorDescription;
            } else {
              this.showLiquidationSettlementTooltip = false;
              this.disableLiquidationSettlement = false;
              this.descErrOrTooltipLiquidation = '';
            }

            this.receiptDate = paymentMethods.receiptDate;
            // Annullamento Quietanza / Liquidazione (RDC-236) - Fine

            // RDC-734 - Review bottone ANNULLO/STORNO - A=ANNULLO (CANCELLATION) / S=STORNO (REVERSAL)
            this.liquidationButtonType = paymentMethods.liquidationButtonType === 'A' && this.showCancellationSettlement ? '_CLAIMS_._CANCELLATION' :
              (paymentMethods.liquidationButtonType === 'S' && this.showCancellationSettlement ? '_CLAIMS_._REVERSAL' : '_CLAIMS_._CANCELLATION_REVERSAL');

            if (paymentMethods.listBeneficiary
              .some(subject => subject.country === 'IT' &&
                (subject.fiscalCode == null || subject.fiscalCode === '') &&
                (subject.vat == null || subject.vat === '')) ||
              paymentMethods.listPayee
                .some(subject => subject.country === 'IT' &&
                  (subject.fiscalCode == null || subject.fiscalCode === '') &&
                  (subject.vat == null || subject.vat === ''))) {
              if (!permissionNoCF) {
                this.noVatOrFiscalCode = true;
              }
            }
            // Restituzione Quietanza RDC-381
            this.receiptReturnDate = paymentMethods.receiptReturnDate;
            this.receiptNote = paymentMethods.noteRientroQuietanza;
            this.receiptMotivoAnnullo = paymentMethods.motivoRifiutoQuietanza;
            if (paymentMethods.statusRientroQuietanza === 'A') {
              this.receiptStatus = 'Receipt Accepted';
              this.translateService.translate('_CLAIMS_._RECEIPT_ACCEPTED').subscribe(res=> {
                this.receiptStatus=res;
              }
              )
            }
            if (paymentMethods.statusRientroQuietanza === 'R') {
              this.translateService.translate('_CLAIMS_._RECEIPT_REJECTED').subscribe(res=> {
                this.receiptStatus=res;
              }
              )
            }
          },
          (error: any) => {
            this.newPaymentService.clearLastConsultationData();
            this.eventService.broadcastEvent('stop-loader');
          }
        );
        // this.consult = true;

        this.isFromSaveNewPayment = false;
        this.newPaymentService.getFeeData(this.idSettlement).subscribe(
          (feeData: FeeDataEntity) => {
            console.log(feeData);
            if (feeData.valid) {
              this.feeData = feeData;
            }
          },
          (error: any) => {
            console.log(error);
          }
        );

      } else {
        this.eventService.broadcastEvent('stop-loader');
        this.translateService.translate('_CLAIMS_._SEARCH_ERROR').subscribe(res=> {
          this.alertService.translatedMessage(res);
        }
        )
      }

      // this.consult = true;
    }
    console.log(this.finPays);


  }

  initPotPayFE() {
    const strSi$ = this.translateService.translate('_CLAIMS_._YES');
    const strNo$ = this.translateService.translate('_CLAIMS_._NO');
    const strForced$ = this.translateService.translate('_CLAIMS_._PAYMENTS._FORCED');
    this.potPayFE = new InitPotentialPaymentEntity();
    this.potPayFE.uuid = this.newPaymentService.getSessionUuid('sessionId');
    this.potPayFE.paymentList = [];
    this.potPayFE.actorId = this.partyCurrent.identifier;
    this.potPayFE.claimNumber = this.claimNumber;
    this.potPayFE.numReceipt = this.idSettlement;
    this.potPayFE.procedureCode = this.finPays[0].procedureCode;
    this.potPayFE.receiptPresent = this.finPays[0].movementType === this.eTipoMovLiquidazQuietanza.eTypeCode;
    this.potPayFE.coassTypeDescr = this.paymentConstants.ETIPOCOASS_ALL().
                                   find(el => el.eTypeCode === this.claimCurrent.coinsuranceType).eTypeDescription;
    this.potPayFE.companyCode = this.claimCurrent.aniaCompanyCode;
    // this.potPayFE.company = this.companies.find(value => value.aniaCode === this.potPayFE.companyCode).description;
    this.potPayFE.coassType = this.claimCurrent.coinsuranceType;
    this.potPayFE.legalProtectionEnabled = this.claimCurrent.isLegalProtectionEnabled;
    this.potPayFE.procedureCodeList = [];
    this.potPayFE.agency = this.claimCurrent.policyAgencyData.agencyDescription;
    this.potPayFE.policyHolder = this.claimCurrent.contractor;
    this.potPayFE.policyNumber = this.claimCurrent.policyNumber;
    this.potPayFE.productDescription = this.claimCurrent.product;
    const branches: string = this.claimCurrent.taxBranches;
    if (branches.split(';') && branches.split(',').length > 0) {
      this.potPayFE.ministerialBranch = branches.split(',')[0];
    } else {
      this.potPayFE.ministerialBranch = this.claimCurrent.taxBranches;
    }
    this.potPayFE.claimType = this.claimCurrent.tipoCard ? this.claimCurrent.tipoCard.descrizione : null;
    this.potPayFE.complaintDate = this.claimCurrent.reportDate;
    this.potPayFE.effectiveLossDate = this.claimCurrent.occurrenceDateCertified;
    this.potPayFE.lossDate = this.claimCurrent.occurrenceDate;

    combineLatest([
      strSi$,
      strNo$,
      strForced$,
    ]).subscribe(
      ([strSi, strNo, strForced]) => {
        this.potPayFE.administrativeReg = (this.claimCurrent.accountingCoverage ? strSi : strNo) +
          (this.claimCurrent.forcingAdministrative ? '(' + strForced + ')' : '');
        this.potPayFE.technicalReg = (this.claimCurrent.technicalCoverage ? strSi : strNo);
      });
    // const nomin = (this.partyCurrent.surname ? this.partyCurrent.surname : '') + ' ' +
    //              (this.partyCurrent.name ? this.partyCurrent.name : '');
    // this.potPayFE.actorNominative = (nomin.trim() !== '') ? nomin :
    //                                 this.partyCurrent.subject.designation;
    this.potPayFE.receiptSavedList = [];
    this.potPayFE.paymentSavedList = [];
    const receiptTotalAmount = 0;
    // this.potPayFE.numReceipt = this.potPayFE.receiptSavedList ? this.potPayFE.receiptSavedList.length : 0;
    this.potPayFE.numReceipt = receiptTotalAmount;
    if (this.potPayFE.receiptSavedList && this.potPayFE.receiptSavedList.length > 0) {
      this.potPayFE.receiptPresent = true;
    }
    if (this.potPayFE.paymentSavedList && this.potPayFE.paymentSavedList.length > 0) {
      this.potPayFE.paymentPresent = true;
    }

    this.potPayFE.compensationPresent = this.potPayFE.compensationSavedList &&
      this.potPayFE.compensationSavedList.length > 0 ? true : false;
    this.potPayFE.totalAssInd = 0;
    this.potPayFE.totalAssSpLiq = 0;
    this.potPayFE.totalAssSpRes = 0;

    this.potPayFE.assessmentSavedList = [];

    this.potPayFE.assessmentPresent = this.potPayFE.assessmentSavedList &&
      this.potPayFE.assessmentSavedList.length > 0 ? true : false;
    this.potPayFE.receiptDate = new Date();
    this.finPays.forEach(finPay => {
      this.potPayFE.paymentList.push(this.translatePaymentToPotentialPayment(finPay));
      if (finPay.procedureCode) {
        this.potPayFE.procedureCodeList.push(finPay.procedureCode);
      }
    });
    this.newPaymentService.getListConfigurationPayment(this.potPayFE.claimNumber, this.potPayFE.actorId)
    .subscribe((configPaymentEntity: any) => {
      console.log('getListConfigurationPayment - outcome:  ', configPaymentEntity);

      this.potPayFE.receiptTypeList = configPaymentEntity.receiptTypeList;
      this.potPayFE.priorityListCombo = configPaymentEntity.priorityList;
      this.potPayFE.procedureCodeList = configPaymentEntity.procedureList;
      this.potPayFE.managementPaymentList = configPaymentEntity.managementPaymentList;
      this.potPayFE.nodoFittizioSx = configPaymentEntity.nodoFittizioSx;
      // if (this.potPayFE.managementPaymentList && this.potPayFE.managementPaymentList.length > 0) {
      //   this.potPayFE.managementPayment = this.potPayFE.managementPaymentList[0].idObject;
      //   this.newPayForm.controls.managementPayment.setValue(this.potPayFE.managementPaymentList[0].idObject);
      // }
      this.potPayFE.paymentTypeList = configPaymentEntity.typePaymentList;
    });
    this.potPayFE.exposuresLoaded = false;
    this.newPaymentService.getListExposuresProfs(this.potPayFE.claimNumber, this.potPayFE.actorId,
      this.authSrv.getOperator().liquidationCentre.code, this.authSrv.getOperator().objectId)
      .subscribe((configPaymentEntity: any) => {
        console.log('getListExposuresProfs - outcome:  ', configPaymentEntity);
        this.potPayFE.exposureList = configPaymentEntity.exposureList;
        this.potPayFE.professionistList = configPaymentEntity.professionistList;
        this.potPayFE.antiFraudMessage = configPaymentEntity.antiFraudMessage;
        if (this.potPayFE.professionistList) {
          this.potPayFE.ctpLawyerFound = (this.potPayFE.professionistList.some(subj => subj.type.toUpperCase() === 'LAV'));
        }
        this.potPayFE.exposuresLoaded = true;
        this.eventService.broadcastEvent('stop-loader');
      },
        (error: any) => {
          this.eventService.broadcastEvent('stop-loader');
        });

        // gestione legali rappresentati per modali di trasformazione quietanza
    this.newPaymentService.getListExposuresProfs(
      this.potPayFE.claimNumber, this.potPayFE.actorId,
      this.authSrv.getOperator().liquidationCentre.code,
      this.authSrv.getOperator().objectId).subscribe((configPaymentEntity: any) => {
        console.log('getListExposuresProfs - outcome:  ', configPaymentEntity);
        this.potPayFE.exposureList = configPaymentEntity.exposureList;
        this.potPayFE.professionistList = configPaymentEntity.professionistList;
        this.potPayFE.antiFraudMessage = configPaymentEntity.antiFraudMessage;
        if (this.potPayFE.professionistList) {
          this.potPayFE.ctpLawyerFound = (this.potPayFE.professionistList.some(subj => subj.type.toUpperCase() === 'LAV'));
        }
        this.potPayFE.paymentList.forEach(payment => {
          if (payment.methodPayment.receiver && payment.methodPayment.receiver.type) {
            if (payment.methodPayment.receiver.type.toUpperCase() === 'RLAV') {
              payment.methodPayment.receiver = this.potPayFE.professionistList.
                filter(subj => subj.idSubject === payment.methodPayment.receiver.legalCode)[0];
            }
          }
        });
      });
  }

  translatePaymentToPotentialPayment(payment: PaymentEntity): PotentialPaymentEntity {
    const potPay = new PotentialPaymentEntity();
    potPay.paymentType = payment.paymentType;
    potPay.paymentManagement = payment.paymentManagement;
    potPay.receiptType = payment.receiptType;
    potPay.receiptDate = payment.receiptDate;
    for (const exposure of payment.exposureList) {
      exposure.selected = true;
      if (!this.isFromSaveNewPayment) {
        exposure.amount += exposure.amountInsured + exposure.amountNet;
      }
    }
    potPay.exposureList = JSON.parse(JSON.stringify(payment.exposureList));
    potPay.totalAmount = payment.totalAmount;
    potPay.totalAmountNet = payment.totalAmountNet;
    potPay.listBeneficiary = payment.listBeneficiary;
    potPay.listPayee = payment.listPayee;
    potPay.procedureCode = payment.procedureCode;
    potPay.generatePayment = payment.generatePayment;
    potPay.separateReceipt = payment.separateReceipt;
    potPay.methodPayment = payment.methodPayment;
    potPay.driveType = payment.driveType;
    potPay.documentList = payment.documentList;
    potPay.id = +payment.number;
    return potPay;
  }

  getProperties() {
    const promises: any[] = [];
    promises.push(this.getSystemProperty.findProperty(NewPaymentDetailComponent.PROPERTY_SHOW_SDI_PROTOCOL_NUMBER));
    promises.push(this.getSystemProperty.findProperty(NewPaymentDetailComponent.PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV));
    promises.push(this.getSystemProperty.findProperty(NewPaymentDetailComponent.PROPERTY_CLAIMS_ENABLE_FORM_PHOTO));
    promises.push(this.getSystemProperty.findProperty(NewPaymentDetailComponent.PROPERTY_ENABLED_CLAIMS_PAYMENT_URGENCY));

    this.eventService.broadcastEvent('start-loader');

    Promise.all(promises).then((results) => {
      results.forEach((result) => {
        if (result && result.systemProperties && result.systemProperties.length) {

          for (const variable of result.systemProperties) {
            if (variable.key === NewPaymentDetailComponent.PROPERTY_CLAIMS_ENABLE_FORM_PHOTO) {
              if (variable.value && NewPaymentDetailComponent.PROPERTY_ENABLED === variable.value) {
                this.enableFormPhoto = true;
              }
              break;
            }
            if (variable.key === NewPaymentDetailComponent.PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV) {
              if (variable.value && NewPaymentDetailComponent.PROPERTY_ENABLED === variable.value) {
                this.claimsManagementSapSRV = true;
              }
              break;
            }
            if (variable.key === NewPaymentDetailComponent.PROPERTY_SHOW_SDI_PROTOCOL_NUMBER) {
              if (variable.value && NewPaymentDetailComponent.PROPERTY_ENABLED === variable.value) {
                this.showSDIProtocolNumber = true;
              }
              break;
            }
            if (variable.key === NewPaymentDetailComponent.PROPERTY_ENABLED_CLAIMS_PAYMENT_URGENCY) {
              if (variable.value && NewPaymentDetailComponent.PROPERTY_ENABLED === variable.value) {
                this.isAbilitatoClaimsPaymentUrgency = true;
              }
              break;
            }
          }
        }
      });
      // this.eventService.broadcastEvent('stop-loader');
    });
  }


  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() => console.log('fired'));
  }

  defPrimary(field: boolean) {
    return field ? '_CLAIMS_._PRINCIPAL' : '';
  }

  defBoolean(field: boolean) {
    return field ? 'YES' : 'NO';
  }

  defDescription(code: string) {
    const description = this.eTipoDefinizContAll.find(el => el.eTypeCode === code);
    return description ? description.eTypeDescription : '';
  }

  defPaymentType(code: string) {
    const type = this.eTipoPagamentoAll.find(el => el.eTypeCode === code);
    return type ? type.eTypeDescription : '';
  }

  defReciptType(code: string) {
    return code ?
           this.eTipoQuietanzaAll.find(el => el.eTypeCode === code).eTypeDescription :
           '';
  }

  defManagementPay(code: string) {
    return code ?
           this.eTipoGestionePagamentoAll.find(el => el.eTypeCode === code).eTypeDescription :
           '';
  }

  defShippingChannel(code: string) {
    if (code) {
      try {
        return this.eShippingChannelAll.find(el => el.eTypeCode === code).eTypeDescription;
      } catch (error) {
        console.log('metodo eShippingChannelAll non ristituisce nessun dato.');
        return '';
      }
    } else {
      return '';
    }
    // return code ?
    //        this.eShippingChannelAll.find(el => el.eTypeCode === code).eTypeDescription :
    //        '';
  }

  defAddresseType(code: string) {
    return code ?
           this.eAddressETypeAll.find(el => el.eTypeCode === code).eTypeDescription :
           '';
  }

  defBenefType(code: string) {
    try {
      return this.eTipoBeneFAll.find(el => el.eTypeCode === code).eTypeDescription;
    } catch (error) {
      return '';
    }
  }

  defPaymentMethod(code: string) {
    return code ? this.eMezzoPagamentoAll.find(el => el.eTypeCode === code).eTypeDescription : '';
  }

  defPriority(code: string) {
    return code ?
           this.ePriorityAll.find(el => el.eTypeCode === code).eTypeDescription : null;
  }

  isBankTransfer(code: string) {
    return this.eMezzoMagamentoBonifico.eTypeCode === code;
  }

  isForeignBankTransfer(code: string) {
    return this.eMezzoPagamentoBonificoEstero.eTypeCode === code ||
    this.eMezzoPagamentoBonficoEsteroEur.eTypeCode === code;
  }

  movementReceipt(finPay: PaymentEntity) {
    return finPay ? finPay.movementType === this.eTipoMovLiquidazQuietanza.eTypeCode : '';
  }


  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }

  cancellation() {
    // Se il bottone di annullamento è visibile esegue le operazioni altrimenti no
    if (this.showCancellationReceipt) {
      this.jump = true;
      const act = {
        extension: null,
        jumpCommand: 'offerRefuredClaim',
        menu: null,
        operation: { identification: '37', code: '000037', description: 'Annullo Quietanza' }
      };

      const filter = this.coreResult.getResult(this.card.id, 'filter');
      filter.settlementId = this.coreResult.getResult(this.card.id, 'idsettlement');
      filter.partyId = null;
      filter.jumpFunction = this.claimsFinderIntegrationPassService.getFunctionByJump(act, filter);
      const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
      // this._createJump(this.card, objIntegrationPass, 'Annullo Quietanza', 'action', this.card.name);
      this.openModalCancellation(objIntegrationPass, receiptCancellation); // RDC-235 Apertura modale
    } else {
      this.translateService.translate('_CLAIMS_._OPERATION_NOT_AVAILABLE').subscribe(res=> {
        this.alertService.translatedMessage(res);
      }
      )

    }
  }

  // Metodo per l'apertura della modale per l'Annullo Quietanza / Liquidazione' RDC-236
  openModalCancellation(detailObjectIn: any, operation: string) {
    this.modalServiceRgi.open(CancellationModalComponent, {
      detailObject: detailObjectIn,
      claimCurrent: this.claimCurrent,
      currentReceiptDate: this.receiptDate,
      operationType: operation,
      feeData: this.feeData,
    }).onClose.subscribe(response => {
      if (response && response !== 'Close') {
        const bodyRequest: CancellationBodyRequest = {
          receiptId: response.receiptId,
          receiptNote: response.note,
          receiptIdMotivoAnnullo: response.reasonCancelledSelected,
          receiptDate: response.dateReceipt
        };
        if (operation === receiptCancellation) {
          this.saveDeleteReceipt(response.claimNumber, bodyRequest);
        } else {
          this.saveDeleteSettlement(response.claimNumber, bodyRequest);
        }
      }
    });
  }

  openModalSettlement(detailObjectIn: any, operation: string, filter: any) {
    const allPaymentType: TypeEnum[] = this.paymentConstants.ETIPOPAGAMENTO_ALL();
    this.potPayFE.paymentList.forEach(payList => {
      const selectedReceipt: TypeEnum = this.paymentConstants.ETIPOQUIETANZA_ALL().find(res => res.eTypeCode === payList.receiptType);
      const selectedPayment: TypeEnum = this.paymentConstants.ETIPOPAGAMENTO_ALL().find(res => res.eTypeCode === payList.paymentType);
      payList.receiptTypeDescr = selectedReceipt.eTypeDescription;
      payList.paymentTypeDescr = selectedPayment.eTypeDescription;
    });
    this.potPayFE.paymentType = this.finPays[0].paymentType;
    this.modalServiceRgi.open(NewSettlementDetailModalComponent, {
      size: 'lg',
      detailObject: detailObjectIn,
      claimCurrent: this.claimCurrent,
      currentReceiptDate: this.receiptDate,
      filterSettlment: filter,
      potPayFe: this.potPayFE,
      operationType: operation,
      feeData: this.feeData,
      card: this.card,
      finPays: this.finPays
    }).onClose.subscribe(response => {
      // RDC-891 section 3 - START
      if (response && response.stateModal === 'confirm') {
        if (response.code === '1') {
          let boolStatus = false;
          this.translateService.translate('_CLAIMS_._ACTIVATED')
          .subscribe(
            res =>  {const translateActivated=res}
          )
          if (this.finPays[0].codStatus === "1") {
            boolStatus = true;
          }
          if (boolStatus) {
            let translatedMessage;
            const obsSettlment$ = this.translateService.translate('_CLAIMS_._SETTLEMENT_NUMBER');
            const issue$ = this.translateService.translate('_CLAIMS_._ISSUED_CORRECTLY');
            combineLatest([
              obsSettlment$,
              issue$,
            ]).subscribe(
                ([settlment, issue]) => {

                  translatedMessage = [settlment, issue]

                })
            this.alertService.message(`${translatedMessage[0]} ${response.settlementNumber} ${translatedMessage[1]}`);
          } else if (!boolStatus) {
            const translatedMessage =
      this.translateService.translate('_CLAIMS_._LIQUIDATION_AUTHORIZATION_PROCESS_STARTED').subscribe(res=> {
        this.translatedMessage= res;
      }
      )
            this.alertService.message(`${translatedMessage}`);
          }
        } else {
          response.messages.forEach(message => {
            this.alertService.message(`${message}`);
          });
        }
        this.exitToClaim();
      }
      // RDC-891 section 3 - END
    });
  }


  saveDeleteReceipt(claim: string, body: CancellationBodyRequest) {
    this.newPaymentService.saveDeleteReceipt(claim, body).subscribe((receiptCancellationResponse: ResponseSaveCancelled) => {
      this.alertService.message(receiptCancellationResponse.descr);
      this.exitToClaim();
    });
  }

  saveDeleteSettlement(claim: string, body: CancellationBodyRequest) {
    this.newPaymentService.saveDeleteSettlement(claim, body).subscribe((settlementCancellationResponse: ResponseSaveCancelled) => {
      this.alertService.message(settlementCancellationResponse.descr);
      this.exitToClaim();
    });
  }

  return() {
    this.jump = true;
    const act = {
      extension: null,
      jumpCommand: 'offerAcceptedClaim',
      menu: null,
      operation: { identification: '36', code: '000036', description: 'Restituzione Quietanza' }
    };

    const filter = this.coreResult.getResult(this.card.id, 'filter');
    filter.settlementId = this.coreResult.getResult(this.card.id, 'idsettlement');
    filter.partyId = null;

    filter.jumpFunction = this.claimsFinderIntegrationPassService.getFunctionByJump(act, filter);
    const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
    this.openModalReceiptReturn(objIntegrationPass);
  }

  // Metodo per l'apertura della modale per la restituzione della quietanza RDC-237
  openModalReceiptReturn(detailData: any) {
    this.modalServiceRgi.open(ReceiptReturnModalComponent, {
      detailObject: detailData,
      claimCurrent: this.claimCurrent
    }).onClose.subscribe( response => {
      if (response && response !== 'Close') {
        const bodyRequest: BodyRequest = {
          receiptId: response.receiptId,
          receiptNote: response.note,
          receiptStatus: response.acceptedCheckbox ? 'A' : 'R',
          receiptDate: response.dateReceipt,
          receiptIdMotivoAnnullo: response.codeReasonRejected
        };
        this.saveReceipt(response.claimNumber, bodyRequest);
      }
    });
  }

  saveReceipt(claim: string, body: BodyRequest) {
    this.newPaymentService.saveReceipt(claim, body).subscribe((receiptResponse: any) => {
      this.exitToClaim();
    });

  }

  settlement() {
    this.jump = true;
    const act = {
      extension: null,
      jumpCommand: 'newSettlementClaim',
      menu: null,
      operation: { identification: '35', code: '000035', description: 'Liquidazione Quietanza' }
    };

    const filter = this.coreResult.getResult(this.card.id, 'filter');
    filter.settlementId = this.coreResult.getResult(this.card.id, 'idsettlement');
    filter.partyId = this.party;

    filter.jumpFunction = this.claimsFinderIntegrationPassService.getFunctionByJump(act, filter);
    const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
    // this._createJump(this.card, objIntegrationPass, 'Liquidazione Quietanza', 'action', this.card.name);
    // NewSettlementDetailModalComponent
    this.openModalSettlement(objIntegrationPass, settlementsCancellation, filter); // RDC-235 Nuova modale liquidazione
  }

  storno() {
    if (this.showCancellationSettlement && this.liquidationButtonType !== '') {
      this.jump = true;
      const act = {
        extension: null,
        jumpCommand: 'settlementAcceptedRefuredClaim',
        menu: null,
        operation: { identification: '32', code: '000032', description: 'Annullo/Storno liquidazione' }
      };

      const filter = this.coreResult.getResult(this.card.id, 'filter');
      filter.settlementId = this.coreResult.getResult(this.card.id, 'idsettlement');
      filter.partyId = null;

      filter.jumpFunction = this.claimsFinderIntegrationPassService.getFunctionByJump(act, filter);
      const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
      // this.cardPassService.createJump(this.card, objIntegrationPass, 'Annullo/Storno liquidazione', 'action', this.card.name);
      // this._createJump(this.card, objIntegrationPass, 'Annullo/Storno liquidazione', 'action', this.card.name);
      if (this.liquidationButtonType === 'A') {
        this.openModalCancellation(objIntegrationPass, settlementsCancellation); // RDC-236 Apertura modale di annullamento liquidazione
      } else {
        this.openModalSettlementReversal(objIntegrationPass); // RDC-268 Apertura modale di storno liquidazione
      }
    } else {
      this.translateService.translate('_CLAIMS_._OPERATION_NOT_AVAILABLE')
      .subscribe(
        res => {
          this.alertService.translatedMessage(res);

        }
      )
    }
  }

  // Metodo per l'apertura della modale per lo Storno della Liquidazione RDC-268
  openModalSettlementReversal(detailObjectIn: any) {
    this.modalServiceRgi.open(SettlementReversalComponent, {
      detailObject: detailObjectIn,
      claimCurrent: this.claimCurrent,
      currentReceiptDate: this.receiptDate
    }).onClose.subscribe(response => {
      if (response && response !== 'Close') {
        const bodyRequest: ReversalBodyRequest = {
          receiptId: response.receiptId,
          receiptNote: response.note,
          receiptIdMotivoAnnullo: response.reasonCancelledSelected,
          receiptDate: response.dateReceipt,
          exposureList: response.exposureList
        };
        this.saveSettlementReversal(response.claimNumber, bodyRequest);
      }
    });
  }

  saveSettlementReversal(claim: string, body: ReversalBodyRequest) {
    this.newPaymentService.saveSettlementsReversal(claim, body).subscribe((receiptReversalResponse: ResponseSaveReversal) => {
      this.alertService.message(receiptReversalResponse.descr);
      this.exitToClaim();
    });
  }

  _createJump(card, objIntegrationPass, title, route, parent) {

    objIntegrationPass.objNavigation.idCardParent = card.id; // add idParent
    card.integrationPass = objIntegrationPass;

    if (objIntegrationPass.objNavigation.newSession) {
      const idNavigation = this.session.open(parent, route, title, true, card.integrationPass, null, card.idSession);

      const idSession = this.sessionServiceComm.idSessionFromId(idNavigation);

      this.sessionServiceComm.registerSessionDeathCallback(idSession, () => {
        this.refresh();
      });

      if (idNavigation) {
        // this.eventService.broadcastEvent(CONTAINER_EVENT.START_LOADER_EVENT, null);
      }
    }
  }

  refresh() {
    this.eventService.broadcastEvent('start-loader');
    this.newPaymentService.getPaymentDetail(this.claimNumber, this.party, this.idSettlement).subscribe(
      (paymentMethods: any) => {
        console.log('getPaymentDetail -->', paymentMethods);
        this.finPays = new Array<PaymentEntity>();
        this.finPays.push(paymentMethods);
        this.eventService.broadcastEvent('stop-loader');
        this.newPaymentService.clearLastConsultationData();
        this.initPotPayFE();
      },
      (error: any) => {
        this.newPaymentService.clearLastConsultationData();
        this.eventService.broadcastEvent('stop-loader');
      }
    );
  }

  gestPrestatoriOpera() {
    this.jump = true;
    const act = {
      extension: null,
      jumpCommand: 'serviceProvidersClaim',
      menu: null,
      operation: { identification: '33', code: '000033', description: 'Gest. prestatori d\'opera' }
    };

    const filter = this.coreResult.getResult(this.card.id, 'filter');
    filter.settlementId = this.coreResult.getResult(this.card.id, 'idsettlement');
    filter.partyId = null;

    filter.jumpFunction = this.claimsFinderIntegrationPassService.getFunctionByJump(act, filter);
    const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
    this._createJump(this.card, objIntegrationPass, 'Gest. prestatori d\'opera', 'action', this.card.name);
  }

  exit() {
    const session = this.getCurrentSession();
    this.session.remove(session.idSession);
  }

  exitToClaim(){
    const claimsFinderSession = this.session.list().find(session => !session.isActive && session.name == 'claimsFinder');
    if(claimsFinderSession) {
      this.getContract().subscribe( res => {
        if(res){
          this.generateNewSessionFinder(res.contract);
        }
      });
    } else {
      this.exit();
    }
  }

  createBranchMap(finPay: PaymentEntity): Array<ExposureEntity> {
    const mappaBranchExp = new Map<string, Array<any>>();
    const listExpComboVisual = new Array<ExposureEntity>();
    if (finPay.exposureList != null) {
      finPay.exposureList.filter(aExp => aExp.visible).forEach(element => {
        if (mappaBranchExp.get(element.branchCode) == null) {
          mappaBranchExp.set(element.branchCode, new Array<ExposureEntity>());
        }
        mappaBranchExp.get(element.branchCode).push(element);
      });
    }

    for (const key of mappaBranchExp.keys()) {
      const arrExp = mappaBranchExp.get(key);
      for (let index = 0; index < arrExp.length; index++) {
        const exp = arrExp[index];
        if (index === 0) {
          exp.branchTitle = exp.branchDescription;
        } else {
          exp.branchTitle = null;
        }
        listExpComboVisual.push(exp);
      }

    }
    return listExpComboVisual;
  }

  dataFee(payNumber: string, idCampoExp: string) {

    let expSel: ExposureEntity;


    expSel = this.finPays.find(apay => apay.number === payNumber)
      .exposureList.find(exp => exp.idCampo === idCampoExp);

    const modalRef = this.modalService.open(FeeDataModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.newPayFormModal = null;
    modalRef.componentInstance.exp = expSel;
    modalRef.componentInstance.idCampoExp = idCampoExp;
    modalRef.componentInstance.idCampoSubExp = null;
    modalRef.componentInstance.potPayFE = null;
  }


  docPresent(aFinPay: PaymentEntity) {
    return aFinPay.documentList && aFinPay.documentList.length > 0;
  }

  getPreviewDocument(idDocument: number) {
    this.eventService.broadcastEvent('start-loader');
    this.newPaymentService.previewDocument(idDocument)
      .subscribe((response: any) => {
        this.eventService.broadcastEvent('stop-loader');
        this.addMimeTypeToDocument(response.draftDocument.file);
        this.document = new ClaimDocument();
        this.document.file = response.draftDocument.file.binary;
        this.openPreviewModal();
      },
        (error: any) => {
          console.log(error);
          this.eventService.broadcastEvent('stop-loader');
        });
  }

  private openPreviewModal() {
    const modalRef = this.modalService.open(PreviewDocModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.document = this.document;
  }

  private addMimeTypeToDocument(file: any) {
    const fileType = file.mimeType;
    if (fileType.toUpperCase() === 'application/pdf'.toUpperCase() || fileType.toUpperCase() === 'docx'.toUpperCase()) {
      file.binary = 'data:application/pdf;base64,' + file.binary;
    } else if (fileType.toUpperCase() === 'txt'.toUpperCase()) {
      file.binary = 'data:text/plain;base64,' + file.binary;
    } else if (fileType.toUpperCase() === 'jpeg'.toUpperCase()) {
      file.binary = 'data:image/jpeg;base64,' + file.binary;
    } else if (fileType.toUpperCase() === 'png'.toUpperCase()) {
      file.binary = 'data:image/png;base64,' + file.binary;
    }
  }

  openDetail(exp: ExposureEntity) {
    const listLegalFee = new Array<any>();

    exp.subExposureList.filter(subExp => subExp.subExposureType === 'LAV' && subExp.feeDataOmniaOK)
      .forEach(element => {
        const aDet = {
          amount: element.legalFeeAmount,
          feeData: element.feeDataOmnia
        };
        listLegalFee.push(aDet);
      });

    if (listLegalFee.length > 0) {
      const modalRef = this.modalService.open(PaymentModalComponent, {
        windowClass: 'basic',
        size: 'lg'
      });
      modalRef.componentInstance.omniaDetail = true;
      modalRef.componentInstance.listOmniaFee = listLegalFee;
    }
  }

  valCFVAT(sogg: any) {
    if (sogg.fiscalCode) {
      return sogg.fiscalCode;
    } else if (sogg.vat) {
      return sogg.vat;
    }
    return '';
  }

  upgrade(sogg: SubjectEntity, finPay: PaymentEntity) {
    const obstr1$ = this.translateService.translate('_CLAIMS_._THE_SUBJECT_WILL_BE_UPGRADE_TO');
    const obstr2$ = this.translateService.translate('_CLAIMS_._WOULD_YOU_LIKE_TO_CONTINUE');
    combineLatest([
      obstr1$,
      obstr2$,
    ]).subscribe(
        ([tr1, tr2]) => {

        if(confirm(tr1 + ': ' +
        sogg.lastNameUpgrade + ' ' +
        sogg.nameUpgrade + ' ' +
        sogg.cfupgrade + ' ' +
        sogg.vatUpgrade
        + tr2 + '?')) {

          this.eventService.broadcastEvent('start-loader');
          this.newPaymentService.upgradeSubject(sogg, finPay.idPayment, this.claimCurrent.claimNumber).subscribe(
            (response: any) => {
              finPay.listBeneficiary.filter(el => el.idSubject === sogg.idSubject).forEach(sg => {
                sg.toUpgrade = false;
                sg.name = sg.nameUpgrade;
                sg.lastName = sg.lastNameUpgrade;
                sg.fiscalCode = sg.cfupgrade;
                sg.vat = sg.vatUpgrade;
              });
              finPay.listPayee.filter(el => el.idSubject === sogg.idSubject).forEach(sg => {
                sg.toUpgrade = false;
                sg.name = sg.nameUpgrade;
                sg.lastName = sg.lastNameUpgrade;
                sg.fiscalCode = sg.cfupgrade;
                sg.vat = sg.vatUpgrade;
              });

              if (!finPay.listBeneficiary
                .some(subject => subject.country === 'IT' &&
                  (subject.fiscalCode == null || subject.fiscalCode === '') &&
                  (subject.vat == null || subject.vat === '')) &&
                !finPay.listPayee
                  .some(subject => subject.country === 'IT' &&
                    (subject.fiscalCode == null || subject.fiscalCode === '') &&
                    (subject.vat == null || subject.vat === ''))) {
                this.disableSettlement = false;
              }
              this.eventService.broadcastEvent('stop-loader');
            },
            (error: any) => {
              console.log('Aggiornamento in errore');
              this.eventService.broadcastEvent('stop-loader');
            }
          );





        }
        })
  }

  blockMsgQuote(msg: string) {
    if (msg) {
        this.disableSettlement = true;
    }
}

  ngOnDestroy(): void {
    if (this.claimCurrent && this.claimCurrent.claimNumber) {
      this.claimsBaseObjectService.reloadClaim(this.claimCurrent.claimNumber, this.claimCurrent.aniaCompanyCode, this.card.idSessionParent);
    }
    if (this.card && this.card.integrationPass) {
      if (typeof this.card.integrationPass.returnCallback === 'function') {
        this.card.integrationPass.returnCallback();
      }

      if (this.card.integrationPass.sessionId) {
        this.session.setActive(this.card.integrationPass.sessionId);
      }
    }
  }

  getContract() {
    const body = {
      filter: {
        policyNumber: this.claimCurrent.policyNumber,
        versionDate: this.claimCurrent.reportDate,
      },
    };

   return this.newPaymentService.getContract(body);
  }

  generateNewSessionFinder(contract) {
    const claimNumber = this.claimCurrent.claimNumber !== null ? this.claimCurrent.claimNumber
      : this.claimCurrent.reportNumber;
    let queryParams = '';

    if (this.claimCurrent.claimNumber === null) {
      queryParams = 'pes/' + claimNumber
        + '?internalCompanyCode=' + this.claimCurrent.internalCompanyCode
        + '&potencialClaimTypeRequest=6';
    } else {
      queryParams = 'claim/' + claimNumber + '?companyCode='
        + contract.company.description
        + '&internalCompanyCode=' + this.claimCurrent.internalCompanyCode;
    }

    this.newPaymentService.getDetailClaim(queryParams).subscribe(res => {
      if (res) {
        const activeSession = this.session.list().find(session => session.isActive).idSession;
        this.session.remove(activeSession);

        const detailResult = res.claim ? res.claim : res.claimInquiry;
        const typeOpen = !res.claim ? 'detail-pes' : 'detail';
        const idResult = this.session.open('claimsFinder', typeOpen, '', true);
        detailResult.idResult = idResult; //appoggio l'id del coreresult per aggiornare l'oggetto in cache
        this.coreResult.setResult(idResult, typeOpen, detailResult);
      }
    }), (error) => {
      this.translateService.translate('_CLAIMS_._MESSAGE_._GET_DETAIL_CLAIM_ERROR').subscribe(res => {
        this.alertService.translatedMessage(res);
      });
    };
  }

}
