import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogueFunction, FormSearch } from '../../gedi/dialogue-handler/dialogue-handler-configuration/dialogue-handler-search.model';
import { Subscription } from 'rxjs';
import { ModalService, RgiRxDataTableColumnSortDef, RgiRxDatatableComponent, RgiRxSwitchChange, TableSchema } from '@rgi/rx/ui';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FivaStateManager } from '../state-manager/fiva-state-manager';
import { CtpDetail, tableSchema } from '../fiva-configuration/fiva-model';
import { FivaPlateCtpDetailModalComponent } from '../fiva-plate-ctp-detail-modal/fiva-plate-ctp-detail.modal.component';

const CUSTOM_TEMPLATE_SORT_DEF: RgiRxDataTableColumnSortDef<FormSearch>[] = []

@Component({
  selector: "claims-fiva-plate-ctp-detail",
  templateUrl: "./fiva-plate-ctp-detail.component.html",
  styleUrls: ["./fiva-plate-ctp-detail.component.scss"],
})
export class FivaPlateCtpDetailComponent {
  @Input() userHasPermission: boolean = false;
  @Input() buttonCondition: boolean = true;
  @Input() searchForm: FormSearch;
  @ViewChild('formModal', {static:false}) modal: any;
  @ViewChild(RgiRxDatatableComponent, {static: false}) dt: RgiRxDatatableComponent;
  selectedFunction: DialogueFunction | null = null;
  subscriptions: Subscription[] = [];
  schema = tableSchema;

  checkFivaCtp: UntypedFormGroup;

  searchDialogueHandlerForm: UntypedFormGroup;

  disable = false;
  fivaStatusToCancel:string;
  fivaStatusCode:number;

  configModel = {
    allowSelections : false,
    stickyHeader: false,
    stickyColumns: [],
    stickySort: false,
    stickySelection: false,
    stickyExpansion: false,
    stickyPagination: false
  };
  data = [
    { status: 1 },
    { status: 2 },
  ];


  selections: SelectionChange<any> = {
    source: new SelectionModel<any>(),
    removed: [],
    added: []
  };
  constructor(
    @Inject('authService') private authService,
    @Inject('enumService') private enumService: any,
    private formBuilder: UntypedFormBuilder,
    public fivaStateManager: FivaStateManager,
    private modalServiceRgi: ModalService,

  ) {}


  ngOnInit() {
    this.fivaStatusToCancel = 'To Be Sent';
    //this.fivaStatusToCancel = this.enumService.getEnum('sinistri.FivaStatus','1' ).descrizione;
    this.fivaStatusCode = this.enumService.getEnum('sinistri.FivaStatus','1' ).codice;
    const listFunz = this.authService.getOperator().enabledFeatures;
    if (listFunz && listFunz.indexOf('PSNDNW') >= 0) {
      this.userHasPermission = true;
    }

    this.fivaStateManager.getState$().subscribe(st => {
    if(this.dt!=null){this.dt.dataSource.update(st.ctpList);
    }
    });

    this.checkFivaCtp = this.formBuilder.group({
      function: [null, Validators.required],
      message: [null, Validators.required],
      cancelRequestDate: [],
      insertionDate: [],
      plateNumber: [],
      occurrenceDate: [],
      company: [],
      mergeAbsorpitionDate: [],
      newCompany: [],
      sendingDate: [],
      cancellationDate: [],
      notificationDate: [],
      errorCode: [],
      messageResponse: []
    });
  }


  /*********** Ordinamento ***********/
  protected sortDef: RgiRxDataTableColumnSortDef<FormSearch>[] | boolean = CUSTOM_TEMPLATE_SORT_DEF

  protected onToggleSortHeaders($event: RgiRxSwitchChange) {
    this.sortDef = $event.selected ? CUSTOM_TEMPLATE_SORT_DEF : false
  }

/*********************/
  
  insertNewRequest(){
    this.fivaStateManager.openModal();
  }


  modalOpen(typeOpen: string, dettaglio?){
    const currentState = this.fivaStateManager.getCurrentState();
    if (typeOpen === 'insert') {
      this.modalServiceRgi.open(FivaPlateCtpDetailModalComponent, {
        detailCtp: null, claim: currentState.claim
      }).onClose.subscribe(response => {
        if(response != null && response.value != null) {
          this.fivaStateManager.saveCtpDetail$(response.value);
        }
      });
    } else if (typeOpen === 'edit') {
      this.modalServiceRgi.open(FivaPlateCtpDetailModalComponent, {
        detailCtp: dettaglio, claim: currentState.claim
      }).onClose.subscribe(response => {
        if(response.value != null) {
          this.fivaStateManager.saveCtpDetail$(response.value);
        }
      });
    } else if (typeOpen === 'delete') {
      this.deleteCtpDetail(dettaglio);
    }
  }

  deleteCtpDetail(ctpDetail: CtpDetail) {
    this.fivaStateManager.deleteCtpDetail$(ctpDetail);
  }
}
