<form [formGroup]="parameterControlForm">

  <div [ngSwitch]="parameter?.type">

    <div *ngSwitchCase="'0'">
      <rgi-rx-form-field>
        <label rgiRxLabel class="rgi-ui-text-5" translate>{{ parameter?.description }}</label>
        <select rgiRxNativeSelect (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id"
                [compareWith]="compareGenericEntities" [attr.disabled]="parameter?.disabled ? 'disabled' : null"
                [formControlName]="parameter?.id" [id]="parameter?.id" class="select-nativa">
          <option></option>
          <option *ngFor="let value of parameter?.parameterValues;" [value]="value?.id">
            {{ value?.description }}</option>
        </select>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'1'">
      <rgi-rx-form-field>
        <label rgiRxLabel class="rgi-ui-text-5" translate>{{ parameter?.description }}</label>
        <input rgiRxInput (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id"
               [disabled]="parameter.disabled" [formControlName]="parameter?.id" [id]="parameter?.id"
               class="form-control mic-input" type="text" rgiCountryLayerInputNumberFormat [maximumFractionDigits]="2">
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'2'">
      <rgi-rx-form-field>
        <label rgiRxLabel class="rgi-ui-text-5" translate>{{ parameter?.description }}</label>
        <input rgiRxInput (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id"
               [disabled]="parameter.disabled" [formControlName]="parameter?.id" [id]="parameter?.id"
               class="form-control mic-input" type="text" rgiCountryLayerInputNumberFormat [maximumFractionDigits]="2">
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'3'" class="horizontal-center mt-4">
      <rgi-rx-form-field>
        <label rgiRxLabel class="rgi-ui-text-5" translate>{{ parameter?.description || ' '}}</label>
        <input rgiRxInput (click)="onParameterValueChange(parameter)" [attr.data-qa]="'parameter_'+parameter?.id"
               [disabled]="parameter?.disabled" [formControlName]="parameter?.id"
               [ngClass]="{'pointerEnabled':!parameter.disabled, 'pointerDisabled':parameter.disabled}" type="checkbox"
               class="ml-1">
      </rgi-rx-form-field>
    </div>
    <div *ngSwitchCase="'4'">
      <rgi-rx-form-field>
        <label rgiRxLabel class="rgi-ui-text-5" translate>{{ parameter?.description }}</label>
        <rgi-rx-date-picker [openOnClick]="false" [attr.data-qa]="'parameter_'+parameter?.id">
          <input [rgiRxDateTimeInput]="date" [formControlName]="parameter?.id" [id]="parameter?.id">
          <rgi-rx-date-time #date [disabled]="parameter?.disabled"></rgi-rx-date-time>
        </rgi-rx-date-picker>
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'5'">
      <rgi-rx-form-field>
        <label rgiRxLabel class="rgi-ui-text-5" translate>{{ parameter?.description }}</label>
        <input rgiRxInput (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id"
               [formControlName]="parameter?.id" [id]="parameter?.id" [readonly]="parameter?.disabled"
               class="form-control mic-input" type="string">
      </rgi-rx-form-field>
    </div>

    <div *ngSwitchCase="'6'">
      <rgi-rx-form-field>
        <label rgiRxLabel class="rgi-ui-text-5" translate>{{ parameter?.description }}</label>
        <input rgiRxInput (change)="onParameterValueChange()" [attr.data-qa]="'parameter_'+parameter?.id"
               [formControlName]="parameter?.id" [id]="parameter?.id" [readonly]="parameter?.disabled"
               [value]="parameter?.value" class="form-control mic-input" type="text">
      </rgi-rx-form-field>
    </div>

  </div>

</form>
