import { LicTranslationsKeys } from '../lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_DE_CH: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: "Policennummer {{num}} korrekt ausgestellt",
  lic_ProposalCorrectlyIssued: "Antragsnummer {{num}} korrekt ausgestellt",
  lic_LifeProposalCorrectlyIssued: "Leben-Antragsnummer {{num}} korrekt ausgestellt",
  lic_LifePolicyCorrectlyIssued: "Leben-Policennummer {{num}} korrekt ausgestellt",
  lic_DamagesPolicyCorrectlyIssued: "Nicht-Leben-Policennummer {{num}} korrekt ausgestellt",
  lic_AuthorizatioProposalForwarded: "Die Anfrage nach Genehmigung der Antragsnummer {{num}} wurde an den zuständigen Benutzer weitergeleitet",
  lic_ProposalCorrectlySaved: "Antragsnummer {{num}} korrekt gespeichert",
  lic_PreventiveCorrectlySaved: "Offertennummer {{num}} korrekt gespeichert",
  lic_benMorteNoAssic: "Die als Begünstigter im Todesfall ausgewählte Partei ist bereits als Versicherter ausgewählt, wählen Sie eine andere Partei aus",
  lic_benMorteNoRef: "Die als Begünstigter im Todesfall ausgewählte Partei ist bereits als Dritte Kontaktperson ausgewählt, wählen Sie eine andere Partei aus",
  lic_benefPresente: "Die als Begünstigter im Todesfall ausgewählte Partei ist bereits ausgewählt, wählen Sie eine andere Partei aus",
  lic_refTerzoNoAssic: "Die als Dritte Kontaktperson ausgewählte Partei ist bereits als Versicherter ausgewählt, wählen Sie eine andere Partei aus",
  lic_refTerzoNoContr: "Die als Dritte Kontaktperson ausgewählte Partei ist bereits als Versicherungsnehmer ausgewählt, wählen Sie eine andere Partei aus",
  lic_refTerzoPresente: "Die als Dritte Kontaktperson ausgewählte Partei ist bereits als Begünstigter im Todesfall ausgewählt, wählen Sie eine andere Partei aus",
  lic_DelegateEqualToPolicyHolder: "Delegierter gleich wie der Versicherungsnehmer?",
  lic_LegalGuardianEqualToPolicyHolder: "Gesetzlicher Vormund gleich wie der Versicherungsnehmer?",
  lic_ThirdPayerEqualToPolicyHolder: "Drittzahler gleich wie der Versicherungsnehmer?",
  lic_DelegateEqualToPolicyHolderWarning: "Partei für die Rolle Delegierter gleich wie der Versicherungsnehmer",
  lic_LegalGuardianEqualToPolicyHolderWarning: "Partei für die Rolle Gesetzlicher Vormund gleich wie der Versicherungsnehmer",
  lic_ThirdPayerEqualToPolicyHolderWarning: "Partei für die Rolle Drittzahler gleich wie der Versicherungsnehmer",
  lic_MasterPolicyHolder: "Hauptversicherungsnehmer",
  lic_SubjectXalreadyselectedfortherole: "Partei {{party}} bereits für die Rolle ausgewählt",
  lic_InsuredAmount: "Versicherungssumme",
  lic_InvestedPremium: "Angelegte Prämie",
  lic_FirstInstalment: "Erste Ratenzahlung",
  lic_InsuredNotEqualHolder: "Versicherter und Versicherungsnehmer müssen unterschiedlich sein",
  lic_SaveEstimate: "Offerte speichern",
  lic_LifeQuote: "Leben-Offerte",
  lic_PreventiveIssue: "Offerte ausstellen",
  lic_ProposalIssue: "Antrag ausstellen",
  lic_PolicyIssue: "Police ausstellen",
  lic_appendixLabel: "Begünstigtennachtrag",
  lic_summary_save_and_accept: "speichern und annehmen",
  lic_summary_save_accept_pol_issue: "speichern, akzeptieren und Police ausstellen",
  lic_summary_save_accept_prop_issue: "speichern, akzeptieren und Antrag ausstellen",
  lic_effectivebeneficiaryholder: "effektiv begünstigter Versicherungsnehmer",
  lic_LifePolicy: "Leben-Police",
  lic_DamagesPolicy: "Nicht-Leben-Police",
  lic_view: "Ansicht",
  lic_premium_detail: "Prämiendetails",
  lic_guarantee_summary: "Zusammenfassung der Deckung",
  lic_commissions: "Provisionen",
  lic_Parties: "Parteien",
  lic_Party: "Partei",
  lic_Employer: "Arbeitgeber",
  lic_SpecificPIPdata: "Spezifische PIP-Daten",
  lic_prev_continue: "Weiter",
  lic_Transferdata: "Daten übertragen",
  lic_Selectatleastonecontribution: "Wählen Sie mindestens einen Beitrag",
  lic_Globalpercentagemustbe100: "Der globale Prozentsatz muss 100 % betragen",
  lic_Selectcontributionsmandatoryfields: "Beiträge auswählen Pflichtfelder",
  lic_Contributiondata: "Beitragsdaten",
  lic_Globalamountmustbemajorthan0: "Der Globalbetrag muss grösser als 0 sein",
  lic_Everycontributionselectedmustbemajorthan0: "Jeder ausgewählte Beitrag muss grösser als 0 sein.",
  lic_error_mandatory_fields: "Rot markierte Felder sind erforderlich oder enthalten falsche Werte",
  lic_error_iban: "IBAN ist nicht korrekt",
  lic_authorization_request: "GENEHMIGUNGSANFRAGE",
  lic_selected_profiles_: "Ausgewählte Profile",
  lic_Disinvestmentsfunds: "Veräusserung von Finanzmitteln",
  lic_reg41_comunication: "Name zum Senden von Mitteilungen an",
  lic_disinvestment_amount: "Betrag der Veräusserung",
  lic_occurrenceNumber: "Vorkommnis",
  lic_TheoccurenceNumbermustbeavalue: "Die Anzahl der Vorkommnisse muss grösser sein als {{min}}",
  lic_Disinvestmentrange: "Geben Sie einen zu veräussernden Betrag ein, der grösser als {{max}} und kleiner oder gleich {{min}} ist",
  lic_Incompleteinvestmentforthefunds: "Unvollständige Anlage, bitte vervollständigen Sie die Anlage für die Finanzmittel",
  lic_Incompleteinvestmentfortheprofiles: "Unvollständige Anlage, bitte vervollständigen Sie die Anlage für die Profile",
  lic_Settlementstoreinvest: "Abfindungen zur Wiederanlage",
  lic_Policynumber: "Policennummer",
  lic_Type: "Art",
  lic_Beneficiary: "Begünstigter",
  lic_State: "Status",
  lic_Net: "Netto",
  lic_Nettoreinvest: "Netto zur Wiederanlage",
  lic_Reinvestmentmustbelargerthanquotation: "Die Wiederanlage muss grösser sein als die Notierung.",
  lic_Mustselectatleastonesettlementtoreinvest: "Sie müssen mindestens eine Abrechnung zur Wiederanlage auswählen.",
  lic_Nettoreinvestmustbeequalorlessthannet: "Netto zur Wiederanlage muss gleich oder kleiner als das Netto sein.",
  lic_Nosettlementstoreinvestexist: "Es gibt keine Abrechnungen zur Wiederanlage.",
  lic_Missingvalueonnettoreinvest: "Fehlender Wert auf Netto zur Wiederanlage.",
  lic_information: "Informationen",
  lic_Percentage: "Prozentsatz",
  lic_Durationin: "Laufzeit in ",
  lic_years: "Jahre",
  lic_months: "Monate",
  lic_days: "Tage",
  lic_Complementary: "(Ergänzend)",
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: "Bitte geben Sie eine Zahlungsweise für den Coupon-Begünstigten an",
  lic_Netavailable: "Netto verfügbar",
  lic_Proposal_Saved: "Der Antrag wurde gespeichert mit der Nummer ",
  lic_add_employer: "Einen lic_Arbeitgeber hinzufügen",
  lic_AvailableGuarantees: "Verfügbare Garantien",
  lic_error: "Fehler",
  lic_error_msg: "Fehler beim Ausstellen der Police",
  lic_auth_error_msg: "Fehler bei der Verarbeitung der Anfrage",
  lic_common_risk: "Häufiges Risiko",
  lic_Sustainability: "Nachhaltigkeit",
  lic_assignment_holder: "Inhaber der Zuordnung",
  lic_ecoSustainabilityAmount: "Ökologische Nachhaltigkeit Betrag",
  lic_ecoSustainabilityPerc: "Ökologische Nachhaltigkeit %",
  lic_envSustainabilityAmount: "Umwelt Nachhaltigkeit Betrag",
  lic_envSustainabilityPerc: "Umwelt Nachhaltigkeit %",
  lic_paiAmount: "PAI-Betrag",
  lic_paiPerc: "PAI %",
  lic_socialSustainabilityAmount: "Soziale Nachhaltigkeit Betrag",
  lic_socialSustainabilityPerc: "Soziale Nachhaltigkeit %",
  lic_benefSevereDisability: "Begünstigter mit schwerer Behinderung",
  lic_SecondInsured: "Zweiter Versicherter",
  lic_Secondinsuredmustbeaphysicalperson: "Der zweite Versicherte muss eine natürliche Person sein",
  lic_Selectasecondinsured: "Wählen Sie einen zweiten Versicherten aus",
  lic_Aderente: "Zeichner",
  lic_NumeroProposta: "Antragsnummer",
  lic_DescrizioneProdotto: "Beschreibung des Produkts",
  lic_DescrizioneRischio: "Beschreibung des Risikos",
  lic_CapitaleAssicurato: "Versicherungssumme",
  lic_SELEZIONA: "-- AUSWÄHLEN --",
  lic_PrNetto: "% Nettoprämie",
  lic_ImpAcq: "Verkaufssteuer",
  lic_profileInvestmentIncomplete: "Anlage unvollständig, vollständige Anlageprofile",
  lic_fundsInvestmentIncomplete: "Anlage unvollständig, bitte vervollständigen Sie Anlagemittel",
  lic_Clauses: "Klauseln",
  lic_quote_type: "Art der Offerte",
  lic_quote_type_anonymous: "Anonym",
  lic_Policyholder: "Versicherungsnehmer",
  lic_quote_type_registered: "Registriert",
  lic_text_required: "*Erforderlicher Text",
  lic_text_required_error: "Text der Klausel ist erforderlich.",
  lic_close: "schliessen",
  lic_save: "speichern",
  lic_NEXT: "WEITER",
  lic_policyData: "Daten zur Police",
  lic_pipData: "PIP-Daten",
  lic_parties: "Parteien",
  lic_assets: "Vermögenswerte",
  lic_quotation: "Offerte",
  lic_summary: "Zusammenfassung",
  lic_issue: "Ausstellung",
  lic_investments: "Anlagen",
  lic_invest_value_range: "Bitte geben Sie einen Wert zwischen {{min}} und {{max}} ein",
  lic_disinvestment_range: "Bitte geben Sie einen Veräusserungswert zwischen {{min}} und {{max}} ein",
  lic_mandatory_threshold_perc: "Fonds: {{value}} - Bitte geben Sie den Schwellenwert in Prozent ein",
  lic_mandatory_one_fund: "Wählen Sie mindestens einen Fonds aus",
  lic_invest_factor_min_validation: "{{factor}} muss weniger als {{amount}} sein",
  lic_invest_factor_max_validation: "{{factor}} muss mehr als {{amount}} sein",
  lic_invest_factor_eq_validation: "{{factor}} muss {{amount}} sein",
  lic_calculate_asset_volatility: "Volatilität des Vermögenswerts berechnen",
  lic_calculate_sustainability_asset: "Nachhaltigkeit des Vermögenswerts berechnen",
  lic_occurance_number_range: "Die Anzahl der Vorkommnisse muss grösser sein als {{min}}",
  lic_policy_holder: "Versicherungsnehmer",
  lic_guarantee: "Garantie",
  lic_co_applicant: "Mitantragsteller ",
  lic_co_applicant_not_same_holder: "Mitantragsteller darf nicht gleich wie der Versicherungsnehmer sein",
  lic_co_applicant_physical: "Mitantragsteller muss eine natürliche Person sein",
  lic_co_applicant_mandatory: "Mitantragsteller ist obligatorisch",
  lic_insured_must_be_physical: "Versicherter muss eine natürliche Person sein",
  lic_mandatory_product: "Wählen Sie ein Produkt aus",
  lic_mandatory_insured: "Wählen Sie einen Versicherten aus",
  lic_mandatory_holder: "Wählen Sie einen Versicherungsnehmer aus",
  lic_contribution_frequency: "Die Häufigkeit des Beitrags {{frequency}} muss gleich sein",
  lic_mandatory_benef: "Begünstigter {{category}}: Begünstigter ist obligatorisch",
  lic_mandatory_appendix_benef: "Begünstigter {{category}}: Begünstigtennachtrag ist obligatorisch",
  lic_add_benef: "Begünstigter {{category}}: Begünstigten aus dem Standesamt hinzufügen",
  lic_add_benef_perc: "Begünstigter {{category}} {{nominative}}: Prozentsatz hinzufügen",
  lic_benef_perc_range: "Begünstigter {{category}} {{nominative}}: Der Prozentsatz muss zwischen 0,01 und 100 liegen",
  lic_benef_total_perc: "Begünstigter {{category}}: Der prozentuale Anteil muss 100 % betragen",
  lic_guarantee_mandatory_data: "Garantie {{unit}}: Füllen Sie alle Pflichtangaben aus",
  lic_quotation_not_done: "Offerte nicht fertig: Wählen Sie 'Offerte' aus, um die Offerte zu aktualisieren",
  lic_quotation_modified_value_quote: "Daten werden aktualisiert: Wählen Sie 'Offerte' aus, um die Offerte zu aktualisieren",
  lic_quotation_benef_custom_msg: "Begünstigter {{category}} - {{msg}}",
  lic_product: "Produkt",
  lic_managementNode: "Verwaltungsknoten",
  lic_branch: "Branche",
  lic_agreement: "Vereinbarung",
  lic_effect_date: "Gültigkeitsdatum",
  lic_exp_date: "Verfallsdatum",
  lic_payment_frequency: "Zahlungsfrequenz",
  lic_means_of_payment: "Zahlungsmittel",
  lic_unit_type: "Anteilart",
  lic_risk_type: "Risikoart",
  lic_substate: "Unterstatus",
  lic_benefit: "Leistung",
  lic_decreasing_frequency: "Abnehmende Frequenz",
  lic_damages: "Nicht-Leben",
  lic_effective_holder: "Wirtschaftlich Berechtigte",
  lic_insured_role: "Versicherter",
  lic_third_contact_person: "Dritte Kontaktperson",
  lic_quotation_benef_owner_perc_validation: "{{roleDescr}} {{name}} des Begünstigten {{benefName}} hält den akzeptierten Mindestprozentsatz {{minPerc}} nicht ein",
  lic_subscriber: "Zeichner",
  lic_yes: "ja",
  lic_no: "nein",
  lic_new_insured: "Neuer Versicherter",
  lic_simple_select: "Auswählen",
  lic_first_instalment: "Erste Ratenzahlung",
  lic_next_instalment_premium: "Nächste Ratenzahlung Prämie",
  lic_payment_type: "Zahlungsart",
  lic_first_instalment_payment_type: "Art der ersten Ratenzahlung",
  lic_annual_premium: "Jahresprämie",
  lic_quote_button: "Offerte",
  lic_quote_save: "Offerte und speichern",
  lic_purchasing_commission: "Einkaufsprovision",
  lic_beneficiaries_label: "Begünstigte",
  lic_general_data_label: "Allgemeine Daten",
  lic_contractual_option_not_allowed: "Vertragliche Optionen nicht zulässig",
  lic_investment_value_label: "Anlagewert",
  lic_investment_profile: "Anlageprofil",
  lic_investment_funds: "Anlagefonds",
  lic_calculate_finantial_parameters: "Finanzielle Parameter berechnen",
  lic_confirm_button: "Bestätigen",
  lic_cancel_button: "Abbrechen",
  lic_save_button: "Speichern",
  lic_continue_button: "Weiter",
  lic_next_button: "Weiter",
  lic_required: "erforderlich",
  lic_node: "Knoten",
  lic_company: "Unternehmen",
  lic_product_type: "Produktart",
  lic_taxes_label: "Steuern",
  lic_fees_label: "Gebühren",
  lic_frequency_interests: "Zinsfrequenz",
  lic_frequency: "Frequenz",
  lic_accessories: "Zubehör",
  lic_taxable: "Steuerpflichtig",
  lic_quotation_detail_modal: "Offertendetails",
  lic_guarantees_overview_modal: "Überblick über die Garantien",
  lic_gross_label: "Brutto",
  lic_int_fract_label: "Int. Teilung.",
  lic_next_instalment_label: "Nächste Ratenzahlung",
  lic_pure_premium_label: "Reine Prämie",
  lic_extra_premium_label: "Zusatz- prämie",
  lic_loadings_label: "Belastungen",
  lic_total_label: "Gesamt",
  lic_beneficiary_perc_is_over: "Die Summe der Prozentsätze der mit {{benefCat}} {{benefRole}} verknüpften Rollen ist grösser als 100",
  lic_questionSTR_invalid: "Die Antwort auf die Frage: {{question}}, ist nicht korrekt",
  lic_purchase_commissions: "Einkaufsprovisionen",
  lic_PurchaseCommission: 'Einkaufsprovision',
  lic_NetPrem: '% Nettoprämie',
  lic_sales_commissions: "Verkaufsprovisionen",
  lic_IBAN_formally_incorrect: "IBAN ist formell nicht gültig",
  lic_holder_physical_person_req: "Versicherungsnehmer muss eine natürliche Person sein",
  lic_holder_legal_person_req: "Versicherungsnehmer muss eine juristische Person sein",
  lic_available_covers: "Verfügbare Deckungen",
  lic_covers_overview: "Übersicht über Deckungen",
  lic_covers: "Deckungen",
  lic_cover: "Deckung",
  lic_config_profile_warning: "ACHTUNG: Die Konfigurationsprofile haben sich geändert.",
  lic_select: "Auswählen",
  lic_Irrevocable_beneficiary: "Unwiderruflicher Begünstigter",
  lic_Base: "(Basis)",
  lic_Accessory: "(Zusatz)",
  lic_investment_value: "Anlagewert",
  lic_volatility: "Volatilität",
  lic_name: "Name",
  lic_to_be_invested: "",
  lic_info_investment_completed: "Information: Anlage abgeschlossen",
  lic_fund: "Fonds",
  lic_max_percentage: "akzeptierter Maximalprozentsatz",
  lic_min_percentage: "akzeptierter Mindestprozentsatz",
  lic_selected_funds: "Ausgewählte Fonds",
  lic_allocation_exceeds: "Anlagezuweisung übersteigt den verfügbaren Betrag",
  lic_percentage_threshold: "% Schwellenwert",
  lic_fund_distribution_identical: "Fondsausschüttung identisch mit der ursprünglichen Zahlung",
  lic_fund_not_available: "kein Fonds für Option",
  lic_details: "Details",
  lic_starting_fund: "Startkapital",
  lic_fund_category: "Profil/ Fonds",
  lic_equivalent: "Äquivalent zum Datum",
  lic_policy_address: "Adresse der Police",
  lic_policy_master_details: "Hauptpolicedetails",
  lic_administrative_data: "Verwaltungsdaten",
  lic_ordinary_mail: "Gewöhnliche Post",
  lic_threshold: "Schwellenwert",
  lic_payment_method: "Nächste Ratenzahlungsweise",
  lic_additional_payment: "Zusätzliche Zahlung",
  lic_contractual_options: "Vertragliche Optionen",
  lic_elements: "Elemente",
  lic_position_number: "Positionsnummer",
  lic_questionnaires_section: "Fragebögen",
  lic_fees: "Feste Gebühren",
  lic__1TERM: "Erste Laufzeit",
  lic__EFFOP: "Gültigkeitsdatum",
  lic__OPZFR: "Frequenz",
  lic__OPZPM: "Zahlungsweise",
  lic_expirationDate: "Verfallsdatum",
  lic__OPBEN: "Begünstigter",
  lic__MINTS: "Mindestschwellenwert",
  lic__MAXTS: "Maximalschwellenwert",
  lic__NUMOC: "Vorfallnummer",
  lic__AMTOP: "Rückkaufbetrag",
  lic__MALMT: "Mindestbetrag",
  lic_amount: "Betrag",
  lic_currency: "Währung",
  lic__CIBAN: "IBAN",
  lic_fixedFees: "Feste Gebühren",
  lic__PCOUP: "Periodischer Rabattprozentsatz",
  lic_holder: "Versicherungsnehmer",
  lic_ACTIVE_STATE: "AKTIV",
  lic_MANDATORY_STATE: "OBLIGATORISCH",
  lic_DEACTIVATE_STATE: "DEAKTIVIEREN",
  lic_premium_label: "Prämie",
  lic_holders_title: "Versicherungsnehmer",
  lic_beneficiary_qualification: " {{benefCat}} {{benefRole}} ",
  lic_invalid_total_position_percentage: "Der prozentuale Betrag für die Gruppe \"{{groupType}}\" für den Begünstigten {{cat}} muss 100 % betragen",
  lic_beneficiary_position_mandatory: "Begünstigter {{benefCat}} {{benefName}}, wählen Sie eine \"Positionsnummer\" aus",
  lic_role_is_mandatory: "Sie müssen eine {{Rolle}} eingeben",
  lic_effective_holder_duplicated: "Effektiver Versicherungsnehmer vervielfältigt",
  lic_beneficiary_perc_req_range: "{{benefCat}} {{benefRole}} {{benefName}}: Der Prozentsatz muss zwischen 0,01 und 100 liegen",
  lic_beneficiary_perc_req: " {{benefCat}} {{benefRole}} {{benefName}}: Geben Sie den Prozentsatz ein",
  lic_beneficiary_from_party_req: " {{benefCat}} {{benefRole}}: Geben Sie einen Begünstigten aus dem Parteien-Repository ein",
  lic_effective_holder_perc: "{{benefName}}'s Prozentsatz des wirtschaftlich Berechtigten {{linkedName}}' liegt unter dem zulässigen Mindestwert von {{linkedPerc}}%",
  lic_effective_holder_perc_req: "Wrtschaftlich Berechtigter {{linkedName}} von {{benefName}}': Geben Sie den Prozentsatz ein",
  lic_or_junction: "oder",
  lic_backdating_not_allowed: "",
  lic_no_common_risk: "Es gibt keine Vorschläge oder Policen, die in die Berechnung des Risikokapitals für den Versicherten fallen",
  lic_print_docs: 'Dokumente drucken',
};
export default LIC_DE_CH ;
