import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { DocumentService } from '../../../services/document.service';
import {DocumentEvent} from '../../../models/document.model';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { RgiRxAccordionDirective } from '@rgi/rx/ui';
import { Policy } from '../../../models/life-detail.model';
import { DocumentsService, OutboundDocumentsService } from '@rgi/shared-components';
import { catchError, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, of, throwError } from 'rxjs';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'lpc-document',
  templateUrl: './lpc-document.component.html',
  styleUrls: ['./lpc-document.component.scss']
})
export class LpcDocumentComponent implements OnInit, OnDestroy {

  @Output() documentEvent = new EventEmitter<DocumentEvent>();
  @Input() contractId: string;
  @Input() operationCode: string;
  @Input() publishMessage: string;
  @Input() printMode: string;
  @Input() resourceId: string;
  @Input() movementId: string;
  @Input() movementDescription: string;
  @Input() contract: Policy;
  @Input() authorizationId: string;
  @ViewChild("accordionRef", {static: false}) multiAccordion: RgiRxAccordionDirective;


  activePanelLists: string[] = [];
  documentList: any[];
  onInitEnd = false;

  pdDocCommonList = [];
  protected $subscriptions: Subscription[] = [];
  stillGeneratingDocs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected documentService: DocumentService,
    protected rgiDocumentService: DocumentsService,
    protected outBoundDocumentService: OutboundDocumentsService,
    @Optional() @Inject(PV_TOKEN.NEW_OUTBOUND_DOC_HANDLER) public newOutboundHandler: boolean,
    protected loaderService: LoaderService,
  ) { }

  ngOnDestroy(): void {
    this.$subscriptions.forEach((sub) => sub.unsubscribe());
  }


  get areThereDocuments(): boolean {
    return this.documentList.length > 0;
  }

  ngOnInit() {
    this.activePanelLists = ['doc-panel'];
    if (this.newOutboundHandler) {
      // async call made by purpose to make the call silent and not blocking the UI
      this.callTheSavingDocuments();
      this.getNewDocumentList();
    } else {
      this.oldDocumentHandler();
    }
  }

  protected getNewDocumentList() {

    const callOutboundDocs = this.getOutboundDocService();

    this.$subscriptions.push(
      this.loaderService.registerBeCall(
        callOutboundDocs.pipe(
          switchMap((documents) => {
            this.pdDocCommonList = documents;
            const vectorOfDoc = [];
            documents.forEach((doc) => {
              vectorOfDoc.push({
                nomeFile: doc.description,
                documentCode: doc.id
              });
            });

            this.documentList = vectorOfDoc;
            this.onInitEnd = true
            return of(documents);
          }),
          catchError((err) => {
            console.error('ERRORE SERVIZIO DOCUMENTI');
            this.onInitEnd = true;
            return throwError(err);
          })
        )
      ).subscribe()
    );
  }

  getOutboundDocService(): Observable<any> {
    const authorizationID = this.contract.authorizationIdGen || this.contract.authorizationId;
    if (authorizationID) {
      return this.outBoundDocumentService.getOutDocumentsFromReservat(
        authorizationID
      );
    }
    return this.outBoundDocumentService.getOutboundDocumentsDataSource$(
      this.contract.proposalNumber,
      this.movementId
    );
  }

  protected oldDocumentHandler() {
    this.documentService.getDocumentList(
      this.contractId,
      this.operationCode,
      [],
      this.printMode
    ).subscribe((response) => {
      const vectorOfDoc = [];
      response.documents.forEach((element: any) => {
        vectorOfDoc.push({
          nomeFile: element.documentName,
          documentCode: element.documentCode
        });
      });

      this.documentList = vectorOfDoc;
      this.onInitEnd = true;
    });
  }

  protected callTheSavingDocuments() {
    this.callDocumentGenerationForTheCorrectFlow()
      .pipe(
        catchError((err) => {
          this.stillGeneratingDocs.next(false);
            return of('Errore in generateOutputDocument');
        })
      ).subscribe(() => {
        this.stillGeneratingDocs.next(false);
      })
  }

  private callDocumentGenerationForTheCorrectFlow() {
    const authId = this.contract.authorizationIdGen || this.contract.authorizationId;
    if (authId) {
      return this.outBoundDocumentService.generateOutDocumentsFromReservat(authId);
    }
    return this.rgiDocumentService.saveOutputDocuments(this.contract.proposalNumber, this.movementId);
  }

  downloadDocument(event: any, doc: any) {
    if (this.newOutboundHandler) {
      this.stillGeneratingDocs.asObservable().subscribe((stillGenerating) => {
        if (!stillGenerating) {
          const document = this.pdDocCommonList.find(d => d.id === doc.documentCode);
          this.rgiDocumentService.downloadDocument$(document.id, document.description, document.file, true, this.contract.objectId, this.movementId).subscribe()
        }
      });
    } else {
      event.stopPropagation();
      this.documentEvent.emit({
        eventName: JS_EVENT.DOWNLOAD_DOC,
        document: doc,
        type: 'life'
      });
    }
  }

  contractAll(){
    this.multiAccordion.contractAll();
  }
}
