import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import { LIC_IT } from './it';
import LIC_EN from './en';
import LIC_FR from './fr/fr';
import LIC_FR_CH from './fr/fr-CH';
import LIC_DE from './de/de';
import LIC_DE_CH from './de/de-CH';
import { LIC_ES } from './es/es';
import LIC_EU_ES from './es/eu-ES';
import LIC_GL_ES from './es/gl-ES';
import LIC_CA_ES from './es/ca-ES';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_EN);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_FR);
  });
}

export function loadFR_CH(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_FR_CH);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_DE);
  });
}

export function loadDE_CH(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_DE_CH);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_ES);
  });
}

export function loadEU_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_EU_ES);
  });
}

export function loadGL_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_GL_ES);
  });
}

export function loadCA_ES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(LIC_CA_ES);
  });
}


export const LIC_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadFR,
    locale: 'fr'
  },
  {
    load: loadFR_CH,
    locale: 'fr-CH'
  },
  {
    load: loadDE,
    locale: 'de'
  },
  {
    load: loadDE_CH,
    locale: 'de-CH'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadEU_ES,
    locale: 'eu-ES'
  },
  {
    load: loadGL_ES,
    locale: 'gl-ES'
  },
  {
    load: loadCA_ES,
    locale: 'ca-ES'
  }
];
