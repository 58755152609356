<div *ngIf="!summary" class="row" [formGroup]="formGroup">
  <rgi-rx-form-field>
    <div class="col-lg-12">
      <div class="col-lg-6">
        <label rgiRxLabel class="label-core" translate>lpc_payment_type</label>
        <select rgiRxNativeSelect formControlName="idPaymentType" [disabled]="!isPaymentTypeEditable"
          data-qa="PaymentType" data-obb="true">
          <option [value]="null" disabled></option>
          <option *ngFor="let paymentType of paymentTypeDefinitions" [value]="paymentType.id">{{paymentType.label}}</option>
        </select>
      </div>
    </div>
  </rgi-rx-form-field>
  <div *ngIf="paymentFieldDefinitions.length" class="col-lg-12" id="payment-fields">
    <lpc-payment [editable]="editable" [parentForm]="formGroup" [pagamenti]="paymentFieldDefinitions">
    </lpc-payment>
  </div>
  <div *ngIf="summary" class="row" [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-6">
        <span for="label" translate>lpc_payment_method</span>
        <span for="value">: {{ paymentSelectedOptionLabel }}</span>
      </div>
    </div>
  </div>
