<div class="grid no-date">
  <rgi-rx-form-field>
    <div class="col-lg-12 mt-2">
      <label rgiRxLabel class="label-core">{{value}}</label>
      <form [formGroup]="group">
        <input rgiRxInput lpcFormatPayment (blur)="onBlur()" [paymentInput]="config" [formControlName]="config.code"
          class="form-control" [attr.data-qa]="'mic-input-payment-'+config.code">
        <br>
      </form>
    </div>
  </rgi-rx-form-field>
</div>
