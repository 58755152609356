
import {Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {
  AnagApiParty,
  AnagEntityIta,
  AnagStorageService,
  AnagFormFieldConfig,
  AnagConfiguration,
  AnagStatelessService,
  AnagConfigService,
  PRIVACY_CONSENSUS_ENUM,
  AnagPhoneNumber,
  DOCUMENT_TYPE_ENUM
} from '@rgi/anag';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import { AnagIdentificationEntity, AnagPrivacyConsensus, AnagApiSaveSubjectResponse, AnagApiCreateSubject } from '../proposal-otp/proposal-otp-model/save-party-interfaces';
import {ProposalOtpService} from '../proposal-otp/proposal-otp-service/proposal-otp.service';
import { Subscription } from 'rxjs';
import {AnagDocument} from '../models/otp-document.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { AnagIntPrefixEntity } from '@rgi/anag/lib/anag-model/anag-domain/anag-int-prefix-entity';
import { AnagPortalUtilityService } from '../services/anag-portal-utility.services';
import { ModalService } from '@rgi/rx/ui';
import {AnagDialogComponent, ModalDialogData} from '@rgi/anag';

@Component({
  selector: 'iiab-issue-privacy-edit',
  templateUrl: './issue-privacy-edit.component.html'
})
export class IssuePrivacyEditComponent implements OnInit, OnModalClose, OnDestroy {

  inputParty: AnagApiParty;

  privacyQuestions: Array<AnagEntityIta>;
  privacyForm: UntypedFormGroup;
  maxDate = new Date();
  submitted: boolean;
  modalClose: EventEmitter<any> = new EventEmitter();
  originOptions: Array<AnagEntityIta>;
  config: AnagConfiguration;
  privacyDataFormMap: Map<string, AnagFormFieldConfig>;

  internationalPrefixes: Array<AnagIntPrefixEntity>;
  subscriptions: Subscription = new Subscription();
  isPrefixHidden: boolean = false;
  controlName: string;
  portfolio: string;

  numberValidator = Validators.pattern('^[0-9]*$');
  pat = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-_]+\\.[a-zA-Z0-9_-]{2,4}$';
  emailValidator = Validators.pattern(this.pat);

  phoneNumberIntPrefix = new UntypedFormControl('',{validators: [Validators.required]});
  phoneNumberPrefix = new UntypedFormControl(undefined, this.numberValidator);
  phoneNumber = new UntypedFormControl(undefined, {validators: [Validators.required,this.numberValidator]});
  email = new UntypedFormControl(undefined, {validators: [Validators.required,this.emailValidator]});
  documentForm: UntypedFormGroup = new UntypedFormGroup({});
  docToEdit: AnagDocument;
  country: string = null;

  list = {
    'phoneNumberIntPrefix': 'phoneNumber'
  };

  documentFormMap = new Map<string, AnagFormFieldConfig>([
    ['documentType', new AnagFormFieldConfig('documentType', this.documentForm, [], 'document')],
    ['documentNumber', new AnagFormFieldConfig('documentNumber', this.documentForm, [], 'document')],
    ['releaseDate', new AnagFormFieldConfig('releaseDate', this.documentForm, [], 'document')],
    ['expirationDate', new AnagFormFieldConfig('expirationDate', this.documentForm, [], 'document')],
    ['releaseLocation', new AnagFormFieldConfig('releaseLocation', this.documentForm, [], 'document')]
  ]);

  contactsForm: UntypedFormGroup = new UntypedFormGroup({
    phoneNumberIntPrefix: this.phoneNumberIntPrefix,
    phoneNumberPrefix: this.phoneNumberPrefix,
    phoneNumber: this.phoneNumber,
    email: this.email
  });

  constructor(
    // public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public anagStatelessService: AnagStatelessService,
    public pushMessageHandler: PushMessageHandlerService,
    protected translateService: RgiRxTranslationService,
    public configService: AnagConfigService,
    protected statelessService: AnagStatelessService,
    @Optional() @Inject(DIALOG_DATA) inputData: any,
    private proposalOtpService: ProposalOtpService,
    protected anagPortalUtilityService: AnagPortalUtilityService,
    protected modalService: ModalService
  ) {
    if (inputData) {
      if (inputData.party && (inputData.party.privacyConsensus || inputData.party.privacyConsensusDate)) {
        this.inputParty = inputData.party;
      }
      if (inputData.partyConfig) {
        this.config = inputData.partyConfig;
      }
      if (inputData.internationalPrefixes) {
        this.internationalPrefixes = inputData.internationalPrefixes;
      }
      if (inputData.portfolio) {
        this.portfolio = inputData.portfolio;
      }
    }
    this.privacyForm = new UntypedFormGroup({});
    this.privacyDataFormMap = new Map<string, AnagFormFieldConfig>([]);
  }

  ngOnInit() {
    this.subscribeToValueChanges('phoneNumberIntPrefix');
    this.manageInputParty();
    this.pushMessageHandler.clearTag('privacy-tag');
    this.anagStorage.getOriginEnums().subscribe(origin => {
      this.originOptions = origin;
    });
    let otpPrivacy;
    if (this.inputParty && this.inputParty.privacyConsensus) {
      otpPrivacy = this.inputParty.privacyConsensus.find(p => {
        return p.privacy.code === '_FROTP';
      });
    }
    // this.config = this.stateMgr.getCurrentState().configuration;
    this.privacyQuestions = [];
    this.anagStorage.getPrivacyEnums().subscribe(privacyQuestions => {
      if (otpPrivacy && otpPrivacy.privacy) {
        let otpQuestion = privacyQuestions.find(p => {return otpPrivacy.privacy.identification === p.codice});
        this.setControlFromQuestion(otpQuestion);
        this.privacyQuestions.push(otpQuestion);
      } else {
        let privacyQuestionsFiltered = privacyQuestions;
        if (this.country && this.country === 'ES') {
          privacyQuestionsFiltered = [];
          let privacyQuestionsFilteredEspana = privacyQuestions.find(q => { return q.codice === '3' });
          privacyQuestionsFiltered.push(privacyQuestionsFilteredEspana);
        }
        privacyQuestionsFiltered.forEach(question => {
          this.setControlFromQuestion(question);
        });
        this.privacyQuestions = privacyQuestionsFiltered;
      }
      this.privacyForm.valueChanges.subscribe(() => {
        this.submitted = false;
      });
      if (otpPrivacy) {
        this.setFormValuesFromConsensus(otpPrivacy);
      } else {
        if (this.inputParty) {
          if (this.inputParty.privacyConsensus) {
            this.inputParty.privacyConsensus.forEach(p => {
              this.setFormValuesFromConsensus(p);
            });
          }
        }
      }
    });
    this.configService.setConfigToForm(this.privacyDataFormMap, this.config);
    this.isPrefixHidden = this.config.partyConfig && this.config.partyConfig.mobilePhone ? this.configService.isFieldHidden(this.config.partyConfig.mobilePhone[0].localPrefix) : true;
    this.fillDocumentForm();
    if (!this.inputParty.documents || this.inputParty.documents.length < 1) {
      this.docToEdit = new AnagDocument();
      this.inputParty.documents = [];
      this.inputParty.documents.push(this.docToEdit);
    } else {
      this.docToEdit = this.proposalOtpService.getDocumentForOtp(this.portfolio, this.inputParty.documents);
    }
    this.adaptPartyToForm();
  }

  setControlFromQuestion(question){
    this.privacyForm.addControl(question.codice, new UntypedFormControl(''));
    this.privacyForm.addControl('date-' + question.codice, new UntypedFormControl(''));
    this.privacyForm.addControl('origin-' + question.codice, new UntypedFormControl(''));
    this.privacyDataFormMap.set(question.codice, new AnagFormFieldConfig('privacyConsensusType', this.privacyForm, []));
    // eslint-disable-next-line max-len
    this.privacyDataFormMap.set('date-' + question.codice, new AnagFormFieldConfig('privacyConsensusDate', this.privacyForm, []));
    // eslint-disable-next-line max-len
    this.privacyDataFormMap.set('origin-' + question.codice, new AnagFormFieldConfig('privacyConsensusOrigin', this.privacyForm, []));
  }

  setFormValuesFromConsensus(p){
    if (p.privacyConsensusValue) {
      this.privacyForm.get(p.privacy.identification).setValue(p.privacyConsensusValue.codice);
    }
    if (p.privacyConsentDate) {
      this.privacyForm.get('date-' + p.privacy.identification).setValue(p.privacyConsentDate);
    }
    if (p.privacyConsentOrigin) {
      this.privacyForm.get('origin-' + p.privacy.identification).setValue(p.privacyConsentOrigin);
    }
  }

  fillDocumentForm() {
    for (const [key] of this.documentFormMap) {
      if(key === 'releaseLocation' || key === 'releaseDate') {
        this.documentForm.addControl(key, new UntypedFormControl(''));
      } else {
        this.documentForm.addControl(key, new UntypedFormControl('', {validators:[Validators.required]}));
      }
    }
  }

  adaptPartyToForm() {
    if (this.docToEdit){
      if (this.docToEdit.documentType) {
        this.setControlValue(this.documentForm.get('documentType'), this.docToEdit.documentType.codice);
      }
      if (this.docToEdit.releaseDate) {
        this.setControlValue(this.documentForm.get('releaseDate'), new Date(this.docToEdit.releaseDate));
      }
      if (this.docToEdit.expirationDate) {
        this.setControlValue(this.documentForm.get('expirationDate'), new Date(this.docToEdit.expirationDate));
      }
      this.setControlValue(this.documentForm.get('documentNumber'), this.docToEdit.documentNumber);
      this.setControlValue(this.documentForm.get('releaseLocation'), this.docToEdit.locationsRelease);
    }

  }

  notifyFormValidationMsg() {
    this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(stringMsg =>
      this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
        tag: 'privacy-tag',
        status: 'danger',
        dismissible: false
      }))
    ).unsubscribe();
  }

  actionClose() {
    this.modalClose.emit(undefined);
  }

  get questionOptions() {
    return this.anagStorage.getEnumsByCode(PRIVACY_CONSENSUS_ENUM);
  }

  confirmChanges() {
    this.submitted = true;
    if (this.privacyForm.valid && this.contactsForm.valid && this.documentForm.valid) {
      this.updatePrivacy();
      this.updateContacts();
      this.updateDocument();
      this.save();
    } else {
      this.privacyForm.markAllAsTouched();
      this.notifyFormValidationMsg();
    }
  }

  updatePrivacy() {
    this.updatePrivacyData(this.privacyForm.getRawValue(), this.privacyQuestions, this.questionOptions, this.originOptions);
    // this.stateMgr.updatePrivacy(this.privacyForm.getRawValue(), this.privacyQuestions, this.questionOptions, this.originOptions);
  }

  updateDocument() {
    this.updateDocumentData(this.documentForm.getRawValue());
  }

  getEnumsByCode(code: string): Array<AnagEntityIta> {
    return this.anagStorage.getEnumsByCode(code);
  }

  updateDocumentData(formData) {
    const docTypeForm = this.getEnumsByCode(DOCUMENT_TYPE_ENUM).find(docType => docType.codice === formData.documentType);
    if (this.docToEdit) {
      this.docToEdit.documentType = docTypeForm ? docTypeForm : null;
      this.docToEdit.documentNumber = docTypeForm && formData.documentNumber ? formData.documentNumber : null;
      this.docToEdit.releaseDate = docTypeForm && formData.releaseDate ? formData.releaseDate : null;
      this.docToEdit.expirationDate = docTypeForm && formData.expirationDate ? formData.expirationDate : null;
      this.docToEdit.locationsRelease = docTypeForm && formData.releaseLocation ? formData.releaseLocation : null;
    }
    if (this.inputParty.documents.length === 1) {
      this.inputParty.documents[0] = this.docToEdit;
    } else {
      this.inputParty.documents.forEach(elem => {
        if (elem.objectId === this.docToEdit.objectId) {
          elem = this.docToEdit;
        }
      });
    }
  }

  updatePrivacyData(privacyForm: any, questions: Array<AnagEntityIta>, options: Array<AnagEntityIta>, originoptions: Array<AnagEntityIta>) {
    const privacyConsensuses = [];
    questions.forEach(obj => {
      const question = obj;
      const privacy = new AnagIdentificationEntity(question.codice, question.descrizione, question.codice);
      const privacyConsensusValue = options.find(opt => opt.codice === privacyForm[question.codice]) ? options.find(opt => opt.codice === privacyForm[question.codice]) : null;
      const privacyConsentDate = privacyForm['date-' + question.codice];
      const privacyConsentOrigin = privacyForm['origin-' + question.codice];
      // eslint-disable-next-line max-len
      const privacyConsentOriginDescription = originoptions.find(originOpt => originOpt.codice === privacyForm['origin-' + question.codice]) ? originoptions.find(originOpt => originOpt.codice === privacyForm['origin-' + question.codice]).descrizione : null;
      // eslint-disable-next-line max-len
      privacyConsensuses.push(new AnagPrivacyConsensus(privacy, privacyConsensusValue, privacyConsentDate, privacyConsentOrigin, privacyConsentOriginDescription));
    });
    this.inputParty.privacyConsensus = privacyConsensuses;
  }

  get datepickerPlaceholder() {
    return this.anagStatelessService.getDatePickerPlaceholderLabel();
  }

  save() {
    this.proposalOtpService.saveParty(new AnagApiCreateSubject(this.inputParty)).subscribe((response: AnagApiSaveSubjectResponse) => {
      this.modalClose.emit(response.subject);
      if(response.outcome && response.outcome[0] == "EXTAXCHECKKO"){
        const savedModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData('Invalid NIF/DNI'));
        savedModal.modal.enableClickBackground = false;
      }
      if(response.outcome && response.outcome[0] == "EXTAXCHECKKODOC"){
        this.translateService.translate('_IIAB_._ANAG_._TAX_ID_DIFFERENT_FROM_DOCUMENT_ID_').subscribe(stringMsg => {
          const savedModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(stringMsg));
          savedModal.modal.enableClickBackground = false;
        });
      }
    }, _ => {
      console.log('ERRORE CHIAMATA salva soggetto');
    });
  }
  /*
  setValidators() {
    this.phoneNumberIntPrefix.setValidators(Validators.required);
    this.phoneNumberPrefix.setValidators([Validators.required, this.numberValidator]);
    this.phoneNumber.setValidators([Validators.required, this.numberValidator]);
    this.phoneNumberIntPrefix.updateValueAndValidity();
    this.phoneNumberPrefix.updateValueAndValidity();
    this.phoneNumber.updateValueAndValidity();
    this.email.setValidators([Validators.required, this.emailValidator]);
    this.email.updateValueAndValidity();
  }

  resetValidators() {
    this.phoneNumberIntPrefix.clearValidators();
    this.phoneNumberIntPrefix.updateValueAndValidity();
    this.phoneNumberPrefix.setValidators(this.numberValidator);
    this.phoneNumberPrefix.updateValueAndValidity();
    this.phoneNumber.setValidators(this.numberValidator);
    this.phoneNumber.updateValueAndValidity();
    this.email.setValidators(this.emailValidator);
    this.email.updateValueAndValidity();
  }

   */

  updateContacts() {
    this.updateContactsData(this.contactsForm.getRawValue());
    // this.notifyFormValidationErrorMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_');
  }

  updateContactsData(formData: any) {
    this.inputParty.mobilePhone = this.inputParty.mobilePhone ? this.inputParty.mobilePhone : [];
    this.inputParty.mobilePhone[0] = this.inputParty.mobilePhone[0] ? this.inputParty.mobilePhone[0] : new AnagPhoneNumber();
    this.inputParty.mobilePhone[0].internationalPrefix = formData.phoneNumberIntPrefix ? formData.phoneNumberIntPrefix : null;
    if (this.isPrefixHidden) {
      this.inputParty.mobilePhone[0].localPrefix = formData.phoneNumberPrefix;
    }
    this.inputParty.mobilePhone[0].number = formData.phoneNumber;
    this.inputParty.emails = this.inputParty.emails ? this.inputParty.emails : [];
    this.inputParty.emails[0] = formData.email;
    this.inputParty.emails = this.inputParty.emails.filter((e): e is Exclude<typeof e, null> => e !== null);
    this.inputParty.emails = this.inputParty.emails.filter((e): e is Exclude<typeof e, ''> => e !== '');
    if (this.inputParty.emails[0]) { formData.email = this.inputParty.emails[0]; }
  }

  notifyFormValidationErrorMsg(errorMsg) {
    this.pushMessageHandler.clearTag('error-tag');
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
      tag: 'error-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  manageInputParty() {
    if (this.inputParty) {
      if (this.inputParty.mobilePhone && this.inputParty.mobilePhone[0]) {
        this.setControlValue(this.phoneNumberIntPrefix, this.inputParty.mobilePhone[0].internationalPrefix);
        if (this.isPrefixHidden) {
          this.setControlValue(this.phoneNumberPrefix, this.inputParty.mobilePhone[0].localPrefix);
        }
        this.setControlValue(this.phoneNumber, this.inputParty.mobilePhone[0].number);
      }
      if (this.inputParty.emails && this.inputParty.emails[0]) {
        this.email.setValue(this.inputParty.emails[0]);
      }
      if (this.inputParty.partyKey.length > 0) {
        this.country = this.inputParty.partyKey[0].country;
      }
    }
  }

  setControlValue(control: any, value: any) {
    if (control && value) {
      control.setValue(value);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get documentTypes() {
    return this.anagStorage.getEnumsByCode(DOCUMENT_TYPE_ENUM).filter(item => item.codice === '1');
  }

  private subscribeToValueChanges(controlName: string): void {
    const control: AbstractControl = this.contactsForm.get(controlName);
    if (control) {
      control.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(intPrefix => {
          const prefix = this.internationalPrefixes?.find(x => x.code === intPrefix);
          if (prefix) {
            this.anagPortalUtilityService.setFormatNumber(controlName, prefix.format);
            this.anagPortalUtilityService.applyFormat(this.contactsForm, this.list[controlName], this.contactsForm.controls[this.list[controlName]].value, prefix.format);
          }
        });
    }
  }

  removeFormat(controlName: string , value: string) {
    this.anagPortalUtilityService.removeFormat(this.contactsForm, controlName, value);
  }

  applyFormat(controlName: string, value: string, controlNameFormat: string) {
    this.anagPortalUtilityService.applyFormat(this.contactsForm, controlName, value, this.anagPortalUtilityService.getFormatNumber(controlNameFormat));
  }

}
