<div class="form-group" [formGroup]="formGroup">
  <div *ngIf="!!label">
    <label class="label-core" translate>{{ label }}</label>
    <lpc-required *ngIf="required" required="required"></lpc-required>
  </div>
  <ng-container *ngIf="tresholdInputType === 'number'">
    <input class="form-control" type="number" formControlName="percent" (blur)="roundValue()"
      data-qa="threshold_percentage">
  </ng-container>
  <ng-container *ngIf="tresholdInputType === 'select'">
    <ng-select class="ng-select factor" formControlName="percent">
      <ng-option *ngFor="let option of options" [value]="option">{{option}}</ng-option>
  </ng-select>
  </ng-container>
</div>
