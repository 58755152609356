<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" id="dates" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step
      [inputFields]="dateDefinitions"
      [formControlName]="'dates'"
      (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()"
      [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step *ngIf="hasListProductFactor" label="lpc_PolicyData" feFieldId="factors" [errors]="errors" id="factors" fieldId="factors"
    (next)="onNext($event)">
    <div formGroupName="factors" class="row">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateFactors($event)"
        [form]="formGroup.controls['factors']" [factors]="listProductFactor" [summary]="atSummaryProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="questsFin"
    [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')"
    *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')"
    fieldId="questsFin"
    label="lpc_financial_questionnaire_step"
    (next)="onNext($event)">
  <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
             [questionnairesCode]="getQuestMap('FIN')" [type]="'FIN'"
             [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
             (loaderQuest)="loaderQuestManager($event)"
             (loaded)="countQuest($event)"
             #financialQuestionnaire></lpc-questionnaire>
  </lpc-step>

  <lpc-step *ngIf="hasListGoodsFactor" label="lpc_asset_factors" [errors]="errors" feFieldId="assetFactors" id="assetFactors" fieldId="assetFactors" (next)="onNext($event)">
    <div formGroupName="assetFactors" class="row">
      <lpc-factor-adapter #goodsFactorAdapter (changeValEmitter)="updateGoodsFactors($event)"
        [form]="formGroup.controls['assetFactors']" [factors]="listGoodsFactor" [summary]="atSummaryGoodsFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="questsHlt" [hidden]="!validQuestsCode.get('HLT')" [jump]="!validQuestsCode.get('HLT')" [feErrors]="errors" *ngIf="showSectionQuestHltAfterInit && showQuestionnaireByType('HLT')" feFieldId="questsHlt" fieldId="questsHlt" label="lpc_health_questionnaire" (next)="onNext('questsHlt', false)">
    <lpc-questionnaire [active]="!isAfterId('questsHlt')" [key]="composeKey(key, 'questsHlt')"
                       [questionnairesCode]="getQuestMap('HLT')" [type]="'HLT'"
                       (loaded)="countQuest($event)"
                       [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       [date]="effectiveDate"
                       (loaderQuest)="loaderQuestManager($event)"
                       #healthQuestionnaire></lpc-questionnaire>
  </lpc-step>

  <lpc-step
    id="quotation"
    [enableAuth]="isAuth"
    label="lpc_quotation_step"
    [errors]="errors"
    fieldId="quotation"
    (next)="onNext($event)"
    [disableNext]="disableNext">
    <lpc-quotation-control
      [disableQuote]="disableQuote"
      (quote)="recalculate(false)"
      [quotationDefinition]="quotationDefinition"
      (openQuotationModal)="openQuotationModal($event)"
      (openFormuleModal)="openFormuleModal($event)"
      (openWarrantiesModal)="openWarrantiesModal($event)"
      [showFirstInstallment]="false"
      [showOpenQuotationModalButton]="showOpenQuotationModalButton"
      [showopenWarrantiesModalButton]="showopenWarrantiesModalButton">
    </lpc-quotation-control>
    <div class="row">
      <lpc-vprin-unit-table
        formControlName="risks"
        *ngIf="!!risksForUnits?.length"
        (saveSelection)="selectionUnit()"
        [risks]="risksForUnits"
        [openSelectedUnits]="false"
        (updateUnitTable)="updateUnit($event)">
      </lpc-vprin-unit-table>
    </div>
    <div *ngIf="showFormuleModal">
      <lpc-formule-modal formControlName="quotation" (save)="saveFormulas($event)"
        (closeModal)="closeFormuleModal($event)" *ngIf="!!risks" [riskFormulasObject]="risks">
      </lpc-formule-modal>
    </div>
  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" [enableAuth]="isAuth" id="quests" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')"
  fieldId="quests" [errors]="errors" label="lpc_avc_questionnaire" (next)="onNext($event)"
  (confirmAccept)="onNext($event)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-questionnaire
                       [active]="!isAfterId('quests')" [key]="composeKey(key, 'quests')"
                       [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #avcQuestionnaire></lpc-questionnaire>
  </lpc-step>

  <lpc-step id="beneficiaries" [enableAuth]="isAuth" *ngIf="hasBeneficiaryDefinitions"
           label="lpc_life_detail_beneficiaries" [errors]="errors"
           feFieldId="beneficiary" [feErrors]="feErrors"
           fieldId="beneficiaries" (next)="onNext($event)"
           (confirmAccept)="onNext($event)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div *ngIf="isActiveStep('beneficiaries')">
      <div formGroupName="beneficiaries">
        <div class="row display-flex">
            <div *ngFor="let beneficiaryDefinition of beneficiaryDefinitions" class="col-lg-6">
              <div>
                  <lpc-beneficiary
                    [formControlName]="'b' + beneficiaryDefinition.code"
                    [beneficiaryDefinition]="beneficiaryDefinition">
                  </lpc-beneficiary>
              </div>
            </div>
        </div>
      </div>
    </div>
      <div *ngIf="!isActiveStep('beneficiaries') || !!modalMessage" class="row">
        <div class="col-lg-12">
          <div class="col-lg-6" *ngFor="let benef of selectedBeneficiariesForSummary">
            <span>{{benef.benefType}}: </span>
            <span>{{benef.benefLabel}}</span>
          </div>
        </div>
      </div>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
            (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons
    (back)="closeCard()"
    [nextVisible]="false"
    [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>
