import {SharedComponentsTranslationKeys} from './translation.keys';

export const FR: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'FR_Add',
      ADD_DOCUMENT: 'FR_Add document',
      ARCHIVE: 'FR_Archive',
      ARCHIVED: 'FR_Archived',
      ARCHIVE_DOC: 'FR_Archive document',
      ARCHIVING_USER: 'FR_Archiving User',
      CANCEL: 'FR_Cancel',
      CLOSE: 'FR_Close',
      CODE: 'FR_Code',
      CONFIRM: 'FR_Confirm',
      CREATED: 'FR_Created',
      DELETE_DOC: 'FR_Delete document',
      DELIVERED: 'FR_Delivered',
      DELIVERY_DOC: 'FR_Delivery document',
      DELIVERY_ADDRESS: 'FR_Delivery Address',
      DELIVERY_PARTY: 'FR_Delivery Party',
      DELIVERY_USER: 'FR_Delivery User',
      DELIVERY_TYPE: 'FR_Delivery Type',
      DESCRIPTION: 'FR_Description',
      DOCUMENTS_MANAGER: 'FR_Documents Manager',
      DOCUMENT_TYPE: 'FR_Document type',
      DOCUMENT_VALIDATION_MESSAGE: 'FR_Proceed with the validation of the document?',
      DOWNLOAD: 'FR_Download',
      DOWNLOAD_DOC: 'FR_Download document',
      EDIT: 'FR_Edit',
      FILE: 'FR_File',
      ERROR: 'FR_Error',
      INPUT_DOCUMENTS: 'FR_Input Documents',
      LOAD: 'FR_LOAD',
      NO: 'FR_No',
      NOTE: 'FR_Note',
      NO_FILES: 'FR_No files',
      NO_SIGN_NEEDED: 'FR_Document does not require signature',
      NOT_DELIVERED: 'FR_Not delivered',
      NOT_SIGNED: 'FR_Not signed',
      NUMBER_OF_FILES_INSERTED: 'FR_{{ filesLength }} of {{ fileMax }} files inserted',
      OUTBOUND_DOC_TITLE: 'FR_Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'FR_Proposal documents nr. {{ proposalNumber }} of movement {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'FR_Output Documents',
      PENDING_SIGNATURE_TOOLTIP: 'FR_Document waiting for signature. Click to stop the process',
      REFRESH: 'FR_Refresh',
      SAVE: 'FR_SAVE',
      SEND: 'FR_Send',
      SIGN: 'FR_Sign',
      SIGN_DOCUMENT: 'FR_Sign document',
      SIGNED: 'FR_Signed',
      SIGNED_DOCUMENT: 'FR_Document signed',
      SUBJECT_SENDING_DATE: 'FR_Sending date to registry subject',
      STATE: 'FR_Status',
      TYPE: 'FR_Type',
      TO_BE_GENERATED: 'FR_To be generated',
      TO_BE_UPLOADED: 'FR_To be uploaded',
      UPDATE_FILE: 'FR_Update file',
      UPLOAD: 'FR_UPLOAD',
      UPLOADED: 'FR_Uploaded',
      UPLOAD_NEW_DOCUMENT: 'FR_Upload new document',
      UPLOADING_USER: 'FR_Uploading User',
      VALIDATING_USER: 'FR_Validation User',
      VALIDATED: 'FR_Validated',
      VALIDATION: 'FR_Validation',
      VALIDATION_DOCUMENT: 'FR_Validation document',
      YES: 'FR_Yes',
      NO_DOCUMENTS_CONFIGURED: 'FR_There are no configured documents for this operation'
    }
};
