import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { Factor, Instance, Unit } from './../../../models/policy.model';
import {PolicyService} from './../../../services/policy-service';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { ExtensionProperty } from './../../../enum/life-issue.enum';

interface UnitOpen {
  open: boolean;
  subUnits: Map<string, boolean>;
}

@Component({
  selector: 'lic-units-select',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnitsComponent implements OnInit, OnChanges {
  NO_QUOTE_VALUE = '- - -';

  @Input() unitsForm: UntypedFormGroup;
  @Input() lifeRiskInsuredMap: Map<string, any>;
  @Output() update = new EventEmitter<any>();
  @Input() instancesArray: Instance[];

  // da settare a true se si vuole aprire (all'onInit e alla selezione) le unit selezionate
  @Input() openSelectedUnits = false;

  @Output() eventPropagation = new EventEmitter<any>();
  @Output() undo = new EventEmitter<any>();

  whichModal = null;
  oldProposal = null;

  @Input() quotatedValues: Map<string, any>;

  private $openUnits: Map<string, any> = new Map<string, any>();
  public get openUnits(): Map<string, any> {
    return this.$openUnits;
  }

  private $savedUnitBodyStatus: any = {};

  constructor(
    protected policyService: PolicyService,
    protected translateService: TranslationWrapperService,
    protected rgiFormatter: RgiCountryLayerNumberFormatPipe
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.instancesArray.firstChange) {
      this.$openUnits = this.getInitialOpenStructure(changes.instancesArray.currentValue);
    }
    if (!!changes.instancesArray) {
      const factors: Factor[] = this.getFactorsBy(
        this.$savedUnitBodyStatus.instanceName,
        this.$savedUnitBodyStatus.sectionCode,
        this.$savedUnitBodyStatus.unitCode,
        this.$savedUnitBodyStatus.subUnitCode
      );
      if (
        (this.openSelectedUnits && this.$savedUnitBodyStatus.selection && factors?.length > 0) ||
        factors?.length > 0 && (this.isAnyFactorCompulsoryAndEnabled(factors) && this.$savedUnitBodyStatus.open)
      ) {
        this.$openUnitBody(
          this.$savedUnitBodyStatus.instanceName,
          this.$savedUnitBodyStatus.sectionCode,
          this.$savedUnitBodyStatus.unitCode,
          this.$savedUnitBodyStatus.subUnitCode
        );
      } else if (!this.$savedUnitBodyStatus.open) {
        this.closeUnitBody(
          this.$savedUnitBodyStatus.instanceName,
          this.$savedUnitBodyStatus.sectionCode,
          this.$savedUnitBodyStatus.unitCode,
          this.$savedUnitBodyStatus.subUnitCode
        );
      }
    }
  }

  /* metodo per prendere tutte le Section del prodotto di un solo assicurato */
  // TODO in futuro il metodo delle istances per la gestione multiassicurato
  buildForm() {
    if (!!this.instancesArray && this.instancesArray.length > 0) {
      this.instancesArray.forEach((instance) => {
        this.unitsForm.addControl(instance.name, new UntypedFormGroup({}));
        const instanceForm = this.unitsForm.get(instance.name) as UntypedFormGroup;
        if (!!instance.sections && instance.sections.length > 0) {
          instance.sections.forEach((section) => {
            instanceForm.addControl(section.code, new UntypedFormGroup({}));
            const sectionForm = instanceForm.get(section.code) as UntypedFormGroup;
            if (!!section.units && section.units.length > 0) {
              section.units.forEach((unit) => {
                const factorsForm = new UntypedFormGroup({});
                if (unit.selection) {
                  unit.instances[0].factors.forEach((fat) => {
                    this.setFactor(fat, factorsForm);
                  });
                }
                sectionForm.addControl(unit.code, new UntypedFormGroup({
                  factors: factorsForm,
                  selection: new UntypedFormControl({value: unit.selection, disabled: !unit.enable}),
                  // riskCode: new FormControl(unit.riskCode),
                  subUnits: new UntypedFormGroup({})
                }));
                if (!unit.enable) {
                  (sectionForm.get(unit.code).get('selection') as UntypedFormControl).disable();
                }
                // SUB UNITS
                const baseForm = sectionForm.get(unit.code) as UntypedFormGroup;
                if (!!unit.subUnits && unit.subUnits.length > 0) {
                  unit.subUnits.forEach((subUnit) => {
                    const subFactorsForm = new UntypedFormGroup({});
                    if (subUnit.selection) {
                      subUnit.instances[0].factors.forEach((fat) => {
                        this.setFactor(fat, subFactorsForm);
                      });
                    }
                    (baseForm.get('subUnits') as UntypedFormGroup).addControl(subUnit.code, new UntypedFormGroup({
                      factors: subFactorsForm,
                      selection: new UntypedFormControl({value: subUnit.selection, disabled: !subUnit.enable}),
                      // riskCode: new FormControl(subUnit.riskCode)
                    }));
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  updateForm() {
    this.instancesArray.forEach((instance) => {
      const instanceForm = this.unitsForm.get(instance.name) as UntypedFormGroup;
      instance.sections.forEach((section) => {
        const sectionForm = instanceForm.get(section.code) as UntypedFormGroup;
        section.units.forEach((unit) => {
          const factorsForm = sectionForm.get(unit.code).get('factors') as UntypedFormGroup;
          if (unit.selection) {
            unit.instances[0].factors.forEach((fat) => {
              this.setFactor(fat, factorsForm);
            });
          }
          sectionForm.get(unit.code).get('selection').setValue(unit.selection);
          // SUB UNITS
          const baseForm = sectionForm.get(unit.code) as UntypedFormGroup;
          if (!!unit.subUnits && unit.subUnits.length > 0) {
            unit.subUnits.forEach((subUnit) => {
              const subFactorsForm = baseForm.get('subUnits').get(subUnit.code).get('factors') as UntypedFormGroup;
              if (subUnit.selection) {
                subUnit.instances[0].factors.forEach((fat) => {
                  this.setFactor(fat, subFactorsForm);
                });
              }
              (baseForm.get('subUnits') as UntypedFormGroup).get(subUnit.code).get('selection').setValue(subUnit.selection);
            });
          }
        });
      });
    });
  }

  /* metodo con il quale si associa la tipologia di Unit in base a quale
  parametro tra accessory, base, complementary ha come value TRUE. */
  printDescription(u): string {
    if (u.base === true) {
      return this.translateService.getImmediate('lic_Base');
    } else if (u.accessory === true) {
      return this.translateService.getImmediate('lic_Accessory');
    } else if (u.complementary === true) {
      return this.translateService.getImmediate('lic_Complementary');
    } else if (!u.life) {
      return null;
    } else {
      return undefined;
    }
  }

  saveAll(unit: Unit, instanceName: string, sectionCode: string, unitCode: string, subUnitCode: string = null) {
    let form: AbstractControl;
    if (!!subUnitCode) {
      form = this.unitsForm.get(instanceName).get(sectionCode).get(unitCode).get('subUnits').get(subUnitCode);
    } else {
      form = this.unitsForm.get(instanceName).get(sectionCode).get(unitCode);
    }
    if (unit.enable) {
      if (!form.getRawValue().selection) {
        unit.instances[0].factors.forEach((fat) => {
          this.setFactor(fat, form.get('factors'));
        });
      } else {
        Object.keys((form.get('factors') as UntypedFormGroup).controls).forEach((key) => {
          (form.get('factors') as UntypedFormGroup).removeControl(key);
        });
      }
      this.saveForUnitBody(!unit.selection, instanceName, sectionCode, unitCode, subUnitCode);

      this.update.emit(unit.code);
    }
  }

  setFactor(config, group) {
    if (!group.contains(config.code)) {
      group.addControl(config.code, new UntypedFormControl());
    }
    if (config.value === null || config.value === '' || config.value === '-1') {
      group.controls[config.code].setValue(null);
    } else {
      group.controls[config.code].setValue(config.value);
    }
    if (config.compulsory) {
      group.controls[config.code].setValidators(Validators.required);
    }
    if (config.modifiable) {
      group.controls[config.code].enable();
    } else {
      group.controls[config.code].disable();
    }
  }

  changeFactor(factors) {
    console.log(factors);
    this.update.emit();
  }

  openModal(unitCode: string) {
    this.whichModal = unitCode;
    this.oldProposal =  JSON.parse(JSON.stringify(this.policyService.mainProposal));
  }

  undoChanges() {
    this.whichModal = null;
    this.policyService.mainProposal = this.oldProposal;
    this.undo.emit();

    this.updateForm();
    this.unitsForm.markAsPristine();
  }

  checkIconView(form) {
    let isFilled = false;
    if (Object.keys(form.get('factors').controls).length > 0) {
      isFilled = true;
    } else {
      Object.keys(form.get('subUnits').controls).forEach((subUnit) => {
        if (Object.keys(form.get('subUnits').get(subUnit).get('factors').controls).length > 0) {
          isFilled = true;
        }
      });
    }

    return isFilled;
  }

  getRataFirma(riskCode: string) {
    let value = this.quotatedValues.get(riskCode) != null ? this.quotatedValues.get(riskCode).rataFirma : this.NO_QUOTE_VALUE;

    value = String(value).replace(',', '.');

    const decimal = 2;
    if (decimal > 0) {
      value = this.getFormattedValue(value, decimal);
    }

    return value;
  }

  protected getFormattedValue(value: any, decimal: number) {
    if (value !== this.NO_QUOTE_VALUE) {
      const fraction = '.' + decimal + '-' + decimal;
      const options: Intl.NumberFormatOptions = this.policyService.getFormatterOptionsWithDecimal('currency', fraction);
      value = this.rgiFormatter.transform(value, this.policyService.currentLocale, options);
    }
    return value;
  }

  getPrestazione(riskCode: string) {
    let value = this.quotatedValues.get(riskCode) != null ? this.quotatedValues.get(riskCode).prestazione : this.NO_QUOTE_VALUE;
    value = String(value).replace(',', '.');
    const decimal = 2;
    if (decimal > 0) {
      value = this.getFormattedValue(value, decimal);
    }

    return value;
  }

  // se non ci sono fattori di unit modificabili all'interno della modale, il tasto conferma non deve essere visualizzato
  isConfirmVisible(instCode: string, sectCode: string, unitCode: string) {
    const baseUnitForm = this.unitsForm.get(instCode).get(sectCode).get(unitCode).get('subUnits') as UntypedFormGroup;
    let isVisible = false;

    Object.keys((this.unitsForm.get(instCode).get(sectCode).get(unitCode).get('factors') as UntypedFormGroup).controls).forEach((fact) => {
      if (!this.unitsForm.get(instCode).get(sectCode).get(unitCode).get('factors').get(fact).disabled) {
        isVisible = true;
      }
    });

    if (isVisible) {
      return true;
    }

    Object.keys(baseUnitForm.controls).forEach((subUnit) => {
      const subunitForm = baseUnitForm.get(subUnit).get('factors') as UntypedFormGroup;
      Object.keys(subunitForm.controls).forEach((fact) => {
        if (!subunitForm.get(fact).disabled) {
          isVisible = true;
        }
      });
    });

    return isVisible;
  }

  isTCMorCPI(unit: Unit) {
    const productType = this.policyService.mainProposal.quote.product.type.code;
    return (productType === 'TCM' || productType === 'CPI') ? true : !unit.base;
  }

  isUnitBodyOpen(instance: string, sect: string, unit: string, subUnit: string = null) {
    if (subUnit) {
      return (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).subUnits.get(subUnit).valueOf();
    } else {
      return (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).open;
    }
    /*if (!!subUnit) {
      return this.unitsForm.get(instance).get(sect).get(unit).get('subUnits').get(subUnit).get('selection').value;
    } else {
      return this.unitsForm.get(instance).get(sect).get(unit).get('selection').value;
    }*/
  }

  /* openUnitBody(instance: string, sect: string, unit: string, subUnit: string = null) {
    const unt: Unit = this.getUnitBy(instance, sect, unit, subUnit);
    if (unt.selection && !!unt.instances[0].factors.length) {
      if (subUnit) {
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).subUnits.set(subUnit, true);
      } else {
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).open = true;
      }
    }
  } */

  $openUnitBody(instance: string, sect: string, unit: string, subUnit: string = null) {
    const unt: Unit = this.getUnitBy(instance, sect, unit, subUnit);
    if (!!unt.instances[0].factors.length) {
      if (subUnit) {
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).subUnits.set(subUnit, true);
      } else {
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).open = true;
      }
    }
  }

  closeUnitBody(instance: string, sect: string, unit: string, subUnit: string = null) {
    if (!!instance && !!sect && !!unit) {
      if (subUnit) {
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).subUnits.set(subUnit, false);
      } else {
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).open = false;
      }
    }
  }

  closeOrOpenUnitBody(instance: string, sect: string, unit: string, subUnit: string = null) {
    if (!!instance && !!sect && !!unit) {
      if (subUnit) {
        const status = (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).subUnits.get(subUnit);
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).subUnits.set(subUnit, !status);
      } else {
        const status = (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).open;
        (this.$openUnits.get(instance).get(sect).get(unit) as UnitOpen).open = !status;
      }
    }
  }

  private isAnyFactorCompulsoryAndEnabled(factors: Factor[]): boolean {
    return factors.some(factor => factor.compulsory && factor.modifiable);
  }

  private saveForUnitBody(
    open: boolean,
    instanceName: string, sectionCode: string, unitCode: string, subUnitCode: string
  ) {
    this.$savedUnitBodyStatus = {
      open,
      instanceName,
      sectionCode,
      unitCode,
      subUnitCode
    };
  }

  private getUnitBy(instanceName: string, sectionCode: string, unitCode: string, subUnitCode: string = null): Unit | undefined {
    try {
      const unit: Unit = this.instancesArray
        .find(instance => instance.name === instanceName).sections
        .find(section => section.code === sectionCode).units
        .find(unt => unt.code === unitCode);
      if (!!subUnitCode) {
        return unit.subUnits.find(subUnt => subUnt.code === subUnitCode);
      } else {
        return unit;
      }
    } catch (e) {
      return undefined;
    }
  }

  public isUnitLife(instanceName: string, sectionCode: string, unitCode: string, subUnitCode: string = null): boolean {
    const unit: Unit = this.getUnitBy(instanceName, sectionCode, unitCode, subUnitCode);
    if (!!unit) {
      return unit.life;
    }
    return false;
  }

  public hasFactorsBy(instanceName: string, sectionCode: string, unitCode: string, subUnitCode: string = null): boolean {
    return !!this.getFactorsBy(instanceName, sectionCode, unitCode, subUnitCode).length;
  }

  private getFactorsBy(instanceName: string, sectionCode: string, unitCode: string, subUnitCode: string = null): Factor[] {
    try {
      return this.getUnitBy(instanceName, sectionCode, unitCode, subUnitCode)?.instances[0]?.factors;
    } catch (e) {
      return [];
    }
  }

  private getInitialOpenStructure(instances: Instance[]): Map<string, any> {
    const openStructure = new Map<string, any>();
    if (!!instances && instances.length > 0) {
      instances.forEach(instance => {
        openStructure.set(instance.name, new Map<string, Map<string, any>>());
        if (!!instance.sections && instance.sections.length > 0) {
          instance.sections.forEach(section => {
            openStructure.get(instance.name).set(section.code, new Map<string, UnitOpen>());
            if (!!section.units && section.units.length > 0) {
              section.units.forEach(unit => {
                const factors = this.getFactorsBy(instance.name, section.code, unit.code);
                openStructure.get(instance.name).get(section.code).set(unit.code, {
                  open: (
                    (this.openSelectedUnits && unit.selection && factors && factors.length > 0) ||
                    this.isAnyFactorCompulsoryAndEnabled(factors) &&
                    this.getUnitBy(instance.name, section.code, unit.code).selection
                  ),
                  subUnits: new Map<string, boolean>()
                });
                unit?.subUnits?.forEach(subUnit => {
                  const subFactors = this.getFactorsBy(instance.name, section.code, unit.code, subUnit.code);
                  (openStructure.get(instance.name).get(section.code).get(unit.code) as UnitOpen).subUnits.set(subUnit.code,
                    (this.openSelectedUnits && subUnit.selection && subFactors && subFactors.length > 0) ||
                    this.isAnyFactorCompulsoryAndEnabled(subFactors) &&
                    this.getUnitBy(instance.name, section.code, unit.code, subUnit.code).selection
                  );
                });
              });
            }
          });
        }
      });
    }
    return openStructure;
  }


  accordionToggled(instance, sect, unit, subUnit = null) {
    if (!this.hasFactorsBy(instance.name, sect.code, unit.code, subUnit?.code)) {
      return;
    }
    this.closeOrOpenUnitBody(instance.name, sect.code, unit.code, subUnit?.code);
  }

  hasNoUnitBodyContent(instance, sect, unit: Unit, subUnit: Unit[] = null) {
    return this.isUnitBodyOpen(instance.name, sect.code, unit.code) && !subUnit?.length && !this.hasFactorsBy(instance.name, sect.code, unit.code);
  }

  isDeathBenefitActive(riskCode) {
    return !!this.policyService.getExtensionProperty(`${ExtensionProperty.DEATHBENEFIT_PREFIX};${riskCode}`)?.valore;
  }

  getDeathBenefit(riskCode, transCode = '') {
    const decimal = 2;
    const value = this.policyService.getExtensionProperty(`${ExtensionProperty.DEATHBENEFIT_PREFIX};${riskCode}${transCode}`)?.valore;
    return (!isNaN(Number(value)) && this.getFormattedValue(value, decimal)) || this.NO_QUOTE_VALUE
  }

}
