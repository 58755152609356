<div *ngIf="enabled && !openOutOfStepper">
    <ol>
        <div class="top-banner" *ngFor="let payment of potPayFE.paymentList; let indexPay = index;">
            <li>
                <div class="col-md-4 col-sm-6 col-xs-12">{{ '_CLAIMS_._PAYMENT_TYPE' | translate }}: {{payment.paymentTypeDescr}}
                </div>
                <div class="col-md-4 col-sm-6 col-xs-12">{{ '_CLAIMS_._RECEIPT_TYPE' | translate }}:
                    {{payment.receiptTypeDescr}}</div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <span style="float: right;">{{ '_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate }}: €
                        {{payment.totalAmountNet}}</span>
                </div>
            </li>
            <div class="top-banner" *ngIf="paymentMap.get(payment.id)">
                <div class="card shadow table-white">
                    <div class="row">
                        <div class="col-xs-2" style="font-size: 14px;">
                            <div class="row" style="margin-left: 5px;">
                                <h5 class="text-info bold">{{ '_CLAIMS_._METHOD_OF_PAYMENT' | translate }}:</h5>
                                <select class="core-select form-control" id="typeOfPay" name="typeOfPay"
                                    (change)="reloadDetails(payment, indexPay)"
                                    [(ngModel)]="paymentMap.get(payment.id).methodId" [disabled]="payment.currencyCode!=null && payment.currencyCode!='EUR'">
                                    <option></option>
                                    <option *ngFor="let aPayType of paymentMethod; " value={{aPayType.idObject}}>
                                        {{aPayType.description}}</option>
                                </select>
                                <span
                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex;" *ngIf="needsIban[indexPay]" class="row">
                <table class="card shadow table-white">
                    <tr>
                        <td>
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._IBAN' | translate }}</label>
                            <pnc-required required="true"></pnc-required>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!enabled" class="inputPay"
                                (change)="ibanPaymentCheckData($event, payment.id)" type="text" required
                                style="width: 210px;" />
                        </td>
                        <td>
                            <label ng-bind="label" class="labelPay" style="margin-left: 20px;"
                                *ngIf="visulizeLabelIban(payment)">{{ '_CLAIMS_._IBAN_ON_SUBJECT' | translate }}: </label>
                        </td>
                        <td style="width: 340px;">
                            <div *ngFor="let payee of payment.listPayee;">
                                <div *ngIf="payee.primary && payee.ibans && payee.ibans.length > 0;else zeroIban">
                                    <select class="core-select form-control" id="ibans" name="ibans"
                                        [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!enabled"
                                        (change)="ibanPaymentCheckData($event, payment.id)" style="width: 400px;">
                                        <option></option>
                                        <option *ngFor="let iban of payment.listPayee[0].ibans" value={{iban.iban}}>
                                            {{iban.iban}} - {{iban.dataPagamento | translate }}</option>
                                    </select>
                                    <span
                                        class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                                </div>
                                <ng-template #zeroIban>
                                    <p style="font-size: 12px; padding-left: 2px;">{{'_CLAIMS_._MESSAGE_._NO_IBAN_PRESENT_OF_THE_PAYEE' |
                                        translate}}</p>
                                </ng-template>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="labelPay">{{ '_CLAIMS_._ACCOUNT_HOLDER' | translate }}: </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).accountHolder" [disabled]="!enabled"
                                class="inputPay" type="text" style="width: 210px;"></td>
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <td class="labelPay">{{ '_CLAIMS_._BANK_DESCRIPTION' | translate }}: </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).bankDescription" [disabled]="!enabled"
                                class="inputPay" type="text" style="width: 210px;" maxlength="50"></td>
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <td class="labelPay">{{ '_CLAIMS_._BANK_ADDRESS' | translate }}: </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).bankAddress" [disabled]="!enabled"
                                class="inputPay" type="text" style="width: 210px;"></td>
                        <td colspan="2"></td>
                    </tr>

                  <!--TRANSFER REASON // CAUSALE BONIFICO-->
                  <!--NUM SX - EDITABLE INPUT -->
                  <!--                  if disabled set directly to numSx-->
                  <tr *ngIf="this.needsReason[indexPay]">
                    <td class="labelPay">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate }}: </td>
                    <td> <span style="display: inline-flex"> <span *ngIf="this.isEnabledEditTransferDescription"  style="color:gray; font-size: 95%">
                        {{transferDescrFixMap.get(payment.id)}}</span>
                      <textarea [(ngModel)]="paymentMap.get(payment.id).transferDescription" [disabled]="!enabled || !this.isEnabledEditTransferDescription"
                        placeholder="{{this.isEnabledEditTransferDescription ? '' : (transferDescrFixMap.get(payment.id))}}"        class="inputPay" type="text" style="width: 600px; margin-left: 10px" ></textarea> </span></td>
                    <!--character counter-->
                    <td colspan="2" style="padding-left: 10px;" [style.color]="this.getTransferDescriptionLength(paymentMap.get(payment.id).transferDescription, payment.id) > 140 ? 'red' : 'black'">{{this.getTransferDescriptionLength(paymentMap.get(payment.id).transferDescription, payment.id)}}/140</td>
                  </tr>
                </table>

            </div>
            <div *ngIf="needsIbanInternational[indexPay]" class="row">
                <table class="card shadow table-white">

                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label" >{{ '_CLAIMS_._IBAN' | translate }}</label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!enabled" class="inputPay"
                                   (keyup)="ibanForeignPaymentCheckData($event, payment.id)" type="text"
                        />
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;" class="labelPay">{{ '_CLAIMS_._SWIFT' | translate }}: </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).swift"  (keyup)="internationPaymentCheckData(payment.id)"
                                   [disabled]="!enabled" class="inputPay"
                                   type="text" required>
                            <pnc-required required="true"></pnc-required>
                        </td>
                    </tr>
                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FOREIGN_BANK_ACCOUNT' | translate
                                }}: </label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).foreignBankAccount" [disabled]="!enabled"
                                   class="inputPay" (keyup)="internationPaymentCheckData(payment.id)" type="text"
                                   required />
                            <pnc-required required="true"></pnc-required>
                        </td>
                        <td>
                            <label ng-bind="label" class="labelPay" style="margin-left: 20px;"
                                *ngIf="visulizeLabelIban(payment)">{{ '_CLAIMS_._FOREIGN_BANK_ACCOUNT_ON_SUBJECT' | translate }}: </label>
                        </td>
                        <td style="width: 340px;">
                            <div *ngFor="let payee of payment.listPayee;">
                                <div *ngIf="payee.primary && payee.swifts && payee.swifts.length > 0;else zeroIban">
                                    <select class="core-select form-control" id="swifts" name="swifts"
                                            [(ngModel)]="paymentMap.get(payment.id).foreignBankAccount" [disabled]="!enabled"
                                            (keyup)="internationPaymentCheckData(payment.id)" style="width: 400px;">
                                        <option></option>
                                        <option *ngFor="let swift of payment.listPayee[0].swifts" value={{swift.iban}}>
                                            {{swift.iban}} - {{swift.dataPagamento | translate }}</option>
                                    </select>
                                    <span
                                        class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                                </div>
                                <ng-template #zeroIban>
                                    <p style="font-size: 12px; padding-left: 2px;">{{'_CLAIMS_._MESSAGE_._NO_BANK_ACCOUNT_FOR_THE_SUBJECT' |
                                        translate}}</p>
                                </ng-template>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._BIC' | translate }}: </label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).bic" (keyup)="internationPaymentCheckData(payment.id)" [disabled]="!enabled"
                                   class="inputPay" type="text" required />
                            <pnc-required required="true"></pnc-required>
                        </td>
                    </tr>
                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label">{{ '_CLAIMS_._CURRENCY' | translate }}: </label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).currency" [disabled]="payment.currencyCode && payment.currencyCode!==null && payment.currencyCode!=='EUR'"
                                class="inputPay" maxlength="3" type="text"/>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;" class="labelPay">{{ '_CLAIMS_._UIC' | translate }}: </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).uic"  (keyup)="internationPaymentCheckData(payment.id)"
                                   [disabled]="!enabled" class="inputPay"
                                   type="text" required>
                            <pnc-required required="true"></pnc-required>
                        </td>
                    </tr>

                  <!--TRANSFER REASON // CAUSALE BONIFICO-->
                  <!--NUM SX - EDITABLE INPUT -->
                  <!--                  if disabled set directly to numSx-->
                  <tr *ngIf="this.needsReason[indexPay]">
                    <td class="labelPay">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate }}: </td>
                    <td> <span style="display: inline-flex"> <span *ngIf="this.isEnabledEditTransferDescription"  style="color:gray; font-size: 95%">
                    {{transferDescrFixMap.get(payment.id)}}</span>
                      <textarea [(ngModel)]="paymentMap.get(payment.id).transferDescription" [disabled]="!enabled || !this.isEnabledEditTransferDescription"
                                placeholder="{{this.isEnabledEditTransferDescription ? '' : (transferDescrFixMap.get(payment.id))}}"        class="inputPay" type="text" style="width: 600px; margin-left: 10px" ></textarea> </span></td>
                    <!--character counter-->
                    <td colspan="2" style="padding-left: 10px;" [style.color]="this.getTransferDescriptionLength(paymentMap.get(payment.id).transferDescription, payment.id) > 140 ? 'red' : 'black'">{{this.getTransferDescriptionLength(paymentMap.get(payment.id).transferDescription, payment.id)}}/140</td>
                  </tr>

                </table>
            </div>

            <div *ngIf="needsIbanInternationalEuro[indexPay]" class="row">
                <table class="card shadow table-white">

                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FOREIGN_BANK_ACCOUNT' | translate
                                }}: </label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).foreignBankAccount"  (keyup)="internationPaymentCheckDataEuro(payment.id)"
                                   [disabled]="!enabled"
                                   class="inputPay" type="text"
                        />
                        </td>
                    </tr>

                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label" >{{ '_CLAIMS_._IBAN' | translate }}</label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!enabled" class="inputPay"
                                   (keyup)="internationPaymentCheckDataEuro(payment.id)" type="text"
                                   required/>
                            <pnc-required required="true"></pnc-required>
                        <td>
                            <label ng-bind="label" class="labelPay" style="margin-left: 20px;"
                                   *ngIf="visulizeLabelIban(payment)">{{ '_CLAIMS_._IBAN_ON_SUBJECT' | translate }}: </label>
                        </td>
                        <td style="width: 340px;">
                            <div *ngFor="let payee of payment.listPayee;">
                                <div *ngIf="payee.primary && payee.sepas && payee.sepas.length > 0;else zeroIban">
                                    <select class="core-select form-control" id="sepas" name="sepas"
                                            [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!enabled"
                                            (keyup)="internationPaymentCheckDataEuro(payment.id)" style="width: 400px;">
                                        <option></option>
                                        <option *ngFor="let sepa of payment.listPayee[0].sepas" value={{sepa.iban}}>
                                            {{sepa.iban}} - {{sepa.dataPagamento | translate }}</option>
                                    </select>
                                    <span
                                            class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                                    </div>
                                    <ng-template #zeroIban>
                                        <p style="font-size: 12px; padding-left: 2px;">{{'_CLAIMS_._MESSAGE_._NO_IBAN_PRESENT_OF_THE_PAYEE' |
                                            translate}}</p>
                                    </ng-template>
                                </div>

                        </td>
                    </tr>
                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._BANK' | translate }}: </label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).bankDescription" [disabled]="!enabled"
                                class="inputPay" type="text"  maxlength="50"/>
                        </td>
                    </tr>
                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label">{{ '_CLAIMS_._REFERENCE' | translate }}: </label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).accountHolder" [disabled]="!enabled"
                                class="inputPay" type="text"/>
                        </td>
                    </tr>

                    <tr>
                        <td style="font-weight: bold;" class="labelPay">{{ '_CLAIMS_._UIC' | translate }}: </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).uic"  (keyup)="internationPaymentCheckDataEuro(payment.id)" [disabled]="!enabled" class="inputPay"
                                   type="text" required>
                            <pnc-required required="true"></pnc-required>
                        </td>

                    </tr>

                    <tr>
                        <td class="labelPay">
                            <label ng-bind="label">{{ '_CLAIMS_._CURRENCY' | translate }}: </label>
                        </td>
                        <td><input [(ngModel)]="paymentMap.get(payment.id).currency" (keyup)="internationPaymentCheckDataEuro(payment.id)" [disabled]="payment.currencyCode && payment.currencyCode!==null && payment.currencyCode!=='EUR'"
                                   class="inputPay" maxlength="3" type="text"/> <pnc-required required="true"></pnc-required>
                        </td>

                    </tr>

                  <!--TRANSFER REASON // CAUSALE BONIFICO-->
                  <!--NUM SX - EDITABLE INPUT -->
                  <!--                  if disabled set directly to numSx-->
                  <tr *ngIf="this.needsReason[indexPay]">
                    <td class="labelPay">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate }}: </td>
                    <td> <span style="display: inline-flex"> <span *ngIf="this.isEnabledEditTransferDescription"  style="color:gray; font-size: 95%">
                    {{transferDescrFixMap.get(payment.id)}}</span>
                      <textarea [(ngModel)]="paymentMap.get(payment.id).transferDescription" [disabled]="!enabled || !this.isEnabledEditTransferDescription"
                                placeholder="{{this.isEnabledEditTransferDescription ? '' : (transferDescrFixMap.get(payment.id))}}"        class="inputPay" type="text" style="width: 600px; margin-left: 10px" ></textarea> </span></td>
                    <!--character counter-->
                    <td colspan="2" style="padding-left: 10px;" [style.color]="this.getTransferDescriptionLength(paymentMap.get(payment.id).transferDescription, payment.id) > 140 ? 'red' : 'black'">{{this.getTransferDescriptionLength(paymentMap.get(payment.id).transferDescription, payment.id)}}/140</td>
                  </tr>

                </table>
            </div>
            <div *ngIf="needsCheck[indexPay] && isDomiciliataryEnabled" class="row">
                <pnc-required required="true"></pnc-required>
                <table class="card shadow table-white" style="width: 100%; font-size: 14px;">
                    <tr>
                        <td style="width: 10%;">
                            <input [(ngModel)]="paymentMap.get(payment.id).receiver.type" [disabled]="!enabled" value="RLAV" type="radio"
                                class="custom-control-input nearRadio" (click)="receiverCheckData('RLAV', payment.id, paymentMap.get(payment.id).receiver)" id="legalAdv"
                                name="sbjLav{{payment.id}}" id="sbjLav{{payment.id}}" [disabled]="noLegalAssociated">

                        </td>
                        <td style="width: 20%;"><label for="legalAdv">{{ '_CLAIMS_._LEGAL_OPPONENT' | translate }}:</label></td>
                        <td *ngIf="noLegalAssociated" style="width: 50%;">
                            <label for="legalAdv">{{ '_CLAIMS_._NO_PARTIES' | translate }}</label>
                        </td>
                        <td *ngIf="!noLegalAssociated" style="width: 50%;">
                            <select class="core-select form-control" id="lavs{{payment.id}}" name="lavs{{payment.id}}" [(ngModel)]="paymentMap.get(payment.id).receiver.legalCode"
                                [disabled]="!enabled" (change)="receiverCheckData('RLAV',payment.id, paymentMap.get(payment.id).receiver)">
                                <option></option>
                                <option *ngFor="let lav of legalDataforCheck" value={{lav.idSubject}}>
                                    {{lav.lastName}} {{lav.name}} - {{formatString(lav.address)}}
                                    {{formatString(lav.number)}} {{formatString(lav.postalCode)}}
                                    {{formatString(lav.municipality)}} ({{formatString(lav.state)}})</option>
                            </select>
                            <span
                                class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                        </td>
                        <td style="width: 20%;"></td>
                    </tr>
                    <!-- il domiciliatario agenzia è stato commentato in quanto non gestito nell'invio a banche-->
                    <!-- <tr>
                        <td>
                            <input [(ngModel)]="paymentMap.get(payment.id).receiver.type" [disabled]="!enabled" value="RAGE" type="radio"
                                (click)="receiverCheckData('RAGE', payment.id)" class="custom-control-input nearRadio"
                                id="policyAgency{{payment.id}}" name="policyAgency{{payment.id}}">
                        </td>
                        <td><label for="legalAdv">{{ '_CLAIMS_._POLICY_AGENCY' | translate }}:</label></td>
                        <td>
                            <label for="legalAdv">{{policyAgency.agencyDescription}} -
                                {{stringAddress | translate}}</label>
                        </td>
                        <td></td>
                    </tr> -->
                    <tr>
                        <td>
                            <input [(ngModel)]="paymentMap.get(payment.id).receiver.type" [disabled]="!enabled" value="RSBJ" type="radio"
                                (click)="receiverCheckData('RSBJ', payment.id)" class="custom-control-input nearRadio" id="subjAnag{{payment.id}}"
                                name="subjAnag{{payment.id}}">
                        </td>
                        <td><label for="legalAdv">{{ '_CLAIMS_._REGISTER_SUBJECT' | translate }}:</label></td>
                        <td>
                            <label for="legalAdv" style="padding-top: 10px;">
                                {{paymentMap.get(payment.id).receiver.name}} {{paymentMap.get(payment.id).receiver.lastName}} -
                                {{formatString(paymentMap.get(payment.id).receiver.address)}}
                                {{formatString(paymentMap.get(payment.id).receiver.number)}}
                                {{formatString(paymentMap.get(payment.id).receiver.postalCode)}}
                                {{formatString(paymentMap.get(payment.id).receiver.municipality)}}
                                ({{formatString(paymentMap.get(payment.id).receiver.state)}})
                                <p *ngIf="paymentMap.get(payment.id).receiver.country!==null && paymentMap.get(payment.id).receiver.address!==null
                                      && (paymentMap.get(payment.id).receiver.municipality!=null || paymentMap.get(payment.id).receiver.municipalityCode!=null)
                                      && paymentMap.get(payment.id).receiver.postalCode!=null && paymentMap.get(payment.id).receiver.number!=null
                                      &&  paymentMap.get(payment.id).receiver.state!==null; else no_content">
                                </p>
                            </label>
                            <ng-template #no_content>{{ '_CLAIMS_._INCOMPLETE_ADDRESS' | translate }}</ng-template>
                        </td>
                        <td>
                            <div class="btn-group">
                                <button type="submit" class="btn btn-warning pull-right" id="policy-search-submit"
                                    (click)="openAnag(payment)">{{ '_CLAIMS_._OPEN_REGISTER' | translate }}</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </ol>
    <div class="form-group">
        <div class="alert alert-danger" *ngIf="MsgError!=null" role="alert">{{MsgError}} </div>
    </div>
    <div class="modal-footer">
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button class="btn btn-primary colorBGBlu2" [disabled]="ismissingData()" (click)="onConfirm()"
                    data-qa="party-data-panel-confirm-button" translate>{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!enabled && !openOutOfStepper">
    <div class="container-fluid table-white">
        <div *ngFor="let payment of potPayFE.paymentList; let num = index;" class="row">
            <div class="row" style="font-size: 16px; font-weight: bold; padding-top: 15px;">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    {{num+1}}.{{ '_CLAIMS_._PAYMENT_TYPE' | translate }}: {{payment.paymentTypeDescr}}</div>
                <div class="col-md-4 col-sm-6 col-xs-12">
{{ '_CLAIMS_._RECEIPT_TYPE' | translate }}: {{payment.receiptTypeDescr}}</div>
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <span style="float: right;">{{ '_CLAIMS_._COMPANY_SETTLEMENT_AMOUNT' | translate }}: €
                        {{payment.totalAmountNet}}</span>
                </div>
            </div>
            <div style="padding-left: 15px; padding-right: 15px">
                <div class="container-fluid table-white">
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        {{payment.methodPayment.description}}
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        &nbsp;
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        &nbsp;
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!enabled && openOutOfStepper">
    <div class="top-banner" *ngFor="let payment of potPayFE.paymentList; let indexPay = index;">
        <div class="top-banner" *ngIf="paymentMap.get(payment.id)">
            <div class="row">
                <div class="col-md-12" style="font-size: 14px;">
                    <div class="row">
                        <rgi-rx-form-field>
                            <label rgiRxLabel><h5 class="text-info bold">{{ '_CLAIMS_._METHOD_OF_PAYMENT' | translate }}:</h5></label>
                            <select rgiRxNativeSelect class="core-select form-control" id="typeOfPay" name="typeOfPay"
                            (change)="reloadDetails(payment, indexPay)"
                            [(ngModel)]="paymentMap.get(payment.id).methodId"
                            [disabled]="payment.currencyCode!=null && payment.currencyCode!='EUR'">
                            <option></option>
                            <option *ngFor="let aPayType of paymentMethod; " value={{aPayType.idObject}}>
                                {{aPayType.description}}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;" *ngIf="needsIban[indexPay]" class="row">
            <div class="col-xs-6">
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._IBAN' | translate }}
                            <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!openOutOfStepper"
                        class="inputPay w-100"
                        (change)="ibanPaymentCheckData($event, payment.id)" type="text" required/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._ACCOUNT_HOLDER' | translate }}: </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).accountHolder"
                            [disabled]="!openOutOfStepper"
                            class="inputPay w-100" type="text">
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._BANK_DESCRIPTION' | translate }}: </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).bankDescription" [disabled]="!openOutOfStepper"
                            class="inputPay w-100" type="text">
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._BANK_ADDRESS' | translate }}: </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).bankAddress" [disabled]="!openOutOfStepper"
                            class="inputPay w-100" type="text">
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4">
                        <label ng-bind="label">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate }}: </label>
                    </div>
                    <div class="col-xs-8">
                        <textarea [(ngModel)]="paymentMap.get(payment.id).transferDescription"
                        [disabled]="!openOutOfStepper ||
                        !this.isEnabledEditTransferDescription"
                    placeholder="{{this.isEnabledEditTransferDescription ? '' : (transferDescrFixMap.get(payment.id))}}"
                    class="inputPay custom-text-area w-100" type="text"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-xs-6">
                <div *ngFor="let payee of payment.listPayee;">
                    <div *ngIf="payee.primary && payee.ibans && payee.ibans.length > 0;else zeroIban">
                        <rgi-rx-form-field>
                            <select rgiRxNativeSelect
                            [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!openOutOfStepper"
                            (change)="ibanPaymentCheckData($event, payment.id)">
                            <option></option>
                            <option *ngFor="let iban of payment.listPayee[0].ibans" value={{iban.iban}}>
                                {{iban.iban}} - {{iban.dataPagamento | translate }}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                    <ng-template #zeroIban>
                        <p style="font-size: 12px; padding-left: 2px;">{{'_CLAIMS_._MESSAGE_._NO_IBAN_PRESENT_OF_THE_PAYEE' |
                            translate}}</p>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="needsIbanInternational[indexPay]" class="row">
            <div class="col-xs-6">
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._IBAN' | translate }}</label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).iban"
                        [disabled]="!openOutOfStepper" class="inputPay w-100"
                        (keyup)="ibanForeignPaymentCheckData($event, payment.id)" type="text"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._SWIFT' | translate }}:
                            <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).swift"
                        (keyup)="internationPaymentCheckData(payment.id)"
                                [disabled]="!openOutOfStepper" class="inputPay w-100"
                                type="text" required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._FOREIGN_BANK_ACCOUNT' | translate }}:
                            <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).foreignBankAccount"
                        [disabled]="!openOutOfStepper"
                        class="inputPay w-100" (keyup)="internationPaymentCheckData(payment.id)" type="text"
                        required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._BIC' | translate }}: </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).bankAddress" [disabled]="!openOutOfStepper"
                            class="inputPay w-100" type="text">
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate }}:
                            <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).bic" (keyup)="internationPaymentCheckData(payment.id)" [disabled]="!openOutOfStepper"
                                class="inputPay w-100" type="text" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._CURRENCY' | translate }}: </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).currency"
                        [disabled]="payment.currencyCode && payment.currencyCode!==null && payment.currencyCode!=='EUR'"
                        class="inputPay w-100" maxlength="3" type="text"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._UIC' | translate }}:
                            <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).uic"  (keyup)="internationPaymentCheckData(payment.id)"
                                [disabled]="!openOutOfStepper" class="inputPay w-100"
                                type="text" required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate }}: </label>
                    </div>
                    <div class="col-xs-8">
                        <textarea [(ngModel)]="paymentMap.get(payment.id).transferDescription" [disabled]="!openOutOfStepper || !this.isEnabledEditTransferDescription"
                            placeholder="{{this.isEnabledEditTransferDescription ? '' : (transferDescrFixMap.get(payment.id))}}"
                            class="inputPay w-100 custom-text-area" type="text"></textarea>
                    </div>
                </div>
            </div>
             <!--TRANSFER REASON // CAUSALE BONIFICO-->
            <div class="col-xs-6">
                <div *ngFor="let payee of payment.listPayee;">
                    <div *ngIf="payee.primary && payee.ibans && payee.ibans.length > 0;else zeroIban">
                        <rgi-rx-form-field *ngIf="payee.primary && payee.swifts && payee.swifts.length > 0;else zeroIban">
                            <label ng-bind="label" class="labelPay" style="margin-left: 20px;"
                            *ngIf="visulizeLabelIban(payment)">{{ '_CLAIMS_._MESSAGE_._FOREIGN_BANK_ACCOUNT_ON_SUBJECT' | translate }}: </label>
                            <select rgiRxNativeSelect
                                id="swifts" name="swifts"
                                [(ngModel)]="paymentMap.get(payment.id).foreignBankAccount" [disabled]="!openOutOfStepper"
                                (keyup)="internationPaymentCheckData(payment.id)">
                            <option></option>
                            <option *ngFor="let swift of payment.listPayee[0].swifts" value={{swift.iban}}>
                                {{swift.iban}} - {{swift.dataPagamento | translate }}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                    <ng-template #zeroIban>
                        <p style="font-size: 12px; padding-left: 2px;">{{'_CLAIMS_._MESSAGE_._NO_BANK_ACCOUNT_FOR_THE_SUBJECT' |
                            translate}}</p>
                    </ng-template>
                </div>
            </div>
        </div>

        <div *ngIf="needsIbanInternationalEuro[indexPay]" class="row">
            <div class="col-xs-6">
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._FOREIGN_BANK_ACCOUNT' | translate }}</label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).foreignBankAccount"
                        (keyup)="internationPaymentCheckDataEuro(payment.id)"
                        [disabled]="!openOutOfStepper"
                        class="inputPay w-100" type="text"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._IBAN' | translate }}
                            <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).iban"
                        [disabled]="!openOutOfStepper" class="inputPay w-100"
                        (keyup)="internationPaymentCheckDataEuro(payment.id)" type="text"
                        required/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._BANK' | translate }}</label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).bankDescription" [disabled]="!openOutOfStepper"
                        class="inputPay w-100" type="text" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._REFERENCE' | translate }}</label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).accountHolder" [disabled]="!openOutOfStepper"
                            class="inputPay w-100" type="text"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._UIC' | translate }}
                            <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).uic"
                        (keyup)="internationPaymentCheckDataEuro(payment.id)" [disabled]="!openOutOfStepper"
                        class="inputPay w-100"
                                type="text" required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._CURRENCY' | translate }}
                        <span class="requiredSpan">*</span>
                        </label>
                    </div>
                    <div class="col-xs-8">
                        <input [(ngModel)]="paymentMap.get(payment.id).currency"
                        (keyup)="internationPaymentCheckDataEuro(payment.id)"
                        [disabled]="payment.currencyCode && payment.currencyCode!==null && payment.currencyCode!=='EUR'"
                        class="inputPay w-100" maxlength="3" type="text"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 text-left">
                        <label ng-bind="label">{{ '_CLAIMS_._BANK_TRANSFER_DESCRIPTION' | translate }}</label>
                    </div>
                    <div class="col-xs-8">
                        <textarea [(ngModel)]="paymentMap.get(payment.id).transferDescription"
                        [disabled]="!openOutOfStepper || !this.isEnabledEditTransferDescription"
                        placeholder="{{this.isEnabledEditTransferDescription ? '' : (transferDescrFixMap.get(payment.id))}}"
                        class="inputPay w-100" type="text"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-xs-6">
                <div *ngFor="let payee of payment.listPayee;">
                    <div *ngIf="payee.primary && payee.sepas && payee.sepas.length > 0;else zeroIban">
                        <rgi-rx-form-field *ngIf="payee.primary && payee.swifts && payee.swifts.length > 0;else zeroIban">
                            <label ng-bind="label" class="labelPay" style="margin-left: 20px;"
                            *ngIf="visulizeLabelIban(payment)">{{ '_CLAIMS_._IBAN_ON_SUBJECT' | translate }}: </label>
                            <select rgiRxNativeSelect
                            class="core-select form-control" id="sepas" name="sepas"
                            [(ngModel)]="paymentMap.get(payment.id).iban" [disabled]="!openOutOfStepper"
                            (keyup)="internationPaymentCheckDataEuro(payment.id)" style="width: 400px;">
                            <option></option>
                            <option *ngFor="let sepa of payment.listPayee[0].sepas" value={{sepa.iban}}>
                                {{sepa.iban}} - {{sepa.dataPagamento | translate }}</option>
                            </select>
                        </rgi-rx-form-field>
                    </div>
                    <ng-template #zeroIban>
                        <p style="font-size: 12px; padding-left: 2px;">{{'_CLAIMS_._MESSAGE_._NO_IBAN_PRESENT_OF_THE_PAYEENessun iban presente sul soggetto' |
                            translate}}</p>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="needsCheck[indexPay] && isDomiciliataryEnabled" class="row">
            <pnc-required required="true"></pnc-required>
            <table class="card shadow table-white" style="width: 100%; font-size: 14px;">
                <tr>
                    <td style="width: 10%;">
                        <input [(ngModel)]="paymentMap.get(payment.id).receiver.type" [disabled]="!openOutOfStepper" value="RLAV" type="radio"
                            class="custom-control-input nearRadio" (click)="receiverCheckData('RLAV', payment.id, paymentMap.get(payment.id).receiver)" id="legalAdv"
                            name="sbjLav{{payment.id}}" id="sbjLav{{payment.id}}" [disabled]="noLegalAssociated">

                    </td>
                    <td style="width: 20%;"><label for="legalAdv">{{ '_CLAIMS_._LEGAL_OPPONENT' | translate }}:</label></td>
                    <td *ngIf="noLegalAssociated" style="width: 50%;">
                        <label for="legalAdv">{{ '_CLAIMS_._NO_PARTIES' | translate }}</label>
                    </td>
                    <td *ngIf="!noLegalAssociated" style="width: 50%;">
                        <select class="core-select form-control" id="lavs{{payment.id}}" name="lavs{{payment.id}}" [(ngModel)]="paymentMap.get(payment.id).receiver.legalCode"
                            [disabled]="!openOutOfStepper" (change)="receiverCheckData('RLAV',payment.id, paymentMap.get(payment.id).receiver)">
                            <option></option>
                            <option *ngFor="let lav of legalDataforCheck" value={{lav.idSubject}}>
                                {{lav.lastName}} {{lav.name}} - {{formatString(lav.address)}}
                                {{formatString(lav.number)}} {{formatString(lav.postalCode)}}
                                {{formatString(lav.municipality)}} ({{formatString(lav.state)}})</option>
                        </select>
                        <span
                            class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </td>
                    <td style="width: 20%;"></td>
                </tr>
                <!-- il domiciliatario agenzia è stato commentato in quanto non gestito nell'invio a banche-->
                <!-- <tr>
                    <td>
                        <input [(ngModel)]="paymentMap.get(payment.id).receiver.type" [disabled]="!openOutOfStepper" value="RAGE" type="radio"
                            (click)="receiverCheckData('RAGE', payment.id)" class="custom-control-input nearRadio"
                            id="policyAgency{{payment.id}}" name="policyAgency{{payment.id}}">
                    </td>
                    <td><label for="legalAdv">{{ '_CLAIMS_._POLICY_AGENCY' | translate }}:</label></td>
                    <td>
                        <label for="legalAdv">{{policyAgency.agencyDescription}} -
                            {{stringAddress | translate}}</label>
                    </td>
                    <td></td>
                </tr> -->
                <tr>
                    <td>
                        <input [(ngModel)]="paymentMap.get(payment.id).receiver.type" [disabled]="!openOutOfStepper" value="RSBJ" type="radio"
                            (click)="receiverCheckData('RSBJ', payment.id)" class="custom-control-input nearRadio" id="subjAnag{{payment.id}}"
                            name="subjAnag{{payment.id}}">
                    </td>
                    <td><label for="legalAdv">{{ '_CLAIMS_._REGISTER_SUBJECT' | translate }}:</label></td>
                    <td>
                        <label for="legalAdv" style="padding-top: 10px;">
                            {{paymentMap.get(payment.id).receiver.name}} {{paymentMap.get(payment.id).receiver.lastName}} -
                            {{formatString(paymentMap.get(payment.id).receiver.address)}}
                            {{formatString(paymentMap.get(payment.id).receiver.number)}}
                            {{formatString(paymentMap.get(payment.id).receiver.postalCode)}}
                            {{formatString(paymentMap.get(payment.id).receiver.municipality)}}
                            ({{formatString(paymentMap.get(payment.id).receiver.state)}})
                            <p *ngIf="paymentMap.get(payment.id).receiver.country!==null && paymentMap.get(payment.id).receiver.address!==null
                                    && (paymentMap.get(payment.id).receiver.municipality!=null || paymentMap.get(payment.id).receiver.municipalityCode!=null)
                                    && paymentMap.get(payment.id).receiver.postalCode!=null && paymentMap.get(payment.id).receiver.number!=null
                                    &&  paymentMap.get(payment.id).receiver.state!==null; else no_content">
                            </p>
                        </label>
                        <ng-template #no_content>{{ '_CLAIMS_._INCOMPLETE_ADDRESS' | translate }}</ng-template>
                    </td>
                    <td>
                        <!-- <div class="btn-group">
                            <button type="submit" class="btn btn-warning pull-right" id="policy-search-submit"
                                (click)="openAnag(payment)">{{ '_CLAIMS_._OPEN_REGISTER' | translate }}</button>
                        </div> -->
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="form-group mbc-2">
        <div class="alert alert-danger" *ngIf="MsgError!=null" role="alert">{{MsgError}} </div>
    </div>
</div>
