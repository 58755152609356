import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { Party, CardComponentService, SessionService, PartyTranslatorService } from '@rgi/digital-claims-common-angular';
import { UtilityService } from '../../../../external-portfolio/utility.service';
import { ClaimOpeningService } from '../../../claim-opening.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { AnagFlowData, AnagIntegrationService, AnagRouteData, AnagSearchOrCreateResolver } from '@rgi/anag';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'claims-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.scss']
})
export class PartyComponent implements OnInit {
  @Input() party: any;
  @Output() partyChange = new EventEmitter<Party>();
  @Input() card: any;
  @Input() componentId: string;
  @Input() partyType: string;
  @Input() partyLabel: string;
  @Input() editable: boolean;
  @Input() disabled: boolean;
  @Input() updateOn: string;
  @Input() mandatory: boolean;
  @Input() statusDescription: string;
  @Input() fullDetails: boolean = false;
  // TODO: manange
  partyDeleteDisabled: false;
  // TODO: manage
  deleteEnabled: true;
  nodeIdSxFitt: string;
  isAntiFinancial: boolean = false;

  private partyDetailLastSession: any;

  editableSubjectFormGroup: UntypedFormGroup;

  constructor(
    @Inject('eventService') private eventService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('AnagFinderSubject') private anagFinderSubject: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('authService') private authService: any,
    @Inject('claimsSessionStatus') private claimsSessionStatus: any,
    private cardComponentService: CardComponentService,
    private sessionServiceComm: SessionService,
    private partyTranslatorService: PartyTranslatorService,
    private claimOpeningService: ClaimOpeningService,
    private utilityService: UtilityService,
    private rxTranslationsService: RgiRxTranslationService,
    public anagIntegrationService: AnagIntegrationService,
    protected anagSearchOrCreateResolver: AnagSearchOrCreateResolver
  ) { }

  ngOnInit() {
    console.log('PartyComponent::editable', this.editable);
    if (this.party && this.party.statusDescription) {
     this.statusDescription = this.party.statusDescription;
    }
    this.utilityService.getNodeSxFitt().subscribe((result: any) => {
      this.nodeIdSxFitt =  result;
    });
  }

  partyDetails(event: any) {
    const that = this;

    const nodeId = this.authService.getSalePointId();

    const request = {
      idSubject: this.party.id,
      idNode: this.nodeIdSxFitt
    };
    this.eventService.broadcastEvent('start-loader');

    this.anagFinderSubject.get(request).$promise.then(result => {
      if (result && result.subject) {
        const flowData: AnagFlowData = new AnagFlowData();
        flowData.idParentSession = that.card.idSession;
        flowData.nodeCode = this.authService.getSalePointCode();
        flowData.partyRole='888';
        const anagRouteData: AnagRouteData = new AnagRouteData();
        anagRouteData.party = result.subject;
        anagRouteData.redirectToMainFlow = true;
        anagRouteData.flowData = flowData;
        // const idNavigation = that.sessionService.open('anagFinder', 'detail', '', true, null, null, that.card.idSession);
        this.anagIntegrationService.openEditSubject(anagRouteData, that.card.idSession);
        /* const idNavigation = that.sessionService.open('anagCardManage', 'home', '', true, null, null, that.card.idSession);
        const idSession = that.sessionServiceComm.idSessionFromId(idNavigation);
        this.partyDetailLastSession = idSession;

        this.sessionServiceComm.registerSessionDeathCallback(idSession, () => {
          that.refreshParty();
        });
        that.coreResult.setResult(idNavigation, 'newSubj', {
          subject: result.subject,
          subjectRole: '888'
        });
        that.coreResult.setResult(idNavigation, 'previousSession', {
          sessionId: that.card.idSession
        }); */
      }
      this.eventService.broadcastEvent('stop-loader');
    });
    event.stopPropagation();
    return false;
  }

  getNameFromType(party: Party) {
    if (party.type === 'F' || party.type === 'M') {
      return party.surname + ' ' + party.name;
    } else {
      return party.description;
    }
  }

  addParty(event) {
    const data = new AnagFlowData();
    data.partyRole = '888';
    data.nodeCode = this.authService.getSalePointCode();
    data.idParentSession = this.card.id;
    this.anagSearchOrCreateResolver.searchOrCreateSubject(data).subscribe(res => {
      if(res) {
        const nodeId = this.authService.getSalePointId();
        let eventName = 'add-';
        if (this.partyType) {
          eventName += this.partyType;
        } else {
          eventName += 'subject';
        }
        this.claimOpeningService.setSubjectType(this.partyType);
         this.cardComponentService.emit({
          eventName,
          salePointId: nodeId,
          data: res
        }, this.card.id);
    
        this.partyChange.emit(event); 
        event.stopPropagation();
      }
    });
    
    return false;
  }

  deleteParty(event) {
    event.stopPropagation();
    this.party = null;
    this.partyChange.emit(this.party);
    return false;
  }

  fiscalCodeOrVat() {
    let ret = '';
    if (this.party) {
      if (this.party.fiscalcode) {
        ret = this.party.fiscalcode;
      } else if (this.party.piva) {
        ret = this.party.piva;
      }
    }

    return ret;
  }

  refreshParty() {
    const that = this;
    const nodeId = this.authService.getSalePointId();
    const request = {
      idSubject: this.party.id,
      idNode: nodeId
    };

    const updatedParty = this.claimsSessionStatus.getSessionStatus(this.partyDetailLastSession, 'partyUpdated');
    if (updatedParty && updatedParty.objectId === this.party.id) {
      // this.sessionService.remove(this.partyDetailLastSession);
      // this.sessionService.setActive(this.card.idSession);
      if (this.updateOn === 'return' || this.updateOn === 'save') {
        this.eventService.broadcastEvent('start-loader');

        this.anagFinderSubject.get(request).$promise.then(result => {
          if (result && result.subject) {
            that.party = that.partyTranslatorService.anagSubjectToPartyDto(result.subject);
          }
          that.eventService.broadcastEvent('stop-loader');
        });
      }
    } else {
      if (this.updateOn === 'return') {
        this.eventService.broadcastEvent('start-loader');

        this.anagFinderSubject.get(request).$promise.then(result => {
          if (result && result.subject) {
            that.party = that.partyTranslatorService.anagSubjectToPartyDto(result.subject);
          }
          that.eventService.broadcastEvent('stop-loader');
        });
      }
    }

  }

  partyTypeLabel(): string {
    let label = '';

    if (this.partyLabel) {
      label = this.partyLabel;
    } else {
      switch (this.partyType) {
        case 'insured':
          this.rxTranslationsService.translate('_CLAIM_._INSURED').subscribe(
            res =>  label = res
          )
          break;
        case 'policyHolder':
          this.rxTranslationsService.translate('_CLAIM_.POLICY_HOLDER').subscribe(
            res =>  label = res
          )
          break;
        case 'driver':
          this.rxTranslationsService.translate('_CLAIM_._DRIVER').subscribe(
            res =>  label = res
          )
          break;
        case 'third':
          this.rxTranslationsService.translate('_CLAIM_._THIRD_PARTY').subscribe(
            res =>  label = res
          )
          break;
        default:
          this.rxTranslationsService.translate('_CLAIM_._PARTY').subscribe(
            res =>  label = res
          )
          break;
      }
    }

    return label;

  }

  isAntiFinancialCrimes(party: Party): boolean {
    if (party != null && party.statusAntiFinancialCrimes != null) {
      switch (party.statusAntiFinancialCrimes) {
        case '2':
          party.statusAntiFinancialCrimesDescr = 'Signaled subject';
          return true;
        case '3':
          party.statusAntiFinancialCrimesDescr = 'Blocked subject';
          return true;
        default:
          party.statusAntiFinancialCrimesDescr = '';
          return false;
}
    }
    return false;
  }

}

