import {State} from '@rgi/rx/state';
import {PNC_POSTSALES_OPERATIONS_TYPES} from '../enums/pnc-postsales-operations-types';
import {ActiveRoute} from '@rgi/rx/router';
import {PncPostsalesInputData} from '../model/pnc-postsales-input-data';
import {GenericEntity} from '../model/common/entities';
import {PncPsalesButton} from '../model/common/button';
import {PncPsalesForm} from '../model/common/form';
import {createDefaultButtonBar} from '../constants/buttons';
import { PncPsalesSummaryStepDetail } from '../model/common/summary-step-detail';

export class PncPsalesBaseState extends State {
  contractId: string;
  currentOperation: GenericEntity;
  draftId: string;
  footerBtns: PncPsalesButton[] = [];
  forms: { [formName: string]: PncPsalesForm } = {};
  formStatus: string;
  idParentSession: string;
  notes: string;
  operations?: GenericEntity[];
  operationType: PNC_POSTSALES_OPERATIONS_TYPES | string;
  policyNumber: string;
  showQuotation: boolean;
  title: string;
  routesPrefix: string;
  summaryStepDetail: PncPsalesSummaryStepDetail;
  additionalDataMap: Map<string, any> = new Map<string, any>();
  movementId: string;
  proposalNumber: string;


  constructor(activeRoute: ActiveRoute) {
    super(activeRoute.key);
    const routeData: PncPostsalesInputData = activeRoute.getRouteData<PncPostsalesInputData>();
    this.policyNumber = routeData.policyNumber;
    this.operationType = routeData.operationType;
    this.operations = routeData.operations;
    this.draftId = routeData.draftId;
    this.currentOperation = routeData.currentOperation;
    this.idParentSession = routeData.idParentSession;
    this.showQuotation = routeData.showQuotation;
    this.contractId = routeData.contractId;
    this.notes = routeData.notes;
    this.routesPrefix = routeData.routesPrefix;
    this.footerBtns = createDefaultButtonBar();
    this.movementId = routeData.movementId;
    this.proposalNumber = routeData.proposalNumber;

  }
}
