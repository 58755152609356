import { LpcTranslationsKeys } from '../lpc-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_EU_ES: LpcTranslationsKeys = {
  lpc_Investment_profiles: 'Inbertsio-profilak',
  lpc_payment_method: 'Ordainketa modua',
  lpc_payment_type: 'Ordainketa mota',
  lpc_investment_funds: 'Inbertsio-funtsak',
  lpc_fund_type: 'Funts mota',
  lpc_profile_type: 'Profil mota',
  lpc_elements: 'elementuak',
  lpc_fund_name: 'Funtsaren izena',
  lpc_profile_name: 'Profilaren izena',
  lpc_profiles: 'Profilak',
  lpc_funds: 'Funtsak',
  lpc_division_type: 'Zatiketa modua',
  lpc_division_description: 'Esleipenaren deskribapena',
  lpc_start_validity_date: 'Indarraldiaren hasiera-data',
  lpc_fund_description: 'Funtsaren deskribapena',
  lpc_ISIN_code: 'ISIN kodea',
  lpc_Allocation: 'Esleipena',
  lpc_profile_details: 'Profilaren xehetasunak',
  lpc_fields_generic_error: '',
  lpc_portfolio_fund_display: 'Inbertsio-funtsen zorroa ikustea',
  lpc_payment_data: 'Ordainketa-datuak',
  lpc_Warning_you_are_deleting_the_contractor: 'Adi, kontratatzailea ezabatzen ari zara. Jarraitu nahi duzu?',
  lpc_Investment_amount: 'Inbertitzeko kopurua:',
  lpc_mov_status: 'Egoeraren titulua',
  lpc_mov_Amount: 'Tituluaren zenbatekoa',
  lpc_ticket_status_label: 'Balorazioa kuoten zain dago',
  lpc_occurenceNumber: '',
  lpc_status_investment_label: 'Inbertsioaren egoera',
  lpc_order_status_label: 'Ordenaren egoera',
  lpc_selected_funds: 'Hautatutako funtsak',
  lpc_Premium: 'Prima',
  lpc_InvestmentByFund: 'Inbertsioak funtsaren arabera',
  lpc_Loading: 'Tasak',
  lpc_NetPremium: 'Prima garbia',
  lpc_IntFract: 'Interesak zatikatzea',
  lpc_Fees: 'Kargu finkoak',
  lpc_Taxes: 'Zergak',
  lpc_GrossPremium: 'Prima gordina',
  lpc_FirstInstallment: 'Lehenengo kuota',
  lpc_AnnualPremium: 'Urteko prima',
  lpc_NextInstallment: 'Hurrengo kuota',
  lpc_Guarantee: 'Bermea',
  lpc_BenefitOnDate: 'Mozkina une honetan',
  lpc_Benefit: 'Mozkina',
  lpc_AnnualSinglePremium: 'Urteko prima / Prima bakarra',
  lpc_ActiveGuarantees: 'Berme aktiboak',
  lpc_Enhancement: 'Finantza-datuak',
  lpc_CalculationData: 'Kalkulu-datuak',
  lpc_ControvaloreAllaData: 'Burtsa-balioa une honetan',
  lpc_AccumulationOfPrizes: 'Primen batura',
  lpc_TotalSurrender: 'Erreskatearen totala',
  lpc_AccumulationOfReducedPremiums: 'Murriztutako primen batura',
  lpc_TotalBalance: 'Balantzea, guztira',
  lpc_EffectiveDate: 'Indarraldiaren data',
  lpc_Movement: 'Mugimendua',
  lpc_Movements: 'Mugimenduak',
  lpc_booking_number: 'Erreserbaren zenbakia',
  lpc_booking_policyData: 'Polizaren datuak',
  lpc_booking_assetData: 'Aseguratutako ondasunaren datuak',
  lpc_PolicyLinked: 'Lotutako polizak',
  lpc_Motivo_Legame: 'Bonuen motibazioa',
  lpc_ProposalIssueDate: 'Proposamenaren jaulkipen-data',
  lpc_PolicyIssueDate: 'Polizaren jaulkipen-data',
  lpc_ExpirationDate: 'Iraungitze-data',
  lpc_NextInstalmentDate: 'Hurrengo kuotaren data',
  lpc_found_duplicate: 'Abisua',
  lpc_duplicate_subject_message: 'Hautatutako subjektua onuradunen artean dago jada',
  lpc_SelectSettlement: 'Aukeratu likidazioa',
  lpc_You_must_select_the_settlement_to_perform_the_variation: 'Likidazioa hautatu behar duzu aldaketa gauzatzeko',
  lpc_beneficiaryPercentage: 'Subjektuarentzako ehunekoa',
  lpc_beneficiaryPercentage_greater: 'Hau baino handiago izan behar du',
  lpc_beneficiaryPercentage_lower: 'Hau baino txikiagoa izan behar du',
  lpc_beneficiaryDuplicate: 'Bikoiztu subjektua',
  lpc_NoOperations: 'Ez dago ekintzarik eskuragarri',
  lpc_confirm: 'Baieztatu',
  lpc_description: 'Deskribapena',
  lpc_issueDate: 'Jaulkipen-data',
  lpc_effectiveDate: 'Indarraldiaren data',
  lpc_MovementData: 'Ezeztatu eranskina',
  lpc_Are_you_sure_you_want_to_confirm_this_claim: 'Ziur ezbehar hori baieztatu nahi duzula?',
  lpc_Are_you_sure_you_want_to_cancel_this_claim: 'Ziur ezbehar hori ezeztatu nahi duzula?',
  lpc_warnings: 'Abisuak',
  lpc_warning: 'Abisua',
  lpc_warning_message: 'Hautatu "BAIMEN-ESKAERA" eragiketa egiteko onarpen-eskaera bidaltzeko',
  lpc_ClaimCanceled: 'Ezbeharra ezeztatu da',
  lpc_ClaimConfirmed: 'Ezbeharra baieztatu da',
  lpc_reserve: 'gorde',
  lpc_questionnaire: 'Galdetegia',
  lpc_bonus_amount: 'Hobariaren zenbatekoa',
  lpc_life_detail_beneficiary: 'Onuraduna',
  lpc_life_detail_percentage: 'Ehunekoa',
  lpc_life_detail_net_amount: 'Zenbateko garbia',
  lpc_life_detail_questionnaire: 'Galdetegia',
  lpc_life_detail_beneficiaries: 'Onuradunak',
  lpc_AppendixNumber: 'Eranskinaren zenbakia',
  lpc_nessuna_variazione: 'Aldaketarik gabe',
  lpc_appendixLabel: 'Onuradunaren eranskina',
  lpc_operation_termination_reason: 'Deuseztapenaren arrazoia',
  lpc_Lien_type_creditor: 'Hartzekodun mota',
  lpc_creditor: 'Hartzekoduna',
  lpc_Open_lien: 'Atxikipen-eskubide irekia',
  lpc_assignment_holder_type: 'Esleipenaren titular mota',
  lpc_assignment_holder: 'Esleipenaren titularra',
  lpc_assignment_amount: 'Zenbatekoa',
  lpc_assignment_policy_number: 'Kontratuaren zenbakia',
  lpc_assignment_company: 'Sozietatea',
  lpc_assignment_type: 'Esleipen mota',
  lpc_assignment_benefit: 'Mozkina',
  lpc_amount_less_of_benefit: 'Zenbatekoak mozkina baino txikiagoa izan behar du',
  lpc_open_assignment: 'Esleipen irekia',
  lpc_sum_det_cancel_proposal: 'Ezeztatu proposamena',
  lpc_sum_det_success_cancel: 'Proposamena ezeztatu da',
  lpc_sum_det_success_cancel_prev: 'Aurrekontua ezeztatu da',
  lpc_sum_det_confirm_cancel: 'Ziur proposamena ezeztatu nahi duzula?',
  lpc_sum_det_confirm_cancel_prev: 'Ziur aurrekontu hori ezeztatu nahi duzula?',
  lpc_sum_det_confirm_prop_issue: 'Aurrekontua jaulkiko da. Baieztatu nahi duzu?',
  lpc_sum_det_confirm_pol_issue: 'Poliza jaulkiko da. Baieztatu nahi duzu?',
  lpc_health_questionnaire: 'Osasunaren inguruko galdetegia',
  lpc_quotation_show_details: 'Erakutsi informazioa',
  lpc_quotation_premium_details: 'Primaren inguruko informazioa',
  lpc_quotation_warranties_details: 'Bermeen inguruko informazioa',
  lpc_quotation_commissions_details: 'Komisioak',
  lpc_Reference_Third_Party: 'Erreferentziarako hirugarren batzuk',
  lpc_policyHolder: '',
  lpc_DelegateEqualToPolicyHolder: 'Ordezkaria eta aseguruduna berdinak dira?',
  lpc_LegalGuardianEqualToPolicyHolder: 'Legezko tutorea eta aseguruduna berdinak dira?',
  lpc_ThirdPayerEqualToPolicyHolder: 'Hirugarren ordaintzailea eta aseguruduna berdinak dira?',
  lpc_DelegateEqualToPolicyHolderWarning: 'Delegatuaren eginkizuna duen subjektua eta aseguruduna berdinak dira',
  lpc_LegalGuardianEqualToPolicyHolderWarning: 'Legezko ordezkariaren eginkizuna duen subjektua eta aseguruduna berdinak dira',
  lpc_ThirdPayerEqualToPolicyHolderWarning: 'Hirugarren ordaintzailearen eginkizuna duen subjektua eta aseguruduna berdinak dira',
  lpc_but_confirm_accept: 'Osatu eta onartu',
  lpc_Date_Start_Variation: 'Aldakuntzaren hasiera-data',
  lpc_New_Frequent_Payment: 'Ohiko ordainketa berria',
  lpc_start_date_profit: 'Mozkinen hasiera-data',
  lpc_end_date_profit: 'Mozkinen amaiera-data',
  lpc_last_rate_profit: 'Azken mozkin-tasa',
  lpc_last_profit: 'Azken mozkinak',
  lpc_Fund_Code: 'Funts-kodea',
  lpc_OperationSynstesis: 'Eragiketen sintesia',
  lpc_PreviusPerceent: 'Aurreko ehunekoa',
  lpc_PreviusValue: 'Aurreko balioa',
  lpc_NumberOfUnit: 'Unitatearen aurreko zenbakia',
  lpc_PostPErcent: 'Postuaren ehunekoa',
  lpc_PostValue: 'Postuaren balioa',
  lpc_PostNumberOfUnit: 'Aurrekontuko postuaren zenbakia',
  lpc_Dati: 'Datuak',
  lpc_PIP_Data: 'PIP datuak',
  lpc_PIP_General: 'Datu orokorrak',
  lpc_PIP_Contribution: 'PIP ekarpenak',
  lpc_HolderData: 'Aseguratutako datuak',
  lpc_RiskData: 'Arrisku-datuak',
  lpc_IBAN_error: 'IBAN zenbakia ez da zuzena',
  lpc_BACK: 'Atzera',
  lpc_ACTIONS: 'Ekintzak',
  lpc_DETAIL: 'Informazioa',
  lpc_CANCEL: 'Ezeztatu proposamena',
  lpc_CONFIRM_PROPOSAL: 'Baieztatu proposamena',
  lpc_CONFIRM_POLICY: 'Baieztatu poliza',
  lpc_not_linked_detail: '{{name}}(r)en inguruko informazioa',
  lpc_quote_number: 'Aurrekontuaren zenbakia',
  lpc_valid_quote: 'Aurrekontua baliozkoa da',
  lpc_BUY_PREV: 'Erosi',
  lpc_EDIT_PREV: 'Editatu',
  lpc_EDIT: 'Editatu',
  lpc_CANCEL_PREV: 'Kendu',
  lpc_modify_quote: 'Aldatu aurrekontua',
  lpc_modify_proposal: 'Aldatu proposamena',
  lpc_buy_quote: 'Erosketa-aurrekontua',
  lpc_Close_assignment: 'Esleipena itxi da',
  lpc_Close_lien: 'Atxikipen-eskubide itxi da',
  lpc_disinvestment_funds: 'Desinbertsio-funtsak',
  lpc_error_mandatory_fields: 'Gorriz markatutako eremuak derrigorrezkoak dira edo balio okerrak dituzte',
  lpc_disinvestment_msg: '{{amount}} desinbertitzeko edo {{percent}}',
  lpc_disinvestment_allocation_msg: 'La asignación de inversión supera el importe disponible',
  lpc_maximum_percentage: 'Porcentaje máximo aceptado',
  lpc_maximum_amount: 'Cantidad máxima aceptada',
  lpc_minimum_percentage: 'Porcentaje mínimo aceptado',
  lpc_minimum_amount: 'Cantidad mínima aceptada',
  lpc_investment_msg: '{{amount}} inbertitzeko edo {{percent}}',
  lpc_Fields_marked_in_red_are_required: 'Gorriz markatutako eremuak derrigorrezkoak dira',
  lpc_amount_format_validation: 'Zenbatekoa ez da baliozkoa; hamartarrak bereizteko karakterea ez da zuzena. Erabili "." zuzentzeko',
  lpc_select_at_least_one_beneficiary: 'Hautatu onuradun bat, gutxienez',
  lpc_quote_due_date: 'Aurrekontuaren jaulkipen-data',
  lpc_roles_variation: 'Eginkizunak',
  lpc_fiscal_data: 'Datu fiskalak',
  lpc_data_calc: 'Kalkulu-data',
  lpc_tipo_liquidazione: 'Likidazio mota',
  lpc_valore_polizza: 'Polizaren balioa',
  lpc_imponibile_imp: 'Zerga',
  lpc_imposta_base: 'Zerga-oinarria',
  lpc_imposta_calcol: 'Kalkulatutako zerga',
  lpc_imposta_da_add: 'Abonatu beharreko zerga',
  lpc_imposta_add: 'Sortutako zerga',
  lpc_imposta_bollo: 'Tinbre fiskala',
  lpc_fiscal_data_detail: 'Datu fiskalen inguruko informazioa',
  lpc_quote_white_list: 'Aurrekontuaren zerrenda zuria',
  lpc_data_rif: 'Erreferentziazko data',
  lpc_tipo_perc: 'Zerrenda zuriko ehunekoa',
  lpc_CancellInstallment: 'Kuota baliogabetzeko eskaeraren laburpena',
  lpc_DescOperazione: 'Eragiketaren deskribapena',
  lpc_RateAmount: 'Kuotaren zenbatekoa, guztira',
  lpc_OPEN_LATEST_VERSION: 'Azken bertsioa ireki da',
  lpc_TOTAL_INVESTED: 'Inbertitutakoa, guztira',
  lpc_REMAINING_AMOUNT: 'Geratzen den zenbatekoa',
  lpc_descrizione_rischio: 'Arriskuen deskribapena',
  lpc_prestazione_ridotta_iniz: 'Jatorrizko prestazioaren murrizketa',
  lpc_prestazione_rivalutata: 'Mozkinaren errebalorizazioaren murrizketa',
  lpc_provvigioni_da_recuperare: 'Berreskuratu beharreko komisioa',
  lpc_reg41: 'Harremanetan jartzeko izena',
  lpc_disinvestment_amount: 'Desinbertsioaren zenbatekoa',
  lpc_occurrenceNumber: 'Gertakari-zenbakia',
  lpc_frequecy: 'Maiztasuna',
  lpc_the_occurenceNumber_must_be_a_value: 'Gertakari-zenbakiak {{min}} baino handiagoa izan behar du',
  lpc_disinvestment_range: 'Sartu desinbertitu nahi duzun zenbatekoa, {{max}} baino handiagoa eta {{min}} edo hori baino txikiagoa dena',
  lpc_state: 'Egoera',
  lpc_substate: 'Azpiegoera',
  lpc_product_type: 'Produktu mota',
  lpc_managementnode: 'Kudeaketa-nodoa',
  lpc_subagency: 'Agenteordea',
  lpc_branch_name: 'Marka',
  lpc_agreement: 'Akordioa',
  lpc_payment_frequency: 'Ordainketa-maiztasuna',
  lpc_mean_of_payment: 'Ordaintzeko modua',
  lpc_Subcause: 'Azpikausa',
  lpc_premium_payment_suspension_date: 'Primaren ordainketaren etendura-data',
  lpc_imposta_sostitutiva: 'Ordezko zerga',
  lpc_novazione_contr: 'Kontratua berritzea',
  lpc_acc_premium_initial_2011: 'Primaren hasierako metaketa 2011/12/31',
  lpc_amount_intital_2011: 'Hasierako zenbatekoa 2011/12/31',
  lpc_acc_premium_2011: 'Primaren metaketa 2011/12/31',
  lpc_amount_2011: 'Zenbatekoa 2011/12/31',
  lpc_acc_premium_initial_2014: 'Primaren hasierako metaketa 2014/06/30',
  lpc_acc_premium_2014: 'Primaren metaketa 2014/06/30',
  lpc_amount_intital_2014: 'Hasierako zenbatekoa 2014/06/30',
  lpc_amount_2014: 'Zenbatekoa 2014/06/30',
  lpc_acc_premium_initial_2022: 'Primen hasierako batura 2022/12/31',
  lpc_acc_premium_2022: 'Primen batura 2022/12/31',
  lpc_amount_intital_2022: 'Hasierako kapitala eta interesak 2022/12/31',
  lpc_amount_2022: 'Kapitala eta interesak 2022/12/31',
  lpc_acc_premium_intial_tax_change: 'Hasierako aldaketa primen gaineko zergaren metaketan',
  lpc_amount_initial_tax_change: 'Aldaketa fiskalaren hasierako zenbatekoa',
  lpc_acc_premium_tax_change: 'Primaren metaketaren aldaketa fiskala',
  lpc_amount_tax_change: 'Aldaketa fiskalaren zenbatekoa',
  lpc_regime_change_date: 'Aldaketa fiskalaren data',
  lpc_please_enter_a_disinvestment_amount: 'Sartu funtsaren desinbertsio-zenbatekoa: {{fondo}}',
  lpc_please_select_at_least_a_disinvestment: 'Hautatu desinbertsio bat, gutxienez',
  lpc_reg41Msg: 'Harremanetarako kontaktua',
  lpc_cost: 'Kostua',
  lpc_paymentFrequencyInterest: 'Maiz. Inter.',
  lpc_netpremium: 'Prima garbia',
  lpc_grosspremium: 'Prima gordina',
  lpc_information: 'Informazioa:',
  lpc_reinvest_data_detail: 'Berrinbertsioen inguruko informazioa',
  lpc_polizza: 'Poliza',
  lpc_data_effetto_mov: 'Indarraldiaren data',
  lpc_netto_liquidazione: 'Likidazioaren zenbateko garbia',
  lpc_netto_reinvestire: 'Berrinbertsioaren zenbateko garbia',
  lpc_stato_reinvest: 'Berrinbertsioaren likidazioaren egoera',
  lpc_reinvested_settlements: 'Berrinbertitutako likidazioak',
  lpc_loans: 'Maileguak',
  lpc_elaboration_date: 'Ekoizpen-data',
  lpc_calculation_date: 'Kalkulu-data',
  lpc_annuity_data: 'Biziarteko errentaren inguruko informazioa',
  lpc_extra_premium: 'Prima estra',
  lpc_loan_interest: 'Maileguaren interesak',
  lpc_fractionation_interest: 'Zatikatze-interesa',
  lpc_default_interest: 'Ez ordaintzeagatiko interesa',
  lpc_reactivation_interest: 'Berraktibatze-interesa',
  lpc_installment_detail: 'Kuotaren inguruko informazioa',
  lpc_effectiveAnnuityDate: 'Urterokoaren indarraldiaren data',
  lpc_firstInstallmentDate: 'Lehenengo kuotaren data',
  lpc_expiryAnnuityDate: 'Urterokoaren epemuga',
  lpc_amountFirstInstallment: 'Lehenengo kuotaren zenbatekoa',
  lpc_initialAnnuityAmount: 'Urterokoaren hasierako zenbatekoa',
  lpc_periodicityInstallmentAnnuity: 'Kuotaren urterokoaren aldizkakotasuna',
  lpc_typePaymentAnnuity: 'Urterokoaren ordainketa mota',
  lpc_performanceType: 'Errendimendu mota',
  lpc_annuityType: 'Urteroko mota',
  lpc_annuity_detail: 'Urterokoaren inguruko informazioa',
  lpc_canceled_liquidation_detail: 'Likidazioaren ezeztapenaren inguruko informazioa',
  lpc_Percentage: 'Ehunekoa',
  lpc_loan_data: 'Maileguaren inguruko informazioa',
  lpc_maxLoanAmount: 'Ordaintzeko gehieneko zenbatekoa',
  lpc_loanRepaymentProcedure: 'Maileguaren dirua itzultzeko prozedura',
  lpc_loanAmount: 'Maileguaren zenbatekoa',
  lpc_rateType: 'Maileguaren interes mota',
  lpc_maxLoanAmountKO: '0 € (errorea ordaintzeko gehieneko zenbatekoa kalkulatzean)',
  lpc_grant_date: 'Emakidaren data',
  lpc_loan_amount: 'Maileguaren zenbatekoa',
  lpc_return_way: 'Itzulketaren metodoa',
  lpc_interest_rate_type: 'Interes mota',
  lpc_residual_amount: 'Hondar-zenbatekoa',
  lpc_refund_type: 'Diru-itzultze mota',
  lpc_refund_amount: 'Diru-itzultzearen zenbatekoa',
  lpc_select_lpc_refund_type: 'Hautatu diru-itzultze mota bat',
  lpc_leftInsuredAmount: 'Aseguratutako hondar-kapitala',
  lpc_insuredAmount: 'Inbertitutako zenbatekoa',
  lpc_reactivatedCapital: 'Berraktibatutako kapitala',
  lpc_premium_reactivation: 'prima_berraktibatzea',
  lpc_negative_amount: 'Hondar-zenbatekoa negatiboa da',
  lpc_zero_amount: 'Ez da hondar-zenbatekoa aurkitu. Sartu 0 baino handiagoa den zenbatekoa',
  lpc_error_mandatory_selected_type_of_refund: 'Hautatu diru-itzultze mota bat',
  lpc_select_refund: 'Hautatu mailegu bat, gutxienez',
  lpc_selected_profiles: 'Hautatutako profilak:',
  lpc_coupon_beneficiary: 'Kupoiaren onuraduna',
  lpc_bookings: 'Erreserbak',
  lpc_booking_type: 'Erreserba motak',
  lpc_booking_state: 'Egoerak',
  lpc_booking_effective_date: 'Erreserbaren indarraldiaren data',
  lpc_booking_extrapremiumHealth: 'Osasun arloko prima estra',
  lpc_booking_extrapremiumProf: 'Lan arloko prima estra',
  lpc_booking_extrapremiumSport: 'Kirol arloko prima estra',
  lpc_booking_extrapremiumOther: 'Beste arloko prima estra',
  lpc_booking_effectiveDate: 'Indarraldiaren data',
  lpc_booking_detail_label: '',
  lpc_Rischi: 'Estaldurak',
  lpc_booking_destinationNode: 'Helmuga-nodoa',
  lpc_booking_cause: 'Kausa',
  lpc_PolicyData: 'Polizaren datuak',
  lpc_booking_cancellationDate: 'Ezeztapen-data',
  lpc_extra_premiums: 'Prima estrak',
  lpc_error_reservation_cancel: 'Errorea {{num}} zenbakiko {{descr}} ezeztatzean',
  lpc_success_reservation_cancel: '{{num}} zenbakiko {{descr}} ezeztatu da',
  lpc_incomplete_disinvestment_for_the_funds: 'Funtsen desinbertsio osatugabea',
  lpc_Sinistro_morte: 'Heriotza-ezbeharra',
  lpc_Sinistro_invalidita: 'Baliaezintasun-ezbeharra',
  lpc_Sinistro_inabilità: 'Ezgaitasun-ezbeharra’',
  lpc_Malattia_grave: 'Gaixotasun larriaren ondoriozko ezbeharra',
  lpc_insured_form: 'Asegurudunarentzako inprimakia',
  lpc_other_contract_list: 'Beste kontaktu-zerrenda batzuk',
  lpc_modified_personal_data_for_the_subject: 'Subjektuaren aldatutako datu pertsonalak',
  lpc_subject_roles: 'Subjektuaren eginkizunak',
  lpc_no_variation_modpd: 'Ez da aldaketarik egin; aldatu subjektua',
  lpc_DeductiblePremium: 'Prima kengarria',
  lpc_firstterm: 'Lehen hiruhilekoa',
  lpc__1TERM_ATOOL: 'Tresnaren aktibatze-data',
  lpc__1TERM_MTOOL: 'Aldatu aldaketa-tresna',
  lpc__1TERM_DTOOL: 'Tresnaren ezabatze-data',
  lpc_management_fees: 'Kudeaketa-komisioak',
  lpc_investment_not_found: 'Ez dago hautatzeko elementurik',
  lpc_No_policies: 'Ez dago polizarik aldatutako subjektuarentzat',
  lpc_variation_convention: 'Konbentzioa aldatzea',
  lpc_andamento_polizza_alla_data: 'Polizaren eboluzioa, gaur egun arte',
  lpc_appendice_dichiarativa: 'Adierazpenaren eranskina',
  lpc_policy_number_dot: 'Polizaren zk.',
  lpc_investment_sum_must_be100: 'Inbertsioen baturak 100 % izan behar du',
  lpc_Operation_detail: 'Eragiketen inguruko informazioa',
  lpc_Notwithstanding_any_ongoing_operations: 'Balioak aldatu egin daitezke uneko eragiketen ondorioz',
  lpc_percentage_max_zero: 'Sartu 0 baino handiagoa den ehunekoa',
  lpc_Mostra_tutti: 'Ikusi dena',
  lpc_Visualizza_dettaglio: 'Ikusi informazioa',
  lpc_consultazione_storica: 'Kontsulta historikoa',
  lpc_dettaglio_movimento: 'Mugimenduaren informazioa',
  lpc_mensile: 'hilean behin',
  lpc_annuale: 'urtean behin',
  lpc_sixmonthly: 'sei hilez behingoa',
  lpc_trimestrale: 'lau hilez behingoa',
  lpc_Titolo_non_incassato: 'Jaso gabeko izakinak',
  lpc_Rid: 'DDP',
  lpc_In_attesa_valorizzazione_quote: 'Balorazioa inbertitutako partaidetzen zain dago',
  lpc_switch_di_perequazione: 'Programatutako aldaketa',
  lpc_SELEZIONA: '--HAUTATU--',
  lpc_settimanale: 'astean behin',
  lpc_Valido: 'Baliozkoa',
  lpc_quotidiano: 'egunean behin',
  lpc_Assegno: 'Egiaztatu',
  lpc_Bonifico: 'Banku-transferentzia',
  lpc_Sdd: 'Banku-helbideratzeko agintea',
  lpc_severeDisabilityMsg: 'Desgaitasun larria duen onuraduna',
  lpc_benDg: 'Desgaitasuna duen onuraduna',
  lpc_distribution: 'Esleipena',
  lpc_Distribution_amount_exceeds: 'Banaketaren zenbatekoak eskura dagoen likidazioaren zenbateko osoa gainditzen du',
  lpc_distribution_invalid_greater: '{{tipo}} ez da baliozkoa; {{min.}} baino handiagoa izan behar du',
  lpc_distribution_invalid_greater_equal: '{{tipo}} ez da baliozkoa; {{máx.}} edo hori baino txikiagoa izan behar du',
  lpc_perc: 'Ehunekoa',
  lpc_amount: 'Zenbatekoa',
  lpc_Please_insert_a_distribution: 'Sartu banaketa',
  lpc_For_the_Beneficiary: 'Onuradunarentzat',
  lpc_global_distribution: 'Likidatzeko zenbateko totalak {{totalAmount}} edo hori baino txikiagoa izan behar du',
  lpc_invalid_char: '{{tipo}} formatuak zuzenak ez diren karaktereak ditu',
  lpc_toDistribute: 'banatzeko',
  lpc_noClaims: 'Ez da ezbeharrik aurkitu',
  lpc_Data_sinistro: 'Ezbeharraren data',
  lpc_Data_Pervenimento_Denuncia: 'Ezbeharraren jakinarazpena jaso zen data',
  lpc_Data_Denuncia_Sinistro: 'Ezbeharraren jakinarazpen-data',
  lpc_Tipo_sinistro: 'Ezbehar mota',
  lpc_inserisci_denuncia: 'Sartu ezbeharra',
  lpc_Conferma_inserimento_denuncia: 'Ezbeharra sartu dela baieztatzen da',
  lpc_Denuncia_inserita_correttamente: 'Ezbeharra sartu da',
  lpc_Stato_denuncia: 'Ezbeharraren egoera',
  lpc_marital_status: 'Egoera zibila',
  lpc_fiscal_code: 'Kode fiskala',
  lpc_birthdate: 'Jaiotze-data',
  lpc_address: 'Helbidea',
  lpc_sex: 'Sexua',
  lpc_annulla_denuncia: 'Ezeztatu ezbeharra',
  lpc_modifica_denuncia: 'Aldatu ezbeharra',
  lpc_conferma_denuncia: 'Baieztatu ezbeharra',
  lpc_new_beneficiary: 'Onuradun berria',
  lpc_Seleziona: 'Hautatu',
  lpc_insert_phisical_sbj_for: 'Sartu subjektu fisikoa {{role}} eginkizunerako',
  lpc_insert_legal_sbj_for: 'Sartu subjektu juridikoa {{role}} eginkizunerako',
  lpc_payment_incomplete: 'Ordainketa-informazioa osatugabea',
  lpc_add_mandatory_linkedroles: 'Sartu hautatutako subjektuarentzako derrigorrezko eginkizunak',
  lpc_beneficiary_owner_recipient_must_be_physical: 'Onuradun efektiboak pertsona fisikoa izan behar du',
  lpc_enter_the_beneficial_owners_of_the_recipient: 'Sartu hartzailearen onuradun efektiboak',
  lpc_global_percentage_must_be_a_maximum_100: 'Ehuneko globalak gehienez 100 izan behar du',
  lpc_overall_percentage_must_equal100: 'Ehuneko totalak 100 izan behar du',
  lpc_Questionario_deve_essere_compilato: 'Inprimakia bete behar da',
  lpc_enter_mandatory_data: 'Sartu derrigorrezko informazioa',
  lpc_enter_an_adult: 'Sartu heldu bat',
  lpc_enter_a_beneficial_owner: 'Sartu onuradun efektibo bat',
  lpc_enter_a_physical_beneficial_owner: 'Sartu onuradun efektibo fisiko bat',
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: 'Onuradun efektiboen ehuneko globala ezin da 100 baino gehiago izan',
  lpc_beneficial_owners_percentages_must_be_greater_than0: 'Onuradun efektiboen ehuneko globalak 0,0 baino gehiago izan behar du',
  lpc_effective_holder_perc: '',
  lpc_effective_holder_duplicated: 'Titular efektiboa bikoiztuta dago',
  lpc_enter_the_minimum_number_of_subjects: 'Sartu subjektuen gutxieneko zenbakia',
  lpc_percentage_must_be_greater_than_0: 'Ehunekoak 0.0 baino handiagoa izan behar du',
  lpc_enter_free_text: 'Idatzi testu librea',
  lpc_beneficiary_executor: 'Onuradun betearazlea',
  lpc_effective_recipient: 'Hartzaile efektiboa',
  lpc_recipient_owner: 'Jabe hartzailea',
  lpc_beneficial_owner_of_beneficiary: 'Jabe onuraduna',
  lpc_confirmed_claim_modify: 'Ezbeharra aldatu dela baieztatzen da',
  lpc_claim_modified_correctly: 'Ezbeharra aldatu da',
  lpc_Assicurato: 'Aseguruduna',
  lpc_Assicurati: 'Asegurudunak',
  lpc_holderType: 'Eskatzailearen enplegu mota',
  lpc_ageRetirement: 'Erretiroa hartzeko uneko adina',
  lpc_accessTransfer: 'Lekualdaketaren eskatzailea',
  lpc_emptyContribution: 'Enplegatzailearen ekarpena',
  lpc_firstEmployment: 'Enpleguaren hasiera-data',
  lpc_oldUnderWritt: 'Lehendik bildutakoa',
  lpc_underWitt1993: '1993/04/29an bilduta',
  lpc_maturato2001: '2000/12/31n sortuta',
  lpc_maturato2006: '2006/12/31n sortuta',
  lpc_riduzImponibiTFR: 'Zergapeko kaleratzearen kalte-ordainen gaineko murrizketa',
  lpc_aliquotaTFR: 'Kaleratzearen kalte-ordain mota (2001 baino lehenagoko fiskalitatea)',
  lpc_PIP_Contribution_Type: 'Kontribuzio mota',
  lpc_YES: 'Bai',
  lpc_PIP: 'Banakako pentsio-plana',
  lpc_PIP_Contribution_Percentage: 'Ehunekoa',
  lpc_PIP_Contribution_Amount: 'Zenbatekoa',
  lpc_transfer_data: 'Transferentziaren inguruko informazioa',
  lpc_unit_questionnaire: 'Unitatearen galdetegia',
  lpc_amount_reimbured: 'Itzultzeko zenbatekoa',
  lpc_Descr_not_handled: 'Eragiketa-kode honetarako kudeatu gabeko deskribapena',
  lpc_select_transfer: 'Hautatu transferentzia',
  lpc_fiscalFolderDataPost2006: '2006tik aurrerako sistema fiskala',
  lpc_fiscalFolderData20012006: '2001-2006 sistema fiskala',
  lpc_fiscalFolderDataBefore2001: '2001 baino lehenagoko sistema fiskala',
  lpc_filledOut: 'Beteta',
  lpc_blank: 'Zuria',
  lpc_desc_previousPenFund: 'Aurreko pentsio-funtsa',
  lpc_companyPreviousPenFund: 'Pentsio-funtsaren aurreko konpainiaren izena',
  lpc_typePreviousPenFund: 'Aurreko pentsio-funts mota',
  lpc_note: 'Oharrak',
  lpc_Benef_irrevocabile: 'Onuradun ezeztaezina',
  lpc_PIP_Transfer: 'Beste funts bateko transferentziak',
  lpc_requestDate: 'Eskaera-data',
  lpc_Type_Previous_Pension_Fund: 'Aurreko pentsio-funts mota',
  lpc_Previous_Pension_Fund: 'Aurreko pentsio-funtsa',
  lpc_Import_Transfert: 'Transferentziaren zenbatekoa',
  lpc_Note: 'Oharrak',
  lpc_relief: '2022ko zerga-arintzeak',
  lpc_selected_settlement: 'Hautatutako likidazioa',
  lpc_EDIT_ANONYMOUS_PREV: 'Editatu',
  lpc_invalid_quote: 'Aurrekontua ez da baliozkoa',
  lpc_reasonForRequest: 'Eskaeraren arrazoia',
  lpc_advancePaymentAmount: 'Ordainketaren aurrerakinaren zenbatekoa',
  lpc_repaidAdvance: 'Aurrerakin itzulia',
  lpc_PIP_AdvancedPayment: 'Ordainketen aurrerakina',
  lpc_requestReason: 'Eskaeraren arrazoia',
  lpc_notRepaidAmount: 'Zenbatekoa oraindik ez da itzuli',
  lpc_fiscalDate: 'Indarraldiaren data',
  lpc_previousPension: 'Aurreko pentsio-plana',
  lpc_fiscalCompetence: 'Eskumen fiskala',
  lpc_settlementStatus: 'Ordainketaren aurrerakinaren egoera',
  lpc_repaidAmount: 'Itzulitako zenbatekoa',
  lpc_advancePaymentTransfer: 'Transferentzien aurrerakina',
  lpc_policy_factors: 'Polizen faktoreak',
  lpc_asset_factors: 'Aseguratutako ondasunen faktoreak',
  lpc_Date_Variation: 'Aldaketaren denbora-lerroa',
  lpc_penalties_amount: 'Zigorren zenbatekoa',
  lpc_tax_amount: 'Zergen zenbatekoa',
  lpc_warranties_detail: 'Bermeen inguruko informazioa',
  lpc_fixedCharges: 'Kargu finkoak',
  lpc_catastrophe: 'Hondamendi arloko prima estra',
  lpc_health: 'Osasun arloko prima estra',
  lpc_other: 'Beste arloko prima estra',
  lpc_professional: 'Lan arloko prima estra',
  lpc_sports: 'Kirol arloko prima estra',
  lpc_collectionFee: 'Kobrantza-tasa',
  lpc_lambdaFee: 'Lambda-tasa',
  lpc_managementFee: 'Kudeaketa-tasa',
  lpc_purchaseFee: 'Erosketa-tasa',
  lpc_continue_button: 'Jarraitu',
  lpc_confirm_button: 'Baieztatu',
  lpc_authorization_request: 'Solicitud de autorización',
  lpc_questionSTR_invalid: 'Hurrengo galderaren erantzuna: {{question}} ez da zuzena',
  lpc_questionNUM_invalid: 'Hurrengo galderarako sartutako kopurua: {{question}} ez da zuzena',
  lpc_Beneficiaryowner: 'Onuradun jabea',
  lpc_confirm_operation: 'Baieztatu eragiketa',
  lpc_financial_questionnaire: 'Finantza-galdetegia',
  lpc_avc_questionnaire: 'AVC galdetegia',
  lpc_confirm_variation: 'Baieztatu aldaketa',
  lpc_Declarative_Appendix: 'Adierazpen eranskina',
  lpc_investment_funds_step: 'Inbertsio-funtsak',
  lpc_disinvestment_profile_step: 'Desinbertsio-profilak',
  lpc_startDate: 'Hasiera-data',
  lpc_end_date: 'Amaiera-data',
  lpc_six_monthly: 'Sei hilez behingoa',
  lpc_quarterly: 'Hiru hilez behingoa',
  lpc_weekly: 'Astean behin',
  lpc_monthly: 'Hilean behin',
  lpc_annual: 'Urtean behin',
  lpc_daily: 'Egunean behin',
  lpc_start_date_cannot_be_earlier_than_the_effective_date: 'Hasiera-data ezin da {{value}} data eraginkorra baino lehenagokoa izan',
  lpc_start_date_must_be_earlier_than_the_end_date: 'Hasiera-datak amaiera-data baino lehenagokoa izan behar du',
  lpc_contract_value: 'Kontratuaren balioa',
  lpc_invested_amount: 'Inbertitutako zenbatekoa',
  lpc_funds_details_and_summary: 'Funtsaren xehetasunak eta laburpena',
  lpc_separate_management_detail: 'Kudeaketa bereiziaren xehetasuna',
  lpc_denuncia_sinistro: 'Erreklamazioaren jakinarazpena',
  lpc_duration_in: 'Iraupena -',
  lpc_years: 'urteak',
  lpc_months: 'hilabeteak',
  lpc_days: 'egunak',
  lpc_policy_number: 'Poliza-zenbakia',
  lpc_proposal_number: 'Proposamen-zenbakia',
  lpc_LifeBeneficiary: 'Onuraduna, bizirik egon bitartean',
  lpc_DeathBeneficiary: 'Onuraduna, heriotza gertatuz gero',
  lpc_NO: 'Ez',
  lpc_claim_data: '',
  lpc_node: 'Nodoa',
  lpc_sbj: 'Gaia',
  lpc_empty: 'hutsik',
  lpc_search_result: 'Bilaketaren emaitzak',
  lpc_show_elemets: 'Erakutsi elementuak',
  lpc_insured: 'Aseguruduna',
  lpc_notification_status: 'Jakinarazpenaren egoera',
  lpc_nuova_denuncia: 'jakinarazpen berria',
  lpc_insured_policy: 'Aseguratutako polizak',
  lpc_product: 'Produktua',
  lpc_find: 'Bilatu',
  lpc_status: 'Egoera',
  lpc_fund_trend: 'Funtsaren joera',
  lpc_category_fund: 'Profila/Funtsa',
  lpc_equivalent_on_date: 'Baliokidea datan',
  lpc_quotes_number: 'Unitateen zenbakia',
  lpc_damages_policy: 'Kalteen gaineko poliza',
  lpc_life_policy: 'Bizitzaren gaineko poliza',
  lpc_next_installment_premium: 'Hurrengo kuotaren prima',
  lpc_no_result: 'Emaitzarik ez',
  lpc_data_value: 'Data Balioa Parte-hartzea',
  lpc_paidAmount: 'Ordaindutako zenbatekoa',
  lpc_total_gross_surrender: 'Diru-itzulketa gordina, guztira',
  lpc_left_premiums_paid: 'Geratzen diren ordaindutako primak',
  lpc_left_premiums_amount: 'Geratzen diren ordaindutako primen zenbatekoa',
  lpc_portfolio_volatility_on_subscription_date: 'Zorroaren hegakortasuna harpidetza-datan',
lpc_portfolio_volatility: 'Zorroaren hegakortasuna',
  lpc_andamento_controvalore: 'Balio nominalaren bilakaera',
  lpc_policy_trend: 'Polizaren bilakaera',
  lpc_calculate: 'Kalkulatu',
  lpc_frequency: 'Maiztasuna',
  lpc_date_variation_step: 'Aldaketaren data',
  lpc_quotation_step: 'Kotizazioa',
  lpc_confirm_variation_step: 'Baieztatu aldaketa',
  lpc_financial_questionnaire_step: 'Finantza-galdetegia',
  lpc_close_button: 'Itxi',
  lpc_Operation_of_executed_correctly: '{{operation}} eragiketa egin da',
  lpc_AuthorizatioPolicyForwarded: '{{policyNumber}} poliza-zenbakirako baimen-eskaera dagokion erabiltzaileari bidali zaio',
  lpc_are_you_sure_you_want_to_cancel_this_operation: 'Ziur eragiketa hori ezeztatu nahi duzula?',
  lpc_cancel_button: 'Ezeztatu',
  lpc_cancel_reservation: 'Ezeztatu erreserba',
  lpc_system_error: 'Sistemaren errorea',
  lpc_generic_error_title: 'Errorea',
  lpc_select_label: 'Hautatu',
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: 'Adi, polizaren titularra ezabatzen ari zara. Jarraitu nahi duzu?',
  lpc_factor_must_be_equal: '{{factor}} faktoreak {{value}} izan behar du',
  lpc_factor_must_be_greater_than: '{{factor}} faktoreak {{value}} baino handiagoa izan behar du',
  lpc_factor_must_be_less_than: '{{factor}} faktoreak {{value}} baino txikiagoa izan behar du',
  lpc_Please_enter_threshold_percentage_value: 'Funtsa: {{value}} - Sartu atalasearen ehunekoa',
  lpc_please_enter_a_value_between: 'Sartu {{min}} eta {{max}} arteko balioa',
  lpc_variation_step_label: 'Aldaketa',
  lpc_contractual_option: 'Kontratuaren aukera',
  lpc_costs_step_label: 'Kostuak',
  lpc_variation_detail_step_label: 'Aldaketaren xehetasuna',
  lpc_ongoing_operations: 'Balioak aldatu egin daitezke uneko eragiketen ondorioz',
  lpc_exchange_rate: 'Aldaketa mota',
  lpc_last_share_value: 'Ekintzaren azken balioa',
  lpc_movement_description: 'Mugimenduaren deskribapena',
  lpc_settlement_amount: 'Akordioaren zenbatekoa',
  lpc_Annual_SinglePremium: 'Urteko prima / Prima bakarra',
  lpc_policy_address: 'Polizaren helbidea',
  lpc_holders: 'Titularrak',
  lpc_last_revaluation: 'Azken balio-handitzea',
  lpc_revalueted_benef: 'Mozkinaren balioa handitu da',
  lpc_investment: 'Inbertsioak',
  lpc_position_number: 'Posizio-zenbakia',
  lpc_invested_premium: 'Inbertitutako prima',
  lpc_operation_details: 'Eragiketaren xehetasunak',
  lpc_contract: 'Kontratua',
  lpc_dis_date: 'Desinbertsio-data',
  lpc_gross_amount: 'Zenbateko gordina',
  lpc_net_amount: 'Zenbateko garbia',
  lpc_net: 'Garbia',
  lpc_quote: 'Birkalkulatu',
  lpc_quote_and_save: 'Birkalkulatu eta gorde',
  lpc_info_investment_complete: 'Informazioa: Inbertsioa egin da',
  lpc_effective_holders: 'Titular efektiboak',
  lpc_effective_holder: 'Titular efektiboa',
  lpc_third_party_role: 'Hirugarren ordaintzailea',
  lpc_lien_credit: 'Hutsaltze-arrazoia',
  lpc_end_validity_date: 'Baliozkotasunaren amaiera-data',
  lpc_start_date: 'Hasiera-data',
  lpc_unit_linked_value: 'Lotutako unitatearen balioa',
  lpc_allocation_profile: 'Esleipen-profila',
  lpc_search_filter: 'Bilaketa-iragazkia',
  lpc_expiry_option_detail: 'Mugaegunaren aukeraren xehetasunak',
  lpc_annuity_frequency: 'Urterokoaren maiztasuna',
  lpc_annuity_payment_type: 'Urterokoaren ordainketa mota',
  lpc_percentage_reversibility: 'Itzulgarritasun-ehunekoa',
  lpc_reversionary: 'Itzulgarria',
  lpc_certain_revenue_years: 'Diru-sarreren urte zehatzak',
  lpc_postponement_type: 'Atzerapen mota',
  lpc_postponement_years: 'Atzerapen urteak',
  lpc_clauses: 'Klausulak',
  lpc_active_contractual_option: 'Kontratuaren aukera aktiboak',
  lpc_expiry_option: 'Amaitzeko aukera',
  lpc_revaluation_history: 'Balio-handitzearen historia',
  lpc_monetary_fund: 'Diru funtsa',
  lpc_benefit_effect_date: 'Mozkinaren eragin-data',
  lpc_initial_benefit: 'Hasierako mozkina',
  lpc_movement_update: 'Mugimenduaren eguneratzea',
  lpc_update_effect_date: 'Eguneratzearen eragin-data',
  lpc_net_rate: 'Tasa garbia',
  lpc_macro_category: 'Makrokategoria',
  lpc_quotation_frequency: 'Kotizazio-maiztasuna',
  lpc_volatility: 'Hegakortasuna',
  lpc_volatility_date: 'Hegakortasun-data',
  lpc_start_quotation: 'Kotizazioaren hasiera',
  lpc_last_quotation: 'Azken kotizazioa',
  lpc_valuation: 'Balioztapena',
  lpc_percentage_total: 'Ehunekoa, guztira',
  lpc_euro_valuation_not_fund_currency: 'Balioztapena eurotan adierazten da; ez funtsaren monetan',
  lpc_disinvstements: 'Desinbertsioak',
  lpc_payment_amount: 'Ordainketaren zenbatekoa',
  lpc_surrender_distribution: 'Kontuen ematearen banaketa',
  lpc_formula: 'Formula',
  lpc_roles_connected_benef: 'Onuradunari lotutako eginkizunak',
  lpc_quotation_details: 'Kotizazioaren xehetasunak',
  lpc_Disinvestmentsfunds: 'Desinbertsio-funtsak',
  lpc_same_fund_distribution: 'Funtsen banaketa hasierako gordailutzearen berdina da',
  lpc_not_same_fund_distribution: 'Funtsen banaketa aldatu da hasierako gordailutzearekin konparatuz',
  lpc_guarantees_oveview: 'Bermeen laburpenak',
  lpc_calculate_volatility: 'Kalkulatu aktiboaren hegakortasuna',
  lpc_benef_death_not_same: 'Heriotzaren ondoriozko onuraduna ezin da subjektu aseguruduna izan',
  lpc_global_percentage_equal_hundred: 'Ehuneko globalak 100 izan behar du',
  lpc_effective_holder_natural_person_req: '{{benefRole}} {{benefCat}} baten benetako titularraren eginkizuna duen {{linkedName}} subjektuak pertsona fisikoa izan behar du',
  lpc_benefPresente_default_msg: 'Subjektua onuradun gisa hautatua izan da jada kategoria horretan.',
  lpc_effective_holder_duplicate: '{{linkedName}} subjektua {{benefName}} titular efektiboaren eginkizunerako hautatua izan da jada.',
  lpc_effective_holder_adult_req: '{{benefRole}} {{benefCat}} baten titular efektiboaren eginkizuna duen {{linkedName}} subjektuak heldua izan behar du.',
  lpc_investment_plan: 'Inbertsio-plana',
  lpc_contract_detail: 'Kontratuaren xehetasunak',
  lpc_variation_reason: 'Aldaketaren arrazoia',
  lpc_investment_plan_detail: 'Inbertsio-planaren xehetasunak',
  lpc_revaluation_rate: 'Balio-handitze tasak',
  lpc_benefit_commencement_date: 'Prestazioaren hasiera-data',
  lpc_update_effective_date: 'Eguneratzearen eragin-data',
  lpc_last_odds_value: 'Kuotaren azken balioa',
  lpc_total: 'Guztira',
  lpc__EFFOP: 'Eragin-data',
  lpc__OPZFR: 'Zatikapena',
  lpc__OPZPM: 'Ordainketa modua',
  lpc__OPBEN: 'Onuraduna',
  lpc__MINTS: 'Gutxieneko atalasearen ehunekoa',
  lpc__MAXTS: 'Gehieneko atalasearen ehunekoa',
  lpc__NUMOC: 'Gertaera-kopurua',
  lpc__AMTOP: 'Erreskatearen zenbatekoa',
  lpc__MALMT: 'Gutxieneko zenbatekoa',
  lpc__CIBAN: 'IBAN',
  lpc__PCOUP: 'Aldizkako kupoiaren ehunekoa',
  lpc_threshold_percentage: 'Atalasearen ehunekoa',
  lpc_pure: 'Sari purua',
  lpc_starting_fund: 'Hasierako funtsa',
  lpc_details: 'Xehetasunak',
  lpc_treshold: 'Atalasea',
  lpc_inv_date: 'Inbertsio-data',
  lpc_beneficiary_perc_req: '{{benefCat}} {{benefRole}} {{benefName}}: Sartu ehunekoa',
  lpc_beneficiary_perc_req_range: ' {{benefRole}} {{benefCat}} {{benefName}}: Ehunekoak 0.01 eta 100 bitartean egon behar du',
  lpc_invalid_total_position_percent: '"{{groupType}}" taldekatzerako ehuneko totalak {{cat}} onuradunean % 100 izan behar du',
  lpc_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Sartu alderdi-biltegiaren onuradun bat',
  lpc_benefPresente: '{{benefName}} dagoeneko sartu da {{benefCat}} {{benefRole}} gisa',
  lpc_benMorteNoAssic: '{{benefName}} {{benefRole}} gisa hautatutako {{benefCat}} dagoeneko asegurudun gisa erregistratu da.',
  lpc_life_beneficiary: 'Bizitza-onuraduna',
  lpc_death_beneficiary: 'Heriotza-onuraduna',
  lpc_beneficiary_position_mandatory: '{{benefCat}} {{benefName}} onuraduna, hautatu posizio-zenbaki bat',
  lpc_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lpc_reversionary_subject: 'Subjektu itzulgarria',
  lpc_gross_detail: '',
  lpc_type_of_covers: '',
  lpc_clausesinsuredClause: 'Asegurudunaren klausulak',
  lpc_clausespolicyClause: 'Polizaren klausulak',
  lpc_clausesriskClause: 'Arriskuaren klausulak',
  lpc_max_capital_amount: 'IMC – Importe Máximo de Capital',
  lpc_fiscal_period_M1: 'M1 – Monto acumulado al 31/12/2000',
  lpc_fiscal_period_M2: 'M2 - Monto acumulado desde el 1/1/2001 hasta el 31/12/2006',
  lpc_fiscal_period_M3: 'M3 - Monto acumulado desde el 1/1/2007',
  lpc_fiscal_period_capital_request_K1: 'Parte de M1 solicitada en capital',
  lpc_fiscal_period_capital_request_K2: 'Parte de M2 solicitada en capital',
  lpc_fiscal_period_capital_request_K3: 'Parte de M3 solicitada en capital',
  lpc_max_amount_exceeded: 'El importe solicitado en capital supera el máximo posible.',
  lpc_max_amount_error: 'El importe en capital solicitado debe ser totalmente asignado a los montos M1 y M2.',
  lpc_performance_data: 'Datos de rendimient',
  lpc_role_is_mandatory: '{{role}} es obligatorio',
  lpc_issue_authorized_proposal: '',
  lpc_confirm_prop_pol_auth: 'Confirmar propuesta/política en Autorización',
  lpc_settlement: 'Liquidación',
  lpc_annuity: 'Renta vitalicia',
  lpc_percentage_in_capital: '% en capital',
  lpc_insured_amount: 'Importe invertido',
  lpc_custom_field_mandatory_error: 'El campo {{field}} es obligatorio',
  lpc_print_docs: 'Imprimir documentos',
  lpc_revisionary_err: 'Ingresar sujeto reversionario',
  lpc_revisionary_perc_err: 'Ingresar porcentaje del sujeto reversionario',
  lpc_percentage_must_be_between: 'El porcentaje debe estar entre 0.01 y 100',
  lpc_PIP_UndeductedPremiums: '',
  lpc_contributionYear: '',
  lpc_annualPremium: '',
  lpc_undeductedPremium : '',
  lpc_reversionario: "Reversionary",
  lpc_firstEnrollmentComplementaryPension: '',
  lpc_PIP_amountForTaxation: '',
  lpc_PIP_amount: '',
  lpc_PIP_contribempl4Perc: '',
  lpc_PIP_reducttaxbltfr: '',
  lpc_PIP_aliqtfr: '',
  lpc_PIP_premdeducted2001: '',
  lpc_PIP_premdeductoffset: '',
  lpc_PIP_annuitytaxdeduct: '',
  lpc_pip_contributions_installment_detail: 'ES_Contributions Details',
  lpc_contribution_type: 'ES_Contribution type',
  lpc_contribution_amount: 'ES_Amount',
  lpc_contribution_year: 'ES_Year',
  lpc_contribution_month_from: 'ES_Month (from)',
  lpc_contribution_month_to: 'ES_Month (to)',
  lpc_contribution_thirteenth: 'ES_13^',
  lpc_contribution_fourteenth: 'ES_14^',
  lpc_incomplete_fund_investment: 'Inversión incompleta para los fondos',
  lpc_incomplete_profile_investment: 'Inversión incompleta para los perfiles',
  lpc_tax_credit: '',
  lpc_clausesinsuredClauses: '',
  lpc_clausespolicyClauses: '',
  lpc_clausesriskClauses: '',
  lpc_tariff_version: '',
  lpc_inbound_documents_mov: '',
};
export default LPC_EU_ES;
