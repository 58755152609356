import {PncPostsalesInputData} from '../model/pnc-postsales-input-data';
import {PncPsalesBaseState} from './pnc-postsales-state';

export function getRouteData<S extends PncPsalesBaseState>(st: S): PncPostsalesInputData {
  return {
    policyNumber: st.policyNumber,
    operationType: st.operationType,
    operations: st.operations,
    draftId: st.draftId,
    currentOperation: st.currentOperation,
    idParentSession: st.idParentSession,
    showQuotation: st.showQuotation,
    contractId: st.contractId,
    notes: st.notes,
    routesPrefix: st.routesPrefix,
    additionalDataMap: st.additionalDataMap,
    movementId: st.movementId,
    proposalNumber: st.proposalNumber,
  };
}
