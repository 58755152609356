import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  private baseApiUrl: string;
  private urlservice = '/claims';
  private headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    // this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getAccessNotes(cliamId: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/' + cliamId + '/accesses');
  } 

  getAccessNotesGroup(pageId: string, ispes: boolean) {
    let queryString: string = '';
    if (ispes) {
        queryString = '?potClaimId=' + pageId; 
    } else {
        queryString = '?claimId=' + pageId; 
    }
    queryString += '&interval=0';

    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/accesses/groupby' + queryString);
  }


  getAccountingMovements(cliamId: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/' + cliamId + '/accounting-movements');
  }

  getReserveMovements(cliamId: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/' + cliamId + '/reserves-movements');
  }

  getDocuments(pageId:string ,ispes: boolean) {
    let queryString: string = '';
    if(ispes){
      queryString = '?potClaimId=';
    } else {
      queryString = '?claimId=';
    }
    queryString+= pageId;
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/documents' + queryString);
  }



  getReserveNotes(cliamId: string) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/' + cliamId + '/reserves');
  }


  getUserNotes(pageId:string ,ispes: boolean) {
    let queryString: string = '';
    if(ispes){
      queryString = '?potClaimId=';
    } else {
      queryString = '?claimId=';
    }
    queryString+= pageId;
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/base-notes' + queryString);
  }

  getComments(cliamId: string, userNoteId: string) {
    // /{claimId}/user-notes/{userNoteId}/comments
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/' + cliamId + '/user-notes/' + userNoteId + '/comments');
  }

  insertComment(cliamId: string, userNoteId: string, message: string) {
    return this.httpClient.put(this.baseApiUrl + this.urlservice + '/' + cliamId + '/user-notes/' + userNoteId + '/comments',
           message, {headers: this.headers});
  }

  cancelComment(cliamId: string, userNoteId: string, userCommentId: string) {
    // @Path("/{claimId}/user-notes/{userNoteId}/comments/{userCommentId}")
     return this.httpClient.delete(this.baseApiUrl + this.urlservice + '/' + cliamId + '/user-notes/'
                                   + userNoteId + '/comments/' + userCommentId);
   } 

  insertManualNote(pageNumber: string, message: string, ispes: boolean) {
    let queryString: string = '';
    if(ispes){
      queryString = '?potClaimNumber=';
    } else {
      queryString = '?claimNumber=';
    }
    queryString+= pageNumber;
    return this.httpClient.put(this.baseApiUrl + this.urlservice + '/manual-notes' + queryString,
      message, { headers: this.headers });   
  }

  cancelManualNote(claimId: string, userNoteId: string, ispes: boolean) {   
    let queryString = '';    
    if (ispes) {
        queryString = '?potClaimId=' + claimId; 
    } else {
        queryString = '?claimId=' + claimId; 
    }    
    return this.httpClient.delete(
        this.baseApiUrl + this.urlservice + '/manual-notes/cancel' + queryString + '&userNoteId=' + userNoteId,
        { headers: this.headers }
    );
}


  getNotes(idClaim) {

    // return this.httpClient.get<Array<Diary>>(this.baseApiUrl + '/claims/activity/activitydictionary');

    const accessNotes = [
      {
        date: new Date('2020-03-21 11:13:00'),
        user: 'FDENUZZO'
      },
      {
        date: new Date('2020-03-20 08:38:21'),
        user: 'GGAGLIARDI'
      },
      {
        date: new Date('2020-03-19 11:35:30'),
        user: 'FDENUZZO'
      },
      {
        date: new Date('2020-03-19 9:37:30'),
        user: 'GGAGLIARDI'
      },
      {
        date: new Date('2020-03-18  8:43:27'),
        user: 'GGAGLIARDI'
      },
      {
        date: new Date('2020-03-17 14:53:25'),
        user: 'GGAGLIARDI'
      }
    ];

    const baseNotes = [
      {
        comments: [],
        date: new Date('2020-03-21  11:15:11'),
        text: 'Sinistro trasferito a UFFICIO ANTIFRODE',
        type: 'automatic',
        user: 'GGAGLIARDI'
      },
      {
        bulletColor: 'red',
        comments: [1, 2, 3],
        date: new Date('2020-03-21  11:15:10'),
        text: 'Nota antifrode: Comprovata fraudolenza del sinistro si gira per competenza all\'ufficio antifrode.',
        type: 'automatic',
        user: 'FDENUZZO'
      },
      {
        bulletColor: 'green',
        comments: [1, 2, 3],
        date: new Date('2020-03-20 08:39:19'),
        text: 'Modificato indicatore falso positivo',
        type: 'automatic',
        user: 'GGAGLIARDI'
      },
      {
        bulletColor: 'yellow',
        comments: [1, 2, 3],
        date: new Date('2020-03-20 01:03:57'),
        text: 'Ricalcolato livello di fraudolenza del sinistro: GIALLO',
        type: 'automatic',
        user: 'ADMIN'
      },
      {
        comments: [1],
        date: new Date('2020-03-19 11:38:17'),
        text: 'Si dispone incarico al medico legale Guido Geller',
        type: 'manual',
        user: 'FDENUZZO'
      },
      {
        comments: [1],
        date: new Date('2020-03-19 9:38:30'),
        cancelUser: 'FDENUZZO',
        cancelDate: new Date('2020-03-19 11:37:30'),
        text: 'Si può disporre incarico al medico legale Augusto De Marinis',
        type: 'canceled',
        user: 'GGAGLIARDI'
      },
      {
        comments: [1, 2, 3],
        date: new Date('2020-03-18 8:48:32'),
        text: 'Inserimento danneggiato SILVANO ROGI',
        type: 'automatic',
        user: 'GGAGLIARDI'
      },
      {
        comments: [1],
        date: new Date('2020-03-18 8:46:25'),
        text: 'Segnalato intervento autorità, si attende verbale.',
        type: 'manual',
        user: 'GGAGLIARDI'
      },
      {
        comments: [1],
        date: new Date('2020-03-18 8:45:26'),
        text: 'Caricato documento: MODULO CAI 2 FIRME su danneggiato PAOLO BITTA',
        type: 'automatic',
        user: 'GGAGLIARDI'
      },
      {
        comments: [1],
        date: new Date('2020-03-17  14:53:21'),
        text: 'Trasferimento automatico a liquidatore della pratica',
        type: 'automatic',
        user: 'GGAGLIARDI'
      },
      {
        comments: [],
        date: new Date('2020-03-17  14:53:20'),
        text: 'Inoltro cdl della pratica',
        type: 'automatic',
        user: 'GGAGLIARDI'
      }
    ];

    const movementNotes = [
      {
        date: new Date('2020-03-18 8:48:27'),
        technicalMovement: 'Variazione sinistro',
        accountingMovement: 'Preventivazione',
        accountingDate: '2020-03-18 8:48:28',
        party: 'Silvano Rogi',
        partyRole: 'Trasportato non proprietario nostro veicolo',
        typeOfLoss: 'Responsabilità Civile Lesioni',
        user: 'GGAGLIARDI'
      },
      {
        date: new Date('2020-03-17  14:53:19'),
        technicalMovement: 'Apertura sinistro',
        accountingMovement: 'Preventivazione',
        accountingDate: '2020-03-17',
        party: 'Luca Nervi',
        partyRole: 'Conducente proprietario veicolo di controparte',
        typeOfLoss: 'Responsabilità Civile Veicolo',
        user: 'GGAGLIARDI'
      },
      {
        date: new Date('2020-03-17 14:53:17'),
        technicalMovement: 'Apertura sinistro',
        accountingMovement: 'Preventivazione',
        accountingDate: '2020-03-17  14:53:20',
        party: 'Paolo Bitta',
        partyRole: 'Conducente proprietario nostro veicolo',
        typeOfLoss: 'Responsabilità Civile Veicolo',
        user: 'GGAGLIARDI'
      }
    ];

    const reserveNotes = [
      {
        date: new Date('2020-03-18 8:48:30'),
        reserveAmount: 6950,
        currencySymbol: '¥'
      },
      {
        date: new Date('2020-03-17  14:53:20'),
        reserveAmount: 2950,
        currencySymbol: '€'
      },
      {
        date: new Date('2020-03-17  14:53:18'),
        reserveAmount: 1500,
        currencySymbol: '€'
      }
    ];

    return { accessNotes, baseNotes, movementNotes, reserveNotes };
  }
}
