<form [formGroup]="group">
  <rgi-rx-form-field>
    <label rgiRxLabel>
      <lic-help-icon [helpFile]="config.helpFile" [title]="value"></lic-help-icon>
      <span>{{value}}</span>
    </label>
        <input
          *ngIf="isInputType; else isTooLongAndNotModifiable"
          licFormatFactor
          (blur)="onBlur()"
          [factorInput]="config"
          [formControlName]="config.code"
          (paste)="onPaste($event)"
          class="form-control"
          [attr.data-qa]="'factor' + config.code"
          [attr.data-mod] = "'factor' + config.code + modifiable"
          [attr.data-obb] = "'factor' + config.code + config.compulsory"
          rgiRxInput
        >
      <ng-template #isTooLongAndNotModifiable>
        <textarea
          style="overflow: hidden; resize: none;"
          rows="4"
          [formControlName]="config.code"
          licFormatFactor
          (blur)="onBlur()"
          [factorInput]="config"
          class="form-control"
          [attr.data-qa]="'factor' + config.code"
          [attr.data-mod] = "'factor' + config.code + modifiable"
          [attr.data-obb] = "'factor' + config.code + config.compulsory" rgiRxInput>
          {{configValue}}
        </textarea>
      </ng-template>

  </rgi-rx-form-field>
</form>
