import { CodiceDescrizione } from "./dsc-model-utility";

export class ClaimsAddress {
    toponymStr: string = '';
    placeAddress: string = '';
    number: string = '';
    postCode: string = '';
    city: string = '';
    province: CodiceDescrizione | string = null;
    contryCode: string = '';
    phoneNumber: string = '';
    emailAddress: string = '';
}

export class DictionaryType {
    id: string;
    value: string;
}

export class CityDetail {
    capgenerico: string;
    description: string;
    descrizioneprovincia: string;
    nomecomune: string;
    siglaprovincia: string;
}

export class AddressEntityData {
    country: string = 'ITALIA';
    countryCode: string = 'IT';
    postCode: string = '';
    city: string = '';
    cityCode?: string = '';
    address: string = null;
    houseNumber?: string = null;
    adminLevel1?: string = '';
    adminLevel2?: string = '';
    adminLevel3?: string = '';
    toponym?: Toponym = null;
    province?: string = '';
    isValidForm: boolean = false;
    editability: boolean = true;
    umaAddress?: UmaAddress = null;
    isRequiredAddress: boolean = false;
    isRequiredHouseNumber: boolean = false;
}

export class UmaAddress {
    adminLevel2: string;
    adminLevel2Short: string;
    adminLevel3: string;
    cap: string;
    countryCode: string;
    normalized: boolean;
    number: string;
    placeAddress: string
  }


export class AddressType {
    address: Address;
}

export class Address {
    adminLevel2: string;
    adminLevel3: string;
    countryCode: string;
    cap: string;
    toponym: Toponym;
    placeAddress?: string;
    number: string;
}

export class Toponym {
    identification?: string;
    code?: string;
    description: string;
}
export class NormalizedToponym {
    codice?: string;
    descrizione: string;
}
export class SelectValue {
    id: string;
    value: string;
}

export class ZipCode {
    capgenerico: string;
    description: string;
    descrizioneprovincia: string;
    nomecomune: string;
    siglaprovincia: string;
}

export class AddressResult {
    result: AddressResponse;
    extension?: any;
    normalizedAddresses?: NormalizedAddress[];
    msgLog?: string;
}

export class AddressResponse {
    codice: string;
    descrizione: string;
}

export class NormalizedAddress {
    objectId: any;
    country: any;
    city: any;
    cab: any;
    normalized: boolean;
    latitude: any;
    placeAddress: string;
    number: any;
    toponym: NormalizedToponym;
    cap: string;
    province: any;
    addressAddition: any;
    specialMention: any;
    countryCode: string;
    adminLevel3Short: string;
    longitude: any;
    formatAddress: string;
    adminLevel3: string;
    adminLevel1: string;
    subLocality: string;
    adminLevel2: string;
    locality: string;
    detailCode: any;
    adminLevel2Short: string;
    fraction: any;
    at: string;
    postalAddress: any;
    extensionSubject: any;
    adminLevel1Short: string;
    actions?: any;
}

export class DataTableAddressNormalizedClaims {
    formatAddress: string;
}


export class Country {
    codice: string;
    descrizione: string;
  }
  
  export class ToponymIt {
    codice: string;
    descrizione: string;
  }
  
  export class Province {
    codice: string;
    descrizione: string;
  }

  
  
  export class ExtensionSubjectProperties {
    chiave: string = '';
    valore: string = '';
  }
  
  export class ExtensionSubject {
    properties: ExtensionSubjectProperties[] = [];
  }

  export class AddressExt {
    objectId?: string;
    country: any;
    id?: any;
    city: any;
    cab: any;
    normalized: boolean;
    latitude: string;
    placeAddress: any;
    number: any;
    toponym: any;
    cap: any;
    province: any;
    addressAddition: any;
    specialMention: any;
    countryCode: any;
    adminLevel3Short: any;
    longitude: string;
    formatAddress: string;
    adminLevel3: any;
    adminLevel1: any;
    subLocality: any;
    adminLevel2: any;
    locality: any;
    detailCode: any;
    adminLevel2Short: any;
    fraction: any;
    at: any;
    postalAddress: any;
    extensionSubject: ExtensionSubject;
    adminLevel1Short: any;
  }
  
  
