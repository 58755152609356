import { Component, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { RoutableComponent } from '@rgi/portal-ng-core';
import {RgiCountryLayerNumberFormatPipe} from '@rgi/country-layer';
import {SummaryComponent, PolicyService, LifeSessionService, CardsNavigationService, SystemPropertiesService, ActionsService, MasterPolicyService, AuthService, SaveActions} from '@rgi/life-issue-card';
import { TranslationWrapperService } from '@rgi/life-issue-card';
import { RgiRxPushMessageHandlerService } from '@rgi/rx';
import { ExtensionPropertyChExt } from '../../public-api';

@Component({
  templateUrl: './summary-ext.component.html',
  styleUrls: ['./summary-ext.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class SummaryExtComponent extends SummaryComponent implements OnInit, RoutableComponent, OnDestroy {

  constructor(
    protected policyService: PolicyService,
    protected lifeSessionService: LifeSessionService,
    protected cardsNavigationService: CardsNavigationService,
    protected systemPropertiesService: SystemPropertiesService,
    protected actionsService: ActionsService,
    protected translate: TranslationWrapperService,
    protected masterPolicyService: MasterPolicyService,
    @Optional() protected questCacheManager: QuestionnaireCacheService,
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected rgiFormatter: RgiCountryLayerNumberFormatPipe,
    protected pushMessageHandler: RgiRxPushMessageHandlerService
  ) { super(policyService, lifeSessionService, cardsNavigationService, systemPropertiesService, actionsService,
    translate, masterPolicyService, questCacheManager, authService, modalService, rgiFormatter, pushMessageHandler); }


  ngOnInit() {
    super.ngOnInit();
    const adjustProposal = this.policyService.getExtensionProperty(ExtensionPropertyChExt.SHOW_ADJUST_PROPOSAL)?.valore === 'true';
    const counterproposal = this.policyService.getExtensionProperty(ExtensionPropertyChExt.SHOW_COUNTER_PROPOSAL)?.valore === 'true';
    this.generalDataMap = this.generateDynamicTable(this.dynamicTableGeneralData);
    this.rowsGeneralData = this.composeArray(Math.ceil(this.dynamicTableGeneralData.length / this.nCol));
    if (adjustProposal) {
      this.flowButtons.push({label: '_ADJUST_PROPOSAL_', code: 'adjustProposal'})
    }
    if (this.isFromAuth && counterproposal) {
      this.flowButtons.push({label: '_COUNTERPROPOSAL_', code: 'counterProposal'})
    }
    const haveToReplaceSaveButton = this.flowButtons.some(button => button.code === 'counterProposal' || button.code === 'adjustProposal');
    if (haveToReplaceSaveButton) {
      this.flowButtons = this.flowButtons.filter(button => button.code !== SaveActions.SAVE_FROM_AUTH);
    }
  }


  onSubmit(itemClicked: {label: string, code: string}, proposalSaveModal = null) {
    if (itemClicked.code === 'adjustProposal') {
      this.policyService.pushExtensionProperty(ExtensionPropertyChExt.COUNTER_PROPOSAL_VALUE, 'false');
      this.saveAutho();
      return;
    } else if (itemClicked.code === 'counterProposal') {
      this.policyService.pushExtensionProperty(ExtensionPropertyChExt.COUNTER_PROPOSAL_VALUE, 'true');
      this.saveAutho();
      return;
    }
    super.onSubmit(itemClicked, proposalSaveModal);
  }

}
