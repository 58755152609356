import {AnagTranslationsKeys} from './anag-translations-keys';

// Mantenere le keys alfabeticamente ordinate
export const ANAG_IT: AnagTranslationsKeys = {
  _ANAG_: {
    _BTN_: {
      _ADD_: 'Aggiungi',
      _ADD_ADDRESS_: 'Aggiungi Indirizzo',
      _BACK_: 'Indietro',
      _CALCULATE_: 'Calcola',
      _CANCEL_: 'Annulla',
      _CLOSE_: 'Chiudi',
      _CONFIRM_: 'Conferma',
      _DETAIL_: 'Dettaglio',
      _EDIT_: 'Modifica',
      _EMPTY_: 'Svuota',
      _FIND_: 'Trova',
      _NEW_: 'Nuovo',
      _NEW_PAYMENT_: 'Nuovo Pagamento',
      _NORMALIZE_: 'NORMALIZZA',
      _SAVE_: 'Salva',
      _DELETE_: 'Elimina',
      _DELETE_DEFINITIVELY_: 'Eliminazione Definitiva',
      _HISTORY_: 'Storico',
      _LIMITATION_MANAGEMENT_: 'Gestione Limitazione',
      _UNDO_: 'Annulla',
      _CONTINUE_: 'Continua'
    },
    _LABEL_: {
      _ACTIONS_: 'Azioni',
      _ACTION_TERRORISM_: 'Info Terrorismo',
      _ADDRESS_: 'Indirizzo',
      _AE_: 'A.R. Gruppo Att.',
      _AGENCY_: 'Agenzia',
      _ATI_ASSOCIATIONS_: 'Associazioni ATI',
      _BIRTH_DATE_: 'Data di Nascita',
      _BIRTH_PLACE_: 'Luogo di nascita',
      _BIRTH_SURNAME_: 'Cognome di nascita / Secondo Cognome',
      _CITIZENSHIP_: 'Cittadinanza',
      _CITY_OF_BIRTH_: 'Città di nascita',
      _CIVIL_STATE_: 'Stato Civile',
      _CLIENT_NUMBER_: 'Numero Cliente',
      _CLIENT_UNIQUE_KEY_: 'Chiave Univoca Soggetto',
      _COMPANY_NAME_: 'Ragione Sociale',
      _CONTACTS_: 'Contatti',
      _CORPORATE_FORM_: 'Forma societaria',
      _CORPORATE_SECTOR_: 'Settore Corporazione',
      _CORPORATION_: 'Ente',
      _COWORKER_: 'Codice fiscale collaboratore',
      _CREDIT_: 'Credito',
      _CRITERIA_: 'Criterio',
      _DEBIT_: 'Debito',
      _DOCUMENT_: 'Documento',
      _DOCUMENT_NO_: 'Numero documento',
      _DOCUMENT_TYPE_: 'Tipo documento',
      _EMAIL_: 'Email',
      _EMAIL2_: 'Email 2',
      _EMAIL3_: 'Email 3',
      _EXPIRATION_DATE_: 'Data scadenza',
      _FAX_NO_: 'Numero fax',
      _FEMALE_: 'Femmina',
      _FILL_ID_DATA_: 'Compilare dati identificazione soggetto',
      _FISCAL_CODE_: 'Codice fiscale',
      _FISCAL_CODE_VAT_NUMBER_: 'Codice fiscale / Partita iva',
      _GENDER_: 'Genere',
      _GENDER_IDENTITY_: 'Identità di genere',
      _INT_PREFIX_: 'Prefisso Int.',
      _IDENTIFICATION_DATA_: 'Dati Identificativi',
      _LANDLINE_NO_: 'Numero telefono',
      _PHONE_NO_: 'Numero telefono',
      _LEADER_: 'Leader ATI',
      _LINK_TYPE_: 'Tipo legame',
      _LINKED_PARTIES_: 'Legami',
      _MALE_: 'Maschio',
      _MAIN_CONTACT_: 'Contatto principale',
      _MANAGEMENT_NODE_: 'Nodo Gestione',
      _MOBILE_NO_: 'Numero Cellulare',
      _MOBILE_NO_2_: 'Numero Cellulare 2',
      _MUNICIPALITY_: 'Comune',
      _NAME_: 'Nome',
      _NATION_: 'Nazione',
      _NODES_: 'Nodi',
      _NO_ADDRESS_MSG_: 'Nessun indirizzo',
      _NUMBER_: 'Numero',
      _OPEN_DATE_: 'Data Registrazione',
      _PARTICIPANTS_: 'Partecipanti',
      _PARTIES_: 'Soggetti',
      _PARTY_: 'Soggetto',
      _PARTY_DATA_: 'Dati Soggetto',
      _PARTY_MANAGEMENT_: 'Gestione soggetti',
      _PARTY_MERGER_: 'Gestione accorpamenti',
      _PARTY_NUMBER_: 'Numero Soggetto',
      _PARTY_TYPE_: 'Tipologia Soggetto',
      _PAYMENT_: 'Pagamento',
      _PERSON_TYPE_: 'Tipo Persona',
      _POTICALLY_EXP: 'Soggetto politicamente esposto',
      _PHYSICAL_: 'Fisico',
      _PREFIX_: 'Prefisso',
      _PRIVACY_: 'Consensi Privacy',
      _PRIVACY_CONSENSUS_DATE_: 'Data',
      _CHANGED_ON_: 'Modificato il',
      _PRODUCTION_NODES_: 'Nodi di produzione',
      _PROFESSION_: 'Professione',
      _PROFESSION_DETAIL_: 'Dettaglio Professione',
      _REGISTERED_OFFICE_: 'Sede Legale',
      _RELEASE_AUTHORITY_: 'Autorità rilascio',
      _RELEASE_DATE_: 'Data di rilascio',
      _RELEASE_LOCATION_: 'Luogo di rilascio',
      _REQUIRED_FIELD_: 'Richiesto',
      _RESIDENCE_: 'Residenza',
      _ANTI_TERRORISM_: 'Antiterrorismo',
      _RESIDENCE_REGISTERED_OFFICE_: 'Residenza / Sede legale',
      _SAE_: 'A.R. Sottogruppo Att. (SAE)',
      _SALE_POINT_: 'Nodo',
      _SAVED_OK_: 'salvato correttamente',
      _SELECT_PARTY_: 'Seleziona Soggetto',
      _SEX_: 'Sesso',
      _STATUS_: 'Stato',
      _STATUS_NOT_PROCESSED_: 'Non processato',
      _STATUS_PROCESSED_: 'Processato',
      _SUBJECT_: 'Soggetto',
      _SUBJECT_DATA_: 'Dati soggetto',
      _SUBJECT_TYPE_: 'Tipo Soggetto',
      _SUBJECT_UNIQUE_KEY_: 'Chiave Univoca Soggetto',
      _SUBJNO_: 'Soggetto n.',
      _SUBSYSTEM_: 'Sottosistema',
      _SUBSYSTEM_PERSON_ID_: 'Identificativo Soggetto Sottosistema',
      _SURNAME_: 'Cognome',
      _SURNAMEORCOMPANY_: 'Cognome / Ragione Sociale',
      _VOID_: ' ',
      _VAT_NUMBER_: 'Partita iva',
      _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Data Modifica Antiterrorismo',
      _COUNTER_TERRORISM_INDICATOR_: 'Stato Antiterrorismo',
      _DOMICILES_: 'Domicili',
      _DOMICILE_: 'Domicilio',
      _PRINCIPAL_ADDRESS_: 'Recapito principale',
      _AVAILABILITY_: 'Reperibilità',
      _TYPE_: 'Tipo',
      _AVAILABILITY_START_DATE_: 'Data inizio reperibilità',
      _AVAILABILITY_END_DATE_: 'Data Fine Reperibilità',
      _AVAILABLE_FROM_: 'Ora reperibilità da',
      _AVAILABLE_UNTIL_: 'Ora reperibilità a',
      _INTERNATIONAL_DIALLING_CODE_: 'Prefisso internazionale',
      _LOCAL_AREA_DIAL_CODE_: 'Prefisso locale',
      _YES_: 'Sì',
      _NO_: 'No',
      _COUNTERTERRORISM_STATUS_: 'Stato Antiterrorismo',
      _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_: 'Data Modifica Antiterrorismo',
      _ORIGIN_: 'Origine',
      _LANGUAGE_: 'Lingua',
      _HONOR_TITLE_ : 'Titolo onorifico',
      _SONS_: 'Numero di figli a carico',
      _ROBINSON_LIST_: 'Registro delle opposizioni',
      _CITIZENSHIP_PERMIT_: 'Permesso di cittadinanza',
      _CITIZENSHIP_EXPIRE_: 'Data fine validità',
      _REG_COUNTRY_: 'Paese di Registrazione',
      _EXTERNALS_IDENTIFIERS_: 'Riferimenti Esterni',
      _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Identificativo Soggetto Sottosistema',
      _TAX_CLASSIFICATION_: 'Classificazioni Fiscali',
      _TIN_:'TIN / EIN / SSN USA​',
      _RISK_CLASSIFICATION_: 'Classificazione del Rischio',
      _REASON_CLASSIFICATION_ : 'Classificazione dei Motivi',
      _BASIS_CLASSIFICATION_ : 'Classificazione di Base',
      _CLASSIFICATION_DATE_ : 'Data della Classificazione',
      _COUNTRY_START_DATE_ : 'Data Inizio Paese',
      _COUNTRY_END_DATE_ : 'Data Fine Paese',
      _NO_TAX_CLASSIFICATIONS_ : 'Nessuna Classificazione Fiscale',
      _COUNTRY_ : 'Nazione' ,
      _NOMINATIVE_ : 'Nominativo',
      _HISTORY_CONSULTATION_: 'Consultazione storico',
      _DO_NOT_REPLY_: 'Non impostato',
      _DRIVING_LICENSE_TYPE_: 'Tipo patente',
      _DRIVER_QUALIFICATION_CARD_: 'CQC',
      _ADD_DRIVING_LICENSE_TYPE_: 'Aggiungi Patente',
      _PRIVACY_INFO_MSG_: 'Il campo consulta i file ufficiali di esclusione dalla pubblicità noti come Registro delle opposizioni e ci indica se il cliente è registrato o meno a tale registro\n' +
        'Se il Registro delle opposizioni  è impostato su Sì, non saremo in grado di inviare comunicazioni commerciali.\n' +
        'Se  il Registro delle opposizioni è impostato su No, le comunicazioni commerciali verranno inviate solo quando il campo Escludi Pubblicità è impostato su No.\n' +
        'Se il cliente non è stato consultato il campo appare senza valore.',
      _LEI_CODE_: 'Codice LEI',
      _RATING_: 'Rating',
      _RATING_DATE_: 'Data',
      _RATING_PERIOD_: 'Periodo',
      _RATING_USER_: 'Utente',
      _RATING_ORIGIN_: 'Origine',
      _RATING_HISTORY_: 'Storia',
      _COWORKERS_: 'Collaboratori',
      _COMPANY_: 'Compagnia'
    },
    _MSG_: {
      _ASK_DELETION_CONFIRM_: 'Confermi l\'eliminazione del soggetto?',
      _DELETION_CONFIRMED_: 'Il soggetto è stato eliminato correttamente',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'I campi segnati in rosso sono obbligatori o contengono valori non corretti',
      _ERROR_DATE_: 'Soggetto minore di 16 anni. Il consenso al trattamento dei dati s\'intende fornito dai genitori/tutori legali',
      _ERROR_DATE_SP_: 'Il consenso per il trattamento dei dati personali per minori di 14 anni',
      _INVALID_DATE_VALUE: 'Data non valida',
      _INVALID_FIELD_: 'Campo non valido',
      _LENGTHERR_: 'Lunghezza massima superata',
      _LINK_ERROR_: 'Soggetto già inserito',
      _LINK_ERROR_SAME_PARTY_: 'Impossibile legare il soggetto a se stesso',
      _NO_ELEMENT_TO_SHOW_: 'Non ci sono elementi da visualizzare',
      _NO_LINKED_PARTIES_: 'Nessun legame',
      _NO_RESULTS_: 'Nessun Risultato',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'Indirizzo normalizzato non trovato',
      _PATTERNERR_: 'Il campo può contenere solo lettere',
      _REFINE_SEARCH_: ' L\'estrazione è parziale. Si prega di affinare la ricerca',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Compilare almeno uno tra: Cognome/Nome Azienda, Cognome di nascita/Secondo Cognome, Chiave univoca, Numero cliente, numero di telefono, Numero soggetto o Identificativo soggetto sottosistema',
      _WRONG_INPUT_DATA_: 'Il flusso di Gestione Soggetti è stato invocato con dei dati di input non supportati',
      _INVALID_END_DATE_DOMICILE_: 'Inserire una data di fine reperibilità successiva alla data di inizio reperibilità!',
      _INVALID_START_DATE_DOMICILE_: 'Inserire una data inizio reperibilità precedente alla data di fine reperibilità!',
      _INHERITANCE_: 'Esiste già un\'anagrafica con la stessa chiave univoca nella rete di vendita. Premere conferma se si vuole utilizzare i dati del soggetto già censito in anagrafe,diversamente, non sarà possibile proseguire con questa valorizzazione della chiave univoca',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'Esiste già una classificazione fiscale valida per il paese, il tipo e il periodo selezionati',
      _INFO_HOMONYMIES_: 'Attenzione! Sono stati trovati soggetti potenzialmente omonimi.\n' +
        // tslint:disable-next-line:max-line-length
        'Selezionare un soggetto dalla lista per accedere in modifica dei suoi dati o il bottone CONTINUA per proseguire con l\'inserimento del nuovo soggetto.',
      _INFO_NATURAL_PERSON_ALLOWED_: 'Ammessi solo soggetti Fisici',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Ammessi solo soggetti Giuridici',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'La data rilascio dev\'essere antecedente alla data scadenza',
      _IBAN_INVALID_: 'Formato IBAN non corretto',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Inserire una data di scadenza successiva alla data di rilascio del documento',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: 'Confermare l\'eliminazione della classificazione corrente?',
      _ASK_DELETION_DOCUMENT_CONFIRM_: 'Confermare l\'eliminazione del documento corrente?',
      _DOC_ALREADY_EXIST: 'Numero di documento già presente',
      _INSERT_DOC_TYPE: 'Inserire il tipo documento prima di continuare.',
      _INVALID_FORMAT_: 'Formato non corretto.',
      _NORMALIZE_ERROR_MSG: 'È obbligatorio normalizzare l\'indirizzo'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Modifica Soggetto',
  _ANAG_TITLE_PARTY_DETAIL_: 'Dettaglio Soggetto',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Gestione Soggetti',
  _ANAG_TITLE_PARTY_ZOOM_: 'Zoom Soggetto',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Ricerca Soggetti',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: 'Risultati Omonimia'
};
