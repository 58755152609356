import {getCurrencySymbol} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {RgiCountryLayerContext} from '../rgi-country-layer.api';

// fixme this does not support context change
@Pipe({
  name: 'rgiCountryLayerCurrencyFormat'
})
export class RgiCountryLayerCurrencyFormatPipe implements PipeTransform {

  constructor(private context: RgiCountryLayerContext) {
  }

  transform(code: string, format: 'wide' | 'narrow' = 'narrow', locale?: string ): string {
    const LOCALE = !!locale ? locale : this.context.locale;

    const formatter = Intl.NumberFormat(LOCALE, {style: 'currency', currency: code}).formatToParts(0);
    const currency = formatter.find(part => part.type === 'currency')?.value;
    return currency;
  }

}
