import {AfterViewChecked, AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {AnagStateManagerPartyEditor} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {DIALOG_DATA, ModalService, OnModalClose} from '@rgi/rx/ui';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  ANAG_SEX,
  BUSINESS_SECTOR_ENUM,
  ECONOMIC_ACTIVITY_SUBGROUP_ENUM,
  GENDER_IDENTITY_ENUM,
  HONORIFIC_TITLE,
  LANGUAGE_ENUM,
  MARITAL_STATUS_ENUM
} from '../../anag-constants/enums-constants';
import {AnagBirthAddress, AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagCountry} from '../../anag-model/anag-domain/anag-country';
import {CORE_ADDRESS_FUNCTION} from '../../anag-constants/anag-constants';
import {RgiRxEventService, PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {of, Subscription} from 'rxjs';
import {AnagApiAddress, AnagApiParty, AnagPartyKey} from '../../anag-model/anag-domain/anag-api-party';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagConfiguration} from '../../anag-model/anag-domain/anag-configuration';
import {AnagConfigService} from '../../anag-resources/anag-config.service';
import {AnagFormFieldConfig} from '../../anag-model/anag-domain/anag-form-field-config';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagDialogComponent, ModalDialogData} from '../modal/anag-dialog/anag-dialog.component';

import {DatePipe} from '@angular/common';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {OutputMajorAge} from '../../anag-model/anag-api/anag-api-major-age.config';
import {OperatorLight} from '../../services/external-service';
import {RgiRxUserService} from '@rgi/rx/auth';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'rgi-anag-identification-data',
  templateUrl: './identification-data.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class IdentificationDataComponent implements OnInit, OnModalClose, OnDestroy, AfterViewChecked {

  inputParty: AnagApiParty;
  defaultPartyNation: string;
  config: AnagConfiguration;
  tmpBirthAddress: AnagBirthAddress;
  subscriptions: Subscription = new Subscription();
  modalClose: EventEmitter<any> = new EventEmitter();
  respMajorAge: OutputMajorAge;
  identificationDataForm: UntypedFormGroup;
  physicalPartyForm: UntypedFormGroup;
  legalPartyForm: UntypedFormGroup;
  partyKeyForm: UntypedFormGroup;
  initialValues: { [key: string]: any } = {};
  partyKeyConf: AnagPartyKey;
  changedPartyKey = false;
  identificationDataFormMap: Map<string, AnagFormFieldConfig>;
  submitted: boolean;
  isDisabled: boolean;
  isHidden: boolean = false;
  birthAddressForm = new UntypedFormGroup({});

  constructor(
    public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public eventService: RgiRxEventService,
    public anagStatelessService: AnagStatelessService,
    public configService: AnagConfigService,
    public pushMessageHandler: PushMessageHandlerService,
    public datePipe: DatePipe,
    protected translateService: RgiRxTranslationService,
    protected modalService: ModalService,
        protected userService: RgiRxUserService,
    @Optional() @Inject(DIALOG_DATA) party: AnagApiParty
  ) {
    this.inputParty = party;
    this.defaultPartyNation = this.userService.getUser<OperatorLight>().salePoint ?
      this.userService.getUser<OperatorLight>().salePoint.context : 'IT';
    if (!this.inputParty.countryOfBirth || !this.inputParty.countryOfBirth.codice) {
      this.inputParty.countryOfBirth = new AnagEntityIta(this.defaultPartyNation, undefined);
    }
  }

  ngAfterViewChecked (): void {
    if(this.birthAddressForm.controls.addressForm) {
      this.configService.setConfigToFormAddress(this.birthAddressForm.controls.addressForm, this.config);
    }
  }

  ngOnInit() {
    this.pushMessageHandler.clearTag('fiscalCodeMsg-tag');
    this.pushMessageHandler.clearTag('errorMsg-tag');
    this.initForms();
    this.tmpBirthAddress = this.anagStatelessService.deepClone(this.inputParty.birthAddress);
    this.config = this.stateMgr.getCurrentState().configuration;
    this.configService.setConfigToForm(this.identificationDataFormMap, this.config);
    this.isHidden = this.checkVisibilityLeiCode();
    this.setPartyToForm();
    if (this.partyKeyForm.get('country') && this.partyKeyForm.get('country').value) {
      this.stateMgr.retrievePartyKeyConf(this.partyKeyForm.get('country').value, this.partyKeyForm);
    } else if (this.inputParty.countryOfBirth && this.inputParty.countryOfBirth.codice) {
      this.stateMgr.retrievePartyKeyConf(this.inputParty.countryOfBirth.codice, this.partyKeyForm);
    }
   // this.stateMgr.getCitizenshipPermitTypes(this.defaultPartyNation);
    this.manageSubforms();
    this.manageFormUpdatesEvents();
    this.physicalPartyForm.get('birthDate').valueChanges.pipe(distinctUntilChanged()).subscribe(birthDate => {
      if (this.physicalPartyForm.get('birthDate').valid) {
        this.isBirthDateAllowed(birthDate);
      }
    });
    this.partyKeyForm.controls.country.disable();
  }

   checkVisibilityLeiCode() : boolean{
    for (let i = 0; i < this.config.configurations.length; i++) {
      const configuration = this.config.configurations[i];
      if (configuration.label === 'PadatisingoliCleicode') {
        return configuration.configuration.codice === '2';
      }
    }
  }

  initForms() {
    this.identificationDataForm = new UntypedFormGroup({});
    this.physicalPartyForm = new UntypedFormGroup({});
    this.legalPartyForm = new UntypedFormGroup({});
    this.partyKeyForm = new UntypedFormGroup({
      country: new UntypedFormControl('', [Validators.required])
    });
    this.identificationDataFormMap = new Map<string, AnagFormFieldConfig>([
      ['economicActivitySubgroup', new AnagFormFieldConfig('eaSubgroup', this.identificationDataForm, [])],
      ['economicActivityGroup', new AnagFormFieldConfig('atecoCode', this.identificationDataForm, [])],
      ['surname', new AnagFormFieldConfig('surname', this.physicalPartyForm, [])],
      ['name', new AnagFormFieldConfig('name', this.physicalPartyForm, [])],
      ['birthDate', new AnagFormFieldConfig('dateOfBirth', this.physicalPartyForm, [])],
      ['leiCode', new AnagFormFieldConfig('PadatisingoliCleicode', this.identificationDataForm, [])],
      ['gender', new AnagFormFieldConfig('sex', this.physicalPartyForm, [])],
      ['genderIdentity', new AnagFormFieldConfig('gender', this.physicalPartyForm, [])],
      ['maritalStatus', new AnagFormFieldConfig('maritalStatus', this.physicalPartyForm, [])],
      ['citizenship', new AnagFormFieldConfig('citizenship', this.physicalPartyForm, [])],
      ['companyName', new AnagFormFieldConfig('denomination', this.legalPartyForm, [])],
      ['openDate', new AnagFormFieldConfig('companyOpen', this.legalPartyForm, [])],
      ['corporateSector', new AnagFormFieldConfig('corporateSector', this.legalPartyForm, [])],
      ['partyKey1', new AnagFormFieldConfig('PasubjectkeyCkey1', this.partyKeyForm, [])],
      ['partyKey2', new AnagFormFieldConfig('PasubjectkeyCkey2', this.partyKeyForm, [])],
      ['partyKey3', new AnagFormFieldConfig('PasubjectkeyCkey3', this.partyKeyForm, [])],
      ['politicallyExp', new AnagFormFieldConfig('politicallyExp', this.physicalPartyForm, [])],
      ['birthName', new AnagFormFieldConfig('birthName', this.physicalPartyForm, [])],
      ['language', new AnagFormFieldConfig('language', this.identificationDataForm, [])],
      ['honorTitle', new AnagFormFieldConfig('honoraryTitle', this.physicalPartyForm, [])],
      ['sonsNumber', new AnagFormFieldConfig('sonsNumber', this.physicalPartyForm, [Validators.min(0)])],
      ['citizenshipPermit', new AnagFormFieldConfig('citizenshipPermit', this.physicalPartyForm, [])],
      ['citizenshipExpire', new AnagFormFieldConfig('citizenshipExpire', this.physicalPartyForm, [])],
      ['regCountry', new AnagFormFieldConfig('regCountry', this.legalPartyForm, [])]
    ]);
  }

  disableCitizenshipPermit() {
    this.physicalPartyForm.controls.citizenshipPermit.disable();
    this.physicalPartyForm.controls.citizenshipPermit.setValidators(null);
    this.physicalPartyForm.controls.citizenshipPermit.setErrors(null);
    this.physicalPartyForm.get('citizenshipPermit').setValue('');

  }

  disableCitizenshipPermitExpire() {
    this.physicalPartyForm.controls.citizenshipExpire.disable();
    this.physicalPartyForm.controls.citizenshipExpire.setValidators(null);
    this.physicalPartyForm.controls.citizenshipExpire.setErrors(null);
    this.physicalPartyForm.get('citizenshipExpire').setValue('');
  }


  protected manageFormUpdatesEvents() {

    if (this.physicalPartyForm.get('citizenship').value === '' || this.defaultPartyNation === this.physicalPartyForm.get('citizenship').value) {

      if (this.physicalPartyForm.controls.citizenshipPermit) {
        this.disableCitizenshipPermit();
      }
      if (this.physicalPartyForm.controls.citizenshipExpire) {
        this.disableCitizenshipPermitExpire();
      }
    } else {
      this.physicalPartyForm.controls.citizenshipExpire.enable();
      this.physicalPartyForm.controls.citizenshipPermit.enable();
    }

    this.subscriptions.add(this.physicalPartyForm.get('citizenship').valueChanges.subscribe(citiCode => {
      const different = citiCode !== '' && this.defaultPartyNation !== citiCode;
      const state = this.stateMgr.getCurrentState();
      if (different) {
        // Leggi dalla configurazione se il campo è obbligatorio
        const isPermitMandatory = this.config.partyConfig.citizenshipPermit.configuration.codice === '1';
        if (isPermitMandatory && state.citizenshipPermitTypes.length>0) {
          this.physicalPartyForm.controls.citizenshipPermit.enable();
          this.physicalPartyForm.get('citizenshipPermit').setValue('');
          this.physicalPartyForm.controls.citizenshipPermit.setValidators([Validators.required]);
          this.physicalPartyForm.controls.citizenshipPermit.setErrors({required: true});
        } else {
          this.physicalPartyForm.controls.citizenshipPermit.enable();
        }

        if (state.citizenshipPermitTypes && state.citizenshipPermitTypes.length > 0) {
          if (this.physicalPartyForm.controls.citizenshipExpire) {
            this.physicalPartyForm.controls.citizenshipExpire.enable();
            this.physicalPartyForm.get('citizenshipExpire').setValue('');
            const isExpireMandatory = this.config.partyConfig.citizenshipExpire.configuration.codice === '1';
            if (isExpireMandatory) {
              this.physicalPartyForm.controls.citizenshipExpire.setValidators([Validators.required]);
              this.physicalPartyForm.controls.citizenshipExpire.setErrors({required: true});
            }
          }
        }
      } else {
        if (this.physicalPartyForm.controls.citizenshipPermit) {
          this.disableCitizenshipPermit();
        }
        if (this.physicalPartyForm.controls.citizenshipExpire) {
          this.disableCitizenshipPermitExpire();

        }
      }
    }));

    // this.subscriptions.add(this.partyKeyForm.get('country').valueChanges.subscribe(countryCode => {
    //   this.resetFormField(this.partyKeyForm, 'partyKey1');
    //   this.resetFormField(this.partyKeyForm, 'partyKey2');
    //   this.resetFormField(this.partyKeyForm, 'partyKey3');
    //   this.stateMgr.retrievePartyKeyConf(countryCode, this.partyKeyForm);
    // }));
    // RQP-7364
    if (this.identificationDataForm.get('economicActivitySubgroup')) {
      this.subscriptions.add(this.identificationDataForm.get('economicActivitySubgroup').valueChanges.subscribe(subgroup => {
        if (subgroup && subgroup !== '') {
        this.stateMgr.getEconomyActivityGroup(subgroup);
        } else {
          this.identificationDataForm.get('economicActivityGroup').setValue(null);
        }
      }));
    }
    this.subscriptions.add(this.stateMgr.getState$().pipe(map(state => state.partyKeyConf), distinctUntilChanged()).subscribe(keyConfig => {
        if (this.partyKeyForm.get('partyKey1')) {
          const key1Validators = this.key1Pattern !== '' ? [Validators.pattern(this.key1Pattern)] : [];
          if (this.partyKeyForm.get('partyKey1').validator) {
            const currValidators = this.partyKeyForm.get('partyKey1').validator({} as AbstractControl);
            if (currValidators && currValidators.required) {
              key1Validators.push(Validators.required);
            }
          }
          this.partyKeyForm.get('partyKey1').setValidators(key1Validators);
        }
        if (this.partyKeyForm.get('partyKey2')) {
          const key2Validators = this.key2Pattern !== '' ? [Validators.pattern(this.key2Pattern)] : [];
          if (this.partyKeyForm.get('partyKey2').validator) {
            const currValidators = this.partyKeyForm.get('partyKey2').validator({} as AbstractControl);
            if (currValidators && currValidators.required) {
              key2Validators.push(Validators.required);
            }
          }
          this.partyKeyForm.get('partyKey2').setValidators(key2Validators);
        }
        if (this.partyKeyForm.get('partyKey3')) {
          const key3Validators = this.key3Pattern !== '' ? [Validators.pattern(this.key3Pattern)] : [];
          if (this.partyKeyForm.get('partyKey3').validator) {
            const currValidators = this.partyKeyForm.get('partyKey3').validator({} as AbstractControl);
            if (currValidators && currValidators.required) {
              key3Validators.push(Validators.required);
            }
          }
          this.partyKeyForm.get('partyKey3').setValidators(key3Validators);
        }
      }
    ));
    // this.subscriptions.add(this.identificationDataForm.valueChanges.subscribe(() => {
    //   if (this.partyKeyForm.get('partyKey1')) {
    //     const key1Validators = this.key1Pattern !== '' ? [Validators.pattern(this.key1Pattern)] : [];
    //     if (this.partyKeyForm.get('partyKey1').validator) {
    //       const currValidators = this.partyKeyForm.get('partyKey1').validator({} as AbstractControl);
    //       if (currValidators && currValidators.required) {
    //         key1Validators.push(Validators.required);
    //       }
    //     }
    //     this.partyKeyForm.get('partyKey1').setValidators(key1Validators);
    //   }
    //   if (this.partyKeyForm.get('partyKey2')) {
    //     const key2Validators = this.key2Pattern !== '' ? [Validators.pattern(this.key2Pattern)] : [];
    //     if (this.partyKeyForm.get('partyKey2').validator) {
    //       const currValidators = this.partyKeyForm.get('partyKey2').validator({} as AbstractControl);
    //       if (currValidators && currValidators.required) {
    //         key2Validators.push(Validators.required);
    //       }
    //     }
    //     this.partyKeyForm.get('partyKey2').setValidators(key2Validators);
    //   }
    //   if (this.partyKeyForm.get('partyKey3')) {
    //     const key3Validators = this.key3Pattern !== '' ? [Validators.pattern(this.key3Pattern)] : [];
    //     if (this.partyKeyForm.get('partyKey3').validator) {
    //       const currValidators = this.partyKeyForm.get('partyKey3').validator({} as AbstractControl);
    //       if (currValidators && currValidators.required) {
    //         key3Validators.push(Validators.required);
    //       }
    //     }
    //     this.partyKeyForm.get('partyKey3').setValidators(key3Validators);
    //   }
    // }));

    this.subscriptions.add(this.physicalPartyForm.get('name').valueChanges.subscribe(name => {
      this.resetChangePartyKey();
    }));
    this.subscriptions.add(this.physicalPartyForm.get('surname').valueChanges.subscribe(surname => {
      this.resetChangePartyKey();
    }));
    this.subscriptions.add(this.physicalPartyForm.get('gender').valueChanges.subscribe(gender => {
      this.resetChangePartyKey();
    }));
    this.subscriptions.add(this.physicalPartyForm.get('birthDate').valueChanges.subscribe(dateOfBirth => {
      this.resetChangePartyKey();
    }));

  }

  protected manageSubforms() {
    if (this.stateMgr.isPartyPhysical) {
      this.identificationDataForm.addControl('physicalPartyForm', this.physicalPartyForm);
    } else {
      this.identificationDataForm.addControl('legalPartyForm', this.legalPartyForm);
    }
    this.identificationDataForm.addControl('partyKeyForm', this.partyKeyForm);
  }

  isBirthDateAllowed(dateOfBirth) {
    const ageSubject = this.calculateAge(this.datePipe.transform(dateOfBirth, 'yyyy-MM-dd'));
    if (this.respMajorAge) {
      if (ageSubject >= this.respMajorAge.majorAges[0].validAge) {
        this.pushMessageHandler.clearTag('data-tag');
      } else {
        this.notifyFormValidationInfoMsgAge(this.respMajorAge.message);
      }
    }
    this.datePipe.transform(dateOfBirth, 'dd-MM-yyyy');
  }

  calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    const notHasBirthdayPassed = (today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()));
    return notHasBirthdayPassed ? age - 1 : age;
  }

  protected setPartyToForm() {
    const state = this.stateMgr.getCurrentState();
    const party = this.inputParty;
    if (this.stateMgr.isPartyPhysical) {
      this.updateFormField(this.physicalPartyForm.get('surname'), party.surname);
      if (state.isUmaPartyHomonymyEnable && party.surname) {
        this.physicalPartyForm.get('surname').disable();
      }
      this.updateFormField(this.physicalPartyForm.get('name'), party.name);
      if (state.isUmaPartyHomonymyEnable && party.name) {
        this.physicalPartyForm.get('name').disable();
      }
      this.updateFormField(this.physicalPartyForm.get('birthName'), party.birthName);
      if (state.isUmaPartyHomonymyEnable && party.birthName) {
        this.physicalPartyForm.get('birthName').disable();
      }
      if (party.dateOfBirth) {
        this.updateFormField(this.physicalPartyForm.get('birthDate'), new Date(party.dateOfBirth));
        if (state.isUmaPartyHomonymyEnable && party.dateOfBirth) {
          this.physicalPartyForm.get('birthDate').disable();
        }
      }
      if (party.dateOfBirth) {
        this.isBirthDateAllowed(party.dateOfBirth);
      }
      if (party.sex) {
        this.updateFormField(this.physicalPartyForm.get('gender'), party.sex.codice);
      }

      if (party.genderIdentity) {
        this.updateFormField(this.physicalPartyForm.get('genderIdentity'), party.genderIdentity.codice);
      }
      if (party.maritalStatus) {
        this.updateFormField(this.physicalPartyForm.get('maritalStatus'), party.maritalStatus.codice);
      }
      if (party.citizenship) {
        this.updateFormField(this.physicalPartyForm.get('citizenship'), party.citizenship.codice);
      }
      if (party.sonsNumber) {
        this.updateFormField(this.physicalPartyForm.get('sonsNumber'), party.sonsNumber.toString());
      }
      if (party.citizenshipPermit) {
        this.updateFormField(this.physicalPartyForm.get('citizenshipPermit'), party.citizenshipPermit.toString());
      }
      if (party.citizenshipExpire) {
        this.updateFormField(this.physicalPartyForm.get('citizenshipExpire'), new Date(party.citizenshipExpire));
      }
      if (party.politicallyExp) {
        this.physicalPartyForm.get('politicallyExp').setValue(party.politicallyExp);
      }
    } else {
      this.updateFormField(this.legalPartyForm.get('companyName'), party.denomination);
      if (state.isUmaPartyHomonymyEnable && party.denomination) {
        this.legalPartyForm.get('companyName').disable();
      }
      if (party.dateOfBirth) {
        this.updateFormField(this.legalPartyForm.get('openDate'), new Date(party.dateOfBirth));
      }

      if (party.corporateSector) {
        this.updateFormField(this.legalPartyForm.get('corporateSector'), party.corporateSector.codice);
      }
      this.setRegCountry(party)
    }
    if (party.sottogruppoAE) {
      this.updateFormField(this.identificationDataForm.get('economicActivitySubgroup'), party.sottogruppoAE.code);
      this.stateMgr.getEconomyActivityGroup(party.sottogruppoAE.code);
    }
    if (party.language) {
      this.updateFormField(this.identificationDataForm.get('language'), party.language.codice);
    }
    if (party.salutation) {
      this.updateFormField(this.physicalPartyForm.get('honorTitle'), party.salutation.codice);
    }
    if (party.codAteco) {
      this.updateFormField(this.identificationDataForm.get('economicActivityGroup'), party.codAteco.code);
    }
    if (party.leicode) {
      this.updateFormField(this.identificationDataForm.get('leiCode'), party.leicode);
    }

    if (party.partyKey && party.partyKey[0]) {
      this.updateFormField(this.partyKeyForm.get('country'), party.partyKey[0].country);
      this.anagStatelessService.getMajorAge(this.defaultPartyNation).subscribe((resp: OutputMajorAge) => {
        if (resp.majorAges && resp.majorAges.length > 0) {
          this.respMajorAge = resp;
        }
      });
    }
    if (party.partyKey && party.partyKey[party.partyKey.length -1]) {
      const partyKey = party.partyKey[party.partyKey.length -1];
      if (partyKey.key1) {
        this.updateFormField(this.partyKeyForm.get('partyKey1'), partyKey.key1.value);
        this.initialValues.partyKey1 = partyKey.key1.value;
      }
      if (partyKey.key2) {
        this.updateFormField(this.partyKeyForm.get('partyKey2'), partyKey.key2.value);
        this.initialValues.partyKey2 = partyKey.key2.value;
      }
      if (partyKey.key3) {
        this.updateFormField(this.partyKeyForm.get('partyKey3'), partyKey.key3.value);
        this.initialValues.partyKey3 = partyKey.key3.value;
      }
    }
  }

  resetChangePartyKey() {
    if (this.stateMgr.isPartyPhysical && this.physicalPartyForm && this.partyKeyForm.controls.country.value === 'IT') {
      this.resetFormField(this.partyKeyForm, 'partyKey1');
      this.resetFormField(this.partyKeyForm, 'partyKey2');
      this.resetFormField(this.partyKeyForm, 'partyKey3');
    }
  }
  notifyFormValidationInfoMsgAge(infoMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(infoMsg, {
      tag: 'data-tag',
      status: 'default',
      dismissible: false
    }));
  }

  onLeiCodeChange(key) {
    if(!!(this.identificationDataForm.get(key).value)) {
      const val = this.identificationDataForm.get(key).value;
      if(val.length !==20 || !/^[a-zA-Z0-9]+$/.test(val)){
        this.identificationDataForm.get('leiCode').markAsDirty();
        this.identificationDataForm.get('leiCode').setErrors({'_ANAG_._MSG_._INVALID_FORMAT_' : true});
      }
      else{
        this.identificationDataForm.get('leiCode').setErrors(null);
      }


    }
  }

  onPartyKeyChange(key) {
    if (this.partyKeyForm.get(key).value !== this.initialValues[key]) {
      this.checkPartyKeyService();
      this.initialValues[key] = this.partyKeyForm.get(key).value;
    }
  }

  checkPartyKeyService() {
    const filter = this.createRequestCheckPartyKey();
    this.stateMgr.checkPartyKey(filter).subscribe(resp => {
      if (resp.outcome !== null) {
        this.notifyFormValidationErrorMsg(resp.outcome);
        this.isDisabled = true;
      } else {
        this.pushMessageHandler.clearTag('errorMsg-tag');
        this.changedPartyKey = true;
        this.isDisabled = false;
      }
    });
  }


  notifyFormValidationInfoMsg(infoMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(infoMsg, {
      tag: 'data-tag',
      status: 'default',
      dismissible: false
    }));
  }

  notifyFormValidationErrorMsg(errorMsg) {
    this.pushMessageHandler.clearTag('errorMsg-tag');
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
      tag: 'errorMsg-tag',
      status: 'danger',
      dismissible: false
    }));
  }


  updateIdentificationData() {
    const physicalPartyFormValid = this.stateMgr.isPartyPhysical && this.physicalPartyForm.valid;
    const legalPartyFormValid = !this.stateMgr.isPartyPhysical && this.legalPartyForm.valid;
    const keyPartyFormValid = !this.partyKeyForm.invalid;
    const identificationFormValid = !this.identificationDataForm.invalid;
    const addressIsValid = this.birthAddressForm.get('addressForm') ? this.birthAddressForm.get('addressForm').valid : true;
    const formsValid = (physicalPartyFormValid || legalPartyFormValid) && identificationFormValid && keyPartyFormValid && addressIsValid;
    this.submitted = true;
    const state = this.stateMgr.getCurrentState();
    const hasK1: boolean = this.partyKeyForm.controls.partyKey1 && this.partyKeyForm.controls.partyKey1.value;
    const hasK2: boolean = this.partyKeyForm.controls.partyKey2 && this.partyKeyForm.controls.partyKey2.value;
    const hasK3: boolean = this.partyKeyForm.controls.partyKey3 && this.partyKeyForm.controls.partyKey3.value;
    const hasPartyKey = hasK1 || hasK2 || hasK3;

    if (formsValid) {
      if (state.isUmaPartyCFDuplicate === true && state.isUmaPartyGroupCompanies === false && hasPartyKey) {
        // tslint:disable-next-line:max-line-length
        const oldParty = this.stateMgr.maintainForm(this.anagStatelessService.deepClone(state), this.identificationDataForm, this.tmpBirthAddress);
        this.stateMgr.duplicateParty(oldParty).subscribe(resp => {
          if (resp.result && resp.result.outcome) {
            switch (resp.result.outcome.codice) {
              case '1':
                this.handleDuplicatePartySuccess(oldParty, resp);
                break;
              case '2':
                this.notifyFormValidationErrorMsg(resp.outcome);
                break;
              default:
                break;
            }
          } else {
            this.updateIdentification();
          }
        });
      } else {
        this.updateIdentification();
      }
    } else {
      this.notifyFormValidationErrorMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_');
    }
  }

  notifyFormValidationMsg() {
    this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(stringMsg =>
      this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
        tag: 'privacy-tag',
        status: 'danger',
        dismissible: false
      }))
    ).unsubscribe();
  }

  getSubject(objectId: string, string2: string, param3) {
    this.stateMgr.getSubjectDetail(objectId, string2, null).subscribe(subject => {
      if (subject) {
        const newState = this.stateMgr.getCurrentState();
        const oldNode = newState.party.node;
        newState.party = subject.subject;
        newState.party.node = oldNode;
        this.stateMgr.updateState$(of(newState));
        this.openIdentificationDataComponent(newState.party);
      }
    });
  }

  openIdentificationDataComponent(party) {
    const stateMgrProvider = {
      provide: AnagStateManagerPartyEditor,
      useValue: this.stateMgr
    };
    this.modalService.openComponent(IdentificationDataComponent,
      this.anagStatelessService.deepClone(party), [stateMgrProvider]);
  }

  updateIdentification() {
    // tslint:disable-next-line:max-line-length
    if (((this.stateMgr.isPartyPhysical && this.physicalPartyForm.valid) || (!this.stateMgr.isPartyPhysical && this.legalPartyForm.valid)) && (this.partyKeyForm.valid || this.partyKeyForm.status === 'DISABLED')) {
      this.stateMgr.updateIdentificationData(this.identificationDataForm, this.tmpBirthAddress);
      this.actionClose();
    } else {
      this.notifyFormValidationMsg();
    }
  }

  get genders(): Array<AnagEntityIta> {
    return ANAG_SEX;
  }

  get genderIdentities(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(GENDER_IDENTITY_ENUM);
  }

  get maritalStatusList(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(MARITAL_STATUS_ENUM);
  }

  get languageList(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(LANGUAGE_ENUM);
  }

  get honorTitleList(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(HONORIFIC_TITLE);
  }

  get economicActivitySubgroups(): Array<AnagEntityIta> {
    return this.stateMgr.getEnumsByCode(ECONOMIC_ACTIVITY_SUBGROUP_ENUM);
  }

  get economicActivityGroups(): Array<AnagEntityIta> {
    let groups;
    const subgroup = this.identificationDataForm.get('economicActivitySubgroup');
    if (subgroup) {
      groups = this.anagStorage.getEconomyActivityGroupBySubgroupCode(subgroup.value);
      if (groups && groups.length > 0) {
        this.identificationDataForm.get('economicActivityGroup').enable({onlySelf: true, emitEvent: false});
      } else {
        this.identificationDataForm.get('economicActivityGroup').disable({onlySelf: true, emitEvent: false});
      }
    } else {
      this.identificationDataForm.get('economicActivityGroup').disable({onlySelf: true, emitEvent: false});
    }
    return groups;
  }

  get corporateSectors(): Array<AnagEntityIta> {
    return this.anagStorage.getEnumsByCode(BUSINESS_SECTOR_ENUM);
  }

  get countriesList(): Array<AnagCountry> {
    return this.stateMgr.countriesList;
  }

  getBirthAddress(): AnagApiAddress {
    return this.inputParty.birthAddress as AnagApiAddress;
  }

  actionClose() {
    this.modalClose.emit();
  }


  birthPlaceEnum() {
    return CORE_ADDRESS_FUNCTION.partyBirthAddress;
  }

  showRequired(form: UntypedFormGroup, field: string) {
    return this.submitted && form.get(field).valid;
  }

  get showCalculate() {
    return this.partyKeyForm.get('country') && this.partyKeyForm.get('country').value === 'IT' && this.stateMgr.isPartyPhysical;
  }

  protected updateFormField(control: AbstractControl, value: string | Date) {
    if (control && value && value !== control.value) {
      control.setValue(value);
    }
  }

  showFieldErr(form: UntypedFormGroup, field: string): boolean {
    return this.submitted && form.get(field).invalid;
  }

  showFieldRequired(form: UntypedFormGroup, field: string): boolean {
    // tslint:disable-next-line:max-line-length
    return form.get(field) && form.get(field).validator && form.get(field).validator({} as AbstractControl) && form.get(field).validator({} as AbstractControl).required;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateBirthAddress(address: AnagApiAddress) {
    this.tmpBirthAddress = address;
    this.resetChangePartyKey();
  }

  calculatePartyKey() {
    this.stateMgr.calculatePartyKey(this.identificationDataForm, this.tmpBirthAddress).subscribe(resp => {
      if(resp.outcome.messages[0]){
        this.notifyFiscalCodeErrorMsg(resp.outcome.messages[0]);
      } else {
        this.pushMessageHandler.clearTag('fiscalCodeMsg-tag');
        if (resp.partyUniqueKeyOutput && this.identificationDataForm.get('partyKeyForm') && this.identificationDataForm.get('partyKeyForm').get('partyKey1')) {
          this.identificationDataForm.get('partyKeyForm').get('partyKey1').setValue(resp.partyUniqueKeyOutput);
        }
      }
    })
  }

  notifyFiscalCodeErrorMsg(errorMsg) {
    this.pushMessageHandler.clearTag('fiscalCodeMsg-tag');
    this.pushMessageHandler.notify(new RgiRxPushMessage(errorMsg, {
      tag: 'fiscalCodeMsg-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  isCalculatePartyKeyDisabled() {
    let disabled = false;
    if (this.stateMgr.isPartyPhysical) {
      // tslint:disable-next-line:max-line-length
      disabled = disabled || (!this.physicalPartyForm.get('surname') || !this.physicalPartyForm.get('surname').value || /\d/.test(this.physicalPartyForm.get('surname').value));
      // tslint:disable-next-line:max-line-length
      disabled = disabled || (!this.physicalPartyForm.get('name') || !this.physicalPartyForm.get('name').value || /\d/.test(this.physicalPartyForm.get('name').value));
      disabled = disabled || (!this.physicalPartyForm.get('gender') || !this.physicalPartyForm.get('gender').value);
      // tslint:disable-next-line:max-line-length
      disabled = disabled || (!this.physicalPartyForm.get('birthDate') || !this.physicalPartyForm.get('birthDate').value || this.physicalPartyForm.get('birthDate').invalid);
      disabled = disabled || !(this.tmpBirthAddress && this.tmpBirthAddress.adminLevel3);
    }
    return disabled;
  }

  getDateErrorLabel(control) {
    if (this.submitted && control.errors) {
      if (control.errors.required) {
        return '_ANAG_._LABEL_._REQUIRED_FIELD_';
      } else {
        return control.errors.message;
      }
    }
    return null;
  }

  private resetFormField(formGroup: UntypedFormGroup, key: string) {
    if (formGroup && formGroup.get(key)) {
      formGroup.get(key).setValue(undefined);
    }
  }

  checkETypeOfKeys(key) {
    switch (key) {
      case '1':
        return '^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$';
      case '2':
        return '^\\d{11}$';
      case '6':
        return '^[ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw]\\d{7}[0-9A-Ja-j]$|^\\d{8}[A-Za-z]$';
      case '7':
        return '^[ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw]\\d{7}[0-9A-Ja-j]$';
      case '18':
        return '^756\\d{4}\\d{4}\\d{2}$';
      case '19':
        return '^[XYZxyz]\\d{7,8}[A-Za-z]$';
      default:
        break;
    }
  }

  get key1Pattern() {
    const state = this.stateMgr.getCurrentState();
    switch (this.partyKeyForm.get('country').value) {
      case 'IT':
        return state.partyKeyConf && state.partyKeyConf.key1 && state.partyKeyConf.key1.type && this.stateMgr.isPartyPhysical ?
          this.checkETypeOfKeys(state.partyKeyConf.key1.type.codice) : '';
      case 'ES':
      case 'CH':
        return state.partyKeyConf && state.partyKeyConf.key1 && state.partyKeyConf.key1.type ?
          this.checkETypeOfKeys(state.partyKeyConf.key1.type.codice) : '';
      default:
        break;
    }
  }

  get key2Pattern() {
    const state = this.stateMgr.getCurrentState();
    switch (this.partyKeyForm.get('country').value) {
      case 'IT':
      case 'ES':
      case 'CH':
        return state.partyKeyConf && state.partyKeyConf.key2 && state.partyKeyConf.key2.type ?
          this.checkETypeOfKeys(state.partyKeyConf.key2.type.codice) : '';
      default:
        break;
    }
  }

  get key3Pattern() {
    const state = this.stateMgr.getCurrentState();
    switch (this.partyKeyForm.get('country').value) {
      case 'ES':
      case 'CH':
        return state.partyKeyConf && state.partyKeyConf.key3 && state.partyKeyConf.key3.type ?
          this.checkETypeOfKeys(state.partyKeyConf.key3.type.codice) : '';
      default:
        break;
    }
  }

  get today() {
    return this.anagStatelessService.today();
  }

  get datepickerPlaceholder() {
    return this.anagStatelessService.getDatePickerPlaceholderLabel();
  }

  createRequestCheckPartyKey() {
    this.partyKeyConf = this.anagStatelessService.deepClone(this.stateMgr.getCurrentState().partyKeyConf);
    const emitter = {partyKey: this.partyKeyConf};
    if (this.identificationDataForm.getRawValue().partyKeyForm.partyKey1) {
      emitter.partyKey.key1.value = this.identificationDataForm.getRawValue().partyKeyForm.partyKey1;
    }
    if (this.identificationDataForm.getRawValue().partyKeyForm.partyKey2) {
      emitter.partyKey.key2.value = this.identificationDataForm.getRawValue().partyKeyForm.partyKey2;
    }
    if (this.identificationDataForm.getRawValue().partyKeyForm.partyKey3) {
      emitter.partyKey.key3.value = this.identificationDataForm.getRawValue().partyKeyForm.partyKey3;
    }
    this.anagStatelessService.extendsAnagPartyKey(this.physicalPartyForm,
      this.legalPartyForm,
      this.partyKeyConf);
    return emitter;

  }

  handleDuplicatePartySuccess(oldParty:any, duplicatePartyCheckResp: any) {
    this.translateService.translate('_ANAG_._MSG_._INHERITANCE_').subscribe(stringMsg => {
      const anagModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(stringMsg, true));
      this.modalClose.emit();
      anagModal.modal.onClose.subscribe(onCloseData => {
        if (onCloseData && onCloseData.confirm) {
          if (duplicatePartyCheckResp.result.messages[3] === '2') {
            this.getSubject(duplicatePartyCheckResp.result.messages[2], duplicatePartyCheckResp.result.messages[4], null);
          } else if (duplicatePartyCheckResp.result.messages[3] === '3') {
            this.getSubject(duplicatePartyCheckResp.result.messages[2], null, null);
          }
        } else {
          this.openIdentificationDataComponent(oldParty);
        }
      });
    }).unsubscribe();
  }

  setRegCountry(party) {
    if (party.regCountry) {
      this.updateFormField(this.legalPartyForm.get('regCountry'), party.regCountry);
    } else {
      if (!this.stateMgr.isPartyPhysical) {
        this.updateFormField(this.legalPartyForm.get('regCountry'), this.defaultPartyNation);
      }
    }
  }


}


function checkPartyKeyService() {
  throw new Error('Function not implemented.');
}

