<div *ngIf="(fivaStateManager.getState$() | async) as state" data-qa="root-component-wrapper" class="portal-navigation-wrapper fivaTable">
    <rgi-rx-datatable  [data]="state.ctpList" [disableSearch]="true" [schema]="schema" [sortHeader]="true" [pageOptions]="[5,10,20,50]">
        <ng-template rgiRxDataTableCell="action" let-ctx>
           <div>
                <button rgi-rx-button aria-label="example button template"
                (click)="deleteCtpDetail(ctx.row)"
                [disabled]="ctx.row.estatus != fivaStatusToCancel " color="info" class="rgi-ui-icon-delete"></button>
            </div>
        </ng-template>
    
    </rgi-rx-datatable>
    <div style="width:100%; display: flex; justify-content: end;">
        <button rgi-rx-button aria-label="example button template" color="info" class="btn btn-section rgi-ui-btn rgi-ui-btn-info"
        (click)="modalOpen('insert')">{{'_CLAIMS_._INSERT_NEW_REQUEST' | translate}}</button>
    </div>
  </div>

