import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Activity } from '../../model/domain/activity/activity';
import { map } from 'rxjs/operators';
import { AllActivity } from '../../model/domain/activity/activity-all';

@Injectable({
  providedIn: 'root'
})
export class ActivityListService {

  private baseApiUrlV2;
  private CONST_ACTIVITY_WORKFLOW: any;
  private authService: any;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject('CONST_ACTIVITY_WORKFLOW') CONST_ACTIVITY_WORKFLOW,
    @Inject('authService') authService: any

  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.CONST_ACTIVITY_WORKFLOW = CONST_ACTIVITY_WORKFLOW;
    this.authService = authService;
  }

  public getActivitiesFromClaimNumber(claimNumber: string) {
    return this.httpClient.get<Activity[]>(this.getActivityFromClaimNumberUri(claimNumber));//.pipe(map(this.mapFunction));
  }

  public getAllActivitiesFromClaimNumber(claimNumber: string) {
    return this.httpClient.get<AllActivity>(this.getAllActivityFromClaimNumberUri(claimNumber));/*.pipe(map(this.mapFunctionAllAct));*/
  }

  /*private mapFunction(elements: Activity[]) {
    elements.forEach(element => {
      element.expirationDate = new Date(element.expirationDate);
    });
    return elements;
  }*/

  /*private mapFunctionAllAct(allAct: AllActivity) {
    allAct.opened.forEach(element => {
      element.expirationDate = new Date(element.expirationDate);
    });
    allAct.closed.forEach(element => {
      element.expirationDate = new Date(element.expirationDate);
      if (element.updDate) {
        element.updDate = new Date(element.updDate);
      }
    });
    return allAct;
  }*/

  private getActivityFromClaimNumberUri(claimNumber: string) {
    return this.baseApiUrlV2 + '/claims/' + claimNumber + '/activities?user=' + this.username() + '&group=' + this.assigneeGroup();
  }

  private getAllActivityFromClaimNumberUri(claimNumber: string) {
    return this.baseApiUrlV2 + '/claims/' + claimNumber + '/activities/all/?user=' + this.username() + '&group=' + this.assigneeGroup();
  }

  private username() {
    return this.authService.getUsername();
  }

  private assigneeGroup() {
    return this.CONST_ACTIVITY_WORKFLOW.CDL_PREFIX + this.authService.getOperator().liquidationCentre.code;
  }
}
