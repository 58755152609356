import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Activity, ActivityListService, ActivityVariable, ApiClaim,
  ApiTranslatorClaimService, CardComponent, EnumType
} from '@rgi/digital-claims-common-angular';
import { AllActivity } from '@rgi/digital-claims-common-angular/lib/model/domain/activity/activity-all';
import { ManualDeadlineComponent } from '../manual-deadline/manual-deadline.component';
import { RgiRxDataTableColumnSortDef, RgiRxDatatableComponent, RgiRxDataTableExpansionModel, RgiRxSwitchChange, RgiRxTableExpansionTrigger, TableRowTypeSchema, TableSchema } from '@rgi/rx/ui';

const canContract = (): RgiRxTableExpansionTrigger => (row: any) => {
  console.info('can contract?', row)
  return true
}

const CUSTOM_TEMPLATE_SORT_DEF: RgiRxDataTableColumnSortDef<Activity>[] = []

@Component({
  selector: 'claims-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent extends CardComponent implements OnInit {
  @ViewChild(RgiRxDatatableComponent, { static: true }) dt: RgiRxDatatableComponent;

  @Input() activitiesList = [];
  currentList: Activity[] = [];
  closedActivityList: Activity[] = [];
  private claim: ApiClaim;
  tabActive: boolean = true;
  priorityLabel: EnumType[] = this.enumService.getEnumList('activity.priority');

  protected hasClosed: boolean = false;



  constructor(
    @Inject('containerCacheService') private cache,
    @Inject('authService') private authService,
    @Inject('enumService') private enumService,
    public activityListService: ActivityListService,
    private apiTranslatorClaimService: ApiTranslatorClaimService,
    private modalService: NgbModal
  ) {
    super();
  }


  ngOnInit() {
    const jsonClaim = this.cache.getCache().get(this.card.idSession).data;
    this.claim = this.apiTranslatorClaimService.jsonClaimToApiClaim(jsonClaim);
    this.getActivitiesFromClaim(this.claim);
  }

  /* Ordinamento*/
  protected sortDef: RgiRxDataTableColumnSortDef<Activity>[] | boolean = CUSTOM_TEMPLATE_SORT_DEF

  private rowsClosedActivity: TableRowTypeSchema[] = [
    { name: 'activity', title: '_CLAIMS_._ACTIVITY' },
    { name: 'assigneeGroup', title: '_CLAIMS_._ASSIGNEE_GROUP' },
    { name: 'assigneeUser', title: '_CLAIMS_._ASSIGNEE_USER' },
    { name: 'involvedParty', title: '_CLAIMS_._ACTOR' },
    { name: 'typeOfLoss', title: '_CLAIMS_._TYPE_OF_LOSS' },
    { name: 'expirationDate', title: '_CLAIMS_._EXPIRATION_DATE' },
    { name: 'updDate', title: '_CLAIMS_._COMPLETION_DATE' },
    { name: 'descrPriority', title: '_CLAIMS_._PRIORITY' }
  ];

  private rowsOpenedActivity: TableRowTypeSchema[] = [
    { name: 'activity', title: '_CLAIMS_._ACTIVITY' },
    { name: 'assigneeGroup', title: '_CLAIMS_._ASSIGNEE_GROUP' },
    { name: 'assigneeUser', title: '_CLAIMS_._ASSIGNEE_USER' },
    { name: 'involvedParty', title: '_CLAIMS_._ACTOR' },
    { name: 'typeOfLoss', title: '_CLAIMS_._TYPE_OF_LOSS' },
    { name: 'updDate', title: '_CLAIMS_._RUN_DATE' },
    { name: 'expirationDate', title: '_CLAIMS_._EXPIRATION_DATE' },
    { name: 'descrPriority', title: '_CLAIMS_._PRIORITY' },
    { name: 'actTaskBean', title: '' }
  ];

  private headerClosedActivity: string[] =  this.rowsClosedActivity.map((row) => row.name);
  protected schemaClosedActivity: TableSchema = { rows: this.rowsClosedActivity, header: this.headerClosedActivity };

  private headerOpenedActivity: string[] =  this.rowsOpenedActivity.map((row) => row.name);
  protected schemaOpenedActivity: TableSchema = { rows: this.rowsOpenedActivity, header: this.headerOpenedActivity };


  protected canExpand = (): RgiRxTableExpansionTrigger => (row: any) => {
    console.info('can expand?', row)
    return true
  }
  protected customExpansionModel: RgiRxDataTableExpansionModel<Activity> = new RgiRxDataTableExpansionModel<Activity>(
    this.closedActivityList, { multiple: false, canExpand: this.canExpand(), canContract: canContract() }
  )

  protected onToggleSortHeaders($event: RgiRxSwitchChange) {
    this.sortDef = $event.selected ? CUSTOM_TEMPLATE_SORT_DEF : false
  }

  getActivitiesFromClaim(claim: ApiClaim) {
    let num = '';
    if (this.authService.getOperator().liquidationCentre && this.authService.getOperator().liquidationCentre.code) {
      if (!claim.claimNumber) {
        num = claim.reportNumber;
      } else {
        num = claim.claimNumber;
      }
      this.activityListService.getAllActivitiesFromClaimNumber(num)
        .subscribe((allAct: AllActivity) => {

          if (allAct.opened.length > 0) {
            allAct.opened = this.getAdditionalInfo(claim, allAct.opened);
            this.currentList = allAct.opened;
          } else {
            this.currentList = null;
          }

          if (allAct.closed.length > 0) {
            allAct.closed = this.getAdditionalInfo(claim, allAct.closed);
            this.closedActivityList = allAct.closed;
          } else {
            this.closedActivityList = null;
          }

          this.hasClosed = this.hasClosedResult()
        });
    }
    // TODO gestire card con utente loggato in retevendita
  }

  getAdditionalInfo(claim: ApiClaim, activities: Activity[]): Activity[] {
    for (const activity of activities) {
      if (activity.actBusinessKeyClaimBean && activity.actBusinessKeyClaimBean.idParty) {
        activity.involvedParty = claim.getInvolvedPartyFromId(activity.actBusinessKeyClaimBean.idParty);
      }
      if (activity.actBusinessKeyClaimBean && activity.actBusinessKeyClaimBean.idTypeOfLoss) {
        activity.typeOfLoss = claim.getTypeOfLossFromId(activity.actBusinessKeyClaimBean.idTypeOfLoss);
      }
      if (activity.actVariableBean) {
        activity.documentKey = this.getVariableByName(activity.actVariableBean, 'DocumentKey');
      }
      this.priorityLabel.forEach(pr => {
        if (activity.priority.toString() === pr.codice) {
          activity.descrPriority = pr.descrizione;
        }
      });
      // TODO commenta prima del commit
      // activity.actTaskBean[0].message = 'Descrizione di prova per il popover...';
      // Descrizione di prova per il popover...Descrizione di prova per il popover...';
    }
    return activities;
  }

  getVariableByName(variableArray: ActivityVariable[], name: string) {
    for (const variable of variableArray) {
      if (variable.varName === name) {
        return variable.varValueStr;
      }
    }
  }

  hasResult(): boolean {
    return !!this.currentList && this.currentList.length > 0;
  }

  private hasClosedResult(): boolean {
    return !!this.closedActivityList && this.closedActivityList.length > 0;
  }

  dateGreaterThenToday(activity: Activity): boolean {
    return activity.expirationDate > (new Date());
    // return true;
  }

  manualDeadline(event: any) {
    event.stopPropagation();

    const modalRef = this.modalService.open(ManualDeadlineComponent, {
      windowClass: 'basic',
      size: 'lg'
    });

    modalRef.componentInstance.apiClaim = this.claim;

    modalRef.result.then((result) => {
      if (result) {
        this.getActivitiesFromClaim(this.claim);
      }
    });

    return false;
  }
  isClaim(): boolean {
    return this.claim && this.claim.claimNumber !== null && this.claim.claimNumber !== '';
  }

  @Input() set reloadsubcard(val: boolean) {
    if (val) {
      console.log('ricevuto evento reload: ', val);
      this.getActivitiesFromClaim(this.claim);
    }
  }

  onChangeTab(nw: boolean) {
    this.tabActive = nw;
  }
}
