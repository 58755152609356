import { QuotationDefinition } from './../../models/postsales-operations-response.model';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, ViewEncapsulation, Inject, Optional } from '@angular/core';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';
@Component({
  selector: 'lpc-quotation-control',
  templateUrl: './lpc-quotation-control.component.html',
  styleUrls: ['./lpc-quotation-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LpcQuotationControlComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

   @Output() openFormuleModal: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() openQuotationModal: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() openWarrantiesModal: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() openProvModal: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Output() quote: EventEmitter<boolean> = new EventEmitter<boolean>();
   @Input() quotationDefinition: QuotationDefinition;
   @Input() disableQuote: boolean;
   @Input() disableDetails: boolean;
   @Input() showFirstInstallment = true;
   @Input() enableProv = false;
   @Input() showopenWarrantiesModalButton = true;
   @Input() showOpenQuotationModalButton = true;
   @Input() showSinglePremium: boolean;

   @ViewChild('premiumElement')
    premiumElement: ElementRef<HTMLDivElement>;

  constructor(
    protected  translate: TranslationWrapperService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
     this.currencyFormatterOptions.currency = currencyService.currency;
    }

  ngOnInit() {
  }

  openFormule() {
    this.openFormuleModal.emit(true);
  }

  openQuotation() {
    this.openQuotationModal.emit(true);
  }

  openWarranties() {
    this.openWarrantiesModal.emit(true);
  }

  openProv() {
    this.openProvModal.emit(true);
  }

  recalculate() {
    this.quote.emit(true);
  }

  areFormulasVisible(): boolean {
    let visible = false;
    if (this.quotationDefinition && this.quotationDefinition.risks) {
      this.quotationDefinition.risks.forEach(risk => {
        if (!!risk.formulas && !!risk.formulas.length) {
          visible =  true;
        }
        if (!!risk.subRisks && risk.subRisks.length > 0) {
          risk.subRisks.forEach((sr) => {
            if (!!sr.formulas && !!sr.formulas.length) {
              visible =  true;
            }
          });
        }
      });
      return visible;
    }
    return false;
  }

}
