<div class="grid-item card card-height-normal card-main" *ngIf="!openOutModal">
    <form [formGroup]="newPayFormModal">
        <div class="modal-content" *ngIf="'taxableAmount'+extension || consult">
            <div class="modal-header">
                <h3 class="modal-title">{{ '_CLAIMS_._FEE_DATA' | translate }}
                    <span class="rgifont rgi-close_fine pull-right" (click)="closeModal()"></span>
                </h3>
            </div>
            <div class="modal-body" style="padding-top: 5px; height: 520px;">
                <div class="card shadow" *ngIf="this.isContextSpain">
                    <fiscal-data [consult]="(consult && !isNdcAuth) || alignedInvoiceDisplay"
                                 [feeData]="feeData"
                                 [taxRegimeListEntity]="exp.taxRegimeListEntity"></fiscal-data>
                </div>
                <div class="card shadow">
                    <div class="row">
                        <div class="col-xs-12" style="font-size: 16px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ exp.description }} </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FEE_NUMBER' | translate }} </label>
                            <br>
                            <input type="text" class="form-control" name="feeNumber{{extension}}"
                                attr.data-qa="new-payment-feeNumber{{extension}}-input"
                                formControlName="feeNumber{{extension}}" *ngIf="!consult" />
                            <input type="text" class="form-control" name="feeNumber" value="{{feeData.feeNumber}}"
                                disabled *ngIf="consult" />
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;" *ngIf="!consult">
                            <pnc-datepicker style="font-weight: bold;" label="{{ '_CLAIMS_._FEE_DATE' | translate }}"
                                customFormControlName="feeDate{{extension}}" [customFormGroup]="newPayFormModal"
                                [maxDate]="today" attr.data-qa="new-payment-feeDate{{extension}}-input"
                                *ngIf="!consult">
                            </pnc-datepicker>
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;" *ngIf="consult">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FEE_DATE' | translate }} </label>
                            <br>
                            <span>{{feeData.feeDate| date:'dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TAXABLE_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="taxableAmount{{extension}}"
                                (change)="calculateAmount()" attr.data-qa="new-payment-taxableAmount{{extension}}-input"
                                formControlName="taxableAmount{{extension}}" *ngIf="!consult || isNdcAuth" />
                            <input type="number" class="form-control" name="taxableAmount"
                                value="{{feeData.taxableAmount}}" disabled *ngIf="consult && !isNdcAuth" />
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._NOT_TAXABLE_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="noTaxableAmount{{extension}}"
                                (change)="calculateAmount()"
                                attr.data-qa="new-payment-noTaxableAmount{{extension}}-input"
                                formControlName="noTaxableAmount{{extension}}" *ngIf="!consult || isNdcAuth" />
                            <input type="number" class="form-control" name="notTaxableAmount"
                                value="{{feeData.notTaxableAmount}}" disabled *ngIf="consult && !isNdcAuth" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._IVA_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <!--value="{{feeData.ivaAmount}}"-->
                            <input type="number" class="form-control" name="ivaAmount{{extension}}" 
                            (input)="calculateAmount()" attr.data-qa="new-payment-ivaAmount{{extension}}-input"
                            formControlName="ivaAmount{{extension}}" *ngIf="!consult || isNdcAuth" />
                            <input type="number" class="form-control" name="ivaAmount"
                                value="{{feeData.ivaAmount}}" disabled *ngIf="consult && !isNdcAuth" />
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._IRPF_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="withHoldingAmount" value="{{feeData.withHoldingAmount}}"
                            (change)="calculateAmount()" attr.data-qa="new-payment-withHoldingAmount{{extension}}-input"
                            formControlName="withHoldingAmount{{extension}}" *ngIf="!consult && foreignCurrency" />
                            <input type="number" class="form-control" name="withHoldingAmount"
                                value="{{feeData.withHoldingAmount}}" disabled *ngIf="consult || !foreignCurrency" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._NET_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="netAmount" value="{{feeData.netAmount}}"
                                disabled />
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;" *ngIf="!(isLegalSpain() && this.isContextSpain)">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._DUTIES_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="bolliAmount{{extension}}"
                                attr.data-qa="new-payment-bolliAmount{{extension}}-input" (change)="calculateAmount()"
                                formControlName="bolliAmount{{extension}}" *ngIf="!consult || isNdcAuth" />
                            <input type="number" class="form-control" name="bolliAmount" value="{{feeData.bolliAmount}}"
                                disabled *ngIf="consult && !isNdcAuth" />
                        </div>

                        <div class="col-xs-6" style="font-size: 14px;" *ngIf="(isLegalSpain() && this.isContextSpain) && !(!consult || isNdcAuth)">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="totalAmount" value="{{feeData.totalAmount}}"
                                disabled />
                        </div>
                    </div>
                    <div class="row" *ngIf="!(isLegalSpain() && this.isContextSpain)">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._CASH_AMOUNT_1' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="cash1Amount" value="{{feeData.cash1Amount}}"
                            (change)="calculateAmount()" attr.data-qa="new-payment-cash1Amount{{extension}}-input"
                            formControlName="cash1Amount{{extension}}" *ngIf="!consult && foreignCurrency" />
                            <input type="number" class="form-control" name="cash1Amount"
                                value="{{feeData.cash1Amount}}" disabled *ngIf="consult || !foreignCurrency" />
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._CASH_AMOUNT_2' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="cash2Amount" value="{{feeData.cash2Amount}}"
                            (change)="calculateAmount()" attr.data-qa="new-payment-cash2Amount{{extension}}-input"
                            formControlName="cash2Amount{{extension}}" *ngIf="!consult && foreignCurrency" />
                            <input type="number" class="form-control" name="cash2Amount"
                                value="{{feeData.cash2Amount}}" disabled *ngIf="consult || !foreignCurrency" />
                        </div>
                    </div>
                    <div class="row" *ngIf="!this.isContextSpain">
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._LEGAL_CODE' | translate }} </label>
                            <br>
                            <input type="text" class="form-control" name="legalCode"
                                value="{{feeData.taxRegime.legalCode}}" disabled />
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FISCAL_TAX_REGIME' | translate }}
                            </label>
                            <br>
                            <input type="text" class="form-control" name="fiscalTaxRegime"
                                value="{{feeData.taxRegime.description}}" disabled />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;" *ngIf="!consult && showGeneratePayment && isGeneratePayment">
                            <label ng-bind="label"
                                style="margin-right: 2%;font-weight: bold;">{{ '_CLAIMS_._GENERATE_PAYMENT' | translate }}:
                            </label>
                            <input type="checkbox" class="btn btn-checkbox" name="createPayment{{extension}}"
                                id="createPayment{{extension}}" formControlName="createPayment{{extension}}">
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;" *ngIf="!(isLegalSpain() && this.isContextSpain) ||  (!consult || isNdcAuth)">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }}
                                &euro;</label>
                            <br>
                            <input type="number" class="form-control" name="totalAmount" value="{{feeData.totalAmount}}"
                                disabled />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-6" style="font-size: 14px;" *ngIf="!consult && eFeeType">
                            <label ng-bind="label"
                                style="margin-right: 2%;font-weight: bold;">{{ '_CLAIMS_._INVOICE_TYPE' | translate }}
                            </label>
                            <select class="core-select form-control" id="feeType{{extension}}"
                                name="feeType{{extension}}" formControlName="feeType{{extension}}"
                                [(ngModel)]="feeData.feeType">
                                <option></option>
                                <option *ngFor="let feeType of eFeeType" value={{feeType.eTypeCode}}>
                                    {{feeType.eTypeDescription}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;"
                            *ngIf="!consult && feeData.feeType === '1' && showSDIProtocolNumber">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PROTOCOLLO_SDI' | translate }}
                                <pnc-required required="true"></pnc-required>
                            </label>
                            <br>
                            <input type="text" class="form-control" name="SDIProtocolCod{{extension}}"
                            (focusout)="sdiCodeCheck($event)" [(ngModel)]="feeData.SDIProtocolCod" formControlName="SDIProtocolCod{{extension}}" />
                        </div>
                        <div class="col-xs-6" style="font-size: 14px;"
                            *ngIf="!consult && feeData.feeType === '2' && claimsManagementSapSRV">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PROTOCOLLO_SAP' | translate }}
                                <pnc-required required="true"></pnc-required>
                            </label>
                            <br>
                            <input type="text" class="form-control" name="SAPProtocolKey{{extension}}"
                                [(ngModel)]="feeData.SAPProtocolKey" formControlName="SAPProtocolKey{{extension}}" />
                        </div>
                    </div>



                </div>
            </div>
            <div class="form-group">
                <div class="alert alert-danger" *ngIf="errorMessage!=null" role="alert">{{ errorMessage | translate }}
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group" *ngIf="!consult || (consult && isNdcAuth)">
                        <button class="btn btn-warning text-uppercase" (click)="onConfirm()" [disabled]="block"
                            >{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="grid-item card card-height-normal card-main" *ngIf="openOutModal">
    <form [formGroup]="newPayFormModal" class="font-size-no-modal">
        <div class="card shadow">
            <fiscal-data [consult]="(consult && !isNdcAuth) || alignedInvoiceDisplay"
                         [feeData]="feeData"
                         [taxRegimeListEntity]="exp.taxRegimeListEntity"></fiscal-data>
        </div>
        <div class="card shadow">
            <div class="row">
                <div class="col-xs-12" style="font-size: 16px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ exp.description }} </label>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FEE_NUMBER' | translate }} </label>
                    <br>
                    <input type="text" class="form-control" name="feeNumber{{extension}}"
                        attr.data-qa="new-payment-feeNumber{{extension}}-input"
                        formControlName="feeNumber{{extension}}" *ngIf="!consult" (keyup)="calculateAmount()"/>
                    <input type="text" class="form-control" name="feeNumber" value="{{feeData.feeNumber}}"
                        disabled *ngIf="consult" />
                </div>
                <div class="col-xs-6" style="font-size: 14px; font-weight: bold;" *ngIf="!consult">
                    <pnc-datepicker label="{{ '_CLAIMS_._FEE_DATE' | translate }}"
                        customFormControlName="feeDate{{extension}}" [customFormGroup]="newPayFormModal"
                        [maxDate]="today" [minDate]="minDatePersonalized" attr.data-qa="new-payment-feeDate{{extension}}-input"
                        *ngIf="!consult" (focusout)="calculateAmount()">
                    </pnc-datepicker>
                </div>
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="consult">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FEE_DATE' | translate }} </label>
                    <br>
                    <span>{{feeData.feeDate| date:'dd/MM/yyyy'}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TAXABLE_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="taxableAmount{{extension}}"
                        (keyup)="calculateAmount()" attr.data-qa="new-payment-taxableAmount{{extension}}-input"
                        formControlName="taxableAmount{{extension}}" *ngIf="!consult || isNdcAuth" />
                    <input type="number" class="form-control" name="taxableAmount"
                        value="{{feeData.taxableAmount}}" disabled *ngIf="consult && !isNdcAuth" />
                </div>
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._NOT_TAXABLE_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="noTaxableAmount{{extension}}"
                        (keyup)="calculateAmount()"
                        attr.data-qa="new-payment-noTaxableAmount{{extension}}-input"
                        formControlName="noTaxableAmount{{extension}}" *ngIf="!consult || isNdcAuth" />
                    <input type="number" class="form-control" name="notTaxableAmount"
                        value="{{feeData.notTaxableAmount}}" disabled *ngIf="consult && !isNdcAuth" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._IVA_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="ivaAmount{{extension}}" (input)="calculateAmount()" 
                        attr.data-qa="new-payment-ivaAmount{{extension}}-input" formControlName="ivaAmount{{extension}}" 
                        *ngIf="(!consult || isNdcAuth) && !alignedInvoiceDisplay" />
                    <input type="number" class="form-control" name="ivaAmount"
                        value="{{feeData.ivaAmount}}" disabled *ngIf="(consult && !isNdcAuth) || alignedInvoiceDisplay" />

                </div>
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._WITH_HOLDING_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="withHoldingAmount" value="{{feeData.withHoldingAmount}}"
                    (keyup)="calculateAmount()" attr.data-qa="new-payment-withHoldingAmount{{extension}}-input"
                    formControlName="withHoldingAmount{{extension}}" *ngIf="(!consult && foreignCurrency) && !alignedInvoiceDisplay"/>
                    <input type="number" class="form-control" name="withHoldingAmount"
                        value="{{feeData.withHoldingAmount}}" disabled *ngIf="consult || !foreignCurrency || alignedInvoiceDisplay" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._NET_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="netAmount" value="{{feeData.netAmount}}"
                        disabled />
                </div>
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="!isLegalSpain()">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._DUTIES_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="bolliAmount{{extension}}"
                        attr.data-qa="new-payment-bolliAmount{{extension}}-input" (keyup)="calculateAmount()"
                        formControlName="bolliAmount{{extension}}" *ngIf="!consult || isNdcAuth" />
                    <input type="number" class="form-control" name="bolliAmount" value="{{feeData.bolliAmount}}"
                        disabled *ngIf="consult && !isNdcAuth" />
                </div>

                <div class="col-xs-6" style="font-size: 14px;" *ngIf="(isLegalSpain() && this.isContextSpain) && !(!consult || isNdcAuth)">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="totalAmount" value="{{feeData.totalAmount}}"
                        disabled />
                </div>
            </div>
            <div class="row" *ngIf="!isLegalSpain()">
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._CASH_AMOUNT_1' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="cash1Amount" value="{{feeData.cash1Amount}}"
                    (keyup)="calculateAmount()" attr.data-qa="new-payment-cash1Amount{{extension}}-input"
                    formControlName="cash1Amount{{extension}}" *ngIf="(!consult && foreignCurrency) && !alignedInvoiceDisplay"/>
                    <input type="number" class="form-control" name="cash1Amount"
                        value="{{feeData.cash1Amount}}" disabled *ngIf="consult || !foreignCurrency || alignedInvoiceDisplay" />
                </div>
                <div class="col-xs-6" style="font-size: 14px;" >
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._CASH_AMOUNT_2' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="cash2Amount" value="{{feeData.cash2Amount}}"
                    (keyup)="calculateAmount()" attr.data-qa="new-payment-cash2Amount{{extension}}-input"
                    formControlName="cash2Amount{{extension}}" *ngIf="(!consult && foreignCurrency) && !alignedInvoiceDisplay"/>
                    <input type="number" class="form-control" name="cash2Amount"
                        value="{{feeData.cash2Amount}}" disabled *ngIf="consult || !foreignCurrency || alignedInvoiceDisplay" />
                </div>
            </div>
            <div class="row" *ngIf="false">
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._LEGAL_CODE' | translate }} </label>
                    <br>
                    <input type="text" class="form-control" name="legalCode"
                        value="{{feeData.taxRegime.legalCode}}" disabled />
                </div>
                <div class="col-xs-6" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._FISCAL_TAX_REGIME' | translate }}
                    </label>
                    <br>
                    <input type="text" class="form-control" name="fiscalTaxRegime"
                        value="{{feeData.taxRegime.description}}" disabled/>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="!consult && showGeneratePayment && isGeneratePayment">
                    <label ng-bind="label"
                        style="margin-right: 2%;font-weight: bold;">{{ '_CLAIMS_._GENERATE_PAYMENT' | translate }}:
                    </label>
                    <input type="checkbox" class="btn btn-checkbox" name="createPayment{{extension}}"
                        id="createPayment{{extension}}" formControlName="createPayment{{extension}}">
                </div>
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="!(isLegalSpain() && this.isContextSpain) ||  (!consult || isNdcAuth)">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }}
                        &euro;</label>
                    <br>
                    <input type="number" class="form-control" name="totalAmount" value="{{feeData.totalAmount}}"
                        disabled />
                </div>
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="isEncapsulated">
                    <label ng-bind="label"
                        style="margin-right: 2%;font-weight: bold;">{{ '_CLAIMS_._INVOICE_TYPE' | translate }}
                    </label>
                    <input type="text" class="form-control" id="feeType{{extension}}" name="feeType{{extension}}" value="{{feeData.feeType}}" disabled />
                </div>
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="!consult && eFeeType && !isEncapsulated && alignedInvoiceDisplay">
                    <label ng-bind="label"
                        style="margin-right: 2%;font-weight: bold;">{{ '_CLAIMS_._INVOICE_TYPE' | translate }}
                    </label>
                    <select class="core-select form-control" id="feeType{{extension}}"
                        name="feeType{{extension}}" formControlName="feeType{{extension}}"
                        [(ngModel)]="feeData.feeType" (ngModelChange)="calculateAmount()">
                        <option></option>
                        <option *ngFor="let feeType of eFeeType" value={{feeType.eTypeCode}}>
                            {{feeType.eTypeDescription}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6" style="font-size: 14px;" *ngIf="!consult && eFeeType && !isEncapsulated && !alignedInvoiceDisplay">
                    <label ng-bind="label"
                        style="margin-right: 2%;font-weight: bold;">{{ '_CLAIMS_._INVOICE_TYPE' | translate }}
                    </label>
                    <select class="core-select form-control" id="feeType{{extension}}"
                        name="feeType{{extension}}" formControlName="feeType{{extension}}"
                        [(ngModel)]="feeData.feeType">
                        <option></option>
                        <option *ngFor="let feeType of eFeeType" value={{feeType.eTypeCode}}>
                            {{feeType.eTypeDescription}}
                        </option>
                    </select>
                </div>
                <div class="col-xs-6" style="font-size: 14px;"
                    *ngIf="!consult && feeData.feeType === '1' && showSDIProtocolNumber">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PROTOCOLLO_SDI' | translate }}
                        <pnc-required required="true"></pnc-required>
                    </label>
                    <br>
                    <input type="text" class="form-control" name="SDIProtocolCod{{extension}}"
                    (focusout)="sdiCodeCheck($event)" [(ngModel)]="feeData.SDIProtocolCod" formControlName="SDIProtocolCod{{extension}}" />
                </div>
                <div class="col-xs-6" style="font-size: 14px;"
                    *ngIf="!consult && feeData.feeType === '2' && claimsManagementSapSRV">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PROTOCOLLO_SAP' | translate }}
                        <pnc-required required="true"></pnc-required>
                    </label>
                    <br>
                    <input type="text" class="form-control" name="SAPProtocolKey{{extension}}"
                        [(ngModel)]="feeData.SAPProtocolKey" formControlName="SAPProtocolKey{{extension}}" />
                </div>
            </div>

        </div>
        <div class="form-group">
            <div class="alert alert-danger" *ngIf="errorMessage!=null" role="alert">{{ errorMessage | translate }}
            </div>
        </div>
    </form>
</div>
