<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" [errors]="errors" fieldId="dates" id="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
                    (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="questsFin" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" fieldId="questsFin" label="lpc_financial_questionnaire_step" (next)="onNext($event)">
    <div *ngIf="showSectionQuestFin">
      <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                         [questionnairesCode]="getQuestMap('FIN')" type="FIN" [savedIds]="questionnairesFromAuthorization"
                         [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                         (loaderQuest)="loaderQuestManager($event)"
                         (loaded)="countQuest($event)"
                         #financialQuestionnaire>
      </lpc-questionnaire>
    </div>
  </lpc-step>

  <lpc-step label="lpc_variation_step_label" [errors]="errors" fieldId="contact" id="contact" (next)="onNext($event, true)">
    <div *ngIf="isActiveStep('contact')">
      <lpc-policy-contact [formControlName]="'contact'"
        [addressesVisible]="addressesVisible"
        [emailsVisible]="emailsVisible"
        [homeInsurancesVisible]="homeInsurancesVisible"
        [addresses]="addresses"
        [homeInsurances]="homeInsurances"
        [emails]="emails"
        [hidden]="modalMessage"
        [handleNewContact]="handleNewContact">
      </lpc-policy-contact>
    </div>
    <div *ngIf="!isActiveStep('contact')" data-qa="summary-policyContact-step">
      <div>
        <div class="row" *ngIf="formGroupValue.contact">
            <div class="col-md-6">
              <lpc-static-label label="Tipo recapito"
                          [value]="formGroupValue.contact.contactType">
              </lpc-static-label>
            </div>
            <div class="col-md-6" [ngSwitch]="formGroupValue.contact.contactType">
              <lpc-static-label *ngSwitchCase="'POSTAL_MAIL'" label="Indirizzo"
                          [value]="formGroupValue.contact.postalAddress | address"></lpc-static-label>
              <lpc-static-label *ngSwitchCase="'EMAIL'" label="Email"
                          [value]="formGroupValue.contact.email"></lpc-static-label>
              <lpc-static-label *ngSwitchCase="'HOME_INSURANCE'" label="Home insurance"
                          [value]="formGroupValue.contact.phoneOrEmail"></lpc-static-label>
            </div>
        </div>
      </div>
    </div>

  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" id="quests" [enableAuth]="isAuth" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')" fieldId="quests" label="lpc_avc_questionnaire" (next)="onNext($event)">
    <lpc-questionnaire
                       [active]="!isAfterId('quests')" [key]="composeKey(key, 'quests')"
                       [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #avcQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
