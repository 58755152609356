<div *ngIf="insuranceBackgroundProperties && insuranceBackgroundProperties?.insuranceBackgroundVisible">


  <!-- TITLE SECTION ----------------------------------------------------------------------------->
  <div *ngIf="showHeader"  class="nmf_cont_titsez nmf_cont_titsez_color">
    <span class="header-icon rgi-nmf rgi-nmf-info"></span>
    <h3 translate>Insurance Background</h3>
    <div *ngIf="insuranceStatusCompletenessErrors" class="nmf_errorautomaticbranch_icon"><span
      class="rgifont rgi-survey_ko"></span></div>
  </div>

  <div *ngIf="!showEdit" class="nmf_cont_dati">
    <div *ngFor="let insuranceBackgroundRow of insuranceBackgroundRows" class="nmf_cont_riga">
      <div class="nmf_cont_single_data1">
        <div *ngFor="let insuranceBackground of insuranceBackgroundRow; index as i; trackBy: insuranceTrackByFn"
             class="nmf_cont_single_data nmf_cont_single_data_color">
          <span>{{ insuranceBackground?.code | translate }}
            <b>{{ insuranceBackground?.description }}</b></span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showEdit">
    <form (ngSubmit)="onSubmit()" [formGroup]="insuranceBackgroundForm">
      <div class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label translate>Vehicle Origin:</label>
          <pnc-required required="true"></pnc-required>
          <select #vehicleOrigin (change)="onVehicleOriginChange()" [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="vehicleOrigin" name="vehicleOrigin">
            <option></option>
            <option *ngFor="let vehicleOrigin of vehicleOrigins; index as i; trackBy: genericEntitiesTrackByFn"
                    [ngValue]="vehicleOrigin">{{vehicleOrigin?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
        <div *ngIf="insuranceBackgroundProperties?.law134Visible" class="col-sm-6 col-xs-12">
          <label translate>Law 134 Benefit:</label>
          <select #bersani (change)="onBersaniChange()" [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="bersani" name="bersani">
            <option></option>
            <option *ngFor="let bersaniValue of bersaniValues; index as i; trackBy: genericEntitiesTrackByFn"
                    [ngValue]="bersaniValue">
              {{bersaniValue?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
      </div>
      <form [formGroup]="variablesForm">
        <div *ngIf="additionalDeclarationVariable?.visible" class="form-group row">
          <div class="col-sm-6 col-xs-12">
            <label translate>Additional Declaration:</label>
            <pnc-required *ngIf="additionalDeclarationMandatory" required="true"></pnc-required>
            <select #addDecl (change)='additionalDeclarationChange(addDecl.value)'
                    [compareWith]="compareGenericEntities" [formControlName]="ADDITIONAL_DECLARATION_VARIABLE_CODE"
                    class="select-nativa">
              <option></option>
              <option *ngFor="let additionalDeclaration of additionalDeclarationVariable?.variablesValue"
                      [value]="additionalDeclaration.code">
                {{additionalDeclaration?.description}}
              </option>
            </select>
            <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
          </div>
        </div>
      </form>
      <div *ngIf="insuranceBackgroundProperties && insuranceBackgroundProperties?.originPlateVisible" class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label translate>Origin Plate Type:</label>
          <pnc-required *ngIf="isOriginPlateTypeEditable" required="true"></pnc-required>
          <select #originPlateType (change)="onOriginPlateTypeChange()" [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="originPlateType" name="originPlateType">
            <option></option>
            <option *ngFor="let originPlateType of originPlateTypes; index as i; trackBy: genericEntitiesTrackByFn"
                    [ngValue]="originPlateType">
              {{originPlateType?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
        <div *ngIf="isOriginPlateForeign" class="col-sm-6 col-xs-12">
          <label translate>Origin Plate Country:</label>
          <pnc-required *ngIf="isOriginPlateForeign" required="true"></pnc-required>
          <select #originPlateCountry [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="originPlateCountry" name="originPlateCountry">
            <option></option>
            <option *ngFor="let country of countries; index as i; trackBy: genericEntitiesTrackByFn"
                    [ngValue]="country">{{country?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
      </div>
      <div *ngIf="insuranceBackgroundProperties && insuranceBackgroundProperties?.originPlateVisible" class="form-group row">
        <div *ngIf="isOriginPlateSpecial" class="col-sm-6 col-xs-12">
          <label translate>Origin Special Plate Type:</label>
          <pnc-required *ngIf="isOriginSpecialPlateEditable" required="true"></pnc-required>
          <select #originSpecialPlateType [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="originSpecialPlateType" name="originSpecialPlateType">
            <option></option>
            <option
              *ngFor="let originSpecialPlateType of originSpecialPlateTypes; index as i; trackBy: genericEntitiesTrackByFn"
              [ngValue]="originSpecialPlateType">{{originSpecialPlateType?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
        <div class="col-sm-6 col-xs-12">
          <label translate>Origin Plate Number:</label>
          <pnc-required *ngIf="isOriginPlateNumberEditable" required="true"></pnc-required>
          <input #originPlateNumber [readonly]="!isOriginPlateNumberEditable || relative" [value]="originPlateNumber?.value | uppercase"
                 class="form-control mic-input" formControlName="originPlateNumber"
                 type="text">
        </div>
      </div>
      <div class="form-group row">
        <div *ngIf="insuranceBackgroundProperties && insuranceBackgroundProperties?.originPlateVisible && !(insuranceStatus.relativeEnabled && !insuranceBackgroundForm.controls.bersani.value)"
             class="col-sm-6 col-xs-12">
          <label translate>Origin Vehicle Type:</label>
          <pnc-required
            required="{{insuranceBackgroundProperties && insuranceBackgroundProperties?.originPlateVisible && !(insuranceStatus.relativeEnabled && !insuranceBackgroundForm.controls.bersani.value)}}"></pnc-required>
          <select #vehicleTypeOrigin [compareWith]="compareGenericEntities" class="select-nativa" formControlName="vehicleTypeOrigin"
                  name="vehicleTypeOrigin">
            <option></option>
            <option
              *ngFor="let vehicleTypeOrigin of vehicleTypeOriginValues; index as i; trackBy: genericEntitiesTrackByFn"
              [ngValue]="vehicleTypeOrigin">{{vehicleTypeOrigin?.description}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <div *ngIf="isTelaioVisible && insuranceBackgroundProperties && insuranceBackgroundProperties?.sourceVINVisible"
             class="col-sm-6 col-xs-12">
          <label translate>Origin Chassis:</label>
          <pnc-required *ngIf="isTelaio" required="true"></pnc-required>
          <input #originChassis class="form-control mic-input" formControlName="originChassis" type="text">
        </div>
      </div>

      <div *ngIf="isRelativeVisible && insuranceStatus.relativeEnabled && !insuranceBackgroundForm.controls.bersani.value"
           class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label translate>Family bonus:</label>
          <div #relativeCheckbox (click)="valuesRelativeCheck()" [formControlName]=""
               [ngClass]="{'pointerEnabled':relative, 'pointerDisabled':!relative}" class="btn btn-checkbox" ngDefaultControl
               type="checkbox">
          <span [ngClass]="{'glyphicon-ok': relative }" class="glyphicon" style="color: #f68020;">
          </span>
          </div>
        </div>
      </div>
      <div *ngIf="isRelativeVisible && insuranceStatus.relativeEnabled && relative" class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label translate>Family vehicle licence plate number:</label>
          <pnc-required required="true"></pnc-required>
          <input #relativePlate [value]="relativePlate?.value | uppercase" class="form-control mic-input"
                 formControlName="relativePlate" type="text">
        </div>
      </div>
      <div *ngIf="isRelativeVisible && insuranceStatus.relativeEnabled && relative" class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label translate>Family Vehicle Type:</label>
          <pnc-required required="{{insuranceStatus.relativeEnabled && relative}}"></pnc-required>
          <select #vehicleTypeRelative [compareWith]="compareGenericEntities" class="select-nativa"
                  formControlName="vehicleTypeRelative" name="vehicleTypeRelative">
            <option></option>
            <option
              *ngFor="let vehicleTypeRelative of vehicleTypeRelativeValues; index as i; trackBy: genericEntitiesTrackByFn"
              [ngValue]="vehicleTypeRelative">{{vehicleTypeRelative?.description}}</option>
          </select>
        </div>
      </div>

      <form [formGroup]="variablesForm">
        <div *ngFor="let variableRow of variablesRows" class="form-group row">
          <ng-container *ngFor="let variable of variableRow; index as i; trackBy: variablesTrackByFn">
            <div *ngIf="variable?.visible" class="col-sm-6 col-xs-12">
              <mic-variable-control [variableControlForm]="variablesForm"
                                    [variable]="variable"
                                    [validateFormOnEmitChanges]="false"
                                    (updateVariable)="onVariableChange()" >
              </mic-variable-control>
            </div>
          </ng-container>
        </div>
      </form>
      <div id="error-container" style="margin-top: 2em">
        <pnc-form-message *ngIf="(submitted && formMessages?.length > 0)" [messages]="formMessages" data-qa="action-error-messages"
                          detail="validationError" type="error">
          <!-- objectType = "complex"> -->
        </pnc-form-message>
      </div>
      <br>
      <div *ngIf="enableConfirm" class="btn-group btn-group-justified">
        <div class="btn-group">
          <button class="btn btn-warning pull-right text-uppercase" type="submit">
            <span translate>Validate Insurance Background</span>
          </button>
        </div>
      </div>

    </form>
  </div>
</div>
