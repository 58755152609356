import { IiabTranslationsKeys } from './iiab-translations-keys';

export const IIAB_IT: IiabTranslationsKeys = {
  _IIAB_: {
    _MOTOR_: {
      _NICE_ACTIMIZE_KO_: 'Nice Actimize non è attualmente disponibile. Si prega di riaprire la richiesta di autorizzazione in un secondo momento per riattivare lo screening delle parti della proposta.'
    },
    _OTP_: {
      _OTP_SERVICE_KO: 'Il servizio OTP non è disponibile. Riprova più tardi.',
      _INITIATED_SIGNING_PROCESS_: 'Processo di firma iniziato',
      _TITLE_: 'Firma documenti',
      _DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_: 'Il consenso sulla privacy della firma digitale non è accettato. Non sarà possibile firmare i documenti.',
      _MOBILE_NOT_PRESENT_NOT_SIGNED_: 'Il numero di cellulare non è presente. Non sarà possibile firmare i documenti.',
      _EMAIL_NOT_PRESENT_NOT_SIGNED_: 'L\'indirizzo email non è presente. Non sarà possibile firmare i documenti.',
      _DOCUMENT_DATA_NOT_PRESENT_NOT_SIGNED_: 'Alcuni dati del documento non sono presenti. Non sarà possibile firmare i documenti.',
      _GENERIC_NOT_SIGNED_ERROR_: 'Non sarà possibile firmare i documenti.',
      _OTP_DOCUMENTS_: 'Firma Digitale - Documenti',
      _CONSENSUSES_AND_CONTACTS_: 'Consensi e contatti',
      _OTP_DATA_MISSING_: 'Mancano alcuni dati obbligatori. Non sarà possibile firmare digitalmente i documenti.',
      _OTP_DOCUMENTS_MISSING_: 'Nessun documento digitale disponibile per questo movimento',
      _BTN_: {
        _SIGN_DOCS_: 'Firma documenti',
      },
      _HOLDERS_: 'Intestatari',
      _SUBSCRIBER_: 'Contraente',
      _MOBILE_PHONE_: 'Cellulare'
    },
    _LABEL_: {
      _DESCRIPTION_: 'Descrizione',
      _VALUE_: 'Valore',
      _RELEASE_DATE_: 'Data di Rilascio',
      _EXPIRATION_DATE_: 'Data di Scadenza',
      _INT_PRIFIX_: '',
      _NUMBER_: ''
    },
    _ANAG_: {
      _TAX_ID_DIFFERENT_FROM_DOCUMENT_ID_: 'IT_The identity document (NIF/NIE/Passport) reported in the Documents section does not match the identity document (NIF/NIE/Passport) reported in the Identification Data section'
    }
  },
  _LS_TITLE_TOTAL_SUMMARY_: 'Riepilogo - informazioni aggiuntive',
  _NICE_ACTIMIZE_: {
    _HEAD_HIT: 'Segnalazioni Nice Actimize',
    _HEAD_NOMINATIVE: 'Nominativo',
    _HEAD_SURNAME: 'Cognome',
    _HEAD_NAME: 'Nome',
    _HEAD_DATE_OF_BIRTH: 'Data di nascita',
    _HEAD_RESULT: 'Risultato',
    _HEAD_TYPE_OF_HIT: 'Tipo di segnalazione',
    _HEAD_REQUEST_ID: 'Identificativo richiesta',
    _HIT_FOUND: 'Segnalazione identificata',
    _HIT_NOT_FOUND: 'Nessuna segnalazione identificata',
    _HIT_YES: 'Il soggetto ha avuto segnalazioni. Un ulteriore controllo su Nice Actimize deve essere effettuato',
    _HIT_NO: 'Il soggetto non ha avuto segnalazioni. Non è stato rilevato alcun rischio per il soggetto'
  },
  _COUNTERPROPOSAL_: 'Controproposta',
  _ADJUST_PROPOSAL_: 'Regola proposta',
  _IIAB_ERR_UPLOAD_MANDATORY_DOC_: 'Caricare i documenti obbligatori'
};
