import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {GenericElement} from '../models/domain-models/generic-element';
import {DocumentsModalComponent} from '../documents/documents-modal.component';
import {CustomModalService} from '../custom-modal.service';
import {StateService} from '../state.service';
import {ProposalService} from '../proposal.service';
import {PartiesService} from '../proposal/parties.service';
import {ParameterService} from '../quotation/parameters/parameters.service';
import {DatePipe} from '@angular/common';
import {QuotationService} from '../quotation/quotation.service';
import {RoutableComponent} from '../routable-component';
import {EventNotificator} from '../event-notificator';
import {ApiPremiumDetail} from '../models/api-models/api-premium-detail';
import {PartiesData} from '../models/domain-models/parties/parties-data';
import {WarrantySummary} from '../quotation/warranties-summary/warranty-summary';
import {Section} from '../models/domain-models/section';
import {Unit} from '../models/domain-models/unit';
import {Variable} from '../models/domain-models/variable';
import {State} from '../models/state';
import {ModalService, OperatorService} from '@rgi/portal-ng-core';
import {RoutingService} from '../routing.service';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiContract} from '../models/api-models/api-contract';
import {ApiVoucher} from '../models/api-models/api-voucher';
import {Action} from '../models/action';
import {Modal} from '../modal';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {OctoInstallerData} from '../models/api-models/api-octo-installers-response';
import {merge, Observable, of, Subject, Subscription} from 'rxjs';
import {PolicyTechnicalData} from '../models/domain-models/parameters/policy-technical-data';
import {ApiQuotation} from '../models/api-models/api-quotation';
import {concatMap, finalize, first, map, mergeMap, tap} from 'rxjs/operators';
import {ApiProposalInfo} from '../models/api-models/api-proposal-info';
import {Message} from '../models/message';
import {ApiPolicyInfo} from '../models/api-models/api-policy-info';
import {IvassService} from '../ivass.service';
import {
  ContractPayment,
  EnumSettlementType,
  IdentificationEntity,
  MeanOfPayment,
  PaymentConfig
} from '../payments/payments-data';
import {PaymentService} from '../payments/service/payment.service';
import {cloneDeep} from 'lodash';
import {mapPayment} from '../payments/adapter/utils/payments-utils';
import {IbanCheckModalComponent, IbanCheckModalData} from '../payments/iban-check-modal/iban-check-modal.component';
import {ModalService as AltModalService} from '@rgi/rx/ui';
import {Warning} from '../models/domain-models/warning';
import {PassProductsService} from '../pass-products.service';
import {AuthorizationRequestComponent} from '../proposal/authorization-request/authorization-request.component';
import {ProposalStatusTypes} from '../proposal/proposal-status-types';
import {ElaborateCustomProperties} from '../custom-properties';
import {ApiDigitalSignature} from '../models/api-models/api-digital-signature';
import {MicDatePipe} from '../pipes/micdate.pipe';
import {AmendmentService} from '../amendment/amendment.service';
import {ApiContractNumber} from '../models/api-models/api-contract-number';
import {ApiAmendmentInfo} from '../models/api-models/api-amendment-info';
import {AuthorizationsSearchService, RequestStatus} from '@rgi/authorizations-card';

@Component({
  selector: 'mic-mandatory-data',
  templateUrl: './mandatory-data.component.html',
  styleUrls: ['./mandatory-data.component.scss'],
  providers: [DatePipe]
})
export class MandatoryDataComponent
  implements OnInit, OnDestroy, RoutableComponent, EventNotificator {
  mandatoryDataForm: UntypedFormGroup;
  issueCompleted = false;

  plate: string;
  proposalNumber: string;
  technicalData: any;
  entitledParty: any;
  installer: OctoInstallerData;
  apiContract: ApiContract;
  product;
  node;
  coass: any;
  apiVoucher: ApiVoucher = new ApiVoucher();

  installerModal: Type<Modal>;
  policySummaryModal: Type<Modal>;
  vehicleColors: any[];

  enableSaveProposal = true;
  enableAuthorization = false;
  enableCheckIBAN = false;
  documentsCompleted = true;
  uploadDocumentsChanged = false;
  documentManagerEnabled = false;
  isPolicyNumberEnabled = false;
  isPaymentEnabled = true;
  proposalStatusTypes = ProposalStatusTypes;

  @Output()
  navigation: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('quotationComparisonModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) quotationComparisonModalOverlay: ViewContainerRef;

  updateInstallerEvent: Subject<ApiVoucher> = new Subject<ApiVoucher>();

  policyGeneralDataRows: GenericElement[][];
  policyGeneralData: Array<any> = new Array<any>();
  premiumDataRows: GenericElement[][];
  premiumData: Array<any> = new Array<any>();
  titolariEffettiviDataRows: GenericElement[][];
  intestatariDataRows: GenericElement[][];
  intestatariData: Array<any> = new Array<any>();
  beneficiariDataRows: GenericElement[][];
  installerDataRows: GenericElement[][];
  installerData: Array<any> = new Array<any>();
  policyNumber;
  subscriber;
  grossPremium;
  warranties: WarrantySummary[] = new Array<WarrantySummary>();
  isOTPError = false;
  isPolicyIssued = false;
  validationMessages = [];
  nonBlockingMessages = [];
  policyMessages: Message[] = new Array<Message>();
  isEndButtonEnabled = false;
  isPrintButtonClicked = false;
  isReg51Allowed = false;
  isReg51BtnDisable = false;
  enableIssueForReg51 = false;
  showVoucher = false;
  contractAvailable = false;
  proposalStatus;
  contractId;
  voucherProvider = 0;
  voucherSubstituted;
  @ViewChild('modalOverlay', {read: ViewContainerRef, static: true})
  modalOverlayRef: ViewContainerRef;
  editablePayments: PaymentConfig;
  selectablePayments: MeanOfPayment[];

  formChanged = false;
  isBindingProposal: boolean = true;
  requireDigitalSignature: boolean = false;
  showParties = false;
  isQuotationRecalculated = false;
  isSubscriberMissing = false;
  quotationComparisonModalComponent: Type<Modal>;
  refreshPolicyContacts: Subject<void> = new Subject<void>();

  private subscriptions: Subscription = new Subscription();
  isEditProposalVisible: boolean = true;
  isSaveProposalVisible: boolean = true;
  isAuthRequestVisible: boolean = false;
  salePoint: any;

  constructor(
    protected customModalService: CustomModalService,
    protected stateService: StateService,
    protected proposalService: ProposalService,
    protected partiesService: PartiesService,
    protected parametersService: ParameterService,
    protected quotationService: QuotationService,
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    @Inject('policySummaryModalComponent')
      policySummaryModalComponent: Type<Modal>,
    @Inject('installerModalComponent') installerModalComponent: Type<Modal>,
    protected routingService: RoutingService,
    protected modalService: ModalService,
    protected ivassService: IvassService,
    protected paymentService: PaymentService,
    protected altModalService: AltModalService,
    protected passProductService: PassProductsService,
    protected elaborateCustomProperties: ElaborateCustomProperties,
    @Inject('quotationComparisonModalComponent') quotationComparisonModalComponent: Type<Modal>,
    protected datePipe: MicDatePipe,
    protected amendmentService: AmendmentService,
    protected authorizationsSearchService: AuthorizationsSearchService,
    protected operatorervice: OperatorService
  ) {
    this.policySummaryModal = policySummaryModalComponent;
    this.installerModal = installerModalComponent;
    this.entitledParty = {};
    this.quotationComparisonModalComponent = quotationComparisonModalComponent;

  }

  ngOnInit() {

    this.salePoint = this.operatorervice.getSalePointDefault();
    if (!this.salePoint) {
      this.salePoint = this.operatorervice.getSalePointLogin();
    }

    const apiContract: ApiContract = this.proposalService.getApiContract();
    const state: State = this.stateService.getPreviousState();
    if (apiContract) {
      if (
        !apiContract.contractNumber ||
        (apiContract.contractNumber && (state === State.PROPOSAL || state === State.SURVEY)) ||
        apiContract.editProposal || this.proposalService.isSubstitution
      ) {
        this.contractAvailable = true;
        this.init();
      }

      this.isEditProposalVisible = this.getEditProposalVisible();
      this.isSaveProposalVisible = this.getSaveProposalVisible();


    }

    const newContractSubscription = this.proposalService
      .getNewContractSignal()
      .subscribe(data => {
        this.contractAvailable = true;
        this.proposalService.getApiContract().editProposal = true;

        this.stateService.setCurrentState(State.TELEMATICS);
        this.routingService.setPreviousState(State.TELEMATICS);
        this.init();
      });
    this.subscriptions.add(newContractSubscription);

  }

  protected getEditProposalVisible(): boolean {
    let isEditProposalVisible = false;
    const state: State = this.stateService.getPreviousState();

    if (state === State.PROPOSAL_WITHOUT_PLATE || state === State.PROPOSAL || this.proposalService.isSubstitution) {
      isEditProposalVisible = true;
    }
    if(this.isAuthorizationAccepted()) {
      isEditProposalVisible = false;
    }
    return isEditProposalVisible;
  }

  /**
   * Issue policy flow: 'Save Proposal' always visible except when there is an authorization accepted (in this case shows only 'Issue Policy')
   * Post Sales flow: 'Save Proposal' visible only into authorization process (es. RAO) and the authorization is not yet accepted
   */
  protected getSaveProposalVisible(): boolean {
    const apiContract: ApiContract = this.proposalService.getApiContract();
    return (!this.isAmendment && !this.isAuthorizationAccepted())
      || (this.isAmendment && apiContract !== null && apiContract.authStatus !== null && !this.isAuthorizationAccepted());
  }


  manageVoucher() {
    this.proposalService.checkNewVoucherIsNeeded().subscribe(newVoucherIsNeeded => {
      if (newVoucherIsNeeded) {
        this.showVoucher = true;
        if (this.isSubstitution === false) {
          // Creazione voucher
          this.proposalService.createNewVoucher().subscribe(
            newVoucher => {
              if (newVoucher) {
                this.apiVoucher = newVoucher;
                if (this.apiVoucher.installerCode) {
                  this.updateInstallerEvent.next(this.apiVoucher);
                }
                if (newVoucher.message) {
                  console.log(newVoucher.message);
                } else {
                  this.addVoucherToForm();
                }
              }
            },
            err => {
              console.log(err);
            }
          );
        } else {
          // Recupero voucher sostituita
          this.proposalService.getVoucherData().subscribe(
            voucher => {
              if (voucher) {
                this.apiVoucher = voucher;

                if (this.apiVoucher.installerCode) {
                  this.voucherSubstituted = voucher;
                }
                if (voucher.message) {
                  console.log(voucher.message);
                } else {
                  this.addVoucherToForm();
                }
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      }
    });
  }

  getQuotationAndVoucherData(): Observable<void> {
    return this.quotationService.getQuotation(null).pipe(
      tap((quotation: ApiQuotation) => {
        this.initUnitsList(quotation);
        this.loadPremiumData(quotation);
        this.quotationService.setPreviousAnnualPremium(quotation.premium.productPremium.annual);
      }),
      mergeMap(() => this.loadVoucherData$()),
      mergeMap(() => this.getAvailablePayments$())
    )
  }

  loadVoucherData$() {
    return this.proposalService.getVoucherManagementProvider().pipe(
      map(
        (provider => {
          this.voucherProvider = provider;

          if (this.voucherProvider > 0) {
            this.proposalService.getColorsEnum().subscribe(
              result => {
                this.vehicleColors = result.enumList;
              },
              err => {
                console.log(err);
              }
            );
          }

          this.parametersService
            .retrievePolicyTechnicalData(this.contractId)
            .subscribe(
              data => {
                this.technicalData = data;
                this.coass = data.coassContainer;
                this.addTechnicalDataToForm();
                if (this.voucherProvider > 0) {
                  this.manageVoucher();
                }
              },
              err => {
                console.log('Error technical data');
              }
            );
        })
      )
    )
  }

  @HostListener('window:isOTPValid', ['$event'])
  onOTPValid(e) {
    this.validationMessages.length = 0;
    this.isOTPError = false;
    this.isEndButtonEnabled = true;
  }

  @HostListener('window:isOTPError', ['$event'])
  onOTPError(e) {
    this.validationMessages.length = 0;
    const message1 =
      'Non è stato possibile contattare il servizio di Stampa OTP. ';
    const message2 = 'Si può procedere con il processo di stampa cartacea.';
    this.validationMessages.push(message1 + message2);
    this.isOTPError = true;
    this.isEndButtonEnabled = false;
  }

  loadPartiesData(data: PartiesData) {
    let subscriber: string;
    let owner: string;
    let driver: string;
    let buyer: string;
    let lessee: string;
    let usufructuary: string;

    if (data.subscriber) {
      subscriber = data.subscriber.nominative;

      data.roles.forEach(role => {
        role.partyRoles.forEach(el => {
          let party = el?.party;
          if (party && el?.main) {
            switch (role.code) {
              case 'OWNER':
                owner = party.nominative;
                break;
              case 'DRIVER':
                driver = party.nominative;
                break;
              case 'BUYER':
                buyer = party.nominative;
                break;
              case 'LESSEE':
                lessee = party.nominative;
                break;
              case 'USUFRUCTUARY':
                usufructuary = party.nominative;
                break;
            }
          }
        })
      });
    }

    this.intestatariData.push(new GenericElement(this.translate.instant('Policyholder'), subscriber));
    this.intestatariData.push(new GenericElement(this.translate.instant('Vehicle Owner'), owner));
    this.intestatariData.push(new GenericElement(this.translate.instant('Driver'), driver));
    this.intestatariData.push(new GenericElement(this.translate.instant('Buyer'), buyer));
    this.intestatariData.push(new GenericElement(this.translate.instant('Tenant'), lessee));
    this.intestatariData.push(new GenericElement(this.translate.instant('Beneficial Owner'), usufructuary));

    const intestatariDataNum = this.intestatariData.length;

    if (intestatariDataNum === 0) {
      return;
    }

    this.intestatariDataRows = new Array<Array<GenericElement>>(
      Math.ceil(intestatariDataNum / 3)
    );

    let i: number;
    let j = 0;

    for (i = 0; i < intestatariDataNum; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!this.intestatariDataRows[j]) {
        this.intestatariDataRows[j] = [];
      }
      this.intestatariDataRows[j].push(this.intestatariData[i]);
    }
  }

  openDownloadModal() {
    this.isEndButtonEnabled = true;
    this.isPrintButtonClicked = true;
    this.proposalService.getPolicyDocuments().subscribe(
      data => {
        const comp = this.customModalService.openModal(
          this.modalOverlayRef,
          DocumentsModalComponent,
          this.eventPropagation
        );
        comp.instance.documents = data.documents;
        comp.instance.title = 'Policy Documents';
      }
    );
  }

  goToHome() {
    this.proposalService.refresh();
    this.stateService.resetState();
    this.eventPropagation.emit({
      eventName: 'backToMainPage'
    });
  }

  getVariablesRows(variables: Variable[]): Variable[][] {
    if (!variables) {
      variables = [];
    }
    const variablesNum = variables.length;

    if (variablesNum === 0) {
      return new Array<Array<Variable>>(0);
    }

    const variablesRows = new Array<Array<Variable>>(
      Math.ceil(variablesNum / 2)
    );

    let i: number;
    let j = 0;

    for (i = 0; i < variablesNum; i++) {
      if (i !== 0 && i % 2 === 0) {
        j++;
      }
      if (!variablesRows[j]) {
        variablesRows[j] = [];
      }
      variablesRows[j].push(variables[i]);
    }
    return variablesRows;
  }

  addTechnicalDataToForm() {
    this.mandatoryDataForm = this.formBuilder.group({
      mandatoryData: this.formBuilder.group({
        // policyNumber: this.formBuilder.control(null), // non richiesto su unipol
        // TODO: next implementations
        // coinsuranceType: this.formBuilder.control(null),
        // ourPercentage: this.formBuilder.control(null, Validators.required),
        coinsuranceType: this.formBuilder.control({
          value: this.technicalData.coassContainer != null ? this.technicalData.coassContainer.coassType : null,
          disabled: true
        }),
        issueDate: this.formBuilder.control({
          value: this.formattedDate(
            new Date(this.technicalData.issueDate.date)
          ),
          disabled: true
        }),
        effectiveDate: this.formBuilder.control({
          value: this.formattedDate(
            new Date(this.technicalData.effectiveDate.date)
          ),
          disabled: true
        }),
        policyTime: this.formBuilder.control({
          value: this.technicalData.policyTime.value,
          disabled: true
        }),
        policyNumber: this.formBuilder.control(''),
        coinsurance: this.formBuilder.control('')
      })
    });
    this.mandatoryDataForm.updateValueAndValidity();
  }

  addVoucherToForm() {
    this.mandatoryDataForm.addControl(
      'voucherData',
      this.formBuilder.group({
        contacts: this.formBuilder.group({
          surname: this.formBuilder.control(
            this.apiVoucher.contactSurname,
            Validators.required
          ),
          name: this.formBuilder.control(
            this.apiVoucher.contactName,
            Validators.required
          ),
          mobilePhone: this.formBuilder.control(
            this.apiVoucher.phoneNumber1,
            Validators.required
          ),
          secondaryPhone: this.formBuilder.control(
            this.apiVoucher.phoneNumber2
          ),
          thirdPhone: this.formBuilder.control(this.apiVoucher.phoneNumber3)
        }),

        vehicle: this.formBuilder.group({
          plate: this.formBuilder.control(this.plate, Validators.required),
          chassis: this.formBuilder.control(
            this.apiVoucher.chassisNumber,
            Validators.required
          ),
          colour: this.formBuilder.control(
            this.apiVoucher.color,
            Validators.required
          )
        }),

        zipCode: this.formBuilder.control(this.apiVoucher.installerZipCode, Validators.required)
      })
    );
  }

  onIssuing() {
    if (this.enableCheckIBAN) {
      this.proposalService.checkExistingIBAN().subscribe((ret) => {
        if (ret && ret.length > 0) {
          const modalData = new IbanCheckModalData(ret);
          const paymentModal = this.altModalService.openComponent(IbanCheckModalComponent, cloneDeep(modalData));
          paymentModal.modal.enableClickBackground = false;
          paymentModal.modal.onClose.subscribe(onCloseData => {
            if (onCloseData) {
              this.issue();
            }
          });
        } else {
          this.issue();
        }
      });
    } else {
      this.issue();
    }
  }

  private issue() {
    if (this.showVoucher) {
      this.fillVoucher();
      this.proposalService.updateVoucherData(this.apiVoucher).subscribe(
        () => {
          this.issuePolicy();
        }
      );
    } else {
      this.issuePolicy();
    }
  }

  fillVoucher() {
    const voucherData = this.mandatoryDataForm.value.voucherData;
    if (voucherData) {
      this.apiVoucher.surname = voucherData.contacts.surname;
      this.apiVoucher.name = voucherData.contacts.name;
      this.apiVoucher.phoneNumber1 = voucherData.contacts.mobilePhone;
      this.apiVoucher.phoneNumber2 = voucherData.contacts.secondaryPhone
        ? voucherData.contacts.secondaryPhone
        : null;
      this.apiVoucher.phoneNumber3 = voucherData.contacts.thirdPhone
        ? voucherData.contacts.thirdPhone
        : null;
      this.apiVoucher.color = voucherData.vehicle.colour;
      this.apiVoucher.chassisNumber = voucherData.vehicle.chassis;
      this.apiVoucher.licencePlateNumber = voucherData.vehicle.plate;
    }
  }

  issuePolicy() {
    if (!this.isSubstitution && this.isPolicyNumberEnabled) {
      this.openPolicySummaryModal();
    } else {
      let policyNumber;
      if (this.isSubstitution && this.isPolicyNumberEnabled
        && this.mandatoryDataForm.controls.mandatoryData.get('policyNumber').value
        && this.mandatoryDataForm.controls.mandatoryData.get('policyNumber').value !== '') {
        policyNumber = this.mandatoryDataForm.controls.mandatoryData.get('policyNumber').value;

        this.proposalService.setManualPolicyNumber(policyNumber);
      }


      this.proposalService.savePolicy(this.contractId, policyNumber)
        .subscribe(data => {
          if (data.messages && data.messages.length > 0) {
            data.messages.forEach(msg => {
              this.policyMessages.push(new Message('POLICY', msg));
            });
            this.enableSaveProposal = false;
          } else {
            this.isPolicyIssued = true;
            this.proposalService.setIssueCompleted();
            this.proposalService.setPolicyInfo(data);
            this.afterIssuePolicy(data);
            this.stateService.nextState(Action.ISSUE_POLICY);
          }
        }, error => {
          for (const errorMes of error.error.messages) {
            this.policyMessages.push(new Message('POLICY', errorMes));
          }
        });
    }
  }

  afterIssuePolicy(data: ApiPolicyInfo) {

  }

  openPolicySummaryModal() {
    const modal = this.customModalService.openModal(
      this.modalOverlayRef,
      this.policySummaryModal,
      this.eventPropagation,
      () => {
        if (modal.instance.policyMessages) {
          modal.instance.policyMessages.forEach(msg => {
            this.validationMessages.push(new Message('POLICY', msg));
          });
          this.enableSaveProposal = false;
          return;
        }
        if (this.proposalService.isIssueCompleted()) {
          this.issueCompleted = true;
          this.stateService.nextState(Action.ISSUE_POLICY);
        } else {
          this.issueCompleted = false;
        }
      }
    );
    modal.instance.isPolicyNumberEnabled = this.isPolicyNumberEnabled;
  }

  compareGenericEntities(ent1: any, ent2: any): boolean {
    return ent1 && ent2 ? ent1.code === ent2.code : ent1 === ent2;
  }

  genericEntitiesTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  // utilizzare utils di common (quali?)

  formattedDate(date: Date) {
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join('/');
  }

  isInstallerCodeValid() {
    return (
      !this.showVoucher || !this.apiVoucher || this.apiVoucher.installerCode
    );
  }

  installerChange(installer) {
    this.apiVoucher.installerCode = installer.installerCode;
    this.apiVoucher.installerZipCode = installer.zipCode;
  }

  genericEntitiesTrackByFnCustom(genericEntity: GenericEntity) {
    return genericEntity.id;
  }

  addSubscription(subscription) {
    this.subscriptions.add(subscription);
  }

  get isSubstitution(): boolean {
    return this.proposalService.isSubstitution;
  }

  get isIssueBtnDisabled(): boolean {
    return (this.mandatoryDataForm && this.mandatoryDataForm.invalid) ||
      !this.isInstallerCodeValid() ||
      (this.policyMessages && this.policyMessages.length > 0) ||
      (this.validationMessages && this.validationMessages.length > 0) ||
      (this.isReg51Allowed && !this.enableIssueForReg51) ||
      this.formChanged;
  }

  saveProposal() {
    if (this.mandatoryDataForm && this.mandatoryDataForm.get('voucherData') && this.mandatoryDataForm.get('voucherData').valid) {
      this.fillVoucher();
      this.proposalService.updateVoucherData(this.apiVoucher).subscribe(
        () => {
          this.callApiSaveProposal();
        }
      );
    } else {
      this.callApiSaveProposal();
    }
  }

  onSaveProposalOKResponse() {
  }// open for ext

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  verifyDocuments(event: any) {
    if (event) {
      this.enableSaveProposal = true;
      this.uploadDocumentsChanged = true;
      this.documentsCompleted = event.completed;
    }
  }

  startDocuments(event: any) {
    if (event) {
      this.documentsCompleted = event.completed;
    }
  }

  protected init() {
    let observablesArray$: Array<Observable<any>> = [];

    this.contractId = this.proposalService.getContractId();
    if (this.proposalService.getPlateNumber()) {
      this.plate = this.proposalService.getPlateNumber().plateNumber;
    }
    this.partiesService.getPartiesOffset(this.contractId, 'true').subscribe(data => {
      this.subscriber = data.subscriber;
      const subscriberNominative = this.subscriber
        ? this.subscriber.nominative
        : '';

      this.eventPropagation.emit({
        eventName: 'setTitle',
        title: this.translate.instant('Policy mandatory data - ') + subscriberNominative
      });

      this.loadPartiesData(data);
      this.loadPolicyGeneralData();
    });

    this.apiContract = this.proposalService.getApiContract();
    this.product = this.proposalService.getProduct();
    this.node = this.proposalService.getNodeDescription();
    this.proposalNumber = this.proposalService.getProposalNumber();
    this.entitledParty = this.partiesService.getEntitledParty();
    this.enableCheckIBAN = this.apiContract.checkIbanOnIssue;

    this.isBindingProposal = this.apiContract.bindingProposal;
    this.proposalService.checkDigitalSignature().subscribe((data: ApiDigitalSignature) => {
      this.requireDigitalSignature = data && data.required;
      this.proposalService.digitalSignatureRequired = this.requireDigitalSignature;
    });
    this.showParties = !this.isBindingProposal;

    this.proposalService.getProposalState().subscribe(data => {
      if (data) {
        this.proposalStatus = data;
        this.afterGetProposalState();
      }
    });

    if (!this.entitledParty) {
      this.partiesService
        .getEntitled(this.contractId)
        .subscribe(data => {
          if (data && data.parties && data.parties.length > 0) {
            this.entitledParty = data.parties[0];
          }
        });
    }

    this.ivassService.isReg51Allowed().subscribe(resp => {
      this.enableIssueForReg51 = resp.isNextButtonEnable;
      this.isReg51Allowed = resp.isReg51Allowed;
    });

    observablesArray$.push(this.getQuotationAndVoucherData());

    this.proposalService.getProposalState().subscribe(data => {
      if (data) {
        this.proposalStatus = data;
        this.afterGetProposalState();
      }
    });

    if (this.proposalService.isSubstitution) {
      this.proposalService.isDocumentManagementEnabled().subscribe(
        (isEnabled) => {
          this.documentManagerEnabled = isEnabled;
        }
      );
    }

    this.proposalService.isManualPolicyNumberEnabled().subscribe((isPolicyNumberEnabled) => {
      this.isPolicyNumberEnabled = isPolicyNumberEnabled;
    });

    this.paymentService.getPaymentEnabled$().subscribe(resp => {
      this.isPaymentEnabled = resp;
    });

    this.paymentService.getEditablePaymentConfigs$(this.contractId).subscribe(
      (paymentConfig: PaymentConfig) => {
        this.editablePayments = paymentConfig;
      }
    );

    // If the proposal is non-binding (BINPRO), when an authorization request or issue is initiated from the consultation,
    // the system navigates directly to the mandatory data page, bypassing the address or contacts page.
    // In this scenario, the address data becomes mandatory for saving the proposal and is better to recover it.
    if (!this.proposalService.getPolicyContacts()) {
      this.proposalService.retrievePolicyContacts().subscribe(
        (data) => {
          this.proposalService.setPolicyContacts(data);
        }
      );
    }

    /**
     * NOTE: right now, only getQuotationAndVoucherData() response is taken in consideration, but it is possible to
     * add multiple Observables and thus the checkAndGetWarnings() will be performed once all subscriptions complete.
     */
    let response$: Observable<any>;
    if (Array.isArray(observablesArray$)) {
      // If observablesArray$ is a non empty array of observables, use spread syntax to pass them into merge
      response$ = observablesArray$.length > 0 ? merge(...observablesArray$) : of(null);
    }

      response$.pipe().subscribe(() => this.checkAndGetWarnings());
  }

  afterGetProposalState() {
    const statusToBeAuthorized: boolean = this.proposalStatus.code === this.proposalStatusTypes.STATE_PROPOSED_TO_BE_AUTHORIZED
      || this.proposalStatus.code === this.proposalStatusTypes.STATE_PROPOSAL_FOR_AMENDMENT_TO_BE_AUTHORIZED;

    this.isAuthRequestVisible = statusToBeAuthorized && (!this.isBindingProposal || this.isSubstitution || this.isAmendment);
    this.enableAuthorization = statusToBeAuthorized && (!this.isAuthorizationPending() || this.isLastAuthEventCreation());
  }


  protected initTechnicalData(policyTechnicalData: PolicyTechnicalData) {
    const contract = this.proposalService.getApiContract();

    let selectedAgreement;
    let selectedPaymentFrequency;

    policyTechnicalData.agreementContainer.agreements.forEach(agreement => {
      if (agreement.selected) {
        selectedAgreement = agreement.description;
      }
    });

    policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach(
      paymentFrequency => {
        if (paymentFrequency.selected) {
          selectedPaymentFrequency = paymentFrequency.description;
        }
      }
    );

    const policyIssueDate =
      policyTechnicalData.issueDate && policyTechnicalData.issueDate.date
        ? new Date(policyTechnicalData.issueDate.date)
        : null;
    const policyEffectiveDate =
      policyTechnicalData.effectiveDate &&
      policyTechnicalData.effectiveDate.date
        ? new Date(policyTechnicalData.effectiveDate.date)
        : null;
    const policyExpirationDate =
      policyTechnicalData.exipreDate && policyTechnicalData.exipreDate.date
        ? new Date(policyTechnicalData.exipreDate.date)
        : null;

    let profession;
    if (this.subscriber && this.subscriber.professionDetail) {
      profession = this.subscriber.professionDetail.description;
    }
    let productDescription = contract.products[0].description;
    if (contract.products[0].extendedDescription && contract.products[0].extendedDescription !== '') {
      productDescription = contract.products[0].extendedDescription;
    }
    this.policyGeneralData.push(
      new GenericElement(this.translate.instant('Product'), productDescription)
    );
    this.policyGeneralData.push(new GenericElement(this.translate.instant('Node'), this.node));
    this.policyGeneralData.push(
      new GenericElement(this.translate.instant('Policyholder'), this.subscriber.nominative)
    );
    this.policyGeneralData.push(
      new GenericElement(this.translate.instant('Agreement'), selectedAgreement)
    );
    this.policyGeneralData.push(
      new GenericElement(this.translate.instant('Proposal Issue Date'), '')
    );
    this.policyGeneralData.push(
      new GenericElement(this.translate.instant('Policy Issue Date'), this.datePipe.transform(policyIssueDate?.toISOString()))
    );
    this.policyGeneralData.push(
      new GenericElement(this.translate.instant('Policy Effective Date'), this.datePipe.transform(policyEffectiveDate?.toISOString()))
    );
    this.policyGeneralData.push(new GenericElement(this.translate.instant('Policy Duration'), ''));
    this.policyGeneralData.push(
      new GenericElement(this.translate.instant('Policy Expiration Date'), this.datePipe.transform(policyExpirationDate?.toISOString()))
    );
    this.policyGeneralData.push(
      new GenericElement('Payment Frequency', selectedPaymentFrequency)
    );

    const policyGeneralDataNum = this.policyGeneralData.length;

    if (policyGeneralDataNum !== 0) {
      this.policyGeneralDataRows = new Array<Array<GenericElement>>(
        Math.ceil(policyGeneralDataNum / 3)
      );

      let i: number;
      let j = 0;

      for (i = 0; i < policyGeneralDataNum; i++) {
        if (i !== 0 && i % 3 === 0) {
          j++;
        }
        if (!this.policyGeneralDataRows[j]) {
          this.policyGeneralDataRows[j] = [];
        }
        this.policyGeneralDataRows[j].push(this.policyGeneralData[i]);
      }
    }
  }

  protected initUnitsList(quotation: ApiQuotation) {
    if (quotation.sections) {
      quotation.sections.forEach((section: Section) => {
        if (section.unitList) {
          this.createUnitRows(section.description, section.unitList);
        }
      });
    } else if (quotation.packages) {
      quotation.packages.forEach((currentPackage) => {
        if (currentPackage.selection && currentPackage.unitList) {
          this.createUnitRows(currentPackage.description, currentPackage.unitList);
        }
      });
    }
  }

  protected createUnitRows(containerDescription: string, unitList: Unit[]) {
    unitList.forEach((unit: Unit) => {
      if (unit.selected) {
        this.warranties.push(
          new WarrantySummary(
            unit.extendedDescription
              ? unit.extendedDescription
              : unit.description,
            containerDescription,
            this.getVariablesRows(unit.variables)
          )
        );
      }
    });
  }

  loadPremiumData(quotation: ApiQuotation) {
    let premium: ApiPremiumDetail;
    if (quotation && quotation.premium && quotation.premium.productPremium) {
      premium = quotation.premium.productPremium.annual;
    }
    let netPremium;
    let interests;
    let fees;
    let taxes;

    if (premium) {
      this.grossPremium = premium.gross;
      netPremium = premium.net;
      interests = premium.paymentFrequencyInterest;
      fees = premium.accessories;
      taxes = premium.taxes;
    }

    this.premiumData.push(new GenericElement(this.translate.instant('Gross'), this.grossPremium));
    this.premiumData.push(new GenericElement(this.translate.instant('Net'), netPremium));
    this.premiumData.push(
      new GenericElement(this.translate.instant('Instalment Interest'), interests)
    );
    this.premiumData.push(new GenericElement(this.translate.instant('Rights'), fees));
    this.premiumData.push(new GenericElement(this.translate.instant('Taxes'), taxes));

    const premiumDataNum = this.premiumData.length;

    if (premiumDataNum === 0) {
      return;
    }

    this.premiumDataRows = new Array<Array<GenericElement>>(
      Math.ceil(premiumDataNum / 3)
    );

    let i: number;
    let j = 0;

    for (i = 0; i < premiumDataNum; i++) {
      if (i !== 0 && i % 3 === 0) {
        j++;
      }
      if (!this.premiumDataRows[j]) {
        this.premiumDataRows[j] = [];
      }
      this.premiumDataRows[j].push(this.premiumData[i]);
    }
  }

  private loadPolicyGeneralData() {
    const policyTechnicalData = this.parametersService.getPolicyTechnicalData();
    if (policyTechnicalData) {
      this.initTechnicalData(policyTechnicalData);
    } else {

      this.parametersService
        .retrievePolicyTechnicalData(this.contractId)
        .subscribe(
          (techData: PolicyTechnicalData) => {
            this.initTechnicalData(techData);

          }
        );
    }
  }

  callApiSaveProposal() {
    this.proposalService.saveProposal()
      .pipe(first(), finalize(() => this.isQuotationRecalculated = false))
      .subscribe((data: ApiProposalInfo) => {
          if (data.messages && data.messages.length > 0) {
            data.messages.forEach(msg => {
              this.validationMessages.push(new Message('PROPOSAL', msg));
            });
            this.createProposalModal(data);
            this.enableSaveProposal = false;
            return;
          }
          this.createProposalModal(data);
          this.onSaveProposalOKResponse();
          this.proposalService.getProposalState().subscribe(status => {
            if (status) {
              this.proposalStatus = status;
            }
          });
        }
      );
  }

  createProposalModal(data: ApiProposalInfo) {
    this.proposalNumber = data.contractNumber.proposalNumber;
    this.proposalService.setProposalNumber(data.contractNumber.proposalNumber);
    this.modalService.open([this.translate.instant('Proposal number {{num}} has been saved successfully',
      {num: data.contractNumber.proposalNumber})], this.translate.instant('SYSTEM MESSAGE'));
  }

  callReg51() {
    const message = this.translate.instant('Quote retrieval from ivass can take longer than 30 seconds');
    const title = this.translate.instant('Attention');
    this.modalService.open([message], title, () => {
      this.callApiReg51();
    });
  }

  callApiReg51() {
    this.ivassService.callReg51().subscribe(resp => {
      this.isReg51BtnDisable = true;
      this.enableIssueForReg51 = true;
    }, error => {
      this.isReg51BtnDisable = true;
      this.enableIssueForReg51 = true;
    });
  }

  setMeanPayment($event: ContractPayment) {
    const toProcess: ContractPayment[] = [];
    let unselectedPaym = [];
    this.selectablePayments.forEach((payment: MeanOfPayment) => {
      const paymentType = payment.paymentConfig.paymentType;
      const firstPass = !$event && payment.selected;
      if (firstPass || ($event?.payment?.idPaymentType !== paymentType && payment.selected)) {
        const meanOfPayment = payment.paymentConfig.meanOfPayment;
        const identificationEntity = new IdentificationEntity(meanOfPayment.code, meanOfPayment.description, meanOfPayment.id);
        const contractPayment = mapPayment(
          identificationEntity,
          paymentType,
          EnumSettlementType.SIGN_INSTALLMENT,
          payment.paymentConfig.paymentsFields
        );
        toProcess.push(contractPayment);
      } else if ((payment.editable && payment.selected) || (payment.editable && $event?.payment?.idPaymentType !== paymentType)) {
        payment.selected = false;
        unselectedPaym.push(payment);
      }
    });
    if ($event) {
      toProcess.push($event);
    }
    return this.paymentService.setMeanPayment(this.contractId, toProcess).pipe(
      concatMap( () => {
        if ($event) {
          return this.paymentService.getAvailablePayments$(this.contractId).pipe(
            map((paym: MeanOfPayment[]) => {
              /**
               * Since the only means retrieved by the service are the configured (thus non-editable) plus the selected ones,
               * it is necessary to store the previously, non-selected, means insered by the user at FE
               */
              this.selectablePayments = paym.concat(unselectedPaym);
              return null;
            })
          );
        }
        return of(null);
      }),
      map(() => null)
    );
  }

  goToEditProposal() {
    this.stateService.nextState(Action.EDIT_PROPOSAL);
  }

  goToEditQuotation() {
    this.stateService.nextState(Action.EDIT_QUOTATION_BUTTON_PRESSED);
  }

  updatePaymentMessages(paymentsMessage: Message) {
    this.validationMessages = this.validationMessages.filter(
      (msg: Message) => msg.area !== 'PAYMENTS'
    );
    if (paymentsMessage) {
      this.validationMessages.push(paymentsMessage);
    }
  }

  getAvailablePayments$(){
    return this.paymentService.getAvailablePayments$(this.contractId).pipe(
      concatMap((paym: MeanOfPayment[]) => {
        this.selectablePayments = paym;
        if (this.selectablePayments &&
          this.selectablePayments.find((payment: MeanOfPayment) => payment.selected === true) !== undefined) {
          return this.setMeanPayment(null);
        }
        return of(null);
      }),
      map(() => null)
    );
  }

  recalculateQuotation() {
    this.quotationService.retrieveEntitledPremium().subscribe(
      (data) => {
        this.quotationService.setEntitledPremium(data);
        const quotationModal = this.customModalService.openModal(this.quotationComparisonModalOverlay,
          this.quotationComparisonModalComponent, this.eventPropagation, () => {
            if (quotationModal.instance.goEditQuotation) {
              this.stateService.nextState(Action.EDIT_QUOTATION_BUTTON_PRESSED);
            } else {
              this.isQuotationRecalculated = true;
            }
            this.formChanged = false;
          });
      });
  }

  onPartyDataChanges() {
    this.formChanged = true;
    this.isQuotationRecalculated = false;
    // clean messages related to digital signature process
    this.validationMessages = this.validationMessages.filter(
      (msg: Message) => msg.area === 'PAYMENTS'
    );
    this.checkAndGetWarnings();
  }

  onSubscriberChanged(initPartyEvent?: boolean) {
    if (!initPartyEvent) {
      this.getAvailablePayments$().subscribe();
    }
  }

  getWarningMessages(areaCode : string) {
    this.proposalService.getFullWarnings().subscribe(
      (messages : Warning[]) => this.updateMessages(messages, areaCode)
    );
  }

  addPartyRolesMessaging(messages: Message[]) {
    messages.forEach((msg : Message) => this.validationMessages.push(msg));
    let subscriberMsg = messages.find(msg => msg.text === 'Subscriber is mandatory');
    this.isSubscriberMissing = !!subscriberMsg;
  }

  updateMessages(messages: Warning[], areaCode : string) {
    this.nonBlockingMessages = this.nonBlockingMessages.filter(
      (msg: Message) => msg.area !== areaCode
    );
    this.validationMessages = this.validationMessages.filter(
      (msg: Message) => msg.area !== areaCode
    );
    messages.forEach((warn: Warning) => {
      let message = new Message(areaCode, warn.description);
      message.mandatory = warn.mandatory;
      if (message.mandatory){
        this.validationMessages.push(message);
      } else {
        this.nonBlockingMessages.push(message);
      }
    });
  }

  onAuthorize() {
    // Post sale, RAO context
    this.proposalService.updateContacts(this.proposalService.getPolicyContacts()).subscribe(savedContacts => {
      this.proposalService.setPolicyContacts(savedContacts);
      this.refreshPolicyContacts.next();
      // Post sale, RAO context
      if (this.amendmentService.isAmendment) {
        this.authorizationModal();
      } else {
        this.proposalService.saveProposal()
          .pipe(first())
          .subscribe((data: ApiProposalInfo) => {
            this.proposalNumber = data.contractNumber.proposalNumber;
            this.proposalService.setProposalNumber(data.contractNumber.proposalNumber);
            this.authorizationModal();
          });
      }
    });
  }

  authorizationModal() {
    this.enableSaveProposal = false;

    // Show the documents button only for the directional users or only if the proposal status is valid
    /*this.showDocuments = this.operatorervice.isDirectionalUser()
      || (this.proposalStatus.code === this.proposalStatusTypes.STATE_VALID_PROPOSAL);*/

    const modalAuth = this.customModalService.openModal(this.modalOverlayRef, AuthorizationRequestComponent, this.eventPropagation);
    modalAuth.instance.authorizationSent.subscribe(() => {
      this.enableAuthorization = false;
    });
  }

  checkValidationMessages() {
    for (const element of this.validationMessages) {
      if (element.mandatory) {
        return true;
      }
    }
    return false;
  }

  areAnyConfiguredPayment() {
    return this.editablePayments.credMeansOfPayment.length > 0 || this.editablePayments.debMeansOfPayment.length > 0;
  }

  protected checkAndGetWarnings() {
    this.partiesService.checkParties(this.contractId, true).subscribe(
      () => this.getWarningMessages('WARN')
    );
  }

  get isAmendment() {
    return this.amendmentService.isAmendment;
  }

  saveAmendment() {
    this.proposalService.checkDigitalSignature().subscribe((data: ApiDigitalSignature) => {
      this.proposalService.digitalSignatureRequired = data && data.required;
      this.amendmentService.saveAmendment(this.amendmentService.flowOriginPolicyNumber, this.amendmentService.resourceId, this.amendmentService.flowOriginAction.type, this.amendmentService.flowOriginOperation.code)
        .subscribe(
          (data: ApiAmendmentInfo) => {
            if (data) {
              const amendmentInfo = new ApiPolicyInfo();
              amendmentInfo.contractNumber = new ApiContractNumber();
              amendmentInfo.contractNumber.policyNumber = data.contractNumber;
              amendmentInfo.contractNumber.proposalNumber = this.proposalService.getProposalNumber();
              amendmentInfo.movementNumber = data.movementId;
              this.proposalService.setPolicyInfo(amendmentInfo);
              this.amendmentService.appendixNumber = data.appendixNumber;
            }
            this.stateService.nextState(Action.AMENDMENT_SAVE_BUTTON_PRESSED);
          },
          errors => {
            if (errors && errors.messages) {
              const filtered = this.validationMessages.filter((msg: Message) => msg.area !== 'AMENDMENT');
              this.validationMessages.length = 0;
              Array.prototype.push.apply(this.validationMessages, filtered);
              errors.messages.forEach(msg => {
                this.validationMessages.push(new Message('AMENDMENT', msg));
              });
            }
          });
    });

  }

  protected isAuthorizationAccepted(): boolean {
    this.apiContract = this.proposalService.getApiContract();
    return this.apiContract !== null && this.apiContract.authStatus !== null && Number(this.apiContract.authStatus) === RequestStatus.ACCEPTED;
  }

  protected isAuthorizationPending(): boolean {
    this.apiContract = this.proposalService.getApiContract();
    return this.apiContract !== null && this.apiContract.authStatus !== null && Number(this.apiContract.authStatus) === RequestStatus.OPEN;
  }

  protected isLastAuthEventCreation(): boolean {
    this.apiContract = this.proposalService.getApiContract();
    return this.apiContract !== null && this.apiContract.authLastEvent !== null && Number(this.apiContract.authLastEvent) === 1;
  }

}

