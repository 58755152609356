import { Component, OnInit } from '@angular/core';
import { RoutableComponent } from '@rgi/rx/router';
import { AnagStateDetail, AnagStateManagerDetail } from '../../anag-states/detail/anag-state-manager-detail';
import { AnagApiAddress, AnagPhoneNumber } from '../../anag-model/anag-domain/anag-api-party';
import { ANAG_FUNCTIONS, PACONF_VALUES } from '../../anag-constants/anag-constants';
import { AnagStateManagerKeyData } from '../../anag-states/key-data/anag-state-manager-key-data';
import { RgiRxUserAuthorizationService } from '@rgi/rx/auth';
import { AnagDisplayLabel } from '../../anag-model/anag-domain/anag-display-label';
import { AnagStatelessService } from '../../anag-resources/anag-stateless.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'rgi-anag-detail',
  templateUrl: './anag-detail.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagDetailComponent extends RoutableComponent implements OnInit {
  state: AnagStateDetail;
  subjectMobilePhoneNumber: string;
  subjectMobilePhoneNumber2: string;
  subjectPhoneNumber: string;
  subjectFaxNumber: string;
  partyInformations: Array<AnagDisplayLabel> = [];

  PACONF_VALUES = PACONF_VALUES;
  DATE_FORMAT: string;

  constructor(
    public stateMgr: AnagStateManagerDetail,
    public stateMgrKeyData: AnagStateManagerKeyData,
    protected userAuthorizationService: RgiRxUserAuthorizationService,
    public statelessService: AnagStatelessService,
    public datePipe: DatePipe) {
    super();
    this.DATE_FORMAT = this.statelessService.getLocaleDateFormat();
  }

  ngOnInit() {
    this.stateMgr.getState$().subscribe((state) => {
      this.state = state;
      this.stateMgr.setTitle();
      this.createContactsDetail();
      this.createPartyInformations();
    });
  }

  fullAddressStringMaps(address: AnagApiAddress) {
    const subjectCity = address.city ? address.city : '';
    const subjectProvince = address.province ? address.province.descrizione : '';
    const subjectCap = address.cap ? address.cap : '';
    const subjectNumber = address.number ? address.number : '';
    const subjectPlaceAddress = address.placeAddress ? address.placeAddress : '';
    const subjectToponym = address.toponym ? address.toponym.descrizione : '';
    if (subjectCity) {
      return 'http://maps.google.com/maps?daddr=' + subjectToponym + ' ' +
        subjectPlaceAddress + ' ' + subjectNumber + ' ' + subjectCap + ' ' +
        subjectCity + ' ' + subjectProvince;
    }
  }

  openUrl(url: string) {
    if (url) {
      window.open(url);
    }

  }

  private createContactsDetail() {

    this.subjectFaxNumber = '';
    this.subjectPhoneNumber = '';
    this.subjectMobilePhoneNumber = '';
    this.subjectMobilePhoneNumber2 = '';
    let internationalPrefix = '';
    let localPrefix = '';
    if (this.state.party.phoneFax) {
      let faxNumber = '';
      // FAX
      if (this.state.partyConfiguration.partyConfig.fax.internationalPrefix.configuration.codice !== PACONF_VALUES.HIDDEN &&
        this.state.party.phoneFax.internationalPrefix) {
        internationalPrefix = this.state.party.phoneFax.internationalPrefix;
      }
      if (this.state.partyConfiguration.partyConfig.fax.localPrefix.configuration.codice !== PACONF_VALUES.HIDDEN &&
        this.state.party.phoneFax.localPrefix) {
        localPrefix = this.state.party.phoneFax.localPrefix;
      }
      if (this.state.party.phoneFax.number) {
        faxNumber = this.state.party.phoneFax.number;
      }
      this.subjectFaxNumber = '' + internationalPrefix + ' ' + localPrefix + ' ' + faxNumber;
    }
    if (this.state.party.landlineNumber) {
      // PHONE
      internationalPrefix = '';
      localPrefix = '';
      let phoneNumber = '';
      if (this.state.partyConfiguration.partyConfig.phone.internationalPrefix.configuration.codice !== PACONF_VALUES.HIDDEN &&
        this.state.party.landlineNumber.internationalPrefix) {
        internationalPrefix = this.state.party.landlineNumber.internationalPrefix;
      }
      if (this.state.partyConfiguration.partyConfig.phone.localPrefix.configuration.codice !== PACONF_VALUES.HIDDEN &&
        this.state.party.landlineNumber.localPrefix) {
        localPrefix = this.state.party.landlineNumber.localPrefix;
      }
      if (this.state.party.landlineNumber.number) {
        phoneNumber = this.state.party.landlineNumber.number;
      }
      this.subjectPhoneNumber = '' + internationalPrefix + ' ' + localPrefix + ' ' + phoneNumber;
    }

    // MOBILE PHONE
    if (this.state.party.mobilePhone && this.state.party.mobilePhone[0]) {
      internationalPrefix = '';
      localPrefix = '';
      let mobilePhoneNumber = '';
      if (this.state.partyConfiguration.partyConfig.mobilePhone[0].internationalPrefix.configuration.codice !== PACONF_VALUES.HIDDEN) {
        internationalPrefix = this.state.party.mobilePhone[0].internationalPrefix;
      }
      if (this.state.partyConfiguration.partyConfig.phone.localPrefix.configuration.codice !== PACONF_VALUES.HIDDEN) {
        localPrefix = this.state.party.mobilePhone[0].localPrefix;
      }
      if (this.state.party.mobilePhone[0].number) {
        mobilePhoneNumber = this.state.party.mobilePhone[0].number;
      }
      this.subjectMobilePhoneNumber = '' + internationalPrefix + ' ' + localPrefix + ' ' + mobilePhoneNumber;
    }
    if (this.state.party.mobilePhone && this.state.party.mobilePhone[1]) {
      internationalPrefix = '';
      localPrefix = '';
      let mobilePhoneNumber2 = '';
      if (this.state.partyConfiguration.partyConfig.mobilePhone[1].internationalPrefix.configuration.codice !== PACONF_VALUES.HIDDEN) {
        internationalPrefix = this.state.party.mobilePhone[1].internationalPrefix;
      }
      if (this.state.partyConfiguration.partyConfig.phone.localPrefix.configuration.codice !== PACONF_VALUES.HIDDEN) {
        localPrefix = this.state.party.mobilePhone[1].localPrefix;
      }
      if (this.state.party.mobilePhone[1].number) {
        mobilePhoneNumber2 = this.state.party.mobilePhone[1].number;
      }
      this.subjectMobilePhoneNumber2 = '' + internationalPrefix + ' ' + localPrefix + ' ' + mobilePhoneNumber2;
    }
  }

  fullNumberUrl(phoneNumber: AnagPhoneNumber, type: 'sms' | 'tel') {
    if (!phoneNumber) {
      return;
    }
    const intPref = phoneNumber.internationalPrefix || '';
    const locPref = phoneNumber.localPrefix || '';
    const theNumber = phoneNumber.number;
    if (theNumber) {
      return type + ':' + intPref + locPref + theNumber;
    }
    return '';
  }

  back() {
    this.stateMgr.actionBack();
  }

  createNewParty() {
    this.stateMgr.actionCreateNewParty(this.stateMgrKeyData);
  }

  editParty() {
    this.stateMgr.actionEditParty(this.stateMgrKeyData);
  }

  detailParty() {
    this.stateMgr.goToFullDetail();
  }

  isEditButtonVisible() {
    return this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.edit);
  }

  isNewButtonVisible() {
    return this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.insert);
  }

  createPartyInformations() {
    const party = this.state.party;
    if (party) {
      if (this.state.isUmaPartyNumberEnable) {
        this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._PARTY_NUMBER_',
          party.partyNumber ? party.partyNumber : '', 'partyNumber'));

      }
      if (party.leicode) {
        this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._LEI_CODE_',
          party.leicode && party.leicode ? party.leicode : '',
          'leiCode'));
      }
      if (this.state.partyConfiguration && this.state.partyConfiguration.partyConfig) {
        const partyConfig = this.state.partyConfiguration.partyConfig;
        if (!this.state.isUmaPartyKeyEnable) {
          if ('2' === party.personType.codice) {
            if (partyConfig.vat &&
              partyConfig.vat.configuration &&
              partyConfig.vat.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._VAT_NUMBER_',
                party.vat ? party.vat : '',
                'vat'));
            }
            if (partyConfig.corporateForm &&
              partyConfig.corporateForm.configuration &&
              partyConfig.corporateForm.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._CORPORATE_FORM_',
                party.corporateForm && party.corporateForm.codice ? party.corporateForm.codice : '',
                'corporateForm'));
            }
          } else {
            if (partyConfig.fiscalCode &&
              partyConfig.fiscalCode.configuration
              && partyConfig.fiscalCode.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._FISCAL_CODE_',
                party.fiscalCode ? party.fiscalCode : '',
                'fiscalCode'));
            }
          }
        } else {
          if (!(party.countryOfBirth && party.personType ? party.countryOfBirth.codice === 'CH' && party.personType.codice === '2' : false)) {
            this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._CLIENT_UNIQUE_KEY_',
              this.statelessService.getUniqueKeysValue(this.state.party),
              'partyKeys'));
          }
          if ('1' === party.personType.codice) {
            if (partyConfig.sex &&
              partyConfig.sex.configuration &&
              partyConfig.sex.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._SEX_',
                party.sex && party.sex.descrizione ? party.sex.descrizione : '',
                'sex'));
            }
            if (partyConfig.gender &&
              partyConfig.gender.configuration &&
              partyConfig.gender.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._GENDER_IDENTITY_',
                party.genderIdentity && party.genderIdentity.descrizione ? party.genderIdentity.descrizione : '',
                'genderIdentity'));
            }
            if (partyConfig.dateOfBirth &&
              partyConfig.dateOfBirth.configuration &&
              partyConfig.dateOfBirth.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._BIRTH_DATE_',
                party.dateOfBirth ? this.datePipe.transform(party.dateOfBirth, this.DATE_FORMAT) : '',
                'dateOfBirth'));
            }
            if (partyConfig.cityOfBirth &&
              partyConfig.cityOfBirth.configuration &&
              partyConfig.cityOfBirth.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._CITY_OF_BIRTH_',
                party.cityOfBirth ? party.cityOfBirth : '',
                'cityOfBirth'));
            }
            if (partyConfig.citizenship &&
              partyConfig.citizenship.configuration &&
              partyConfig.citizenship.configuration.codice !== PACONF_VALUES.HIDDEN) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._CITIZENSHIP_',
                party.citizenship && party.citizenship.descrizione ? party.citizenship.descrizione : '',
                'citizenship'));
            }
            if (party.maritalStatus && party.maritalStatus.descrizione) {
              this.partyInformations.push(new AnagDisplayLabel('_ANAG_._LABEL_._CIVIL_STATE_',
                party.maritalStatus.descrizione,
                'civilState'));
            }
          }
        }
      }
    }
  }

}
