<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step label="{{TAX_RELIEF_STEP.DATE.label}}" id="{{TAX_RELIEF_STEP.DATE.id}}" [errors]="errors" fieldId="{{TAX_RELIEF_STEP.DATE.fieldId}}" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="TAX_RELIEF_STEP.DATE.formName" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step label="{{TAX_RELIEF_STEP.VARIATION.label}}" [errors]="errors" fieldId="{{TAX_RELIEF_STEP.VARIATION.fieldId}}" id="{{TAX_RELIEF_STEP.VARIATION.id}}" (next)="onNext($event)">
    <div *ngIf="!!taxReliefDefinitions.length" class="row">
      <ng-container *ngFor="let taxRelief of taxReliefDefinitions">
        <div>{{taxRelief.riskDescription}}</div>
        <hr>
        <lpc-form-field-handler [isActive]="isActiveStep(TAX_RELIEF_STEP.VARIATION.id)"
        [isOpen]="isActiveOrPassedStep(TAX_RELIEF_STEP.VARIATION.id)" [formControlName]="taxRelief.code" [definitions]="taxRelief.taxReliefDetail"></lpc-form-field-handler>
      </ng-container>
    </div>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="{{TAX_RELIEF_STEP.DOCUMENT.label}}" [errors]="errors" fieldId="{{TAX_RELIEF_STEP.DOCUMENT.fieldId}}" id="{{TAX_RELIEF_STEP.DOCUMENT.id}}"
    [nextVisible]=false>
    <lpc-document-wrapper *ngIf="!!publishMessage" [contractId]="getContractId" [operationCode]="getOperationCode"
      [publishMessage]="publishMessage" [isAuth]="isAuth" [authorizationId]="authorizationId" [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>
    <lpc-navigation-buttons id="last-step-buttons" (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'"
      [backVisible]="true" [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="warnings" label="lpc_warnings" (next)="publish()" [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
            (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>
