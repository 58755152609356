import {SharedComponentsTranslationKeys} from './translation.keys';

export const IT: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'Aggiungi',
      ADD_DOCUMENT: 'Aggiungi documento',
      ARCHIVE: 'Archivia',
      ARCHIVED: 'Archiviato',
      ARCHIVE_DOC: 'Archivia documento',
      ARCHIVING_USER: 'Utente archiviazione',
      CANCEL: 'Cancella',
      CLOSE: 'Chiudi',
      CODE: 'Codice',
      CONFIRM: 'Conferma',
      CREATED: 'Creato',
      DELETE_DOC: 'Cancella documento',
      DELIVERED: 'Inviato',
      DELIVERY_DOC: 'Invia documento',
      DELIVERY_ADDRESS: 'Indirizzio invio',
      DELIVERY_PARTY: 'Soggetto invio',
      DELIVERY_USER: 'Utente invio',
      DELIVERY_TYPE: 'Tipo invio',
      DESCRIPTION: 'Descrizione',
      DOCUMENTS_MANAGER: 'Gestione Documenti',
      DOCUMENT_TYPE: 'Tipo documento',
      DOCUMENT_VALIDATION_MESSAGE: 'Procedere con la validazione del documento?',
      DOWNLOAD: 'Download',
      DOWNLOAD_DOC: 'Scarica documento',
      EDIT: 'Modifica',
      ERROR: 'Errore',
      FILE: 'File',
      INPUT_DOCUMENTS: 'Documenti Input',
      LOAD: 'CARICA',
      NO: 'No',
      NOTE: 'Note',
      NO_FILES: 'Nessun file',
      NO_SIGN_NEEDED: 'Il documento non necessita firma',
      NOT_DELIVERED: 'Non inviato',
      NOT_SIGNED: 'Non firmato',
      NUMBER_OF_FILES_INSERTED: '{{ filesLength }} di {{ fileMax }} files inseriti',
      OUTBOUND_DOC_TITLE: 'Documenti polizza nr. {{ policyNumber }} del movimento {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'Documenti proposta nr. {{ proposalNumber }} del movimento {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'Documenti Output',
      PENDING_SIGNATURE_TOOLTIP: 'Documento in attesa di firma. Cliccare per interrompere il processo',
      REFRESH: 'Ricarica',
      SAVE: 'SALVA',
      SEND: 'Invia',
      SIGN: 'Firma',
      SIGN_DOCUMENT: 'Firma documento',
      SIGNED: 'Firmato',
      SIGNED_DOCUMENT: 'Documento firmato',
      SUBJECT_SENDING_DATE: 'Data invio a soggetto anagrafico',
      STATE: 'Stato',
      TO_BE_GENERATED: 'Da generare',
      TO_BE_UPLOADED: 'Da caricare',
      TYPE: 'Tipo',
      UPDATE_FILE: 'Modifica file',
      UPLOAD: 'UPLOAD',
      UPLOADED: 'Caricato',
      UPLOAD_NEW_DOCUMENT: 'Upload nuovo documento',
      UPLOADING_USER: 'Utente caricamento',
      VALIDATING_USER: 'Utente validazione',
      VALIDATED: 'Validato',
      VALIDATION: 'Validazione',
      VALIDATION_DOCUMENT: 'Validazione documento',
      YES: 'Sì',
      NO_DOCUMENTS_CONFIGURED: 'Non sono presenti documenti configurati per questa operazione'
    }
};
