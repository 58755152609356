<div>
    <div class="card shadow" style="min-height: 120em;">
        <form [formGroup]="newPolicyForm">
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._COMPANY' | translate }} <pnc-required required="true"></pnc-required></label>
                <select class="core-select form-control" id="companySel" name="company" formControlName="company"
                    attr.data-qa="external-portfolio-company-input">
                    <option></option>
                    <option *ngFor="let aComp of companies; " value={{aComp.aniaCode}}>{{aComp.description}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._POLICY_NUMBER' | translate }} <pnc-required required="true"></pnc-required>
                    </label>
                <input type="text" class="form-control" name="policyNumber"
                    attr.data-qa="external-portfolio-policyNumber-input" formControlName="policyNumber" />
            </div>
            <div class="col-xs-12" style="font-size: 6px;">&nbsp;</div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._EFFECT_DATE' | translate }}" customFormControlName="effectDate"
                    [customFormGroup]="newPolicyForm" attr.data-qa="external-portfolio-effectDate-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}" customFormControlName="expirationDate"
                    [customFormGroup]="newPolicyForm" attr.data-qa="external-portfolio-expirationDate-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._EMISSION_DATE' | translate }}" customFormControlName="emissionDate"
                    [customFormGroup]="newPolicyForm" attr.data-qa="external-portfolio-emissionDate-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._DATE_OF_COLLECTION' | translate }}" customFormControlName="releaseDate"
                    [customFormGroup]="newPolicyForm" attr.data-qa="external-portfolio-releaseDate-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._SUSPENSION_DATE_FROM' | translate }}"
                    customFormControlName="suspensionDateFrom" [customFormGroup]="newPolicyForm"
                    attr.data-qa="external-portfolio-suspensionDateFrom-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._SUSPENSION_DATE_TO' | translate }}" customFormControlName="suspensionDateTo"
                    [customFormGroup]="newPolicyForm" attr.data-qa="external-portfolio-suspensionDateTo-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._PREMIUM_DATE_FROM' | translate }}" customFormControlName="premiumFrom"
                    [customFormGroup]="newPolicyForm" attr.data-qa="external-portfolio-premiumFrom-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <pnc-datepicker label="{{ '_CLAIMS_._PREMIUM_DATE_TO' | translate }}" customFormControlName="premiumTo"
                    [customFormGroup]="newPolicyForm" attr.data-qa="external-portfolio-premiumTo-input">
                </pnc-datepicker>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._ARREARS_DAYS' | translate }}</label>
                <input type="number" class="form-control" name="ggMora" attr.data-qa="external-portfolio-ggMora-input"
                    formControlName="ggMora" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._OUT_OF_RANGE' | translate }} </label><br>
                <label class="btn rgi-radio-btn btn-primary" [ngClass]="attivaOutRangeS" (click)="valOutRange('S')">
                    SI</label>
                <label class="btn rgi-radio-btn btn-primary" [ngClass]="attivaOutRangeN" (click)="valOutRange('N')">
                    NO</label>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._POLICY_REPLACED_NUMBER' | translate }} </label>
                <input type="text" class="form-control" name="policyReplacedNumber"
                    attr.data-qa="external-portfolio-policyReplacedNumber-input"
                    formControlName="policyReplacedNumber" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._POLICY_REPLACEMENT_NUMBER' | translate }}</label>
                <input type="text" class="form-control" name="policyReplacementNumber"
                    attr.data-qa="external-portfolio-policyReplacementNumber-input"
                    formControlName="policyReplacementNumber" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._PRODUCT_CODE' | translate }} </label>
                <input type="text" class="form-control" name="productCode"
                    attr.data-qa="external-portfolio-productCode-input" formControlName="productCode" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._PRODUCT_DESCRIPTION' | translate }}</label>
                <input type="text" class="form-control" name="productDescription"
                    attr.data-qa="external-portfolio-productDescription-input" formControlName="productDescription" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._BRANCH' | translate }}</label>
                <input type="text" class="form-control" name="branch" attr.data-qa="external-portfolio-branch-input"
                    formControlName="branch" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._TERM_CODE' | translate }}</label>
                <input type="text" class="form-control" name="codTerm" attr.data-qa="external-portfolio-codTerm-input"
                    formControlName="codTerm" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._RISK' | translate }}</label>
                <input type="text" class="form-control" name="riskObject"
                    attr.data-qa="external-portfolio-riskObject-input" formControlName="riskObject" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._DIARIA' | translate }}</label>
                <input type="number" class="form-control" name="diaria" attr.data-qa="external-portfolio-diaria-input"
                    formControlName="diaria" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._DIARIA_DAYS' | translate }}</label>
                <input type="number" class="form-control" name="ggDiaria"
                    attr.data-qa="external-portfolio-ggDiaria-input" formControlName="ggDiaria" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._AGENCY' | translate }}</label>
                <input type="text" class="form-control" name="agency" attr.data-qa="external-portfolio-agency-input"
                    formControlName="agency" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._ASSET_TYPE' | translate }}</label>
                <select class="core-select form-control" id="assetInsuredType" name="assetInsuredType"
                    formControlName="assetInsuredType" attr.data-qa="external-portfolio-assetInsuredType-input">
                    <option value="ALTRO">{{ '_CLAIMS_._OTHER' | translate }}</option>
                    <option value="UBICAZIONE">{{ '_CLAIMS_._LOCATION' | translate }}</option>
                    <option value="VEICOLO">{{ '_CLAIMS_._VEHICLE' | translate }}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._ASSET_INSURED' | translate }}</label>
                <input type="text" class="form-control" name="assetInsured"
                    attr.data-qa="external-portfolio-assetInsured-input" formControlName="assetInsured" />
            </div>
            <div class="col-xs-12" style="font-size: 6px;">&nbsp;</div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._ADMINISTRATIVE_REGULARITY' | translate }}</label>
                <select class="core-select form-control" id="regAmm" name="regAmm" formControlName="regAmm"
                    attr.data-qa="external-portfolio-regAmm-input">
                    <option value="S">{{ '_CLAIMS_._YES' | translate }}</option>
                    <option value="N">{{ '_CLAIMS_._NO' | translate }}</option>
                    <option value="NP">{{ '_CLAIMS_._NA' | translate }}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._FRACTIONING' | translate }}</label>
                <input type="text" class="form-control" name="policySplitting"
                    attr.data-qa="external-portfolio-policySplitting-input" formControlName="policySplitting" />
            </div>
            <div class="col-xs-12" style="font-size: 6px;">&nbsp;</div>
            <!-- SOGGETTI INIZIO -->
            <!-- CONRAENTE -->
            <div class="form-group">
                <span class="rgifont rgi-user"></span>&nbsp;<label ng-bind="label"
                    style="color:#72BCFF; font-size: 25px;">{{ '_CLAIMS_._SUBJECTS' | translate }}</label>
            </div>
            <div class="form-group">
                <label ng-bind="label" style="color:#1569C7; font-size: 20px;">{{ '_CLAIMS_._CONTRACTOR' | translate }}
                    <pnc-required required="true"></pnc-required></label>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label" *ngIf="newPolicyForm.controls.subjectCode.value === 'F'">{{ '_CLAIMS_._SURNAME' | translate
                    }} <pnc-required required="true"></pnc-required></label>
                <label ng-bind="label" *ngIf="newPolicyForm.controls.subjectCode.value === 'G'">{{ '_CLAIMS_._BUSINESS_NAME' |
                    translate }} <pnc-required required="true"></pnc-required></label>
                <label ng-bind="label" *ngIf="newPolicyForm.controls.subjectCode.value == null">{{ '_CLAIMS_._SURNAME__BUSINESS_NAME' | translate }}
                     <pnc-required required="true"></pnc-required></label>

                <input type="text" class="form-control" name="denomination"
                    attr.data-qa="external-portfolio-denomination-input" formControlName="denomination" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._NAME' | translate }}</label>
                <input type="text" class="form-control" name="firstName"
                    attr.data-qa="external-portfolio-firstName-input" formControlName="firstName"
                    *ngIf="attivaNomeCnt" />
                <input type="text" class="form-control" name="firstName"
                    attr.data-qa="external-portfolio-firstName-input" formControlName="firstName" *ngIf="!attivaNomeCnt"
                    disabled />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._TAX_CODE__VAT_NUMBER' | translate }} </label>
                <input type="text" class="form-control" name="fiscalCodeVat"
                    attr.data-qa="external-portfolio-fiscalCodeVat-input" formControlName="fiscalCodeVat" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._PERSON_TYPE' | translate }} <pnc-required required="true"></pnc-required>
                    </label>
                <select class="core-select form-control" id="subjectCode" name="subjectCode"
                    formControlName="subjectCode" attr.data-qa="external-portfolio-subjectCode-input"
                    (change)="attivaNome('CNT')">
                    <option value="F">{{ '_CLAIMS_._PHYSICAL_PERSON' | translate }}</option>
                    <option value="G">{{ '_CLAIMS_._LEGAL_PERSON' | translate }}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._NATION' | translate }}</label>
                <select class="core-select form-control" id="countrySel" name="country" formControlName="country"
                    attr.data-qa="external-portfolio-country-input">
                    <option></option>
                    <option *ngFor="let aCountry of countries; " value={{aCountry.sigla}}>{{aCountry.descrizione}}
                    </option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._PROVINCE' | translate }}</label>
                <select class="core-select form-control" id="provinceSel" name="province" formControlName="province"
                    attr.data-qa="external-portfolio-stateCode-input" (change)="caricaComuni('CNT')">
                    <option></option>
                    <option *ngFor="let aProv of provinces; " value={{aProv.sigla}}>{{aProv.descrizione}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>

            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._MUNICIPALITY' | translate }}</label>
                <select class="core-select form-control" id="municipalitySel" name="municipality"
                    formControlName="municipality" attr.data-qa="external-portfolio-municipality-input"
                    (change)="caricaCap('CNT')">
                    <option></option>
                    <option *ngFor="let aCom of comunisCnt; " value={{aCom.descrizione}}>{{aCom.descrizione}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._POSTAL_CODE' | translate }}</label>
                <select class="core-select form-control" id="postalCodeSel" name="postalCode"
                    formControlName="postalCode" attr.data-qa="external-portfolio-postalCode-input">
                    <option></option>
                    <option *ngFor="let aCap of capsCnt; " value={{aCap}}>{{aCap}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-12" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._ADDRESS' | translate }}</label>
                <input type="text" class="form-control" name="address" attr.data-qa="external-portfolio-address-input"
                    formControlName="address" />
            </div>


            <!-- <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._GENDER' | translate }}</label><br>
                    <label  class="btn rgi-radio-btn btn-primary" [ngClass]="attivaUomoCnt" style="width: 250px;" (click)="valSex('CNT','M')"> Uomo</label>
                    <label  class="btn rgi-radio-btn btn-primary" [ngClass]="attivaDonnaCnt" style="width: 250px;" (click)="valSex('CNT','F')"> Donna</label>
                    <label  class="btn rgi-radio-btn btn-primary" [ngClass]="attivaAziendaCnt" style="width: 250px;" (click)="valSex('CNT','A')"> Azienda </label>

            </div> -->

            <div class="form-group">
                <label ng-bind="label" style="color:#1569C7; font-size: 20px;">{{ '_CLAIMS_._INSURED' | translate }} </label>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label" *ngIf="newPolicyForm.controls.subjectCodeInsured.value === 'F'">{{ '_CLAIMS_._SURNAME' | translate }} </label>
                <label ng-bind="label" *ngIf="newPolicyForm.controls.subjectCodeInsured.value === 'G'">{{ '_CLAIMS_._BUSINESS_NAME' | translate }} </label>
                <label ng-bind="label" *ngIf="newPolicyForm.controls.subjectCodeInsured.value == null">{{
                    '_CLAIMS_._SURNAME__BUSINESS_NAME' | translate }} </label>

                <input type="text" class="form-control" name="denominationInsured"
                    attr.data-qa="external-portfolio-denominationInsured-input" formControlName="denominationInsured" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._NAME' | translate }}</label>
                <input type="text" class="form-control" name="firstNameInsured"
                    attr.data-qa="external-firstNameInsured-input" formControlName="firstNameInsured"
                    *ngIf="attivaNomeAss" />

                <input type="text" class="form-control" name="firstNameInsured"
                    attr.data-qa="external-firstNameInsured-input" formControlName="firstNameInsured"
                    *ngIf="!attivaNomeAss" disabled />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._TAX_CODE__VAT_NUMBER' | translate }} </label>
                <input type="text" class="form-control" name="fiscalCodeVatInsured"
                    attr.data-qa="external-portfolio-fiscalCodeVatInsured-input"
                    formControlName="fiscalCodeVatInsured" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._PERSON_TYPE' | translate }} </label>
                <select class="core-select form-control" id="subjectCodeInsured" name="subjectCodeInsured"
                    formControlName="subjectCodeInsured" attr.data-qa="external-portfolio-subjectCodeInsured-input"
                    (change)="attivaNome('ASS')">
                    <option value="F">{{ '_CLAIMS_._PHYSICAL_PERSON' | translate }}</option>
                    <option value="G">{{ '_CLAIMS_._LEGAL_PERSON' | translate }}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._NATION' | translate }}</label>
                <select class="core-select form-control" id="countryInsuredSel" name="countryInsured"
                    formControlName="countryInsured" attr.data-qa="external-portfolio-countryInsured-input">
                    <option></option>
                    <option *ngFor="let aCountry of countries; " value={{aCountry.sigla}}>{{aCountry.descrizione}}
                    </option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>

            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._PROVINCE' | translate }}</label>
                <select class="core-select form-control" id="provinceInsuredSel" name="provinceInsured"
                    formControlName="provinceInsured" attr.data-qa="external-portfolio-stateCodeInsured-input"
                    (change)="caricaComuni('ASS')">
                    <option></option>
                    <option *ngFor="let aProv of provinces; " value={{aProv.sigla}}>{{aProv.descrizione}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>

            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._MUNICIPALITY' | translate }}</label>
                <select class="core-select form-control" id="municipalityInsuredSel" name="municipalityInsured"
                    formControlName="municipalityInsured" attr.data-qa="external-portfolio-municipalityInsured-input"
                    (change)="caricaCap('ASS')">
                    <option></option>
                    <option *ngFor="let aCom of comunisAss; " value={{aCom.descrizione}}>{{aCom.descrizione}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._POSTAL_CODE' | translate }}</label>
                <select class="core-select form-control" id="postalCodeInsuredSel" name="postalCodeInsured"
                    formControlName="postalCodeInsured" attr.data-qa="external-portfolio-postalCodeInsured-input">
                    <option></option>
                    <option *ngFor="let aCap of capsAss; " value={{aCap}}>{{aCap}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <div class="col-xs-12" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._ADDRESS' | translate }}</label>
                <input type="text" class="form-control" name="addressInsured"
                    attr.data-qa="external-portfolio-addressInsured-input" formControlName="addressInsured" />
            </div>


            <!-- <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._GENDER' | translate }}</label><br>
                <label  class="btn rgi-radio-btn btn-primary" [ngClass]="attivaUomoAss" style="width: 250px;" (click)="valSex('ASS','M')"> Uomo</label>
                <label  class="btn rgi-radio-btn btn-primary" [ngClass]="attivaDonnaAss" style="width: 250px;" (click)="valSex('ASS','F')"> Donna</label>
                <label  class="btn rgi-radio-btn btn-primary" [ngClass]="attivaAziendaAss" style="width: 250px;" (click)="valSex('ASS','A')"> Azienda </label>

            </div> -->

            <div class="form-group">
                <span class="rgifont rgi-list"></span>&nbsp;<label ng-bind="label"
                    style="color:#72BCFF; font-size: 25px;">{{ '_CLAIMS_._POLICY_CONDITION' | translate }}</label>
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._POLICY_CEILING' | translate }} <pnc-required required="true"></pnc-required>
                    </label>
                <input type="number" class="form-control" name="policyCeiling"
                    attr.data-qa="external-portfolio-policyCeiling-input" formControlName="policyCeiling" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._DEDUCTIBLE' | translate }} (%)</label>
                <input type="number" class="form-control" name="uncovered"
                    attr.data-qa="external-portfolio-uncovered-input" formControlName="uncovered" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._MINIMUM_UNCOVERED_VALUE' | translate }}</label>
                <input type="number" class="form-control" name="miniDiscoveredValue"
                    attr.data-qa="external-portfolio-miniDiscoveredValue-input" formControlName="miniDiscoveredValue" />
            </div>
            <div class="col-xs-6" style="font-size: 14px;">
                <label ng-bind="label">{{ '_CLAIMS_._EXCESS' | translate }}</label>
                <input type="number" class="form-control" name="deductible"
                    attr.data-qa="external-portfolio-deductible-input" formControlName="deductible" />
            </div>
            <div class="col-xs-12" style="font-size: 14px;">
                &nbsp;
            </div>
            <div class="col-xs-12">
                <div [ngClass]="classMsg" *ngIf="errore" role="alert">{{ errorMessage | translate }} </div>
                <div *ngIf="!errore">&nbsp; </div>
            </div>
            <div class="col-xs-12">
                <div class="alert alert-danger" *ngIf="externalError" role="alert">{{ externalErrorMessage | translate }} </div>
                <div *ngIf="!externalError">&nbsp; </div>
            </div>

        </form>
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button class="btn btn-warning pull-right" (click)="back()">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
            </div>
            <div class="btn-group">
                <button class="btn btn-warning pull-right" (click)="clearFilter()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
            </div>
            <div class="btn-group">
                <button class="btn btn-warning pull-right" (click)="confirmNewPolicy()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate
                    }}</button>
            </div>
        </div>
        <p>
            <ngb-progressbar type="warning" [value]="valueBar" height=".5rem">&nbsp;</ngb-progressbar>
        </p>
    </div>
</div>
