import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Activity, ActivityBusinessKeyClaim, Activityconstants, ActivityDto, ActivityGroup, ActivityTask,
  ActivityUser, ActivityVariable, ApiClaim, Outcome
} from '@rgi/digital-claims-common-angular';
import { ActivityService } from '../activity-list/activity.service';
import { ReassignTaskService } from './reassign-task.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-reassign-task',
  templateUrl: './reassign-task.component.html',
  styleUrls: ['./reassign-task.component.scss']
})
export class ReassignTaskComponent implements OnInit {

  public errorMessage: string = '';
  selectedGroup: any;
  groupCheckChecked: boolean = false;
  groups: any[];
  users: any[];
  user: any;
  formGroup: UntypedFormGroup;
  activityDto: ActivityDto;
  validationMessages: string[];

  filteredActivityUsers: ActivityUser[];
  apiClaim: ApiClaim;
  activityBusinessKeyBean: ActivityBusinessKeyClaim;
  activityTaskBean: ActivityTask;
  activityVariableBeanClaimNumber: ActivityVariable;
  activityVariableBeanAniaCompanyCode: ActivityVariable;
  activityVariableBeanPotClaimNumber: ActivityVariable;


  idUserIns: number;
  enabled: boolean = true;
  aniaCompanyCode: string;

  @Input() activity: Activity;

  idTaskCamunda: string;
  idTaskBPM: string;
  actTaskId: string;
  idAct: string;
  area: number;
  idActDict: string;
  cNumeroSinistro = 'CONTESTO_DENUNCIA';
  cNumeroDenuncia: string;

  allWfGroup = false;

  authSrv: any;

  @Input() codice: string;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private activityService: ActivityService,
    private reassigntask: ReassignTaskService,
    private activeModal: NgbActiveModal,
    private activityConstants: Activityconstants,
    private formBuilder: UntypedFormBuilder,
    @Inject('eventService') private eventService: any,
    @Inject('claimsActivityService') private claimsActivityService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    private rxTranslationsService: RgiRxTranslationService
  ) {
  }

  ngOnInit() {
    this.initDataForm();

    if (this.apiClaim != null && this.apiClaim.claimNumber != null) {
      this.cNumeroSinistro = this.apiClaim.claimNumber;
    }

    if (this.apiClaim != null && this.apiClaim.reportNumber != null) {
      this.cNumeroDenuncia = this.apiClaim.reportNumber;
    }

    this.manageGroups();
  }

  @Input() set task(task) {
    if (task != null) {
      this.idTaskBPM = task.taskId;
      this.actTaskId = task.actTaskId;
      this.idAct = task.id;
      if (task.claimNumber != null) {
        this.cNumeroSinistro = task.claimNumber;
      }
      this.cNumeroDenuncia = task.potClaimNumber;
      this.idActDict = task.idActDictionary;
    }
  }

  onClickSelectedUser(user: any) {
    this.user = user;
    this.errorMessage = null;
  }



  onGroupOptionSelected() {
    this.selectedGroup = this.formGroup.get('gruppiWF').value;
    if (!this.groupCheckChecked && this.selectedGroup !== '') {
      this.getUtentiWorkflowByGruppo(this.selectedGroup);
      // this.formGroup.get('utentiWF').enable();
    } else {
      this.users = null;
      this.errorMessage = null;
    }
  }


  getUtentiWorkflowByGruppo(selectedGroup: any) {
    // this.groups.forEach(group => {
    // if (group.codice === selectedGroup) {
    this.activityService.getUsers(selectedGroup)
      .subscribe((wfutenti: any[]) => {

        this.users = wfutenti;

      });
    // }
    // });
  }


  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    this.eventPropagation.emit('chiusuraModale');
    return false;
  }

  // (actTaskId: string, idTaskBPM: string, user: string, codice: string, callWF: string)

  onConfirm() {
    if (this.user != null) {

      if (this.activity != null) {
        this.idTaskBPM = this.activity.actTaskBean[0].taskId;
        this.actTaskId = this.activity.actTaskBean[0].idActTask.toString();
        if (!this.idAct && this.activity.idAct) {
          this.idAct = this.activity.idAct.toString();
        }
      }

      const codiceGruppo = this.formGroup.value.gruppiWF;
      let descrizioneGruppo = '';

      this.groups.forEach(group => {
        if (group.codice === codiceGruppo) {
          descrizioneGruppo = group.descrizione;
        }
      });



      this.activityService.riassegnaTask(this.idTaskBPM,
        this.actTaskId,
        this.idAct,
        this.user.codice,
        codiceGruppo,
        '1',
        '')
        .subscribe(
          (response: any) => {
            if (response.code === Outcome.OK) {
              var msgOk = '';
              this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._TASK_ASSIGNED_TO_USER').subscribe(res => msgOk = msgOk+res);
              msgOk = msgOk + ' ' + this.user.realName +' ';
              this.rxTranslationsService.translate('_CLAIMS_._GROUP').subscribe(res => msgOk = msgOk+res);
              this.eventService.broadcastEvent('alertMsg',
                { varMsg: msgOk +' '+ descrizioneGruppo });
              this.activeModal.close(response);
              this.eventPropagation.emit('riassegnatoOK');
            } else {
              this.validationMessages = ['KO'];
              this.eventService.broadcastEvent('alertMsg', { varMsg: 'Errore' });
              this.eventPropagation.emit('riassegnatoKO');
            }
          }
        );
    } else {
      this.rxTranslationsService.translate('_CLAIMS_._SELECT_A_USER_BEFORE_CONFIRMING')
        .subscribe(res => this.errorMessage = res)
    }
  }

  protected formatDate(date: Date) {
    const formatdate = 'dd-MM-yyyy';
    const dateString = formatDate(date, formatdate, this.locale);
    return dateString;
  }


  initDataForm() {

    const today = new Date();

    if (!this.formGroup) {
      this.formGroup = this.formBuilder.group({

        gruppiWF: []

      }, {

      });

    }
  }

  manageGroups() {
    this.getSystemProperty.findProperty('ClaimsAllWfGroupForReminder').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsAllWfGroupForReminder') {
            this.allWfGroup = (variable.value === 'Enable');
            if (this.activity !== undefined) {
              this.area = this.activity.actDictionaryBean.area;
            }
            if (this.area === undefined) {
              this.activityService.getDictionary(this.idActDict).subscribe((results: any) => {
                this.area = results.area;
                this.getGroups();
              });
            } else {
              this.getGroups();
            }
            break;
          }
        }
      }
    });
  }

  getGroups() {
    if (this.allWfGroup && this.area === 6) {
      this.groups = new Array();
      this.activityService.getGruppiAll().subscribe((results: any[]) => {
        results.forEach(res => {
          this.groups.push({ codice: res.code, descrizione: res.description });
        });
      });
    } else {
      this.activityService.getGruppi(this.cNumeroDenuncia, this.cNumeroSinistro).subscribe((results: any[]) => {
        this.groups = results;
      });
    }
  }

  sortBy(prop: string) {
    if (this.users !== undefined) {
      return this.users.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    } else {
      return this.users;
    }
  }

}
