<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="rent" *ngIf="hasRentFactors" label="lpc_annuity_data" [errors]="errors" fieldId="rent" (next)="onNext($event)">
    <div formGroupName="factors" class="row">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateFactors($event)"
        [form]="formGroup.controls['factors']" [factors]="listRentFactors">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="quotation" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event, true)">
    <div formGroupName="quotation" class="row">
      <div *ngFor="let insuredSettlement of insuredSettlements">
        <p *ngIf="!!insuredSettlement.insured && insuredSettlement.insured.length > 1;else singleInsured" class="insured">
          <label translate>lpc_Assicurati</label>
          <label>: {{ insuredSettlement.insured[0] + ' - ' + insuredSettlement.insured[1] }}</label>
        </p>
        <ng-template #singleInsured>
          <p class="insured">
            <label translate>lpc_Assicurato</label>
            <label>: {{ insuredSettlement.insured[0] }}</label>
          </p>
        </ng-template>
        <lpc-definition-table
          [label]="'Canceled liquidation detail'"
          [definitions]="tableDefinitions"
          [rows]="insuredSettlement.settlement">
        </lpc-definition-table>
        <lpc-formule-quotation-table
          [label]="'Annuity Detail'"
          [definitions]="quotationTableDefinitions">
        </lpc-formule-quotation-table>
        <br>
      </div>
    </div>
  </lpc-step>

  <lpc-step id="beneficiaries" label="lpc_life_detail_beneficiaries" [errors]="errors" fieldId="beneficiaries"
    (next)="onNext($event)" feFieldId="beneficiaries" [feErrors]="feErrors">
       <lpc-claim-beneficiaries
          formControlName="beneficiaries"
          [paymentTypes]="paymentTypes"
          [beneficiaryType]="beneficiaryType"
          [idAssicurato]="idAssicurato" [summary]="!isActiveStep('beneficiaries')"
          [blockBeneficiaries]="blockBeneficiaries"
          (addedSubject)="onAddedSubject($event)"
          #beneficiaries >
      </lpc-claim-beneficiaries>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
[resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
