import { IiabTranslationsKeys } from './iiab-translations-keys';

export const IIAB_FR: IiabTranslationsKeys = {
  _IIAB_: {
    _MOTOR_: {
      _NICE_ACTIMIZE_KO_: 'Le service est momentanément indisponible. Sil vous plait, réessayez dans quelques minutes.'
    },
    _OTP_: {
      _OTP_SERVICE_KO: 'Le service OTP n\'est pas disponible. Veuillez réessayer plus tard.',
      _INITIATED_SIGNING_PROCESS_: 'Processus de signature initié',
      _TITLE_: 'Signer des documents',
      _DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_: 'Le consentement de confidentialité de la signature numérique n\'est pas accepté. Il ne sera pas possible de signer numériquement des documents.',
      _MOBILE_NOT_PRESENT_NOT_SIGNED_: 'Le numéro de mobile n\'est pas présent. Il ne sera pas possible de signer numériquement des documents.',
      _EMAIL_NOT_PRESENT_NOT_SIGNED_: 'L\' adresse email n\'est pas présent. Il ne sera pas possible de signer numériquement des documents.',
      _DOCUMENT_DATA_NOT_PRESENT_NOT_SIGNED_: 'Certaines données du document sont manquantes. Il ne sera pas possible de signer numériquement des documents.',
      _GENERIC_NOT_SIGNED_ERROR_: 'Il ne sera pas possible de signer numériquement des documents.',
      _OTP_DOCUMENTS_: 'Signature Numérique - Documents',
      _CONSENSUSES_AND_CONTACTS_: 'Consensus et contacts',
      _OTP_DATA_MISSING_: 'Certaines données obligatoires manquent. Il ne sera pas possible de signer numériquement des documents.',
      _OTP_DOCUMENTS_MISSING_: 'Aucun document numérique disponible pour ce mouvement',
      _BTN_: {
        _SIGN_DOCS_: 'Signer des documents',
      },
      _HOLDERS_: 'FR_Holders',
      _SUBSCRIBER_: 'FR_subscriber',
      _MOBILE_PHONE_: 'Mobile phone'
    },
    _LABEL_: {
      _DESCRIPTION_: 'Description',
      _VALUE_: 'Valeur',
      _RELEASE_DATE_: '',
      _EXPIRATION_DATE_: '',
      _INT_PRIFIX_: '',
      _NUMBER_: ''
    },
    _ANAG_: {
      _TAX_ID_DIFFERENT_FROM_DOCUMENT_ID_: 'FR_The identity document (NIF/NIE/Passport) reported in the Documents section does not match the identity document (NIF/NIE/Passport) reported in the Identification Data section'
    }
  },
  _LS_TITLE_TOTAL_SUMMARY_: 'Résumé total',
  _NICE_ACTIMIZE_ : {
    _HEAD_HIT : 'Nice Actimize hits',
    _HEAD_NOMINATIVE: 'Nominatif',
    _HEAD_SURNAME : 'Nom',
    _HEAD_NAME : 'Nom',
    _HEAD_DATE_OF_BIRTH : 'Date de naissance',
    _HEAD_RESULT : 'Résultat',
    _HEAD_TYPE_OF_HIT: 'Type de hit',
    _HEAD_REQUEST_ID : 'Identifiant de la demande',
    _HIT_FOUND : 'Hit identifié',
    _HIT_NOT_FOUND : 'Aucun hit identifié',
    _HIT_YES: 'Le sujet avait hits. Un contrôle complémentaire sur Nice Actimize doit être effectué',
    _HIT_NO: 'Le sujet n\'avait aucun hits. Aucun risque n\'a été détecté sur la fête'
    },
    _COUNTERPROPOSAL_: 'Contre-proposition',
    _ADJUST_PROPOSAL_: 'Ajuster la proposition',
    _IIAB_ERR_UPLOAD_MANDATORY_DOC_: 'Veuillez télécharger les documents obligatoires'
};
