<div [formGroup]="formGroup" class="active">
  <div data-qa="contract-list">
    <div class="col-sm-6">
      <span class="styled-select">
        <ng-select class="col-lg-12" id="custom" [clearable]="false" formControlName="count" (change)="handlePageSizeChange($event)">
        <ng-option *ngFor="let size of pageSizes" [value]="size">
            {{ size }}
        </ng-option>
      </ng-select>
      </span>
      <span translate>lpc_show_elemets</span>
    </div>
    <div class="col-sm-6">
      <input placeholder="{{'Find'}}" formControlName="search" name="filter" style="margin-left: 65%;">
    </div>

    <div class="row" style="margin-top: 50px;">
      <div class="col-md-12">
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate><pnc-checkbox (click)="checkAll()" #checkBoxComponent></pnc-checkbox></div>
            <div class="tbld_col tbld_col_title" translate>lpc_policy_number</div>
            <div class="tbld_col tbld_col_title" translate>lpc_product</div>
            <div class="tbld_col tbld_col_title" translate>lpc_insured_form</div>
            <div class="tbld_col tbld_col_title" translate>lpc_roles_variation</div>
          </div>
          <ng-container *ngFor="let contract of contractListData | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count }; index as i" formArrayName="contracts">
            <div class="tbld_row" [formGroupName]="getCorrectIndex(contract.id)">
              <div class="tbld_col tbld_col_value">
                <pnc-checkbox formControlName="active" (click)="singleCheck()"></pnc-checkbox>
              </div>
              <div class="tbld_col tbld_col_value">{{contract.number}}</div>
              <div class="tbld_col tbld_col_value">{{contract.product}}</div>
              <div class="tbld_col tbld_col_value">{{contract.insuranceForm}}</div>
              <div class="tbld_col tbld_col_value">
                <ng-container *ngFor="let role of contract.roles; index as j">
                  {{role}}{{j < contract.roles.length-1 ? ', ' : ''}}
                </ng-container>
              </div>
            </div>
          </ng-container>

        </div>

      </div>

    </div>
    <div class="right-align pages-box">
      <pagination-controls previousLabel=""
      nextLabel="" (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>
    </div>

  </div>
</div>


<div class="row inactive" *ngIf="!!getPolicySummary()?.length" >
  <div class="tbld life-detail-margin-top">
    <div class="tbld_row life-detail-tbld_row_header">
      <div class="tbld_col tbld_col_title" translate>lpc_policy_number</div>
      <div class="tbld_col tbld_col_title" translate>lpc_product</div>
      <div class="tbld_col tbld_col_title" translate>lpc_insured_form</div>
      <div class="tbld_col tbld_col_title" translate>lpc_roles_variation</div>
    </div>
    <ng-container *ngFor="let pol of getPolicySummary()">
      <div class="tbld_row">
        <div class="tbld_col tbld_col_value">{{pol.pol.number}}</div>
        <div class="tbld_col tbld_col_value">{{pol.pol.product}}</div>
        <div class="tbld_col tbld_col_value">{{pol.pol.insuranceForm}}</div>
        <div class="tbld_col tbld_col_value">
          <ng-container *ngFor="let role of pol.pol.roles; index as j">
            {{role}}{{j < pol.pol.roles.length-1 ? ', ' : ''}}
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="pages-box"></div>
</div>
