<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">
  <lpc-step id="dates" label="lpc_date_variation_step" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>


  <lpc-step [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation" (next)="onNext($event, true)" id="quotation">
    <div *ngIf="!!quotationData" class="overflow">
      <lpc-reactivation-quotation [quotationData]="quotationData"></lpc-reactivation-quotation>
    </div>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

      <lpc-document-wrapper
        *ngIf="!!publishMessage"
        [contractId]="getContractId"
        [operationCode]="getOperationCode"
        [publishMessage]="publishMessage"
        [isAuth]="isAuth"
        [authorizationId]="authorizationId"
        [printMode]="printMode"
        [config]="documentsConfig"
        [contract]="policy"
        [movmentDescription]="session.operationDescription"
        [movementId]="lastMovementId"
        (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
      </lpc-document-wrapper>

      <lpc-navigation-buttons
        id="last-step-buttons"
        (back)="closeCardWithoutModal()"
        [backLabel]="'lpc_close_button'"
        [backVisible]="true"
        [nextVisible]="false">
      </lpc-navigation-buttons>

  </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
