import { AfterContentChecked, Component, Inject, Injector, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import {
  ActionsService,
  DocumentService,
  LifeSessionService,
  PolicyService,
  SystemPropertiesService,
  CardsNavigationService,
  LifeRoleService,
  ValidationSubjectsService,
  LayeredRuleService,
  IssueComponent,
  TranslationWrapperService,
  DocumentEvent,
  _DOCUMENT_, INJECT_TOKEN
} from '@rgi/life-issue-card';
import {OtpService} from '../../services/otp-service.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { catchError, debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { of} from 'rxjs';
import {DocumentsComponent, DocumentsService, OutboundDocumentsService} from "@rgi/shared-components";

@Component({
  templateUrl: './issue-ext.component.html',
  styleUrls: ['./issue-ext.component.css']
})
export class IssueExtComponent extends IssueComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('inputDocuments') inputDocuments: DocumentsComponent;

  otpSectionMessage: string;

  digitalSigned = false;
  showOtpDocuments = true;

  constructor(
    @Inject('$injector') protected injector: Injector,
    protected policyService: PolicyService,
    protected cardsNavigationService: CardsNavigationService,
    protected actionsService: ActionsService,
    protected lifeSessionService: LifeSessionService,
    protected lifeRoleService: LifeRoleService,
    protected validationSubjectsService: ValidationSubjectsService,
    protected layeredRuleService: LayeredRuleService,
    protected documentService: DocumentService,
    protected translate: TranslationWrapperService,
    protected notifierService: NotifierService,
    protected systemPropertiesService: SystemPropertiesService,
    protected modalService: NgbModal,
    @Optional() protected questCacheManager: QuestionnaireCacheService,
    @Optional() @Inject(INJECT_TOKEN.NEW_OUTBOUND_DOC_HANDLER) public newOutboundHandler: boolean,
    protected rgiDocumentService: DocumentsService,
    protected outBoundDocumentService: OutboundDocumentsService,
    protected otpService: OtpService,
    protected translateService: RgiRxTranslationService
  ) {
    super(injector, policyService, cardsNavigationService, actionsService,
      lifeSessionService, lifeRoleService, validationSubjectsService, layeredRuleService,
      documentService, translate, notifierService, systemPropertiesService, modalService, questCacheManager,
      newOutboundHandler, rgiDocumentService, outBoundDocumentService);
  }
  ngOnInit(): void {
    this.$subscriptions.push(this.otpService.callOtpAbilitation().subscribe(() => this.showOtpDocuments = this.otpService.otpAbilitation));
    super.ngOnInit();
  }

  ngAfterContentChecked(): void {
    if (!!this.inputDocuments) {
      this.$subscriptions.push(
        this.inputDocuments?.eventModifiedDocument?.asObservable().pipe(
          debounceTime(600),
          take(1),
          tap(() => {
            this.hideDeleteDocumentButton();
          })
        ).subscribe(),
        this.inputDocuments.startDocument.pipe(
          debounceTime(600),
          take(1),
          tap(() => {
            this.hideDeleteDocumentButton();
          })
        ).subscribe(
        )
      );
    }
  }

  startDocuments(event) {
    super.startDocuments(event);
    this.hideDeleteDocumentButton();
  }

  /**
   * Signs the documents and initiates the signing process.
   * Emits 'startLoader' event to indicate the start of the signing process.
   * Retrieves the movement ID from the policy service.
   * Retrieves the document codes from the document list.
   * Retrieves the contract ID from the main proposal.
   * Retrieves the subject ID from the life role service.
   * Calls the otpService to sign the documents.
   * Emits 'stopLoader' event to indicate the end of the signing process.
   * Sets the otpSectionMessage to 'Signing process initiated' if the signing is successful.
   * Sets digitalSigned to true if the signing is successful.
   */
  sign() {
    this.eventPropagation.emit('startLoader');
    const movementId = this.policyService.getMovementID();
    const documentCodes: string[] = [];
    if (this.documentList && this.documentList.length > 0) {
      this.documentList.map(doc => doc.documentCode).forEach(code => documentCodes.push(code));
    }
    const idPolizza = (this.policyService.mainProposal.proposal.contractData.find(c => !!c && !!c.contractId) || {contractId: ''}).contractId;
    const idSoggetto = this.lifeRoleService.holderSubj.objectId;
    this.otpService.signDocuments(idPolizza, movementId, idSoggetto).subscribe(res => {
      this.eventPropagation.emit('stopLoader');
      if (res) {
        this.translateService.translate('_IIAB_._OTP_._INITIATED_SIGNING_PROCESS_').subscribe(translation => this.otpSectionMessage = translation);
        this.digitalSigned = true;
        this.afterSign(res);
      }
    }, (error) => {
      this.eventPropagation.emit('stopLoader');
    });
  }
  //Template method
  afterSign(res: any) {};

  /**
   * Checks if the OTP sign button is visible based on privacy consensus("_FROTP" = 1) and phone number.
   *
   * @returns True if the sign is visible, false otherwise.
   */
  signVisible() {
    if (!this.showOtpDocuments) {
      return false;
    }
    if (this.is3a() && this.haveDocuments() && this.havePhoneNumberAndPrivacyConsensus()) {
      const existsPhoneNumber = this.lifeRoleService.holderSubj.mobilePhone[0].number !== '';
      const privacyOTYes = this.lifeRoleService.holderSubj.privacyConsensus.filter(p => p.privacy.code === '_FROTP' && p.privacyConsensusValue.codice === '1');
      const existsPrivacyConsensus = privacyOTYes.length > 0;
      const existsDocument = this.existsDocument();    
      return existsPhoneNumber && existsPrivacyConsensus && existsDocument;
    }
    return false;
  }

  private existsDocument() {
    let existsDocument = false;
    let subject: any = this.lifeRoleService.holderSubj;
    if (subject.documents && subject.documents.length > 0) {
      for (let document of subject.documents) {
        existsDocument = document.documentType && document.documentNumber &&
          document.locationsRelease && document.releaseDate && document.expirationDate;
        if(existsDocument)
          break;
      }
    }
    return existsDocument;
  }

  /**
   * Checks if the factor with code '3PISEL' has a value of '1' in the policy service.
   * @returns  Returns true if the factor exists and has a value of '1', otherwise returns false.
   */
  is3a(): boolean {
    if (this.policyService && this.policyService.mainProposal && this.policyService.mainProposal.quote
      && this.policyService.mainProposal.quote.product && this.policyService.mainProposal.quote.product.factors) {
      const factor = this.policyService.mainProposal.quote.product.factors.find(f => f.code === '3PISEL');
      return factor && factor.value === '1';
    }
    return false;
  }


  private haveDocuments() {
    return this.documentList && this.documentList.length > 0;
  }

  private havePhoneNumberAndPrivacyConsensus() {
    return this.lifeRoleService && this.lifeRoleService.holderSubj && this.lifeRoleService.holderSubj.mobilePhone
      && this.lifeRoleService.holderSubj.mobilePhone.length > 0 && this.lifeRoleService.holderSubj.mobilePhone[0].number
      && this.lifeRoleService.holderSubj.privacyConsensus && this.lifeRoleService.holderSubj.privacyConsensus.length > 0
      && this.lifeRoleService.holderSubj.emails && this.lifeRoleService.holderSubj.emails.length > 0
      && !this.isPolicy();
  }

  private isPolicy() {
    return this.policyService.mainProposal.proposal.contractData
      && this.policyService.mainProposal.proposal.contractData.length > 0
      && this.policyService.mainProposal.proposal.contractData[0].policyNumber;
  }

  override oldDownloadSystem(docEvent: DocumentEvent): void {
    let fileUrl = '';
    const movementId = this.policyService.getMovementID();
    this.documentService.downloadDocument(movementId, docEvent.document.documentCode).pipe(
      switchMap(resp => {
        if (!resp) {
          return of(null);
        } else {
          fileUrl = `${resp.fileUrl}`;
          return this.documentService.callDocument(fileUrl);
        }
      }),
      catchError((err) => {
        return of({error: 'KO_injection', fileUrl});
      }),
    ).subscribe(responseDownload => {
      if (!!responseDownload) {
        const contentType = this.documentService.getDocumentContentTypeByURL(fileUrl);
        const fileName = fileUrl.split('/').pop();
        const extension = fileName.split('.').pop();
        const windowTarget = '_blank';
        const windowFeatures = 'name=' + fileName;
        const blob = new Blob([responseDownload], {type: contentType});
        const file = new File([blob], fileName, {type: contentType});
        const URL = window.URL;
        const downloadUrl = URL.createObjectURL(file);
        if (extension === _DOCUMENT_.EXTENSION_PDF || extension === _DOCUMENT_.EXTENSION_HTML) {
            window.open(downloadUrl, windowTarget, windowFeatures);
        } else {
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(downloadUrl);
        }
      }
    });
  }

  private isEnabledFeatures() {

    return  this.lifeSessionService.getOperator() &&
            this.lifeSessionService.getOperator().enabledFeatures &&
            this.lifeSessionService.getOperator().enabledFeatures.length;
  }
  private isOperationEnabledForOperator(operation: string): boolean {

    if (!this.isEnabledFeatures()) {
      return false;
    }
    const featureFounded = this.lifeSessionService.getOperator().enabledFeatures.find(feature => feature === operation);
    return !!featureFounded && !!featureFounded.length;
  }

  private isSubmitAvailableForType(): boolean {

    return this.type === 'lifeAndDamageProposal' || this.type === 'lifeProposal' || this.type === 'saveAcceptAndPropIssue';
  }
  public isSubmitAvailable(): boolean {

    return this.isSubmitAvailableForType() &&
           this.isOperationEnabledForOperator(this.policyService.mainProposal.quote.product.operationCode);
  }

  /* override verifyDocuments(event: any): void {
    super.verifyDocuments(event);
    this.hideDeleteDocumentButton();
  } */


  protected hideDeleteDocumentButton() {
    if (!!this.inputDocuments?.documents && !this.inputDocuments?.documents?.documentsDelatable) {
      this.inputDocuments.deleteDocumentEnabled = false;
      /* const openPreviewFiles = (document as Document).getElementsByClassName('rgishared-ml-s');
      if (openPreviewFiles.length) {
        Array.from(openPreviewFiles)
        .filter(elementAt => Array.from(elementAt.children).filter(child => child.getElementsByClassName('rgi-ui-icon-delete')).length > 0)
        .forEach(element => {
          element.setAttribute('style', 'display: none');
        });
      } */
    }
  }
}
