<div *ngIf="!!quoteData" class="blank">
  <h3 translate>{{label}}</h3>
<div class="tbld postsales-table-style hidden-xs" *ngIf="!!quoteData.risksQuotation && !!quoteData.risksQuotation.length">
  <!-- INTESTAZIONE -->
  <div class="tbld_row postsales-col-title">
    <div class="tbld_col" translate>lpc_Guarantee</div>
    <div class="tbld_col" translate>lpc_insuredAmount</div>
  </div>
  <!-- GARANZIE -->
  <div *ngFor="let risk of quoteData.risksQuotation" class="tbld_row postsales-row-content">
    <div class="tbld_col postsales-row-title" data-qa="risk-description">{{risk.riskDescription}}</div>
    <div class="tbld_col psvalue" data-qa="benefit">{{risk.benefit | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
  </div>
  <div class="tbld_row postsales-row-total">
    <div class="tbld_col" translate data-qa="garanzia-rataFirma-vita-total">lpc_total</div>
    <div class="tbld_col psvalue" data-qa="total-benefit">{{totalBenefit | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</div>
  </div>
</div>
</div>

<div class="row instalments-row">
  <ng-container *ngFor="let inst of instalments">
    <div class="{{'col-lg-'+ 12 / instalments.length}}" *ngIf="!!inst && !!inst.instalment">
      <table class="table premium-table">
        <thead>
          <tr>
            <th translate>{{inst.label}}</th>
            <th [attr.data-qa]="'th-inst-total'">{{inst.instalment.total | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td translate>lpc_Taxes</td>
            <td [attr.data-qa]="'td-inst-taxes'">{{ inst.instalment.taxes | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</td>
          </tr>
          <tr>
            <td translate>lpc_Fees</td>
            <td [attr.data-qa]="'td-inst-fees'">{{ inst.instalment.fees | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</td>
          </tr>
          <tr>
            <td translate>lpc_fractionation_interest</td>
            <td [attr.data-qa]="'td-inst-frequencyInterests'">{{ inst.instalment.frequencyInterests | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</td>
          </tr>
          <tr>
            <td translate>lpc_default_interest</td>
            <td [attr.data-qa]="'td-inst-delayInterests'">{{ inst.instalment.delayInterests | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</td>
          </tr>
          <tr>
            <td translate>lpc_default_interest</td>
            <td [attr.data-qa]="'td-inst-reactivationInterests'">{{ inst.instalment.reactivationInterests | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</td>
          </tr>
          <tr>
            <td translate>lpc_net</td>
            <td [attr.data-qa]="'td-inst-net'">{{ inst.instalment.net | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

</div>
