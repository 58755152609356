<!-- STORICO RIVALUTAZIONI -->
<div *ngIf="!!revaluationData && !!revaluationData.length; else noReval"
  class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail"
  data-qa="tabella storico rivalutazioni">
    <div class="row life-detail-card-title-container">
      <div class="row life-detail-title">
        <div class="card-title">
          <span class="header-icon rgifont "></span>
          <span class="menu-title" translate>lpc_revaluation_history</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_Benefit</div>
            <div class="tbld_col tbld_col_title" *ngIf="hasRevaluationRate" translate>lpc_revaluation_rate</div>
            <div class="tbld_col tbld_col_title" translate>lpc_benefit_commencement_date</div>
            <div class="tbld_col tbld_col_title" translate>lpc_initial_benefit</div>
            <div class="tbld_col tbld_col_title" *ngIf="hasTariffVersion" translate>lpc_tariff_version</div>
            <div class="tbld_col tbld_col_title" translate>lpc_movement_update</div>
            <div class="tbld_col tbld_col_title" translate>lpc_update_effective_date</div>
            <div class="tbld_col tbld_col_title" *ngIf="hasNetRate" translate>lpc_net_rate</div>
            <div class="tbld_col tbld_col_title" translate>lpc_revalueted_benef</div>
          </div>
          <ng-container *ngFor="let revaluation of revaluationData; let i = index">
            <div class="tbld_row regular"  [ngClass]="revaluation.cssClass">
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="performance-description">{{revaluation.prestazioneMaster ? revaluation.prestazioneMaster : revaluation.prestazione}}<span *ngIf="revaluation.prestazioneMaster && revaluation.prestazione" [lpcTooltip]="revaluation.prestazione" placement="top" delay="200" class="rgifont rgi-info-circle ml-2"></span></div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="gestione-separata" *ngIf="hasRevaluationRate">{{revaluation.gs ? revaluation.gs : '-'}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="data-effetto-prestazione">{{revaluation.dataEffetto | date: 'dd/MM/yyyy'}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="prestazione-iniziale"> {{ !!revaluation.prestazioneIniziale ? (revaluation.prestazioneIniziale | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions ) : '-' }} </div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="versione-tariffa" *ngIf="hasTariffVersion"> {{ revaluation.tariffVersion }} </div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="movimento aggiornamento" > {{ !!revaluation.movimentoAggiornamentoMaster ? revaluation.movimentoAggiornamentoMaster : revaluation.movimentAggiornamento}}<span *ngIf="revaluation.movimentoAggiornamentoMaster && revaluation.movimentAggiornamento" [lpcTooltip]="revaluation.movimentAggiornamento" placement="top" delay="200" class="rgifont rgi-info-circle ml-2"></span></div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="data-effetto-aggiornamento"> {{ !!revaluation.effettoAggiornamento ? (revaluation.effettoAggiornamento | date: 'dd/MM/yyyy') : '-'}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="tasso-netto" *ngIf="hasNetRate"> {{ !!revaluation.tassNetto ? (revaluation.tassNetto | rgiCountryLayerNumberFormat ) : '-'}}</div>
              <div class="tbld_col tbld_col_value life-detail-table-border" data-qa="prestazione-rivalutata">{{revaluation.prestazioneRivalutata | rgiCountryLayerNumberFormat: currencyService.locale : currencyFormatterOptions }} </div>
            </div>
          </ng-container>
        </div>
    </div>

  </div>
</div>
<ng-template #noReval>
<p translate>lpc_no_result</p>
</ng-template>
