import {
    Component, EventEmitter, OnInit, Output,
    ComponentFactoryResolver, ViewChild, ViewContainerRef, OnDestroy, Inject
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AuthorizationFilter} from '../resources/models/authorization-filter';
import {AuthorizationsStatesService} from '../services/authorizations-states.service';
import {AuthorizationsSearchService} from '../services/authorizations-search.service';
import {Authorization} from '../resources/models/authorization';
import {AuthorizationsStates} from '../resources/constants/authorizations-states';
import { DateComparisonValidator } from './date-comparison.validator';
import {PolicyType} from '../resources/constants/policy-type';
import {RgiRxUserService, UserService} from "@rgi/rx/auth";
import { AuthorizationsActions } from '../resources/constants/authorizations-actions';
import {ActiveRoute, RgiRxRouter} from "@rgi/rx/router";
import {StateManagedComponent} from '@rgi/rx/state';
import {AuthorizationCardStateStart} from '../authorization-card-state/authorization-card-state';
import {
    AuthorizationCardStateManagerStart
} from '../authorization-card-state/authorization-card-statemanager-start';
import { NodeService } from '../services/node.services';
import { Agency } from '../resources/models/sales-network-api-agency';
import {
    FIELD,
    FIELD_AUTH_COMPETENT_USER, FIELD_AUTH_DEROGATION_LEVEL,
    FIELD_AUTH_DEROGATION_LEVEL_NUMBER,
    FIELD_AUTH_LAST_ACTION,
    FIELD_AUTH_POLICY_TYPE, FIELD_AUTH_POSTSELL_CODE,
    FIELD_AUTH_PRIORITY, FIELD_AUTH_PRODUCT_TYPE,
    FIELD_AUTH_REQUEST_CODE, FIELD_AUTH_REQUEST_DATE_FROM, FIELD_AUTH_REQUEST_DATE_TO,
    FIELD_AUTH_REQUEST_STATUS,
    FIELD_AUTH_REQUEST_TYPE,
    FIELD_AUTH_USER_TYPE,
    FIELD_AUTH_WORKFLOW_STATUS
} from "../auth-constants/auth-home-fields-constants";
import {AuthHomeFieldConfigurationService} from "../services/auth-home-field-configuration.service";
import { RgiRxTranslationService } from '@rgi/rx/i18n';


const ART_AGENCY_CODE_KEY = 'artAgencyCode';

@Component({
    selector: 'ac-start',
    templateUrl: './start.component.html',
    host: {
        class: 'rgi-authorizations-style'
    }
})
export class StartComponent extends StateManagedComponent<AuthorizationCardStateStart, AuthorizationCardStateManagerStart> implements OnInit, OnDestroy {
    @Output() navigation = new EventEmitter<string>();

    @ViewChild('nodeSearchModalOverlay', {
        read: ViewContainerRef,
        static: true
    }) nodeSearchModalOverlayContainerRef: ViewContainerRef;

    componentRef: any;

    startForm: UntypedFormGroup;
    submitted = false;
    salePointCode: string;
    salePoint: any;
    maxNode = 10;
    isAgency: boolean;
    nodeSelected: any;
    availableAgencies: any;
    node: any;
    subscriber: any = {};
    subtree = true;
    advancedSearch = false;
    hasResults = true;

    requestTypes: Array<any> = new Array<any>();
    salePointGroups: Array<any> = new Array<any>();
    priorities: Array<any> = new Array<any>();
    contractTypes: Array<any> = new Array<any>();
    userTypes: Array<any> = new Array<any>();
    requestStatusList: Array<any> = new Array<any>();
    workflowStatusList: Array<any> = new Array<any>();
    policyTypeList: Array<any> = new Array<any>();
    productTypeList: Array<any> = new Array<any>();
    subsystemList: Array<any> = new Array<any>();
    derogationLevelList: Array<any> = new Array<any>();
    lastActionList: Array<any> = new Array<any>();

    derogationLevel = false;
    validationMessages: string[] = [];
    noResultsMessages: string[] = [];

    authorizationListFiltered: Array<Authorization> = new Array<Authorization>();
    showPmFields = false;
    protected subscriptions: Subscription = new Subscription();

    FIELD_AUTH_REQUEST_CODE: FIELD = FIELD_AUTH_REQUEST_CODE;
    FIELD_AUTH_REQUEST_TYPE: FIELD = FIELD_AUTH_REQUEST_TYPE;
    FIELD_AUTH_PRIORITY: FIELD = FIELD_AUTH_PRIORITY;
    FIELD_AUTH_POLICY_TYPE: FIELD = FIELD_AUTH_POLICY_TYPE;
    FIELD_AUTH_USER_TYPE: FIELD = FIELD_AUTH_USER_TYPE;
    FIELD_AUTH_COMPETENT_USER: FIELD = FIELD_AUTH_COMPETENT_USER;
    FIELD_AUTH_REQUEST_STATUS: FIELD = FIELD_AUTH_REQUEST_STATUS;
    FIELD_AUTH_WORKFLOW_STATUS: FIELD = FIELD_AUTH_WORKFLOW_STATUS;
    FIELD_AUTH_REQUEST_DATA_FROM: FIELD = FIELD_AUTH_REQUEST_DATE_FROM;
    FIELD_AUTH_REQUEST_DATA_TO: FIELD = FIELD_AUTH_REQUEST_DATE_TO;
    FIELD_AUTH_PRODUCT_TYPE: FIELD = FIELD_AUTH_PRODUCT_TYPE;
    FIELD_AUTH_POSTSELL_CODE: FIELD = FIELD_AUTH_POSTSELL_CODE;
    FIELD_AUTH_DEROGATION_LEVEL: FIELD = FIELD_AUTH_DEROGATION_LEVEL;
    FIELD_AUTH_DEROGATION_LEVEL_NUMBER: FIELD = FIELD_AUTH_DEROGATION_LEVEL_NUMBER;
    FIELD_AUTH_LAST_ACTION: FIELD = FIELD_AUTH_LAST_ACTION;
    translationValidationMessagesKeys: string[] = ['_AUTH_._NODE_IS_MANDATORY','_AUTH_._SUBSYSTEM_IS_MANDATORY','_AUTH_._FILL_IN_REQUEST_STATUS_OR_USER','_AUTH_._REQUEST_DATE_FROM_IS_NOT_A_VALID_DATE','_AUTH_._REQUEST_DATE_TO_IS_NOT_A_VALID_DATE','_AUTH_._REQUEST_DATE_FROM_MUST_BE_GREATER_THEN_REQUEST_DATE_TO'];
    translatedValidationMessages: string[];
    translationNoResultsMessagesKeys: string[] = ['_AUTH_._NO_RESULTS'];
    translatedNoResultsMessages: string[];

    constructor(
        public activeRoute: ActiveRoute,
        private manager: AuthorizationCardStateManagerStart,
        protected formBuilder: UntypedFormBuilder,
        protected stateService: AuthorizationsStatesService,
        protected authorizationsSearchService: AuthorizationsSearchService,
        protected userService: RgiRxUserService,
        protected nodeService: NodeService,
        protected authHomeFieldConfigurationService: AuthHomeFieldConfigurationService,
        protected translationService: RgiRxTranslationService,
    ) {
        super(manager);
    }

    ngOnInit() {

        this.stateService.setCurrentState(AuthorizationsStates.START);

        this.startForm = this.formBuilder.group(
            {
                subscriber: undefined,
                node: ['', Validators.required],
                requestCode: undefined,
                policyNumber: undefined,
                proposalNumber: undefined,
                requestType: undefined,
                salePointGroup: undefined,
                priority: undefined,
                userType: undefined,
                competentUser: undefined,
                requestStatus: undefined,
                workflowStatus: undefined,
                policyType: undefined,
                productType: undefined,
                requestDateFrom: undefined,
                requestDateTo: undefined,
                postSellCode: undefined,
                subsystem: [undefined, Validators.required],
                derogationLevelNumber: undefined,
                lastAction: undefined,
                contractType: undefined
                // proposalNumberPM: undefined,
                // policyNumberPM: undefined
            }, {
                validator: DateComparisonValidator('requestDateFrom', 'requestDateTo')
            }
        );

        this.advancedSearch = this.authorizationsSearchService.isAdvancedSearch();
        this.getOperators();
        this.initializeLists();
        this.initializeAgencies();

        const updatePartySubscription = this.authorizationsSearchService.getPartyObservable().subscribe(
            (data: any) => {
            if (data && data.partyId) {
                this.subscriber.party = data;
            } else if (data && data.idSp) {
                this.nodeSelected = data;
                this.startForm.patchValue({ node: this.nodeSelected.idSp });
            }
        },
            err => console.log(err));
        this.subscriptions.add(updatePartySubscription);

        const startFormValueChangesSubscription = this.startForm.valueChanges.subscribe((val) => {
            this.validateForm();
        });
        this.subscriptions.add(startFormValueChangesSubscription);
        const translatedValidationMessagesSubscription = this.translationService.translateAll(...this.translationValidationMessagesKeys).subscribe((translations)=> this.translatedValidationMessages = translations);
        const translatedNoResultsMessagesSubscription = this.translationService.translateAll(...this.translationNoResultsMessagesKeys).subscribe((translations)=> this.translatedNoResultsMessages = translations);
        this.subscriptions.add(translatedValidationMessagesSubscription);
        this.subscriptions.add(translatedNoResultsMessagesSubscription);
        this.noResultsMessages.push(this.translatedNoResultsMessages[0])
    }

    getOperators() {
        let user :any;
        user = this.userService.getUser();
        this.salePoint = user.salePoint;
        const artAgencyProperty = this.extractArtAgencyProperty();

        if (!(this.salePoint && this.salePoint.objectId)) {
            this.salePoint = user.salePoint;
            if (artAgencyProperty) {
                this.salePoint.extension.properties.push(artAgencyProperty);
            }
        }
        if (this.salePoint) {
            this.salePointCode = this.salePoint.code;
            this.nodeSelected = this.salePoint;
            this.startForm.patchValue({ node: this.salePoint });
        }
    }

    extractArtAgencyProperty(): any {
        if (this.salePoint && this.salePoint.extension && Array.isArray(this.salePoint.extension.properties)) {
            const artAgencyCodeArr = this.salePoint.extension.properties.filter(property => property.chiave === ART_AGENCY_CODE_KEY);
            if (artAgencyCodeArr.length > 0) {
                return artAgencyCodeArr[0];
            }
        }
        return null;
    }

    valuesSubtree() {
        this.subtree = !this.subtree;
    }

    valuesDerogationLevel() {
        this.derogationLevel = !this.derogationLevel;
    }

    protected initializeAgencies() {
        this.nodeService.getNodes$().subscribe((data: Array<Agency>) => {
            this.availableAgencies = data;
            if (this.availableAgencies.length === 1 && this.salePoint) {
                this.startForm.patchValue({node: this.salePoint});
                this.node = this.salePoint.objectId;
            }
        });
    }

    initializeLists() {
        this.authorizationsSearchService.getFilterValues().subscribe(
            (filter) => {
                this.requestTypes = filter.requestTypes;
                this.salePointGroups = filter.salePointGroups;
                this.priorities = filter.priorities;
                this.userTypes = filter.userTypes;
                this.requestStatusList = filter.requestStatusList;
                this.workflowStatusList = filter.workflowStatusList;
                this.policyTypeList = filter.policyTypeList;
                this.productTypeList = filter.productTypeList;
                this.subsystemList = filter.subsystemList;
                this.derogationLevelList = filter.derogationLevelList;
                this.lastActionList = filter.lastActionList;
                this.contractTypes = filter.contractTypes;
                // // MOCK TO DELETE
                // this.contractTypes = [
                //     new any(null, '1', 'Individuale', ),
                //     new any(null, '2', 'Polizza Madre', ),
                //     new any(null, '3', 'Applicazioni', )
                // ];
                this.startForm.patchValue({
                    userType: filter.userType,
                    requestStatus: filter.requestStatus,
                    subsystem: (filter.subsystemList.length === 1 ? filter.subsystemList[0] : undefined)
                });
            }
        );
    }

    onSubmit(event) {
        this.submitted = true;

        this.stateService.resetState(this.activeRoute.id);
        this.validateForm();
        this.setFieldsDirty();

        if (this.startForm.valid) {
            const filter: AuthorizationFilter = new AuthorizationFilter();

            this.setFilter(filter);
            this.findAuthorization(filter);
        }
    }

    findAuthorization(filter: AuthorizationFilter) {
        this.authorizationsSearchService.getAuthorizationsList(filter).subscribe(
            (authList) => {
                this.authorizationListFiltered = authList;
                this.authorizationsSearchService.setFilteredAuthorizations(authList);

                if (authList.length === 1) {
                    this.authorizationsSearchService.getAuthorizationDetail(authList[0]).subscribe(
                        (item) => {
                            this.authorizationsSearchService.setAuthorization(item);

                            this.stateService.nextState(this.activeRoute.id, this.navigation, AuthorizationsActions.FIND);
                        });
                } else if (authList.length > 0) {
                    this.stateService.nextState(this.activeRoute.id, this.navigation, AuthorizationsActions.FIND);
                } else {
                    this.hasResults = false;
                }
            },
            (err) => {
                console.log(err);
            });
    }

    onClear() {
        this.submitted = false;
        this.hasResults = true;
        this.showPmFields = false;
        this.ngOnInit();
        this.cleanSubscriber();
        this.startForm.reset();
        if (this.availableAgencies.length === 1) {
            this.startForm.patchValue({node: this.node});
        }

        if (this.subsystemList.length === 1) {
            this.startForm.patchValue({subsystem: this.subsystemList[0]});
        }
    }

    // bottone sul campo nodo (cestino)
    cleanNode() {
        this.startForm.patchValue({node: null});
        this.nodeSelected = null;
        // this.showBasket = false;
    }

    selectChangeHandlerNode(event: any) {
        this.node = event;
    }

    protected validateForm() {
        const controls = this.startForm.controls;

        this.validationMessages.length = 0;
        this.hasResults = true;

        if (controls.node.errors && controls.node.errors.required) {
            this.validationMessages.push(this.translatedValidationMessages[0]);
        }
        if (controls.subsystem.errors && controls.subsystem.errors.required) {
            this.validationMessages.push(this.translatedValidationMessages[1]);
        }

        if (!controls.userType.value && !controls.requestStatus.value) {
            controls.userType.setErrors({required: true});
            controls.requestStatus.setErrors({required: true});
            this.validationMessages.push(this.translatedValidationMessages[2]);
        } else {
            controls.userType.setErrors(null);
            controls.requestStatus.setErrors(null);
        }

        if (controls.requestDateFrom.errors && controls.requestDateFrom.errors.ngbDate) {
            this.validationMessages.push(this.translatedValidationMessages[3]);
        }

        if (controls.requestDateTo.errors && controls.requestDateTo.errors.ngbDate) {
            this.validationMessages.push(this.translatedValidationMessages[4]);
        }

        if (controls.requestDateFrom.errors && controls.requestDateFrom.errors.date1AfterDate2 ||
            controls.requestDateTo.errors && controls.requestDateTo.errors.date1AfterDate2) {
            this.validationMessages.push(this.translatedValidationMessages[5]);
        }
    }

    protected setFieldsDirty() {
        Object.keys(this.startForm.controls).forEach(field => {
            this.startForm.get(field).markAsDirty();
        });
    }

    protected setFilter(filter: AuthorizationFilter) {
        const controls = this.startForm.controls;
        const nodeId = controls.node.value.idSp || controls.node.value.objectId || controls.node.value.id;

        const requestDateFrom = controls.requestDateFrom.value;
        const requestDateTo = controls.requestDateTo.value;

        const user: any = this.userService.getUser();
        filter.userLogin = user.username;
        if (this.subscriber && this.subscriber.objectId) {
            filter.idSubject = this.subscriber.objectId;
        }
        filter.nodeCode = nodeId;
        filter.subtree = this.subtree;
        filter.derogationLevel = this.derogationLevel;

        if (controls.requestCode.value) {
            filter.requestCode = controls.requestCode.value.trim();
        }
        if (controls.policyNumber.value) {
            filter.policyNumber = controls.policyNumber.value.trim();
        }
        if (controls.proposalNumber.value) {
            filter.proposalNumber = controls.proposalNumber.value.trim();
        }
        if (controls.salePointGroup.value) {
            filter.sellGroup = controls.salePointGroup.value.id;
        }
        if (controls.requestType.value) {
            filter.requestType = controls.requestType.value.id;
        }
        if (controls.priority.value) {
            filter.priority = controls.priority.value.id;
        }
        if (controls.userType.value) {
            filter.userType = controls.userType.value.id;
        }
        if (controls.competentUser.value) {
            filter.user = controls.competentUser.value;
        }
        if (controls.requestStatus.value) {
            filter.authorizationState = controls.requestStatus.value.id;
        }
        if (controls.workflowStatus.value) {
            filter.workflowState = controls.workflowStatus.value.id;
        }
        if (requestDateFrom) {
            filter.dateFrom = requestDateFrom.getTime() - 60000 * requestDateFrom.getTimezoneOffset();
        }
        if (requestDateTo) {
            filter.dateTo = requestDateTo.getTime() - 60000 * requestDateTo.getTimezoneOffset();
        }
        if (controls.policyType.value) {
            filter.policyType = controls.policyType.value.id;
        }
        if (controls.productType.value) {
            filter.productType = controls.productType.value.id;
        }
        if (controls.postSellCode.value) {
            filter.postSellCode = controls.postSellCode.value;
        }
        if (controls.subsystem.value) {
            filter.underSystem = controls.subsystem.value.id;
        }
        if (controls.derogationLevelNumber.value) {
            filter.derogationLevelNumber = controls.derogationLevelNumber.value.id;
        }
        if (controls.lastAction.value) {
            filter.lastAction = controls.lastAction.value.id;
        }
        // if (controls.proposalNumberPM.value) {
        //     // filter.proposalNumberPM = controls.proposalNumberPM.value.trim();
        // }
        // if (controls.policyNumberPM.value) {
        //     filter.policyNumberPM = controls.proposalNumberPM.value.trim();
        // }
        this.authorizationsSearchService.setAuthorizationFilter(filter);
    }

    cleanSubscriber() {
        this.subscriber = {};

        this.startForm.patchValue({
            subscriber:  this.subscriber
        });
    }

    compareGenericEntities(ent1: any, ent2: any): boolean {
        return ent1 && ent2 ? ent1.description === ent2.description : ent1 === ent2;
    }

    genericEntitiesTrackByFn(index, any: any) {
        return any.code;
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    showPMFields() {
        this.showPmFields = !!this.startForm.controls.policyType &&
            (PolicyType.MASTER === this.startForm.controls.policyType.value.code ||
                PolicyType.CHILD === this.startForm.controls.policyType.value.code);
        if (!this.showPmFields) {
            this.cleanSubscriber();
        }
    }

    onPartySelected($event) {
        if ($event && $event.changed) {
            this.subscriber = $event.changed;
        }
    }

    public isFieldVisible(fieldToSearch: FIELD): boolean {
        let visible = true;
        if (this.authHomeFieldConfigurationService.getFields(this.id)) {
            if (this.authHomeFieldConfigurationService.getFields(this.id).find(field => field.name === fieldToSearch.name).visible === 0) {
                visible = false;
            }
        }
        return visible;
    }
}
