<ng-container *ngIf="state">
  <div class="rgi-anag-flex-center rgi-anag-p-l">
    <div class="rgi-anag-circle rgi-anag-hm-xl"><span class="rgi-anag-icon-xxl rgi-ui-icon-user-filled"></span></div>
    <div class="rgi-anag-flex-gr-1">
      <div class="rgi-anag-flex-center">
        <div class="rgi-anag-detail-drag">
          <rgi-rx-drop-container [ngModel]="state.party" viewField="nominative" data-qa="anag-detail-party-drop">
          </rgi-rx-drop-container>
        </div>
        <div class="label-desc-title"
             *ngIf="state.partyConfiguration && state.partyConfiguration.partyConfig.subjectType.configuration.codice !== PACONF_VALUES.HIDDEN">
          - {{state.party.subjectType.descrizione}}
        </div>
      </div>
      <div class="anag-ml-m rgi-ui-text-3">
        <div class="rgi-anag-w-100 rgi-anag-vm-m" *ngFor="let partyInfo of partyInformations">
          <span translate>{{partyInfo.label}}</span>
          <b class="anag-ml-m" [attr.data-qa]='partyInfo.dataQa'>{{ partyInfo.value}}</b>
        </div>
      </div>
    </div>
  </div>
  <table class="rgi-anag-detail-contact-table">
    <tbody>
    <tr>
      <td>
        <div class="rgi-anag-flex-center">
          <div class="rgi-anag-detail-block-icon rgi-ui-icon-marker icon-contacts"></div>
          <a class="anag-ml-l" role="button" (click)="openUrl(fullAddressStringMaps(state.party.residence))"
             data-qa="party-ubication-link"
             *ngIf="state.party.residence && !state.party.residence.formatAddress">

            <span
              *ngIf="state.party.residence.placeAddress && state.partyConfiguration.partyConfig.address.placeAddress.configuration.codice !== PACONF_VALUES.HIDDEN && state.partyConfiguration.partyConfig.address.toponym.configuration.codice !== PACONF_VALUES.HIDDEN">{{state.party.residence.toponym.descrizione}}</span>
            {{state.party.residence.placeAddress}}<span
            *ngIf="state.party.residence.number && state.partyConfiguration.partyConfig.address.number.configuration.codice !== PACONF_VALUES.HIDDEN">,</span>
            {{state.party.residence.number}}<span
            *ngIf="state.party.residence.cap && state.party.residence.city && state.partyConfiguration.partyConfig && state.partyConfiguration.partyConfig.address.cap.configuration.codice !== PACONF_VALUES.HIDDEN && state.partyConfiguration.partyConfig.address.city.configuration.codice !== PACONF_VALUES.HIDDEN">
            &minus;
          </span> {{state.party.residence.cap}} {{state.party.residence.city}} <span
            *ngIf="state.party.residence.province && state.party.residence.province.descrizione && state.partyConfiguration && state.partyConfiguration.partyConfig.address.province.configuration.codice !== PACONF_VALUES.HIDDEN">({{state.party.residence.province.descrizione}}
            )</span>
          </a>
          <a class="anag-ml-l" href="http://maps.google.com/maps?daddr={{state.party.residence.formatAddress}}"
             target="_blank" data-qa="party-ubication-link"
             *ngIf="state.party.residence && state.party.residence.formatAddress">
            {{state.party.residence.formatAddress}}
          </a>
        </div>
      </td>
      <td>
        <div class="rgi-anag-flex-wrap">
          <div class="rgi-anag-flex-center rgi-anag-w-100 rgi-anag-mb-s">
            <div class="rgi-anag-detail-block-icon rgi-ui-icon-smartphone icon-contacts"></div>
            <a class="anag-ml-l" role="button"
               *ngIf="state.partyConfiguration && state.partyConfiguration.partyConfig.phone.number.configuration.codice !== PACONF_VALUES.HIDDEN"
               (click)="openUrl(fullNumberUrl(state.party.mobilePhone[0], 'sms'))"
               data-qa="mobileNumber">{{subjectMobilePhoneNumber}}
            </a>
          </div>
          <div class="rgi-anag-flex-center rgi-anag-w-100 rgi-anag-mb-s">
            <div class="rgi-anag-detail-block-icon rgi-ui-icon-smartphone icon-contacts"></div>
            <a class="anag-ml-l" role="button"
               *ngIf="state.partyConfiguration && state.partyConfiguration.partyConfig.phone.number.configuration.codice !== PACONF_VALUES.HIDDEN"
               (click)="openUrl(fullNumberUrl(state.party.mobilePhone[1], 'sms'))"
               data-qa="mobileNumber">{{subjectMobilePhoneNumber2}}
            </a>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="rgi-anag-flex-wrap">
          <div class="rgi-anag-flex-center rgi-anag-w-100 rgi-anag-mb-s">
            <div class="rgi-anag-detail-block-icon rgi-ui-icon-phone icon-contacts"></div>
            <a class="anag-ml-l" role="button" (click)="openUrl(fullNumberUrl(state.party.landlineNumber, 'tel'))"
               data-qa="phoneNumber">{{subjectPhoneNumber}}</a>
          </div>
          <div class="rgi-anag-flex-center rgi-anag-w-100">
            <div class="rgi-anag-detail-block-icon rgi-ui-icon-fax icon-contacts"></div>
            <span class="anag-ml-l" data-qa="fax">{{subjectFaxNumber}}</span>
          </div>
        </div>
      </td>
      <td>
        <div class="rgi-anag-flex-center">
          <div class="rgi-anag-detail-block-icon rgi-ui-icon-mail icon-contacts"></div>
          <a class="anag-ml-l" data-qa="mail"
             href="mailto:{{state.party.emails[0]}}" *ngIf="state.party.emails">{{state.party.emails[0] | lowercase}}
          </a>
          <a class="anag-ml-l" data-qa="mail2"
             href="mailto:{{state.party.emails[1]}}" *ngIf="state.party.emails">{{state.party.emails[1] | lowercase}}
          </a>
          <a class="anag-ml-l" data-qa="mail3"
             href="mailto:{{state.party.emails[2]}}" *ngIf="state.party.emails">{{state.party.emails[2] | lowercase}}
          </a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="rgi-ui-panel-footer rgi-anag-tm-1">
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="back()"
            data-qa="anag-btn-back" translate>_ANAG_._BTN_._BACK_</button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="createNewParty()" *ngIf="isNewButtonVisible()"
            data-qa="anag-btn-new-party" translate>_ANAG_._BTN_._NEW_</button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="editParty()" *ngIf="isEditButtonVisible()" [disabled]="state.party.limitation"
            data-qa="anag-btn-edit-party" translate>_ANAG_._BTN_._EDIT_</button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="detailParty()"
            data-qa="anag-btn-detail" translate>_ANAG_._BTN_._DETAIL_</button>
  </div>
</ng-container>
