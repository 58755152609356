import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export const emptyStringValidator: ValidatorFn = (control: UntypedFormControl) => {
  const value = control.value;

  // If value is an empty string, return the validation error
  if (typeof value === 'string' && value.trim() === '') {
    return { emptyString: true };
  }

  // Otherwise, return null to indicate no error
  return null;
};

export const numericValidator: ValidatorFn = (control: UntypedFormControl) => {
  const value = control.value;
  if (value && !/^[0-9]+$/.test(value)) {
    return { error: 'non Numeric value' };
  }
  return null;
}
