import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { QuestionnaireFlatI } from '@rgi/ng-passpro';
import { Questionnaire, QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BENEFICIARY_CHOICE, ExtensionProperty, OperationCode, QuestionnaireCodes, Roles } from '../enum/life-issue.enum';
import { FactorCode } from '../invest-standalone-session/utils/factor-code.enum';
import { PassProfileData } from '../lic-fund/model/PassProfileData';
import { AuthorizedToolResponse, PassResponse, PassResponseOptions, PassResponseProfile } from '../lic-fund/model/PassResponse';
import { Profile } from '../lic-fund/model/profile';
import { InboundTools, ToolDefinition } from '../lic-investment-contract/model/tool-definition';
import { InvestmentDataResponse, InvestmentPlanData } from '../lic-karma-funds/model/karma-profile';
import { IDDProduct } from '../models/iddProduct.model';
import { Contribution, ContributionResp, FormFieldDefinition } from '../models/pip.model';
import {
  CommonRiskResponse,
  Factor,
  Installment,
  LifeParty,
  LifeRiskInsuredData,
  LifeSavePolicyResponse,
  PolicyModel,
  Product,
  Properties,
  Proposal,
  QuestionnaireManagerElement,
  QuestionnairesManager,
  RiskInstallment
} from '../models/policy.model';
import { LicObjectUtils } from '../utils/lic-object-utils';
import { CustomProperties, Value } from './../models/policy.model';
import { LifeSessionService } from './life-session-service';
import { SystemPropertiesService } from './system-properties.service';
import { EnviromentsService } from './enviroments.service';
import { LifeRoleService } from './life-role.service';
import { PassUtils } from '../invest-standalone-session/utils/PassUtils';
import {RGI_RX_I18N_CONFIG, RgiRx18nConfig, RgiRxTranslationService} from '@rgi/rx/i18n';
import {RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {LifeRgiRxHttpClientWrapperService} from './http/wrapper/life-rgi-rx-http-client-wrapper.service';


export enum Insured {
  COULD_BE_EQUAL,
  MUST_NOT_BE_EQUAL,
  NOT_SHOWED,
  MUST_BE_EQUAL,
  MUST_BE_EMPTY
}

// FIXME: change when right product will be available
const FINANCIAL_PRODUCT_CODE = 'UNIT';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  toolContrib: any;
  investmentContrib: any;
  showCommonRisk: BehaviorSubject<boolean> = new BehaviorSubject(true);
  scheduledPremiumInvestmentPlan: any;

  constructor(protected http: HttpClient,
              protected environment: EnviromentsService,
              protected lifeSessionService: LifeSessionService,
              protected lifeRoleService: LifeRoleService,
              protected systemPropertiesService: SystemPropertiesService,
              @Inject(RGI_RX_I18N_CONFIG) protected rgiRxConfig: RgiRx18nConfig,
              protected rxTranslationsService: RgiRxTranslationService,
              protected lifeRgiRxHttpClientWrapperService: LifeRgiRxHttpClientWrapperService) {
  }

  public policySavefromquote = false;

  oldQuote: PolicyModel;
  mainProposal: PolicyModel;
  mainProposalResourceId = null;
  linkedProposal: PolicyModel;
  showInsured = false;
  showSecondInsured = false;
  operation: Insured;
  quotationDone = false;
  showCoSigner = false;
  demandAndNeedsVersionInfo: any;
  idMasterPolicy = null;
  masterPolicyStatus = null;

  coreResultData: any = null;

  productFromIDD: IDDProduct = null;
  availableProducts: Product[] = [];
  subjectFromIDD = null;

  isFromIDD = false; // indica quando arrivo dal questionario IDD
  haveToInitIDD = false;


  transferJson: FormFieldDefinition[] = [];
  contribJson: ContributionResp;
  contributionData: Contribution[] = [];

  isFromAuth = false; // indica quando arrivo dalla MODIFICA PROPOSTA in auth
  isSaveFromAuth = false; // indica quando arrivo dal SALVA PROPOSTA/POLIZZA in auth
  isFromPreventive = false; // indica quando arrivo dal flusso di PREVENTIVO
  isAnonymous = false; // indica quando arrivo da un preventivo anonimo (senza soggetto)
  isFromConsultation = false; // indica quando arrivo da consultazione proposta
  isFromBaseIssueFlow = false; // indica quando arrivo dal flusso normale di emissione
  isSaveAcceptAndIssue = false; // indica quando arrivo dal flusso di salva-accetta-emetti (settato in fase di emissione)
  isFromQuoteModification = false; // indica quando arrivo dal flusso di modifica preventivo
  isFromBuyQuote = false; // indica quando arrivo da acquista preventivo dettaglio
  isFromProposalModification = false; // indica quando arrivo dal botton EDIT in consultazione proposta
  hideButtonSaveUndefinedProposal= false;

  flowTypeOnConfirm = null; // indica il tipo di flusso che abbiamo confermato

  investmentsData = null;
  toolsData: Properties = null;

  private _productId = null; // setted on summary's submit

  private subject = new Subject<any>();

  quotationvisited = false;

  loading = false;

  ppevoFactorsProp = [];
  modifiedQuestionnaire = [];
  surveyVersionNumberMap = new Map();

  haveToResetQuest = false;
  firstTimeInit = true;
  private _movementID = null;
  // i'll stock the last value of the context sent on the extensionData
  private _currentContext = null;
  private _currentCurrencyCode = null;

  get idPvManagement(): string {
    return this.lifeSessionService.idPvManagement;
  }

  get productId() {
    return !!this._productId ? this._productId : this.mainProposal.quote.productID;
  }
  set productId(prodID) {
    this._productId = prodID;
  }

  getMovementID(): string {
    if (!!this._movementID) {
      return this._movementID;
    }
    const property = this.mainProposal && this.mainProposal.proposal.extensionData.properties
    .find(prop => prop && prop.chiave === ExtensionProperty.ISSUE_MOV);
    return property && property.valore;
  }

  setMovementID(movId) {
    this._movementID = movId;
  }

  /** returns the current context which is the context of the policy to view correctly the format of the numbers */
  get currentContext() {
    if (!this.mainProposal) {
      return this._currentContext;
    }
    const currentContext = this.getExtensionProperty(ExtensionProperty.RGI_CURRENT_CONTEXT);
    return currentContext && currentContext.valore;
  }

  get currentLocale(): string {
    const language = !!this.rxTranslationsService && !!this.rxTranslationsService.currentLanguage ?
    this.rxTranslationsService.currentLanguage :
    this.rgiRxConfig.defaultLocale;
    const currentContext = !!this.currentContext ? this.currentContext : language.toLocaleUpperCase();
    return language + '-' + currentContext;
  }

  get currencyCode(): string {
    const currencyCodeProd =  this.mainProposal && this.mainProposal.quote && this.mainProposal.quote.product.currencyCode;
    return !!currencyCodeProd ? currencyCodeProd : this._currentCurrencyCode;
  }

  get productHolderType(): number {
    const res =  this.mainProposal && this.mainProposal.quote  && this.mainProposal.quote.product
                                   && this.mainProposal.quote.product.holderType;
    return res;
  }

  getFormatterOptionsWithDecimal(style: 'decimal' | 'currency' | 'percent' | 'unit', decimal?: string): Intl.NumberFormatOptions {
    const options: Intl.NumberFormatOptions = { style, currency: this.currencyCode ? this.currencyCode : 'EUR' };
    if (!!decimal) {
      const dec = decimal.split('.');
      if (!!Number(dec[0])) { options.minimumIntegerDigits = Number(dec[0]); }
      if (!!Number(dec[1].split('-')[0])) { options.minimumFractionDigits = Number(dec[1].split('-')[0]); }
      if (!!Number(dec[1].split('-')[1])) {options.maximumFractionDigits = Number(dec[1].split('-')[1]); }
    }
    return options;
  }

  // viene chiamato prima che il flusso inizi
  // serve per caricare le informazioni che ci servono all'avvio
  // (c'è sempre il problema che apre la card due volte)
  loadAllIssueConfigurations(): Observable<any> {
    return this.systemPropertiesService.loadAllProperties();
  }

  emitInitProposalEnd(id: string) {
    this.loading = false;
    this.subject.next(id);
  }

  clearMessages() {
    this.subject.next();
  }

  resetProposal() {
    // chiamato all'onDestroy della card padre (life-card.component)
    this.quotationvisited = false;
    this.mainProposal = null;
    this.mainProposalResourceId = null;
    this.ppevoFactorsProp = [];
    this.contribJson = null;
  }

  isProposalCreated(): boolean {
    return (this.mainProposal != null);
  }

  get isCAC(): boolean {
    if (!!this.mainProposal) {
      return this.mainProposal.proposal.typeAdhesionCode === '300001';
    } else {
      return false;
    }
  }

  getProposal() {
    if (this.mainProposal.quote.resourceId == null) {
      console.log('No resource ID...');
      return;
    }
    this.getProposalFromCache().subscribe(
      (proposal: any) => {
        console.log(proposal, 'proposal');
      }, err => {
        console.error(err);
      }
    );
  }

  isSecondInsuredPresent(): boolean {
    return this.showSecondInsured;
  }
  setSecondInsuredPresent(value: boolean) {
    return this.showSecondInsured = value;
  }

  isInsuredPresent(): boolean {
    return this.showInsured;
  }
  setInsuredPresent(value: boolean) {
    return this.showInsured = value;
  }

  isUnitLinkedRiskType(lifeRiskInsuredData: LifeRiskInsuredData[]) {
    return lifeRiskInsuredData.some(data => data.insuredRisks.riskType.codice === '3');
  }

  getProposalFromCache(): Observable<any> {
    if (this.isFromPreventive || this.isFromQuoteModification) {
      return this.http.get(this.environment.baseApiPassURL + '/ptflife/estimate/quotation/' + this.mainProposalResourceId);
    } else {
      return this.http.get(this.environment.baseApiPassURL + '/ptflife/proposal/quotation/' + this.mainProposalResourceId);
    }
  }

  addRoles(lifePartiesFromStorage: LifeParty[]) {
    this.mainProposal.proposal.lifeParty = lifePartiesFromStorage;
  }

  initProposal(): Observable<any> {
    // CREAZIONE PROPOSTA
    if (!this.loading) {
      this.loading = true;
      return this.saveProposal().pipe(
        tap((resourceIdContainer) => {
          this.mainProposal = new PolicyModel();
          /* When i'm buying a quote i'm initializing a new proposal-get new one-add data from old quote detail
              so here i already have the main proposal resource id */
          if (!!resourceIdContainer.resourceId && this.firstTimeInit) {
            this.mainProposalResourceId = resourceIdContainer.resourceId;
          }
        }),
        switchMap(firstProp => {
          return this.getProposalFromCache().pipe(
            tap((el: any) => {
              this.mainProposal = el.output;
              this.mainProposal.proposal.contractData = null;
              this.mainProposal.proposal.payments = null;
            }),
            switchMap(_ => {
              return this.doActionsAfterInitProposal();
            }),
            switchMap(_ => {
              // FINE INIZIALIZZAZIONE
              return of(null);
            })
          );
        })
      );
    } else {
      return of(null);
    }
  }

  doActionsAfterInitProposal(): Observable<any> {
    if (this.isFromBuyQuote && this.firstTimeInit) {
      this.firstTimeInit = false;
      this.loading = false;
      return this.initProposal();
    } else if (!!this.isFromPreventive && !!this.isAnonymous) {
      // devo lanciare una updateProposal per avere i dati che mi servono
      if (!this.mainProposal.quote.product) {
        this.mainProposal.quote.product = new Product();
      }
      if (!!this.mainProposal.quote.product && !!this.productFromIDD) {
        this.mainProposal.quote.product.code = this.productFromIDD.code;
      }
      return this.saveProposal();
    } else {
      return of(null);
    }
  }

  handleExtensionDataKeyByCode(propertyType: ExtensionProperty): Properties | null {
    let jsonValue = null;

    if (!!this.mainProposal && !!this.mainProposal.proposal.extensionData && !!this.mainProposal.proposal.extensionData.properties) {
      const extensionProperty = this.getExtensionProperty(propertyType);

      if (!!extensionProperty && !!extensionProperty.valore) {
        const str = extensionProperty.valore;
        jsonValue = LicObjectUtils.parsifyString(str);

        // Set the specific property based on the type
        switch (propertyType) {
          case ExtensionProperty.ISSUE_PIPTRANSFER:
            this.transferJson = jsonValue.fields;
            break;
          case ExtensionProperty.ISSUE_PIPCONTRIBUTIONS:
            this.contribJson = jsonValue;
            break;
          case ExtensionProperty.ISSUE_TOOLS:
            this.toolContrib = jsonValue;
            break;
          case ExtensionProperty.ISSUE_INVESTMENTS:
            this.investmentContrib = jsonValue;
            break;
          // Add more cases if needed for other types
        }
      }
    }

    return jsonValue;
  }

  createInitProposalReq(quotationModeOn?: boolean, initialStateUnitOn?: boolean) {
    const quotationReq: PolicyModel = new PolicyModel();
    const isQuotationModeOn = (quotationModeOn === undefined || !quotationModeOn) ? 'false' : 'true';
    const isSetInitialStateUnit = (initialStateUnitOn === undefined || !initialStateUnitOn) ? 'false' : 'true';
    quotationReq.proposal = {
      extensionData: {
        properties: this.setPropSaveProposal(
          isQuotationModeOn,
          isSetInitialStateUnit,
          this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_PIPTRANSFER),
          this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_PIPCONTRIBUTIONS),
          this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_TOOLS),
          this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_INVESTMENTS)
        ).concat(this.ppevoFactorsProp)
      },
      demandAndNeedsData: this.demandAndNeedsVersionInfo,
      anonymousEstimate: this.isAnonymous
    };
    quotationReq.quote.agencyID = this.lifeSessionService.idPvManagement;
    quotationReq.codManagementNode = this.lifeSessionService.codNodeManagement;
    quotationReq.idManagementNode = this.lifeSessionService.idPvManagement;
    quotationReq.codLoginNode = this.lifeSessionService.codLoginNode;
    quotationReq.userCode = this.lifeSessionService.getOperatorUsername();
    if (this.idMasterPolicy) {
      quotationReq.proposal.idMasterPolicy = this.idMasterPolicy;
    }

    return quotationReq;
  }

  createPutProposalReq(quotationModeOn?: boolean, initialStateUnitOn?: boolean) {
    const quotationPutReq: PolicyModel = new PolicyModel();
    const isQuotationModeOn = (quotationModeOn === undefined || !quotationModeOn) ? 'false' : 'true';
    const isSetInitialStateUnit = (initialStateUnitOn === undefined || !initialStateUnitOn) ? 'false' : 'true';
    quotationPutReq.codManagementNode = this.lifeSessionService.codNodeManagement;
    quotationPutReq.idManagementNode = this.lifeSessionService.idPvManagement;
    quotationPutReq.codLoginNode = this.lifeSessionService.codLoginNode;
    quotationPutReq.userCode = this.lifeSessionService.getOperatorUsername();
    quotationPutReq.quote = this.mainProposal.quote;
    quotationPutReq.proposal = this.mainProposal.proposal;
    quotationPutReq.proposal.anonymousEstimate = this.isAnonymous;
    quotationPutReq.proposal.extensionData = {
      properties: this.setPropSaveProposal(
        isQuotationModeOn,
        isSetInitialStateUnit,
        this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_PIPTRANSFER),
        this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_PIPCONTRIBUTIONS),
        this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_TOOLS),
        this.handleExtensionDataKeyByCode(ExtensionProperty.ISSUE_INVESTMENTS)
      ).concat(this.ppevoFactorsProp)
    };
    quotationPutReq.proposal.demandAndNeedsData = this.demandAndNeedsVersionInfo;
    quotationPutReq.quote.agencyID = this.lifeSessionService.idPvManagement;
    quotationPutReq.quote.fase = { properties: [] };
    // When quotation mode is on, reset the quotation data on each request
    if ('true' === isQuotationModeOn) {
      this.quotationDone = true;
      quotationPutReq.proposal.benefit = null;
      quotationPutReq.proposal.policyPremium = null;
      quotationPutReq.proposal.installment = null;
      quotationPutReq.proposal.riskPremium = null;
    }
    if (!!quotationPutReq.proposal.installment && quotationPutReq.proposal.installment.length > 0) {
      quotationPutReq.proposal.installment = this.setInstallmentProp(quotationPutReq.proposal.installment);
    }
    // storing the currencyCode
    this._currentCurrencyCode = this.mainProposal.quote && this.mainProposal.quote.product && this.mainProposal.quote.product.currencyCode;
    return quotationPutReq;
  }

  saveProposal(quotationModeOn?: boolean, initialStateUnitOn?: boolean): Observable<any> {
    let path = '';
    let req;
    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv
    };

    if (!this.isProposalCreated()) {
      req = this.createInitProposalReq(quotationModeOn, initialStateUnitOn);
      if (this.isFromPreventive || this.isFromQuoteModification) {
        path = '/ptflife/estimate/quotation';
      } else {
        path = '/ptflife/proposal/quotation';
      }

      return this.http.post(this.environment.baseApiPassURL + path, { input: req }, { headers });

    } else {
      req = this.createPutProposalReq(quotationModeOn, initialStateUnitOn);

      if (this.isFromPreventive || this.isFromQuoteModification) {
        path = '/ptflife/estimate/quotation/';
      } else {
        path = '/ptflife/proposal/quotation/';
      }

      return this.http.put(this.environment.baseApiPassURL + path + this.mainProposalResourceId, { input: req }, { headers }).pipe(
        tap(r => {
          this.handleSaveProposalResponse(r);
        })
      );
    }
  }

  /**
   * @description
   * service call in post to create the proposal from the buy quote flow (Preventive)
   */
  createInitProposalFromBuyQuote(): Observable<any> {
    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv
    };
    const req = this.createInitProposalReq();

    return this.http.post(this.environment.baseApiPassURL + '/ptflife/proposal/quotation', { input: req }, { headers });
  }

  /**
   * @description
   * service call in post to create the proposal from the buy quote flow (Preventive)
   */
  putProposalFromBuyQuote(reqForModProposal: any) {
    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv
    };
    return this.http.put(this.environment.baseApiPassURL + '/ptflife/proposal/quotation/' +
      this.mainProposalResourceId, { input: reqForModProposal }, { headers });
  }

  handleSaveProposalResponse(response: any) {
    this.mainProposal = response.output;

    // mantengo il valore prodotto aggiornato
    if (this.mainProposal.quote.product.type) {
      this.lifeSessionService.productType = this.mainProposal.quote.product.type.code;
    }

    this.modifiedQuestionnaire = [];
    this.setPPEvoFactorProperties();
  }

  handleSaveEstimateResponse(response: any) {
    const previusMainProposal = LicObjectUtils.cloneObject(this.mainProposal);
    const currentProposal = response.output;
    const previusProduct = previusMainProposal.quote.product.type && previusMainProposal.quote.product.type.code;
    const currentProduct = currentProposal.quote.product.type && currentProposal.quote.product.type.code;

    // mantengo il valore prodotto aggiornato
    if (previusProduct !== currentProduct) {
        this.lifeSessionService.productType = currentProduct;
    }
    this.mainProposal = currentProposal;

    if (this.isFromBuyQuote) {
        Object.assign(this.mainProposal.quote.product.assets, previusMainProposal.quote.product.assets);
    }

    this.modifiedQuestionnaire = [];
    this.setPPEvoFactorProperties();
  }


  setPropSaveProposal(isQuotationModeOn?: string, isSetInitialStateUnit?: string, pipTransfer?: any, pipContrib?: any,
                      toolsData?: any, investmentsData?: any): any[] {
    const jsonProperties: Properties[] = [
      {
        chiave: ExtensionProperty.IS_QUOTATION_MODE_ON,
        valore: isQuotationModeOn
      },
      {
        chiave: ExtensionProperty.IS_SETINITIALSTATE_UNITS_ON,
        valore: isSetInitialStateUnit
      },
      {
        chiave: ExtensionProperty.QUOTATION_VISITED,
        valore: this.quotationvisited.toString()
      }
    ];

    if (!!investmentsData) {
      jsonProperties.push({
        chiave: ExtensionProperty.ISSUE_INVESTMENTS,
        valore: LicObjectUtils.strigifyObject(investmentsData)
      });
    }

    if (!!toolsData) {
      jsonProperties.push({
        chiave: ExtensionProperty.ISSUE_TOOLS,
        valore: LicObjectUtils.strigifyObject(toolsData)
      });
    }

    if (!!pipTransfer) {
      jsonProperties.push({
        chiave: ExtensionProperty.ISSUE_PIPTRANSFER,
        valore: LicObjectUtils.strigifyObject(pipTransfer)
      });
    }
    if (!!pipContrib) {
      jsonProperties.push({
        chiave: ExtensionProperty.ISSUE_PIPCONTRIBUTIONS,
        valore: LicObjectUtils.strigifyObject(pipContrib)
      });
    }
    if (!!this.oldQuote && !!this.oldQuote.proposal && !!this.oldQuote.proposal.contractData[0]
      && !!this.oldQuote.proposal.contractData[0].proposalNumber) {
      jsonProperties.push(
        {chiave: ExtensionProperty.OLD_QUOTE_NUMBER, valore: this.oldQuote.proposal.contractData[0].proposalNumber}
      );
    }

    if (!!this.mainProposal && !!this.mainProposal.proposal.extensionData && !!this.mainProposal.proposal.extensionData.properties) {
      const propertyList = [
        this.getExtensionProperty(ExtensionProperty.REINVEST_LIQ),
        this.getExtensionProperty(ExtensionProperty.SNAPSHOT_SAVED),
        this.getExtensionProperty(ExtensionProperty.REINVESTMENT_INITIALIZED),
        this.getExtensionProperty(ExtensionProperty.ISSUE_ASSIGMENT_HOLDER),
        this.getExtensionProperty(ExtensionProperty.RGI_CURRENT_CONTEXT),
        this.getExtensionProperty(ExtensionProperty.ISSUE_INVESTMENTS_PLAN)
      ];
      this.filterDeathBenefitPropertiesAndAddToPropList(propertyList);
      propertyList.forEach(prop => {
        if (prop !== null && prop !== undefined && prop.valore !== null && prop.valore !== undefined) {
          jsonProperties.push(prop);
        }
      });
    }

    return jsonProperties;
  }

  setPropSaveFromQuote(pipTransfer?: any, pipContrib?: any): any[] {
    const jsonProperties: Properties[] = [
      {
        chiave: ExtensionProperty.IS_QUOTATION_MODE_ON,
        valore: 'false'
      },
      {
        chiave: ExtensionProperty.IS_SETINITIALSTATE_UNITS_ON,
        valore: 'false'
      },
      {
        chiave: ExtensionProperty.ISSUE_INVESTMENTS,
        valore: this.investmentsData != null ? LicObjectUtils.strigifyObject(this.investmentsData.valore) : ''
      },
      {
        chiave: ExtensionProperty.ISSUE_TOOLS,
        valore: this.toolsData != null ? LicObjectUtils.strigifyObject(this.toolsData.valore) : ''
      }
    ];
    if (!!pipTransfer) {
      jsonProperties.push({
        chiave: ExtensionProperty.ISSUE_PIPTRANSFER,
        valore: pipTransfer.valore
      });
    }
    if (!!pipContrib) {
      jsonProperties.push({
        chiave: ExtensionProperty.ISSUE_PIPCONTRIBUTIONS,
        valore: LicObjectUtils.strigifyObject(pipContrib.valore)
      });
    }
    if (!!this.oldQuote && !!this.oldQuote.proposal && !!this.oldQuote.proposal.contractData[0]
      && !!this.oldQuote.proposal.contractData[0].proposalNumber) {
      jsonProperties.push(
        {chiave: ExtensionProperty.OLD_QUOTE_NUMBER, valore: this.oldQuote.proposal.contractData[0].proposalNumber}
      );
    }

    if (!!this.mainProposal && !!this.mainProposal.proposal.extensionData && !!this.mainProposal.proposal.extensionData.properties) {
      const propertyList = [
        this.getExtensionProperty(ExtensionProperty.REINVEST_LIQ),
        this.getExtensionProperty(ExtensionProperty.ISSUE_ASSIGMENT_HOLDER),
        this.getExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE),
        this.getExtensionProperty(ExtensionProperty.SNAPSHOT_SAVED),
        this.getExtensionProperty(ExtensionProperty.RGI_CURRENT_CONTEXT),
        this.getExtensionProperty(ExtensionProperty.ISSUE_INVESTMENTS_PLAN)
      ];
      this.filterDeathBenefitPropertiesAndAddToPropList(propertyList);
      propertyList.forEach(prop => {
        if (prop !== null && prop !== undefined && prop.valore !== null && prop.valore !== undefined) {
          jsonProperties.push(prop);
        }
      });
    }

    return jsonProperties;
  }

  protected filterDeathBenefitPropertiesAndAddToPropList(propertyList: Properties[]) {
    if (this.getExtensionProperty(ExtensionProperty.DEATHBENEFIT_PREFIX)?.valore) {
      this.mainProposal.proposal.extensionData.properties
        .filter(prop => prop.chiave.includes(ExtensionProperty.DEATHBENEFIT_PREFIX))
        .forEach(prop => {
          propertyList.push(prop);
        });
    }
  }

  setPPEvoFactorProperties() {
    this.ppevoFactorsProp = [];
    this.mainProposal.proposal.extensionData.properties.forEach((extProp) => {
      if (extProp.chiave.startsWith('PPEvoFactor')) {
        this.ppevoFactorsProp.push(extProp);
      }
    });
  }

  lifeAuthsavePolicy(contractId: string, nodeCode: string): Observable<LifeSavePolicyResponse> {
    /* const requestParams: HttpParams = new HttpParams();
    requestParams.set('userCode', nodeCode);
    requestParams.set('authId', authId); */
    return this.http.put(this.environment.baseApiURL + '/v2' + '/life/auth/policyIssue/' + contractId
      + '?userCode=' + nodeCode, {}) as Observable<LifeSavePolicyResponse>;
  }

  lifeAuthsaveProposal(contractId: string, nodeCode: string, authId: string): Observable<LifeSavePolicyResponse> {
    /* const requestParams: HttpParams = new HttpParams()
        .set('userCode', nodeCode)
        .set('authId', authId); */
    return this.http.put(this.environment.baseApiURL + '/v2' + '/life/auth/proposalIssue/' + contractId
      + '?userCode=' + nodeCode + '&authId=' + authId, {}) as Observable<LifeSavePolicyResponse>;
  }

  lifeScheduledPremiumUpdateInvestmentPlan(investmentPlan: InvestmentPlanData): Observable<PolicyModel> {
    const url = this.environment.baseApiURL + '/v2' + '/ptflife/proposal/' + this.mainProposalResourceId + '/investmentsPlan';
    return this.http.post(url, investmentPlan).pipe(
      map((response: PolicyModel) => this.mainProposal = response as PolicyModel)
    );
  }


  cleanProposal(prodDisabled = true) {
    // AZZERO I DATI DELLA QUOTAZIONE
    this.mainProposal.proposal.benefit = null;
    this.mainProposal.proposal.policyPremium = null;
    this.mainProposal.proposal.installment = null;
    this.mainProposal.proposal.riskPremium = null;
    this.mainProposal.proposal.questionnaires = [];
    this.mainProposal.proposal.questionnairesManager = null;
    this.mainProposal.proposal.damagesInstalments = null;
    this.mainProposal.proposal.beneficiaryData = null;
    this.mainProposal.proposal.payments = [];

    if (!prodDisabled) {
      this.mainProposal.quote.product = null;
    }
  }

  cleanQuotationMessages() {
    this.mainProposal.proposal.quotationMessages = [];
  }

  createProduct() {
    this.mainProposal.quote.product = new Product();
  }

  setInsuredLogic(operation: Insured) {
    this.operation = operation;
  }

  getInsuredLogic(): Insured {
    return this.operation;
  }

  deleteProduct() {
    this.mainProposal.quote.product.code = null;
  }

  isQuotationDone() {
    return this.quotationDone;
  }

  resetQuotation() {
    this.quotationDone = false;
    this.mainProposal.proposal.benefit = null;
    this.mainProposal.proposal.policyPremium = null;
    this.mainProposal.proposal.installment = null;
    this.mainProposal.proposal.riskPremium = null;

    // annullo investimenti
    this.investmentsData = null;
    this.toolsData = null;
  }

  saveTransferData(transferData: FormFieldDefinition[]) {
    this.transferJson = transferData;
  }

  saveContributionData(contributions: Contribution[]) {
    this.contributionData = [];
    this.contributionData = contributions;
  }

  resetTransferData() {
    this.transferJson = [];
  }

  getPointOfSale(): Observable<any> {
    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv
    };

    const request = {
      nodeId: this.lifeSessionService.idPv
    };

    return this.http.post(this.environment.baseApiURL + '/admin/pointofsale', { filter: request }, { headers });
  }

  private cleanKey(q: QuestionnaireManagerElement): string {
    return !q.assetInstanceName || q.assetInstanceName === ''
      || q.assetInstanceName.indexOf('FAKE') >= 0 ? '' : q.assetInstanceName;
  }

  saveQuestAnd(questCacheManager?: QuestionnaireCacheService): Observable<any> {
    if (questCacheManager) {
      const questionnaires = [];
      this.modifiedQuestionnaire = [];
      return this.callEphimeralToEvaluateQuestScore(questCacheManager).pipe(
        switchMap(_ => {
          return questCacheManager.persistAll();
        }),
        map((d: any) => {
          d.forEach((questionnairesList, key) => {
            questionnairesList.forEach((survey) => {
              const computedKey = key.toString().indexOf('FAKE') !== -1 ? '' : key.toString();
              const questType = survey.questionnaire.questionnaireType.code;
              questionnaires.push(
                new QuestionnaireManagerElement(
                  computedKey,
                  survey.uuid,
                  survey.questionnaire.code,
                  survey.questionnaire.name,
                  this.getQuestScoreFromCache(questCacheManager, questType)
                )
              );
              if (!!this.surveyVersionNumberMap.get(survey.uuid)) {
                if (this.surveyVersionNumberMap.get(survey.uuid) < survey.versionNumber) {
                  // QUESTIONARIO MODIFICATO
                  this.modifiedQuestionnaire.push(survey.uuid);
                  this.surveyVersionNumberMap.set(survey.uuid, survey.versionNumber);
                }
              } else {
                // QUESTIONARIO NUOVO
                this.modifiedQuestionnaire.push(survey.uuid);
                this.surveyVersionNumberMap.set(survey.uuid, survey.versionNumber);
              }
            });
          });
          if (this.isFromAuth || this.isFromProposalModification) {
            const manager = this.mainProposal.proposal.questionnairesManager;
            questionnaires.forEach(savedQuestionnaire => {
              const oldQuestionnaire = manager.questionnaires
                .find(q => this.cleanKey(q) === this.cleanKey(savedQuestionnaire) && q.questCode === savedQuestionnaire.questCode);
              if (oldQuestionnaire == null) {
                manager.questionnaires.push(savedQuestionnaire);
              } else {
                oldQuestionnaire.assetInstanceName = savedQuestionnaire.assetInstanceName;
                oldQuestionnaire.idQuest = savedQuestionnaire.idQuest;
              }
            });
          } else {
            this.mainProposal.proposal.questionnairesManager = new QuestionnairesManager();
            if (questionnaires.length > 0) {
              this.mainProposal.proposal.questionnairesManager.questionnaires = questionnaires;
            }
          }
        })
      );
    } else {
      return of({});
    }
  }


  public hideSaveButton(forceVisible?: boolean) {

    return this.hideButtonSaveUndefinedProposal || (this.isFromPreventive && !forceVisible);
  }


  callEphimeralToEvaluateQuestScore(questCacheManager: QuestionnaireCacheService): Observable<any> {
    let _SOSTquestFlatI: QuestionnaireFlatI = null;
    let _FINquestFlatI: QuestionnaireFlatI = null;
    const productCode = this.mainProposal.quote.product.code;
    const questMap = questCacheManager.cache.get(QuestionnaireCodes.LIFE_GENERIC_KEY);
    const sustainabilityQuest = this.getQuestByCode(questMap, QuestionnaireCodes.SUSTAINABILITY);
    const financialQuest = this.getQuestByCode(questMap, QuestionnaireCodes.FINANCIAL);
    if ((!sustainabilityQuest || !sustainabilityQuest.compiled) && (!financialQuest || !financialQuest.compiled)) {
      return of(null);
    }
    _SOSTquestFlatI = !!sustainabilityQuest && !!sustainabilityQuest.compiled ? questMap.get(sustainabilityQuest) : null;
    _FINquestFlatI = !!financialQuest && !!financialQuest.compiled ? questMap.get(financialQuest) : null;
    const ephemeralArray = [
      !!_SOSTquestFlatI ? questCacheManager.callEvaluationByProductCode(sustainabilityQuest, _SOSTquestFlatI, productCode) : of(null),
      !!_FINquestFlatI ? questCacheManager.callEvaluationByProductCode(financialQuest, _FINquestFlatI, productCode) : of(null)
    ];
    // i'll call the ephemeral to calculate the score of the quest just for the Sustainability quest and the Financial one
    return combineLatest(ephemeralArray).pipe(
      tap(([_SOSTquestScore, _FINquestScore]) => {
        if (!!_SOSTquestFlatI) {
          _SOSTquestFlatI.score = _SOSTquestScore;
        }
        if (!!_FINquestFlatI) {
          _FINquestFlatI.score = _FINquestScore;
        }
      })
    );
  }

  protected getQuestByCode(questMap: Map<Questionnaire, QuestionnaireFlatI>, questCode: string): Questionnaire {
    return !!questMap
      ? Array.from(questMap.keys()).find(quest => quest.questionnaireType.code === questCode)
      : null;
  }

  protected getQuestScoreFromCache(questCacheManager: QuestionnaireCacheService, questType: string) {
    const questMap = questCacheManager.cache.get(QuestionnaireCodes.LIFE_GENERIC_KEY);
    if (questType !== QuestionnaireCodes.SUSTAINABILITY && questType !== QuestionnaireCodes.FINANCIAL) {
      return;
    }
    if (!!questMap && !!this.getQuestByCode(questMap, questType)) {
      const quest = this.getQuestByCode(questMap, questType);
      return questMap.get(quest).score;
    }
    return;
  }

  saveFromQuote(isIssuePolicy?: boolean, questCacheManager?: QuestionnaireCacheService, skipErrorInterceptor = false): Observable<any> {
    const pipTransfer = this.getExtensionProperty(ExtensionProperty.ISSUE_PIPTRANSFER);
    const pipContrib = this.getExtensionProperty(ExtensionProperty.ISSUE_PIPCONTRIBUTIONS);

    return this.saveQuestAnd(questCacheManager).pipe(
      switchMap((resp) => {
        let headers;
        if (skipErrorInterceptor) {
          headers = {
            RGI_executionId: this.lifeSessionService.executionId,
            RGI_idPv: this.lifeSessionService.idPv,
            'X-Disable-Error-Interceptor': 'true'
          };
        } else {
          headers = {
            RGI_executionId: this.lifeSessionService.executionId,
            RGI_idPv: this.lifeSessionService.idPv
          };
        }

        const request = {
          quote: {
            agencyID: this.lifeSessionService.idPvManagement,
            product: this.mainProposal.quote.product,
            resourceId: this.mainProposalResourceId,
            fase: {
              properties: []
            }
          },
          proposal: this.mainProposal.proposal,
          codManagementNode: this.lifeSessionService.codNodeManagement,
          idManagementNode: this.lifeSessionService.idPvManagement,       // <--- Cambiare, impostato di default!
          codLoginNode: this.lifeSessionService.codLoginNode,
          userCode: this.lifeSessionService.getOperatorUsername(),
        };

        request.proposal.extensionData = {
          properties: this.setPropSaveFromQuote(pipTransfer, pipContrib).concat(this.ppevoFactorsProp)
        };

        this.quotationDone = false;

        if (request.proposal.installment !== null && request.proposal.installment.length > 0) {
          request.proposal.installment = this.setInstallmentProp(request.proposal.installment);
        }

        return this.http.post(
          this.environment.baseApiPassURL + this.getUrlSaveFromQuote(isIssuePolicy),
          { quote: request },
          { headers }).pipe(
          tap((r: any) => {
            // DO SOMETHING
          })
        );
      })
    );
  }

  getUrlSaveFromQuote(isIssuePolicy?: boolean): string {
    let saveUrl = null;
    this.policySavefromquote = false;

    if (this.isFromPreventive || this.isFromQuoteModification) {
      saveUrl = '/ptflife/estimate/savefromquote/';
    } else if (isIssuePolicy != null && isIssuePolicy) {
      this.policySavefromquote = true;
      saveUrl = '/ptflife/policy/savefromquote/';
    } else {
      saveUrl = '/ptflife/proposal/savefromquote/';
    }

    return saveUrl;
  }

  setInstallmentProp(installment) {
    const riskProp = installment.map((i: Installment) => {
      if (i.riskInstallment != null) {
        i.riskInstallment.map((riskInstallment: RiskInstallment) => {
          if (!!this.lifeRoleService.getPolicyInsured()) {
            riskInstallment.properties = [{
              chiave: 'nomeIstanza',
              valore: this.lifeRoleService.getPolicyInsured().objectId
            }];
          }
          return riskInstallment;
        });
      }
      return i;
    });
    return riskProp;
  }



  setCoSigner(value: boolean) {
    this.showCoSigner = value;
  }

  isCoSignerShowed() {
    return this.showCoSigner;
  }

  updateCoreResultData() {
    if (this.coreResultData != null) {
      const lifeProposalSettings = this.coreResultData.lifeProposalSettings;
      if (lifeProposalSettings) {
        this.lifeSessionService.productType = lifeProposalSettings.productType;
        this.lifeSessionService.codNodeManagement = lifeProposalSettings.nodeCode;
        this.lifeSessionService.idPvManagement = lifeProposalSettings.idpv;
        this.idMasterPolicy = lifeProposalSettings.idMasterPolicy;
      }

      this.subjectFromIDD = (lifeProposalSettings && lifeProposalSettings.subject) ?
        lifeProposalSettings.subject : this.coreResultData.subject;
      this.productFromIDD = (lifeProposalSettings && lifeProposalSettings.product) ?
        lifeProposalSettings.product : this.coreResultData.product;

      // CAPISCO SE PROVENGO DALL'IDD
      this.isFromIDD = this.subjectFromIDD != null || this.productFromIDD != null; // OR in caso di preventivo anonimo

      // isFromAuth -> indica quando arrivo dalla MODIFICA PROPOSTA in auth
      this.isSaveFromAuth = !!this.coreResultData.isSaveFromAuth;
      this.isFromAuth = (this.coreResultData.proposalFromAuth != null && !this.isSaveFromAuth);

      // isFromConsultation -> indica quando arrivo da consultazione proposta
      this.isFromConsultation = this.coreResultData.isFromConsultation;

      // isFromPreventive -> indica quando arrivo dal flusso di preventivo
      this.isFromPreventive = !!this.coreResultData.isFromPreventive;

      // isAnonymous -> indica quando arrivo dal flusso di preventivo ANONIMO
      this.isAnonymous = !!this.coreResultData.isAnonymous;

      // isFromPreventive -> indica quando arrivo dal flusso normale di emissione
      this.isFromBaseIssueFlow = !(this.isFromAuth || this.isFromPreventive || this.isSaveFromAuth || this.isFromConsultation);

      this.isFromQuoteModification = !!this.coreResultData.isFromQuoteModification;

      // is coming from consultation of a proposal (EDIT button)
      this.isFromProposalModification = !!this.coreResultData.isFromProposalModification;

      this.hideButtonSaveUndefinedProposal = this.coreResultData.hideButtonSaveUndefinedProposal;

      this.isFromBuyQuote = !!this.coreResultData.isFromBuyQuote;

      if (this.isFromAuth || !!this.isSaveFromAuth) {
        this.mainProposal = this.coreResultData.proposalFromAuth;
        if (!this.lifeSessionService.productType && this.mainProposal.quote.product.type) {
          this.lifeSessionService.productType = this.mainProposal.quote.product.type.code;
        }
        this.valueNodeManagement();
      }

      if (this.isFromIDD) {
        this.haveToInitIDD = true;

        // se arrivo da IDD ho salvato in managementNode il nodo
        const selectedManagementNode = this.coreResultData.managementNode;
        if (!!selectedManagementNode) {
          if (!!selectedManagementNode.code) {
            this.lifeSessionService.codNodeManagement = selectedManagementNode.code;
          } else if (!!selectedManagementNode.data && !!selectedManagementNode.data.code) {
            this.lifeSessionService.codNodeManagement = selectedManagementNode.data.code;
          }
          if (!!selectedManagementNode.idSp) {
            this.lifeSessionService.idPvManagement = selectedManagementNode.idSp;
          } else if (!!selectedManagementNode.data && !!selectedManagementNode.data.idSp) {
            this.lifeSessionService.idPvManagement = selectedManagementNode.data.idSp;
          } else if (!!selectedManagementNode.objectId) {
            this.lifeSessionService.idPvManagement = selectedManagementNode.objectId.toString();
          }
        } else if (this.coreResultData.anag) {
          this.lifeSessionService.idPvManagement = this.coreResultData.anag.idManagementNode;
          this.lifeSessionService.codNodeManagement = this.coreResultData.anag.codManagementNode;
        }

        if (this.coreResultData.productEvaluation != null) {
          this.demandAndNeedsVersionInfo = this.coreResultData.productEvaluation.demandAndNeedsData;
        }
      }

      if (!!this.coreResultData.quoteFromDetail) {
        if (!this.isFromBuyQuote) {
          this.mainProposal = this.coreResultData.quoteFromDetail;
          this.valueNodeManagement();
        } else {
          this.oldQuote = this.coreResultData.quoteFromDetail;
          this.nodeManagementFromOldQuote();
        }
      }

      if (this.isFromAuth) { // se arrivo da modifica ribalto il prodotto su productFromIDD
        if (this.coreResultData.proposalFromAuth.quote && this.coreResultData.proposalFromAuth.quote.product) {
          this.productFromIDD = this.coreResultData.proposalFromAuth.quote.product.code;
        }
      }

      if (this.isFromProposalModification) {
        this.mainProposal = this.coreResultData.proposalFromDetail;
        this.valueNodeManagement();
      }
    }
  }

  protected nodeManagementFromOldQuote() {
    this.lifeSessionService.codNodeManagement = this.oldQuote.proposal.policyVersion.managmentNode.code;
    this.lifeSessionService.idPvManagement = this.oldQuote.quote.agencyID;
  }

  private valueNodeManagement() {
    this.lifeSessionService.codNodeManagement = this.mainProposal.proposal.policyVersion.managmentNode.code;
    // this.lifeSessionService.codLoginNode = this.mainProposal.codLoginNode; lo prendo dall'utente loggato
    this.lifeSessionService.idPvManagement = this.mainProposal.quote.agencyID;
  }

  isFinancialProduct() {
    return this.mainProposal.quote.product.type.code === FINANCIAL_PRODUCT_CODE;
  }

  getHolderBankAccount(): string {
    const bankAccount = this.mainProposal.proposal.payments.find(pay => pay.instalmentType.codice === '2');
    return !!bankAccount ? bankAccount.bankAccountOwner : null;

  }

  getIban(): string {
    const ibanAccount = this.mainProposal.proposal.payments.find(pay => pay.instalmentType.codice === '2');
    return !!ibanAccount ? ibanAccount.iban : null;
  }

  getAvailableFunds(): Observable<PassResponse> {
    return this.http.get<PassResponse>(this.lifeSessionService.baseApiURLPass + '/ptflife/proposal/quotation/'
      + this.mainProposalResourceId + '/funds/available');
  }

  getOptionsAvailable(): Observable<PassResponseOptions> {
    return this.http.get<PassResponseOptions>(this.lifeSessionService.baseApiURLPass + '/ptflife/proposal/quotation/'
      + this.mainProposalResourceId + '/options/available');
  }

  getProfilesAvailable(): Observable<PassResponseProfile> {
    return this.http.get<PassResponseProfile>(this.lifeSessionService.baseApiURLPass + '/ptflife/proposal/quotation/'
      + this.mainProposalResourceId + '/profiles/available');
  }

  useRgiRxHttpClientWrapper(): boolean {
    return this.environment.useRgiRxHttpClientWrapper;
  }

  getExcludeErrorInterceptor(): boolean {
    return !!this.environment.excludeErrorInterceptor;
  }

  invest(profiles: Array<PassProfileData>): Observable<any> {
    const investUrl: string = this.lifeSessionService.baseApiURLPass + '/ptflife/proposal/' + this.mainProposalResourceId + '/investments';

    if (this.useRgiRxHttpClientWrapper()) {
      const rgiRxHttpClientWrapper: RgiRxHttpClientWrapper = this.lifeRgiRxHttpClientWrapperService.getClient();
      return rgiRxHttpClientWrapper.post(investUrl, { profiles });
    }

    const headers = {
      RGI_executionId: this.lifeSessionService.executionId,
      RGI_idPv: this.lifeSessionService.idPv,
    };

    if (!!this.getExcludeErrorInterceptor()) {
      headers['X-Disable-Error-Interceptor'] = 'true';
    }

    return this.http.post(investUrl, { profiles }, { headers });
  }

  public getInvestmentData(proposal: Proposal, currentValue: PassProfileData[]): Observable<InvestmentDataResponse> {
    let result = {} as InvestmentDataResponse;
    const investString = proposal.extensionData.properties.find(prop => prop.chiave === ExtensionProperty.ISSUE_INVESTMENTS);
    if (!!investString && !!investString.valore) {
      result = PassUtils.mapInvestmentPropToInvestmentDataResponse(JSON.parse(investString.valore));
    }
    return this.http
    .get<InvestmentDataResponse>(
      this.environment.baseApiURL + '/v2/life/authorization/' + proposal.contractData[0].proposalNumber + '/policy'
      ).pipe(map(resInvestmentData => {
      // new impl commented
      const investDataRespond = this.getMappedInvestmentData(currentValue, result, resInvestmentData);
      const property = this.mainProposal.proposal.extensionData.properties.find(prop => prop.chiave === ExtensionProperty.ISSUE_TOOLS);
      investDataRespond.tools = {};
      if (!!property) {
        const inboundTools: InboundTools = JSON.parse(property.valore);
        investDataRespond.tools = PassUtils.convertPropertyExtensionDataTool(inboundTools);
      }
      return investDataRespond;

      /* let returnObj = null;
      if (!this.investmentsVisited) { // at the fr
        const sameProf = LicObjectUtils.equal(result.profiles, mappedData.profiles);
        returnObj = result;
        if (!sameProf) {
          returnObj = mappedData;
        }
      } else {
        returnObj = !!currentValue.length ? mappedData : result;
      }
      return Object.assign(resInvestmentData, returnObj); */
    }));
  }

  /** @description method used to map the data on authorizatin flow to fill the investment page */
  protected getMappedInvestmentData(
    currentFormValues: PassProfileData[],
    issueInvestmentProp: InvestmentDataResponse,
    resInvestmentData: InvestmentDataResponse
    ): InvestmentDataResponse {
    const mergedData = PassUtils.mergeObjectForAuthFlow(currentFormValues, issueInvestmentProp);
    return Object.assign(resInvestmentData, mergedData);
  }

  public getToolDefinition(operationCode: string): Observable<ToolDefinition> {
    return this.http.get<AuthorizedToolResponse>(
      this.lifeSessionService.baseApiURLPass + '/ptflife/proposal/quotation/' +
      this.mainProposalResourceId + '/tools/authorized?operationCode=' + operationCode
    ).pipe(
      map(authorizedTool => {
        const definition: ToolDefinition = LicObjectUtils.merge({}, authorizedTool.toolsConfiguration[0]);
        definition.operationCodeId = definition.operationCodeId;
        return definition;
      })
    );
  }

  public tools(inboundTools): Observable<any> {
    return this.http.post(this.lifeSessionService.baseApiURLPass + '/ptflife/proposal/'
      + this.mainProposalResourceId
      + '/tools', inboundTools);
  }

  getAllSubjectCodeFromAuth(): { partyCode: string, partyRole: Roles, percentage: any, subSubjects: any[] }[] {
    const subjectVector = [];

    // LIFE PARTY
    if (!!this.mainProposal && !!this.mainProposal.proposal) {
      if (this.mainProposal.proposal.lifeParty != null && this.mainProposal.proposal.lifeParty.length > 0) {
        this.mainProposal.proposal.lifeParty.forEach(s => {
          const subSubjects = [];
          if (!!s.linkedLifeParties && s.linkedLifeParties.length > 0) {
            s.linkedLifeParties.forEach(subSubj => {
              subSubjects.push({
                partyRole: subSubj.party.partyRole,
                partyCode: subSubj.party.objectID,
                percentage: subSubj.percentageParty
              });
            });
          }
          subjectVector.push({
            partyCode: s.party.objectID,
            partyRole: s.party.partyRole,
            percentage: s.percentageParty,
            subSubjects
          });
        });
      }

      // BENEFICIARIES
      if (!!this.mainProposal.proposal.beneficiaryData && !!this.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData
        && this.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData.length > 0
      ) {
        this.mainProposal.proposal.beneficiaryData.choiceBeneficiaryData.forEach(benef => {
          if (benef.choiceTypeBeneficiary === BENEFICIARY_CHOICE.ANAGRAFICAL_BENEF) {
            if (!!benef.beneficiaryLifeParty && benef.beneficiaryLifeParty.party) {
              const subBeneficiaries = [];
              if (!!benef.beneficiaryLifeParty.linkedLifeParties && benef.beneficiaryLifeParty.linkedLifeParties.length > 0) {
                benef.beneficiaryLifeParty.linkedLifeParties.forEach(subBenef => {
                  subBeneficiaries.push({
                    partyRole: subBenef.party.partyRole,
                    partyCode: subBenef.party.objectID
                  });
                });
              }
              subjectVector.push({
                partyCode: benef.beneficiaryLifeParty.party.objectID,
                partyRole: benef.beneficiaryLifeParty.party.partyRole,
                subSubjects: subBeneficiaries
              });
            }
          }
        });
      }
    }
    return subjectVector;
  }

  getPassProProSavedQuestionnaireUUIDs(instanceName: string): string[] {
    const res: string[] = [];
    const manager = this.mainProposal.proposal.questionnairesManager;
    if (!!manager && !!manager.questionnaires) {
      manager.questionnaires.forEach(q => {
        // instanceName and q.assetInstanceName are null when asking for policy questionnaires
        if (q.assetInstanceName === instanceName && q.idQuest !== '' && q.idQuest !== null) {
          res.push(q.idQuest);
        }
      });
    }
    return res;
  }

  public getMainProposalProfileIntersectionOf(profiles: Array<Profile>): Profile[] {
    const resultProfiles = [];
    this.mainProposal.quote.product.assets.forEach(asset => {
      asset.instances.forEach(instance => {
        instance.sections.forEach(section => {
          section.units.forEach(unit => {
            if (unit.instances && 1 === unit.instances.length) {
              const unitInstance = unit.instances[0];

              const percentFactor = unitInstance.factors.find(f => f.code === FactorCode.PERCR);
              // RDDL-4779 given priority to the _LINEA factor
              const listFactor = this.getListFactor(unitInstance.factors);
              if (!!listFactor && !!percentFactor) {
                const value = listFactor.values.find(v => v.value === listFactor.value);
                const profileId = this.getProfileId(value, listFactor);

                if (null !== profileId) {
                  const foundProfile = profiles.filter(p => p.profileId === profileId);
                  foundProfile.forEach(p => {
                    p.selected = true;
                    p.percent = Number(percentFactor.value);
                    p.readOnly = true;
                  });
                  resultProfiles.push(...foundProfile);
                }
              } else if (!!percentFactor) {
                // With factor without PROPRI_CODE profileId is on transcoderCode1 on _PERCR
                const profileId = percentFactor.transcoderCode1;

                const foundProfile = profiles.filter(p => p.profileId === profileId);
                foundProfile.forEach(p => {
                  p.selected = true;
                  p.percent = Number(percentFactor.value);
                  p.readOnly = true;
                });
                resultProfiles.push(...foundProfile);
              }
            }
          });
        });
      });
    });

    return resultProfiles;
  }

  getListFactor(factors: Factor[]): Factor {
    // _PRORI management is only for external financial management
    // to indicate the profiles ID by hand.
    const lineaFactor = factors.find(f => f.code === FactorCode.LINEA);
    if (!!lineaFactor) {
      return lineaFactor;
    }
    const propriFactor = factors.find(f => f.code === FactorCode.PROPRI);
    return propriFactor;
  }

  getProfileId(value: Value, factor: Factor) {
    // const profileId = value ? value.transcoderCode1 : null;
    let profileId = null;
    if (!!value) {
      profileId = factor.code === FactorCode.LINEA ? value.value : value.transcoderCode1;
    }
    return profileId;
  }

  resetPipContribution() {
    // chiamato all'onDestroy della card padre (life-card.component)
    this.contribJson = null;
  }

  getPropertyFromProduct(property: string): CustomProperties {
    return this.mainProposal.quote.product.customProperties.find(customProp =>
      customProp.code === property);
  }

  /** @description return the property from the current proposal */
  getExtensionProperty(property: string): Properties {
    return this.mainProposal && this.mainProposal.proposal.extensionData && this.mainProposal.proposal.extensionData.properties &&
           this.mainProposal.proposal.extensionData.properties.find(prop => prop.chiave.includes(property));
  }

  /** @description the property will be pushed to the array */
  pushExtensionProperty(chiave: string, valore: string) {
    this.mainProposal.proposal.extensionData.properties.push({chiave, valore});
  }

  /** @description checks if the propertyCode already present and if not it will be add to the array */
  addExtensionProperty(property: string, prop: Properties) {
    const index = this.mainProposal.proposal.extensionData.properties.findIndex(p => p.chiave === property);
    if (!!index && index >= 0) {
      this.mainProposal.proposal.extensionData.properties[index] = prop;
    } else {
      this.mainProposal.proposal.extensionData.properties.push(prop);
    }
  }

  public getCommonRisk(idParties: string[], operationCode = OperationCode.EMISSIONE_POLIZZA): Observable<any> {
    const url: string = this.environment.baseApiURL + '/v2/life/estimatecommonrisk?operationcode=' + operationCode;
    const contractId = this.mainProposal.proposal.contractData[0].proposalNumber;
    if (this.useRgiRxHttpClientWrapper()) {
      const rgiRxHttpClientWrapper: RgiRxHttpClientWrapper = this.lifeRgiRxHttpClientWrapperService.getClient();
      return rgiRxHttpClientWrapper.post(url, { contractId, idParties });
    }
    return this.http.post<CommonRiskResponse>(url, { idParties });
  }

  /**
   * @description if you don't have the productTypeCode coming from the idd but i have just the productCode from the PPEVO flow
   * i'll get the product from the list and return the productTypeCode
   */
  getProductTypeFromIDD(productTypeCode: string, products: Product[]) {
    if (!productTypeCode) {
      const product = products.find(prod => prod.productCode === this.productFromIDD.code);
      return !!product ? product.productTypeCode : productTypeCode;
    }
    return productTypeCode;
  }

  isVisibleCommonRisk(): Observable<boolean> {
    return this.showCommonRisk.asObservable();
  }

}
