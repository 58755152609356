import {Injectable} from '@angular/core';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {RgiRxUserService} from '@rgi/rx/auth';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {OperatorLight} from '../../services/external-service';
import {AnagStateKeyData} from './anag-state-manager-key-data';
import {AnagResourceService} from '../../anag-resources/anag-resource.service';
import {
  ROUTES_HOME,
  ROUTES_MODAL_RESULT, ROUTES_MODAL_RESULT_HOMONYMY,
  ROUTES_RESULT_HOMONYMY
} from '../../anag-constants/routes-constants';
import {
  AnagApiConfiguration,
  AnagApiPartyTypeConfigRequest
} from '../../anag-model/anag-api/anag-api-configuration-filter';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {AnagApiNode} from '../../anag-model/anag-api/anag-api-node';
import {AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {SUBJECT_TYPE_ENUM} from '../../anag-constants/enums-constants';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagApiParty, AnagPartyKey, AnagPartyKeyService} from '../../anag-model/anag-domain/anag-api-party';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {
  AnagApiPartyKeyConfigRequest,
  AnagPartyKeyFilter
} from "../../anag-model/anag-api/anag-api-party-key-config-request";
import {CORE_PARTY_CONFIG, PACONF_VALUES} from "../../anag-constants/anag-constants";
import {AnagSubjectsFilter} from "../../anag-model/anag-api/anag-subjects-filter";
import {AnagUtilsService} from "../../anag-resources/anag-utils.service";
import { AnagExtensionSubject } from '../../anag-model/anag-domain/anag-extension-subject';


@Injectable({
  providedIn: 'root'
})
export class AnagStatelessOpKeyDataService {

  constructor(
    protected anagResourceService: AnagResourceService,
    protected routingService: RoutingService,
    protected userService: RgiRxUserService,
    protected anagStorage: AnagStorageService,
    protected anagIntegration: AnagIntegrationService,
    protected statelessService: AnagStatelessService,
    protected i18nService: RgiRxTranslationService,
    protected anagUtilsService: AnagUtilsService
  ) {
  }

  initKeyData$(stateKeyData$: Observable<AnagStateKeyData>): Observable<AnagStateKeyData> {
    return stateKeyData$
      .pipe(
        mergeMap((firstState) => {
          if (this.userService.isLoggedIn()) {
            if (this.userService.getUser<OperatorLight>().salePoint &&
              this.userService.getUser<OperatorLight>().salePoint.salePointType.codice !== '1' &&
              firstState.party && (!firstState.party.node || !firstState.party.node.identification)) {
              const defaultNode = this.userService.getUser<OperatorLight>().salePoint;
              firstState.party.node = new AnagApiNode(defaultNode.code, defaultNode.description, '' + defaultNode.objectId);
            }
          }
          return combineLatest([
            of(firstState),
            this.anagStorage.getNodes$(),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.partyHomonymy).pipe(catchError((err) => {
                return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagCheckCFDuplicato).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagGruppoCompagnie).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.anagGroupingNodeslevel).pipe(catchError((err) => {
              return of(err);
            })),
            this.anagStorage.getSystemProperties$(CORE_PARTY_CONFIG.sinistriNodoFittizioAnagrafe).pipe(catchError((err) => {
              return of(err);
            })),
          ]).pipe(
            map(([st, nodes, systemPropPartyHomonymy, systemPropPartyCFDuplicate, systemPropPartyGroupCompanies, anagGroupingNodeslevel, anagNodeFitSx]) => {
                if (systemPropPartyHomonymy && systemPropPartyHomonymy.value && systemPropPartyHomonymy.key) {
                  st.isUmaPartyHomonymyEnable = systemPropPartyHomonymy.value === 'Enable';
                }
                if (systemPropPartyCFDuplicate && systemPropPartyCFDuplicate.value) {
                  st.isUmaPartyCFDuplicate = systemPropPartyCFDuplicate.value === 'true';
                }
                if (systemPropPartyGroupCompanies && systemPropPartyGroupCompanies.value) {
                  st.isUmaPartyGroupCompanies = systemPropPartyGroupCompanies.value === 'true';
                }
                if (anagNodeFitSx && anagNodeFitSx.value) {
                  st.anagNodeFitSx = anagNodeFitSx.value;
                }
                if (anagGroupingNodeslevel && anagGroupingNodeslevel.value) {
                  if(this.userService.getUser().isUserNetworkClaims){

                    this.anagStorage.getNodesSinistri$(st.anagNodeFitSx).subscribe(res => {
                      st.nodes = res;
                      if(st.nodes){
                        firstState.party.node = new AnagApiNode(st.nodes[0].code, st.nodes[0].description, '' + st.nodes[0].objectId);
                      }
                    });

                  } else {
                    st.nodes = nodes.filter(node => node.level >= anagGroupingNodeslevel.value);
                  }
                }
                else {
                  st.nodes = nodes;
                }
                return st;
              }
            )
          );
        })
      );
  }


  retrieveProfessionDetails$(state$: Observable<AnagStateKeyData>, professionCode: string) {
    return state$
      .pipe(
        mergeMap((state) => {
            return forkJoin(
              of(state),
              this.anagStorage.getProfessionDetails(professionCode)
            );
          }
        ),
        map(
          ([st, professionDetails]) => {
            st.professionDetails = professionDetails;
            return st;
          }
        )
      );
  }

  actionCancel(fromSearchFlow: boolean, activeRoute: ActiveRoute, fromResultFlow: boolean) {
    if (fromSearchFlow) {
      this.anagIntegration.navigate(this.routingService, ROUTES_HOME, activeRoute.getRouteData<AnagRouteData>(), activeRoute);
    } else if (fromResultFlow) {
        this.anagIntegration.navigate(this.routingService, ROUTES_MODAL_RESULT, activeRoute.getRouteData<AnagRouteData>(), activeRoute);
    }
  }

  actionUpdateConfig$(state$: Observable<AnagStateKeyData>, formRawData, partyTypeReq: AnagApiPartyTypeConfigRequest) {
    return state$.pipe(
      mergeMap((st: AnagStateKeyData) => {
        st.errors = [];
        return combineLatest([of(st), this.statelessService.retrieveSubjectType(partyTypeReq)]);
      }),
      mergeMap(([st, partyType]: [AnagStateKeyData, any]) => {
        if (typeof partyType === 'string') {
          st.errors.push(partyType);
          return combineLatest([of(st), of(partyType)]);
        } else {
          const configFilter = this.statelessService.getFlowDataUpdatedConfFilter(undefined, st.flowData);
          configFilter.partyType = partyType.output.partyType.codice;
          configFilter.country = formRawData.nation;
          configFilter.legalForm = 999;
          if (configFilter.extensionSubject == null) {
            configFilter.extensionSubject = new AnagExtensionSubject();
          }
          if (configFilter.extensionSubject.properties == null) {
            configFilter.extensionSubject.properties = [];
          }
          configFilter.extensionSubject.properties.push({chiave: 'idParty', valore: st.party.objectId});
          st.party.subjectType = new AnagEntityIta(partyType.output.partyType.codice,
            this.anagStorage.getEnumsByCode(SUBJECT_TYPE_ENUM).find(en =>
              en.codice === partyType.output.partyType.codice).descrizione);
          const filterApi = new AnagPartyKeyFilter();
          const keyConfApi = new AnagApiPartyKeyConfigRequest();
          keyConfApi.partyConfigurationFilter = filterApi;
          filterApi.partyType = partyType.output.partyType.codice;
          filterApi.country = formRawData.nation;
          filterApi.partyRole = st.flowData && st.flowData.partyRole ? parseInt(st.flowData.partyRole) : PACONF_VALUES.FILTER_DEFAULT;
          filterApi.legalForm = PACONF_VALUES.FILTER_DEFAULT;
          return combineLatest([
            of(st), this.statelessService.retrievePartyConf(configFilter), this.anagStorage.getPartyKeysByFilter(keyConfApi)
          ]);
        }
      }),
      map(([st, configuration, partyKeyConf]: [AnagStateKeyData, AnagApiConfiguration | string, AnagPartyKey]) => {
        if (typeof configuration === 'string') {
          if (st.errors.length === 0) {
            st.errors.push(configuration);
          }
        } else if (configuration.error && configuration.error.descrizioneErroreOccorso) {
          st.errors.push(configuration.error.descrizioneErroreOccorso);
        } else if (!configuration.output.partyConfig) {
          this.i18nService.translate('_ANAG_._MSG_._WRONG_INPUT_DATA_').subscribe(tr => {
            st.errors.push(tr);
          }).unsubscribe();
        } else {
          st.partyConfiguration = configuration.output;
          st.partyKeyConf = partyKeyConf;
          const partyKeys = st.party.partyKey ? st.party.partyKey : [];
          if (partyKeys.length > 0) {
            if (partyKeys[0].country !== formRawData.nation) {
              this.resetPartyKey(partyKeys[0]);
              partyKeys[0].country = formRawData.nation;
            }
           // partyKeys[0] = partyKeyConf;
            partyKeys[0].main = true;
          } else {
            partyKeys.push({ country: formRawData.nation, main: true });
          }
          st.party.partyKey = partyKeys;
        }
        return st;
      })
    );
  }

  resetPartyKey(partyKey: AnagPartyKey) {
    ['key1', 'key2', 'key3'].forEach(key => partyKey[key] ? partyKey[key].value = null : null);
  }

  duplicateParty$(filter: AnagApiParty): Observable<any> {
    return this.anagResourceService.duplicateParty$(filter).pipe(
      map(resp => {
        if (resp) {
          return resp;
        }
      })
    );
  }

  getSubjectDetail$(idSubject, idNode, nodeCode) {
    return this.anagResourceService.getSubjectDetail$(idSubject, idNode, nodeCode).pipe(
      map(resp => {
        if (resp) {
          return resp;
        }
      })
    );
  }

  actionFindParties$(stateKeyData$: Observable<AnagStateKeyData>, filterReq: AnagApiParty, activeRoute: ActiveRoute) {
    return stateKeyData$.pipe(
      mergeMap(
        (st) => {
          if (st.flowData) {
            if (st.flowData.nodeCode) {
              st.searchNodeCode = st.flowData.nodeCode;
            }
            if (st.flowData.partyType) {
              st.partyType = st.flowData.partyType;
            }
          } else {
            st.partyType = st.party.personType ? st.party.personType.codice : null;
            st.searchNodeCode = st.party.node ? st.party.node.code : null;
            st.searchNodeId = st.party.node ? st.party.node.identification : null;
            st.partyKey = st.party.partyKey.length > 0 ? st.party.partyKey[0] : null;
            st.isHomonymySearch = st.isUmaPartyHomonymyEnable;
          }
          return combineLatest([
            of(st), this.anagResourceService.findParties$(this.anagUtilsService.convertDateToString(AnagSubjectsFilter.adaptFromParty(st, filterReq))).pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              ([state, resp]) => {
                if (resp && resp.output) {
                  if (resp.output.totalCounter === '0') {
                    return resp;
                  } else {
                      const routeData = {
                        ...activeRoute.getRouteData<AnagRouteData>(),
                        party: this.statelessService.deepClone(state.party),
                        oldParties: activeRoute.getRouteData<AnagRouteData>() ? activeRoute.getRouteData<AnagRouteData>().oldParties ?
                          activeRoute.getRouteData<AnagRouteData>().oldParties :
                          activeRoute.getRouteData<AnagRouteData>().parties ?
                          activeRoute.getRouteData<AnagRouteData>().parties : null : null,
                        parties: resp.output.subjects,
                        totalCounterParties: resp.output.totalCounter,
                        redirectToMainFlow: state.redirectToMainFlow,
                        isUmaPartyHomonymyEnable: state.isUmaPartyHomonymyEnable,
                        isUmaPartyCFDuplicate: state.isUmaPartyCFDuplicate,
                        isUmaPartyGroupCompanies: state.isUmaPartyGroupCompanies,
                        isBackFromHomonymy: false
                      };
                      let route = ROUTES_RESULT_HOMONYMY;
                      if (routeData && routeData.isModalFlow) {
                        route = ROUTES_MODAL_RESULT_HOMONYMY;
                      }
                      state.party = new AnagApiParty();
                      this.anagIntegration.navigate(this.routingService, route, routeData, activeRoute);
                  }
                }
                return resp;
              }
            )
          );
        }
      )
    );
  }

  checkPartyKey$(filter: AnagPartyKeyService): Observable<any> {
    return this.anagResourceService.checkPartyKey$(filter).pipe(
      map(resp => {
        if (resp) {
          return resp;
        }
      })
    );
  }
}
