export class SearchPayments {
    searchType: string = ''
    functionCode: string = ''
    company: string = ''
    companyCtr: string = ''
    year: string = ''
    month: string = ''
    msgcode: string = ''
    forcedRecords: ClaimsTireaForcedRecord[] = []
}

export class ClaimsTireaForcedRecord {
    idTireaPayment: string = ''
    forced: boolean = false
    skipped: boolean = false
}