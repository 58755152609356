import { IiabTranslationsKeys } from '../iiab-translations-keys';

export const IIAB_DE_CH: IiabTranslationsKeys = {
  _IIAB_: {
    _MOTOR_: {
      _NICE_ACTIMIZE_KO_: ''
    },
    _OTP_: {
      _OTP_SERVICE_KO: 'Der OTP-Dienst ist nicht verfügbar. Bitte versuchen Sie es später noch einmal.',
      _INITIATED_SIGNING_PROCESS_: 'Initiierter Signaturprozess',
      _TITLE_: 'Dokumente unterzeichnen',
      _DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_: 'Die Zustimmung zur digitalen Signatur ist nicht akzeptiert. Es wird nicht möglich sein, Dokumente digital zu unterzeichnen.',
      _MOBILE_NOT_PRESENT_NOT_SIGNED_: 'Die Mobilfunknummer ist nicht vorhanden. Es wird nicht möglich sein, Dokumente digital zu unterzeichnen.',
      _EMAIL_NOT_PRESENT_NOT_SIGNED_: 'Die Email-adresse ist nicht vorhanden. Es wird nicht möglich sein, Dokumente digital zu unterzeichnen.',
      _DOCUMENT_DATA_NOT_PRESENT_NOT_SIGNED_: 'Einige Daten im Dokument fehlen. Es wird nicht möglich sein, Dokumente digital zu unterzeichnen.',
      _GENERIC_NOT_SIGNED_ERROR_: 'Es wird nicht möglich sein, Dokumente digital zu unterzeichnen.',
      _OTP_DOCUMENTS_: 'Digital Signatur - Dokumente',
      _CONSENSUSES_AND_CONTACTS_: 'Konsens und Kontakte',
      _OTP_DATA_MISSING_: 'Einige obligatorische Daten fehlen. Es ist nicht möglich, Dokumente digital zu signieren.',
      _OTP_DOCUMENTS_MISSING_: 'Für diese Bewegung sind keine digitalen Dokumente verfügbar',
      _BTN_: {
        _SIGN_DOCS_: 'Dokumente unterzeichnen',
      },
      _HOLDERS_: 'Inhaber',
      _SUBSCRIBER_: 'Auftragnehmer',
      _MOBILE_PHONE_: 'Handy-Nummer'
    },
    _LABEL_: {
      _DESCRIPTION_: 'Beschreibung',
      _VALUE_: 'Wert',
      _RELEASE_DATE_: '',
      _EXPIRATION_DATE_: '',
      _INT_PRIFIX_: "",
      _NUMBER_: ""
    },
    _ANAG_: {
      _TAX_ID_DIFFERENT_FROM_DOCUMENT_ID_: 'DE_The identity document (NIF/NIE/Passport) reported in the Documents section does not match the identity document (NIF/NIE/Passport) reported in the Identification Data section'
    }
  },
  _LS_TITLE_TOTAL_SUMMARY_: 'Gesamtübersicht',
  _NICE_ACTIMIZE_: {
    _HEAD_HIT: 'Nice Actimize hits',
    _HEAD_NOMINATIVE: 'Nominativ',
    _HEAD_SURNAME: 'Nachname',
    _HEAD_NAME: 'Name',
    _HEAD_DATE_OF_BIRTH: 'Geburtsdatum',
    _HEAD_RESULT: 'Ergebnis',
    _HEAD_TYPE_OF_HIT: 'Typ des Berichts',
    _HEAD_REQUEST_ID: 'Anforderungskennung',
    _HIT_FOUND: 'Treffer identifiziert',
    _HIT_NOT_FOUND: 'Keine Berichte identifiziert',
    _HIT_YES: 'Das Thema hatte Berichte. Eine weitere Überprüfung von Nice Actimize muss durchgeführt werden',
    _HIT_NO: 'Das Thema hatte keine Berichte. Auf der Party wurde kein Risiko festgestellt'
    },
    _COUNTERPROPOSAL_: 'Gegenangebot',
    _ADJUST_PROPOSAL_: 'Angebot anpassen',
    _IIAB_ERR_UPLOAD_MANDATORY_DOC_: 'Bitte laden Sie die obligatorischen Dokumente hoch'
};
