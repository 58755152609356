/**
 * GEL REST API
 * Rest API's for Workflow services
 *
 * OpenAPI spec version: 0.0.1
 * Contact: info@rgigroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { TypeBusiness, TypeCheck, TypeIdentifier } from "../../../common/model/models";


export interface PartyNiceActimizeCheck { 
    idPfpassauth: TypeIdentifier;
    idParty: TypeIdentifier;
    typeBusiness: TypeBusiness;
    typeCheck: TypeCheck;
    hasHits: boolean;
    score: number;
    idRequest: string;
    surname?: string;
    name?: string;
    fullName?: string;
    nominative?: string;
    birthDate?: string;
}