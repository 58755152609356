import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, combineLatest, forkJoin, of } from "rxjs";
import { DetailObjectToNew, DialogueHandlerSearch, ListObjectToSummary, NewDialogueState, ObjectToNew } from "../dialogue-handler-configuration/dialogue-handler.model";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { restEndPoint } from "../dialogue-handler-configuration/dialogue-handler.endpoint";
import { DialogueHandlerIntegrationService } from "../dialogue-handler-configuration/dialogue-handler-integration.service";
import { ActiveRoute } from "@rgi/rx/router";
import { ActCausOutcome, ActDoc, DialogueObject, Document, Etype, FunParam, FunctionDoc, NewDialogueRequest, ResponseSaveObject } from "../dialogue-handler-configuration/new-dialogue.model";
import { NewDialogueViewConfiguration, RoleCompany } from "../new-dialogue/new-dialogue.component";
import { DialogueSummaryObject, OutcomeService } from "../dialogue-handler-configuration/dh-summary.model";
import { DialogueHandlerListResponse } from "../dialogue-handler-configuration/dialogue-handler-list.model";
import { SummaryViewConfiguration } from "../dialogue-summary/dialogue-summary.component";
import {ModalService} from '@rgi/portal-ng-core';
import {RgiRxTranslationService} from "@rgi/rx/i18n";

@Injectable({
  providedIn: "root",
})
export class DialogueHandlerNewDialogueService {
  private baseApiUrlV2: string;
  private urlDialogueHandlerNew = "/claims/dialogueHandlerNew";

  constructor(
    private httpClient: HttpClient,
    private integrationService: DialogueHandlerIntegrationService,
    @Inject("environment") environment: any,
    @Inject('sessionService') private sessionService: any,
    private modalWarn: ModalService,
    private translateService: RgiRxTranslationService
  ) {
    this.baseApiUrlV2 =
      environment.api.portal.host + environment.api.portal.path + "/v2";
  }

  // Metodi
  //   initStateObject(routerData: any, state: Observable<DialogueHandlerSearch>){
  //       return state.pipe(map(st => {
  //         st.formSearch = routerData;
  //         return st;
  //       }));

  // }

  getCurrentSessionId() {
    const idSession = this.sessionService.list().find(el => el.isActive)?.idSession;
    return idSession ? idSession : null;
  }

  updateFormNew(
    newDialogueRequest: NewDialogueRequest,
    // isValidForm: boolean,
    st: Observable<NewDialogueState>
  ): Observable<NewDialogueState> {
    return st.pipe(
      map((state) => {
        state.newDialogueRequest = newDialogueRequest;
        // state.isValidForm = isValidForm;
        return state;
      })
    );
  }

  initStateNew$(st: Observable<NewDialogueState>, filterList: any): Observable<NewDialogueState> {
    return st.pipe(
      map((state) => {
        state.idParentSession = filterList.idParentSession;
        if (filterList.viewConfig == NewDialogueViewConfiguration.RESPONSE_VIEW_CONF) {
          state.searchWrapperObject = filterList.searchWrapperObject;
          state.viewConfig = filterList.viewConfig;
          state.responseObject = filterList.responseObject;
          state.messageList = filterList.messageList;
          state.dialogueSummaryObject = filterList.dialogueSummaryObject;
          state.list = filterList.list;
        }
        else if (filterList.viewConfig == NewDialogueViewConfiguration.PARTIAL_VIEW_CONF) {
          state.viewConfig = filterList.viewConfig;
          state.searchWrapperObject = filterList.searchWrapperObject;
          state.newWrapperObject.responseRoleList = filterList.newWrapperObject.responseRoleList;
          state.list = null;
        } else {
          state.searchWrapperObject = filterList.searchWrapperObject;
          // state.wrapperNewDialogueObject = filterList.wrapperNewDialogueObject;
          state.newWrapperObject = filterList.newWrapperObject;
          state.viewConfig = filterList.viewConfig;
          state.claimNumberPass = filterList.claimNumberPass;
          state.list = null;
          console.log("initStateNew$() valore state====> ", state);
          if (state.newWrapperObject.responseActCausOutcome !== undefined && state.newWrapperObject.responseActDoc !== undefined && state.newWrapperObject.responseFunParam !== undefined && state.newWrapperObject.responseFunctionDoc !== undefined) {
            state.newWrapperObject.responseActCausOutcome.response = null;
            state.newWrapperObject.responseActDoc.response = null;
            state.newWrapperObject.responseFunParam.response = null;
            state.newWrapperObject.responseFunctionDoc.response = null;
          }
        }


        return state;
      })
    );
  }


  // saveDialogue2( st: Observable<NewDialogueState>,  activeRouteData: ActiveRoute): Observable<NewDialogueState> {
  //   return st.pipe(
  //     switchMap((state) => {
  //       // console.log("state.newDialogueRequest prima della chiamata asinc ====> ", state.newDialogueRequest);
  //       const newInfoRequest: NewDialogueRequest = state.newDialogueRequest;
  //       // console.log("newInfoRequest prima della chiamata asincrona ===> ", newInfoRequest);
  //       return this.httpClient.post<OutcomeService>( this.baseApiUrlV2 + restEndPoint.saveDialogue, newInfoRequest)
  //     .pipe(
  //         map((apiResponse) => {
  //           if (!apiResponse) {
  //             console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
  //             return state;
  //           }
  //           state.outcomeService = this.processSaveResponse(apiResponse);
  //           // this.integrationService.goToDialogueHandlerSummary(null, activeRouteData);
  //           //navigazione momentanea
  //           return state;
  //         }),
  //         catchError((error) => {
  //           console.error("Errore durante il salvataggio del dialogo:", error);
  //           return of(state)
  //         })
  //       );
  //     })
  //   );
  // }


  saveDialogue(st: Observable<NewDialogueState>, activeRouteData: ActiveRoute, documents: Document[],param?: any[],msgId?): Observable<NewDialogueState> {
    return st.pipe(
      mergeMap((state) => {
        let saveDialogueManualActivityRequest: NewDialogueRequest = null;
        if (state.viewConfig != NewDialogueViewConfiguration.RESPONSE_VIEW_CONF) {
          state.newDialogueRequest.documents = documents;
          state.newDialogueRequest.response = param;
          state.newDialogueRequest.idFunStatusSelected = msgId;
          const { dialogueMsgCode, dialogueMsgDescription, response, ...saveDialogueManualActivity } = (state.newDialogueRequest as any);
          saveDialogueManualActivityRequest= saveDialogueManualActivity;
          console.error("body del save=======>", saveDialogueManualActivityRequest)
        } else {
          let parameters = null;
          if (state.newDialogueRequest.response) {
            parameters = state.newDialogueRequest.response;
          }
          let saveObj: NewDialogueRequest = {
            newDialogue: false,
            idHistoryStatus: (state.messageList.idHistoryStatus).toString(),
            claimNumberPass: state.dialogueSummaryObject.numCl,
            potClaimNumberPass: state.dialogueSummaryObject.numPotClaim,
            idClnotifcard: (state.list.idClnotifcard).toString(),
            licensePlateUs: state.dialogueSummaryObject.insurPlate,
            licensePlateOther: state.dialogueSummaryObject.plateCtnParty,
            idFunStatusSelected: state.newDialogueRequest.idFunStatusSelected,
            insuranceCodeUs: state.dialogueSummaryObject.codCompanyAnia,
            insuranceCodeOther: state.dialogueSummaryObject.codCompCtnpAnia,
            lossDate: (state.list.lossDate).toString(),
            roleCompanyUs: state.dialogueSummaryObject.roleCompany.code,
            idDialogueSubject: state.dialogueSummaryObject.dialogueSubject.code,
            documents: documents,
            parameters: parameters,
            externalIdentifyDamageParty: null,
            idTypeLoss: null,
            note: state.newDialogueRequest.note,
            tinVatDamageParty: null
          };

          saveDialogueManualActivityRequest = saveObj;
        }
        const request = saveDialogueManualActivityRequest;
        const response = this.httpClient.post<ResponseSaveObject>(this.baseApiUrlV2 + restEndPoint.saveDialogue, request)
        return combineLatest([response, of(state)]);
      }),
      mergeMap(([response, state]) => {
        if (response.requestOutcome.code === 'KO'){
          this.translateService.translate('_CLAIMS_._ERROR_DURING_SAVING').subscribe(res => {
            this.modalWarn.open([response.requestOutcome.description], res);
          });
          return combineLatest( [new Observable<DialogueSummaryObject>(), st]);
        }

        state.outcomeService = response;
        const queryString: string = "?idClnotifcard=" + response.idnotifcard;

        const summary: Observable<DialogueSummaryObject> = this.httpClient.get<DialogueSummaryObject>(this.baseApiUrlV2 + restEndPoint.summary + queryString);

        return combineLatest([summary, st]);
      }),
      mergeMap(([summary, st]) => {
        let viewCond = SummaryViewConfiguration.SUMMARY_VIEW_CONF;
        const objectToSummary: ListObjectToSummary = {
          searchWrapperObject: st.searchWrapperObject,
          dialogueSummaryObject: summary,
          responseFromSearchList: st.list,
          viewConfig: viewCond,
          idClnotifcard: st.outcomeService.idnotifcard,
          idParentSession: st.idParentSession
        }
         this.integrationService.goToDialogueHandlerSummary(objectToSummary,activeRouteData);

        // this.integrationService.goToDialogueHandlerSummary(objectToSummary, activeRouteData);
        return of(st)
      }),
    )
  }


  // private processSaveResponse(apiResponse: Etype): Etype {
  //   if (!apiResponse) {
  //     console.error(
  //       "La risposta non è nel formato atteso o è vuota: ",
  //       apiResponse
  //     );
  //     return null;
  //   }
  //   else
  //    {
  //     return apiResponse
  //   }
  // }

  //creazione metodo per functionParam
  popolateCascadeSections(st: Observable<NewDialogueState>, activeRouteData: ActiveRoute, funStatus: number) {
    return st.pipe(
      mergeMap((state) => {
        const queryStringForCausaleBySinistro = this.generateQueryStringForCausaleBySinistro(state);//caricare parametro come per state.formSearch
        const queryStringForFunParam = this.generateQueryStringForFunParam(state, funStatus);
        const queryStringForFunctionDoc = this.generateQueryStringForFunctionDoc(state, funStatus);

        return forkJoin({
          responseAttCausBySinistro: this.httpClient.get<ActCausOutcome>(this.baseApiUrlV2 + restEndPoint.attoreCausaleBySinistroList + queryStringForCausaleBySinistro),
          responseFunParam: this.httpClient.get<FunParam>(this.baseApiUrlV2 + restEndPoint.functionParam + queryStringForFunParam),
          responseFunctionDoc: this.httpClient.get<FunctionDoc>(this.baseApiUrlV2 + restEndPoint.functionDoc + queryStringForFunctionDoc)
        }).pipe(
          map((apiResponses) => {
            state.newWrapperObject.responseActCausOutcome = this.processSecondResponse(apiResponses.responseAttCausBySinistro);
            state.newWrapperObject.responseFunParam = this.processFourthResponse(apiResponses.responseFunParam);
            state.newWrapperObject.responseFunctionDoc = this.processFunctionDocResponse(apiResponses.responseFunctionDoc);
            state.newWrapperObject.responseActCausOutcome.response = this.filterByRole(state.newDialogueRequest.roleCompanyUs, state.newWrapperObject.responseActCausOutcome.response);
            console.log("STATE AGGIORNATO DOPO CHIAMATE======================>", state);
            return state;
          })
        );

      })
    );
  }

  popolateResponseCascadeSections(st: Observable<NewDialogueState>, activeRouteData: ActiveRoute, funStatus: number) {
    return st.pipe(
      mergeMap((state) => {
        // const queryStringForCausaleBySinistro = this.generateQueryStringForCausaleBySinistro(state);//caricare parametro come per state.formSearch
        const queryStringForFunParam = this.generateQueryStringForFunParam(state, funStatus);
        const queryStringForFunctionDoc = this.generateQueryStringForFunctionDoc(state, funStatus);

        return forkJoin({
          // responseAttCausBySinistro: this.httpClient.get<ActCausOutcome>(this.baseApiUrlV2 + restEndPoint.attoreCausaleBySinistroList + queryStringForCausaleBySinistro),
          responseFunParam: this.httpClient.get<FunParam>(this.baseApiUrlV2 + restEndPoint.functionParam + queryStringForFunParam),
          responseFunctionDoc: this.httpClient.get<FunctionDoc>(this.baseApiUrlV2 + restEndPoint.functionDoc + queryStringForFunctionDoc)
        }).pipe(
          map((apiResponses) => {
            // state.newWrapperObject.responseActCausOutcome = this.processSecondResponse(apiResponses.responseAttCausBySinistro);
            state.newWrapperObject.responseFunParam = this.processFourthResponse(apiResponses.responseFunParam);
            state.newWrapperObject.responseFunctionDoc = this.processFunctionDocResponse(apiResponses.responseFunctionDoc);
            return state;
          })
        );

      })
    );
  }

  private processSecondResponse(apiResponse: any): ActCausOutcome {
    if (!apiResponse || !Array.isArray(apiResponse.response)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    console.error(apiResponse)
    return {
      requestOutcome: apiResponse.requestOutcome,
      response: apiResponse.response.map((item: any) => ({
        // causaleId: item.causaleId,
        // causaleDescr: item.causaleDescr,
        // actorId: item.actorId,
        // actorExternalIdentifyDamageParty: item.actorExternalIdentifyDamageParty,
        // actorName: item.actorName,
        // licensePlateUS: item.licensePlateUS,
        ...item,
        selected: false
      }))
    };
  }

private filterByRole(roleCompanyUs: any, response: any){
  let creditorCodeList = ['1','3','5','7','9','25'];
  let debtorCodeList = ['2','4','6', '8','10','26'];
  let filteredList = response;

  if(roleCompanyUs == RoleCompany.CREDITOR) {
    filteredList = response.filter(act =>
      creditorCodeList.map(role => role).includes(act.ruolo.code));
  }else if(roleCompanyUs == RoleCompany.DEBTOR){
    filteredList = response.filter(act =>
      debtorCodeList.map(role => role).includes(act.ruolo.code));
  }
  return filteredList;

}

  private processFunctionDocResponse(apiResponse: any): FunctionDoc {
    if (!apiResponse || !Array.isArray(apiResponse.response)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    return {
      requestOutcome: apiResponse.requestOutcome,
      response: apiResponse.response.map((item: any) => ({
        docId: item.docId,
        docCode: item.docCode,
        dimensionMax: item.dimensionMax,
        compulsory: item.compulsory,
        numAttach: item.numAttach,
      })),
    };
  }

  private processFourthResponse(apiResponse: any): FunParam {
    console.log("apiResponseIncriminata===>", apiResponse);
    if (!apiResponse || !Array.isArray(apiResponse.response)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    return {
      requestOutcome: apiResponse.requestOutcome,
      response: apiResponse.response.map((item: any) => ({
        idParam: item.idParam,
        idFunctionStatus: item.idFunctionStatus,
        paramLabel: item.paramLabel,
        typeValue: item.typeValue,
        defaultValue: item.defaultValue,
        listCode: item.listCode,
        listValue: item.listValue,
        required: item.required,
        maxLength: item.maxLength
      }))
    };
  }

  generateQueryStringForFunParam(state: NewDialogueState, funStatus: number): string {
    const queryParams = [];
    // TODO manca parametro idFunctionstatus che dovrebbero arrivare da componente angularJS
    if (state.viewConfig == NewDialogueViewConfiguration.RESPONSE_VIEW_CONF) {
      if (state.newDialogueRequest?.idFunStatusSelected) {
        queryParams.push(`idFunctionstatus=${state.newDialogueRequest?.idFunStatusSelected}`);
      }
    } else {
      queryParams.push(`idFunctionstatus=${funStatus}`);
    }

    return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  }

  generateQueryStringForFunctionDoc(state: NewDialogueState, funStatus: number): string {
    const queryParams = [];
    // TODO manca parametro idFunctionstatus che dovrebbero arrivare da componente angularJS
    //  queryParams.push(`idFunctionstatus = testParameterStatus`);
    if (state.viewConfig == NewDialogueViewConfiguration.RESPONSE_VIEW_CONF) {
      if (state.newDialogueRequest?.idFunStatusSelected) {
        queryParams.push(`idFunctionstatus=${state.newDialogueRequest?.idFunStatusSelected}`);
      }
    } else {
      queryParams.push(`idFunctionstatus=${funStatus}`);
    }

    return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  }

  generateQueryStringForCausaleBySinistro(state: NewDialogueState): string {
    const queryParams = [];
    // TODO manca parametro del codice sinistro che dovrebbe arrivare da componente angularJS
    if (state.newDialogueRequest.idFunStatusSelected) {
      queryParams.push(`functionCode=${state.newDialogueRequest.idFunStatusSelected}`);
    }
    if (state.newDialogueRequest.idDialogueSubject) {
      queryParams.push(`dialogueSubjectCode=${state.newDialogueRequest.idDialogueSubject}`);
    }
    console.error(state.claimNumberPass)

    if (state.claimNumberPass) {
      queryParams.push(`claimNumberPass=${state.claimNumberPass}`);
    }
    if (state.newDialogueRequest.roleCompanyUs) {
      queryParams.push(`ruolo=${state.newDialogueRequest.roleCompanyUs}`);
    }

    // queryParams.push(`claimNumberPass=22`);

    return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  }


  popolateLastTableData(st: Observable<NewDialogueState>, activeRouteData: ActiveRoute, index: number) {
    return st.pipe(
      mergeMap((state) => {
        const queryStringForAttoreDocumenti = this.generateQueryStringForActDoc(state, index);

        return this.httpClient.get<ActDoc>(this.baseApiUrlV2 + restEndPoint.attoreDocumenti + queryStringForAttoreDocumenti).pipe(
          map((responseActDoc) => {
            state.newWrapperObject.responseActDoc = this.processActDocResponse(responseActDoc);
            console.log("STATE AGGIORNATO DOPO CHIAMATE======================>", state);

            // const objectToNew: ObjectToNew = {
            //   searchWrapperObject: state.searchWrapperObject,
            //   newWrapperObject: state.newWrapperObject,
            //   viewConfig: viewCond,
            //   newDialogueRequest: null
            // };
            // this.integrationService.openNewDialogue(objectToNew, activeRouteData);

            return state;
          })
        );
      })
    );
  }


  private processActDocResponse(apiResponse: any): ActDoc {
    if (!apiResponse || !Array.isArray(apiResponse.response)) {
      console.error("La risposta non è nel formato atteso o è vuota: ", apiResponse);
      return null;
    }
    return {
      requestOutcome: apiResponse.requestOutcome,
      response: apiResponse.response.map((item: any) => ({
        docId: item.docId,
        docName: item.docName,
        docTitle: item.docTitle,
        docCategory: item.docCategory,
        docSubCategory: item.docSubCategory,
        actorName: item.actorName
      }))
    };
  }

  generateQueryStringForActDoc(state: NewDialogueState, index: number): string {
    const queryParams = [];
    // TODO mancano parametri claimNumberPass e actorId che dovrebbero arrivare da componente angularJS
    queryParams.push(`claimNumberPass=${state.newDialogueRequest.claimNumberPass}`);
    queryParams.push(`actorId=${state.newWrapperObject.responseActCausOutcome.response[index].actorId}`);
    return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  }


  partialPopolateCascadeSections(st: Observable<NewDialogueState>, activeRouteData: ActiveRoute, funStatus: number) {
    return st.pipe(
      mergeMap((state) => {
        const queryStringForFunParam = this.generateQueryStringForFunParam(state, funStatus);
        return this.httpClient.get<FunParam>(this.baseApiUrlV2 + restEndPoint.functionParam + queryStringForFunParam).pipe(
          map((param) => {
            state.newWrapperObject.responseFunParam = this.processFourthResponse(param);

            return state
          })
        )
      })
    )
  }

  goBackToSummary(st: Observable<NewDialogueState>, activeRouteData: ActiveRoute) {
    return st.pipe(
      mergeMap((state) => {
        let obj: ListObjectToSummary = {
          dialogueSummaryObject : state.dialogueSummaryObject,
          viewConfig : SummaryViewConfiguration.SUMMARY_VIEW_CONF,
          searchWrapperObject : state.searchWrapperObject,
          responseFromSearchList : null,
          idParentSession: state.idParentSession
        }

        this.integrationService.goToDialogueHandlerSummary(obj, activeRouteData);
        return st;
      })
    )

  }

  closeSession$(activeRouteId: string, state: NewDialogueState) {
    this.sessionService.remove(activeRouteId);
    if (state.idParentSession !== null) {
      this.sessionService.setActive(state.idParentSession);
    }
  }

}

