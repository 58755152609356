import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import { LicDatepickerComponent } from './lic-datepicker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RgiRxDatePickerModule, RgiRxFormElementsModule } from '@rgi/rx/ui';


@NgModule({
  declarations: [LicDatepickerComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    ReactiveFormsModule,
    RgiRxDatePickerModule,
    RgiRxFormElementsModule
  ],
  exports: [LicDatepickerComponent]
})
export class LicDatepickerModule { }
