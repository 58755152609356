import {AnagApiAddress, AnagApiOtherAddress, AnagApiParty} from './anag-api-party';
import {AnagConfiguration} from './anag-configuration';
import {AnagFlowData} from './anag-flow-data';

export class AnagRouteData {
  isModalFlow ? = false;
  isSearchFlow ? = false;
  isEditFlow ? = false;
  party?: AnagApiParty;
  configuration?: AnagConfiguration;
  parties?: Array<AnagApiParty>;
  oldParties?: Array<AnagApiParty>;
  totalCounterParties?: string;
  action?: string;
  lastAction?: string;
  idParentSession?: string;
  flowData?: AnagFlowData;
  redirectToMainFlow ? = false;
  isUmaPartyHomonymyEnable ? = false;
  isBackFromHomonymy ? = false;
  isUmaPartyCFDuplicate ? = false;
  isUmaPartyGroupCompanies ? = false;
  lastIdFromDamage?: string;
}

export class AnagAddressData {
  address: AnagApiAddress;
  otherAddress: AnagApiOtherAddress;
  addressType: string;
  modalLabel: string;
  showOtherAddress: boolean;
  disabledMainAddress: boolean;
  isQuote?: boolean;
  partyRole?: string;
}
