import { BeneficiaryService } from './../../services/beneficiary.service';
import { Component, EventEmitter, OnDestroy, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, Subscription, of, throwError, combineLatest } from 'rxjs';
import { queue } from 'rxjs/internal/scheduler/queue';
import { catchError, distinctUntilChanged, take, switchMap, map, tap } from 'rxjs/operators';
import { RoutableComponent } from '../../../life-card/routable-component';
import { CardsNavigationService } from '../../cards-navigation/cards-navigation.service';
import { PolicyRole, Product } from '../../models/policy.model';
import { ErrorType, LifeIssueMessage } from '../../models/response.model';
import { ActionsService } from '../../services/actions.service';
import { IddProductService } from '../../services/iddproduct.service';
import { LifeRoleService } from '../../services/life-role.service';
import { LifeSessionService } from '../../services/life-session-service';
import { NavigationSummaryService } from '../../services/navigation-summary.service';
import { PassProLifeService } from '../../services/passpro-life.service';
import { Insured, PolicyService } from '../../services/policy-service';
import { LicErrorsUtils } from '../../utils/lic-errors-utils';
import { Roles, SubjectConstants, SystemProperties } from './../../enum/life-issue.enum';
import { SystemPropertiesService } from './../../services/system-properties.service';
import { ValidationSubjectsService } from './../../services/validation-subjects.service';
import { PartyCheck } from '../../models/party-service.model';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { IDDProduct } from '../../models/iddProduct.model';
import { Subject } from '../../models/subject.model';
import { PushMessageHandlerService, RgiRxPushMessage } from '@rgi/rx';
import { LicObjectUtils } from '../../utils/lic-object-utils';
/* eslint-disable max-len */

@Component({
  templateUrl: './life-role-page.component.html',
  styleUrls: ['./life-role-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LifeRolePageComponent implements OnInit, OnDestroy, RoutableComponent {
  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();

  private onInitCalled = false;

  public form: UntypedFormGroup;
  public valProd: string;
  public policyRole: PolicyRole;
  public prodotti: Product[] = [];
  public roles: PolicyRole[] = [];
  public showRoles = false;
  public showEffectiveHolders = false;
  public _messages: string[] = [];
  public _blockingMsgs = [];
  public _warningMsgs = [];
  public _authoMsgs = [];
  public lastGroupId = 0;
  public isQuickQuotation = true;
  public oldAnagHolderValue: Subject;
  public oldAnagValue: Subject;
  public operator;
  public ROLES = Roles;

  setmessages(messages: string[]) {
    this.pushMessageHandler.clearTag('validation');
    messages = LicObjectUtils.asValidArray(messages).concat(this._messages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'validation', status: 'danger', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setblockingMsgs(blockingMsgs: string[]) {
    this.pushMessageHandler.clearTag('blocking');
    const messages = LicObjectUtils.asValidArray(blockingMsgs).concat(this.getBlockingMessages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'blocking', status: 'danger', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setwarningMsgs(warningMsgs: string[]) {
    this.pushMessageHandler.clearTag('warning');
    const messages = LicObjectUtils.asValidArray(warningMsgs).concat(this.getWarningMessages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'warning', status: 'default', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setauthoMsgs(authoMsgs: string[]) {
    this.pushMessageHandler.clearTag('auth');
    const messages = LicObjectUtils.asValidArray(authoMsgs).concat(this.getAuthMessages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'auth', status: 'warning', dismissible: false, options: {icon: 'rgi-ui-icon-info'} }))
    );
  }

  public checkBoxOptions = [
    {label: this.translateService.getImmediate('lic_yes'), value: 'Yes'},
    {label: this.translateService.getImmediate('lic_no'), value: 'No'}
  ];
  public enablePresetDelegato = false;
  public showSubjectBoxDelegato = false;
  public selectedValueDelegato: 'Yes' | 'No' = 'No';

  public enablePresetTutoreLegale = false;
  public showSubjectBoxTutoreLegale = false;
  public selectedValueTutoreLegale: 'Yes' | 'No' = 'No';

  public enablePresetTerzoPagatore = false;
  public showSubjectBoxTerzoPagatore = false;
  public selectedValueTerzoPagatore: 'Yes' | 'No' = 'No';

  public messageFromPreviousPage: LifeIssueMessage[] = [];

  public onInitEnd = false;

  public isFromAuth = false;
  public productEnabled: boolean;
  protected $subscriptions: Subscription[] = [];
  protected hasHolderPrevalorized = false;

  get policyHolder(): UntypedFormGroup { return this.form.get('policyHolder') as UntypedFormGroup; }

  get allRoles(): UntypedFormArray { return this.form.get('allRoles') as UntypedFormArray; }

  get disableProductSelect(): boolean {
    return this.policyService.isFromIDD || this.policyService.isFromAuth
      || this.policyService.isFromPreventive || this.policyService.isFromQuoteModification
      || this.policyService.isFromBuyQuote || this.policyService.isFromProposalModification;
  }

  get showHolderTrash(): boolean {
    return !this.isFromAuth;
  }

  get disableHolderTrash(): boolean {
    return this.policyService.isFromPreventive ||
      this.policyService.isFromQuoteModification ||
      this.policyService.isFromBuyQuote ||
      this.policyService.isFromIDD;
  }

  get showProductSelect(): boolean {
    return this.isHolderPresent;
  }

  get isCAC() {
    return this.policyService.isCAC;
  }

  /* get getValidationMessages(): string[] {
    return this._messages;
  } */

  get getWarningMessages(): string[] {
    return this._warningMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.WARNING)
    .map(msg => msg.message));
  }

  get getBlockingMessages(): string[] {
    return this._blockingMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.ERROR)
    .map(msg => msg.message));
  }

  get getAuthMessages(): string[] {
    return this._authoMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.AUTH)
    .map(msg => msg.message));
  }

  get isHolderPresent(): boolean {
    return (this.form.controls.holder.value !== undefined && this.form.controls.holder.value !== null);
  }

  get showInsured(): boolean {
    return this.policyService.isInsuredPresent();
  }

  get showSecondInsured(): boolean {
    return this.policyService.isSecondInsuredPresent();
  }

  constructor(protected lifeRoleService: LifeRoleService,
              protected formBuilder: UntypedFormBuilder,
              protected policyService: PolicyService,
              protected passProService: PassProLifeService,
              protected cardsNavigationService: CardsNavigationService,
              protected validationService: ValidationSubjectsService,
              protected actionService: ActionsService,
              protected translateService: TranslationWrapperService,
              protected navigationSummaryService: NavigationSummaryService,
              protected systemPropertiesService: SystemPropertiesService,
              protected lifeSessionService: LifeSessionService,
              protected iddProductService: IddProductService,
              @Optional() protected cache: QuestionnaireCacheService,
              protected beneficiaryService: BeneficiaryService,
              public pushMessageHandler: PushMessageHandlerService
             ) { }

  ngOnInit() {
    this.pushMessageHandler.clearTag('validation');
    this.pushMessageHandler.clearTag('blocking');
    this.pushMessageHandler.clearTag('warning');
    this.pushMessageHandler.clearTag('auth');
    this.operator = this.lifeSessionService.getOperator();
    this.cardsNavigationService.resetMessages(this.cardsNavigationService.STEP.SOGGETTI.errorId);
    this.eventPropagation.emit('startLoader');
    if (!this.onInitCalled) {
      this.onInitCalled = true;
    }

    this.validationService.clean();
    this.cardsNavigationService.resetNavigationSteps();
    this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.SOGGETTI.route);
    this.navigationSummaryService.resetNavigationSummary();

    // AXA-9772
    if (!this.policyService.idMasterPolicy) {
      this.policyService.idMasterPolicy = this.policyService.mainProposal.proposal.idMasterPolicy;
    }

    if (
      this.policyService.mainProposal != null &&
      this.policyService.mainProposal.quote != null &&
      this.policyService.mainProposal.quote.product != null
      ) {
        this.valProd = this.policyService.mainProposal.quote.product.code;
    }

    // RDDL-3764: Add initialization of product for lifeProposalSettings
    if (this.policyService.subjectFromIDD == null && this.policyService.productFromIDD != null) {
      this.valProd = this.policyService.productFromIDD.code;
      this.changeProdWithCallback({ target: { value: this.valProd } }, null);
    }

    if (this.policyService.isFromProposalModification) {
      this.valProd = this.policyService.mainProposal.quote.product.code;
      this.changeProdWithCallback({ target: { value: this.valProd } }, this.afterSaveFromQuoteModification());
    }

    this.buildForm();
    this.resetMessagesOnFormValid();

    this.hasHolderPrevalorized = !!this.lifeRoleService.getPolicyHolder();

    if (this.onInitCalled) {
      if (this.policyService.isFromPreventive || this.policyService.isFromQuoteModification) {
        this.$subscriptions.push(
          this.passProService.getProductsFromService(this.isQuickQuotation).subscribe(productsResp => {
            this.prodotti = productsResp.products;
            this.doCommonInit();
          })
        );
      } else if (this.policyService.isFromAuth || this.policyService.isFromProposalModification) {
        this.$subscriptions.push(
          this.passProService.getProductsFromService().subscribe(productsResp => {
            this.prodotti = productsResp.products;
            this.doCommonInit();
          })
        );
      } else {
        // Populate the products select
        this.$subscriptions.push(
          this.passProService.getProductsFromService().subscribe(productResponse => {
            const productTypeCode = this.policyService.getProductTypeFromIDD(this.lifeSessionService.productType, productResponse.products);
            // disabilito la selezione se ho già un prodotto selezionanto andando a filtrare la response e ottenendo solo un elemento
            this.prodotti = productResponse.products.filter(prod => prod.productTypeCode === productTypeCode);
          })
        );
        this.doCommonInit();
      }
    }
  }

  // Metodo dove inserire tutto quello che c'è da fare dopo il saveProposal da modifica quotazione
  protected afterSaveFromQuoteModification(response = null) { }

  public getAllMessagesForNextPage() {
    return this._authoMsgs.map(m => new LifeIssueMessage(ErrorType.AUTH, m)).concat(
      this._warningMsgs.map(m => new LifeIssueMessage(ErrorType.WARNING, m))
    );
  }

  public initEnd(): boolean {
    return this.onInitEnd;
  }

  public getRoleGroup(groupId: string): UntypedFormGroup {
    const array = this.allRoles.controls.filter(c => c.get('groupId').value === groupId);
    if (array.length > 0) {
      return array[0] as UntypedFormGroup;
    }
    return null;
  }

  public getRoleListValue(groupId: string): any {
    return this.getRoleGroup(groupId).getRawValue().roleList ? this.getRoleGroup(groupId).getRawValue().roleList : null;
  }

  public getRoleList(groupId: string): UntypedFormArray {
    return this.getRoleGroup(groupId).get('roleList') as UntypedFormArray;
  }

  public getSubjectGroup(groupId: string, j: number): UntypedFormGroup {
    return this.getRoleList(groupId).get(j.toString()) as UntypedFormGroup;
  }

  public onInitEndMethod() {
    // metodo dove inserire tutto quello che c'è da fare prima e dopo il caricamento del FE
    this.onInitEnd = true; // CARICA IL FE DEI RUOLI
    this.eventPropagation.emit('stopLoader');
  }

  resetMessagesOnFormValid() {
    // check validity on value changes
    this.form.valueChanges.subscribe(x => {
      if (this.form.valid) {
        this._messages = this.validationService.removeMessage('allRoles');
        this.setmessages(this._messages);
      }
    });
  }

  private getIdPvForAnag() {
    return  !!this.lifeSessionService.idPvManagement && !!this.lifeSessionService.idPvManagement.trim() ?
              this.lifeSessionService.idPvManagement : this.lifeSessionService.idPv;
  }

  public initIDD() {
    if (this.policyService.productFromIDD != null) {
      this.eventPropagation.emit('startLoader');
      this.valProd = this.policyService.productFromIDD.code;
      this.changeProdWithCallback({ target: { value: this.valProd } }, (response: any) => {
        this.$subscriptions.push(
          this.lifeRoleService.getSubjectFromAnag(
            this.policyService.subjectFromIDD.objectId,
            this.getIdPvForAnag()
          ).pipe(
            switchMap((subjectFromAnag) => {
              const subject = subjectFromAnag.subject;
              return combineLatest(
                [
                  this.checkPartyCompleted(
                    subject,
                    this.lifeRoleService.getRoleDescription(new PolicyRole(this.getRoleOfHolder(), subject)),
                    { index: 0 }
                  ),
                  this.getCheckPartyCompletedForInsured(subject),
                  this.callPartyCompleteForCheckboxRoles(subject),
                  of(subject) as Observable<Subject>
                ]);
            })
          )
          .subscribe(([partyCompletedHolder, partyCompleteInsured, checkBoxParty, subjectFromAnag]) => {
            this.handleSubjectInit(subjectFromAnag);
          })
        );
      });
    }
  }

  getCheckPartyCompletedForInsured(subject: Subject): Observable<{partyCompleted: boolean}> {
    if (
      (this.policyService.getInsuredLogic() === Insured.COULD_BE_EQUAL ||
      this.policyService.getInsuredLogic() === Insured.MUST_BE_EQUAL) &&
      !this.validationService.isLegal(new PolicyRole(Roles.CONTRACTOR, subject))
      ) {
        return this.checkPartyCompleted(
          subject,
          this.lifeRoleService.getRoleDescription(new PolicyRole(Roles.INSURED, subject)),
          { index: null }
        );
    }
    return of({ partyCompleted: null });
  }

  handleSubjectInit(subjectFromAnag) {
    this.lifeRoleService.setPolicyHolder(subjectFromAnag, this.getRoleOfHolder());
    this._messages = this.validationService.removeMessage('holder');
    this.buildForm();
    this.onInitEndMethod();
  }

  public getRoleOfHolder(): Roles {
      return (this.isCAC) ? Roles.ADERENTE : Roles.CONTRACTOR;
  }

  public initRoles() {
    if (!!this.valProd) {
      this.eventPropagation.emit('startLoader');
      const event = {
        target: {
          value: this.valProd
        }
      };
      this.changeProdWithCallback(event, (response: any) => {
        this.buildForm();
        this.onInitEndMethod();
      });
    }
  }

  public buildForm() {
    if (this.lifeRoleService.getPolicyHolder() != null) {
      this.policyRole = this.lifeRoleService.getPolicyHolder();
      this.showRoles = true;
      this.getAllRoles();
    }
    this.form = this.formBuilder.group({
      product: [this.valProd],
      insured: [this.lifeRoleService.getPolicyInsured()],
      secondInsured: [this.lifeRoleService.getSecondPolicyInsured()],
      holder: [this.lifeRoleService.getPolicyHolder()],
      allRoles: new UntypedFormArray([])
    });
    // product state management and validation
    this.form.controls.product.setValidators(Validators.required);
    if (this.disableProductSelect) {
      this.form.controls.product.disable();
    } else {
      this.form.controls.product.enable();
    }
  }

  public openAnagHolder(event: any) {
    this.eventPropagation.emit(event);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).pipe(
        switchMap((value: Subject) => {
          if (value != null && !this.oldAnagHolderValue) {
            return this.handleAnagHolder(value);
          }
      }),
      ).subscribe((response) => {
        this.showRoles = true;
        this.getAllRoles();
      })
    );
  }

  public editAnagHolder(event: any) {
    if (event.subject != null) {
      this.handleAnagHolder(event.subject).pipe(
        switchMap(() => this.callPartyCompletedForOtherRolesWithSameObjectId(this.lifeRoleService.getPolicyHolder()))
      ).subscribe();
    }
  }

  protected handleAnagHolder(value: Subject): Observable<any> {
    this.oldAnagHolderValue = value; // Workaround: Each time the modal is opened, an event is triggered
    this.doActionWhenSubjectReceived(value, this.getRoleOfHolder());
    this.lifeRoleService.setPolicyHolder(value, this.getRoleOfHolder());
    this.policyRole = this.lifeRoleService.createPolicyRole(this.lifeRoleService.getPolicyHolder(), this.form.controls.holder);
    return this.checkPartyCompleted(value, this.policyRole, { index: 0 });
  }


  public openAnag(groupId: string, event: any) {
    this.eventPropagation.emit(event.request);
    // TODO refactor nested subscription
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).pipe(
        switchMap(value => {
          if (value != null && !this.oldAnagValue) {
            return this.handleNewAnagSubject(value, groupId, event);
          }
          return of(null);
        })
      ).subscribe()
    );
  }

  public editAnag(groupId: string, event: any) {
    if (event && event.request && event.request.subject) {
      const parentId = this.getParentId(groupId);
      // first i'll delete the role and then i'll add with the changed one
      const role = this.getRoleGroup(groupId).value.roleObj;
      this.validationService.deleteFilledRole(role.partyRole, groupId);
      this.removeRoleFromForm(parentId, event);
      this.$subscriptions.push(
        this.handleNewAnagSubject(event.request.subject, groupId, event).pipe(
          switchMap((res) => {
            if (res.partyCompleted) {
              const regexAnagMsgCode = new RegExp(`^${this.validationService.PARTY_CHECK_MSG_CODE}-${role.partyRole}-`);
              this._messages = this.validationService.removeMessageByPattern(regexAnagMsgCode);
              this.setmessages(this._messages);
            }
            const subject = event.request.subject;
            return this.callPartyCompletedForOtherRolesWithSameObjectId(this.lifeRoleService.getRoleDescription(new PolicyRole(role.partyRole, subject)));
          })
        ).subscribe()
      );
    }
  }

  protected handleNewAnagSubject(value: Subject, groupId: string, event: any): Observable<{partyCompleted: boolean}> {
    this.oldAnagValue = value; // Workaround: Each time the modal is opened, an event is triggered
    const role = this.getRoleGroup(groupId).value.roleObj;
    const parentId = this.getParentId(groupId);
    let parentRole = null;
    let isAlreadyPresent = false;

    const params = {
      dateOfBirth: value.dateOfBirth,
      personType: value.personType.codice,
      objectId: value.objectId,
      partyRole: role.partyRole
    };

    // if parent has linkedRoles we have to check if the current subject was already insered
    if (parentId !== null && parentId !== '') {
      parentRole = this.getSubjectGroup(parentId, 0).get('val').value;
      if (parentRole.linkedSubjectRoles == null) {
        parentRole.linkedSubjectRoles = [];
      } else if (parentRole.linkedSubjectRoles.length > 0) {
        isAlreadyPresent = !!parentRole.linkedSubjectRoles
        .find(linkedRole => (linkedRole.objectId === params.objectId && linkedRole.partyRole === params.partyRole)
        );
      }
    }

    if (!isAlreadyPresent) {
      this.doActionWhenSubjectReceived(value, role.partyRole);
      return this.getLinkedRoles(params).pipe(
        switchMap(response => {
          if (!!response) {
            // rebuild displayed roles with the new roles inserted after the current role
            this.cleanLinkedForms(groupId);

            // if current subject has subroles, then show them
            if (response.subjectRole !== null && response.subjectRole !== undefined) {
              if (!!response.subjectRole.linkedSubjectRoles && response.subjectRole.linkedSubjectRoles.length > 0) {
                this.insertForms(response.subjectRole.linkedSubjectRoles, groupId);
              }
            }

            let pr = this.lifeRoleService.getRoleDescription(new PolicyRole(role.partyRole, value));
            pr = this.lifeRoleService.createPolicyRole(pr, this.getSubjectGroup(groupId, event.index).get('val'));

            // if current role has a parent, then add itself to the list
            if (parentRole !== null && parentRole !== '') {
              if (parentRole.linkedSubjectRoles == null) {
                parentRole.linkedSubjectRoles = [];
              }
              parentRole.linkedSubjectRoles.push(pr);
            }

            // validate current role
            this.checkSubjectEqualToHolder(pr.partyRole, pr.objectId);

            return this.checkPartyCompleted(value, pr, event);
          }
        }));

    } else {
      this._messages = [this.translateService.getImmediate('lic_SubjectXalreadyselectedfortherole', { subject: value.nominative })];
      this.setmessages(this._messages);
      return of(null);
    }
  }

  protected checkPartyCompleted(value: Subject, pr: PolicyRole, event: any): Observable<{partyCompleted: boolean}> {
    return this.lifeRoleService.checkPartyCompleted(
      Number(value.objectId),
      Number(value.idLatestPhotos),
      Number(pr.partyRole),
      null,
      Number(this.getIdPvForAnag())
    ).pipe(
      switchMap(responsePartyComplete => {
        let policyRolesFilled = this.lifeRoleService.getPolicyRolesFilled(this.validationService);
        // if i'm editing a role, i have to remove it from the list of roles filled
        if (!!event.editCallback) {
          policyRolesFilled = policyRolesFilled.filter(policyRole => policyRole.partyRole !== pr.partyRole);
        }
        return this.validationService.checkRole(
          pr,
          event.index,
          policyRolesFilled,
          this.policyService.isFromPreventive
        ).pipe(
          map(responseCheck => {
            return this.validationService.mapResponsePartyCompleteToCheck(responsePartyComplete, responseCheck, pr);
          })
        );
      }),
      switchMap((responseMessages) => {
        return this.addAnagMessagesAndReturnObsForPartyCompleted(responseMessages, pr);
      })
    );
  }

  protected callPartyCompletedForOtherRolesWithSameObjectId(pr: PolicyRole): Observable<any> {
    const benefRolesFilled = this.beneficiaryService.getAllRelatedSubjects().map(rr => new PolicyRole(rr.roleCode, rr.subject));
    return combineLatest(
      this.lifeRoleService.getAllPolicyRolesFilled(this.validationService).concat(benefRolesFilled)
      .filter(policyRole => policyRole.partyRole !== pr.partyRole && policyRole.objectId === pr.objectId)
      .map((policyRole) => {
        const subject = this.lifeRoleService.getStoredSubject(policyRole.objectId);
        if (benefRolesFilled.includes(policyRole)) {
          const equalRoleOfRolesPage = this.lifeRoleService.getPolicyRolesFilled(this.validationService).find(p => p.objectId === policyRole.objectId);
          // calling checkPartyCompleted for the beneficiary role and related roles
          return this.checkPartyCompletedBenef(subject, policyRole.partyRole, null, equalRoleOfRolesPage);
        }
        const regexAnagMsgCode = new RegExp(`^${this.validationService.PARTY_CHECK_MSG_CODE}-${policyRole.partyRole}-`);
        this._messages = this.validationService.removeMessageByPattern(regexAnagMsgCode);
        this.setmessages(this._messages);
        // calling checkPartyCompleted for the policy roles filled with the same object ID
        return this.checkPartyCompleted(subject, policyRole, { index: null, editCallback: true });
      })
    ).pipe(tap((res) => {
      console.log(res);
    }));
  }

  public openInsured(event: any) {
    this.eventPropagation.emit(event);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).pipe(
        switchMap(value => {
          if (value != null) {
            return this.handleNewInsured(value, Roles.INSURED);
          }
        })
      )
      .subscribe()
    );
  }

  public editAnagInsured(event: any, insuredControl = 'insured') {
    let policyInsured = this.lifeRoleService.getPolicyInsured();
    if (event.subject != null) {
      // first i'll delete the role and then i'll add with the changed one
      let insuredCode = Roles.INSURED;
      if (insuredControl === 'insured') {
        this.resetInsured();
      } else if (insuredControl === 'secondInsured') {
        this.resetSecondInsured();
        insuredCode = Roles.SECOND_INSURED;
        policyInsured = this.lifeRoleService.getSecondPolicyInsured();
      }
      return this.handleNewInsured(event.subject, insuredCode, true).pipe(
        switchMap(() => this.callPartyCompletedForOtherRolesWithSameObjectId(policyInsured))
      ).subscribe();
    }
  }

  protected handleNewInsured(value: Subject, insuredCode: Roles, editingSubj = false): Observable<any> {
    this.doActionWhenSubjectReceived(value, insuredCode);
    let insuredPolicyRole = null;
    if (insuredCode === Roles.INSURED) {
      this.lifeRoleService.setPolicyInsured(value);
      this.policyRole = this.lifeRoleService.createPolicyRole(this.lifeRoleService.getPolicyInsured(), this.form.controls.insured);
      insuredPolicyRole = this.lifeRoleService.getRoleDescription(this.lifeRoleService.getPolicyInsured());
    } else if (insuredCode === Roles.SECOND_INSURED) {
      this.lifeRoleService.setSecondPolicyInsured(value);
      this.policyRole = this.lifeRoleService.createPolicyRole(this.lifeRoleService.getSecondPolicyInsured(), this.form.controls.secondInsured);
      insuredPolicyRole = this.lifeRoleService.getRoleDescription(this.lifeRoleService.getSecondPolicyInsured());
    }
    if (!editingSubj && this.policyService.mainProposal.quote.product.beneficiaries.length === 0) {
      this.policyService.mainProposal.proposal.beneficiaryData = null;
    }
    return this.checkPartyCompleted(
      value,
      insuredPolicyRole,
      { index: null }
    );
  }

  public openSecondInsured(event: Event) {
    this.eventPropagation.emit(event);
    this.$subscriptions.push(
      this.lifeRoleService.getObservable().pipe(distinctUntilChanged(), take(1)).pipe(
        switchMap(value => {
          if (value != null) {
            return this.handleNewInsured(value, Roles.SECOND_INSURED);
          }
        })
      ).subscribe()
    );
  }

  public doActionWhenSubjectReceived(value, roleCode) {
    const regexAnagMsgCode = new RegExp(`^${this.validationService.PARTY_CHECK_MSG_CODE}-${roleCode}-`);
    this._messages = this.validationService.removeMessageByPattern(regexAnagMsgCode);
    this.setmessages(this._messages);
    switch (roleCode) {
      case Roles.CONTRACTOR: {
        // removes the messages with anag validation
        this._messages = this.validationService.removeMessage('anag_holder').concat(this.validationService.removeMessage('holder'));
        this.setmessages(this._messages);
        break;
      }
      case Roles.ADERENTE: {
        // removes the messages with anag validation
        this._messages = this.validationService.removeMessage('anag_holder').concat(this.validationService.removeMessage('holder'));
        this.setmessages(this._messages);
        break;
      }
      case Roles.INSURED: {
        // removes the messages with anag validation
        this._messages = this.validationService.removeMessage('anag_insured').concat(this.validationService.removeMessage('insured'));
        this.setmessages(this._messages);
        break;
      }
      case Roles.SECOND_INSURED: {
        // removes the messages with anag validation
        this._messages = this.validationService.removeMessage('anag_secondInsured').concat(this.validationService.removeMessage('secondInsured'));
        this.setmessages(this._messages);
        break;
      }
      default: {
        this._messages = this.validationService.removeMessage('allRoles');
        this.setmessages(this._messages);
        break;
      }
    }

    console.log(value);
  }

  public getAllRoles() {
    this.$subscriptions.push(
      this.lifeRoleService.getRoles(
        this.policyRole,
        this.policyService.isFromPreventive,
        this.policyService.isFromQuoteModification,
        this.policyService.idMasterPolicy
        ).pipe(
          catchError(error => throwError(error))
        )
        .subscribe((res) => {
          let role: PolicyRole;
          this.lifeRoleService.policyRoles = [];
          res.subjectRole.sort((a, b) => a.partyRole - b.partyRole);
          res.subjectRole.map((e) => {
            if (!this.lifeRoleService.isPolicyHolder(e.partyRole)) {
              role = this.lifeRoleService.getRoleDescription(e);
              this.lifeRoleService.policyRoles.push(role);
              this.enableCheckbox(role);
            }
          });
          this.roles = this.lifeRoleService.policyRoles;
          this.createFormElement();
          this.setCheckBoxValueBasedOnRole();
          this.eventPropagation.emit('stopLoader');
        })
    );
  }

  public getDepth(groupId: string): number {
    const MAX = 10;
    let depth = 0;
    let parentId = this.getParentId(groupId);
    while (parentId !== null && depth < MAX) {
      parentId = this.getParentId(parentId);
      depth++;
    }
    return depth;
  }

  public delete(groupId: string, event: { index: string; request: {partyRole: Roles} }) {
    const parentId = this.getParentId(groupId);
    const regexAnagMsgCode = new RegExp(`^${this.validationService.PARTY_CHECK_MSG_CODE}-${event.request.partyRole}-`);
    this._messages = this.validationService.removeMessageByPattern(regexAnagMsgCode).concat(this.validationService.removeMessage(this.msgCode(parentId, event.request.partyRole, event.index)));
    this.setmessages(this._messages);
    this.validationService.deleteFilledRole(event.request.partyRole, event.index);
    this.removeRoleFromForm(parentId, event);
    this.cleanLinkedForms(groupId);
  }

  private removeRoleFromForm(parentId: string, event: { index: string; request: { partyRole: Roles; }; }) {
    if (parentId != null) {
      const parentRole = this.getRoleGroup(parentId).get('roleList').get('0').get('val').value;
      parentRole.linkedSubjectRoles.splice(event.index, 1);
    }
  }

  public createFormElement() {
    let filterRole = [];
    this.roles.map((role) => {

        if (this.lifeRoleService.storedPolicyRoles != null) {
          filterRole = this.lifeRoleService.storedPolicyRoles.filter((subj) => role.partyRole === subj.partyRole);
        }
        const subjArray = new UntypedFormArray([]);
        this.eventPropagation.emit('startLoader');
        const groupId = this.newGroupId();
        const roleGroup = new UntypedFormGroup({
          roleObj: new UntypedFormControl(role),
          groupId: new UntypedFormControl(groupId),
          roleId: new UntypedFormControl(role.partyRole),
          maxInstances: new UntypedFormControl(role.maxInstances),
          presetValue: new UntypedFormControl(),
          roleList: subjArray
        });
        if (filterRole.length > 0) {
          filterRole.map((sbj) => {
            const subject = new UntypedFormGroup({
                val : new UntypedFormControl(sbj),
                perc: new UntypedFormControl(sbj.percentage, [
                  Validators.max(100),
                  Validators.min(!isNaN(+role.minPercentage) ? +role.minPercentage : 1)
                ])
              });
            subjArray.push(subject);
            });
          this.allRoles.push(roleGroup);

          filterRole.map(sbj => {

            const params = {
              dateOfBirth: sbj.dateOfBirth,
              personType: sbj.personType,
              objectId: sbj.objectId,
              partyRole: sbj.partyRole
            };

            this.$subscriptions.push(
              this.getLinkedRoles(params).subscribe(response => {
                if (!!response && response.subjectRole !== null && response.subjectRole !== undefined) {

                  const linkedSubjectRoles = response.subjectRole.linkedSubjectRoles;

                  if (linkedSubjectRoles !== null && linkedSubjectRoles !== undefined && linkedSubjectRoles.length > 0) {
                    this.insertForms(linkedSubjectRoles, groupId);

                    if (sbj.linkedSubjectRoles !== null && sbj.linkedSubjectRoles !== undefined) {
                      this.loadSubjectList(sbj.linkedSubjectRoles, roleGroup.controls.groupId.value);
                    }
                  }
                }
              })
            );
          });
      } else {
        this.allRoles.push(this.createEmptyGroup(role));
      }
        this.form.addControl('allRoles', this.allRoles);
      });
  }

  public resetHolder() {
    if (!this.disableProductSelect) {
      this.valProd = null;
      this.lifeRoleService.cleanPolicyHolder();
      this.policyService.setInsuredPresent(false);
    }
    this.policyService.cleanProposal(this.disableProductSelect);
    this.lifeRoleService.resetService();
    this._messages = this.validationService.clean();
    this.setmessages(this._messages);
    this.validationService.policyRolesFilled = new Map();
    this.form.controls.holder.setValue(null);
    this.form.controls.insured.setValue(null);
    this.lifeRoleService.cleanInsured();
    this.form.controls.secondInsured.setValue(null);
    this.lifeRoleService.cleanSecondInsured();
    this.policyService.cleanQuotationMessages();
    this._blockingMsgs = [];
    this.setblockingMsgs(this._blockingMsgs);
    this.policyService.setSecondInsuredPresent(false);
    this.oldAnagHolderValue = undefined;
    this.enablePresetDelegato = false;
    this.enablePresetTutoreLegale = false;
    this.enablePresetTerzoPagatore = false;
    this.buildForm();
  }

  public changeProd(e: any) {
    if (this.cache) {
      this.cache.clearAll();
    }
    this.eventPropagation.emit('startLoader');
    this.changeProdWithCallback(e, null);
  }

  public changeProdWithCallback(e: any, callback: any) {
    this._blockingMsgs = [];
    this.setblockingMsgs(this._blockingMsgs);
    this.policyService.cleanQuotationMessages();

    if (!this.policyService.isFromAuth && !this.policyService.isFromQuoteModification && !this.policyService.isFromBuyQuote
        && !this.policyService.isFromProposalModification) {
      this.policyService.cleanProposal();
      this.lifeRoleService.setCosigned(null);
      this.valProd = e.target.value;
      this.policyService.createProduct();
    } else {
      // se arrivo da auth/modifica o acquista preventivo ho già il prodotto e non devo ricrearlo
      // oppure se arrivo da modifica proposta indefinita
      this.valProd = this.policyService.mainProposal.quote.product.code;
    }

    if (this.valProd !== '') {
      this.policyService.mainProposal.quote.product.code = this.valProd;
      if (!this.policyService.productFromIDD && this.policyService.isFromBuyQuote) {
        this.policyService.productFromIDD = {
          code: this.policyService.mainProposal.quote.product.code,
          description: this.policyService.mainProposal.quote.product.description
        } as IDDProduct;
      }
      this.iddProductService.setIddProduct(this.policyService.productFromIDD);
      this.policyService.mainProposal.proposal.iddAdequateWarranties = this.iddProductService.getAdequateWarranties();

      this.$subscriptions.push(
        queue.schedule(() =>
          this.policyService.saveProposal().pipe(
            switchMap((response: any) => {
              /* La gestione qui sotto adrebbe centralizzata in quanto replicata dalla pagina di quotazione */
              response.output.proposal.quotationMessages.map((act) => {
                if (!this.actionService.checkIsAlert(act)) {
                  this.checkAction(true, true, act, '0');
                } else { this._warningMsgs.push(act.messageDescription); }
              });

              this.setInsuredLogic(response.output.quote.product);
              this.setSecondInsuredLogic(response.output.quote.product);
              if (!this.showSecondInsured) {
                this.form.controls.secondInsured.setValue(null);
                this.lifeRoleService.cleanSecondInsured();
              }
              this._messages = this.validationService.removeMessage('product');
              if (this.lifeRoleService.getPolicyInsured() == null) {
                this._messages = this.validationService.removeMessage('insured');
              }
              this.setmessages(this._messages);
              // call checkPartyCompleted for the policy roles filled with the same object ID as the policy holder on change product
              if (!!this.lifeRoleService.getPolicyHolder()) {
                const subject = this.lifeRoleService.getStoredSubject(this.lifeRoleService.getPolicyHolder().objectId);

                return combineLatest([
                  this.getCheckPartyCompletedForInsured(subject),
                  this.callPartyCompleteForCheckboxRoles(subject, false),
                  of(response)
                ]);
              } else {
                return combineLatest([of(null), of(null), of(response) ]);
              }

            }),
            switchMap(([partyCompleteOnInsured, partyCompleteOnCheckbox, response]) => {
              return of(response);
            })
          ).subscribe(response => {
            if (callback != null) {
              callback(response);
            }
            this.eventPropagation.emit('stopLoader');
          }, err => {
            console.error(err);
            this.eventPropagation.emit('stopLoader');
          })
      )
    );
    } else {
      console.error('ERRORE: non trovo il prodotto');
    }
  }

  public setInsuredLogic(p: any) {
    if ( parseInt(p.assets[0].minInstances, 10) === 1 &&  parseInt(p.assets[0].maxInstances, 10) === 1) {
/*    0 = bene ass fisso
      1 = multibene
      2 = nessun bene */
      if (2 !== parseInt(p.assetManagementMode, 10)) {
        this.policyService.setInsuredPresent(true);
        if (3 === parseInt(p.insuredAsPolicyHolder, 10)) {
          this.policyService.setInsuredLogic(Insured.COULD_BE_EQUAL);
        } else if (0 === parseInt(p.insuredAsPolicyHolder, 10)) {
          this.policyService.setInsuredLogic(Insured.MUST_NOT_BE_EQUAL);
        } else if (2 === parseInt(p.insuredAsPolicyHolder, 10)) {
          this.policyService.setInsuredLogic(Insured.MUST_BE_EQUAL);
        } else {
          this.policyService.setInsuredLogic(Insured.NOT_SHOWED);
        }
      } else {
        this.policyService.setInsuredLogic(Insured.MUST_BE_EMPTY);
      }
    } else {
      this.policyService.setInsuredPresent(false);
    }
  }

  public setSecondInsuredLogic(p: Product) {
    this.policyService.setSecondInsuredPresent(false);
    (p.customProperties && p.customProperties).forEach( customProperty => {
      if (customProperty && '_V2ASS' === customProperty.code && customProperty.value === 'true') {
        this.policyService.setSecondInsuredPresent(true);
      }
    });
  }

  public getPolicyRolesFromForm(): PolicyRole[] {
    const policyRoleArray = [];
    const formValue = this.form.value;

    if (!!formValue.holder) {
      policyRoleArray.push(new PolicyRole(Roles.CONTRACTOR, this.lifeRoleService.getStoredSubject(formValue.holder.objectId)));
    }
    if (!!formValue.insured) {
      policyRoleArray.push(new PolicyRole(Roles.INSURED, this.lifeRoleService.getStoredSubject(formValue.insured.objectId)));
    }
    if (!!formValue.secondInsured) {
      policyRoleArray.push(new PolicyRole(Roles.SECOND_INSURED, this.lifeRoleService.getStoredSubject(formValue.secondInsured.objectId)));
    }
    if (this.selectedValueDelegato === 'Yes') {
      this.setRoleWithHolderId(Roles.DELEGATE);
    }
    if (this.selectedValueTerzoPagatore === 'Yes') {
      this.setRoleWithHolderId(Roles.THIRDPAYER);
    }
    if (this.selectedValueTutoreLegale === 'Yes') {
      this.setRoleWithHolderId(Roles.LEGAL_GUARDIAN);
    }


    if (!!formValue.allRoles && formValue.allRoles.length > 0) {
      formValue.allRoles.forEach(element => {
        if (!!element.roleList && element.roleList.length > 0) {
          element.roleList.forEach(roleContainer => {
            if (!!roleContainer.val) {
              const sbj = this.lifeRoleService.getStoredSubject(roleContainer.val.objectId);
              const vectorOfRelatedRoles = [];
              if (!!roleContainer.val.linkedSubjectRoles && roleContainer.val.linkedSubjectRoles.length > 0) {
                roleContainer.val.linkedSubjectRoles.forEach(relatedRole => {
                  vectorOfRelatedRoles.push(
                    new PolicyRole(
                      relatedRole.partyRole,
                      this.lifeRoleService.getStoredSubject(relatedRole.objectId)
                    )
                  );
                });
              }
              const finalRole = new PolicyRole(roleContainer.val.partyRole, sbj);
              finalRole.linkedSubjectRoles = vectorOfRelatedRoles;
              finalRole.percentage = roleContainer.perc;
              finalRole.showPercentage = element.roleObj.showPercentage;
              policyRoleArray.push(finalRole);
            }
          });
        }
      });
    }

    return policyRoleArray;
  }

  public resetInsured() {
    this.form.controls.insured.setValue(null);
    this.lifeRoleService.cleanInsured();
    this._messages = this.validationService.removeMessage('insured');
    const regexAnagMsgCode = new RegExp(`^${this.validationService.PARTY_CHECK_MSG_CODE}-${Roles.INSURED}-`);
    this._messages = this.validationService.removeMessageByPattern(regexAnagMsgCode);
    this.setmessages(this._messages);
  }

  public resetSecondInsured() {
    this.form.controls.secondInsured.setValue(null);
    this.lifeRoleService.cleanSecondInsured();
    this._messages = this.validationService.removeMessage('secondInsured');
    const regexAnagMsgCode = new RegExp(`^${this.validationService.PARTY_CHECK_MSG_CODE}-${Roles.SECOND_INSURED}-`);
    this._messages = this.validationService.removeMessageByPattern(regexAnagMsgCode);
    this.setmessages(this._messages);
  }

  public onSubmit() {
    if (!!this.validationService.getAnagValidationMessages().length) {
      return ;
    }
    this.eventPropagation.emit('startLoader');
    this.lifeSessionService.productCode = this.valProd;
    this._messages = [].concat(this.validationService.clean());
    this.setmessages(this._messages);
    this._warningMsgs = [];
    this.setwarningMsgs(this._warningMsgs);
    this._authoMsgs = [];
    this.setauthoMsgs(this._authoMsgs);
    console.log(this.form, 'form');
    this.checkRoles();
    if (this.validationService.isLegal(this.form.value.insured)) {
      this._messages = this.validationService.addMessage('insured', this.translateService.getImmediate('lic_insured_must_be_physical'));
      this.setmessages(this._messages);
    }

    this.checkMandatoryRoles();
    if (this.validationService.messages.length === 0 && this._blockingMsgs.length === 0) {
      this.$subscriptions.push(
        this.validationService.checkRoles(
          this.getPolicyRolesFromForm(),
          this.policyService.isFromPreventive,
          this.policyService.idMasterPolicy
        ).subscribe((el) => {
          el.errorMessages.map((message) => {
            if (!this.validationService.checkIfAddingDuplicateMessages(message.errorDescription)) {
              this._messages = this.validationService.addMessage('allRoles', message.errorDescription);
              this.setmessages(this._messages);
            }
          });
          this.afterCheckSaveProposal();
        })
      );
    } else {
      this.eventPropagation.emit('stopLoader');
    }
  }

  private checkMandatoryRoles() {
    const mandatoryRolesOnCheckboxEnable = [Roles.DELEGATE, Roles.LEGAL_GUARDIAN, Roles.THIRDPAYER];
    mandatoryRolesOnCheckboxEnable.forEach(role => {
      const fondRole = this.form.value && this.form.value.allRoles.find(item => item.roleId === role);
      const isRoleNo = this.getValueOfCheckboxByRoleCode(role);
      if (fondRole && !!fondRole.roleList) {
        const isSubjectUndefined = fondRole.roleList.some(element => element.val === null);

        if (isRoleNo && (fondRole.roleList.length === 0 || isSubjectUndefined)) {
          this._messages = this.validationService.addMessage(
            role,
            this.translateService.getImmediate('lic_role_is_mandatory', {
              role: this.lifeRoleService.getRoleDescription(new PolicyRole(role, {} as any)).partyRoleDescription
            })
          );
          this.setmessages(this._messages);
        }
      }
    });
  }

  private getValueOfCheckboxByRoleCode(role: string): boolean {
    switch (role) {
      case Roles.DELEGATE:
        return this.enablePresetDelegato && this.selectedValueDelegato === 'No';
      case Roles.LEGAL_GUARDIAN:
        return this.enablePresetTutoreLegale && this.selectedValueTutoreLegale === 'No';
      case Roles.THIRDPAYER:
        return this.enablePresetTerzoPagatore && this.selectedValueTerzoPagatore === 'No';
    }
    return false;
  }

  public afterCheckSaveProposal() {
    if (this._messages.length === 0 ) {
      this.saveRoles();
      this.$subscriptions.push(
        this.policyService.saveProposal().subscribe(
        (response: any) => {
          this.goNextPage(response);
        }, err => {
          console.log('Errore leggendo la proposta');
          this.eventPropagation.emit('stopLoader');
        })
      );
    } else {
      this.eventPropagation.emit('stopLoader');
    }
  }

  public goNextPage(response) {
    this.cardsNavigationService.setMessageForPage(this.cardsNavigationService.STEP.SOGGETTI.errorId, this.getAllMessagesForNextPage());
    this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.route);
    this.navigation.emit(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.route);
    this.eventPropagation.emit('stopLoader');
    /* ASMC-2765 -> impedire un reset tornando in questa pagina */
    this.policyService.haveToInitIDD = false;
  }

  public checkRoles() {
    this.checkHolder();
    this.checkProduct();
    this.checkInsured();
    this.checkSecondInsured();
  }

  public checkAction(blocking: boolean, nonBlocking: boolean, action, blockingCode) {
    if (blocking) {
      if (action.derogationLevel === blockingCode) {
          this._blockingMsgs.push(action.messageDescription);
      }
    }
    if (nonBlocking) {
      if (+action.derogationLevel > +blockingCode) {
        if (this.actionService.getWarningErrors(this.operator, action) != null) {
          this._warningMsgs.push(this.actionService.getWarningErrors(this.operator, action));
        }
        if (this.actionService.getAuthoErrors(this.operator, action) != null) {
          this._authoMsgs.push(this.actionService.getAuthoErrors(this.operator, action));
        }
      }
    }
    this.setblockingMsgs(this._blockingMsgs);
    this.setauthoMsgs(this._authoMsgs);
    this.setwarningMsgs(this._warningMsgs);

  }

  public checkProduct() {
    if (this.form.getRawValue().product == null) {
      this._messages = this.validationService.addMessage('product', this.translateService.getImmediate('lic_mandatory_product'));
    } else {
      this._messages = this.validationService.removeMessage('product');
    }
    this.setmessages(this._messages);
  }

  public checkInsured() {
    const insuredLogic = this.policyService.getInsuredLogic();
    const insuredValue = this.form.value.insured ? this.form.value.insured.objectId : null;
    if (this.showInsured && !insuredValue) {
      this._messages = this.validationService.addMessage('insured', this.translateService.getImmediate('lic_mandatory_insured'));
    } else if (this.showInsured && (undefined !== insuredLogic)) {
      if (Insured.MUST_NOT_BE_EQUAL === insuredLogic) {
        if (insuredValue === this.form.value.holder.objectId) {
          this._messages = this.validationService.addMessage('insured', this.translateService.getImmediate('lic_InsuredNotEqualHolder'));
        }
      } else if (Insured.MUST_BE_EMPTY === insuredLogic || Insured.NOT_SHOWED === insuredLogic) {
        this._messages = this.validationService.removeMessage('insured');
      } else if (Insured.COULD_BE_EQUAL === insuredLogic || Insured.MUST_BE_EQUAL === insuredLogic) {
        if (!insuredValue) {
          this._messages = this.validationService.addMessage('insured', this.translateService.getImmediate('lic_mandatory_insured'));
        }
      }
    }
    this.setmessages(this._messages);
  }

  public checkSecondInsured() {
    const insuredValue = this.form.value.secondInsured ? this.form.value.secondInsured.objectId : null;
    if (this.policyService.isSecondInsuredPresent() && !insuredValue) {
      this._messages = this.validationService.addMessage(
        'secondInsured',
        this.translateService.getImmediate('lic_Selectasecondinsured')
      );
    }
    if (this.validationService.isLegal(insuredValue)) {
      this._messages = this.validationService.addMessage(
        'secondInsured',
        this.translateService.getImmediate('lic_Secondinsuredmustbeaphysicalperson')
      );
    }
    this.setmessages(this._messages);
  }

  public checkHolder() {
    if (this.form.value.holder == null) {
      this._messages = this.validationService.addMessage('holder', this.translateService.getImmediate('lic_select_policyholder'));
    } else if (this.checkHolderProductCompatibility()) {
      this._messages = this.validationService.removeMessage('holder');
    }
    this.setmessages(this._messages);
  }

  private checkHolderProductCompatibility(): boolean {

    if (!this.form.value.holder || !this.form.value.holder.objectId || this.policyService.productHolderType == null) {
      return true;
    }

    const PRODUCT_FOR_PHYSICAL_HOLDER = 0;
    const PRODUCT_FOR_LEGAL_HOLDER = 1;
    const PRODUCT_FOR_BOTH_LEGAL_AND_PHYSICAL_HOLDER = 2;

    const holderTypeAdmitted = this.policyService.productHolderType;
    if (PRODUCT_FOR_BOTH_LEGAL_AND_PHYSICAL_HOLDER === holderTypeAdmitted) {
      return true;
    }

    const isLegal = this.validationService.isLegal(this.form.value.holder);
    if (isLegal && PRODUCT_FOR_PHYSICAL_HOLDER === holderTypeAdmitted) {
        this._messages = this.validationService.addMessage(
          'holder',
          this.translateService.getImmediate('lic_holder_physical_person_req')
        );
        this.setmessages(this._messages);
        return false;
    }

    if (!isLegal && PRODUCT_FOR_LEGAL_HOLDER === holderTypeAdmitted) {
        this._messages = this.validationService.addMessage(
          'holder',
           this.translateService.getImmediate('lic_holder_legal_person_req')
        );
        this.setmessages(this._messages);
        return false;
    }
    return true;
  }


  public saveRoles() {
    const lifePartyArray = [];
    const policyRolesFromForm = this.getPolicyRolesFromForm();
    this.lifeRoleService.storePolicyRoles(policyRolesFromForm);
    policyRolesFromForm.map((el) => lifePartyArray.push(this.lifeRoleService.policyRoleToLifeParty(el)));
    this.policyService.mainProposal.proposal.lifeParty = lifePartyArray;
    console.log(this.policyService.mainProposal.proposal.lifeParty, 'in proposal');
  }

  public trackByFn(index, item) {
    return index;
  }

  protected createEmptyGroup(role: PolicyRole): UntypedFormGroup {
    this.lifeRoleService.getRoleDescription(role);
    const subSubject = new UntypedFormArray([]);
    const subSubjectGroup =  new UntypedFormGroup({
      val : new UntypedFormControl(null),
      perc: new UntypedFormControl(null, [Validators.max(100), Validators.min(role.minPercentage)])
    });
    subSubject.push(subSubjectGroup);
    const subject = new UntypedFormGroup({
      val : new UntypedFormControl(!!role.name || role.surname || role.companyName ? role : null),
      perc: new UntypedFormControl(!!role.percentage ? role.percentage : null, [
        Validators.max(100),
        Validators.min(!isNaN(+role.minPercentage) ? +role.minPercentage : 1)
      ])
    });
    const roleGroup = new UntypedFormGroup({
      roleObj: new UntypedFormControl(role),
      groupId: new UntypedFormControl(this.newGroupId()),
      roleId: new UntypedFormControl(role.partyRole),
      maxInstances: new UntypedFormControl(role.maxInstances),
      presetValue: new UntypedFormControl(),
      roleList: new UntypedFormArray([subject])
    });
    return roleGroup;
  }

  protected doCommonInit() {
    this.isFromAuth = this.policyService.isFromAuth;
    if (this.policyService.isFromIDD && this.policyService.haveToInitIDD) {
      this.initIDD();
    } else if (this.isFromAuth || this.policyService.isFromQuoteModification || this.policyService.isFromBuyQuote) {
      this.initRoles();
    } else {
      this.onInitEndMethod();
    }
  }

  protected getLinkedRoles(role: any): Observable<any> {
    return this.lifeRoleService.getLinkedRoles(role, this.policyService.isFromPreventive);
  }

  private msgCode(parentId, partyRole, index) {
    return (parentId !== null ? '@' + parentId + '/' : '') + partyRole + '-' + index;
  }

  private newGroupId(): string {
    this.lastGroupId++;
    return this.lastGroupId.toString();
  }

  private cleanLinkedForms(parentId: string): void {
    for (let j = this.allRoles.controls.length - 1; j >= 0; j--) {
      const control = this.allRoles.controls[j] as UntypedFormGroup;
      if (control.contains('parentId') && control.get('parentId').value === parentId) {
        this.allRoles.controls.splice(j, 1);
      }
    }
    this._messages = this.validationService.removeMessageByPattern(new RegExp('@' + parentId + '/.*?-.*?'));
    this.setmessages(this._messages);
    this.oldAnagValue = undefined;
  }

  private insertForms(roles: PolicyRole[], parentGroupId: string): void {
    for (let i = 0; i < this.allRoles.controls.length; i++) {
      if (this.allRoles.controls[i].get('groupId').value === parentGroupId) {
        for (let j = 0; j < roles.length; j++) {
          const newIndex = i + 1 + j;
          const g = this.createEmptyGroup(roles[j]);
          g.addControl('parentId', new UntypedFormControl(parentGroupId));
          this.allRoles.controls.splice(newIndex, 0, g);
        }
        return;
      }
    }
  }

  private loadSubjectList(linkedRoles: PolicyRole[], groupId: string): void {
    for (const form of this.allRoles.controls as UntypedFormGroup[]) {
      const roles = linkedRoles.filter(r =>  r.partyRole === form.get('roleId').value);
      if (form.contains('parentId') && form.get('parentId').value === groupId && roles.length > 0) {
        const list = form.get('roleList') as UntypedFormArray;
        list.clear();
        roles.map(sbj => {
          const subject = new UntypedFormGroup({
            val : new UntypedFormControl(sbj),
            perc: new UntypedFormControl(null, [Validators.max(100), Validators.min(!isNaN(+sbj.minPercentage) ? +sbj.minPercentage : 1)])
          });
          list.push(subject);
        });
      }
    }
  }

  private getParentId(groupId: string): string {
    return this.getRoleGroup(groupId).contains('parentId') ? this.getRoleGroup(groupId).get('parentId').value : null;
  }

  protected enableCheckbox(role: PolicyRole, holder?: PolicyRole) {
    const policyHolder = this.lifeRoleService.getPolicyHolder() || holder;
    if (policyHolder.personType === SubjectConstants.PHYSICAL_SUBJECT && this.isMaggiorenne(policyHolder.dateOfBirth)) {
      switch (role.partyRole) {
        case Roles.DELEGATE: {
          this.enablePresetDelegato =
          this.systemPropertiesService.getStoredSystemProp(SystemProperties.PREVAL_DELEGATE) === 'SI' ? true : false;
          this.selectedValueDelegato = this.enablePresetDelegato ? 'Yes' : 'No';
          break;
        }
        case Roles.LEGAL_GUARDIAN: {
          this.enablePresetTutoreLegale =
          this.systemPropertiesService.getStoredSystemProp(SystemProperties.PREVAL_LEGALGUARDIAN) === 'SI' ? true : false;
          this.selectedValueTutoreLegale = this.enablePresetTutoreLegale ? 'Yes' : 'No';
          break;
        }
        case Roles.THIRDPAYER: {
          this.enablePresetTerzoPagatore =
          this.systemPropertiesService.getStoredSystemProp(SystemProperties.PREVAL_THIRDPAYER) === 'SI' ? true : false;
          this.selectedValueTerzoPagatore = this.enablePresetTerzoPagatore ? 'Yes' : 'No';
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  isCheckBoxRolePreset(role: Roles) {
    switch (role) {
      case Roles.DELEGATE:
        return this.enablePresetDelegato;
      case Roles.LEGAL_GUARDIAN:
        return this.enablePresetTutoreLegale;
      case Roles.THIRDPAYER:
        return this.enablePresetTerzoPagatore;
      default:
        return false;
    }
  }

  isCheckBoxRoleSelectedAsHolder(role: Roles) {
    switch (role) {
      case Roles.DELEGATE:
        return this.isCheckBoxRolePreset(role) && this.selectedValueDelegato === 'Yes';
      case Roles.LEGAL_GUARDIAN:
        return this.isCheckBoxRolePreset(role) && this.selectedValueTutoreLegale === 'Yes';
      case Roles.THIRDPAYER:
        return this.isCheckBoxRolePreset(role) && this.selectedValueTerzoPagatore === 'Yes';
    }
  }

  onClickCheckBoxRole(role: Roles, groupId: any) {
    switch (role) {
      case Roles.DELEGATE:
        this.handleCLickEventOnRadioButtonRoles(this.selectedValueDelegato, role, groupId);
        break;
      case Roles.LEGAL_GUARDIAN:
        this.handleCLickEventOnRadioButtonRoles(this.selectedValueTutoreLegale, role, groupId);
        break;
      case Roles.THIRDPAYER:
        this.handleCLickEventOnRadioButtonRoles(this.selectedValueTerzoPagatore, role, groupId);
        break;
    }
  }

  protected handleCLickEventOnRadioButtonRoles(selectedValue: 'No' | 'Yes', roleCode: Roles, groupId) {
    if (selectedValue === 'No') {
      this.removeSubjectEqualToHolderWarning(this.getWarningMsgCodeByRoleCode(roleCode));
      this.getRoleList(groupId).get('0').get('val').setValue(null);
      this.showSubjectBoxByRoleCode(roleCode, true);
    } else {
      this.showSubjectBoxByRoleCode(roleCode, false);
      const regexAnagMsgCode = new RegExp(`${roleCode}`);
      this.validationService.removeMessageByPattern(regexAnagMsgCode);
    }
    if (selectedValue === 'Yes') {
      const ev = {
        index : '0',
        request : {partyRole : roleCode}
      };
      this.delete(groupId, ev);
      const ph = this.lifeRoleService.getStoredSubject(this.form.value.holder.objectId);
      this.getRoleList(groupId).get('0').get('val').setValue(new PolicyRole(roleCode, ph));
      const roleValue = this.lifeRoleService.getRoleDescription(this.getPolicyRoleByCodeFromForm(roleCode));
      const subject = this.lifeRoleService.getStoredSubject(roleValue.objectId);
      this.checkPartyCompleted(subject, roleValue, {index: null})
      .pipe(switchMap(() => this.callPartyCompletedForOtherRolesWithSameObjectId(roleValue)))
      .subscribe();
    }
  }

  private showSubjectBoxByRoleCode(roleCode: Roles, boxVisibility: boolean) {
    switch (roleCode) {
      case Roles.DELEGATE:
        this.showSubjectBoxDelegato = boxVisibility;
        break;
      case Roles.LEGAL_GUARDIAN:
        this.showSubjectBoxTutoreLegale = boxVisibility;
        break;
      case Roles.THIRDPAYER:
        this.showSubjectBoxTerzoPagatore = boxVisibility;
        break;
    }
  }

  private getWarningMsgCodeByRoleCode(roleCode: Roles): string {
    switch (roleCode) {
      case Roles.DELEGATE:
        return 'lic_DelegateEqualToPolicyHolderWarning';
      case Roles.LEGAL_GUARDIAN:
        return 'lic_LegalGuardianEqualToPolicyHolderWarning';
      case Roles.THIRDPAYER:
        return 'lic_ThirdPayerEqualToPolicyHolderWarning';
    }
  }

  protected setRoleWithHolderId(roleCode: Roles) {
    const formValue = this.form.value;
    const holderId = formValue.holder.objectId;
    const ph = this.lifeRoleService.getStoredSubject(holderId);
    if (!!formValue.allRoles && formValue.allRoles.length > 0) {
      formValue.allRoles.forEach(element => {
        if (element.roleObj.partyRole === roleCode) {
          if (!!element.roleList && element.roleList.length > 0) {
            element.roleList.forEach(roleContainer => {
              roleContainer.val = new PolicyRole(roleCode, ph);
            });
          }
        }
      });
    }
  }

  protected getPolicyRoleByCodeFromForm(roleCode: string): PolicyRole {
    const formValue = this.form.value;
    if (!!formValue.allRoles && formValue.allRoles.length > 0) {
      const foundRole = formValue.allRoles.find(role => role.roleObj.partyRole === roleCode);
      return foundRole && foundRole.roleList && foundRole.roleList.find(l => l.val.partyRole === roleCode).val || foundRole;
    }
    return null;
  }

  protected setCheckBoxValueBasedOnRole() {
    const holder = this.lifeRoleService.getPolicyHolder();
    const lifeParty = this.policyService.mainProposal.proposal.lifeParty;
    if (lifeParty && lifeParty.length > 0) {
      const delegate = this.policyService.mainProposal.proposal.lifeParty.find(role => role.party.partyRole === Roles.DELEGATE);
      const legalGuardian = this.policyService.mainProposal.proposal.lifeParty.find(role => role.party.partyRole === Roles.LEGAL_GUARDIAN);
      const thirdPayer = this.policyService.mainProposal.proposal.lifeParty.find(role => role.party.partyRole === Roles.THIRDPAYER);

      [delegate, legalGuardian, thirdPayer].filter(valorizedRole => !!valorizedRole).forEach(role => {
        if (!this.isCheckBoxRolePreset(role.party.partyRole as Roles)) {
          return;
        }
        if (role && role.party.objectID !== holder.objectId) {
          this.setValueCheckBox(role.party.partyRole as Roles);
        }
      });
    } else {
      const ph = this.lifeRoleService.getStoredSubject(this.form.value.holder.objectId);
      [Roles.DELEGATE, Roles.LEGAL_GUARDIAN, Roles.THIRDPAYER].forEach(partyRole => {
        if (!this.isCheckBoxRolePreset(partyRole as Roles)) {
          return;
        }
        const role = this.allRoles.controls.find(r => r.value.roleId as Roles === partyRole);
        if (!!role) {
          const groupId = role.value.groupId;
          this.getRoleList(groupId).get('0').get('val').setValue(new PolicyRole(partyRole as Roles, ph));
        }
      });
    }
  }

  setValueCheckBox(role: Roles, value: 'Yes' | 'No' = 'No') {
    switch (role) {
      case Roles.DELEGATE:
        this.selectedValueDelegato = value;
        break;
      case Roles.LEGAL_GUARDIAN:
        this.selectedValueTutoreLegale = value;
        break;
      case Roles.THIRDPAYER:
        this.selectedValueTerzoPagatore = value;
        break;
    }
  }

  public checkSubjectEqualToHolder(role: string, subjectId: string) {
    if (this.isCheckBoxRolePreset(role as Roles) && subjectId === this.lifeRoleService.getPolicyHolderId()) {
      this._warningMsgs.push(this.getWarningMsgCodeByRoleCode(role as Roles));
      this.setValueCheckBox(role as Roles, 'Yes');
    }
    if (!!subjectId) {
      this.validationService.removeMessage(role);
    }
    this.setwarningMsgs(this._warningMsgs);
  }

  public removeSubjectEqualToHolderWarning(warningMsg: string) {
    const index = this._warningMsgs.indexOf(warningMsg);
    if (index >= 0) {
      this._warningMsgs.splice(index, 1);
    }
    this.setwarningMsgs(this._warningMsgs);
  }

  private isMaggiorenne(dateOfBirth: any): boolean {
    let age = 0;
    if (dateOfBirth) {
      const timeDiff = Math.abs(Date.now() - new Date(dateOfBirth).getTime());
      age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      return age >= 18 ? true : false;
    }

  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  protected checkPartyCompletedBenef(subj: Subject, roleCode: Roles, cathegory: string, equalRole: PolicyRole): Observable<{partyCompleted: boolean}> {
    return this.lifeRoleService.checkPartyCompleted(
      Number(subj.objectId),
      Number(subj.idLatestPhotos),
      Number(roleCode),
      null,
      Number(this.getIdPvForAnag())
    ).pipe(
      switchMap(response => {
        return of({errorMessages: [], partycongruent: null}).pipe(
          map(responseCheck => {
            const pr = this.lifeRoleService.getRoleDescription(new PolicyRole(roleCode, subj));
            return this.validationService.mapResponsePartyCompleteToCheck(response, responseCheck, pr);
          })
        );
      }),
      switchMap(resp => {
        return this.addAnagMessagesAndReturnObsForPartyCompleted(resp, equalRole);
      })
    );
  }

  private addAnagMessagesAndReturnObsForPartyCompleted(responseMessages: PartyCheck, pr: PolicyRole) {
    responseMessages.errorMessages
      .forEach((m, i) => {
        this._messages = this.validationService.addMessage(`${this.validationService.PARTY_CHECK_MSG_CODE}-${pr.partyRole}-${i}`, m.errorDescription);
      });

    this.setmessages(this._messages);
    return of({ partyCompleted: responseMessages.partycongruent && responseMessages.errorMessages.length === 0 });
  }

  callPartyCompleteForCheckboxRoles(holderFromIdd: Subject, callEnableCheckBox = true): Observable<any> {
    const policyHolder = this.lifeRoleService.getRoleDescription(new PolicyRole(this.getRoleOfHolder(), holderFromIdd));
    return combineLatest(
      this.lifeSessionService.partyRoles
      .filter(r => [Roles.DELEGATE, Roles.THIRDPAYER, Roles.LEGAL_GUARDIAN].includes(r.codice as Roles))
      .map(r => {
        const role = this.lifeRoleService.getRoleDescription(new PolicyRole(r.codice as Roles, holderFromIdd));
        if (callEnableCheckBox) {
          this.enableCheckbox(role, policyHolder);
        }
        return role;
      }).map((role) => {
          if (this.isCheckBoxRoleSelectedAsHolder(role.partyRole)) {
            return this.checkPartyCompleted(holderFromIdd, role, { index: null, editCallback: true });
          } else {
            const policyRole = this.validationService.policyRolesFilled.get(role.partyRole);
            const subj = policyRole && this.lifeRoleService.getStoredSubject(policyRole.objectId);
            if (!!subj) {
              return this.checkPartyCompleted(subj, policyRole, { index: null, editCallback: true });
            }
          }
          return of(null);
      })
    );
  }

}
