import {Observable, of} from 'rxjs';
import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {AnagStatelessOpPartyEditorService} from './anag-stateless-op-party-editor.service';
import {
  AnagApiAddress,
  AnagApiOtherAddress,
  AnagApiParty,
  AnagPartyKey,
  AnagPartyKeyService,
  AnagPhoneNumber
} from '../../anag-model/anag-domain/anag-api-party';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {
  ANAG_SEX,
  BUSINESS_SECTOR_ENUM,
  DOCUMENT_ISSUING_AUTHORITY_ENUM,
  DOCUMENT_TYPE_ENUM,
  ECONOMIC_ACTIVITY_GROUP_ENUM,
  ECONOMIC_ACTIVITY_SUBGROUP_ENUM,
  GENDER_IDENTITY_ENUM,
  HONORIFIC_TITLE,
  LANGUAGE_ENUM,
  MAIN_CONTACT_ENUM,
  MARITAL_STATUS_ENUM
} from '../../anag-constants/enums-constants';
import {AnagCountry} from '../../anag-model/anag-domain/anag-country';
import {AnagApiEntity, AnagBirthAddress, AnagEntityIta, EntityUtils} from '../../anag-model/anag-api/anag-subject-api';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagPrivacyConsensus} from '../../anag-model/anag-domain/anag-privacy-config';
import {AnagIdentificationEntity} from '../../anag-model/anag-domain/anag-identification-entity';
import {take, tap} from 'rxjs/operators';
import {UntypedFormGroup} from '@angular/forms';
import {ROUTES_FULL_DETAIL} from '../../anag-constants/routes-constants';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {AnagDialogComponent, ModalDialogData} from '../../anag-components/modal/anag-dialog/anag-dialog.component';
import {ModalService} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {
  AnagDocument,
  AnagDrivingLicense,
  AnagDrivingLicenseTypeList
} from '../../anag-model/anag-domain/anag-document';
import {LinkPartyEvent} from '../../anag-components/party-link/party-link.component';
import {MeansOfPaymentAnag} from '../../anag-model/anag-domain/anag-payments';
import {AnagConfiguration} from '../../anag-model/anag-domain/anag-configuration';
import {AnagConfigService} from '../../anag-resources/anag-config.service';
import {AnagDynamicObj} from '../../anag-model/anag-domain/anag-dynamic-obj';
import {AnagPartyPayment} from '../../anag-model/anag-domain/anag-party-payment';
import {CounterTerrorismStatus} from '../../anag-model/anag-api/anag-api-terrorism-config';
import {OperatorLight} from '../../services/external-service';
import {RgiRxUserService} from '@rgi/rx/auth';
import {KeyDataModalComponent} from "../../anag-components/party-create-key-data/key-data-modal/key-data-modal.component";
import {AnagStateManagerKeyData} from "../key-data/anag-state-manager-key-data";
import {KEY_DATA_UPDATE, KeyDataUpdateEvent} from "../../anag-events/anag-event";
import {RgiRxEventService} from "@rgi/rx";
import {AnagTaxClassification, AnagtaxClassificationData} from "../../anag-model/anag-domain/anag-tax-classification";
import { AnagRating } from '../../anag-model/anag-domain/anag-rating';
import {AnagIntPrefixEntity} from "../../anag-model/anag-domain/anag-int-prefix-entity";
import { AnagFormFieldConfig } from '../../anag-model/anag-domain/anag-form-field-config';



export class AnagStatePartyEditor extends State {
  party: AnagApiParty;
  configuration: AnagConfiguration;
  partyKeyConf: AnagPartyKey;
  privacyQuestions: Array<AnagEntityIta>;
  linkTypes: Map<string, Array<AnagIdentificationEntity>> = new Map();
  editorModeEnable = true;
  meansOfPayment: MeansOfPaymentAnag;
  flowData: any;
  redirectToMainFlow = false;
  errors: Array<string> = [];
  blockedFlow = false;
  partyKeyService: AnagPartyKeyService = new AnagPartyKeyService();
  citizenshipPermitTypes: any;
  isUmaPartyHomonymyEnable: boolean;
  isUmaPartyCFDuplicate: boolean;
  isUmaPartyGroupCompanies: boolean;
  drivingLecenseList: AnagDrivingLicenseTypeList[];
  isValidDrivingLecense: boolean = true;
  internationalPrefixes: Array<AnagIntPrefixEntity>;
  lastIdFromDamage: string;
  editResidenceEnable = true;
}

export class AnagStateManagerPartyEditor extends RgiRxLocalStateManager<AnagStatePartyEditor> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelessOps: AnagStatelessOpPartyEditorService,
    protected anagStorage: AnagStorageService,
    protected statelessService: AnagStatelessService,
    protected anagIntegrationService: AnagIntegrationService,
    protected modalService: ModalService,
    protected i18nService: RgiRxTranslationService,
    protected configService: AnagConfigService,
    protected userService: RgiRxUserService,
    public eventService: RgiRxEventService,
  ) {
    super(activeRoute, stateStoreService);
    this.initPartyEditor();
  }

  private initPartyEditor() {
    const partyEditorState = new AnagStatePartyEditor(this.activeRoute.key);
    const routeData = this.activeRoute.getRouteData<AnagRouteData>();
    partyEditorState.editorModeEnable = this.activeRoute.route !== ROUTES_FULL_DETAIL;
    partyEditorState.party = this.statelessService.deepClone(routeData.party);
    partyEditorState.configuration = this.statelessService.deepClone(routeData.configuration);
    partyEditorState.redirectToMainFlow = routeData.redirectToMainFlow;
    partyEditorState.flowData = routeData.flowData;
    partyEditorState.lastIdFromDamage = routeData.lastIdFromDamage;
    partyEditorState.editResidenceEnable = true;
    this.anagStorage.setNodeId(partyEditorState.party.node.identification);
    this.updateState$(this.statelessOps.initPartyEditor$(of(partyEditorState)));
  }

  get isPartyPhysical() {
    return this.getCurrentState().party.personType.codice === '1';
  }

  getEnumsByCode(code: string): Array<AnagEntityIta> {
    return this.anagStorage.getEnumsByCode(code);
  }

  get countriesList(): Array<AnagCountry> {
    return this.anagStorage.countries;
  }

  checkPartyKey(filter: AnagPartyKeyService) {
    return this.statelessOps.checkPartyKey$(filter);
  }

  duplicateParty(filter: AnagApiParty) {
    return this.statelessOps.duplicateParty$(filter);
  }

  getSubjectDetail(idSubject, idNode, nodeCode) {
    return this.statelessOps.getSubjectDetail$(idSubject, idNode, nodeCode);
  }

  getEconomyActivityGroup(subgroup: string) {
    if (!this.anagStorage.getEconomyActivityGroupBySubgroupCode(subgroup)) {
      this.updateState$(this.statelessOps.getEconomyActivityGroup$(subgroup, of(this.getCurrentState())));
    }
  }

  getCitizenshipPermitTypes(country: string) {
    const state = this.getCurrentState();
    this.updateState$(this.statelessOps.getCitizenshipPermitTypes(state, country));
  }


  maintainForm(state, formGroup: UntypedFormGroup, address: AnagBirthAddress) {
    state.party.birthAddress = address;
    return this.getIdDataUpdatedParty(formGroup, state);
  }

  updateIdentificationData(formGroup: UntypedFormGroup, address: AnagBirthAddress) {
    const state = this.getCurrentState();
    const party = state.party;
    // [RCT-6569]
    // BIRTH NAME
    if (formGroup.get('birthName') != null) {
      state.party.birthName = formGroup.get('birthName').value;
      party.birthName = state.party.birthName;
    } else {
      state.party.birthName = undefined;
      party.birthName = state.party.birthName;
    }
    // SEX
    if (formGroup.get('gender') != null) {
      state.party.sex = formGroup.get('gender').value;
      party.sex = state.party.sex;
    } else if (state.party.sex) {
      state.party.sex.codice = '';
      state.party.sex.descrizione = '';
      party.sex = state.party.sex;
    } else {
        state.party.sex = new AnagEntityIta(null, null);
        party.sex = state.party.sex;
    }
    // TITLE
    if (formGroup.get('honorTitle')) {
      state.party.salutation = formGroup.get('honorificTitle').value;
      party.salutation = state.party.salutation;
    } else if (state.party.salutation) {
      party.salutation = state.party.salutation;
    } else {
        state.party.salutation = new AnagEntityIta(null, null);
        party.salutation = state.party.salutation;
    }

    if (formGroup.get('leiCode') != null) {
      state.party.leicode = formGroup.get('leiCode').value;
      party.leicode = state.party.leicode;
    } else {
      state.party.leicode = undefined;
      party.leicode = state.party.leicode;
    }
    // MARITAL STATUS
    if (formGroup.get('maritalStatus')) {
      state.party.maritalStatus = formGroup.get('maritalStatus').value;
      party.maritalStatus = state.party.maritalStatus;
    } else if (state.party.maritalStatus) {
      party.maritalStatus = state.party.maritalStatus;
    } else {
        state.party.maritalStatus = new AnagEntityIta(null, null);
        party.maritalStatus = state.party.maritalStatus;
    }
    // BIRTH DATE
    if (formGroup.get('birthDate')) {
      state.party.dateOfBirth = formGroup.get('birthDate').value;
      party.dateOfBirth = state.party.dateOfBirth;
    } else {
      if (state.party.dateOfBirth) {
         state.party.dateOfBirth = null;
         party.dateOfBirth = state.party.dateOfBirth;
      } else {
        state.party.dateOfBirth = null;
        party.dateOfBirth = state.party.dateOfBirth;
      }
    }
    // GROUP SAE
    if (formGroup.get('economicActivityGroup')) {
      state.party.gruppoAE = formGroup.get('economicActivityGroup').value;
      party.gruppoAE = state.party.gruppoAE;
    } else {
      state.party.gruppoAE = new AnagApiEntity(null, null);
      party.gruppoAE = state.party.gruppoAE;
    }
    // SUBGROUP SAE
    if (formGroup.get('economicActivitySubgroup')) {
      state.party.sottogruppoAE = formGroup.get('economicActivitySubgroup').value;
      party.sottogruppoAE = state.party.sottogruppoAE;
    } else {
      state.party.sottogruppoAE = new AnagApiEntity(null, null);
      party.sottogruppoAE = state.party.sottogruppoAE;
    }
    party.birthAddress = address;
    this.getIdDataUpdatedParty(formGroup, state);
    this.updateState$(of(state));
  }

  private getIdDataUpdatedParty(formGroup: UntypedFormGroup, state: AnagStatePartyEditor) {
    const form = formGroup.getRawValue();
    const formData = this.isPartyPhysical ? form.physicalPartyForm : form.legalPartyForm;
    const party = state.party;
    const partyKeyConf = this.statelessService.deepClone(state.partyKeyConf);
    if (this.isPartyPhysical) {
      party.surname = formData.surname;
      party.name = formData.name;
      party.dateOfBirth = formData.birthDate;
      if (formData.genderIdentity) {
        const selectedGenderIdentity = this.getEnumsByCode(GENDER_IDENTITY_ENUM).find(gender => gender.codice === formData.genderIdentity);
        party.genderIdentity = new AnagEntityIta(selectedGenderIdentity.codice, selectedGenderIdentity.descrizione);
      } else {
        party.genderIdentity = null;
      }
      if (formData.gender) {
        const selectedSex = ANAG_SEX.find(gender => gender.codice === formData.gender);
        // [RQP-7159]
        party.sex = new AnagEntityIta(selectedSex.codice, selectedSex.descrizione);
      } else {
        party.sex = null;
      }
      const citizenship = this.countriesList.find(country => country.abbreviation === formData.citizenship);
      party.citizenship = citizenship ? new AnagEntityIta(citizenship.abbreviation, citizenship.description) : undefined;
      party.maritalStatus = this.getEnumsByCode(MARITAL_STATUS_ENUM).find(status => status.codice === formData.maritalStatus);
      party.sonsNumber = formData.sonsNumber;
      // state.party.politicallyExp = formData.politicallyExp;
      party.citizenshipPermit = formData.citizenshipPermit;
      party.citizenshipExpire = formData.citizenshipExpire;
      if (party.birthAddress && party.birthAddress.adminLevel3) {
        party.cityOfBirth = party.birthAddress.adminLevel3;
      }
      party.politicallyExp = formData.politicallyExp;
    } else {
      party.dateOfBirth = formData.openDate;
      party.denomination = formData.companyName;
      party.corporateSector = this.getEnumsByCode(BUSINESS_SECTOR_ENUM).find(sector => sector.codice === formData.corporateSector);
      party.regCountry = formData.regCountry;
    }
    if (formData.birthName) {
      party.birthName = formData.birthName;
    }
    if(form.leicode) {
      party.leicode = form.leicode;
    }
    const languageSelected = this.getEnumsByCode(LANGUAGE_ENUM).find(language => language.codice === form.language);
    party.language = languageSelected ? languageSelected : null;
    const honorTitleSelected = this.getEnumsByCode(HONORIFIC_TITLE).find(salutation => salutation.codice === formData.honorTitle);
    party.salutation = honorTitleSelected ? honorTitleSelected : null;
    if (form.economicActivitySubgroup) {
      party.sottogruppoAE = EntityUtils.toEngEntity(this.getEnumsByCode(ECONOMIC_ACTIVITY_SUBGROUP_ENUM).find(
        subGroup => subGroup.codice === form.economicActivitySubgroup
      ));
    } else {
      party.sottogruppoAE = null;
    }
    if (form.economicActivityGroup) {
      party.codAteco = EntityUtils.toEngEntity(this.getEnumsByCode(ECONOMIC_ACTIVITY_GROUP_ENUM).find(
        group => group.codice === form.economicActivityGroup
      ));
      party.gruppoAE = null;
    } else {
      party.codAteco = null;
      party.gruppoAE = null;
    }
    partyKeyConf.main = true;
    if (party.partyKey && party.partyKey.length > 0) {
      party.partyKey[0] = partyKeyConf;
    } else {
      party.partyKey.push(partyKeyConf);
    }
    const partyKeyForm = form.partyKeyForm;
    // [RQP-7159]
    if (partyKeyForm.country && (!party.countryOfBirth || party.countryOfBirth.codice !== partyKeyForm.country)) {
      const country = this.anagStorage.getCountryByCode(partyKeyForm.country);
      if (country) {
        party.countryOfBirth = new AnagEntityIta(country.abbreviation, country.description);
      } else {
        party.countryOfBirth = null;
      }
    }
    if (partyKeyForm.country &&
      (partyKeyForm.partyKey1 || partyKeyForm.partyKey2 || partyKeyForm.partyKey3)) {
      if (partyKeyForm.partyKey1) {
        partyKeyConf.key1.value = partyKeyForm.partyKey1;
      }
      if (partyKeyForm.partyKey2) {
        partyKeyConf.key2.value = partyKeyForm.partyKey2;
      }
      if (partyKeyForm.partyKey3) {
        partyKeyConf.key3.value = partyKeyForm.partyKey3;
      }
    }
    state.partyKeyService.partyKey = partyKeyConf;
    return party;
  }

  addTaxClassification(taxClassification: AnagtaxClassificationData) {
    const state = this.getCurrentState();
    const party = state.party;
    if (!party.taxClassifications || party.taxClassifications.length === 0) {
      party.taxClassifications = [];
      const newTaxClassification = new AnagTaxClassification();
      newTaxClassification.regCountry = taxClassification.country ? taxClassification.country.codice : null;
      newTaxClassification.taxClassificationsData = [];
      newTaxClassification.taxClassificationsData.push(taxClassification);
      party.taxClassifications.push(this.statelessService.deepClone(newTaxClassification));
    } else {
      if (taxClassification.country && taxClassification.country.codice) {
        let taxClass = party.taxClassifications.find(taxClass => taxClass.regCountry === taxClassification.country.codice);
        if (taxClass) {
          taxClass.taxClassificationsData.push(this.statelessService.deepClone(taxClassification));
        } else {
          taxClass = new AnagTaxClassification();
          taxClass.regCountry = taxClassification.country ? taxClassification.country.codice : null;
          taxClass.taxClassificationsData = [];
          taxClass.taxClassificationsData.push(this.statelessService.deepClone(taxClassification));
          party.taxClassifications.push(taxClass);
        }
      }
    }
    this.updateState$(of(state));
  }

  updateTaxClassification(editTaxClassificationData: AnagtaxClassificationData, taxClassificationData: AnagtaxClassificationData) {
    const state = this.getCurrentState();
    const party = state.party;
    party.taxClassifications.forEach(taxClass => {
      if (taxClass.taxClassificationsData.some(data => data.objectId === taxClassificationData.objectId)) {
        taxClass.taxClassificationsData.find(data => data.objectId === taxClassificationData.objectId).classificationUpdated = true;
        editTaxClassificationData.objectId = (-Math.floor(Math.random() * 1000) + 1).toString();
        editTaxClassificationData.classificationUpdated = null;
        taxClass.taxClassificationsData.push(this.statelessService.deepClone(editTaxClassificationData));
      }
    })
    this.updateState$(of(state));
  }

  deleteTaxClassification(taxClassData: AnagtaxClassificationData) {
    const state = this.getCurrentState();
    const party = state.party;
    this.i18nService.translate('_ANAG_._MSG_._ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_').subscribe(msg => {
      const anagModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(msg, true));
      anagModal.modal.enableClickBackground = false;
      anagModal.modal.onClose.subscribe(onCloseData => {
        if (onCloseData && onCloseData.confirm) {
          if (!isNaN(parseInt(taxClassData.objectId, 10)) && parseInt(taxClassData.objectId, 10) > 0) {
            party.taxClassifications.forEach(taxClass => {
              if (taxClass.taxClassificationsData.some(data => data.objectId === taxClassData.objectId)) {
                taxClass.taxClassificationsData.find(data => data.objectId === taxClassData.objectId).classificationDeleted = true;
              }
            })
          } else {
            party.taxClassifications.forEach(taxClass => {
              if (taxClass.taxClassificationsData.some(data => data.objectId === taxClassData.objectId)) {
                taxClass.taxClassificationsData.splice(taxClass.taxClassificationsData.findIndex(data => data.objectId === taxClassData.objectId), 1);
              }
            })
          }
        }
      });
    }).unsubscribe();
    this.updateState$(of(state));
  }



  deleteDocument(document: AnagDocument,index: number) : void {
    const state = this.getCurrentState();
    const party = state.party;
    this.i18nService.translate('_ANAG_._MSG_._ASK_DELETION_DOCUMENT_CONFIRM_').subscribe(msg => {
      const anagModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(msg, true));
      anagModal.modal.enableClickBackground = false;
      anagModal.modal.onClose.subscribe(onCloseData => {
        if (onCloseData && onCloseData.confirm) {
          party?.documents.splice(index, 1);
        }
      });
    }).unsubscribe();
    this.updateState$(of(state));
  }

  isTaxClassificationsEnabled(): boolean {
    return this.isTaxClassificationFieldVisible('idTax');
  }

  isTaxClassificationsValid(): boolean {
    let isTaxRequired = false;
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig && currState.configuration.partyConfig.taxClassifications && currState.configuration.partyConfig.taxClassifications.idTax) {
      isTaxRequired = this.configService.isFieldMandatory(currState.configuration.partyConfig.taxClassifications.idTax);
    }
    if (isTaxRequired && (!currState.party.taxClassifications || currState.party.taxClassifications.length === 0)) {
      return false;
    }
    return true;
  }

  isTaxClassificationsMandatory(): boolean {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig
      && currState.configuration.partyConfig.taxClassifications && currState.configuration.partyConfig.taxClassifications.idTax) {
      return this.configService.isFieldMandatory(currState.configuration.partyConfig.taxClassifications.idTax);
    }
    return false;
  }

  isTaxClassificationFieldVisible(fieldName: string): boolean {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig && currState.configuration.partyConfig.taxClassifications
      && currState.configuration.partyConfig.taxClassifications[fieldName]) {
      return !this.configService.isFieldHidden(currState.configuration.partyConfig.taxClassifications[fieldName]);
    }
    return true;
  }

  isRatingsEnabled(): boolean {
    return this.isRatingFieldVisible('idRating');
  }

  isAntiTerrorismEnabled(): boolean {
    return this.isFieldVisible('antiTerrorismStatus');
  }

  isRatingValid(): boolean {
    let isRatingRequired = false;
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig && currState.configuration.partyConfig.ratings && currState.configuration.partyConfig.ratings.idRating) {
      isRatingRequired = this.configService.isFieldMandatory(currState.configuration.partyConfig.ratings.idRating);
    }
    if (isRatingRequired && (!currState.party.ratings || currState.party.ratings.length === 0)) {
      return false;
    }
    return true;
  }

  isRatingMandatory(): boolean {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig
      && currState.configuration.partyConfig.ratings && currState.configuration.partyConfig.ratings.idRating) {
      return this.configService.isFieldMandatory(currState.configuration.partyConfig.ratings.idRating);
    }
    return false;
  }

  isRatingFieldVisible(fieldName: string): boolean {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig && currState.configuration.partyConfig.ratings
      && currState.configuration.partyConfig.ratings[fieldName]) {
      return !this.configService.isFieldHidden(currState.configuration.partyConfig.ratings[fieldName]);
    }
    return true;
  }

  isFieldVisible(fieldName: string): boolean {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig && currState.configuration.partyConfig[fieldName]) {
      return !this.configService.isFieldHidden(currState.configuration.partyConfig[fieldName]);
    }
    return true;
  }

  addOtherAddress(otherAddress: AnagApiOtherAddress) {
    const state = this.getCurrentState();
    const party = state.party;
    if (!party.otherAddress || party.otherAddress.length === 0) {
      party.otherAddress = [];
    }
    party.otherAddress.push(this.statelessService.deepClone(otherAddress));
    this.updateState$(of(state));
  }
  updateOtherAddress(otherAddress: AnagApiOtherAddress, i) {
    const state = this.getCurrentState();
    const party = state.party;
    party.otherAddress[i] = this.statelessService.deepClone(otherAddress);
    this.updateState$(of(state));
  }

  deleteOtherAddress(i) {
    const state = this.getCurrentState();
    const party = state.party;
    party.otherAddress.splice(i, 1);
    this.updateState$(of(state));
  }

  updateResidence(address: AnagApiAddress) {
    const state = this.getCurrentState();
    const party = state.party;
    party.residence = this.statelessService.deepClone(address);
    this.updateState$(of(state));
  }

  retrievePartyKeyConf(countryCode: string, keyForm: UntypedFormGroup) {
    const state = this.getCurrentState();
    this.updateState$(this.statelessOps.retrievePartyKeyConf$(of(state), countryCode, keyForm));
  }

  updateParty(party: AnagApiParty) {
    const currState = this.getCurrentState();
    currState.party = this.statelessService.deepClone(party);
    this.updateState$(of(currState));
  }

  updatePrivacy(privacyForm: any, questions: Array<AnagEntityIta>, options: Array<AnagEntityIta>, originoptions: Array<AnagEntityIta>) {
    const privacyConsensuses = [];
    questions.forEach(obj => {
      const question = obj;
      const privacy = new AnagIdentificationEntity(question.codice, question.descrizione, question.codice);
      const privacyConsensusValue = options.find(opt => opt.codice === privacyForm[question.codice]) ? options.find(opt => opt.codice === privacyForm[question.codice]) : null;
      const privacyConsentDate = privacyForm['date-' + question.codice];
      const privacyConsentOrigin = privacyForm['origin-' + question.codice];
      // tslint:disable-next-line:max-line-length
      const privacyConsentOriginDescription = originoptions.find(originOpt => originOpt.codice === privacyForm['origin-' + question.codice]) ? originoptions.find(originOpt => originOpt.codice === privacyForm['origin-' + question.codice]).descrizione : null;
      // tslint:disable-next-line:max-line-length
      privacyConsensuses.push(new AnagPrivacyConsensus(privacy, privacyConsensusValue, privacyConsentDate, privacyConsentOrigin, privacyConsentOriginDescription));
    });
    const currState = this.getCurrentState();
    currState.party.robinsonList = privacyForm.robinsonList;
    currState.party.privacyConsensus = privacyConsensuses;
    this.updateState$(of(currState));
  }

  isIdentificationDataValid(): boolean {
    const context = this.userService.getUser<OperatorLight>().salePoint.context;
    const currState = this.getCurrentState();
    return this.configService.isIdentificationDataValid(currState.configuration, currState.party, context, currState.citizenshipPermitTypes);
  }


  isKeyDataValid(): boolean {
    const currState = this.getCurrentState();
    return this.configService.isKeyDataValidConfig(currState.configuration, currState.party);
  }

  isPrivacyConsensusValid(): boolean {
    const currentState = this.getCurrentState();
    // tslint:disable-next-line:max-line-length
    return this.configService.isPrivacyConsensusValidConfig(currentState.configuration, currentState.privacyQuestions, currentState.party.privacyConsensus);
  }

  isContactsIsValid() {
    const currentState = this.getCurrentState();
    return this.configService.isContactsValidConfig(currentState.configuration, currentState.party.mainContact);
  }

  isDocumentValid(): boolean {
    const currentState = this.getCurrentState();
    return this.configService.isDocumentValidConfig(currentState.configuration, currentState.party.documents, currentState.isValidDrivingLecense);

  }

  areLinksComplete(): boolean {
    const party = this.getCurrentState().party;
    let valid = true;
    if (party.links) {
      party.links.forEach(link => {
        if (!link.linkType) {
          valid = false;
        }
      });
    }
    return valid;
  }

  canSaveParty(): boolean {
    const currState = this.getCurrentState();
    return !currState.blockedFlow && this.isIdentificationDataValid() && this.isKeyDataValid() &&
      this.isPrivacyConsensusValid() && this.areLinksComplete() &&
      this.configService.isDocumentValidConfig(currState.configuration, currState.party.documents, currState.isValidDrivingLecense) && this.isContactsIsValid() && this.isTaxClassificationsValid();
  }

  saveParty(): Observable<AnagStatePartyEditor> {
    const currState = this.getCurrentState();
    if (this.canSaveParty) {
      currState.party.mainSubject = true;
      return this.statelessOps.saveParty(of(currState)).pipe(take(1)).pipe(tap(updState => {
        this.updateState$(of(updState));
      }));
    }
  }

  calculatePartyKey(form: UntypedFormGroup, birthAddress: AnagBirthAddress): Observable<any> {
    const currState = this.getCurrentState();
    const reqParty = this.statelessService.deepClone(this.getIdDataUpdatedParty(form, currState));
    reqParty.birthAddress = birthAddress;
    return this.statelessOps.calculatePartyKey$(reqParty);
  }

  updateContacts(formData: any) {
    const currState = this.getCurrentState();
    const party = currState.party;
    party.mainContact = this.anagStorage.getEnumsByCode(MAIN_CONTACT_ENUM).find(contact => contact.codice === formData.mainContact);
    party.phoneFax = party.phoneFax ? party.phoneFax : new AnagPhoneNumber();
    party.landlineNumber = party.landlineNumber ? party.landlineNumber : new AnagPhoneNumber();
    party.mobilePhone = party.mobilePhone ? party.mobilePhone : [];
    party.mobilePhone[0] = party.mobilePhone[0] ? party.mobilePhone[0] : new AnagPhoneNumber();
    party.mobilePhone[0].internationalPrefix = formData.phoneNumberIntPrefix ? formData.phoneNumberIntPrefix : null;
    party.mobilePhone[0].localPrefix = formData.phoneNumberPrefix;
    party.mobilePhone[0].number = formData.phoneNumber;
    party.mobilePhone[1] = party.mobilePhone[1] ? party.mobilePhone[1] : new AnagPhoneNumber();
    party.mobilePhone[1].internationalPrefix = formData.phoneNumberIntPrefix2 ? formData.phoneNumberIntPrefix2 : null;
    party.mobilePhone[1].localPrefix = formData.phoneNumberPrefix2;
    party.mobilePhone[1].number = formData.phoneNumber2;
    party.landlineNumber.internationalPrefix = formData.landlineNumberIntPrefix ? formData.landlineNumberIntPrefix : null;
    party.landlineNumber.localPrefix = formData.landlineNumberPrefix;
    party.landlineNumber.number = formData.landlineNumber;
    party.phoneFax.internationalPrefix = formData.faxNumberIntPrefix ? formData.faxNumberIntPrefix : null;
    party.phoneFax.localPrefix = formData.faxNumberPrefix;
    party.phoneFax.number = formData.faxNumber;
    party.emails = party.emails ? party.emails : [];
    party.emails[0] = formData.email;
    party.emails[1] = formData.email2;
    party.emails[2] = formData.email3;
    party.emails = party.emails.filter((e): e is Exclude<typeof e, null> => e !== null);
    party.emails = party.emails.filter((e): e is Exclude<typeof e, ''> => e !== '');
    if (party.emails[0]) { formData.email = party.emails[0]; }
    if (party.emails[1]) { formData.email2 = party.emails[1]; }
    if (party.emails[2]) { formData.email3 = party.emails[2]; }
    currState.party.emails = party.emails;
    this.updateState$(of(currState));
  }

  updateDocument(formData, drivingLicense: Array<AnagDrivingLicense>, isValidDrivingLicense: boolean, index: number) {
    const currState = this.getCurrentState();
    currState.isValidDrivingLecense = isValidDrivingLicense;
    const party = currState.party;
    const docTypeForm = this.getEnumsByCode(DOCUMENT_TYPE_ENUM).find(docType => docType.codice === formData.documentType);
    const doc = party.documents[index];
    if (doc) {
      doc.documentType = docTypeForm ? docTypeForm : null;
      doc.documentNumber = docTypeForm && formData.documentNumber ? formData.documentNumber : null;
      doc.releaseDate = docTypeForm && formData.releaseDate ? formData.releaseDate : null;
      doc.expirationDate = docTypeForm && formData.expirationDate ? formData.expirationDate : null;
      doc.locationsRelease = docTypeForm && formData.locationsRelease ? formData.locationsRelease : null;
      const authRelease = this.getEnumsByCode(DOCUMENT_ISSUING_AUTHORITY_ENUM).find(auth => auth.codice === formData.authoritiesRelease);
      doc.authoritiesRelease = docTypeForm && authRelease ? authRelease : null;
      doc.drivingLicense = drivingLicense;
    }
    this.updateState$(of(currState));
  }

  addDocument(formData, drivingLicense: Array<AnagDrivingLicense>, isValidDrivingLicense: boolean) {
    const currState = this.getCurrentState();
    currState.isValidDrivingLecense = isValidDrivingLicense;
    const party = currState.party;
    const docTypeForm = this.getEnumsByCode(DOCUMENT_TYPE_ENUM).find(docType => docType.codice === formData.documentType);
    const doc = new AnagDocument();
    if (doc) {
      doc.documentType = docTypeForm ? docTypeForm : null;
      doc.documentNumber = docTypeForm && formData.documentNumber ? formData.documentNumber : null;
      doc.releaseDate = docTypeForm && formData.releaseDate ? formData.releaseDate : null;
      doc.expirationDate = docTypeForm && formData.expirationDate ? formData.expirationDate : null;
      doc.locationsRelease = docTypeForm && formData.locationsRelease ? formData.locationsRelease : null;
      const authRelease = this.getEnumsByCode(DOCUMENT_ISSUING_AUTHORITY_ENUM).find(auth => auth.codice === formData.authoritiesRelease);
      doc.authoritiesRelease = docTypeForm && authRelease ? authRelease : null;
      doc.drivingLicense = drivingLicense;
    }
    if(!party.documents) {
      party.documents = [];
    }
    party.documents.push(doc);

    this.updateState$(of(currState));


  }

  actionEditParty(stateMgrKeyData: AnagStateManagerKeyData, party: AnagApiParty) {
    const currState = this.getCurrentState();
    if (currState.isUmaPartyHomonymyEnable) {
      const keyDataModal = this.modalService.openComponent(KeyDataModalComponent, party, [
        {
          provide: AnagStateManagerKeyData,
          useValue: stateMgrKeyData
        }
      ]);
      keyDataModal.modal.enableClickBackground = false;
      keyDataModal.modal.onClose.asObservable().pipe(take(1));
      this.eventService.listen<KeyDataUpdateEvent>(KEY_DATA_UPDATE).pipe(take(1)).subscribe(eventInstance => {
        this.anagIntegrationService.openEditSubject(eventInstance.event);
      });
    } else {
      this.anagIntegrationService.openEditSubject(this.activeRoute.getRouteData<AnagRouteData>());
    }

  }

  actionLimitationManagement() {
    this.anagIntegrationService.openLimitationManagement(
      this.activeRoute.id,
      this.getCurrentState().party.objectId,
      this.getCurrentState().party.node.identification);
  }

  actionHistory() {
    this.anagIntegrationService.openHistory(
      this.activeRoute.id,
      this.getCurrentState().party.objectId,
      this.getCurrentState().party.node.identification);
  }

  actionGoToDetailPage() {
    const currentState = this.getCurrentState();
    this.updateState$(this.statelessOps.goBackToDetailPage$(currentState.lastIdFromDamage, of(currentState), this.activeRoute));
  }

  actionDelete(definitely: boolean) {
    let msg = '';
    this.i18nService.translate('_ANAG_._MSG_._ASK_DELETION_CONFIRM_').subscribe(translated => msg = translated);
    const anagModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(msg, true));
    anagModal.modal.enableClickBackground = false;
    anagModal.modal.onClose.subscribe(onCloseData => {
      if (onCloseData.confirm) {
        this.updateState$(this.statelessOps.deleteParty$(of(this.getCurrentState()), this.activeRoute, definitely));
      }
    });
  }

  updateLinks($event: LinkPartyEvent) {
    const currentState = this.getCurrentState();
    const party = currentState.party;
    const links = party.links ? party.links : [];
    switch ($event.action) {
      case 'addLink':
        const alreadyExists = links.find(link => link.linkedPartyObjectId === $event.party.linkedPartyObjectId);
        if (!alreadyExists) {
          links.push($event.party);
        }
        break;
      case 'updateLink':
        const linkToUpdate = links.find(link => link.linkedPartyObjectId === $event.party.linkedPartyObjectId);
        linkToUpdate.linkType = $event.party.linkType;
        linkToUpdate.linkSubjectNominative = $event.party.linkSubjectNominative;
        break;
      case 'deleteLink':
        links.splice(links.findIndex(linkToDelete => linkToDelete.linkedPartyObjectId === $event.party.linkedPartyObjectId), 1);
        break;
    }
    party.links = links;
    if ($event.action === 'addLink' && $event.party.linkType !== '23' && $event.party.linkType !== '24') {
      this.updateState$(this.statelessOps.retrieveLinkTypes$(of(currentState), $event.linkSubjectType));
    } else {
      this.updateState$(of(currentState));

    }
  }

  retrieveValuedLinksOptions() {
    const currentState = this.getCurrentState();
    this.updateState$(this.statelessOps.retrieveValuedLinksTypes$(of(currentState)));
  }

  updatePaymentMethods(partyPayments: AnagPartyPayment[]) {
    const currentState = this.getCurrentState();
    currentState.party.payments = partyPayments;
    this.updateState$(of(currentState));
  }

  updateConfiguration(configuration: AnagConfiguration) {
    const currentState = this.getCurrentState();
    if (configuration) {
      currentState.configuration = configuration;
      const party = currentState.party;
      party.dynamicObjects = this.statelessOps.getConfUpdatedDynObjs(party.dynamicObjects, configuration.dynamicObjects);
      this.updateState$(of(currentState));
    }
  }

  updateDynamicObject(inputDynamicObject: AnagDynamicObj) {
    const currentState = this.getCurrentState();
    const party = currentState.party;
    if (inputDynamicObject.idDynObject) {
      const index = party.dynamicObjects.findIndex(elem => elem.idDynObject === inputDynamicObject.idDynObject);
      party.dynamicObjects[index] = inputDynamicObject;
    } else {
      party.dynamicObjects.push(inputDynamicObject);
    }
    this.updateState$(of(currentState));

  }

  isDocumentMandatory() {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig && currState.configuration.partyConfig.document) {
      const docConf = currState.configuration.partyConfig.document;
      return this.configService.isFieldMandatory(docConf.documentType) || this.configService.isFieldMandatory(docConf.documentNumber) ||
        this.configService.isFieldMandatory(docConf.releaseDate) || this.configService.isFieldMandatory(docConf.expirationDate) ||
        this.configService.isFieldMandatory(docConf.locationsRelease) || this.configService.isFieldMandatory(docConf.authoritiesRelease);
    }
    return false;
  }

  isContactsMandatory() {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig && currState.configuration.partyConfig.mainContact) {
      return this.configService.isFieldMandatory(currState.configuration.partyConfig.mainContact);
    }
    return false;
  }

  isPrivacyMandatory() {
    const currState = this.getCurrentState();
    if (currState.configuration && currState.configuration.partyConfig
      && currState.configuration.partyConfig.privacyConsensusType
      && currState.configuration.partyConfig.privacyConsensusDate
      && currState.configuration.partyConfig.privacyConsensusOrigin
      && currState.configuration.partyConfig.privacyConsensusDateUpd) {
      const conf = currState.configuration.partyConfig;
      return this.configService.isFieldMandatory(conf.privacyConsensusType)
        || this.configService.isFieldMandatory(conf.privacyConsensusDate)
        || this.configService.isFieldMandatory(conf.privacyConsensusOrigin)
        || this.configService.isFieldMandatory(conf.privacyConsensusDateUpd);
    }
    return false;
  }

  setTitle() {
    const currState = this.getCurrentState();
    const party = currState.party;
    let name = '';
    let titleKey;
    if (currState.editorModeEnable) {
      titleKey = '_ANAG_TITLE_EDIT_PARTY_';
      if (this.isPartyPhysical) {
        name = (party.surname ? party.surname + ' ' : '') + (party.name ? party.name : '');
      } else {
        name = party.denomination ? party.denomination : '';
      }
    } else {
      titleKey = '_ANAG_TITLE_PARTY_ZOOM_';
      name = party.nominative;
    }
    this.i18nService.translate(titleKey).subscribe(title => {
      this.anagIntegrationService.changeTitle(this.activeRoute.id, title + ' ' + name);
    }).unsubscribe();
  }

  updateTerrorismInfo(partyTerrorismStatus: CounterTerrorismStatus) {
    const currentState = this.getCurrentState();
    currentState.party.counterTerrorismStatus = partyTerrorismStatus;
    currentState.party.counterTerrorismDate = new Date();
    this.updateState$(of(currentState));

  }

  updateRating(editedRating: AnagRating) {
    const state = this.getCurrentState();
    const party = state.party;
    if (!party.ratings) {
      party.ratings = [];
    }
    let findNull = party.ratings.find(elem => !elem.objectId);
    if (findNull) {
      findNull = editedRating;
    } else {
      party.ratings.push(this.statelessService.deepClone(editedRating));
    }
    this.updateState$(of(state));
  }

  get ratingList(): Observable<AnagEntityIta[]> {
    return this.anagStorage.getEnumtable$('it.rgi.dbobjects.EntRatingtype');
  }
}
