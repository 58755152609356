import { Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from '@rgi/digital-claims-common-angular';
import { DocumentEntity } from '../new-payment/domain/document-entity';
import { AccessNoteComponent } from './components/access-note/access-note.component';
import { AddManualNoteComponent } from './components/add-manual-note/add-manual-note.component';
import { BaseNoteComponent } from './components/base-note/base-note.component';
import { DocumentNoteComponent } from './components/document-note/document-note.component';
import { MovementNoteComponent } from './components/movement-note/movement-note.component';
import { ReserveNoteComponent } from './components/reserve-note/reserve-note.component';
import { Note } from './objects/note';
import { TimelineService } from './timeline.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent extends CardComponent implements OnInit {

  private claim: any;
  days: any;
  infoTimeLine: any;
  infoTimeLineComplete: any;
  colors: any[] = [{ ita: 'giallo', en: 'yellow' },
  { ita: 'rosso', en: 'red' },
  { ita: 'verde', en: 'green' }];
  showAcesses = false;
  finishCharge = false;
  pes = false;
  showFilterType = false;
  placeHolderFilterType = '';
  placeHolderFilterBasic = '';
  filterSelectionDescr = '';
  filterSelectionBasicNoteDescr = '';
  showFilterTypeBasicNote = false;
  filterType = [];

  filterBasicNote = [];
  orderDesc = true;
  dateFrom: string;
  dateTo: string;
  pageId: string;

  constructor(
    @Inject('containerCacheService') private cache,
    @Inject('authService') private authService,
    private timelineService: TimelineService,
    private modalService: NgbModal,
    private rxTranslationsService: RgiRxTranslationService
  ) {
    super();
    const listFunz = this.authService.getOperator().enabledFeatures;
    if (listFunz && listFunz.indexOf('SXTLAC') >= 0) {
      this.showAcesses = true;
    }
    this.filterType = this.setFilterType(true);
  }

  ngOnInit() {
    this.waitInit();
  }

  init(){
    this.claim = this.cache.getCache().get(this.card.idSession).data;
        if(this.claim.idClaim){
          this.pageId = this.claim.idClaim;
        } else {
          this.pageId = this.claim.idPotencialClaim;
          this.pes = true;
        }
        if (!this.infoTimeLine && this.pageId) {
          this.infoTimeLine = this.initialize();
          this.infoTimeLineComplete = this.initializeEmpty();
          // this.infoTimeLine = this.getNotes(this.claim.claimnumber);
          // this.build();
          if (!this.showAcesses) {
            this.filterType = this.setFilterType(false);
          }

          this.filterType.forEach(el => {
            this.placeHolderFilterType += el.descr + ',';
          });

          this.finishCharge = false;

          if (this.showAcesses) {
            this.timelineService.getAccessNotesGroup(this.pageId ,this.pes).subscribe(
              (response: any[]) => {
                console.log('getAccessNotes -->', response);
                const accNotes: any[] = [];
                response.forEach(aAccNoteOut => {
                  // {
                  //   date: new Date('2020-03-21 11:13:00'),
                  //   user: 'FDENUZZO'
                  // },
                  const aAccNoteFE = {
                    date: new Date(aAccNoteOut.date),
                    user: aAccNoteOut.user
                  };
                  accNotes.push(aAccNoteFE);
                });
                this.infoTimeLine.accessNotes = accNotes;
                this.infoTimeLineComplete.accessNotes = accNotes;

                this.build();
              },
              (error: any) => {
              }
            );
          } else {
            this.infoTimeLine.accessNotes = [];
          }

          if(!this.pes){
          this.timelineService.getReserveMovements(this.pageId).subscribe(
            (response: any[]) => {
              console.log('getReserveMovements ->', response);
              const accNotes: any[] = [];
              response[0].forEach(aAccNoteOut => {
                // {
                //   date: new Date('2020-03-18 8:48:27'),
                //   technicalMovement: 'Variazione sinistro',
                //   accountingMovement: 'Preventivazione',
                //   accountingDate: '2020-03-18 8:48:28',
                //   party: 'Silvano Rogi',
                //   partyRole: 'Trasportato non proprietario nostro veicolo',
                //   typeOfLoss: 'Responsabilità Civile Lesioni',
                //   user: 'GGAGLIARDI'
                // },
                const aAccNoteFE = {
                  date: new Date(aAccNoteOut.movementDateStr),
                  technicalMovement: aAccNoteOut.technicalMovement,
                  accountingMovement: aAccNoteOut.accountingMovement,
                  accountingDate: aAccNoteOut.accountingDateStr,
                  party: aAccNoteOut.party,
                  idParty: aAccNoteOut.idParty,
                  partyRole: aAccNoteOut.partyRole,
                  typeOfLoss: aAccNoteOut.typeOfLoss,
                  user: aAccNoteOut.user,
                  amount: aAccNoteOut.importo,
                  idPayment: aAccNoteOut.idPayment,
                  type: aAccNoteOut.type
                };
                accNotes.push(aAccNoteFE);
              });
              this.infoTimeLine.movementNotes = accNotes;
              this.infoTimeLineComplete.movementNotes = accNotes;

              const rsvNotes: any[] = [];
              response[1].forEach(rsvNoteOut => {
                // {
                //   date: new Date('2020-03-18 8:48:30'),
                //   reserveAmount: 6950,
                //   currencySymbol: '¥'
                // },
                const rsvNoteFE = {
                  date: new Date(rsvNoteOut.dateStr),
                  reserveAmount: rsvNoteOut.reserveAmout,
                  reserveAmountSL: rsvNoteOut.reserveAmoutSL,
                  reserveAmountSR: rsvNoteOut.reserveAmoutSR,
                  paidAmount: rsvNoteOut.paidAmount,
                  currencySymbol: rsvNoteOut.currency
                };
                rsvNotes.push(rsvNoteFE);
              });
              this.infoTimeLine.reserveNotes = rsvNotes;
              this.infoTimeLineComplete.reserveNotes = rsvNotes;

              this.build();
            },
            (error: any) => {
            }
          );}


          // this.timelineService.getAccountingMovements(this.claim.idClaim).subscribe(
          //   (response: any[]) => {
          //     console.log('getAccountingMovements ->', response);
          //     const accNotes: any[] = [];
          //     response.forEach(aAccNoteOut => {
          //       // {
          //       //   date: new Date('2020-03-18 8:48:27'),
          //       //   technicalMovement: 'Variazione sinistro',
          //       //   accountingMovement: 'Preventivazione',
          //       //   accountingDate: '2020-03-18 8:48:28',
          //       //   party: 'Silvano Rogi',
          //       //   partyRole: 'Trasportato non proprietario nostro veicolo',
          //       //   typeOfLoss: 'Responsabilità Civile Lesioni',
          //       //   user: 'GGAGLIARDI'
          //       // },
          //       const aAccNoteFE = {
          //         date: new Date(aAccNoteOut.movementDateStr),
          //         technicalMovement: aAccNoteOut.technicalMovement,
          //         accountingMovement: aAccNoteOut.accountingMovement,
          //         accountingDate: aAccNoteOut.accountingDateStr,
          //         party: aAccNoteOut.party,
          //         partyRole: aAccNoteOut.partyRole,
          //         typeOfLoss: aAccNoteOut.typeOfLoss,
          //         user: aAccNoteOut.user
          //       };
          //       accNotes.push(aAccNoteFE);
          //     });
          //     this.infoTimeLine.movementNotes = accNotes;
          //     this.infoTimeLineComplete.movementNotes = accNotes;
          //     this.build();
          //   },
          //   (error: any) => {
          //   }
          // );

          // this.timelineService.getReserveNotes(this.claim.idClaim).subscribe(
          //   (response: any[]) => {
          //     console.log('getReserveNotes ->', response);
          //     const rsvNotes: any[] = [];
          //     response.forEach(rsvNoteOut => {
          //       // {
          //       //   date: new Date('2020-03-18 8:48:30'),
          //       //   reserveAmount: 6950,
          //       //   currencySymbol: '¥'
          //       // },
          //       const rsvNoteFE = {
          //         date: new Date(rsvNoteOut.dateStr),
          //         reserveAmount: rsvNoteOut.reserveAmout,
          //         currencySymbol: rsvNoteOut.currency
          //       };
          //       rsvNotes.push(rsvNoteFE);
          //     });
          //     this.infoTimeLine.reserveNotes = rsvNotes;
          //     this.infoTimeLineComplete.reserveNotes = rsvNotes;
          //     this.build();
          //   },
          //   (error: any) => {
          //   }
          // );

          this.updateUserNotes();

          // this.infoTimeLine.docNotes = null;
          this.timelineService.getDocuments(this.pageId,this.pes).subscribe(
            (response: DocumentEntity[]) => {
              console.log('getDocuments ->', response);
              const docNotes: any[] = [];
              response.forEach(docNoteOut => {
                // {
                //   date: new Date('2020-03-18 8:48:30'),
                //   reserveAmount: 6950,
                //   currencySymbol: '¥'
                // },

                const docNoteFE = {
                  date: new Date(docNoteOut.data),
                  idDocInfo: docNoteOut.idDocInfo,
                  documentId: docNoteOut.documentId,
                  category: docNoteOut.category,
                  categoryId: docNoteOut.categoryId,
                  user: docNoteOut.user,
                  note: docNoteOut.note,
                  documentName: docNoteOut.documentName,
                  mailed: docNoteOut.mailed,
                  fileType: docNoteOut.fileType,
                  claim: this.claim
                };

                docNotes.push(docNoteFE);
              });
              this.infoTimeLine.docNotes = docNotes;
              this.infoTimeLineComplete.docNotes = docNotes;
              this.build();
            },
            (error: any) => {
              this.infoTimeLine.docNotes = [];
            });
          }
        //  else if (!this.pageId) {
        //   this.pes = true;
        // }
        else {
          this.build();
        }
  }

  waitInit(){
    if(this.cache.getCache().get(this.card.idSession).data != null){
          this.init();
    }else{
      setTimeout(function () {
         this.waitInit();
      }, 300)
    }
  }



  setFilterType(isInitValue: boolean): { sel: boolean, code: string, descr: string }[]  {

    const filterType = [];

    this.rxTranslationsService.translate('_CLAIMS_._MOVEMENTS').subscribe(
      res => filterType.push({ sel: false, code: 'MOV', descr: res })
    )
    this.rxTranslationsService.translate('_CLAIMS_._ACTIVITY').subscribe(
      res => filterType.push({ sel: false, code: 'BNOT', descr: res })
    )
    this.rxTranslationsService.translate('_CLAIMS_._DOCUMENTS').subscribe(
      res => filterType.push({ sel: false, code: 'DOC', descr: res })
    )
    this.rxTranslationsService.translate('_CLAIMS_._RESERVES').subscribe(
      res => filterType.push({ sel: false, code: 'RSV', descr: res })
    )

    if (isInitValue) {
      this.rxTranslationsService.translate('_CLAIMS_._ACCESSES').subscribe(
        res => filterType.push({ sel: false, code: 'ACC', descr: res })
      )
    }

    return filterType
  }

  splittaData(dataStr: string) {
    let splDate: string[];

    if (!dataStr) {
      return null;
    }

    if (dataStr.indexOf('/') >= 0) {
      splDate = dataStr.split('/');
    }
    if (dataStr.indexOf('-') >= 0) {
      splDate = dataStr.split('-');
    }
    if (dataStr.indexOf('\\') >= 0) {
      splDate = dataStr.split('\\');
    }
    if (dataStr.indexOf('.') >= 0) {
      splDate = dataStr.split('.');
    }

    try {
      const data = new Date('\'' + splDate[2] + '-' + splDate[1] + '-' + splDate[0] + '\'');
      if (isNaN(data.getTime())) {
        return null;
      } else {
        return data;
      }
    } catch (error) {
      return null;
    }
  }

  filterByDate() {
    const from = this.splittaData(this.dateFrom);
    const to = this.splittaData(this.dateTo);
    // if(this.filterType.find(el => el.sel)) {
    this.closeFilterType();
    //  }
    if (this.infoTimeLine.baseNotes && this.infoTimeLine.baseNotes.length > 0) {
      this.closeFilterTypeBasic();
    }

    const baseNotes = this.infoTimeLine.baseNotes;
    const accessNotes = this.infoTimeLine.accessNotes;
    const reserveNotes = this.infoTimeLine.reserveNotes;
    const movementNotes = this.infoTimeLine.movementNotes;
    const docNotes = this.infoTimeLine.docNotes;


    if (from && !to) {
      this.infoTimeLine.movementNotes = [];
      movementNotes.forEach(element => {
        if (element.date > from) {
          this.infoTimeLine.movementNotes.push(element);
        }
      });
      this.infoTimeLine.reserveNotes = [];
      reserveNotes.forEach(element => {
        if (element.date > from) {
          this.infoTimeLine.reserveNotes.push(element);
        }
      });
      this.infoTimeLine.accessNotes = [];
      accessNotes.forEach(element => {
        if (element.date > from) {
          this.infoTimeLine.accessNotes.push(element);
        }
      });
      this.infoTimeLine.baseNotes = [];
      baseNotes.forEach(element => {
        if (element.date > from) {
          this.infoTimeLine.baseNotes.push(element);
        }
      });
      this.infoTimeLine.docNotes = [];
      docNotes.forEach(element => {
        if (element.date > from) {
          this.infoTimeLine.docNotes.push(element);
        }
      });
    } else if (!from && to) {
      this.infoTimeLine.movementNotes = [];
      movementNotes.forEach(element => {
        if (element.date < to) {
          this.infoTimeLine.movementNotes.push(element);
        }
      });
      this.infoTimeLine.reserveNotes = [];
      reserveNotes.forEach(element => {
        if (element.date < to) {
          this.infoTimeLine.reserveNotes.push(element);
        }
      });
      this.infoTimeLine.accessNotes = [];
      accessNotes.forEach(element => {
        if (element.date < to) {
          this.infoTimeLine.accessNotes.push(element);
        }
      });
      this.infoTimeLine.baseNotes = [];
      baseNotes.forEach(element => {
        if (element.date < to) {
          this.infoTimeLine.baseNotes.push(element);
        }
      });
      this.infoTimeLine.docNotes = [];
      docNotes.forEach(element => {
        if (element.date < to) {
          this.infoTimeLine.docNotes.push(element);
        }
      });
    } else if (from && to) {
      this.infoTimeLine.movementNotes = [];
      movementNotes.forEach(element => {
        if (element.date > from && element.date < to) {
          this.infoTimeLine.movementNotes.push(element);
        }
      });
      this.infoTimeLine.reserveNotes = [];
      reserveNotes.forEach(element => {
        if (element.date > from && element.date < to) {
          this.infoTimeLine.reserveNotes.push(element);
        }
      });
      this.infoTimeLine.accessNotes = [];
      accessNotes.forEach(element => {
        if (element.date > from && element.date < to) {
          this.infoTimeLine.accessNotes.push(element);
        }
      });
      this.infoTimeLine.baseNotes = [];
      baseNotes.forEach(element => {
        if (element.date > from && element.date < to) {
          this.infoTimeLine.baseNotes.push(element);
        }
      });
      this.infoTimeLine.docNotes = [];
      docNotes.forEach(element => {
        if (element.date > from && element.date < to) {
          this.infoTimeLine.docNotes.push(element);
        }
      });
    }

    if (!from && !to) {
      this.infoTimeLine.baseNotes = baseNotes;
      this.infoTimeLine.accessNotes = accessNotes;
      this.infoTimeLine.reserveNotes = reserveNotes;
      this.infoTimeLine.movementNotes = movementNotes;
      this.infoTimeLine.docNotes = docNotes;
    }

    this.build();
  }

  updateUserNotes() {
    this.timelineService.getUserNotes(this.pageId,this.pes).subscribe(
      (response: any[]) => {
        console.log('getUserNotes ->', response);
        const userNotes: any[] = [];
        response.forEach(userNoteOut => {
          // {
          //   bulletColor: 'red',
          //   comments: [1, 2, 3],
          //   date: new Date('2020-03-21  11:15:10'),
          // cancelUser: 'FDENUZZO',
          // cancelDate: new Date('2020-03-19 11:37:30'),
          //   text: 'Nota antifrode: Comprovata fraudolenza del sinistro si gira per competenza all\'ufficio antifrode.',
          //   type: 'automatic',
          //   user: 'FDENUZZO'
          // },

          const userNoteFE = {
            bulletColor: (this.colors.find(el => el.ita === userNoteOut.color.toLowerCase()) ?
              this.colors.find(el => el.ita === userNoteOut.color.toLowerCase()).en : ''),
            comments: userNoteOut.comments ? userNoteOut.comments : [],
            date: new Date(userNoteOut.dInsStr),
            text: userNoteOut.text,
            type: userNoteOut.type,
            elementType: userNoteOut.elementType,
            user: userNoteOut.userIns,
            cancelUser: userNoteOut.userCanc,
            cancelDate: userNoteOut.dCancStr ? new Date(userNoteOut.dCancStr) : null,
            noteId: userNoteOut.userNoteId,
            claimId: this.pageId,
            pes: this.pes,
            eventType: userNoteOut.eventType
          };
          userNotes.push(userNoteFE);

          if (!this.filterBasicNote.find(el => el.code === userNoteOut.elementType)) {
            this.filterBasicNote.push(
              { sel: false, code: userNoteOut.eventType, descr: userNoteOut.elementType },
            );

            if (this.placeHolderFilterBasic.length < 50) {
              this.placeHolderFilterBasic += userNoteOut.elementType + ',';
            }
          }
        });
        this.infoTimeLine.baseNotes = userNotes;
        this.infoTimeLineComplete.baseNotes = userNotes;
        this.build();
      },
      (error: any) => {

      }
    );
  }

  build() {
    if ((this.infoTimeLine.accessNotes &&
      this.infoTimeLine.baseNotes &&
      this.infoTimeLine.movementNotes &&
      this.infoTimeLine.reserveNotes &&
      this.infoTimeLine.docNotes) ||
      (this.pes && this.infoTimeLine.baseNotes && this.infoTimeLine.docNotes)) {
      this.finishCharge = true;
      const notes = this.transform(this.infoTimeLine);

      const filteredNotes = notes.filter((note) => note);
        console.error(notes)
      const sortedNotes = this.sortNotesChronologically(filteredNotes);
      const groupedNotes = this.groupNotesByDay(sortedNotes);
      this.days = this.objectToArray(groupedNotes);
    }
  }

  private getNotes(claimNumber) {
    return this.timelineService.getNotes(claimNumber);
  }

  initialize() {

    // return this.httpClient.get<Array<Diary>>(this.baseApiUrl + '/claims/activity/activitydictionary');

    const accessNotes = null;

    const baseNotes = null;

    const movementNotes = null;

    const reserveNotes = null;

    const docNotes = null;

    return { accessNotes, baseNotes, movementNotes, reserveNotes, docNotes };
  }

  initializeEmpty() {

    // return this.httpClient.get<Array<Diary>>(this.baseApiUrl + '/claims/activity/activitydictionary');

    const accessNotes = [];

    const baseNotes = [];

    const movementNotes = [];

    const reserveNotes = [];

    const docNotes = [];


    return { accessNotes, baseNotes, movementNotes, reserveNotes, docNotes };
  }

  addManualNote() {
    const modalRef = this.modalService.open(AddManualNoteComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    if(this.pes){
      modalRef.componentInstance.reportNumber = this.claim.reportNumber;
    } else {
      modalRef.componentInstance.claimNumber = this.claim.claimNumber;
    }

    modalRef.result.then((result) => {
      if (result) {
        console.log('aggiorna note utente ->', result);
        this.updateUserNotes();
      }
    });
  }

  private transform(notes: any): Note[] {

    const accessNotes = notes.accessNotes;
    const baseNotes = notes.baseNotes;
    const movementNotes = notes.movementNotes;
    const reserveNotes = notes.reserveNotes;
    const docNotes = notes.docNotes;
    const hasLeftColumn = movementNotes && movementNotes.length ||
      (docNotes && docNotes.length);
    const hasRightColumn = (accessNotes && accessNotes.length) ||
      (baseNotes && baseNotes.length) ||
      (reserveNotes && reserveNotes.length);
    console.log('access',accessNotes)
    console.log('base',baseNotes)
    console.log('movement',movementNotes)
    console.log('reserve',reserveNotes)
    console.log('doc',docNotes)

    return []
      .concat(
        this.transformNote(AccessNoteComponent, accessNotes),
        this.transformNote(BaseNoteComponent, baseNotes),
        this.transformNote(MovementNoteComponent, movementNotes),
        this.transformNote(ReserveNoteComponent, reserveNotes),
        this.transformNote(DocumentNoteComponent, docNotes)
      )
      .map(
        note => {
        if(note){
          note.data.hasLeftColumn = hasLeftColumn;
          note.data.hasRightColumn = hasRightColumn;
          return note;
        }
        }
      );
  }

  private transformNote(controller: any, notes: any[]) {
    if(notes != null){
      return notes.map(
        note => {
          return new Note(controller, note);
        }
      );
    }
  }

  private sortNotesChronologically(notes: Note[]) {
    return notes.sort(
      (a, b) => {
        if (this.orderDesc) {
          return b.data.date - a.data.date;
        } else {
          return a.data.date - b.data.date;
        }
      }
    );
  }

  private groupNotesByDay(notes: Note[]) {
    return notes.reduce(
      (groupedNotes, note) => {
        const date = note.data.date.toLocaleDateString();
        groupedNotes[date] = groupedNotes[date] || [];
        groupedNotes[date].push(note);
        return groupedNotes;
      }, {}
    );
  }

  private objectToArray(notes) {
    const array = [];
    for (const prop of Object.keys(notes)) {
      array.push(notes[prop]);
    }
    return array;
  }


  openFilterType() {
    this.showFilterType = true;
  }

  openFilterTypeBasic() {
    this.showFilterTypeBasicNote = true;
  }

  closeFilterTypeBasic() {
    this.filterSelectionBasicNoteDescr = '';

    let noSel = true;
    this.infoTimeLine.baseNotes = [];
    this.filterBasicNote.forEach(el => {
      if (el.sel) {
        noSel = false;
        this.filterSelectionBasicNoteDescr += el.descr + ' ';
        this.infoTimeLineComplete.baseNotes.forEach(element => {
          if (element.eventType === el.code) {
            this.infoTimeLine.baseNotes.push(element);
          }
        });


      }
    });

    if (noSel) {
      // this.infoTimeLine.movementNotes = this.infoTimeLineComplete.movementNotes;
      this.infoTimeLine.baseNotes = this.infoTimeLineComplete.baseNotes;
      // this.infoTimeLine.reserveNotes = this.infoTimeLineComplete.reserveNotes;
      // this.infoTimeLine.accessNotes = this.infoTimeLineComplete.accessNotes;
    }

    this.build();



    this.showFilterTypeBasicNote = false;
  }

  closeFilterType() {
    this.filterSelectionDescr = '';
    this.infoTimeLine = this.initializeEmpty();
    let noSel = true;
    this.filterType.forEach(el => {
      if (el.sel) {
        noSel = false;
        this.filterSelectionDescr += el.descr + ' ';
        if (el.code === 'MOV') {
          this.infoTimeLine.movementNotes = this.infoTimeLineComplete.movementNotes;
        } else if (el.code === 'BNOT') {
          this.infoTimeLine.baseNotes = this.infoTimeLineComplete.baseNotes;
          // this.filterSelectionBasicNoteDescr = '';
          // this.filterBasicNote.forEach(ele => {
          //   ele.sel = false;
          // });
        } else if (el.code === 'RSV') {
          this.infoTimeLine.reserveNotes = this.infoTimeLineComplete.reserveNotes;
        } else if (el.code === 'ACC') {
          this.infoTimeLine.accessNotes = this.infoTimeLineComplete.accessNotes;
        } else if (el.code === 'DOC') {
          this.infoTimeLine.docNotes = this.infoTimeLineComplete.docNotes;
        }
      }
    });

    if (noSel) {
      this.infoTimeLine.movementNotes = this.infoTimeLineComplete.movementNotes;
      this.infoTimeLine.baseNotes = this.infoTimeLineComplete.baseNotes;
      this.infoTimeLine.reserveNotes = this.infoTimeLineComplete.reserveNotes;
      this.infoTimeLine.accessNotes = this.infoTimeLineComplete.accessNotes;
      this.infoTimeLine.docNotes = this.infoTimeLineComplete.docNotes;
    }

    this.build();

    this.showFilterType = false;
  }

  reorder() {
    this.orderDesc = !this.orderDesc;
    this.build();

  }

  recharge(event: any) {
    console.log('aggiorna note utente da annullo ->');
    this.updateUserNotes();
  }
}
