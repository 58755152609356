import { LpcTranslationsKeys } from '../lpc-translations-keys';

export const LPC_FR_CH: LpcTranslationsKeys = {
  lpc_Investment_profiles: "Profils d'investissement",
  lpc_payment_method: "Mode de paiement",
  lpc_payment_type: "Type de paiement",
  lpc_investment_funds: "Fonds d'investissement",
  lpc_fund_type: "Type de fonds",
  lpc_profile_type: "Type de profil",
  lpc_elements: "éléments",
  lpc_fund_name: "Nom du fonds",
  lpc_profile_name: "Nom du profil",
  lpc_profiles: "Profils",
  lpc_funds: "Fonds",
  lpc_division_type: "Type de division",
  lpc_occurenceNumber: "Le nombre d'occurrences doit être supérieur à {{min}}",
  lpc_division_description: "Description de l'allocation",
  lpc_start_validity_date: "Date de début de validité",
  lpc_fund_description: "Description du fonds",
  lpc_ISIN_code: "Code ISIN",
  lpc_Allocation: "Allocation",
  lpc_fields_generic_error: "Les champs obligatoires sont incomplets ou contiennent des valeurs incorrectes",
  lpc_profile_details: "Détails du profil",
  lpc_portfolio_fund_display: "Affichage des fonds de portefeuille",
  lpc_payment_data: "Données de paiement",
  lpc_Warning_you_are_deleting_the_contractor: "Avertissement, vous êtes en train de supprimer le titulaire de la police. 'Voulez-vous continuer ?",
  lpc_Investment_amount: "Montant de l'investissement :",
  lpc_mov_status: "Titre de l'état",
  lpc_mov_Amount: "Montant du titre",
  lpc_ticket_status_label: "En attente de l'évaluation des parts",
  lpc_status_investment_label: "État de l'investissement",
  lpc_order_status_label: "État de la commande",
  lpc_selected_funds: "Fonds sélectionnés",
  lpc_Premium: "Prime",
  lpc_InvestmentByFund: "Investissements par fonds",
  lpc_Loading: "Frais",
  lpc_NetPremium: "Prime nette",
  lpc_IntFract: "Int. Fréquence",
  lpc_Fees: "Charges fixes",
  lpc_Taxes: "Impôts",
  lpc_GrossPremium: "Prime brute",
  lpc_FirstInstallment: "Premier versement",
  lpc_AnnualPremium: "Prime annuelle",
  lpc_NextInstallment: "Prochain versement",
  lpc_Guarantee: "Garantie",
  lpc_BenefitOnDate: "Prestations à la date",
  lpc_Benefit: "Prestation",
  lpc_AnnualSinglePremium: "Prime annuelle/unique",
  lpc_ActiveGuarantees: "Garanties actives",
  lpc_Enhancement: "Données financières",
  lpc_CalculationData: "Date de calcul",
  lpc_ControvaloreAllaData: "Valeur de marché à la date",
  lpc_AccumulationOfPrizes: "Somme des primes",
  lpc_TotalSurrender: "Rachat total",
  lpc_AccumulationOfReducedPremiums: "Somme des primes réduites",
  lpc_TotalBalance: "Solde total",
  lpc_EffectiveDate: "Date d'entrée en vigueur",
  lpc_Movement: "Mouvement",
  lpc_Movements: "Mouvements",
  lpc_booking_number: "Numéro de réservation",
  lpc_booking_policyData: "Données de la police",
  lpc_booking_assetData: "Données sur les actifs",
  lpc_PolicyLinked: "Polices liées",
  lpc_Motivo_Legame: "La motivation de la caution",
  lpc_ProposalIssueDate: "Date d'émission de la proposition",
  lpc_PolicyIssueDate: "Date d'émission de la police",
  lpc_ExpirationDate: "Date d'expiration",
  lpc_NextInstalmentDate: "Date du prochain versement",
  lpc_found_duplicate: "Avertissement",
  lpc_duplicate_subject_message: "Partie sélectionnée déjà présente parmi les bénéficiaires",
  lpc_SelectSettlement: "Sélectionner un règlement",
  lpc_You_must_select_the_settlement_to_perform_the_variation: "Vous devez sélectionner le règlement pour effectuer la variation",
  lpc_beneficiaryPercentage: "Pourcentage pour la partie",
  lpc_beneficiaryPercentage_greater: "doit être supérieur à",
  lpc_beneficiaryPercentage_lower: "doit être inférieur à",
  lpc_beneficiaryDuplicate: "Partie en double",
  lpc_NoOperations: "Aucune action disponible",
  lpc_confirm: "Confirmer",
  lpc_description: "Description",
  lpc_issueDate: "Date d'émission",
  lpc_effectiveDate: "Date d'entrée en vigueur",
  lpc_MovementData: "Annuler l'approbation",
  lpc_Are_you_sure_you_want_to_confirm_this_claim: "Vous êtes sûr de vouloir confirmer cette demande ?",
  lpc_Are_you_sure_you_want_to_cancel_this_claim: "Vous êtes sûr de vouloir annuler cette demande ?",
  lpc_warnings: "Avertissements",
  lpc_warning: "Avertissement",
  lpc_warning_message: "Cliquez sur 'DEMANDE d'AUTORISATION'' pour transmettre une demande d'approbation pour l'opération",
  lpc_ClaimCanceled: "Demande d'indemnisation annulée correctement",
  lpc_ClaimConfirmed: "Réclamation confirmée correctement",
  lpc_reserve: "enregistrer",
  lpc_questionnaire: "Questionnaire",
  lpc_bonus_amount: "Montant du bonus",
  lpc_life_detail_beneficiary: "Bénéficiaire",
  lpc_life_detail_percentage: "Pourcentage",
  lpc_life_detail_net_amount: "Montant net",
  lpc_life_detail_questionnaire: "Questionnaire",
  lpc_life_detail_beneficiaries: "Bénéficiaires",
  lpc_AppendixNumber: "Numéro de l'approbation",
  lpc_nessuna_variazione: "Pas de variation",
  lpc_appendixLabel: "Approbation du bénéficiaire",
  lpc_operation_termination_reason: "Raison d'annulation de l'opération",
  lpc_Lien_type_creditor: "Type de créancier",
  lpc_creditor: "Créancier",
  lpc_Open_lien: "Nantissement ouvert",
  lpc_assignment_holder_type: "Type de titulaire de l'affectation",
  lpc_assignment_holder: "Titulaire de l'affectation",
  lpc_assignment_amount: "Montant",
  lpc_assignment_policy_number: "Numéro de contrat",
  lpc_assignment_company: "Société",
  lpc_assignment_type: "Type d'affectation",
  lpc_assignment_benefit: "Prestation",
  lpc_amount_less_of_benefit: "Le montant doit être inférieur à la prestation.",
  lpc_open_assignment: "Affectation ouverte",
  lpc_sum_det_cancel_proposal: "annuler la proposition",
  lpc_sum_det_success_cancel: "La proposition a été annulée correctement",
  lpc_sum_det_success_cancel_prev: "Le devis a été annulé correctement",
  lpc_sum_det_confirm_cancel: "Vous confirmez que la proposition sera annulée ?",
  lpc_sum_det_confirm_cancel_prev: "Vous souhaitez confirmer l'annulation de ce devis ?",
  lpc_sum_det_confirm_prop_issue: "Une proposition sera émise. 'La confirmez-vous ?",
  lpc_sum_det_confirm_pol_issue: "Une police sera émise. 'La confirmez-vous ?",
  lpc_health_questionnaire: "Questionnaire sur la santé",
  lpc_quotation_show_details: "Afficher les détails",
  lpc_quotation_premium_details: "Détails de la prime",
  lpc_quotation_warranties_details: "Détails des garanties",
  lpc_quotation_commissions_details: "Commission",
  lpc_Reference_Third_Party: "Tiers de référence",
  lpc_policyHolder: "Titulaire de la police",
  lpc_DelegateEqualToPolicyHolder: "Le délégué est-il identique au titulaire de la police ?",
  lpc_LegalGuardianEqualToPolicyHolder: "Le tuteur légal est-il identique au titulaire de la police ?",
  lpc_ThirdPayerEqualToPolicyHolder: "Le tiers payeur est-il identique au titulaire de la police ?",
  lpc_DelegateEqualToPolicyHolderWarning: "Partie pour le rôle Délégué identique à celui du titulaire de la police",
  lpc_LegalGuardianEqualToPolicyHolderWarning: "Partie pour le rôle Tuteur légal identique à celui du titulaire de la police",
  lpc_ThirdPayerEqualToPolicyHolderWarning: "Partie pour le rôle Tiers payeur identique à celle du titulaire de la police",
  lpc_but_confirm_accept: "confirmer et accepter",
  lpc_Date_Start_Variation: "Variation de la date de début",
  lpc_New_Frequent_Payment: "Nouvelle fréquence de paiement",
  lpc_start_date_profit: "Date de début du profit",
  lpc_end_date_profit: "Date de fin du profit",
  lpc_last_rate_profit: "profit du dernier taux",
  lpc_last_profit: "dernier profit",
  lpc_Fund_Code: "Code du fonds",
  lpc_OperationSynstesis: "Synthèse de l'opération",
  lpc_PreviusPerceent: "Pourcentage précédent",
  lpc_PreviusValue: "Valeur précédente",
  lpc_NumberOfUnit: "Nombre précédent d'unités",
  lpc_PostPErcent: "Pourcentage d'affichage",
  lpc_PostValue: "Post-value",
  lpc_PostNumberOfUnit: "Numéro de poste du devis",
  lpc_Dati: "Données",
  lpc_PIP_Data: "Données PIP",
  lpc_PIP_General: "Données générales",
  lpc_PIP_Contribution: "Contribution PIP",
  lpc_HolderData: "Données assurées",
  lpc_RiskData: "Données sur les risques",
  lpc_IBAN_error: "IBAN n'est pas correct",
  lpc_BACK: "Retour",
  lpc_ACTIONS: "Actions",
  lpc_DETAIL: "Détail",
  lpc_CANCEL: "Annuler la proposition",
  lpc_CONFIRM_PROPOSAL: "Confirmer la proposition",
  lpc_CONFIRM_POLICY: "Confirmer la police",
  lpc_not_linked_detail: "Détail de {{name}}",
  lpc_quote_number: "Numéro de devis",
  lpc_valid_quote: "Devis valide",
  lpc_BUY_PREV: "Acheter",
  lpc_EDIT_PREV: "Éditer",
  lpc_EDIT: "Éditer",
  lpc_CANCEL_PREV: "Supprimer",
  lpc_modify_quote: "Modifier le devis",
  lpc_modify_proposal: "Modifier la proposition",
  lpc_buy_quote: "Acheter le devis",
  lpc_Close_assignment: "Fermer l'affectation",
  lpc_Close_lien: "Fermer le nantissement",
  lpc_disinvestment_funds: "Fonds de cession",
  lpc_error_mandatory_fields: "Les champs marqués en rouge sont obligatoires ou contiennent des valeurs incorrectes",
  lpc_disinvestment_msg: "{{amount}} à céder, ou {{percent}}",
  lpc_disinvestment_allocation_msg: "L'allocation d'investissement dépasse le montant disponible",
  lpc_maximum_percentage: "Pourcentage maximum accepté",
  lpc_maximum_amount: "Montant maximum accepté",
  lpc_minimum_percentage: "Pourcentage minimum accepté",
  lpc_minimum_amount: "Montant minimum accepté",
  lpc_investment_msg: "{{amount}} à investir, ou {{percent}}",
  lpc_Fields_marked_in_red_are_required: "Les champs marqués en rouge sont obligatoires",
  lpc_amount_format_validation: "Le montant n'est pas valide, il contient le mauvais séparateur décimal, veuillez le corriger en utilisant '.'",
  lpc_select_at_least_one_beneficiary: "Sélectionner au moins un bénéficiaire",
  lpc_quote_due_date: "Date d'échéance du devis",
  lpc_roles_variation: "Rôles",
  lpc_fiscal_data: "Données fiscales",
  lpc_data_calc: "Date de calcul",
  lpc_tipo_liquidazione: "Type de règlement",
  lpc_valore_polizza: "Valeur de la police",
  lpc_imponibile_imp: "Taxe imposable",
  lpc_imposta_base: "Base fiscale",
  lpc_imposta_calcol: "Taxe calculée",
  lpc_imposta_da_add: "Taxe à percevoir",
  lpc_imposta_add: "Taxé",
  lpc_imposta_bollo: "Timbre fiscal",
  lpc_fiscal_data_detail: "Détail des données fiscales",
  lpc_quote_white_list: "Liste blanche de devis",
  lpc_data_rif: "Date de référence",
  lpc_tipo_perc: "Pourcentage WL",
  lpc_CancellInstallment: "Demande récapitulative d'annulation d'un versement",
  lpc_DescOperazione: "Description de l'opération",
  lpc_RateAmount: "Montant total du versement",
  lpc_OPEN_LATEST_VERSION: "Ouvrir la dernière version",
  lpc_TOTAL_INVESTED: "Total investi",
  lpc_REMAINING_AMOUNT: "Montant restant",
  lpc_descrizione_rischio: "Description du risque",
  lpc_prestazione_ridotta_iniz: "Réduction de la prestation initiale",
  lpc_prestazione_rivalutata: "Réduction de la prestation réévaluée",
  lpc_provvigioni_da_recuperare: "Commission à récupérer",
  lpc_reg41: "Nom du destinataire des communications",
  lpc_disinvestment_amount: "Montant de la cession",
  lpc_occurrenceNumber: "Numéro de l'événement",
  lpc_frequecy: "Fréquence",
  lpc_the_occurenceNumber_must_be_a_value: "Le nombre d'occurrences doit être supérieur à {{min}}",
  lpc_disinvestment_range: "Saisir un montant à céder supérieur à {{max}} et inférieur ou égal à {{min}}",
  lpc_state: "État",
  lpc_substate: "Sous-état",
  lpc_product_type: "Type de produit",
  lpc_managementnode: "Nœud de gestion",
  lpc_subagency: "Sous-agence",
  lpc_branch_name: "Branche",
  lpc_agreement: "Accord",
  lpc_payment_frequency: "Fréquence de paiement",
  lpc_mean_of_payment: "Moyens de paiement",
  lpc_Subcause: "souscause",
  lpc_premium_payment_suspension_date: "Date de suspension du paiement de la prime",
  lpc_imposta_sostitutiva: "Taxe de substitution",
  lpc_novazione_contr: "Novation contractuelle",
  lpc_acc_premium_initial_2011: "Accumuler la prime au 31/12/2011 à partir de",
  lpc_amount_intital_2011: "Montant 31/12/2011 à partir de",
  lpc_acc_premium_2011: "Accumuler la prime au 31/12/2011",
  lpc_amount_2011: "Montant 31/12/2011",
  lpc_acc_premium_initial_2014: "Accumuler la prime au 30/06/2014 à partir de",
  lpc_acc_premium_2014: "Accumuler la prime au 30/06/2014",
  lpc_amount_intital_2014: "Montant au 30/06/2014 à partir de",
  lpc_amount_2014: "Montant au 30/06/2014",
  lpc_acc_premium_initial_2022: "Montant initial des primes au 31/12/2022",
  lpc_acc_premium_2022: "Somme des primes au 31/12/2022",
  lpc_amount_intital_2022: "Capital initial et intérêts au 31/12/2022",
  lpc_amount_2022: "Capital et intérêts au 31/12/2022",
  lpc_acc_premium_intial_tax_change: "Accumulation de changement de la taxe sur les primes à partir du",
  lpc_amount_initial_tax_change: "Changement du montant de la taxe à partir du",
  lpc_acc_premium_tax_change: "Accumulation de changement de la taxe sur les primes",
  lpc_amount_tax_change: "Modification du montant des taxes",
  lpc_regime_change_date: "Date de changement de taxe",
  lpc_please_enter_a_disinvestment_amount: "Veuillez saisir un montant de désinvestissement pour le fonds : {{fonds}}",
  lpc_please_select_at_least_a_disinvestment: "Veuillez sélectionner au moins une cession",
  lpc_reg41Msg: "Destinataire des communications",
  lpc_cost: "Coût",
  lpc_paymentFrequencyInterest: "Fréq. 'Inter",
  lpc_netpremium: "Prime nette",
  lpc_grosspremium: "Prime brute",
  lpc_information: "Information :",
  lpc_reinvest_data_detail: "Détail du réinvestissement",
  lpc_polizza: "Police",
  lpc_data_effetto_mov: "Date d'entrée en vigueur",
  lpc_netto_liquidazione: "Montant net du règlement",
  lpc_netto_reinvestire: "Montant net du réinvestissement",
  lpc_stato_reinvest: "Statut de réinvestissement des règlements",
  lpc_reinvested_settlements: "Règlements réinvestis",
  lpc_loans: "Prêts",
  lpc_elaboration_date: "Date de traitement",
  lpc_calculation_date: "Date de calcul",
  lpc_annuity_data: "Données sur les rentes",
  lpc_extra_premium: "Ajouter une prime",
  lpc_loan_interest: "Intérêts de l'emprunt",
  lpc_fractionation_interest: "Intérêts sur le paiement fractionné",
  lpc_default_interest: "Intérêt par défaut",
  lpc_reactivation_interest: "Intérêt pour la réactivation",
  lpc_installment_detail: "Détail des versements",
  lpc_effectiveAnnuityDate: "Date d'entrée en vigueur de la rente",
  lpc_firstInstallmentDate: "Date du premier versement",
  lpc_expiryAnnuityDate: "Date d'expiration de la rente",
  lpc_amountFirstInstallment: "Montant du premier versement",
  lpc_initialAnnuityAmount: "Montant initial de la rente",
  lpc_periodicityInstallmentAnnuity: "Rente à versements périodiques",
  lpc_typePaymentAnnuity: "Type d'annuité de paiement",
  lpc_performanceType: "Type de performance",
  lpc_annuityType: "Type de rente",
  lpc_annuity_detail: "Détail de la rente",
  lpc_canceled_liquidation_detail: "Détail de la liquidation annulée",
  lpc_Percentage: "Pourcentage",
  lpc_loan_data: "Données relatives aux prêts",
  lpc_maxLoanAmount: "Montant maximum payable",
  lpc_loanRepaymentProcedure: "Procédure de remboursement du prêt",
  lpc_loanAmount: "Montant du prêt",
  lpc_rateType: "Type de taxe sur les intérêts",
  lpc_maxLoanAmountKO: "€ 0 (erreur dans le calcul du montant maximum à payer)",
  lpc_grant_date: "Date d'attribution",
  lpc_loan_amount: "Montant du prêt",
  lpc_return_way: "Voie du retour",
  lpc_interest_rate_type: "Type de taux d'intérêt",
  lpc_residual_amount: "Montant résiduel",
  lpc_refund_type: "Type de remboursement",
  lpc_refund_amount: "Montant du remboursement",
  lpc_select_lpc_refund_type: "Sélectionnez un type de remboursement",
  lpc_leftInsuredAmount: "Capitaux résiduels assurés",
  lpc_insuredAmount: "Montant investi",
  lpc_reactivatedCapital: "Capital réactivé",
  lpc_premium_reactivation: "réactivation_prime",
  lpc_negative_amount: "Montant résiduel négatif",
  lpc_zero_amount: "Montant résiduel absent, veuillez insérer un montant supérieur à 0",
  lpc_error_mandatory_selected_type_of_refund: "Veuillez sélectionner un type de remboursement",
  lpc_select_refund: "Veuillez sélectionner au moins un prêt",
  lpc_selected_profiles: "Profils sélectionnés :",
  lpc_coupon_beneficiary: "Bénéficiaire du coupon",
  lpc_bookings: "Réservations",
  lpc_booking_type: "Type de réservation",
  lpc_booking_state: "État",
  lpc_booking_effective_date: "Date d'entrée en vigueur",
  lpc_booking_extrapremiumHealth: "Prime d'assurance maladie",
  lpc_booking_extrapremiumProf: "Prime professionnelle",
  lpc_booking_extrapremiumSport: "Prime sports suppl.",
  lpc_booking_extrapremiumOther: "Autres primes supplémentaires",
  lpc_booking_effectiveDate: "Date d'entrée en vigueur",
  lpc_booking_detail_label: "Détails de la réservation",
  lpc_Rischi: "Couvertures",
  lpc_booking_destinationNode: "Nœud de destination",
  lpc_booking_cause: "Cause",
  lpc_PolicyData: "Données de la police",
  lpc_booking_cancellationDate: "Date d'annulation",
  lpc_extra_premiums: "Ajouter des primes",
  lpc_error_reservation_cancel: "Erreur sur l'annulation du numéro {{descr}} {{num}}",
  lpc_success_reservation_cancel: "L'annulation du {{descr}} numéro {{num}} a été confirmée",
  lpc_incomplete_disinvestment_for_the_funds: "Cession incomplète pour les fonds",
  lpc_Sinistro_morte: "Demande d'indemnisation en cas de décès",
  lpc_Sinistro_invalidita: "Demande d'indemnisation pour invalidité",
  lpc_Sinistro_inabilità: "Demande d'indemnisation pour incapacité",
  lpc_Malattia_grave: "Demande d'indemnisation pour maladie grave",
  lpc_insured_form: "Formulaire assuré",
  lpc_other_contract_list: "Liste des autres contrats",
  lpc_modified_personal_data_for_the_subject: "Données personnelles modifiées pour la partie",
  lpc_subject_roles: "Rôles des parties",
  lpc_no_variation_modpd: "Aucun changement n'a été apporté, modifier une partie",
  lpc_DeductiblePremium: "Prime déductible",
  lpc_firstterm: "Première échéance",
  lpc__1TERM_ATOOL: "Date d'activation de l'outil",
  lpc__1TERM_MTOOL: "Modification de l'outil",
  lpc__1TERM_DTOOL: "Date de suppression de l'outil",
  lpc_management_fees: "Frais de gestion",
  lpc_investment_not_found: "Il n'y a pas d'éléments à sélectionner",
  lpc_No_policies: "Il n'y a pas de police pour la partie modifiée",
  lpc_variation_convention: "Variation de l'accord",
  lpc_andamento_polizza_alla_data: "Tendance de la police à ce jour",
  lpc_appendice_dichiarativa: "Déclaration d'approbation",
  lpc_policy_number_dot: "Police n°",
  lpc_investment_sum_must_be100: "La somme des investissements doit être égale à 100 %",
  lpc_Operation_detail: "Détails de l'opération",
  lpc_Notwithstanding_any_ongoing_operations: "Les valeurs peuvent être sujettes à des changements en 'raison des opérations en cours",
  lpc_percentage_max_zero: "insérer un pourcentage supérieur à zéro",
  lpc_Mostra_tutti: "Afficher tout",
  lpc_Visualizza_dettaglio: "Voir le détail",
  lpc_consultazione_storica: "Consultation de l'historique",
  lpc_dettaglio_movimento: "Détail du mouvement",
  lpc_mensile: "mensuel",
  lpc_annuale: "annuel",
  lpc_sixmonthly: "semestriel",
  lpc_trimestrale: "trimestriel",
  lpc_Titolo_non_incassato: "stock non collecté",
  lpc_Rid: "DDP",
  lpc_In_attesa_valorizzazione_quote: "En attendant la valorisation des actions investies",
  lpc_switch_di_perequazione: "Commutateur programmé",
  lpc_SELEZIONA: "-- SÉLECTIONNER --",
  lpc_settimanale: "hebdomadaire",
  lpc_Valido: "Valide",
  lpc_quotidiano: "quotidien",
  lpc_Assegno: "Vérifier",
  lpc_Bonifico: "Virement bancaire",
  lpc_Sdd: "Ordre de prélèvement automatique",
  lpc_severeDisabilityMsg: "Bénéficiaire souffrant d'un handicap grave",
  lpc_benDg: "Bénéficiaire désactivé",
  lpc_distribution: "Allocation par",
  lpc_Distribution_amount_exceeds: "Le montant de la distribution dépasse le montant total disponible pour le règlement",
  lpc_distribution_invalid_greater: "Le {{type}} n'est pas valide, il doit être supérieur à {{min}}",
  lpc_distribution_invalid_greater_equal: "Le {{type}} n'est pas valide, il devrait être inférieur ou égal à {{max}}",
  lpc_perc: "Pourcentage",
  lpc_amount: "Montant",
  lpc_Please_insert_a_distribution: "Veuillez insérer une distribution",
  lpc_For_the_Beneficiary: "Pour le bénéficiaire",
  lpc_global_distribution: "Le montant total à régler doit être inférieur ou égal à {{totalAmount}}",
  lpc_invalid_char: "Le format du {{type}} contient des caractères incorrects",
  lpc_toDistribute: "à distribuer",
  lpc_noClaims: "Aucune déclaration n'a été trouvée",
  lpc_Data_sinistro: "Date de la demande d'indemnisation",
  lpc_Data_Pervenimento_Denuncia: "Date de réception de la déclaration de sinistre",
  lpc_Data_Denuncia_Sinistro: "Date de la notification du sinistre",
  lpc_Tipo_sinistro: "Type de demande d'indemnisation",
  lpc_inserisci_denuncia: "insérer une demande d'indemnisation",
  lpc_Conferma_inserimento_denuncia: "Confirmation de l'insertion de la demande d'indemnisation",
  lpc_Denuncia_inserita_correttamente: "Réclamation insérée correctement",
  lpc_Stato_denuncia: "État de la demande d'indemnisation",
  lpc_marital_status: "Statut matrimonial",
  lpc_fiscal_code: "Code fiscal",
  lpc_birthdate: "Date de naissance",
  lpc_address: "Adresse",
  lpc_sex: "Sexe",
  lpc_annulla_denuncia: "annuler la demande d'indemnisation",
  lpc_modifica_denuncia: "modifier la demande d'indemnisation",
  lpc_conferma_denuncia: "confirmer la demande d'indemnisation",
  lpc_new_beneficiary: "Nouveau bénéficiaire",
  lpc_Seleziona: "Sélectionner",
  lpc_insert_phisical_sbj_for: "Insérer une personne physique pour le rôle {{rôle}}",
  lpc_insert_legal_sbj_for: "Insérer une personne morale pour le rôle {{rôle}}",
  lpc_payment_incomplete: "Détails de paiement incomplets",
  lpc_add_mandatory_linkedroles: "Saisir les rôles obligatoires pour la partie saisie",
  lpc_beneficiary_owner_recipient_must_be_physical: "Le bénéficiaire effectif doit être une personne physique",
  lpc_enter_the_beneficial_owners_of_the_recipient: "Saisir les bénéficiaires effectifs du destinataire",
  lpc_global_percentage_must_be_a_maximum_100: "le pourcentage global doit être au maximum de 100",
  lpc_overall_percentage_must_equal100: "le pourcentage global doit être égal à 100",
  lpc_Questionario_deve_essere_compilato: "Le questionnaire doit être complété",
  lpc_enter_mandatory_data: "Saisir les données obligatoires",
  lpc_enter_an_adult: "Saisir une personne adulte",
  lpc_enter_a_beneficial_owner: "Saisir un bénéficiaire effectif",
  lpc_enter_a_physical_beneficial_owner: "Saisir une personne physique comme bénéficiaire effectif",
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: "Le pourcentage global de bénéficiaires effectifs ne doit pas dépasser 100",
  lpc_beneficial_owners_percentages_must_be_greater_than0: "Les pourcentages de bénéficiaires effectifs' doivent être supérieurs à 0,0",
  lpc_effective_holder_perc: "{{benefName}}'s bénéficiaire effectif {{linkedName}}'s le pourcentage est moins que le minimum autorisé de {{linkedPerc}}%",
  lpc_effective_holder_duplicated: "Détenteur efficace dupliqué",
  lpc_enter_the_minimum_number_of_subjects: "Saisir le nombre minimum de parties",
  lpc_percentage_must_be_greater_than_0: "Le pourcentage doit être supérieur à 0,0",
  lpc_enter_free_text: "Saisir un texte libre",
  lpc_beneficiary_executor: "Exécutant bénéficiaire",
  lpc_effective_recipient: "destinataire effectif",
  lpc_recipient_owner: "Propriétaire du destinataire",
  lpc_beneficial_owner_of_beneficiary: "Propriétaire effectif",
  lpc_confirmed_claim_modify: "Modifier demande d'indemnisation confirmée",
  lpc_claim_modified_correctly: "La demande d'indemnisation a été modifiée correctement",
  lpc_Assicurato: "Assuré",
  lpc_Assicurati: "Assurés",
  lpc_holderType: "Type d'emploi du demandeur'",
  lpc_ageRetirement: "L'âge à la retraite",
  lpc_accessTransfer: "Demandeur de transfert",
  lpc_emptyContribution: "Contribution de l'employeur",
  lpc_firstEmployment: "Date de début de l'emploi",
  lpc_oldUnderWritt: "Ancien inscrit",
  lpc_underWitt1993: "Inscrit le 29/4/1993",
  lpc_maturato2001: "Courus avant le 31/12/2000",
  lpc_maturato2006: "Courus jusqu'au 31/12/2006",
  lpc_riduzImponibiTFR: "Réduction de l'ISF imposable",
  lpc_aliquotaTFR: "Taux d'ISF (imposition avant 2001)",
  lpc_PIP_Contribution_Type: "Type de contribution",
  lpc_YES: "Oui",
  lpc_PIP: "Régime de pension personnel",
  lpc_PIP_Contribution_Percentage: "Pourcentage",
  lpc_PIP_Contribution_Amount: "Montant",
  lpc_transfer_data: "Transfert de données",
  lpc_unit_questionnaire: "Questionnaire de l'unité",
  lpc_amount_reimbured: "Montant à rembourser",
  lpc_Descr_not_handled: "Description non gérée pour ce code d'opération",
  lpc_select_transfer: "Sélection de transfert",
  lpc_fiscalFolderDataPost2006: "Système fiscal après 2006",
  lpc_fiscalFolderData20012006: "Système fiscal 2001 - 2006",
  lpc_fiscalFolderDataBefore2001: "Système fiscal antérieur à 2001",
  lpc_filledOut: "Complété",
  lpc_blank: "Blanc",
  lpc_desc_previousPenFund: "Caisse de pension précédente",
  lpc_companyPreviousPenFund: "Nom de la société de l'ancienne caisse de pension",
  lpc_typePreviousPenFund: "Type de caisses de pension précédentes",
  lpc_note: "Notes",
  lpc_Benef_irrevocabile: "Bénéficiaire irrévocable",
  lpc_PIP_Transfer: "Transferts d'autres fonds",
  lpc_requestDate: "Date de la demande",
  lpc_Type_Previous_Pension_Fund: "Type de caisses de pension précédentes",
  lpc_Previous_Pension_Fund: "Caisse de pension précédente",
  lpc_Import_Transfert: "Montant du transfert",
  lpc_Note: "Notes",
  lpc_relief: "Allégement fiscal 2022",
  lpc_selected_settlement: "Règlement sélectionné",
  lpc_EDIT_ANONYMOUS_PREV: "Éditer",
  lpc_invalid_quote: "Devis annulé",
  lpc_reasonForRequest: "Motif de la demande",
  lpc_advancePaymentAmount: "Montant de l'avance",
  lpc_repaidAdvance: "Avance remboursée",
  lpc_PIP_AdvancedPayment: "Paiements anticipés",
  lpc_requestReason: "Motif de la demande",
  lpc_notRepaidAmount: "Montant non encore remboursé",
  lpc_fiscalDate: "Date d'entrée en vigueur",
  lpc_previousPension: "Régime de pension précédent",
  lpc_fiscalCompetence: "Compétence fiscale",
  lpc_settlementStatus: "État de paiement anticipé",
  lpc_repaidAmount: "Montant remboursé",
  lpc_advancePaymentTransfer: "Avances sur transferts",
  lpc_policy_factors: "Facteurs des polices",
  lpc_asset_factors: "Facteurs d'actifs",
  lpc_Date_Variation: "Période de variation",
  lpc_penalties_amount: "Montant des pénalités",
  lpc_tax_amount: "Montant des impôts",
  lpc_warranties_detail: "Détail des garanties",
  lpc_fixedCharges: "Charges fixes",
  lpc_catastrophe: "Catastrophe supp. Prime",
  lpc_health: "Santé suppl. Prime",
  lpc_other: "Autre suppl. Prime",
  lpc_professional: "Professionnel suppl. Prime",
  lpc_sports: "Sports suppl. Prime",
  lpc_collectionFee: "Frais de recouvrement",
  lpc_lambdaFee: "Frais lambda",
  lpc_managementFee: "Frais de gestion",
  lpc_purchaseFee: "Frais d'achat",
  lpc_continue_button: "Continuer",
  lpc_confirm_button: "Confirmer",
  lpc_authorization_request: "Demande d'autorisation",
  lpc_questionSTR_invalid: "La réponse à la question : {{question}}, n'est pas correcte",
  lpc_questionNUM_invalid: "Montant saisi pour la question : {{question}} n'est pas correct",
  lpc_Beneficiaryowner: "Propriétaire effectif",
  lpc_confirm_operation: "Confirmer l'opération",
  lpc_financial_questionnaire: "Questionnaire financier",
  lpc_avc_questionnaire: "Questionnaire AVC",
  lpc_confirm_variation: "Confirmer la variation",
  lpc_Declarative_Appendix: "Déclaration d'approbation",
  lpc_investment_funds_step: "Fonds d'investissement",
  lpc_disinvestment_profile_step: "Profils de cession",
  lpc_startDate: "Date de début",
  lpc_end_date: "Date de fin",
  lpc_six_monthly: "Semestriel",
  lpc_quarterly: "Trimestriel",
  lpc_weekly: "Hebdomadaire",
  lpc_monthly: "Mensuel",
  lpc_annual: "Annuel",
  lpc_daily: "Quotidien",
  lpc_start_date_cannot_be_earlier_than_the_effective_date: "La date de début ne peut être antérieure à la date d'entrée en vigueur {{value}}",
  lpc_start_date_must_be_earlier_than_the_end_date: "La date de début doit être antérieure à la date de fin",
  lpc_contract_value: "Valeur du contrat",
  lpc_invested_amount: "Montant investi",
  lpc_funds_details_and_summary: "Détails et résumé du fonds",
  lpc_separate_management_detail: "Détail de gestion séparé",
  lpc_denuncia_sinistro: "Notification de sinistre",
  lpc_duration_in: "Durée en",
  lpc_years: "années",
  lpc_months: "mois",
  lpc_days: "jours",
  lpc_policy_number: "Numéro de police",
  lpc_proposal_number: "Numéro de la proposition",
  lpc_LifeBeneficiary: "Bénéficiaire Vie",
  lpc_DeathBeneficiary: "Bénéficiaire en cas de décès",
  lpc_NO: "Non",
  lpc_claim_data: "",
  lpc_node: "Nœud",
  lpc_sbj: "Partie",
  lpc_empty: "vide",
  lpc_search_result: "Résultats de la recherche",
  lpc_show_elemets: "Afficher les éléments",
  lpc_insured: "Assuré",
  lpc_notification_status: "État de la notification",
  lpc_nuova_denuncia: "nouvelle notification",
  lpc_insured_policy: "Polices assurées",
  lpc_product: "Produit",
  lpc_find: "Trouver",
  lpc_status: "État",
  lpc_fund_trend: "Tendance du fonds",
  lpc_category_fund: "Profil/Fonds",
  lpc_equivalent_on_date: "Équivalent à la date",
  lpc_quotes_number: "Nombre d'unités",
  lpc_damages_policy: "Police d'assurance non-Vie",
  lpc_life_policy: "Police Vie",
  lpc_next_installment_premium: "Prime de la prochaine échéance",
  lpc_no_result: "Aucun résultat",
  lpc_data_value: "Action à valeur de date",
  lpc_paidAmount: "Montant payé",
  lpc_total_gross_surrender: "Rachat brut total",
  lpc_left_premiums_paid: "Les primes restant ont été payées",
  lpc_left_premiums_amount: "Montant des primes restant",
  lpc_portfolio_volatility_on_subscription_date: "Volatilité du portefeuille à la date de souscription",
  lpc_portfolio_volatility: "Volatilité du portefeuille le",
  lpc_andamento_controvalore: "Tendance de la valeur de marché de la police",
  lpc_policy_trend: "Tendance de la police",
  lpc_calculate: "Calculer",
  lpc_frequency: "Fréquence",
  lpc_date_variation_step: "Variation de la date",
  lpc_quotation_step: "Devis",
  lpc_confirm_variation_step: "Confirmer la variation",
  lpc_financial_questionnaire_step: "Questionnaire financier",
  lpc_close_button: "Fermer",
  lpc_Operation_of_executed_correctly: "L'opération de {{operation}} s'est déroulée correctement",
  lpc_AuthorizatioPolicyForwarded: "La demande d'autorisation pour le numéro de police {{policyNumber}} a été transmise à l'utilisateur approprié",
  lpc_are_you_sure_you_want_to_cancel_this_operation: "Voulez-vous vraiment annuler cette opération ?",
  lpc_cancel_button: "Annuler",
  lpc_cancel_reservation: "Annuler la réservation",
  lpc_system_error: "Erreur système",
  lpc_generic_error_title: "Erreur",
  lpc_select_label: "Sélectionner",
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: "Avertissement, vous êtes en train de supprimer le titulaire de la police. 'Voulez-vous continuer ?",
  lpc_factor_must_be_equal: "Le {{facteur}} doit être égal à {{montant}}",
  lpc_factor_must_be_greater_than: "Le {{facteur}} doit être supérieur à {{montant}}",
  lpc_factor_must_be_less_than: "Le {{facteur}} doit être inférieur à {{montant}}",
  lpc_Please_enter_threshold_percentage_value: "Fonds: {{value}} - Veuillez saisir le pourcentage de seuil",
  lpc_please_enter_a_value_between: "Veuillez saisir une valeur comprise entre {{min}} et {{max}}",
  lpc_variation_step_label: "Variation",
  lpc_contractual_option: "Options contractuelles",
  lpc_costs_step_label: "Coûts",
  lpc_variation_detail_step_label: "Détails de la variation",
  lpc_ongoing_operations: "Les valeurs peuvent être sujettes à des changements en 'raison des opérations en cours",
  lpc_exchange_rate: "Taux de change",
  lpc_last_share_value: "Dernière valeur de l'action",
  lpc_movement_description: "Description du mouvement",
  lpc_settlement_amount: "Montant du règlement",
  lpc_Annual_SinglePremium: "Prime annuelle/unique",
  lpc_policy_address: "Adresse de la police",
  lpc_holders: "Détenteurs",
  lpc_last_revaluation: "Dernière réévaluation",
  lpc_revalueted_benef: "Bénéfice réévalué",
  lpc_investment: "Investissements",
  lpc_position_number: "Numéro de position",
  lpc_invested_premium: "Prime investie",
  lpc_operation_details: "Détails de l'opération",
  lpc_contract: "Contrat",
  lpc_dis_date: "Date de la cession",
  lpc_gross_amount: "Montant brut",
  lpc_net_amount: "Montant net",
  lpc_net: "Net",
  lpc_quote: "Devis",
  lpc_quote_and_save: "Établir un devis et enregistrer",
  lpc_info_investment_complete: "Information : l'investissement est terminé",
  lpc_effective_holders: "Bénéficiaires effectifs",
  lpc_effective_holder: "Propriétaire effectif",
  lpc_third_party_role: "Tiers payant",
  lpc_lien_credit: "Type de créancier",
  lpc_end_validity_date: "Date de fin de validité",
  lpc_start_date: "Date de début",
  lpc_unit_linked_value: "Valeur en unités de compte",
  lpc_allocation_profile: "Profil d'allocation",
  lpc_search_filter: "Filtre de recherche",
  lpc_expiry_option_detail: "Détail de l'option d'expiration",
  lpc_annuity_frequency: "Fréquence des rentes",
  lpc_annuity_payment_type: "Type de paiement de la rente",
  lpc_percentage_reversibility: "Pourcentage de réversibilité",
  lpc_reversionary: "Bénéficiaire désigné",
  lpc_certain_revenue_years: "Certaines années de revenus",
  lpc_postponement_type: "Type de ajournement",
  lpc_postponement_years: "Années de ajournement",
  lpc_clauses: "Clauses",
  lpc_active_contractual_option: "Options contractuelles actives",
  lpc_expiry_option: "Option d'expiration",
  lpc_revaluation_history: "Historique des réévaluations",
  lpc_monetary_fund: "Fonds monétaire",
  lpc_benefit_effect_date: "Date d'entrée en vigueur de la prestation",
  lpc_initial_benefit: "Prestation initiale",
  lpc_movement_update: "Mise à jour du mouvement",
  lpc_update_effect_date: "Date d'entrée en vigueur de la mise à jour",
  lpc_net_rate: "Taux net",
  lpc_macro_category: "Catégorie macro",
  lpc_quotation_frequency: "Fréquence des devis",
  lpc_volatility: "Volatilité",
  lpc_volatility_date: "Date de la volatilité",
  lpc_start_quotation: "Devis initial",
  lpc_last_quotation: "Dernier devis",
  lpc_valuation: "Évaluation",
  lpc_percentage_total: "Pourcentage total",
  lpc_euro_valuation_not_fund_currency: "L'évaluation est exprimée en euros et non dans la devise du fonds",
  lpc_disinvstements: "Cessions",
  lpc_payment_amount: "Montant du paiement",
  lpc_surrender_distribution: "Distribution du rachat",
  lpc_formula: "Formule",
  lpc_roles_connected_benef: "Rôles liés au bénéficiaire",
  lpc_quotation_details: "Détail du devis",
  lpc_Disinvestmentsfunds: "Fonds de cession",
  lpc_same_fund_distribution: "Distribution du fonds identique au paiement initial",
  lpc_not_same_fund_distribution: "Distribution du fonds modifiée à partir du paiement initial",
  lpc_guarantees_oveview: "Aperçu des garanties",
  lpc_calculate_volatility: "Calculer la volatilité des actifs",
  lpc_benef_death_not_same: "Le bénéficiaire du décès ne peut pas être l'assuré",
  lpc_global_percentage_equal_hundred: "Le pourcentage total doit être égal à 100",
  lpc_effective_holder_natural_person_req: "{{benefCat}} {{benefRole}} - La partie {{linkedName}} en tant que bénéficiaire effectif doit être une personne physique",
  lpc_benefPresente_default_msg: "La partie a déjà été sélectionnée comme bénéficiaire dans la même catégorie",
  lpc_effective_holder_duplicate: "{{linkedName}} a déjà été saisi en tant que {{benefName}}'s bénéficiaire effectif",
  lpc_effective_holder_adult_req: "{{benefCat}} {{benefRole}} - La partie {{linkedName}} en tant que bénéficiaire effectif doit être un adulte",
  lpc_investment_plan: "Plan d'investissement",
  lpc_contract_detail: "Détail du contrat",
  lpc_variation_reason: "Motif de la variation",
  lpc_investment_plan_detail: "Détail du plan d'investissement",
  lpc_revaluation_rate: "Taux de réévaluation",
  lpc_benefit_commencement_date: "Prestation initiale",
  lpc_update_effective_date: "Date d'entrée en vigueur de la mise à jour",
  lpc_last_odds_value: "Dernière valeur de la cote",
  lpc_total: "Total",
  lpc__EFFOP: "Date d'entrée en vigueur",
  lpc__OPZFR: "Paiement échelonné",
  lpc__OPZPM: "Mode de paiement",
  lpc__OPBEN: "Bénéficiaire",
  lpc__MINTS: "Pourcentage du seuil minimal",
  lpc__MAXTS: "Pourcentage du seuil maximum",
  lpc__NUMOC: "Nombre d'occurrences",
  lpc__AMTOP: "Montant du rachat",
  lpc__MALMT: "Montant minimum",
  lpc__CIBAN: "IBAN",
  lpc__PCOUP: "Pourcentage du coupon périodique",
  lpc_threshold_percentage: "Pourcentage du seuil",
  lpc_pure: "Prime pure",
  lpc_starting_fund: "Fonds de départ",
  lpc_details: "Détails",
  lpc_treshold: "Seuil",
  lpc_inv_date: "Date d'investissement",
  lpc_beneficiary_perc_req: "{{benefCat}} {{benefRole}} {{benefName}}: Saisir un pourcentage",
  lpc_beneficiary_perc_req_range: " {{benefCat}} {{benefRole}} {{benefName}}: Le pourcentage doit être compris entre 0,01 et 100",
  lpc_invalid_total_position_percent: "Le montant en pourcentage pour le groupe \"{{groupType}}\" pour le bénéficiaire {{cat}} doit être de 100 %",
  lpc_beneficiary_from_party_req: "{{benefCat}} {{benefRole}}: Saisir un bénéficiaire à partir du référentiel des parties",
  lpc_benefPresente: "{{benefName}} a déjà été saisi en tant que {{benefCat}} {{benefRole}}",
  lpc_benMorteNoAssic: "{{benefName}} saisi en tant que {{benefCat}} {{benefRole}} a déjà été saisi en tant qu'assuré",
  lpc_life_beneficiary: "Bénéficiaire Vie",
  lpc_death_beneficiary: "Bénéficiaire en cas de décès",
  lpc_beneficiary_position_mandatory: "Bénéficiaire {{benefCat}} {{benefName}}, sélectionnez un \"Numéro de poste\"",
  lpc_reversionary_subject: "Bénéficiaire désigné",
  lpc_beneficiary_qualification: " {{benefCat}} {{benefRole}} ",
  lpc_gross_detail: "Détail de la prime brute",
  lpc_type_of_covers: "Type de couvertures",
  lpc_clausesinsuredClauses: "Clauses assurées",
  lpc_clausespolicyClauses: "Clauses de police",
  lpc_clausesriskClauses: "Clauses de risque",
  lpc_max_capital_amount: "IMC - Montant maximum du capital",
  lpc_fiscal_period_M1: "M1 - Montant accumulé au 31/12/2000",
  lpc_fiscal_period_M2: "M2 - Montant accumulé du 1/1/2001 au 31/12/2006",
  lpc_fiscal_period_M3: "M3 - Montant accumulé à partir du 1/1/2007",
  lpc_fiscal_period_capital_request_K1: "Partie de M1 demandée en capital",
  lpc_fiscal_period_capital_request_K2: "Partie de M2 demandée en capital",
  lpc_fiscal_period_capital_request_K3: "Partie de M3 demandée en capital",
  lpc_max_amount_exceeded: "Le montant demandé en capital dépasse le maximum possible.",
  lpc_max_amount_error: "Le montant du capital demandé doit être entièrement alloué aux montants M1 et M2.",
  lpc_performance_data: "Données de performance",
  lpc_role_is_mandatory: "Vous devez saisir un(e) {{role}}",
  lpc_issue_authorized_proposal: "Émettre une proposition/politique autorisée",
  lpc_confirm_prop_pol_auth: "Confirmer la proposition/politique dans Autorisation",
  lpc_settlement: "Indemnité de départ",
  lpc_annuity: "Rente",
  lpc_percentage_in_capital: "% dans le capital",
  lpc_insured_amount: "Montant Assuré",
  lpc_custom_field_mandatory_error: "Le champ {{fieldName}} est obligatoire",
  lpc_print_docs: "Imprimer des documents",
  lpc_revisionary_err: "Entrer le sujet réversionnaire",
  lpc_revisionary_perc_err: "Entrer le pourcentage du sujet réversionnaire",
  lpc_percentage_must_be_between: "Le pourcentage doit être compris entre 0,01 et 100",
  lpc_PIP_UndeductedPremiums: "",
  lpc_contributionYear: "",
  lpc_undeductedPremium: "",
  lpc_reversionario: "Reversionary",
  lpc_annualPremium: "",
  lpc_firstEnrollmentComplementaryPension: "",
  lpc_PIP_amountForTaxation: "",
  lpc_PIP_amount: "",
  lpc_PIP_contribempl4Perc: "",
  lpc_PIP_reducttaxbltfr: "",
  lpc_PIP_aliqtfr: "",
  lpc_PIP_premdeducted2001: "",
  lpc_PIP_premdeductoffset: "",
  lpc_PIP_annuitytaxdeduct: "",
  lpc_pip_contributions_installment_detail: "FR_Contributions EStails",
  lpc_contribution_type: "FR_Contribution type",
  lpc_contribution_amount: "FR_Amount",
  lpc_contribution_year: "FR_Year",
  lpc_contribution_month_from: "FR_Month (from)",
  lpc_contribution_month_to: "FR_Month (to)",
  lpc_contribution_thirteenth: "FR_13^",
  lpc_contribution_fourteenth: "FR_14^",
  lpc_incomplete_fund_investment: "Investissement incomplet pour les fonds",
  lpc_incomplete_profile_investment: "Investissement incomplet pour les profils",
  lpc_tax_credit: "",
  lpc_clausesinsuredClause: "Clauses assurées",
  lpc_clausespolicyClause: "Clauses de police",
  lpc_clausesriskClause: "Clauses de risque",
  lpc_tariff_version: "",
  lpc_policy_notes: "",
  lpc_operation_rescission_reason: "Raison de la rescision",
  lpc_lic_modify_quote: "Modifier le devis",
  lpc_lic_modify_proposal: "Modifier la proposition",
  lpc_inbound_documents_mov: "Documents inbound",
};
export default LPC_FR_CH;
