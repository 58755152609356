<div *ngIf="!!onInitEnd" class="example-row" style="padding:30px" id="issue-page">
  <div class="core-header-icon" >
    <span class="header-text">{{titleOfIssue}}</span>
    <span class="pull-right header-text"></span>
  </div>
  <div>
    <div [ngClass]="{'issue-container': !areThereDocuments, 'issue-container-min': areThereDocuments}" >
      <div class="middle-div-message" data-qa="life-number">
        <span>{{label}}</span>
      </div>
      <div *ngIf="!!damageLabel" class="middle-div-message" data-qa="damage-number">
        <span>{{damageLabel}}</span>
      </div>
    </div>

    <div class="example-row">
      <lic-document-list
        [documentList]="documentList"
        (actionHandler)="downloadDocument($event)">
      </lic-document-list>
      <!-- <ng-template #newOutboundDocuments>
        <rgi-outbound-documents
          [policyNumber]="policyNumber[0]?.policyNumber"
          [proposalNumber]="policyNumber[0]?.proposalNumber"
          [movementId]="policyService.getMovementID()"
          [movementDesc]="titleOfIssue">
        </rgi-outbound-documents>
      </ng-template> -->
    </div>


    <div class="example-row nmf_cont_page">
      <div class="nmf_cont_sez">
        <rgi-documents [contractId]="contractId"
                       [uploadDownloadEnabled]="policyNumber[0]?.proposalNumber!==undefined"
                       (startDocument)="startDocuments($event)"
                       (eventModifiedDocument)="verifyDocuments($event)">
        </rgi-documents>
      </div>
    </div>

    <div class="btn-group btn-group-justified">
      <div *ngIf="type === 'lifeAndDamageProposal' || type === 'lifeProposal' || type === 'saveAcceptAndPropIssue'; else close" style="margin-top: 50px;" class="btn-group" data-qa="policyIssue-button">
        <button (click)="onSubmit()" [disabled]="isError" class="btn btn-warning pull-right text-uppercase" translate>{{buttonLabel}}</button>
      </div>
      <ng-template #close>
        <div style="margin-top: 50px;" class="btn-group" data-qa="policyIssue-button">
          <button (click)="onClose()" class="btn btn-warning pull-right text-uppercase" translate>lic_close</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
