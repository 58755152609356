import { Subscription } from 'rxjs/internal/Subscription';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ValidatorService } from 'angular-iban';
import { PaymentFieldDefinition, PaymentTypeDefinition } from '../../models/postsales-operations-response.model';
import { PaymentControl } from './model/payment-control.model';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'lpc-payment-step',
  templateUrl: './lpc-payment-step.component.html',
  styleUrls: ['./lpc-payment-step.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcPaymentStepComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcPaymentStepComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LpcPaymentStepComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {

  protected $subscriptions: Subscription[] = [];

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    idPaymentType: new UntypedFormControl(null, Validators.required),
    cchecknumber: new UntypedFormControl(null),
    ciban: new UntypedFormControl(null),
    cholder: new UntypedFormControl(null),
    creditCardNumber: new UntypedFormControl(null),
    creditCardExpiredDate: new UntypedFormControl(null)
  });

  @Input()
  public paymentTypeDefinitions: PaymentTypeDefinition[] = [];

  @Input() summary = false;
  @Input() required = false;
  @Input() editable = true;
  @Output() change = new EventEmitter<any>();
  @Input() isPaymentTypeEditable = true;


  public get paymentFieldDefinitions(): PaymentFieldDefinition[] {
    if (!!this.paymentTypeDefinitions) {
      const paymentTypeDefinition: PaymentTypeDefinition = this.paymentTypeDefinitions
      .find(paymTypes => paymTypes.id === this.formGroup.get('idPaymentType').value);
      if (!!paymentTypeDefinition && !!paymentTypeDefinition.paymentFields) {
        return paymentTypeDefinition.paymentFields;
      }
    }
    return [];
  }
  public get paymentSelectedOptionLabel(): string {
    const paymentTypeDefinition: PaymentTypeDefinition = this.paymentTypeDefinitions.find(
      paymTypes => paymTypes.id === this.formGroup.get('idPaymentType').value
    );

    return !!paymentTypeDefinition ? paymentTypeDefinition.label : null;
  }

  constructor() { }

  ngOnInit(): void {
    this.$subscriptions.push(
      this.formGroup.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        this.onChange(this.getMergedObject(new PaymentControl(value)));
        this.writeValue(this.getMergedObject(new PaymentControl(value)));
      })
    );
    this.$subscriptions.push(
      this.formGroup.get('idPaymentType').valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        this.onChangeIdPaymentType(value);
      })
    );
  }
  

  onChangeIdPaymentType(value) {
    this.resetValidation();
    this.onChange(this.getMergedObject(new PaymentControl({ idPaymentType: value })));
    this.change.emit(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: { [key: string]: any }): void {
    obj = !!obj ? new PaymentControl(obj) : {
      idPaymentType: null,
      cchecknumber: null,
      ciban: null,
      cholder: null,
      creditCardNumber: null,
      creditCardExpiredDate: null
    };
    this.formGroup.patchValue(obj, {emitEvent: false});
  }

  validate(control: AbstractControl): { [key: string]: boolean } | null {
    const errors: { [key: string]: boolean } = {};
    if (!this.formGroup.get('idPaymentType').value && this.required) {
      errors.idPaymentType = true;
    }
    Object.keys(this.formGroup.controls).forEach(key => {
      if (!!this.formGroup.get(key).invalid) {
        errors[key] = true;
      }
    });
    if (!!Object.keys(errors).length) {
      return errors;
    }
    return null;
  }

  onChange(obj: { [key: string]: any }) {
  }

  onTouch(obj: { [key: string]: any }) {
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subs => subs.unsubscribe());
  }

  // @ts-ignore
  private resetPaymentFieldsControl(paymentFields: PaymentFieldDefinition[]) {
    paymentFields.forEach(oldControl => {
      this.formGroup.removeControl(oldControl.code);
    });
  }

  private getMergedObject(obj) {
    return Object.assign({
      idPaymentType: null,
      cchecknumber: null,
      ciban: null,
      cholder: null,
      creditCardNumber: null,
      creditCardExpiredDate: null
    }, obj);
  }

  private resetValidation() {
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).clearValidators();
      this.formGroup.get(key).updateValueAndValidity();
    });
    this.paymentFieldDefinitions.forEach(definition => {
      const validators: ValidatorFn[] = [];
      if (definition.required) {
        validators.push(Validators.required);
      }
      if (definition.code === 'ciban') {
        validators.push(ValidatorService.validateIban);
      }
      this.formGroup.get(definition.code).setValidators(validators);
      this.formGroup.get(definition.code).updateValueAndValidity();
    });
  }
}
