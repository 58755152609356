import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ComponentWithQuotation } from '../../interfaces/component-with-quotation';
import { OperationWithFinancialStepComponent } from '../../interfaces/impl/operation-with-financial-step.component';
import { InvestmentAdapterConfig } from '../../interfaces/operation-with-financial-step';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import { InvestmentMode, OperationPropertyCode } from '../../models/operation-property-code.enum';
import {
  FactorDefinition,
  Instalment,
  OperationProperty,
  PaymentTypeDefinition, PostsalesOperationObject, QuotationDefinition,
  Risk
} from '../../models/postsales-operations-response.model';
import { RisksFactors } from '../../models/risks-factors.model';
import { LpcCommissionDetailModalComponent } from '../../modules/lpc-commission-detail-modal/lpc-commission-detail-modal.component';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { Premium } from '../../modules/lpc-premium/model/premium.model';
import { LpcQuotationDetailModalComponent } from '../../modules/lpc-quotation-detail-modal/lpc-quotation-detail-modal.component';
import { LpcRolesUtils } from '../../modules/lpc-roles-step/lpc-roles-utils';
import { LpcWarrantyDetailModalComponent } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail-modal.component';
import { Instalments } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail/lpc-instalments.model';
import { AnagService } from '../../services/anag.service';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { PlcQuotationUtils } from '../../utils/plc-quotation-utils';
import { InstalmentType } from '../dynamic-operation/dynamic-operation.model';

@Component({
  selector: 'lpc-additional-payment',
  templateUrl: './additional-payment.component.html',
  styleUrls: ['./additional-payment.component.scss']
})
export class AdditionalPaymentComponent extends OperationWithFinancialStepComponent implements ComponentWithQuotation {

  protected operationDataKey = 'additional-payment';

  @ViewChild('factorAdapter') child: LpcFactorAdapterComponent;
  @ViewChild('assetFactorAdapter') assetChild: LpcFactorAdapterComponent;

  public quotationData: any;
  public damageQuotationData: any;
  public lifeWarrantiesData: any[];
  public damageWarrantiesData: any[];
  public paymentTypes: PaymentTypeDefinition[] = [];

  public listProductFactor: FactorDefinition[] = [];
  protected requestProductFactor: RequestFactor[] = [];
  get hasProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  public listAssetFactor: FactorDefinition[] = [];
  protected requestAssetFactor: RequestFactor[] = [];
  get hasAssetFactor(): boolean {
    return !!this.listAssetFactor && !!this.listAssetFactor.length;
  }

  public quotationDefinition: QuotationDefinition;

  // QUOTATION DETAIL MODAL
  public annualPremium: Premium;
  public firstInstalment: Premium;
  public nextInstalment: Premium;

  // WARRANTIES DETAIL MODAL
  public instalments: Instalments;

  // QUOTATION CONTROLS BUTTONS
  public disableQuote = true;
  public disableDetails = true;
  public disableNext: boolean;
  public enableProv: boolean;

  // INVESTIMENTI
  protected canEditInvestmentFunds = true; // indica se i fondi degli investimenti sono modificabili (scende dalle definitions)

  public risksForUnits: Risk[] = [];
  private reqUnitFactors: RisksFactors[] = [];

  public showSinglePremium = true;

  enableRoleCheckbox(roleCode: string): boolean {
    return LpcRolesUtils.enableCheckbox(this.operations, LpcRolesUtils.getRoleCode(roleCode));
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected karmaService: KarmaFundService,
    protected angularCommunicationService: AngularCommunicationService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag, karmaService, angularCommunicationService);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      operationRoles: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      assetFactors: new UntypedFormGroup({}),
      investmentProfiles: new UntypedFormGroup({}),
      investmentFunds: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      payment: new UntypedFormControl(),
      risks: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        const paymentData = result.data.operationData.data.payment;
        if (!!paymentData) {
          this.formGroup.get('payment').patchValue({
            idPaymentType: paymentData.idPaymentType.toString(),
            cchecknumber: paymentData.cchecknumber,
            ciban: paymentData.ciban,
            cholder: paymentData.cholder,
            creditCardNumber: paymentData.creditCardNumber,
            creditCardExpiredDate: paymentData.creditCardExpiredDate}, {emitEvent: false});
        }

        this.populateInvestmentForms(result);
      })
    );
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    if (step === 'factors') {
      this.requestProductFactor = this.child.getRequestFactor();
    } else if (step === 'assetFactors') {
      this.requestAssetFactor = this.assetChild.getRequestFactor();
    }
    if (this.isFormValid(this.formGroup.get(step))) {
      super.onNext(step, publish, context, isConfirmAndAccept);
    } else {
      this.setFeErrors(step);
    }
  }

  protected updateDraft(step: string, reload?: boolean): Observable<any> {
    if (step === 'quests') {
      return super.saveQuestAnd(step).pipe(
        switchMap(e => {
          return super.updateDraft(step, reload).pipe(
            switchMap(result => {
              if (this.canCalculateVolatility(step)) {
                return this.calculateVolatility().pipe(
                  switchMap(_ => {
                    return of(result);
                  })
                );
              } else {
                return of(result);
              }
            })
          );
        })
      );
    } else {
      return super.updateDraft(step, reload).pipe(
        switchMap(result => {
          if (this.canCalculateVolatility(step)) {
            return this.calculateVolatility().pipe(
              switchMap(_ => {
                return of(result);
              })
            );
          } else {
            return of(result);
          }
        })
      );
    }  
  }

  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    const tmpRisk = result.data.operationData.data.listRisksFactor;
    this.disableQuote = true;
    this.disableDetails = false;
    this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    this.listAssetFactor = result.definitions.goodsFactors as FactorDefinition[];
    this.quotationDefinition = this.createQuotationDefinition(result.data.operationData.data.quotationInfo);
    if (tmpRisk && tmpRisk.length > 0) {
      this.risksForUnits = this.mapRisksForUnits(tmpRisk, this.quotationDefinition.risks);
    } else {
      this.risksForUnits = this.quotationDefinition.risks;
    }
    this.enableProv = this.checkProvVisibility(this.risksForUnits);
    this.paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
    this.instalments = {
      totAnnualPremium: null,
      totFirstInstalment: this.quotationDefinition.firstInstalment,
      totNextInstalment: null,
      annualPremiumSection: null,
      firstInstalmentSection: null,
      nextInstalmentSection: null
    };
    this.updateModals(this.quotationDefinition);

    // AGGIORNO I VALORI DEGLI INVESTIMENTI
    this.updateProfilesAndFunds(step, result.definitions);

    this.detectChanges();
  }

  public isPaymentVisibile(): boolean {
    if (this.hasOperationPropertyByCode(OperationPropertyCode.POST_SALES_PAYMENT_TYPES)) {
      return  PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(OperationPropertyCode.POST_SALES_PAYMENT_TYPES).booleanValue
      );
    }
    return true;
  }

  public createQuotationDefinition(q): QuotationDefinition {
    return {
      annualPremium: null,
      firstInstalment: q.installmentAmount,
      nextInstalment: null,
      recoveredCommission: null,
      reimbursementAmount: null,
      risks: q.risksInfo,
      beneficiaries: null
    };
  }

  public isFormValid(form): boolean {
    if (!!form) {
      return form.disabled ? true : form.valid;
    }
    return true;
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestProductFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe());
  }

  public updateAssetFactors(factors: RequestFactor[]) {
    this.requestAssetFactor = factors;
    this.$subscriptions.push(this.onReload('assetFactors').subscribe());
  }

  public recalculate(disable: boolean) {
    if (this.formGroup.get('risks').valid) {
      this.updateOnQuotation(disable);
    } else {
      this.setFeErrors('quotation');
    }
  }

  public updateOnQuotation(disable: boolean) {
    this.$subscriptions.push(
      this.updateDraft('recalculateQuote', true).subscribe(result => {
        this.disableNext = disable;
      })
    );
  }

  public openQuotationModal(event) {
    const modalRef = this.modalService.open(LpcQuotationDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.firstInstalment = this.firstInstalment;
    modalRef.componentInstance.nextInstalment = this.nextInstalment;
    modalRef.componentInstance.annualPremium = this.annualPremium;
    modalRef.componentInstance.quotationData = this.quotationData;
  }

  public openWarrantiesModal(event) {
    const modalRef = this.modalService.open(LpcWarrantyDetailModalComponent, {
      centered: true,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      size: <any> 'xl',
      windowClass: 'in lpc-modal',
      backdropClass: 'light-blue-backdrop in'
    });
    modalRef.componentInstance.instalments = this.instalments;
  }

  public openProvModal(event) {
    const modalRef = this.modalService.open(LpcCommissionDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.risks = this.risksForUnits;
  }

  public updatePremiumModal(quotationDefinition: QuotationDefinition) {
    this.annualPremium = PlcQuotationUtils.instalmentToPremium(quotationDefinition.annualPremium);
    if (this.annualPremium) {
      this.annualPremium.typeDescription = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    this.firstInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.firstInstalment);
    if (!!this.firstInstalment) {
      this.firstInstalment.typeDescription = this.translate.getImmediate(InstalmentType.SINGLE_PREMIUM);
    }
    this.nextInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.nextInstalment);
    if (!!this.nextInstalment) {
      this.nextInstalment.typeDescription = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }
    this.quotationData = PlcQuotationUtils.quotationDefToQuotationData(quotationDefinition);
  }

  public updateWarrantiesModal(quotationDefinition: QuotationDefinition) {
    this.instalments.firstInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.SINGLE_PREMIUM
    );
    this.instalments.nextInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.NEXT_INSTALMENT
    );
    this.instalments.annualPremiumSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.ANNUAL_PREMIUM
    );
    if (!!quotationDefinition.annualPremium) {
      quotationDefinition.annualPremium.description = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    if (!!quotationDefinition.firstInstalment) {
      quotationDefinition.firstInstalment.description = this.translate.getImmediate(InstalmentType.SINGLE_PREMIUM);
    }
    if (!!quotationDefinition.nextInstalment) {
      quotationDefinition.nextInstalment.description = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }

    this.instalments.totFirstInstalment = quotationDefinition.firstInstalment;
    this.instalments.totAnnualPremium = quotationDefinition.annualPremium;
    this.instalments.totNextInstalment = quotationDefinition.nextInstalment;
  }

  public updateModals(quotationDefinition: QuotationDefinition) {
    this.updatePremiumModal(quotationDefinition);
    this.updateWarrantiesModal(quotationDefinition);
  }

  // creating sections for warranties detail modal
  public createInstalmentSectionWarranties(risks: Risk[], instalmentType: string): Instalment[] {
    return PlcQuotationUtils.createInstalmentSectionWarranties(risks, instalmentType);
  }

  public checkProvVisibility(risks: Risk[]): boolean {
    return PlcQuotationUtils.checkProvVisibility(risks);
  }

  public updateUnit(riskFactors: Map<string, RequestFactor[]>) {
    this.reqUnitFactors = [];
    riskFactors.forEach((value: RequestFactor[], key: string) => {
      this.reqUnitFactors.push({ codeRisk: key, factors: value } as RisksFactors);
    });
    this.$subscriptions.push(this.onReload('quotation').subscribe((result: PostsalesOperationObject) => {
      this.quotationDefinition = this.createQuotationDefinition(result.data.operationData.data.quotationInfo);
      this.risksForUnits = this.mapRisksForUnits(result.data.operationData.data.listRisksFactor, this.quotationDefinition.risks);
      // this.quotationDefinition.firstInstalment.gross = 0.00;
      this.disableDetails = true;
      this.disableQuote = false;
      this.disableNext = true;
    }));
  }

  protected getTransformedOperationData(): any {
    let investmentProfiles = [];
    if (!!this.formGroup.getRawValue().investmentProfiles && !!this.formGroup.getRawValue().investmentFunds && !!this.investmentProfiles) {
      investmentProfiles = LpcKarmaFundUtils.getBackendStructureOfProfiles(
        this.investmentProfiles,
        this.formGroup.getRawValue().investmentProfiles,
        this.formGroup.getRawValue().investmentFunds
      );
    }
    return  {
      listGoodFactor: this.requestAssetFactor.length > 0 ? this.requestAssetFactor : [],
      listProductFactor: this.requestProductFactor.length > 0 ? this.requestProductFactor : [],
      investmentProfiles,
      payment: !!this.formGroupValue.payment ? this.formGroupValue.payment : null,
      volatility: this.volatility,
      listRisksFactor: this.reqUnitFactors
    };
  }

  private mapRisksForUnits(srcRisk: { [key: string]: any | any[] }, destRisk: any): Risk[] {
    srcRisk.forEach( (singleRisk: any, i) => {
      if (singleRisk) {
        singleRisk.factors.forEach((singleFactor: any, j) => {
          if (singleFactor) {
            destRisk[i].factors[j].defaultValue = singleFactor.value;
          }
        });
      }
    });

    return destRisk;
  }


  // ---------------------------------------- INVESTIMENTI ----------------------------------------

  // FLAG PER SALTARE GLI STEP DI INVESTIMENTI
  public get skipInvestmentProfiles(): boolean {
    return !this.canEditInvestmentFunds || this.profileFixedPercentage;
  }
  public get skipInvestmentFunds(): boolean {
    return !this.canEditInvestmentFunds || this.fundFixedPercentage;
  }

  // configurazioni da passare al componente degli investimenti per gestire le varie customizzazioni
  get getInvestmentProfileConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      investmentMode: this.getInvestmentMode(),
      enableVolatility: false,
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: false,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true
    };
  }
  get getInvestmentFundConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      fundFormGroupName: 'investmentFunds',
      investmentMode: this.getInvestmentMode(),
      enableVolatility: this.isWithVolatility(),
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: false,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true
    };
  }

  // salto sempre lo step dei disinvestimenti (non ci sono in questa operazione)
  public get skipDisinvestment(): boolean {
    return true;
  }

  public getTotalAmount(quotationDef: QuotationDefinition): number {

    if (!!quotationDef && !!quotationDef.risks) {
      return quotationDef.risks
      .map(risk => Number(risk.firstInstalment.purePremium).valueOf())
      .reduce((acc, premium: number) => acc + premium, 0);
    }

    return null;
  }

  public getInvestmentMode(): InvestmentMode | undefined {
    const prop: OperationProperty = this.getOperationPropertyByCode(OperationPropertyCode.INVESTMENT_MODE);
    return !!prop ? (prop.stringValue as InvestmentMode) : undefined;
  }

  // metodo che mantine aggiornati gli investimenti ad ogni avanti
  public updateProfilesAndFunds(step: string, definitions) {
    this.totalAmountToInvest = this.getTotalAmount(this.quotationDefinition);
    this.canEditInvestmentFunds = JSON.parse(!!definitions.modifiableFunds ? definitions.modifiableFunds.value.toLowerCase() : true);

    // INVESTIMENTI - VISIBILITà STEP & GESTIONE UPDATE
    super.updateProfilesAndFunds(step, definitions);
  }

  public isWithVolatility(): boolean {
    if (this.hasOperationPropertyByCode(OperationPropertyCode.VOLATILITY_CALCULATION)) {
      return  PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(OperationPropertyCode.VOLATILITY_CALCULATION).booleanValue
      );
    }
    return super.isWithVolatility();
  }

  // ---------------------------------------- INVESTIMENTI ----------------------------------------

}
