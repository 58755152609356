<form [formGroup]="group">
  <div class="pull-left">
    <lic-help-icon [helpFile]="config.helpFile" [title]="label"></lic-help-icon>
  </div>
  <!-- <pnc-datepicker
    [label]="label"
    [id]="config.code"
    [customFormControlName]="config.code"
    [customFormGroup]="group"
    [required]="config.compulsory"
    (change)="onChange()"
    (dateSelect)="onChange()"
    [attr.data-qa]="'factor' + config.code"
    [attr.data-mod] = "'factor' + config.code + modifiable" [attr.data-obb] = "'factor' + config.code + config.compulsory"
  >
  </pnc-datepicker> -->
  <rgi-rx-form-field>
    <label [for]="label" rgiRxLabel>{{label}}</label>
    <rgi-rx-date-picker [openOnClick]="false">
      <input
        [rgiRxDateTimeInput]="picker1"
        formControlName="{{config.code}}"
        [attr.data-qa]="'factor' + config.code"
        [attr.data-mod] = "'factor' + config.code + modifiable"
        [attr.data-obb] = "'factor' + config.code + config.compulsory"
        (dateTimeChange)="onChange()">
    </rgi-rx-date-picker>
    <rgi-rx-date-time #picker1></rgi-rx-date-time>
   </rgi-rx-form-field>
</form>
