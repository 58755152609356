export enum SYSTEM_PROPERTY_ENABLING {
    ENABLED = 'enable',
    DISABLED = 'disable'
}

export enum PARTY_QUEST_CODES {
     FIRST_QUESTION_CODE = '000001',
     SECOND_QUESTION_CODE = '000002',
     BENEFICIARY_QUESTION_CODE = '000003',
     DEFAULT_FIRST_QUESTION_ANSWER = '000003',
     ANSWER_SHOW_SECOND_QUEST_CODE = '000004',
     ANSWER_SHOW_SECOND_QUEST_OTHER_CODE = '000002',
     ANSWER_SHOW_BENEF_QUEST_OTHER_CODE = '000002',
     ANSWER_SHOW_BENEFICIARY_CODE = '000005'
}

export enum PARTY_QUEST_CONTROLS_NAME {
    FIRST_QUESTION = 'firstQuestion',
    FOUNDS_QUESTION = 'secondQuestion',
    FOUNDS_OTHER = 'otherResponse',
    RELATIONSHIP_QUESTION_CONTROL_NAME = 'beneficiaryQuestion-',
    BENEF_FOUNDS_QUESTION_NAME = 'beneficiaryFoundsQuestion-',
    RELATIONSHIP_OTHER = 'beneficiaryQuestionOther-',
    BENEF_FOUNDS_OTHER = 'beneficiaryFoundsQuestionOther-'
}
