import {Component, OnInit, ViewChild} from '@angular/core';
import {PncPsalesConfirmationState} from '../../resources/states/confirmation-state';
import {PncPsalesConfirmationStateManager} from '../../state-managers/confirmation/confirmation-state-manager.service';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {RgiPncPostsalesStepComponent} from '../../api/rgi-pnc-postsales-step.component';
import {FormService} from '../../services/form.service';
import {PncDocumentsComponent} from '../../components/pnc-documents/pnc-documents.component';

@Component({
  selector: 'rgi-pnc-psales-confirmation',
  templateUrl: './confirmation.component.html'
})
export class PncPsalesConfirmationComponent extends RgiPncPostsalesStepComponent<PncPsalesConfirmationState> implements OnInit {

  @ViewChild(PncDocumentsComponent) documentsComponent: PncDocumentsComponent;

  constructor(
    public stateMgr: PncPsalesConfirmationStateManager,
    formService: FormService,
    public pushMessageHandler: PushMessageHandlerService,
    public translateService: RgiRxTranslationService
  ) {
    super(stateMgr, formService);
  }

  ngOnInit() {
    this.pushMessageHandler.clearTag(this.stateMgr.activeRoute.route);
    this.stateMgr.getState$().subscribe((st: PncPsalesConfirmationState) => {
      this.state = st;
      this.translateService.translate('_PCPSALES_._MSG_._OPERATION_FOR_POLICY_OK_',
        {opDescr: st.currentOperation.description, policyNumber: st.policyNumber}).subscribe(okMsg => {
        this.pushMessageHandler.notify(new RgiRxPushMessage(
          okMsg, {
            status: 'info',
            tag: this.stateMgr.activeRoute.route,
            dismissible: false,
            options: {
              icon: 'rgi-ui-icon-info'
            }
          }
        ));
      });
    });
  }

  exit() {
    this.stateMgr.actionExit();
  }

 openDetail() {
    this.stateMgr.actionOpenDetail();
  }

  wip(msg: string) {
    alert('Work in progress: ' + msg);
  }

  download(documentCode: string){
    this.documentsComponent.download(this.stateMgr.downloadDocuments$(this.state.policyNumber, documentCode))
  }

  openOutboundDocuments() {
    this.stateMgr.openOutboundDocumentsModal();
  }

  showPrintDocumentsButton() {
    return this.stateMgr.showPrintDocumentsButton();
  }

}
