<form [formGroup]="variableControlForm">

  <div [ngSwitch]="variable?.type">

    <div *ngSwitchCase="'0'">
      <label>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
      <mic-help [helpFile]="variable?.helpFile"></mic-help>
      <pnc-required *ngIf="variable?.compulsory" required="true"></pnc-required>

      <select *ngIf="variable?.variableClass === 7" (change)="onVariableValueChange()"
              [attr.data-qa]="'variable'+variable?.code" [formControlName]="variable?.identificationCode"
              [id]="variable?.code" [compareWith]="compareCities"
              class="select-nativa">
        <option [value]="-1"></option>
        <option *ngFor="let city of cities" [value]="city">
          {{ city?.cityName }}</option>
      </select>

      <select *ngIf="variable?.variableClass === 8" (change)="onVariableValueChange()"
              [attr.data-qa]="'variable'+variable?.code" [formControlName]="variable?.identificationCode"
              [id]="variable?.code" [compareWith]="compareProvinces"
              class="select-nativa">
        <option [value]="-1"></option>
        <option *ngFor="let province of provinces" [value]="province">
          {{ province?.description }}</option>
      </select>

      <select *ngIf="!(variable?.variableClass === 8 || variable?.variableClass === 7)"
              (change)="onVariableValueChange()"
              [attr.data-qa]="'variable'+variable?.code" [formControlName]="variable?.identificationCode"
              [id]="variable?.code"
              class="select-nativa">
        <option [value]="-1"></option>
        <option *ngFor="let value of variable?.variablesValue; index as i; trackBy: genericEntitiesTrackByFn"
                [value]="value?.code">
          {{ value?.description }}</option>
      </select>

      <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>

    </div>

    <div *ngSwitchCase="'1'">
      <label>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
      <mic-help [helpFile]="variable?.helpFile"></mic-help>
      <pnc-required *ngIf="variable?.compulsory" required="true"></pnc-required>
      <input (blur)="onVariableValueChange()" *ngIf="(!variable.variablesValue || variable.variablesValue.length==0)"
             [attr.data-qa]="'variable'+variable?.code" [formControlName]="variable?.identificationCode"
             [id]="variable?.code"
             class="form-control mic-input" type="text" rgiCountryLayerInputNumberFormat  [maximumFractionDigits]="2">

      <select (change)="onVariableValueChange()" *ngIf="(variable.variablesValue && variable.variablesValue.length>0)"
              [attr.data-qa]="'variable'+variable?.code"
              [formControlName]="variable?.identificationCode"
              [id]="variable?.code" class="select-nativa">
        <option [value]="-1"></option>
        <option *ngFor="let value of variable?.variablesValue; index as i; trackBy: genericEntitiesTrackByFn"
                [value]="value?.code">
          {{ value?.description }}</option>
      </select>
      <span *ngIf="(variable.variablesValue?.length>0)"
            class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>

    </div>

    <div *ngSwitchCase="'2'">
      <label>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
      <mic-help [helpFile]="variable?.helpFile"></mic-help>
      <pnc-required *ngIf="variable?.compulsory" required="true"></pnc-required>
      <input (blur)="onVariableValueChange()" *ngIf="(!variable.variablesValue || variable.variablesValue.length==0)"
             [attr.data-qa]="'variable'+variable?.code" [formControlName]="variable?.identificationCode"
             [id]="variable?.code"
             class="form-control mic-input" type="text" rgiCountryLayerInputNumberFormat  [maximumFractionDigits]="2">

      <select (change)="onVariableValueChange()" *ngIf="(variable.variablesValue && variable.variablesValue.length>0)"
              [attr.data-qa]="'variable'+variable?.code" [formControlName]="variable?.identificationCode"
              [id]="variable?.code" class="select-nativa">
        <option [value]="-1"></option>
        <option *ngFor="let value of variable?.variablesValue; index as i; trackBy: genericEntitiesTrackByFn"
                [value]="value?.code">
          {{ value?.description }}</option>
      </select>

      <span *ngIf="(variable.variablesValue?.length>0)"
            class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>

    </div>

    <div *ngSwitchCase="'3'">
      <label>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
      <mic-help [helpFile]="variable?.helpFile"></mic-help>
      <pnc-required *ngIf="variable?.compulsory" required="true"></pnc-required>
      <select (change)="onVariableValueChange()" [attr.data-qa]="'variable'+variable?.code"
              [formControlName]="variable?.identificationCode" [id]="variable?.code"
              class="select-nativa">

        <option [value]="-1"></option>
        <option
          *ngFor="let variableValue of variable?.variablesValue; index as i; trackBy: genericEntitiesTrackByFn"
          [value]="variableValue?.code">
          {{variableValue?.description}}
        </option>
      </select>
      <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>

    </div>

    <div *ngSwitchCase="'4'">
      <label rgiRxLabel translate>{{variable?.extendedDescription ? variable?.extendedDescription :
        variable?.description}}</label>
      <mic-help [helpFile]="variable?.helpFile"></mic-help>
      <rgi-rx-date-picker [openOnClick]="false">
        <input [rgiRxDateTimeInput]="configPicker3" [id]="variable?.code" [value]="variable?.value"
          formControlName="variable?.identificationCode">
        <rgi-rx-date-time #configPicker3></rgi-rx-date-time>
      </rgi-rx-date-picker>
    </div>

    <div *ngSwitchCase="'5'">
      <label>{{ variable?.extendedDescription ? variable?.extendedDescription : variable?.description }}</label>
      <mic-help [helpFile]="variable?.helpFile"></mic-help>
      <pnc-required *ngIf="variable?.compulsory" required="true"></pnc-required>
      <input (blur)="onVariableValueChange()" [formControlName]="variable?.identificationCode"
             [id]="variable?.code" class="form-control mic-input"
             type="string">

    </div>

  </div>

</form>
