<form [formGroup]="newPayForm">
    <div class="top-banner" *ngIf="enabled">
        <div class="card shadow">
            <div class="row">
                <div class="col-xs-3" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PAYMENT_TRANSACTION_TYPE' | translate }}
                        <pnc-required required="true"></pnc-required>
                    </label>
                    <select class="core-select form-control" id="paymentType" name="paymentType"
                        formControlName="paymentType" attr.data-qa="new-payment-paymentType-input"
                        (change)="loadTypeReceiptList()">
                        <option></option>
                        <option *ngFor="let aPayType of potPayFE.paymentTypeListCombo; " value={{aPayType.eTypeCode}}>
                            {{aPayType.eTypeDescription | titlecase}}</option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-3" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._MANAGEMENT_PAYMENT' | translate }} </label>
                    <select class="core-select form-control" id="managementPayment" name="managementPayment"
                        formControlName="managementPayment" attr.data-qa="new-payment-managementPayment-input">
                        <option *ngFor="let aManType of potPayFE.managementPaymentList; " value={{aManType.idObject}}>
                            {{aManType.description}}</option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-2" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._RECEIPT_TYPE' | translate }} <pnc-required
                            required="true"></pnc-required></label>
                    <select class="core-select form-control" id="receiptType" name="receiptType"
                        formControlName="receiptType" attr.data-qa="new-payment-paymentType-input"
                        (change)="exposureDefinition()">
                        <option></option>
                        <option *ngFor="let aRecType of potPayFE.receiptTypeListCombo; " value={{aRecType.idObject}}>
                            {{aRecType.description}}</option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-2" style="font-size: 14px;">
                    <pnc-datepicker label="{{ '_CLAIMS_._QUITTANCE_DATE' | translate }}" customFormControlName="receiptDate"
                        [customFormGroup]="newPayForm" [maxDate]="today" attr.data-qa="new-payment-receiptDatenewPayForm-input">
                    </pnc-datepicker>
                </div>

                <div *ngIf="isAbilitatoClaimsPaymentUrgency" class="col-xs-2" style="font-size: 14px;">
                  <pnc-datepicker label="{{ '_CLAIMS_._PAYABILITY' | translate }}" customFormControlName="dueDate"
                   [customFormGroup]="newPayForm" [minDate]="generateMinDate(potPayFE.lossDate)" [maxDate]="today" attr.data-qa="new-payment-dueDate-input">
                  </pnc-datepicker>
              </div>

                <div  *ngIf="isAbilitatoClaimsPaymentUrgency"class="col-xs-2" style="font-size: 14px;">
                  <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PRIORITY' | translate }}</label>
                  <select class="core-select form-control" id="priority" name="priority"
                      formControlName="priority" attr.data-qa="new-payment-priority-input">
                      <option></option>
                      <option *ngFor="let priority of potPayFE.priorityListCombo; " value={{priority.idObject}}>
                          {{priority.description}}</option>
                  </select>
                  <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
              </div>


                <div class="col-xs-2 col-md-2" style="font-size: 14px;" *ngIf="potPayFE.procedureCodeList!=null &&
                                                                               potPayFE.procedureCodeList &&
                                                                               potPayFE.procedureCodeList.length > 0">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PROCEDURE_CODE' | translate }}:</label>
                    <select class="core-select form-control" id="procedureCode" name="procedureCode" (ngModelChange)="setProcedureSelected($event)"
                        formControlName="procedureCode" attr.data-qa="new-payment-procedureCode-input">
                        <option></option>
                        <option *ngFor="let proc of potPayFE.procedureCodeList; " value={{proc.code}}>
                            {{proc.description}}</option>
                    </select><span
                        class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-2 col-md-2" style="font-size: 14px;" *ngIf="!noCoass()">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._SEPARATE_RECEIPT' | translate }}:</label>
                    <input type="checkbox" *ngIf="delegaNostra()" class="btn btn-checkbox"
                        formControlName="separateReceipt">
                    <input type="checkbox" *ngIf="delegaAltrui()" class="btn btn-checkbox" readonly disabled>
                </div>

                <div *ngIf="multiCurrencyIsoCodes" class="col-xs-2 col-md-2" style="font-size: 14px;">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._CURRENCY' | translate }}:</label>
                    <select class="core-select form-control" id="currencyCode" name="currencyCode" formControlName="currencyCode"
                         attr.data-qa="new-payment-currencyCode-input">

                        <option *ngFor="let code of multiCurrencyIsoCodes" [ngValue]=code.codiceDivisaISO>
                           {{code.codiceDivisaISO}} - {{code.descrizioneDivisa}}</option>
                    </select><span
                        class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>


            </div>
            <div *ngIf="newPayForm.controls.currencyCode.value!='EUR' && multiCurrencyIsoCodes" class="row">
                <div  class="col-xs-2 col-xs-offset-10" style="font-size: 14px;">
                    <pnc-datepicker label="{{ '_CLAIMS_._CURRENCY_DATE' | translate }}" customFormControlName="currencyCodeDate"
                    [customFormGroup]="newPayForm"
                         [maxDate]="today" attr.data-qa="new-payment-currencyCodeDate-input">
                    </pnc-datepicker>
                </div>
            </div>

            <p style="font-size: 10px; text-align: center;" *ngIf="!newPayForm.controls.expSel0">
                ...{{ '_CLAIMS_._WAITING' | translate }} .....
                <!-- <ngb-progressbar type="warning" [value]="valueBar" height=".5rem">&nbsp;</ngb-progressbar> -->
            </p>

            <div *ngIf="newPayForm.controls.expSel0">
                <div class="row"
                    *ngFor="let exp of listExpComboVisual; let even = even; let odd = odd; let expIndex = index;">
                    <div [ngClass]="newPayForm.controls.currencyCode.value!='EUR' && multiCurrencyIsoCodes? 'col-xs-4 col-md-4' : 'col-xs-6 col-md-6'"  style="font-size: 14px;font-weight: bold;">
                        <label ng-bind="label" *ngIf="exp.branchTitle" style="font-size: 16px; color:#145f9e;">{{'_CLAIMS_._CLAIM_BRANCH' | translate}}:
                            {{exp.branchTitle}}</label>
                        <br>
                        <table style="width: 100%;">
                            <tr class="col-xs-12 col-md-12">
                                <td class="col-xs-1 col-md-1" style="vertical-align: top;">
                                    <input type="checkbox" class="btn btn-checkbox" name="expSel{{exp.idCampo}}"
                                        id="expSel{{exp.idCampo}}" formControlName="expSel{{exp.idCampo}}">

                                </td>
                                <td class="col-xs-11 col-md-11" style="vertical-align: top;">
                                    <table>
                                    <tr>
                                        <td colspan="4">
                                        <label ng-bind="label" *ngIf="exp.managementType">
                                            {{exp.description}}&nbsp;-&nbsp;
                                            {{exp.moreInfo}}&nbsp;-&nbsp;
                                            ({{exp.managementType}})</label>
                                        <label ng-bind="label" *ngIf="!exp.managementType">
                                            {{exp.description}}&nbsp;-&nbsp;
                                            {{exp.moreInfo}}</label>
                                        </td>
                                    </tr>
                                    <tr class="col-xs-12 col-md-12" *ngIf="(valDefinition(exp) && exp.substatus) || potPayFE.ministerialBranch.startsWith('000010')">
                                        <td class="col-xs-4 col-md-4" *ngIf="exp.substatus">
                                            <label ng-bind="label" >{{ '_CLAIMS_._SUBSTATUS' | translate }}:</label>
                                            <input type="text" class="form-control" value="{{ exp.substatus}}" disabled/>

                                        </td>
                                        <td class="col-xs-4 col-md-4" *ngIf="exp.substatus">

                                            <label ng-bind="label" *ngIf="valDefinition(exp) === '1'">{{ '_CLAIMS_._BUTTONS_._NEW' | translate }}<pnc-required required="true"></pnc-required></label>
                                            <label ng-bind="label" *ngIf="valDefinition(exp) === '2'">{{ '_CLAIMS_._BUTTONS_._NEW' | translate }}</label>

                                            <select class="core-select form-control" style="margin-bottom:0px" id="expIdSubstatus{{exp.idCampo}}"
                                                name="expIdSubstatus{{exp.idCampo}}" formControlName="expIdSubstatus{{exp.idCampo}}"
                                                attr.data-qa="new-payment-expIdSubstatus{{exp.idCampo}}-input"
                                                *ngIf="valDefinition(exp) === '2'" >

                                                <option *ngFor="let aComb of exp.subStatusOpen;" value="{{aComb.idObject}}">{{aComb.description | translate }}</option>

                                            </select>
                                            <select class="core-select form-control" style="margin-bottom:0px" id="expIdSubstatus{{exp.idCampo}}"
                                                name="expIdSubstatus{{exp.idCampo}}" formControlName="expIdSubstatus{{exp.idCampo}}"
                                                attr.data-qa="new-payment-expIdSubstatus{{exp.idCampo}}-input"
                                                *ngIf="valDefinition(exp) === '1'">

                                                <option *ngFor="let aComb of exp.subStatusClose;" value="{{aComb.idObject}}">{{aComb.description | translate }}</option>

                                            </select>
                                            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                                        </td>
                                        <td style="text-align: center; width: 5%;" *ngIf="exp.substatus">
                                            <label ng-bind="label" >{{ '_CLAIMS_._MAIN' | translate }} </label> <br>
                                            <input type="checkbox" class="btn btn-checkbox"
                                                name="mainSubStatus_{{exp.idCausaleDanno}}"
                                                id="mainSubStatus_{{exp.idCausaleDanno}}" [checked]="selectedMainSubStatus == exp.idCausaleDanno"
                                                (change)="onMainSubStatusChange(exp.idCausaleDanno)" >
                                        </td>
                                        <td class="col-xs-4 col-md-4" *ngIf="potPayFE.ministerialBranch.startsWith('000010')">
                                            <label ng-bind="label" >{{ labelCoherence | translate}}:</label>
                                            <select class="core-select form-control" style="margin-bottom:0px" id="expCoherence{{exp.idCampo}}"
                                                name="expCoherence{{exp.idCampo}}" formControlName="expCoherence{{exp.idCampo}}"
                                                attr.data-qa="new-payment-expCoherence{{exp.idCampo}}-input">

                                                <option *ngFor="let aComb of listCoherence;" value="{{aComb.eTypeCode}}">{{aComb.eTypeDescription | translate }}</option>

                                            </select>
                                            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>

                                        </td>
                                    </tr>
                                    </table>
                                </td>
                                <td style="width: 18%;">
                                    <div class="btn-group" *ngIf="exp.feeDataNeeded" style="margin-bottom: 55px;">
                                        <button (click)="dataFee(exp.idCampo, null)"
                                            class="btn btn-warning btn-secondary pull-right" style="width: 75px;">
                                        {{ '_CLAIMS_._FEE_DATA' | translate }}</button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="col-xs-2 col-md-2" style="font-size: 14px;">
                        <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._PAYMENT_DEFINITION' | translate
                            }}:</label>
                        <select class="core-select form-control" id="expDefinition{{exp.idCampo}}"
                            name="expDefinition{{exp.idCampo}}" formControlName="expDefinition{{exp.idCampo}}"
                            attr.data-qa="new-payment-expDefinition{{exp.idCampo}}-input">
                            <option value="1">{{ '_CLAIMS_._TOTAL' | translate }}</option>
                            <option value="2">{{ '_CLAIMS_._PARTIAL' | translate }}</option>
                        </select><span
                            class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-xs-2 col-md-2" style="font-size: 14px;" *ngIf="exp.reserveAmount">
                        <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._RESERVED_AMOUNT' | translate }}
                            &euro;</label>
                        <input type="string" step="0.01" class="form-control" name="reserve" value="{{exp.reserveAmount | number:'1.2-2'}}"
                            disabled />

                    </div>
                    <div class="col-xs-2 col-md-2" style="font-size: 14px;">
                        <div class="row">
                        <div class="col-xs-11 col-md-11">
                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._AMOUNT' | translate }} &euro;</label>
                            <input type="number" step="0.01" class="form-control" name="expAmount{{exp.idCampo}}"
                                (change)="selectedExp(exp.idCampo)"
                                attr.data-qa="new-payment-expAmount{{exp.idCampo}}-input"
                                formControlName="expAmount{{exp.idCampo}}" />
                        </div>
                        <div class="col-xs-1 col-md-1" *ngIf="exp.paymentFactors && exp.paymentFactors.length>0">
                            <br>
                            <img src="assets/img/calc_img.jpg" (click)="openCalculator(exp)"
                            style="width: 40px; height: 40px; margin: 0px 0px 0px -10px; cursor: pointer;" title="{{ '_CLAIMS_._CALCULATE' | translate }}"/>

                        </div>
                      </div>
                    </div>

                    <div  *ngIf="newPayForm.controls.currencyCode.value!='EUR' && multiCurrencyIsoCodes" class="col-xs-2 col-md-2" style="font-size: 14px;">
                        <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._AMOUNT' | translate }} {{newPayForm.controls.currencyCode.value}} </label>
                        <input type="number" step="0.01" class="form-control" name="currencyAmount{{exp.idCampo}}"
                            (change)="selectedExp(exp.idCampo)"
                            attr.data-qa="new-payment-currencyAmount{{exp.idCampo}}-input"
                            formControlName="currencyAmount{{exp.idCampo}}" />

                    </div>


                    <div *ngFor="let subExp of exp.subExposureList; let evenSub = even; let oddSub = odd; let subExpIndex = index;"
                        class="col-xs-12 col-md-12" style="font-size: 14px;">
                        <div *ngIf="subExp.visible && (subExp.procedureCode === procedureCodeSelected || procedureCodeSelected === '') ">
                            <div class="col-xs-12 col-md-12" style="font-size: 14px;">
                                <table style="width: 100%; ">
                                    <tr>
                                        <td style="width: 10%;">
                                            &nbsp;
                                        </td>
                                        <td style="width: 5%;">
                                            <input type="checkbox" class="btn btn-checkbox"
                                                name="subExpSel{{subExp.idCampo}}" id="subExpSel{{subExp.idCampo}}"
                                                (change)="selectedExpFather(exp.idCampo,subExp.idCampo)"
                                                formControlName="subExpSel{{subExp.idCampo}}">
                                        </td>
                                        <td style="width: 40%; text-align: left;">
                                            <label ng-bind="label"> {{subExp.description}} </label>
                                        </td>
                                        <td style="width: 10%;">
                                            <div class="btn-group" *ngIf="subExp.feeDataNeeded">
                                                <button (click)="dataFee(exp.idCampo,subExp.idCampo)"
                                                    class="btn btn-warning btn-secondary pull-right">
                                                {{ '_CLAIMS_._FEE_DATA' | translate }}</button>
                                            </div>
                                            <div class="btn-group" *ngIf="subExp.feeDataOmniaNeeded">
                                                <button (click)="dataFeeOmnia(exp.idCampo,subExp.idCampo)"
                                                    class="btn btn-warning btn-secondary pull-right">
                                                {{ '_CLAIMS_._FEE_DATA' | translate }}</button>
                                            </div>
                                        </td>
                                        <td style="width: 15%;">
                                            <div style="vertical-align: middle;" *ngIf="!subExp.disableDefinition">
                                                <label ng-bind="label" style="font-weight: bold;">{{ 'Payment
                                                    definition' | translate }}:</label><br>
                                                <select class="core-select form-control"
                                                    id="subExpDefinition{{subExp.idCampo}}"
                                                    name="subExpDefinition{{subExp.idCampo}}"
                                                    formControlName="subExpDefinition{{subExp.idCampo}}"
                                                    attr.data-qa="new-payment-subExpDefinition{{subExp.idCampo}}-input">
                                                    <option value="1">{{ '_CLAIMS_._TOTAL' | translate }}</option>
                                                    <option value="2">{{ '_CLAIMS_._PARTIAL' | translate }}</option>
                                                </select><span
                                                    class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                                            </div>
                                        </td>
                                        <td style="width: 1%;" *ngIf="!subExp.disableOmniaAmount || !subExp.real">
                                        </td>
                                        <td style="width: 20%;"
                                            *ngIf="subExp.disableOmniaAmount && subExp.real && !subExp.feeDataNeeded">
                                            <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._AMOUNT' | translate }}
                                                &euro;</label>
                                            <input type="number" step="0.01" class="form-control" name="expAmount{{exp.idCampo}}"
                                                (change)="selectedExp(exp.idCampo)"
                                                attr.data-qa="new-payment-expAmount{{exp.idCampo}}-input"
                                                formControlName="expAmount{{exp.idCampo}}" />
                                        </td>
                                        <td style="width: 20%;">
                                            <label ng-bind="label" *ngIf="!subExp.disableOmniaAmount"
                                                style="font-weight: bold;">{{ '_CLAIMS_._MESSAGE_._OF_WHICH_ATTORNEYS_FEES' | translate }}
                                                &euro;</label>
                                            <label ng-bind="label" *ngIf="subExp.disableOmniaAmount"
                                                style="font-weight: bold;">{{ '_CLAIMS_._AMOUNT' | translate }} &euro;</label>
                                            <br>
                                            <input type="number" step="0.01" *ngIf="!subExp.disableOmniaAmount" class="form-control"
                                                style="margin-bottom: 10px;"
                                                name="subExpLegalFeeAmount{{subExp.idCampo}}"
                                                (change)="calculateTotalOmniaAmount(exp.idCampo, subExp.idCampo)"
                                                attr.data-qa="new-payment-subExpLegalFeeAmount{{subExp.idCampo}}-input"
                                                formControlName="subExpLegalFeeAmount{{subExp.idCampo}}" />

                                            <input type="number" step="0.01" *ngIf="subExp.disableOmniaAmount" class="form-control"
                                                style="margin-bottom: 10px;" name="subExpAmount{{subExp.idCampo}}"
                                                (change)="calculateTotalAmount(exp.idCampo)"
                                                attr.data-qa="new-payment-subExpAmount{{subExp.idCampo}}-input"
                                                formControlName="subExpAmount{{subExp.idCampo}}" />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <label ng-bind="label" style="font-weight: bold;">{{ '_CLAIMS_._NOTE' | translate }}
                    </label>
                    <input type="text" class="form-control" maxlength="255" id="note" name="note" formControlName="note"
                      attr.data-qa="new-payment-note-input" />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="alert alert-danger" *ngIf="errorMessage!=null" role="alert">{{ errorMessage | translate }}
            </div>
        </div>
        <div class="modal-footer">
            <div class="btn-group btn-group-justified">
                <div class="btn-group">
                    <button class="btn btn-primary colorBGBlu2" (click)="onConfirm()" data-qa="party-data-panel-confirm-button"
                        >{{'_CLAIMS_._BUTTONS_._CONFIRM' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!enabled">
        <div class="container-fluid table-white">
            <div *ngFor="let payment of potPayFE.paymentList; let num = index;" class="row">
                <div class="row" style="font-size: 16px; font-weight: bold; padding-top: 15px;">
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        {{num+1}}.{{ '_CLAIMS_._PAYMENT_TYPE' | translate }}: {{payment.paymentTypeDescr}}</div>
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        {{ '_CLAIMS_._RECEIPT_TYPE' | translate }}: {{payment.receiptTypeDescr}}</div>
                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <span style="float: right;">{{ '_CLAIMS_._TOTAL_AMOUNT' | translate }}: €{{payment.totalAmount | number:'1.2-2'}}</span>
                    </div>
                </div>
                <div style="padding-left: 15px; padding-right: 15px">
                    <div class="container-fluid table-white">
                        <div *ngFor="let exposure of payment.exposureList" class="row">
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                {{exposure.description}}&nbsp;-&nbsp;
                                {{exposure.moreInfo}}
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                {{defDescription(exposure.definition)}}
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <div *ngIf="receiptOmnia()">
                            {{ '_CLAIMS_._MESSAGE_._OF_WHICH_ATTORNEYS_FEES' | translate }}: € {{exposure.legalFeeAmount | number:'1.2-2'}}
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <div style="float: right;">
                                    € {{exposure.amount | number:'1.2-2'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="errorMessage!=null">
                <div class="alert alert-danger"  role="alert">{{ errorMessage | translate }}</div>
            </div>
        </div>
        <!--      <ol>
    <div class="row"  *ngFor="let aPotPay of potPayFE.paymentList; ">
        <table style="width: 100%;">
            <tr *ngIf="aPotPay.exposureList.length>0">
            <td>
                <table style="width:100%;background-color:#d7e5f0;">
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><span *ngIf="receiptOmnia()">{{ '_CLAIMS_._MESSAGE_._OF_WHICH_ATTORNEYS_FEES' | translate }}</span></td>
                    <td></td>
                </tr>
                <tr *ngFor="let aExp of aPotPay.exposureList;">
                    <td></td>
                    <td>{{aExp.description}}</td>
                    <td> {{defDescription(aExp.definition)}}</td>
                    <td style="width: 150px"></td>
                    <td style="width: 150px"><span *ngIf="receiptOmnia()">€ {{aExp.legalFeeAmount}}</span></td>
                    <td style="text-align: right;width: 150px">€ {{aExp.amount}}</td>
                </tr>
                </table>
            </td>
            </tr>
        </table>
    </div>
    </ol> -->
    </div>
</form>
