import { Serialized18n } from '@rgi/rx/i18n';
import { AllPersonalData_Key, AllPersonalData_Value, AnyExistingInsurance_Key, AnyExistingInsurance_Value, AuthorisationToForwardDetails_Key, AuthorisationToForwardDetails_Value, AuthorisationToShareData_Key, AuthorisationToShareData_Value, ConsequencesOfNonCompliance_Key, ConsequencesOfNonCompliance_Value, DeclarationWithRegard_Key, DeclarationWithRegard_Value, ForMoreInformation_Key, ForMoreInformation_Value, HaveYouEverBeen_Key, HaveYouEverBeen_Value, IAmNotAUSPerson_Key, IAmNotAUSPerson_Value, IfYouAreConcerned_Key, IfYouAreConcerned_Value, InAddition_Key, InAddition_Value, ObligationToNotify_Key, ObligationToNotify_Value, PleaseProvideAll_Key, PleaseProvideAll_Value, PleaseTakeNote_Key, PleaseTakeNote_Value, ShouldYouChoose_Key, ShouldYouChoose_Value, SubjectToYourConsent_Key, SubjectToYourConsent_Value, TheInsuredPersonMust_Key, TheInsuredPersonMust_Value, USTax_Key, USTax_Value, YouAcknowledgeThat_Key, YouAcknowledgeThat_Value, YouConfirmThatTheAnswers_Key, YouConfirmThatTheAnswers_Value, YouConfirmThatTheDifferences_Key, YouConfirmThatTheDifferences_Value, YouConfirmThatYouHaveReceived_Key, YouConfirmThatYouHaveReceived_Value, YouHerewithRelease_Key, YouHerewithRelease_Value } from './quest-long_keys_de_CH';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const IIAB_LIFE_PPEVO_DE_CH: Serialized18n = {
  "PEP self declaration":"PEP Selbstdeklaration",
  "Are you or a person close to you (relatives in direct line, life partners or close business partners) a politically exposed person (PEP)?":"Sind Sie oder eine Ihnen nahestehende Person (Verwandte in direkter Linie, Lebensgefährten oder enger Geschäftspartner) eine politisch exponierte Person (PEP)?",
  "What is the exact function?":"Was ist die genaue Funktion?",
  "Investment Profile":"Erläuterungen zu den Anlageprofilen",
  "For what period of time do you intend to save?":"Über welchen Zeitraum möchten Sie sparen?",
  "Less than 15 years":"Weniger als 15 Jahre",
  "15 - 25 years":"15-25 Jahre",
  "More than 25 years":"Mehr als 25 Jahre",
  "Are you dependent on the maturity benefit from this contract to cover your living costs?":"Sind Sie auf die Ablaufleistung aus diesem Vertrag zur Finanzierung des Lebenunterhalts angewiesen?",
  "Yes":"Ja",
  "Partly":"Teilweise",
  "No":"Nein",
  "In which form of providence would you like to invest?":"In welche Vorsorgeform möchten Sie investieren?",
  "Investment (single premium payment)":"Anlegen",
  "Savings (regular premiums)":"Sparen",
  "What range of price fluctuation do you consider acceptable for this contract?":"Welche Kursschwankungen erachten Sie für diesen Vertrag als vertretbar?",
  "I tolerate only small fluctuations in price":"Ich toleriere nur geringe Kursschwankungen",
  "I accept certain fluctuations in return for slightly higher returns":"Für eine höhere Rendite nehme ich gewisse Kursschwankungen in Kauf",
  "I accept high price fluctuations in return for higher returns in the long term":"Für eine langfristig höhere Rendite nehme ich grosse Kursschwankungen in Kauf",
  "Have you already considered potential investment risks?":"Haben Sie sich bereits mit möglichen Anlagerisiken beschäftigt?",
  "A little":"Wenig",
  "I have a basic understanding":"Ich konnte mir Grundkenntnisse aneignen",
  "I have looked into this in detail":"Ich habe mich intensiv damit auseinandergesetzt",
  "In which of these have you already invested?":"In welche dieser Anlagen haben Sie bereits investiert?",
  "Bank account":"Bankkonto",
  "Bonds (bond funds)":"Obligationen (-fonds)",
  "Equities (equity funds)":"Aktien (-fonds)",
  "All of above":"Alle oben erwähnten Anlagen",
  "Do you want an ESG investment?":"Möchten Sie ein ESG-Investment?",
  "Confirmations":"Bestätigungen",
  "Identification of the policyholder":"Identifikation des Versicherungsnehmers oder der Versicherungsnehmerin",
  "Personal contact with customer and delegation agreement on file.":"Persönlicher Kontakt mit der Kundin oder dem Kunden und eine Delegationsvereinbarung sind vorhanden.",
  "No personal contact with the customer, e.g. telephone contact, or no delegation agreement on file.":"Es erfolgte kein persönlicher Kontakt mit dem Kunden oder der Kundin, z.B. aufgrund telefonischer Kontaktaufnehme,  oder es ist keine Delegationsvereinbarung vorhanden. ",
  "The agent signed below declares they have a valid delegation agreement from Generali and have confirmed the identity of the policyholder by means of a valid passport, a valid identity card or a valid driver's license in the case of Swiss citizens, or by means of a valid residence permit in the case of foreign nationals. An easily legible copy/photograph of the valid form of identification is to be included with the application. The copy/photograph is to be dated and signed by the agent.":"Der unterzeichnende Vermittler erklärt, über eine gültige Delegationsvereinbarung der Generali zu verfügen und die Identifikation des Versicherungsnehmers für Schweizer Staatsbürger anhand eines gültigen Passes, einer gültigen Identitätskarte oder eines gültigen Führerausweises, für ausländische Staatsangehörige anhand eines gültigen Ausländerausweises festgestellt zu haben. Eine gut lesbare Kopie/Fotografie des gültigen Ausweises ist dem Antrag beizulegen. Die Kopie/Fotografie ist vom Vermittler zu datieren und zu unterzeichnen.",
  "The identity of the policyholder is confirmed by means of a valid passport, a valid identity card or a valid driver's license in the case of Swiss citizens, or by means of a valid residence permit in the case of foreign nationals. A certified (confirmation only by a Generali agency, the Swiss post office, the community of residents, a Swiss notary or a lawyer entered in the Swiss Lawyer's Register), easily legible copy/photograph of the valid form of identification is to be included with the application.":"Die Identifikation des Versicherungsnehmers erfolgt für Schweizer Staatsbürger anhand eines gültigen Passes, einer gültigen Identitätskarte oder eines gültigen Führerausweises, für ausländische Staatsangehörige anhand eines gültigen Ausländerausweises. Eine echtheitsbestätigte (Bestätigung nur durch eine Generali Agentur, die schweizerische Post, die Wohngemeinde, durch einen schweizerischen Notar oder durch einen in einem schweizerischen Anwaltsregister eingetragenen Anwalt), gut lesbare Kopie/Fotografie des gültigen Ausweises ist dem Antrag beizulegen.",
  "Legality of delegation of due diligence obligations":"Rechtmässigkeit der Delegation der Sorgfaltspflichten",
  "The person identifying the policyholder (usually the broker) confirms that they are an employee of Generali itself or a Generali sales partner or that they have a Generali delegation agreement and have carried out the identification themselves.":"Die den Versicherungsnehmer oder Versicherungsnehmerin identifizierende Person (in der Regel der Vermittler) bestätigt, dass sie Mitarbeitende der Generali selbst oder eines Vertriebspartners von Generali ist oder selbst eine Delegationsvereinbarung der Generali hat und die Identifikation persönlich durchgeführt hat.",
  "Consent for data consultation":"Einwilligung zur Einsichtnahme",
  "A comprehensive and full advice is very important to me. Therefore, as the person to be insured, I expressly agree by my signature that the advisor in charge of the contract may take note of my health data and that the response to the benefits review (e.g. additional conditions) may be sent to him/her personally (unsealed, but confidential), so that he/she can advise the policyholder in a transparent manner (this explicitly excludes access to my medical reports and diagnoses). I authorize Generali's relevant internal service to provide the necessary information to my advisor in charge of the contract.":"Eine gesamtheitliche und vollständige Beratung ist mir sehr wichtig. Daher stimme ich als zu versichernde Person mit Unterzeichnung ausdrücklich zu, dass der/die vertragsbetreuende Berater*in meine Gesundheitsdaten zur Kenntnis nehmen darf und ihm/ihr die Antwort der Leistungsprüfung (zum Beispiel Ergänzende Bedingungen) persönlich (unverschlossen, jedoch vertraulich) zugestellt werden, so dass er den/die Versicherungsnehmer*in transparent beraten kann (dies schliesst einen Zugang zu meinen Arztberichten und Diagnosen explizit aus). Ich ermächtige die dafür zuständige interne Stelle bei der Generali, meinem/meiner vertragsbetreuenden Berater*in die dafür notwendigen Informationen zuzustellen.",
  "I agree":"Ich bin einverstanden",
  "I disagree":"Ich bin nicht einverstanden",
  "I do not agree":"Ich bin nicht einverstanden",
  "Data protection and digital communication":"Datenschutz und digitale Kommunikation",
  [SubjectToYourConsent_Key]: SubjectToYourConsent_Value,
  [InAddition_Key]: InAddition_Value,
  [ShouldYouChoose_Key]: ShouldYouChoose_Value,
  "Confirmation by the undersigned":"Bestätigung der unterzeichnenden Personen",
  [YouHerewithRelease_Key]: YouHerewithRelease_Value,
  [YouConfirmThatYouHaveReceived_Key]: YouConfirmThatYouHaveReceived_Value,
  [YouConfirmThatTheDifferences_Key]: YouConfirmThatTheDifferences_Value,
  [YouAcknowledgeThat_Key]: YouAcknowledgeThat_Value,
  [YouConfirmThatTheAnswers_Key]: YouConfirmThatTheAnswers_Value,
  "Intermediary Confirmation":"Bestätigungen Vermittler",
  "Is the policyholder related to you?":"Ist der Versicherungsnehmer oder die Versicherungsnehmerin mit Ihnen verwandt?",
  "If yes, how:":"Wenn ja, wie:",
  "Has/have the signature(s) on this application been appended by the person(s) concerned in his/their own hand in your presence?":"Wurde(n) die Unterschrift(en) zu diesem Antrag von der (den) betreffenden Person(en) eigenhändig in Ihrer Gegenwart gesetzt?",
  "If not, which documents have not been signed in person and why not?":"Wenn nein, welche Unterschriften wurden nicht eigenhändig gesetzt und warum nicht?",
  "Manual signature by agent or intermediary required":"Unterschrift durch den Agent oder Vermittler erforderlich",
  "Declaration of the policyholder regarding tax residencies":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten",
  [DeclarationWithRegard_Key]: DeclarationWithRegard_Value,
  [PleaseProvideAll_Key]: PleaseProvideAll_Value,
  "Tax residence (country)":"Steuerliche Ansässigkeit (Land)",
  "Tax Identification Number (TIN)":"Steueridentifikationsnummer (TIN)",
  "Reason if no TIN is included for tax residence":"Begründung, falls keine TIN zur Steueransässigkeit angegeben wird",
  "If you are only liable to taxation in Switzerland, please just mention the country.":"Von ausschliesslich in der Schweiz steueransässigen Personen genügt die Angabe des Landes.",
  "Do you have another tax residence?":"Haben Sie eine weitere steuerliche Ansässigkeit?",
  "Obligation to notify: If you move and your tax residence changes as a result, you must notify Generali within 30 days. Under Art. 35 of the AEOI Act, any person who deliberately or negligently provides a Swiss financial institution with a false self-disclosure, fails to notify it of changes to their circumstances or makes false statements concerning changes to their circumstances will be punished by a fine.":"Meldepflicht: Wenn Sie umziehen und sich dadurch Ihre steuerliche Ansässigkeit ändert, müssen Sie Generali innerhalb von 30 Tagen informieren. Gemäss Art. 35 AIA-Gesetz wird mit Busse bestraft, wer einem schweizerischen Finanzinstitut vorsätzlich eine falsche Selbstauskunft erteilt, Änderungen der Gegebenheiten nicht mitteilt oder über Änderungen der Gegebenheiten falsche Angaben macht.",
  [AuthorisationToForwardDetails_Key]: AuthorisationToForwardDetails_Value,
  [ForMoreInformation_Key]: ForMoreInformation_Value,
  "Generali is not permitted to advise you on tax matters in this regard.":"Generali ist es nicht erlaubt, Sie diesbezüglich in steuerlichen Angelegenheiten zu beraten.",
  "If you have any other questions about your tax residences, please contact a tax advisor.":"Bei weiteren Fragen zu Ihren Steueransässigkeiten wenden Sie sich bitte an eine Steuerberatung.",
  "Declaration regarding US tax liability or a person with indication of US tax liability":"Erklärung zur US-Steuerpflicht oder zu einem Indiz für eine US-Steuerpflicht",
  "Switzerland has concluded an agreement with the United States of America (hereinafter the US or USA) for Cooperation to Facilitate the Implementation of the Foreign Account Tax Compliance Act (FATCA). We are therefore obliged to clarify whether our contractual partners are subject to US tax or whether there is any indication of US tax liability. This occurs when concluding a contract, when amending a contract, at regular intervals and when paying out benefits.":"Die Schweiz hat mit den Vereinigten Staaten von Amerika (nachstehend US oder USA) ein Abkommen über die Zusammenarbeit für die erleichterte Umsetzung des Foreign Account Tax Compliance Act (FATCA) geschlossen. Demnach sind wir verpflichtet abzuklären, ob unsere Vertragspartner und Vertragspartnerinnen einer US-Steuerpflicht unterliegen oder ob es ein Indiz für eine US-Steuerpflicht gibt. Dies erfolgt bei Vertragsabschluss, bei Vertragsänderungen, in regelmässigen Abständen sowie bei Auszahlungen von Leistungen.",
  "U.S. Indications":"U.S. Indizien ",
  "In particular, the following are considered as such:":"Als solche gelten insbesondere:",
  "1. US citizens or individuals with US dual citizenship":"1. US-Staatsbürger oder US-Doppelbürger",
  "2. Individuals resident in the US on the basis of a permanent or temporary residence permit (e.g. green card, incl. dual residence, visa)":"2. Personen mit Wohnsitz in den USA aufgrund einer permanenten oder befristeten Aufenthaltsbewilligung (z.B. Greencard, inkl. Doppelwohnsitz, Visum",
  "3. Individuals born in the US":"3. US-Geburtsort",
  "4. Individuals at present with a US postal or residential address (incl. US P.O. Box or a c/o address domicile)":"4. Gegenwärtige US-Post- oder Wohnadresse (inkl. US-Postfach oder eines „c/o Adresse“-Domizils)",
  "5. Individuals with a current US telephone number":"5. Gegenwärtige US-Telefonnummer",
  "6. Individuals with a standing order on an account held in the USA":"6. Dauerauftrag auf ein in USA geführtes Konto",
  "7. Individuals with a currently valid power of attorney or authorisation to sign issued to an individual with a US address in relation to assets":"7. Gegenwärtig geltende Vollmacht oder Unterzeichnungsberechtigung zu Gunsten einer Person mit US-Adresse bezüglich Vermögensangelegenheiten",
  "None of the definitions listed above as points 1-7 apply to my situation.":"Es trifft keine der vorstehenden Definitionen nach Ziffer 1–7 auf mich zu",
  "Status for FATCA":"Status für FATCA",
  "I hereby confirm that :":"Ich bestätige hiermit: ",
  "I am a U.S. person as defined by U.S. tax law and I am submitting a U.S. tax form W-9 (duly completed, dated and signed)":"Ich bin eine U.S-Person im Sinne des U.S. Steuerrecht und reiche das US-Steuerformular W-9 ausgefüllt, datiert und unterzeichnet ein.",
  [IAmNotAUSPerson_Key]: IAmNotAUSPerson_Value,
  "I am not a U.S. Person as defined by U.S. tax law and none of the U.S. indices (as listed above) apply to me":"Ich bin keine U.S.-Person im Sinne des U.S. Steuerrechts obwohl U.S.-Indizien auf mich zutreffen (vgl. oben). Alle oben aufgeführten Indizien sind durch Einreichung von Dokumenten wie folgt zu widerlegen: ",
  "For all indications listed from 1 to 7 :":"Für alle Indizien nach Ziffer 1 – 7: ",
  [USTax_Key]: USTax_Value,
  "As well as":"sowie zusätzlich:",
  [IfYouAreConcerned_Key]: IfYouAreConcerned_Value,
  "You can download the U.S. tax forms from our homepage www.generali.ch.":"Sie finden die U.S.-Steuerformulare auf unserer Homepage www.generali.ch. ",
  [ObligationToNotify_Key]: ObligationToNotify_Value,
  [AuthorisationToShareData_Key]: AuthorisationToShareData_Value,
  [PleaseTakeNote_Key]: PleaseTakeNote_Value,
  [ConsequencesOfNonCompliance_Key]: ConsequencesOfNonCompliance_Value,
  "Benefit & premium":"Leistungen & Prämien",
  "Disability/disability benefits insured under Pillar 2":"Leistungen bei Erwerbsunfähigkeit/Invalidität in der 2. Säule",
  "Private insurance for death or disability/invalidity benefits":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität",
  "AHV pension":"AHV Einkommen",
  "Clarifying insurance needs":"Abklärung Versicherungsbedarf",
  "6.5a - Do you already have disability/disability benefits insured under Pillar 2 (occupational benefits insurance)?":"6.5a - Haben Sie bereits Leistungen bei Erwerbsunfähigkeit/Invalidität in der 2. Säule (berufliche Vorsorge) versichert?",
  "How much is your annual pension in CHF?":"Wie hoch ist die Jahresrente in CHF?",
  "6.5c - Have you already taken out other private insurance for death or disability/invalidity benefits or have you currently applied for one?":"6.5c - Haben Sie weitere private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität abgeschlossen oder haben Sie gegenwärtig solche beantragt? ",
  "Data relating to the proposal currently being applied for does not need to be entered here. This data are already taken into account automatically.":"Daten, die sich auf den aktuell beantragten Vorschlag beziehen, müssen hier nicht eingegeben werden. Diese Daten werden bereits automatisch berücksichtigt.",
  "Proposal/contracts":"Anträge/Verträge",
  "Company":"Gesellschaft",
  "Sum insured in the event of death/ capitalization":"Versicherungssumme Todesfall/ Kapitalisierung",
  "Annual pension in the event of incapacity for work/disability":"Jahresrente bei Erwerbsunfähigkeit/ Invalidtät",
  "Annual premium":"Jahresprämie",
  "Other private insurance?":"Weitere private Versicherungen?",
  "6.6 - How much is your annual current fixed gross AHV pension (excluding bonus, etc.)? Amount in CHF":"6.6 - Wie hoch ist Ihr jährliches fixes Brutto-AHV Einkommen (ohne Bonus etc.)?",
  [AnyExistingInsurance_Key]: AnyExistingInsurance_Value,
  "Do you have children?":"Haben Sie Kinder?",
  "Age Child":"Alter Kind",
  "Add child?":"Kind hinzufügen?",
  "Details regarding the purpose of the insurance":"Angaben zum Zweck der Versicherung",
  "Please provide a detailed explanation why the insurance is needed and provide a calculation of the amount of insurance cover requested, or attach a separate needs analysis.":"Bitte erklären Sie ausführlich den Bedarf und tragen Sie die Berechnung des beantragten Versicherungsbedarfs ein oder legen Sie eine separate Bedarfsanalyse bei.",
  "Details of the insured person's employment":"Angaben zur Erwerbstätigkeit der versicherten Person",
  "What was your annual income subject to AHV contributions over the last three years?":"Wie hoch war Ihr jährliches AHV- pflichtiges Einkommen in den letzten 3 Jahren?",
  "Income in CHF subject to AHV/AVS":"Einkommen CHF AHV-pflichtig",
  "Year":"Jahr",
  "Firm owner / key person or company shareholder":"Firmeninhaber / Keyman oder beteiligt an Firmen",
  "Are you, as the insured person, the owner or key person of a company or do you hold company shares?":"Sind Sie als versicherte Person Inhaber oder Keyman einer Firma oder halten Sie Beteiligungen an Firmen?",
  "Company name":"Name der Firma",
  "PC":"PLZ",
  "Town":"Ort",
  "Postcode":"PLZ",
  "Town/city":"Ort",
  "Street":"Strasse",
  "no.":"Nr.",
  "Type of business":"Art des Geschäfts",
  "High insurance benefits":"Hohe Versicherungsleistungen",
  "This is the case if: insured death benefits exceed CHF 2,000,000, or monthly disability benefits exceed CHF 5,000.":"Übersteigen die Todesfallleistungen CHF 2'000'000.- oder die monatlichen Invaliditätsrenten CHF 5'000.-?",
  [TheInsuredPersonMust_Key]: TheInsuredPersonMust_Value,
  "Signatures and authorisation for the reinsurer":"Unterschriften und Vollmacht für den Rückversicherer",
  [AllPersonalData_Key]: AllPersonalData_Value,
  "Contract":"Vertrag",
  "Application":"Antrag",
  "General questions for the person to be insured":"Allgemeine Fragen an die zu versichernde Person",
  "Insurance application for life, accident, health or income protection":"Abgelehnte oder mit Erschwerung angenommene Anträge",
  "Insurance benefits":" Versicherungsleistungen",
  "Exposition to particular risk":"Besondere Risiken",
  "Martial Arts":"Kampfsport",
  "Non-motorised aeronautics":"Nichtmotorisierte Flugsportarten",
  "Diving":"Tauchsport",
  "Aviation":"Luftfahrt",
  "Mountaineering":"Bergsport",
  "Motor Racing":"Motorrennsport",
  "Overseas stay":"Auslandsaufenthalt",
  "6.1 - Have you ever had an application for life, accident, health or income protection insurance declined or accepted with reservations or have you ever declined rigorous conditions?":"6.1 - Wurde bei Ihnen jemals ein Antrag für Lebens-, Unfall-, Kranken- oder Erwerbsunfähigkeitsversicherung abgelehnt oder mit Erschwerung angenommen, oder haben Sie erschwerte Bedingungen abgelehnt?",
  "Reason":"Grund",
  "Further applications?":"Weitere Anträge?",
  "6.2 - Have you ever taken advantage of benefits, e.g. daily allowance, pension, impairment compensation, assistance, retraining, etc. provided by disability insurance or another insurer?":"6.2 - Haben Sie jemals Leistungen, z. B. Taggeld, Rente, Integritätsentschädigung, Hilfsmittel, Umschulungs- massnahmen etc. bei einer Invalidenversicherung oder einem anderen Versicherer beantragt?",
  "Insurer":"Versicherer",
  "Duration: from":"Dauer: von",
  "Duration: to":"Dauer: bis",
  "Add benefits?":"Leistungen hinzufügen?",
  "6.3 - Are you exposed to a particular risk at work or in your free time? For example as a diver, skydiver, hang glider or paraglider, motor racing driver or motorbike racer or other extreme sport participant?":"6.3 - Sind Sie bei Ihrem Beruf oder in der Freizeit einem besonderen Risiko ausgesetzt? Zum Beispiel als Taucher, Fallschirmspringer, Delta- oder Gleitschirmflieger, Auto- oder Motorradrennfahrer oder anderen Extremsportarten?",
  "What are the risks?":"Welche Risiken sind das?",
  "Please provide more detailed information:":"Bitte machen Sie genauere Angaben:",
  "Do you participate in martial arts?":"Machen Sie Kampfsport?",
  "Which type of martial arts do you pursue?":"Welche Arten Kampfsport betreiben Sie?",
  "Do you take part in competitions/tournaments?":"Nehmen Sie an Wettkämpfen/Turnieren teil?",
  "If yes, which?":"Wenn ja, welche?",
  "How many times a year?":"Wie oft pro Jahr?",
  "Have you ever had an accident or been injured during a fight?":"Hatten Sie dabei schon einmal einen Unfall oder wurden Sie bei einem Kampf verletzt? ",
  "How and where did this happen?":"Wie und wo ist das passiert?",
  "Are you able to provide any more information to allow us to better assess your risk?":"Haben Sie noch weitere Informationen für uns, damit wir Ihr Risiko besser einschätzen können?",
  "What?":"Was?",
  "What type of non-motorised aviation you pursue?":"Welche Art von Flugsport betreiben Sie?",
  "Please enter the number of jumps per year":"Bitte geben Sie die Anzahl Sprünge pro Jahr an",
  "Please enter the flight hours per year":"Bitte geben Sie die Flugstunden pro Jahr an",
  "In which countries do you pursuethese activities?":"In welchen Ländern üben Sie die obengenannten Flugsportarten aus?",
  "Please select the one that applies to you:":"Bitte wählen Sie das auf Sie Zutreffende aus:",
  "Please provide a copy of your licence/certificate.":"Bitte legen Sie eine Kopie Ihrer Lizenz / Ihres Brevets bei",
  "Accepted formats: PDF, JPG, JPEG, PNG":"Akzeptierte Formate: PDF, JPG, JPEG, PNG ",
  "What type of diving do you do?":"Welche Art von Tauchen üben Sie aus?",
  "Do you have a valid diving license?":"Haben Sie einen gültigen Tauchschein?",
  "How many dives do you complete a year (on average)?":"Wie viele Tauchgänge machen Sie pro Jahr (durchschnittlich)?",
  "How deep do you normally dive? (Please specify the number of metres.)":"Wie tief tauchen Sie normalerweise? (Bitte geben Sie die Anzahl Meter an.)",
  "What is your maximum diving depth? (Please specify the number of metres.)":"Was ist Ihre maximale Tauchtiefe? (Bitte geben Sie die Anzahl Meter an.)",
  "If you dive deeper than 40 metres: when and how often do you do this?":"Falls Sie mehr als 40 Meter tief tauchen: Wann und wie oft tun Sie das?",
  "Do you normally dive":"Tauchen Sie normalerweise",
  "Where do you dive?":"Wo tauchen Sie?",
  "Have you ever been injured while diving? Have you ever had a diving accident?":"Wurden Sie beim Tauchen jemals verletzt? Hatten Sie jemals einen Tauchunfall?",
  "If yes, please provide more detailed information: what happened, when and where? What were the consequences?":"Falls ja, machen Sie bitte genauere Angaben: Was ist passiert, wann und wo? Was waren die Folgen?",
  "If so, please specify:":"Wenn ja welche",
  "Please provide a copy of your diving license.":"Bitte legen Sie eine Kopie des Tauchscheins bei.",
  "In what capacity do you fly?":"In welcher Funktion fliegen Sie?",
  "Which types of aircraft do you fly?":"Welche Flugzeugtypen fliegen Sie?",
  "What kind of pilot’s licence(s) have you been issued with?":"Welche Fluglizenzen besitzen Sie?",
  "Other, which:":"Andere, welche:",
  "Has your licence ever been suspended or revoked?":"Wurde Ihre Lizenz jemals gesperrt oder widerrufen?",
  "What is the purpose of your flights?":"Welchem Zweck dienen Ihre Flüge?",
  "How many hours have you already flown and do you intend to fly?":"Wie viele Stunden sind Sie schon geflogen oder werden Sie noch fliegen?",
  "current total":"bis heute insgesamt",
  "in the last 12 months":"in den letzten 12 Monaten",
  "estimated in the next 12 months":"in den nächsten 12 Monaten",
  "In which areas/regions/countries do you fly?":"In welchen Gebieten/Ländern fliegen Sie?",
  "How long have you been mountaineering?":"Seit wann machen Sie Bergsport?",
  "Do you mountaineer professionally or simply as a hobby?":"Betreiben Sie Bergsteigen beruflich oder nur als Hobby?",
  "Since:":"Seit:",
  "In which types of activity do you participate?":"Welche Art von Bergsteigen/Touren unternehmen Sie?",
  "Mountain hikes / trekking - Since:":"Bergwanderung / Trekking - seit:",
  "Mountain climbing - Since:":"Bergsteigen - seit:",
  "Rock climbing  - Since:":"Felsklettern - seit:",
  "Climbing wall - Since:":"Klettern an künstlichen Wänden - seit:",
  "Ice climbing - Since:":"Eisklettern - seit:",
  "Speleology  - Since:":"Höhlenerkundung - seit:",
  "Other - Since:":"andere - seit:",
  "If you are a climber, please supply further information":"Falls Sie klettern, benötigen wir folgende Angaben:",
  "UIAA degree of difficulty:":"UIAA Schwierigkeitsgrad:",
  "Do you climb alone or in groups?":"Klettern Sie allein und/oder in Gruppen?",
  "Are you secured by rope and/or unsecured?":"Klettern Sie gesichert und/oder ungesichert?",
  "How many times a year do you go climbing?":"Wie oft pro Jahr?",
  "Where and how often do you participate in mountaineering?":"Wo und wie oft sind Sie unterwegs?",
  "Countries/regions/areas?":"Länder/Regionen/Gebiete? ",
  "Up to what altitude?":"Bis in welche Höhe? ",
  "Average number of tours per year?":"Mittlere Anzahl Touren jährlich? ",
  "Are you planning a climb/expedition overseas?":"Planen Sie eine Tour / eine Expedition im Ausland?",
  "If so, please provide more detailed information about this:":"Wenn ja, machen Sie bitte genauere Angaben dazu:",
  "Have you ever been injured or had an accident while mountaineering?":"Haben Sie sich beim Bergsteigen jemals verletzt oder hatten Sie einen Unfall?",
  "If so, what exactly happened?":"Wenn ja, was ist genau passiert?",
  "If so, please give details:":"Wenn ja, welche:",
  "How long have you been motor racing?":"Seit wann machen Sie Motorrennsport?",
  "What kind of car/motorbike do you drive/ride?":"Was für ein Auto/Motorrad fahren Sie?",
  "type":"Typ",
  "brand":"Marke",
  "kW/HP":"kW/PS",
  "cylinder capacity":"Hubraum",
  "What type of racing do you do?":"Welche Art von Rennen fahren Sie? ",
  "in Europe":"in Europa",
  "outside  Europe":"ausserhalb Europa",
  "Which?":"Welche?",
  "Circuit races - If so, number of races total till now":"Rundstrecken - Wenn ja, Anzahl der Rennen bisher total",
  "Mountain races - If so, number of races total till now":"Bergrennen - Wenn ja, Anzahl der Rennen bisher total",
  "Rallies in Europe - If so, number of races total till now":"Geländerennen/Rallye in Europa - Wenn ja, Anzahl der Rennen bisher total",
  "Rallies outside Europe - If so, number of races total till now":"Geländerennen/Rallye ausserhalb Europa - Wenn ja, Anzahl der Rennen bisher total",
  "Go-Kart - If so, number of races total till now":"Go-Kart - Wenn ja, Anzahl der Rennen bisher total",
  "Other - If so, number of races total till now":"Andere - Wenn ja, Anzahl der Rennen bisher total",
  "Circuit races - If so, number of races planned per year":"Rundstrecken - Wenn ja, Anzahl der Rennen geplant pro Jahr",
  "Mountain races - If so, number of races planned per year":"Bergrennen - Wenn ja, Anzahl der Rennen geplant pro Jahr",
  "Rallies in Europe - If so, number of races planned per year":"Geländerennen/Rallye in Europa - Wenn ja, Anzahl der Rennen geplant pro Jahr",
  "Rallies outside Europe - If so, number of races planned per year":"Geländerennen/Rallye ausserhalb Europa - Wenn ja, Anzahl der Rennen geplant pro Jahr",
  "Go-Kart - If so, number of races planned per year":"Go-Kart - Wenn ja, Anzahl der Rennen geplant pro Jahr",
  "Other - If so, number of races planned per year":"Andere - Wenn ja, Anzahl der Rennen geplant pro Jahr",
  "Circuit races - Description of races and possible title":"Rundstrecken - Bezeichnung der Rennen und möglichen Titel",
  "Mountain races - Description of races and possible title":"Bergrennen - Bezeichnung der Rennen und möglichen Titel",
  "Rallies in Europe - Description of races and possible title":"Geländerennen/Rallye in Europa- Bezeichnung der Rennen und möglichen Titel",
  "Rallies outside Europe - Description of races and possible title":"Geländerennen/Rallye ausserhalb Europa - Bezeichnung der Rennen und möglichen Titel",
  "Go-Kart - Description of races and possible title":"Go-Kart - Bezeichnung der Rennen und möglichen Titel",
  "Other - Description of races and possible title":"Andere - Bezeichnung der Rennen und möglichen Titel",
  "Have you ever had an accident during a race or training? Were you injured or was your vehicle damaged?":"Hatten Sie bei einem Rennen oder beim Training schon einmal einen Unfall? Wurden Sie dabei verletzt oder wurde Ihr Fahrzeug beschädigt?",
  "6.4 - Are you planning an overseas stay in the next two years that will be longer than six months?":"Planen Sie in den nächsten 2 Jahren ein Auslandsaufenthalt von länger als 6 Monaten?",
  "Risks of travelling abroad":"Aufenthaltsrisiko ",
  "Country and location of the planned visit":"Land und Ort des bevorstehenden oder künftigen Aufenthaltes",
  "Adress":"Adresse",
  "Country":"Land",
  "Mobile":"Mobile",
  "E-Mail":"E-Mail",
  "What is the purpose of your overseas stay?":"Was ist der Zweck des Auslandaufenthalts?",
  "Please describe your occupation:":"Bitte beschreiben Sie Ihre berufliche Tätigkeit:",
  "Who is your employer (name, registered office)?":"Welches ist der Auftrag-/Arbeitgeber (Name, Sitz)?",
  "Its local address?":"Adresse des dortigen Arbeitgebers?",
  "Your place of work?":"Arbeitsort?",
  "Is your overseas stay permanent or temporary?":"Gehen Sie dauerhaft oder nur vorübergehend ins Ausland?",
  "Are you under Swiss AHV-obligatory (obligatory insurance) during your stay?":"Unterstehen Sie während Ihres Auslandaufenthaltes weiterhin der schweizerischen AHV-Pflicht (obligatorische Versicherung)?",
  "During your stay do you have to pay taxes in Switzerland?":"Sind Sie während Ihres Auslandaufenthaltes weiterhin in der Schweiz steuerpflichtig?",
  "Will you continue to work in Switzerland following your move, either as a cross-border commuter or as a weekly resident?":"Arbeiten Sie nach Ihrem Umzug weiterhin in der Schweiz, entweder als Grenzgänger oder als Wochenaufenthalter?",
  "Cross border commuter: in which canton?":"Grenzgänger: in welchem Kanton",
  "Week worker: in which canton?":"Wochenaufenthalter: in welchem Kanton",
  "Have you any further information which is important for risk assessment?":"Haben Sie weitere, risikorelevante Angaben zu machen?",
  "If yes, which one:":"Wenn ja, welche:",
  "Address for correspondence in Switzerland (if your place of residence abroad is beyond the neighbor states of Switzerland)":"Korrespondenzadresse in der Schweiz (falls Wohnsitz ausserhalb der Nachbarstaaten der Schweiz)",
  "Disability insurance":"Invalidenversicherung",
  "Other":"Andere",
  "Professionally":"berufsmässig",
  "As a hobby":"als Hobby",
  "Boxing":"Boxen",
  "Kickboxing":"Kickboxen",
  "Asian martial arts":"Asiatische Kampfsportarten",
  "Swiss wrestling":"Schwingen",
  "Ballooning / Gliding / Paragliding":"Ballonfahren / Segelfliegen / Gleitschirmfliegen",
  "Hang gliding / Parachuting / Skydiving":"Hängegleiten / Fallschirmspringen / Skydiving",
  "Bungee Jumping":"Bungee Jumping",
  "Base jumping / Wing Suite":"Base jumping / Wing Suite",
  "In Europe":"In Europa",
  "Outside Europe":"Ausserhalb Europa",
  "I am a professional athlete":"Ich bin Berufssportler",
  "I am an amateur athlete":"Ich bin Amateursportler",
  "I am an instructor/trainer":"Ich bin Lehrer/Ausbildner) ",
  "Free diving (ABC diving, Apnea)":"freies Tauchen (ABC-Tauchen, Apnoe)",
  "With compressed air equipment":"mit Presslufttauchgerät",
  "alone":"allein",
  "with one other person":"zu zweit",
  "in a group":"in einer Gruppe",
  "the sea":"Meer",
  "lakes":"See",
  "rivers":"Flüsse",
  "caves (please specify)":"Höhlen (bitte nähere Angaben)",
  "Private pilot":"Privatpilot",
  "Commercial pilot":"Berufspilot",
  "Military pilot":"Militärpilot",
  "Flight instructor":"Fluglehrer",
  "Student pilot":"Flugschüler",
  "Aerobatics":"Kunstflug",
  "mountain hikes / trekking":"Bergwanderung / Trekking",
  "mountain climbing":"Bergsteigen",
  "rock climbing":"Felsklettern",
  "climbing wall":"Klettern an künstlichen Wänden",
  "ice climbing":"Eisklettern",
  "speleology":"Höhlenerkundung",
  "other":"andere",
  "Circuit races":"Rundstrecken",
  "Mountain races":"Bergrennen",
  "Rallies":"Geländerennen/Rallye",
  "Go-Kart":"Go-Kart",
  "Private":"Privat",
  "Business":"Beruflich",
  "definitive":"definitiv",
  "temporary":"vorübergehen",
  "General notice":"Genereller Hinweis",
  "Physical or mental disorders":"Körperliche oder psychische Beeinträchtigungen",
  "Limited capacity to work":"Eingeschränkte Arbeitsfähigkeit",
  "Doctor":"Arzt",
  "Body measurements":"Körpermasse",
  "Medication":"Medikamente",
  "HIV Test":"HIV-Test",
  "Unability to work":"Arbeitsunfähigkeit",
  "Disease":"Krankheit",
  "Surgery undergone":"Durchgeführte Operation",
  "Surgery need":"Anstehende Operation",
  "Medical treatment and check-ups":"Medizinische Behandlung und Vorsorgeuntersuchungen",
  "Psychiatrist, psychologist or psychotherapist treatment":"Behandlung durch einen Psychiater, Psychologen oder Psychotherapeuten",
  "Chiropractor, physiotherapist or naturopath treatment":"Behandlung durch Chiropraktiker, Physiotherapeuten oder Naturheilpraktiker",
  "Addictive substances or drugs":"Suchtmittel oder Drogen",
  "Smoking products":"Rauchwaren",
  "If genetic screening has been carried out for diagnostic purposes, i.e. to clarify clinical symptoms that have already been identified, as well as non-genetic screening, these must be declared.":"Wurden genetische Untersuchungen zu diagnostischen Zwecken durchgeführt, d. h. zur Abklärung bereits feststellbarer Krankheitssymptome, sowie nichtgenetische Untersuchungen, müssen diese deklariert werden.",
  "7.1 - Are you suffering from physical or mental disorders, sequels of accidents or infirmities?":"7.1 - Bestehen bei Ihnen körperliche oder psychische Beeinträchtigungen, Unfallfolgen oder Gebrechen?",
  "Please provide further information.":"Bitte machen Sie nähere Angaben. ",
  "Please provide the last name, first name and address of your doctor or therapist:":"Bitte geben Sie Namen, Vornamen und Adresse Ihres Arztes oder Therapeuten an:",
  "Surname":"Name",
  "First name":"Vorname",
  "Add doctor or therapist?":"Arzt oder Therapeuten hinzufügen?",
  "Please provide the name and location of the hospital or clinic where you were treated:":"Bitte geben Sie Namen und Ort des Spitals oder der Klinik an, wo Sie behandelt wurden: ",
  "Name":"Name",
  "Last name":"Name",
  "Add hospital or clinic?":"Spital oder Klinik hinzufügen?",
  "7.2 - Is your capacity to work limited today?":"7.2 - Ist Ihre Arbeitsfähigkeit heute eingeschränkt?",
  "7.3 - Which doctor is best placed to discuss your health?":"7.3 - Welcher Arzt oder welche Ärztin weiss am besten über Ihre Gesundheit Bescheid?",
  "No doctor is best placed to discuss my health.":"Es gibt keinen Arzt der am besten über meine Gesundheit Bescheid weiss. ",
  "7.4 - Please specify your body measurements.":"7.4 - Bitte geben Sie Ihre Körpermasse an.",
  "Height in cm":"Grösse in cm",
  "Weight in kg":"Gewicht in kg",
  "7.5 - Do you regularly take medication or have you been prescribed medication?":"7.5 - Nehmen Sie regelmässig Medikamente, oder sind Ihnen Medikamente verordnet worden?",
  "Name of medication":"Name des Medikaments",
  "How often do you take this medication and what dose do you take?":"Wie oft nehmen Sie dieses Medikament und welche Dosis nehmen Sie ein?",
  "How long have you been taking this medication?":"Seit wann nehmen Sie dieses Medikament?",
  "Why are you taking this medication?":"Warum nehmen Sie dieses Medikament?",
  "Has this medication been prescribed by a doctor?":"Wurde Ihnen dieses Medikament ärztlich verschrieben?",
  "Add?":"Hinzufügen?",
  "7.6 - Have you ever taken a HIV test?":"7.6 - Haben Sie jemals einen HIV-Test gemacht?",
  "Test result:":"Testresultat:",
  "Please specify where the test was taken:":"Bitte geben Sie an wo der Test gemacht worden ist:",
  "7.7 - In the last 5 years have you ever been fully or partially unable to work in accordance with a doctor's certificate for a period of more than 4 weeks without interruption?":"7.7 - Waren Sie in den letzten 5 Jahren gemäss Arztzeugnis länger als 4 Wochen ohne Unterbruch ganz oder teilweise arbeitsunfähig?",
  "From when?":"Von wann?",
  "To when?":"Bis wann? ",
  "What was the reason for this?":"Was war der Grund dafür?",
  "How many treatments, consultations and check-ups did you have during this time?":"Wie viele Behandlungs-, Beratungs- und Kontrolltermine hatten Sie in dieser Zeit?",
  "What were the consequences and the outcome?":"Was waren die Folgen und das Resultat?",
  "Additional information":"Weitere Informationen",
  "Have you been unable to work at any other time?":"Gab es noch weitere Arbeitsunfähigkeiten?  ",
  "I would like to include:":"Ich möchte Folgendes einbeziehen:",
  [HaveYouEverBeen_Key] : HaveYouEverBeen_Value,
  "7.9 - Have you ever undergone a surgery?":"7.9 - Wurden Sie jemals operiert?",
  "When did you have surgery?":"Wann wurden Sie operiert?",
  "Why did you have surgery?":"Warum wurden Sie operiert?",
  "7.10 - Do you need to have surgery?":"7.10 - Müssen Sie sich operieren lassen?",
  "When is your surgery?":"Wann ist die Operation?",
  "Why are you having surgery?":"Warum werden Sie operiert?",
  "7.11 - Have you sought medical treatment or advice in the last five years? Have you undergone medical check-ups or had specific screening in the last five years?":"7.11 - Haben Sie sich in den letzten 5 Jahren ärztlich behandeln oder beraten lassen? Standen Sie in den letzten 5 Jahren unter medizinischer Kontrolle oder haben Sie besondere Untersuchungen machen lassen?",
  "How many treatments did you have during this time?":"Wie viele Behandlungen hatten Sie in dieser Zeit?",
  "7.12 - Have you ever consulted or been treated by a psychiatrist, psychologist or psychotherapist?":"7.12 - Sind Sie jemals von einem Psychiater, Psychologen oder Psychotherapeuten beraten oder behandelt worden?",
  "What was the diagnosis?":"Was war die Diagnose?",
  "7.13 - Have you ever been advised or treated by a chiropractor, physiotherapist or naturopath?":"7.13 - Sind Sie jemals von einem Chiropraktiker, Physiotherapeuten oder Naturheilpraktiker beraten oder behandelt worden?",
  "7.14 - Have you taken any addictive substances or drugs in the last 10 years?":"7.14 - Haben Sie in den letzten 10 Jahren Suchtmittel oder Drogen genommen?",
  "Which one(s)?":"Welche?",
  "From when to when?":"Von wann bis wann?",
  "How many per week, month or year?":"Wie viel pro Woche, Monat oder Jahr?",
  "7.15 - Have you smoked cigarettes, e-cigarettes, cigars or a pipe in the last 12 months?":"7.15 - Haben Sie in den letzten 12 Monaten Zigaretten, E-Zigaretten, Zigarren oder Pfeife geraucht?",
  "Which of these have you smoked?":"Was haben Sie geraucht?",
  "How many?":"Wie viel?",
  "How often?":"Wie oft?",
  "Doctor details":"Angaben zum Arzt",
  "Hospital details":"Angaben zum Krankenhaus",
  "Both":"Beides",
  "HIV positive":"HIV positiv",
  "HIV negative":"HIV negativ",
  "per day":"pro Tag",
  "per week":"pro Woche",
  "per month":"pro Monat",
  "Afghanistan":"Afghanistan",
  "Albania":"Albanien",
  "Antarctica":"Antarktis",
  "Algeria":"Algerien",
  "American Samoa":"Samoa (amerikanischer Teil)",
  "Andorra":"Andorra",
  "Angola":"Angola",
  "Antigua and Barbuda":"Antigua und Barbuda",
  "Azerbaijan":"Aserbaidschan",
  "Argentina":"Argentinien",
  "Australia":"Australien",
  "Austria":"Österreich",
  "Bahamas":"Bahamas",
  "Bahrain":"Bahrain",
  "Bangladesh":"Bangladesh",
  "Armenia":"Armenien",
  "Barbados":"Barbados",
  "Belgium":"Belgien",
  "Bermuda":"Bermuda",
  "Bhutan":"Bhutan",
  "Bolivia":"Bolivien",
  "Bosnia and Herzegovina":"Bosnien-Herzegowina",
  "Botswana":"Botswana",
  "Bouvet Island":"Bouvetinsel",
  "Brazil":"Brasilien",
  "Belize":"Belize",
  "British Indian Ocean Territory":"Brit. Territorium im Indischen Ozean",
  "Solomon Islands":"Salomon-Inseln",
  "British Virgin Islands":"Brit. Jungferninseln",
  "Brunei Darussalam":"Brunei Darussalam",
  "Bulgaria":"Bulgarien",
  "Myanmar":"Myanmar (Union)",
  "Burundi":"Burundi",
  "Belarus":"Belarus",
  "Cambodia":"Kambodscha",
  "Cameroon":"Kamerun",
  "Canada":"Kanada",
  "Cape Verde":"Kapverdische Inseln",
  "Cayman Islands":"Cayman",
  "Central African Republic":"Zentralafrikanische Republik",
  "Sri Lanka":"Sri Lanka",
  "Chad":"Tschad",
  "Chile":"Chile",
  "China":"China, Volksrepublik",
  "Taiwan, Republic of China":"China, Taiwan",
  "Christmas Island":"Weihnachtsinsel",
  "Cocos (Keeling) Islands":"Kokosinseln",
  "Colombia":"Kolumbien",
  "Comoros":"Komoren",
  "Mayotte":"Mayotte",
  "Congo (Brazzaville)":"Kongo",
  "Congo, (Kinshasa)":"Kongo, Demokr. Republik (ehem. Zaire)",
  "Cook Islands":"Cookinseln",
  "Costa Rica":"Costa Rica",
  "Croatia":"Kroatien",
  "Cuba":"Kuba",
  "Cyprus":"Zypern",
  "Czech Republic":"Tschechische Republik",
  "Benin":"Benin",
  "Denmark":"Dänemark",
  "Dominica":"Dominica",
  "Dominican Republic":"Dominikanische Republik",
  "Ecuador":"Ecuador",
  "El Salvador":"El Salvador",
  "Equatorial Guinea":"Äquatorial-Guinea",
  "Ethiopia":"Äthiopien",
  "Eritrea":"Eritrea",
  "Estonia":"Estland",
  "Faroe Islands":"Färöer",
  "Falkland Islands (Malvinas)":"Falklandinseln",
  "South Georgia and the South Sandwich Islands":"Südgeorgien u. südl. Sandwichinseln",
  "Finland":"Finnland",
  "Aland Islands":"Åland Islands",
  "France":"Frankreich",
  "French Guiana":"Französisch-Guayana",
  "French Polynesia":"Französisch-Polynesien",
  "French Southern Territories":"Französische Südgebiete",
  "Djibouti":"Djibouti",
  "Gabon":"Gabun",
  "Georgia":"Georgien",
  "Gambia":"Gambia",
  "Palestinian Territory":"Palestinian Territory,Occupied",
  "Germany":"Deutschland",
  "Ghana":"Ghana",
  "Gibraltar":"Gibraltar",
  "Kiribati":"Kiribati",
  "Greece":"Griechenland",
  "Greenland":"Grönland",
  "Grenada":"Grenada",
  "Guadeloupe":"Guadeloupe",
  "Guam":"Guam",
  "Guatemala":"Guatemala",
  "Guinea":"Guinea",
  "Guyana":"Guyana",
  "Haiti":"Haiti",
  "Heard and Mcdonald Islands":"Herald und McDonaldinseln",
  "Holy See (Vatican City State)":"Vatikan",
  "Honduras":"Honduras",
  "Hong Kong, SAR China":"Hongkong",
  "Hungary":"Ungarn",
  "Iceland":"Island",
  "India":"Indien",
  "Indonesia":"Indonesien",
  "Iran, Islamic Republic of":"Iran",
  "Iraq":"Irak",
  "Ireland":"Irland",
  "Israel":"Israel",
  "Italy":"Italien",
  "Côte d'Ivoire":"Côte d'Ivoire",
  "Jamaica":"Jamaika",
  "Japan":"Japan",
  "Kazakhstan":"Kasachstan",
  "Jordan":"Jordanien",
  "Kenya":"Kenia",
  "Korea (North)":"Korea, Demo.Volksrepublik (Nordkorea)",
  "Korea (South)":"Korea, Republik (Südkorea)",
  "Kuwait":"Kuwait",
  "Kyrgyzstan":"Kirgisistan",
  "Lao PDR":"Laos",
  "Lebanon":"Libanon",
  "Lesotho":"Lesotho",
  "Latvia":"Lettland",
  "Liberia":"Liberia",
  "Libya":"Libyen",
  "Liechtenstein":"Liechtenstein",
  "Lithuania":"Litauen",
  "Luxembourg":"Luxemburg",
  "Macao, SAR China":"Macao",
  "Madagascar":"Madagaskar",
  "Malawi":"Malawi",
  "Malaysia":"Malaysia",
  "Maldives":"Malediven",
  "Mali":"Mali",
  "Malta":"Malta",
  "Martinique":"Martinique",
  "Mauritania":"Mauretanien",
  "Mauritius":"Mauritius",
  "Mexico":"Mexiko",
  "Monaco":"Monaco",
  "Mongolia":"Mongolei",
  "Moldova":"Moldova",
  "Montenegro":"Montenegro",
  "Montserrat":"Montserrat",
  "Morocco":"Marokko",
  "Mozambique":"Mosambik",
  "Oman":"Oman",
  "Namibia":"Namibia",
  "Nauru":"Nauru",
  "Nepal":"Nepal",
  "Netherlands":"Niederlande",
  "Aruba":"Aruba",
  "New Caledonia":"Neukaledonien",
  "Vanuatu":"Vanuatu",
  "New Zealand":"Neuseeland",
  "Nicaragua":"Nicaragua",
  "Niger":"Niger",
  "Nigeria":"Nigeria",
  "Niue":"Niue",
  "Norfolk Island":"Norfolk",
  "Norway":"Norwegen",
  "Northern Mariana Islands":"Marianen, Nördliche",
  "US Minor Outlying Islands":"Amerik. Überseeinseln, kleinere",
  "Micronesia, Federated States of":"Mikronesien, Föderierte Staaten von",
  "Marshall Islands":"Marshallinseln",
  "Palau":"Palau",
  "Pakistan":"Pakistan",
  "Panama":"Panama",
  "Papua New Guinea":"Papua-Neuguinea",
  "Paraguay":"Paraguay",
  "Peru":"Peru",
  "Philippines":"Philippinen",
  "Pitcairn":"Pitcairn",
  "Poland":"Polen",
  "Portugal":"Portugal",
  "Guinea-Bissau":"Guinea-Bissau",
  "Timor-Leste":"Timor-Leste",
  "Puerto Rico":"Puerto Rico",
  "Qatar":"Katar",
  "Réunion":"Réunion",
  "Romania":"Rumänien",
  "Russian Federation":"Russische Föderation",
  "Rwanda":"Rwanda",
  "Saint Helena":"Ascension",
  "Saint Kitts and Nevis":"St. Christoph und Nevis",
  "Anguilla":"Anguilla",
  "Saint Lucia":"St. Lucia",
  "Saint Pierre and Miquelon":"St. Pierre und Miquelon",
  "Saint Vincent and Grenadines":"St. Vincent und die Grenadinen",
  "San Marino":"San Marino",
  "Sao Tome and Principe":"St.Thomas und Principe",
  "Saudi Arabia":"Saudi-Arabien",
  "Senegal":"Senegal",
  "Serbia":"Serbien",
  "Seychelles":"Seychellen",
  "Sierra Leone":"Sierra Leone",
  "Singapore":"Singapur",
  "Slovakia":"Slowakische Republik",
  "Viet Nam":"Vietnam",
  "Slovenia":"Slowenien",
  "Somalia":"Somalia",
  "South Africa":"Südafrika",
  "Zimbabwe":"Zimbabwe",
  "Spain":"Spanien",
  "Western Sahara":"Westsahara",
  "Sudan":"Sudan",
  "Suriname":"Suriname",
  "Svalbard and Jan Mayen Islands":"Svalbard und Jan Mayen",
  "Swaziland":"Eswatini",
  "Sweden":"Schweden",
  "Switzerland":"Schweiz",
  "Syrian Arab Republic (Syria)":"Syrien",
  "Tajikistan":"Tadschikistan",
  "Thailand":"Thailand",
  "Togo":"Togo",
  "Tokelau":"Tokelau",
  "Tonga":"Tonga",
  "Trinidad and Tobago":"Trinidad und Tobago",
  "United Arab Emirates":"Vereinigte Arabische Emirate",
  "Tunisia":"Tunesien",
  "Turkey":"Türkei",
  "Turkmenistan":"Turkmenistan",
  "Turks and Caicos Islands":"Turks und Caicos",
  "Tuvalu":"Tuvalu",
  "Uganda":"Uganda",
  "Ukraine":"Ukraine",
  "Macedonia, Republic of":"Nordmazedonien",
  "Egypt":"Ägypten",
  "United Kingdom":"Grossbritannien",
  "Guernsey":"Guernsey",
  "Jersey":"Jersey",
  "Isle of Man":"Isle Of Man",
  "Tanzania, United Republic of":"Tansania",
  "United States of America":"Vereinigte Staaten von Amerika",
  "Virgin Islands, US":"Amerik. Jungferninseln",
  "Burkina Faso":"Burkina Faso",
  "Uruguay":"Uruguay",
  "Uzbekistan":"Usbekistan",
  "Venezuela (Bolivarian Republic)":"Venezuela",
  "Wallis and Futuna Islands":"Wallis und Futuna",
  "Samoa":"Samoa (West)",
  "Yemen":"Jemen",
  "Zambia":"Sambia",
  "Netherlands Antilles":"Niederländische Antillen",
  "Saint-Barthélemy":"Saint Barthélemy",
  "Fiji":"Fidschi",
  "Saint-Martin (French part)":"Saint Martin",
  "Kosovo":"Kosovo",
  "Questions about the state of health of the person to be insured":"Fragen zum Gesundheitszustand der zu versichernden Person",
  "Addictive substances or drugs (2)":"Suchtmittel oder Drogen (2)",
  "Insurance application for life, accident, health or income protection (2)":"Abgelehnte oder mit Erschwerung angenommene Anträge(2)",
  "Limited capacity to work (2)":"Eingeschränkte Arbeitsfähigkeit (2)",
  "Physical or mental disorders (2)":"Körperliche oder psychische Beeinträchtigungen (2)",
  "Medication (2)":"Medikamente (2)",
  "Private insurance for death or disability/invalidity benefits (2)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (2)",
  "Insurance benefits (2)":"Hohe Versicherungsleistungen (2)",
  "Unability to work (2)":"Arbeitsunfähigkeit (2)",
  "Disease (2)":"Krankheit (2)",
  "Surgery undergone (2)":"Durchgeführte Operation (2)",
  "Surgery need (2)":"Anstehende Operation (2)",
  "Medical treatment and check-ups (2)":"Medizinische Behandlung und Vorsorgeuntersuchungen (2)",
  "Psychiatrist, psychologist or psychotherapist treatment (2)":"Behandlung durch einen Psychiater, Psychologen oder Psychotherapeuten (2)",
  "Chiropractor, physiotherapist or naturopath treatment (2)":"Behandlung durch Chiropraktiker, Physiotherapeuten oder Naturheilpraktiker (2)",
  "Declaration of the policyholder regarding tax residencies (2)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (2)",
  "Addictive substances or drugs (3)":"Suchtmittel oder Drogen (3)",
  "Insurance application for life, accident, health or income protection (3)":"Abgelehnte oder mit Erschwerung angenommene Anträge(3)",
  "Limited capacity to work (3)":"Eingeschränkte Arbeitsfähigkeit (3)",
  "Physical or mental disorders (3)":"Körperliche oder psychische Beeinträchtigungen (3)",
  "Medication (3)":"Medikamente (3)",
  "Private insurance for death or disability/invalidity benefits (3)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (3)",
  "Insurance benefits (3)":"Hohe Versicherungsleistungen (3)",
  "Unability to work (3)":"Arbeitsunfähigkeit (3)",
  "Disease (3)":"Krankheit (3)",
  "Surgery undergone (3)":"Durchgeführte Operation (3)",
  "Surgery need (3)":"Anstehende Operation (3)",
  "Medical treatment and check-ups (3)":"Medizinische Behandlung und Vorsorgeuntersuchungen (3)",
  "Psychiatrist, psychologist or psychotherapist treatment (3)":"Behandlung durch einen Psychiater, Psychologen oder Psychotherapeuten (3)",
  "Chiropractor, physiotherapist or naturopath treatment (3)":"Behandlung durch Chiropraktiker, Physiotherapeuten oder Naturheilpraktiker (3)",
  "Declaration of the policyholder regarding tax residencies (3)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (3)",
  "Addictive substances or drugs (4)":"Suchtmittel oder Drogen (4)",
  "Limited capacity to work (4)":"Eingeschränkte Arbeitsfähigkeit (4)",
  "Physical or mental disorders (4)":"Körperliche oder psychische Beeinträchtigungen (4)",
  "Medication (4)":"Medikamente (4)",
  "Private insurance for death or disability/invalidity benefits (4)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (4)",
  "Unability to work (4)":"Arbeitsunfähigkeit (4)",
  "Disease (4)":"Krankheit (4)",
  "Surgery undergone (4)":"Durchgeführte Operation (4)",
  "Surgery need (4)":"Anstehende Operation (4)",
  "Medical treatment and check-ups (4)":"Medizinische Behandlung und Vorsorgeuntersuchungen (4)",
  "Psychiatrist, psychologist or psychotherapist treatment (4)":"Behandlung durch einen Psychiater, Psychologen oder Psychotherapeuten (4)",
  "Chiropractor, physiotherapist or naturopath treatment (4)":"Behandlung durch Chiropraktiker, Physiotherapeuten oder Naturheilpraktiker (4)",
  "Declaration of the policyholder regarding tax residencies (4)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (4)",
  "Addictive substances or drugs (5)":"Suchtmittel oder Drogen (5)",
  "Limited capacity to work (5)":"Eingeschränkte Arbeitsfähigkeit (5)",
  "Physical or mental disorders (5)":"Körperliche oder psychische Beeinträchtigungen (5)",
  "Medication (5)":"Medikamente (5)",
  "Private insurance for death or disability/invalidity benefits (5)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (5)",
  "Unability to work (5)":"Arbeitsunfähigkeit (5)",
  "Disease (5)":"Krankheit (5)",
  "Surgery undergone (5)":"Durchgeführte Operation (5)",
  "Surgery need (5)":"Anstehende Operation (5)",
  "Medical treatment and check-ups (5)":"Medizinische Behandlung und Vorsorgeuntersuchungen (5)",
  "Psychiatrist, psychologist or psychotherapist treatment (5)":"Behandlung durch einen Psychiater, Psychologen oder Psychotherapeuten (5)",
  "Chiropractor, physiotherapist or naturopath treatment (5)":"Behandlung durch Chiropraktiker, Physiotherapeuten oder Naturheilpraktiker (5)",
  "Declaration of the policyholder regarding tax residencies (5)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (5)",
  "Private insurance for death or disability/invalidity benefits (6)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (6)",
  "Declaration of the policyholder regarding tax residencies (6)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (6)",  
  "Medication (7)":"Medikamente (7)",
  "Private insurance for death or disability/invalidity benefits (7)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (7)",
  "Declaration of the policyholder regarding tax residencies (7)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (7)",
  "Medication (8)":"Medikamente (8)",
  "Private insurance for death or disability/invalidity benefits (8)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (8)",
  "Declaration of the policyholder regarding tax residencies (8)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (8)",
  "Medication (9)":"Medikamente (9)",
  "Private insurance for death or disability/invalidity benefits (9)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (9)",
  "Declaration of the policyholder regarding tax residencies (9)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (9)",
  "Medication (10)":"Medikamente (10)",
  "Private insurance for death or disability/invalidity benefits (10)":"Private Versicherungen für Leistungen bei Tod oder Erwerbsunfähigkeit/Invalidität (10)",
  "Declaration of the policyholder regarding tax residencies (10)":"Erklärung des Versicherungsnehmers oder der Versicherungsnehmerin zu den steuerlichen Ansässigkeiten (10)"
}
