import { LpcTranslationsKeys } from '../lpc-translations-keys';


export const LPC_DE_CH: LpcTranslationsKeys = {
  lpc_Investment_profiles: "Anlageprofile",
  lpc_payment_method: "Zahlungsweise",
  lpc_payment_type: "Zahlungsart",
  lpc_investment_funds: "Anlagefonds",
  lpc_fund_type: "Fondsart",
  lpc_fields_generic_error: "Die Pflichtfelder sind unvollständig oder enthalten falsche Werte",
  lpc_profile_type: "Profilart",
  lpc_elements: "Elemente",
  lpc_fund_name: "Name des Fonds",
  lpc_profile_name: "Profilname",
  lpc_profiles: "Profile",
  lpc_funds: "Fonds",
  lpc_division_type: "Aufteilungsart",
  lpc_start_validity_date: "Datum des Beginns der Gültigkeit",
  lpc_fund_description: "Beschreibung des Fonds",
  lpc_ISIN_code: "ISIN-Code",
  lpc_Allocation: "Zuweisung",
  lpc_profile_details: "Profildetails",
  lpc_portfolio_fund_display: "Anzeige der Portfoliofonds",
  lpc_payment_data: "Zahlungsdaten",
  lpc_Warning_you_are_deleting_the_contractor: "Warnung, Sie löschen den Versicherungsnehmer. Möchten Sie fortfahren?",
  lpc_Investment_amount: "Anlagebetrag:",
  lpc_mov_status: "Status Titel",
  lpc_mov_Amount: "Titel Betrag",
  lpc_ticket_status_label: "Anhängige Bewertung der Aktien",
  lpc_status_investment_label: "Anlagestatus",
  lpc_order_status_label: "Bestellstatus",
  lpc_selected_funds: "Ausgewählte Fonds",
  lpc_Premium: "Prämie",
  lpc_InvestmentByFund: "Anlage nach Fonds",
  lpc_Loading: "Gebühren",
  lpc_NetPremium: "Nettoprämie",
  lpc_IntFract: "Int. Frequenz",
  lpc_Fees: "Feste Gebühren",
  lpc_Taxes: "Steuern",
  lpc_GrossPremium: "Bruttoprämie",
  lpc_FirstInstallment: "Erste Ratenzahlung",
  lpc_AnnualPremium: "Jahresprämie",
  lpc_NextInstallment: "Nächste Ratenzahlung",
  lpc_Guarantee: "Garantie",
  lpc_BenefitOnDate: "Leistung zum Datum",
  lpc_Benefit: "Leistung",
  lpc_AnnualSinglePremium: "Jährliche/einmalige Prämie",
  lpc_ActiveGuarantees: "Aktive Garantien",
  lpc_Enhancement: "Finanzdaten",
  lpc_CalculationData: "Datum der Berechnung",
  lpc_ControvaloreAllaData: "Marktwert zum Datum",
  lpc_AccumulationOfPrizes: "Summe der Prämien",
  lpc_TotalSurrender: "Rückkauf insgesamt",
  lpc_AccumulationOfReducedPremiums: "Summe der reduzierten Prämien",
  lpc_TotalBalance: "Gesamtsaldo",
  lpc_EffectiveDate: "Gültigkeitsdatum",
  lpc_Movement: "Bewegung",
  lpc_Movements: "Bewegungen",
  lpc_booking_number: "Buchungsnummer",
  lpc_booking_policyData: "Daten zur Police",
  lpc_booking_assetData: "Vermögensdaten",
  lpc_PolicyLinked: "Verknüpfte Policen",
  lpc_Motivo_Legame: "Anleihe Motivation",
  lpc_ProposalIssueDate: "Ausstellungsdatum des Antrags",
  lpc_PolicyIssueDate: "Ausstellungsdatum der Police",
  lpc_ExpirationDate: "Verfallsdatum",
  lpc_NextInstalmentDate: "Datum der nächsten Ratenzahlung",
  lpc_found_duplicate: "Warnung",
  lpc_duplicate_subject_message: "Ausgewählte Partei, die bereits zu den Begünstigten gehört",
  lpc_SelectSettlement: "Abrechnung auswählen",
  lpc_You_must_select_the_settlement_to_perform_the_variation: "Sie müssen die Abrechnung auswählen, um die Abweichung durchzuführen",
  lpc_beneficiaryPercentage: "Prozentsatz für Partei",
  lpc_beneficiaryPercentage_greater: "muss grösser sein als",
  lpc_beneficiaryPercentage_lower: "muss kleiner sein als",
  lpc_beneficiaryDuplicate: "Duplikat Partei",
  lpc_NoOperations: "Keine Massnahme verfügbar",
  lpc_confirm: "Bestätigen",
  lpc_description: "Beschreibung",
  lpc_issueDate: "Datum der Ausstellung",
  lpc_effectiveDate: "Gültigkeitsdatum",
  lpc_MovementData: "Nachtrag stornieren",
  lpc_Are_you_sure_you_want_to_confirm_this_claim: "Sind Sie sicher, dass Sie diesen Anspruch bestätigen möchten?",
  lpc_Are_you_sure_you_want_to_cancel_this_claim: "Sind Sie sicher, dass Sie diesen Anspruch stornieren möchten?",
  lpc_warnings: "Warnungen",
  lpc_warning: "Warnung",
  lpc_warning_message: "Klicken Sie auf 'GENEHMIGUNGSANFRAGE', um eine Genehmigungsanfrage für den Vorgang zu übermitteln",
  lpc_ClaimCanceled: "Anspruch erfolgreich storniert",
  lpc_ClaimConfirmed: "Anspruch erfolgreich bestätigt",
  lpc_reserve: "speichern",
  lpc_questionnaire: "Fragebogen",
  lpc_bonus_amount: "Bonusbetrag",
  lpc_life_detail_beneficiary: "Begünstigter",
  lpc_life_detail_net_amount: "Nettobetrag",
  lpc_life_detail_questionnaire: "Fragebogen",
  lpc_life_detail_beneficiaries: "Begünstigte",
  lpc_AppendixNumber: "Nachtragsnummer",
  lpc_nessuna_variazione: "Keine Abweichung",
  lpc_appendixLabel: "Begünstigtennachtrag",
  lpc_operation_termination_reason: "",
  lpc_Lien_type_creditor: "Art des Gläubigers",
  lpc_creditor: "Gläubiger",
  lpc_Open_lien: "Offenes Pfandrecht",
  lpc_assignment_holder_type: "Art des Zuweisungsinhabers",
  lpc_assignment_holder: "Inhaber der Zuordnung",
  lpc_assignment_amount: "Betrag",
  lpc_assignment_policy_number: "Vertragsnummer",
  lpc_assignment_company: "Unternehmen",
  lpc_assignment_type: "Art der Zuweisung",
  lpc_assignment_benefit: "Leistung",
  lpc_amount_less_of_benefit: "Betrag muss geringer sein als die Leistung.",
  lpc_open_assignment: "Offene Zuweisung",
  lpc_sum_det_cancel_proposal: "Antrag stornieren",
  lpc_sum_det_success_cancel: "Antrag wurde erfolgreich storniert",
  lpc_sum_det_success_cancel_prev: "Offerte wurde erfolgreich storniert",
  lpc_sum_det_confirm_cancel: "Bestätigen Sie, dass der Antrag storniert wird?",
  lpc_sum_det_confirm_cancel_prev: "Möchten Sie die Stornierung dieser Offerte bestätigen?",
  lpc_sum_det_confirm_prop_issue: "Antrag wird ausgestellt. Bestätigen Sie dies?",
  lpc_sum_det_confirm_pol_issue: "Police wird ausgestellt. Bestätigen Sie dies?",
  lpc_health_questionnaire: "Gesundheitsfragebogen",
  lpc_quotation_show_details: "Details anzeigen",
  lpc_quotation_premium_details: "Prämiendetails",
  lpc_quotation_warranties_details: "Gewährleistungsdetails",
  lpc_quotation_commissions_details: "Provision",
  lpc_Reference_Third_Party: "Bezug Drittpartei",
  lpc_DelegateEqualToPolicyHolder: "Delegierter gleich wie der Versicherungsnehmer?",
  lpc_LegalGuardianEqualToPolicyHolder: "Gesetzlicher Vormund gleich wie der Versicherungsnehmer?",
  lpc_ThirdPayerEqualToPolicyHolder: "Drittzahler gleich wie der Versicherungsnehmer?",
  lpc_DelegateEqualToPolicyHolderWarning: "Partei für die Rolle Delegierter gleich wie der Versicherungsnehmer",
  lpc_LegalGuardianEqualToPolicyHolderWarning: "Partei für die Rolle Gesetzlicher Vormund gleich wie der Versicherungsnehmer",
  lpc_ThirdPayerEqualToPolicyHolderWarning: "Partei für die Rolle Drittzahler gleich wie der Versicherungsnehmer",
  lpc_but_confirm_accept: "bestätigen und akzeptieren",
  lpc_Date_Start_Variation: "Abweichung des Startdatums",
  lpc_New_Frequent_Payment: "Neue Frequenzzahlung",
  lpc_start_date_profit: "Startdatum des Gewinns",
  lpc_end_date_profit: "Enddatum des Gewinns",
  lpc_last_rate_profit: "Letzte Rate Gewinn",
  lpc_last_profit: "letzter Gewinn",
  lpc_Fund_Code: "Fonds-Code",
  lpc_OperationSynstesis: "Vorgang Synthese",
  lpc_PreviusPerceent: "Vorheriger Prozentsatz",
  lpc_PreviusValue: "Vorheriger Wert",
  lpc_NumberOfUnit: "Vorherige Anzahl Anteile",
  lpc_PostPErcent: "Prozentsatz buchen",
  lpc_PostValue: "Wert buchen",
  lpc_PostNumberOfUnit: "Offertennummer buchen",
  lpc_Dati: "Daten",
  lpc_PIP_Data: "Pip-Daten",
  lpc_PIP_General: "Allgemeine Daten",
  lpc_PIP_Contribution: "Pip-Beitrag",
  lpc_HolderData: "Daten der Versicherten",
  lpc_RiskData: "Risikodaten",
  lpc_IBAN_error: "IBAN ist nicht korrekt",
  lpc_BACK: "Zurück",
  lpc_ACTIONS: "Aktionen",
  lpc_DETAIL: "Detail",
  lpc_CANCEL: "Antrag stornieren",
  lpc_CONFIRM_PROPOSAL: "Antrag bestätigen",
  lpc_CONFIRM_POLICY: "Police bestätigen",
  lpc_quote_number: "Offertennummer",
  lpc_valid_quote: "Gültige Offerte",
  lpc_BUY_PREV: "Kaufen",
  lpc_EDIT_PREV: "Bearbeiten",
  lpc_EDIT: "Bearbeiten",
  lpc_CANCEL_PREV: "Löschen",
  lpc_buy_quote: "Offerte kaufen",
  lpc_Close_assignment: "Zuweisung schliessen",
  lpc_Close_lien: "Pfandrecht schliessen",
  lpc_disinvestment_funds: "Veräusserungsfonds",
  lpc_error_mandatory_fields: "Rot markierte Felder sind erforderlich oder enthalten falsche Werte",
  lpc_disinvestment_msg: "{{amount}} zur Veräusserung, oder {{percent}}",
  lpc_disinvestment_allocation_msg: "",
  lpc_maximum_percentage: "",
  lpc_maximum_amount: "",
  lpc_minimum_percentage: "",
  lpc_minimum_amount: "",
  lpc_investment_msg: "{{amount}} zur Anlage, oder {{percent}}",
  lpc_Fields_marked_in_red_are_required: "Rot markierte Felder sind erforderlich",
  lpc_amount_format_validation: "Der Betrag ist ungültig, er enthält das falsche Dezimaltrennzeichen, bitte korrigieren Sie ihn mit '.'",
  lpc_select_at_least_one_beneficiary: "Wählen Sie mindestens einen Begünstigten aus",
  lpc_quote_due_date: "Fälligkeitsdatum der Offerte",
  lpc_roles_variation: "Rollen",
  lpc_fiscal_data: "Steuerliche Daten",
  lpc_data_calc: "Berechnungsdatum",
  lpc_tipo_liquidazione: "Abrechnungsart",
  lpc_valore_polizza: "Wert der Police",
  lpc_imponibile_imp: "Steuerpflichtige Steuer",
  lpc_imposta_base: "Steuerbemessungsgrundlage",
  lpc_imposta_calcol: "Berechnete Steuer",
  lpc_imposta_da_add: "Zu belastende Steuer",
  lpc_imposta_add: "Belastete Steuer",
  lpc_imposta_bollo: "Steuermarke",
  lpc_fiscal_data_detail: "Steuerdaten Detail",
  lpc_quote_white_list: "Offerte weisse Liste",
  lpc_data_rif: "Bezugsdatum",
  lpc_tipo_perc: "WL-Prozentsatz",
  lpc_CancellInstallment: "Zusammenfassende Anfrage Ratenzahlung stornieren",
  lpc_DescOperazione: "Beschreibung des Vorgangs",
  lpc_RateAmount: "Gesamtbetrag der Ratenzahlung",
  lpc_OPEN_LATEST_VERSION: "Neueste Version öffnen",
  lpc_TOTAL_INVESTED: "Insgesamt angelegt",
  lpc_REMAINING_AMOUNT: "Verbleibender Betrag",
  lpc_descrizione_rischio: "Beschreibung des Risikos",
  lpc_prestazione_ridotta_iniz: "Kürzung der ursprünglichen Leistung",
  lpc_prestazione_rivalutata: "Kürzung der neu bewerteten Leistung",
  lpc_provvigioni_da_recuperare: "Zurückzufordernde Provision",
  lpc_reg41: "Name zum Senden von Mitteilungen an",
  lpc_disinvestment_amount: "Betrag der Veräusserung",
  lpc_frequecy: "Frequenz",
  lpc_the_occurenceNumber_must_be_a_value: "Die Anzahl der Vorkommnisse muss grösser sein als {{min}}",
  lpc_occurenceNumber: "Die Anzahl der Vorkommnisse muss grösser sein als {{min}}",
  lpc_disinvestment_range: "Geben Sie einen zu veräussernden Betrag ein, der grösser als {{max}} und kleiner oder gleich {{min}} ist",
  lpc_state: "Status",
  lpc_substate: "Unterstatus",
  lpc_product_type: "Produktart",
  lpc_managementnode: "Verwaltungsknoten",
  lpc_subagency: "Unteragentur",
  lpc_branch_name: "Branche",
  lpc_agreement: "Vereinbarung",
  lpc_payment_frequency: "Zahlungsfrequenz",
  lpc_mean_of_payment: "Zahlungsmittel",
  lpc_Subcause: "Unterursache",
  lpc_premium_payment_suspension_date: "Datum der Aussetzung der Prämienzahlung",
  lpc_imposta_sostitutiva: "Ersatzsteuer",
  lpc_novazione_contr: "Vertragliche Novation",
  lpc_acc_premium_initial_2011: "Prämie kumulieren ab 31.12.2011",
  lpc_amount_intital_2011: "Betrag ab 31.12.2011",
  lpc_acc_premium_2011: "Prämie kumulieren 31.12.2011",
  lpc_amount_2011: "Betrag 31.12.2011",
  lpc_acc_premium_initial_2014: "Prämie kumulieren ab 30.06.2014",
  lpc_acc_premium_2014: "Prämie kumulieren 30.06.2014",
  lpc_amount_intital_2014: "Betrag ab 30.06.2014",
  lpc_amount_2014: "Betrag 30.06.2014",
  lpc_acc_premium_initial_2022: "Erste Prämiensumme am 31.12.2022",
  lpc_acc_premium_2022: "Prämiensumme am 31.12.2022",
  lpc_amount_intital_2022: "Anfängliche Hauptschuld und Zinsen am 31.12.2022",
  lpc_amount_2022: "Hauptschuld und Zinsen am 31.12.2022",
  lpc_acc_premium_intial_tax_change: "Prämien kumulieren Steueränderung ab",
  lpc_amount_initial_tax_change: "Steuerbetragsänderung ab",
  lpc_acc_premium_tax_change: "Prämie kumulieren Steueränderung",
  lpc_amount_tax_change: "Steuerbetragsänderung",
  lpc_regime_change_date: "Datum der Steueränderung",
  lpc_please_enter_a_disinvestment_amount: "Bitte geben Sie einen Veräusserungsbetrag für den Fonds ein: {{fund}}",
  lpc_please_select_at_least_a_disinvestment: "Bitte wählen Sie mindestens eine Veräusserung aus",
  lpc_reg41Msg: "Empfänger von Mitteilungen",
  lpc_cost: "Kosten",
  lpc_paymentFrequencyInterest: "Freq. Inter.",
  lpc_netpremium: "Nettoprämie",
  lpc_grosspremium: "Bruttoprämie",
  lpc_information: "Information:",
  lpc_reinvest_data_detail: "Wiederanlagedetails",
  lpc_polizza: "Police",
  lpc_data_effetto_mov: "Gültigkeitsdatum",
  lpc_netto_liquidazione: "Nettobetrag der Abrechnung",
  lpc_netto_reinvestire: "Nettobetrag der Wiederanlage",
  lpc_stato_reinvest: "Abrechnung Wiederanlagestatus",
  lpc_reinvested_settlements: "Wiederangelegte Abrechnungen",
  lpc_loans: "Darlehen",
  lpc_elaboration_date: "Verarbeitungsdatum",
  lpc_calculation_date: "Berechnungsdatum",
  lpc_annuity_data: "Daten zur Lebensrente",
  lpc_extra_premium: "Prämie hinzufügen",
  lpc_loan_interest: "Darlehenszinsen",
  lpc_fractionation_interest: "Geteilte Zahlung von Zinsen",
  lpc_default_interest: "Verzugszinsen",
  lpc_reactivation_interest: "Reaktivierungszinsen",
  lpc_installment_detail: "Ratenzahlungsdetails",
  lpc_effectiveAnnuityDate: "Datum der effektiven Lebensrente",
  lpc_firstInstallmentDate: "Erstes Ratenzahlungsdatum",
  lpc_expiryAnnuityDate: "Verfallsdatum der Lebensrente",
  lpc_amountFirstInstallment: "Betrag der ersten Ratenzahlung",
  lpc_initialAnnuityAmount: "Betrag der ursprünglichen Lebensrente",
  lpc_periodicityInstallmentAnnuity: "Periodizität Ratenzahlung Lebensrente",
  lpc_typePaymentAnnuity: "Lebensrente Zahlungsart",
  lpc_performanceType: "Erfüllungsart",
  lpc_annuityType: "Art der Lebensrente",
  lpc_annuity_detail: "Lebensrentedetail",
  lpc_canceled_liquidation_detail: "Details zur stornieten Liquidation",
  lpc_Percentage: "Prozentsatz",
  lpc_loan_data: "Darlehensdaten",
  lpc_maxLoanAmount: "Maximal zahlbarer Betrag",
  lpc_loanRepaymentProcedure: "Verfahren zur Darlehensrückzahlung",
  lpc_loanAmount: "Darlehensbetrag",
  lpc_rateType: "Art des Zinssatzes",
  lpc_maxLoanAmountKO: "0 € (Fehler bei der Berechnung des zu zahlenden Höchstbetrags)",
  lpc_grant_date: "Datum der Gewährung",
  lpc_loan_amount: "Darlehensbetrag",
  lpc_return_way: "Rückweg",
  lpc_interest_rate_type: "Art des Zinssatzes",
  lpc_residual_amount: "Restbetrag",
  lpc_refund_type: "Rückzahlungsart",
  lpc_refund_amount: "Rückzahlungsbetrag",
  lpc_select_lpc_refund_type: "Wählen Sie eine Rückzahlungsart aus",
  lpc_leftInsuredAmount: "Restliches Versicherungskapital",
  lpc_insuredAmount: "Angelegter Betrag",
  lpc_reactivatedCapital: "Reaktiviertes Kapital",
  lpc_premium_reactivation: "Prämien_Reaktivierung",
  lpc_negative_amount: "Negativer Restbetrag",
  lpc_zero_amount: "Restbetrag nicht vorhanden, bitte geben Sie einen Betrag grösser als 0 ein",
  lpc_error_mandatory_selected_type_of_refund: "Bitte wählen Sie eine Rückzahlungsart",
  lpc_select_refund: "Bitte wählen Sie mindestens ein Darlehen aus",
  lpc_selected_profiles: "Ausgewählte Profile:",
  lpc_coupon_beneficiary: "Coupon-Begünstigter",
  lpc_bookings: "Buchungen",
  lpc_booking_type: "Buchungsart",
  lpc_booking_state: "Status",
  lpc_booking_effective_date: "Gültigkeitsdatum",
  lpc_booking_extrapremiumHealth: "Zusatzprämie Gesundheit",
  lpc_booking_extrapremiumProf: "Zusatzprämie Beruf",
  lpc_booking_extrapremiumSport: "Zusatzprämie Sport",
  lpc_booking_extrapremiumOther: "Zusatzprämie Sonstiges",
  lpc_booking_effectiveDate: "Gültigkeitsdatum",
  lpc_booking_detail_label: "Buchungsdetails",
  lpc_Rischi: "Deckungen",
  lpc_booking_destinationNode: "Zielknoten",
  lpc_booking_cause: "Ursache",
  lpc_PolicyData: "Daten zur Police",
  lpc_booking_cancellationDate: "Datum der Stornierung",
  lpc_extra_premiums: "Zusatzprämien",
  lpc_error_reservation_cancel: "Fehler bei der Stornierung der {{descr}} Nummer {{num}}",
  lpc_success_reservation_cancel: "Die Stornierung der {{descr}} Nummer {{num}} wurde bestätigt",
  lpc_incomplete_disinvestment_for_the_funds: "Unvollständige Veräusserung für die Fonds",
  lpc_Sinistro_morte: "Todesfallanspruch",
  lpc_Sinistro_invalidita: "Anspruch auf Behinderung",
  lpc_Sinistro_inabilità: "Anspruch auf Erwerbsunfähigkeit",
  lpc_Malattia_grave: "Anspruch auf schwere Krankheit",
  lpc_insured_form: "Formular Versicherter",
  lpc_other_contract_list: "Sonstige Vertragsliste",
  lpc_modified_personal_data_for_the_subject: "Geänderte persönliche Daten für die Partei",
  lpc_subject_roles: "Parteirollen",
  lpc_no_variation_modpd: "Keine Änderungen vorgenommen, ändern Sie eine Partei",
  lpc_DeductiblePremium: "Prämie mit Selbstbeteiligung",
  lpc_firstterm: "Erste Laufzeit",
  lpc__1TERM_ATOOL: "Datum der Tool-Aktivierung",
  lpc__1TERM_MTOOL: "Tool-Änderung",
  lpc__1TERM_DTOOL: "Datum des Löschens des Tools",
  lpc_management_fees: "Verwaltungsgebühren",
  lpc_investment_not_found: "Es gibt keine Posten zur Auswahl",
  lpc_No_policies: "Es gibt keine Police für die veränderte Partei",
  lpc_variation_convention: "Vereinbarungsabweichung",
  lpc_andamento_polizza_alla_data: "Bisherige Entwicklung der Police",
  lpc_appendice_dichiarativa: "Deklarationsnachtrag",
  lpc_policy_number_dot: "Policen-Nr.",
  lpc_investment_sum_must_be100: "Die Summe der Anlagen muss 100 % betragen",
  lpc_Operation_detail: "Vorgangsdetails",
  lpc_Notwithstanding_any_ongoing_operations: "Die Werte können sich aufgrund des laufenden Betriebs ändern",
  lpc_percentage_max_zero: "einen Prozentsatz grösser als Null einfügen",
  lpc_Mostra_tutti: "Alle anzeigen",
  lpc_Visualizza_dettaglio: "Details ansehen",
  lpc_consultazione_storica: "Konsultation des Verlaufs",
  lpc_dettaglio_movimento: "Bewegungsdetails",
  lpc_mensile: "monatlich",
  lpc_annuale: "jährlich",
  lpc_sixmonthly: "halbjährlich",
  lpc_trimestrale: "vierteljährlich",
  lpc_Titolo_non_incassato: "Aktie nicht abgeholt​",
  lpc_Rid: "DDP​",
  lpc_In_attesa_valorizzazione_quote: "Anhängige Valorisierung der angelegten Aktien",
  lpc_switch_di_perequazione: "Geplanter Wechsel",
  lpc_SELEZIONA: "-- AUSWÄHLEN --",
  lpc_settimanale: "wöchentlich",
  lpc_Valido: "Gültig",
  lpc_quotidiano: "täglich",
  lpc_Assegno: "Prüfen",
  lpc_Bonifico: "Banküberweisung",
  lpc_Sdd: "Lastschriftauftrag",
  lpc_severeDisabilityMsg: "Begünstigter mit schwerer Behinderung",
  lpc_benDg: "Behinderter Begünstigter",
  lpc_distribution: "Zuweisung nach",
  lpc_Distribution_amount_exceeds: "Ausschüttungsbetrag übersteigt den insgesamt verfügbaren Abrechnungsbetrag",
  lpc_distribution_invalid_greater: "Der {{type}} ist ungültig, er muss grösser sein als {{min}}",
  lpc_distribution_invalid_greater_equal: "Der {{type}} ist ungültig, er sollte kleiner oder gleich dem {{max}} sein",
  lpc_perc: "Prozentsatz",
  lpc_amount: "Betrag",
  lpc_Please_insert_a_distribution: "Bitte fügen Sie eine Ausschüttung ein",
  lpc_For_the_Beneficiary: "Für den Begünstigten",
  lpc_global_distribution: "Der zu begleichende Gesamtbetrag muss kleiner oder gleich {{totalAmount}} sein",
  lpc_invalid_char: "Das Format des {{type}} enthält falsche Zeichen",
  lpc_toDistribute: "zur Ausschüttung",
  lpc_noClaims: "Es wurden keine Ansprüche gefunden",
  lpc_Data_sinistro: "Anspruchsdatum",
  lpc_Data_Pervenimento_Denuncia: "Datum des Eingangs der Anspruchsanmeldung",
  lpc_Data_Denuncia_Sinistro: "Datum der Anspruchsanmeldung",
  lpc_Tipo_sinistro: "Anspruchsart",
  lpc_inserisci_denuncia: "Anspruch einfügen",
  lpc_Conferma_inserimento_denuncia: "Bestätigung der Einfügung des Anspruchs",
  lpc_Denuncia_inserita_correttamente: "Anspruch erfolgreich eingefügt",
  lpc_Stato_denuncia: "Anspruchstatus",
  lpc_marital_status: "Familienstand",
  lpc_fiscal_code: "Steuerkennzeichen",
  lpc_birthdate: "Geburtsdatum",
  lpc_address: "Adresse",
  lpc_sex: "Geschlecht",
  lpc_annulla_denuncia: "Anspruch stornieren",
  lpc_modifica_denuncia: "Anspruch ändern",
  lpc_conferma_denuncia: "Anspruch bestätigen",
  lpc_new_beneficiary: "Neuer Begünstigter",
  lpc_Seleziona: "Auswählen",
  lpc_insert_phisical_sbj_for: "Fügen Sie eine natürliche Person für die Rolle {{role}} ein",
  lpc_insert_legal_sbj_for: "Fügen Sie eine juristische Person für die Rolle {{role}} ein",
  lpc_payment_incomplete: "Unvollständige Zahlungsangaben",
  lpc_add_mandatory_linkedroles: "Geben Sie die obligatorischen Rollen für die eingegebene Partei ein",
  lpc_beneficiary_owner_recipient_must_be_physical: "Der wirtschaftlich Berechtigter des Empfängers muss eine natürliche Person sein",
  lpc_enter_the_beneficial_owners_of_the_recipient: "Geben Sie die wirtschaftlich Berechtigten des Empfängers ein",
  lpc_global_percentage_must_be_a_maximum_100: "der globale Prozentsatz muss maximal 100 betragen",
  lpc_overall_percentage_must_equal100: "der Gesamtprozentsatz muss 100 betragen",
  lpc_Questionario_deve_essere_compilato: "Fragebogen muss ausgefüllt werden",
  lpc_enter_mandatory_data: "Geben Sie die obligatorischen Daten ein",
  lpc_enter_an_adult: "Geben Sie einen Erwachsenen ein",
  lpc_enter_a_beneficial_owner: "Geben Sie einen wirtschaftlich Berechtigen ein",
  lpc_enter_a_physical_beneficial_owner: "Geben Sie eine natürliche Person als wirtschaftlich Berechtigen ein",
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: "Der globale Prozentsatz der wirtschaftlich Berechtigen darf 100 nicht überschreiten",
  lpc_beneficial_owners_percentages_must_be_greater_than0: "Der Prozentsatz der wirtschaftlich Berechtigen' muss grösser als 0,0 sein",
  lpc_effective_holder_perc: "{{benefName}}'s Prozentsatz des wirtschaftlich Berechtigten {{linkedName}}' liegt unter dem zulässigen Mindestwert von {{linkedPerc}}%",
  lpc_effective_holder_duplicated: "Effektiver Versicherungsnehmer vervielfältigt",
  lpc_enter_the_minimum_number_of_subjects: "Geben Sie die Mindestanzahl der Parteien ein",
  lpc_percentage_must_be_greater_than_0: "Der Prozentsatz muss grösser als 0,0 sein",
  lpc_enter_free_text: "Freitext eingeben",
  lpc_beneficiary_executor: "Begünstigter Testamentsvollstrecker",
  lpc_effective_recipient: "Effektiver Empfänger",
  lpc_recipient_owner: "Empfänger Besitzer",
  lpc_beneficial_owner_of_beneficiary: "Wirtschaftlich Berechtigter",
  lpc_confirmed_claim_modify: "Bestätigten Anspruch ändern",
  lpc_claim_modified_correctly: "Anspruch korrekt geändert",
  lpc_Assicurato: "Versicherter",
  lpc_Assicurati: "Versicherte",
  lpc_holderType: "Antragsteller' Art der Beschäftigung",
  lpc_ageRetirement: "Alter bei Pensionierung",
  lpc_accessTransfer: "Antragsteller übertragen",
  lpc_emptyContribution: "Beitrag des Arbeitgebers",
  lpc_firstEmployment: "Datum des Beginns der Beschäftigung",
  lpc_oldUnderWritt: "Alter Antragsteller",
  lpc_underWitt1993: "Eingetragen bis 29.4.1993",
  lpc_maturato2001: "Akkumuliert bis 31.12.2000",
  lpc_maturato2006: "Akkumuliert bis 31.12.2006",
  lpc_riduzImponibiTFR: "Senkung der steuerpflichtigen TFR",
  lpc_aliquotaTFR: "TFR-Quote (vor 2001 Besteuerung)",
  lpc_PIP_Contribution_Type: "Beitragsart",
  lpc_YES: "Ja",
  lpc_PIP: "Persönliche Rentenversicherung",
  lpc_PIP_Contribution_Percentage: "Prozentsatz",
  lpc_PIP_Contribution_Amount: "Betrag",
  lpc_transfer_data: "Daten übertragen",
  lpc_unit_questionnaire: "Anteil-Fragebogen",
  lpc_amount_reimbured: "Zu erstattender Betrag",
  lpc_Descr_not_handled: "Beschreibung wird für diesen Vorgangscode nicht verwaltet",
  lpc_select_transfer: "Auswahl übertragen",
  lpc_fiscalFolderDataPost2006: "Steuersystem nach 2006",
  lpc_fiscalFolderData20012006: "Steuersystem 2001 - 2006",
  lpc_fiscalFolderDataBefore2001: "Steuersystem vor 2001",
  lpc_filledOut: "Ausgefüllt",
  lpc_blank: "Leer",
  lpc_desc_previousPenFund: "Frühere Rentenversicherung",
  lpc_companyPreviousPenFund: "Firmenname der früheren Rentenversicherung",
  lpc_typePreviousPenFund: "Art der früheren Rentenversicherung",
  lpc_note: "Anmerkungen",
  lpc_Benef_irrevocabile: "Unwiderruflicher Begünstigter",
  lpc_PIP_Transfer: "Übertragungen aus einem anderem Fonds",
  lpc_requestDate: "Datum der Anfrage",
  lpc_Type_Previous_Pension_Fund: "Art der früheren Rentenversicherung",
  lpc_Previous_Pension_Fund: "Frühere Rentenversicherung",
  lpc_Import_Transfert: "Überweisungsbetrag",
  lpc_Note: "Anmerkungen",
  lpc_relief: "Steuererleichterung 2022",
  lpc_selected_settlement: "Abrechnung ausgewählt",
  lpc_EDIT_ANONYMOUS_PREV: "Bearbeiten",
  lpc_invalid_quote: "Offerte storniert",
  lpc_reasonForRequest: "Grund der Anfrage",
  lpc_advancePaymentAmount: "Betrag der Vorauszahlung",
  lpc_repaidAdvance: "Vorauszahlung zurückgezahlt",
  lpc_PIP_AdvancedPayment: "Vorauszahlungen",
  lpc_requestReason: "Grund der Anfrage",
  lpc_notRepaidAmount: "Betrag noch nicht zurückgezahlt",
  lpc_fiscalDate: "Gültigkeitsdatum",
  lpc_previousPension: "Frühere Rentenversicherung",
  lpc_fiscalCompetence: "Steuerliche Zuständigkeit",
  lpc_settlementStatus: "Status der Vorauszahlung",
  lpc_repaidAmount: "Zurückgezahlter Betrag",
  lpc_advancePaymentTransfer: "Vorauszahlungen aus Überweisungen",
  lpc_policy_factors: "Faktoren der Police",
  lpc_asset_factors: "Faktoren der Vermögenswerte",
  lpc_Date_Variation: "Abweichung Zeitrahmen",
  lpc_penalties_amount: "Höhe der Sanktionen",
  lpc_tax_amount: "Steuerbetrag",
  lpc_warranties_detail: "Garantiedetails",
  lpc_fixedCharges: "Feste Gebühren",
  lpc_catastrophe: "Katastrophe Zusatz- prämie",
  lpc_health: "Gesundheit Zusatz- prämie",
  lpc_other: "Sonstige Zusatz- prämie",
  lpc_professional: "Beruf Zusatz- prämie",
  lpc_sports: "Sport Zusatz- prämie",
  lpc_collectionFee: "Erfassungsgebühr",
  lpc_lambdaFee: "Lambda-Gebühr",
  lpc_managementFee: "Verwaltungsgebühr",
  lpc_purchaseFee: "Kaufgebühr",
  lpc_continue_button: "Weiter",
  lpc_confirm_button: "Bestätigen",
  lpc_authorization_request: "",
  lpc_questionSTR_invalid: "Die Antwort auf die Frage: {{question}}, ist nicht korrekt",
  lpc_questionNUM_invalid: "Der für die Frage: {{question}} eingegebene Betrag ist nicht korrekt",
  lpc_Beneficiaryowner: "Wirtschaftlich Berechtigter",
  lpc_confirm_operation: "Vorgang bestätigen",
  lpc_financial_questionnaire: "Finanzfragebogen",
  lpc_avc_questionnaire: "AVC-Fragebogen",
  lpc_confirm_variation: "Abweichung bestätigen",
  lpc_Declarative_Appendix: "Deklarationsnachtrag",
  lpc_investment_funds_step: "Anlagefonds",
  lpc_disinvestment_profile_step: "Veräusserungsprofoile",
  lpc_startDate: "Startdatum",
  lpc_end_date: "Enddatum",
  lpc_six_monthly: "Halbjährlich",
  lpc_quarterly: "Vierteljährlich",
  lpc_weekly: "Wöchentlich",
  lpc_monthly: "Monatlich",
  lpc_annual: "Jährlich",
  lpc_daily: "Täglich",
  lpc_start_date_cannot_be_earlier_than_the_effective_date: "Startdatum kann nicht vor dem effektiven Datum {{value}} liegen",
  lpc_start_date_must_be_earlier_than_the_end_date: "Das Startdatum muss vor dem Enddatum liegen",
  lpc_contract_value: "Vertragswert",
  lpc_invested_amount: "Angelegter Betrag",
  lpc_funds_details_and_summary: "Fondsdetails und Zusammenfassung",
  lpc_separate_management_detail: "Getrennte Verwaltungdetails",
  lpc_denuncia_sinistro: "Anspruchsanmeldung",
  lpc_duration_in: "Laufzeit in",
  lpc_years: "Jahre",
  lpc_months: "Monate",
  lpc_days: "Tage",
  lpc_policy_number: "Policennummer",
  lpc_proposal_number: "Antragsnummer",
  lpc_LifeBeneficiary: "Leben-Begünstigter",
  lpc_DeathBeneficiary: "Begünstigter im Todesfall",
  lpc_NO: "Nein",
  lpc_claim_data: "",
  lpc_node: "Knote",
  lpc_sbj: "Partei",
  lpc_empty: "leer",
  lpc_search_result: "Suchergebnisse",
  lpc_show_elemets: "Elemente anzeigen",
  lpc_insured: "Versicherter",
  lpc_nuova_denuncia: "Neue Benachrichtigung",
  lpc_insured_policy: "Versicherte Policen",
  lpc_product: "Produkt",
  lpc_find: "Suchen",
  lpc_status: "Status",
  lpc_division_description: "Beschreibung der Zuweisung",
  lpc_fund_trend: "Fondsentwicklung",
  lpc_category_fund: "Profil/ Fonds",
  lpc_equivalent_on_date: "Äquivalent zum Datum",
  lpc_quotes_number: "Anzahl der Anteile",
  lpc_damages_policy: "Nicht-Leben-Police",
  lpc_life_policy: "Leben-Police",
  lpc_next_installment_premium: "Nächste Ratenzahlung Prämie",
  lpc_no_result: "Keine Ergebnisse",
  lpc_data_value: "Datum Wert Aktie",
  lpc_paidAmount: "Bezahlter Betrag",
  lpc_total_gross_surrender: "Bruttorückkauf insgesamt",
  lpc_left_premiums_paid: "Linke Prämien bezahlt",
  lpc_left_premiums_amount: "Linke Prämienhöhe",
  lpc_portfolio_volatility_on_subscription_date: "Portfolio-Volatilität am Tag der Zeichnung",
  lpc_portfolio_volatility: "Portfolio-Volatilität am",
  lpc_andamento_controvalore: "Entwicklung des Marktwerts der Police",
  lpc_policy_trend: "Entwicklung der Police",
  lpc_calculate: "Berechnen",
  lpc_frequency: "Frequenz",
  lpc_date_variation_step: "Datumsabweichung",
  lpc_quotation_step: "Offerte",
  lpc_confirm_variation_step: "Abweichung bestätigen",
  lpc_financial_questionnaire_step: "Finanzfragebogen",
  lpc_close_button: "Schliessen",
  lpc_Operation_of_executed_correctly: "Vorgang von {{operation}} korrekt ausgeführt",
  lpc_AuthorizatioPolicyForwarded: "Die Anfrage auf Genehmigung für die Policennummer {{policyNumber}} wurde an den zuständigen Benutzer weitergeleitet",
  lpc_are_you_sure_you_want_to_cancel_this_operation: "Sind Sie sicher, dass Sie diesen Vorgang abbrechen möchten?",
  lpc_cancel_button: "Abbrechen",
  lpc_cancel_reservation: "Reservierung stornieren",
  lpc_system_error: "Systemfehler",
  lpc_generic_error_title: "Fehler",
  lpc_select_label: "Auswählen",
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: "Warnung, Sie löschen den Versicherungsnehmer. Möchten Sie fortfahren?",
  lpc_factor_must_be_equal: "Der {{factor}} muss gleich {{amount}} sein",
  lpc_factor_must_be_greater_than: "Der {{factor}} muss grösser als {{amount}} sein",
  lpc_factor_must_be_less_than: "Der {{factor}} muss kleiner als {{amount}} sein",
  lpc_Please_enter_threshold_percentage_value: "Fonds: {{value}} - Bitte geben Sie den Schwellenwert in Prozent ein",
  lpc_please_enter_a_value_between: "Bitte geben Sie einen Wert zwischen {{min}} und {{max}} ein",
  lpc_variation_step_label: "Abweichung",
  lpc_contractual_option: "Vertragliche Optionen",
  lpc_costs_step_label: "Kosten",
  lpc_variation_detail_step_label: "Abweichungsdetails",
  lpc_ongoing_operations: "Die Werte können sich aufgrund des laufenden Betriebs ändern",
  lpc_exchange_rate: "Wechselkurs",
  lpc_last_share_value: "Letzter Aktienwert",
  lpc_movement_description: "Beschreibung der Bewegung",
  lpc_settlement_amount: "Abrechnungsbetrag",
  lpc_Annual_SinglePremium: "Jährliche/einmalige Prämie",
  lpc_policy_address: "Adresse der Police",
  lpc_holders: "Versicherungsnehmer",
  lpc_last_revaluation: "Letzte Neubewertung",
  lpc_revalueted_benef: "Neu bewertete Leistung",
  lpc_investment: "Anlagen",
  lpc_position_number: "Positionsnummer",
  lpc_invested_premium: "Angelegte Prämie",
  lpc_operation_details: "Vorgangsdetails",
  lpc_contract: "Vertrag",
  lpc_dis_date: "Veräusserungsdatum",
  lpc_net_amount: "Nettobetrag",
  lpc_net: "Netto",
  lpc_quote: "Offerte",
  lpc_quote_and_save: "Offerte und speichern",
  lpc_info_investment_complete: "Information: Anlage abgeschlossen",
  lpc_effective_holders: "Wirtschaftlich Berechtigte",
  lpc_effective_holder: "Wirtschaftlich Berechtigter",
  lpc_lien_credit: "Art des Gläubigers",
  lpc_notification_status: "Status der Benachrichtigung",
  lpc_policyHolder: "Versicherungsnehmer",
  lpc_end_validity_date: "Enddatum der Gültigkeit",
  lpc_start_date: "Startdatum",
  lpc_unit_linked_value: "Anteilsgebundener Wert",
  lpc_allocation_profile: "Zuweisungsprofil",
  lpc_search_filter: "Suchfilter",
  lpc_expiry_option_detail: "Details der Verfallsoption",
  lpc_annuity_payment_type: "Art der Lebensrentenzahlung",
  lpc_annuity_frequency: "Häufigkeit der Lebensrente",
  lpc_percentage_reversibility: "Prozentuale Umkehrbarkeit",
  lpc_reversionary: "Benannter Begünstigter",
  lpc_certain_revenue_years: "Bestimmte Jahre der Einnahmen",
  lpc_postponement_type: "Art des Aufschubs",
  lpc_postponement_years: "Jahre des Aufschubs",
  lpc_clauses: "Klauseln",
  lpc_active_contractual_option: "Aktive Vertragsoptionen",
  lpc_expiry_option: "Verfallsoption",
  lpc_revaluation_history: "Neubewertungsverlauf",
  lpc_monetary_fund: "Geldfonds",
  lpc_benefit_effect_date: "Gültigkeitsdatum der Leistung",
  lpc_initial_benefit: "Ursprüngliche Leistung",
  lpc_movement_update: "Aktualisierung der Bewegung",
  lpc_update_effect_date: "Gültigkeitsdatum aktualisieren",
  lpc_net_rate: "Nettorate",
  lpc_macro_category: "Kategorie Makro",
  lpc_quotation_frequency: "Offertenfrequenz",
  lpc_volatility: "Volatilität",
  lpc_volatility_date: "Datum der Volatilität",
  lpc_start_quotation: "Offerte starten",
  lpc_last_quotation: "Letzte Offerte",
  lpc_valuation: "Bewertung",
  lpc_percentage_total: "Prozentsatz insgesamt",
  lpc_euro_valuation_not_fund_currency: "Die Bewertung ist in € und nicht in der Fondswährung ausgedrückt",
  lpc_disinvstements: "Veräusserungen",
  lpc_life_detail_percentage: "Prozentsatz",
  lpc_payment_amount: "Zahlungsbetrag",
  lpc_surrender_distribution: "Ausschüttung des Rückkaufs",
  lpc_formula: "Formel",
  lpc_roles_connected_benef: "Mit dem Begünstigten verbundene Rollen",
  lpc_quotation_details: "Offertendetails",
  lpc_Disinvestmentsfunds: "Veräusserungsfonds",
  lpc_same_fund_distribution: "Fondsausschüttung identisch mit der ursprünglichen Zahlung",
  lpc_guarantees_oveview: "Überblick über die Garantien",
  lpc_calculate_volatility: "Volatilität des Vermögenswerts berechnen",
  lpc_benef_death_not_same: "Der Begünstigte im Todesfall darf nicht der Versicherte sein",
  lpc_global_percentage_equal_hundred: "Der Gesamtprozentsatz muss 100 betragen",
  lpc_benefPresente_default_msg: "Die Partei wurde bereits als Begünstigter in der gleichen Kategorie ausgewählt",
  lpc_effective_holder_natural_person_req: "{{benefCat}} {{benefRole}} - Die Partei {{linkedName}} als wirtschaftlich Berechtigter muss eine natürliche Person sein",
  lpc_effective_holder_duplicate: "{{linkedName}} wurde bereits als wirtschaftlich Berechtigter von {{benefName}}' eingetragen",
  lpc_effective_holder_adult_req: "{{benefCat}} {{benefRole}} - Die Partei {{linkedName}} als wirtschaftlich Berechtigter muss ein Erwachsener sein",
  lpc_investment_plan: "Anlageplan",
  lpc_contract_detail: "Vertragsdetails",
  lpc_variation_reason: "Grund der Abweichung",
  lpc_investment_plan_detail: "Anlageplandetails",
  lpc_revaluation_rate: "Neubewertungsrate",
  lpc_benefit_commencement_date: "Ursprüngliche Leistung",
  lpc_update_effective_date: "Gültigkeitsdatum aktualisieren",
  lpc_last_odds_value: "Letzter Quotenwert",
  lpc_total: "Gesamt",
  lpc__EFFOP: "Gültigkeitsdatum",
  lpc__OPZFR: "Zahlung per Raten",
  lpc__OPZPM: "Zahlungsweise",
  lpc__OPBEN: "Begünstigter",
  lpc__MINTS: "Mindestschwellenwert in Prozent",
  lpc__MAXTS: "Maximalschwellenwert in Prozent",
  lpc__NUMOC: "Anzahl der Vorkommnisse",
  lpc__AMTOP: "Rückzahlungsbetrag",
  lpc__MALMT: "Mindestbetrag",
  lpc__CIBAN: "IBAN",
  lpc__PCOUP: "Regelmässger Coupon-Prozentsatz",
  lpc_threshold_percentage: "Schwellenwert in Prozent",
  lpc_pure: "Reine Prämie",
  lpc_starting_fund: "Startkapital",
  lpc_details: "Details",
  lpc_treshold: "Schwellenwert",
  lpc_inv_date: "Anlagedatum",
  lpc_beneficiary_perc_req: "{{benefCat}} {{benefRole}} {{benefName}}: Geben Sie den Prozentsatz ein",
  lpc_beneficiary_perc_req_range: " {{benefCat}} {{benefRole}} {{benefName}}: Der Prozentsatz muss zwischen 0,01 und 100 liegen",
  lpc_invalid_total_position_percent: "Der prozentuale Betrag für die Gruppe \"{{groupType}}\" für den Begünstigten {{cat}} muss 100 % betragen",
  lpc_beneficiary_from_party_req: " {{benefCat}} {{benefRole}}: Geben Sie einen Begünstigten aus dem Parteien-Repository ein",
  lpc_benefPresente: "{{benefName}} wurde bereits als {{benefCat}} {{benefRole}} eingegeben",
  lpc_benMorteNoAssic: "{{benefName}} eingegeben als {{benefCat}} {{benefRole}} wurde bereits als Versicherter eingegeben",
  lpc_life_beneficiary: "Leben-Begünstigter",
  lpc_death_beneficiary: "Begünstigter im Todesfall",
  lpc_beneficiary_position_mandatory: "Begünstigter {{benefCat}} {{benefName}}, wählen Sie eine \"Positionsnummer\" aus",
  lpc_reversionary_subject: "Benannter Begünstigter",
  lpc_gross_detail: "Bruttoprämiendetails",
  lpc_type_of_covers: "Deckungsart",
  lpc_beneficiary_qualification: " {{benefCat}} {{benefRole}} ",
  lpc_not_linked_detail: "",
  lpc_clausesinsuredClauses: "",
  lpc_clausespolicyClauses: "",
  lpc_clausesriskClauses: "",
  lpc_max_capital_amount: "",
  lpc_fiscal_period_M1: "",
  lpc_fiscal_period_M2: "",
  lpc_fiscal_period_M3: "",
  lpc_fiscal_period_capital_request_K1: "",
  lpc_fiscal_period_capital_request_K2: "",
  lpc_fiscal_period_capital_request_K3: "",
  lpc_max_amount_exceeded: "",
  lpc_max_amount_error: "",
  lpc_performance_data: "",
  lpc_role_is_mandatory: "",
  lpc_issue_authorized_proposal: "",
  lpc_confirm_prop_pol_auth: "",
  lpc_settlement: "",
  lpc_annuity: "",
  lpc_percentage_in_capital: "",
  lpc_insured_amount: "",
  lpc_custom_field_mandatory_error: "",
  lpc_print_docs: "",
  lpc_revisionary_err: "",
  lpc_revisionary_perc_err: "",
  lpc_modify_quote: "",
  lpc_modify_proposal: "",
  lpc_occurrenceNumber: "Vorfallnummer",
  lpc_gross_amount: "Bruttobetrag",
  lpc_third_party_role: "Drittzahler",
  lpc_not_same_fund_distribution: "Fondsausschüttung abweichend von der ursprünglichen Zahlung",
  lpc_percentage_must_be_between: "",
  lpc_PIP_UndeductedPremiums: "",
  lpc_contributionYear: "",
  lpc_annualPremium: "",
  lpc_undeductedPremium: "",
  lpc_reversionario: "",
  lpc_firstEnrollmentComplementaryPension: "",
  lpc_PIP_amountForTaxation: "",
  lpc_PIP_amount: "",
  lpc_PIP_contribempl4Perc: "",
  lpc_PIP_reducttaxbltfr: "",
  lpc_PIP_aliqtfr: "",
  lpc_PIP_premdeducted2001: "",
  lpc_PIP_premdeductoffset: "",
  lpc_PIP_annuitytaxdeduct: "",
  lpc_pip_contributions_installment_detail: "DE_Contributions Details",
  lpc_contribution_type: "DE_Contribution type",
  lpc_contribution_amount: "DE_Amount",
  lpc_contribution_year: "DE_Year",
  lpc_contribution_month_from: "DE_Month (from)",
  lpc_contribution_month_to: "DE_Month (to)",
  lpc_contribution_thirteenth: "DE_13^",
  lpc_contribution_fourteenth: "DE_14^",
  lpc_incomplete_fund_investment: "",
  lpc_incomplete_profile_investment: "",
  lpc_tax_credit: "",
  lpc_clausesinsuredClause: "Versicherte Klauseln",
  lpc_clausespolicyClause: "Policenklauseln",
  lpc_clausesriskClause: "Risikoklauseln",
  lpc_tariff_version: "",
  lpc_operation_rescission_reason: "Grund der Auflösung",
  lpc_lic_modify_proposal: "",
  lpc_inbound_documents_mov: '',
};
export default LPC_DE_CH;
