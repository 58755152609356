import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { RoutingService } from '@rgi/portal-ng-core'
import { ValidationConsolidationStateManager } from '../../state-managers/validation-consolidation.state.manager'
import { RgiRxDataTableColumnSortDef, RgiRxDataTableExpansionModel, RgiRxDatatableComponent, RgiRxSwitchChange, RgiRxTableExpansionTrigger, TableRowSchema, TableRowTypeSchema, TableSchema } from '@rgi/rx/ui'
import { codFunction, codMessage, companyCodeCnt, companyCodeUs, error, errorCode, externalKey, forced, importForfait, internalKey, lossDate, lotto, month, paveeDlg, registerType, role, roleDlg, sendingFlowDate, sequence, settlementNumber, skipped, state, uniqueCode, year } from '../../constants/validation.constants'
import { SearchPaymentsOutput } from '../../models/payments/search-payments-output.model'
import { ValidationConsolidationState } from '../../states/validation-consolidation.state'
import { of, Subscription } from 'rxjs'
import { ViewFunctionCondition } from '../../models/validation/function-condition.enum'
import { ValidationConsolidationStatus } from '../../models/validation/validation-consolidation-status.enum'
import { UtilityMethod } from '../../constants/forfait.constants'
import { ClaimsTireaForcedRecord, SearchPayments } from '../../models/payments/search-payments.model'
import { take } from 'rxjs/operators'
import { AccordionRow1, AccordionRow2, AccordionRow3 } from '../../models/validation/accordion-rows.model'
import { RgiRxTranslationService } from '@rgi/rx/i18n'

// Consente la chiusura dell'accordion
const canContract = (): RgiRxTableExpansionTrigger => (row: any) => {
  console.info('can contract?', row)
  return true
}

// Ordinamento dei componenti custom all'interno della tabella
const CUSTOM_TEMPLATE_SORT_DEF: RgiRxDataTableColumnSortDef<SearchPaymentsOutput>[] = [
  { name: forced, comparator: 'name' },
  { name: skipped, comparator: 'name' }
]

@Component({
  selector: "table-validation-consolidation",
  templateUrl: "./table-validation-consolidation.component.html",
  styleUrls: ["./table-validation-consolidation.component.scss"],
})
export class TableValidationConsolidationComponent implements OnInit {
  @ViewChild(RgiRxDatatableComponent, { static: true }) dt: RgiRxDatatableComponent

  protected isValida: boolean = false
  protected isConsolida: boolean = false
  protected isBilancio: boolean = false


  protected subscriptions: Subscription[] = []
  protected localState: ValidationConsolidationState = null
  protected searchPaymentsInputDTO: SearchPayments = new SearchPayments()


  protected totaleRecord: number = 0
  protected totaleRecordKO: number = 0
  protected totaleRecordOK: number = 0
  protected importoTotalePagamenti: number = 0
  protected importoTotaleRecuperi: number = 0


  protected accordionRow1: AccordionRow1 = new AccordionRow1()
  protected accordionRow2: AccordionRow2 = new AccordionRow2()
  protected accordionRow3: AccordionRow3 = new AccordionRow3()
  protected accordionKeysRow1: string[] = []
  protected accordionKeysRow2: string[] = []
  protected accordionKeysRow3: string[] = []


  protected tableData: SearchPaymentsOutput[] = []
  protected sortDef: RgiRxDataTableColumnSortDef<SearchPaymentsOutput>[] | boolean = CUSTOM_TEMPLATE_SORT_DEF
  protected canExpand = (): RgiRxTableExpansionTrigger => (row: any) => {
    console.info('can expand?', row)
    this.hideFileds(row)
    return true
  }
  protected customExpansionModel: RgiRxDataTableExpansionModel<SearchPaymentsOutput> = new RgiRxDataTableExpansionModel<SearchPaymentsOutput>(
    this.tableData, { multiple: false, canExpand: this.canExpand(), canContract: canContract() }
  )
  private rows: TableRowTypeSchema[] = [
    { name: codFunction, title: '_CLAIMS_._FUNCTION' },
    { name: uniqueCode, title: '_CLAIMS_._PAYMENTS._UNIQUECODE' },
    { name: companyCodeUs, title: '_CLAIMS_._PAYMENTS._CODE_COMPANY' },
    { name: internalKey, title: '_CLAIMS_._PAYMENTS._INTERNALKEY' },
    { name: companyCodeCnt, title: '_CLAIMS_._PAYMENTS._COMPANYCODECNT' },
    { name: externalKey, title: '_CLAIMS_._PAYMENTS._EXTERNALKEY' },
    { name: role, title: '_CLAIMS_._ROLE' },
    { name: codMessage, title: '_CLAIMS_._PAYMENTS._CODMESSAGE' }
  ]
  protected schema: TableSchema = { rows: this.rows, header: [] }


  constructor(
    @Inject('authService') private authService,
    protected routingService: RoutingService,
    public tableStateManager: ValidationConsolidationStateManager,
    public translateService: RgiRxTranslationService
  ) { }


  ngOnInit(): void {
    this.checkState()
    this.updateTotals()
    this.updateButtons()
  }


  checkState() {
    this.subscriptions.push(this.tableStateManager.getState$().subscribe((state: ValidationConsolidationState) => {
      if (state) {
        if (this.localState == null) {
          this.localState = state
        }

        if (!!state.searchPaymentsOutputDTO) {
          const tableVar = this.tableData

          this.tableData = []
          this.tableData = [...state.searchPaymentsOutputDTO]

          if(tableVar != null) {
            this.dynamicTableColumns()
          } else {
            of(this.tableData).pipe(take(1)).subscribe(next => this.dt.dataSource.update(next));
            this.updateTotals()
            this.updateButtons()
          }
        }

        if (!!state.searchPaymentsInputDTO) {
          this.searchPaymentsInputDTO = state.searchPaymentsInputDTO
        }
      }
    }))
  }


  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe()
        sub = null
      }
    })
  }




  protected onToggleSortHeaders($event: RgiRxSwitchChange) {
    this.sortDef = $event.selected ? CUSTOM_TEMPLATE_SORT_DEF : false
  }

  protected setForzatura(searchPaymentsOutput: SearchPaymentsOutput) {
    if(searchPaymentsOutput.skipped) {
      searchPaymentsOutput.skipped = !searchPaymentsOutput.forced
    }

    searchPaymentsOutput.forced = searchPaymentsOutput.forced

    const index: number = this.tableData.indexOf(searchPaymentsOutput)
    this.tableData[index] = searchPaymentsOutput
    this.updateButtons()
  }

  protected setSkip(searchPaymentsOutput: SearchPaymentsOutput) {
    if (searchPaymentsOutput.forced) {
      searchPaymentsOutput.forced = !searchPaymentsOutput.skipped
    }

    searchPaymentsOutput.skipped = searchPaymentsOutput.skipped

    const index: number = this.tableData.indexOf(searchPaymentsOutput)
    this.tableData[index] = searchPaymentsOutput
    this.updateButtons()
  }

  protected isDisabledForzatura(searchPaymentsOutput: SearchPaymentsOutput): boolean {
    if (
      !!searchPaymentsOutput
      && !!searchPaymentsOutput.state
      && !!searchPaymentsOutput.state.code
      && !!searchPaymentsOutput.claimNumberDlg
    ) {
      return !(
        searchPaymentsOutput.state.code == ValidationConsolidationStatus.INERROR
        && !!searchPaymentsOutput.claimNumberDlg
        && !!searchPaymentsOutput.idTypeOfLossDlg
        && !!searchPaymentsOutput.importForfait
      )
    }

    // this.isDisabledConsolida()
    // this.isDisabledValida()

    return true;
  }

  protected isDisabledSkip(searchPaymentsOutput: SearchPaymentsOutput): boolean {
    if (
      !!searchPaymentsOutput.state
      && !!searchPaymentsOutput.state.code
    ) {
      return searchPaymentsOutput.state.code !== ValidationConsolidationStatus.INERROR
    }

    // this.isDisabledConsolida()
    // this.isDisabledValida()

    return true;
  }

  protected hideFileds(ctx: SearchPaymentsOutput): void {
    this.accordionRow1.lotto = ctx[lotto]
    this.accordionRow1.sequence = ctx[sequence]
    this.accordionRow1.registerType = ctx[registerType]
    this.accordionRow1.roleDlg = ctx[roleDlg] ? ctx[roleDlg].descr : ''
    this.accordionRow1.lossDate = UtilityMethod.transform(ctx[lossDate])
    this.accordionRow1.settlementDate = ctx[year] + '/' + ctx[month]
    this.accordionKeysRow1 = Object.keys(this.accordionRow1)

    this.accordionRow2.error = ctx[errorCode] && ctx[error] ? ctx[errorCode] + '-' + ctx[error] : ''
    if(ctx[companyCodeCnt] != null) {
      this.accordionRow2.companyCodeCnt = ctx[companyCodeCnt].descr
    }


    ctx[role].code == '2'
      ? this.translateService.translate('_CLAIMS_._PAYMENTS._PAY').subscribe(res => this.accordionRow2.paymentCollection = res.toUpperCase())
      : this.translateService.translate('_CLAIMS_._PAYMENTS._RECOVER').subscribe(res => this.accordionRow2.paymentCollection = res.toUpperCase())

    this.accordionRow2.sendingFlowDate = UtilityMethod.transform(ctx[sendingFlowDate])
    this.accordionKeysRow2 = Object.keys(this.accordionRow2)

    this.accordionRow3.settlementNumber = ctx[settlementNumber]
    this.accordionRow3.paveeDlg = ctx[paveeDlg]
    this.accordionKeysRow3 = Object.keys(this.accordionRow3)
  }

  protected valida(): void {
    this.tableData = null
    this.tableStateManager.bookValidation$(this.searchPaymentsInputDTO)
  }

  protected consolida() {
    this.tableData.forEach(row => {
      const forced: boolean = row.forced
      const skipped: boolean = row.skipped

      if(row.state.code == ValidationConsolidationStatus.INERROR
        && (forced || skipped)
      ) {
        const forcedRecord: ClaimsTireaForcedRecord = {
          idTireaPayment: row.idTireaPayment,
          forced: row.forced,
          skipped: row.skipped
        }

        this.searchPaymentsInputDTO.forcedRecords.push(forcedRecord)
      }
    })

    this.tableData = null
    this.tableStateManager.bookConsolidation$(this.searchPaymentsInputDTO)
  }

  protected bilancio() { }



  private addColumn(columnName: string, titleColumn: string): void {
    const columnSchema: TableRowSchema = { name: columnName, title: titleColumn }
    this.rows.push(columnSchema)
  }

  private dynamicTableColumns(): void {
    if (
      !!this.tableData
      && this.tableData.length > 0
      && !!this.tableData[0]
      && !!this.tableData[0].codFunction
      && !!this.tableData[0].codFunction.code
    ) {
      const functionCode: string = this.tableData[0].codFunction.code

      switch (functionCode) {
        case ViewFunctionCondition.CICOS:
          this.addColumn(importForfait, '_CLAIMS_._PAYMENTS._IMPORTFORFAIT')
          break
        case ViewFunctionCondition.SDM:
          break
        case ViewFunctionCondition.SDM_MODULAR:
          break
        case ViewFunctionCondition.CAS:
          this.rows = this.rows.filter(row => row.name !== role)
          break
      }

      const endRows: TableRowTypeSchema[] = [
        { name: state, title: '_CLAIMS_._STATUS' },
        { name: forced, title: '_CLAIMS_._PAYMENTS._FORCED' },
        { name: skipped, title: '_CLAIMS_._PAYMENTS._SKIPPED' }
      ]

      this.rows = [...this.rows, ...endRows]

      this.schema.rows = this.rows;
      this.schema.header = this.rows.map((row) => row.name);
    }
  }

  private isDisabledValida(): boolean {
    if(this.tableData != null) {
      let find: SearchPaymentsOutput = this.tableData.find(
        (searchPaymentsOutput: SearchPaymentsOutput) =>
          searchPaymentsOutput.state.code == ValidationConsolidationStatus.TOBEVALIDATED
          || (searchPaymentsOutput.state.code == ValidationConsolidationStatus.INERROR
          && (!searchPaymentsOutput.forced && !searchPaymentsOutput.skipped))
      )
      return !!!find
    }
  }

  private isDisabledConsolida(): boolean {
    let foundErr = null;
    if (this.tableData != null) {
      foundErr = this.tableData.find((searchPaymentsOutput: SearchPaymentsOutput)=>{

        if (searchPaymentsOutput.state.code != ValidationConsolidationStatus.VALIDATED &&
          (searchPaymentsOutput.state.code != ValidationConsolidationStatus.INERROR || (!searchPaymentsOutput.forced && !searchPaymentsOutput.skipped))) {
            return true;
        }
      })

      return (foundErr != null);
    }
  }

  private isDisabledBilancio(): boolean {
    return true
  }

  private updateTotals() {
    this.importoTotalePagamenti = 0
    this.importoTotaleRecuperi = 0

    this.totaleRecord = this.tableData.length
    this.totaleRecordKO = this.tableData.filter((v) => v.state.code == ValidationConsolidationStatus.INERROR).length
    this.totaleRecordOK = this.tableData.filter((v) => v.state.code == ValidationConsolidationStatus.VALIDATED || (v.state.code == ValidationConsolidationStatus.WAITINGFORCONSOLIDATION && !v.errorCode)).length;
    this.tableData.filter((v) => v.role.code == '2'
      ? this.importoTotalePagamenti += Number(v.importForfait)
      : this.importoTotaleRecuperi += Number(v.importForfait)
    )
  }

  private updateButtons() {
    this.isValida = this.isDisabledValida()
    this.isConsolida = this.isDisabledConsolida()
    this.isBilancio = this.isDisabledBilancio()
  }
}
