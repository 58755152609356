import {PncPsalesSummaryStateManager} from '../../../state-managers/summary/summary-state-manager.service';
import {ActiveRoute} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {RgiRxPushMessageHandlerService} from '@rgi/rx';
import {PncPostSalesOrchestratorService} from '../../../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../../../services/pnc-postsales-integration.service';
import {PncPsalesHttpErrorService} from '../../../services/pnc-postsales-http-error.service';
import {Observable, of} from 'rxjs';
import {PncPsalesSummaryState} from '../../../resources/states/summary-state';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ModalService} from '@rgi/rx/ui';
import {PncDomainService} from '../../../resources/http/pnc-domain.service';
import {PncPsalesNote} from '../../../resources/model/api/notes';
import {PncPsalesBaseState} from '../../../resources/states/pnc-postsales-state';
import {PROPERTY_PP} from '../../../resources/constants/properties';
import {QuotationAPI} from '../../../resources/model/api/quotation/quotation-api';
import {PropertyApi} from '../../../resources/model/api/property';
import {createDefaultButtonBar} from "../../../resources/constants/buttons";
import {PncPsalesButton} from "../../../resources/model/common/button";
import {PNC_PSALES_ACTIONS} from "../../../resources/constants/actions";

export class PncPsalesSummaryStateManagerVariations extends PncPsalesSummaryStateManager {

  private readonly routePrefix: string;

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    modalService: ModalService,
    translateService: RgiRxTranslationService,
    private resourceService: PncDomainService,
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context, modalService, translateService);
    this.routePrefix = context.apiPrefix;
    if (!this.routePrefix) {
      throw Error('Missing apiPrefix in context');
    }
  }

  initState$(_state: Observable<PncPsalesSummaryState>): Observable<PncPsalesSummaryState> {
    const initialState = new PncPsalesSummaryState(this.activeRoute);
    initialState.stepSummaries = [];
    this.orchestrator.getPreviousRoutes(this.activeRoute).forEach(route => {
      const storedState = Array.from(this.stateStoreService.list())
        .find(storedKeyValue => storedKeyValue[0].includes(route))[1] as PncPsalesBaseState;
      if (storedState?.summaryStepDetail) {
        initialState.stepSummaries.push(storedState.summaryStepDetail);
      }
    });
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    return of(initialState).pipe(
      concatMap((st: PncPsalesSummaryState) => {
        return this.resourceService.getDraftNotes$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix, st.draftId).pipe(
          map((notes: PncPsalesNote ) => {
            st.notes = notes.text;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st:PncPsalesSummaryState) => {
        return this.resourceService.getProperty$(this.context.apiPrefix, st.policyNumber, st.operationType, st.currentOperation.code, st.draftId, PROPERTY_PP.SHOW_QUOTATION).pipe(
          map(resp => resp),
          concatMap((property:PropertyApi) => {
            st.showQuotation = +property?.value > 0;
            if (st.showQuotation) {
              return this.resourceService.getQuotationData$(this.context.apiPrefix, st.policyNumber, st.operationType, st.currentOperation.code, st.draftId).pipe(
                map((premiums: QuotationAPI) => {
                  st.quotationHeaderData = this.populateQuotationHeader(premiums?.commissions, premiums?.annualPremium, premiums?.installmentDetail, premiums?.nextInstallmentDetail, null);
                  return st;
                }),
                catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
              );
            }
            return of(st);
          })
        )
      }),
      catchError(this.errorService.manageStreamErrFn()),
       map((st: PncPsalesSummaryState) => {
              st.footerBtns = createDefaultButtonBar([new PncPsalesButton('_PCPSALES_._BTN_._DETAIL_', PNC_PSALES_ACTIONS.FULL_SUMMARY,'primary',false)]);
              return st;
            })
    );
  }

  protected finalizeOperation$(state: PncPsalesSummaryState) {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const stateObs = of(state).pipe(
      concatMap((st) => {
        return this.setNotes$(st);
      }),
      concatMap((st) => {
        return this.resourceService.executeOperation$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix, st.draftId)
          .pipe(
            map((resp) => {
              // todo gestione messaggi da mandare in ultima pagina - orchestrator.getNextStepRoute(st, this.activeRoute) per il tag della notify
              st.contractId = resp.contractId;
              st.proposalNumber = resp.proposalNumber;
              st.movementId = resp.movementId;
              this.orchestrator.goToNextStep(st, this.activeRoute);
              return st;
            }),
            catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
          );
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesSummaryState) => st)
    );
    this.updateState$(stateObs);
  }

  private setNotes$(st: PncPsalesSummaryState) {
    if (!st.notes || st.notes.trim().length === 0) {
      return of(st);
    }
    const notes: PncPsalesNote = {text: st.notes};
    return this.resourceService.setDraftNotes$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix, st.draftId, notes)
      .pipe(
        map((_resp) => st),
        catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
      );
  }

  actionBack() {
    const state$ = of(this.getCurrentState()).pipe(
      concatMap((st: PncPsalesSummaryState) => this.setNotes$(st)),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesSummaryState) => st)
    );
    this.updateState$(state$);
    super.actionBack();
  }
}
