import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AngularIbanModule} from 'angular-iban';
import {NgSelectModule} from '@ng-select/ng-select';
import {ReactiveFormsModule} from '@angular/forms';
import {LpcFormatPaymentDirective} from './directives/lpc-format-payment.directive';
import {LpcPaymentLoaderDirective} from './directives/lpc-payment.loader.directive';
import {LpcPaymentComponent} from './lpc-payment/lpc-payment.component';
import {LpcPaymentDatepickerComponent} from './lpc-payment-datepicker/lpc-payment-datepicker.component';
import {LpcPaymentInputComponent} from './lpc-payment-input/lpc-payment-input.component';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';


@NgModule({
    declarations: [
        LpcFormatPaymentDirective,
        LpcPaymentLoaderDirective,
        LpcPaymentComponent,
        LpcPaymentDatepickerComponent,
        LpcPaymentInputComponent
    ],
    imports: [
        CommonModule,
        AngularIbanModule,
        NgSelectModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        LpcRequiredModule,
        RgiRxFormElementsModule
    ],
    exports: [
        LpcPaymentComponent,
        LpcPaymentInputComponent,
        LpcPaymentDatepickerComponent,
        LpcFormatPaymentDirective,
        LpcPaymentLoaderDirective,
        LpcPaymentComponent
    ]
})
export class LpcPaymentFieldsModule {
}
