import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { EnviromentsService, LifeRoleService, LifeSessionService, PolicyService, SystemPropertiesService, LifeRgiRxHttpClientWrapperService } from "@rgi/life-issue-card";
import { RGI_RX_I18N_CONFIG, RgiRx18nConfig, RgiRxTranslationService } from "@rgi/rx/i18n";
import { Observable } from "rxjs";
import { ExtensionPropertyChExt } from "../../../ext-rest/life/common/model/enums";

@Injectable({
  providedIn: 'root'
})
export class PolicyServiceExt extends PolicyService {

    isFromConsultationModifysign = false;
    idSession = null;

    constructor(
      protected http: HttpClient,
      protected environment: EnviromentsService,
      protected lifeSessionService: LifeSessionService,
      protected lifeRoleService: LifeRoleService,
      protected systemPropertiesService: SystemPropertiesService,
      @Inject(RGI_RX_I18N_CONFIG) protected rgiRxConfig: RgiRx18nConfig,
      protected rxTranslationsService: RgiRxTranslationService,
      protected lifeRgiRxHttpClientWrapperService: LifeRgiRxHttpClientWrapperService,
      @Inject('containerCacheService') protected portalContainerCacheService: any) {
            super(http, environment, lifeSessionService, lifeRoleService, systemPropertiesService,
                rgiRxConfig, rxTranslationsService, lifeRgiRxHttpClientWrapperService);
    }

    public getLifeProposal(propNumber: string): Observable<{output: any}> {
        return this.http.get<{output: any}>(this.environment.baseApiPassURL + '/ptflife/proposal/quote/' + propNumber);
    }


    override setPropSaveProposal(isQuotationModeOn?: string, isSetInitialStateUnit?: string, pipTransfer?: any, pipContrib?: any,
      toolsData?: any, investmentsData?: any): any[] {
      const jsonProperties = super.setPropSaveProposal(isQuotationModeOn, isSetInitialStateUnit, pipTransfer, pipContrib, toolsData, investmentsData);
      if (!!this.mainProposal && !!this.mainProposal.proposal.extensionData && !!this.mainProposal.proposal.extensionData.properties) {
          [
              this.getExtensionProperty(ExtensionPropertyChExt.DERISK_SELECTED),
              this.getExtensionProperty(ExtensionPropertyChExt.PARTY_QUESTIONNAIRE),
              this.getExtensionProperty(ExtensionPropertyChExt.COUNTER_PROPOSAL_VALUE)
          ].forEach(prop => {
              if (prop !== null && prop !== undefined && prop.valore !== null && prop.valore !== undefined) {
                  jsonProperties.push(prop);
              }
          });
      }
      return jsonProperties;
    }

    override setPropSaveFromQuote(pipTransfer?: any, pipContrib?: any): any[] {
      const jsonProperties = super.setPropSaveFromQuote(pipTransfer, pipContrib);
      if (!!this.mainProposal && !!this.mainProposal.proposal.extensionData && !!this.mainProposal.proposal.extensionData.properties) {
        [
          this.getExtensionProperty(ExtensionPropertyChExt.PARTY_QUESTIONNAIRE),
          this.getExtensionProperty(ExtensionPropertyChExt.COUNTER_PROPOSAL_VALUE)
        ].forEach(prop => {
          if (prop !== null && prop !== undefined && prop.valore !== null && prop.valore !== undefined) {
            jsonProperties.push(prop);
          }
        });
      }
      return jsonProperties;
    }



    updateCoreResultData() {
      this.isFromConsultationModifysign = !!this.portalContainerCacheService.get(this.coreResultData.sessionId)?.isFromConsultationModifysign;
      super.updateCoreResultData();
    }
}
