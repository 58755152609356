import { Component, Input, Output, EventEmitter, OnInit, AfterViewChecked, Inject } from '@angular/core';
import {OTPDocument} from '../models/otp-document.model';
import {OtpService} from '../services/otp-service.service';
import { JS_EVENT, PV_TOKEN } from '@rgi/life-postsales-card';
import {JS_EVENT_EXT} from '../life-postsales-card-ext/models/consts/life-postsales-card-ext-consts';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MessageSubType, MessageType } from '@rgi/portal-ng-core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'iiab-digital-sign-documents',
  templateUrl: './digital-sign-documents.component.html',
  styleUrls: ['digital-sign-documents.component.scss']
})
export class DigitalSignDocumentsComponent implements OnInit, AfterViewChecked {

  @Input() data: any;
  @Input() id: string;
  @Output() eventPropagation = new EventEmitter<any>();
  documentList: OTPDocument[];
  message: {msg: string[], type: MessageType, detail: MessageSubType | 'warningMessages' | 'infoMessages'}[] = null;
  srcSanithized: SafeResourceUrl = '';

  isMotor = false;

  constructor(
    protected otpService: OtpService,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    @Inject('coreResult') protected coreResult?: any
  ) {
  }

  get areThereDocuments(): boolean {
    return this.documentList && this.documentList.length > 0;
  }

  ngOnInit() {
    if (this.data && this.data.sessionId && !this.data.otpDocuments) {
      const coreResultDocumentList = this.coreResult.getResult(this.data.sessionId, 'documentList');
      this.documentList = coreResultDocumentList;
      this.isMotor = true;
    } else {
      this.documentList = this.data?.otpDocuments;
    }
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  openDocument(doc: OTPDocument, modal: any) {
    this.resetErrorsAndSrcSanithized();
    this.otpService.downloadDossier(doc.idPol, doc.idMov, doc.idDoc).pipe(
      catchError(() => {
        this.message = this.otpService.dossierStatus;
        return throwError('error');
      })
    ).subscribe(() => {
      const documentSanithized = this.otpService.getDocumentContent(doc.idDoc, doc.idDossier);
      if (documentSanithized) {
        this.srcSanithized = documentSanithized;
        this.message = this.otpService.dossierStatus;
        modal.open();
      } else {
        this.message = this.otpService.dossierStatus;
      }
    });
  }

  private resetErrorsAndSrcSanithized() {
    this.message = null;
    this.srcSanithized = '';
    this.otpService.clearDocuments();
  }

  public backLife() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  public backMotor() {
    this.eventPropagation.emit(JS_EVENT_EXT.BACK_DETAIL_DAMAGE);
  }

}
