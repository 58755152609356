<div *ngIf="movement" data-qa="life-loan-detail">

  <div class="row">
      <div class="col-lg-12">
          <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
              <div class="row life-detail-card-title-container">
                  <div class="row life-detail-title">
                      <div class="card-title">
                          <span class="glyphicon glyphicon-circle-arrow-left arrow-precise-pos"
                              style="cursor: pointer;" (click)="back()" data-qa="back-arrow"></span>
                          <!-- <span class="separator"> | </span> -->
                          <span class="menu-title" translate>lpc_inbound_documents_mov</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">
    <rgi-documents
        [contractId]="contractId"
        [movementId]="movement.idMov"
        [uploadDownloadEnabled]="true"
        (startDocument)="startDocuments($event)"
        [getAllDocuments]="true"
        (eventModifiedDocument)="verifyDocuments($event)">
    </rgi-documents>
  </div>
</div>
