import {
  ROUTES_CONFIRMATION,
  ROUTES_MOVEMENTS,
  ROUTES_REQUIRED_DATA,
  ROUTES_SUMMARY, ROUTES_SUPPLEMENTARY_TECH_ACC,
  ROUTES_TECHNICAL_ACCOUNTING
} from './routes';
import {PncPsalesRequiredDataComponent} from '../../steps/required-data/required-data.component';
import {PncPsalesSummaryComponent} from '../../steps/summary/summary.component';
import {
  PncPsalesRequiredDataStateManager
} from '../../state-managers/required-data/required-data-state-manager.service';
import {PncPsalesSummaryStateManager} from '../../state-managers/summary/summary-state-manager.service';
import {ModalService} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {PncPsalesConfirmationComponent} from '../../steps/confirmation/confirmation.component';
import {PncPsalesConfirmationStateManager} from '../../state-managers/confirmation/confirmation-state-manager.service';
import {PncPsalesTechnicalAccountingComponent} from '../../steps/technical-accounting/technical-accounting.component';
import {
  PncPsalesTechAccountingStateManager
} from '../../state-managers/technical-accounting/tech-accounting-state-manager.service';
import {PncDomainService} from '../http/pnc-domain.service';
import {PncPsalesMovementsStateManager} from "../../state-managers/movements/movements-state-manager.service";
import {PncPsalesMovementsComponent} from "../../steps/movements/movements.component";
import {
  PncPsalesSupplementaryTechAccTransactionComponent
} from '../../steps/supplementary-tech-acc-transaction/supplementary-tech-acc-transaction.component';
import {
  PncPsalesSupplementaryTechAccStateManager
} from '../../state-managers/technical-accounting/supplementary-tech-acc-state-manager.service';

export interface PncPsalesPageStep {
  routeName: string;
  component: any;
  manager: any;
  deps?: any[];
}

export const RGI_PNC_POSTSALES_STEPS: { [key: string]: PncPsalesPageStep } = {
  REQUIRED_DATA: {
    routeName: ROUTES_REQUIRED_DATA,
    component: PncPsalesRequiredDataComponent,
    manager: PncPsalesRequiredDataStateManager,
    deps: []
  },
  SUMMARY: {
    routeName: ROUTES_SUMMARY,
    component: PncPsalesSummaryComponent,
    manager: PncPsalesSummaryStateManager,
    deps: [ModalService, RgiRxTranslationService]
  },
  CONFIRMATION: {
    routeName: ROUTES_CONFIRMATION,
    component: PncPsalesConfirmationComponent,
    manager: PncPsalesConfirmationStateManager,
    deps: [PncDomainService, ModalService]
  },
  TECHNICAL_ACCOUNTING: {
    routeName: ROUTES_TECHNICAL_ACCOUNTING,
    component: PncPsalesTechnicalAccountingComponent,
    manager: PncPsalesTechAccountingStateManager,
    deps: [PncDomainService]
  },
  MOVEMENTS: {
    routeName: ROUTES_MOVEMENTS,
    component: PncPsalesMovementsComponent,
    manager: PncPsalesMovementsStateManager,
    deps: [PncDomainService, ModalService, RgiRxTranslationService]
  },
  SUPPLEMENTARY_TECH_ACC: {
    routeName: ROUTES_SUPPLEMENTARY_TECH_ACC,
    component: PncPsalesSupplementaryTechAccTransactionComponent,
    manager: PncPsalesSupplementaryTechAccStateManager,
    deps: [PncDomainService]
  }
};
