import { TypeIdentifier } from "../model/models";


export class ObjectUtil {

    public static convertToIdentifier(id: string): TypeIdentifier {
        return Number(id);
    }

    public static isEmptyArray(val: any[]): boolean {
        return !val || !val.length 
    }
    public static isEmptyString(val: string): boolean {
        return !val || !val.trim().length; 
    }

    public static getJoined(val: string[], separator = ' '): string {
        let result = ' ';
        if (!this.isEmptyArray(val)) {
            result = val.join(separator);
        }

        return result;
    }

   
}
