<!-- 
    <span class="rgifont" [class.rgi-plus]="!panelOpened" [class.rgi-minus]="panelOpened"></span>
 -->

<span attr.data-qa="panel-complete-{{panelId}}" class="icon rgifont"
    [ngClass]="{'rgi-survey_ok green-color': contentComplete, 'rgi-survey_ko red-color': !contentComplete}"></span>

<span data-qa="panel-button" class="rgifont"></span>
<span class="panel-name">{{title}}</span>

<button type="button" class="btn btn-flat btn-sm pull-right" [disabled]="!modifyEnabled" (click)="modify($event)"
    *ngIf="modifyVisible">
    {{ '_CLAIMS_COMMON_._EDIT_' | translate }} <span class="rgifont rgi-pencil"></span>
</button>