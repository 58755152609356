<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" id="dates" [errors]="errors" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step *ngIf="isOperationRoleStepPresent" id="operationRoles" label="lpc_roles_variation" [errors]="errors" fieldId="operationRoles" (next)="onNext($event)">
    <lpc-roles-step-wrapper
      [active]="isActiveStep('operationRoles')"
      [roles]="operationRoles"
      [operationCode]="session.operation"
      [roleDefinition]="operationRolesAdmitted"
      [enableDelegateCheckbox]="enableRoleCheckbox('delegate')"
      [enableLegalGuardianCheckbox]="enableRoleCheckbox('legalGuardian')"
      [enableThirdPayerCheckbox]="enableRoleCheckbox('thirdPayer')"
      (roleEvent)="handleRolesEvent($event)">
    </lpc-roles-step-wrapper>
  </lpc-step>

  <lpc-step id="questsFin" [hidden]="!validQuestsCode.get('FIN')" [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')" fieldId="questsFin"
            label="lpc_financial_questionnaire_step" (next)="onNext($event)">
      <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                         [questionnairesCode]="getQuestMap('FIN')" [type]="'FIN'"
                         [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                         (loaderQuest)="loaderQuestManager($event)"
                         (loaded)="countQuest($event)"
                         #financialQuestionnaire></lpc-questionnaire>
  </lpc-step>


  <lpc-step id="factors" *ngIf="hasProductFactor" label="lpc_PolicyData" [errors]="errors" fieldId="factors"
    (next)="onNext($event)">
    <div formGroupName="factors" class="row">
      <lpc-factor-adapter #factorAdapter (changeValEmitter)="updateProductFactors($event)"
        [form]="formGroup.controls['factors']" [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="assetFactors" *ngIf="hasAssetFactor" label="lpc_asset_factors" [errors]="errors" fieldId="assetFactors"
    (next)="onNext($event)">
    <div formGroupName="assetFactors" class="row">
      <lpc-factor-adapter #assetFactorAdapter (changeValEmitter)="updateAssetFactors($event)"
        [form]="formGroup.controls['assetFactors']" [factors]="listAssetFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="quotation" [enableAuth]="isAuth" label="lpc_quotation_step" [errors]="errors" fieldId="quotation"
    (next)="onNext($event)" [disableNext]="disableNext">
    <lpc-quotation-control [disableQuote]="disableQuote" [showSinglePremium]="showSinglePremium" [disableDetails]="disableDetails" [enableProv]="enableProv" (quote)="recalculate(false)"
      [quotationDefinition]="quotationDefinition" (openProvModal)="openProvModal($event)"
      (openQuotationModal)="openQuotationModal($event)" (openWarrantiesModal)="openWarrantiesModal($event)">
    </lpc-quotation-control>
    <div class="row">
      <lpc-unit-table
        *ngIf="!!risksForUnits?.length"
        formControlName="risks"
        [investedAmountOnQuotation]="getTotalAmount(quotationDefinition)"
        [risks]="risksForUnits"
        [showInsuredAmount]="false"
        [showSinglePremium]="showSinglePremium"
        [openSelectedUnits]="false"
        (updateUnitTable)="updateUnit($event)">
      </lpc-unit-table>
    </div>
  </lpc-step>

  <lpc-step *ngIf="showInvestment"
    id="investmentProfiles" label="lpc_Investment_profiles" fieldId="investmentProfiles"
    [jump]="skipInvestmentProfiles" [nextVisible]="!formGroup.get('investmentProfiles').invalid"
    (next)="onNext($event)">
    <lpc-investment-step-adapter *ngIf="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
      [currentStep]="isActiveStep('investmentProfiles')"
      [activeStep]="isActiveStep('investmentProfiles') || isAfterId('investmentProfiles')"
      [customFormGroup]="formGroup"
      [definition]="investmentProfiles"
      [viewOnly]="skipInvestmentProfiles"
      [totalAmount]="totalAmountToInvest"
      [config]="getInvestmentProfileConfigurations"
      (volatilityEvent)="handleVolatilityEvent($event)">
    </lpc-investment-step-adapter>
  </lpc-step>

  <lpc-step *ngIf="showInvestment" id="investmentFunds" [jump]="skipInvestmentFunds && isPaymentVisibile()"
    label="lpc_investment_funds" [nextVisible]="!formGroup.get('investmentFunds').invalid && (!isWithVolatility() || !!volatility)" fieldId="investmentFunds"
    (next)="onNext($event, !showSectionQuestAfterInit)">
    <lpc-investment-step-adapter *ngIf="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
      [currentStep]="isActiveStep('investmentFunds')"
      [activeStep]="isActiveStep('investmentFunds') || isAfterId('investmentFunds')"
      [customFormGroup]="formGroup"
      [definition]="investmentProfiles"
      [viewOnly]="skipInvestmentFunds"
      [totalAmount]="totalAmountToInvest"
      [(volatility)]="volatility"
      [config]="getInvestmentFundConfigurations"
      (volatilityEvent)="handleVolatilityEvent($event)">
    </lpc-investment-step-adapter>
  </lpc-step>

  <lpc-step *ngIf="isPaymentVisibile()" id="payment" [enableAuth]="isAuth" label="lpc_payment_data" [errors]="errors" fieldId="payment"
    (next)="onNext($event, !showSectionQuestAfterInit)" (confirmAccept)="onNext($event)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-payment-step
      [paymentTypeDefinitions]="paymentTypes"
      [required]="true"
      [summary]="isAfterId('payment')"
      formControlName="payment">
    </lpc-payment-step>
  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" [enableAuth]="isAuth" id="quests" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')"
    fieldId="quests" [errors]="errors" label="lpc_avc_questionnaire"
            (confirmAccept)="onNext($event)" [isConfirmAndAccept]="isConfirmAndAccept"
            (next)="onNext($event, true)">
      <lpc-questionnaire
                         [active]="!isAfterId('quests')" [key]="composeKey(key, 'quests')"
                         [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                         [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                         [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                         (loaderQuest)="loaderQuestManager($event)"
                         (loaded)="countQuest($event)"
                         #avcQuestionnaire>
      </lpc-questionnaire>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length"
        id="warnings"
        label="lpc_warnings"
        [isConfirmAndAccept]="isConfirmAndAccept"
        (confirmAccept)="publish(true)"
        (next)="publish()"
        [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'">
      <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
      <lpc-error-messages [errors]="errors" [fieldType]="'tools'"></lpc-error-messages>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="lpc_confirm_variation_step" [errors]="errors" fieldId="documents" id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false"
      >
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>
