import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {RoutableComponent} from '../routable-component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EventNotificator} from '../event-notificator';
import {PassProductsService} from '../pass-products.service';
import {DobValidator} from '../dob.validator';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {MagazinesService} from './magazines-service';
import {ValidateGenericEntity} from '../start-card/generic-entity.validator';
import {VehicleProperties} from '../models/domain-models/vehicle-properties';
import {ApiSetup} from '../models/api-models/api-setup';
import {VariablesService} from '../variables/variables-service';
import {StateService} from '../state.service';
import {State} from '../models/state';
import {ProposalService} from '../proposal.service';
import {ApiVehicleStaticData} from '../models/api-models/api-vehicle-static-data';
import {Subscription} from 'rxjs';
import {Message} from '../models/message';
import {CommonService} from '../common.service';
import {ApiContract} from '../models/api-models/api-contract';
import {ApiVehicle} from '../models/api-models/api-vehicle';
import {Action} from '../models/action';
import {ChangeVehicleModalComponent} from '../change-vehicle-modal/change-vehicle-modal.component';
import {CustomModalService} from '../custom-modal.service';
import {ApiDisabledComponents} from '../models/api-models/api-disabled-components';
import {MagazineDataFilter} from '../models/domain-models/magazine/magazine-data-filter';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'mic-vehicle',
  templateUrl: './vehicle-data.component.html',
  styleUrls: ['./vehicle-data.component.scss']
})

export class VehicleDataComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator, AfterViewInit {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() assetDataCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() hideAll: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateAssetData: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() vehicleDataOnInitValidation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() vehicleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() hasSystemIntegration: boolean = false;

  @ViewChild('productAssetModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) productAssetModalViewContainerRef: ViewContainerRef;

  assetForm: UntypedFormGroup;
  validationMessages: Message[] = [];
  submitted = false;
  contract: ApiContract;
  vehicle: ApiVehicle;
  staticData: ApiVehicleStaticData;
  products: Array<string>;
  assetTypes: Array<GenericEntity>;
  vehicleClasses: Array<GenericEntity>;
  usages: Array<GenericEntity>;
  brands: Array<GenericEntity>;
  models: Array<GenericEntity>;
  setups: Array<ApiSetup> = new Array<ApiSetup>();
  extensions: Array<GenericEntity>;
  fuelTypes = '';
  advancedFilters: MagazineDataFilter[] = [];
  isRegistrationDateRequired = true;
  extensionsListMode = false;
  brandsListMode = true;
  modelsListMode = true;
  setupsListMode = true;
  vehicleProperties: VehicleProperties;
  adjustInsuredValue;
  enabled = true;
  registrationDateBeforeJuly2006 = false;
  editModeEnabled = false;
  assetDataCompletenessErrors = false;

  assetsInitialized = false;
  vehicleInitialized = false;
  classesInitialized = false;
  usesInitialized = false;
  extensionsInitialized = false;
  brandsInitialized = false;
  modelsInitialized = false;
  setupsInitialized = false;
  updatableInsuredValue;
  State = State;
  areaCode = 'VEHICLE_DATA';

  outOfMagazine = false;
  enableOutOfMagazineCheckbox = true;

  insValueEnabledField = true;
  outOfBoxEnabledField = true;

  private subscriptions: Subscription = new Subscription();
  isPlateChanged: boolean;
  showBtnChangeVehicle = false;
  constructor(
    public formBuilder: UntypedFormBuilder,
    public passProductsService: PassProductsService,
    public proposalService: ProposalService,
    public magazineService: MagazinesService,
    public variablesService: VariablesService,
    public commonService: CommonService,
    public stateService: StateService,
    protected customModalService: CustomModalService,
    protected translate: TranslateService,

    @Inject('SHOW_CHANGE_VEHICLE_ASSET_PAGE') protected showChangeVehicleAssetPage: boolean) {
  }

  ngOnInit(): void {
    this.contract = this.proposalService.getApiContract();
    this.outOfMagazine = this.contract.isbValueOutsideMagazine;

    this.initializeAsset();
    this.initializeVehicleStaticData();
    this.getFuelTypes();
    const regDate = this.staticData.registrationDate;

    const product = this.getProduct();
    const assetType = this.getAssetType();
    this.assetForm = this.formBuilder.group(
      {
        product: [(product.extendedDescription ? product.extendedDescription : product.description), Validators.required],
        assetType: [assetType.description, Validators.required],
        vehicleClass: [this.staticData.vehicleClass, [Validators.required, ValidateGenericEntity]],
        usage: [this.staticData.vehicleUse, [Validators.required, ValidateGenericEntity]],
        extension: [this.staticData.extension, Validators.required],
        extensionDescription: [(this.staticData.extension) ? this.staticData.extension.description : ''],
        licensePlate: [(this.vehicle.plate) ? this.vehicle.plate.plateNumber : ''],
        plateType: [(this.vehicle.plate?.plateType) ? this.vehicle.plate.plateType.description : ''],
        registrationDate: [regDate ? new Date(regDate) : undefined, Validators.required],
        frame: [this.staticData.chassis?.description, Validators.pattern('^(|[a-zA-Z0-9]{1,17})$')],
        engineNumber: [this.staticData.engineNumber],
        brand: [this.staticData.brand, [Validators.required, ValidateGenericEntity]],
        brandDescription: [(this.staticData.brand) ? this.staticData.brand.description : '', Validators.required],
        model: [this.staticData.model, [Validators.required, ValidateGenericEntity]],
        modelDescription: [(this.staticData.model) ? this.staticData.model.description : '', Validators.required],
        setup: [this.staticData.setup, [Validators.required, ValidateGenericEntity]],
        setupDescription: [(this.staticData.setup) ? this.staticData.setup.description : '', Validators.required],
      }, {
        validator: DobValidator('registrationDate')
      }
    );

    this.initializeAdvancedFilters();

    this.assetForm.get('product').disable();
    this.assetForm.get('assetType').disable();
    this.assetForm.get('plateType').disable();
    this.assetForm.get('licensePlate').disable();
    if(regDate && this.hasSystemIntegration){
      this.assetForm.get('registrationDate').disable();
    }
    if(this.staticData.chassis?.fromExternalService){
      this.assetForm.get('frame').disable();
    }
    if (this.staticData.clusterInherit) {
      // TODO manage the case ANIA doesn't recover class or use
      // this.assetForm.get('vehicleClass').disable();
      // this.assetForm.get('usage').disable();
    }

    if (this.proposalService.getVehicleProperties()) {
      this.vehicleProperties = this.proposalService.getVehicleProperties();
      this.adjustInsuredValue = this.proposalService.getApiContract().adjustInsuredValue;
    } else {
      this.proposalService.retrieveVehicleProperties(this.proposalService.getContractId()).subscribe(
        (data) => {
          this.vehicleProperties = data;

          if (this.vehicleProperties.engineNumberMandatory) {
            this.assetForm.controls.engineNumber.setValidators([Validators.required]);
          }
          this.registrationDateBeforeJuly2006 = this.vehicleProperties.registrationDateJuly
            && this.assetForm.controls.registrationDate.value
            && this.assetForm.controls.registrationDate.value < new Date(2006, 7, 14);

          if (this.registrationDateBeforeJuly2006) {
            this.assetForm.controls.frame.setValidators([Validators.required]);
          }
          this.adjustInsuredValue = this.proposalService.getApiContract().adjustInsuredValue;
          this.updatableInsuredValue = this.proposalService.getApiContract().updatableInsuredValue;
        }
      );
    }

    if (this.proposalService.existAssetDataCompletenessErrors()) {
      this.assetDataCompletenessErrors = true;
      this.editModeEnabled = true;

      this.validationMessages.length = 0;
      this.submitted = true;
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Asset Data not complete')));
      this.hideAll.emit(true);

    } else {
      this.assetDataCompleted.emit(true);
      this.hideAll.emit(false);
      this.assetDataCompletenessErrors = false;
    }

    const registrationDateValueChangesSubscription = this.assetForm.controls.registrationDate.valueChanges.subscribe(value => {
      if (this.vehicleProperties) {
        this.registrationDateBeforeJuly2006 = this.vehicleProperties.registrationDateJuly
          && this.assetForm.controls.registrationDate.value
          && this.assetForm.controls.registrationDate.value < new Date(2006, 7, 14);
      }
      this.onBrandChange();
      // this.reInitializeSetups();
    });
    this.subscriptions.add(registrationDateValueChangesSubscription);

    const assetFormValueChangesSubscription = this.assetForm.valueChanges.subscribe((val) => {
      if (this.assetsInitialized && this.vehicleInitialized && this.classesInitialized
        && this.usesInitialized && this.extensionsInitialized && this.modelsInitialized
        && this.brandsInitialized && this.setupsInitialized && !this.isPlateChanged) {
        this.editModeEnabled = true;

        this.hideAll.emit(true);
        this.validateForm();
      }
    });
    this.subscriptions.add(assetFormValueChangesSubscription);

    if (this.stateService.getCurrentState() === State.EDIT_ASSET_DATA_ALTERNATIVE_PLATE) {
      this.hideAll.emit(true);
      this.editModeEnabled = true;
    }

    if (this.assetForm.invalid) {
      this.vehicleDataOnInitValidation.emit(false);
    } else {
      this.vehicleDataOnInitValidation.emit(true);
    }

    this.showBtnChangeVehicle = this.showChangeVehicleAssetPage &&
      (this.proposalService.isFieldEnabled(ApiDisabledComponents.SECTION_VEHICLE_DATA)
        || this.proposalService.isFieldEnabled(ApiDisabledComponents.SECTION_VEHICLE_DATA) === null );

  }

  getFuelTypes() {
    this.commonService.getEnumValues('FUELTYPES', this.proposalService.getContractId()).subscribe(data => {
      this.fuelTypes = data;
    }, err => {
      this.fuelTypes = null;
    });
  }

  getValues(){
    this.commonService.getValues( this.proposalService.getContractId()).subscribe(data => {
      console.log(data);
    });
  }

  onVehicleClassChange() {
    const assetTypeCode = this.getAssetType().code;
    const vehicleClassCode = this.assetForm.controls.vehicleClass.value.code;
    this.assetForm.patchValue({
      usage: null
    });

    this.initializeUses(assetTypeCode, vehicleClassCode);
  }

  onVehicleUsageChange() {
    const usageCode = this.assetForm.controls.usage.value.code;
    this.initializeExtensions(usageCode);
    this.initializeBrands();
  }

  onBrandChange() {
    if (this.assetForm.status !== 'DISABLED') {
      this.assetForm.patchValue({
        model: '', setup: '',
        modelDescription: '', setupDescription: ''
      });
      this.staticData.model = new GenericEntity(null, null, null);
      this.staticData.setup = new ApiSetup(null, null, null, null, null, null);

      this.setupsListMode = false;
      this.assetForm.patchValue({
        brandDescription: this.assetForm.controls.brand.value.description
      });

      const brandCode = this.assetForm.controls.brand.value.code;
      this.initializeModels(brandCode);
    }
  }

  onModelChange() {
    this.assetForm.patchValue({
      modelDescription: this.assetForm.controls.model.value.description,
      setup: ''
    });
    const brandCode = this.assetForm.controls.brand.value.code;
    const modelCode = this.assetForm.controls.model.value.code;
    const fuelType = this.assetForm.controls.fuel.value.code;
    const areFilterFieldsValid = this.checkFilterFieldsValidity();

    let registrationDate = this.assetForm.controls.registrationDate.value;
    const isRegistrationDateValid = this.isRegistrationDateRequired ? registrationDate : true;

    if (!(this.assetForm.controls.registrationDate.valid || this.assetForm.controls.registrationDate.disabled) || !isRegistrationDateValid || !areFilterFieldsValid) {
      if (!registrationDate) {
        this.assetForm.get('registrationDate').markAsDirty();
      }
      if (!fuelType) {
        this.assetForm.get('fuel').markAsDirty();
      }
      return;
    }
    let registrationMonth = null;
    let registrationYear = null;

    if (registrationDate && !(registrationDate instanceof Date)) {
      registrationDate = new Date(registrationDate);
    }
    if(registrationDate){
      registrationMonth = registrationDate.getMonth() + 1;
      registrationYear = registrationDate.getFullYear();
    }

    const vehicleStaticData = this.proposalService.getApiContract().vehicle.vehicleStaticData;

    if (vehicleStaticData) {
      vehicleStaticData.approvalCode = '';
      vehicleStaticData.vehicleCodes = [];
    }
    this.staticData.approvalCode = '';
    this.staticData.vehicleCodes = [];
    this.initializeSetups(fuelType, null, null, brandCode, modelCode, registrationMonth, registrationYear, '', false, []);
    this.getAdvancedFilters(brandCode,modelCode,registrationMonth,registrationYear);
  }

  assetModified() {
    // per gestione caso fuori rivista (es: camper)
    this.editModeEnabled = true;
    this.hideAll.emit(true);
  }

  reInitializeSetups() {

    if (this.outOfMagazine) {
      this.modelsListMode = false;
      this.brandsListMode = false;
      this.setupsListMode = false;
    }

    this.assetModified();

    const fuelType = this.assetForm.controls.fuel?.value.code;
    const doors =  this.assetForm.controls.doors?.value.description;
    const power = this.assetForm.controls.power?.value.description;
    const brandCode = this.assetForm.controls.brand.value.code;
    const modelCode = this.assetForm.controls.model.value.code;
    let registrationDate = this.assetForm.controls.registrationDate.value;
    const areFilterFieldsValid = this.checkFilterFieldsValidity();
    let isRegistrationDateValid = this.isRegistrationDateRequired ? registrationDate : true;

    if (brandCode && modelCode && isRegistrationDateValid && areFilterFieldsValid) {

      let registrationMonth = null;
      let registrationYear = null;

      if (registrationDate && !(registrationDate instanceof Date)) {
        registrationDate = new Date(registrationDate);
      }
      if(registrationDate){
        registrationMonth = registrationDate.getMonth() + 1;
        registrationYear = registrationDate.getFullYear();
      }

      const vehicle = this.proposalService.getApiContract().vehicle;
      let approvalCode = '';
      let vehicleCodes = [];

      if (vehicle && vehicle.vehicleStaticData && vehicle.vehicleStaticData.approvalCode && vehicle.vehicleStaticData.vehicleCodes) {
        if (!this.isFuelChanged()) {
          approvalCode = String(vehicle.vehicleStaticData.approvalCode);
          vehicleCodes = vehicle.vehicleStaticData.vehicleCodes;
        } else {
          vehicle.vehicleStaticData.approvalCode = '';
          this.staticData.approvalCode = '';
          vehicle.vehicleStaticData.vehicleCodes = [];
          this.staticData.vehicleCodes = [];
        }
      }

      this.initializeSetups(fuelType, doors, power, brandCode, modelCode, registrationMonth, registrationYear, approvalCode, false,
        vehicleCodes);
    }
  }

  isFuelChanged() {
    const vehicle = this.proposalService.getApiContract().vehicle;
    let isFuelChanged = false;
    if (vehicle && vehicle.vehicleStaticData && vehicle.vehicleStaticData.fuel) {
      isFuelChanged = vehicle.vehicleStaticData.fuel.code !== this.assetForm.controls.fuel.value.code;
    }
    return isFuelChanged;
  }

  onSubmit(event) {
    this.submitted = true;
    this.validateForm();
    this.setFieldsDirty();

    this.assetForm.updateValueAndValidity();

    if (this.assetForm.valid) {
      this.validationMessages.length = 0;

      this.updateAsset();
      this.updateContract();
    }
  }

  onClear() {
    this.submitted = false;
    this.assetForm.reset();
  }

  updateInsuredValue() {
    if (this.updatableInsuredValue && this.insValueEnabledField) {
      this.adjustInsuredValue = !this.adjustInsuredValue;
    }
  }

  compareGenericEntities(ent1: any, ent2: any): boolean {
    return ent1 && ent2 ? ent1.code === ent2.code : ent1 === ent2;
  }

  genericEntitiesTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  setupsTrackByFn(index, setup: ApiSetup) {
    return setup.code;
  }

  valuesOutOfMagazine() {
    if (this.enableOutOfMagazineCheckbox && this.outOfBoxEnabledField) {
      this.outOfMagazine = !this.outOfMagazine;
      if (this.outOfMagazine) {
        this.brandsListMode = false;
        this.modelsListMode = false;
        this.setupsListMode = false;
        this.adjustInsuredValue = false;
      } else {
        this.brandsListMode = true;
        this.modelsListMode = true;
        this.setupsListMode = true;
        this.adjustInsuredValue = true;
      }
      this.manageFilterFields();
    }
  }

  onSetupChange() {
    const setupValue = this.assetForm.controls.setup.value;
    this.assetForm.patchValue({
      setupDescription: setupValue.description
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  manageSetupField() {
    const isSetupEditable = this.proposalService.isFieldEnabled('setupDescription');
    if (isSetupEditable === null) {
      return;
    }
    if (this.setupsListMode) {
      if (isSetupEditable) {
        this.assetForm.get('setup').enable();
      } else {
        this.assetForm.get('setup').disable();
      }
    } else {
      if (isSetupEditable) {
        this.assetForm.get('setupDescription').enable();
      } else {
        this.assetForm.get('setupDescription').disable();
      }
    }
  }

  ngAfterViewInit() {
    const outOfMagazine = this.proposalService.isFieldEnabled('outOfMagazine');
    this.outOfBoxEnabledField = outOfMagazine === null ? this.enableOutOfMagazineCheckbox : outOfMagazine;

    const updatableInsValue = this.proposalService.isFieldEnabled('updatableInsValue');
    this.insValueEnabledField = updatableInsValue === null ? this.updatableInsuredValue : updatableInsValue;

    this.manageSetupField();
  }

  private initializeAsset() {
    if (!this.proposalService.getAsset()) {
      this.proposalService.initializeAsset();
    }
    this.vehicle = this.proposalService.getAsset();

    if (!this.vehicle.vehicleStaticData) {
      this.proposalService.initializeStaticData();
    }
    this.staticData = this.vehicle.vehicleStaticData;

    if (!this.staticData.vehicleClass) {
      this.staticData.vehicleClass = new GenericEntity('', '', '');
    }
    if (!this.staticData.vehicleUse) {
      this.staticData.vehicleUse = new GenericEntity('', '', '');
    }
    if (!this.staticData.extension) {
      this.staticData.extension = new GenericEntity('', '', '');
    }
    if (!this.staticData.fuel) {
      this.staticData.fuel = new GenericEntity('', '', '');
    }
    if (!this.staticData.brand) {
      this.staticData.brand = new GenericEntity('', '', '');
    }
    if (!this.staticData.model) {
      this.staticData.model = new GenericEntity('', '', '');
    }
    if (!this.staticData.doors) {
      this.staticData.doors = new GenericEntity('', '', '');
    }
    if (!this.staticData.power) {
      this.staticData.power = new GenericEntity('', '', '');
    }

    this.assetsInitialized = true;
    this.vehicleInitialized = true;
  }

  private initializeVehicleStaticData() {
    const assetType = this.getAssetType();

    this.initializeClasses(assetType.code);
    if (this.vehicle && this.staticData.vehicleClass) {
      this.initializeUses(assetType.code, this.staticData.vehicleClass.code);
      this.initializeExtensions(this.staticData.vehicleUse.code);
    }

    this.initializeBrands();
  }

  private initializeBrands() {
    let classCode = this.assetForm?.controls?.vehicleClass?.value?.code ?
      this.assetForm?.controls?.vehicleClass?.value?.code : this.staticData.vehicleClass.code;

    this.magazineService.getBrands(this.vehicle.magazineType, classCode)
      .subscribe((data: any) => {
        this.brands = data;
        if (!this.brands || this.brands.length < 1) {
          this.outOfMagazine = true;
          this.brandsListMode = false;
          this.modelsListMode = false;
          this.setupsListMode = false;
          this.enableOutOfMagazineCheckbox = false;
        } else {
          this.outOfMagazine = false;
          this.brandsListMode = true;
          this.modelsListMode = true;
          this.setupsListMode = true;
          if (this.brands.length === 1) {
            this.assetForm.patchValue({
              brand: this.brands[0]
            });
            this.onBrandChange();
          } else {
            const currentBrandCode = this.assetForm.get('brand').value?.code;
            if (this.brands.some(brand => brand.code === currentBrandCode)) {
              this.initializeModels(currentBrandCode);
            }
          }
        }
        this.brandsInitialized = true;

        if (this.outOfMagazine) {
          this.modelsListMode = false;
          this.brandsListMode = false;
          this.setupsListMode = false;
        }
      },
      err => {
        this.brandsListMode = false;
        this.modelsListMode = false;
        this.setupsListMode = false;
        this.brandsInitialized = true;
      });
  }

  private initializeClasses(assetTypeCode: string) {
    if (assetTypeCode) {
      this.passProductsService.getAvailableClasses(assetTypeCode).subscribe(
        x => {
          this.vehicleClasses = x;
          if (this.vehicleClasses &&
            this.vehicleClasses.length === 1) {
            this.assetForm.patchValue({
              vehicleClass: this.vehicleClasses[0]
            });

            // only if is different with the setted class
            const vehicleClassCode = this.assetForm.controls.vehicleClass.value.code;
            // if (this.staticData.vehicleClass.code && this.staticData.vehicleClass.code !== vehicleClassCode) {
            this.initializeUses(assetTypeCode, vehicleClassCode);
            // }
          }
          this.classesInitialized = true;
        },
        err => {
          this.classesInitialized = true;
        }
      );
    }
  }

  private initializeUses(assetTypeCode: string, vehicleClassCode: string) {
    if (assetTypeCode && vehicleClassCode) {
      this.passProductsService.getAvailableUses(assetTypeCode, vehicleClassCode).subscribe(
        x => {
          this.usages = x;
          if (this.usages &&
            this.usages.length === 1) {
            this.assetForm.patchValue({
              usage: this.usages[0]
            });
            this.onVehicleUsageChange();
          }
          this.usesInitialized = true;
        },
        err => {
          this.usesInitialized = true;
        }
      );
    }
  }

  private initializeExtensions(usageCode: string) {
    if (usageCode) {
      let vehicleClassCode;
      if (this.assetForm?.controls?.vehicleClass) {
        vehicleClassCode = this.assetForm.controls.vehicleClass.value.code;
      }

      const contractId = this.proposalService.getContractId();

      this.passProductsService.getAvailableExtensions(contractId, vehicleClassCode, usageCode).subscribe(
        data => {
          this.extensions = data;
          if (!this.extensions || this.extensions.length < 1) {
            this.extensionsListMode = false;
          } else {
            this.extensionsListMode = true;
          }
          this.extensionsInitialized = true;
        },
        err => {
          this.extensionsListMode = false;
          this.extensionsInitialized = true;
        }
      );
    }
  }

  private initializeModels(brandCode: string) {
    if (brandCode) {
      const registrationYear = this.getRegistrationYear();
      if (!registrationYear && this.isRegistrationDateRequired) {
        return;
      }
      let classCode = this.assetForm?.controls?.vehicleClass?.value?.code ?
      this.assetForm?.controls?.vehicleClass?.value?.code : this.staticData.vehicleClass.code;

      this.magazineService.getModels(this.vehicle.magazineType, brandCode, registrationYear, classCode).subscribe(
        (data: any) => {
          this.models = data;
          this.setupsListMode = false;
          if (!this.models || this.models.length < 1) {
            this.modelsListMode = false;
          } else {
            this.modelsListMode = true;
          }

          if (this.models && this.models.length === 1) {
            this.assetForm.patchValue({
              model: this.models[0],
              modelDescription: this.models[0].description
            });
          }

          if (this.assetForm.controls.model.value.code && this.assetForm.controls.model.value.description !== ''
            && (this.assetForm.controls.registrationDate.value || !this.isRegistrationDateRequired)) {

            let registrationDate = this.assetForm.controls.registrationDate.value;
            let registrationMonth = null;
            let registrationYear = null;

            if (registrationDate && !(registrationDate instanceof Date)) {
              registrationDate = new Date(registrationDate);
            }
            if (registrationDate) {
              registrationMonth = registrationDate.getMonth() + 1;
              registrationYear = registrationDate.getFullYear();
            }
            this.initializeSetups(
              this.assetForm.controls.fuel?.value.code,
               this.assetForm.controls.doors?.value.description ,
              this.assetForm.controls.power?.value.description,
              brandCode, this.assetForm.controls.model.value.code,
              registrationMonth,
              registrationYear,
              this.proposalService.getApiContract().vehicle.vehicleStaticData.approvalCode,
              true, this.proposalService.getApiContract().vehicle.vehicleStaticData.vehicleCodes);
          }
          this.modelsInitialized = true;

          if (this.outOfMagazine) {
            this.modelsListMode = false;
            this.brandsListMode = false;
            this.setupsListMode = false;
          }
        }, err => {
          this.modelsListMode = false;
          this.setupsListMode = false;
          this.modelsListMode = false;
          this.modelsInitialized = true;
        });
    }
  }

  private getRegistrationYear() {
    if (this.isRegistrationDateValorized()) {
      const registrationDateFormValue = this.assetForm.controls.registrationDate.value;
      if (this.isValidDate(registrationDateFormValue)) {
        const registrationYear = this.assetForm.controls.registrationDate.value.getFullYear();
        if (String(registrationYear).length === 4) {
          return registrationYear;
        } else {
          return undefined;
        }
      } else {
        return this.getRegistrationDateFromStaticData();
      }
    } else {
      return this.getRegistrationDateFromStaticData();
    }
  }

  private getRegistrationDateFromStaticData() {
    if (this.staticData && this.staticData.registrationDate) {
      const registrationDate = new Date(this.staticData.registrationDate);
      if (registrationDate) {
        return registrationDate.getFullYear();
      }
    }
  }

  private isValidDate(date) {
    if (date instanceof Date) {
      return true;
    } else {
      const dateRegex = new RegExp(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/);
      return dateRegex.test(date);
    }
  }

  private isRegistrationDateValorized() {
    return this.assetForm && this.assetForm.controls &&
      this.assetForm.controls.registrationDate && this.assetForm.controls.registrationDate.value;
  }

  protected initializeSetups(
    fuelType: string,
    doors: string,
    power: string,
    brandCode: string,
    modelCode: string,
    registrationMonth: string,
    registrationYear: string,
    approvalCode: string,
    firstInitialization: boolean,
    vehicleCodes: string[]) {

    this.magazineService.getSetups(this.vehicle.magazineType,
      fuelType, doors, power, brandCode, modelCode, registrationMonth, registrationYear, approvalCode, vehicleCodes).subscribe(
      (data: any) => {
        if (!firstInitialization) {
          this.setupsInitialized = true;
        }
        if (!data || data.length < 1) {
          this.setupsListMode = false;
          if (firstInitialization && this.staticData.setup) {
            this.assetForm.patchValue({
              setup: '',
              setupDescription: this.staticData.setup.description
            });
          } else {
            this.assetForm.patchValue({
              setup: '',
              setupDescription: ''
            });
          }
        } else if (data.length === 1) {
          this.setups = data;
          this.setupsListMode = true;
          this.staticData.setup = data[0];
          this.assetForm.patchValue({
            setup: this.staticData.setup,
            setupDescription: this.staticData.setup.description
          });
        } else {
          this.setups = data;
          this.setupsListMode = true;
          if (firstInitialization && this.staticData.setup) {
            this.assetForm.patchValue({
              setup: this.staticData.setup,
              setupDescription: this.staticData.setup.description
            });
          } else {
            this.assetForm.patchValue({setup: '', setupDescription: ''});
          }
        }
        if (firstInitialization) {
          this.setupsInitialized = true;
        }
        if (this.outOfMagazine || this.modelsListMode || this.brandsListMode || this.setupsListMode) {
          this.enableOutOfMagazineCheckbox = true;
        } else {
          this.enableOutOfMagazineCheckbox = false;
        }

        if (this.outOfMagazine) {
          this.modelsListMode = false;
          this.brandsListMode = false;
          this.setupsListMode = false;
        }
      }, err => {
        this.setupsListMode = false;
        if (firstInitialization) {
          this.assetForm.patchValue({
            setup: '',
            setupDescription: ''
          });
        } else {
          this.assetForm.patchValue({
            setup: '',
            setupDescription: ''
          });
        }

        this.setupsInitialized = true;
        if (this.outOfMagazine || this.modelsListMode || this.brandsListMode || this.setupsListMode) {
          this.enableOutOfMagazineCheckbox = true;
        } else {
          this.enableOutOfMagazineCheckbox = false;
        }

        if (this.outOfMagazine) {
          this.modelsListMode = false;
          this.brandsListMode = false;
          this.setupsListMode = false;
        }
      });
  }

  private setFieldsDirty() {
    Object.keys(this.assetForm.controls).forEach(field => {
      this.assetForm.get(field).markAsDirty();
    });
  }

  private updateAsset() {

    this.staticData.vehicleClass.description = this.assetForm.controls.vehicleClass.value.description;
    this.staticData.vehicleClass.code = this.assetForm.controls.vehicleClass.value.code;
    this.staticData.vehicleClass.id = this.assetForm.controls.vehicleClass.value.id;

    this.staticData.vehicleUse.id = this.assetForm.controls.usage.value.id;
    this.staticData.vehicleUse.description = this.assetForm.controls.usage.value.description;
    this.staticData.vehicleUse.code = this.assetForm.controls.usage.value.code;

    if (this.stateService.getCurrentState() === State.EDIT_ASSET_DATA_STANDARD_PLATE
      || this.stateService.getCurrentState() === State.EDIT_ASSET_DATA_ALTERNATIVE_PLATE) {
      this.vehicle.plate.plateNumber = this.assetForm.controls.licensePlate.value.toUpperCase();
    }

    if (!this.staticData.extension) {
      this.staticData.extension = new GenericEntity('', '', '');
    }

    if (this.extensionsListMode && this.assetForm.controls.extension.value) {
      this.staticData.extension.description = this.assetForm.controls.extension.value.description;
      this.staticData.extension.code = this.assetForm.controls.extension.value.code;
    } else {
      this.staticData.extension.description = this.assetForm.controls.extensionDescription.value;
    }

    this.staticData.registrationDate = this.assetForm.controls.registrationDate.value
      ? new Date(this.assetForm.controls.registrationDate.value.getTime()) : null;
    if(this.staticData.chassis){
      this.staticData.chassis.description = this.assetForm.controls.frame.value;
    }
    this.staticData.engineNumber = this.assetForm.controls.engineNumber.value;

    if (!this.staticData.brand) {
      this.staticData.brand = new GenericEntity(null, null, null);
    }
    if (this.brandsListMode || (!this.brandsListMode
      && this.staticData.brand && this.assetForm.controls.brand.value
      && this.assetForm.controls.brandDescription.value
      && this.staticData.brand.description === this.assetForm.controls.brandDescription.value)) {
      this.staticData.brand = this.assetForm.controls.brand.value;
    } else {
      this.staticData.brand.id = '';
      this.staticData.brand.code = '';
      this.staticData.brand.description = this.assetForm.controls.brandDescription.value;
    }
    if (!this.staticData.model) {
      this.staticData.model = new GenericEntity(null, null, null);
    }
    if (this.modelsListMode || (!this.modelsListMode
      && this.staticData.model && this.assetForm.controls.model.value
      && this.assetForm.controls.modelDescription.value
      && this.staticData.model.description === this.assetForm.controls.modelDescription.value)) {
      this.staticData.model = this.assetForm.controls.model.value;
    } else {
      this.staticData.model.id = '';
      this.staticData.model.code = '';
      this.staticData.model.description = this.assetForm.controls.modelDescription.value;
    }
    if (!this.staticData.setup) {
      this.staticData.setup = new ApiSetup(null, null, null, null, null, null);
    }

    if (this.setupsListMode || (!this.setupsListMode
      && this.staticData.setup && this.assetForm.controls.setup.value
      && this.assetForm.controls.setupDescription.value
      && this.staticData.setup.description === this.assetForm.controls.setupDescription.value)) {
      this.staticData.setup = this.assetForm.controls.setup.value;
    } else {
      this.staticData.setup.id = '';
      this.staticData.setup.code = '';
      this.staticData.setup.description = this.assetForm.controls.setupDescription.value;
    }

    this.proposalService.setAsset(this.vehicle);
    if (!this.proposalService.getApiContract().vehicle.vehicleStaticData) {
      this.proposalService.getApiContract().vehicle.vehicleStaticData
        = new ApiVehicleStaticData(null, null, null, null,null, null, null, null, null, null, null, null, null, null, null);
    }
    this.proposalService.getApiContract().vehicle.vehicleStaticData.brand = this.staticData.brand;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.model = this.staticData.model;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.setup = this.staticData.setup;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.chassis = this.staticData.chassis;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.registrationDate = this.staticData.registrationDate;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.engineNumber = this.staticData.engineNumber;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.extension = this.staticData.extension;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.vehicleClass = this.staticData.vehicleClass;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.vehicleUse = this.staticData.vehicleUse;
    this.proposalService.getApiContract().vehicle.vehicleStaticData.clusterInherit = this.staticData.clusterInherit;

    this.saveAdvancedFiltersData();
  }

  private updateContract() {
    this.proposalService.getApiContract().adjustInsuredValue = this.adjustInsuredValue;
    if (!this.proposalService.getApiContract().vehicle.vehicleType) {
      this.proposalService.getApiContract().vehicle.vehicleType = this.proposalService.getVehicleType();
    }
    this.proposalService.getApiContract().isbValueOutsideMagazine = this.outOfMagazine;
    this.proposalService.updateContract(this.proposalService.getApiContract()).subscribe(
      (data) => {
        if (data.correctnessErrors && data.correctnessErrors.length > 0) {
          data.correctnessErrors.forEach( message => {
            this.validationMessages.push(new Message(this.areaCode, message.description));
          });
        } else {
          this.proposalService.setApiContract(data);
          this.advancedFilters.forEach(filterObj => {
            const vehicleStaticDatum = this.proposalService.getAsset().vehicleStaticData[filterObj.code];
            if(vehicleStaticDatum != null) {
              this.assetForm.get(filterObj.code).setValue(vehicleStaticDatum);
            }
          });
          this.assetDataCompleted.emit(true);
          this.hideAll.emit(false);
          this.editModeEnabled = false;
          this.assetDataCompletenessErrors = false;
          this.updateAssetData.emit(true);
          this.getVariables();
        }
      }
    );
  }

  private getVariables() {
    this.variablesService.getVariables().subscribe(
      (data) => {
        this.variablesService.setVariables(data);
      }
    );
  }

  private validateForm() {//TODO see outOfMagazine
    const controls = this.assetForm.controls;

    if (!this.brandsListMode || this.outOfMagazine) {
      this.assetForm.get('brand').clearValidators();
      this.assetForm.get('brand').setErrors(null);
      this.assetForm.get('brandDescription').setValidators([Validators.required]);
    } else {
      this.assetForm.get('brand').setValidators([Validators.required]);
      this.assetForm.get('brandDescription').clearValidators();
    }

    if (!this.modelsListMode || this.outOfMagazine) {
      this.assetForm.get('model').clearValidators();
      this.assetForm.get('model').setErrors(null);
      this.assetForm.get('modelDescription').setValidators([Validators.required]);
    } else {
      this.assetForm.get('model').setValidators([Validators.required]);
      this.assetForm.get('modelDescription').clearValidators();
    }

    if (!this.setupsListMode || this.outOfMagazine) {
      this.assetForm.get('setup').clearValidators();
      this.assetForm.get('setup').setErrors(null);
      this.assetForm.get('setupDescription').setValidators([Validators.required]);
    } else {
      this.assetForm.get('setup').setValidators([Validators.required]);
      this.assetForm.get('setupDescription').clearValidators();
    }

    Object.keys(this.assetForm.controls).forEach(field => {
      this.assetForm.get(field).markAsDirty();
    });

    this.validationMessages.length = 0;

    if (controls.product.errors?.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Product is mandatory')));
    }
    if (controls.assetType.errors?.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Asset Type is mandatory')));
    }
    if (controls.vehicleClass.errors) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Class is mandatory')));
    }
    if (controls.usage.errors) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Usage is mandatory')));
    }

    if (this.stateService.getCurrentState() === State.EDIT_ASSET_DATA_STANDARD_PLATE
      || this.stateService.getCurrentState() === State.EDIT_ASSET_DATA_ALTERNATIVE_PLATE) {
      if (controls.licensePlate.errors?.required) {
        this.validationMessages.push(new Message(this.areaCode,this.translate.instant('License Plate is mandatory')));
      }
    }

    if (controls.engineNumber.errors?.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Engine Number is mandatory')));
    }

    if (controls.frame.errors?.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Frame is mandatory')));
    }

    if (controls.registrationDate.errors?.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Registration date is mandatory')));
    }
    if (controls.registrationDate.errors
      && !controls.registrationDate.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Registration date is not valid')));
    }
    if (controls.fuel?.errors && controls.fuel?.errors.required) {
      this.validationMessages.push(new Message(this.areaCode,this.translate.instant('Fuel Type is mandatory')));
    }
    if ((this.brandsListMode && controls.brand.errors)
      || (!this.brandsListMode && controls.brandDescription.errors)) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Car Brand is mandatory')));
    }
    if ((this.modelsListMode && controls.model.errors)
      || (!this.modelsListMode && controls.modelDescription.errors)) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Car Model is mandatory')));
    }
    if ((this.setupsListMode && controls.setup.errors)
      || (!this.setupsListMode && controls.setupDescription.errors)) {
      this.validationMessages.push(new Message(this.areaCode, this.translate.instant('Car Setup is mandatory')));
    }

    this.eventPropagation.emit('layoutChanged');
  }

  private getProduct() {
    return this.contract.products[0];
  }

  private getAssetType() {
    const product = this.getProduct();
    return product.assets[0];
  }

  thisIsNotMyVehicle() {
    this.openProductAssetModal();
  }

  protected openProductAssetModal() {
    const modal = this.customModalService.openModal(this.productAssetModalViewContainerRef, ChangeVehicleModalComponent,
      this.eventPropagation, () => {
        if (modal.instance.isChangePlate) {
          this.isPlateChanged = true;
          if (this.stateService.isNotValidContract() === false) {
            this.stateService.nextState(Action.CONFIRM_BUTTON_PRESSED);
          } else {
            this.ngOnInit();
            this.editModeEnabled = false;
            this.vehicleChanged.emit(true);
          }
        } else {
          this.onProductAssetModalDestroy();
        }
      });
  }

  protected onProductAssetModalDestroy() {
    if (this.proposalService.getIsReadyToCreateNewContract()) {
      this.proposalService.setIsReadyToCreateNewContract(false);
      this.proposalService.createNewContract(this.proposalService.getContractIdCached()).subscribe(
        (contract: ApiContract) => {
          this.proposalService.setApiContract(contract);
          this.ngOnInit();
          this.editModeEnabled = false;
          this.vehicleChanged.emit(true);
        }
      );
    }
  }

  protected manageAmendmentFlow() {
    if (this.proposalService.isFieldEnabled(ApiDisabledComponents.SECTION_VEHICLE_DATA) === false) {
      this.proposalService.toggleFieldDisable(this.assetForm, null, ApiDisabledComponents.SECTION_VEHICLE_DATA);
      this.outOfBoxEnabledField = false;
      this.insValueEnabledField = false;
    }
  }

  initializeAdvancedFilters() {
    this.filterFirstInitialization();
    let isRegistrationDateValid = this.isRegistrationDateRequired ? this.staticData.registrationDate : true;

    if (this.staticData.model.code !== '' &&
      this.staticData.brand.code !== '' &&
      isRegistrationDateValid
    ) {
      const brandCode = this.staticData.brand.code;
      const modelCode = this.staticData.model.code;
      let registrationDate = this.assetForm.controls.registrationDate.value;
      let registrationMonth = null;
      let registrationYear = null;

      if (registrationDate && !(registrationDate instanceof Date)) {
        registrationDate = new Date(registrationDate);
      }
      if(registrationDate){
        registrationMonth = registrationDate.getMonth() + 1;
        registrationYear = registrationDate.getFullYear();
      }


      this.getAdvancedFilters(brandCode, modelCode, registrationMonth, registrationYear);
    }
  }

  filterFirstInitialization() {
    this.magazineService.getFilters(null, null, null, null, null, true).subscribe(
      (data: Array<MagazineDataFilter>) => {
        this.advancedFilters = data;
        this.addFilterFieldsToAssetForm();
        this.manageAmendmentFlow();
        if (data.some(field => field.mandatory === false)) {
          const modelSubscription = this.assetForm.get('model').valueChanges.subscribe(() => {
            this.manageFilterFields();
          });
          this.subscriptions.add(modelSubscription);
          /**
           * For Project Generali, the registration date is not a required value in order to perform the call to the services that fetch
           * the setups, models etc; since the advanced filters are non-mandatory only for Project Generali, i use the condition to
           * set also the requiredness of the registration date (this is not the correct approach though, this behaviour should be fixed
           * after the refactor of the NMF)
           */
          this.isRegistrationDateRequired = false;
        }
      });
  }

  getAdvancedFilters(brandCode: string, modelCode: string, registrationMonth: string, registrationYear: string) {
    this.magazineService.getFilters(this.vehicle.magazineType, brandCode, modelCode, registrationMonth, registrationYear, false).subscribe(
      (data: Array<MagazineDataFilter>) => {
        this.advancedFilters = data;
        this.advancedFilters.forEach(filterObj => {
          if (filterObj.values.length === 1) {
            this.assetForm.get(filterObj.code).setValue(filterObj.values[0]);
          }
        });
      }
    );
  }

  manageFilterFields() {
    this.advancedFilters.forEach(filterObj => {
      if (!filterObj.mandatory) {
        const field = this.assetForm.get(filterObj.code);
        field.setValue('');
        this.setFilterValidators(filterObj, !this.outOfMagazine);
        if (this.outOfMagazine === true) {
          field.enable();
        } else {
          const modelValue = this.assetForm.get('model').value.description;
          modelValue ? field.enable() : field.disable();
        }
      }
    });
  }

  addFilterFieldsToAssetForm() {
    this.advancedFilters.forEach(filterObj => {
      this.assetForm.addControl(filterObj.code,
        new UntypedFormControl({
          value: this.outOfMagazine ? this.staticData[filterObj.code].description : this.staticData[filterObj.code],
          disabled: !this.staticData.model.description && !filterObj.mandatory && !this.outOfMagazine,
        }, filterObj.mandatory ? Validators.required : null));
    });
  }

  saveAdvancedFiltersData() {
    this.advancedFilters.forEach(filterObj => {
      const value = this.assetForm.get(filterObj.code).value;
      if (!this.outOfMagazine || filterObj.code === 'fuel') {
        this.staticData[filterObj.code] = value;
      } else {
        this.staticData[filterObj.code] = new GenericEntity(value, value, value);
      }
      this.proposalService.getApiContract().vehicle.vehicleStaticData[filterObj.code] = this.staticData[filterObj.code];
    });
  }

  checkFilterFieldsValidity(): boolean {
    return this.advancedFilters.every(filterObj => {
      if (filterObj.mandatory === true) {
        return this.assetForm.get(filterObj.code).value.description !== '';
      }
      return true;
    });
  }

  setFilterValidators(filterObj: MagazineDataFilter, clearValidators: boolean) {
    const field = this.assetForm.get(filterObj.code);
    if (filterObj.code === 'doors' || filterObj.code === 'power') {
      clearValidators ? field.clearValidators() : field.setValidators(Validators.pattern("^[0-9]*$"));
    }
  }
}
