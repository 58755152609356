import {Inject, Injectable} from '@angular/core';
import {MotorProposal} from './models/domain-models/motor-proposal';
import {GenericEntity} from './models/domain-models/generic-entity';
import {ApiContract} from './models/api-models/api-contract';
import {ApiProduct} from './models/api-models/api-product';
import {LicensePlate} from './models/domain-models/license-plate';
import {ApiVehicle} from './models/api-models/api-vehicle';
import {ApiSetup} from './models/api-models/api-setup';
import {VehicleProperties} from './models/domain-models/vehicle-properties';
import {ApiVehicleStaticData} from './models/api-models/api-vehicle-static-data';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ApiAssetType} from './models/api-models/api-asset-type';
import {Warning} from './models/domain-models/warning';
import {Documents} from './documents/documents';
import {ApiContractNumber} from './models/api-models/api-contract-number';
import {AuthorizationRequest} from './proposal/authorization-request/authorization-request';
import {ExpectedDocument} from './documents/expected-documents/expected-document';
import {ApiPolicyInfo} from './models/api-models/api-policy-info';
import {ApiVoucher} from './models/api-models/api-voucher';
import {ApiOctoInstallersFilter} from './models/api-models/api-octo-installers-filter';
import {OctoInstallersResponse} from './models/api-models/api-octo-installers-response';
import {UntypedFormGroup} from '@angular/forms';
import {ApiDisabledComponents} from './models/api-models/api-disabled-components';
import {ApiProposalInfo} from './models/api-models/api-proposal-info';
import {ApiSubstitutionReasonConf} from './models/api-models/api-substitution-reason-config';
import {getDisabledComponentsFromSubstitutionConfig} from './substitution/substitution-utils';
import {PolicyContacts} from './proposal/addresses/policy-contacts';
import {MicHttpService} from './http/mic-http.service';
import {MicHttpDisableErrorInterceptorService} from './http/mic-http-disable-error-interceptor.service';
import {ApiMasterPolicyRef} from './models/api-models/api-master-policy';
import {PolicyNumberAndHolder} from './payments/payments-data';
import {ApiProposalPrint} from './models/api-models/api-proposal-print';
import {ApiDigitalSignature} from './models/api-models/api-digital-signature';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  public DISABLE_ERR_INTERCEPT_HEADER = 'X-Disable-Error-Interceptor';
  public substitution: boolean;
  public editQuote: false;
  public substReason: number;
  public substReasonCode: string;
  public subClusterCode: string;
  public subAssetCode: string;
  protected baseApiUrlV2;
  protected baseApiUrlV1;
  protected httpClient: HttpClient;
  protected motorContractEndpoint;
  protected contractEndpoint;
  protected ptfDanniEndpoint;
  protected firmaOTPBeneEndpoint;
  protected viperaBeneEndpoint;
  protected authorizationDataEndpoint;
  protected portfolioEndpoint;
  protected enumTableEndpoint;
  protected commonContractEndpoint;
  protected commonEndpoint;
  protected disabledFields: any;
  protected substitutionConfig: ApiSubstitutionReasonConf;
  protected disableCollecting: boolean;
  protected proposal: MotorProposal = MotorProposal.createEmptyProposal();
  protected policyInfo: ApiPolicyInfo;
  private proposalInfo: ApiProposalInfo;
  protected isReadyToCreateNewContract = false;
  protected quoteId: string;
  protected dateOfBirth: number;
  protected bProposal = false;
  protected issueCompleted = false;
  protected manualPolicyNumber;
  protected nodeDescription;
  protected contractChannel: Subject<ApiContract> = new Subject<ApiContract>();
  private refreshContactsSignalChannel: Subject<any> = new Subject<any>();
  private isInclusionApplication = false;
  // tslint:disable-next-line:variable-name
  private _isApplicationSubstitution = false;
  private inclusionData: ApiMasterPolicyRef;
  // tslint:disable-next-line:variable-name
  private _vehicleAlreadyChanged = false;
  digitalSignatureRequired: boolean;
  protected contractIdCached: string;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any
  ) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.httpClient = this.micHttpClient.getHttpClient();

    this.baseApiUrlV1 = environment.api.portal.host + environment.api.portal.path;
    this.motorContractEndpoint = this.baseApiUrlV2 + '/motor/contract';
    this.contractEndpoint = this.baseApiUrlV2 + '/portfolio/contract';
    this.ptfDanniEndpoint = this.baseApiUrlV1 + '/ptfdanni';
    this.firmaOTPBeneEndpoint = this.baseApiUrlV1 + '/beneapi/initfirmaotp';
    this.viperaBeneEndpoint = this.baseApiUrlV1 + '/beneapi/viperaurl';
    this.authorizationDataEndpoint = this.baseApiUrlV2 + '/portfolio/contract';
    this.portfolioEndpoint = this.baseApiUrlV2 + '/portfolio';
    this.enumTableEndpoint = this.baseApiUrlV1 + '/system/enumtable';
    this.commonEndpoint = this.baseApiUrlV2 + '/common';
    this.commonContractEndpoint = this.commonEndpoint + '/contract';
    this.disabledFields = {
      contractId: undefined,
      disabledComponents: new ApiDisabledComponents()
    };
  }

  get isSubstitution(): boolean {
    const apicontract = this.getApiContract();
    return apicontract && (this.substitution || apicontract.editSubstitution);
  }

  existTechnicalDataCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let tdDataNotComplete = [];
    if (completenessErrors) {
      tdDataNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'TD_NOT_COMPLETE';
      });
    }

    return tdDataNotComplete.length > 0;
  }

  existVehiclevariablesCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let vehicleVariablesNotComplete = [];
    if (completenessErrors) {
      vehicleVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VEHICLE_VARIABLES_NOT_COMPLETE';
      });
    }

    return vehicleVariablesNotComplete.length > 0;
  }

  existAssetAreaVariablesCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let assetAreaVariablesNotComplete = [];
    if (completenessErrors) {
      assetAreaVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VARIABLES_AREA_ASSET_NOT_COMPLETE';
      });
    }

    return assetAreaVariablesNotComplete.length > 0;
  }

  existContractAreaVariablesCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let assetAreaVariablesNotComplete = [];
    if (completenessErrors) {
      assetAreaVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VARIABLES_AREA_CONTRACT_NOT_COMPLETE';
      });
    }

    return assetAreaVariablesNotComplete.length > 0;
  }

  existPartyAreaVariablesCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let partyAreaVariablesNotComplete = [];
    if (completenessErrors) {
      partyAreaVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VARIABLES_AREA_PARTY_NOT_COMPLETE';
      });
    }

    return partyAreaVariablesNotComplete.length > 0;
  }

  existVehicleDataAreaVariablesCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let vehicleDataAreaVariablesNotComplete = [];
    if (completenessErrors) {
      vehicleDataAreaVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VARIABLES_AREA_VEHICLE_DATA_NOT_COMPLETE';
      });
    }

    return vehicleDataAreaVariablesNotComplete.length > 0;
  }

  existInsuranceStatusCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let insuranceStatusNotAvailable = [];
    if (completenessErrors) {
      insuranceStatusNotAvailable = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'INS_STAT_NOT_AVAILABLE' || val.code === 'INS_STAT_NOT_COMPLETE';
      });
    }

    return insuranceStatusNotAvailable.length > 0;
  }

  isFiscalCodeNotConsistent(): boolean {

    let fiscalCodeNotConsistentErrors: Array<GenericEntity> = [];
    if (this.getApiContract().correctnessErrors) {
      fiscalCodeNotConsistentErrors = this.getApiContract().correctnessErrors.filter((val: GenericEntity) => {
        return val.code === 'DIFFERENT_FISCAL_CODES' || val.code === 'DIFFERENT_FISCAL_CODES_DATE_BIRTH_INPUT';
      });
    }
    return fiscalCodeNotConsistentErrors.length > 0;
  }

  isPolicyATRExpiresBeyond() {
    let policyATRExpiresBeyondError: Array<GenericEntity> = [];
    if (this.getApiContract().correctnessErrors) {
      policyATRExpiresBeyondError = this.getApiContract().correctnessErrors.filter((val: GenericEntity) => {
        return val.code === 'POLICY_ATR_EXPIRES_BEYOND';
      });
    }
    return policyATRExpiresBeyondError.length > 0;
  }

  isInsuranceStatusNoLongerValid(): boolean {

    let insuranceStatusNoLongerValid: Array<GenericEntity> = [];
    if (this.getApiContract().correctnessErrors) {
      insuranceStatusNoLongerValid = this.getApiContract().correctnessErrors.filter((val: GenericEntity) => {
        return val.code === 'POLICY_EXPIRY_DATE_5_YEARS';
      });
    }
    return insuranceStatusNoLongerValid.length > 0;
  }

  existCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let assetDataNotComplete;
    let tdNotComplete;
    let vehicleVariablesNotComplete;
    let insuranceStatusNotAvailable;
    let assetAreaVariablesNotComplete;
    let partyAreaVariablesNotComplete;
    let vehicleDataAreaVariablesNotComplete;
    let insuranceStatusNotComplete;
    if (completenessErrors) {
      assetDataNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'ASSET_DATA_NOT_COMPLETE';
      });
      tdNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'TD_NOT_COMPLETE';
      });
      vehicleVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VEHICLE_VARIABLES_NOT_COMPLETE';
      });
      insuranceStatusNotAvailable = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'INS_STAT_NOT_AVAILABLE';
      });
      insuranceStatusNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'INS_STAT_NOT_COMPLETE';
      });
      assetAreaVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VARIABLES_AREA_ASSET_NOT_COMPLETE';
      });
      partyAreaVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VARIABLES_AREA_PARTY_NOT_COMPLETE';
      });
      vehicleDataAreaVariablesNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'VARIABLES_AREA_VEHICLE_DATA_NOT_COMPLETE';
      });
    }

    return ((assetDataNotComplete && assetDataNotComplete.length > 0)
      || (tdNotComplete && tdNotComplete.length > 0)
      || (vehicleVariablesNotComplete && vehicleVariablesNotComplete.length > 0)
      || (insuranceStatusNotAvailable && insuranceStatusNotAvailable.length > 0)
      || (assetAreaVariablesNotComplete && assetAreaVariablesNotComplete.length > 0)
      || (partyAreaVariablesNotComplete && partyAreaVariablesNotComplete.length > 0)
      || (vehicleDataAreaVariablesNotComplete && vehicleDataAreaVariablesNotComplete.length > 0)
      || (insuranceStatusNotComplete && insuranceStatusNotComplete.length > 0));
  }

  existAssetDataCompletenessErrors(): boolean {
    const completenessErrors: Array<GenericEntity> = this.getApiContract().completenessErrors;
    let assetDataNotComplete = [];
    if (completenessErrors) {
      assetDataNotComplete = completenessErrors.filter((val: GenericEntity) => {
        return val.code === 'ASSET_DATA_NOT_COMPLETE';
      });
    }

    return assetDataNotComplete.length > 0;
  }

  setCompletenessErrors(completenessErrors: Array<GenericEntity>) {
    this.getApiContract().completenessErrors = completenessErrors;
  }

  getApiContract() {
    return this.proposal.apiContract;
  }

  setApiContract(apiContract: ApiContract) {
    this.proposal.apiContract = apiContract;
  }

  initializeAsset() {
    const asset = new ApiVehicle(null, null, null);
    this.setAsset(asset);
    this.initializeStaticData();
  }

  initializeStaticData() {
    const asset = this.getAsset();

    asset.vehicleStaticData = new ApiVehicleStaticData(
      new GenericEntity('', '', ''),
      new GenericEntity('', '', ''),
      new GenericEntity('', '', ''), null,
      new GenericEntity('', '', ''),
      new GenericEntity('', '', ''),
      new GenericEntity('', '', ''),
      new GenericEntity('', '', ''),
      new GenericEntity('', '', ''),
      new ApiSetup(),
      null, null, null, null, null);
  }

  getAsset(): ApiVehicle {
    if (this.proposal.apiContract) {
      return this.proposal.apiContract.vehicle;
    } else {
      return null;
    }
  }

  setAsset(asset: ApiVehicle) {
    if (!this.proposal.apiContract) {
      this.proposal.apiContract = new ApiContract();
    } else {
      this.proposal.apiContract.vehicle = asset;
    }
  }

  refreshAsset() {
    if (this.proposal.apiContract) {
      this.proposal.apiContract.vehicle = null;
    }
  }

  refreshProductAndAssetType() {
    this.proposal.assetType = null;
    this.proposal.product = null;
    this.proposal.multipleProductsAssetTypes = null;
  }

  setProduct(product: GenericEntity) {
    this.proposal.product = product;
  }

  getProduct(): GenericEntity {
    return this.proposal.product;
  }

  setAssetType(assetType) {
    this.proposal.assetType = assetType;
  }

  getAssetType() {
    return this.proposal.assetType;
  }

  getContractId() {
    return this.proposal.apiContract && this.proposal.apiContract.id;
  }

  setQuoteId(quoteId) {
    this.quoteId = quoteId;
  }

  getQuoteId() {
    return this.quoteId;
  }

  setMultipleProductsAssetTypes(apiProducts: Array<ApiProduct>) {
    this.proposal.multipleProductsAssetTypes = apiProducts;
  }

  getMultipleProductsAssetTypes() {
    return this.proposal.multipleProductsAssetTypes;
  }

  refreshContractData() {
    if(this.proposal.apiContract) {
      this.setContractIdCached(this.proposal.apiContract.id);
    }
    this.quoteId = null;
    this.proposal.apiContract = null;
    this.proposal.contractId = null;
  }

  refresh() {
    this.refreshProductAndAssetType();
    this.refreshAsset();
    this.refreshContractData();
    this.setProposalState(false);
    this.dateOfBirth = null;
    this.proposal.licensePlate = null;
    this.proposal.vehicleProperties = null;
    this.issueCompleted = false;
    this.manualPolicyNumber = null;
  }

  setPlateNumber(licensePlate: LicensePlate) {
    this.proposal.licensePlate = licensePlate;
  }

  getPlateNumber(): LicensePlate {
    return this.proposal.licensePlate;
  }

  getIsReadyToCreateNewContract() {
    return this.isReadyToCreateNewContract;
  }

  setIsReadyToCreateNewContract(value: boolean) {
    this.isReadyToCreateNewContract = value;
  }

  setCompanyId(companyId) {
    this.proposal.companyId = companyId;
  }

  getCompanyId() {
    return this.proposal.companyId;
  }

  setNodeId(nodeId) {
    this.proposal.nodeId = nodeId;
  }

  getNodeId() {
    return this.proposal.nodeId;
  }

  setNodeDescription(nodeDescription) {
    this.nodeDescription = nodeDescription;
  }

  getNodeDescription() {
    return this.nodeDescription;
  }

  createNewContract(contractIdCached?: string) {
    let apiVehicle: ApiVehicle = this.getApiContract() ? this.getApiContract().vehicle : null;

    const plate: LicensePlate = this.getPlateNumber();
    if (plate) {
      if (!apiVehicle) {
        apiVehicle = new ApiVehicle(
          null, null, null,
          null, null, null, null, null, null,
          new ApiVehicleStaticData(null, null, null, null, null, null, null, null, null, null, null, '', '', null, null));
      }
      apiVehicle.plate = plate;
    }

    return this.newContract(
      this.getCompanyId(),
      this.getNodeId(),
      this.getProduct(),
      this.getAssetType(),
      null,
      apiVehicle,
      this.getDateOfBirth(),
      contractIdCached
    );
  }

  newContract(
    companyId: string,
    nodeId: string,
    product?: GenericEntity,
    asset?: ApiAssetType,
    licensePlateNumber?: string,
    vehicle?: ApiVehicle,
    dateOfBirth?: number,
    contractIdCached?: string): Observable<ApiContract> {

    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('companyId', companyId);
    requestParams = requestParams.set('nodeId', nodeId);

    if (!licensePlateNumber) {
      const contract: ApiContract = new ApiContract(
        null,
        [new ApiProduct(product.id, product.code, product.description,
          [new ApiAssetType(asset.id, asset.code, asset.description, asset.classId,
            asset.useId, asset.extendedDescription, asset.aniaVehicleTypeCode)], null)], false, false,
        vehicle,
        dateOfBirth
      );
      if(contractIdCached) {
        requestParams = requestParams.set('contractIdCached', contractIdCached);
      }
      return this.httpClient.post<ApiContract>(this.motorContractEndpoint, contract, {params: requestParams});
    } else {
      requestParams = requestParams.set('plate', licensePlateNumber);
      if (dateOfBirth) {
        requestParams = requestParams.set('dateOfBirth', String(dateOfBirth));
      }
      return this.httpClient.post<ApiContract>(this.motorContractEndpoint + '/byPlate', null, {params: requestParams});
    }
  }

  loadContract(contract: ApiContract): Observable<ApiContract> {
    return this.httpClient.post<ApiContract>(this.motorContractEndpoint, contract);
  }

  getContractInCache(contract: ApiContract): Observable<ApiContract> {
    return this.httpClient.get<ApiContract>(this.motorContractEndpoint + '/' + contract.id);
  }

  updateContract(apiContract: ApiContract): Observable<ApiContract> {
    return this.httpClient.put<ApiContract>(this.motorContractEndpoint, apiContract);
  }

  setVehicleType(vehicleType: string) {
    this.proposal.vehicleType = vehicleType;
  }

  getVehicleType() {
    return this.proposal.vehicleType;
  }

  getVehicleProperties() {
    return this.proposal.vehicleProperties;
  }

  getPolicyContacts() {
    return this.proposal.policyContacts;
  }

  setPolicyContacts(policyContacts: PolicyContacts) {
    this.proposal.policyContacts = policyContacts;
  }

  getRefreshContactsSignal() {
    return this.refreshContactsSignalChannel.asObservable();
  }

  sendRefreshContactsSignal(data) {
    this.refreshContactsSignalChannel.next(data);
  }

  updateContacts(newContacts: PolicyContacts) {
    return this.httpClient.put<PolicyContacts>(
      this.baseApiUrlV2 + '/portfolio/contract/' + this.getContractId() + '/contacts/', newContacts
    );
  }

  retrieveVehicleProperties(contractId: string) {
    return this.httpClient.get<VehicleProperties>(this.motorContractEndpoint + '/' + contractId + '/vehicle/properties');
  }

  getWarnings() {
    const contractId = this.getContractId();
    return this.httpClient.get<Array<Warning>>(this.contractEndpoint + '/' + contractId + '/warnings');
  }

  getFullWarnings() {
    const contractId = this.getContractId();
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('fullcheck', String(true));
    return this.httpClient.get<Array<Warning>>(this.contractEndpoint + '/' + contractId + '/warnings', {params: requestParams});
  }

  saveProposal() {
    const contractId = this.getContractId();
    return this.httpClient.post<ApiProposalInfo>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/proposal',
      this.proposal);
  }

  printDocuments(automatic: boolean) {
    const contractId = this.getContractId();
    const phase = automatic ? 'aut' : 'man';
    return this.httpClient.post<ApiProposalPrint>(this.baseApiUrlV2 + '/portfolio/policy/' + contractId + '/documents/'+phase, null);
  }

  retrievePolicyContacts() {
    const contractId = this.getContractId();
    return this.httpClient.get<PolicyContacts>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/contacts');
  }

  savePolicy(contractId: string, policyNumber?: string) {
    let requestParams: HttpParams = new HttpParams();
    if (policyNumber) {
      requestParams = requestParams.set('policyNumber', policyNumber);
    }

    return this.httpClient.post<ApiPolicyInfo>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/policy',
      this.proposal, {params: requestParams});
  }

  removeContract() {
    const contractId = this.getContractId();
    if (contractId) {
      this.deleteContractTmp(contractId).subscribe(data => {
      });
    }
  }

  deleteContractTmp(contractId) {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('temp', 'true');

    return this.httpClient.delete(this.contractEndpoint + '/' + contractId, {params: requestParams});
  }

  getProposalState() {
    const contractId = this.getContractId();
    return this.httpClient.get<GenericEntity>(this.contractEndpoint + '/' + contractId + '/status');
  }

  setLoadedProposal(proposal: ApiContract, isProposal: boolean = true) {
    this.setApiContract(proposal);
    this.setProposalState(isProposal);
    this.setPlateNumber(proposal.vehicle.plate);
    this.setProduct(proposal.products[0]);
    this.setCompanyId(proposal.companyId);
    this.setNodeId(proposal.nodeId);
  }

  getDateOfBirth() {
    return this.dateOfBirth;
  }

  setDateOfBirth(date) {
    this.dateOfBirth = date;
  }

  isProposal() {
    return this.bProposal;
  }

  setProposalState(bProposal) {
    this.bProposal = bProposal;
  }

  isEntitledBeforeQuotation() {
    return this.proposal.apiContract?.entitledBeforeQuotation && !this.substitution;
  }

  isIBANCheckOnIssueEnabled() {
    return this.proposal.apiContract.checkIbanOnIssue;
  }

  getPolicyDocuments() {
    const contractNumber = this.getPolicyNumber();
    const inputObject = {inputGetDocuments: {causativeCode: '000001', policyNumber: contractNumber}};
    return this.getDocuments(inputObject);
  }

  getProposalDocuments() {
    const contractNumber = this.getProposalNumber();
    const inputObject = {inputGetDocuments: {causativeCode: '000000', proposalNumber: contractNumber}};
    return this.getDocuments(inputObject);
  }

  getDocuments(inputObject: any) {
    return this.httpClient.post<Documents>(this.ptfDanniEndpoint + '/document/list', inputObject);
  }

  downloadDocument(url) {
    return this.httpClient.get<any>(this.ptfDanniEndpoint + url);
  }

  setIssueCompleted() {
    this.issueCompleted = true;
  }

  isIssueCompleted() {
    return this.issueCompleted;
  }

  setManualPolicyNumber(policyNumber) {
    this.manualPolicyNumber = policyNumber;
  }

  getManualPolicyNumber() {
    return this.manualPolicyNumber;
  }

  setContractNumber(contractNumber: ApiContractNumber) {
    this.proposal.apiContract.contractNumber = contractNumber;
  }

  getContractNumber(): ApiContractNumber {
    return this.proposal.apiContract.contractNumber;
  }

  setPolicyInfo(policyInfo: ApiPolicyInfo) {
    this.policyInfo = policyInfo;

    if (this.policyInfo.contractNumber) {
      this.setContractNumber(this.policyInfo.contractNumber);
    }
  }

  setProposalInfo(proposalInfo: ApiProposalInfo) {
    this.proposalInfo = proposalInfo;
    if (this.proposalInfo.contractNumber) {
      this.setContractNumber(this.proposalInfo.contractNumber);
    }
  }

  getPolicyInfo(): ApiPolicyInfo {
    return this.policyInfo;
  }

  getProposalInfo(): ApiProposalInfo {
    return this.proposalInfo;
  }

  setProposalNumber(propNum: string) {
    if (!this.proposal.apiContract.contractNumber) {
      this.proposal.apiContract.contractNumber = new ApiContractNumber();
    }
    this.proposal.apiContract.contractNumber.proposalNumber = propNum;
  }

  getPolicyNumber() {
    if (this.proposal.apiContract.contractNumber) {
      return this.proposal.apiContract.contractNumber.policyNumber;
    }
  }

  getProposalNumber() {
    if (this.proposal.apiContract.contractNumber) {
      return this.proposal.apiContract.contractNumber.proposalNumber;
    }
  }

  initFirmaOTPBene(idMovimento) {
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('idMovimento', idMovimento);
    return this.httpClient.get<any>(this.firmaOTPBeneEndpoint, {params: requestParams});
  }

  initVipera(filtroVipera) {
    return this.httpClient.post<any>(this.viperaBeneEndpoint, filtroVipera);
  }

  getAuthorizationData(requestType: string) {
    const contractId = this.getContractId();
    let requestParams: HttpParams = new HttpParams();
    requestParams = requestParams.set('requestType', requestType);
    return this.httpClient.get<AuthorizationRequest>(this.authorizationDataEndpoint
      + '/' + contractId + '/authorization', {params: requestParams});
  }

  updateAuthorizationData(authorizationRequest: AuthorizationRequest) {
    const contractId = this.getContractId();
    return this.httpClient.post<AuthorizationRequest>(this.authorizationDataEndpoint
      + '/' + contractId + '/authorization', authorizationRequest);
  }

  getNewContractSignal() {
    return this.contractChannel.asObservable();
  }

  sendNewContractSignal(apiContract: ApiContract) {
    this.contractChannel.next(apiContract);
  }

  retrieveExpectedDocumentsList() {
    const contractId = this.getContractId();
    return this.httpClient.get<Array<ExpectedDocument>>(this.contractEndpoint + '/' + contractId + '/expectedDocuments');
  }

  getWarningsByActionLevels() {
    const contractId = this.getContractId();
    return this.httpClient.get<Array<Warning>>(this.portfolioEndpoint + '/contract/' + contractId + '/proposalwarnings');
  }

  checkNewVoucherIsNeeded() {
    const contractId = this.getContractId();
    const httpOptions = {
      headers: new HttpHeaders().set(this.DISABLE_ERR_INTERCEPT_HEADER, 'true')
    };
    return this.httpClient.get<boolean>(this.motorContractEndpoint + '/' + contractId + '/voucher/required');
  }

  createNewVoucher() {
    const contractId = this.getContractId();
    const httpOptions = {
      headers: new HttpHeaders().set(this.DISABLE_ERR_INTERCEPT_HEADER, 'true')
    };
    return this.httpClient.post<ApiVoucher>(this.motorContractEndpoint + '/' + contractId + '/voucher', null, httpOptions);
  }

  updateVoucherData(voucher: ApiVoucher) {
    const contractId = this.getContractId();
    return this.httpClient.put<any>(this.motorContractEndpoint + '/' + contractId + '/voucher', voucher);
  }

  getVoucherData() {
    const contractId = this.getContractId();
    const httpOptions = {
      headers: new HttpHeaders().set(this.DISABLE_ERR_INTERCEPT_HEADER, 'true')
    };
    return this.httpClient.get<ApiVoucher>(this.motorContractEndpoint + '/' + contractId + '/voucher', httpOptions);
  }

  findInstallers(filter: ApiOctoInstallersFilter) {
    return this.httpClient.post<OctoInstallersResponse>(this.baseApiUrlV1 + '/octo/installers', filter);
  }

  getColorsEnum() {
    return this.httpClient.get<any>(this.enumTableEndpoint + '/OTColore');
  }

  isDocumentManagementEnabled() {
    const contractId = this.getContractId();
    return this.httpClient.get<boolean>(this.commonContractEndpoint + '/' + contractId + '/documentmanagement');
  }

  getVoucherManagementProvider() {
    const contractId = this.getContractId();
    return this.httpClient.get<number>(this.motorContractEndpoint + '/' + contractId + '/voucher/provider');
  }

  getContractDisabledFields() {
    const contractId = this.getContractId();
    if (this.disabledFields.contractId === contractId) {
      return this.disabledFields.disabledComponents;
    }
    this.disabledFields.contractId = contractId;
    this.disabledFields.disabledComponents = new ApiDisabledComponents();
    return this.httpClient.get<ApiDisabledComponents>(this.contractEndpoint + '/' + contractId + '/disabledComponents');
  }

  setDisabledFields(disabledComponents: ApiDisabledComponents) {
    this.disabledFields.contractId = this.getContractId();
    this.disabledFields.disabledComponents = disabledComponents;
  }

  toggleFieldDisable(form: UntypedFormGroup, disabledComponents?: ApiDisabledComponents, sectionKey?: string) {
    if (!disabledComponents) {
      disabledComponents = this.disabledFields.disabledComponents;
    }
    if (disabledComponents && !disabledComponents.unhandledCase && disabledComponents.components) {
      disabledComponents.components.forEach(component => {
        if (component.code === sectionKey) {
          if (!component.enabled) {
            form.disable();
          }
        }

        if (component.enabled && form.get(component.code)) {
          form.get(component.code).enable();
        } else if (!component.enabled && form.get(component.code)) {
          form.get(component.code).disable();
        }
      });
    }
  }

  isFieldEnabled(fieldCode: string, disabledComponents?: ApiDisabledComponents) {
    if (!disabledComponents) {
      disabledComponents = this.disabledFields.disabledComponents;
    }
    let enabled = null;
    if (disabledComponents && disabledComponents.components && !disabledComponents.unhandledCase) {
      disabledComponents.components.forEach(component => {
        if (component.code.toLowerCase() === fieldCode.toLowerCase()) {
          enabled = component.enabled;
        }
      });
    }
    // if result === null -> the field is not managed
    return enabled;
  }

  setSubstitutionReason(reasonId: number) {
    this.substReason = reasonId;
  }

  setSubstitutionReasonCode(reasonCode: string) {
    this.substReasonCode = reasonCode;
  }

  public setSubClusterCode(value: string) {
    this.subClusterCode = value;
  }

  public setSubAssetCode(value: string) {
    this.subAssetCode = value;
  }

  public setSubstitutionConfig(substConfig: ApiSubstitutionReasonConf) {
    this.substitutionConfig = substConfig;
    this.setDisabledFields(getDisabledComponentsFromSubstitutionConfig(substConfig));
  }

  public getSubstitutionConfig() {
    return this.substitutionConfig;
  }

  public getFunctionEnabledByCode(codeFx: string) {
    return this.httpClient.get<boolean>(this.commonEndpoint + '/function/' + codeFx);
  }

  setDisableCollectingButtons(disableCollecting) {
    this.disableCollecting = disableCollecting;
  }

  getDisableCollectingButtons() {
    return this.disableCollecting;
  }

  getProposal() {
    return this.proposal;
  }

  setProposal(proposal: MotorProposal) {
    this.proposal = proposal;
  }

  public updateVehicleFromPlate(companyId: string, nodeId: string, resourceId: string, plate: string,
                                plateTypeId: GenericEntity, assetCode?: string, maintainInsuranceStatus?: boolean) {
    const headers = MicHttpDisableErrorInterceptorService.getErrorInterceptorDisable();
    const path = this.baseApiUrlV2 + '/motor/policy/' + resourceId + '/changeByPlate';
    let requestParams: HttpParams = new HttpParams();
    if (companyId) {
      requestParams = requestParams.set('companyId', companyId);
    }
    if (nodeId) {
      requestParams = requestParams.set('nodeId', nodeId);
    }
    if (plate) {
      requestParams = requestParams.set('plate', plate);
    }
    if (assetCode) {
      requestParams = requestParams.set('assetCode', assetCode);
      if (plateTypeId && plateTypeId.id) {
        requestParams = requestParams.set('plateTypeId', plateTypeId.id);
      }
    }
    if (maintainInsuranceStatus !== undefined) {
      requestParams = requestParams.set('maintainInsuranceStatus', String(maintainInsuranceStatus));
    }
    return this.httpClient.post<ApiContract>(path, null, {params: requestParams, headers});
  }

  public isManualPolicyNumberEnabled(): Observable<boolean> {
    const contractId = this.getContractId();
    return this.httpClient.get<boolean>(this.contractEndpoint + '/' + contractId + '/manual-policy-number/enable');
  }

  public setIsInclusionApplication(isInclusion: boolean) {
    this.isInclusionApplication = isInclusion;
  }

  public getIsInclusionApplication() {
    return this.isInclusionApplication;
  }

  public isApplicationSubstitution(): boolean {
    return this._isApplicationSubstitution;
  }

  public setApplicationSubstitution(value: boolean) {
    this._isApplicationSubstitution = value;
  }

  public setInclusionData(value: ApiMasterPolicyRef) {
    this.inclusionData = value;
  }

  public getInclusionData() {
    return this.inclusionData;
  }

  checkExistingIBAN() {
    const contractId = this.getContractId();
    return this.httpClient.get<Array<PolicyNumberAndHolder>>(this.portfolioEndpoint + '/contract-draft/' +
      contractId + '/contracts/holders/iban');
  }

  get vehicleAlreadyChanged(): boolean {
    return this._vehicleAlreadyChanged;
  }

  set vehicleAlreadyChanged(value: boolean) {
    this._vehicleAlreadyChanged = value;
  }

  public checkDigitalSignature(): Observable<ApiDigitalSignature> {
    const contractId = this.getContractId();
    return this.httpClient.get<ApiDigitalSignature>(this.contractEndpoint + '/' + contractId + '/documents/requireDigitalSignature');
  }

  public setContractIdCached(contractIdCached: string) {
    this.contractIdCached = contractIdCached;
  }

  public getContractIdCached() {
    return this.contractIdCached;
  }
}
