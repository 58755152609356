import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {
  OnModalClose,
  RGI_RX_DROP_SELECTION_DATA,
  RgiRxDatatableRowAction,
  RgiRxDropContainerSelectData,
  TableSchema
} from '@rgi/rx/ui';
import {PNC_ACTIONS} from '../../../resources/constants/actions';
import {ApiNode} from '../../../resources/model/api/nodes';

@Component({
  selector: 'rgi-pnc-node-selection-modal',
  templateUrl: './pnc-node-selection-modal.component.html'
})
export class PncNodeSelectionModalComponent implements OnInit, OnModalClose {
  modalClose: EventEmitter<ApiNode | null> = new EventEmitter();
  nodeList: ApiNode[];
  schema: TableSchema = {
    rows: [
      {name: 'code', title: '_RGIPNC_._LABEL_._CODE_'},
      {name: 'description', title: '_RGIPNC_._LABEL_._DESCRIPTION_'},
      {
        name: 'action', title: '_RGIPNC_._LABEL_._ACTION_',
        actions: [{
          name: PNC_ACTIONS.NODE_SELECTION,
          styleClass: 'rgi-ui-icon-right-arrow'
        }]
      }
    ],
    header: ['code', 'description', 'action']
  };

  constructor(
    @Inject(RGI_RX_DROP_SELECTION_DATA) private dropSelectionData: RgiRxDropContainerSelectData<any, ApiNode[]>
  ) {
  }

  onActionClick(event: RgiRxDatatableRowAction<ApiNode>) {
    if (event.name === PNC_ACTIONS.NODE_SELECTION) {
      this.modalClose.emit(event.row);
    }
  }

  actionCancel() {
    this.modalClose.emit(null);
  }

  ngOnInit() {
    if (!!this.dropSelectionData?.selectData?.length) {
      this.nodeList = this.dropSelectionData.selectData;
    } else {
      throw Error('No node list provided');
    }
  }

}
