<div class="table-white">
    <div class="row">
        <div class="col-md-1">
            <div> <label>{{ '_CLAIMS_._ACTIVITY' | translate }}</label></div>
            <input [value]="details.elaborationData?.elabActivity?.code" disabled style="width: 95%;">
        </div>
        <div class="col-md-3">
            <div style="padding-top: 3px;"><label></label></div>
            <input [value]="details.elaborationData?.elabActivity?.title" disabled style="width: 95%;"><span
                (click)="modal.open()">
                <span class="glyphicon glyphicon-info-sign" style="color: blue;"></span></span>
            <rgi-rx-modal #modal>
                <rgi-rx-panel>
                    <rgi-rx-panel-header [closeable]="true" (onClose)="modal.close()"></rgi-rx-panel-header>
                    <rgi-rx-panel-footer>
                        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modal.close()">close</button>
                    </rgi-rx-panel-footer>
                    <div style="white-space: pre-line;">
                        {{details.elaborationData?.elabActivity?.description }}
                    </div>
                </rgi-rx-panel>
            </rgi-rx-modal>
        </div>
        <div class="col-md-2">
            <div> <label>{{ '_CLAIMS_._EXTERNAL_KEY' | translate }}</label></div>
            <input [value]="details.elaborationData?.elabExternalKey" disabled style="width: 95%;">
        </div>
        <div class="col-md-2">
            <div> <label>{{ '_CLAIMS_._INSERTED' | translate}}</label></div>
            <input [value]="details.elaborationData?.elabTIns | date:'dd/MM/yyyy' " disabled style="width: 95%;">
        </div>
        <div class="col-md-2">
            <div> <label>{{ '_CLAIMS_._CODE_CHAIN' | translate}}</label></div>
            <input [value]="details.elaborationData?.elabCodeChain" disabled style="width: 95%;">
        </div>
        <div class="col-md-2">
            <div><label>{{ '_CLAIMS_._STATUS' | translate}}</label></div>
            <input [value]="status.descrizione" disabled style="width: 95%;">
        </div>
    </div>
</div>

<div class="table-white">
    <h4>{{ '_CLAIMS_._JSON_N' | translate }} {{details.jsonData?.length}} </h4>

    <div class="head-result thDataTable row col-md-12">
        <!-- <div class="col-md-1" >
            <span class="bold"></span>
        </div> -->
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._ID' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._LAST_ELABORATION' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._OUTCOME' | translate }}</span>
        </div>
        <div align="left" class="col-md-2">
            <span class="bold">{{ '_CLAIMS_._ERROR' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._CANCELLATION_DATE' | translate }}</span>
        </div>
        <div align="left" class="col-md-2">
            <span class="bold">{{ '_CLAIMS_._CANCELLATION_REASON' | translate }}</span>
        </div>
        <div align="left" class="col-md-2">
            <span class="bold">{{ '_CLAIMS_._ASSOCIATED_ENTITY' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._CLAIM' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold"></span>
        </div>
    </div>
</div>


<span *ngFor="let detail of details.jsonData; let even = even; let odd = odd; let elaborationRequestIndex = index">
    <div class="container-fluid table-white col-md-12">
        <div [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="row">
            <div class="col-md-1">
                <span>{{detail.jsonId}}</span>
            </div>
            <div class="col-md-1">
                <span>{{detail.jsonLastElaborationDate | date:'dd/MM/yyyy'}}</span>
            </div>
            <div class="col-md-1 ">
                <ng-container *ngIf="detail.jsonElaborationOutcome?.code && detail.jsonElaborationOutcome?.description">
                    <span>{{detail.jsonElaborationOutcome.code}}-{{detail.jsonElaborationOutcome.description}}</span>
                </ng-container>
            </div>
            <div class="col-md-2">
                <ng-container *ngIf="detail.jsonElaborationError?.code && detail.jsonElaborationError?.description">
                    <span>{{detail.jsonElaborationError.code}}-{{detail.jsonElaborationError.description}}</span>
                </ng-container>
            </div>
            <div class="col-md-1">
                <span>{{detail.jsonCancellationDate | date:'dd/MM/yyyy' }}</span>
            </div>
            <div class="col-md-2">
                <span>{{detail.jsonCancellationReason}}</span>
            </div>
            <div class="col-md-2">
                <ng-container *ngIf="detail?.jsonIdElement && detail.jsonElementType?.description">
                    <span>{{detail.jsonIdElement}}-{{detail.jsonElementType?.description}}</span>
                </ng-container>

            </div>
            <div class="col-md-1">
                <span>{{detail.jsonIdClaimPass }}</span>
            </div>

            <div class="col-md-1" style="text-align: right;">

                <div class="btn-group btn-group-justified ">

                    <div class="btn-group" *ngIf="status.codice == '1' ">
                        <button title="CANCEL SINGLE JSON" rgi-rx-button variant="icon" type="button" color="info" (click)="stop(detail.jsonId)"
                            attr.data-qa="elaboration-request-list-description-btn"><span
                                class="glyphicon glyphicon-remove-circle"></span></button>
                    </div>

                    <ng-container >
                        <div class="btn-group">
                            <button rgi-rx-button variant="icon" type="button" color="info" (click)="modal.open()"
                                attr.data-qa="elaboration-request-list-detail-btn"
                                id="elaboration-request-list-detail"><span
                                    class="glyphicon glyphicon-chevron-right"></span></button>
                            <rgi-rx-modal #modal>
                                <rgi-rx-panel>
                                    <rgi-rx-panel-header [closeable]="true"
                                        (onClose)="modal.close()"></rgi-rx-panel-header>
                                    <rgi-rx-panel-footer>
                                        <button class="rgi-ui-btn rgi-ui-btn-primary"
                                            (click)="modal.close()">close</button>
                                    </rgi-rx-panel-footer>
                                    <span>{{detail.jsonBody}}</span>
                                </rgi-rx-panel>
                            </rgi-rx-modal>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
        <!-- <div class="row"></div> -->
        <!-- </div> -->
    </div>
</span>


<div class="table-white">
    <h4>{{ '_CLAIMS_._EXECUTIONS' | translate }}</h4>
    <div class="head-result thDataTable row">
        <!-- <div class="col-md-1" >
            <span class="bold"></span>
        </div> -->
        <div align="left" class="col-md-1 rgi-claims-col-md-05">
            <span class="bold"></span>
        </div>
        <!-- <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._ID' | translate }}</span>
        </div> -->
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._TYPE' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._INSERT' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._REQUEST' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._ELABORATION' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._CANCELLATION' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._OUTCOME' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._ERROR' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._JSON_OK_N' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._JSON_KO_N' | translate }}</span>
        </div>
        <div align="left" class="col-md-1">
            <span class="bold">{{ '_CLAIMS_._RERUN' | translate }}</span>
        </div>
        <div align="left" class="col-md-1 rgi-claims-col-md-05">
            <span class="bold"></span>
        </div>
    </div>
</div>


<span *ngFor="let queueData of details.queueDataList; let even = even; let odd = odd; let queueDataIndex = index">
    <div class="container-fluid table-white">
        <div [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="row">
            <div class="col-md-1 rgi-claims-col-md-05">
                <span><span *ngIf="queueData.queueActive" class="rgi-ui-icon-check"
                        style="color: rgb(27, 202, 21)"></span></span>
            </div>
            <!--div class="col-md-1">
                <span>{{queueData?.queueExecutionId}}</span>
            </div-->
            <div class="col-md-1">
                <ng-container
                    *ngIf="queueData?.queueElabType?.code  && queueData?.queueElabType?.description">
                    <span>{{queueData.queueElabType.code}}-{{queueData.queueElabType.description}}</span>
                </ng-container>
            </div>
            <div class="col-md-1">
                <span>{{queueData?.queueTIns | date:'dd/MM/yyyy'}}</span>
            </div>
            <div class="col-md-1 ">
                <span>{{queueData?.queueElaborationRequestDate | date:'dd/MM/yyyy'}}</span>
            </div>
            <div class="col-md-1">
                <span>{{queueData?.queueEleborationDate | date:'dd/MM/yyyy'}}</span>
            </div>
            <div class="col-md-1">
                <span>{{queueData?.queueCancellationDate | date:'dd/MM/yyyy' }}</span>
            </div>
            <div class="col-md-1">
                <ng-container
                    *ngIf="queueData?.queueOutcomeState?.code  && queueData?.queueOutcomeState?.description">
                    <span>{{queueData.queueOutcomeState.code}}-{{queueData.queueOutcomeState.description}}</span>
                </ng-container>
            </div>
            <div class="col-md-1">
                <ng-container
                    *ngIf="queueData?.queueOutcomeError?.code  && queueData?.queueOutcomeError?.description">
                    <span>{{queueData.queueOutcomeError.code}}-{{queueData.queueOutcomeError.description}}</span>
                </ng-container>
            </div>
            <div class="col-md-1">
                <span>{{queueData?.queueJsonOkNr}}</span>
            </div>
            <div class="col-md-1">
                <span>{{queueData?.queueJsonKONr}}</span>
            </div>
            <div class="col-md-1">
                <span>{{(queueData?.queueRerunRequest)?('_CLAIMS_._YES' | translate):('_CLAIMS_._NO' | translate)}}</span>
            </div>

            <div class="col-md-1 rgi-claims-col-md-05" style="text-align: right;">

                <div class="btn-group btn-group-justified ">
                    <ng-container *ngIf="status.codice=='1'">
                        <div class="btn-group">
                            <button title="TOTAL CANCEL" rgi-rx-button variant="icon" type="button" color="info" (click)="stop()"
                                attr.data-qa="elaboration-request-list-description-btn"><span
                                    class="glyphicon glyphicon-remove-circle"></span></button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="status.codice=='1' && (queueData.queueOutcomeState.code == '3' || queueData.queueOutcomeState.code == '5')">
                        <div class="btn-group">
                            <button title="RERUN" rgi-rx-button variant="icon" type="button" color="info"
                                (click)="modalOpen('RERUN', null)" attr.data-qa="elaboration-request-list-detail-btn"
                                id="elaboration-request-list-submit"><span
                                    class="glyphicon glyphicon-chevron-right"></span></button>
                        </div>

                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</span>

<br>
<br>

<div class="btn-group btn-group-justified btn-group-justified-claim">

    <div class="btn-group">
        <button (click)="back()" class="btn btn-warning pull-right text-uppercase">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
    </div>

</div>

<rgi-rx-modal #modalSR>
    <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose()"></rgi-rx-panel-header>
        <rgi-rx-panel-footer>
            <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose()">{{'_CLAIMS_._BUTTONS_._CLOSE' | translate }}</button>
            <button *ngIf="modalType=='RERUN' && outcome==null" class="rgi-ui-btn rgi-ui-btn-primary" (click)="rerun()"
            [disabled]="!rerunType">{{'_CLAIMS_._BUTTONS_._RERUN' | translate }}</button>
        </rgi-rx-panel-footer>
        <p>{{outcome}},{{modalError}}</p>
        <div *ngIf="modalType == 'RERUN' && outcome == null">
            <rgi-rx-button-toggle [(ngModel)]="rerunType">
                <button rgi-rx-button rgiRxValueOption [value]="r.RESERVATION">{{ '_CLAIMS_._BUTTONS_._RESERVATION' | translate }}</button>
                <button rgi-rx-button rgiRxValueOption [value]="r.ONDEMAND">{{ '_CLAIMS_._BUTTONS_._ONDEMAND' | translate }}</button>
            </rgi-rx-button-toggle>
            <form [formGroup]="formDate" id="datePicker">
                <pnc-datepicker [label]="'_CLAIMS_._RESERVATION_DATE' | translate" customFormControlName="rerunDate"
                    [customFormGroup]="formDate" [disabled]="rerunType !== r.RESERVATION">
                </pnc-datepicker>
            </form>
        </div>
    </rgi-rx-panel>
</rgi-rx-modal>
