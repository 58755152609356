<!-- <pnc-datepicker
  [customFormGroup]="formGroup"
  customFormControlName="date"
  [label]="dateLabel"
  [required]="required"
  [minDate]="minLocaleDate"
  [maxDate]="maxLocaleDate"
  [disabled]="disabled"
  [id]="id"
  [attr.data-qa]="'factor-date-' + dateLabel"
  [attr.data-obb]="id + required">
</pnc-datepicker>
 -->
<div [formGroup]="formGroup">
  <rgi-rx-form-field>
    <label [for]="dateLabel" rgiRxLabel>{{dateLabel}}</label>
    <rgi-rx-date-picker [openOnClick]="false">
      <input
        [rgiRxDateTimeInput]="picker1"
        [min]="minLocaleDate"
        [max]="maxLocaleDate"
        formControlName="date"
        [attr.data-qa]="'factor-date-' + dateLabel"
        [attr.data-obb]="id + required">
    </rgi-rx-date-picker>
    <rgi-rx-date-time #picker1></rgi-rx-date-time>
   </rgi-rx-form-field>
</div>

