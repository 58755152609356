<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="!!publishMessage">

  <lpc-step label="{{PENSION_BUYBACK_STEP.DATE.label}}" id="{{PENSION_BUYBACK_STEP.DATE.id}}" [errors]="errors"
    fieldId="{{PENSION_BUYBACK_STEP.DATE.fieldId}}" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="PENSION_BUYBACK_STEP.DATE.formName"
      (dateChange)="onDateChange($event)" (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="{{PENSION_BUYBACK_STEP.POLICY_FACTORS.id}}" *ngIf="hasProductFactor"
    label="{{PENSION_BUYBACK_STEP.POLICY_FACTORS.label}}" [errors]="errors"
    fieldId="{{PENSION_BUYBACK_STEP.POLICY_FACTORS.fieldId}}" (next)="onNext($event)">
    <div formGroupName="{{PENSION_BUYBACK_STEP.POLICY_FACTORS.formName}}" class="row">
      <lpc-factor-adapter #factorAdapter
        (changeValEmitter)="updateProductFactors($event)"
        [form]="formGroup.controls['factors']"
        [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step id="{{PENSION_BUYBACK_STEP.QUESTS_FIN.id}}" [hidden]="!validQuestsCode.get('FIN')"
    [jump]="!validQuestsCode.get('FIN')" *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')"
    fieldId="{{PENSION_BUYBACK_STEP.QUESTS_FIN.fieldId}}" label="{{PENSION_BUYBACK_STEP.QUESTS_FIN.label}}"
    (next)="onNext($event)">
    <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
      [questionnairesCode]="getQuestMap('FIN')" [type]="'FIN'" [date]="effectiveDate" [productCode]="productCode"
      [savedIds]="questionnairesFromAuthorization" (loaderQuest)="loaderQuestManager($event)"
      (loaded)="countQuest($event)" #financialQuestionnaire></lpc-questionnaire>
  </lpc-step>

  <lpc-step id="{{PENSION_BUYBACK_STEP.PERFORMANCE_DATA.id}}" *ngIf="!!performanceData"
    label="{{PENSION_BUYBACK_STEP.PERFORMANCE_DATA.label}}" [errors]="errors"
    fieldId="{{PENSION_BUYBACK_STEP.PERFORMANCE_DATA.fieldId}}" (next)="onNext($event)">
    <lpc-performance-data
      [percentage]="percentage"
      [usePercentage]="!!!performanceData?.fiscalPeriods?.length"
      [active]="!isAfterId(PENSION_BUYBACK_STEP.PERFORMANCE_DATA.id)"
      [formControl]="formGroup.controls[PENSION_BUYBACK_STEP.PERFORMANCE_DATA.formName]"
      [fiscalData]="performanceData">
    </lpc-performance-data>
  </lpc-step>

  <lpc-step id="{{PENSION_BUYBACK_STEP.ANNUITY_DATA.id}}" *ngIf="!isPayoutMaxed"
    label="{{PENSION_BUYBACK_STEP.ANNUITY_DATA.label}}" [errors]="errors"
    fieldId="{{PENSION_BUYBACK_STEP.ANNUITY_DATA.fieldId}}" (next)="onNext($event)">
    <div [formGroupName]="PENSION_BUYBACK_STEP.ANNUITY_DATA.formName" class="mb-2">
      <div class="row" *ngFor="let risks of listRiskFactor">
        <lpc-factor-adapter #factorAdapter
          (changeValEmitter)="updateRiskFactors($event, risks.code)"
          [form]="formGroup.get(PENSION_BUYBACK_STEP.ANNUITY_DATA.formName).get(risks.code)"
          [factors]="risks.factors"
          [emitValueOnInit]="isRiskFactorJustCreated">
        </lpc-factor-adapter>
      </div>
      <div class="row col-md-12">
        <lpc-reversionary-subject
          *ngIf="hasReversionarySubj()"
          formControlName="reversionary"
          [reversionary]="reversionary"
          [active]="!isAfterId(PENSION_BUYBACK_STEP.ANNUITY_DATA.formName)">
        </lpc-reversionary-subject>
      </div>
      <div class="col-lg-6 mb-4">
        <lpc-payment-step
          [editable]="true"
          [required]="true"
          [summary]="isAfterId(PENSION_BUYBACK_STEP.ANNUITY_DATA.formName)"
          [paymentTypeDefinitions]="annuityDataPaymetType"
          formControlName="paymentMethod"
          [isPaymentTypeEditable]="true"
          (change)="onChangePayment($event)">
        </lpc-payment-step>
      </div>
    </div>
    <div class="m-0" *ngIf="!isActiveStep('annuity') && !!formGroup.get('reversionary')?.value">
        <span translate>lpc_reversionary_subject</span>:<span> {{formGroup.get('reversionary')?.value.value.name}} </span>
        <br>
        <span translate>lpc_percentage_reversibility</span>:<span> {{formGroup.get('reversionary')?.value.value.percentage}} % </span>
    </div>
  </lpc-step>

  <lpc-step id="{{PENSION_BUYBACK_STEP.QUOTATION.id}}" [enableAuth]="isAuth"
    label="{{PENSION_BUYBACK_STEP.QUOTATION.label}}" [errors]="errors"
    fieldId="{{PENSION_BUYBACK_STEP.QUOTATION.fieldId}}" (next)="onNext($event)">
    <div formGroupName="quotation" class="row">
      <div *ngFor="let insuredSettlement of insuredSettlements">
        <p *ngIf="!!insuredSettlement.insured && insuredSettlement.insured.length > 1;else singleInsured"
          class="insured">
          <label translate>lpc_Assicurati</label>
          <label>: {{ insuredSettlement.insured[0] + ' - ' + insuredSettlement.insured[1] }}</label>
        </p>
        <ng-template #singleInsured>
          <p class="insured">
            <label translate>lpc_Assicurato</label>
            <label>: {{ insuredSettlement.insured[0] }}</label>
          </p>
        </ng-template>
        <lpc-definition-table
          *ngIf="hasCapitaleTable"
          [label]="'lpc_settlement'"
          [definitions]="tableDefinitions"
          [rows]="insuredSettlement.settlement">
        </lpc-definition-table>
      </div>
    </div>
    <lpc-annuity-table
      *ngIf="hasRenditaTable"
      [label]="'lpc_annuity'"
      [definitions]="annuityTableDefinitions">
    </lpc-annuity-table>
  </lpc-step>

  <lpc-step id="{{PENSION_BUYBACK_STEP.BENEFICIARIES.id}}" [enableAuth]="isAuth"
    label="{{PENSION_BUYBACK_STEP.BENEFICIARIES.label}}" [errors]="errors" fieldId="beneficiaries"
    (next)="onNext($event, false, 'beneficiaries')" [feErrors]="feErrors" feFieldId="beneficiaries"
    [fieldTypes]="['quotation','beneficiaries']" (confirmAccept)="onNext($event, false, null, true)"
    [isConfirmAndAccept]="isConfirmAndAccept" *ngIf="!!beneficiariesDefinitions?.length">
    <lpc-claim-beneficiaries formControlName="{{PENSION_BUYBACK_STEP.BENEFICIARIES.formName}}"
      [paymentTypes]="paymentTypes" [beneficiaryType]="beneficiaryType" [idAssicurato]="idAssicurato"
      [summary]="!isActiveStep('beneficiaries')" [questionnairesCode]="getUnfilteredQuestMap('IST')"
      [questionnairesDefinitions]="questionnaireDefinitions" [blockBeneficiaries]="blockBeneficiaries"
      (addedSubject)="onAddedSubject($event)" (triggerQuestPreval)="onTriggerQuestPreval($event)"
      (loaderQuest)="loaderQuestManager($event)" [questFactorsArray]="questFactorsArray"
      [disabledQuestionArray]="disabledQuestionArray" #beneficiaries>
    </lpc-claim-beneficiaries>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="{{PENSION_BUYBACK_STEP.WARNING.id}}"
    label="{{PENSION_BUYBACK_STEP.WARNING.label}}" (next)="publish()"
    [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'" (confirmAccept)="publish(isConfirmAndAccept)"
    [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="{{PENSION_BUYBACK_STEP.DOCUMENT.label}}" [errors]="errors"
    fieldId="{{PENSION_BUYBACK_STEP.DOCUMENT.fieldId}}" id="{{PENSION_BUYBACK_STEP.DOCUMENT.id}}" [nextVisible]=false>
    <lpc-document-wrapper *ngIf="!!publishMessage" [contractId]="getContractId" [operationCode]="getOperationCode"
      [publishMessage]="publishMessage" [isAuth]="isAuth" [authorizationId]="authorizationId" [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>
    <lpc-navigation-buttons id="last-step-buttons" (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'"
      [backVisible]="true" [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>

</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>
