import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ExposureEntity } from '../domain/exposure-entity';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { LegalFeeEntity } from '../domain/legal-fee-entity';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { NewPaymentService } from '../new-payment.service';
import { forEach } from 'angular';
import { Invoice } from '../../invoice/dto/invoice';
import { TaxregimeEntity } from '../domain/taxregime-entity';
import { InvoiceService } from '../../invoice/invoice.service';
import { ModifyNdCLegalInputItem } from '../../invoice/dto/modify-ndc-legal-input';
import { Outcome } from '@rgi/digital-claims-common-angular';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { SubjectEntity } from '../domain/subject-entity';

@Component({
  selector: 'claims-fee-data-modal',
  templateUrl: './fee-data-modal.component.html',
  styleUrls: ['./fee-data-modal.component.scss']
})
export class FeeDataModalComponent implements OnInit {
  private static PROPERTY_SHOW_SDI_PROTOCOL_NUMBER: string = 'ShowSDIProtocolCod';
  private static PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV: string = 'ClaimsManagementSapSRV';
  private static PROPERTY_ENABLED: string = 'Enable';

  @Input() newPayFormModal: UntypedFormGroup;
  @Input() exp: ExposureEntity;
  @Input() idCampoExp: string;
  @Input() idCampoSubExp: string;
  @Input() potPayFE: InitPotentialPaymentEntity;
  @Input() openOutModal: boolean = false;

  @Input() isEncapsulated: boolean = false;
  @Input() alignedInvoiceDisplay: boolean = false;
  @Input() isGeneratePayment: boolean = true;
  @Input() minDatePersonalized: NgbDate = null;

  @Output() outputForm: EventEmitter<LegalFeeEntity> = new EventEmitter<LegalFeeEntity>();

  public feeData: LegalFeeEntity;
  public feeDataModal: LegalFeeEntity;
  public extension: string;
  public today: NgbDate;
  public errorMessage = null;
  public block: boolean = false;
  public consult: boolean = false;
  public showSDIProtocolNumber: boolean = false;       // ATTENZIONE DA  METTERE A FALSE DOPO CHE LA SYPROP è STATA CREATA
  public claimsManagementSapSRV: boolean = false;
  public showGeneratePayment: boolean;
  public foreignCurrency: boolean = false;

  @Input() set variables(array) {
    this.idClaim = array[0];
    this.idAct = array[1];
    this.idLiqStorno = array[2];
    this.idLiquidazione = array[3];
    this.idDatiFattura = array[4];
    this.idLegal = array[5];
    this.idCausaleDanno = array[6];

    this.exp = new ExposureEntity();
    this.exp.feeData = new LegalFeeEntity();
    this.exp.description = 'test desc';

    const invoice: Invoice = array[7];

    this.feeData = new LegalFeeEntity();

    this.feeData.taxableAmount = invoice.invoiceDetailsList[0].taxableAmount;
    this.feeData.notTaxableAmount = invoice.invoiceDetailsList[0].notTaxableAmount;
    this.feeData.bolliAmount = invoice.stampDutyAmount;
    this.feeData.netAmount = invoice.amountToBePayd;
    this.feeData.cash1Amount = invoice.cashPrevidAmount;
    this.feeData.cash2Amount = 0; // sul batch è settato a 0
    this.feeData.totalAmount = invoice.invoiceDetailsList[0].totAmountUnit;
    this.feeData.ivaAmount = invoice.vatAmount;
    this.feeData.withHoldingAmount = invoice.amountHoldTax;
    this.feeData.taxRegime = new TaxregimeEntity();
    this.feeData.taxRegime.legalCode = 'legalCode';
    this.feeData.feeNumber = invoice.invoiceNumber;
    this.feeData.feeDate = invoice.invoiceDate;
    this.feeData.feeDateRecording = invoice.sapRecordingDate;
    this.feeData.generatePayment = false;
    this.feeData.feeType = invoice.invoiceType.descrizione;
    this.feeData.SDIProtocolCod = invoice.sdiCode;
    this.feeData.SAPProtocolKey = invoice.sapCode;
    this.feeData.idSoggLegal = this.idLegal + '';

    this.isNdcAuth = true;
  }

  // variabili per l'act modifyFeeProf
  public idLiqStorno: number;
  public idLiquidazione: number;
  public idDatiFattura: number;
  public idClaim: number;
  public idAct: number;
  public idLegal: number;
  public idCausaleDanno: number;
  public isNdcAuth: boolean = false;


  public eFeeType: TypeEnum[] = [];
  public eSuccessService: TypeEnum;
  public isContextSpain: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    @Inject('eventService') private eventService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('authService') private authService,
    private paymentConstants: PaymentConstants,
    public translateService: RgiRxTranslationService,
    private formBuilder: UntypedFormBuilder,
    private newPaymentService: NewPaymentService,
    private invoiceService: InvoiceService) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    let localContext = this.authService.getDefaultContext();
    this.isContextSpain = localContext === 'ES';
  }

  initEnums() {
    this.eFeeType = this.paymentConstants.getSingleEFeeType();
    this.eSuccessService = this.paymentConstants.getSingleSuccessServer(ENUMSLISTPAYMENT.SUCCESS_SERVICE);
  }

  ngOnInit() {
    this.initEnums();

    this.showGeneratePayment = this.authService.getOperator().enabledFeatures.indexOf('SXFALD') === -1;

    this.getProperties();

    this.foreignCurrency = this.newPayFormModal && this.newPayFormModal.value.currencyCode !== 'EUR';

    if (!this.isNdcAuth) {
      this.feeData = this.exp.feeData;
    }

    this.extension = '';
    this.block = false;

    if (this.idCampoSubExp == null) {
      this.extension = this.idCampoExp;
    } else {
      this.extension = this.idCampoSubExp;
    }

    if (this.newPayFormModal) {
      this.consult = false;
      

      this.populateForm();

      if ((this.exp && this.exp.injured && this.exp.injured.country === 'IT'
        && (!this.exp.injured.fiscalCode || this.exp.injured.fiscalCode === '') &&
        (!this.exp.injured.vat || this.exp.injured.vat === '')) ||
        this.feeData.taxRegime.percIVA == null ||
        this.feeData.taxRegime.percCash == null ||
        this.feeData.taxRegime.percCash2 == null ||
        this.feeData.taxRegime.percTaxableAmount == null ||
        this.feeData.taxRegime.percWithHolding == null ||
        this.feeData.taxRegime.legalCode == null) {
          this.translateService.translate('_CLAIMS_._SUBJECT_WITH_INCOMPLETE_TAX_DATA__UNABLE_TO_PROCEED').subscribe(res=> {
            this.errorMessage=res;
          }
          )
        this.block = true;
      }
    } else {
      this.consult = true;
      this.newPayFormModal = this.formBuilder.group({});

      if (this.isNdcAuth) {
        this.extension = 'modNdc';
        this.populateForm();
      }
    }
  }

  populateForm() {
    this.newPayFormModal.addControl('taxableAmount' + this.extension,
      new UntypedFormControl({ value: this.feeData.taxableAmount, disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('noTaxableAmount' + this.extension,
      new UntypedFormControl({ value: this.feeData.notTaxableAmount, disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('bolliAmount' + this.extension,
      new UntypedFormControl({ value: this.feeData.bolliAmount, disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('feeNumber' + this.extension,
      new UntypedFormControl({ value: this.feeData.feeNumber, disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('feeDate' + this.extension,
      new UntypedFormControl({ value: new Date(this.feeData.feeDate), disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('createPayment' + this.extension,
      new UntypedFormControl({ value: true, disabled: false }, Validators.nullValidator));

    this.newPayFormModal.addControl('feeType' + this.extension,
      new UntypedFormControl({ value: this.feeData.feeType, disabled: false }, Validators.required));
    this.newPayFormModal.addControl('SDIProtocolCod' + this.extension,
      new UntypedFormControl({ value: this.feeData.SDIProtocolCod, disabled: false }, Validators.required));
    this.newPayFormModal.addControl('SAPProtocolKey' + this.extension,
      new UntypedFormControl({ value: this.feeData.SAPProtocolKey, disabled: false }, Validators.required));
    this.newPayFormModal.addControl('ivaAmount' + this.extension,
      new UntypedFormControl({ value: this.feeData.ivaAmount, disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('withHoldingAmount' + this.extension,
      new UntypedFormControl({ value: this.feeData.withHoldingAmount, disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('cash1Amount' + this.extension,
      new UntypedFormControl({ value: this.feeData.cash1Amount, disabled: false }, Validators.nullValidator));
    this.newPayFormModal.addControl('cash2Amount' + this.extension,
      new UntypedFormControl({ value: this.feeData.cash2Amount, disabled: false }, Validators.nullValidator));

    if (this.isNdcAuth) {
      this.newPayFormModal.addControl('expAmount' + this.extension,
        new UntypedFormControl({ value: this.feeData.totalAmount, disabled: false }, Validators.nullValidator));
      this.newPayFormModal.addControl('correctModalClose',
        new UntypedFormControl({ value: false, disabled: false }, Validators.nullValidator));
    }
  }

  getProperties() {
    /*     this.eventService.broadcastEvent('start-loader');
        this.getSystemProperty.findProperty(FeeDataModalComponent.PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV).then((result) => {
          if (result && result.systemProperties && result.systemProperties.length) {
            for (const variable of result.systemProperties) {
              if (variable.key === FeeDataModalComponent.PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV) {
                if (variable.value && FeeDataModalComponent.PROPERTY_ENABLED === variable.value) {
                  this.claimsManagementSapSRV = true;
                }
                break;
              }
            }
          }
          this.eventService.broadcastEvent('stop-loader');
        }); */
    /*     this.getSystemProperty.findProperty(FeeDataModalComponent.PROPERTY_SHOW_SDI_PROTOCOL_NUMBER).then((result) => {
          if (result && result.systemProperties && result.systemProperties.length) {
            for (const variable of result.systemProperties) {
              if (variable.key === FeeDataModalComponent.PROPERTY_SHOW_SDI_PROTOCOL_NUMBER) {
                if (variable.value && FeeDataModalComponent.PROPERTY_ENABLED === variable.value) {
                  this.claimsManagementSapSRV = true;
                }
                break;
              }
            }
          }
          this.eventService.broadcastEvent('stop-loader');
        }); */

    //  QUANDO CI SARA LA SYSPROP SHOWSDI SI POTRA USARE QUESTO CODICE
    const promises: any[] = [];
    promises.push(this.getSystemProperty.findProperty(FeeDataModalComponent.PROPERTY_SHOW_SDI_PROTOCOL_NUMBER));
    promises.push(this.getSystemProperty.findProperty(FeeDataModalComponent.PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV));
    this.eventService.broadcastEvent('start-loader');
    Promise.all(promises).then((results) => {
      results.forEach((result) => {
        if (result && result.systemProperties && result.systemProperties.length) {
          for (const variable of result.systemProperties) {
            if (variable.key === FeeDataModalComponent.PROPERTY_CLAIMS_MANAGEMENT_SAP_SRV) {
              if (variable.value && FeeDataModalComponent.PROPERTY_ENABLED === variable.value) {
                this.claimsManagementSapSRV = true;
              }
              break;
            }
            if (variable.key === FeeDataModalComponent.PROPERTY_SHOW_SDI_PROTOCOL_NUMBER) {
              if (variable.value && FeeDataModalComponent.PROPERTY_ENABLED === variable.value) {
                this.showSDIProtocolNumber = true;
              }
              break;
            }
          }
        }
      });
      this.eventService.broadcastEvent('stop-loader');
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  protected isLegalSpain() {
    return this.exp.injured && this.exp.injured.country === 'ES';
  }

  onConfirm() {
    this.eventService.broadcastEvent('start-loader');

    let missingData = false;

    /*     if (this.newPayFormModal.controls['createPayment' + this.extension].value) {
          if (this.newPayFormModal.controls['feeType' + this.extension].value) {
            if (this.newPayFormModal.controls['feeType' + this.extension].value == null ||
              this.newPayFormModal.controls['feeType' + this.extension].value === '') {
              missingData = true;
            } else {
              if (this.newPayFormModal.controls['feeType' + this.extension].value === '1' && this.showSDIProtocolNumber &&
                (!this.newPayFormModal.controls['SDIProtocolCod' + this.extension].value ||
                  this.newPayFormModal.controls['SDIProtocolCod' + this.extension].value == null ||
                  this.newPayFormModal.controls['SDIProtocolCod' + this.extension].value === '')) {
                missingData = true;
              }
              if (this.newPayFormModal.controls['feeType' + this.extension].value === '2' && this.claimsManagementSapSRV &&
                (!this.newPayFormModal.controls['SAPProtocolKey' + this.extension].value ||
                  this.newPayFormModal.controls['SAPProtocolKey' + this.extension].value == null ||
                  this.newPayFormModal.controls['SAPProtocolKey' + this.extension].value === '')) {
                missingData = true;
              }
            }
          } else {
            missingData = true;
          }
        } */

    // if (this.newPayFormModal.controls['createPayment' + this.extension].value) {
    if (!this.feeData.feeType) {
      missingData = true;
    } else {
      if (this.feeData.feeType === '1' && this.showSDIProtocolNumber && (
        !this.feeData.SDIProtocolCod || this.feeData.SDIProtocolCod == null || this.feeData.SDIProtocolCod === '')) {
        missingData = true;
      } else if (this.feeData.feeType === '1' && this.showSDIProtocolNumber) {
        this.feeData.SAPProtocolKey = null;
      }
      if (this.feeData.feeType === '2' && this.claimsManagementSapSRV && (
        !this.feeData.SAPProtocolKey || this.feeData.SAPProtocolKey == null || this.feeData.SAPProtocolKey === '')) {
        missingData = true;
      } else if (this.feeData.feeType === '2' && this.claimsManagementSapSRV) {
        this.feeData.SDIProtocolCod = null;
      }
    }
    // }


    if (( // this.newPayFormModal.controls['createPayment' + this.extension].value &&
      (this.newPayFormModal.controls['feeType' + this.extension].value !== '3' &&
        (!this.newPayFormModal.controls['feeNumber' + this.extension].value ||
          this.newPayFormModal.controls['feeNumber' + this.extension].value == null ||
          !this.newPayFormModal.controls['feeDate' + this.extension].value ||
          isNaN(this.newPayFormModal.controls['feeDate' + this.extension].value) ||
          this.newPayFormModal.controls['feeDate' + this.extension].value == null))) ||
      (this.newPayFormModal.controls['taxableAmount' + this.extension].value == null &&
        this.newPayFormModal.controls['noTaxableAmount' + this.extension].value == null) ||
      (this.newPayFormModal.controls['taxableAmount' + this.extension].value < 0 ||
        this.newPayFormModal.controls['bolliAmount' + this.extension].value < 0) ||
      missingData
    ) {
      this.errorMessage = 'Fee data missing';
      this.eventService.broadcastEvent('stop-loader');
    } else {
      this.exp.feeData.taxableAmount = (this.newPayFormModal.controls['taxableAmount' + this.extension].value == null) ?
        0 :
        this.newPayFormModal.controls['taxableAmount' + this.extension].value;
      this.exp.feeData.notTaxableAmount = (this.newPayFormModal.controls['noTaxableAmount' + this.extension].value == null) ?
        0 :
        this.newPayFormModal.controls['noTaxableAmount' + this.extension].value;
      this.exp.feeData.bolliAmount = (this.newPayFormModal.controls['bolliAmount' + this.extension].value == null) ?
        0 :
        this.newPayFormModal.controls['bolliAmount' + this.extension].value;
      this.exp.feeData.feeNumber = this.newPayFormModal.controls['feeNumber' + this.extension].value;
      this.exp.feeData.feeDate = this.newPayFormModal.controls['feeDate' + this.extension].value;
      this.exp.feeData.generatePayment = this.newPayFormModal.controls['createPayment' + this.extension].value;


      // se feeType è undefined gli metto 0, valore di default dell'enum
      if (!this.feeData.feeType) {
        this.feeData.feeType = null;
      }

      if (this.idCampoSubExp) {
        this.newPayFormModal.controls['subExpAmount' + this.extension].setValue(this.feeData.totalAmount);
      } else {
        this.newPayFormModal.controls['expAmount' + this.extension].setValue(this.feeData.totalAmount);
      }

      this.exp.amount = this.feeData.totalAmount;

      if (this.isNdcAuth) {
        this.newPaymentService.dataFeeVerify(this.feeData).subscribe((response: any) => {
          if (response.code === this.eSuccessService.eTypeCode) {

            const modifyNdCLegalInputItem: ModifyNdCLegalInputItem = new ModifyNdCLegalInputItem();
            modifyNdCLegalInputItem.legalFeeEntity = this.feeData;
            modifyNdCLegalInputItem.idClaim = this.idClaim;
            modifyNdCLegalInputItem.idActivity = this.idAct;
            modifyNdCLegalInputItem.idLiqStorno = this.idLiqStorno;
            modifyNdCLegalInputItem.idLiq = this.idLiquidazione;
            modifyNdCLegalInputItem.idDatiFattura = this.idDatiFattura;
            modifyNdCLegalInputItem.idLegal = this.idLegal;
            modifyNdCLegalInputItem.idCausaleDanno = this.idCausaleDanno;

            this.invoiceService.modifyNdCLegal(modifyNdCLegalInputItem).subscribe(
              (secondResponse: any) => {
                if (secondResponse.code === Outcome.OK) {
                  this.activeModal.close(secondResponse);
                  this.newPayFormModal.controls.correctModalClose.setValue(true);
                  this.eventService.broadcastEvent('stop-loader');
                } else {
                  // non sono stati superati i controlli lato server
                  this.errorMessage = secondResponse.descr;
                  this.eventService.broadcastEvent('stop-loader');
                }
              },
              (error: any) => {
                console.log('dataFeeVerify - outcome: ERRORE ');
                this.eventService.broadcastEvent('stop-loader');
              }
            );
          } else {
            // non sono stati superati i controlli lato server
            this.errorMessage = response.descr;
            this.eventService.broadcastEvent('stop-loader');
          }
        },
          (error: any) => {
            console.log('dataFeeVerify - outcome: ERRORE ');
            this.eventService.broadcastEvent('stop-loader');
          });
      } else {
        this.calculateTotalAmount(this.idCampoExp);

        this.errorMessage = null;

        let jumpCtrl = false;
        const payOrig = this.newPaymentService.getPaymentDB();

        if (payOrig) {
          // se la fattura è la stessa in entrata si può saltare il controllo di esistenza pregressa perchè
          // la quietanza sarà annullata
          for (const aExp of this.potPayFE.exposureList) {
            // const aExp = this.potPayFE.exposureList[i];
            const expOrig = payOrig.exposureList.find(ex => ex.idCausaleDanno === aExp.idCausaleDanno);
            if (expOrig.feeData.feeNumber === this.feeData.feeNumber &&
              expOrig.feeData.feeDate === this.feeData.feeDate) {
              jumpCtrl = true;
            }
          }
        }

        if (!jumpCtrl) {
          this.newPaymentService.dataFeeVerify(this.feeData).subscribe((response: any) => {
            if (response.code === this.eSuccessService.eTypeCode) {
              this.activeModal.close(response);
              this.newPayFormModal.controls.correctModalClose.setValue(true);
              this.eventService.broadcastEvent('stop-loader');
            } else {
              // non sono stati superati i controlli lato server
              this.errorMessage = response.descr;
              this.eventService.broadcastEvent('stop-loader');
            }
          },
            (error: any) => {
              console.log('dataFeeVerify - outcome: ERRORE ');
              this.eventService.broadcastEvent('stop-loader');
            });
        } else {
          this.activeModal.close('OK');
          this.newPayFormModal.controls.correctModalClose.setValue(true);
          this.eventService.broadcastEvent('stop-loader');
        }
      }
    }
  }
  public sdiCodeCheck(event: any) {
    if (event.target.value && event.target.value !== null) {
      event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    }
    this.newPayFormModal.controls[event.target.name].setValue(event.target.value);
  }
  round(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  calculateAmount() {
    const taxableAmount = this.newPayFormModal.controls['taxableAmount' + this.extension].value || 0;
    const cash1Control = this.newPayFormModal.controls['cash1Amount' + this.extension];
    const cash2Control = this.newPayFormModal.controls['cash2Amount' + this.extension];
    const ivaControl = this.newPayFormModal.controls['ivaAmount' + this.extension];
    const withHoldingControl = this.newPayFormModal.controls['withHoldingAmount' + this.extension];

    let cash2Amount, cash1Amount, totCompensoImp, ivaAmount, lordo, withHoldingAmount;

    // Calcolo cash2 e cash1
    if (!this.isContextSpain) {
        cash2Amount = this.calculateCashAmount('cash2', taxableAmount, this.feeData.taxRegime.percCash2, cash2Control);
        cash1Amount = this.calculateCashAmount('cash1', taxableAmount + cash2Amount, this.feeData.taxRegime.percCash, cash1Control);
    } else {
        cash1Amount = cash2Amount = 0;
    }

    // Calcolo totale compenso imponibile
    totCompensoImp = taxableAmount + cash1Amount + cash2Amount;

    // Calcolo IVA
    ivaAmount = this.calculateIva(totCompensoImp, this.isContextSpain ? this.feeData.taxIvaRegime.percIVA : this.feeData.taxRegime.percIVA, ivaControl);
    ivaControl.setValue(ivaAmount, { emitEvent: false });
    
    // Calcolo lordo
    lordo = ivaAmount + totCompensoImp + this.getValueOrDefault('noTaxableAmount') + this.getValueOrDefault('bolliAmount');

    // Calcolo ritenuta d'acconto
    withHoldingAmount = this.calculateWithHolding(taxableAmount, cash2Amount, this.feeData.taxRegime, withHoldingControl);

    // Aggiornamento dei campi e output dei dati
    this.updateAmounts(lordo, withHoldingAmount, ivaAmount);
}

calculateCashAmount(type, baseAmount, percentage, control) {
    let amount = this.round((baseAmount * percentage) / 100, 2);
    const editedAmount = this.round(control.value, 2);
    if (control.value != null && editedAmount !== amount) {
        amount = editedAmount;
    }
    return amount;
}

calculateIva(totCompensoImp, ivaPercentage, control) {
    let ivaAmount = this.round((totCompensoImp * ivaPercentage) / 100, 2);
    control.setValue(ivaAmount);
    
    const ivaAmountEdit = this.round(control.value, 2);
    if (control.value != null && ivaAmountEdit !== ivaAmount) {
        ivaAmount = ivaAmountEdit;
    }
    return ivaAmount;
}

calculateWithHolding(taxableAmount, cash2Amount, taxRegime, control) {
    let withHoldingAmount = this.round(((taxableAmount * taxRegime.percTaxableAmount) / 100 + cash2Amount) * taxRegime.percWithHolding / 100, 2);
    if (this.alignedInvoiceDisplay) {
        control.setValue(withHoldingAmount);
    }
    const editedWithHoldingAmount = this.round(control.value, 2);
    if (control.value != null && editedWithHoldingAmount !== withHoldingAmount) {
        withHoldingAmount = editedWithHoldingAmount;
    }
    return withHoldingAmount;
}

updateAmounts(lordo, withHoldingAmount, ivaAmount) {
    this.feeData.withHoldingAmount = withHoldingAmount;
    this.feeData.netAmount = this.round(lordo - withHoldingAmount, 2);
    this.feeData.totalAmount = this.round(lordo, 2);
    this.feeData.ivaAmount = ivaAmount;

    if (this.openOutModal) {
        this.outputFeeData();
    }
}

getValueOrDefault(fieldName) {
    const field = this.newPayFormModal.controls[fieldName + this.extension];
    return field && field.value != null ? field.value : 0;
}


  calculateTotalAmount(idCampoExp: string) {
    this.potPayFE.exposureList.filter(exp => exp.idCampo === idCampoExp).forEach(expFnd => {
      let total = 0;
      // for (let j = 0; j < expFnd.subExposureList.length; j++) {
      for (const aSubExp of expFnd.subExposureList) {
        // const aSubExp = expFnd.subExposureList[j];
        total += this.newPayFormModal.controls['subExpAmount' + aSubExp.idCampo].value;
      }
      expFnd.amountTotal = total;
    });
  }

  outputFeeData() {
    this.feeData.taxableAmount = this.newPayFormModal.controls['taxableAmount' + this.extension].value;
    this.feeData.notTaxableAmount = this.newPayFormModal.controls['noTaxableAmount' + this.extension].value;
    this.feeData.feeDate = this.newPayFormModal.controls['feeDate' + this.extension].value;
    this.feeData.feeNumber = this.newPayFormModal.controls['feeNumber' + this.extension].value;
    this.feeData.ivaAmount = this.newPayFormModal.controls['ivaAmount' + this.extension].value;
    this.feeData.withHoldingAmount = this.newPayFormModal.controls['withHoldingAmount' + this.extension].value;
    this.outputForm.emit(this.feeData);
  }

}
