/* eslint-disable max-len */
import {
  Component,
  EventEmitter,
  OnInit, Optional, Output, ViewChild, ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { QuestionnairesManagerComponent } from '@rgi/questionnaires-manager';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { tap } from 'rxjs/operators';
import { CardsNavigationService } from '../../cards-navigation/cards-navigation.service';
import { ExtensionProperty, FACTOR_TYPE } from '../../enum/life-issue.enum';
import { ActionServiceErrorMessage, ActionServiceResponse } from '../../models/actions.model';
import { Instance, Questionnaire, QuestionnaireValue } from '../../models/policy.model';
import { ErrorType, LifeIssueMessage } from '../../models/response.model';
import { RoutableComponent } from '../../routable-component';
import { LicCacheService } from '../../services/lic-cache.service';
import { PolicyService } from '../../services/policy-service';
import { LicDateUtils } from '../../utils/lic-date-utils';
import { LicErrorsUtils } from '../../utils/lic-errors-utils';
import { LicPipUtils } from '../../utils/lic-pip-utils';
import { ActionsService } from './../../services/actions.service';
import { LifeSessionService } from './../../services/life-session-service';
import { LicObjectUtils } from '../../utils/lic-object-utils';
import { RgiRxPushMessage, RgiRxPushMessageHandlerService } from '@rgi/rx';

@Component({
  templateUrl: './goods-info.component.html',
  styleUrls: ['./goods-info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GoodsInfoComponent implements OnInit, RoutableComponent {
  @Output() navigation = new EventEmitter<string>();
  @Output() eventPropagation = new EventEmitter<any>();
  @ViewChild(QuestionnairesManagerComponent) qMc: QuestionnairesManagerComponent;

  public insuredIstances: any;
  public form: UntypedFormGroup;
  public questForm: UntypedFormGroup;
  public productCode: string;
  public effectiveDate: any;
  public productStructure: any;

  public _messages = [];
  public _warningMsgs = [];
  public _authoMsgs = [];
  public _blockingMsgs = [];
  public semaphore = false;
  public operator;

  /* QUESTIONARI */
  private _validQuestsCode: Map<string, boolean> = new Map<string, boolean>();

  public questionnairesList: Questionnaire[] = [];
  public showSectionQuest = false;
  public questViewMap = new Map<string, boolean>();
  public questMap = new Map<string, QuestionnaireValue>();
  public questFactorsArray: any[] = [];
  public ppevoQuestionnaires: Questionnaire[] = [];
  public showQuestPPEVO = false;
  public isQuestAccordionOpen = true;

  public proposalNumber: string;

  public messageFromPreviousPage: LifeIssueMessage[] = [];

  get validQuestsCode(): Map<string, boolean> {
    return this._validQuestsCode;
  }

  get getValidationMessages(): string[] {
    return this._messages;
  }

  get getWarningMessages(): string[] {
    return this._warningMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.WARNING)
    .map(msg => msg.message));
  }

  get getBlockingMessages(): string[] {
    return this._blockingMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.ERROR)
    .map(msg => msg.message));
  }

  get getAuthMessages(): string[] {
    return this._authoMsgs.concat(LicErrorsUtils.getPreviousPageMessages(this.messageFromPreviousPage, ErrorType.AUTH)
    .map(msg => msg.message));
  }

  get showAccordionQuestionnaires(): boolean {
    return !this.policyService.isFromPreventive && !this.policyService.isFromQuoteModification;
  }

  get isFromPreventive(): boolean {
    return this.policyService.isFromPreventive || this.policyService.isFromQuoteModification;
  }

  setmessages(messages: string[]) {
    this.pushMessageHandler.clearTag('validation');
    messages = LicObjectUtils.asValidArray(messages).concat(this._messages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'validation', status: 'danger', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setblockingMsgs(blockingMsgs: string[]) {
    this.pushMessageHandler.clearTag('blocking');
    const messages = LicObjectUtils.asValidArray(blockingMsgs).concat(this.getBlockingMessages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'blocking', status: 'danger', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setwarningMsgs(warningMsgs: string[]) {
    this.pushMessageHandler.clearTag('warning');
    const messages = LicObjectUtils.asValidArray(warningMsgs).concat(this.getWarningMessages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'warning', status: 'default', dismissible: false, options: {icon: 'rgi-ui-icon-alert'} }))
    );
  }

  public setauthoMsgs(authoMsgs: string[]) {
    this.pushMessageHandler.clearTag('auth');
    const messages = LicObjectUtils.asValidArray(authoMsgs).concat(this.getAuthMessages);
    this.pushMessageHandler.notify(
      ...LicObjectUtils.removeDuplicate(messages).map(m => new RgiRxPushMessage(m, { tag: 'auth', status: 'warning', dismissible: false, options: {icon: 'rgi-ui-icon-info'} }))
    );
  }


  constructor(
    protected cardsNavigationService: CardsNavigationService,
    protected policyService: PolicyService,
    protected actionService: ActionsService,
    protected lifeSessionService: LifeSessionService,
    protected translateService: TranslationWrapperService,
    protected cacheInvestments: LicCacheService,
    @Optional() protected cache: QuestionnaireCacheService,
    protected pushMessageHandler: RgiRxPushMessageHandlerService) { } // ERRORE QUI USAVE IMPL !!!!!!!!!!!!!!!!!!!!!!!!!!!

  ngOnInit(): void {
    this.pushMessageHandler.clearTag('validation');
    this.pushMessageHandler.clearTag('blocking');
    this.pushMessageHandler.clearTag('warning');
    this.pushMessageHandler.clearTag('auth');
    this.operator = this.lifeSessionService.getOperator();
    this.messageFromPreviousPage = this.cardsNavigationService.getPreviousMessages(this.getErrorStep());
    this.setblockingMsgs([]);
    this.setwarningMsgs([]);
    this.setauthoMsgs([]);
    this.initForm();
    if (this.insuredIstances && this.cache) {
      this.insuredIstances.forEach(insured => {
        const quests = this.cache.get(insured.name);
        if (quests && quests.size > 0) {
          this.showQuestPPEVO = true;
        }
      });
    }
  }

  protected getErrorStep(): number {
    return this.cardsNavigationService.STEP.DATI_DI_BENE.pos;
  }

  // necessario per visualizzare i questionari (PP+PPEVO) solo al termine del caricamento dei quest ppEvo
  public countQuest() {
    this.showQuestPPEVO = (this.qMc.getQuest().length + this.ppevoQuestionnaires.length) > 0;
  }

  public getSavedUUIDs(insured: any): string[] {
    return this.policyService.getPassProProSavedQuestionnaireUUIDs(insured.name);
  }

  public initForm() {
    this.form = new UntypedFormGroup({});

    /* QUESTIONNAIRES */
    this.questForm = new UntypedFormGroup({});
    this.questionnairesList.map((q) => {
      this.questViewMap.set(q.code, q.included);
    });

    this.questionnairesList = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires // TODO multi-assicurato
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType !== 'QPPEVO' && quest.included);
    this.showSectionQuest = this.questionnairesList.length > 0;

    this.insuredIstances = this.policyService.mainProposal.quote.product.assets[0].instances;
    this.insuredIstances.map((insured) => {
      this.form.addControl(insured.name, new UntypedFormGroup({}));
    });

    // Questionari PPEVO
    this.productCode = this.policyService.mainProposal.quote.product.code;
    this.effectiveDate = this.policyService.mainProposal.proposal.policyVersion.effectiveDate;
    this.productStructure = this.policyService.mainProposal.quote.product;
    this.ppevoQuestionnaires = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType === 'QPPEVO' && quest.included);

    this.insuredIstances.forEach(insured => { // Only one insured
      if (this.cache && !this.cache.get(insured.name)) {
        this.ppevoQuestionnaires.forEach((passQuest: Questionnaire) => this._validQuestsCode.set(passQuest.code, passQuest.compulsory));
        this.showQuestPPEVO = this.ppevoQuestionnaires.length > 0;
      }
    });
  }

  public updateFactors(submit: boolean, intermediateSave: boolean = false, modalOpen: Function = () => { }) {
    this.semaphore = true;
    let codes: string[] = [];

    // ASMC-11575 - azzero gli investimenti al cambio valore di un fattore
    if (!submit) {
      this.cacheInvestments.deleteSession(this.policyService.mainProposalResourceId);
    }

    setTimeout(() => {
      if (this.semaphore) {
        this.eventPropagation.emit('startLoader');
      }
    }, 500);

    // setto i questionari sulla proposal
    this.saveQuestIntoProposal();

    this.insuredIstances.forEach(insured => {
      const formInsured = this.form.get(insured.name) as UntypedFormGroup;
      insured.factors.forEach(fat => {
        const factorsForm = formInsured;
        if (factorsForm != null && factorsForm.get(fat.code) != null) {
          let valFromForm = factorsForm.get(fat.code).value;
          if (valFromForm != null) {
            if (LicObjectUtils.isFactorTypeEqual(fat.type, FACTOR_TYPE.DATE) && valFromForm) {
              valFromForm = LicDateUtils.convertToYMD(valFromForm);
            } else if (LicObjectUtils.isFactorTypeEqual(fat.type, FACTOR_TYPE.NUMERIC) && valFromForm.includes(',')) {
              valFromForm = valFromForm.replace(',', '.');
            }
          } else if (LicObjectUtils.isFactorTypeEqual(fat.type, FACTOR_TYPE.NUMERIC)) {
            valFromForm = '-1';
          }
          if (fat.value !== valFromForm) {
            fat.value = valFromForm;
            fat.manuallySet = true;
          }
        }
      });
    });
    let i = 0;
    for (const ins of this.insuredIstances) {
      this.policyService.mainProposal.quote.product.assets[0].instances[i].factors = ins.factors;
      i++;
    }

    this.policyService.saveProposal(false, submit).subscribe(
      (response: any) => {
        this.semaphore = false;
        this.insuredIstances = this.policyService.mainProposal.quote.product.assets[0].instances;

        codes = [];

        // Aggiungo i fattori mancanti
        this.insuredIstances.map((insured) => {
          insured.factors.map((fat) => {
            codes.push(fat.code);
          });

          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const insuredForm = this.form.get(insured.name) as UntypedFormGroup;

          // Rimuovo i vecchi controlli
          Object.keys(insuredForm.controls).forEach((factorCode) => {
            if (!codes.includes(factorCode)) {
              insuredForm.removeControl(factorCode);
            }
          });
        });

        console.log(this.policyService.mainProposal.quote.product.assets[0].instances[0].sections[0].units);
        this.cardsNavigationService.resetMessages(this.cardsNavigationService.STEP.DATI_DI_BENE.errorId);
        this.actionService.cleanErrorMessagesForStep(this.cardsNavigationService.STEP.DATI_DI_BENE.errorId);
        this.actionService.setAuthorization('goods_info', false);
        const nonBlockingMessages = [];
        const blockingCode = '0';
        if (submit) {
          this.actionService.getMsgByCodLivelloAzioni('PG;SB;B').subscribe((res: ActionServiceResponse) => {
            res.errorMessages.map((action: ActionServiceErrorMessage) => {
              this.actionService.setActionErrorMessage(this.cardsNavigationService.STEP.DATI_DI_BENE.errorId, action);
              if (Number(action.derogationLevel) === Number(blockingCode)) {
                this._blockingMsgs.push(action.messageDescription);
                this.setblockingMsgs(this._blockingMsgs);
              }
              if (+action.derogationLevel > +blockingCode) { // warning or auto
                if (!!this.actionService.getWarningErrors(this.operator, action)) {
                  nonBlockingMessages.push({type: ErrorType.WARNING, message: this.actionService.getWarningErrors(this.operator, action)});
                }
                if (!!this.actionService.getAuthoErrors(this.operator, action)) {
                  this.actionService.setAuthorization('goods_info', true);
                  nonBlockingMessages.push({type: ErrorType.AUTH, message: this.actionService.getAuthoErrors(this.operator, action)});
                }
              }
            });
            this.cardsNavigationService.setMessageForPage(this.cardsNavigationService.STEP.DATI_DI_BENE.errorId, nonBlockingMessages);
            this.eventPropagation.emit('stopLoader');
            if (this._blockingMsgs.length === 0) {
              if (!intermediateSave) {
                this.goToQuotationPage();
              } else {
                this.saveUndefinedProposal(modalOpen);
              }

            } else {
              this.checkViewQuest();
            }
          }, (err) => {
            console.log('Errore execute action');
            this.eventPropagation.emit('stopLoader');
          });
        } else {
          this.eventPropagation.emit('stopLoader');
        }

      }, err => {
        this.semaphore = false;
        console.log('Errore leggendo la proposta');
        this.eventPropagation.emit('stopLoader');
      });
  }

  goToQuotationPage() {
    this.cardsNavigationService.setCurrentCards(this.cardsNavigationService.STEP.QUOTAZIONE.route);
    this.navigation.emit(this.cardsNavigationService.STEP.QUOTAZIONE.route);
  }

  public onSubmit(saveUndefinedProposal: boolean = false, modalOpen: any = () => { }) {
    const resolvedKey = !!this.qMc && !!this.qMc.key ? this.qMc.key : undefined;
    this._messages = [];
    this._blockingMsgs = [];
    this.setmessages(this._messages);
    this.setblockingMsgs(this._blockingMsgs);

    if (this.checkGoodsInfoPage(resolvedKey)) {
      this.policyService.saveQuestAnd(!!resolvedKey ? this.cache : null).subscribe(() => {
        this.eventPropagation.emit('startLoader');
        this.policyService.resetQuotation();
        this.updateFactors(true, saveUndefinedProposal, modalOpen);
      });
    } else {
      this.checkDirty();
      console.log(this.form);
      this._messages.push(this.translateService.getImmediate('lic_error_mandatory_fields'));
      this.setmessages(this._messages);
    }
  }

  public saveUndefinedProposal(modalOpen: Function = () => { }) {
    this.policyService.pushExtensionProperty(ExtensionProperty.INTERMEDIATE_SAVE, 'true');

    this.eventPropagation.emit('startLoader');

    this.policyService.saveFromQuote(false).pipe(
      tap((response: any) => {
        this.semaphore = false;
        this.policyService.mainProposal.proposal = response.output.proposal;
        this.eventPropagation.emit('stopLoader');
        modalOpen();
        this.proposalNumber = this.policyService.mainProposal.proposal.contractData[0].proposalNumber;
      })
    ).subscribe();
  }

  public showSaveButton() {

    return !this.policyService.hideSaveButton();
  }


  public checkGoodsInfoPage(questKey): boolean {
    let allowNext = true;

    Object.keys(this.form.controls).forEach(insured => {
      const insuredForm = this.form.get(insured) as UntypedFormGroup;
      if (!!insuredForm.invalid) {
        allowNext = false;
      }
    });

    if (allowNext && this._messages.length > 0) {
      allowNext = false;
    }
    if (allowNext && this.showAccordionQuestionnaires) {
      allowNext = (!this.cache || this.cache.checkCompileByKey(questKey));
    }

    return allowNext;
  }

  public checkDirty() {
    Object.keys(this.form.controls).forEach(insured => {
      const insuredForm = this.form.get(insured) as UntypedFormGroup;
      if (!insuredForm.valid) {
        Object.keys(insuredForm.controls).forEach(fat => {
          insuredForm.get(fat).markAsDirty();
        });
      }
    });
  }

  public saveQuestionnaire(quest: QuestionnaireValue, onInit?: boolean) {
    this.questMap.set(quest.questionnaire.code, quest);

    if (!onInit) {
      this.updateFactors(false);
    }
  }

  public deleteQuestionnaire(questCode: string) {
    this.questMap.delete(questCode);
  }

  public checkViewQuest() {
    this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires.map((q) => {
      if (this.questViewMap.get(q.code) !== q.included) {
        if (!q.included) { // è diventato non visibile
          this.questMap.delete(q.code);
        }
        this.questViewMap.set(q.code, q.included);
      }
    });

    this.questionnairesList = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires // TODO multi-assicurato
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType !== 'QPPEVO');
    this.showSectionQuest = this.questionnairesList.length > 0;
    this.ppevoQuestionnaires = this.policyService.mainProposal.quote.product.assets[0].instances[0].questionnaires
      .filter((quest: Questionnaire) => quest.included === true && quest.codeType === 'QPPEVO');

    this._validQuestsCode = new Map<string, boolean>();
    this.ppevoQuestionnaires.forEach((passQuest: Questionnaire) => this._validQuestsCode.set(passQuest.code, passQuest.compulsory));
    if (this._validQuestsCode.size > 0) {
      this.insuredIstances.forEach(insured => {
        if (this.cache && !this.cache.get(insured.name)) {
          this.qMc.loadQuestionnairesByCode(this.validQuestsCode);
        } else {
          this.cache.setMandatory(this.validQuestsCode);
        }
      });
    } else {
      this.insuredIstances.forEach(insured => this.cache.delete(insured.name));
      if (this.qMc && this.qMc.questionnaireManagerService) {
        this.qMc.questionnaireManagerService.listChange.emit(this.qMc.questionnaireManagerService.getQuestionnaires());
      }
    }
  }

  public saveQuestIntoProposal() {
    // se non è contenuto nella mappa allora arriva da un'altra pagina e va mantenuto
    const questToCopy = [];
    this.policyService.mainProposal.proposal.questionnaires.map((q) => {
      if (!this.questViewMap.has(q.questionnaire.code)) {
        questToCopy.push(q);
      }
    });

    this.policyService.mainProposal.proposal.questionnaires = questToCopy;

    for (const questionnaire of this.questMap.values()) {
      this.policyService.mainProposal.proposal.questionnaires.push(questionnaire);
    }
  }


  public loaderQuestManager(event: string) {
    this.eventPropagation.emit(event);
  }

  public getInsuredFactor(insured: Instance) {
    return insured.factors.filter((f) => !LicPipUtils.isPipFactor(f));
  }


  public clickOnQuestAccordion() {
    this.isQuestAccordionOpen = !this.isQuestAccordionOpen;
  }

}
