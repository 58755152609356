import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EventNotificator} from '../event-notificator';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {InsuranceStatus} from '../models/domain-models/insurance-status';
import {InsuranceBackgroundProperties} from '../models/domain-models/insurance-background-properties';
import {CommonService} from '../common.service';
import {Enumerated} from '../models/domain-models/enumerated';
import {InsuranceBackgroundService} from './insurance-background.service';
import {InsuranceBackground} from '../models/domain-models/insuranceBackground';
import {ProposalService} from '../proposal.service';
import {Subscription} from 'rxjs';
import {Message} from '../models/message';
import {VariablesService} from '../variables/variables-service';
import {Variable} from '../models/domain-models/variable';
import {TranslateService} from '@ngx-translate/core';
import {AtrType} from './atr-type';
import {AtrBranchType} from './atr-branch-type';
import {PlateType} from './plate-type';
import {SubstitutionService} from '../substitution/substitution.service';
import {ApiDisabledComponents} from '../models/api-models/api-disabled-components';

@Component({
  selector: 'mic-insurance-background',
  templateUrl: './insurance-background.component.html',
  styleUrls: ['./insurance-background.component.scss']
})

export class InsuranceBackgroundComponent implements OnInit, OnDestroy, EventNotificator {

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() showATRHeader: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showATRTable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() insuranceBackgroundCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() riskCertificateError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateMessages: EventEmitter<Array<GenericEntity>> = new EventEmitter<Array<GenericEntity>>();
  @Output() showEditAtr: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() insuranceStatusCompletenessErrors: boolean;
  @Input() showEdit = true;
  @Input() avoidSubmitOnInit = true;
  @Input() showHeader = true;

  validationMessages = [];
  formMessages = [];
  submitted = false;

  relative = false;

  insuranceBackgroundForm: UntypedFormGroup;
  variablesForm: UntypedFormGroup;
  vehicleOrigins: Array<Enumerated>;
  bersaniValues: Array<GenericEntity>;
  originPlateTypes: Array<GenericEntity>;
  countries: Array<GenericEntity>;
  originSpecialPlateTypes: Array<GenericEntity>;
  vehicleTypeOriginValues: Array<GenericEntity>;
  vehicleTypeRelativeValues: Array<GenericEntity>;

  @Input() insuranceStatus: InsuranceStatus;

  insuranceBackgroundProperties: InsuranceBackgroundProperties;

  isOriginPlateSpecial = false;
  isOriginPlateForeign = false;
  isOriginPlateNumberEditable = false;
  isBersaniEditable = false;
  isOriginPlateTypeEditable = false;
  isOriginSpecialPlateEditable = false;
  isTelaio = false;

  isTelaioVisible = false;
  additionalDeclarationMandatory: boolean;
  variablesRows: Variable[][];
  ADDITIONAL_DECLARATION_VARIABLE_CODE;

  atrType = AtrType;
  atrBranchType = AtrBranchType;
  plateType = PlateType;
  insuranceStatusInitialized = false;
  countriesInitialized = false;
  enableConfirm = false;
  variablesChanged = false;
  insuranceStatusChanged = false;
  vehicleOriginToAtrTypeMap: Map<string, string> = new Map<string, string>();
  vehicleOriginToAtrBranchTypeMap: Map<string, string> = new Map<string, string>();
  insuranceBackgroundList: Array<GenericEntity> = Array<GenericEntity>();
  insuranceBackgroundRows: GenericEntity[][];
  insuranceBackground: InsuranceBackground;
  areaCode = 'INSURANCE_BACKGROUND';
  additionalDeclarationVariable: Variable;
  insuranceBackgroundVariables: Array<Variable> = new Array<Variable>();
  private subscriptions: Subscription = new Subscription();
  isRelativeVisible: boolean;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected commonService: CommonService,
    protected proposalService: ProposalService,
    protected insuranceBackgroundService: InsuranceBackgroundService,
    protected variableService: VariablesService,
    protected translate: TranslateService,
    protected substitutionService: SubstitutionService) {
    this.ADDITIONAL_DECLARATION_VARIABLE_CODE = InsuranceBackgroundService.ADDITIONAL_DECLARATION_VARIABLE_CODE;
  }

  get isAdditionalDeclarationMandatory(): boolean {
    return this.additionalDeclarationMandatory;
  }

  @Input()
  set isAdditionalDeclarationMandatory(isAdditionalDeclarationMandatory: boolean) {

    this.additionalDeclarationMandatory = isAdditionalDeclarationMandatory;
    if (this.variablesForm) {
      const additionalDeclarationContrtoll = this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
      if (additionalDeclarationContrtoll) {
        additionalDeclarationContrtoll.setValidators(isAdditionalDeclarationMandatory ? [Validators.required] : null);
        additionalDeclarationContrtoll.updateValueAndValidity();
        additionalDeclarationContrtoll.markAsDirty();
      }
    }
  }

  ngOnInit() {

    this.validationMessages.length = 0;

    if (this.insuranceStatus) {
      this.relative = this.insuranceStatus.relative;
    }

    this.variablesForm = this.formBuilder.group({});

    this.insuranceBackgroundForm = this.formBuilder.group({
      vehicleOrigin: [null, Validators.required],
      bersani: [null],
      originPlateType: [null],
      originPlateCountry: [null],
      originSpecialPlateType: [null],
      originPlateNumber: [null],
      vehicleTypeOrigin: [null],
      originChassis: [null],
      relative: [null],
      relativePlate: [null],
      vehicleTypeRelative: [null]
    });

    this.retrieveInsuranceStatus(true);
    const recalculateInsuranceStatusSubscription = this.insuranceBackgroundService.getRecalculateInsuranceStatusSignal().subscribe(
      (data) => {
        if (data) {
          this.insuranceStatus = data;
        } else {
          this.retrieveInsuranceStatus(false);
        }
      }
    );
    this.subscriptions.add(recalculateInsuranceStatusSubscription);
  }

  checkFormDisabled() {
    if (this.insuranceBackgroundForm.get('vehicleOrigin').disabled) {
      this.enableConfirm = false;
      this.manageRiskCertificateVisibility();
    }
  }

  doSubstitutionChecks() {
    this.substitutionService.checkPartyRoleChanged(this.proposalService.getContractId(), 3).subscribe((isOwnerChanged) => {
      if (isOwnerChanged || this.proposalService.getSubstitutionConfig().BREAK_CHAIN === '1') {
        this.cleanFields();
        this.insuranceBackgroundForm.get('vehicleOrigin').enable();
        this.insuranceBackgroundForm.get('bersani').enable();
      } else {
        this.checkFormDisabled();
      }
    });
  }

  additionalDeclarationChange(value: string) {
    this.variablesChanged = true;
    this.enableConfirmAndResetStatus();
  }

  enableConfirmAndResetStatus() {
    this.enableConfirm = true;
    this.insuranceBackgroundCompleted.emit(false);
  }

  onVehicleOriginChange() {
    if (!this.insuranceBackgroundForm) {
      return;
    }

    this.insuranceStatus.originPlate = null;
    this.insuranceStatus.vehicleTypeOrigin = null;
    this.insuranceStatus.relativePlate = null;
    this.insuranceStatus.vehicleTypeRelative = null;
    this.insuranceBackgroundForm.get('originPlateNumber').setValue(null);
    this.insuranceBackgroundForm.get('vehicleTypeOrigin').setValue(null);
    this.insuranceBackgroundForm.get('relativePlate').setValue(null);
    this.insuranceBackgroundForm.get('vehicleTypeRelative').setValue(null);

    const vehicleOrigin = this.insuranceBackgroundForm.get('vehicleOrigin').value;
    if (vehicleOrigin) {

      this.refreshVariablesStatus();

      const atrTypeCode = vehicleOrigin ? this.vehicleOriginToAtrTypeMap.get(vehicleOrigin.code) : undefined;
      const atrBranchTypeCode = vehicleOrigin ? this.vehicleOriginToAtrBranchTypeMap.get(vehicleOrigin.code) : undefined;

      // TODO al momento mantengo questo controllo per evitare chiamate inutili
      if (vehicleOrigin.id === 12 || vehicleOrigin.id === 16) {
        // if (atrTypeCode === this.atrType.ATR && atrBranchTypeCode !== this.atrBranchType.MANUAL) {

        const dateofbirth = this.proposalService.getDateOfBirth();
        this.insuranceBackgroundService.setVehicleOrigin(this.proposalService.getContractId(), vehicleOrigin.id, dateofbirth).subscribe(
          (variables) => {
            const insuranceBackgroundVariables = variables.filter(
              (element) => {
                return element.visibilityArea === 5;
              }
            );
            this.additionalDeclarationVariable = insuranceBackgroundVariables.find(
              (variable) => variable.identificationCode === this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
            this.insuranceBackgroundService.setVariables(insuranceBackgroundVariables);
            this.refreshInsuranceStatusForm();
          });
      } else {
        this.additionalDeclarationVariable = null;
        this.refreshInsuranceStatusForm();
      }
    }
  }

  manageInsuranceBackgroundForm() {
    const vehicleOrigin = this.insuranceBackgroundForm.get('vehicleOrigin').value;
    const atrTypeCode = vehicleOrigin ? this.vehicleOriginToAtrTypeMap.get(vehicleOrigin.code) : undefined;
    const atrBranchTypeCode = vehicleOrigin ? this.vehicleOriginToAtrBranchTypeMap.get(vehicleOrigin.code) : undefined;

    this.isOriginPlateNumberEditable = false;
    this.isBersaniEditable = false;
    this.isOriginSpecialPlateEditable = false;
    this.isOriginPlateForeign = false;
    this.isOriginPlateSpecial = false;
    this.isOriginPlateTypeEditable = false;
    this.isRelativeVisible = false;

    this.insuranceBackgroundForm.get('bersani').disable();
    this.insuranceBackgroundForm.get('originPlateType').disable();
    this.insuranceBackgroundForm.get('originPlateCountry').disable();
    this.insuranceBackgroundForm.get('originChassis').disable();
    this.insuranceBackgroundForm.get('originPlateNumber').disable();
    this.insuranceBackgroundForm.get('originSpecialPlateType').disable();
    this.insuranceBackgroundForm.get('vehicleTypeOrigin').disable();
    this.insuranceBackgroundForm.get('vehicleTypeRelative').disable();

    const vehicle = this.proposalService.getAsset();
    // ATR or ATR senza Compagnia
    if (atrTypeCode === this.atrType.ATR || atrTypeCode === this.atrType.ATR_SAME_COMPANY) {
      this.isOriginPlateNumberEditable = true;
      this.isOriginPlateTypeEditable = true;
      this.isRelativeVisible = true;
      this.isTelaioVisible = true;
      this.insuranceBackgroundForm.get('originPlateNumber').setValidators([Validators.required]);
      this.insuranceBackgroundForm.get('originPlateType').setValidators([Validators.required]);
      this.insuranceBackgroundForm.get('originPlateType').enable();


      if (this.insuranceBackgroundService.enableOriginPlateNumber(atrBranchTypeCode)) {
        this.insuranceBackgroundForm.get('originPlateNumber').enable();
      }

      const originPlateType = this.insuranceStatus.originPlateType;
      const newOriginPlateType = vehicle.plate ? vehicle.plate.plateType : (originPlateType ? originPlateType : null);

      let newOriginPlateNumber = this.insuranceStatus.originPlate;
      if (!newOriginPlateNumber) {
        newOriginPlateNumber = vehicle.plate && vehicle.plate.plateNumber ? vehicle.plate.plateNumber : null;
      }

      this.insuranceBackgroundForm.patchValue(
        {
          originPlateType: newOriginPlateType,
          originPlateNumber: newOriginPlateNumber,
          originChassis: this.insuranceStatus.originChassis
        }
      );
    }

    this.checkIfBersaniIsEditable();
  }

  onBersaniChange() {
    if (!this.insuranceBackgroundForm) {
      return;
    }

    const originPlateTypeControl = this.insuranceBackgroundForm.get('originPlateType');
    const originPlateNumberControl = this.insuranceBackgroundForm.get('originPlateNumber');

    if (!this.insuranceBackgroundForm.get('bersani').value) {
      this.isOriginPlateTypeEditable = false;

      this.insuranceBackgroundForm.get('originPlateType').clearValidators();
      this.insuranceBackgroundForm.get('originPlateType').disable();
      this.insuranceBackgroundForm.get('originPlateNumber').disable();
      this.insuranceBackgroundForm.get('originChassis').disable();
      this.insuranceBackgroundForm.patchValue(
        {
          originPlateType: null,
          originPlateNumber: null,
          originChassis: null
        }
      );
    } else {
      this.relative = false;
      this.insuranceBackgroundForm.patchValue({
        relativePlate: null,
        vehicleTypeRelative: null
      });

      this.isOriginPlateTypeEditable = true;
      originPlateTypeControl.setValidators([Validators.required]);
      originPlateTypeControl.enable();
      if (originPlateTypeControl.value) {
        this.isOriginPlateNumberEditable = true;
        originPlateNumberControl.setValidators([Validators.required]);
        originPlateNumberControl.enable();
      }
    }
  }

  manageRiskCertificateVisibility() {
    const vehicleOrigin = this.insuranceBackgroundForm.get('vehicleOrigin').value;
    const atrTypeCode = vehicleOrigin ? this.vehicleOriginToAtrTypeMap.get(vehicleOrigin.code) : undefined;

    if (atrTypeCode === this.atrType.ATR || atrTypeCode === this.atrType.ATR_SAME_COMPANY) {
      this.showATRHeader.emit(true);
      this.showATRTable.emit(true);
    } else if (atrTypeCode === this.atrType.BERSANI) {
      // Bersani
      this.showATRTable.emit(false);

      if (!this.insuranceBackgroundForm.get('bersani').value) {
        this.showATRHeader.emit(false);
      } else {
        this.showATRHeader.emit(true);
      }
    } else if (!atrTypeCode || (atrTypeCode && (atrTypeCode === this.atrType.NONE || atrTypeCode === this.atrType.EXPLICIT))) {
      this.showATRHeader.emit(false);
      this.showATRTable.emit(false);
    }

    // A rinnovo potrei avere una nuova immatricolazione oppure voltura al pra con pagellino, in questo caso visualizzo l atr
    if (this.insuranceStatus && this.insuranceStatus.policyExpirationDate && this.insuranceStatus.claimsReportYears) {
      const claimsReportLastYear = this.insuranceStatus.claimsReportYears[this.insuranceStatus.claimsReportYears.length - 1];
      if (claimsReportLastYear && claimsReportLastYear.principalResponsibility && claimsReportLastYear.principalResponsibility.id) {
        this.showATRTable.emit(true);
      }
    }

    this.evaluateEditAtr();

  }

  onOriginPlateTypeChange() {
    if (!this.insuranceBackgroundForm) {
      return;
    }

    this.insuranceBackgroundForm.patchValue(
      {
        originSpecialPlateType: null,
        originPlateCountry: null,
        originChassis: null,
        vehicleTypeOrigin: null,
        vehicleTypeRelative: null
      });

    const originPlateTypeValue = this.insuranceBackgroundForm.get('originPlateType').value;

    if (!originPlateTypeValue) {
      this.isOriginPlateNumberEditable = false;
      this.insuranceBackgroundForm.get('originPlateNumber').clearValidators();
      this.insuranceBackgroundForm.get('originPlateNumber').disable();
    } else {
      this.isOriginPlateNumberEditable = true;
      this.insuranceBackgroundForm.get('originPlateNumber').setValidators([Validators.required]);
      this.insuranceBackgroundForm.get('originPlateNumber').enable();
    }

    this.isTelaio = false;
    this.insuranceBackgroundForm.get('originChassis').clearValidators();
    this.insuranceBackgroundForm.get('originChassis').disable();

    this.insuranceBackgroundForm.get('vehicleTypeOrigin').clearValidators();
    this.insuranceBackgroundForm.get('vehicleTypeOrigin').disable();

    this.insuranceBackgroundForm.get('vehicleTypeRelative').clearValidators();
    this.insuranceBackgroundForm.get('vehicleTypeRelative').disable();

    // Targa Speciale
    if (originPlateTypeValue.code === this.plateType.SPECIAL) {
      this.isOriginPlateSpecial = true;
      this.isOriginPlateForeign = false;
      this.insuranceBackgroundForm.get('originPlateCountry').clearValidators();

      this.insuranceBackgroundForm.get('originPlateCountry').disable();

      this.isOriginSpecialPlateEditable = true;
      this.insuranceBackgroundForm.get('originSpecialPlateType').setValidators([Validators.required]);
      this.insuranceBackgroundForm.get('originSpecialPlateType').enable();
      // Targa Estera
    } else if (originPlateTypeValue.code === this.plateType.FOREIGN) {
      this.isOriginPlateSpecial = false;
      this.isOriginPlateForeign = true;
      this.isOriginSpecialPlateEditable = false;
      this.insuranceBackgroundForm.get('originPlateCountry').setValidators([Validators.required]);
      this.insuranceBackgroundForm.get('originPlateCountry').enable();
      this.insuranceBackgroundForm.get('originPlateNumber').clearValidators();

      this.insuranceBackgroundForm.get('originSpecialPlateType').disable();

      // Telaio
    } else if (originPlateTypeValue.code === this.plateType.CHASSIS) {
      this.isTelaio = true;
      this.isTelaioVisible = true;
      this.insuranceBackgroundForm.get('originChassis').setValidators([Validators.required]);
      this.insuranceBackgroundForm.get('originChassis').enable();
    } else {
      this.isOriginPlateSpecial = false;
      this.isOriginPlateForeign = false;
      this.isOriginSpecialPlateEditable = false;
      this.insuranceBackgroundForm.get('originPlateCountry').clearValidators();
      this.insuranceBackgroundForm.get('originPlateNumber').clearValidators();

      this.insuranceBackgroundForm.get('originPlateCountry').disable();
    }
  }

  compareGenericEntities(ent1, ent2): boolean {
    return ent1 && ent2 ? ent1.code === ent2.code : ent1 === ent2;
  }

  isInsuranceSourceChanged(insuranceStatus: InsuranceStatus) {

    const aProps = Object.getOwnPropertyNames(insuranceStatus);
    const bProps = Object.getOwnPropertyNames(this.insuranceStatus);

    if (aProps.length !== bProps.length) {
      this.insuranceStatusChanged = true;
    }
    aProps.forEach(value => {

      if (insuranceStatus && Object.getOwnPropertyDescriptors(insuranceStatus)[value] &&
        this.insuranceStatus && Object.getOwnPropertyDescriptors(this.insuranceStatus)[value]) {

        const aValue = Object.getOwnPropertyDescriptors(insuranceStatus)[value].value;
        const bValue = Object.getOwnPropertyDescriptors(this.insuranceStatus)[value].value;

        if (Object.prototype.toString.call(aValue) === '[object Array]') {
          // bIsEquivalent = this.isEquivalent(aValue, bValue);
        } else if (typeof aValue === 'object') {
          if (aValue !== null && bValue !== null) {
            if (!this.isEquivalent(aValue, bValue)) {
              this.insuranceStatusChanged = true;
            }
          } else if (aValue !== bValue) {
            this.insuranceStatusChanged = true;
          }
        } else {
          if (aValue !== bValue) {
            this.insuranceStatusChanged = true;
          }
        }
      }
    });
  }

  onSubmit() {

    if (this.areThereAnyReasonsForBlockingFormSubmit()) {
      return;
    }

    this.submitted = true;

    this.validateForm();

    if (!this.insuranceBackgroundForm) {
      return;
    }

    if (this.isFormValid()) {
      let insuranceStatus: InsuranceStatus = InsuranceStatus.createEmptyObject();
      insuranceStatus = Object.assign(insuranceStatus, this.insuranceStatus);
      this.updateInsuranceStatusAttrs(insuranceStatus);
      this.insuranceStatusChanged = false;
      this.isInsuranceSourceChanged(insuranceStatus);
      if (this.variablesChanged && !this.insuranceStatusChanged) {
        // Update only the variables
        this.updateAdditionalDeclarationVariable();
        this.manageRiskCertificateVisibility();
      } else {
        this.insuranceBackgroundService.onPreSubmitInsuranceStatus(insuranceStatus);
        this.updateVariables(insuranceStatus);
      }
    } else {
      this.insuranceStatusCompletenessErrors = true;
      this.insuranceBackgroundCompleted.emit(false);
    }
  }

  updateInsuranceStatusAttrs(insuranceStatus: InsuranceStatus) {
    const vehicleOriginControl = this.insuranceBackgroundForm.get('vehicleOrigin');
    const bersaniControl = this.insuranceBackgroundForm.get('bersani');
    const originPlateTypeControl = this.insuranceBackgroundForm.get('originPlateType');
    const originPlateCountryControl = this.insuranceBackgroundForm.get('originPlateCountry');
    const originSpecialPlateTypeControl = this.insuranceBackgroundForm.get('originSpecialPlateType');
    const originPlateNumberControl = this.insuranceBackgroundForm.get('originPlateNumber');
    const originChassisControl = this.insuranceBackgroundForm.get('originChassis');
    const vehicleTypeOriginControl = this.insuranceBackgroundForm.get('vehicleTypeOrigin');
    const vehicleTypeRelativeControl = this.insuranceBackgroundForm.get('vehicleTypeRelative');

    if (vehicleOriginControl) {
      insuranceStatus.updateVehicleOrigin(vehicleOriginControl.value);
    }
    if (bersaniControl) {
      insuranceStatus.updateIngressoBersani(bersaniControl.value);
    }
    if (originPlateTypeControl) {
      insuranceStatus.updateOriginPlateType(originPlateTypeControl.value);
    }
    if (originPlateCountryControl && originPlateCountryControl.value) {
      insuranceStatus.originPlateCountry = originPlateCountryControl.value.description;
    } else {
      insuranceStatus.originPlateCountry = null;
    }
    if (originSpecialPlateTypeControl) {
      insuranceStatus.updateSpecialOriginPlateType(originSpecialPlateTypeControl.value);
    }
    if (originPlateNumberControl) {
      insuranceStatus.originPlate = originPlateNumberControl.value ?
        originPlateNumberControl.value.toUpperCase() : null;
    }
    if (originChassisControl && originChassisControl.enabled) {
      insuranceStatus.originChassis = originChassisControl.value;
    }
    insuranceStatus.relative = this.relative;
    insuranceStatus.relativePlate = this.insuranceBackgroundForm.get('relativePlate').value;

    if (vehicleTypeOriginControl.value) {
      insuranceStatus.updateVehicleTypeOrigin(vehicleTypeOriginControl.value);
    }

    if (vehicleTypeRelativeControl.value) {
      insuranceStatus.updateVehicleTypeRelavite(vehicleTypeRelativeControl.value);
    } else {
      insuranceStatus.updateVehicleTypeRelavite(null);
    }
  }

  // Da overridare in EXT, se necessario
  areThereAnyReasonsForBlockingFormSubmit(): boolean {
    return false;
  }

  // Da overridare in EXT, se necessario
  onUpdateInsuranceStatusCompleted(): void {
    return;
  }

  // Da overridare in EXT, se necessario
  onInsuranceStatusRetrieved() {
    return;
  }

  checkIfBersaniIsEditable() {
    if (!this.insuranceBackgroundForm) {
      return;
    }

    const vehicleOrigin = this.insuranceBackgroundForm.get('vehicleOrigin').value;
    const atrTypeCode = vehicleOrigin ? this.vehicleOriginToAtrTypeMap.get(vehicleOrigin.code) : undefined;

    const isAtrTypeBersani2: boolean = atrTypeCode && atrTypeCode === this.atrType.BERSANI;
    const plate = this.proposalService.getAsset().plate;
    const plateType = plate ? plate.plateType : null;
    const isPlateTypeNewOrOldOrForeign = plate ? plateType.code === this.plateType.NEW
      || plateType.code === this.plateType.OLD || plateType.code === this.plateType.FOREIGN : true;
    this.isBersaniEditable = isAtrTypeBersani2 && isPlateTypeNewOrOldOrForeign;
    if (this.isBersaniEditable) {
      this.insuranceBackgroundForm.get('bersani').enable();
    } else {
      this.insuranceBackgroundForm.get('bersani').disable();
    }
  }

  valuesRelativeCheck() {
    this.relative = !this.relative;

    if (this.relative === false) {
      this.insuranceBackgroundForm.patchValue({
        relativePlate: null,
        vehicleTypeRelative: null
      });
    }
    this.evaluateEditAtr();
  }

  evaluateEditAtr() {
    let isEditAtrEnabled = this.proposalService.isFieldEnabled('editAtr');
    if (isEditAtrEnabled === null) {
      isEditAtrEnabled = true;
    }
    this.showEditAtr.emit(!this.relative && isEditAtrEnabled);
  }

  genericEntitiesTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  insuranceTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  variablesTrackByFn(index, variable: Variable) {
    return variable.identificationCode;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private addVariablesToInsuranceStatusForm(variablesChanged: boolean = false) {
    this.variablesForm.reset();
    const insBackgroundVariables = this.insuranceBackgroundService.getVariables();
    if (insBackgroundVariables) {
      insBackgroundVariables.forEach((variable) => {
        let value;
        if (variable.type === 4) {
          if (variable.value) {
            const date: Array<string> = variable.value.split('/');
            const day = Number(date[0]);
            const month = Number(date[1]) - 1;
            const year = Number(date[2]);
            value = new Date(year, month, day);
          } else {
            value = null;
          }
        } else {
          value = variable.value;
        }
        const validator = variable.compulsory ? Validators.required : null;
        const variableFormControl = this.variablesForm.get(variable.identificationCode);

        if (variableFormControl) {
          variableFormControl.setValue(value, {emitEvent: false});
          variableFormControl.setValidators(validator);
        } else {
          this.variablesForm.addControl(variable.identificationCode, new UntypedFormControl(value, validator));
        }

        if (variable.compulsory && variable.editable && variable.visible
          && !variable.value && !this.enableConfirm) {

          this.additionalDeclarationMandatory = true;
          this.enableConfirmAndResetStatus();
        }
      });

      if (variablesChanged) {
        this.enableConfirmAndResetStatus();
      }

      const variablesNum = insBackgroundVariables.length;

      if (variablesNum === 0) {
        return;
      }

      insBackgroundVariables.sort(
        (a: Variable, b: Variable) => {
          if (a.order > b.order) {
            return 1;
          } else if (a.order < b.order) {
            return -1;
          } else {
            return 0;
          }
        }
      );

      this.variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 2));

      let i: number;
      let j = 0;

      for (i = 0; i < variablesNum; i++) {
        if (i !== 0 && i % 2 === 0) {
          j++;
        }
        if (!this.variablesRows[j]) {
          this.variablesRows[j] = [];
        }
        if (insBackgroundVariables[i].identificationCode !== this.ADDITIONAL_DECLARATION_VARIABLE_CODE) {
          this.variablesRows[j].push(insBackgroundVariables[i]);
        }
      }
    }
  }

  private retrieveInsuranceStatus(isOnInit: boolean) {
    const dateofbirth = this.proposalService.getDateOfBirth();
    this.insuranceBackgroundService.getInsuranceStatus(this.proposalService.getContractId(), dateofbirth).subscribe(
      data => {
        this.insuranceBackgroundService.setCorrectIdOnCompany(data);
        this.insuranceBackgroundService.setInsuranceStatus(data);
        this.insuranceStatus = data;
        this.onInsuranceStatusRetrieved();

        this.relative = this.insuranceStatus.relative;

        this.variableService.getVariables().subscribe(
          (variables) => {
            // this.variables = variables;
            const insuranceBackgroundVariables = new Array<Variable>();
            variables.forEach(
              (element) => {
                if (element.visibilityArea === 5) {
                  insuranceBackgroundVariables.push(element);
                }
              }
            );
            this.insuranceBackgroundService.setVariables(insuranceBackgroundVariables);
            this.initInsuranceBackground();
            this.addVariablesToInsuranceStatusForm();
            this.manageSectionFormDisabling();
            if (this.proposalService.isSubstitution) {
              this.doSubstitutionChecks();
            } else {
              if (isOnInit && !this.avoidSubmitOnInit) {
                this.onSubmit();
              } else {
                this.checkFormDisabled();
              }
            }
          }
        );
      }, err => {
        this.insuranceStatusInitialized = true;
      }
    );
  }

  manageSectionFormDisabling() {
    if (this.isSectionEditable(ApiDisabledComponents.SECTION_INSURANCE_STATUS) === false) {
      this.insuranceBackgroundForm.disable();
      this.variablesForm.disable();
    }
  }

  isSectionEditable(sectionKey) {
    return this.proposalService.isFieldEnabled(sectionKey) === false ? false : true;
  }


  private initInsuranceBackground() {
    this.insuranceBackground = this.insuranceBackgroundService.populateArrayInsuranceBackground(this.insuranceStatus);
    this.insuranceBackgroundList = this.insuranceBackground.insuranceBackgroundList;
    this.insuranceBackgroundRows = this.insuranceBackground.insuranceBackgroundRows;

    this.insuranceBackgroundProperties = this.insuranceStatus.properties;
    this.originPlateTypes = this.insuranceStatus.originPlateTypeValues;
    this.originSpecialPlateTypes = this.insuranceStatus.originSpecialPlateTypeValues;
    this.vehicleTypeOriginValues = this.insuranceStatus.vehicleTypeOriginValues;
    this.vehicleTypeRelativeValues = this.insuranceStatus.vehicleTypeRelativeValues;
    this.bersaniValues = this.insuranceStatus.bersaniValues;

    let vehicleOrigin;
    if (this.insuranceStatus.vehicleOrigin) {
      vehicleOrigin = this.insuranceStatus.vehicleOriginValues.find(
        item => item.id === this.insuranceStatus.vehicleOrigin.id  // && item.code === this.insuranceStatus.vehicleOrigin.code
      );
    }

    this.insuranceBackgroundForm.patchValue({
      vehicleOrigin,
      bersani: this.insuranceStatus.ingressoBersani,
      originPlateType: this.insuranceStatus.originPlateType,
      originPlateCountry: this.insuranceStatus.originPlateCountry,
      originSpecialPlateType: this.insuranceStatus.originSpecialPlateType,
      originPlateNumber: this.insuranceStatus.originPlate,
      originChassis: this.insuranceStatus.originChassis,
      relativePlate: this.insuranceStatus.relativePlate,
      relative: this.insuranceStatus.relative,
      vehicleTypeOrigin: this.insuranceStatus.vehicleTypeOrigin,
      vehicleTypeOriginValues: this.insuranceStatus.vehicleTypeOriginValues,
      vehicleTypeRelative: this.insuranceStatus.vehicleTypeRelative,
      vehicleTypeRelativeValues: this.insuranceStatus.vehicleTypeRelativeValues
    });

    this.insuranceBackgroundVariables = this.insuranceBackgroundService.getVariables();
    this.additionalDeclarationVariable = this.insuranceBackgroundVariables.find(
      (variable) => variable.identificationCode === this.ADDITIONAL_DECLARATION_VARIABLE_CODE);

    this.insuranceBackgroundForm.get('bersani').disable();
    this.insuranceBackgroundForm.get('originPlateType').disable();
    this.insuranceBackgroundForm.get('originSpecialPlateType').disable();
    this.insuranceBackgroundForm.get('vehicleTypeOrigin').disable();
    this.insuranceBackgroundForm.get('vehicleTypeRelative').disable();

    this.insuranceStatusInitialized = true;

    const insuranceBackgroundFormValueChangesSubscription = this.insuranceBackgroundForm.valueChanges.subscribe((val) => {
      const isVehicleOriginEnabled = this.insuranceBackgroundForm.get('vehicleOrigin').enabled;
      if (this.countriesInitialized && this.insuranceStatusInitialized && isVehicleOriginEnabled) {
        this.enableConfirmAndResetStatus();
      }
      this.validateForm();
    });

    this.subscriptions.add(insuranceBackgroundFormValueChangesSubscription);

    const originPlateNumberValueChangesSubscription = this.insuranceBackgroundForm.controls.originPlateNumber.valueChanges.subscribe(
      val => {
        const mandatory = this.additionalDeclarationMandatory;
        this.additionalDeclarationMandatory = false;
        const additionalDeclarationContrtoll = this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
        if (additionalDeclarationContrtoll && !(mandatory && this.additionalDeclarationMandatory)) {
          additionalDeclarationContrtoll.setValue(null);
          additionalDeclarationContrtoll.setValidators(this.additionalDeclarationMandatory ? [Validators.required] : null);
          additionalDeclarationContrtoll.updateValueAndValidity();
          additionalDeclarationContrtoll.markAsDirty();
        }
      }
    );
    this.subscriptions.add(originPlateNumberValueChangesSubscription);

    this.initializeVehicleOriginsAndATRTypes();
    this.manageRiskCertificateVisibility();
    this.initializeCountries();
  }

  private isFormValid(): boolean {
    let allFieldsValid = true;

    const vehicleOrigin = this.insuranceBackgroundForm.controls.vehicleOrigin;
    const originPlateNumber = this.insuranceBackgroundForm.controls.originPlateNumber;

    if (vehicleOrigin && vehicleOrigin.value && originPlateNumber && originPlateNumber.value && this.insuranceBackgroundForm.touched) {
      const atrTypeCode = vehicleOrigin ? this.vehicleOriginToAtrTypeMap.get(vehicleOrigin.value.code) : undefined;

      if (atrTypeCode === this.atrType.BERSANI) {
        const plateNumber = this.proposalService.getPlateNumber();

        if (plateNumber && originPlateNumber.value.toUpperCase() === plateNumber.plateNumber) {
          originPlateNumber.setErrors({samePlate: true});
          allFieldsValid = false;
          this.validationMessages.length = 0;

          this.validationMessages.push(new Message(this.areaCode,
            'Origin Plate Number should be different from the Input Plate Number'));
          return;
        }
      }
    }

    Object.keys(this.insuranceBackgroundForm.controls).forEach(field => {
      const formField = this.insuranceBackgroundForm.get(field);
      if (formField.invalid) {
        formField.markAsDirty();
        allFieldsValid = false;
      }
    });

    Object.keys(this.variablesForm.controls).forEach(field => {
      const formField = this.variablesForm.get(field);
      if (formField.invalid && this.isFormFieldVisible(field)) {
        formField.markAsDirty();
        allFieldsValid = false;
      }
    });
    return allFieldsValid;
  }

  // Se il parametro additionalDeclarationOnly === true, viene aggiornato solo il fattore _2DICAG;

  private initializeVehicleOriginsAndATRTypes() {
    this.vehicleOrigins = this.insuranceStatus.vehicleOriginValues;
    this.vehicleOrigins.forEach((vehicleOrigin) => {
      const atrType = 'ATR_TYPE';
      const branchType = 'BRANCH_TYPE';

      this.vehicleOriginToAtrTypeMap.set(vehicleOrigin.code, vehicleOrigin.additionalData[atrType]);
      this.vehicleOriginToAtrBranchTypeMap.set(vehicleOrigin.code, vehicleOrigin.additionalData[branchType]);
    });

    if (this.insuranceStatus.vehicleOrigin) {
      this.manageInsuranceBackgroundForm();
    }

    if (this.insuranceBackgroundForm.controls.bersani.value) {
      if (this.insuranceBackgroundForm.get('originPlateType').value) {
        this.isOriginPlateTypeEditable = true;
        this.insuranceBackgroundForm.get('originPlateType').setValidators([Validators.required]);
        this.insuranceBackgroundForm.get('originPlateType').enable();
      }
      if (this.insuranceBackgroundForm.get('originPlateNumber').value) {
        this.isOriginPlateNumberEditable = true;
        this.insuranceBackgroundForm.get('originPlateNumber').setValidators([Validators.required]);
        this.insuranceBackgroundForm.get('originPlateNumber').enable();
      }
    }
  }

  private initializeCountries() {
    this.commonService.getEnumValues('COUNTRIES', null).subscribe(data => {
        this.countries = data;
      },
      _ => {
      },
      () => {
        this.countriesInitialized = true;
        this.insuranceBackgroundForm.updateValueAndValidity();
      });
  }

  private cleanFields() {
    const insuranceStatus: InsuranceStatus = InsuranceStatus.createEmptyObject();
    // patch values
    this.insuranceBackgroundForm.patchValue({
      bersani: insuranceStatus.ingressoBersani,
      originPlateType: insuranceStatus.originPlateType,
      originPlateCountry: insuranceStatus.originPlateCountry,
      originSpecialPlateType: insuranceStatus.originSpecialPlateType,
      originPlateNumber: insuranceStatus.originPlate,
      originChassis: insuranceStatus.originChassis,
      relativePlate: insuranceStatus.relativePlate,
      relative: insuranceStatus.relative,
      vehicleTypeOrigin: insuranceStatus.vehicleTypeOrigin,
      vehicleTypeRelative: insuranceStatus.vehicleTypeRelative
    });
  }

  private refreshVariablesStatus() {
    const vehicleOriginControl = this.insuranceBackgroundForm.get('vehicleOrigin');
    this.cleanFields();
    let insuranceStatus: InsuranceStatus = InsuranceStatus.createEmptyObject();
    insuranceStatus = Object.assign(insuranceStatus, this.insuranceStatus);
    this.updateInsuranceStatusAttrs(insuranceStatus);
    insuranceStatus.updateVehicleOrigin(vehicleOriginControl.value);
    const dateOfBirth = this.proposalService.getDateOfBirth();
    const contractId = this.proposalService.getContractId();
    this.insuranceBackgroundService.updateInsuranceStatus(contractId, insuranceStatus, dateOfBirth).subscribe(
      (data) => {
        this.insuranceBackgroundService.setCorrectIdOnCompany(data);
        this.insuranceStatus = data;
        this.variableService.getVariables().subscribe(
          (variables: Array<Variable>) => {
            const insuranceStatusVariables = variables.filter(
              (element) => {
                return element.visibilityArea === 5;
              }
            );
            this.insuranceBackgroundService.setVariables(insuranceStatusVariables);
            this.addVariablesToInsuranceStatusForm();
            this.validateForm();
          }
        );
      }
    );
  }

  private refreshInsuranceStatusForm() {

    this.insuranceBackgroundForm.get('originPlateNumber').clearValidators();
    this.insuranceBackgroundForm.get('originPlateType').clearValidators();
    this.insuranceBackgroundForm.get('originSpecialPlateType').clearValidators();
    this.insuranceBackgroundForm.get('originPlateCountry').clearValidators();
    this.insuranceBackgroundForm.get('originChassis').clearValidators();
    this.insuranceBackgroundForm.get('vehicleTypeOrigin').clearValidators();
    this.insuranceBackgroundForm.get('vehicleTypeRelative').clearValidators();

    this.insuranceBackgroundForm.patchValue(
      {
        originPlateType: null,
        bersani: null,
        originSpecialPlateType: null,
        originPlateCountry: null,
        originPlateNumber: null,
        originChassis: null,
        vehicleTypeOrigin: null,
        vehicleTypeRelative: null
      });

    if (this.additionalDeclarationVariable) {
      if (!this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE)) {
        const validator = this.additionalDeclarationVariable.compulsory ? Validators.required : null;
        const addDeclVarFormControl = this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
        if (addDeclVarFormControl) {
          addDeclVarFormControl.setValue(this.additionalDeclarationVariable.value, {emitEvent: false});
          addDeclVarFormControl.setValidators(validator);
        } else {
          this.variablesForm.addControl(this.ADDITIONAL_DECLARATION_VARIABLE_CODE,
            new UntypedFormControl(this.additionalDeclarationVariable.value, validator));
        }
      } else {
        this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE)
          .setValue(this.additionalDeclarationVariable.value);
      }
      this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE).updateValueAndValidity();
      this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE).markAsDirty();
    } else {
      this.variablesForm.removeControl(this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
    }

    this.manageInsuranceBackgroundForm();
  }

  private isEquivalent(a: object, b: object): boolean {

    let bEquivalent = true;
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      bEquivalent = false;
    }
    aProps.forEach(value => {

      const aValue = Object.getOwnPropertyDescriptors(a)[value].value;
      const bValue = Object.getOwnPropertyDescriptors(b)[value].value;

      if (Object.prototype.toString.call(aValue) === '[object Array]') {
        // bIsEquivalent = this.isEquivalent(aValue, bValue);
      } else if (typeof aValue === 'object') {
        if (aValue !== null && bValue !== null) {
          bEquivalent = this.isEquivalent(aValue, bValue);
        } else if (aValue !== bValue) {
          bEquivalent = false;
        }
      } else {
        if (aValue !== bValue) {
          bEquivalent = false;
        }
      }
    });

    return bEquivalent;
  }

  updateInsuranceStatus(insuranceStatus: InsuranceStatus, inputDateOfBirth, additionalDeclaration: boolean) {

    this.insuranceBackgroundService.updateInsuranceStatus(this.proposalService.getContractId(),
      insuranceStatus, inputDateOfBirth).subscribe((data: InsuranceStatus) => {
        this.insuranceBackgroundService.setCorrectIdOnCompany(data);
        this.onUpdateInsuranceStatusCompleted();
        this.insuranceBackgroundService.setInsuranceStatus(data);
        this.insuranceStatusCompletenessErrors = true;
        this.insuranceStatus = data;
        this.onUpdateInsuranceStatusCompleted();

        if (data.messages && data.messages.length > 0) {
          this.updateMessages.emit(data.messages);
        }

        this.relative = this.insuranceStatus.relative;
        this.insuranceBackgroundForm.patchValue({
          relative: this.relative,
          relativePlate: this.relative ? this.insuranceStatus.relativePlate : null,
          vehicleTypeRelative: this.relative ? this.insuranceStatus.vehicleTypeRelative : null
        });

        if (data.originPlate && data.vehicleTypeOrigin) {
          this.insuranceBackgroundForm.get('vehicleTypeOrigin').setValue(data.vehicleTypeOrigin);
          this.insuranceBackgroundForm.get('vehicleTypeOrigin').disable();
        } else {
          this.insuranceBackgroundForm.get('vehicleTypeOrigin').enable();
        }

        if (data.relativePlate && data.vehicleTypeRelative) {
          this.insuranceBackgroundForm.get('vehicleTypeRelative').setValue(data.vehicleTypeRelative);
          this.insuranceBackgroundForm.get('vehicleTypeRelative').disable();
        } else {
          this.insuranceBackgroundForm.get('vehicleTypeRelative').enable();
        }

        this.evaluateEditAtr();

        const isInsuranceStatusCallError = this.insuranceBackgroundService.isInsuranceStatusCallError(this.insuranceStatus);
        // compilare atr manualmente mettendo un blocco finche non compila tutto
        // il comportamento del prodotto ritorna false e non blocca in base alla provenienza della situazione assicurativa
        const errorRiskCertificate = this.insuranceBackgroundService.isManualATR(this.insuranceStatus.vehicleOrigin);

        if (isInsuranceStatusCallError || errorRiskCertificate) {
          this.riskCertificateError.emit(true);
        } else {
          this.riskCertificateError.emit(false);
        }
        if ((this.insuranceStatus.completenessErrors && this.insuranceStatus.completenessErrors.length > 0)
          || (this.insuranceStatus.correctnessErrors && this.insuranceStatus.correctnessErrors.length > 0)) {
          this.insuranceStatus.completenessErrors.forEach(
            (completenessError) => {
              this.formMessages.push(completenessError.description);
            }
          );
          this.insuranceStatus.correctnessErrors.forEach(
            (correctnessError) => {
              this.formMessages.push(correctnessError.description);
            }
          );
          this.enableConfirm = false;
          return;
        }

        this.insuranceStatusCompletenessErrors = false;
        this.removeInsuranceStatusCompletenessErrors();

        this.manageRiskCertificateVisibility();

        this.validationMessages.length = 0;

        this.insuranceBackground = this.insuranceBackgroundService.populateArrayInsuranceBackground(this.insuranceStatus);
        this.insuranceBackgroundList = this.insuranceBackground.insuranceBackgroundList;
        this.insuranceBackgroundRows = this.insuranceBackground.insuranceBackgroundRows;

        this.updateAdditionalDeclarationVariable();
        this.variableService.getVariables().subscribe(
          (variables) => {
            this.variableService.setVariables(variables);
          }
        );
      });
  }

  // Altrimenti vengono aggiornati tutti i fattori presenti in situazione assicurativa TRANNE il _2DICAG.
  private updateVariablesValue(additionalDeclarationOnly: boolean) {

    this.insuranceBackgroundVariables = this.insuranceBackgroundService.getVariables();

    if (!this.insuranceBackgroundVariables) {
      this.insuranceBackgroundVariables = new Array<Variable>();
    }

    if (this.insuranceBackgroundVariables.length === 0) {
      const variableObj = {} as Variable;
      variableObj.code = this.ADDITIONAL_DECLARATION_VARIABLE_CODE;
      variableObj.identificationCode = this.ADDITIONAL_DECLARATION_VARIABLE_CODE;
      this.updateSingleVariableValue(variableObj);
    } else {
      this.insuranceBackgroundVariables.forEach(
        (insuranceBackgroundVariable) => {
          if (additionalDeclarationOnly) {
            if (insuranceBackgroundVariable.identificationCode === this.ADDITIONAL_DECLARATION_VARIABLE_CODE) {
              this.updateSingleVariableValue(insuranceBackgroundVariable);
            }
          } else {
            if (insuranceBackgroundVariable.identificationCode !== this.ADDITIONAL_DECLARATION_VARIABLE_CODE) {
              this.updateSingleVariableValue(insuranceBackgroundVariable);
            }
          }
        }
      );
    }

  }

  private updateSingleVariableValue(insuranceBackgroundVariable: Variable) {
    const variableFormControl = this.variablesForm.get(insuranceBackgroundVariable.identificationCode);

    if (variableFormControl) {
      const variable = this.insuranceBackgroundVariables.find(
        variab => {
          return variab.identificationCode === insuranceBackgroundVariable.identificationCode;
        }
      );
      if (variable) {
        variable.value = variableFormControl.value;
      } else {
        const variableObj = {} as Variable;
        variableObj.code = insuranceBackgroundVariable.identificationCode;
        variableObj.identificationCode = insuranceBackgroundVariable.identificationCode;
        variableObj.value = variableFormControl.value;
        variableObj.type = 0;
        this.insuranceBackgroundVariables.push(variableObj);
      }
    }
  }

  private updateVariables(insuranceStatus) {
    const inputDateOfBirth = this.proposalService.getDateOfBirth();
    this.updateVariablesValue(false);
    this.variableService.updateVariables(this.insuranceBackgroundVariables).subscribe(
      (data) => {
        const additionalDeclaration = this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
        this.updateInsuranceStatus(insuranceStatus, inputDateOfBirth, additionalDeclaration ? true : false);
      }
    );
  }

  protected updateAdditionalDeclarationVariable() {

    const additionalDeclarationControl = this.variablesForm.get(this.ADDITIONAL_DECLARATION_VARIABLE_CODE);

    this.updateVariablesValue(true);


    this.variableService.updateVariables(this.insuranceBackgroundVariables).subscribe(
      element => {
        if (additionalDeclarationControl) {
          this.variableService.setVariables(element);
          const additionalDeclaration = element.filter(
            elem => {
              return elem.code === this.ADDITIONAL_DECLARATION_VARIABLE_CODE && elem.visibilityArea === 5;
            }
          ).values().next().value;

          const insBackgroundVariables = this.insuranceBackgroundService.getVariables();
          if (insBackgroundVariables) {
            let additionalDecl: Variable = insBackgroundVariables.find(
              (insBackgroundVar) => insBackgroundVar.code === this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
            if (additionalDecl) {
              additionalDecl = this.additionalDeclarationVariable;
            } else {
              insBackgroundVariables.push(this.additionalDeclarationVariable);
            }
          }

          if (additionalDeclaration && additionalDeclaration.compulsory) {
            this.additionalDeclarationMandatory = true;
          } else {
            this.additionalDeclarationMandatory = false;
          }
          additionalDeclarationControl.setValidators(this.additionalDeclarationMandatory ? [Validators.required] : null);
          additionalDeclarationControl.updateValueAndValidity();
          additionalDeclarationControl.markAsDirty();

          if (!additionalDeclarationControl.value && this.additionalDeclarationMandatory) {
            this.insuranceStatusCompletenessErrors = true;
            this.showATRHeader.emit(false);
            this.showATRTable.emit(false);
            this.enableConfirmAndResetStatus();
          } else {
            this.insuranceBackgroundCompleted.emit(true);

            this.enableConfirm = false;

            this.insuranceStatusCompletenessErrors = false;
          }
        } else {
          this.insuranceBackgroundCompleted.emit(true);

          this.enableConfirm = false;
        }
      }
    );
  }

  protected removeInsuranceStatusCompletenessErrors() {
    const completenessErrors = this.proposalService.getApiContract().completenessErrors;
    if (completenessErrors) {
      for (let index = 0; index < completenessErrors.length; index++) {
        if ('INS_STAT_NOT_AVAILABLE' === completenessErrors[index].code) {
          completenessErrors.splice(index, 1);
          index -= 1;
          continue;
        }
        if ('INS_STAT_NOT_COMPLETE' === completenessErrors[index].code) {
          completenessErrors.splice(index, 1);
          index -= 1;

        }
      }
      this.proposalService.setCompletenessErrors(completenessErrors);
    }
  }

  protected validateForm() {
    if (this.submitted === true) {
      const controls = this.insuranceBackgroundForm.controls;

      /* I don't use the messageService for not append this messages under the confirm button */
      if (this.formMessages) {
        this.formMessages.length = 0;
      } else {
        this.formMessages = [];
      }

      if (controls.vehicleOrigin.errors && controls.vehicleOrigin.errors.required) {
        this.formMessages.push('Vehicle origin is mandatory');
      }
      if (controls.bersani.enabled && controls.bersani.errors && controls.bersani.errors.required) {
        this.formMessages.push('Law 134 Benefit is mandatory');
      }
      if (controls.originPlateType.enabled && controls.originPlateType.errors && controls.originPlateType.errors.required) {
        this.formMessages.push('Origin plate type is mandatory');
      }
      if (controls.originPlateNumber.enabled && controls.originPlateNumber.errors && controls.originPlateNumber.errors.required) {
        this.formMessages.push('Origin plate number is mandatory');
      }
      if (this.relative === true && (controls.relativePlate.value === null || controls.relativePlate.value === '')) {
        this.formMessages.push('Family vehicle licence plate number is mandatory');
        controls.relativePlate.setErrors({required: true});
      } else {
        controls.relativePlate.setErrors(null);
      }

      this.insuranceBackgroundVariables = this.insuranceBackgroundService.getVariables();

      if (this.insuranceBackgroundVariables) {
        this.insuranceBackgroundVariables.filter(variable => variable.visible === true).forEach(
          (variable) => {
            const variableFormControl = this.variablesForm.get(variable.identificationCode);
            if (variableFormControl && variableFormControl.enabled
              && variableFormControl.errors && variableFormControl.errors.required) {

              const msg = this.translate.instant(
                '{{val}} is mandatory', {val: (variable.extendedDescription ? variable.extendedDescription : variable.description)}
              );
              this.formMessages.push(msg);
            }
          }
        );
      }
      this.eventPropagation.emit('layoutChanged');
    }
  }

  onVariableChange() {
    this.updateVariablesValue(false);
    this.variableService.updateVariables(this.insuranceBackgroundVariables).subscribe(
      () => {
        let insuranceStatus: InsuranceStatus = InsuranceStatus.createEmptyObject();
        insuranceStatus = Object.assign(insuranceStatus, this.insuranceStatus);
        this.updateInsuranceStatusAttrs(insuranceStatus);
        const dateOfBirth = this.proposalService.getDateOfBirth();
        const contractId = this.proposalService.getContractId();
        // workaround to update variable defaults
        this.insuranceBackgroundService.updateInsuranceStatus(contractId, insuranceStatus, dateOfBirth).subscribe(
          (data) => {
            this.insuranceBackgroundService.setCorrectIdOnCompany(data);
            this.insuranceStatus = data;
            this.variableService.getVariables().subscribe(
              (variables: Array<Variable>) => {
                const insuranceBackgroundVariables = variables.filter(v => v.visibilityArea === 5);
                this.insuranceBackgroundService.setVariables(insuranceBackgroundVariables);
                this.additionalDeclarationVariable = insuranceBackgroundVariables.find(
                  (variable) => variable.identificationCode === this.ADDITIONAL_DECLARATION_VARIABLE_CODE);
                this.addVariablesToInsuranceStatusForm(true);
              }
            );
          }
        );
      }
    );
  }

  isFormFieldVisible(code: string): boolean {
    const variable = this.insuranceBackgroundService.getVariables().find(el => el.code === code);
    return variable ? variable.visible : false;
  }

}
