import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcPaymentStepComponent} from './lpc-payment-step.component';
import {AngularIbanModule} from 'angular-iban';
import {NgSelectModule} from '@ng-select/ng-select';
import {ReactiveFormsModule} from '@angular/forms';
import {LpcPaymentFieldsModule} from '../lpc-payment-fields/lpc-payment-fields.module';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';

@NgModule({
    declarations: [LpcPaymentStepComponent],
    exports: [
        LpcPaymentStepComponent
    ],
  imports: [
    CommonModule,
    AngularIbanModule,
    NgSelectModule,
    ReactiveFormsModule,
    LpcPaymentFieldsModule,
    PortalNgCoreModule,
    RgiRxI18nModule,
    LpcRequiredModule,
    RgiRxFormElementsModule
  ]
})
export class LpcPaymentStepModule { }
