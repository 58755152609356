import { LicTranslationsKeys } from './lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_EN: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: 'Policy number {{num}} issued correctly',
  lic_ProposalCorrectlyIssued: 'Proposal number {{num}} issued correctly',
  lic_LifeProposalCorrectlyIssued: 'Life Proposal number {{num}} issued correctly',
  lic_LifePolicyCorrectlyIssued: 'Life Policy number {{num}} issued correctly',
  lic_DamagesPolicyCorrectlyIssued: 'Damages Policy number {{num}} issued correctly',
  lic_AuthorizatioProposalForwarded: 'The request of authorization for the proposal number {{num}} has been forwarded to the appropriate User',
  lic_ProposalCorrectlySaved: 'Proposal number {{num}} saved correctly',
  lic_PreventiveCorrectlySaved: 'Quote number {{num}} saved correctly',
  lic_benMorteNoAssic: 'Subject selected as Death beneficiary is already selected as insured, select another subject',
  lic_benMorteNoRef: 'Subject selected as Death beneficiary is already selected as Third Contact Person, select another subject',
  lic_benefPresente: 'Subject selected as Death beneficiary is already selected, select another subject',
  lic_refTerzoNoAssic: 'Subject selected as Third Contact Person is already selected as insured, select another subject',
  lic_refTerzoNoContr: 'Subject selected as Third Contact Person is already selected as Policy Holder, select another subject',
  lic_refTerzoPresente: 'Subject selected as Third Contact Person is already selected as Death beneficiary, select another subject',
  lic_DelegateEqualToPolicyHolder: 'Delegate equal to the Policyholder ?',
  lic_LegalGuardianEqualToPolicyHolder: 'Legal guardian equal to the Policyholder ?',
  lic_ThirdPayerEqualToPolicyHolder: 'Third payer equal to the Policyholder ?',
  lic_DelegateEqualToPolicyHolderWarning: 'Subject for role Delegate equal to the Policyholder',
  lic_LegalGuardianEqualToPolicyHolderWarning: 'Subject for role Legal Guardian equal to the Policyholder',
  lic_ThirdPayerEqualToPolicyHolderWarning: 'Subject for role Third payer equal to the Policyholder',
  lic_MasterPolicyHolder: 'Maser policy holder',
  lic_SubjectXalreadyselectedfortherole: 'Subject {{subject}} already selected for the role',
  lic_InsuredAmount: 'Insured Amount',
  lic_InvestedPremium: 'Invested Premium',
  lic_FirstInstalment: 'First Installment',
  lic_InsuredNotEqualHolder: 'Insured and Holder must be different',
  lic_SaveEstimate: 'Save Quote',
  lic_LifeQuote: 'Life Quote',
  lic_PreventiveIssue: 'Quote Issuance',
  lic_ProposalIssue: 'Issue Proposal',
  lic_PolicyIssue: 'Issue Policy',
  lic_appendixLabel: 'Beneficiary Appendix',
  lic_summary_save_and_accept: 'save and accept',
  lic_summary_save_accept_pol_issue: 'save accept and issue policy',
  lic_summary_save_accept_prop_issue: 'save accept and issue proposal',
  lic_effectivebeneficiaryholder: 'effective beneficiary holder',
  lic_LifePolicy: 'Life Policy',
  lic_DamagesPolicy: 'Damages Policy',
  lic_view: 'View',
  lic_premium_detail: 'Premium detail',
  lic_guarantee_summary: 'Cover summary',
  lic_commissions: 'Commissions',
  lic_Parties: 'Parties',
  lic_Party: 'Party',
  lic_Employer: 'Employer',
  lic_SpecificPIPdata: 'Specific PIP data',
  lic_prev_continue: 'Continue',
  lic_Transferdata: 'Transfer data',
  lic_Selectatleastonecontribution: 'Select at least one contribution',
  lic_Globalpercentagemustbe100: 'Global percentage must be 100%',
  lic_Selectcontributionsmandatoryfields: 'Select contributions mandatory fields',
  lic_Contributiondata: 'Contribution data',
  lic_Globalamountmustbemajorthan0: 'The global amount must be major than 0',
  lic_Everycontributionselectedmustbemajorthan0: 'Every contribution selected must be major than 0',
  lic_error_mandatory_fields: 'Fields marked in red are required or contain incorrect values',
  lic_error_iban: 'Iban is not correct',
  lic_authorization_request: 'AUTHORIZATION REQUEST',
  lic_selected_profiles_: 'Selected Profiles',
  lic_Disinvestmentsfunds: 'Disinvestments funds',
  lic_reg41_comunication: 'Name to send communications to',
  lic_disinvestment_amount: 'Disinvestment amount',
  lic_occurrenceNumber: 'Occurrence',
  lic_TheoccurenceNumbermustbeavalue: 'The Occurence number must be greater than {{min}}',
  lic_Disinvestmentrange: 'Inserire un importo da disinvestire maggiore di {{max}} e minore o uguale a {{min}}',
  lic_Incompleteinvestmentforthefunds: 'Incomplete investment, please complete the investment for the funds',
  lic_Incompleteinvestmentfortheprofiles: 'Incomplete investment, please complete the investment for the profiles',
  lic_Settlementstoreinvest: 'Settlements to reinvest',
  lic_Policynumber: 'Policy number',
  lic_Type: 'Type',
  lic_Beneficiary: 'Beneficiary',
  lic_State: 'State',
  lic_Net: 'Net',
  lic_Nettoreinvest: 'Net to reinvest',
  lic_Reinvestmentmustbelargerthanquotation: 'Reinvestment must be larger than quotation.',
  lic_Mustselectatleastonesettlementtoreinvest: 'Must select at least one settlement to reinvest.',
  lic_Nettoreinvestmustbeequalorlessthannet: 'Net to reinvest must be equal or less than net.',
  lic_Nosettlementstoreinvestexist: 'No settlements to reinvest exist.',
  lic_Missingvalueonnettoreinvest: 'Missing value on net to reinvest.',
  lic_information: 'Information',
  lic_Percentage: 'Percentage',
  lic_Durationin: 'Duration in ',
  lic_years: 'years',
  lic_months: 'months',
  lic_days: 'days',
  lic_Complementary: '(Complementary)',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: 'Please provide a payment method to the Coupon Beneficiary',
  lic_Netavailable: 'Net available',
  lic_Proposal_Saved: 'The proposal has been saved with number ',
  lic_add_employer: 'Add an lic_Employer',
  lic_AvailableGuarantees: 'Available Covers',
  lic_error: 'Error',
  lic_error_msg: 'Error when issuing the policy',
  lic_auth_error_msg: 'Error when processing the request',
  lic_common_risk: 'Common risk',
  lic_assignment_holder: 'Assignment holder',
  lic_Sustainability: 'Sustainability',
  lic_ecoSustainabilityAmount: 'Eco Sustainability Amount',
  lic_ecoSustainabilityPerc: 'Eco Sustainability %',
  lic_envSustainabilityAmount: 'Environment Sustainability Amount',
  lic_envSustainabilityPerc: 'Environment Sustainability %',
  lic_paiAmount: 'PAI Amount',
  lic_paiPerc: 'PAI %',
  lic_socialSustainabilityAmount: 'Social Sustainability Amount',
  lic_socialSustainabilityPerc: 'Social Sustainability %',
  lic_benefSevereDisability: 'Beneficiary with severe disability',
  lic_SecondInsured: 'Second Insured',
  lic_Secondinsuredmustbeaphysicalperson: 'Second insured must be a physical person',
  lic_Selectasecondinsured: 'Select a second insured',
  lic_Aderente: 'Adherent​',
  lic_NumeroProposta: 'Proposal Number',
  lic_DescrizioneProdotto: 'Product Description',
  lic_DescrizioneRischio: 'Risk Description',
  lic_CapitaleAssicurato: 'Insured amount',
  lic_SELEZIONA: '-- SELECT --',
  lic_PrNetto: '% net premium',
  lic_ImpAcq: 'Purchase Tax',
  lic_profileInvestmentIncomplete: 'Investment incomplete, complete investment profiles',
  lic_fundsInvestmentIncomplete: 'Investment incomplete, please complete investment funds',
  lic_Clauses: 'Clauses',
  lic_quote_type: 'Quote type',
  lic_Policyholder: 'Policyholder',
  lic_quote_type_anonymous: 'Anonymous',
  lic_quote_type_registered: 'Registered',
  lic_text_required: '*Required Text',
  lic_text_required_error: 'Clause text is Required.',
  lic_close: 'close',
  lic_save: 'save',
  lic_NEXT: 'NEXT',
  lic_policyData: 'Policy Data',
  lic_pipData: 'PIP data',
  lic_parties: 'Parties',
  lic_assets: 'Assets',
  lic_quotation: 'Quotation',
  lic_summary: 'Summary',
  lic_issue: 'Issue',
  lic_investments: 'Investments',
  lic_invest_value_range: 'Please enter a value between {{min}} and {{max}}',
  lic_disinvestment_range: 'Please enter a disinvestment value between {{min}} and {{max}}',
  lic_mandatory_threshold_perc: 'Fund: {{value}} - Please enter threshold percentage',
  lic_mandatory_one_fund: 'Select at least one fund',
  lic_invest_factor_min_validation: '{{factor}} must be less than {{amount}}',
  lic_invest_factor_max_validation: '{{factor}} must be greater than {{amount}}',
  lic_invest_factor_eq_validation: '{{factor}} must be {{amount}}',
  lic_calculate_asset_volatility: 'Calculate Asset Volatility',
  lic_calculate_sustainability_asset: 'Calculate Asset Sustainability',
  lic_occurance_number_range: 'The Occurence number must be greater than {{min}}',
  lic_policy_holder: 'Policy Holder',
  lic_guarantee: 'Cover',
  lic_co_applicant_not_same_holder: 'Co-applicant must not be the same to policy holder',
  lic_co_applicant_physical: 'Co-applicant must be a physical person',
  lic_co_applicant_mandatory: 'Co-applicant is mandatory',
  lic_co_applicant: 'Co-applicant',
  lic_insured_must_be_physical: 'Insured must be a physical person',
  lic_mandatory_product: 'Select a product',
  lic_mandatory_insured: 'Select an insured',
  lic_mandatory_holder: 'Select a policy holder',
  lic_contribution_frequency: 'The frequency of contribution {{frequency}} must be the same',
  lic_mandatory_benef: 'Beneficiary {{cathegory}}: Beneficiary is mandatory',
  lic_mandatory_appendix_benef: 'Beneficiary {{cathegory}}: Appendix beneficiary is mandatory',
  lic_add_benef: 'Beneficiary {{cathegory}}: Add beneficiary from civil registry',
  lic_add_benef_perc: 'Beneficiary {{cathegory}} {{nominative}}: Add percentage',
  lic_benef_perc_range: 'Beneficiary {{cathegory}} {{nominative}}: Percentage must be between 0.01 and 100',
  lic_benef_total_perc: 'Beneficiary {{cathegory}}: Percentage amount must be 100%',
  lic_guarantee_mandatory_data: 'Cover {{unit}}: Fill all mandatory data',
  lic_quotation_not_done: 'Quotation not done: select "Quote" to update quotation',
  lic_quotation_modified_value_quote: 'Data is updated: select "Quote" to update quotation',
  lic_quotation_benef_custom_msg: 'Beneficiary {{cathegory}} - {{msg}}',
  lic_product: 'Product',
  lic_managementNode: 'Management node',
  lic_branch: 'Branch',
  lic_agreement: 'Agreement',
  lic_effect_date: 'Effective date',
  lic_exp_date: 'Expiration date',
  lic_payment_frequency: 'Payment frequency',
  lic_means_of_payment: 'Means of payment',
  lic_unit_type: 'Unit type',
  lic_risk_type: 'Risk type',
  lic_substate: 'Substate',
  lic_benefit: 'Benefit',
  lic_decreasing_frequency: 'Decreasing Frequency',
  lic_damages: 'Damages',
  lic_effective_holder: 'Beneficial owners',
  lic_insured_role: 'Insured',
  lic_third_contact_person: 'Third contact person',
  lic_quotation_benef_owner_perc_validation: '{{roleDescr}} {{name}} of beneficiary {{benefName}} does not respect the minimum accepted percentage {{minPerc}}',
  lic_subscriber: 'Subscriber',
  lic_yes: 'yes',
  lic_no: 'no',
  lic_new_insured: 'New Insured',
  lic_simple_select: 'Select',
  lic_first_instalment: 'First installment',
  lic_next_instalment_premium: 'Next Installment Premium',
  lic_payment_type: 'Payment type',
  lic_first_instalment_payment_type: 'First installment payment type',
  lic_annual_premium: 'Annual Premium',
  lic_quote_button: 'Quote',
  lic_quote_save: 'Quote and save',
  lic_purchasing_commission: 'Purchasing commission',
  lic_beneficiaries_label: 'Beneficiaries',
  lic_general_data_label: 'General Data',
  lic_contractual_option_not_allowed: 'Contractual options not allowed',
  lic_investment_value_label: 'Investment value',
  lic_investment_profile: 'Investment profile',
  lic_investment_funds: 'Investment funds',
  lic_calculate_finantial_parameters: 'Calculate Finantial parameters',
  lic_confirm_button: 'Confirm',
  lic_cancel_button: 'Cancel',
  lic_save_button: 'Save',
  lic_continue_button: 'Continue',
  lic_next_button: 'Next',
  lic_required: 'required',
  lic_node: 'Node',
  lic_company: 'Company',
  lic_product_type: 'Product type',
  lic_taxes_label: 'Taxes',
  lic_fees_label: 'Fixed Charges',
  lic_frequency_interests: 'Frequency Interests',
  lic_frequency: 'Frequency',
  lic_accessories: 'Accessories',
  lic_taxable: 'Taxable',
  lic_quotation_detail_modal: 'Quotation detail',
  lic_guarantees_overview_modal: 'Covers Overview',
  lic_gross_label: 'Gross',
  lic_int_fract_label: 'Int. Fract.',
  lic_next_instalment_label: 'Next Installment',
  lic_pure_premium_label: 'Pure Premium',
  lic_extra_premium_label: 'Extra Premium',
  lic_loadings_label: 'Fees',
  lic_total_label: 'Total',
  lic_beneficiary_perc_is_over: 'The sum of the percentages of the roles linked to {{benefCat}} {{benefRole}} is over 100',
  lic_questionSTR_invalid: 'The answer to the question: {{question}}, is not correct',
  lic_purchase_commissions: 'Purchase commissions',
  lic_PurchaseCommission: 'Purchase Commission',
  lic_NetPrem: '% net premium',
  lic_sales_commissions: 'Sales Commissions',
  lic_IBAN_formally_incorrect: 'IBAN is not formally valid',
  lic_holder_physical_person_req: 'Policy holder must be a physical person',
  lic_holder_legal_person_req: 'Policy holder must be a legal person',
  lic_available_covers: 'Available Covers',
  lic_covers_overview: 'Covers Overview',
  lic_covers: 'Covers',
  lic_cover: 'Cover',
  lic_config_profile_warning: 'WARNING: Configuration profiles has changed.',
  lic_select: 'Select',
  lic_Irrevocable_beneficiary: 'Irrevocable beneficiary',
  lic_Base: '(Base)',
  lic_Accessory: '(Accessory)',
  lic_investment_value: 'Investment value',
  lic_volatility: 'Volatility',
  lic_name: 'Name',
  lic_to_be_invested: 'To be invested',
  lic_info_investment_completed: 'Information: investment complete',
  lic_fund: 'Fund',
  lic_max_percentage: 'maximum accepted percentage',
  lic_min_percentage: 'minimum accepted percentage',
  lic_selected_funds: 'Selected Funds',
  lic_allocation_exceeds: 'Investment allocation exceeds the available amount',
  lic_percentage_threshold: '% Threshold',
  lic_fund_distribution_identical: 'Fund distribution identical to the initial payment',
  lic_fund_not_available: 'no fund for option',
  lic_details: 'Details',
  lic_starting_fund: 'Starting fund',
  lic_fund_category: 'Profile/ Fund',
  lic_equivalent: 'Equivalent on the date',
  lic_policy_address: 'Policy Address',
  lic_policy_master_details: 'Policy Master Detail',
  lic_administrative_data: 'Administrative Data',
  lic_ordinary_mail: 'Ordinary mail',
  lic_threshold: 'Threshold',
  lic_payment_method: 'Next installments payment method',
  lic_additional_payment: 'Additional payment',
  lic_contractual_options: 'Contractual Options',
  lic_elements: 'Elements',
  lic_position_number: 'Position number',
  lic_questionnaires_section: 'Questionnaires',
  lic_fees: 'Fixed Charges',
  lic__1TERM: 'First Term',
  lic__EFFOP: 'Effective Date',
  lic__OPZFR: 'Frequency',
  lic__OPZPM: 'Payment mode',
  lic_expirationDate: 'Expiration Date',
  lic__OPBEN: 'Beneficiary',
  lic__MINTS: 'Minimal Threshold',
  lic__MAXTS: 'Maximal Threshold',
  lic__NUMOC: 'Occurrence Number',
  lic__AMTOP: 'Surrender Amount',
  lic__MALMT: 'Minimal Amount',
  lic_amount: 'Amount',
  lic_currency: 'Currency',
  lic__CIBAN: 'Iban',
  lic_fixedFees: 'Fixed Fees',
  lic__PCOUP: 'Periodic Discount Percentage',
  lic_holder: 'Holder',
  lic_ACTIVE_STATE: 'ACTIVE',
  lic_MANDATORY_STATE: 'MANDATORY',
  lic_DEACTIVATE_STATE: 'DEACTIVATE',
  lic_premium_label: 'Premium',
  lic_holders_title: 'Holders',
  lic_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lic_invalid_total_position_percentage: 'The Percentage amount for the group \"{{groupType}}\" on Beneficiary {{cat}} must be 100%',
  lic_beneficiary_position_mandatory: 'Beneficiary {{benefCat}} {{benefName}}, select a \"Position Number\"',
  lic_role_is_mandatory: 'You need to enter a {{role}}',
  lic_effective_holder_duplicated: 'Effective holder duplicated',
  lic_beneficiary_perc_req_range: '{{benefCat}} {{benefRole}} {{benefName}}: Percentage must be between 0.01 and 100',
  lic_beneficiary_perc_req: ' {{benefCat}} {{benefRole}} {{benefName}}: Enter a percentage',
  lic_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Enter a beneficiary from the party repository',
  lic_effective_holder_perc: '{{benefName}}\'s beneficial owner {{linkedName}}\'s percentage is below the minimum allowed of {{linkedPerc}}%',
  lic_effective_holder_perc_req: '{{benefName}}\'s beneficial owner {{linkedName}}: Enter a percentage',
  lic_or_junction: 'or',
  lic_backdating_not_allowed: 'It is not allowed to backdate a proposal saved earlier',
  lic_print_docs: 'Output documents',
};
export default LIC_EN ;
