<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="!!publishMessage">

  <lpc-step label="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.DATE.label}}" id="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.DATE.id}}" [errors]="errors"
            fieldId="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.DATE.fieldId}}" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.DATE.formName"
                    (dateChange)="onDateChange($event)" (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step id="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.POLICY_FACTORS.id}}" *ngIf="hasProductFactor"
            label="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.POLICY_FACTORS.label}}" [errors]="errors"
            fieldId="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.POLICY_FACTORS.fieldId}}" (next)="onNext($event)">
    <div formGroupName="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.POLICY_FACTORS.formName}}" class="row">
      <lpc-factor-adapter #factorAdapter
                          (changeValEmitter)="updateProductFactors($event)"
                          [form]="formGroup.controls['factors']"
                          [factors]="listProductFactor">
      </lpc-factor-adapter>
    </div>
  </lpc-step>

  <lpc-step *ngIf="isAuth || !!errors && !!errors.length" id="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.WARNING.id}}"
            label="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.WARNING.label}}" (next)="publish()"
            [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'" (confirmAccept)="publish(isConfirmAndAccept)"
            [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>
  </lpc-step>

  <lpc-step [enableAuth]="isAuth" label="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.DOCUMENT.label}}" [errors]="errors"
            fieldId="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.DOCUMENT.fieldId}}" id="{{UNDEDUCTED_PREMIUMS_ACQUISITION_STEP.DOCUMENT.id}}" [nextVisible]=false>
    <lpc-document-wrapper *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>
    <lpc-navigation-buttons id="last-step-buttons" (back)="closeCardWithoutModal()" [backLabel]="'lpc_close_button'"
                            [backVisible]="true" [nextVisible]="false">
    </lpc-navigation-buttons>
  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'">
  </lpc-navigation-buttons>
</div>
