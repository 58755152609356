import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityListComponent } from './activity-list.component';
import { ActivityMenuComponent } from './components/activity-menu/activity-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    CardService,
    containerCacheServiceProvider,
    enumServiceProvider
} from '@rgi/digital-claims-common-angular';
import { ManualDeadlineComponent } from '../manual-deadline/manual-deadline.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { ReassignTaskModule } from '../reassign-task/reassign-task.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
import { RgiRxExpansionModule, RgiRxTableModule, RgiRxTabModule } from '@rgi/rx/ui';

@NgModule({
    declarations: [
        ActivityListComponent,
        ActivityMenuComponent,
        ManualDeadlineComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        ReassignTaskModule,
        RgiRxTabModule,
        RgiRxTableModule,
        RgiRxExpansionModule
 
    ],
    exports: [
        ActivityListComponent,
        ActivityMenuComponent
    ],
    providers: [
        containerCacheServiceProvider,
        enumServiceProvider
    ]
})
export class ActivityListModule {
    constructor(cardService: CardService) {
        const title = '_ACTIVITY';
        const moduleName = 'activity-list';
        const parentCardName = 'claimsFinder';
        const debugCardsOn = false;

        const communication = {
            reloadsubcard: 'reloadsubcard'
        };

        cardService.registerSubCard(
            moduleName,
            title,
            ActivityListComponent,
            parentCardName,
            null,
            'ClaimsCommunicationCtrl',
            communication
        );
    }

}
