<rgi-rx-panel [@fadeAnimation]>
    <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
        {{ data?.description }}
      </rgi-rx-panel-header>
      <div *ngIf="!!data">
        <table class="table-responsive table coreDT life-detail-margin-top">
            <thead>
                <tr class="head-result thDataTable life-detail-tbld_row_header">
                  <th *ngFor="let header of headers; let i = index" class="table-center">{{ header.title }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-row table-background" style="background: #9e9e9e73; font-weight: bold;">
                    <td class="table-border table-center ">{{'lpc_total' | translate}}</td>
                    <td class="table-border table-center">{{data.purePremium | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    <td class="table-border table-center">{{data.fees | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    <td class="table-border table-center">{{data.extraPremium | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    <td class="table-border table-center">{{data.net | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    <td class="table-border table-center">{{data.installmentInterest | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    <td class="table-border table-center">{{data.fixedCharges | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    <td class="table-border table-center">{{data.taxes | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    <td class="table-border table-center">{{data.gross | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                </tr>
                <ng-container *ngFor="let layer of data?.layerList">
                    <tr class="table-row table-background">
                        <td class="table-border table-center">{{layer.description | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.purePremium | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.fees | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.extraPremium | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.net | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.installmentInterest | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.fixedCharges | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.taxes | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                        <td class="table-border table-center">{{layer.gross | rgiCountryLayerNumberFormat :  currencyService.locale : currencyFormatterOptions}}</td>
                    </tr>
                </ng-container>                            
            </tbody>
        </table>
      </div>
    <rgi-rx-panel-footer>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modalClose.emit()" translate>lpc_close_button</button>
    </rgi-rx-panel-footer>
</rgi-rx-panel>