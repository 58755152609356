import { ActiveRoute } from "@rgi/rx/router";
import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import { DialogueHandlerSearch, SummaryState } from "../dialogue-handler-configuration/dialogue-handler.model";
import { Observable, of } from "rxjs";
import { DialogueHandlerIntegrationService } from "../dialogue-handler-configuration/dialogue-handler-integration.service";
import { Injectable } from "@angular/core";
import { DialogueHandlerSummaryService } from "./dialogue-handler-summary.service";
import { NewDialogueViewConfiguration } from "../new-dialogue/new-dialogue.component";
import { CausalData, MessageList, SummaryForm } from "../dialogue-handler-configuration/dh-summary.model";
import { ListDialogueViewConfiguration } from "../constants/listEnumConstants";

@Injectable()
export class DialogueHandlerSummaryStateManager extends AbstractStateManager<SummaryState> {

  constructor(activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: DialogueHandlerSummaryService,
    private integrationService: DialogueHandlerIntegrationService) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<SummaryState>(this.storeKey)
      ? stateStoreService.get<SummaryState>(this.activeRoute.key)
      : new SummaryState(this.storeKey);
    //          console.error(this.activeRoute.getRouteData<any>().filterList)
    if (this.activeRoute.getRouteData<any>().filterList) {
      this.updateState$(this.statelesOps.initStateObject(this.activeRoute.getRouteData<any>().filterList, of(st)));
    }
     else if (this.activeRoute.getRouteData<any>().dialogue) {
      console.error(this.activeRoute.getRouteData<any>())
      this.updateState$(this.statelesOps.initStateObjectbyId(this.activeRoute.getRouteData<any>().dialogue, of(st)));
    }
    else {
      this.updateState$(of(st));
    }

  }


  goToResponse$(ctx: MessageList) {
    const st = this.getCurrentState();
    const viewCond = NewDialogueViewConfiguration.RESPONSE_VIEW_CONF;
    this.updateState$(this.statelesOps.getNewDialogueData(of(st), this.activeRoute, viewCond, ctx));
  }

  cancelDialogue$() {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.cancelDialogue(of(st),this.activeRoute));
  }

  setNeedDetailsReInitialize$(needDetailsReInitialize: boolean) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.setNeedDetailsReInitialize(of(st), needDetailsReInitialize));
  }

  resetAdvance$(idHistoryStatus: number) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.resetAdvance(of(st), idHistoryStatus));
  }


  searchCausal$() {
    const st = this.getCurrentState();
    if(st.summaryForm.claimNumberPass) {
      this.updateState$(this.statelesOps.searchCausal(of(st)));
    }
  }

  cleanCausal$() {
    const st = this.getCurrentState();
    if(st.summaryForm.claimNumberPass) {
      this.updateState$(this.statelesOps.cleanCausal(of(st)));
    }
  }

  updateSummaryForm$(form: SummaryForm) {
    this.updateState$(this.statelesOps.updateSummaryForm(form, of(this.getCurrentState())));
  }

  associateClaim$(localCausals: CausalData[]) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.associateClaim(of(st), localCausals));
  }


  getDeatail$() {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.getDettaglioSinistroDenuncia(of(st)));
  }

  checkAssociation$(){
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.verifyReassociation(of(st)));
  }

  goBack(){
  this.closeCardSession();
  }

  closeCardSession() {
    this.statelesOps.closeSession$(this.activeRoute.id, this.getCurrentState());
  }

  goToDialogueList$(){
    const st = this.getCurrentState();
    const viewCond = ListDialogueViewConfiguration.SEARCH_LIST_VIEW;
    this.updateState$(this.statelesOps.goToDialogueList(of(st), this.activeRoute, viewCond));
  }
}

