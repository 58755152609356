<div id="life-role-page">
  <div *ngIf="initEnd()">
    <form [formGroup]="form">
      <div class="core-header-icon ">
          <span class="header-icon rgifont rgi-riparti_coass"></span>
          <span class="header-text" translate>lic_Parties</span>
          <span class="pull-right header-text">  </span>
      </div>

      <div class="example-row">
        <div style="height:auto">
          <lic-select-holder
            (delete)="resetHolder()"
            [val]="form.controls['holder'].value"
            [parentForm]="form"
            [showTrash]="showHolderTrash"
            [disableTrash]="disableHolderTrash"
            [roleCode]="getRoleOfHolder()"
            (eventPropagation)="openAnagHolder($event)"
            (subjetAnagEditedEvent)="editAnagHolder($event)">
          </lic-select-holder>
        </div>
      </div>

      <div formArrayName="allRoles" *ngIf="showRoles"  [ngClass]="'role-form-list'">
        <div *ngFor="let fg of allRoles.controls; let i=index; trackBy: trackByFn" class="row">
          <form [formGroup]="getRoleGroup(fg.value.groupId)">
            <ng-container [ngSwitch]="fg.value.roleId">

              <ng-container *ngSwitchCase="ROLES.DELEGATE">
                <!-- DELEGATO -->
                <div class="col-lg-6 col-md-12 p-0" *ngIf="isCheckBoxRolePreset(fg.value.roleId)">
                  <div class="col-lg-12 col-md-12 p-0">
                    <label class="lbl" translate>lic_DelegateEqualToPolicyHolder</label>
                  </div>
                  <div class="col-lg-12 col-md-12 p-0">
                    <rgi-rx-button-toggle
                        [(ngModel)]="selectedValueDelegato"
                        formControlName="presetValue"
                        [attr.data-qa]="'delegateEqualToPolicyHolder'"
                        rgiRxGrid="2" style="width: 50%"
                        (ngModelChange)="onClickCheckBoxRole(ROLES.DELEGATE, fg.value.groupId)">
                        <div rgiRxGridCol *ngFor="let option of checkBoxOptions" style="width: 50%">
                          <button style="width: 100%" rgi-rx-button rgiRxValueOption [value]="option.value">{{option.label}}</button>
                        </div>
                    </rgi-rx-button-toggle>
                    <br><br>
                  </div>
                </div>
              </ng-container>

            <ng-container *ngSwitchCase="ROLES.THIRDPAYER">
              <!-- TERZO PAGATORE -->
              <div class="col-lg-6 col-md-12 p-0" *ngIf="isCheckBoxRolePreset(fg.value.roleId)">
                <div class="col-lg-12 col-md-12 p-0">
                  <label class="lbl" translate>lic_ThirdPayerEqualToPolicyHolder</label>
                </div>
                <div class="col-lg-12 col-md-12 p-0">
                  <rgi-rx-button-toggle
                        [(ngModel)]="selectedValueTerzoPagatore"
                        formControlName="presetValue"
                        [attr.data-qa]="'thirdPayerEqualToPolicyHolder'"
                        rgiRxGrid="2" style="width: 50%"
                        (ngModelChange)="onClickCheckBoxRole(ROLES.THIRDPAYER, fg.value.groupId)">
                        <div rgiRxGridCol *ngFor="let option of checkBoxOptions" style="width: 50%">
                          <button style="width: 100%" rgi-rx-button rgiRxValueOption [value]="option.value">{{option.label}}</button>
                        </div>
                  </rgi-rx-button-toggle>
                  <br><br>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="ROLES.LEGAL_GUARDIAN">
              <!-- TUTORE LEGALE -->
              <div class="col-lg-6 col-md-12 p-0" *ngIf="isCheckBoxRolePreset(fg.value.roleId)">
                <div class="col-lg-12 col-md-12 p-0">
                  <label class="lbl" translate>lic_LegalGuardianEqualToPolicyHolder</label>
                </div>
                <div class="col-lg-12 col-md-12 p-0">
                  <rgi-rx-button-toggle
                        [(ngModel)]="selectedValueTutoreLegale"
                        formControlName="presetValue"
                        [attr.data-qa]="'legalGuardianEqualToPolicyHolder'"
                        rgiRxGrid="2" style="width: 50%"
                        (ngModelChange)="onClickCheckBoxRole(ROLES.LEGAL_GUARDIAN, fg.value.groupId)">
                        <div rgiRxGridCol *ngFor="let option of checkBoxOptions" style="width: 50%">
                          <button style="width: 100%" rgi-rx-button rgiRxValueOption [value]="option.value">{{option.label}}</button>
                        </div>
                  </rgi-rx-button-toggle>
                  <br><br>
                </div>
              </div>
            </ng-container>

          </ng-container>
        </form>

          <div [formGroupName]="i" [ngClass]="'role-input-indent-'+getDepth(fg.value.groupId)">
            <lic-select-subject-list [label]="fg.value.roleObj.partyRoleDescription"
                                      [role]="fg.value.roleId"
                                      [val]="getRoleListValue(fg.value.groupId)"
                                      [parentForm]="getRoleGroup(fg.value.groupId)"
                                      [disableDelete]="isCheckBoxRoleSelectedAsHolder(fg.value.roleId)"
                                      (eventPropagation)="openAnag(fg.value.groupId,$event)"
                                      (subjetAnagEditedEvent)="editAnag(fg.value.groupId,$event)"
                                      (delete)="delete(fg.value.groupId,$event)">
            </lic-select-subject-list>
          </div>

        </div>
      </div>

      <div *ngIf="isHolderPresent" class="row">
        <div *ngIf="showProductSelect" class="col-lg-6 col-md-12 mb-2">
          <div class="col-lg-12 col-md-12 p-0">
            <label class="lbl" translate>lic_mandatory_product</label>
          </div>
          <div class="col-lg-12 col-md-12 p-0">
            <select data-qa="select-product" class="drop-container dashedSelect" (change)="changeProd($event)" formControlName="product" style="min-width: 50%;">
              <option *ngFor="let product of prodotti" [value]="product.productCode">{{product.productDescription}}</option>
            </select>
          </div>
        </div>
        <br>
        <div *ngIf="showInsured" class="col-lg-12 col-md-12">
          <div class="core-header-icon">
            <span class="header-icon rgifont rgi-ui-icon-user-group-under-umbrella"></span>
            <span class="header-text" translate>lic_insured_role</span>
            <span class="pull-right header-text">  </span>
          </div>
          <div class="example-row">
              <div style="height:auto">
                <lic-select-insured
                  (delete)="resetInsured()"
                  [val]="form.controls['insured'].value"
                  [role]="4"
                  [parentForm]="form"
                  (eventPropagation)="openInsured($event)"
                  (subjetAnagEditedEvent)="editAnagInsured($event)">
                </lic-select-insured>
              </div>
            </div>
        </div>

        <div *ngIf="showSecondInsured" class="col-lg-12 col-md-12">
          <div class="example-row">
            <div style="height:auto">
              <lic-select-second-insured
                (delete)="resetSecondInsured()"
                [val]="form.controls['secondInsured'].value"
                [role]="300022"
                [parentForm]="form"
                (eventPropagation)="openSecondInsured($event)"
                (subjetAnagEditedEvent)="editAnagInsured($event, 'secondInsured')"
                data-qa="secondInsured">
              </lic-select-second-insured>
            </div>
          </div>
        </div>

      </div>

      <!-- MESSAGGI di ERRORE -->
      <!-- <pnc-floating-error-messages [validationMessages]="getValidationMessages"
                                   [blockingMsgs]="getBlockingMessages"
                                   [warningMsgs]="getWarningMessages"
                                   [authoMsgs]="getAuthMessages">
      </pnc-floating-error-messages> -->

      <rgi-rx-snackbar tag="validation"></rgi-rx-snackbar>
      <rgi-rx-snackbar tag="blocking"></rgi-rx-snackbar>
      <rgi-rx-snackbar tag="warning"></rgi-rx-snackbar>
      <rgi-rx-snackbar tag="auth"></rgi-rx-snackbar>

      <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="submit"
                    class="btn btn-warning pull-right text-uppercase"
                    data-qa="lic-role-submit"
                    (click)="onSubmit()" translate>lic_NEXT</button>
        </div>
      </div>

    </form>
  </div>
</div>
