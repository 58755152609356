import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimDocumentsComponent } from './claim-documents.component';
import {
  CardService,
  enumServiceProvider,
  getSystemPropertyProvider
} from '@rgi/digital-claims-common-angular';
import {
  RgiRxDatePickerModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule, RgiRxMenuModule, RgiRxModalModule, RgiRxNativeDateTimeModule,
  RgiRxPanelModule,
  RgiRxTableModule,
  RgiRxTabModule,
  RgiRxTooltipModule
} from '@rgi/rx/ui';
import {UiModalDialogEditDocumentComponent} from './dialogs/edit-document-dialog';
import {UiModalDialogUploadDocumentComponent} from './dialogs/upload-document-dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {RgiRxFormModule} from '@rgi/rx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PotclaimDocumentsComponent} from './potclaim-documents/potclaim-documents.component';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
@NgModule({
  declarations: [ClaimDocumentsComponent,     UiModalDialogEditDocumentComponent,
    UiModalDialogUploadDocumentComponent,
    PotclaimDocumentsComponent],
    imports: [
        CommonModule,
        RgiRxTabModule,
        RgiRxExpansionModule,
        RgiRxTableModule,
        RgiRxFormElementsModule,
        RgiRxTooltipModule,
        RgiRxPanelModule,
        RgiRxModalModule,
        ReactiveFormsModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        FormsModule,
        RgiRxFormModule,
        RgiRxMenuModule,
        RgiRxDatePickerModule,
      RgiRxNativeDateTimeModule,
      BrowserAnimationsModule
    ],
  exports : [
    ClaimDocumentsComponent,
  ],
  providers : [
    enumServiceProvider,
    getSystemPropertyProvider,
    { provide : 'claimDocumentsComponent', useValue: ClaimDocumentsComponent},
  ],
  entryComponents: [
    ClaimDocumentsComponent,
    PotclaimDocumentsComponent,
    UiModalDialogEditDocumentComponent,
    UiModalDialogUploadDocumentComponent,
  ],

})
export class ClaimDocumentsModule {
  constructor(cardService: CardService) {
    const title = '_DOSSIER_MANAGEMENT';
    const moduleName = 'claim-documents';
    const parentCardName = 'claimsFinder';
    const moduleNameCard = 'documentsNew';
    const communication = {
      reloadsubcard: 'reloadsubcard'
    };
    cardService.registerSubCard(
      moduleName,
      title,
      ClaimDocumentsComponent,
      parentCardName,
      null,
      'ClaimsCommunicationCtrl',
      communication
    );
    cardService.registerCard(
          moduleNameCard,
          title,
          ClaimDocumentsComponent,
          undefined,
          undefined,
          undefined,
          undefined,
          false,
          'Claims'
        );
  }
}
