export class ElaborationRequestDetails {

    elaborationData: {
        elabId: number,
        elabActivity: {
            code: string,
            description: string,
            title: string,
        },
        elabExternalKey: string,
        elabCodeChain: string,
        elabJsonInputNr: number,
        elabTIns: string
    };
    jsonData: Array<JsonData>;
    queueData: QueueData;
    queueDataList: QueueData[];

        // tslint:disable-next-line
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

class JsonData {
    jsonId: number;
    jsonExternalKey: string;
    jsonBody: string;
    jsonIdElement: string;
    jsonElementType: {
        code: string,
        description: string
    };
    jsonIdClaimPass: string;
    jsonElaborationOutcome: {
        code: string,
        description: string,
    };
    jsonElaborationError: {
        code: string,
        description: string
    };
    jsonCancellationReason: string;
    jsonLastElaborationDate: string;
    jsonCancellationDate: string;
}

class QueueData {
    queueExecutionId: number;
    queueOutcomeState: {
        code: string,
        description: string,
    };
    queueElabType: {
        code: string,
        description: string,
    };
    queueTIns: string;
    queueActive: boolean;
    queueEleborationDate: string;
    queueElaborationRequestDate: string;
    queueCancellationDate: string;
    queueRerunRequest: boolean;
    queueOutcomeError: {
        code: string,
        description: string
    };
    queueJsonKONr: number;
    queueJsonOkNr: number
}
