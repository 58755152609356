import {
  PortalCardConfig,
  PortalCards,
} from '@rgi/rx/portal';
import {
  TotalSummaryService
} from '@rgi/iiab-portal-ext';
import { RgiRxPortalCardService } from '@rgi/rx/portal/portal-integration/portal-card.service';

export const TOTAL_SUMMARY_CARD: PortalCardConfig = {
  card: {
    title: '_LS_TITLE_TOTAL_SUMMARY_',
    name: 'cardTotalSummary',
    category: 'Cross',
    type: 'sub',
    subCards: [],
    subCardsActive: [],
    navigationInSession: false
  },
  routes: [
    { // route for the sub-card using a dumb component
      route: 'totalSummary',
      controller: TotalSummaryController,
      label: '_LS_TITLE_TOTAL_SUMMARY_',
      template: 'cardTotalSummary.subcard.template' // this is just a key since we use the $templateCache
    }
  ]
};

export const CARDS: PortalCards = [TOTAL_SUMMARY_CARD];

export function angularGridInstanceFactory(injector: any) {
  return injector.get('angularGridInstance');
}

export const angularGridInstance = {
  provide: 'angularGridInstance',
  useFactory: angularGridInstanceFactory,
  deps: ['$injector']
};

TotalSummaryController.$inject = ['$scope', '$controller', 'containerCacheService', 'sessionService', 'totalSummaryService', 'coreResult', 'RgiRxPortalCardService'];

function TotalSummaryController($scope, $controller, containerCacheService, sessionService, totalSummaryService: TotalSummaryService, coreResult, portalCardService: RgiRxPortalCardService) {

  $controller('CardBaseCtrl', { $scope: $scope });
  const parentCache = containerCacheService.get($scope.card.idSession);

  $scope.data = [];
  $scope.schema = totalSummaryService.getTotalSummarySchema();
  if(parentCache.data.node){
    const subscription = totalSummaryService.getData(parentCache.data).subscribe(next => {
      if (next.items) {
        $scope.data = next.items;
      }
    });
    $scope.$on('$destroy', () => {
      // cleanup
      subscription.unsubscribe();
    });
  }
}
