import { HttpClient } from '@angular/common/http';
import {
  Component, EventEmitter, Inject,
  Input, OnDestroy, OnInit, Optional, Output, ViewEncapsulation
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { APP_SETTING, EMPTY_STR, JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { ContractualOption } from '../../../models/contractual-option.model';
import {
  BeneficiaryLifeDetail, ConsultinServicesObj, CoverDetailsResponse, ExpiryOptionData, LifeContractDetail,
  LifeContractWarranty, Profile, ReservationConsultationData, ReservationDetail, ResponseInvestments
} from '../../../models/life-detail.model';
import { Movement, MovementDetail } from '../../../models/movement.model';
import {
  LIFE_ISSUE_PROPOSAL_OK, LIFE_PREVENTIVE_OK, Notify,
  NOTIFY_AFTER_PUBLISH, NOTIFY_CLOSE_BUY_QUOTE, NOTIFY_ON_CLOSE_AFTER_PUBLISH
} from '../../../models/notify-model';
import { PipDataResp, PIPConfig, ContributionPipEvent } from '../../../models/pip-data.model';
import { ModalMessage, ModalMessageType } from '../../../modules/lpc-modal/lpc-modal/lpc-modal.model';
import { OperationsListComponent } from '../../../modules/lpc-operations-list/operations-list/operations-list.component';
import { AnagService } from '../../../services/anag.service';
import { CustomModalService } from '../../../services/custom-modal.service';
import {PipDataServiceService } from '../../../services/pip-data-service';
import { ProposalDetailService } from '../../../services/proposal-detail.service';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { RenewalReservationResponse, SubjectData } from './../../../models/life-detail.model';
import { LifeDetailComponentUtils } from './life-detail.component-utils';
import { PolicyStatusCode } from '../../../enum/life-detail-enum';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';
import { Roles } from '../../../models/enum/lpc-subjects.enum';
import { LpcLayeredRuleService } from '../../../services/lpc-layered-rule.service';
import { CustomPropertyService } from '../../../services/custom-property.service';
import { ModalService } from '@rgi/rx/ui';
import { BuyQuoteComponent } from '../../../modules/buy-quote/buy-quote.component';
import { BuyQuoteData } from '../../../modules/buy-quote/config/buy-quote-data';
import { CustomPropertyCode, OwnerType, PropertyType } from '../../../models/enums/vita.enum';
import { AnagSubject } from '../../../models/subject.model';
import { LpcRolesUtils } from '../../../modules/lpc-roles-step/lpc-roles-utils';
import { RgiRxEventService } from '@rgi/rx';
import { NEW_PARTY, NewPartyEvent, PARTY_CREATED, PartyCreatedEvent } from '@rgi/anag';

@Component({
  selector: 'lpc-life-detail',
  templateUrl: './life-detail.component.html',
  styleUrls: ['./life-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LifeDetailComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() data: any; // da usare solo per caricare il contratto tramite loadDetail()
  @Output() eventPropagation = new EventEmitter<any>();

  private $subscription: Subscription[] = [];
  private readonly baseApiUrl: string;

  public checkMultipleRelatedSubject = LifeDetailComponentUtils.checkMultipleRelatedSubject;
  public getTotalFund = LifeDetailComponentUtils.getTotalFund;
  public getFundGrossAmount = LifeDetailComponentUtils.getFundGrossAmount;
  public getProfileGrossAmount = LifeDetailComponentUtils.getProfileGrossAmount;
  public setDateVal = LifeDetailComponentUtils.setDateVal;
  public multiplyPercentage = LifeDetailComponentUtils.multiplyPercentage;
  public getSymbol = LifeDetailComponentUtils.getSymbol;

  public lifeContract: LifeContractDetail;
  public lifeContractPremium: any;
  public lifeContractWarranties: LifeContractWarranty[] = [];
  public lifeContractInvestimentWarranties: LifeContractWarranty[] = [];
  public isContractPresent: boolean;
  public isPolicy: boolean;
  public initializeView: boolean;
  public haveToShowAppendixNumber = false;
  public loader = false;
  public mobileLoaderActive: boolean;
  public showRisk = false;
  public benediciaries: BeneficiaryLifeDetail[] = [];
  public deathBeneficiariesLinked: BeneficiaryLifeDetail[] = [];
  public investments: ResponseInvestments;
  public movements: Movement[] = [];
  public fiscalData: any;
  public expiryOption: ExpiryOptionData;
  public options: ContractualOption[] = [];
  public availablesInquiryActions: { label: string, action: string, order: number }[] = [];
  public lastMovementId: any;
  public currentMovementId: number = null;
  public movDetail: string;
  public investmentPlan: Array<Profile>;

  public bookings: ReservationConsultationData[] = [];
  pipConfig: PIPConfig[] = [];
  lifeDetailMovementsColumns: string[] = ['Movements', 'Effective Date', 'Title Amount', 'Appendix Number', 'Status Title', ' '];
  lifeDetailMovementsColumnsWithoutNumApp: string[] = ['Movements', 'Effective Date', 'Title Amount', 'Status Title', ' '];
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };
  public quoteNumberFormatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 4
  };

  public decimalNumberFormatterOptionsSix: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 6
  };

  get baseUrl(): string {
    return this.baseApiUrl + APP_SETTING.REST_BASE_URL;
  }

  /**
   * Returns proposal number from LifeDetailComponentUtils
   * old
   * !!this.lifeContract.generalData && !!this.lifeContract.generalData.propNum ? this.lifeContract.generalData.propNum
   *       : !!this.data.lifeContract && !!this.data.lifeContract.proposalNumber ? this.data.lifeContract.proposalNumber : null;
   *
   * @returns string
   */
  get storedProposalNumber(): string {
    return LifeDetailComponentUtils.getStoreProposalNumber(this.lifeContract, this.data);
  }

  /**
   * Returns policy number from LifeDetailComponentUtils
   * old
   * !!this.lifeContract.generalData && !!this.lifeContract.generalData.polNum ? this.lifeContract.generalData.polNum
   *       : !!this.data.lifeContract && !!this.data.lifeContract.policyNumber ? this.data.lifeContract.policyNumber : null;
   *
   * @returns string
   */
  get storedPolicyNumber(): string {
    return LifeDetailComponentUtils.getStoredPolicyNumber(this.lifeContract, this.data);
  }

  constructor(
    protected modalService: NgbModal,
    protected httpClient: HttpClient,
    protected pipDataServiceService: PipDataServiceService,
    @Inject(PV_TOKEN.ENV) protected environment: any,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    protected translate: TranslationWrapperService,
    protected notifierService: NotifierService,
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    protected anagService: AnagService,
    protected proposalDetailService: ProposalDetailService,
    protected customModalService: CustomModalService,
    @Inject(PV_TOKEN.OPERATION_LIST_COMPONENT) protected operationsListComponent: OperationsListComponent,
    @Inject(PV_TOKEN.SHOW_LOADER_MOBILE) protected showMobileLoader: any,
    @Optional() @Inject(LpcCurrencyCache) public currencyService: CurrencyCacheService,
    protected layeredRuleService: LpcLayeredRuleService,
    protected customPropService: CustomPropertyService,
    protected rgiModalService: ModalService,
    protected eventService: RgiRxEventService) {
    this.mobileLoaderActive = !!showMobileLoader ? showMobileLoader : false;
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  ngOnInit(): void {
    this.initializeView = false;
    this.isContractPresent = false;
    this.isPolicy = null;
    if (this.data != null && this.data.lifeContract != null) {
      this.isContractPresent = true;
      this.setPolicy();
    }

    if (this.isContractPresent && this.isPolicy != null) {
      if (!!this.data && !!this.data.lifeContract &&
        (this.data.lifeContract.proposalNumber != null || this.data.lifeContract.policyNumber != null)
      ) {
        this.loadDetail(this.data.lifeContract.proposalNumber, this.data.lifeContract.policyNumber);
      }
    }

    this.$subscription.push(this.notifierService.onNotify().subscribe((notify: Notify) => {
      if (notify) {
        this.handleNotifyEvent(notify.id);
      }
    }));
  }

  ngOnDestroy(): void {
    this.$subscription.forEach(subscription => subscription.unsubscribe());
  }

  public handleNotifyEvent(id) {
    switch (id) {
      case NOTIFY_ON_CLOSE_AFTER_PUBLISH: {
        this.reloadLifeDetailDataAfterPublish();
        break;
      }
      case NOTIFY_AFTER_PUBLISH: {
        this.reloadLifeDetailDataAfterPublish();
        break;
      }
      case LIFE_ISSUE_PROPOSAL_OK: {
        this.reloadLifeDetailDataAfterPublish();
        break;
      }
      case LIFE_PREVENTIVE_OK: {
        this.reloadLifeDetailDataAfterPublish();
        break;
      }
      case NOTIFY_CLOSE_BUY_QUOTE: {
        if (this.storedProposalNumber != null) {
          this.loadDetail(this.storedProposalNumber);
        }
        break;
      }
      default: {
        console.log(`From notificationService: ${id}`);
        break;
      }
    }
  }

  public reloadLifeDetailDataAfterPublish() {
    if (this.storedProposalNumber != null || this.storedPolicyNumber != null) {
      this.setPolicy();
      this.loadDetail(this.storedProposalNumber, this.storedPolicyNumber);
    }
  }

  // @ts-ignore
  private getDetailSession() {
    const session = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE).list().find(ss => ss.name === 'ptfallFinder');
    return !!session ? session.id : null;
  }

  private get detailSessionParent() {
    const session = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE).list().find(ss => ss.name === 'ptfallFinder');
    return !!session ? session.idSession : null;
  }

  private loadDetail(proposalNumber: string, policyNumber?: string, movement?: Movement) {
    this.loader = true;
    const subscription = this.getPolicyDetail(proposalNumber, policyNumber, movement)
      .pipe(
        switchMap((response: LifeContractDetail) => {
          this.currencyService.locale = response.context
          return this.handleLifeDetailResponse(response, movement);
        }),
        switchMap((_) => this.setShowPIPValues()),
        tap((_) => {
          this.checkPossibleActionsOperations();
          this.orderPossibleActionsOperations();
          this.showRiskData();
        })
      ).subscribe(_ => {
        this.loader = false;
      });

    this.$subscription.push(subscription);
  }

  get checkAssetsFactors(): boolean {
    if (!!this.lifeContract.factorsForConsultation.assetsFactors && !!this.lifeContract.factorsForConsultation.assetsFactors.length) {
      return this.lifeContract.factorsForConsultation.assetsFactors.map(factor =>
      ((!!factor.assetFactors && !!factor.assetFactors.length) ||
        (!!factor.risksFactors && !!factor.risksFactors.length))
      ).some(areThereAssetsFactors => areThereAssetsFactors);
    } else {
      return false;
    }
  }

  public handleLifeDetailResponse(response: LifeContractDetail, movement?: Movement): Observable<any> {
    // setting the context on the service used for the number formatter
    this.currencyService.currency = response.currencyInfo.code;
    this.currencyService.context = response.context;
    this.layeredRuleService.context = response.context;

    this.currencyService.locale = response.context;
    this.currencyFormatterOptions.currency = response.currencyInfo.code;

    this.lifeContract = response;
    this.expiryOption = response.expiryOptionData;

    if (!!response.premiumData && !!response.premiumData.premiums
      && response.premiumData.premiums.length > 0) {
      this.lifeContractPremium = {
        firstInstallment: LifeDetailComponentUtils.getPremiumByType(response.premiumData.premiums, 'TYPE_FIRST_INSTALLMENT'),
        annualPremium: LifeDetailComponentUtils.getPremiumByType(response.premiumData.premiums, 'TYPE_ANNUAL_PREMIUM'),
        nextInstallment: LifeDetailComponentUtils.getPremiumByType(response.premiumData.premiums, 'TYPE_NEXT_INSTALLMENT')
      };
    } else {
      this.lifeContractPremium = null;
    }

    if (!!response.warrantiesData && !!response.warrantiesData.warranties) {
      this.lifeContractWarranties = response.warrantiesData.warranties;
    }

    if (!!response.warrantiesData && !!response.warrantiesData.investimentWarranties) {
      this.lifeContractInvestimentWarranties = response.warrantiesData.investimentWarranties;
    }

    // BENEFICIARIES
    this.benediciaries = [];
    this.getBenefFilteredByType().forEach(cathegory => {
      this.benediciaries = this.benediciaries.concat(this.setBeneficiaries(cathegory));
    });

    // RESERVATIONS
    this.bookings = !!response && !!response.policyData.reservations ? response.policyData.reservations : [];


    this.initializeView = true;
    this.plcGraphicFix.fixCardGraphic(this.id);

    return combineLatest([
        this.getConsultinServices(movement).callInvestments,
        this.getConsultinServices(movement).callMovements,
        this.getConsultinServices(movement).callTools,
        this.getConsultinServices(movement).callFiscalData,
        this.getConsultinServices(movement).callInvestmentPlan
      ]).pipe(
      tap(([investmentData, movementData, toolData, fiscalData, investmentPlan]) => {
        this.investments = investmentData;
        this.movements = movementData;
        this.lastMovementId = this.movements[0] ? this.movements[0].idMov : null;
        this.currentMovementId = movement?.idMov;
        this.haveToShowAppendixNumber = LifeDetailComponentUtils.haveMovementAppendixNumber(this.movements);
        this.options = toolData;
        this.fiscalData = fiscalData;
        this.investmentPlan = investmentPlan;
        console.log(this.fiscalData, 'fiscalData');
      }, _ => {
        console.log('Error to call detail data');
        this.loader = false;
      })
    );
  }

  protected getConsultinServices(movement: Movement): ConsultinServicesObj {
    let callInvestments: Observable<any> = of(null);
    let callMovements: Observable<any>;
    let callTools: Observable<any> = of(null);
    let callFiscalData: Observable<any>;
    let callInvestmentPlan: Observable<Array<Profile>>;

    callInvestments = this.getCallInvestments(callInvestments, movement);
    callTools = this.getCallTools(callTools, movement);

    // MOVEMENTS
    callMovements = this.getMovements(this.storedProposalNumber, this.storedPolicyNumber);

    // FISCAL DATA
    const idMov = movement ? movement.idMov : undefined;
    callFiscalData = this.proposalDetailService.getFiscalData(this.storedProposalNumber, idMov);

    // INVESTMENT PLAN
    callInvestmentPlan = this.getInvestmentPlan(idMov);

    return { callInvestments, callMovements, callTools, callFiscalData, callInvestmentPlan};
  }

  protected getCallTools(callTools: Observable<any>, movement: Movement) {
    if (this.isValid() && !!this.storedProposalNumber) {
      callTools = this.getContractualOptions(this.storedProposalNumber, movement);
    }
    return callTools;
  }

  protected getCallInvestments(callInvestments: Observable<any>, movement: Movement) {
    if (this.isValid() && !!this.storedProposalNumber) {
      callInvestments = this.getPolicyInvestments(this.storedProposalNumber, movement);
    }
    return callInvestments;
  }

  private setPolicy() {
    if (this.data.lifeContract.policyNumber != null) {
      this.isPolicy = true;
    } else if (this.data.lifeContract.proposalNumber != null) {
      this.isPolicy = false;
    } else {
      console.error('Contract without policy or proposal number');
    }
  }

  public getHolders() {
    if (this.lifeContract.subjectsData == null) {
      return [];
    }
    const holders = this.lifeContract.subjectsData.subject.filter(s => !s.beneficiary);
    holders.sort((s1, s2) => LifeDetailComponentUtils.compareHolders(s1, s2));
    return holders;
  }
  protected getBenefFilteredByType(): string[] {
    return [...new Set(this.lifeContract.subjectsData.subject.filter(s => s.beneficiary).map(b => b.typeBenef))];
  }

  public getBeneficiaries(type) {
    if (this.lifeContract.subjectsData == null) {
      return [];
    }
    return this.lifeContract.subjectsData.subject.filter(s => s.beneficiary && (type == null || s.typeBenef === type));
  }

  protected setBeneficiaries(category): BeneficiaryLifeDetail[] {
    const benef = this.getBeneficiaries(category);

    const benefVector = [];
    if (!!benef && benef.length > 0) {
      benef.forEach((element, index) => {
        benefVector.push(this.getBenefToShow(element, index));
      });
    }
    return benefVector;
  }

  protected showRiskData(): boolean {
    this.lifeContract.factorsForConsultation.assetsFactors.forEach(element => {
      element.risksFactors.forEach(risk => {
        if (risk.riskFactors.length > 0) {
          this.showRisk = true;
        }
      });
    });
    return this.showRisk;
  }

  public showFiscalData(): boolean {
    return this.fiscalData && (this.fiscalData.whiteListData || this.fiscalData.stampDutyData || this.fiscalData.substituteTaxData);
  }

  protected getBenefToShow(subj: SubjectData, index: number): BeneficiaryLifeDetail {
    return LifeDetailComponentUtils.getBenefToShow(subj, index, this.rgiNumberFormatter, this.percentFormatterOptions);
  }

  public openSession(fund: any) {
    const compose = {
      fund,
      gs: (fund.typeId === '4'),
      contractData: this.data.lifeContract // TODO: numero polizza dopo trasferimento !!!!
    };
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_FUNDS_DETAIL, compose });
  }

  public openTrendSession(policyId: any) {
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_TREND_LIST, policyId });
  }

  public removeSession() {
    this.eventPropagation.emit(JS_EVENT.CLOSE_LIFE_DETAIL);
  }

  public openOptionDetail(option: ContractualOption) {
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_CONTRACTUAL_OPTION_DETAIL, option });
  }

  public openFundDetailSession(warranty: LifeContractWarranty) {
    const movmentId = this.currentMovementId || this.lastMovementId;
    this.getCoverDetails(this.storedPolicyNumber || this.storedProposalNumber, warranty.riskCode, movmentId).subscribe(res => {
      const compose = {
        gs: true,
        contractData: this.data.lifeContract, // TODO: numero polizza dopo trasferimento !!!!
        warrantyName: warranty.unitDescription,
        riskCode: warranty.riskCode,
        labelValues: PlcObjectUtils.isArrayEmpty(res.labelValues) ? res.labelValues : [],
        movmentId
      };
      this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_FUNDS_DETAIL, compose });
    });
    /* return this.proposalDetailService.getBenefitVersion(this.data.lifeContract.policyNumber, warranty.riskCode).subscribe(el => {
      console.log(el);
    }); */
  }

  public openHistoricalConsultation(movement: Movement) {
    if (movement) {
      this.loadDetail(this.storedProposalNumber, this.storedPolicyNumber, movement);
    }
  }

  public openMovementDetailSession(movement: Movement) {
    if (!!movement) {
      if (movement.reasonCode === '_BOLAN') {
        this.openFiscalDataDetail({ stampDutyData: this.fiscalData.stampDutyData });
      } else if (movement.reasonCode === '_CQTWL') {
        this.openFiscalDataDetail({ whiteListData: this.fiscalData.whiteListData });
      } else {
        this.eventPropagation.emit(JS_EVENT.LOADER.START);
        this.$subscription.push(
          this.getMovementDetail(
            this.storedProposalNumber,
            movement.idMov
          ).subscribe((responseDetail: MovementDetail) => {
            const movementDetail: any = movement;
            LifeDetailComponentUtils.evalMovementDetailObj(movementDetail, responseDetail);
            this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
            this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_MOVEMENT_DETAIL, movementDetail });
          }, _ => {
            console.log('ERRORE CHIAMATA Dettaglio Movimento');
            this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          })
        );
      }
    }
  }


  public durationLabel() {
    return LifeDetailComponentUtils.durationLabel(this.translate, this.lifeContract);
  }

  public durationValue() {
    return LifeDetailComponentUtils.durationValue(this.lifeContract);
  }

  public getContractualOptions(contractId: string, movement?: Movement): Observable<ContractualOption[]> {
    if (movement) {
      return this.httpClient.get<ContractualOption[]>(`${this.baseUrl}life/tools/${contractId}/tool?idMovement=${movement.idMov}`);
    } else {
      return this.httpClient.get<ContractualOption[]>(`${this.baseUrl}life/tools/${contractId}/tool`);
    }
  }


  public getMovements(proposalId: string, policyId: string): Observable<Movement[]> {
    const endpoint = !!policyId ? '/?policyNumber=' + policyId : EMPTY_STR;
    return this.httpClient.get<Movement[]>(this.baseUrl + 'life/consultation/' + proposalId + '/movements' + endpoint);
  }

  public getMovementDetail(proposalNumber: string, movementId: number): Observable<MovementDetail> {
    return this.httpClient.get<MovementDetail>(
      `${this.baseUrl}life/consultation/${proposalNumber}/movement/detail/${movementId}`);
  }

  public getReservationDetail(reservationId: any): Observable<ReservationDetail> {
    return this.httpClient.get<ReservationDetail>(
      `${this.baseUrl}life/consultation/reservation/detail/${reservationId}`);
  }

  public cancelReservationDetail(reservationId: any): Observable<RenewalReservationResponse> {
    return this.httpClient.put<RenewalReservationResponse>(
      `${this.baseUrl}life/renewalreservation/${reservationId}/cancel`, null).pipe(
        switchMap(resp => {
          return of(!!resp.errorMessages && !!resp.errorMessages.length ? resp : {errorMessages: []});
        })
    );
  }

  public getPolicyDetail(proposalId: string, policyId: string, movement?: Movement): Observable<LifeContractDetail> {
    this.movDetail = EMPTY_STR;
    let paramPol = EMPTY_STR;
    let paramMov = EMPTY_STR;
    if (movement) {
      this.movDetail = `${movement.description} del ${this.setDateVal(movement.effectDate)}`;
      paramMov = `${!!policyId ? '&' : '?'}idMovement=${movement.idMov}`;
    }
    if (policyId) {
      paramPol = '?PolicyNumber=' + policyId;
    }
    return this.httpClient.get<LifeContractDetail>(this.baseUrl + 'life/contracts/' + proposalId + '/inquiry' + paramPol + paramMov);
  }

  public getPolicyInvestments(policyId: string, movement?: Movement): Observable<any> {
    if (movement) {
      return this.httpClient.get<any>(this.baseApiUrl + '/v2/life/policies/' + policyId + '/synthesis_values' + '?idMovement='
        + movement.idMov);
    } else {
      return this.httpClient.get<any>(this.baseApiUrl + '/v2/life/policies/' + policyId + '/synthesis_values');
    }
  }

  public getInvestmentPlan(idMovement): Observable<Array<Profile>> {
    return this.proposalDetailService.getInvestmentPlan(this.storedProposalNumber, idMovement);
  }

  public getCoverDetails(policyId: string, riskCode: string, idMovement = null): Observable<CoverDetailsResponse> {
    const movement = !!idMovement ? '?idMovement=' + idMovement : '';
    return this.httpClient.get<CoverDetailsResponse>(
      this.baseApiUrl + `/v2/life/policies/${policyId}/coverdetails?riskcode=${riskCode}${movement}`
    );
  }

  public getTotalInvestments(): string {
    const totalInvestments = (+this.investments.grossAmount) - (+this.investments.leftPaidAmount);
    return PlcObjectUtils.roundToDecimal(totalInvestments, 2).toString();
  }

  public getInvestmentAmount(): number {
    return PlcObjectUtils.roundToDecimal(+this.investments.grossAmount, 2);
  }

  public openFiscalDataDetail(detail) {
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_FISCALDATA_DETAIL, fiscalDataDetail: detail });
  }

  public openReinvestmentDataDetail() {
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_REINVESTDATA_DETAIL, reinvestDataDetail: this.lifeContract.reinvestmentData });
  }

  public openLoanDataDetail() {
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_LOANDATA_DETAIL, loanDataDetail: this.lifeContract.loanData });
  }

  public openExpiryOptionDetail() {
    const expiryOptionDetail = this.expiryOption;
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_EXPIRYOPTION_DETAIL, expiryOptionDetail });
  }

  public openInvestmentPlan() {
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_INVESTMENT_PLAN_DETAIL, investmentPlan:  this.investmentPlan });
  }

  public isValid(): boolean {
    return this.lifeContract.generalData.statusCode.toString() !== PolicyStatusCode.CANCELLED &&
      this.lifeContract.generalData.statusCode.toString() !== PolicyStatusCode.VOID;
  }
  // EVENTS -------------------------------------------------------------------------------------
  // metodi da estendere per inserire un'azione a livello di progetto
  // checkPossibleActionsOperations -> super() + aggiungere pulsante
  // getOrder -> aggiungere ordine + super()
  // actionHandler -> aggiungere gestione azione + super()
  // super() indica quando eseguire il metodo di prodotto (prima o dopo la nostra estensione)

  // propaga verso angularJs
  public propagate(event) {
    this.eventPropagation.emit(event);
  }

  // costuisco il vettore di bottoni in base alla risposta del BE
  public checkPossibleActionsOperations() {
    this.availablesInquiryActions = [];
    if (!!this.lifeContract.actionOperations && !!this.lifeContract.actionOperations.length) {
      this.lifeContract.actionOperations.forEach(actionOperation => {
        this.availablesInquiryActions.push({
          label: this.translate.getImmediate('lpc_' + actionOperation),
          action: actionOperation,
          order: this.orderAction(actionOperation) // ordine personalizzato
        });
      });
    }
  }

  public orderAction(actionOperation: string): number {
    return LifeDetailComponentUtils.getOrder(actionOperation);
  }

  // Sort the buttons according to the set order
  public orderPossibleActionsOperations() {
    this.availablesInquiryActions.sort((a, b) => {
      return a.order < b.order ? -1 : 1;
    });
  }

  // Actions are performed based on the code of the event received
  public actionHandler(actionCode: string) {
    switch (actionCode) {
      case 'BACK':
        this.back();
        break;
      case 'ACTIONS':
        this.openActions();
        break;
      case 'DETAIL':
        this.openDetail();
        break;
      case 'CANCEL':
      case 'CANCEL_PREV':
        this.cancelProposalOrPrev(this.storedProposalNumber, actionCode);
        break;
      case 'EDIT':
        this.openQuote(this.lifeContract.generalData.policyId);
        break;
      case 'CONFIRM_PROPOSAL':
        this.issueProposal(this.lifeContract.generalData.policyId);
        break;
      case 'CONFIRM_POLICY':
        this.issuePolicy(this.lifeContract.generalData.policyId);
        break;
      case 'EDIT_PREV':
      case 'EDIT_ANONYMOUS_PREV':
        this.getQuoteFromProposal(this.lifeContract.generalData.policyId, true, actionCode === 'EDIT_ANONYMOUS_PREV');
        break;
      case 'BUY_PREV':
        this.handleBuyQuoteAction();
        break;
      case 'OPEN_LATEST_VERSION':
        this.loadDetail(this.storedProposalNumber, this.storedPolicyNumber);
        break;
      default:
        console.log('event ' + actionCode + ' not found');
        break;
    }
  }

  private handleBuyQuoteAction() {
    this.$subscription.push(
      this.isBuyQuoteThroughIDD().subscribe((res: { isBuyQuote: boolean; subject: AnagSubject}) => {
        console.log('isBuyQuote', res.isBuyQuote);
        if (res.isBuyQuote) {
          this.openBuyQuoteModal(res.subject, this.lifeContract.generalData.policyId);
        } else {
          this.openProposalFromQuote(this.lifeContract.generalData.policyId);
        }
      })
    );
  }

  // -------------------------------------------------------------------------------------

  public back() { // TODO: numero polizza dopo trasferimento !!!!
    (this.data.lifeContract.isGroupPolicy) ? this.propagate(JS_EVENT.CLOSE_ALL_SESSIONS) : this.propagate({eventName: JS_EVENT.BACK_TO_CONTRACT_LIST, id: this.id});
  }

  public openDetail() { // TODO: numero polizza dopo trasferimento !!!!
    this.propagate({ eventName: JS_EVENT.JUMP_PASS_DETAIL, idPolicy: this.data.lifeContract.objectId });
  }

  public openActions() {
    const modalRef = this.modalService.open(this.operationsListComponent, {
      centered: true,
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });
    this.setModalData(modalRef);
  }

  public setModalData(modalRef) {
    if (this.isPolicy) {
      modalRef.componentInstance.policyNumber = this.storedPolicyNumber;
    } else {
      modalRef.componentInstance.policyNumber = this.storedProposalNumber;
    }
    const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
    const s = sessionService.list().find(ss => ss.id === this.id);
    if (s) {
      modalRef.componentInstance.parentSessionId = s.idSession;
    }
    modalRef.componentInstance.loadData();
  }

  public cancelProposalOrPrev(propNumber: string, actionCode: string) {
    this.$subscription.push(
      fromPromise(
        this.openConfirmModal(actionCode).result
      ).subscribe(result => {
        if (result) {
          this.eventPropagation.emit(JS_EVENT.LOADER.START);
          switch (actionCode) {
            case 'CANCEL':
              this.proposalDetailService.cancelProposal(propNumber).subscribe(() => { },
                err => console.error('Observer got an error: ' + err.message),
                () => {
                  this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
                  this.$subscription.push(
                    fromPromise(
                      this.customModalService.openModal('lpc_confirm_operation',
                        this.translate.getImmediate('lpc_sum_det_success_cancel'), true).result
                    ).subscribe(rs => {
                      if (rs) {
                        this.notifierService.notifyComponent(NOTIFY_ON_CLOSE_AFTER_PUBLISH);
                      }
                    })
                  );
                });
              break;
            case 'CANCEL_PREV':
              this.proposalDetailService.cancelPrev(propNumber).subscribe(() => { },
                err => console.error('Observer got an error: ' + err.message),
                () => {
                  this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
                  this.$subscription.push(
                    fromPromise(
                      this.customModalService.openModal('lpc_confirm_operation',
                        this.translate.getImmediate('lpc_sum_det_success_cancel_prev'), true).result
                    ).subscribe(rs => {
                      if (rs) {
                        this.notifierService.notifyComponent(NOTIFY_ON_CLOSE_AFTER_PUBLISH);
                      }
                    })
                  );
                });
              break;
          }
        }
      })
    );
  }

  public issueProposal(policyId: string) {
    this.$subscription.push(
      fromPromise(
        this.customModalService.openModal(
          this.translate.getImmediate('lpc_confirm'),
          this.translate.getImmediate('lpc_sum_det_confirm_prop_issue'), true, false, true).result
      ).subscribe(result => {
        if (result) {
          this.eventPropagation.emit(JS_EVENT.LOADER.START);
          this.$subscription.push(
            this.proposalDetailService.proposalIssue(
              policyId, this.authService.getUsername(), this.lifeContract.generalData.proposalAuthorizationId).pipe(
                switchMap(() => {
                  return this.proposalDetailService.getLifeProposal(policyId);
                }),
                finalize(() => this.eventPropagation.emit(JS_EVENT.LOADER.STOP))
              ).subscribe((prop: any) => {
                this.buildEventForIssue(
                  'ptflifeProposal',
                  'anag',
                  this.translate.getImmediate('lpc_confirm_prop_pol_auth'),
                  JS_EVENT.NAV_LIFE_ISSUE_PAGE,
                  prop.output,
                  'life'
                );
              }));
        }
      })
    );
  }

  public issuePolicy(policyId: string) {
    let contractData = null;
    this.$subscription.push(
      fromPromise(
        this.customModalService.openModal(
          this.translate.getImmediate('lpc_confirm'),
          this.translate.getImmediate('lpc_sum_det_confirm_pol_issue'), true, false, true).result
      ).subscribe(result => {
        if (result) {
          this.eventPropagation.emit(JS_EVENT.LOADER.START);
          this.$subscription.push(
            this.anagService.checkRole(this.getHolders().find(s => s.erole === Number(Roles.CONTRACTOR))).pipe(
                switchMap(res => {
                  if (!!res.checkMsg) {
                    this.customModalService.openModal(
                      this.translate.getImmediate('lpc_generic_error_title'), res.checkMsg, true, false, true
                    );
                    return of();
                  }
                  return this.proposalDetailService.policyIssue(
                    policyId, this.authService.getUsername()
                  );
                }),
                switchMap((resp: any) => {
                  if (!!resp.lifeMovementId) {
                    this.lastMovementId = resp.lifeMovementId;
                  }
                  contractData = [
                    {
                      proposalNumber: this.storedProposalNumber,
                      policyNumber: resp.lifePolicyNumber,
                      contractId: resp.lifePolicyId,
                      objectId: null
                    },
                    {
                      proposalNumber: null,
                      policyNumber: resp.damagePolicyNumber,
                      contractId: resp.damagePolicyId,
                      objectId: null
                    }
                  ];
                  return this.proposalDetailService.getLifeProposal(policyId);
                }),
                finalize(() => this.eventPropagation.emit(JS_EVENT.LOADER.STOP))
              ).subscribe((prop: any) => {
                prop.output.proposal.authorizationId = this.lifeContract.generalData.proposalAuthorizationId;
                prop.output.proposal.contractData = contractData;
                prop.output.proposal.installment = [{ movementId: this.lastMovementId }];
                prop.output.quote.productID = this.lifeContract.generalData.productId;
                this.buildEventForIssue('ptflifeProposal', 'anag',
                  this.translate.getImmediate('lic_PolicyIssue'), JS_EVENT.NAV_LIFE_ISSUE_PAGE, prop.output, 'life');
              }));
        }
      })
    );
  }

  public buildEventForIssue(parent: string, route: string, title: string, eventName: string, proposal: any, type: string) {
    this.propagate({ parent, route, title, eventName, proposal, type, origin: 'consultation' });
  }

  public openSubjDetail(subjId) {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.anagService.openSubjectDetail(subjId).subscribe(resp => {
      // if (resp.anagJs) {
        this.eventPropagation.emit({ eventName: JS_EVENT.ANAG_MANAGE.OPEN_DETAIL, subject: resp.subject });
      // }
    }, err => {
      console.error(err);
    });
  }

  protected getQuoteFromProposal(quoteId, quoteMode: boolean, isAnonymous = false) {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.proposalDetailService.getQuoteFromProposal(quoteId, quoteMode).subscribe((response: any) => {
        response.output.quote.productID = this.lifeContract.generalData.productId;
        const quoteEvent = {
          parent: 'ptflifeProposal',
          route: 'anag',
          title: this.translate.getImmediate('lpc_modify_quote'),
          eventName: JS_EVENT.NAV_LIFE_MODIFY_QUOTE,
          proposal: response.output,
          type: 'life',
          contract: this.lifeContract,
          isAnonymous,
          isFromPreventive: true
        };
        this.eventPropagation.emit(quoteEvent);
    },
      (err) => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        console.error(err);
      });
  }

  protected openQuote(quoteId) {
    this.openIssueSession(quoteId, this.translate.getImmediate('lpc_modify_proposal'), JS_EVENT.NAV_LIFE_MODIFY_INDEF_PROP);
  }

  protected openProposalFromQuote(quoteId) {
    this.openIssueSession(quoteId, this.translate.getImmediate('lpc_buy_quote'), JS_EVENT.NAV_LIFE_BUY_QUOTE);
  }

  private openIssueSession(quoteId: any, title: string, eventName: string) {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.proposalDetailService.getLifeProposal(quoteId).subscribe((response: any) => {
      this.eventPropagation.emit({
        parent: 'ptflifeProposal',
        route: 'anag',
        title,
        eventName,
        proposal: response.output,
        type: 'life',
        contract: this.lifeContract
      });
    },
      (err) => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        console.error(err);
      });
  }

  public cancelReservation(reservation: ReservationConsultationData) {
    // chiamare servizio che si occupa di cancellare la prenotazione, in seguito rifare la chiamata per aggiornare i valori
    // oppure cambiare stato alla conferma di avvenuta cancellazione
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.$subscription.push(
      this.cancelReservationDetail(reservation.reservationId).pipe(
        catchError(err => {
          console.log('ERRORE CHIAMATA Cancellazione Prenotazione');
          this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          throw new Error(err);
        })
      ).subscribe((responseReservationDetail: RenewalReservationResponse) => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        const interpolateParams = {descr: reservation.reservationType, num: reservation.reservationId};
        const messages = responseReservationDetail.errorMessages.map(msg => new ModalMessage(msg));

        if (!messages.length) {
          const msg = new ModalMessage();
          msg.message = this.translate.getImmediate('lpc_success_reservation_cancel', interpolateParams);
          msg.messageType = ModalMessageType.SUCCESS;
          messages.push(msg);
        }
        const title = `${this.translate.getImmediate('lpc_confirm')} ${interpolateParams.descr}`;
        fromPromise(
          this.customModalService.openModal(title, EMPTY_STR, false, false, true, messages).result
        ).subscribe(r => this.loadDetail(this.data.lifeContract.proposalNumber, this.data.lifeContract.policyNumber));
      })
    );
  }

  public openReservationDetail(reservationId) {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.$subscription.push(
      this.getReservationDetail(reservationId).pipe(
        catchError(err => {
          console.log('ERRORE CHIAMATA Dettaglio Prenotazione');
          this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          throw new Error(err);
        })
      ).subscribe((responseReservationDetail: ReservationDetail) => {
        this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
        this.eventPropagation.emit({eventName: JS_EVENT.OPEN_BOOKING_DETAIL, data: responseReservationDetail});
      })
    );
  }

  public showPIPData(): boolean {
    return !!this.pipConfig.length;
  }

  public pipDetailSession(event: ContributionPipEvent) {
    this.$subscription.push(
      this.pipDataServiceService.getPIPbyType(this.data.lifeContract.proposalNumber,
        this.currentMovementId || this.lastMovementId,
        this.pipDataServiceService.getTypeByEvent(event))
        .subscribe( (pipdata: PipDataResp ) => {
          const pipSpecificData: PipDataResp = {
           pipCardDataType: event,
           [event]: pipdata[event]
          };
          this.eventPropagation.emit(JS_EVENT.LOADER.STOP);
          this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_PIP_DATA, data: pipSpecificData});
      } )
    );
  }

  private setShowPIPValues(): Observable<PIPConfig[]> {
      return this.pipDataServiceService.getPIPConfig(
        this.data.lifeContract.proposalNumber,
        this.currentMovementId || this.lastMovementId)
        .pipe(
        tap( data =>  this.pipConfig = data)
      );
  }

  openPremiumDetail() {
    const movmentId = this.currentMovementId || this.lastMovementId;
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    // make service call
    this.$subscription.push(
      this.proposalDetailService.getPremiumDetail(this.storedPolicyNumber || this.storedProposalNumber, movmentId)
      .pipe(
        catchError(err => {
          console.log('ERRORE CHIAMATA Dettaglio Prenotazione');
          return throwError(err);
        }),
        finalize(() => this.eventPropagation.emit(JS_EVENT.LOADER.STOP))
      )
      .subscribe(res => {
        this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_PREMIUM_DETAIL, data: res.installments});
      })
    );
  }

  getValidMovements(movements: Movement[]): Movement[]{
    return this.movements.filter(movement => this.isMovementIdValid(movement.reasonCode));
  }

  // RDDL-9260 hide _INVEF _DISEF from movement table, please add anyother Id you wish to hide
  isMovementIdValid(id: string): boolean {
    const blackListedMovementIds = ['_INVEF', '_DISEF']; // Add more IDs to the blacklist as needed
    return !blackListedMovementIds.includes(id);
  }

  protected openBuyQuoteModal(subject: AnagSubject, quoteID: string) {

    const data: BuyQuoteData = {
      subject,
      node: this.lifeContract.generalData.managmentNode,
    }
    const {modal, component} = this.rgiModalService.openComponent(BuyQuoteComponent, data);
    component.idParentSession = this.detailSessionParent;

    this.$subscription.push(
      this.eventService.listen<NewPartyEvent>(NEW_PARTY).subscribe(event => {
        modal.elRef.nativeElement.classList.remove('rgi-rx-modal-show');
        modal.elRef.nativeElement.classList.add('rgi-rx-modal-hidden');
        document.body.classList.remove('rgi-rx-modal-for-body');
      }),
      this.eventService.listen<PartyCreatedEvent>(PARTY_CREATED).subscribe(event => {
        modal.elRef.nativeElement.classList.add('rgi-rx-modal-show');
        modal.elRef.nativeElement.classList.remove('rgi-rx-modal-hidden');
        document.body.classList.add('rgi-rx-modal-for-body');
      }),
      modal.onClose.subscribe((res) => {
        if (!!res) {
          let proposal = null;
          return this.$subscription.push(
            this.proposalDetailService.getLifeProposal(quoteID).pipe(
              switchMap((response: any) => {
                proposal = response.output;
                if (!res.holder?.iddQuestionnaires) {
                  return this.anagService.openSubjectDetail(res.holder.objectId).pipe(map(res => res.subject));
                }
                return of(res.holder);
              })
            ).subscribe((holder: any) => {
              if (!proposal.proposal.lifeParty?.length) {
                proposal.proposal.lifeParty = [LpcRolesUtils.anagSubjectToLifeParty(holder, Roles.CONTRACTOR)];
              }
              this.eventPropagation.emit({eventName: JS_EVENT.PASSPRO_PRO_CARDS, holder, proposal });
            })
          );
        }
      })
    );
  }

  protected isBuyQuoteThroughIDD(): Observable<{isBuyQuote: boolean, subject: AnagSubject}> {
    let isBuyQuote = false;
    return this.customPropService.getCustomProperty(
      CustomPropertyCode.LIFE_QUICK_QUOTATION_PURCHASE_THROUGH_IID,
      PropertyType.BOOLEAN,
      this.lifeContract.generalData.productCode,
      OwnerType.TIPO_OWNER_PRODOTTO
    ).pipe(
      switchMap(customProp => {
        isBuyQuote = customProp.booleanValue;
        const holder = this.lifeContract.subjectsData.subject.find(s => s.erole === Number(Roles.CONTRACTOR));
        if (!isBuyQuote || !holder) {
          return of({subject: null})
        }
        return this.anagService.openSubjectDetail(holder.id);
      }),
      map(res => {
        return {
          isBuyQuote,
          subject: res.subject
        }
      })
    );
  }

  public openConfirmModal(actionCode: string){
    return this.customModalService.openModal(
      this.translate.getImmediate('lpc_confirm'),
      LifeDetailComponentUtils.getMessageForPopUp(actionCode, this.translate), true, false, true);

  }

  public openMovementDocumentSession(movement: Movement) {
    const proposalNumber = this.storedProposalNumber;
    const contractId = this.lifeContract.generalData.policyId;
    this.eventPropagation.emit({ eventName: JS_EVENT.OPEN_INBOUND_DOC_INQURY, data: {movement, proposalNumber, contractId}});
  }

}
