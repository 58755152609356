<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="true" #first color="info">
    <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-6 col-md-6">
                <label ng-bind="label" *ngIf="inputObject.claimNumber else potentialClaim">
                    {{ '_CLAIMS_._CLAIM_NUMBER' | translate }}: {{inputObject.claimNumber}}
                </label>
                <ng-template #potentialClaim>
                    <label ng-bind="label">
                        {{ '_CLAIMS_._POTENTIAL_CLAIM_NUMBER' | translate }}: {{inputObject.potentialClaimNumber}}
                    </label>
                </ng-template>
            </div>
            <div class="col-xs-6 col-md-6">
            </div>
            <!-- <div class="col-xs-6 col-md-6" *ngIf="inputObject.subTitleLabel">
                <label ng-bind="label">
                    {{ inputObject.subTitleLabel | translate }}: {{inputObject.subTitle}}
                </label>
            </div> -->
            <div class="col-xs-6 col-md-6">
                <label ng-bind="label">
                    {{inputObject.companyCode}} - {{inputObject.companyDescription}}
                </label>
            </div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="containerInfoClaims"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion">
<div class="row rgi-claims-sub-title-section-shared ">
    <div class="col-xs-12 col-md-12">
    <label ng-bind="label">
        {{ '_CLAIMS_._GENERAL_DATA' | translate }}
    </label>
    </div>
</div>
<ng-container *ngTemplateOutlet="containerInfoClaims"></ng-container>
</div>


<ng-template #containerInfoClaims>
    <div class="container-fluid table-white">
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._POLICY_NUMBER' | translate
                    }}:</label>
                <label ng-bind="label">{{inputObject.policyNumber}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CONTRACTOR' | translate
                    }}:</label>
                <label ng-bind="label">{{inputObject.policyHolder}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._PRODUCT' | translate
                    }}:</label>
                <label ng-bind="label">{{inputObject.productCode}} - {{inputObject.productDescription}}</label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._AGENCY' | translate
                    }}:</label>
                <label ng-bind="label">{{inputObject.policyAgencyEntity.agencyCode}} - {{inputObject.policyAgencyEntity.agencyDescription}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._ADMINISTRATIVE_REGULARITY' |
                    translate }}:</label>
                <label ng-bind="label">{{(inputObject.administrativeRegularity ? '_CLAIMS_._YES' : '_CLAIMS_._NO') | translate}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._TECHNICAL_COVERAGE' |
                    translate }}:</label>
                <label ng-bind="label">{{(inputObject.technicalCoverage ? '_CLAIMS_._YES' : '_CLAIMS_._NO') | translate}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MINISTERIAL_BRANCH' |
                    translate }}:</label>
                <label ng-bind="label">{{inputObject.ministerialCode}} - {{inputObject.ministerialDescription}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COINSURANCE_TYPE' |
                    translate }}:</label>
                <label ng-bind="label">{{inputObject.coinsuranceType}} </label>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COMPLAINT_DATE' | translate
                    }}:</label>
                <label ng-bind="label">{{inputObject.potentialClaimDate | date:'dd/MM/yyyy'}} </label>
            </div>
            <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._OCCURRENCE_DATE' | translate
                    }}:</label>
                <label ng-bind="label">{{inputObject.occurrenceDate | date:'dd/MM/yyyy'}} </label>
            </div>
            <div class="col-xs-3 col-md-3" *ngIf="inputObject.occurrenceDateCertified !== null" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._EFFECTIVE_COMPLAINT_DATE' |
                    translate }}:</label>
                <label ng-bind="label">{{inputObject.occurrenceDateCertified | date:'dd/MM/yyyy'}} </label>
            </div>
            <div class="col-xs-3 col-md-3" *ngIf="inputObject.claimType" style="font-size: 14px;">
                <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._CLAIM_TYPE' | translate
                    }}:</label>
                <label ng-bind="label">{{inputObject.claimType}} </label>
            </div>
        </div>
    </div>
</ng-template>
