import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {CustomModalService} from '../../custom-modal.service';
import {AddressModalComponent} from './address-modal/address-modal.component';
import {AddressesService} from './addresses.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PolicyContacts} from './policy-contacts';
import {ProposalService} from '../../proposal.service';
import {QuestionnaireService} from '../../questionnaires/questionnaire.service';
import {PolicyAddress} from './policy-address';
import {Address} from './address';
import {Message} from '../../models/message';
import {Observable, Subscription} from 'rxjs';
import {AddressType} from './address.type';
import { AnagAddressData, AnagApiAddress, AnagUbicationComponent, CORE_ADDRESS_FUNCTION } from '@rgi/anag';
import { ModalService } from '@rgi/rx/ui';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'mic-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit, DoCheck, OnDestroy {
  public addressesControlForm: UntypedFormGroup;
  public validationMessages: Message[] = [];
  public mailMessages: Message[] = [];
  public addressesOptions: Array<any>;
  public model: any;
  public selectedOption: any;
  public policyContacts: PolicyContacts;
  public showPostalAddresses = true;
  public holderMails = [];
  public newEmail: boolean;
  public customMail = '';
  public mailConfirmed: boolean;
  public usingCustom: boolean;
  public showEmailAddress = false;
  public showHomeInsurance = false;
  @Input() retrieveNewContacts: Observable<void>;
  @Input() refreshPolicyContacts: Observable<void>;
  @Input () isFormEnabled = true;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() valuesChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('addressModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) addressModalViewContainerRef: ViewContainerRef;
  componentRef: any;
  protected eventsSubscription: Subscription;
  private lastContactsCall: Date;
  private areaCode = 'POLICY_CONTACTS';

  constructor(
    protected proposalService: ProposalService,
    protected addressesService: AddressesService,
    protected modalService: CustomModalService,
    protected formBuilder: UntypedFormBuilder,
    protected questionnaireService: QuestionnaireService,
    protected rgiRxModalService: ModalService,
    protected translate: TranslateService
  ) {
  }

  @Input()
  set viewEmailAddress(event) {
    this.showEmailAddress = event;
    this.showPostalAddresses = !event;
  }

  @Input()
  set viewPostalAddresses(event) {
    this.showPostalAddresses = event;
    this.showEmailAddress = !event;
  }

  public get addressType(): typeof AddressType {
    return AddressType;
  }

  ngOnInit() {

    this.eventsSubscription = this.retrieveNewContacts.subscribe(() => {
      this.getContacts();
    });

    const refreshContactsSubscription = this.refreshPolicyContacts.subscribe(() => {
      this.policyContacts = this.proposalService.getPolicyContacts();
      if (this.policyContacts.addressList.find(policyAddresses => policyAddresses.main)) {
        this.policyContacts.availableAddress.forEach(address => address.main = false);
      }
      if (this.policyContacts.channel === 1) {
        this.addressesControlForm.get('emailInput').setValue(this.policyContacts.email);
      }
    });

    this.eventsSubscription.add(refreshContactsSubscription);

    this.addressesOptions = [
      {label: 'Postal Mail', value: 'postal', channel: null, show: this.showPostalAddresses, dataQa: 'btn-postal-mail'},
      {label: 'Electronic Mail', value: 'email', channel: 1, show: this.showEmailAddress, dataQa: 'btn-email'},
      {label: 'Home Insurance', value: 'hi', channel: 2, show: this.showHomeInsurance, dataQa: 'btn-home-insurance'}];

    this.addressesControlForm = this.formBuilder.group(
      {
        emailInput: new UntypedFormControl('', [Validators.required]),
        phoneInput: new UntypedFormControl('', [])
      }
    );

    this.addressesService.retrieveContactsConfig().subscribe(
      data => {
        if (data) {
          this.addressesOptions[1].show = this.showEmailAddress = data.emailEnabled;
          this.addressesOptions[2].show = this.showHomeInsurance = data.homeInsuranceEnabled;
        }
      }
    );

    this.proposalService.setPolicyContacts(null);

    if (!this.proposalService.getPolicyContacts()) {
      this.retrievePolicyContacts();
    } else {
      this.policyContacts = this.getCopy(this.proposalService.getPolicyContacts());
    }

    this.questionnaireService.getConfirmQuestionnaireSignal().subscribe(
      (data) => {
        this.getContacts();
      }
    );

    if (this.isFormEnabled === false) {
      this.addressesControlForm.disable();
    } else {
      this.addressesControlForm.get('emailInput').valueChanges.subscribe(selectedValue => {
        if (this.policyContacts.email != null && this.policyContacts.availableEmails &&
          this.policyContacts.availableEmails.indexOf(this.policyContacts.email) < 0) {
          this.policyContacts.email = selectedValue;
        }
      });

      this.addressesControlForm.get('phoneInput').valueChanges.subscribe(selectedValue => {
        if (this.policyContacts.mobilePhone != null && this.policyContacts.availablePhoneNumbers
          && this.policyContacts.availablePhoneNumbers.indexOf(this.policyContacts.mobilePhone) < 0) {
          this.policyContacts.mobilePhone = selectedValue;
        }
      });
    }
  }

  onChangeValue(option: any) {
    if (this.policyContacts.channel !== option.channel && 'postal' !== option.value) {
      if (!this.policyContacts.email && this.policyContacts.availableEmails && this.policyContacts.availableEmails.length > 0) {
        this.selectMail(this.policyContacts.availableEmails[0]);
      } else if (this.policyContacts.availablePhoneNumbers && this.policyContacts.availablePhoneNumbers.length > 0) {
        this.selectPhoneNumber(this.policyContacts.availablePhoneNumbers[0]);
      }
    }
    this.policyContacts.channel = option.channel;
    this.ngDoCheck();
    this.valuesChanged.emit();
  }

  editAddress(event: any) {
    this.valuesChanged.emit();
    const ubicationData = new AnagAddressData();
    ubicationData.modalLabel = this.translate.instant('Edit Address');
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.portfolioAddress;
    ubicationData.address = this.transformAnagAddress(event.address, true);
    const ubicationModal = this.rgiRxModalService.openComponent(AnagUbicationComponent, ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(addr => {
      if(addr){
        const address: Address = this.transformAnagAddress(addr);
        this.policyContacts.addressList[event.index].address = address;
      }
    });
  }

  retrievePolicyContacts() {
    this.lastContactsCall = new Date();
    this.proposalService.retrievePolicyContacts().subscribe(
      (data) => {
        if (!data.addressList) {
          data.addressList = [];
        }
        if (!data.availableEmails) {
          data.availableEmails = [];
        }
        if (!data.availablePhoneNumbers) {
          data.availablePhoneNumbers = [];
        }
        this.policyContacts = data;
        this.proposalService.setPolicyContacts(data);
        this.addressesService.setAddresses(data.addressList.map(x => x.address));
        this.proposalService.setPolicyContacts(this.getCopy(this.policyContacts));
        // prioritize main policy address (multiple main addresses from backend scenario)
        if (this.policyContacts.addressList.length > 0) {
          const mainPolAddr: PolicyAddress = this.policyContacts.addressList.find(address => address.main);
          if (mainPolAddr) {
            this.selectMainAddress(mainPolAddr, AddressType.POLICYADDRESS);
          }
        } else if (this.policyContacts.availableAddress.length > 0) {
          const mainSubscriberAddr: PolicyAddress = this.policyContacts.availableAddress.find(address => address.main);
          if (mainSubscriberAddr) {
            this.selectMainAddress(mainSubscriberAddr, AddressType.SUBSCRIBERADDRESS);
          }
        }
        if (this.policyContacts.channel === 1) {
          this.addressesControlForm.get('emailInput').setValue(this.policyContacts.email);
        }
      }
    );
  }

  ngDoCheck() {
    if (this.policyContacts && !this.deepEqual(this.policyContacts, this.proposalService.getPolicyContacts())) {
      this.validateForm();
      this.proposalService.setPolicyContacts(this.getCopy(this.policyContacts));
      // send event change
      this.proposalService.sendRefreshContactsSignal(this.addressesControlForm.valid);
    }
  }

  getCopy(obj) {
    return (JSON.parse(JSON.stringify(obj)));
  }

  deepEqual(obj1, obj2) {
    return JSON.stringify(obj1).toLowerCase() === JSON.stringify(obj2).toLowerCase();
  }

  delAddress(index: number) {
    this.valuesChanged.emit();
    const policyAddresses = this.policyContacts.addressList;
    const adr = policyAddresses[index];
    policyAddresses.splice(index, 1);
    if (adr.main) {
      if (policyAddresses.length > 0) {
        this.selectMainAddress(this.policyContacts.addressList[0], AddressType.POLICYADDRESS);
      } else if (this.policyContacts.availableAddress && this.policyContacts.availableAddress.length > 0) {
        this.selectMainAddress(this.policyContacts.availableAddress[0], AddressType.SUBSCRIBERADDRESS);
      }
    }
  }

  addAddress() {
    const ubicationData = new AnagAddressData();
    ubicationData.address = new AnagApiAddress();
    ubicationData.modalLabel = this.translate.instant('ADD NEW ADDRESS');
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.portfolioAddress;
    const ubicationModal = this.rgiRxModalService.openComponent(AnagUbicationComponent, ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(addr => {
      if(addr){
        const address: Address = this.transformAnagAddress(addr);
        const polAddress = new PolicyAddress(address, false, null);
        if (!this.policyContacts.addressList) {
          this.policyContacts.addressList = [];
        }
        this.policyContacts.addressList.push(polAddress);
        this.selectMainAddress(polAddress, AddressType.POLICYADDRESS);
      }
    });
  }

  selectMainAddress(policyAddress: PolicyAddress, addressType: AddressType) {
    this.valuesChanged.emit();
    if (addressType === AddressType.POLICYADDRESS) {
      if (this.policyContacts.addressList) {
        this.policyContacts.addressList.forEach(el => el.main = (el === policyAddress));
      }
      if (this.policyContacts.availableAddress) {
        this.policyContacts.availableAddress.forEach(el => el.main = false);
      }
    } else if (addressType === AddressType.SUBSCRIBERADDRESS) {
      if (this.policyContacts.availableAddress) {
        this.policyContacts.availableAddress.forEach(el => el.main = (el === policyAddress));
      }
      if (this.policyContacts.addressList) {
        this.policyContacts.addressList.forEach(el => el.main = false);
      }
    }
  }

  openAddressModal(policyAddress?: PolicyAddress) {
    const isNewAddress = !!policyAddress;
    this.valuesChanged.emit();
    const addressModal = this.modalService.openModal(this.addressModalViewContainerRef,
      AddressModalComponent, this.eventPropagation, () => {
        // remove the address if the city is missing (case user press cancel after add)
        if (isNewAddress && addressModal.instance.closingFromButton) {
          const filtered = this.policyContacts.addressList.filter((value, index, arr) => value !== policyAddress);
          this.policyContacts.addressList = filtered;
        } else {
          if (policyAddress) {
            this.selectMainAddress(policyAddress, AddressType.POLICYADDRESS);
          }
        }
      });
  }

  selectMail(email) {
    this.policyContacts.mobilePhone = null;
    this.policyContacts.email = email;
    this.addressesControlForm.get('emailInput').setValue(email);
  }

  selectPhoneNumber(phone) {
    this.policyContacts.mobilePhone = phone;
    this.policyContacts.email = null;
  }

  editMail() {
    this.mailConfirmed = false;
  }

  checkAddress(address) {
    for (const addressObj of this.policyContacts.availableAddress) {
      const add: any = addressObj.address;
      if (add.cap === address.cap && add.city === address.city && add.countryCode === address.countryCode &&
        add.formatAddress === address.formatAddress && add.adminLevel1 === address.adminLevel1 &&
        add.adminLevel2 === address.adminLevel2 && add.adminLevel3 === address.adminLevel3 && add.placeAddress === address.placeAddress &&
        add.number === address.number) {
        return true;
      }
    }
    return false;
  }

  selectAddress(event: any) {
    this.selectMainAddress(event.selectedAddress, event.addressType);
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  private getContacts() {
    if (this.proposalService.getContractId()) {
      this.retrievePolicyContacts();
    }
  }

  private validateForm() {
    this.addressesControlForm.get('emailInput').clearValidators();
    this.addressesControlForm.get('phoneInput').clearValidators();
    if (1 === this.policyContacts.channel || (2 === this.policyContacts.channel && this.policyContacts.email != null)) {
      if (this.policyContacts.availableEmails.indexOf(this.policyContacts.email) < 0) {
        this.addressesControlForm.get('emailInput').setValidators([Validators.required, Validators.email]);
      }
    }
    if (2 === this.policyContacts.channel && this.policyContacts.mobilePhone != null) {
      if (!this.policyContacts.availablePhoneNumbers ||
        this.policyContacts.availablePhoneNumbers.indexOf(this.policyContacts.mobilePhone) < 0) {
        const input = this.addressesControlForm.get('phoneInput');
        input.setValidators([Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]);
      }
    }
    this.addressesControlForm.get('emailInput').updateValueAndValidity();
    this.addressesControlForm.get('phoneInput').updateValueAndValidity();
    this.addressesControlForm.updateValueAndValidity();
    const controls = this.addressesControlForm.controls;


    this.validationMessages.length = 0;
    if (controls.emailInput.errors) {
      const msg = controls.emailInput.errors.required ? this.translate.instant('Email is mandatory') : this.translate.instant('Incorrect Email');
      this.validationMessages.push(new Message(this.areaCode, msg));
    }
    if (controls.phoneInput.errors) {
      const msg = controls.phoneInput.errors.required ? this.translate.instant('Phone Number is mandatory') : this.translate.instant('Incorrect Mobile Phone');
      this.validationMessages.push(new Message(this.areaCode, msg));
    }
    this.eventPropagation.emit('layoutChanged');
  }

  isFormDisabled() {
    return this.addressesControlForm.disabled;
  }

  transformAnagAddress(addressVal: any, toAnag:boolean = false) {

    let newAddressVal = null;

    if (addressVal) {
      newAddressVal = {
        country: this._getEnum(addressVal.country, toAnag),
        province: this._getEnum(addressVal.province, toAnag),
        city: addressVal.city,
        placeAddress: addressVal.placeAddress,
        number: addressVal.number,
        cap: addressVal.cap,
        countryCode: addressVal.countryCode,
        adminLevel1: addressVal.adminLevel1,
        adminLevel1Short: addressVal.adminLevel1Short,
        adminLevel2: addressVal.adminLevel2,
        adminLevel2Short: addressVal.adminLevel2Short,
        adminLevel3: addressVal.adminLevel3,
        adminLevel3Short: addressVal.adminLevel3Short,
        formatAddress: addressVal.formatAddress ? addressVal.formatAddress : this.formatAddress(addressVal),
        locality: addressVal.locality,
        fraction: addressVal.fraction,
        toponym: this._getEnum(addressVal.toponym, toAnag),
        normalized: addressVal.normalized
      };
    }
    return newAddressVal;
  }

  protected _getEnum(enumVal, toAnag:boolean) {
    let newEnumVal = null;

    if (enumVal) {
      if(toAnag){
        newEnumVal = {
          codice: enumVal.code,
          descrizione: enumVal.description
        };
      }else{
        newEnumVal = {
          code: enumVal.codice,
          description: enumVal.descrizione
        }
      }
    }
    return newEnumVal;
  }

  formatAddress(address: any): string {
    const formattedAddress =
      `${address.placeAddress},
       ${address.number}
       ${address.cap}
       ${address.adminLevel3}
       (${address.adminLevel2})
       ${address.countryCode}`;

    return formattedAddress;
  }
}
