import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {tap} from 'rxjs/operators';
import {AnagStateHome, AnagStateManagerHome} from '../../anag-states/home/anag-state-manager-home';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {RgiRxTabSelectionChange} from '@rgi/rx/ui';
import {ANAG_FUNCTIONS, ANAG_TABS, CORE_ADDRESS_FUNCTION} from '../../anag-constants/anag-constants';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {
  CloseAnagModalEvent,
  CloseAnagModalEventInstance,
  UMA_ADDRESS_UPDATE,
  UmaAddressUpdateEvent
} from '../../anag-events/anag-event';
import {PushMessageHandlerService, RgiRxEventService, RgiRxPushMessage} from '@rgi/rx';
import {AnagHomeFieldConfigurationService} from '../../services/anag-home-field-configuration.service';
import {
  FIELD,
  FIELD_ANAG_BIRTH_DATE,
  FIELD_ANAG_BIRTH_NAME,
  FIELD_ANAG_CLIENT_NUMBER,
  FIELD_ANAG_COWORKER,
  FIELD_ANAG_CRITERIA,
  FIELD_ANAG_EMAIL,
  FIELD_ANAG_INTERNATIONAL_PREFIX,
  FIELD_ANAG_LOCAL_PREFIX,
  FIELD_ANAG_NAME,
  FIELD_ANAG_PARTY_NUMBER,
  FIELD_ANAG_PARTY_TYPE,
  FIELD_ANAG_PHONE,
  FIELD_ANAG_RESIDENCE,
  FIELD_ANAG_STATE,
  FIELD_ANAG_SUBSYSTEM,
  FIELD_ANAG_SURNAME,
  FIELD_ANAG_TAB_ACCORPAMENTO,
  FIELD_ANAG_TAB_PARTY_MANAGEMENT,
  FIELD_ANAG_UNIQUE_KEY
} from '../../anag-constants/anag-home-fields-constants';
import {AnagApiAddress} from '../../anag-model/anag-domain/anag-api-party';
import {of, Subject, Subscription} from 'rxjs';
import {onDropSubjectChangeFormControl} from '../../anag-dropcontainer/anag-dropcontainer-functions';
import {AnagStateManagerKeyData} from '../../anag-states/key-data/anag-state-manager-key-data';
import {RgiRxUserAuthorizationService, RgiRxUserService} from '@rgi/rx/auth';
import {AnagFlowData} from '../../anag-model/anag-domain/anag-flow-data';
import {OperatorLight} from '../../services/external-service';
import {AnagIdentificationEntity} from '../../anag-model/anag-domain/anag-identification-entity';
import {distinctUntilChanged} from "rxjs/internal/operators/distinctUntilChanged";
import { AnagExtensionsService } from '../../anag-resources/anag-extensions.service';


@Component({
  selector: 'rgi-anag-home',
  templateUrl: './anag-home.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagHomeComponent extends RoutableComponent implements OnInit, OnDestroy {

  constructor(
    public stateMgr: AnagStateManagerHome,
    public stateMgrKeyData: AnagStateManagerKeyData,
    protected anagStorage: AnagStorageService,
    protected anagIntegration: AnagIntegrationService,
    protected eventService: RgiRxEventService,
    protected activeRoute: ActiveRoute,
    protected anagHomeFieldConfigurationService: AnagHomeFieldConfigurationService,
    protected authorizationService: RgiRxUserAuthorizationService,
    public pushMessageHandler: PushMessageHandlerService,
    protected userService: RgiRxUserService,
    protected anagExtensionsService: AnagExtensionsService
  ) {
    super();
    this.defaultPartyNation = this.userService.getUser<OperatorLight>().salePoint ?
      this.userService.getUser<OperatorLight>().salePoint.context : 'IT';
  }

  numberValidator = Validators.pattern('^[0-9]*$');
  internationalPrefixes: Array<AnagIdentificationEntity>;
  anagDropContainerSelectData: AnagFlowData;
  state: AnagStateHome;

  subscriptions: Subscription = new Subscription();

  tabs: number;

  partyNotFound: boolean;
  FIELD_ANAG_SURNAME = FIELD_ANAG_SURNAME;
  FIELD_ANAG_NAME = FIELD_ANAG_NAME;
  FIELD_ANAG_UNIQUE_KEY = FIELD_ANAG_UNIQUE_KEY;
  FIELD_ANAG_COWORKER = FIELD_ANAG_COWORKER;
  FIELD_ANAG_CLIENT_NUMBER = FIELD_ANAG_CLIENT_NUMBER;
  FIELD_ANAG_PARTY_NUMBER = FIELD_ANAG_PARTY_NUMBER;
  FIELD_ANAG_BIRTH_NAME = FIELD_ANAG_BIRTH_NAME;
  FIELD_ANAG_PARTY_TYPE = FIELD_ANAG_PARTY_TYPE;
  FIELD_ANAG_TAB_PARTY_MANAGEMENT = FIELD_ANAG_TAB_PARTY_MANAGEMENT;
  FIELD_ANAG_TAB_ACCORPAMENTO = FIELD_ANAG_TAB_ACCORPAMENTO;
  FIELD_ANAG_RESIDENCE = FIELD_ANAG_RESIDENCE;
  FIELD_ANAG_SUBSYSTEM = FIELD_ANAG_SUBSYSTEM;
  FIELD_ANAG_CRITERIA = FIELD_ANAG_CRITERIA;
  FIELD_ANAG_STATE = FIELD_ANAG_STATE;
  FIELD_ANAG_PHONE = FIELD_ANAG_PHONE;
  FIELD_ANAG_LOCAL_PREFIX = FIELD_ANAG_LOCAL_PREFIX;
  FIELD_ANAG_INTERNATIONAL_PREFIX = FIELD_ANAG_INTERNATIONAL_PREFIX;
  FIELD_ANAG_EMAIL = FIELD_ANAG_EMAIL;
  FIELD_ANAG_BIRTH_DATE = FIELD_ANAG_BIRTH_DATE;
  CORE_ADDRESS_FUNCTION = CORE_ADDRESS_FUNCTION;
  defaultPartyNation: string;
  isPartyGrouping: boolean;

  onDropChange = onDropSubjectChangeFormControl;

  homeForm = new UntypedFormGroup({});

  requiredFieldsValidatorFn: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
    const surname = this.isFieldEmpty(formGroup, 'surname');
    const birthName = this.isFieldEmpty(formGroup, 'birthName');
    const key1 = this.isFieldEmpty(formGroup, 'partyKey1');
    const key2 = this.isFieldEmpty(formGroup, 'partyKey2');
    const key3 = this.isFieldEmpty(formGroup, 'partyKey3');
    const coworker = this.isFieldEmpty(formGroup, 'coworker');
    const clientNumber = this.isFieldEmpty(formGroup, 'clientNumber');
    const fiscalCode = this.isFieldEmpty(formGroup, 'fiscalCode');
    const vatNumber = this.isFieldEmpty(formGroup, 'vatNumber');
    const partyNumber = this.isFieldEmpty(formGroup, 'partyNumber');
    const subsystemId = this.isFieldEmpty(formGroup, 'subsystemIdentifier');
    const zip = !(this.residenceAddress && this.residenceAddress.cap);
    const phoneNumber = !this.isPhoneValid(formGroup);
    return surname && birthName && key1 && key2 && key3 && coworker && clientNumber && fiscalCode && vatNumber && partyNumber
    && subsystemId && zip && phoneNumber ? {missingKey: true} : null;
  }

  onePrefixVisible() {
    return !this.twoPrefixVisible();
  }

  twoPrefixVisible() {
    return this.isFieldVisible(FIELD_ANAG_LOCAL_PREFIX) && this.isFieldVisible(FIELD_ANAG_INTERNATIONAL_PREFIX);
  }

  isPhoneValid(formGroup: AbstractControl) {
    const fieldsToCheck = [];
    if (this.isFieldVisible(FIELD_ANAG_INTERNATIONAL_PREFIX)) {
      fieldsToCheck.push('internationalPrefix');
    }
    if (this.isFieldVisible(FIELD_ANAG_LOCAL_PREFIX)) {
      fieldsToCheck.push('localPrefix');
    }
    if (this.isFieldVisible(FIELD_ANAG_PHONE)) {
      fieldsToCheck.push('phoneNumber');
    }
    if (fieldsToCheck.length && !this.isFieldVisible(FIELD_ANAG_PHONE)) {
      return false;
    }
    let existsValuedFields = fieldsToCheck.filter(e => !this.isFieldEmpty(formGroup, e)).length > 0;
    let existsNotValues = fieldsToCheck.filter(e => this.isFieldEmpty(formGroup, e)).length > 0;
    return !(existsValuedFields && existsNotValues);
  }

  searchPartyForm = new UntypedFormGroup({
    node: new UntypedFormControl('', Validators.required),
    surname: new UntypedFormControl(''),
    name: new UntypedFormControl({value: '', disabled: true}),
    coworker: new UntypedFormControl('', Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$')),
    fiscalCode: new UntypedFormControl(''),
    vatNumber: new UntypedFormControl(''),
    clientNumber: new UntypedFormControl(''),
    partyNumber: new UntypedFormControl(''),
    birthName: new UntypedFormControl(''),
    birthDate: new UntypedFormControl(''),
    partyType: new UntypedFormControl(''),
    subsystemIdentifier: new UntypedFormControl(''),
    subsystemCode: new UntypedFormControl(''),
    country: new UntypedFormControl(''),
    partyKey1: new UntypedFormControl(''),
    partyKey2: new UntypedFormControl(''),
    partyKey3: new UntypedFormControl(''),
    subjectSelected: new UntypedFormControl(''),
    company: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    internationalPrefix: new UntypedFormControl(''),
    localPrefix: new UntypedFormControl('', this.numberValidator),
    phoneNumber: new UntypedFormControl('', this.numberValidator)
  }, {validators: this.requiredFieldsValidatorFn});

  partyMergerForm = new UntypedFormGroup({
    criteria: new UntypedFormControl('', Validators.required),
    status: new UntypedFormControl('', Validators.required),
  });

  residenceAddress = new AnagApiAddress();
  reloadResidenceEvent = new Subject<AnagApiAddress>();

  public isFieldVisible(fieldToSearch: FIELD): boolean {
    let visible = true;
    if (this.anagHomeFieldConfigurationService.getFields(this.id)) {
      if (this.anagHomeFieldConfigurationService.getFields(this.id).find(field => field.name === fieldToSearch.name).visible === 0) {
        visible = false;
      }
    }
    return visible;
  }

  ngOnInit() {
    this.anagDropContainerSelectData = new AnagFlowData();
    this.anagDropContainerSelectData.idParentSession = this.id;
    this.isPartyGrouping = this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.merge);
    this.subscriptions.add(this.eventService.listen<UmaAddressUpdateEvent>(UMA_ADDRESS_UPDATE).subscribe(eventInstance => {
      if (eventInstance.event.isAddressValid) {
        this.residenceAddress = eventInstance.event.address;
      }
    }));

    this.anagStorage.getEnumtable$('it.rgi.dbobjects.EntConfcriteri').subscribe();
    this.anagStorage.getEnumtable$('it.rgi.dbobjects.EntSubsystems').subscribe();

    this.homeForm.addControl('searchPartyForm', this.searchPartyForm);
    this.homeForm.addControl('partyMergerForm', this.partyMergerForm);
    this.stateMgr.getState$()
      .pipe(
        tap(() => this.tickHostChange())
      ).subscribe((state) => {
        this.state = state;
        this.partyNotFound = state.partyNotFound;
        if (!this.searchPartyForm.get('node').value) {
          const userNode = this.stateMgr.getNodeCode();
          if (state.nodes.some(el => el.code === userNode)) {
            this.searchPartyForm.get('node').setValue(userNode);
          }
          if (!this.searchPartyForm.get('country').value && this.defaultPartyNation) {
            this.searchPartyForm.get('country').setValue(this.defaultPartyNation);
          }
        }
        if (this.state.isMultiCompagnia) {
          this.searchPartyForm.controls.company.addValidators(Validators.required);
        }
      }
    );
    this.anagStorage.getInternationalPrefixes().subscribe(prefixes => {
      this.internationalPrefixes = prefixes;
    });
    this.searchPartyForm.get('country').valueChanges.subscribe(value => {
      if (value) {
        this.stateMgr.getPartyKeysByCountry(value);
      }
    });

    this.searchPartyForm.valueChanges.subscribe(() => {
      this.partyNotFound = false;
    });

    this.searchPartyForm.controls.surname.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      const enableSurname = !!value;
      if (enableSurname) {
        this.searchPartyForm.controls.name.enable();
      } else {
        this.searchPartyForm.controls.name.reset();
        this.searchPartyForm.controls.name.disable();
      }

      ['partyKey1', 'partyKey2', 'partyKey3', 'fiscalCode', 'vatNumber'].forEach(controlName => {
        const control = this.searchPartyForm.controls[controlName];
        enableSurname ? control.disable() : control.enable();
      });
    });

    ['partyKey1', 'partyKey2', 'partyKey3', 'fiscalCode', 'vatNumber'].forEach(controlName => {
      this.searchPartyForm.controls[controlName].valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(value => {
        const disableSurname = !!value;
        this.searchPartyForm.controls.surname[disableSurname ? 'disable' : 'enable']();
      });
    });

    if (this.isDisabledPartyKeyNation()) {
      this.searchPartyForm.get('country').disable();
    }

  }

  emptyForm() {
    this.searchPartyForm.reset();
    this.partyMergerForm.reset();
    this.residenceAddress = new AnagApiAddress();
    this.reloadResidenceEvent.next(this.residenceAddress);
    this.stateMgr.clearState();
  }

  findParty() {
    if (this.tabs === ANAG_TABS.PARTY_MERGER) {
      this.pushMessageHandler.clearTag('error-tag');
      this.partyMergerForm.updateValueAndValidity();
      if (this.partyMergerForm.valid) {
        const criteria = this.partyMergerForm.get('criteria').value;
        const status = this.partyMergerForm.get('status').value;
        const parentSessionId = this.state.flowData && this.state.flowData.idParentSession ?
          this.state.flowData.idParentSession : this.activeRoute.id;
        this.anagIntegration.openPartyMerger(parentSessionId, criteria, status);
        this.eventService.emit<CloseAnagModalEvent>(new CloseAnagModalEventInstance());
      } else {
        this.notifyValidationMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_');
      }
    } else {
      this.pushMessageHandler.clearTag('error-tag');
      this.searchPartyForm.updateValueAndValidity();
      if (this.searchPartyForm.valid) {
        this.stateMgr.actionFindParties(this.searchPartyForm.getRawValue(), this.residenceAddress);
      } else {
        !this.searchPartyForm.get('node').value ? this.notifyValidationMsg('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_') :
          this.notifyValidationMsg('_ANAG_._MSG_._SEARCH_FORM_MANDATORY_FIELDS_ERROR_');
      }
    }
  }

  isFieldEmpty(formGroup: AbstractControl, key: string) {
    return !formGroup.get(key) || !formGroup.get(key).value || formGroup.get(key).value === '';
  }

  createNewParty() {
    this.stateMgr.actionCreateParty(this.stateMgrKeyData, this.searchPartyForm.getRawValue());
  }

  onSelectionChange($event: RgiRxTabSelectionChange) {
    this.tabs = $event.index;
    if (this.tabs === ANAG_TABS.PARTY_MERGER) {
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateResidenceSearch(address: AnagApiAddress) {
    this.residenceAddress = address;
    this.partyNotFound = false;
  }

  isBtnNewVisible() {
    return this.state.flowData?.partyRole ? this.partyNotFound && this.state.canCreateParty && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.processInsertion) : this.partyNotFound && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.insert) && this.state.canCreateParty;
  }

  removeSpecialCharacters(formControlName: string, event: any) {
    const key = event.key;
    const match = (/[a-zA-Z0-9]/i).test(key);
    if (!match) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  notifyValidationMsg(stringMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
      tag: 'error-tag',
      status: 'danger',
      options: {icon: 'rgi-ui-icon-alert'},
      dismissible: false
    }));
  }

  resetKeyFields() {
    this.resetSearchPartyFormFields();
    this.updateStateForResetFields();
  }

  resetSearchPartyFormFields() {
    this.searchPartyForm.patchValue({
      partyKey1: null,
      partyKey2: null,
      partyKey3: null
    });
    this.searchPartyForm.updateValueAndValidity();
  }

  updateStateForResetFields() {
    const newState = this.stateMgr.getCurrentState();
    this.state.partyKey.key1 = null;
    this.state.partyKey.key2 = null;
    this.state.partyKey.key3 = null;
    this.stateMgr.updateState$(of(newState));
  }

  isDisabledPartyKeyNation() {
    return this.anagExtensionsService.isDisabledPartyKeyNation();
  }

}
