import {Component, OnInit} from '@angular/core';
import {
  AnagStateManagerPartyEditor,
  AnagStatePartyEditor
} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {AnagStateManagerKeyData} from '../../anag-states/key-data/anag-state-manager-key-data';
import {ModalService, RgiRxDataTableDataSource, TableSchema} from '@rgi/rx/ui';
import {RgiRxEventService} from '@rgi/rx';
import {
  KeyDataModalComponent
} from '../../anag-components/party-create-key-data/key-data-modal/key-data-modal.component';
import {AnagDisplayLabel} from '../../anag-model/anag-domain/anag-display-label';
import {AnagUbicationComponent} from '../../anag-components/ubication-component/ubication.component';
import {
  KEY_DATA_UPDATE,
  KeyDataUpdateEvent,
  PartyCreatedEvent,
  PartyCreatedEventInstance
} from '../../anag-events/anag-event';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {distinctUntilChanged, map, mergeMap, take} from 'rxjs/operators';
import {AnagDialogComponent, ModalDialogData} from '../../anag-components/modal/anag-dialog/anag-dialog.component';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {combineLatest, of, Subscription} from 'rxjs';
import {AnagContactsComponent} from '../../anag-components/contacts/anag-contacts.component';
import {AnagDocumentComponent} from '../../anag-components/document/anag-document.component';
import {ANAG_SEX} from '../../anag-constants/enums-constants';
import {RgiRxUserAuthorizationService, RgiRxUserService} from '@rgi/rx/auth';
import {
  ADDRESS_STATE_MANDATORY,
  ANAG_FUNCTIONS,
  CORE_ADDRESS_FUNCTION
} from '../../anag-constants/anag-constants';
import {LinkPartyEvent} from '../../anag-components/party-link/party-link.component';
import {AnagApiAddress, AnagSubjectNode, CounterTerrorismStatus} from '../../anag-model/anag-domain/anag-api-party';
import {AnagConfigService} from '../../anag-resources/anag-config.service';
import {
  AnagDynamicObj,
  AnagRowDataDynamicObject,
  AnagRowDataDynamicObjectLim
} from '../../anag-model/anag-domain/anag-dynamic-obj';
import {
  PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA,
  PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA_LIM
} from '../../anag-constants/anag-party-dynamic-object-tableschema';
import {AnagDynamicObjectsComponent} from '../../anag-components/dynamic-objects/anag-dynamic-objects.component';
import {AnagDynamicObjectsService} from '../../services/anag-dynamic-objects.service';
import {DatePipe} from '@angular/common';
import { ActiveRoute, RoutableComponent } from '@rgi/rx/router';
import { AnagAddressData, AnagRouteData } from '../../anag-model/anag-domain/anag-route-data';
import {DetailPartyTerrorism} from '../../anag-model/anag-api/anag-api-terrorism-config';
import {
  CounterTerrorismComponent
} from '../../anag-components/counterTerrorism/counter-terrorism/counter-terrorism.component';
import {OperatorLight} from '../../services/external-service';
import {AnagAddressLayoutConfig} from '../../anag-model/anag-domain/anag-address-config';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagUmaAddressService} from "../../anag-components/uma-address/anag-uma-address.service";
import {AnagModalService} from '../../anag-modal/anag-modal-service';
import {AnagUtilsService} from "../../anag-resources/anag-utils.service";
import { AnagRatingComponent } from '../../anag-components/rating/anag-rating/anag-rating.component';
import {AnagDocument} from '../../anag-model/anag-domain/anag-document';
import {AnagPrivacyConsensus} from '../../anag-model/anag-domain/anag-privacy-config';
import {A} from '@angular/cdk/keycodes';
import { AnagPartyConfiguration } from '../../anag-model/anag-domain/anag-party-configuration';
import { AnagConfiguration, AnagFormFieldConfig } from '../../../public-api';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'rgi-anag-party-editor',
  templateUrl: './anag-party-editor.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagPartyEditorComponent extends RoutableComponent implements OnInit {

  subscriptions: Subscription;
  DATE_FORMAT: string;
  isLimitationManagementVisible: boolean;
  negativeResp: string;
  positiveResp: string;
  noResp: string;

  state: AnagStatePartyEditor;
  stateMgrProvider: any;
  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA: TableSchema = PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA;
  PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA_LIM: TableSchema = PARTY_DYNAMIC_OBJECT_TABLE_SCHEMA_LIM;
  detailPartyterrorism = new DetailPartyTerrorism();
  subjectUniqueKeyIsVisible: boolean;
  addressConfig: AnagAddressLayoutConfig = new AnagAddressLayoutConfig();
  birthAddressIsValid: boolean;
  residenceAddressIsValid: boolean;
  defaultPartyNation: string;
  isIstanceOfLimitedDynamicObj: boolean;
  offsetAlternativeRole = 0;
  documents: AnagDocument[];
  tablesDynamicObjectsData: Array<{
    noElementToShow: boolean,
    dynamicObj: AnagDynamicObj,
    dataSourceTable: RgiRxDataTableDataSource<AnagRowDataDynamicObject> | RgiRxDataTableDataSource<AnagRowDataDynamicObjectLim>
  }>;

  tablesDynamicObjectsDataLim: Array<{
    noElementToShow: boolean,
    dynamicObj: AnagDynamicObj,
    dataSourceTable: RgiRxDataTableDataSource<AnagRowDataDynamicObjectLim>
  }>;
  isVariationHistoryInquiryEnabled: boolean;

  constructor(
    public stateMgr: AnagStateManagerPartyEditor,
    public stateMgrKey: AnagStateManagerKeyData,
    public modalService: ModalService,
    public anagModalService: AnagModalService,
    public eventService: RgiRxEventService,
    public statelessService: AnagStatelessService,
    public translateService: RgiRxTranslationService,
    public userAuthorizationService: RgiRxUserAuthorizationService,
    public configService: AnagConfigService,
    public dynamicObjectService: AnagDynamicObjectsService,
    public datePipe: DatePipe,
    protected userService: RgiRxUserService,
    public anagStorage: AnagStorageService,
    public addressService: AnagUmaAddressService,
    protected anagUtilsService: AnagUtilsService,
    protected activeRoute: ActiveRoute

  ) {
    super();
    this.stateMgrProvider = {
      provide: AnagStateManagerPartyEditor,
      useValue: this.stateMgr
    };
    this.DATE_FORMAT = this.statelessService.getLocaleDateFormat();
    this.defaultPartyNation = this.userService.getUser<OperatorLight>().salePoint ?
      this.userService.getUser<OperatorLight>().salePoint.context : null;
  }

  async ngOnInit() {
    this.isVariationHistoryInquiryEnabled = this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.variationHistoryInquiry);
    this.anagStorage.getEnumtable$('it.rgi.dbobjects.EntRatingtype').subscribe();
    this.subscriptions = new Subscription();
    this.stateMgr.getState$().subscribe((state) => {
      this.state = state;
      this.stateMgr.setTitle();
      if (this.state.party.dynamicObjects && this.state.party.dynamicObjects.length) {
        this.isIstanceOfLimitedDynamicObj = this.getVisibilityByName("CF", this.state.party.dynamicObjects[0].dynamicAttributes) !== '1';
          this.tablesDynamicObjectsData = this.state.party.dynamicObjects.map(obj => {
            return {
              noElementToShow: obj.dynamicAttributes[0].values.length === 0,
              dynamicObj: obj,
              dataSourceTable: new RgiRxDataTableDataSource(this.dynamicObjectService.getDynamicObjectTableData(obj))
            };
          });
      }
      else {
        this.tablesDynamicObjectsData = [];
      }
      if (this.state.party) {
        this.initDetailPartyTerrorismInfo();
      }
      if (!this.state.citizenshipPermitTypes) {
        this.stateMgr.getCitizenshipPermitTypes(this.userService.getUser<OperatorLight>().salePoint.context);
      }
      this.manageSubscriptions();
      this.subjectUniqueKeyIsVisible = !(this.state.party.countryOfBirth && this.state.party.personType ?
        this.state.party.countryOfBirth.codice === 'CH' && this.state.party.personType.codice === '2' : false);
      let addressTypeForConfig = (this.state.flowData && this.state.flowData.isQuote && (this.offsetAlternativeRole > 0)) ? CORE_ADDRESS_FUNCTION.partyBirthAddress + 'Quote' + this.getPartyRole(): CORE_ADDRESS_FUNCTION.partyBirthAddress;
      if (this.state.party.personType && this.state.party.personType.codice === '1') {
        this.anagStorage.getAddressConfig(addressTypeForConfig, this.state.party.birthAddress &&
        this.state.party.birthAddress.countryCode ? this.state.party.birthAddress.countryCode :
          this.defaultPartyNation).pipe(take(1)).subscribe(addressConfig => {
          if (addressConfig) {
            this.birthAddressIsValid = this.addressIsMandatory(addressConfig, this.state.party.birthAddress);
          }
        });
      } else {
        this.birthAddressIsValid = true;
      }
      addressTypeForConfig = (this.state.flowData && this.state.flowData.isQuote && (this.offsetAlternativeRole > 0)) ? CORE_ADDRESS_FUNCTION.partySubjectResidence + 'Quote' + this.getPartyRole() : CORE_ADDRESS_FUNCTION.partySubjectResidence;
      addressTypeForConfig = (this.state.flowData && this.state.flowData.partyRole==='888') ? CORE_ADDRESS_FUNCTION.partySubjectResidence + 'Claim' : addressTypeForConfig;
      this.anagStorage.getAddressConfig(addressTypeForConfig, this.state.party.residence &&
        this.state.party.residence.countryCode ? this.state.party.residence.countryCode :
        this.defaultPartyNation).subscribe(addressConfig => {
        if (addressConfig) {
          this.residenceAddressIsValid  = this.addressIsMandatory(addressConfig, this.state.party.residence);
        }
      });
      if (this.state.party.partyKey && this.state.party.partyKey.length > 0) {
        this.anagStorage.getDrivingLicenseType(this.state.party.partyKey[0].country).subscribe(drivingLicenseList => {
          if (drivingLicenseList && drivingLicenseList.length > 0) {
            this.state.drivingLecenseList = drivingLicenseList;
          }
        });
      }
      this.subscriptions.add(this.stateMgr.getState$().pipe(map(state => state.party.dateOfBirth), distinctUntilChanged()).subscribe(dateOfBirth => {
        if(dateOfBirth && this.state.party?.documents && this.state.party.documents.length > 0 ) {
          this.state.party.documents.forEach( doc => {
            if(doc.drivingLicense && doc.drivingLicense.length > 0 ) {
              const filter = this.anagUtilsService.createCheckDrivingLicenseFilter(dateOfBirth, doc.expirationDate, doc.drivingLicense);
              this.anagStorage.checkDrivingLicense(filter).subscribe(resp => {
                state.isValidDrivingLecense = resp?.outcomeCode !== '0';
              });
            }
          })
        }
        }
      ));


    });

    this.offsetAlternativeRole = await this.addressService.offsetAlternativeRole();
    this.setTranslation();
    this.isLimitationManagementVisible = this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.limitationManagement);
  }


  manageSubscriptions() {

  }

  private getPartyRole(): string {
    return this.state.flowData && this.state.flowData.partyRole ? this.state.flowData.partyRole : '';
  }


  setTranslation() {
    this.translateService.translate('_ANAG_._LABEL_._NO_').subscribe(resp => this.negativeResp = resp).unsubscribe();
    this.translateService.translate('_ANAG_._LABEL_._YES_').subscribe(next => this.positiveResp = next).unsubscribe();
    this.translateService.translate('_ANAG_._LABEL_._DO_NOT_REPLY_').subscribe(next => this.noResp = next).unsubscribe();
  }

  initDetailPartyTerrorismInfo() {
    this.detailPartyterrorism.counterTerrorismDate = this.state.party.counterTerrorismDate ?
      this.datePipe.transform(this.state.party.counterTerrorismDate, 'dd/MM/yyyy') : null;
    this.detailPartyterrorism.counterTerrorismStatus = this.state.party.counterTerrorismStatus ?
      this.state.party.counterTerrorismStatus : new CounterTerrorismStatus();
  }

  getVisibilityByName(attributeName, dynamicAttributes) {
    let visibilityValue = null;
    for (let i = 0; i < dynamicAttributes.length; i++) {
      if (dynamicAttributes[i].codeDynAttribute === attributeName && dynamicAttributes[i].visibilityValues && dynamicAttributes[i].visibilityValues.codice) {
        visibilityValue = dynamicAttributes[i].visibilityValues.codice ? dynamicAttributes[i].visibilityValues.codice : null;
      }
    }
    return visibilityValue;
  }

  openModalTerrorism() {
    const idDataModal = this.modalService.openComponent(
      CounterTerrorismComponent,
      this.statelessService.deepClone(this.state.party),
      [this.stateMgrProvider]
    );
    idDataModal.modal.onClose.subscribe((onCloseData: CounterTerrorismStatus) => {
      if (onCloseData) {
        this.detailPartyterrorism.counterTerrorismStatus = onCloseData;
        this.stateMgr.updateTerrorismInfo(onCloseData);
      }
    });
  }
  getStyleTerrorism() {
    if (this.detailPartyterrorism.counterTerrorismStatus && this.detailPartyterrorism.counterTerrorismStatus.codice) {
      if (this.detailPartyterrorism.counterTerrorismStatus.codice === '1' ||
        this.detailPartyterrorism.counterTerrorismStatus.codice === '0') {
        return { color: 'black' };
      } else {
        return { color: 'red' };
      }
    } else {
      return { color: 'black' };
    }
  }

  getInfoExternals() {
    const external = this.state.party;
    if (external.subsysReferences) {
      return external.subsysReferences;
    }
  }




  get residenceOrOffice(): string {
    return this.stateMgr.isPartyPhysical ? '_ANAG_._LABEL_._RESIDENCE_' : '_ANAG_._LABEL_._REGISTERED_OFFICE_';
  }


  getPhysicalPartyType() {
    const party = this.state.party;
    if (party && party.professionDetail) {
      return party.professionDetail.descrizione;
    }
    return null;
  }

  editIdentificationData() {
    const idDataModal = this.anagModalService.openComponent(
      'IdentificationDataComponent',
      this.statelessService.deepClone(this.state.party),
      [this.stateMgrProvider]
    );
    idDataModal.modal.enableClickBackground = false;
  }

  editKeyData() {
    const keyDataModal = this.modalService.openComponent(KeyDataModalComponent, this.statelessService.deepClone(this.state.party), [
      {
        provide: AnagStateManagerKeyData,
        useValue: this.stateMgrKey
      }
    ]);
    keyDataModal.modal.enableClickBackground = false;
    keyDataModal.modal.onClose.asObservable().pipe(take(1));
    this.eventService.listen<KeyDataUpdateEvent>(KEY_DATA_UPDATE).pipe(take(1)).subscribe(eventInstance => {
      this.stateMgr.updateParty(eventInstance.event.party);
      this.stateMgr.updateConfiguration(eventInstance.event.configuration);
    });
  }


  isEditResidenceEnable(config): boolean {
    const find = config.configurations.find(paConf => paConf.label === 'PasoggettoIdresidence');
    if(find && config.partyConfig['PasoggettoIdresidence']) {
      const contactsForm = new UntypedFormGroup({});
      const value = new AnagFormFieldConfig('PasoggettoIdresidence', contactsForm, []);
      const field = config.partyConfig[value.partyConfProperty][value.configFieldName];
      return !!field && !!field.configuration && field.configuration.codice === '5';
    }
    return true;
  }

  editUbication() {
    const ubicationData = new AnagAddressData();
    ubicationData.address = this.state.party.residence;
    ubicationData.modalLabel = this.residenceOrOffice;
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.partySubjectResidence;
    ubicationData.isQuote = this.activeRoute.getRouteData<AnagRouteData>()?.flowData?.isQuote;
    ubicationData.partyRole = this.activeRoute.getRouteData<AnagRouteData>()?.flowData?.partyRole;
    ubicationData.disabledMainAddress = this.isEditResidenceEnable(this.state.configuration);
    const ubicationModal = this.anagModalService.openComponent('AnagUbicationComponent',ubicationData );
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(addr => {
      if (addr) {
        this.stateMgr.updateResidence(addr);
      }
    });
  }

  editPrivacy() {
    const privacyModal = this.anagModalService.openComponent(
      'AnagPrivacyComponent',
      this.statelessService.deepClone(this.state.party),
      [this.stateMgrProvider]
    );
    privacyModal.modal.enableClickBackground = false;
  }

  editContacts() {
   const contactsModal = this.anagModalService.openComponent(
        'AnagContactsComponent', this.statelessService.deepClone(this.state.party), [this.stateMgrProvider]
   );
    contactsModal.modal.enableClickBackground = false;
  }


  editDocument(document: AnagDocument, index : number ) {
    const documentModal = this.modalService.openComponent(AnagDocumentComponent, this.statelessService.deepClone(document), [this.stateMgrProvider]
    );
    documentModal.modal.enableClickBackground = false;
    documentModal.modal.onClose.subscribe(newDocument => {
      if(newDocument){
        this.stateMgr.updateDocument(newDocument.document, newDocument.drivingLicenseList, newDocument.isValidDrivingLicense,index);
      }
    } )
  }

  addDocument() {
    const documentModal = this.modalService.openComponent(AnagDocumentComponent,null,
      [
        {
          provide: AnagStateManagerPartyEditor,
          useValue: this.stateMgr
        }
    ]);
    documentModal.modal.enableClickBackground = false;
    documentModal.modal.onClose.subscribe(newDocument => {
      if(newDocument){
        this.stateMgr.addDocument(newDocument.document, newDocument.drivingLicenseList, newDocument.isValidDrivingLicense);
      }
    } )
  }




  deleteDocument(document: AnagDocument, index: number) {
    this.stateMgr.deleteDocument(document,index);
  }

  editRating() {
    const ratingModal = this.modalService.openComponent(
      AnagRatingComponent, this.statelessService.deepClone(this.state), [this.stateMgrProvider]
    );
    ratingModal.modal.enableClickBackground = false;
  }

  updateLinks($event: LinkPartyEvent) {
    this.stateMgr.updateLinks($event);
  }

  get identificationDataLabels(): Array<AnagDisplayLabel> {
    const idDatas = [];
    const party = this.state.party;
    if (this.stateMgr.isPartyPhysical) {
      // if (party.language) {
      //   idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._LANGUAGE_', party.language.descrizione, 'idDatalanguage'));
      // }
      if (party.salutation) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._HONOR_TITLE_', party.salutation.descrizione, 'idDatasalutation'));
      }
      if (party.birthName) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._BIRTH_SURNAME_', party.birthName, 'idbirthName'));
      }
      if (party.sex) {
        this.translateService.translate(ANAG_SEX.find(g => g.codice === party.sex.codice).descrizione).subscribe(sexLabel => {
          idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._SEX_', sexLabel, 'idDataSex'));
        }).unsubscribe();
      }
      if (party.genderIdentity) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._GENDER_IDENTITY_', party.genderIdentity.descrizione, 'idDataGenderIdentity'));
      }
      if (party.dateOfBirth) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._BIRTH_DATE_',
          this.datePipe.transform(party.dateOfBirth, this.DATE_FORMAT), 'idDataBirthDate'));
      }
      if (party.cityOfBirth) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._CITY_OF_BIRTH_', party.cityOfBirth, 'idDataBirthCity'));
      }
      if (party.citizenship) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._CITIZENSHIP_', party.citizenship.codice, 'idDataCitizenship'));
      }
      if (party.citizenshipPermit && this.state.citizenshipPermitTypes) {
        const cityPermit = this.state.citizenshipPermitTypes.find(permCity => permCity.code === party.citizenshipPermit);
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._CITIZENSHIP_PERMIT_', cityPermit.description, 'citizenshipPermit'));

      }
      if (party.citizenshipExpire) {
        // tslint:disable-next-line:max-line-length
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._CITIZENSHIP_EXPIRE_', this.datePipe.transform(party.citizenshipExpire, this.DATE_FORMAT), 'citizenshipExpire'));
      }
      if (party.sonsNumber && party.sonsNumber > 0) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._SONS_', party.sonsNumber, 'sonsNumber'));
      }
      if (party.politicallyExp) {
        this.translateService.translate('_ANAG_._LABEL_._YES_').subscribe(value => {
          idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._POTICALLY_EXP', value, 'idDataPoliticalyExp'));
        }).unsubscribe();
      }
      if (party.maritalStatus) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._CIVIL_STATE_', party.maritalStatus.descrizione, 'idDataMaritalStatus'));
      }
    } else {
      // if (party.language) {
      //   idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._LANGUAGE_', party.language.descrizione, 'idDatalanguage'));
      // }
      if (party.corporateForm) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._CORPORATE_FORM_', party.corporateForm.descrizione, 'idDataCorporateForm'));
      }
      if (party.dateOfBirth) {
        // tslint:disable-next-line:max-line-length
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._OPEN_DATE_', this.datePipe.transform(party.dateOfBirth, this.DATE_FORMAT), 'idDataOpenDate'));
      }
      if (party.regCountry) {
        idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._REG_COUNTRY_', party.regCountry, 'idDataRegCountry'));
      }
    }
    if (party.codAteco) {
      idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._AE_', party.codAteco.description, 'idDataAtecoCode'));
    }
    if (party.sottogruppoAE) {
      idDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._SAE_', party.sottogruppoAE.description, 'idDataSAE'));
    }
    return idDatas;
  }

  get keyDataLabels(): Array<AnagDisplayLabel> {
    const keyDatas: Array<AnagDisplayLabel> = [];
    const party = this.state.party;
    keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._MANAGEMENT_NODE_',
      party.node ? party.node.description : '', 'keyDataNode'));

    if (party.partyKey && party.partyKey[0]) {
      const countries = this.stateMgr.countriesList;
      const partyKeyCountry = party.partyKey[0].country;
      const country = countries.find(c => c.abbreviation === partyKeyCountry);
      keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._NATION_',
        country.description, 'keyDataCountry'));
    } else {
      keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._NATION_',
        '', 'keyDataCountry'));
    }
    keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._PERSON_TYPE_',
      party.personType ? party.personType.descrizione : '', 'keyDataPersonType'));
    keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._PARTY_TYPE_',
      party.subjectType ? party.subjectType.descrizione : '', 'keyDataSubjectType'));
    if (this.stateMgr.isPartyPhysical) {
      keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._PROFESSION_',
        party.professionType ? party.professionType.descrizione : '', 'keyDataProfession'));
      keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._PROFESSION_DETAIL_',
        party.professionDetail ? party.professionDetail.descrizione : '', 'keyDataProfessionDetail'));
    } else {
      keyDatas.push(new AnagDisplayLabel('_ANAG_._LABEL_._CORPORATE_FORM_',
        party.corporateForm ? party.corporateForm.descrizione : '', 'keyDataCorporateForm'));
    }
    return keyDatas;
  }


  get residenceLabels(): Array<AnagDisplayLabel> {
    const labels: Array<AnagDisplayLabel> = [];
    const residence = this.state.party.residence;
    if (residence && !this.isResidenceEmpty) {
      if (residence.formatAddress) {
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._ADDRESS_', residence.formatAddress, 'residenceLabel'));
      } else {
        const adminLevel3 = residence.adminLevel3 ? residence.adminLevel3 : '';
        const adminLevel1 =  residence.adminLevel1 ? residence.adminLevel1 : '';
        let adminLevel2 = '';
        if (adminLevel3 && residence.adminLevel2) {
          adminLevel2 = residence.adminLevel2Short ? `(${residence.adminLevel2Short}) ` : `(${residence.adminLevel2 || ''}) `;
        } else {
          adminLevel2 = residence.adminLevel2 || '';
        }
        const renderedAddress = this.getRenderedAddress(residence) + adminLevel3 + ' ' + adminLevel1  + ' ' + (adminLevel2 ?? '') + ' - '
          + residence.countryCode;
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._ADDRESS_', renderedAddress ? renderedAddress : '', 'renderedAddress'));
      }
    }
    return labels;
  }

  getRenderedAddress(residence: AnagApiAddress): string {
    const toponym = residence.toponym ? residence.toponym.descrizione + ' ' : '';
    const placeAddress = residence.placeAddress ? residence.placeAddress + ', '  : '';
    const num = residence.number ? residence.number + ' ' : '';
    const cap = residence.cap ? ' - ' + ' ' + residence.cap + ' ' : '';
    const rendAddress = toponym + placeAddress + num + cap ;

    return rendAddress;
  }

  get privacyDate() {
    return this.state.party.privacyConsensusDate ? this.datePipe.transform(this.state.party.privacyConsensusDate, this.DATE_FORMAT) : '';
  }

  get privacyLabels(): Array<AnagDisplayLabel[]> {
    const privacyLabels: Array<AnagDisplayLabel> = [];
    const privacy = this.state.party.privacyConsensus;
    if (privacy && privacy.length > 0) {
      privacy.forEach(p => {
        privacyLabels.push(new AnagDisplayLabel(p.privacy.description,
          p.privacyConsensusValue ? p.privacyConsensusValue.descrizione : '-', `privacy-${p.privacy.code}`));
        this.translateService.translate('_ANAG_._LABEL_._PRIVACY_CONSENSUS_DATE_').subscribe(title => {
          // tslint:disable-next-line:max-line-length
          privacyLabels.push(new AnagDisplayLabel(title, p.privacyConsentDate ? this.datePipe.transform(p.privacyConsentDate, this.DATE_FORMAT) : '-', `data-${p.privacy.code}`));
        }).unsubscribe();
        this.translateService.translate('_ANAG_._LABEL_._ORIGIN_').subscribe(title => {
          // tslint:disable-next-line:max-line-length
          privacyLabels.push(new AnagDisplayLabel(title, p.privacyConsentOriginDescription ? p.privacyConsentOriginDescription : '-', `origin-${p.privacy.code}`));
        }).unsubscribe();
        this.translateService.translate('_ANAG_._LABEL_._CHANGED_ON_').subscribe(title => {
          // tslint:disable-next-line:max-line-length
          const formattedPrivacyConsentDateUpd = this.formatPrivacyConsentDateUpd(p.privacyConsentDateUpd);
          privacyLabels.push(new AnagDisplayLabel(title, p.privacyConsentDateUpd ? formattedPrivacyConsentDateUpd : '-', `privacyConsentDateUpd-${p.privacy.code}`));
        }).unsubscribe();

      });
    } else {
      this.state.privacyQuestions.forEach(p => {
        privacyLabels.push(new AnagDisplayLabel(p.descrizione, '-', ''));
        this.translateService.translate('_ANAG_._LABEL_._PRIVACY_CONSENSUS_DATE_').subscribe(title => {
          privacyLabels.push(new AnagDisplayLabel(title, '-', ''));
        }).unsubscribe();
        this.translateService.translate('_ANAG_._LABEL_._ORIGIN_').subscribe(title => {
          privacyLabels.push(new AnagDisplayLabel(title, '-', ''));
        }).unsubscribe();

        this.translateService.translate('_ANAG_._LABEL_._CHANGED_ON_').subscribe(title => {
          privacyLabels.push(new AnagDisplayLabel(title, '-', ''));
        }).unsubscribe();
      });
    }
    return this.chunkArray(privacyLabels, 4);
  }

   formatPrivacyConsentDateUpd(privacyConsentDateUpd) {
    const currentPrivacyConsentDateUpd = new Date(privacyConsentDateUpd ? privacyConsentDateUpd : null);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return currentPrivacyConsentDateUpd.toLocaleString('it-IT', options);
  }

  get contactsLabels(): Array<AnagDisplayLabel> {
    const labels: Array<AnagDisplayLabel> = [];
    const party = this.state.party;
    if (party) {
      const mainContact = party.mainContact ? party.mainContact.descrizione : '-';
      labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._MAIN_CONTACT_', mainContact ? mainContact : '-', 'mainContact'));
      labels.push(new AnagDisplayLabel(
        '_ANAG_._LABEL_._LANDLINE_NO_', this.getParsedPhoneNumber('landlineNumber'), 'landlineNumber')
      );
      labels.push(new AnagDisplayLabel(
        '_ANAG_._LABEL_._FAX_NO_', this.getParsedPhoneNumber('phoneFax'), 'faxNumber')
      );
      labels.push(new AnagDisplayLabel(
        '_ANAG_._LABEL_._MOBILE_NO_', this.getParsedPhoneNumber('mobile'), 'mobileNumber')
      );
      labels.push(new AnagDisplayLabel(
        '_ANAG_._LABEL_._MOBILE_NO_2_', this.getParsedPhoneNumber('mobile2'), 'mobileNumber2')
      );
      const email = party.emails && party.emails[0] ? party.emails[0] : '-';
      labels.push(new AnagDisplayLabel(
        '_ANAG_._LABEL_._EMAIL_', email, 'email')
      );
      if (party.emails && party.emails[1]) {
        const email2 = party.emails && party.emails[1] ? party.emails[1] : '-';
        labels.push(new AnagDisplayLabel(
          '_ANAG_._LABEL_._EMAIL2_', email2, 'email2')
        );
      }
      if (party.emails && party.emails[2]) {
        const email3 = party.emails && party.emails[2] ? party.emails[2] : '-';
        labels.push(new AnagDisplayLabel(
          '_ANAG_._LABEL_._EMAIL3_', email3, 'email3')
        );
      }
    }
    return labels;
  }

   documentLabels(doc: any): Array<AnagDisplayLabel> {
    const labels: Array<AnagDisplayLabel> = [];
    if (doc) {
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._DOCUMENT_TYPE_', doc.documentType?.descrizione ?? '', 'rgi-anag-document-type'));
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._DOCUMENT_NO_', doc.documentNumber ?? '', 'rgi-anag-document-number'));
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._RELEASE_DATE_', this.datePipe.transform(doc.releaseDate, this.DATE_FORMAT) ?? '', 'rgi-anag-release-date'));
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._EXPIRATION_DATE_', this.datePipe.transform(doc.expirationDate, this.DATE_FORMAT) ?? '', 'rgi-anag-expiration-date'));
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._RELEASE_LOCATION_', doc.locationsRelease ?? '', 'rgi-anag-release-location'));
        labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._RELEASE_AUTHORITY_', doc.authoritiesRelease?.descrizione ?? '', 'rgi-anag-release-auth'));

    }

    return labels;
  }

  get nodesLabels(): Array<AnagSubjectNode> {
    return this.state.party.subjectNodes;
  }

  getParsedPhoneNumber(phoneType: string): string {
    const party = this.state.party;
    let valueString = '';
    const partyNumber = phoneType === 'mobile' && party.mobilePhone ? party.mobilePhone[0] :  party[phoneType];
    const partyNumber2 = phoneType === 'mobile2' && party.mobilePhone && party.mobilePhone[1] ? party.mobilePhone[1] : null;
    if (partyNumber) {
      const values = [];
      if (partyNumber.internationalPrefix) {
        values.push(partyNumber.internationalPrefix);
      }
      if (partyNumber.localPrefix) {
        values.push(partyNumber.localPrefix);
      }
      if (partyNumber.number) {
        values.push(partyNumber.number);
      }
      values.forEach((value, index) => {
        valueString += value;
        if (index + 1 !== values.length) {
          valueString += ' - ';
        }
      });
    }
    if (partyNumber2) {
      const values = [];
      if (partyNumber2.internationalPrefix) {
        values.push(partyNumber2.internationalPrefix);
      }
      if (partyNumber2.localPrefix) {
        values.push(partyNumber2.localPrefix);
      }
      if (partyNumber2.number) {
        values.push(partyNumber2.number);
      }
      values.forEach((value, index) => {
        valueString += value;
        if (index + 1 !== values.length) {
          valueString += ' - ';
        }
      });
    }
    if (!valueString) {
      valueString = '-';
    }
    return valueString;
  }

  actionSaveParty() {
    this.stateMgr.saveParty().pipe(
      mergeMap((st: AnagStatePartyEditor) => {
        return combineLatest([
          of(st), this.translateService.translate('_ANAG_._LABEL_._SUBJNO_'),
          this.translateService.translate('_ANAG_._LABEL_._SAVED_OK_'),
          this.translateService.translate('_ANAG_._LABEL_._SUBJECT_')
        ]);
      }),
      map(([st, tr1, tr2, tr3]: [AnagStatePartyEditor, string, string, string]) => {
        return st.party.partyNumber ? `${tr1} ${st.party.partyNumber} ${tr2}` : `${tr3} ${tr2}`;
      })
    ).subscribe(msg => {
      const savedModal = this.modalService.openComponent(AnagDialogComponent, new ModalDialogData(msg));
      savedModal.modal.enableClickBackground = false;
      savedModal.modal.onClose.subscribe(onCloseData => {
        if (this.stateMgr.getCurrentState().redirectToMainFlow) {
          this.eventService.emit<PartyCreatedEvent>(new PartyCreatedEventInstance(this.stateMgr.getCurrentState().party, this.id));
        }
      });
    });
  }

  actionBack() {
    this.stateMgr.actionGoToDetailPage();
  }

  actionEdit() {
    this.stateMgr.actionEditParty(this.stateMgrKey, this.state.party);
  }

  actionDelete() {
    this.stateMgr.actionDelete(false);
  }

  actionDeleteDefinitively() {
    this.stateMgr.actionDelete(true);
  }

  actionHistory() {
    this.stateMgr.actionHistory();
  }

  actionLimitationManagement() {
    this.stateMgr.actionLimitationManagement();
  }

  isEditButtonVisible() {
    return this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.edit) && !this.state.lastIdFromDamage;
  }

  isDeleteButtonVisible() {
    return this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.delete) && !this.state.lastIdFromDamage;
  }

  isDeleteDefinitivelyButtonVisible() {
    return this.userAuthorizationService.isAuthorizedFor(ANAG_FUNCTIONS.physicalDelete) && !this.state.lastIdFromDamage;
  }

  getSectionIconClass(section: string) {
    return this.configService.getIconBadgeCssClass(section, this.state.configuration, this.state.party);
  }

  editDynamicObject(dynamicObject: AnagDynamicObj) {
    const dynamicObjectModal = this.modalService.openComponent(
      AnagDynamicObjectsComponent, { party: this.statelessService.deepClone(this.state.party), dynamicObject }, [this.stateMgrProvider]
    );
    dynamicObjectModal.modal.enableClickBackground = false;
  }

  getIdentificationDataBadge(): string {
    let css = 'rgi-anag-hm-m ';
    if (this.stateMgr.isIdentificationDataValid() && this.birthAddressIsValid) {
      css += 'rgi-ui-icon-check rgi-anag-ok-badge';
    } else {
      css += 'rgi-ui-icon-warning rgi-anag-ko-badge';
    }
    return css;
  }

  get showDocumentBadge(): boolean {
    return this.stateMgr.isDocumentMandatory();
  }

  get showContactsBadge(): boolean {
    return this.stateMgr.isContactsMandatory();
  }
  get showTaxClassifications(): boolean {
    return this.stateMgr.isTaxClassificationsEnabled();
  }

  get showRatings(): boolean {
    return this.stateMgr.isRatingsEnabled();
  }

  get showAntiTerrorism(): boolean {
    return this.stateMgr.isAntiTerrorismEnabled();
  }

  get showPrivacyBadge(): boolean {
    return this.stateMgr.isPrivacyMandatory();
  }

  get isResidenceEmpty(): boolean {
    const resid = this.state.party.residence;
    return !resid || (!resid.adminLevel1 && !resid.adminLevel2 && !resid.adminLevel3 && !resid.postalAddress && !resid.country &&
      !resid.placeAddress && !resid.toponym && !resid.number);
  }

  chunkArray(array: any[], chunkSize: number): Array<AnagDisplayLabel[]> {
    const chunkedArray: any[][] = [];
    let index = 0;

    while (index < array.length) {
      chunkedArray.push(array.slice(index, index + chunkSize));
      index += chunkSize;
    }

    return chunkedArray;
  }

  addressIsMandatory(addressConfig: AnagAddressLayoutConfig, address: any) {
    const keysConfig = Object.keys(addressConfig);
    let valid = true;
    let i = 0;
    while (valid && i < keysConfig.length) {
      const key = keysConfig[i];
      if (this.isFieldMandatory(key, addressConfig)) {
        if (address) {
          const keyMappings = { address: 'placeAddress', zip: 'cap', cco: 'at', houseNumber: 'number' };
          const mappedKey = keyMappings[key] || key;
          const value = address[mappedKey];
          if (!value) {
            valid = false;
          }
        } else {
          valid = false;
        }
      }
      i++;
    }
    return valid;
  }
  isFieldMandatory(field: string, addressConfig): boolean {
    if (addressConfig && addressConfig[field]) {
      return addressConfig[field].state === ADDRESS_STATE_MANDATORY;
    }
    return false;
  }

  getDynamicAttributeByName() {
    return null;
  }
}


