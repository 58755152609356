/**
 * GEL REST API
 * Rest API's for Workflow services
 *
 * OpenAPI spec version: 0.0.1
 * Contact: info@rgigroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CommonMessage { 
    code?: string;
    typeMessage?: CommonMessage.TypeMessageEnum;
    message: string;
}
export namespace CommonMessage {
    export type TypeMessageEnum = 'Info' | 'Warning' | 'Error';
    export const TypeMessageEnum = {
        Info: 'Info' as TypeMessageEnum,
        Warning: 'Warning' as TypeMessageEnum,
        Error: 'Error' as TypeMessageEnum
    };
}