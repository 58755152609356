/**
 * GEL REST API
 * Rest API's for Workflow services
 *
 * OpenAPI spec version: 0.0.1
 * Contact: info@rgigroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { CommonMessages } from "../../../common/model/models";
import { PartyNiceActimizeCheck } from "./partyNiceActimizeCheck";


export interface PartyNiceActimizeCheckResponse { 
    content: PartyNiceActimizeCheck;
    messages?: CommonMessages;
}