import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { RoutingService } from '@rgi/rx/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SurveyCommonOperations, SurveyEditState, SurveyEditStateOperations } from '@rgi/ng-passpropro-survey';
import { AnswerFlatI, AnswerType, QuestionFlatI } from '@rgi/ng-passpro';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';

@Injectable()
export class SurveyEditStateLifeOperations<S extends SurveyEditState> extends SurveyEditStateOperations<SurveyEditState> {


    constructor(
        protected commOps: SurveyCommonOperations,
        protected datePipe: DatePipe,
        protected routingService: RoutingService,
        protected translationWrapper: TranslationWrapperService
    ) {
      super(commOps, datePipe, routingService);
    }


    override beforeUpdate$(st: S): Observable<SurveyEditState> {
        return super.beforeUpdate$(st).pipe(
          switchMap((beforeState) => {
            return this.mapQuestionsTranslated(beforeState);
          })
        );
    }


    override getSaveSurvey$(st$: Observable<S>): Observable<SurveyEditState> {
        return super.getSaveSurvey$(st$).pipe(
          switchMap(st => {
            return this.mapQuestionsTranslated(st);
          })
        )
    }

    override getMakeNewVersion$(st$: Observable<S>): Observable<SurveyEditState> {
      return super.getMakeNewVersion$(st$).pipe(
        switchMap((beforeState) => {
          return this.mapQuestionsTranslated(beforeState);
        })
      )
    }

    protected getInitUpdate$(st$: Observable<S>): Observable<SurveyEditState> {
      return super.getInitUpdate$(st$).pipe(
        switchMap((beforeState) => {
          return this.mapQuestionsTranslated(beforeState);
        })
      )
    }



  protected mapQuestionsTranslated(beforeState: SurveyEditState) {
    if (beforeState?.surveyVersion?.survey?.questionnaire.name) {
      beforeState.surveyVersion.survey.questionnaire.name = this.translationWrapper.getImmediate(beforeState.surveyVersion.survey.questionnaire.name);
    }
    if (beforeState?.surveyVersion?.lastVersion?.questionnaire) {
      beforeState.surveyVersion.lastVersion.questionnaire.description = this.translationWrapper.getImmediate(beforeState.surveyVersion.lastVersion.questionnaire.description);
    }
    beforeState?.surveyVersion?.lastVersion?.questionnaire?.questions?.map(quest => {
      this.mappingTerms(quest);
      quest.description = this.translationWrapper.getImmediate(quest.description);
      quest.text = this.translationWrapper.getImmediate(quest.text);
      quest.section.name = this.translationWrapper.getImmediate(quest.section.name);
      this.iterOnAnswers(quest);
      return quest;
    });
    return of(beforeState);
  }

  protected iterOnAnswers(quest: QuestionFlatI) {
    quest.answers = quest.answers.map(answer => {
      this.translateAnswerValues(answer);
      return answer;
    });
  }

  protected translateAnswerValues(answer: AnswerFlatI) {
    answer.values = answer.values.map(value => {
      value.description = this.translationWrapper.getImmediate(value.description);
      return value;
    });
  }

  private mappingTerms(quest: QuestionFlatI) {
    quest.conditions = quest.conditions.map(condition => {
      condition.terms?.map(term => {
        if (term.type === AnswerType.STRING) {
          term.value = this.translationWrapper.getImmediate(term.value);
          return term;
        }
      });
      return condition;
    });
  }
}
