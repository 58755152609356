// ################# Subject to your consent... #################

export const SubjectToYourConsent_Key = `Subject to your consent, we process your data and the data of the insured persons strictly confidentially and in full compliance with the Swiss Data Protection Act - in accordance with your consent and always in strict confidence. The processing of your personal data forms the basis for us to be able to prepare insurance products and/or other solutions offers for you as well as to process applications and to calculate premiums. We also need your data for the administration of your contracts and the processing of benefits in the event of a claim. If necessary and contractually agreed we also transfer your data to third parties in Switzerland and abroad. These are, for example, co-insurers and reinsurers, participating partners or other companies of the Generali Group. By accepting the offer and/or signing the application, you allow us to collect, process and store the data required to process the offer, check the application and manage the contract.`;

export const SubjectToYourConsent_Value = `Wir bearbeiten Ihre Daten sowie die Daten der versicherten Personen - unter Beachtung des schweizerischen Datenschutzgesetzes - gemäss Ihrer Einwilligung und stets streng vertraulich. Die Bearbeitung Ihrer Personendaten bildet dabei die Grundlage, damit wir für Sie Offerten erstellen, Anträge bearbeiten oder die Prämien berechnen können. Wir brauchen Ihre Daten auch für die Verwaltung Ihrer Verträge sowie die Bearbeitung von Leistungen bei einem Schaden- oder Leistungsfall. Falls notwendig übermitteln wir Ihre Daten an Dritte im In- und Ausland, sollten diese einen entsprechenden Rechtfertigungsgrund haben. Das sind zum Beispiel Mit- und Rückversicherer, beteiligte Partner oder weitere Gesellschaften der Generali Gruppe. Mit Zustellung der Offerte oder mit Unterzeichnung des Antrages erlauben Sie uns, die zur Offertbearbeitung, zur Antragsprüfung und Vertragsverwaltung erforderlichen Daten zu sammeln, zu bearbeiten und zu speichern.`;


// ################# In addition, you consent to allowing... #################

export const InAddition_Key = `In addition, you consent to allowing us to obtain information from relevant authorities and third parties, in particular information from your previous insurer regarding your claims history. By submitting this form, you also authorise us to use your data for (statistical) evaluations, for analyses within Generali and for marketing purposes. For existing Generali customers, we link your additional personal data to your already existing data in order to offer you better insurance solutions and products.`;
  
export const InAddition_Value = `Zudem erlauben Sie uns, dass wir bei Amtsstellen und Dritten sachdienliche Auskünfte einholen dürfen. Das betrifft insbesondere Informationen vom Vorversicherer in Bezug auf den Schadenverlauf. Zudem ermächtigen Sie uns mit Ihrer Unterzeichnung, Ihre Daten für (statistische) Auswertungen, für personenbezogene Analysen und für Marketingzwecke innerhalb von Generali zu verwenden. Sollten Sie bereits Kundin oder Kunde von Generali sein, können wir die erhobenen Personendaten mit bereits vorhandenen Daten und Produkten verknüpfen, so dass wir Ihnen bessere Angebote machen können.`;

// ################# Should you choose to communicate... #################

export const ShouldYouChoose_Key = `Should you choose to communicate with us via digital channels, such as through the customer portal or by e-mail, we would be pleased to advise you in the same manner and potentially make you additional offers. Please note that sending your personal data through digital channels may compromise your data, if not sent through a secure channel. You can find further information about the processing of your data on our data protection declaration at https://www.generali.ch/en/allgemein/footnote/datenschutz`;
  
export const ShouldYouChoose_Value = `Sie können mit uns auch über digitale Kanäle, wie zum Beispiel das Kundenportal oder per E-Mail kommunizieren. Sollten Sie sich für eine solche digitale Kommunikationsform entscheiden (z.B. durch Zustellung Ihrer E-Mail-Adresse), werden wir Sie gerne entsprechend beraten und Ihnen ein mögliches Zusatzangebot machen. Bitte beachten Sie, dass es dabei Risiken geben kann, zum Beispiel ein unsicherer E-Mail-Kanal. Weitergehende Angaben über die Bearbeitung Ihrer Daten finden Sie auf unserer Datenschutzerklärung unter www.generali.ch/datenschutz`;

// ################# You herewith release the following persons... #################

export const YouHerewithRelease_Key = `You herewith release the following persons, companies and institutions from their obligation to maintain their professional and official secrecy or medical duty of discretion and authorise them to provide Generali and its representatives with the information they need to examine the application, claim for insurance benefits or clarify any infringement of the obligation to declare information:
- Hospitals and further sanatoriums, doctors, psychologists, psychotherapists, physiotherapists or other therapists and persons with medical training
- Employers, accident, health and life insurers, pension funds and health insurance funds, the military insurance, the Federal disability insurance and SUVA`;


export const YouHerewithRelease_Value = `Sie entbinden hiermit folgende Personen, Gesellschaften und Institutionen von ihrem Berufs- und Amtsgeheimnis bzw. ihrer ärztlichen Schweigepflicht und ermächtigen diese gegenüber Generali und deren Bevoll mächtigte Auskünfte zu erteilen welche für die Prüfung des Antrages, des Anspruchs auf Versicherungsleistungen oder für die Abklärung einer allfälligen Anzeigepflichtverletzung benötigt werden:
- Spitäler und weitere Heilanstalten, Ärzte, Psychologen, Psychotherapeuten, Physiotherapeuten oder andere Therapeuten und medizinisch geschulte Personen
- Arbeitgeber, Unfall-, Kranken- und Lebensversicherer, Pensions- und Krankenkassen, die Militärversicherung, die Eidgenössische Invalidenversicherung und die SUVA`;

// ################# You confirm that you have received... #################

export const YouConfirmThatYouHaveReceived_Key = `You confirm that you have received and accepted the terms and conditions of insurance, receipt of the proposal, product and intermediary information and explanation of the documents by the intermediary. The insurance cover that was selected (choice between benefits on maturity, in the event of death, in the event of disability, etc.) meets the needs and the requirements of the applicant.`;
  
export const YouConfirmThatYouHaveReceived_Value = `Sie bestätigen Empfang und Annahme der Versicherungsbedingungen, Empfang des Vorschlages, der Produkte- und Vermittler-Informationen sowie Erläuterung der Dokumente durch den Vermittler. Der gewählte Versicherungsschutz (wahlweise Leistungen im Erlebensfall, im Todesfall, bei Invalidität, usw.) entspricht dem Bedürfnis und den Vorgaben der Antragstellerin/des Antragstellers.`;

// ################# You confirm that the differences... #################

export const YouConfirmThatTheDifferences_Key = `You confirm that the differences between qualified provident insurance Pillar 3a and voluntary provident insurance Pillar 3b have been explained to you by the advisor.`;
  
export const YouConfirmThatTheDifferences_Value = `Sie bestätigen, dass Sie durch den Vermittler über die Unterschiede der Säule 3a (gebundene Vorsorge) und der Säule 3b (freie Vorsorge) orientiert wurden.`;

// ################# You acknowledge that... #################

export const YouAcknowledgeThat_Key = `You acknowledge that the intermediary will receive compensation from Generali for brokering the insurance contract. The amount of compensation may depend on the amount of the premium and the term of the brokered insurance contract.You are responsible for the accuracy and completeness of your answers, regardless of who in particular writes the information down.`;
  
export const YouAcknowledgeThat_Value = `Sie nehmen zur Kenntnis, dass der Vermittler für die Vermittlung des Versicherungsvertrages eine Entschädigung von Generali erhält. Die Höhe der Entschädigung kann von der Höhe der Prämie und der Laufzeit des vermittelten Versicherungsvertrages abhängen.

Sie sind für die Richtigkeit und Vollständigkeit der Antworten verantwortlich, gleichgültig, wer diese niederschreibt.`;

// ################# You confirm that the answers... #################

export const YouConfirmThatTheAnswers_Key = `You confirm that the answers given correspond to the information given to the advisor.You confirm all of the agreements and information contained in this application. Special agreements or assurances shall only be binding on Generali if they have been confirmed in writing by Generali. In the case of incomplete or untruthful replies Generali can within 4 weeks after they have become aware of the infringement of the duty terminate the contract. The applicant is bound to his application for 14 days or for 4 weeks for insurances with medical examination.`;
  
export const YouConfirmThatTheAnswers_Value = `Sie bestätigen, dass sich die festgehaltenen Antworten mit den beim Vermittler gegenüber gemachten Angaben decken. Sie bestätigen alle in diesem Antrag festgehaltenen Vereinbarungen und Angaben. Besondere Abmachungen oder Zusicherungen sind für Generali nur verbindlich, wenn sie von der Direktion schriftlich bestätigt wurden. Bei unvollständiger oder nicht wahrheitsgetreuer Beantwortung kann Generali innert 4 Wochen, nachdem sie von der Verletzung der Anzeigepflicht Kenntnis erlangt hat, den Vertrag kündigen. Der Antragsteller bleibt 14 Tage bzw. bei Versicherungen mit ärztlicher Untersuchung 4 Wochen an seinen Antrag gebunden.`;

// ################# Declaration with regard to all tax residencies... #################

export const DeclarationWithRegard_Key = `Declaration with regard to all tax residencies. Based on the Swiss law on the international automatic exchange of information in tax matters (the AEOI Act), which regulates the implementation of the automatic exchange of information in tax matters (AEOI) between Switzerland and its partner countries, we are obliged when concluding a contract, amending a contract and making payouts to clarify where our policyholders or other beneficiaries are liable for taxation.`;


export const DeclarationWithRegard_Value = `Erklärung zu allen steuerlichen Ansässigkeiten.
Basierend auf dem schweizerischen Gesetz über den internationalen automatischen Informationsaustausch in Steuersachen (AIA-Gesetz), welches die Umsetzung des automatischen Informationsaustausches in Steuersachen (AIA) zwischen der Schweiz und deren Partnerstaaten regelt, sind wir verpflichtet, bei Vertragsabschluss, bei Vertragsänderungen sowie bei Auszahlungen von Leistungen abzuklären, wo unsere Versicherungsnehmer und Versicherungsnehmerinnen oder andere Empfänger von Leistungen steuerpflichtig sind.`;


// ################# Please provide all... #################

export const PleaseProvideAll_Key = `Please provide all your tax residences, along with your tax identification numbers (TINs). A tax residence is generally in the country in which you have your main residence.`;


export const PleaseProvideAll_Value = `Bitte geben Sie alle Ihre steuerlichen Ansässigkeiten sowie Ihre Steueridentifikationsnummern (Tax Identification Number, TIN) an. Eine steuerliche Ansässigkeit befindet sich in der Regel in demjenigen Staat, in dem Sie Ihren Hauptwohnsitz haben.`;



// ################# Authorisation to forward details... #################

export const AuthorisationToForwardDetails_Key = `Authorisation to forward details: If your tax residence is in a country with which Switzerland has concluded an AEOI Agreement, Generali is obliged to supply the information contained in this form to the Swiss Federal Tax Administration (FTA). This applies to data pertaining to your life insurance contract, particularly information about your assets and payouts. The FTA will then share this data with the tax authorities of the partner countries concerned.`;


export const AuthorisationToForwardDetails_Value = `Datenweiterleitung: Wenn sich Ihre Steueransässigkeit in einem Staat befindet, mit welchem die Schweiz den AIA vereinbart hat, ist Generali verpflichtet, die in diesem Formular enthaltenen Informationen der Eidgenössischen Steuerverwaltung (ESTV) zu melden. Dies betrifft Daten zu Ihrem Lebensversicherungsvertrag, insbesondere Angaben über Ihre Vermögenswerte und erfolgte Auszahlungen. Die ESTV tauscht diese Daten alsdann mit den Steuerbehörden von betroffenen Partnerstaaten aus.`;

// ################# For more information... #################

export const ForMoreInformation_Key = `For more information on the AEOI, click on the link below: 
https://www.sif.admin.ch/sif/en/home/multilateral-relations/exchange-information-tax-matters/automatic-exchange-information/financial-accounts.html`;


export const ForMoreInformation_Value = `Mehr Informationen zum AIA finden Sie unter diesem Link: 
https://www.sif.admin.ch/sif/de/home/themen/internationale-steuerpolitik/automatischer-informationsaustausch.html`;


// ################# I am not a "U.S. Person"... #################

export const IAmNotAUSPerson_Key = `I am not a "U.S. Person" as defined by U.S. tax law, although U.S. indices (as listed above) apply to me.  All the above mentioned indications must be refuted by providing the following documents`;

export const IAmNotAUSPerson_Value = "Ich bin keine U.S.-Person im Sinne des U.S. Steuerrechts und es trifft kein U.S. Indiz auf mich zu (vgl. oben)."

//

export const USTax_Key = `- U.S. tax Form W-8Ben (completed, dated and signed) 
- Copy of a non-U.S. passport / ID card 
and
- a statement explaining why there is no U.S. personal status that applies to you`;

export const USTax_Value = `- US-Steuerformular W-8BEN (ausgefüllt, datiert und unterzeichnet),
- Kopie eines nichtamerikanischen Passes/einer nichtamerikanischen Identitätskarte UND
- eine Erklärung warum kein U.S. Personenstatus vorliegt`;



// ################# 7.8 - Have you ever been... #################
export const HaveYouEverBeen_Key = `7.8 - Have you ever been diagnosed with cardiovascular disease, stroke, kidney disease, brain haemorrhage or cancer?

Do you suffer from multiple sclerosis or diabetes? Do you have or have you ever had a psychological disorder?`;

export const HaveYouEverBeen_Value = `7.8 - Wurde bei Ihnen jemals Herz- und Kreislaufkrankheiten, einen Schlaganfall, eine Nierenerkrankung, eine Hirnblutung oder Krebs diagnostiziert?

Leiden Sie an Multipler Sklerose oder an Diabetes? Haben oder hatten Sie jemals eine psychische Störung?`;



// ################ The insured person must...  ##################
export const TheInsuredPersonMust_Key = `The insured person must provide the following documents: 

- Complete tax returns for the last two years (incl. debt and asset statement) 
- Company balance sheets from the last three years (if the company is the policyholder, the insured person has shares in it or the company is involved in the contract in some other way) 
- Additionally, for persons who have been self-employed for less than 12 months: Provisional, current AHV statement of account`;

export const TheInsuredPersonMust_Value = `Wir benötigen von der versicherten Person zwingend die folgenden Unterlagen:
- Vollständige Steuererklärungen der letzten 2 Jahre (inkl. Schulden- und Wertschriftenverzeichnis).
- Bilanzen der letzten 3 Jahre der Firma (wenn diese Versicherungsnehmer ist, die versicherte Person daran beteiligt ist oder die Firma auf eine andere Art und Weise am Vertrag beteiligt ist).
- Zusätzlich für Personen, die seit weniger als 12 Monaten selbständig- erwerbend sind: Vorläufige, aktuelle AHV-Abrechnung`;


// ################# All personal data provided by you... #################
export const AllPersonalData_Key = `All personal data provided by you, including details about your state of health, may be passed on to the parent company in Italy, Assicurazioni Generali S.p.A, with registered office in Trieste (34132), Piazza Duca degli Abruzzi No 2 and entered in the Register of Italian Insurance and Reinsurance Companies maintained by the insurance supervisory authority ISVAP under number 1.00003. The parent company may use the data for risk assessment, for the provision of contractual benefits or for co-insurance and reinsurance purposes. Moreover, Assicurazioni Generali S.p.A. may pass on these data for the same purposes to trusted individuals (e.g. doctors, lawyers, specialists, other advisors), other companies within the Generali Group, or other insurers or reinsurers in Italy or abroad, who are acting on its behalf. 
Generali Insurances of Persons Ltd., Soodmattenstrasse 10, 8134 Adliswil will provide you with information in this regard. 
By signing this declaration, you give your express consent to your general and sensitive personal data being passed on, for the above-mentioned purposes, to Assicurazioni Generali S.p.A. and other persons and companies mentioned 
above, as well as to their processing these data. 
The signatories hereof are responsible for the truthfulness and completeness of their answers, regardless of who in particular writes the information down. The signatories confirm that the answers given to the questions are in accordance with the answers given to the agent. In the case of incomplete or untruthful answers, Generali Insurances 
of Persons Ltd. may terminate the contract within four weeks of learning of the infringement of the obligation to disclose information.`;

export const AllPersonalData_Value = `Alle von Ihnen bekannt gegebenen personenbezogenen Daten, einschließlich der Angaben zum Gesundheitszustand, können an die Muttergesellschaft in Italien, Assicurazioni Generali S.p.A., weitergegeben werden, die ihren Rechtssitz in Triest (34132), Piazza Duca degli Abruzzi Nr. 2 hat und unter der Nummer 1.00003 in das von der Versicherungsaufsichtsbehörde ISVAP geführte Verzeichnis der Versicherungs- und Rückversicherungsunternehmen eingetragen ist. Die Muttergesellschaft kann die Daten zur Risikobewertung, zur Erbringung der vertraglichen Leistungen sowie zu Mitversicherungs- und Rückversicherungszwecken verwenden. Ferner kann die Assicurazioni Generali S.p.A. die besagten Daten zu den gleichen Zwecken an für sie tätige Vertrauenspersonen (zum Beispiel: Ärzte, Anwälte, Sachverständige, sonstige Berater), an andere Unternehmen der Generali-Gruppe sowie an andere Versicherer oder Rückversicherer in Italien und im Ausland weitergeben.
Auskünfte in diesem Zusammenhang erteilt Ihnen Generali Personenversicherungen AG, Soodmattenstrasse 10,
8134 Adliswil.
Mit Ihrer Unterschrift billigen Sie ausdrücklich die Weitergabe Ihrer allgemeinen und sensiblen personenbezogenen Daten zu den oben genannten Zwecken an die Gesellschaft Assicurazioni Generali S.p.A. und die anderen oben ange führten Personen und Unternehmen sowie die Verarbeitung der Daten durch diese.
Die Unterzeichner sind für die Richtigkeit und Vollständigkeit ihrer Antworten verantwortlich, gleichgültig, wer diese niederschreibt. Die  Unterzeichnenden bestätigen, dass sich die festgehaltenen Antworten mit den dem Vermittler gegenüber gemachten Angaben decken. Bei unvollständiger oder nicht wahrheitsgetreuer Beantwortung kann Generali Personen versicherungen AG innert 4 Wochen, nachdem sie von der Verletzung der Anzeigepflicht Kenntnis erlangt hat, den Vertrag kündigen.`;

// ################# If you are concerned by... #################
export const IfYouAreConcerned_Key = `- If you are concerned by indication No. 2: A copy of the authorization to stay outside the United States and, for a Greencard, proof that the Greencard has been relinquished. 
- If you are concerned by Indication No. 1 or No. 3: A copy of the certificate of loss of U.S. citizenship or a statement indicating the reason why you were not granted U.S. citizenship.`;

export const IfYouAreConcerned_Value = `- für Ziff. 2: Kopie der Aufenthaltsbewilligung ausserhalb der USA und bei einer Greencard zudem ein Nachweis der Aufgabe der Green Card
- für Ziff. 1 und Ziff. 3: Kopie der Bescheinigung über den Verlust der Staatsbürgerschaft oder allenfalls eine Erklärung über den Grund, weshalb Sie die U.S.-Staatsbürgerschaft nicht erhalten haben.`;


// ################# Obligation to notify... #################
export const ObligationToNotify_Key = `Obligation to notify
You are obliged to notify Generali immediately if there is a new indication of US tax liability or if you subsequently become liable for tax in the US.`;

export const ObligationToNotify_Value = `Meldepflicht
Sie sind verpflichtet, Generali umgehend zu informieren, falls es ein neues Indiz für eine US-Steuerpflicht gibt oder falls Sie nachträglich in den USA steuerpflichtig geworden sind.`;


// ################# Authorisation to share data... #################
export const AuthorisationToShareData_Key = `Authorisation to share data
By your signature, you authorise Generali, in so far as you are or will be subject to U.S. liability or if indications of U.S. tax liability apply to your situation, to communicate personal tax details and tax details relating to this contract to authorities in Switzerland or abroad (in particular to the U.S. Internal Revenue Service, the IRS). Such details will be forwarded electronically and across borders.`;

export const AuthorisationToShareData_Value = `Ermächtigung zur Weiterleitung von Daten
Mit Ihrer Unterschrift ermächtigen Sie Generali, sofern Sie aktuell oder künftig der U.S.-Steuerpflicht unterliegen oder ein Indiz für eine U.S.-Steuerpflicht auf Sie zutrifft, zur Meldung personen- und vertragsbezogener Steuerdaten an in- oder ausländische Behörden (insbesondere den U.S.-Internal Revenue Service, IRS). Die Weiterleitung erfolgt auf elektronischem Weg und grenzüberschreitend.`;

// ################# Please take note... #################
export const PleaseTakeNote_Key = `Please take note that Generali can exchange the information we received from you regarding tax residence(s) with its sister company, Fortuna Life Insurance Ltd., in case you have life insurance contracts with both companies.`;

export const PleaseTakeNote_Value = `Bitte beachten Sie, dass Generali die von Ihnen erhaltenen Informationen bezüglich steuerliche Ansässigkeit(en) mit ihrer Schwestergesellschaft, der Fortuna Lebens-Versicherungs AG, austauschen kann, sofern Sie bei beiden Gesellschaften über einen Lebensversicherungsvertrag verfügen.`;


// ################# Consequences of non-compliance... #################
export const ConsequencesOfNonCompliance_Key = `Consequences of non-compliance
If you choose to not duly complete and return this questionnaire and the other required paperwork, and/or to not provide us with further information and/or to not agree to your data being forwarded to the IRS, we will have to note your lack of compliance in our files. In this case, we would be obliged under certain circumstances to deduct a withholding tax of 30% from the amounts paid and pay it to the U.S. tax authorities. In addition, tax data relating to individuals and contracts are generally communicated to national or foreign authorities.`;

export const ConsequencesOfNonCompliance_Value = `Folgen bei Verweigerung
Sollten Sie uns diesen Fragebogen und weitere notwendige Schriftstücke nicht ausgefüllt einreichen und/oder uns weitere Auskünfte und/oder die Zustimmung zur Datenübermittlung an die IRS verweigern, müssen wir Ihre fehlende Zustimmung entsprechend vermerken. Diesfalls müssen wir unter gewissen Umständen auf Auszahlungsbeträgen eine Quellensteuer von 30% abziehen und an die US-amerikanische Steuerbehörde überweisen. Zudem wird im Regelfall eine Meldung von personen- und vertragsbezogener Steuerdaten an in- oder ausländische Behörden vorgenommen.`;

// ################# Any existing insurance and/or... #################
export const AnyExistingInsurance_Key = `Any existing insurance and/or insurance applied for, including with other insurers, must be taken into account. 
Please answer all the questions below and attach the documentation requested. Your answers will be treated in the strictest confidence. 
In case of questions, do not hesitate to contact Ms Christine Hofer or her representative, on +41 58 472 43 57 at any time.`;

export const AnyExistingInsurance_Value = `Es sind sämtliche bestehenden und/oder beantragten Deckungen, auch bei anderen Versicherern, zu berücksichtigen.
Bitte beantworten Sie alle nachstehenden Fragen vollständig und legen Sie die erforderlichen Unterlagen dazu. Ihre Angaben werden streng vertraulich behandelt.
Bei Fragen steht Ihnen Frau Christine Hofer oder Ihre Stellvertretung unter der Telefonnummer +41 58 472 43 57 jederzeit gerne zur Verfügung. `;


