import { SystemPropertiesService } from "@rgi/life-issue-card";
import {Observable} from "rxjs/internal/Observable";
import {switchMap} from "rxjs/operators";
import {SYSTEM_PROPERTY_ENABLING} from "./enums";
import {of} from "rxjs";
import {Injectable} from "@angular/core";
import {SYS_PROP_ENABLE_AUTHORIZATION_FLOW} from "./constants";

@Injectable({
    providedIn: 'root'
})
export class AuthorizationFlowService {

    private enableAuthFlow = false;
    private isFromConsultation = false;

    set enableAuthorizationFlow(state: boolean) {
        this.enableAuthFlow = state;
    }

    get enableAuthorizationFlow(): boolean {
        return this.enableAuthFlow;
    }

    setfromConsultation(fromConsult : boolean) {
        this.isFromConsultation = fromConsult;
    }

    getfromConsultation() : boolean{
        return this.isFromConsultation;
    }

    constructor(
        protected systemPropertiesService: SystemPropertiesService
    ) {}

    getEnableAuthorizationValue(): Observable<boolean>{
        return this.systemPropertiesService.getSystemPropByKeys([SYS_PROP_ENABLE_AUTHORIZATION_FLOW]).pipe(
            switchMap((res: {systemProperties: {key: string, value: string} []}) => {
                this.enableAuthorizationFlow = !!res && !!res.systemProperties && res.systemProperties
                    .map(p => p.value.toLocaleLowerCase()).includes(SYSTEM_PROPERTY_ENABLING.ENABLED);
                return of(this.enableAuthorizationFlow);
            })
        );
    }
}
