import { LicTranslationsKeys } from '../lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LicTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_FR: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: 'Numéro de police {{num}} émis correctement',
  lic_ProposalCorrectlyIssued: 'Numéro de proposition {{num}} émis correctement',
  lic_LifeProposalCorrectlyIssued: 'Numéro de proposition vie {{num}} émis correctement',
  lic_LifePolicyCorrectlyIssued: 'Numéro de police vie {{num}} émis correctement',
  lic_DamagesPolicyCorrectlyIssued: 'Numéro de police de dommages {{num}} émis correctement',
  lic_AuthorizatioProposalForwarded: 'La demande d\'autorisation pour le numéro de proposition {{num}} a été transmise à l\'utilisateur approprié',
  lic_ProposalCorrectlySaved: 'Numéro de proposition {{num}} enregistré correctement',
  lic_PreventiveCorrectlySaved: 'Numéro de devis {{num}} enregistré correctement',
  lic_benMorteNoAssic: 'Le bénéficiaire du décès sélectionné est déjà sélectionné en tant qu\'assuré, veuillez sélectionner un autre sujet',
  lic_benMorteNoRef: 'Le bénéficiaire du décès sélectionné est déjà sélectionné en tant que personne de contact tierce, veuillez sélectionner un autre sujet',
  lic_benefPresente: 'Le bénéficiaire du décès sélectionné est déjà sélectionné, veuillez sélectionner un autre sujet',
  lic_refTerzoNoAssic: 'La personne de contact tierce sélectionnée est déjà sélectionnée en tant qu\'assuré, veuillez sélectionner un autre sujet',
  lic_refTerzoNoContr: 'La personne de contact tierce sélectionnée est déjà sélectionnée en tant que titulaire de police, veuillez sélectionner un autre sujet',
  lic_refTerzoPresente: 'La personne de contact tierce sélectionnée est déjà sélectionnée en tant que bénéficiaire du décès, veuillez sélectionner un autre sujet',
  lic_DelegateEqualToPolicyHolder: 'Délégué égal au titulaire de la police ?',
  lic_LegalGuardianEqualToPolicyHolder: 'Tuteur légal égal au titulaire de la police ?',
  lic_ThirdPayerEqualToPolicyHolder: 'Tiers payeur égal au titulaire de la police ?',
  lic_DelegateEqualToPolicyHolderWarning: 'Sujet pour le rôle de délégué égal au titulaire de la police',
  lic_LegalGuardianEqualToPolicyHolderWarning: 'Sujet pour le rôle de tuteur légal égal au titulaire de la police',
  lic_ThirdPayerEqualToPolicyHolderWarning: 'Sujet pour le rôle de tiers payeur égal au titulaire de la police',
  lic_MasterPolicyHolder: 'Titulaire de police principal',
  lic_SubjectXalreadyselectedfortherole: 'Sujet {{subject}} déjà sélectionné pour le rôle',
  lic_InsuredAmount: 'Montant assuré',
  lic_InvestedPremium: 'Prime investie',
  lic_FirstInstalment: 'Premier versement',
  lic_InsuredNotEqualHolder: 'L\'assuré et le titulaire doivent être différents',
  lic_SaveEstimate: 'Enregistrer devis',
  lic_LifeQuote: 'Devis vie',
  lic_PreventiveIssue: 'Émission de devis',
  lic_ProposalIssue: 'Émission de proposition',
  lic_PolicyIssue: 'Émission de police',
  lic_appendixLabel: 'Annexe bénéficiaire',
  lic_summary_save_and_accept: 'enregistrer et accepter',
  lic_summary_save_accept_pol_issue: 'enregistrer, accepter et émettre une police',
  lic_summary_save_accept_prop_issue: 'enregistrer, accepter et émettre une proposition',
  lic_effectivebeneficiaryholder: 'titulaire bénéficiaire effectif',
  lic_LifePolicy: 'Police vie',
  lic_DamagesPolicy: 'Police de dommages',
  lic_view: 'Voir',
  lic_premium_detail: 'Détail de la prime',
  lic_guarantee_summary: 'Résumé de la couverture',
  lic_commissions: 'Commissions',
  lic_Parties: 'Parties',
  lic_Party: 'Partie',
  lic_Employer: 'Employeur',
  lic_SpecificPIPdata: 'Données PIP spécifiques',
  lic_prev_continue: 'Continuer',
  lic_Transferdata: 'Transférer des données',
  lic_Selectatleastonecontribution: 'Sélectionnez au moins une contribution',
  lic_Globalpercentagemustbe100: 'Le pourcentage global doit être de 100 %',
  lic_Selectcontributionsmandatoryfields: 'Sélectionnez les champs obligatoires des contributions',
  lic_Contributiondata: 'Données de contribution',
  lic_Globalamountmustbemajorthan0: 'Le montant global doit être supérieur à 0',
  lic_Everycontributionselectedmustbemajorthan0: 'Chaque contribution sélectionnée doit être supérieure à 0',
  lic_error_mandatory_fields: 'Les champs marqués en rouge sont requis ou contiennent des valeurs incorrectes',
  lic_error_iban: 'L\'IBAN n\'est pas correct',
  lic_authorization_request: 'DEMANDE D\'AUTORISATION',
  lic_selected_profiles_: 'Profils sélectionnés',
  lic_Disinvestmentsfunds: 'Fonds de désinvestissement',
  lic_reg41_comunication: 'Nom pour envoyer des communications à',
  lic_disinvestment_amount: 'Montant du désinvestissement',
  lic_occurrenceNumber: 'Occurrence',
  lic_TheoccurenceNumbermustbeavalue: 'Le numéro d\'occurrence doit être supérieur à {{min}}',
  lic_Disinvestmentrange: 'Veuillez saisir un montant de désinvestissement supérieur à {{max}} et inférieur ou égal à {{min}}',
  lic_Incompleteinvestmentforthefunds: 'Investissement incomplet, veuillez compléter l\'investissement pour les fonds',
  lic_Incompleteinvestmentfortheprofiles: 'Investissement incomplet, veuillez compléter l\'investissement pour les profils',
  lic_Settlementstoreinvest: 'Règlements à réinvestir',
  lic_Policynumber: 'Numéro de police',
  lic_Type: 'Type',
  lic_Beneficiary: 'Bénéficiaire',
  lic_State: 'État',
  lic_Net: 'Net',
  lic_Nettoreinvest: 'Net à réinvestir',
  lic_Reinvestmentmustbelargerthanquotation: 'Le réinvestissement doit être supérieur à la citation.',
  lic_Mustselectatleastonesettlementtoreinvest: 'Vous devez sélectionner au moins un règlement à réinvestir.',
  lic_Nettoreinvestmustbeequalorlessthannet: 'Le montant net à réinvestir doit être égal ou inférieur au net.',
  lic_Nosettlementstoreinvestexist: 'Aucun règlement à réinvestir n\'existe.',
  lic_Missingvalueonnettoreinvest: 'Valeur manquante sur le montant net à réinvestir.',
  lic_information: 'Information',
  lic_Percentage: 'Pourcentage',
  lic_Durationin: 'Durée en ',
  lic_years: 'années',
  lic_months: 'mois',
  lic_days: 'jours',
  lic_Complementary: '(Complémentaire)',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: 'Veuillez fournir un mode de paiement au bénéficiaire du coupon.',
  lic_Netavailable: 'Net disponible',
  lic_Proposal_Saved: 'La proposition a été enregistrée avec le numéro ',
  lic_add_employer: 'Ajouter un employeur',
  lic_AvailableGuarantees: 'Garanties disponibles',
  lic_error: 'Erreur',
  lic_error_msg: 'Erreur lors de l\'émission de la police',
  lic_auth_error_msg: 'Erreur lors du traitement de la demande',
  lic_common_risk: 'Risque commun',
  lic_assignment_holder: 'Titulaire de l\'affectation',
  lic_Sustainability: 'Durabilité',
  lic_ecoSustainabilityAmount: 'Montant de la durabilité écologique',
  lic_ecoSustainabilityPerc: 'Pourcentage de durabilité écologique',
  lic_envSustainabilityAmount: 'Montant de la durabilité environnementale',
  lic_envSustainabilityPerc: 'Pourcentage de durabilité environnementale',
  lic_paiAmount: 'Montant PAI',
  lic_paiPerc: 'Pourcentage PAI',
  lic_socialSustainabilityAmount: 'Montant de la durabilité sociale',
  lic_socialSustainabilityPerc: 'Pourcentage de durabilité sociale',
  lic_benefSevereDisability: 'Bénéficiaire en situation de handicap grave',
  lic_SecondInsured: 'Deuxième assuré',
  lic_Secondinsuredmustbeaphysicalperson: 'Le deuxième assuré doit être une personne physique',
  lic_Selectasecondinsured: 'Sélectionnez un deuxième assuré',
  lic_Aderente: 'Adhérent',
  lic_NumeroProposta: 'Numéro de proposition',
  lic_DescrizioneProdotto: 'Description du produit',
  lic_DescrizioneRischio: 'Description du risque',
  lic_CapitaleAssicurato: 'Montant assuré',
  lic_SELEZIONA: '-- SÉLECTIONNEZ --',
  lic_PrNetto: '% de prime nette',
  lic_ImpAcq: 'Taxe d\'achat',
  lic_profileInvestmentIncomplete: 'Investissement incomplet, complétez les profils d\'investissement',
  lic_fundsInvestmentIncomplete: 'Investissement incomplet, veuillez compléter les fonds d\'investissement',
  lic_Clauses: 'Clauses',
  lic_quote_type: 'Type de devis',
  lic_Policyholder: 'Titulaire de la police',
  lic_quote_type_anonymous: 'Anonyme',
  lic_quote_type_registered: 'Enregistré',
  lic_text_required: '*Texte requis',
  lic_text_required_error: 'Le texte de la clause est requis.',
  lic_close: 'fermer',
  lic_save: 'enregistrer',
  lic_NEXT: 'SUIVANT',
  lic_policyData: 'Données de police',
  lic_pipData: 'Données PIP',
  lic_parties: 'Parties',
  lic_assets: 'Actifs',
  lic_quotation: 'Devis',
  lic_summary: 'Résumé',
  lic_issue: 'Émission',
  lic_investments: 'Investissements',
  lic_invest_value_range: 'Veuillez saisir une valeur entre {{min}} et {{max}}',
  lic_disinvestment_range: 'Veuillez saisir une valeur de désinvestissement entre {{min}} et {{max}}',
  lic_mandatory_threshold_perc: 'Fonds: {{value}} - Veuillez saisir le pourcentage de seuil',
  lic_mandatory_one_fund: 'Sélectionnez au moins un fonds',
  lic_invest_factor_min_validation: '{{factor}} doit être inférieur à {{amount}}',
  lic_invest_factor_max_validation: '{{factor}} doit être supérieur à {{amount}}',
  lic_invest_factor_eq_validation: '{{factor}} doit être {{amount}}',
  lic_calculate_asset_volatility: 'Calculer la volatilité des actifs',
  lic_calculate_sustainability_asset: 'Calculer la durabilité des actifs',
  lic_occurance_number_range: 'Le numéro d\'occurrence doit être supérieur à {{min}}',
  lic_policy_holder: 'Titulaire de la police',
  lic_guarantee: 'Garantie',
  lic_co_applicant_not_same_holder: 'Le co-demandeur ne doit pas être le même que le titulaire de la police',
  lic_co_applicant_physical: 'Le co-demandeur doit être une personne physique',
  lic_co_applicant_mandatory: 'Le co-demandeur est obligatoire',
  lic_co_applicant: 'Co-demandeur',
  lic_insured_must_be_physical: 'L\'assuré doit être une personne physique',
  lic_mandatory_product: 'Sélectionnez un produit',
  lic_mandatory_insured: 'Sélectionnez un assuré',
  lic_mandatory_holder: 'Sélectionnez un titulaire de police',
  lic_contribution_frequency: 'La fréquence de contribution {{frequency}} doit être la même',
  lic_mandatory_benef: 'Bénéficiaire {{catégorie}} : le bénéficiaire est obligatoire',
  lic_mandatory_appendix_benef: 'Bénéficiaire {{catégorie}} : le bénéficiaire de l\'appendice est obligatoire',
  lic_add_benef: 'Bénéficiaire {{catégorie}} : ajoutez un bénéficiaire du registre civil',
  lic_add_benef_perc: 'Bénéficiaire {{catégorie}} {{nominatif}} : ajoutez un pourcentage',
  lic_benef_perc_range: 'Bénéficiaire {{catégorie}} {{nominatif}} : le pourcentage doit être compris entre 0,01 et 100',
  lic_benef_total_perc: 'Bénéficiaire {{catégorie}} : le montant en pourcentage doit être de 100%',
  lic_guarantee_mandatory_data: 'Couverture {{unit}} : Remplissez toutes les données obligatoires',
  lic_quotation_not_done: 'Devis non effectué : sélectionnez "Devis" pour mettre à jour le devis',
  lic_quotation_modified_value_quote: 'Les données sont mises à jour : sélectionnez "Devis" pour mettre à jour le devis',
  lic_quotation_benef_custom_msg: 'Bénéficiaire {{catégorie}} - {{msg}}',
  lic_product: 'Produit',
  lic_managementNode: 'Nœud de gestion',
  lic_branch: 'Branche',
  lic_agreement: 'Accord',
  lic_effect_date: 'Date d\'effet',
  lic_exp_date: 'Date d\'expiration',
  lic_payment_frequency: 'Fréquence de paiement',
  lic_means_of_payment: 'Moyens de paiement',
  lic_unit_type: 'Type d\'unité',
  lic_risk_type: 'Type de risque',
  lic_substate: 'Sous-état',
  lic_benefit: 'Avantage',
  lic_decreasing_frequency: 'Fréquence de diminution',
  lic_damages: 'Dommages',
  lic_effective_holder: 'Titulaires effectifs',
  lic_insured_role: 'Assuré',
  lic_third_contact_person: 'Troisième personne de contact',
  lic_quotation_benef_owner_perc_validation: '{{roleDescr}} {{name}} du bénéficiaire {{benefName}} ne respecte pas le pourcentage minimum accepté de {{minPerc}}',
  lic_subscriber: 'Abonné',
  lic_yes: 'oui',
  lic_no: 'non',
  lic_new_insured: 'Nouvel assuré',
  lic_simple_select: 'Sélectionner',
  lic_first_instalment: 'Première échéance',
  lic_next_instalment_premium: 'Prime de la prochaine échéance',
  lic_payment_type: 'Type de paiement',
  lic_first_instalment_payment_type: 'Type de paiement de la première échéance',
  lic_annual_premium: 'Prime annuelle',
  lic_quote_button: 'Devis',
  lic_quote_save: 'Devis et enregistrer',
  lic_purchasing_commission: 'Commission d\'achat',
  lic_beneficiaries_label: 'Bénéficiaires',
  lic_general_data_label: 'Données générales',
  lic_contractual_option_not_allowed: 'Options contractuelles non autorisées',
  lic_investment_value_label: 'Valeur d\'investissement',
  lic_investment_profile: 'Profil d\'investissement',
  lic_investment_funds: 'Fonds d\'investissement',
  lic_calculate_finantial_parameters: 'Calculer les paramètres financiers',
  lic_confirm_button: 'Confirmer',
  lic_cancel_button: 'Annuler',
  lic_save_button: 'Enregistrer',
  lic_continue_button: 'Continuer',
  lic_next_button: 'Suivant',
  lic_required: 'requis',
  lic_node: 'Nœud',
  lic_company: 'Société',
  lic_product_type: 'Type de produit',
  lic_taxes_label: 'Taxes',
  lic_fees_label: 'Frais',
  lic_frequency_interests: 'Fréquence des intérêts',
  lic_frequency: 'Fréquence',
  lic_accessories: 'Accessoires',
  lic_taxable: 'Imposable',
  lic_quotation_detail_modal: 'Détail du devis',
  lic_guarantees_overview_modal: 'Aperçu des garanties',
  lic_gross_label: 'Brut',
  lic_int_fract_label: 'Int. Fract.',
  lic_next_instalment_label: 'Prochaine échéance',
  lic_pure_premium_label: 'Prime pure',
  lic_extra_premium_label: 'Prime supplémentaire',
  lic_loadings_label: 'Charges',
  lic_total_label: 'Total',
  lic_beneficiary_perc_is_over: 'La somme des pourcentages des rôles liés à {{benefCat}} {{benefRole}} dépasse 100',
  lic_questionSTR_invalid: 'La réponse à la question : {{question}}, n\'est pas correcte',
  lic_purchase_commissions: 'Commissions d\'achat',
  lic_PurchaseCommission: 'Commission d\'achat',
  lic_NetPrem: '% prime nette',
  lic_sales_commissions: 'Commissions de vente',
  lic_IBAN_formally_incorrect: 'L\'IBAN n\'est pas formellement valide',
  lic_holder_physical_person_req: 'Le titulaire de la police doit être une personne physique',
  lic_holder_legal_person_req: 'Le titulaire de la police doit être une personne morale',
  lic_available_covers: 'Garanties disponibles',
  lic_covers_overview: 'Aperçu des garanties',
  lic_covers: 'Garanties',
  lic_cover: 'Garantie',
  lic_config_profile_warning: 'AVERTISSEMENT : Les profils de configuration ont changé.',
  lic_select: 'Sélectionner',
  lic_Irrevocable_beneficiary: 'Bénéficiaire irrévocable',
  lic_Base: '(Base)',
  lic_Accessory: '(Accessoire)',
  lic_investment_value: 'Valeur d\'investissement',
  lic_volatility: 'Volatilité',
  lic_name: 'Nom',
  lic_to_be_invested: '',
  lic_info_investment_completed: 'Information : investissement terminé',
  lic_fund: 'Fonds',
  lic_max_percentage: 'pourcentage maximum accepté',
  lic_min_percentage: 'pourcentage minimum accepté',
  lic_selected_funds: 'Fonds sélectionnés',
  lic_allocation_exceeds: "L'allocation d'investissement dépasse le montant disponible",
  lic_percentage_threshold: '% de seuil',
  lic_fund_distribution_identical: "Distribution du fonds identique au paiement initial",
  lic_fund_not_available: "aucun fonds pour l'option",
  lic_details: 'Détails',
  lic_starting_fund: 'Fonds de départ',
  lic_fund_category: 'Profil/Fonds',
  lic_equivalent: 'Équivalent à la date',
  lic_policy_address: 'Adresse de la police',
  lic_policy_master_details: 'Détails maîtres de la police',
  lic_administrative_data: 'Données administratives',
  lic_ordinary_mail: 'Courrier ordinaire',
  lic_threshold: 'Seuil',
  lic_payment_method: 'Méthode de paiement des prochaines échéances',
  lic_additional_payment: 'Paiement supplémentaire',
  lic_contractual_options: 'Options contractuelles',
  lic_elements: 'Éléments',
  lic_position_number: 'Numéro de position',
  lic_questionnaires_section: 'Questionnaires',
  lic_fees: 'Frais fixes',
  lic__1TERM: 'Premier terme',
  lic__EFFOP: 'Date d\'effet',
  lic__OPZFR: 'Fréquence',
  lic__OPZPM: 'Mode de paiement',
  lic_expirationDate: 'Date d\'expiration',
  lic__OPBEN: 'Bénéficiaire',
  lic__MINTS: 'Seuil minimal',
  lic__MAXTS: 'Seuil maximal',
  lic__NUMOC: 'Nombre d\'occurrences',
  lic__AMTOP: 'Montant de rachat',
  lic__MALMT: 'Montant minimal',
  lic_amount: 'Montant',
  lic_currency: 'Devise',
  lic__CIBAN: 'IBAN',
  lic_fixedFees: 'Frais fixes',
  lic__PCOUP: 'Pourcentage de remise périodique',
  lic_holder: 'Titulaire',
  lic_ACTIVE_STATE: 'ACTIF',
  lic_MANDATORY_STATE: 'OBLIGATOIRE',
  lic_DEACTIVATE_STATE: 'DÉSACTIVER',
  lic_premium_label: 'Prime',
  lic_holders_title: 'Titulaires',
  lic_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lic_invalid_total_position_percentage: 'Le montant en pourcentage pour le groupe "{{groupType}}" sur le bénéficiaire {{cat}} doit être de 100%',
  lic_beneficiary_position_mandatory: 'Bénéficiaire {{benefCat}} {{benefName}}, sélectionnez un "Numéro de position"',
  lic_role_is_mandatory: 'Vous devez entrer un {{role}}',
  lic_effective_holder_duplicated: 'Titulaire effectif dupliqué',
  lic_beneficiary_perc_req_range: '{{benefCat}} {{benefRole}} {{benefName}} : Le pourcentage doit être compris entre 0,01 et 100',
  lic_beneficiary_perc_req: ' {{benefCat}} {{benefRole}} {{benefName}} : Entrez un pourcentage',
  lic_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}} : Entrez un bénéficiaire du répertoire des parties',
  lic_effective_holder_perc: "Le pourcentage du propriétaire bénéficiaire de {{benefName}} {{linkedName}} est inférieur au minimum autorisé de {{linkedPerc}}%",
  lic_effective_holder_perc_req: "Propriétaire bénéficiaire de {{benefName}} {{linkedName}} : Entrez un pourcentage",
  lic_or_junction: 'ou',
  lic_backdating_not_allowed: '',
  lic_print_docs: 'Imprimer les documents',
};
export default LIC_FR ;
