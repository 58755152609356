import {PNC_PSALES_ACTIONS} from '../../constants/actions';

export class PncPsalesButton {
  action: PNC_PSALES_ACTIONS | string;
  color: string;
  disabled = false;
  label: string;
  visible = true;

  constructor(label: string, action: string, color?: string, visible?: boolean) {
    this.label = label;
    this.action = action;
    this.color = color ? color : 'primary';
    this.visible = visible !== undefined ? visible : true;
  }
}
