import { Component, Inject, Input, OnInit } from '@angular/core';
import { RoutingService } from '@rgi/portal-ng-core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RgiRxTabSelectionChange } from '@rgi/rx/ui';
import { DialogueFunction } from '../../models/dialogue-function.model';
import { Months, months } from '../../constants/payments-searchboard.constants';
import { PaymentsSearchboardStateManager } from '../../state-managers/payments-searchboard.state.manager';
import { Searchboard } from '../../states/searchboard.state';
import { SearchForfait } from '../../models/forfait/search-forfait.model';
import { UtilityMethod } from '../../constants/forfait.constants';
import { SearchPayments } from '../../models/payments/search-payments.model';
import { Etype } from '../../models/etype.model';

const currentMonth: number = new Date().getMonth() + 1;
const currentYear: number = new Date().getFullYear();

export enum ViewFunctionCondition {
  CICOS = 'CICOS',
  CAS = 'CAS',
  SDM = 'SDM',
  SDM_MODULAR = 'SDM/MODULAR'
}

export enum TabViewCondition {
  VALIDATION_CONSOLIDATION = 0,
  PAYMENT_MANAGE = 1,
  FORFAIT_MANAGE = 2,
}

export enum SearchType {
  VALIDATION_CONSOLIDATION = "VC",
  PAYMENT_MANAGE = "G",
}

@Component({
  selector: "claims-payments-searchboard",
  templateUrl: "./payments-searchboard.component.html",
  styleUrls: ["./payments-searchboard.component.scss"],
})
export class PaymentsSearchboardComponent implements OnInit {
  @Input() userHasPermission: boolean = false;

  searchForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  searchTableForm: UntypedFormGroup;
  selectedFunctionCode: number;
  errorMessage: string | null = null;
  localState: Searchboard;
  selectedFunction: DialogueFunction | null = null;
  tabArray: string[] = ['_CLAIMS_._VALIDATION_AND_CONSOLIDATION', '_CLAIMS_._PAYMENT_MANAGE', '_CLAIMS_._FORFAIT_MANAGE']
  months: Months[] = months;
  years: number[] = [];

  selectedIndex = TabViewCondition.VALIDATION_CONSOLIDATION;

  viewFunctionCondition = ViewFunctionCondition;
  tabViewCondition = TabViewCondition;

  allCompanies: Etype[] = []
  filteredCompanies: Etype[] = []


  configForm = new UntypedFormGroup({
    selectedIndex: new UntypedFormControl(0)
  });

  constructor(
    @Inject('authService') private authService,
    private formBuilder: UntypedFormBuilder,
    protected routingService: RoutingService,
    public searchboardState: PaymentsSearchboardStateManager
  ) {
    this.configForm.controls.selectedIndex.valueChanges.subscribe(
      (next) => (this.selectedIndex = next)
    );
  }

  ngOnInit() {
    this.checkState();
    this.initForm();

    this.errorMessage = null
  }

  checkState() {
    this.subscriptions.push(this.searchboardState.getState$().subscribe((state: Searchboard) => {
      if (state) {
        if (this.localState == null) {
          this.localState = state;
        }


        if(!!state.searchWrap 
          && !!state.searchWrap.dialogueCompResponse
          && !!state.searchWrap.dialogueCompResponse.counterpartCompany
        ) {
          this.allCompanies = state.searchWrap.dialogueCompResponse.counterpartCompany
          this.filteredCompanies = this.allCompanies
        }

        if (state.errorMessage) {
          this.errorMessage = state.errorMessage;
        }
      }
    }))
  }

  private initForm(): void {
    this.years = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => 2024 + i);
    this.months = months.slice(0, currentMonth);

    this.searchForm = this.formBuilder.group({
      functionCode: new FormControl("", Validators.required),
      msgCode: "",
      company: "",
      companyCtr: "",
      month: "",
      year: "",
      startDate: null,
      endDate: null
    })
  }

  protected onYearChange(event: any) {
    const newValue = event.target.value;

    if (newValue == currentYear) {
      this.months = months.slice(0, currentMonth);
    } else if (newValue >= 2024 && newValue < currentYear) {
      this.months = months;
    } else {
      this.months = [];
    }
  }

  private goToValidation(): void {
    const searchPaymentsInputDTO: SearchPayments = this.populateSearchPaymentsInputDTO(SearchType.VALIDATION_CONSOLIDATION)
    this.searchboardState.searchPayments$(searchPaymentsInputDTO)
  }

  private goToPaymentManage(): void {
    const searchPaymentsInputDTO: SearchPayments = this.populateSearchPaymentsInputDTO(SearchType.PAYMENT_MANAGE)
    this.searchboardState.searchPayments$(searchPaymentsInputDTO)
  }

  private populateSearchPaymentsInputDTO(searchType: string): SearchPayments {
    let searchPaymentsInputDTO: SearchPayments = new SearchPayments()
    searchPaymentsInputDTO.searchType = searchType

    if (this.searchForm.get('functionCode').value) searchPaymentsInputDTO.functionCode = this.searchForm.get('functionCode').value
    if (this.searchForm.get('company').value) searchPaymentsInputDTO.company = this.searchForm.get('company').value
    if (this.searchForm.get('companyCtr').value) searchPaymentsInputDTO.companyCtr = this.searchForm.get('companyCtr').value
    if (this.searchForm.get('year').value) searchPaymentsInputDTO.year = this.searchForm.get('year').value

    const searchFormMonth: string = this.searchForm.get('month').value
    if (!!searchFormMonth) {
      const formattedMonth: string = searchFormMonth.padStart(2, '0')

      if (!!formattedMonth) {
        searchPaymentsInputDTO.month = formattedMonth
      }
    }

    if (this.searchForm.get('msgCode').value) searchPaymentsInputDTO.msgcode = this.searchForm.get('msgCode').value

    return searchPaymentsInputDTO
  }

  private goToForfait(): void {
    const codiceMessaggio: string = this.searchForm.get('msgCode').value

    const searchElement: SearchForfait = {
      codiceFunzione: this.searchForm.get('functionCode').value,
      codiceMessaggio: codiceMessaggio,
      dataInizioValidita: UtilityMethod.transform(this.searchForm.get('startDate').value),
      dataFineValidita: UtilityMethod.transform(this.searchForm.get('endDate').value)
    }

    this.searchboardState.searchForfait$(searchElement);
  }

  protected switchFunctions(selectedIndex: number): void {
    this.errorMessage = null

    if (this.searchForm.valid) {
      switch (selectedIndex) {
        case 0:
          this.goToValidation();
          break;
        case 1:
          this.goToPaymentManage();
          break;
        case 2:
          this.goToForfait();
          break;
        default:
          this.errorMessage = '_CLAIMS_._MESSAGE_._ERROR'
          break;
      }
    } else {
      if(!(!!this.searchForm.get('functionCode').value)){
        this.errorMessage = '_CLAIMS_._MANDATORY_FUNCTION_CODE'
      }
      
      if(!(!!this.searchForm.get('companyCtr').value) && this.selectedIndex == this.tabViewCondition.PAYMENT_MANAGE) {
        this.errorMessage = 'Compagnia controparte obbligatoria'
      }
    }
  }

  onSelectionChange(tabSelection: RgiRxTabSelectionChange) {
    this.configForm.controls.selectedIndex.patchValue(tabSelection.index, {
      emitEvent: false,
    })
    this.selectedIndex = this.configForm.controls.selectedIndex.value
    this.errorMessage = null
    this.searchForm.reset()
  }

  onChangeFunctionCode() {
    let functionCode = this.searchForm.controls.functionCode.value;

    this.filteredCompanies = this.allCompanies.filter(controparty => controparty.codeConv.includes(functionCode))
    
    this.selectedFunction = this.searchForm.valid
      ? this.localState.searchWrap.dialogueFunctionResponse.find(elem => elem.functionCode == functionCode)
      : null;

    this.searchForm.controls.msgCode.setValue(null);
  }

  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
        sub = null;
      }
    })
  }
}