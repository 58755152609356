import { LpcTranslationsKeys } from '../lpc-translations-keys'

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_GL_ES: LpcTranslationsKeys = {
  lpc_Investment_profiles: 'Perfís de investimento',
  lpc_payment_method: 'Método de pagamento',
  lpc_payment_type: 'Tipo de pagamento',
  lpc_investment_funds: 'Fondos de investimento',
  lpc_fund_type: 'Tipo de fondo',
  lpc_profile_type: 'Tipo de perfil',
  lpc_elements: 'elementos',
  lpc_fund_name: 'Nome do fondo',
  lpc_profile_name: 'Nome do perfil',
  lpc_profiles: 'Perfís',
  lpc_funds: 'Fondos',
  lpc_division_type: 'Tipo de división',
  lpc_division_description: 'Descrición da asignación',
  lpc_start_validity_date: 'Data do inicio da validez',
  lpc_fund_description: 'Descrición do fondo',
  lpc_ISIN_code: 'Código ISIN',
  lpc_Allocation: 'Asignación',
  lpc_profile_details: 'Detalles do perfil',
  lpc_fields_generic_error: '',
  lpc_portfolio_fund_display: 'Visualización de carteira de fondos de investimento',
  lpc_payment_data: 'Datos de pagamento',
  lpc_Warning_you_are_deleting_the_contractor: 'Aviso, estás eliminando a parte contratante. Quere seguir?',
  lpc_Investment_amount: 'Cantidade de investimento:',
  lpc_mov_status: 'Título de estado',
  lpc_mov_Amount: 'Importe do título',
  lpc_ticket_status_label: 'Valoración pendente das cotas',
  lpc_occurenceNumber: '',
  lpc_status_investment_label: 'Estado do investimento',
  lpc_order_status_label: 'Estado Orde',
  lpc_selected_funds: 'Fondos seleccionados',
  lpc_Premium: 'Prima',
  lpc_InvestmentByFund: 'Investimento por fondo',
  lpc_Loading: 'Taxas',
  lpc_NetPremium: 'Prima neta',
  lpc_IntFract: 'Fraccionamento de xuros',
  lpc_Fees: 'Cargos fixos',
  lpc_Taxes: 'Impostos',
  lpc_GrossPremium: 'Prima bruta',
  lpc_FirstInstallment: 'Primeira cota',
  lpc_AnnualPremium: 'Prima anual',
  lpc_NextInstallment: 'Seguinte cota',
  lpc_Guarantee: 'Garantía',
  lpc_BenefitOnDate: 'Beneficio na data',
  lpc_Benefit: 'Beneficio',
  lpc_AnnualSinglePremium: 'Prima anual/única',
  lpc_ActiveGuarantees: 'Garantías activas',
  lpc_Enhancement: 'Datos financeiros',
  lpc_CalculationData: 'Datos de cálculos',
  lpc_ControvaloreAllaData: 'Valor bolsista na data',
  lpc_AccumulationOfPrizes: 'Suma das primas',
  lpc_TotalSurrender: 'Total do rescate',
  lpc_AccumulationOfReducedPremiums: 'Suma das primas reducidas',
  lpc_TotalBalance: 'Balance total',
  lpc_EffectiveDate: 'Data de vixencia',
  lpc_Movement: 'Movemento',
  lpc_Movements: 'Movementos',
  lpc_booking_number: 'Números da reserva',
  lpc_booking_policyData: 'Datos da póliza',
  lpc_booking_assetData: 'Datos do ben asegurado',
  lpc_PolicyLinked: 'Pólizas vinculadas',
  lpc_Motivo_Legame: 'Motivación dos bonos',
  lpc_ProposalIssueDate: 'Data de emisión da proposta',
  lpc_PolicyIssueDate: 'Data de emisión da póliza',
  lpc_ExpirationDate: 'Data de caducidade',
  lpc_NextInstalmentDate: 'Data da seguinte cota',
  lpc_found_duplicate: 'Aviso',
  lpc_duplicate_subject_message: 'O suxeito seleccionado xa está incluído entre os beneficiarios',
  lpc_SelectSettlement: 'Elixa a liquidación',
  lpc_You_must_select_the_settlement_to_perform_the_variation: 'Debe seleccionar a liquidación para executar a variación',
  lpc_beneficiaryPercentage: 'Porcentaxe para o suxeito',
  lpc_beneficiaryPercentage_greater: 'Debe ser maior que',
  lpc_beneficiaryPercentage_lower: 'Debe ser menor que',
  lpc_beneficiaryDuplicate: 'Duplicar o suxeito',
  lpc_NoOperations: 'Non hai accións dispoñibles',
  lpc_confirm: 'Confirmar',
  lpc_description: 'Descrición',
  lpc_issueDate: 'Data de emisión',
  lpc_effectiveDate: 'Data de vixencia',
  lpc_MovementData: 'Cancelar apéndice',
  lpc_Are_you_sure_you_want_to_confirm_this_claim: 'Está seguro que quere confirmar este sinistro',
  lpc_Are_you_sure_you_want_to_cancel_this_claim: 'Está seguro que quere cancelar este sinistro?',
  lpc_warnings: 'Avisos',
  lpc_warning: 'Aviso',
  lpc_warning_message: 'Seleccione "PETICIÓN DE AUTORIZACIÓN" para enviar unha solicitude de aprobación para facer a operación',
  lpc_ClaimCanceled: 'O sinistro cancelouse con éxito',
  lpc_ClaimConfirmed: 'O sinistro confirmouse con éxito',
  lpc_reserve: 'gardar',
  lpc_questionnaire: 'Cuestionario',
  lpc_bonus_amount: 'Importe da bonificación',
  lpc_life_detail_beneficiary: 'Beneficiario',
  lpc_life_detail_percentage: 'Porcentaxe',
  lpc_life_detail_net_amount: 'Cantidade neta',
  lpc_life_detail_questionnaire: 'Cuestionario',
  lpc_life_detail_beneficiaries: 'Beneficiarios',
  lpc_AppendixNumber: 'Número do apéndice',
  lpc_nessuna_variazione: 'Sen variación',
  lpc_appendixLabel: 'Apéndice do beneficiario',
  lpc_operation_termination_reason: 'Razón da rescisión',
  lpc_Lien_type_creditor: 'Tipo de acredor',
  lpc_creditor: 'Acredor',
  lpc_Open_lien: 'Dereito de retención aberto',
  lpc_assignment_holder_type: 'Tipo de titular da asignación',
  lpc_assignment_holder: 'Titular da asignación',
  lpc_assignment_amount: 'Importe',
  lpc_assignment_policy_number: 'Número do contrato',
  lpc_assignment_company: 'Sociedade',
  lpc_assignment_type: 'Tipo de asignación',
  lpc_assignment_benefit: 'Beneficio',
  lpc_amount_less_of_benefit: 'O importe debe ser menor que o beneficio',
  lpc_open_assignment: 'Asignación aberta',
  lpc_sum_det_cancel_proposal: 'Cancelar a proposta',
  lpc_sum_det_success_cancel: 'A proposta cancelouse con éxito',
  lpc_sum_det_success_cancel_prev: 'O orzamento cancelouse con éxito',
  lpc_sum_det_confirm_cancel: 'Quere confirmar a cancelación da proposta?',
  lpc_sum_det_confirm_cancel_prev: 'Quere confirmar a cancelación deste orzamento?',
  lpc_sum_det_confirm_prop_issue: 'Emitirase o orzamento. Quere confirmar?',
  lpc_sum_det_confirm_pol_issue: 'Emitirase a póliza. Quere confirmar?',
  lpc_health_questionnaire: 'Cuestionario de saúde',
  lpc_quotation_show_details: 'Amosar información',
  lpc_quotation_premium_details: 'Información da prima',
  lpc_quotation_warranties_details: 'Información das garantías',
  lpc_quotation_commissions_details: 'Comisións',
  lpc_Reference_Third_Party: 'Terceiros de referencia',
  lpc_policyHolder: '',
  lpc_DelegateEqualToPolicyHolder: 'Delegado igual ao Asegurado?',
  lpc_LegalGuardianEqualToPolicyHolder: 'Representante legal igual ao Asegurado?',
  lpc_ThirdPayerEqualToPolicyHolder: 'Terceiro pagador igual ao Asegurado?',
  lpc_DelegateEqualToPolicyHolderWarning: 'Suxeito co rol de Delegado igual ao do Asegurado',
  lpc_LegalGuardianEqualToPolicyHolderWarning: 'Suxeito co rol de Representante legal igual ao do Asegurado',
  lpc_ThirdPayerEqualToPolicyHolderWarning: 'Suxeito co rol de Terceiro pagador igual ao do Asegurado',
  lpc_but_confirm_accept: 'Confirmar e aceptar',
  lpc_Date_Start_Variation: 'Data do inicio da variación',
  lpc_New_Frequent_Payment: 'Novo pagamento frecuente',
  lpc_start_date_profit: 'Data de inicio dos beneficios',
  lpc_end_date_profit: 'Data de fin dos beneficios',
  lpc_last_rate_profit: 'Última taxa de beneficios',
  lpc_last_profit: 'Últimos beneficios',
  lpc_Fund_Code: 'Código do fondo',
  lpc_OperationSynstesis: 'Síntese das operacións',
  lpc_PreviusPerceent: 'Porcentaxe previa',
  lpc_PreviusValue: 'Valor previo',
  lpc_NumberOfUnit: 'Número previo de unidade',
  lpc_PostPErcent: 'Porcentaxe do posto',
  lpc_PostValue: 'Valor do posto',
  lpc_PostNumberOfUnit: 'Número do posto do orzamento',
  lpc_Dati: 'Datos',
  lpc_PIP_Data: 'Datos Pip',
  lpc_PIP_General: 'Datos xerais',
  lpc_PIP_Contribution: 'Contribución Pip',
  lpc_HolderData: 'Datos asegurados',
  lpc_RiskData: 'Datos de risco',
  lpc_IBAN_error: 'O Iban non é correcto',
  lpc_BACK: 'Atrás',
  lpc_ACTIONS: 'Accións',
  lpc_DETAIL: 'Información',
  lpc_CANCEL: 'Cancelar a proposta',
  lpc_CONFIRM_PROPOSAL: 'Confirmar a proposta',
  lpc_CONFIRM_POLICY: 'Confirmar a póliza',
  not_linked_detail: 'Información de {{name}}',
  lpc_quote_number: 'Número de orzamento',
  lpc_valid_quote: 'Orzamento válido',
  lpc_BUY_PREV: 'Mercar',
  lpc_EDIT_PREV: 'Editar',
  lpc_EDIT: 'Editar',
  lpc_CANCEL_PREV: 'Suprimir',
  lpc_modify_quote: 'Modificar orzamento',
  lpc_buy_quote: 'Orzamento de compras',
  lpc_Close_assignment: 'Asignación pechada',
  lpc_Close_lien: 'Dereito de retención pechado',
  lpc_disinvestment_funds: 'Fondos de desinvestimento',
  lpc_error_mandatory_fields: 'Os campos en vermello son obrigatorios ou conteñen valores incorrectos',
  lpc_disinvestment_msg: '{{amount}} para desinvestir ou {{percent}}',
  lpc_disinvestment_allocation_msg: 'La asignación de inversión supera el importe disponible',
  lpc_maximum_percentage: 'Porcentaje máximo aceptado',
  lpc_maximum_amount: 'Cantidad máxima aceptada',
  lpc_minimum_percentage: 'Porcentaje mínimo aceptado',
  lpc_minimum_amount: 'Cantidad mínima aceptada',
  lpc_investment_msg: '{{amount}} para investir ou {{percent}}',
  lpc_Fields_marked_in_red_are_required: 'Os campos en vermello son obrigatorios',
  lpc_amount_format_validation: 'O importe non é válido, o separador decimal é incorrecto. Empregue "." para corrixilo',
  lpc_select_at_least_one_beneficiary: 'Seleccione polo menos un beneficiario',
  lpc_quote_due_date: 'Data da emisión do orzamento',
  lpc_roles_variation: 'Roles',
  lpc_fiscal_data: 'Datos fiscais',
  lpc_data_calc: 'Data de cálculo',
  lpc_tipo_liquidazione: 'Tipo de liquidación',
  lpc_valore_polizza: 'Valor da póliza',
  lpc_imponibile_imp: 'Imposto impoñible',
  lpc_imposta_base: 'Base impoñible',
  lpc_imposta_calcol: 'Imposto calculado',
  lpc_imposta_da_add: 'Imposto que debe aboarse',
  lpc_imposta_add: 'Imposto devindicado',
  lpc_imposta_bollo: 'Timbre fiscal',
  lpc_fiscal_data_detail: 'Información de datos fiscais',
  lpc_quote_white_list: 'Listaxe branca de orzamento',
  lpc_data_rif: 'Data de referencia',
  lpc_tipo_perc: 'Porcentaxe da lista branca',
  lpc_CancellInstallment: 'Resumo da solicitude de anulación da cota',
  lpc_DescOperazione: 'Descrición da operación',
  lpc_RateAmount: 'Importe total da cota',
  lpc_OPEN_LATEST_VERSION: 'Última versión aberta',
  lpc_TOTAL_INVESTED: 'Total investido',
  lpc_REMAINING_AMOUNT: 'Importe remanente',
  lpc_descrizione_rischio: 'Descrición dos riscos',
  lpc_prestazione_ridotta_iniz: 'Redución da prestación orixinal',
  lpc_prestazione_rivalutata: 'Redución da revalorización do beneficio',
  lpc_provvigioni_da_recuperare: 'Comisión que debe recuperarse',
  lpc_reg41: 'Nominativo co que comunicarse',
  lpc_disinvestment_amount: 'Importe do desinvestimento',
  lpc_occurrenceNumber: 'Número de incidencia',
  lpc_frequecy: 'Frecuencia',
  lpc_the_occurenceNumber_must_be_a_value: 'O número de incidencia debe ser maior que {{min}}',
  lpc_disinvestment_range: 'Insira un importe a desinvestir superior a {{max}} e inferior ou igual a {{min}}',
  lpc_state: 'Estado',
  lpc_substate: 'Subestado',
  lpc_product_type: 'Tipo de produto',
  lpc_managementnode: 'Nodo de xestión',
  lpc_subagency: 'Subaxencia',
  lpc_branch_name: 'Marca',
  lpc_agreement: 'Acordo',
  lpc_payment_frequency: 'Frecuencia de pagamento',
  lpc_mean_of_payment: 'Medio de pagamento',
  lpc_Subcause: 'Subcausa',
  lpc_premium_payment_suspension_date: 'Data de suspensión do pagamento da prima',
  lpc_imposta_sostitutiva: 'Imposto substitutivo',
  lpc_novazione_contr: 'Novación contractual',
  lpc_acc_premium_initial_2011: 'Acumulación da prima 31/12/2011 inicial',
  lpc_amount_intital_2011: 'Importe 31/12/2011 inicial',
  lpc_acc_premium_2011: 'Acumulación da prima 31/12/2011',
  lpc_amount_2011: 'Importe 31/12/2011',
  lpc_acc_premium_initial_2014: 'Acumulación da prima 30/06/2014 inicial',
  lpc_acc_premium_2014: 'Acumulación da prima 30/06/2014',
  lpc_amount_intital_2014: 'Importe 30/06/2014 inicial',
  lpc_amount_2014: 'Importe 30/06/2014',
  lpc_acc_premium_initial_2022: 'Suma inicial das primas o 31/12/2022',
  lpc_acc_premium_2022: 'Suma das primas o 31/12/2022',
  lpc_amount_intital_2022: 'Capital inicial e xuros o 31/12/2022',
  lpc_amount_2022: 'Capital e xuros o 31/12/2022',
  lpc_acc_premium_intial_tax_change: 'Cambio inicial na acumulación do imposto sobre as primas',
  lpc_amount_initial_tax_change: 'Importe inicial do cambio fiscal',
  lpc_acc_premium_tax_change: 'Cambio fiscal da acumulación da prima',
  lpc_amount_tax_change: 'Importe do cambio fiscal',
  lpc_regime_change_date: 'Data do cambio fiscal',
  lpc_please_enter_a_disinvestment_amount: 'Insira un importe de desinvestimento do fondo: {{fondo}}',
  lpc_please_select_at_least_a_disinvestment: 'Seleccione polo menos un desinvestimento',
  lpc_reg41Msg: 'Receptor das comunicacións',
  lpc_cost: 'Custo',
  lpc_paymentFrequencyInterest: 'Frec. Inter.',
  lpc_netpremium: 'Prima neta',
  lpc_grosspremium: 'Prima bruta',
  lpc_information: 'Información:',
  lpc_reinvest_data_detail: 'Información sobre os reinvestimentos',
  lpc_polizza: 'Póliza',
  lpc_data_effetto_mov: 'Data de vixencia',
  lpc_netto_liquidazione: 'Importe neto da liquidación',
  lpc_netto_reinvestire: 'Importe neto do reinvestimento',
  lpc_stato_reinvest: 'Estado da liquidación do reinvestimento',
  lpc_reinvested_settlements: 'Liquidacións reinvestidas',
  lpc_loans: 'Préstamos',
  lpc_elaboration_date: 'Data de elaboración',
  lpc_calculation_date: 'Data de cálculo',
  lpc_annuity_data: 'Información da renda vitalicia',
  lpc_extra_premium: 'Prima extra',
  lpc_loan_interest: 'Xuros do préstamo',
  lpc_fractionation_interest: 'Xuro de fraccionamento',
  lpc_default_interest: 'Xuro por falta de pagamento',
  lpc_reactivation_interest: 'Xuro de reactivación',
  lpc_installment_detail: 'Información da cota',
  lpc_effectiveAnnuityDate: 'Data de vixencia da anualidade',
  lpc_firstInstallmentDate: 'Data da primeira cota',
  lpc_expiryAnnuityDate: 'Data de vencemento da anualidade',
  lpc_amountFirstInstallment: 'Importe da primeira cota',
  lpc_initialAnnuityAmount: 'Importe inicial da anualidade',
  lpc_periodicityInstallmentAnnuity: 'Periodicidade da anualidade da cota',
  lpc_typePaymentAnnuity: 'Tipo de pagamento da anualidade',
  lpc_performanceType: 'Tipo de rendemento',
  lpc_annuityType: 'Tipo de anualidade',
  lpc_annuity_detail: 'Información da anualidade',
  lpc_canceled_liquidation_detail: 'Información sobre a cancelación da liquidación',
  lpc_Percentage: 'Porcentaxe',
  lpc_loan_data: 'Información sobre o préstamo',
  lpc_maxLoanAmount: 'Importe máximo pagadoiro',
  lpc_loanRepaymentProcedure: 'Procedemento de reembolso do préstamo',
  lpc_loanAmount: 'Importe do préstamo',
  lpc_rateType: 'Tipo de xuro do préstamo',
  lpc_maxLoanAmountKO: '0 € (erro no cálculo do importe máximo pagadoiro)',
  lpc_grant_date: 'Data da concesión',
  lpc_loan_amount: 'Importe do préstamo',
  lpc_return_way: 'Método da devolución',
  lpc_interest_rate_type: 'Tipo de xuro',
  lpc_residual_amount: 'Importe residual',
  lpc_refund_type: 'Tipo de reembolso',
  lpc_refund_amount: 'Importe do reembolso',
  lpc_select_lpc_refund_type: 'Seleccione un tipo de reembolso',
  lpc_leftInsuredAmount: 'Capital asegurado residual',
  lpc_insuredAmount: 'Importe investido',
  lpc_reactivatedCapital: 'Capital reactivado',
  lpc_premium_reactivation: 'reactivación_prima',
  lpc_negative_amount: 'Importe residual negativo',
  lpc_zero_amount: 'Non se atopa o importe residual. Insira un importe superior a 0',
  lpc_error_mandatory_selected_type_of_refund: 'Seleccione un tipo de reembolso',
  lpc_select_refund: 'Seleccione polo menos un préstamo',
  lpc_selected_profiles: 'Perfís seleccionados:',
  lpc_coupon_beneficiary: 'Beneficiario do cupón',
  lpc_bookings: 'Reservas',
  lpc_booking_type: 'Tipo de reservas',
  lpc_booking_state: 'Estados',
  lpc_booking_effective_date: 'Data de vixencia da reserva',
  lpc_booking_extrapremiumHealth: 'Prima extra de saúde',
  lpc_booking_extrapremiumProf: 'Prima extra profesional',
  lpc_booking_extrapremiumSport: 'Prima extra deportiva',
  lpc_booking_extrapremiumOther: 'Outra prima extra',
  lpc_booking_effectiveDate: 'Data de vixencia',
  lpc_booking_detail_label: '',
  lpc_Rischi: 'Coberturas',
  lpc_booking_destinationNode: 'Nodo de destino',
  lpc_booking_cause: 'Causa',
  lpc_PolicyData: 'Datos da póliza',
  lpc_booking_cancellationDate: 'Data de cancelación',
  lpc_extra_premiums: 'Primas extra',
  lpc_error_reservation_cancel: 'Erro na cancelación do {{descr}} número {{num}}',
  lpc_success_reservation_cancel: 'Confirmouse a cancelación do {{descr}} número {{num}}',
  lpc_incomplete_disinvestment_for_the_funds: 'Desinvestimento incompleto dos fondos',
  lpc_Sinistro_morte: 'Sinistro por falecemento',
  lpc_Sinistro_invalidita: 'Sinistro de invalidez',
  lpc_Sinistro_inabilità: 'Sinistro de incapacidade',
  lpc_Malattia_grave: 'Sinistro por enfermidade grave',
  lpc_insured_form: 'Formulario para o asegurado',
  lpc_other_contract_list: 'Outra lista de contratos',
  lpc_modified_personal_data_for_the_subject: 'Datos persoais modificados do suxeito',
  lpc_subject_roles: 'Roles do suxeito',
  lpc_no_variation_modpd: 'Non se realizaron cambios, modificar o suxeito',
  lpc_DeductiblePremium: 'Prima deducible',
  lpc_firstterm: 'Primeiro trimestre',
  lpc__1TERM_ATOOL: 'Data de activación da ferramenta',
  lpc__1TERM_MTOOL: 'Modificar a ferramenta de modificación',
  lpc__1TERM_DTOOL: 'Data de eliminación da ferramenta',
  lpc_management_fees: 'Comisións de xestión',
  lpc_investment_not_found: 'Non hai elementos para seleccionar',
  lpc_No_policies: 'Non hai pólizas para o suxeito modificado',
  lpc_variation_convention: 'Variación da convención',
  lpc_andamento_polizza_alla_data: 'Evolución da póliza ata a data',
  lpc_appendice_dichiarativa: 'Apéndice da declaración',
  lpc_policy_number_dot: 'N.º da póliza',
  lpc_investment_sum_must_be100: 'A suma dos investimentos debe ser igual ao 100 %',
  lpc_Operation_detail: 'Información das operacións',
  lpc_Notwithstanding_any_ongoing_operations: 'Os valores poden estar suxeitos a cambios por mor das operacións en curso',
  lpc_percentage_max_zero: 'Insira unha porcentaxe superior a 0',
  lpc_Mostra_tutti: 'Visualizar todo',
  lpc_Visualizza_dettaglio: 'Ver información',
  lpc_consultazione_storica: 'Consulta histórica',
  lpc_dettaglio_movimento: 'Información do movemento',
  lpc_mensile: 'mensual',
  lpc_annuale: 'anual',
  lpc_sixmonthly: 'semestral',
  lpc_trimestrale: 'cuadrimestral',
  lpc_Titolo_non_incassato: 'Existencias sen recoller',
  lpc_Rid: 'DDP',
  lpc_In_attesa_valorizzazione_quote: 'Valoración pendente das participacións investidas',
  lpc_switch_di_perequazione: 'Cambio programado',
  lpc_SELEZIONA: '--SELECCIONE--',
  lpc_settimanale: 'semanalmente',
  lpc_Valido: 'Válido',
  lpc_quotidiano: 'diario',
  lpc_Assegno: 'Verificar',
  lpc_Bonifico: 'Transferencia bancaria',
  lpc_Sdd: 'Orde de domiciliación bancaria',
  lpc_severeDisabilityMsg: 'Beneficiario con discapacidade grave',
  lpc_benDg: 'Beneficiario con discapacidade',
  lpc_distribution: 'Asignación',
  lpc_Distribution_amount_exceeds: 'O importe da distribución supera o importe total da liquidación dispoñible',
  lpc_distribution_invalid_greater: 'O {{tipo}} non é válido, debe ser superior a {{min.}}',
  lpc_distribution_invalid_greater_equal: 'O {{tipo}} non é válido: debe ser inferior ou igual a {{máx.}}',
  lpc_perc: 'Porcentaxe',
  lpc_amount: 'Importe',
  lpc_Please_insert_a_distribution: 'Insira unha distribución',
  lpc_For_the_Beneficiary: 'Para o Beneficiario',
  lpc_global_distribution: 'O importe total para liquidar debe ser inferior ou igual a {{totalAmount}}',
  lpc_invalid_char: 'O formato de {{tipo}} contén caracteres incorrectos',
  lpc_toDistribute: 'para distribuír',
  lpc_noClaims: 'Non se atoparon sinistros',
  lpc_Data_sinistro: 'Data do sinistro',
  lpc_Data_Pervenimento_Denuncia: 'Data na que se recibiu a notificación do sinistro',
  lpc_Data_Denuncia_Sinistro: 'Data da notificación do sinistro',
  lpc_Tipo_sinistro: 'Tipo de sinistro',
  lpc_inserisci_denuncia: 'Insira sinistro',
  lpc_Conferma_inserimento_denuncia: 'Confirmación da inserción do sinistro',
  lpc_Denuncia_inserita_correttamente: 'O sinistro inseriuse con éxito',
  lpc_Stato_denuncia: 'Estado do sinistro',
  lpc_marital_status: 'Estado civil',
  lpc_fiscal_code: 'Código fiscal',
  lpc_birthdate: 'Data de nacemento',
  lpc_address: 'Enderezo',
  lpc_sex: 'Sexo',
  lpc_annulla_denuncia: 'Cancelar o sinistro',
  lpc_modifica_denuncia: 'Modificar o sinistro',
  lpc_conferma_denuncia: 'Confirmar o sinistro',
  lpc_new_beneficiary: 'Novo beneficiario',
  lpc_Seleziona: 'Seleccionar',
  lpc_insert_phisical_sbj_for: 'Inserir un suxeito físico para o rol {{role}}',
  lpc_insert_legal_sbj_for: 'Insira un suxeito legal para o rol {{role}}',
  lpc_payment_incomplete: 'Información de pagamento incompleta',
  lpc_add_mandatory_linkedroles: 'Insira roles obrigatorios para o suxeito introducido',
  lpc_beneficiary_owner_recipient_must_be_physical: 'O beneficiario efectivo debe ser unha persoa física',
  lpc_enter_the_beneficial_owners_of_the_recipient: 'Insira os beneficiarios efectivos do destinatario',
  lpc_global_percentage_must_be_a_maximum_100: 'A porcentaxe global debe ser 100 como máximo',
  lpc_overall_percentage_must_equal100: 'A porcentaxe total debe ser igual a 100',
  lpc_Questionario_deve_essere_compilato: 'Debe cubrir o cuestionario',
  lpc_enter_mandatory_data: 'Insira a información obrigatoria',
  lpc_enter_an_adult: 'Insira un adulto',
  lpc_enter_a_beneficial_owner: 'Insira un beneficiario efectivo',
  lpc_enter_a_physical_beneficial_owner: 'Insira un beneficiario efectivo físico',
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: 'A porcentaxe global dos beneficiarios efectivos non debe superar os 100',
  lpc_beneficial_owners_percentages_must_be_greater_than0: 'A porcentaxe do beneficiario efectivo debe superar os 0,0',
  lpc_effective_holder_perc: '',
  lpc_effective_holder_duplicated: 'Titular efectivo duplicado',
  lpc_enter_the_minimum_number_of_subjects: 'Inserir o número mínimo dos suxeitos',
  lpc_percentage_must_be_greater_than_0: 'A porcentaxe debe superar o 0.0',
  lpc_enter_free_text: 'Inserir un texto libre',
  lpc_beneficiary_executor: 'Testamenteiro beneficiario',
  lpc_effective_recipient: 'Receptor efectivo',
  lpc_recipient_owner: 'Propietario receptor',
  lpc_beneficial_owner_of_beneficiary: 'Propietario beneficiario',
  lpc_confirmed_claim_modify: 'Modificación do sinistro confirmada',
  lpc_claim_modified_correctly: 'Sinistro modificado correctamente',
  lpc_Assicurato: 'Asegurado',
  lpc_Assicurati: 'Asegurados',
  lpc_holderType: 'Tipo de emprego do solicitante',
  lpc_ageRetirement: 'Idade no momento da xubilación',
  lpc_accessTransfer: 'Solicitante de traslado',
  lpc_emptyContribution: 'Contribución do empregador',
  lpc_firstEmployment: 'Data de inicio do emprego',
  lpc_oldUnderWritt: 'Antigo contratado',
  lpc_underWitt1993: 'Contratado o 29/4/1993',
  lpc_maturato2001: 'Devindicado a 31/12/2000',
  lpc_maturato2006: 'Devindicado a 31/12/2006',
  lpc_riduzImponibiTFR: 'Redución sobre a TGF impoñible',
  lpc_aliquotaTFR: 'Tipo TFR (fiscalidade anterior a 2001)',
  lpc_PIP_Contribution_Type: 'Tipo de contribución',
  lpc_YES: 'Si',
  lpc_PIP: 'Plan de pensións individual',
  lpc_PIP_Contribution_Percentage: 'Porcentaxe',
  lpc_PIP_Contribution_Amount: 'Importe',
  lpc_transfer_data: 'Información da transferencia',
  lpc_unit_questionnaire: 'Cuestionario da unidade',
  lpc_amount_reimbured: 'Importe para reembolsar',
  lpc_Descr_not_handled: 'Descrición non xestionada para este código de operación',
  lpc_select_transfer: 'Selección da transferencia',
  lpc_fiscalFolderDataPost2006: 'Sistema fiscal posterior a 2006',
  lpc_fiscalFolderData20012006: 'Sistema fiscal do 2001-2006',
  lpc_fiscalFolderDataBefore2001: 'Sistema fiscal anterior a 2001',
  lpc_filledOut: 'Cuberto',
  lpc_blank: 'Branco',
  lpc_desc_previousPenFund: 'Fondo de pensións anterior',
  lpc_companyPreviousPenFund: 'Nome da compañía anterior ao fondo de pensións',
  lpc_typePreviousPenFund: 'Tipo do fondo de pensións anterior',
  lpc_note: 'Notas',
  lpc_Benef_irrevocabile: 'Beneficiario irrevogable',
  lpc_PIP_Transfer: 'Transferencias doutro fondo',
  lpc_requestDate: 'Data de solicitude',
  lpc_Type_Previous_Pension_Fund: 'Tipo do fondo de pensións anterior',
  lpc_Previous_Pension_Fund: 'Fondo de pensións anterior',
  lpc_Import_Transfert: 'Importe da transferencia',
  lpc_Note: 'Notas',
  lpc_relief: 'Desgravacións fiscais do 2022',
  lpc_selected_settlement: 'Liquidación seleccionada',
  lpc_EDIT_ANONYMOUS_PREV: 'Editar',
  lpc_invalid_quote: 'Orzamento non válido',
  lpc_reasonForRequest: 'Motivo da solicitude',
  lpc_advancePaymentAmount: 'Importe do anticipo do pagamento',
  lpc_repaidAdvance: 'Anticipo reembolsado',
  lpc_PIP_AdvancedPayment: 'Anticipo dos pagamentos',
  lpc_requestReason: 'Motivo da solicitude',
  lpc_notRepaidAmount: 'Aínda non se reembolsou o importe',
  lpc_fiscalDate: 'Data de vixencia',
  lpc_previousPension: 'Plan de pensións anterior',
  lpc_fiscalCompetence: 'Competencia fiscal',
  lpc_settlementStatus: 'Estado do anticipo do pagamento',
  lpc_repaidAmount: 'Importe reembolsado',
  lpc_advancePaymentTransfer: 'Anticipos das transferencias',
  lpc_policy_factors: 'Factores das pólizas',
  lpc_asset_factors: 'Factores dos bens asegurados',
  lpc_Date_Variation: 'Liña temporal da variación',
  lpc_penalties_amount: 'Importe das penalizacións',
  lpc_tax_amount: 'Importe dos impostos',
  lpc_warranties_detail: 'Información das garantías',
  lpc_fixedCharges: 'Cargos fixos',
  lpc_catastrophe: 'Prima extra de catástrofes',
  lpc_health: 'Prima extra de saúde',
  lpc_other: 'Outra prima extra',
  lpc_professional: 'Prima extra profesional',
  lpc_sports: 'Prima extra deportiva',
  lpc_collectionFee: 'Taxa de cobramento',
  lpc_lambdaFee: 'Taxa de Lambda',
  lpc_managementFee: 'Taxa de xestión',
  lpc_purchaseFee: 'Taxa de compra',
  lpc_continue_button: 'Continuar',
  lpc_confirm_button: 'Confirmar',
  lpc_authorization_request: 'Solicitud de autorización',
  lpc_questionSTR_invalid: 'A resposta á pregunta: {{question}}, non é correcta',
  lpc_questionNUM_invalid: 'A cantidade ingresada para a pregunta: {{question}} non é correcta',
  lpc_Beneficiaryowner: 'Beneficiario propietario',
  lpc_confirm_operation: 'Confirmar operación',
  lpc_financial_questionnaire: 'Cuestionario financeiro',
  lpc_avc_questionnaire: 'Cuestionario AVC',
  lpc_confirm_variation: 'Confirmar variación',
  lpc_Declarative_Appendix: 'Apéndice declarativo',
  lpc_investment_funds_step: 'Fondos de investimento',
  lpc_disinvestment_profile_step: 'Perfís de desinvestimento',
  lpc_startDate: 'Data de inicio',
  lpc_end_date: 'Data de remate',
  lpc_six_monthly: 'Semestral',
  lpc_quarterly: 'Trimestral',
  lpc_weekly: 'Semanal',
  lpc_monthly: 'Mensual',
  lpc_annual: 'Anual',
  lpc_daily: 'Diario',
  lpc_start_date_cannot_be_earlier_than_the_effective_date: 'A data de inicio non pode ser anterior á data efectiva {{value}}',
  lpc_start_date_must_be_earlier_than_the_end_date: 'A data de inicio debe ser anterior á data de remate',
  lpc_contract_value: 'Valor do contrato',
  lpc_invested_amount: 'Cantidade investida',
  lpc_funds_details_and_summary: 'Detalles e resumo dos fondos',
  lpc_separate_management_detail: 'Detalle de xestión separada',
  lpc_denuncia_sinistro: 'Notificación de reclamación',
  lpc_duration_in: 'Duración en',
  lpc_years: 'anos',
  lpc_months: 'meses',
  lpc_days: 'días',
  lpc_policy_number: 'Número de póliza',
  lpc_proposal_number: 'Número de proposta',
  lpc_LifeBeneficiary: 'Beneficiario en vida',
  lpc_DeathBeneficiary: 'Beneficiario en caso de falecemento',
  lpc_NO: 'Non',
  lpc_claim_data: '',
  lpc_node: 'Nodo',
  lpc_sbj: 'Asunto',
  lpc_empty: 'baleiro',
  lpc_search_result: 'Resultados da busca',
  lpc_show_elemets: 'Amosar elementos',
  lpc_insured: 'Asegurado',
  lpc_notification_status: 'Estado da notificación',
  lpc_nuova_denuncia: 'nova notificación',
  lpc_insured_policy: 'Pólizas aseguradas',
  lpc_product: 'Produto',
  lpc_find: 'Buscar',
  lpc_status: 'Estado',
  lpc_fund_trend: 'Tendencia do fondo',
  lpc_category_fund: 'Perfil/Fondo',
  lpc_equivalent_on_date: 'Equivalente na data',
  lpc_quotes_number: 'Número de unidades',
  lpc_damages_policy: 'Póliza de danos',
  lpc_life_policy: 'Póliza de vida',
  lpc_next_installment_premium: 'Prima da próxima cota',
  lpc_no_result: 'Sen resultados',
  lpc_data_value: 'Data valor participación',
  lpc_paidAmount: 'Importe pagado',
  lpc_total_gross_surrender: 'Total da devolución bruta',
  lpc_left_premiums_paid: 'Primas pagadas restantes',
  lpc_left_premiums_amount: 'Importe das primas pagadas restantes',
  lpc_portfolio_volatility_on_subscription_date: 'Volatilidade da carteira na data de subscrición',
  lpc_portfolio_volatility: 'Volatilidade da carteira',
  lpc_andamento_controvalore: 'Evolución do valor nominal',
  lpc_policy_trend: 'Evolución da póliza',
  lpc_calculate: 'Calcular',
  lpc_frequency: 'Frecuencia',
  lpc_date_variation_step: 'Data da variación',
  lpc_quotation_step: 'Cotización',
  lpc_confirm_variation_step: 'Confirmar a variación',
  lpc_financial_questionnaire_step: 'Cuestionario financeiro',
  lpc_close_button: 'Pechar',
  lpc_Operation_of_executed_correctly: 'Operación {{operation}} realizada correctamente',
  lpc_AuthorizatioPolicyForwarded: 'A solicitude de autorización para o número de póliza {{policyNumber}} enviouse ao Usuario correspondente',
  lpc_are_you_sure_you_want_to_cancel_this_operation: 'Está seguro de que quere cancelar esta operación?',
  lpc_cancel_button: 'Cancelar',
  lpc_cancel_reservation: 'Cancelar a reserva',
  lpc_system_error: 'Erro do sistema',
  lpc_generic_error_title: 'Erro',
  lpc_select_label: 'Seleccionar',
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: 'Atención, está eliminando o titular da póliza. Quere continuar?',
  lpc_factor_must_be_equal: 'O {{factor}} debe ser igual a {{value}}',
  lpc_factor_must_be_greater_than: 'O {{factor}} debe ser superior a {{value}}',
  lpc_factor_must_be_less_than: 'O {{factor}} debe ser inferior a {{value}}',
  lpc_Please_enter_threshold_percentage_value: 'Fondo: {{value}} - Pregámoslle que insira a porcentaxe como limiar',
  lpc_please_enter_a_value_between: 'Pregámoslle que insira un valor entre {{min}} e {{max}}',
  lpc_variation_step_label: 'Variación',
  lpc_contractual_option: 'Opción contractual',
  lpc_costs_step_label: 'Custos',
  lpc_variation_detail_step_label: 'Detalle da variación',
  lpc_ongoing_operations: 'Os valores poderían estar suxeitos a cambios por mor de operacións en curso',
  lpc_exchange_rate: 'Tipo de cambio',
  lpc_last_share_value: 'Último valor da acción',
  lpc_movement_description: 'Descrición do movemento',
  lpc_settlement_amount: 'Importe do acordo',
  lpc_Annual_SinglePremium: 'Prima anual/única',
  lpc_policy_address: 'Enderezo da póliza',
  lpc_holders: 'Titulares',
  lpc_last_revaluation: 'Última revalorización',
  lpc_revalueted_benef: 'Beneficio revalorizado',
  lpc_investment: 'Investimentos',
  lpc_position_number: 'Número de posición',
  lpc_invested_premium: 'Prima investida',
  lpc_operation_details: 'Detalles da operación',
  lpc_contract: 'Contrato',
  lpc_dis_date: 'Data de desinvestimento',
  lpc_gross_amount: 'Importe bruto',
  lpc_net_amount: 'Importe neto',
  lpc_net: 'Neto',
  lpc_quote: 'Recalcular',
  lpc_quote_and_save: 'Recalcular e gardar',
  lpc_info_investment_complete: 'Información: Investimento completo',
  lpc_effective_holders: 'Titulares efectivos',
  lpc_effective_holder: 'Titular efectivo',
  lpc_third_party_role: 'Terceiro pagador',
  lpc_lien_credit: 'Motivo da rescisión',
  lpc_end_validity_date: 'Data de fin de validez',
  lpc_start_date: 'Data de inicio',
  lpc_unit_linked_value: 'Valor de unidade vinculada',
  lpc_allocation_profile: 'Perfil de asignación',
  lpc_search_filter: 'Filtro de busca',
  lpc_expiry_option_detail: 'Detalle de opción de vencemento',
  lpc_annuity_frequency: 'Frecuencia de anualidade',
  lpc_annuity_payment_type: 'Tipo de pagamento de anualidade',
  lpc_percentage_reversibility: 'Porcentaxe de reversibilidade',
  lpc_reversionary: 'Reversionario',
  lpc_certain_revenue_years: 'Anos certos de ingresos',
  lpc_postponement_type: 'Tipo de adiamento',
  lpc_postponement_years: 'Anos de adiamento',
  lpc_clauses: 'Cláusulas',
  lpc_active_contractual_option: 'Opcións contractuais activas',
  lpc_expiry_option: 'Opción de expiración',
  lpc_revaluation_history: 'Historial de revalorización',
  lpc_monetary_fund: 'Fondo monetario',
  lpc_benefit_effect_date: 'Data de efecto do beneficio',
  lpc_initial_benefit: 'Beneficio inicial',
  lpc_movement_update: 'Actualización de movemento',
  lpc_update_effect_date: 'Data de efecto da actualización',
  lpc_net_rate: 'Taxa neta',
  lpc_macro_category: 'Macro categoría',
  lpc_quotation_frequency: 'Frecuencia de cotización',
  lpc_volatility: 'Volatilidade',
  lpc_volatility_date: 'Data de volatilidade',
  lpc_start_quotation: 'Inicio de cotización',
  lpc_last_quotation: 'Última cotización',
  lpc_valuation: 'Valoración',
  lpc_percentage_total: 'Porcentaxe total',
  lpc_euro_valuation_not_fund_currency: 'A valoración exprésase en € e non na moeda do fondo',
  lpc_disinvstements: 'Desinvestimentos',
  lpc_payment_amount: 'Importe do pagamento',
  lpc_surrender_distribution: 'Distribución de rendición',
  lpc_formula: 'Fórmula',
  lpc_roles_connected_benef: 'Roles vinculados ao Beneficiario',
  lpc_quotation_details: 'Detalle de cotización',
  lpc_Disinvestmentsfunds: 'Fondos de desinvestimento',
  lpc_same_fund_distribution: 'Distribución de fondos idéntica ao depósito inicial',
  lpc_not_same_fund_distribution: 'Distribución de fondos modificada respecto ao depósito inicial',
  lpc_guarantees_oveview: 'Resumo de garantías',
  lpc_calculate_volatility: 'Calcular a volatilidade do activo',
  lpc_benef_death_not_same: 'O beneficiario por falecemento non pode ser o suxeito asegurado',
  lpc_global_percentage_equal_hundred: 'A porcentaxe global debe ser igual ao 100',
  lpc_effective_holder_natural_person_req: 'O suxeito {{linkedName}} no papel de titular real dun {{benefRole}} {{benefCat}} debe ser unha persoa física',
  lpc_benefPresente_default_msg: 'O suxeito xa foi seleccionado como beneficiario na mesma categoría.',
  lpc_effective_holder_duplicate: 'O suxeito {{linkedName}} xa foi seleccionado no papel de titular efectivo de {{benefName}}.',
  lpc_effective_holder_adult_req: 'O suxeito {{linkedName}} no papel de titular efectivo dun {{benefRole}} {{benefCat}} debe ser un adulto.',
  lpc_investment_plan: 'Plan de investimento',
  lpc_contract_detail: 'Detalle do contrato',
  lpc_variation_reason: 'Motivo da variación',
  lpc_investment_plan_detail: 'Detalle do plan de investimento',
  lpc_revaluation_rate: 'Taxa de revalorización',
  lpc_benefit_commencement_date: 'Data de inicio da prestación',
  lpc_update_effective_date: 'Data de efecto da actualización',
  lpc_last_odds_value: 'Último valor cota',
  lpc_total: 'Total',
  lpc__EFFOP: 'Data efectiva',
  lpc__OPZFR: 'Fraccionamento',
  lpc__OPZPM: 'Método de pagamento',
  lpc__OPBEN: 'Beneficiario',
  lpc__MINTS: 'Porcentaxe limiar mínimo',
  lpc__MAXTS: 'Porcentaxe limiar máximo',
  lpc__NUMOC: 'Número de ocorrencias',
  lpc__AMTOP: 'Importe do rescate',
  lpc__MALMT: 'Importe mínimo',
  lpc__CIBAN: 'IBAN',
  lpc__PCOUP: 'Porcentaxe de cupón periódico',
  lpc_threshold_percentage: 'Porcentaxe limiar',
  lpc_pure: 'Premio puro',
  lpc_starting_fund: 'Fondo inicial',
  lpc_details: 'Detalles',
  lpc_treshold: 'Limiar',
  lpc_inv_date: 'Data de investimento',
  lpc_beneficiary_perc_req: '{{benefCat}} {{benefRole}} {{benefName}}: Ingrese unha porcentaxe',
  lpc_beneficiary_perc_req_range: ' {{benefRole}} {{benefCat}} {{benefName}}: A porcentaxe debe estar comprendida entre 0.01 e 100',
  lpc_invalid_total_position_percent: 'A porcentaxe total para a agrupación "{{groupType}}" no Beneficiario {{cat}} debe ser do 100 %',
  lpc_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Ingrese un beneficiario do repositorio de partes',
  lpc_benefPresente: '{{benefName}} xa foi ingresado como {{benefCat}} {{benefRole}}',
  lpc_benMorteNoAssic: '{{benefName}} seleccionado como {{benefRole}} {{benefCat}} xa foi rexistrado como asegurado.',
  lpc_life_beneficiary: 'Beneficiario vida',
  lpc_death_beneficiary: 'Beneficiario morte',
  lpc_beneficiary_position_mandatory: 'Beneficiario {{benefCat}} {{benefName}}, seleccione un "Número de Posición"',
  lpc_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lpc_reversionary_subject: 'Suxeito reversionario',
  lpc_gross_detail: '',
  lpc_type_of_covers: '',
  lpc_clausesinsuredClause: 'Cláusulas do asegurado',
  lpc_clausespolicyClause: 'Cláusulas da póliza',
  lpc_clausesriskClause: 'Cláusulas do risco',
  lpc_max_capital_amount: 'IMC – Importe Máximo de Capital',
  lpc_fiscal_period_M1: 'M1 – Monto acumulado al 31/12/2000',
  lpc_fiscal_period_M2: 'M2 - Monto acumulado desde el 1/1/2001 hasta el 31/12/2006',
  lpc_fiscal_period_M3: 'M3 - Monto acumulado desde el 1/1/2007',
  lpc_fiscal_period_capital_request_K1: 'Parte de M1 solicitada en capital',
  lpc_fiscal_period_capital_request_K2: 'Parte de M2 solicitada en capital',
  lpc_fiscal_period_capital_request_K3: 'Parte de M3 solicitada en capital',
  lpc_max_amount_exceeded: 'El importe solicitado en capital supera el máximo posible.',
  lpc_max_amount_error: 'El importe en capital solicitado debe ser totalmente asignado a los montos M1 y M2.',
  lpc_performance_data: 'Datos de rendimient',
  lpc_role_is_mandatory: '{{role}} es obligatorio',
  lpc_issue_authorized_proposal: '',
  lpc_confirm_prop_pol_auth: 'Confirmar propuesta/política en Autorización',
  lpc_settlement: 'Liquidación',
  lpc_annuity: 'Renta vitalicia',
  lpc_percentage_in_capital: '% en capital',
  lpc_insured_amount: 'Importe invertido',
  lpc_custom_field_mandatory_error: 'El campo {{field}} es obligatorio',
  lpc_print_docs: 'Imprimir documentos',
  lpc_revisionary_err: 'Ingresar sujeto reversionario',
  lpc_revisionary_perc_err: 'Ingresar porcentaje del sujeto reversionario',
  lpc_percentage_must_be_between: 'El porcentaje debe estar entre 0.01 y 100',
  lpc_PIP_UndeductedPremiums: '',
  lpc_contributionYear: '',
  lpc_annualPremium: '',
  lpc_undeductedPremium : '',
  lpc_reversionario: "Reversionary",
  lpc_firstEnrollmentComplementaryPension: '',
  lpc_PIP_amountForTaxation: '',
  lpc_PIP_amount: '',
  lpc_PIP_contribempl4Perc: '',
  lpc_PIP_reducttaxbltfr: '',
  lpc_PIP_aliqtfr: '',
  lpc_PIP_premdeducted2001: '',
  lpc_PIP_premdeductoffset: '',
  lpc_PIP_annuitytaxdeduct: '',
  lpc_pip_contributions_installment_detail: 'ES_Contributions Details',
  lpc_contribution_type: 'ES_Contribution type',
  lpc_contribution_amount: 'ES_Amount',
  lpc_contribution_year: 'ES_Year',
  lpc_contribution_month_from: 'ES_Month (from)',
  lpc_contribution_month_to: 'ES_Month (to)',
  lpc_contribution_thirteenth: 'ES_13^',
  lpc_contribution_fourteenth: 'ES_14^',
  lpc_incomplete_fund_investment: 'Inversión incompleta para los fondos',
  lpc_incomplete_profile_investment: 'Inversión incompleta para los perfiles',
  lpc_tax_credit: '',
  lpc_clausesinsuredClauses: '',
  lpc_clausespolicyClauses: '',
  lpc_clausesriskClauses: '',
  lpc_not_linked_detail: '',
  lpc_modify_proposal: '',
  lpc_tariff_version: '',
  lpc_inbound_documents_mov: '',
};

export default LPC_GL_ES
