// Modules
export { ActivityListModule } from './lib/activity-list/activity-list.module';
export { ChatModule } from './lib/chat/chat.module';
export { TimelineModule } from './lib/timeline/timeline.module';
export { ClaimOpeningModule } from './lib/claim-opening/claim-opening.module';
export { ExternalPortfolioModule } from './lib/external-portfolio/external-portfolio.module';
export { NewPaymentModule } from './lib/new-payment/new-payment.module';
export { LimitsManagementModule } from './lib/limits-management/limits-management.module';
export { AutosoftBoardModule } from './lib/autosoft-board/autosoft-board.module';
export { AssistantModule } from './lib/assistant/assistant.module';
export { ExpertsModule } from './lib/experts/experts.module';
export { ClaimSubjectsModule } from './lib/claim-subjects/claim-subjects.module';
export { InvoiceModule } from './lib/invoice/invoice.module';
export { LetterTemplatesModule } from './lib/letter-templates/letter-templates.module';
export { AccountingTransactionsModule } from './lib/accounting-transactions/accounting-transactions.module';
export { ExternalPortfolioExtrainfoModule } from './lib/external-portfolio/components/external-portfolio-extrainfo/external-portfolio-extrainfo.module';
export { ClaimsElasticModule } from './lib/claims-elastic/claims-elastic.module';
export { ClaimLimitedDetailModule } from './lib/claim-limited-detail/claim-limited-detail.module';
export { UpdateReserveModule } from './lib/update-reserve/update-reserve.module';
export { CompensationListModule } from './lib/compensation-list/compensation-list.module';
export { OccurrenceDataModifyModule } from './lib/occurrence-data-modify/occurrence-data-modify.module';
export { ClaimQuestionnaireModule } from './lib/claim-questionnaire/claim-questionnaire.module';
export { DialogueHandlerModule } from './lib/gedi/dialogue-handler/dialogue-handler.module';
export { FivaModule } from './lib/fiva/fiva.module';
export { ElaborationRequestModule } from './lib/claims-connector/elaboration-request/elaboration-request.module';
export { CaiOpeningModule } from './lib/cai-opening/cai-opening.module';
export { ClaimDocumentsModule } from './lib/claim-documents/claim-documents.module';
export { ReassignTaskModule } from './lib/reassign-task/reassign-task.module';
export { PaymentsDashboardModule } from './lib/tirea/payments-dashboard/payments-dashboard.module';
export { DscComponentModule } from './lib/shared/dsc-component.module';

// Components
export { ActivityListComponent } from './lib/activity-list/activity-list.component';
export { ChatComponent } from './lib/chat/chat.component';
export { TimelineComponent } from './lib/timeline/timeline.component';
export { ClaimOpeningComponent } from './lib/claim-opening/claim-opening.component';
export { ExternalPortfolioComponent } from './lib/external-portfolio/external-portfolio.component';
export { NewPaymentComponent } from './lib/new-payment/new-payment.component';
export { LimitsManagementComponent } from './lib/limits-management/limits-management.component';
export { AutosoftBoardComponent } from './lib/autosoft-board/autosoft-board.component';
export { AssistantComponent } from './lib/assistant/assistant.component';
export { ExpertsComponent } from './lib/experts/experts.component';
export { ReassignTaskComponent } from './lib/reassign-task/reassign-task.component';
export { ClaimSubjectsComponent } from './lib/claim-subjects/claim-subjects.component';
export { InvoiceComponent } from './lib/invoice/invoice.component';
export { AccountingTransactionsComponent } from './lib/accounting-transactions/accounting-transactions.component';
export { ClaimsElasticComponent } from './lib/claims-elastic/claims-elastic.component';
export { ExternalPortfolioExtrainfoComponent } from './lib//external-portfolio/components/external-portfolio-extrainfo/external-portfolio-extrainfo.component';
export { PaymentListComponent } from './lib/new-payment/payment-list/payment-list.component';
export { CompensationListComponent } from './lib/compensation-list/compensation-list.component';
export { ActivityMenuComponent } from './lib/activity-list/components/activity-menu/activity-menu.component';
export { ClaimOpeningDetailsComponent } from './lib/claim-opening/components/claim-opening-details/claim-opening-details.component';
export { ClaimOpeningPolicySearchComponent } from './lib/claim-opening/components/claim-opening-policy-search/claim-opening-policy-search.component';
export { ClaimOpeningPolicyResultsComponent } from './lib/claim-opening/components/claim-opening-policy-results/claim-opening-policy-results.component';
export { ClaimOpeningPolicyDetailsComponent } from './lib/claim-opening/components/claim-opening-policy-details/claim-opening-policy-details.component';
export { InjuryAssetDetailComponent } from './lib/claim-opening/components/claim-opening-details/party-data-panel-content/injury-asset-detail/injury-asset-detail.component';
export { ExternalPortfolioListComponent } from './lib/external-portfolio/components/external-portfolio-list/external-portfolio-list.component';
export { ExternalPortfolioSubjectsComponent } from './lib/external-portfolio/components/external-portfolio-subjects/external-portfolio-subjects.component';
export { ExternalPortfolioDetailsComponent } from './lib/external-portfolio/components/external-portfolio-details/external-portfolio-details.component';
export { ExternalPortfolioSearchComponent } from './lib/external-portfolio/components/external-portfolio-search/external-portfolio-search.component';
export { ExternalPortfolioNewComponent } from './lib/external-portfolio/components/external-portfolio-new/external-portfolio-new.component';
export { LetterTemplatesComponent } from './lib/letter-templates/letter-templates.component';
export { ClaimLimitedDetailComponent } from './lib/claim-limited-detail/claim-limited-detail.component';
export { OccurrenceDataModifyComponent } from './lib/occurrence-data-modify/occurrence-data-modify.component';
export { ClaimQuestionnaireEditDataComponent } from './lib/claim-questionnaire/claim-questionnaire-edit-module/claim-questionnaire-edit-data.component';
export { ClaimQuestionnaireComponent } from './lib/claim-questionnaire/claim-questionnaire/claim-questionnaire.component';
export { ElaborationRequestSearchComponent } from './lib/claims-connector/elaboration-request/elaboration-request-search/elaboration-request-search.component';
export { ElaborationRequestComponent } from './lib/claims-connector/elaboration-request/elaboration-request.component';
export { ElaborationRequestDetailComponent } from './lib/claims-connector/elaboration-request/elaboration-request-single-page/elaboration-request-detail/elaboration-request-detail.component';
export { ElaborationRequestListComponent } from './lib/claims-connector/elaboration-request/elaboration-request-single-page/elaboration-request-list/elaboration-request-list.component';
export { NewElaborationRequestComponent } from './lib/claims-connector/elaboration-request/elaboration-request-single-page/new-elaboration-request/new-elaboration-request.component';
export { ElaborationRequestSinglePageComponent } from './lib/claims-connector/elaboration-request/elaboration-request-single-page/elaboration-request-single-page.component';
export { CaiOpeningComponent } from './lib/cai-opening/cai-opening.component';
export { ClaimDocumentsComponent } from './lib/claim-documents/claim-documents.component';
export { NewDialogueComponent } from './lib/gedi/dialogue-handler/new-dialogue/new-dialogue.component';
export { DialogueSummaryComponent } from './lib/gedi/dialogue-handler/dialogue-summary/dialogue-summary.component';
export { DialogueHandlerListComponent } from './lib/gedi/dialogue-handler/dialogue-handler-list/dialogue-handler-list.component';
export { DialogueHandlerSearchComponent } from './lib/gedi/dialogue-handler/dialogue-handler-search/dialogue-handler-search.component';
export { PaymentsSearchboardComponent } from './lib/tirea/payments-dashboard/components/payments-searchboard/payments-searchboard.component';
export { TableValidationConsolidationComponent } from './lib/tirea/payments-dashboard/components/table-validation-consolidation/table-validation-consolidation.component';
export { TableForfaitComponent } from './lib/tirea/payments-dashboard/components/table-forfait/table-forfait.component';
export { ModalForfaitComponent } from './lib/tirea/payments-dashboard/components/table-forfait/modal-forfait/modal-forfait.component';
export { AddEditForfaitComponent } from './lib/tirea/payments-dashboard/components/table-forfait/add-edit-forfait/add-edit-forfait.component';

export { DscAdditionalDataComponent } from './lib/shared/dsc-additional-data/dsc-additional-data.component';
export { DscGeneralDetailComponent } from './lib/shared/dsc-general-detail/dsc-general-detail.component';
export { DscAddressDetailComponent } from './lib/shared/dsc-address-detail-data/dsc-address-detail.component';
export { DscInjuryCodeComponent } from './lib/shared/dsc-injury-code/dsc-injury-code.component';
export { DscIncidentDataComponent } from './lib/shared/dsc-incident-data/dsc-incident-data.component';
export { DscBaremsModalComponent } from './lib/shared/dsc-circumstances-data/dsc-barems-modal/dsc-barems-modal.component';
export { DscCircumstancesDataComponent } from './lib/shared/dsc-circumstances-data/dsc-circumstances-data.component';
export { DscPolicyDetailComponent } from './lib/shared/dsc-policy-list/dsc-policy-detail/dsc-policy-detail.component';
export { DscPolicyListComponent } from './lib/shared/dsc-policy-list/dsc-policy-list.component';
export { DscPolicyHolderComponent } from './lib/shared/dsc-policy-holder/dsc-policy-holder.component';
export { DscVehicleComponent } from './lib/shared/dsc-vehicle/dsc-vehicle.component';
export { DscInsuranceCompanyComponent } from './lib/shared/dsc-insurance-company/dsc-insurance-company.component';
export { DscOwnerComponent } from './lib/shared/dsc-owner/dsc-owner.component';
export { DscDriverLicenseModalComponent } from './lib/shared/dsc-driver/dsc-driver-license-modal/dsc-driver-license-modal.component';
export { DscDriverComponent } from './lib/shared/dsc-driver/dsc-driver.component';
export { DscDamagedPartsModalComponent } from './lib/shared/dsc-damaged-parts/dsc-damaged-parts-modal/dsc-damaged-parts-modal.component';
export { DscDamagedPartsComponent } from './lib/shared/dsc-damaged-parts/dsc-damaged-parts.component';
export { DscCarBodyworkModalComponent } from './lib/shared/dsc-car-bodywork/dsc-car-bodywork-modal/dsc-car-bodywork-modal.component';
export { DscCarBodyworkComponent } from './lib/shared/dsc-car-bodywork/dsc-car-bodywork.component';
export { DscSimilarClaimsComponent } from './lib/shared/dsc-similar-claims/dsc-similar-claims.component';
export { SubjectActionComponent } from './lib/shared/subject/subject-action/subject-action.component';
export { SubjectFormComponent } from './lib/shared/subject/subject-form/subject-form.component';
export { SubjectInfoComponent } from './lib/shared/subject/subject-info/subject-info.component';
export { DscPhisicalInjuryComponent } from './lib/shared/dsc-phisical-injury/dsc-phisical-injury.component';
export { SubjectAddMinorGuardianComponent } from './lib/shared/subject/subject-add-minor-guardian/subject-add-minor-guardian.component';
export { DscInjuryAssetDetailComponent } from './lib/shared/dsc-injury-code/dsc-injury-asset-detail/dsc-injury-asset-detail.component';
export { DscCarBodyworkTableComponent } from './lib/shared/dsc-car-bodywork/dsc-car-bodywork-modal/dsc-car-bodywork-table/dsc-car-bodywork-table.component';
export { DscHeirComponent } from './lib/shared/dsc-heir/dsc-heir.component';
export { GlobalModalConfirmComponent } from './lib/shared/modal/global-modal-confirm/global-modal-confirm.component';
export { FivaPlateCtpDetailComponent } from './lib/fiva/fiva-plate-ctp-detail/fiva-plate-ctp-detail.component';
export { FivaPlateCtpDetailModalComponent } from './lib/fiva/fiva-plate-ctp-detail-modal/fiva-plate-ctp-detail.modal.component';


// Services
export { DamageCategoryService } from './lib/claim-opening/damage-category.service';
export { DscAdditionalDataService } from './lib/shared/dsc-additional-data/dsc-additional-data-service/dsc-additional-data.service'
export { OccurrenceDataModifyState } from './lib/occurrence-data-modify/state-service/occurrence-data-modify.state.manager';

// Dto
export { DamageCategory } from './lib/claim-opening/components/claim-opening-policy-search/dto/damage-categories';
export { UpdateReserveComponent } from './lib/update-reserve/update-reserve.component';
export { FeeDataModalComponent } from './lib/new-payment/fee-data-modal/fee-data-modal.component';

// Model
export { AdditionalData } from './lib/shared/dsc-additional-data/dsc-additional-data.model';
export { Extension } from './lib/shared/dsc-additional-data/dsc-additional-data.model';
export { ExtensionProperty } from './lib/shared/dsc-additional-data/dsc-additional-data.model';

//routes
export { OCCURRENCE_DATA_ROUTES } from './lib/occurrence-data-modify/router-occurrence';


export { NewClaim } from './lib/cai-opening/config-model/cai-opening-claims.module';
export { CaiOpeningState } from './lib/cai-opening/config-model/cai-opening-config.module';
export { CaiOpeningClaimUtility } from './lib/cai-opening/state-service/utility/cai-opening-claim.utility';

export { ExternalPolicy } from './lib/external-portfolio/dto/external-policy';
export { ClaimOpeningDetailsService } from './lib/claim-opening/components/claim-opening-details/claim-opening-details.service';
export { ClaimOpeningPolicySearchResultsService } from './lib/claim-opening/components/claim-opening-policy-results/claim-opening-policy-results.service';
export { ClaimOpeningService } from './lib/claim-opening/claim-opening.service';
