<div class="container-fluid">
    <rgi-rx-datatable [sortable]="false" [data]="this.tableData" [schema]="schema" [sortHeader]="true" [selectable]="false"
        [pageOptions]="[5,10,20]" [expansionRow]="rowDetail" [expansionModel]="customExpansionModel">
        
        <ng-template rgiRxDataTableCell="codFunction" let-ctx>
            <div *ngIf="ctx.row?.codFunction">
                <span>{{ ctx.row?.codFunction?.code }} </span>
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="companyCodeUs" let-ctx>
            <div *ngIf="ctx.row?.companyCodeUs">
                <span>{{ ctx.row?.companyCodeUs?.descr }} </span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="role" let-ctx>
            <div *ngIf="ctx.row?.role">
                <span>{{ ctx.row?.role?.descr }} </span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="state" let-ctx>
            <div *ngIf="ctx.row?.state">
                <span>{{ ctx.row?.state?.descr }} </span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="companyCodeCnt" let-ctx>
            <div *ngIf="ctx.row?.companyCodeCnt">
                <span>{{ ctx.row?.companyCodeCnt?.code }}</span>              
            </div>
        </ng-template>

        <ng-template rgiRxDataTableCell="forced" let-ctx>
            <div>
                <rgi-rx-form-field>
                    <input 
                        type="checkbox" 
                        [(ngModel)]="ctx.row.forced"
                        (ngModelChange)="setForzatura(ctx.row)"
                        [disabled]="isDisabledForzatura(ctx.row)"
                        rgiRxInput
                    >
                </rgi-rx-form-field>
            </div>
        </ng-template>
        
        <ng-template rgiRxDataTableCell="skipped" let-ctx>
            <div>
                <rgi-rx-form-field>
                    <input 
                        type="checkbox" 
                        [(ngModel)]="ctx.row.skipped"
                        (ngModelChange)="setSkip(ctx.row)"
                        [disabled]="isDisabledSkip(ctx.row)"
                        rgiRxInput
                    >
                </rgi-rx-form-field>
            </div>
        </ng-template>
    </rgi-rx-datatable>

    <ng-template #rowDetail let-ctx>
        <div class="payment-accordion-container1">
            <div class="payment-accordion-element" *ngFor="let key1 of this.accordionKeysRow1">
                <span class="payment-accordion-title">{{ "_CLAIMS_._PAYMENTS._" + key1.toUpperCase() | translate }}</span>
                <br><span class="payment-accordion-body">{{ this.accordionRow1[key1] }}</span>
            </div>
        </div>

        <div class="payment-accordion-container2">
            <div class="payment-accordion-element" *ngFor="let key2 of this.accordionKeysRow2">
                <span class="payment-accordion-title">{{ "_CLAIMS_._PAYMENTS._" + key2.toUpperCase() | translate }}</span>
                <br><span class="payment-accordion-body">{{ this.accordionRow2[key2] }}</span>
            </div>
        </div>

        <div class="payment-accordion-container3">
            <div class="payment-accordion-element" *ngFor="let key3 of this.accordionKeysRow3">
                <span class="payment-accordion-title">{{ "_CLAIMS_._PAYMENTS._" + key3.toUpperCase() | translate }}</span>
                <br><span class="payment-accordion-body">{{ this.accordionRow3[key3] }}</span>
            </div>
        </div>
    </ng-template>

    <div>
        <br><p class="totalsDetails">{{ "_CLAIMS_._PAYMENTS._TOTALRECORD" | translate }}: <b>{{ this.totaleRecord }}</b></p>
        <p class="totalsDetails" *ngIf="this.localState.searchPaymentsInputDTO.searchType != 'G'">{{ "_CLAIMS_._PAYMENTS._TOTALRECORDKO" | translate }}: <b>{{ this.totaleRecordKO }}</b></p>
        <p class="totalsDetails" *ngIf="this.localState.searchPaymentsInputDTO.searchType != 'G'">{{ "_CLAIMS_._PAYMENTS._TOTALRECORDOK" | translate }}: <b>{{ this.totaleRecordOK }}</b></p>
        <p class="totalsDetails">{{ "_CLAIMS_._PAYMENTS._TOTALAMOUNTPAYMENTS" | translate }}: <b>{{ this.importoTotalePagamenti }}</b></p>
        <p class="totalsDetails">{{ "_CLAIMS_._PAYMENTS._TOTALAMOUNTRECOVERED" | translate }}: <b>{{ this.importoTotaleRecuperi }}</b></p>
    </div>

    <div class="btn-group btn-group-justified" *ngIf="this.localState.searchPaymentsInputDTO.searchType != 'G'">
        <div class="btn-group">
            <button  class="btn btn-warning pull-right" (click)="this.valida()" [disabled]="this.isValida">
                {{ '_CLAIMS_._VALIDATE' | translate}}
            </button>
        </div>
        <div class="btn-group">
            <button class="btn btn-warning pull-right" (click)="this.consolida()" [disabled]="this.isConsolida">
                {{ '_CLAIMS_._CONSOLIDATE' | translate }}
            </button>
        </div>
        <div class="btn-group" *ngIf="false">
            <button class="btn btn-warning pull-right" (click)="this.bilancio()" [disabled]="this.isBilancio">
                {{ '_CLAIMS_._BALANCE' | translate }}
            </button>
        </div>
    </div>
</div>