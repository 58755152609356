import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { ReactiveFormsModule } from '@angular/forms';
import { DatepickerPaymentComponent } from './datepicker-payment.component';
import { RgiRxDatePickerModule, RgiRxFormElementsModule } from '@rgi/rx/ui';


@NgModule({
    declarations: [DatepickerPaymentComponent],
    imports: [
        CommonModule,
        PortalNgCoreModule,
        ReactiveFormsModule,
        RgiRxDatePickerModule,
        RgiRxFormElementsModule
    ],
    providers: [],
    exports: [DatepickerPaymentComponent]
})
export class DatepickerPaymentModule { }
