import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Document, Documents} from '../../resources/model/api/document';
import {Observable} from "rxjs";

@Component({
  selector: 'rgi-pnc-psales-pnc-documents',
  templateUrl: './pnc-documents.component.html',
  styleUrls: ['./pnc-documents.component.css'],
})
/**
* @deprecated The component will be removed in the future
*/

export class PncDocumentsComponent {
  @Input() documents: Documents;
  @Output() actionDownload = new EventEmitter<string>();
  canDownload:boolean;
  documentSelected: Document;

  downloadDocument(documentInfo: Document): void {
    this.emitActionDownload(documentInfo, true);
  }

  openDocument(documentInfo: Document): void {
    this.emitActionDownload(documentInfo, false);
  }

  emitActionDownload(documentInfo: Document, isDownload): void {
    this.canDownload = isDownload;
    this.documentSelected = documentInfo;
    this.actionDownload.emit(documentInfo.documentCode);
  }

  download(obs: Observable<Blob>){
    obs.subscribe(res =>{
      const a = document.createElement('a');
      const newBlob = new Blob([res], {type: this.documentSelected.contentType.description});
      let fileUrl = URL.createObjectURL(newBlob);
      a.href = fileUrl;
      if(this.canDownload) {
        a.download = `${this.documentSelected.documentName}.${this.documentSelected.extension}`;
      } else {
        a.target = '_blank';
      }
      a.click();
      setTimeout(function(){
        window.URL.revokeObjectURL(fileUrl);
      }, 100);
    });
  }
}
