import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AnswerFlatI, AnswerType, QuestionFlatI, QuestionnaireFlatI } from '@rgi/ng-passpro';
import { Questionnaire, SurveyVersionService } from '@rgi/questionnaires-manager';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyVersionLifeService extends SurveyVersionService {

  constructor(
    _datePipe: DatePipe,
    protected translationWrapper: TranslationWrapperService
  ) {
    super(_datePipe);
  }

  override makeSurveyVersion(questionnaire: Questionnaire, questFlat: QuestionnaireFlatI) {

    questFlat.description = this.translationWrapper.getImmediate(questFlat.description);
    questFlat.questions = questFlat.questions.map(quest => {
      this.mappingTerms(quest);
      quest.section.name = this.translationWrapper.getImmediate(quest.section.name);
      quest.text = this.getTranslationByKey(quest.text);
      this.iterOnAnswers(quest);
      return quest;
    });
    return super.makeSurveyVersion(questionnaire, questFlat);
  }

  private getTranslationByKey(key: string): string {
    if (key) {
      if (!key.includes('\\"') && key.includes('"')) {
        key = key.replace(/"/g, '\\"');
      }
      return this.cleanString(this.translationWrapper.getImmediate(`["${key}"]`));
    }
  }

  private cleanString(input: string): string {
    return input.replace(/\\+"/g, '"') 
                .replace(/\["\["/g, '')
                .replace(/"]\]/g, '')
                .replace(/\["/g, '')
                .replace(/"]/g, '');
  }

  protected iterOnAnswers(quest: QuestionFlatI) {
    quest.answers = quest.answers.map(answer => {
      this.translateAnswerValues(answer);
      return answer;
    });
  }

  protected translateAnswerValues(answer: AnswerFlatI) {
    answer.values = answer.values.map(value => {
      value.description = this.getTranslationByKey(value.description); 
      return value;
    });
  }

  protected mappingTerms(quest: QuestionFlatI) {
    quest.conditions = quest.conditions.map(condition => {
      condition.terms.map(term => {
        if (term.type === AnswerType.STRING || term.type === AnswerType.LIST) {
          term.value = this.translationWrapper.getImmediate(term.value);
          return term;
        }
      });
      return condition;
    });
  }

}
