<form [formGroup]="homeForm" (submit)="findParty()" *ngIf="stateMgr.getState$() | async as state"
      class="rgi-anag-home-form">
  <div [ngClass]="{'rgi-ui-panel-content': state.isModalFlow}">
    <rgi-rx-tab-group (onTabSelectionChange)="onSelectionChange($event)" [selectedIndex]="0">
      <rgi-rx-tab label="{{'_ANAG_._LABEL_._PARTY_MANAGEMENT_' | translate}}" [formGroupName]="'searchPartyForm'"
                  *ngIf="isFieldVisible(FIELD_ANAG_TAB_PARTY_MANAGEMENT)">
        <ng-template rgiRxTabContent>
          <div class="party-type-info" *ngIf="state.isModalFlow && state.flowData && state.flowData.partyType">
            <span class="rgi-ui-icon-info"></span>
            <span translate>{{
                state.flowData.partyType === '1' ?
                  '_ANAG_._MSG_._INFO_NATURAL_PERSON_ALLOWED_' : '_ANAG_._MSG_._INFO_LEGAL_ENTITY_ALLOWED_'
              }}</span>
          </div>

          <rgi-rx-form-field *ngIf="!state.isModalFlow">
            <label rgiRxLabel translate>_ANAG_._LABEL_._SALE_POINT_</label>
            <select rgiRxNativeSelect formControlName="node" data-qa="rgi-anag-node">
              <option value=""></option>
              <option *ngFor="let node of state.nodes" [value]="node.code">{{ node.description }}</option>
            </select>
          </rgi-rx-form-field>

          <rgi-rx-drop-container
            *ngIf="!state.isModalFlow" formControlName="subjectSelected"
            label="{{'_ANAG_._LABEL_._PARTY_' | translate}}"
            [select]="'searchOrCreateSubject'" viewField="nominative" [selectData]="anagDropContainerSelectData"
            data-qa="anag-select-party-drop">
          </rgi-rx-drop-container>
          <br>
          <div *ngIf="state.isMultiCompagnia">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._COMPANY_</label>
              <select rgiRxNativeSelect formControlName="company" name="company">
                <option></option>
                <option *ngFor="let company of stateMgr.companies"
                        [ngValue]="company">
                  {{ company?.description }}
                </option>
              </select>
            </rgi-rx-form-field>
          </div>
          <rgi-rx-form-field *ngIf="state.isModalFlow || isFieldVisible(FIELD_ANAG_SURNAME)">
            <input rgiRxInput formControlName="surname" data-qa="rgi-anag-surname">
            <label rgiRxLabel translate>_ANAG_._LABEL_._SURNAMEORCOMPANY_</label>
          </rgi-rx-form-field>
          <rgi-rx-form-field *ngIf="state.isModalFlow || isFieldVisible(FIELD_ANAG_NAME)">
            <input rgiRxInput formControlName="name" data-qa="rgi-anag-name">
            <label rgiRxLabel translate>_ANAG_._LABEL_._NAME_</label>
          </rgi-rx-form-field>
          <rgi-rx-form-field *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_COWORKER)">
            <input rgiRxInput formControlName="coworker" data-qa="rgi-anag-coworker">
            <label rgiRxLabel translate>_ANAG_._LABEL_._COWORKER_</label>
          </rgi-rx-form-field>

          <ng-container
            *ngIf="!state.isUmaPartyKeyEnable && (state.isModalFlow || isFieldVisible(FIELD_ANAG_UNIQUE_KEY))">
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="fiscalCode" data-qa="rgi-anag-fiscal-code">
              <label rgiRxLabel translate>_ANAG_._LABEL_._FISCAL_CODE_</label>
            </rgi-rx-form-field>
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="vatNumber" data-qa="rgi-anag-vat-number">
              <label rgiRxLabel translate>_ANAG_._LABEL_._VAT_NUMBER_</label>
            </rgi-rx-form-field>
          </ng-container>

          <rgi-rx-form-field *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_BIRTH_NAME)">
            <input rgiRxInput formControlName="birthName" data-qa="rgi-anag-birth-name">
            <label rgiRxLabel translate>_ANAG_._LABEL_._BIRTH_SURNAME_</label>
          </rgi-rx-form-field>
            <rgi-rx-form-field style="display: block" *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_BIRTH_DATE)">
            <label rgiRxLabel><span>{{ '_ANAG_._LABEL_._BIRTH_DATE_' | translate }}</span></label>
            <div style="display:flex;">
              <input [rgiRxDateTimeInput]="birthDatePicker"
                     style="flex: 1"
                     data-qa="rgi-anag-expiration-date"
                     formControlName="birthDate"
                     selectMode="single"
                     rangeSeparator="-"
                     placeholder="" #inputToTriggerbirthDate="rgiRxDateTimeInput">
              <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                      [rgiRxDateTimeTrigger]="inputToTriggerbirthDate"></button>
            </div>
            <rgi-rx-date-time #birthDatePicker pickerType="calendar"
                              [hour12Timer]="true"></rgi-rx-date-time>
          </rgi-rx-form-field>

          <div class="rgi-ui-grid-3" *ngIf="!state.isModalFlow">
            <div class="rgi-ui-col-3" *ngIf="isFieldVisible(FIELD_ANAG_INTERNATIONAL_PREFIX)">
              <rgi-rx-form-field>
                <select rgiRxNativeSelect data-qa="anag-phone-number-int-prefix" [formControlName]="'internationalPrefix'">
                  <option selected value="">{{'_ANAG_._LABEL_._INT_PREFIX_' | translate}}</option>
                  <option *ngFor="let prefix of internationalPrefixes"
                          [value]="prefix.code">{{prefix.code + ' - ' + prefix.description}}</option>
                </select>
                <label rgiRxLabel translate>_ANAG_._LABEL_._INT_PREFIX_</label>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col-3" *ngIf="isFieldVisible(FIELD_ANAG_LOCAL_PREFIX)">
              <rgi-rx-form-field>
                <input rgiRxInput formControlName="localPrefix" data-qa="rgi-anag-local-prefix">
                <label rgiRxLabel translate>_ANAG_._LABEL_._PREFIX_</label>
              </rgi-rx-form-field>
            </div>
            <rgi-rx-form-field [ngClass]="{'rgi-ui-col-6': twoPrefixVisible(), 'rgi-ui-col-9': onePrefixVisible()}"
                               *ngIf="isFieldVisible(FIELD_ANAG_PHONE)">
              <input rgiRxInput formControlName="phoneNumber" data-qa="rgi-anag-phone-number">
              <label rgiRxLabel translate>_ANAG_._LABEL_._PHONE_NO_</label>
            </rgi-rx-form-field>
          </div>

          <rgi-rx-form-field *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_EMAIL)">
            <input rgiRxInput formControlName="email" data-qa="rgi-anag-email">
            <label rgiRxLabel translate>_ANAG_._LABEL_._EMAIL_</label>
          </rgi-rx-form-field>

          <ng-container
            *ngIf="state.isUmaPartyKeyEnable && stateMgr.countriesList && (state.isModalFlow || isFieldVisible(FIELD_ANAG_UNIQUE_KEY))">
            <div class="rgi-anag-form-section" translate>_ANAG_._LABEL_._SUBJECT_UNIQUE_KEY_</div>
            <div class="rgi-anag-form-grouper">
              <div class="rgi-ui-form-group">
                <label class="rgi-ui-form-control-label" translate>_ANAG_._LABEL_._NATION_</label>
                <div class="rgi-ui-select-container rgi-anag-w-100">
                  <select class="rgi-ui-form-control rgi-anag-w-100" formControlName="country"
                          data-qa="rgi-anag-nation" (change)="resetKeyFields()">
                    <option *ngFor="let country of stateMgr.countriesList"
                            [value]="country.abbreviation">{{ country.description }}
                    </option>
                  </select>
                </div>
              </div>
              <div *ngIf="state.partyKey">
                <div class="rgi-ui-form-group" *ngIf="state.partyKey.key1">
                  <label class="rgi-ui-form-control-label">{{ state.partyKey.key1.label }}</label>
                  <input type="text" class="rgi-ui-form-control"
                         formControlName="partyKey1" (keydown)="removeSpecialCharacters('partyKey1', $event)"
                         data-qa="rgi-anag-party-key1">
                </div>
                <div class="rgi-ui-form-group" *ngIf="state.partyKey.key2">
                  <label class="rgi-ui-form-control-label">{{ state.partyKey.key2.label }}</label>
                  <input type="text" class="rgi-ui-form-control"
                         formControlName="partyKey2" (keydown)="removeSpecialCharacters('partyKey2', $event)"
                         data-qa="rgi-anag-party-key2">
                </div>
                <div class="rgi-ui-form-group" *ngIf="state.partyKey.key3">
                  <label class="rgi-ui-form-control-label">{{ state.partyKey.key3.label }}</label>
                  <input type="text" class="rgi-ui-form-control"
                         formControlName="partyKey3" (keydown)="removeSpecialCharacters('partyKey3', $event)"
                         data-qa="rgi-anag-party-key3">
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_RESIDENCE)">
            <br>
            <div class="rgi-ui-panel-header rgi-anag-form-section" translate>_ANAG_._LABEL_._RESIDENCE_</div>
            <div class="rgi-anag-form-grouper">
              <rgi-anag-uma-address data-qa="anag-address-search" [addressType]="CORE_ADDRESS_FUNCTION.subjectFinder"
                                    [inputAddress]="residenceAddress" (outputAddress)="updateResidenceSearch($event)"
                                    [reloadEvent]="reloadResidenceEvent"
                                    [isQuote]="anagDropContainerSelectData.isQuote">
              </rgi-anag-uma-address>
            </div>
            <br>
          </ng-container>
          <rgi-rx-form-field *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_CLIENT_NUMBER)">
            <input rgiRxInput formControlName="clientNumber" data-qa="rgi-anag-client-number">
            <label rgiRxLabel translate>_ANAG_._LABEL_._CLIENT_NUMBER_</label>
          </rgi-rx-form-field>
          <rgi-rx-form-field
            *ngIf="!state.isModalFlow && state.isUmaPartyNumberEnable && isFieldVisible(FIELD_ANAG_PARTY_NUMBER)">
            <input rgiRxInput formControlName="partyNumber" data-qa="rgi-anag-party-number">
            <label rgiRxLabel translate>_ANAG_._LABEL_._PARTY_NUMBER_</label>
          </rgi-rx-form-field>

          <rgi-rx-form-field *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_PARTY_TYPE)">
            <label rgiRxLabel translate>_ANAG_._LABEL_._PARTY_TYPE_</label>
            <select rgiRxNativeSelect formControlName="partyType" data-qa="rgi-anag-party-type">
              <option value=""></option>
              <option *ngFor="let partyType of stateMgr.partyTypes"
                      [value]="partyType.codice">{{ partyType.descrizione }}
              </option>
            </select>
          </rgi-rx-form-field>

          <ng-container *ngIf="!state.isModalFlow && isFieldVisible(FIELD_ANAG_SUBSYSTEM)">
            <rgi-rx-form-field>
              <label rgiRxLabel translate>_ANAG_._LABEL_._SUBSYSTEM_</label>
              <select rgiRxNativeSelect formControlName="subsystemCode" data-qa="rgi-anag-subsystem-code">
                <option value=""></option>
                <option *ngFor="let subsystem of stateMgr.subsystemList | async"
                        [value]="subsystem.codice">{{ subsystem.descrizione }}
                </option>
              </select>
            </rgi-rx-form-field>
            <rgi-rx-form-field>
              <input rgiRxInput formControlName="subsystemIdentifier" data-qa="rgi-anag-subsystem-identifier">
              <label rgiRxLabel translate>_ANAG_._LABEL_._SUBSYSTEM_PERSON_ID_</label>
            </rgi-rx-form-field>
          </ng-container>
        </ng-template>
      </rgi-rx-tab>
      <rgi-rx-tab label="{{'_ANAG_._LABEL_._PARTY_MERGER_' | translate}}" [formGroupName]="'partyMergerForm'"
                  *ngIf="isFieldVisible(FIELD_ANAG_TAB_ACCORPAMENTO) && isPartyGrouping">
        <ng-template rgiRxTabContent>
          <rgi-rx-form-field *ngIf="isFieldVisible(FIELD_ANAG_CRITERIA)">
            <label rgiRxLabel translate>_ANAG_._LABEL_._CRITERIA_</label>
            <select rgiRxNativeSelect formControlName="criteria" data-qa="rgi-anag-criteria">
              <option *ngFor="let criteria of stateMgr.criteriaList | async"
                      [value]="criteria.codice">{{ criteria.descrizione }}
              </option>
            </select>
          </rgi-rx-form-field>
          <rgi-rx-form-field *ngIf="isFieldVisible(FIELD_ANAG_STATE)">
            <label rgiRxLabel translate>_ANAG_._LABEL_._STATUS_</label>
            <select rgiRxNativeSelect formControlName="status" data-qa="rgi-anag-status">
              <option *ngFor="let status of stateMgr.statusList"
                      [value]="status.code">{{ status.description | translate }}
              </option>
            </select>
          </rgi-rx-form-field>
        </ng-template>
      </rgi-rx-tab>
    </rgi-rx-tab-group>
    <div class="rgi-ui-snackbar rgi-ui-default" *ngIf="partyNotFound">
      <span class="rgi-ui-icon-snackbar rgi-ui-icon-warning"></span>
      <span class="rgi-ui-message" data-qa="partyNotFoundMsg" translate>_ANAG_._MSG_._NO_RESULTS_</span>
    </div>
    <rgi-rx-snackbar tag="error-tag"></rgi-rx-snackbar>
    <div class="rgi-ui-snackbar rgi-ui-danger" *ngIf="state.serviceError" data-qa="rgi-anag-httpError">
      <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
      <span class="rgi-ui-message" translate>{{ state.serviceError }}</span>
    </div>
  </div>
  <div class="rgi-ui-panel-footer" [ngClass]="{'rgi-anag-tm-1': !state.isModalFlow}">
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="emptyForm()"
            data-qa="anag-btn-empty" translate>_ANAG_._BTN_._EMPTY_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="submit" *ngIf="!partyNotFound"
            data-qa="anag-btn-find" translate>_ANAG_._BTN_._FIND_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="createNewParty()" *ngIf="isBtnNewVisible()"
            data-qa="anag-btn-new-party" translate>_ANAG_._BTN_._NEW_
    </button>
  </div>
</form>
