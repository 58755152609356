<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate> Risk Certificate </span>
        </h3>
      </div>
      <div class="modal-body">
        <form [formGroup]="riskCertificateForm">
          <br>
          <div class="form-group row">
            <div class="col-sm-6 col-xs-12">
              <pnc-datepicker [customFormGroup]="riskCertificateForm" customFormControlName="policyExpirationDate"
                              data-qa="policyExpirationDate" id="policyExpirationDate"
                              label="{{'Policy Expiration Date' | translate}}" placeholder="dd/mm/aaaa">
                <pnc-required required="true"></pnc-required>
              </pnc-datepicker>
            </div>
            <div class="col-sm-6 col-xs-12">
              <label translate>Rate of Origin:</label>
              <pnc-required required="true"></pnc-required>
              <select #originRate (change)="onRateChange()" [compareWith]="compareGenericEntities" class="select-nativa"
                      formControlName="originRate" name="originRate">
                <option></option>
                <option *ngFor="let rate of originRates; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="rate">
                  {{rate?.description}}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
          </div>
          <div *ngIf="riskCertificateData?.originUniqueClassVisible" class="form-group row">
            <div class="col-sm-6 col-xs-12">
              <label translate>Unique Class of Origin:</label>
              <select #uniqueClassFrom [compareWith]="compareGenericEntities" class="select-nativa" formControlName="uniqueClassFrom"
                      name="uniqueClassFrom">
                <option></option>
                <option *ngFor="let originCU of originCUs; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="originCU">
                  {{originCU?.description}}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
            <div class="col-sm-6 col-xs-12">
              <label translate>Assigned Unique Class:</label>
              <pnc-required required="true"></pnc-required>
              <select #uniqueClassTo [compareWith]="compareGenericEntities" class="select-nativa" formControlName="uniqueClassTo"
                      name="uniqueClassTo">
                <option></option>
                <option *ngFor="let assignedCU of assignedCUs; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="assignedCU">
                  {{assignedCU?.description}}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-6 col-xs-12">
              <label translate>Company of Origin:</label>
              <pnc-required required="true"></pnc-required>
              <select #companyOfOrigin [compareWith]="compareGenericEntities" class="select-nativa" formControlName="companyOfOrigin"
                      name="companyOfOrigin">
                <option></option>
                <option *ngFor="let company of companies; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="company">
                  {{company?.description}}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
            <div class="col-sm-6 col-xs-12">
              <label translate>Policy Number:</label>
              <input class="form-control mic-input" formControlName="policyNumber" type="text">
            </div>
          </div>
          <div class="form-group row">
            <div *ngIf="riskCertificateData.showPejus" class="col-sm-6 col-xs-12">
              <label translate>Pejus</label>
              <pnc-required required="true"></pnc-required>
              <select #pejus [compareWith]="compareGenericEntities" class="select-nativa" formControlName="pejus"
                      name="pejus">
                <option></option>
                <option *ngFor="let percentage of pejusPercentages; index as i; trackBy: genericEntitiesTrackByFn"
                        [ngValue]="percentage">
                  {{percentage?.description}}
                </option>
              </select>
              <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
            </div>
            <div *ngIf="riskCertificateData.showDeductibles" class="col-sm-6 col-xs-12">
              <label translate>Number of unpaid deductibles:</label>
              <pnc-required required="true"></pnc-required>
              <input class="form-control mic-input" formControlName="deductiblesNumber" type="text">
            </div>
            <div *ngIf="riskCertificateData.showDeductibles" class="col-sm-6 col-xs-12">
              <label translate>Amount of unpaid deductibles:</label>
              <pnc-required required="true"></pnc-required>
              <input class="form-control mic-input" formControlName="deductiblesAmount" type="text">
            </div>
          </div>

          <br>


          <!-- ATR TABLE ----------------------------------------------------------------------------->
          <!-- Risck certificate with last year = null -->
          <table *ngIf="riskCertificateData.showATRTable
                && !showCompleteCertificate" class="nmf_cont_atr_edit">
            <thead>
            <tr>
              <th></th>
              <th *ngFor="let year of riskCertificateData.years; let i = index">
                <div *ngIf="i < riskCertificateData.years.length -1">-</div>
                <div *ngIf="i === riskCertificateData.years.length -1">
                  <input (change)="onChangeLastYear()" [readonly]="!riskCertificateForm.controls.policyExpirationDate.value" class="form-control mic-input"
                         formControlName="lastYear"
                         type="text">
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td data-label="Title" translate>Paid with majority responsibility</td>
              <td *ngFor="let cell of riskCertificateData.principalRespTotalAmounts" class="nmf_add_td_padding"
                  data-label="anno">==
              </td>
            </tr>
            <tr class="nmf_oddCol">
              <td data-label="Title" translate>Paid with equal responsibility</td>
              <td *ngFor="let cell of riskCertificateData.pairRespTotalAmounts" class="nmf_add_td_padding"
                  data-label="anno">==
              </td>
            </tr>
            </tbody>
          </table>

          <!-- Risck certificate with last year valued -->
          <table *ngIf="riskCertificateData.showATRTable
              && showCompleteCertificate" class="nmf_cont_atr_edit">
            <thead>
            <tr>
              <th></th>
              <th *ngFor="let year of riskCertificateData.years; let i = index">
                <div *ngIf="i < riskCertificateData.years.length -1
                           && riskCertificateForm.controls.lastYear.value">{{ year }}</div>
                <div *ngIf="i === riskCertificateData.years.length -1">
                  <input (change)="onChangeLastYear()" [readonly]="!riskCertificateForm.controls.policyExpirationDate.value" class="form-control mic-input"
                         formControlName="lastYear"
                         type="text">
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td data-label="Title" translate>Paid with majority responsibility</td>
              <td *ngFor="let cell of riskCertificateData.principalRespTotalAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input [formControlName]="cell?.id" [id]="cell?.id" class="form-control mic-input" type="text">
              </td>
            </tr>
            <tr>
              <td class="nmf_add_td_padding" data-label="Title" translate>of which person</td>
              <td *ngFor="let cell of riskCertificateData.principalRespPeopleAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input (change)="recalculateTotalClaims(cell?.year, cell?.type, i)" [formControlName]="cell?.id" [id]="cell?.id" class="form-control mic-input"
                       type="text">
              </td>
            </tr>
            <tr>
              <td class="nmf_add_td_padding" data-label="Title" translate>of which things</td>
              <td *ngFor="let cell of riskCertificateData.principalRespThingsAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input (change)="recalculateTotalClaims(cell?.year, cell?.type, i)" [formControlName]="cell?.id" [id]="cell?.id" class="form-control mic-input"
                       type="text">
              </td>
            </tr>
            <tr>
              <td class="nmf_add_td_padding" data-label="Title" translate>of which (person + things)</td>
              <td *ngFor="let cell of riskCertificateData.principalRespMixedAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input (change)="recalculateTotalClaims(cell?.year, cell?.type, i)" [formControlName]="cell?.id" [id]="cell?.id" class="form-control mic-input"
                       type="text">
              </td>
            </tr>
            <tr class="nmf_oddCol">
              <td data-label="Title" translate>Paid with equal responsibility</td>
              <td *ngFor="let cell of riskCertificateData.pairRespTotalAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input [formControlName]="cell?.id" [id]="cell?.id" class="form-control mic-input" type="text">
              </td>
            </tr>
            <tr class="nmf_oddRow">
              <td class="nmf_add_td_padding" data-label="Title" translate>of which person</td>
              <td *ngFor="let cell of riskCertificateData.pairRespPeopleAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input (change)="recalculateTotalClaims(cell?.year, cell?.type, i)" [formControlName]="cell?.id" [id]="cell.id" class="form-control mic-input"
                       type="text">
              </td>
            </tr>
            <tr class="nmf_oddCol">
              <td class="nmf_add_td_padding" data-label="Title" translate>of which things</td>
              <td *ngFor="let cell of riskCertificateData.pairRespThingsAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input (change)="recalculateTotalClaims(cell?.year, cell?.type, i)" [formControlName]="cell?.id" [id]="cell?.id" class="form-control mic-input"
                       type="text">
              </td>
            </tr>
            <tr class="nmf_oddRow">
              <td class="nmf_add_td_padding" data-label="Title" translate>of which (person + things)</td>
              <td *ngFor="let cell of riskCertificateData.pairRespMixedAmounts; index as i; trackBy: riskCertificateCellsTrackByFn"
                  data-label="anno">
                <input (change)="recalculateTotalClaims(cell?.year, cell?.type, i)" [formControlName]="cell?.id" [id]="cell?.id" class="form-control mic-input"
                       type="text">
            </tr>
            <tr class="nmf_tr_button">
              <td>&nbsp;</td>
              <td *ngFor="let year of riskCertificateData.years; index as i" data-label="">
                <button (click)="fillCellsWithValue(year, 'N.A.', i)" class="btn btn-primary" type="button">N.A.
                </button>
            </tr>
            <tr class="nmf_tr_button">
              <td>&nbsp;</td>
              <td *ngFor="let year of riskCertificateData.years; index as i" data-label="">
                <button (click)="fillCellsWithValue(year, 'N.D.', i)" class="btn btn-primary" type="button">N.D.
                </button>
            </tr>
            <tr class="nmf_tr_button">
              <td>&nbsp;</td>
              <td *ngFor="let year of riskCertificateData.years; index as i" data-label="">
                <button (click)="fillCellsWithValue(year, '0', i)" class="btn btn-primary" type="button">0</button>
            </tr>
            </tbody>
          </table>

        </form>
        <div *ngIf="(validationMessages && validationMessages?.length > 0)" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>

      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-risk-certificate-modal-cancel" translate>
              CANCEL
            </button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" data-qa="btn-risk-certificate-modal-confirm" translate>
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
