export class AccordionRow1 {
    lotto: string = ''
    sequence: string = ''
    registerType: string = ''
    roleDlg: string = ''
    lossDate: string = ''
    settlementDate: string = ''
}

export class AccordionRow2 {
    sendingFlowDate: string = ''
    companyCodeCnt: string = ''
    paymentCollection: string = ''
    error: string = ''
}

export class AccordionRow3 {
    settlementNumber: string = ''
    paveeDlg: string = ''
}