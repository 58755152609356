import { IiabTranslationsKeys } from './iiab-translations-keys';

export const IIAB_EN: IiabTranslationsKeys = {
  _IIAB_: {
    _MOTOR_: {
      _NICE_ACTIMIZE_KO_: 'Nice Actimize is currently not available. Please re-open the authorization request at a later time to retrigger the screening of the proposal parties.'
    },
    _OTP_: {
      _OTP_SERVICE_KO: 'The OTP service is not available. Please try again later.',
      _INITIATED_SIGNING_PROCESS_: 'Initiated signing process',
      _TITLE_: 'Sign documents',
      _DIGITAL_PRIVACY_CONSENT_NOT_ACCEPTED_NOT_SIGNED_: 'The digital sign privacy consent is not accepted. It will not be possible to digital sign documents.',
      _MOBILE_NOT_PRESENT_NOT_SIGNED_: 'The mobile number is not present. It will not be possible to digital sign documents.',
      _EMAIL_NOT_PRESENT_NOT_SIGNED_: 'The email address is not present. It will not be possible to digital sign documents.',
      _DOCUMENT_DATA_NOT_PRESENT_NOT_SIGNED_: 'Some document data is missing. It will not be possible to digital sign documents.',
      _GENERIC_NOT_SIGNED_ERROR_: 'It will not be possible to digital sign documents.',
      _OTP_DOCUMENTS_: 'Digital signature - Documents',
      _CONSENSUSES_AND_CONTACTS_: 'Consensuses and contacts',
      _OTP_DATA_MISSING_: 'Some mandatory data missing. It will not be possible to digital sign documents.',
      _OTP_DOCUMENTS_MISSING_: 'No digital documents available for this movement',
      _BTN_: {
        _SIGN_DOCS_: 'Sign documents',
      },
      _HOLDERS_: 'Holders',
      _SUBSCRIBER_: 'Subscriber',
      _MOBILE_PHONE_: 'Mobile phone'
    },
    _LABEL_: {
      _DESCRIPTION_: 'Description',
      _VALUE_: 'Value',
      _RELEASE_DATE_: 'Release Date',
      _EXPIRATION_DATE_: 'Expiration Date',
      _INT_PRIFIX_: 'Int. Prifix',
      _NUMBER_: 'Number'
    },
    _ANAG_: {
      _TAX_ID_DIFFERENT_FROM_DOCUMENT_ID_: 'The identity document (NIF/NIE/Passport) reported in the Documents section does not match the identity document (NIF/NIE/Passport) reported in the Identification Data section'
    }
  },
  _LS_TITLE_TOTAL_SUMMARY_: 'Total summary',
  _NICE_ACTIMIZE_: {
    _HEAD_HIT: 'Nice Actimize Hits',
    _HEAD_NOMINATIVE: 'Nominative',
    _HEAD_SURNAME: 'Surname',
    _HEAD_NAME: 'Name',
    _HEAD_DATE_OF_BIRTH: 'Date of birth',
    _HEAD_RESULT: 'Result',
    _HEAD_TYPE_OF_HIT: 'Type of hit',
    _HEAD_REQUEST_ID: 'Request ID',
    _HIT_FOUND: 'Hit identified',
    _HIT_NOT_FOUND: 'Hit not identified',
    _HIT_YES: 'The party has got hits. A further check on Nice Actimize must be carried out',
    _HIT_NO: 'The party has got no hits. No risk has been detected on the party'
  },
  _COUNTERPROPOSAL_: 'Counterproposal',
  _ADJUST_PROPOSAL_: 'Adjust proposal',
  _IIAB_ERR_UPLOAD_MANDATORY_DOC_: 'Please upload the mandatory documents'
};
