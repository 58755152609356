<form [formGroup]="keyDataForm" (submit)="manageConfirm()" *ngIf="isVisible && stateMgr.getState$() | async as state">
  <div class="rgi-ui-panel-content">
    <div class="rgi-ui-grid-2-sm-2 rgi-ui-grid-2-md-2">
      <!-- GENERAL DATA -->
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._SALE_POINT_</label>
          <select rgiRxNativeSelect formControlName="salePoint" data-qa="salePoint" [disabled]="isNodeFitx">
            <option  value="{{this.nodeCodeFitSx && isNodeFitx ? this.nodeCodeFitSx: ''}}">{{this.nodeDescriptionFitSx && isNodeFitx  ? this.nodeDescriptionFitSx: ''}}</option>
            <ng-container *ngIf="state.nodes.length < 1000">
              <option *ngFor="let node of state.nodes" [value]="node.code">{{node.description}}</option>
            </ng-container>
            <ng-container *ngIf="currentNode">
              <option [value]="currentNode.code">{{currentNode.description}}</option>
            </ng-container>
          </select>
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._NATION_</label>
          <select rgiRxNativeSelect formControlName="nation" data-qa="country"  [disabled]="isDisabledPartyKeyNation">
            <option *ngFor="let country of stateMgr.countriesList"
                    [value]="country.abbreviation">{{country.description}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <div class="rgi-ui-col">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._PERSON_TYPE_</label>
          <select rgiRxNativeSelect formControlName="personType" data-qa="person-type">
            <option *ngFor="let personType of personTypes"
                    [value]="personType.codice">{{personType.descrizione}}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <!-- PARTY TYPE PHYSICAL [1] -->
      <form class="rgi-ui-col" [formGroup]="physicalPartyForm" *ngIf="keyDataForm.get('physicalPartyForm')">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._PROFESSION_</label>
          <select rgiRxNativeSelect formControlName="professionType" data-qa="profession-type">
            <option [value]=""></option>
            <option *ngFor="let professionType of professionTypes"
                    [value]="professionType.codice">{{professionType.descrizione}}</option>
          </select>
        </rgi-rx-form-field>
      </form>
      <form class="rgi-ui-col" [formGroup]="physicalPartyForm" *ngIf="keyDataForm.get('physicalPartyForm')">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._PROFESSION_DETAIL_</label>
          <select rgiRxNativeSelect formControlName="professionDetail" data-qa="profession-detail">
            <option [value]=""></option>
            <option *ngFor="let professionDetail of state.professionDetails"
                    [value]="professionDetail.codice">{{professionDetail.descrizione}}</option>
          </select>
        </rgi-rx-form-field>
      </form>
      <form class="rgi-ui-col" [formGroup]="physicalPartyForm" *ngIf="keyDataForm.get('physicalPartyForm') && physicalPartyForm.get('homyName')">
           <rgi-rx-form-field>
                  <input rgiRxInput formControlName="homyName" data-qa="rgi-anag-homyName">
                  <label rgiRxLabel translate>_ANAG_._LABEL_._NAME_</label>
            </rgi-rx-form-field>
      </form>
      <form class="rgi-ui-col" [formGroup]="physicalPartyForm" *ngIf="keyDataForm.get('physicalPartyForm') && physicalPartyForm.get('homySurname')">
              <rgi-rx-form-field>
                <input rgiRxInput formControlName="homySurname" data-qa="rgi-anag-homySurname">
                <label rgiRxLabel translate>_ANAG_._LABEL_._SURNAME_</label>
              </rgi-rx-form-field>
      </form>
      <form class="rgi-ui-col" [formGroup]="physicalPartyForm" *ngIf="keyDataForm.get('physicalPartyForm') && physicalPartyForm.get('homyBirthName')">
        <rgi-rx-form-field>
          <input rgiRxInput formControlName="homyBirthName" data-qa="rgi-anag-homyBirthName">
          <label rgiRxLabel translate>_ANAG_._LABEL_._BIRTH_SURNAME_</label>
        </rgi-rx-form-field>
      </form>
      <form class="rgi-ui-col" [formGroup]="physicalPartyForm" *ngIf="keyDataForm.get('physicalPartyForm') && physicalPartyForm.get('homyDateofBirth')">
              <rgi-rx-form-field style="display: contents">
                <div style="display: block">
                  <label rgiRxLabel translate>_ANAG_._LABEL_._BIRTH_DATE_</label>
                  <div style="display:flex;">
                    <input [rgiRxDateTimeInput]="birthDate"
                           style="flex: 1"
                           formControlName="homyDateofBirth"
                           data-qa="rgi-anag-homyDateofBirth"
                           selectMode="single"
                           rangeSeparator="-"
                           #inputToTriggerBirthDate="rgiRxDateTimeInput">
                    <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                            [rgiRxDateTimeTrigger]="inputToTriggerBirthDate"></button>
                  </div>
                </div>
                <rgi-rx-date-time #birthDate
                                  pickerType="calendar"
                                  [hour12Timer]="true"
                ></rgi-rx-date-time>
              </rgi-rx-form-field>
      </form>
      <!-- PARTY TYPE LEGAL [2] -->
      <form class="rgi-ui-col" [formGroup]="legalPartyForm" *ngIf="keyDataForm.get('legalPartyForm')">
        <rgi-rx-form-field>
          <label rgiRxLabel translate>_ANAG_._LABEL_._CORPORATE_FORM_</label>
          <select rgiRxNativeSelect formControlName="corporateForm" data-qa="corporate-form">
            <option *ngFor="let form of corporateForms"
                    [value]="form.codice">{{form.descrizione}}</option>
          </select>
        </rgi-rx-form-field>
      </form>
      <form class="rgi-ui-col" [formGroup]="legalPartyForm" *ngIf="keyDataForm.get('legalPartyForm')">
        <rgi-rx-form-field  *ngIf="legalPartyForm.get('homyCompanyName')">
          <input rgiRxInput formControlName="homyCompanyName" data-qa="rgi-anag-homyCompanyName">
          <label rgiRxLabel translate>{{ '_ANAG_._LABEL_._COMPANY_NAME_' | translate}}</label>
        </rgi-rx-form-field>
      </form>
      <!-- HOMONYMY PARTY KEY -->
        <form class="rgi-ui-col" [formGroup]="partyKeyForm" *ngIf="state.isUmaPartyHomonymyEnable && partyKeyForm && state.partyKeyConf && state.partyKeyConf.key1">
          <rgi-rx-form-field>
            <input rgiRxInput formControlName="partyKey1" (blur)="onPartyKeyChange('partyKey1')" data-qa="rgi-anag-party-key1">
            <label rgiRxLabel>{{state.partyKeyConf?.key1?.label}}</label>
          </rgi-rx-form-field>
        </form>
        <form class="rgi-ui-col" [formGroup]="partyKeyForm" *ngIf="state.isUmaPartyHomonymyEnable && partyKeyForm && state.partyKeyConf && state.partyKeyConf.key2">
          <rgi-rx-form-field>
            <input rgiRxInput formControlName="partyKey2" (blur)="onPartyKeyChange('partyKey2')" data-qa="rgi-anag-party-key2">
            <label rgiRxLabel>{{state.partyKeyConf?.key2?.label}}</label>
          </rgi-rx-form-field>
        </form>
        <form class="rgi-ui-col" [formGroup]="partyKeyForm" *ngIf="state.isUmaPartyHomonymyEnable && partyKeyForm && state.partyKeyConf && state.partyKeyConf.key3">
          <rgi-rx-form-field>
            <input rgiRxInput formControlName="partyKey3" (blur)="onPartyKeyChange('partyKey3')" data-qa="rgi-anag-party-key3">
            <label rgiRxLabel>{{state.partyKeyConf?.key3?.label}}</label>
          </rgi-rx-form-field>
        </form>
    </div>

    <!-- ERRORS -->
    <div class="rgi-ui-snackbar rgi-ui-danger" *ngFor="let err of state?.errors"
         data-qa="rgi-anag-httpError">
      <span class="rgi-ui-icon-snackbar rgi-ui-icon-alert"></span>
      <span class="rgi-ui-message">{{err}}</span>
    </div>
  </div>
  <rgi-rx-snackbar tag="er-tag"></rgi-rx-snackbar>
  <rgi-rx-snackbar tag="errorMsg-tag"></rgi-rx-snackbar>
  <div class="rgi-ui-panel-footer">
    <button class="rgi-ui-btn rgi-ui-btn-secondary" type="button" (click)="actionCancel()"
            data-qa="anag-btn-cancel" translate>_ANAG_._BTN_._CANCEL_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="manageConfirm()"
            [disabled]="state.errors.length"
            data-qa="anag-btn-confirm" translate>_ANAG_._BTN_._CONFIRM_
    </button>
  </div>
</form>
