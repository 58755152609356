export const digitalSignDocuments = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsalesComplete">
    <iiab-digital-sign-documents
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </iiab-digital-sign-documents>
</div>`;

export const lifeDetailCardExt = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsalesComplete">
    <iiab-lpc-life-detail-ext
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [data]="data">
    </iiab-lpc-life-detail-ext>
</div>`;

export const inboundDocumentDetailExt = `<div class="ng-card-wrapper" id="life-detail-card-wrapper" ng-controller="AngularCommunicationCtrlPostsalesComplete">
    <iiab-inquiry-in-document-ext
        (event-propagation)="wrapEvent($event)"
        [id]="card.id"
        [movement]="data.lifeInquiryInboundDocuments.movement"
        [contract-id]="data.lifeInquiryInboundDocuments.contractId"
        [proposal-number]="data.lifeInquiryInboundDocuments.proposalNumber">
    </iiab-inquiry-in-document-ext>
</div>`;
