import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { AuthService } from '../../services/auth.service';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { AnagService } from '../../services/anag.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import {
  BeneficiaryDefinition, BeneficiaryRole, Definition, FactorDefinition,
  OperationBeneficiaryData, OperationProperty, PaymentTypeDefinition, PostsalesError, PostsalesOperationObject, Role
} from '../../models/postsales-operations-response.model';
import { RequestFactor } from '../../models/factor.model';
import { switchMap, tap } from 'rxjs/operators';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { ADVANCE_PAYMENT, AdvancePaymentStepperConfig } from './config/advance-payment-configuration';
import { Observable, of } from 'rxjs';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { InvestmentMode, OperationPropertyCode } from '../../models/operation-property-code.enum';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { OperationWithFinancialStepComponent } from '../../interfaces/impl/operation-with-financial-step.component';
import { KarmaProfileDefinition } from '../../modules/lpc-karma-funds/model/karma-profile-definition';
import { KarmaFundDefinition } from '../../modules/lpc-karma-funds/model/karma-fund-definition';
import { CurrencyCacheService, LpcCurrencyCache } from '../../services/currency-cache.service';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';

@Component({
  selector: 'lpc-advance-payment',
  templateUrl: './advance-payment.component.html',
  styleUrls: ['./advance-payment.component.css']
})
export class AdvancePaymentComponent extends OperationWithFinancialStepComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  protected operationDataKey = 'advance-payment';
  public listProductFactor: FactorDefinition[] = [];
  public listGoodFactor: FactorDefinition[] = [];
  public requestFactor: RequestFactor[] = [];
  private $paymentTypes: PaymentTypeDefinition[] = [];
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  private _beneficiaryData: OperationBeneficiaryData[];
  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];
  protected anagSubj = null;
  public isMandatoryBeneficiaries = false;
  public beneficiariesData: any;
  protected requestAssetFactor: RequestFactor[] = [];
  protected requestProductFactor: RequestFactor[] = [];
  protected advancePaymentTables: any[] = [];
  public advancePaymentTransferData: any;
  // mappa degli amount dei profili che si aggiorna ad ogni chiamata
  public disinvestmentProfileAmountMap = new Map<string, number>();
  public disinvestmentProfileAmountWithPercentageMap = new Map<string, number>();
  public disinvestmentProfiles: KarmaProfileDefinition[] = [];

  public readonly ADVANCE_PAYMENT_STEP: AdvancePaymentStepperConfig =
  ADVANCE_PAYMENT;

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  public advancePaymentHeaders: string[] = [
    'requestDate',
    'effectiveDate',
    'requestReason',
    'advancePaymentAmount',
    'settlementStatus',
    'repaidAmount',
    'notRepaidAmount'
  ];

  public advancePaymentTransferHeaders: string[] = [
     'fiscalDate',
     'previousPension',
     'fiscalCompetence',
     'advancePaymentAmount',
     'settlementStatus',
     'repaidAmount',
     'notRepaidAmount'
  ];

  public advancePaymentData: any;

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return '';
    }
  }

  get beneficiaryType(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  public get hasProductFactors(): boolean {
    return !!this.listProductFactor.length;
  }

  public get hasAssetFactors(): boolean {
    return !!this.listGoodFactor.length;
  }

  private $investedProfiles: KarmaProfileDefinition[] = [];
  get investedProfiles(): KarmaProfileDefinition[] {
    return this.$investedProfiles;
  }

  // FLAG PER SALTARE GLI STEP DI INVESTIMENTI
  public get skipDisinvestment(): boolean {
    return this.disinvestmentProfiles && this.disinvestmentProfiles.length > 0 &&
      this.disinvestmentProfiles.every(
        p => p.maxPercentAllocation === p.minPercentAllocation && p.maxPercentAllocation > 0
      );
  }
  // PROFILI SELEZIONATI
  get selectedDisinvestmentProfiles(): KarmaProfileDefinition[] {
    const profiles: any = this.formGroup.getRawValue().disinvestmentProfiles ? this.formGroup.getRawValue().disinvestmentProfiles : {};
    const profileKeys = Object.keys(profiles).filter(key => !!profiles[key]);
    return this.disinvestmentProfiles.filter(pr => profileKeys.includes(pr.id));
  }

  public getInvestmentInsertMode() {
    const prop: OperationProperty = this.getOperationPropertyByCode(OperationPropertyCode.DISINVESTMENT_MODE);
    if (!!prop) {
      return prop.stringValue as InvestmentMode;
    }
    return undefined;
  }

  public getActivePanelIds(fieldId: string): string[] {
    const panels = [];
    if (!!fieldId) {
      if (fieldId === ADVANCE_PAYMENT.FUND_DISININVESTMENT.formName) {
        this.selectedDisinvestmentProfiles.forEach(el => {
          panels.push('panel' + el.id);
        });
      }
    }
    return panels;
  }

  public getProfilePercent(id: string): number {
    return this.formGroup.get(ADVANCE_PAYMENT.PROFILE_DISININVESTMENT.formName).value[id];
  }

  public getProfileAmount(id: string): number {
    const percentage = this.formGroup.get(ADVANCE_PAYMENT.PROFILE_DISININVESTMENT.formName).value[id];
    const amount = this.totalAmountToInvest;
    if (!!amount) {
      return amount * percentage;
    }
    return 0;
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected karmaService: KarmaFundService,
    protected angularCommunicationService: AngularCommunicationService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anagService: AnagService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anagService, karmaService, angularCommunicationService
    );
    this.currencyFormatterOptions.currency = currencyService.currency;
  }


  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      [ADVANCE_PAYMENT.DATE.formName]: new UntypedFormControl(),
      [ADVANCE_PAYMENT.POLICY_FACTORS.formName]: new UntypedFormGroup({}),
      [ADVANCE_PAYMENT.QUESTS_UNIT.formName]: new UntypedFormGroup({}),
      [ADVANCE_PAYMENT.ASSET_FACTORS.formName]: new UntypedFormGroup({}),
      [ADVANCE_PAYMENT.PROFILE_DISININVESTMENT.formName]: new UntypedFormControl({}),
      [ADVANCE_PAYMENT.FUND_DISININVESTMENT.formName]: new UntypedFormGroup({}),
      [ADVANCE_PAYMENT.BENFICIARIES.formName]: new UntypedFormControl([]),
      [ADVANCE_PAYMENT.QUOTATION.formName]: new UntypedFormControl({})
    });
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().pipe(
        tap(result => {
          LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
          this.insuredSettlements = result.data.operationData.data.insuredSettlements;
          this.advancePaymentData = result.definitions.advancePayments;
          this.advancePaymentTransferData = result.definitions.advancePaymentTransfers;
          this.tableDefinitions = result.definitions.settlement as Definition[];
          this.listProductFactor = PlcObjectUtils.asValidArray(result.definitions.productFactors as FactorDefinition[]);
          this.listGoodFactor = PlcObjectUtils.asValidArray(result.definitions.goodsFactors as FactorDefinition[]);
          if (result.definitions.beneficiaries != null) {
            this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
          }
          this._beneficiaryData = result.data.operationData.data.beneficiaries;
          if (!!this._beneficiaryData) {
            this.formGroup.get(ADVANCE_PAYMENT.BENFICIARIES.formName).setValue(
              (this._beneficiaryData as any[]).map(beneficiary => {
                return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
              }),
              {emitEvent: false}
            );
          }
        })
      ).subscribe()
    );
  }

  public isPaymentVisibile(): boolean {
    if (this.hasOperationPropertyByCode(OperationPropertyCode.POST_SALES_PAYMENT_TYPES)) {
      return PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(OperationPropertyCode.POST_SALES_PAYMENT_TYPES).booleanValue
      );
    }
    return true;
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestProductFactor = factors;
    this.$subscriptions.push(this.onReload(ADVANCE_PAYMENT.POLICY_FACTORS.formName).subscribe(result =>
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[]));
  }

  public updateAssetFactors(factors: RequestFactor[]) {
    this.requestAssetFactor = factors;
    this.$subscriptions.push(this.onReload(ADVANCE_PAYMENT.ASSET_FACTORS.formName).subscribe(result =>
      this.listGoodFactor = result.definitions.goodsFactors as FactorDefinition[]));
  }

  onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, '', this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, '', this);
        })
      );
    }
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

  protected getTransformedOperationData(): any {
    const operationData = {
      listProductFactor: !!this.requestProductFactor ? this.requestProductFactor : [],
      listGoodFactor: !!this.requestAssetFactor.length ? this.requestAssetFactor : [],
      beneficiaries: this.formGroup.getRawValue().beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        }),
      blockBeneficiaries: this.blockBeneficiaries,
      insuredSettlements: this.insuredSettlements,
      mandatoryBeneficiaries: this.isMandatoryBeneficiaries
    } as any;
    if (!!this.formGroup.getRawValue().disinvestmentProfiles && !!this.formGroup.getRawValue().disinvestmentFunds &&
    !!this.disinvestmentProfiles) {
      operationData.disinvestments = LpcKarmaFundUtils.getBackendStructureOfProfiles(
        this.disinvestmentProfiles,
        this.formGroup.getRawValue().disinvestmentProfiles,
        this.formGroup.getRawValue().disinvestmentFunds
      );
    }
    return operationData;
  }

  public updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload);
    this.insuredSettlements = result.data.operationData.data.insuredSettlements;
    this.tableDefinitions = result.definitions.settlement as Definition[];
    this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
    this.isMandatoryBeneficiaries = result.data.operationData.data.mandatoryBeneficiaries;
    this.blockBeneficiaries = result.data.operationData.data.blockBeneficiaries;
    const totalAmount = this.getTotalAmountToInvest(result);
    if(!!totalAmount) {
      this.totalAmountToInvest = totalAmount;
    }
    // eslint-disable-next-line max-len
    this.beneficiariesDefinitions = result.definitions.beneficiaries && (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    this.formGroup.get(ADVANCE_PAYMENT.BENFICIARIES.formName).setValue(
      this.getClaimBeneficiaries(result),
      { emitEvent: false }
    );
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    if (this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
    return this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id)
        .pipe(switchMap(_ => this.defaultUpdateDraft(step, reload, opDataType)));
    } else {
      return this.defaultUpdateDraft(step, reload, opDataType);
    }
  }

  private defaultUpdateDraft(step: string, reload: boolean, opDataType: string): Observable<PostsalesOperationObject> {
    return super.updateDraft(step, reload, opDataType).pipe(
      switchMap(result => {
        this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
        LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
        this.beneficiariesDefinitions = result.definitions.beneficiaries &&
          (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        this.tableDefinitions = result.definitions.settlement as Definition[];
        this.insuredSettlements = result.data.operationData.data.insuredSettlements;

        this.inizializeFinancialSteps(result, step);
        return of(result);
      })
    );
  }

  private inizializeFinancialSteps(result: PostsalesOperationObject, step: string) {
    const disinvestmentProfiles: any = result.definitions.disinvestmentProfiles;
    if (!!disinvestmentProfiles && !!disinvestmentProfiles.length) {
      this.updateProfilesAndFunds(step, result.definitions);
      //this.recalculateTotalAmount(result.definitions);
      if (this.canCalculateVolatility(step)) {
        this.onCalculateVolatility();
      }
    }
  }

  private getTotalAmountToInvest(result: any) {    
    return result?.definitions?.trendSurrenderAmount?.value ?? 0;
  }

  // ricalcola il totale disinvestito e lo setta nelle mappe
  // disinvestmentProfileAmountMap -> contiene l'amount della definitions
  // disinvestmentProfileAmountWithPercentageMap -> contiene l'amount di ogni fondo diviso per la perc disinvestita
  /**
   * @deprecated The method should not be used >> 
   * https://support.rgigroup.com/browse/RDDL-12125 
   * Anticipazione - Errato importo da disinvestire - FE
   */
  recalculateTotalAmount(definitions: any) {
    let totalDisinvestmentAmount = 0;
    const disinvestmentFg = this.formGroup.get(ADVANCE_PAYMENT.PROFILE_DISININVESTMENT.formName) as UntypedFormGroup;
    if (definitions.disinvestmentProfiles && !!definitions.disinvestmentProfiles.length) {
      definitions.disinvestmentProfiles.forEach(p => {
        const disinvestedPercentage = !!disinvestmentFg.value ? +disinvestmentFg.value[p.id] : null;
        let disinvestedAmount = PlcObjectUtils.roundToDecimalTrunc(p.amount, 2);
        if (!p.amount) {
          const amount = definitions.investedProfiles.find(iprof => iprof.id === p.id).counterValue;
          disinvestedAmount = (amount * (!!disinvestedPercentage ? +disinvestedPercentage : 0));
        }
        totalDisinvestmentAmount += disinvestedAmount;
        // Aggiorno le mappe con il totale per profilo e con il totale pesato con la perc
        this.disinvestmentProfileAmountMap.set(p.id, PlcObjectUtils.roundToDecimalTrunc(p.amount, 2));
        this.disinvestmentProfileAmountWithPercentageMap.set(p.id, disinvestedAmount);
        });
    }
    this.totalAmountToInvest = totalDisinvestmentAmount;
  }

  public updateProfilesAndFunds(step, definitions) {
    super.updateProfilesAndFunds(step, definitions);
    // INVESTIMENTI - VISIBILITà STEP & GESTIONE UPDATE
    if (this.showDisinvestment) {
      this.initDisinvestmentProfilesAndFunds(step, definitions);
    }

  }

  private initDisinvestmentProfilesAndFunds(step, definitions): void {
    // INIZIALIZZO I PROFILES SOLO LA PRIMA VOLTA, POI NON DEVO PIù CONSIDERARE LE DEFINITIONS
    if (!this.$investedProfiles || this.$investedProfiles.length === 0) {
      this.$investedProfiles = definitions.investedProfiles;
    }
    const disinvestmentProfilesDefinition = definitions.disinvestmentProfiles;
    if (!!disinvestmentProfilesDefinition && !!disinvestmentProfilesDefinition.length) {
      this.disinvestmentProfiles.forEach(profile => {
        (this.formGroup.get(ADVANCE_PAYMENT.FUND_DISININVESTMENT.formName) as UntypedFormGroup).removeControl(profile.id.toString());
      });
      this.disinvestmentProfiles = disinvestmentProfilesDefinition; // CASO PREVALORIZZAZIONE (IN TENDENZA O DA ULTIMO MOVIMENTO)
      const profilesFormValue: any = {};
      this.disinvestmentProfiles.forEach(profile => {
        const fundsFormValue: any = {};
        const profileValue = LpcKarmaFundUtils.getElementDefaultValue(profile);
        profilesFormValue[profile.id] = profileValue === 100 ? 1 : profileValue;
        profile.funds.forEach(fund => {
          const fundValue = LpcKarmaFundUtils.getElementDefaultValue(fund);
          fundsFormValue[fund.id] = fundValue === 100 ? 1 : fundValue;
        });
        this.formGroup.get(ADVANCE_PAYMENT.PROFILE_DISININVESTMENT.formName).setValue(profilesFormValue, {emitEvent: false});
        (this.formGroup.get(ADVANCE_PAYMENT.FUND_DISININVESTMENT.formName) as UntypedFormGroup)
        .addControl(profile.id.toString(), new UntypedFormControl());
        this.formGroup
        .get(ADVANCE_PAYMENT.FUND_DISININVESTMENT.formName)
        .get(profile.id.toString())
        .setValue(fundsFormValue, {emitEvent: false});
      });
    }
  }

  // restituisce i fondi investiti di un profilo, ovvero quellli da cui si può disivestire
  public getInvestedFundsByProfileId(id: number): KarmaFundDefinition[] {
    const profileFound = this.investedProfiles.find(el => el.id.toString() === id.toString());
    if (!!profileFound) {
      return profileFound.funds;
    }
  }
  public getDisinvestmentAmountWithProfileId(profileId: string): number {
    return this.disinvestmentProfileAmountWithPercentageMap.get(profileId);
  }

  getClaimBeneficiaries(result: any) {
    if (result.data.operationData.data.beneficiaries) {
      return (result.data.operationData.data.beneficiaries as any[]).map(beneficiary => {
        return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
      });
    }
    return [];
  }


}
