import {Injectable} from "@angular/core";
import {AnagFormFieldConfig} from "../anag-model/anag-domain/anag-form-field-config";
import {UntypedFormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class AnagExtensionsService {
  constructor() {}

  addExtensionProperty(extensions: Array<{}>, key: string, value: string) {
    if (value) {
      extensions.push({chiave: key, valore: value});
    }
  }

  // method to permit extension in projects
  onChangeDocType(formMap: Map<string, AnagFormFieldConfig>, actualForm: UntypedFormGroup, statePartyEditor: any) {
  }

  isTerrorismVisibileOtherChecks():boolean {
    return true;
  }

  isDisabledPartyKeyNation():boolean {
    return false;
  }
}
