import {PortalNgCommonTranslationKeys} from './translation.keys';

export const ES: PortalNgCommonTranslationKeys = {
    PORTAL_NG_COMMON: {
        ADD: 'Agregar',
        ADD_DOCUMENT: 'Añadir documento',
        DOCUMENTS_MANAGER: 'Administrador de documentos',
        DOCUMENT_TYPE: 'Tipo de documento',
        DOCUMENT_VALIDATION_MESSAGE: 'Proceder con la validación del documento?',
        DOWNLOAD: 'DESCARGAR',
        INPUT_DOCUMENTS: 'Documentos anexos​',
        LOAD: 'Aceptar',
        NO_FILES: 'No hay archivos',
        NOTE: 'Nota',
        OUTPUT_DOCUMENTS: 'Documentos de salida',
        SAVE: 'GUARDAR',
        STATE: 'Estado',
        UPDATE_FILE: 'Actualizar archivo',
        UPLOAD: 'Añadir documento',
        UPLOAD_NEW_DOCUMENT: 'Añadir documento',
        VALIDATION: 'Validación',
        VALIDATION_DOCUMENT: 'Documento de validación',
        DELETE_DOC: 'Eliminar documento',
        FILE: 'Archivo',
        CANCEL: 'CANCELAR',
        CONFIRM: 'Confirmar',
        CODE: 'Código',
        NODE_LIST: 'Lista de Nodos'
    }
}