import {SharedComponentsTranslationKeys} from './translation.keys';

export const DE: SharedComponentsTranslationKeys = {
    RGI_SHARED_COMPONENTS: {
      ADD: 'DE_Add',
      ADD_DOCUMENT: 'DE_Add document',
      ARCHIVE: 'DE_Archive',
      ARCHIVED: 'DE_Archived',
      ARCHIVE_DOC: 'DE_Archive document',
      ARCHIVING_USER: 'DE_Archiving User',
      CANCEL: 'DE_Cancel',
      CLOSE: 'DE_Close',
      CODE: 'DE_Code',
      CONFIRM: 'DE_Confirm',
      CREATED: 'DE_Created',
      DELETE_DOC: 'DE_Delete document',
      DELIVERED: 'DE_Delivered',
      DELIVERY_DOC: 'DE_Delivery document',
      DELIVERY_ADDRESS: 'DE_Delivery Address',
      DELIVERY_PARTY: 'DE_Delivery Party',
      DELIVERY_USER: 'DE_Delivery User',
      DELIVERY_TYPE: 'DE_Delivery Type',
      DESCRIPTION: 'DE_Description',
      DOCUMENTS_MANAGER: 'DE_Documents Manager',
      DOCUMENT_TYPE: 'DE_Document type',
      DOCUMENT_VALIDATION_MESSAGE: 'DE_Proceed with the validation of the document?',
      DOWNLOAD: 'DE_Download',
      DOWNLOAD_DOC: 'DE_Download document',
      EDIT: 'DE_Edit',
      FILE: 'DE_File',
      ERROR: 'DE_Error',
      INPUT_DOCUMENTS: 'DE_Input Documents',
      LOAD: 'DE_LOAD',
      NO: 'DE_No',
      NOTE: 'DE_Note',
      NO_FILES: 'DE_No files',
      NO_SIGN_NEEDED: 'DE_Document does not require signature',
      NOT_DELIVERED: 'DE_Not delivered',
      NOT_SIGNED: 'DE_Not signed',
      NUMBER_OF_FILES_INSERTED: 'DE_{{ filesLength }} of {{ fileMax }} files inserted',
      OUTBOUND_DOC_TITLE: 'DE_Policy documents nr. {{ policyNumber }} of movement {{ movementDesc }}',
      OUTBOUND_DOC_PROPOSAL_TITLE: 'DE_Proposal documents nr. {{ proposalNumber }} of movement {{ movementDesc }}',
      OUTPUT_DOCUMENTS: 'DE_Output Documents',
      PENDING_SIGNATURE_TOOLTIP: 'DE_Document waiting for signature. Click to stop the process',
      REFRESH: 'DE_Refresh',
      SAVE: 'DE_SAVE',
      SEND: 'DE_Send',
      SIGN: 'DE_Sign',
      SIGN_DOCUMENT: 'DE_Sign document',
      SIGNED: 'DE_Signed',
      SIGNED_DOCUMENT: 'DE_Document signed',
      SUBJECT_SENDING_DATE: 'DE_Sending date to registry subject',
      STATE: 'DE_Status',
      TYPE: 'DE_Type',
      TO_BE_GENERATED: 'DE_To be generated',
      TO_BE_UPLOADED: 'DE_To be uploaded',
      UPDATE_FILE: 'DE_Update file',
      UPLOAD: 'DE_UPLOAD',
      UPLOADED: 'DE_Uploaded',
      UPLOAD_NEW_DOCUMENT: 'DE_Upload new document',
      UPLOADING_USER: 'DE_Uploading User',
      VALIDATING_USER: 'DE_Validation User',
      VALIDATED: 'DE_Validated',
      VALIDATION: 'DE_Validation',
      VALIDATION_DOCUMENT: 'DE_Validation document',
      YES: 'DE_Yes',
      NO_DOCUMENTS_CONFIGURED: 'DE_There are no configured documents for this operation'
    }
};
