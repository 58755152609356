<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="{{ROLES_STEP.DATE.label}}" fieldId="{{ROLES_STEP.DATE.fieldId}}" id="{{ROLES_STEP.DATE.id}}" [errors]="errors" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="ROLES_STEP.DATE.formName" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

 <lpc-step
    id="{{ROLES_STEP.QUESTS_FIN.id}}" [hidden]="!validQuestsCode.get('FIN')"
    [jump]="!validQuestsCode.get('FIN')"
    *ngIf="showSectionQuestFinAfterInit && showQuestionnaireByType('FIN')"
    fieldId="{{ROLES_STEP.QUESTS_FIN.fieldId}}" label="{{ROLES_STEP.QUESTS_FIN.label}}" (next)="onNext($event)">
   <lpc-questionnaire [active]="!isAfterId('questsFin')" [key]="composeKey(key, 'questsFin')"
                      [questionnairesCode]="getQuestMap('FIN')" type="FIN"
                      [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                      [date]="effectiveDate" [savedIds]="questionnairesFromAuthorization"
                      [productCode]="productCode"
                      (loaderQuest)="loaderQuestManager($event)"
                      (loaded)="countQuest($event)"
                      #financialQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step label="{{ROLES_STEP.ROLES.label}}" fieldId="{{ROLES_STEP.ROLES.fieldId}}" id="{{ROLES_STEP.ROLES.id}}" [errors]="errors" (next)="onNext($event, true)"
            (confirmAccept)="onNext($event, true, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <div data-qa="step-roles-roles"> <!-- RUOLI -->
      <div *ngIf="isActiveStep(ROLES_STEP.ROLES.id)">
        <div class="row">
          <div [ngClass]="{'col-lg-6': isInitialHolderChanged, 'col-lg-12': !isInitialHolderChanged}">
            <lpc-roles-control
              [roleDefinitions]="getDefinitionsByCodes(getRoleCode('contractor'))"
              [roles]="getRolesDataByCodes(getRoleCode('contractor'))"
              [disableButtons]="disableContractor"
              (add)="addRole($event)"
              (delete)="deleteRole($event)">
            </lpc-roles-control>
          </div>
          <div *ngIf="isInitialHolderChanged" class="col-lg-6">
            <label class="label">
              {{'lpc_variation_reason' | translate}}
              <lpc-required required="true"></lpc-required>
            </label>
            <select class="drop-container dashedSelect"
              data-qa="variation-reason-dashedSelect"
              [value]="session.subCauseCode"
              (change)="changeSubCause($event)" required>
              <option *ngFor="let reason of changeReasons" [value]="reason.code">{{reason.label}}</option>
              <option value="" selected disabled hidden></option>
            </select>
          </div>
        </div>
        <div *ngIf="roles?.length !== 0">
          <div class="core-header-icon ">
            <span class="header-text" translate>lpc_holders</span>
            <span class="pull-right header-text"> </span>
          </div>
          <div class="row">
          <div *ngFor="let definition of getExcludedDefinitionsByCodes(getRoleCode('contractor'), getRoleCode('effectiveHolder')) ">
            <div class="col-lg-6">
              <lpc-roles-control
                [roleDefinitions]="[definition]"
                [roles]="getRolesDataByCodes(definition.code)"
                [contractor]="holderRole"
                [enableDelegateCheckbox]="enableRoleCheckbox('delegate')"
                [enableLegalGuardianCheckbox]="enableRoleCheckbox('legalGuardian')"
                [enableThirdPayerCheckbox]="enableRoleCheckbox('thirdPayer')"
                (add)="addRole($event)" [disableButtons]="disableAllRoles"
                (addSub)="addSubRole($event)"
                (delete)="deleteRole($event)"
                (deleteSub)="deleteSubRole($event)"
                (selectedRoleEqualHolder)="selectRoleEqualHolder($event)"
                (checkboxWarning)="handleRolesEvent({event:'checkboxWarning'})"
                #policyRoles>
              </lpc-roles-control>
            </div>
          </div>
          </div>
          <div class="row">
            <div *ngIf="!!getDefinitionsByCodes(getRoleCode('effectiveHolder')).length" class="core-header-icon ">
              <span class="header-text" translate>lpc_effective_holders</span>
              <span class="pull-right header-text"> </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <lpc-roles-control
                [roleDefinitions]="getDefinitionsByCodes(getRoleCode('effectiveHolder'))"
                [disableButtons]="disableAllRoles"
                [roles]="getRolesDataByCodes(getRoleCode('effectiveHolder'))"
                (delete)="deleteRole($event)"
                (setRolePercentage)="replaceRoleWithPerc($event)"
                (add)="addRole($event)">
              </lpc-roles-control>
            </div>
          </div>
        </div>
      </div>
      <!-- riepilogo -->
      <div *ngIf="!isActiveStep(ROLES_STEP.ROLES.id)" data-qa="summary-roles-step">
        <div class="col-lg-12"> <!-- RUOLI -->
          <div class="col-lg-6">
            <span translate>lpc_policyHolder</span>
            <span>: {{ selectedRoles }}</span>
          </div>
          <div *ngIf="isInitialHolderChanged" class="col-lg-6">
            <span translate>lpc_variation_step_label</span>
            <span>: {{ VariationLabel }}</span>
          </div>
          <div *ngIf="isViewThirdPayer" class="col-lg-6">
            <span translate>lpc_third_party_role</span>
            <span>: {{ thirdPayerName }}</span>
          </div>
          <div *ngIf="isViewEffectiveHolder" class="col-lg-6">
            <span translate>lpc_effective_holder</span>
            <span>: {{ effectiveHolderName }}</span>
          </div>
        </div>
      </div>
    </div>

    <div data-qa="step-roles-payments"> <!-- PAGAMENTI -->
      <div *ngIf="viewStepPayment">
        <div *ngIf="isActiveStep(ROLES_STEP.ROLES.id)" class="core-header-icon ">
          <span class="header-text" translate>{{ROLES_STEP.PAYMENT.label}}</span>
          <span class="pull-right header-text"> </span>
        </div>
        <lpc-payment-step
          [paymentTypeDefinitions]="paymentTypes"
          [formControlName]="ROLES_STEP.PAYMENT.formName"
          [required]="true"
          [summary]="!isActiveStep(ROLES_STEP.ROLES.id)">
        </lpc-payment-step>
      </div>
    </div>

  </lpc-step>

  <lpc-step [hidden]="!validQuestsCode.get('ADVER')" [jump]="!validQuestsCode.get('ADVER')" id="{{ROLES_STEP.QUESTS.id}}" [errors]="errors" [enableAuth]="isAuth" *ngIf="showSectionQuestAfterInit && showQuestionnaireByType('ADVER')"
            fieldId="{{ROLES_STEP.QUESTS.fieldId}}" label="{{ROLES_STEP.QUESTS.label}}" (next)="onNext($event)"
            (confirmAccept)="onNext($event, false, null, true)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-questionnaire
                       [active]="!isAfterId(ROLES_STEP.QUESTS.id)" [key]="composeKey(key, ROLES_STEP.QUESTS.id)"
                       [questionnairesCode]="getQuestMap('ADVER')" type="ADVER"
                       [factors]="questFactorsArray" [disabledQuestions]="disabledQuestionArray"
                       [date]="effectiveDate" [productCode]="productCode" [savedIds]="questionnairesFromAuthorization"
                       (loaderQuest)="loaderQuestManager($event)"
                       (loaded)="countQuest($event)"
                       #avcQuestionnaire>
    </lpc-questionnaire>
  </lpc-step>

  <lpc-step
        *ngIf="isAuth || !!errors && !!errors.length"
        id="{{ROLES_STEP.WARNING.id}}" label="{{ROLES_STEP.WARNING.label}}"
        (next)="publish()"
        [nextLabel]="isAuth ? 'lpc_authorization_request' :  'lpc_confirm_button'"
        (confirmAccept)="publish(isConfirmAndAccept)" [isConfirmAndAccept]="isConfirmAndAccept">

    <lpc-warning-messages *ngIf="!!errors" [errors]="errors"></lpc-warning-messages>

  </lpc-step>

  <lpc-step
    label="{{ROLES_STEP.DOCUMENT.label}}"
    [errors]="errors"
    fieldId="{{ROLES_STEP.DOCUMENT.fieldId}}"
    id="{{ROLES_STEP.DOCUMENT.id}}"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      [contract]="policy"
      [movmentDescription]="session.operationDescription"
      [movementId]="lastMovementId"
      (documentEvent)="handleDocumentEvent($event)"
      [resourceId]="session?.draft">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep(ROLES_STEP.DOCUMENT.id)">
    <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>
