import {AnagTranslationsKeys} from './anag-translations-keys';
/* tslint:disable:max-line-length */
// Please keep the translation keys alphabetically ordered
export const ANAG_EU_ES: AnagTranslationsKeys = {
  _ANAG_: {
    _BTN_: {
      _ADD_: 'Gehitu’',
      _ADD_ADDRESS_: 'Gehitu helbidea',
      _BACK_: 'Atzera',
      _CALCULATE_: 'Kalkulatu',
      _CANCEL_: 'Ezeztatu',
      _CLOSE_: 'Itxi',
      _CONFIRM_: 'Baieztatu',
      _DETAIL_: 'Informazioa',
      _EDIT_: 'Editatu',
      _EMPTY_: 'Hutsik',
      _FIND_: 'Aurkitu',
      _NEW_: 'Berria',
      _NEW_PAYMENT_: 'Ordainketa berria',
      _NORMALIZE_: 'NORMALIZATU',
      _SAVE_: 'Gorde',
      _DELETE_: 'Kendu',
      _DELETE_DEFINITIVELY_: 'Kendu behin betiko',
      _HISTORY_: 'Historia',
      _LIMITATION_MANAGEMENT_: 'Muga kudeatzea',
      _UNDO_: 'Desegin',
      _CONTINUE_: 'Jarraitu'
    },
    _LABEL_: {
      _ACTIONS_: 'Ekintzak',
      _ACTION_TERRORISM_: 'Terrorismoaren inguruko informazioa',
      _ADDRESS_: 'Helbidea',
      _AE_: 'JEZ',
      _AGENCY_: 'Agentzia',
      _ATI_ASSOCIATIONS_: 'UTE elkarteak',
      _BIRTH_DATE_: 'Jaiotze-data',
      _BIRTH_SURNAME_: 'Bataio-izena',
      _BIRTH_PLACE_: 'Jaioterria',
      _CITIZENSHIP_: 'Nazionalitatea',
      _CITY_OF_BIRTH_: 'Jaiotze-hiria',
      _CIVIL_STATE_: 'Egoera zibila',
      _CLIENT_NUMBER_: 'Bezero-zenbakia',
      _CLIENT_UNIQUE_KEY_: 'Subjektuaren gako bakarra',
      _COMPANY_NAME_: 'Konpainiaren izena',
      _CONTACTS_: 'Kontaktuak',
      _CORPORATE_FORM_: 'Forma korporatiboa',
      _CORPORATE_SECTOR_: 'Sektore korporatiboa',
      _CORPORATION_: 'Korporazioa',
      _COWORKER_: 'Lankide baten kode fiskala',
      _CREDIT_: 'Kreditua',
      _CRITERIA_: 'Irizpideak',
      _DEBIT_: 'Zorra',
      _DOCUMENT_: 'Dokumentua',
      _DOCUMENT_NO_: 'Dokumentu-zenbakia',
      _DOCUMENT_TYPE_: 'Dokumentu mota',
      _EMAIL_: 'Helbide elektronikoa',
      _EMAIL2_: 'Helbide elektronikoa 2',
      _EMAIL3_: 'Helbide elektronikoa 3',
      _EXPIRATION_DATE_: 'Epemuga',
      _FAX_NO_: 'Fax-zenbakia',
      _FEMALE_: 'Emakumea',
      _FILL_ID_DATA_: 'Bete alderdiaren identifikazio-datuak',
      _FISCAL_CODE_: 'Kode fiskala',
      _FISCAL_CODE_VAT_NUMBER_: 'Kode fiskala / IFZ',
      _GENDER_: 'Generoa',
      _GENDER_IDENTITY_: 'Genero-identitatea',
      _IDENTIFICATION_DATA_: 'Identifikazio-datuak',
      _INT_PREFIX_: 'Aurrezenbaki int.',
      _LANDLINE_NO_: 'Telefono-zenbakia',
      _PHONE_NO_: 'Telefono zenbakia',
      _LEADER_: 'Liderra',
      _LINK_TYPE_: 'Lotura mota',
      _LINKED_PARTIES_: 'Lotutako alderdiak',
      _MALE_: 'Gizona',
      _MAIN_CONTACT_: 'Kontaktu nagusia',
      _MANAGEMENT_NODE_: 'Kudeaketa-nodoa',
      _MOBILE_NO_: 'Telefono mugikorraren zenbakia',
      _MOBILE_NO_2_: 'Telefono mugikorraren zenbakia 2',
      _MUNICIPALITY_: 'Udalerria',
      _NAME_: 'Izena',
      _NATION_: 'Nazioa',
      _NODES_: 'Nodoak',
      _NO_ADDRESS_MSG_: 'Helbiderik gabe',
      _NUMBER_: 'Zenbakia',
      _OPEN_DATE_: 'Data irekia',
      _PARTICIPANTS_: 'Parte-hartzaileak',
      _PARTIES_: 'Subjektuak',
      _PARTY_: 'Subjektua',
      _PARTY_DATA_: 'Alderdiaren datuak',
      _PARTY_MANAGEMENT_: 'Alderdiaren kudeaketa',
      _PARTY_MERGER_: 'Alderdien fusioa',
      _PARTY_NUMBER_: 'Alderdi-zenbakia',
      _PARTY_TYPE_: 'Alderdi mota',
      _PAYMENT_: 'Ordainketa',
      _PERSON_TYPE_: 'Pertsona mota',
      _PHYSICAL_: 'Fisikoa',
      _POTICALLY_EXP: 'Politikoki esposiziopeko alderdia',
      _PREFIX_: 'Aurrezenbakia',
      _PRIVACY_: 'Pribatutasuna',
      _PRIVACY_CONSENSUS_DATE_: 'Pribatutasunaren onartze-data',
      _CHANGED_ON_: 'Aldatuta',
      _PRODUCTION_NODES_: 'Ekoizpen-nodoak',
      _PROFESSION_: 'Lanbidea',
      _PROFESSION_DETAIL_: 'Lanbidearen xehetasunak',
      _REGISTERED_OFFICE_: 'Erregistratutako bulegoa',
      _RELEASE_AUTHORITY_: 'Jaulkipen-agintaritza',
      _RELEASE_DATE_: 'Jaulkipen-data',
      _RELEASE_LOCATION_: 'Jaulkipenaren kokalekua',
      _REQUIRED_FIELD_: 'Eskatuta',
      _RESIDENCE_: 'Bizitegia',
      _ANTI_TERRORISM_: 'Terrorismoaren aurkako borroka',
      _RESIDENCE_REGISTERED_OFFICE_: 'Bizitegia / Erregistratutako bulegoa',
      _SAE_: 'JESN',
      _SALE_POINT_: 'Salmenta-puntua',
      _SAVED_OK_: 'gorde da',
      _SELECT_PARTY_: 'Hautatu alderdia',
      _SEX_: 'Sexua',
      _STATUS_: 'Egoera',
      _STATUS_NOT_PROCESSED_: 'Prozesatu gabe',
      _STATUS_PROCESSED_: 'Prozesatuta',
      _SUBJECT_: 'Subjektua',
      _SUBJECT_DATA_: 'Alderdiaren datuak',
      _SUBJECT_TYPE_: 'Subjektu mota',
      _SUBJECT_UNIQUE_KEY_: 'Subjektuaren gako bakarra',
      _SUBJNO_: 'Partearen zk.',
      _SUBSYSTEM_: 'Azpisistema',
      _SUBSYSTEM_PERSON_ID_: 'Azpisistemako pertsonaren IDa',
      _SURNAME_: 'Abizena(k)',
      _SURNAMEORCOMPANY_: 'Konpainiaren abizena(k) / izena',
      _VOID_: ' ',
      _VAT_NUMBER_: 'IFZ',
      _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Terrorismoaren aurkako borrokaren edizio-data',
      _COUNTER_TERRORISM_INDICATOR_: 'Terrorismoaren aurkako borrokaren egoera',
      _DOMICILES_: 'Bizilekuak',
      _DOMICILE_: 'Bizilekua',
      _PRINCIPAL_ADDRESS_: 'Helbide nagusia',
      _AVAILABILITY_: 'Eskuragarritasuna',
      _TYPE_: 'Mota',
      _AVAILABILITY_START_DATE_: 'Eskuragarritasunaren hasiera-data',
      _AVAILABILITY_END_DATE_: 'Eskuragarritasunaren amaiera-data',
      _AVAILABLE_FROM_: 'Inprimakia eskuragarri',
      _AVAILABLE_UNTIL_: 'Unitatea eskuragarri',
      _INTERNATIONAL_DIALLING_CODE_: 'Markatze-kode internazionala',
      _LOCAL_AREA_DIAL_CODE_: 'Tokiko markatze-kodea',
      _YES_: 'Bai',
      _NO_: 'Ez',
      _COUNTERTERRORISM_STATUS_ : 'Terrorismoaren aurkako borrokaren egoera',
      _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_ : 'Terrorismoaren aurkako borrokaren edizio-data',
      _ORIGIN_: 'Jatorria',
      _LANGUAGE_: 'Hizkuntza',
      _HONOR_TITLE_ : 'Ohorezko titulua',
      _SONS_: 'Neska-mutil kopurua',
      _ROBINSON_LIST_: 'Robinson zerrenda',
      _CITIZENSHIP_PERMIT_: 'Bizileku-baimena',
      _CITIZENSHIP_EXPIRE_: 'Bizilekua baliozkoa hurrengo datara arte',
      _REG_COUNTRY_: 'Erregistro-herrialdea',
      _EXTERNALS_IDENTIFIERS_: 'Kanpoko identifikatzaileak',
      _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Azpisistema gaiaren inguruko identifikatzaileak',
      _TAX_CLASSIFICATION_: 'Sailkapen fiskalak',
      _TIN_:'TIN / EIN / SSN USA​',
      _RISK_CLASSIFICATION_ : 'Arrisku-sailkapena',
      _REASON_CLASSIFICATION_ : 'Arrazoi-sailkapena',
      _BASIS_CLASSIFICATION_ : 'Oinarrizko sailkapena',
      _CLASSIFICATION_DATE_ : 'Sailkapen-data',
      _COUNTRY_START_DATE_ : 'Herrialdearen hasiera-data',
      _COUNTRY_END_DATE_ : 'Herrialdearen amaiera-data',
      _NO_TAX_CLASSIFICATIONS_ : 'Sailkapen fiskalik gabe',
      _COUNTRY_ : 'Herrialdea' ,
      _NOMINATIVE_ : 'Izenduna',
      _HISTORY_CONSULTATION_: 'kontsultatu historia',
      _DO_NOT_REPLY_: 'Ezarri gabea',
      _DRIVING_LICENSE_TYPE_: 'Gidabaimen mota',
      _DRIVER_QUALIFICATION_CARD_: 'Gidariaren kalifikazio-txartela',
      _ADD_DRIVING_LICENSE_TYPE_: 'Gehitu gidabaimen mota',
      _PRIVACY_INFO_MSG_: 'eu-ES_The field consults the official advertising exclusion files known as the Robinson List (RL) and tells us whether the client is registered in the RL or not.\n' +
        ' \n' +
        'If the Robinson List is set to "Yes", we will not be able to send commercial communications.\n' +
        ' \n' +
        'If the Robinson List is set to "No", commercial communications will only be sent when the Exclude Advertising field is set to No.\n' +
        ' \n' +
        'If the client has not been consulted, the field appears with value "Do not Reply"',
      _LEI_CODE_: 'Legal Entity Identifier',
      _RATING_: 'Balorazioa',
      _RATING_DATE_: 'Balorazio-data',
      _RATING_PERIOD_: 'Balorazio epea',
      _RATING_USER_: 'Erabiltzailea',
      _RATING_ORIGIN_: 'Jatorria',
      _RATING_HISTORY_: 'Balorazioen historia',
      _COWORKERS_: 'Coworkers',
      _COMPANY_: 'Empresa'
    },
    _MSG_: {
      _ASK_DELETION_CONFIRM_: 'Ziur subjektua ezabatu nahi duzula?',
      _DELETION_CONFIRMED_: 'Subjektua ezabatu da',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Gorriz markatutako eremuak derrigorrezkoak dira edo balio okerrak dituzte',
      _ERROR_DATE_: '16 urtetik beherako subjektua. Datuen tratamendurako baimena gurasoek/legezko tutoreek ematen dutela ulertzen da.',
      _ERROR_DATE_SP_: '14 urtetik beherakoen datu pertsonalak tratatzeko baimena',
      _INVALID_DATE_VALUE: 'Data ez da baliozkoa',
      _INVALID_FIELD_: 'Eremua ez da baliozkoa',
      _LENGTHERR_: 'Eremuaren luzera ez da onartzen',
      _LINK_ERROR_: 'Alderdia sartuta dago jada',
      _LINK_ERROR_SAME_PARTY_: 'Ezinezkoa da alderdi bat bere buruarekin lotzea',
      _NO_ELEMENT_TO_SHOW_: 'Ez dago erakusteko elementurik',
      _NO_LINKED_PARTIES_: 'Loturarik ez',
      _NO_RESULTS_: 'Emaitzarik ez',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'Ez da normalizatutako helbidea aurkitu',
      _PATTERNERR_: 'Eremuak zuzenak ez diren karaktereak ditu',
      _REFINE_SEARCH_: 'Partzialki atera da. Zehaztu bilaketa',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Bete hurrengo eremuetako bat gutxienez: Konpainiaren abizena(k) / izena, kode fiskala edo IFZ, bezero-zenbakia, parte-zenbakia, telefono zenbakia, baliozko posta-kodea edo azpisistemaren ID pertsonala',
      _WRONG_INPUT_DATA_: 'Onartu gabeko sarrera-balioekin adierazten da alderdien kudeaketa-fluxua.',
      _INVALID_END_DATE_DOMICILE_: 'Sartu erabilgarritasunaren hasiera-data baino geroagoko erabilgarritasunaren amaiera-data bat',
      _INVALID_START_DATE_DOMICILE_: 'Sartu erabilgarritasunaren amaiera-data baino lehenagoko erabilgarritasunaren hasiera-data bat',
      _INHERITANCE_: 'Sare komertzialean badago gako bakar bera duen erregistro bat. Sakatu Baieztatu dagoeneko erregistroan erregistratutako subjektuaren datuak erabili nahi badituzu; bestela, ezin izango duzu gako bakarraren hobekuntza horrekin jarraitu.',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'Dagoeneko badago baliozko sailkapen fiskala hautatutako herrialde, mota eta aldirako',
      _INFO_HOMONYMIES_: 'Adi! Homonimiak izan daitezkeen parteak aurkitu dira.\n' +
        'Seleccione un partido de la lista para acceder a editar sus datos o el botón CONTINÙA para continuar insertando el nuevo partido.',
      _INFO_NATURAL_PERSON_ALLOWED_: 'Pertsona naturala besterik ez da onartzen',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Entitate legala besterik ez da onartzen',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'Irteera-datak epemuga baino lehenagokoa izan behar du',
      _IBAN_INVALID_ : 'IBAN formatua ez da zuzena',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Sartu dokumentuaren jaulkipen-data baino geroagoko mugaeguna',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: 'Ziur uneko sailkapen fiskala ezabatu nahi duzula?',
      _ASK_DELETION_DOCUMENT_CONFIRM_: 'Baieztatzen al duzu egungo dokumentua ezabatu dela?',
      _DOC_ALREADY_EXIST: ' Dokumentuaren zenbakia prest',
      _INSERT_DOC_TYPE: 'Sartu dokumentu mota jarraitu baino lehen.',
      _INVALID_FORMAT_: 'Invalid format',
      _NORMALIZE_ERROR_MSG: 'Derrigorrezkoa da helbidea normalizatzea'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Editatu alderdia',
  _ANAG_TITLE_PARTY_DETAIL_: 'Alderdiaren xehetasunak',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Alderdiaren kudeaketa',
  _ANAG_TITLE_PARTY_ZOOM_: 'Alderdiaren zoom-a',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Bilaketaren emaitzak',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: ''
};
