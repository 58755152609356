import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {ApiSetup} from '../models/api-models/api-setup';
import {MicHttpService} from '../http/mic-http.service';
import {MagazineDataFilter} from "../models/domain-models/magazine/magazine-data-filter";

@Injectable(
  {providedIn: 'root'}
)
export class MagazinesService {

  private baseApiUrlV2: string;
  private getBrandsEndpoint: string;
  private getModelsEndpoint: string;
  private getSetupsEndpoint: string;
  private getFiltersEndpoint: string;
  protected httpClient: HttpClient;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any) {

    this.httpClient = this.micHttpClient.getHttpClient();
    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.getBrandsEndpoint = this.baseApiUrlV2 + '/magazine/brands';
    this.getModelsEndpoint = this.baseApiUrlV2 + '/magazine/models';
    this.getSetupsEndpoint = this.baseApiUrlV2 + '/magazine/setups';
    this.getFiltersEndpoint = this.baseApiUrlV2 + '/magazine/filters';

  }

  getBrands(magazineType: string, vehicleType?: string): Observable<Array<GenericEntity>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('magazineType', magazineType);
    if(vehicleType){
      httpParams = httpParams.set('vehicleType', vehicleType);
    }
    return this.httpClient.get<Array<GenericEntity>>(this.getBrandsEndpoint, {params: httpParams});
  }

  getModels(magazineType: string, brandCode: string, registrationYear: string, vehicleType: string): Observable<Array<GenericEntity>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('magazineType', magazineType);
    httpParams = httpParams.set('brandCode', brandCode);
    httpParams = httpParams.set('vehicleType', vehicleType);

    if (registrationYear) {
      httpParams = httpParams.set('registrationYear', registrationYear);
    }
    return this.httpClient.get<Array<GenericEntity>>(this.getModelsEndpoint, {params: httpParams});
  }

  getSetups(
    magazineType: string,
    fuelType: string,
    doors: string,
    power: string,
    brandCode: string,
    modelCode: string,
    registrationMonth: string,
    registrationYear: string,
    approvalCode: string,
    vehicleCode: string[]): Observable<Array<ApiSetup>> {

    doors = doors === "" ? null : doors;
    power = power === "" ? null : power;
    fuelType = fuelType === "" ? null : fuelType;

    const magazineSetupFilter = {
      magazineType: magazineType,
      brandCode: brandCode,
      modelCode: modelCode,
      registrationMonth: registrationMonth,
      registrationYear: registrationYear,
      fuelType: fuelType,
      door: doors,
      power: power,
      approvalCode: approvalCode,
      vehicleCode: vehicleCode
    };
    return this.httpClient.post<Array<ApiSetup>>(this.getSetupsEndpoint, magazineSetupFilter);
  }

  getFilters(
    magazineType: string,
    brandCode: string,
    modelCode: string,
    registrationMonth: string,
    registrationYear: string,
    firstInitialization: boolean
  ) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('firstInitialization', firstInitialization);
    if(!firstInitialization){
      httpParams = httpParams.set('magazineType', magazineType);
      httpParams = httpParams.set('brandCode', brandCode);
      httpParams = httpParams.set('modelCode', modelCode);
      httpParams = httpParams.set('registrationMonth', registrationMonth);
      httpParams = httpParams.set('registrationYear', registrationYear);
    }
    return this.httpClient.get<Array<MagazineDataFilter>>(this.getFiltersEndpoint, {params: httpParams});

  }
}
