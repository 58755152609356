import { FactorToolCode } from '../../../models/enums/vita.enum';

export interface ToolConfDefinition {
  code: FactorToolCode;
  type: FactorInputType;
  readOnly: boolean;
  mandatory: boolean;
  value?: any;
  values?: ToolConfOptDefinition[];
  rules?: any;    // TODO: what is this?
  visible: boolean;
  group: string;
  reloadOnChange?: boolean;
}

export enum FactorInputType {
  DATE = 'DATE',
  ENUM = 'ENUM',
  DOUBLE = 'DOUBLE',
  BENEFICIARY = 'BENEFICIARY',
  IBAN = 'IBAN',
  DATELIST = 'DATELIST',
  STRING = 'STRING',
  INT = 'INT',
}

export interface ToolConfOptDefinition {
  id: any;
  description: string;
}

export interface Rule {
  ruleType: string;
  value: string;
  dependencies: Dependency[];
}

export interface Dependency {
  factorCode: string;
  rule: Rule;
}
