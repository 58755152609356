<div class="nmf_cont_page">
  <div class="nmf_cont_sez">
    <div class="nmf_cont_titsez nmf_cont_titsez_color">
      <span class="header-icon rgifont rgi-indirizzi nfm_font_size"></span>
      <span class="header-text" translate>Policy Address</span>
      <span class="pull-right header-text"> </span>
    </div>
    <div class="nmf_cont_dati">

      <form [formGroup]="addressesControlForm">

        <div class="btn-group btn-group-justified">
          <div *ngFor="let option of addressesOptions; let i=index" class="btn-group">
            <button (click)="onChangeValue(option)" *ngIf="option.show"
                    [attr.data-qa]="option.dataQa" [ngClass]="{'btn-default btn-quotation-detail': policyContacts?.channel!=option.channel ,
            'btn-warning': policyContacts?.channel==option.channel}"
                    class="btn" translate>{{option.label}}
            </button>
          </div>
        </div>

        <br>
        <template #addressModalOverlay></template>
        <div *ngIf="showPostalAddresses && (!policyContacts?.channel)"
             class="form-group row">
          <div class="tbld_col_issue_title" translate>Addresses</div>
          <br>
          <table class="table">
            <thead>
            <tr>
              <th scope="col" translate>Main</th>
              <th scope="col" translate>Description</th>
            </tr>
            </thead>
            <tbody (addressDeleteEvnt)="delAddress($event)"
                   (addressEditEvnt)="editAddress($event)" (addressSelectedEvnt)="selectAddress($event)"
                   [addressesList]="policyContacts?.addressList" [addressesType]="addressType.POLICYADDRESS"
                   [areAddressesEnabled] = "!isFormDisabled()"
                   data-qa="policy-addresses-list" mic-addresses-list="">
            </tbody>
            <tbody (addressSelectedEvnt)="selectAddress($event)"
                   [addressesList]="policyContacts?.availableAddress" [addressesType]="addressType.SUBSCRIBERADDRESS"
                   [areAddressesEnabled] = "!isFormDisabled()"
                   data-qa="subscriber-addresses-list" mic-addresses-list>
            </tbody>
          </table>
          <div class="padding-top-15">
            <button (click)="addAddress()" class="btn btn-default btn-quotation-detail" data-qa="btn-add-address"
                    translate type="button" *ngIf="!isFormDisabled()">
              Insert an address
            </button>
          </div>
        </div>
        <div *ngIf="policyContacts?.channel==1 && showEmailAddress" id="content-emails">
          <h3 class="address-type" translate>E-mail</h3>
          <table class="table">
            <thead>
            <tr>
              <th scope="col" translate>Main</th>
              <th scope="col" translate>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let email of policyContacts?.availableEmails; let i=index">
              <th scope="row">
                <input (click)="selectMail(email)" [checked]="policyContacts?.email==email" type="radio" [disabled]="isFormDisabled()"/>
              </th>
              <td>
                <span class="address-description" data-qa="subscriber-mail-description">{{email}}</span>
              </td>
            </tr>
            <tr *ngIf="policyContacts?.availableEmails.indexOf(policyContacts.email) < 0">
              <th scope="row">
                <input [checked]="true" type="radio" [disabled]="isFormDisabled()"/>
              </th>
              <td>
                <input class="form-control" data-qa="policy-email-input" formControlName="emailInput" name="email"
                       pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="{{'Email' | translate}}"
                       required [disabled]="isFormDisabled()"/>
              </td>
            </tr>
            <tr *ngIf="policyContacts?.availableEmails.indexOf(policyContacts.email) >= 0">
              <th scope="row">
                <input (click)="selectMail('')" [disabled]="isFormDisabled()" type="radio"/>
              </th>
              <td>
                <span class="address-description">"{{'New Email' | translate}}"</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="policyContacts?.channel==2 && showHomeInsurance" id="content-home-insurance">
          <h3 class="address-type" translate>Home insurance - E-mail</h3>
          <table class="table">
            <thead>
            <tr>
              <th scope="col" translate>Main</th>
              <th scope="col" translate>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let email of policyContacts?.availableEmails; let i=index">
              <th scope="row">
                <input (click)="selectMail(email)" [checked]="policyContacts?.email==email" type="radio" [disabled]="isFormDisabled()"/>
              </th>
              <td>
                <span class="address-description">{{email}}</span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <input (click)="selectMail('')" [checked]="policyContacts?.email!=null && policyContacts?.availableEmails?.indexOf(policyContacts.email) < 0"
                       type="radio" [disabled]="isFormDisabled()"/>
              </th>
              <td>
                {{policyContacts?.email}}
                <input
                  *ngIf="policyContacts.email!=null && policyContacts?.availableEmails.indexOf(policyContacts.email) < 0"
                  [value]="policyContacts?.email" class="form-control"
                  formControlName="emailInput" name="email"
                  placeholder="{{'Email' | translate}}" required type="email"/>
                <span
                  *ngIf="policyContacts.email==null || policyContacts?.availableEmails.indexOf(policyContacts.email) >= 0"
                  class="address-description">"{{'New Email' | translate}}"</span>
              </td>
            </tbody>
          </table>
          <h3 class="address-type" translate>Home insurance - Phone Number</h3>
          <table class="table">
            <thead>
            <tr>
              <th scope="col" translate>Main</th>
              <th scope="col" translate>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let mobilePhone of policyContacts?.availablePhoneNumbers; let i=index">
              <th scope="row">
                <input (click)="selectPhoneNumber(mobilePhone)" [checked]="policyContacts?.mobilePhone==mobilePhone"
                       type="radio"/>
              </th>
              <td>
                <span class="address-description">{{mobilePhone}}</span>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <input (click)="selectPhoneNumber('')" [checked]="policyContacts?.mobilePhone != null && policyContacts?.availablePhoneNumbers?.indexOf(policyContacts.mobilePhone) < 0"
                       type="radio" [disabled]="isFormDisabled()"/>
              </th>
              <td>
                <input
                  *ngIf="policyContacts?.availablePhoneNumbers.indexOf(policyContacts.mobilePhone) < 0 && policyContacts.mobilePhone!=null"
                  [value]="policyContacts?.mobilePhone" class="form-control" formControlName="phoneInput"
                  name="phone" required type="tel"/>
                <span
                  *ngIf="policyContacts.mobilePhone==null || policyContacts?.availablePhoneNumbers.indexOf(policyContacts.mobilePhone) >= 0"
                  class="address-description">"{{'New Phone Number' | translate}}"</span>
              </td>
            </tbody>
          </table>
        </div>
        <div *ngIf="(addressesControlForm?.invalid && validationMessages && validationMessages?.length > 0)" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>
      </form>
      <br>
    </div>
  </div>

</div>
